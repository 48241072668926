/* @flow */

import moment from 'moment';

function dateInMoreThanXDaysValidator(value: moment, options: {days: number, message: ?string}, key: string, attributes: any) {
  // format value as moment
  let dateValue = value;
  if (typeof dateValue === 'undefined') {
    return 'value required';
  } else if (typeof dateValue === 'string') {
    dateValue = moment(value);
  }

  // make sure number of days has been provided
  if (typeof options.days !== 'number') {
    throw new Error('Days number is needed');
  }

  // make sure message has been provided
  if (options.message === undefined || options.message === null) {
    throw new Error('Message is needed');
  }

  // check if the date is after now+days
  const minDate = moment().add(options.days, 'days');
  if (dateValue.isAfter(minDate, 'day')) {
    return options.message;
  }

  // success
  return undefined;
}

export default dateInMoreThanXDaysValidator;
