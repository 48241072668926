/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import IntlMessageComponent from 'components/formatters/intlMessage';

type ActiveFilterComponentProps = {
  isActiveFilter: boolean;
  handleActiveFilterChange: () => void;
};

export default function ActiveFilterComponent(props: ActiveFilterComponentProps) {

  const handleActiveFilterChange = (event) => props.handleActiveFilterChange(event);

  return (
    <Box mb={2}>
      <Paper elevation={0}>
        <Box p={3}>
          <Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={3}>
            <Grid container item xs={12} md={6}>
              <Grid item xs={12} md={6}>
                <Box display="flex" height="100%" alignItems="center">
                  <b><IntlMessageComponent id="filters.showonlyactive" /></b>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Select
                  // style={{minWidth: 286}}
                  labelId="select-active-dropdown-label"
                  id="select-active-dropdown"
                  value={props.isActiveFilter}
                  onChange={handleActiveFilterChange}
                >
                  <MenuItem value={true}><IntlMessageComponent id={`filters.onlyactive.yes`} /></MenuItem>
                  <MenuItem value={false}><IntlMessageComponent id={`filters.onlyactive.showall`} /></MenuItem>
                </Select>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
}


