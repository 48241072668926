/* @flow */

export default class UpdateCompanyUserWithOfficeRequest {
  companyId: number;
  companyUserId: number;
  functionName: string;
  debtorRoleId: number;
  isoRoleId: number;
  officeId: number;
  buyerRoleId: number;
  sellerRoleId: number;
}
