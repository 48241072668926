/* @flow */

import {all} from 'redux-saga/effects';
import authSagas from './authSaga';
import localeSagas from './localeSaga';

export default function* rootSaga() {
  yield all([
    authSagas(),
    localeSagas(),
  ]);
}
