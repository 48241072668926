/* @flow */

export default class RegisterLeadRequest {
  isoCompanyId: number;
  insurerId: number;
  assignToEdebex: boolean;

  lastName: string;
  firstName: string;
  email: string;
  mobile: string;
  languageId: number;
  titleId: number;
}
