/* @flow */

import React, { useState } from 'react';

import moment from 'moment';
import customSort from 'lib/sortHelpers';

import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import AmountFormatterComponent from 'components/formatters/amount';
import CompanyStatusComponent from 'components/company/status';
import DateFormatterComponent from 'components/formatters/date';
import IntlMessageComponent from 'components/formatters/intlMessage';
import StyledTableCellTextComponent from 'components/table/cellText';
import StyledTableCellNumbersComponent from 'components/table/cellNumbers';
import StyledTableHeadComponent from 'components/table/head';
import StyledTableRowComponent from 'components/table/row';

import SellerReport from 'models/isos/sellers/sellerReport';

const columnData = [
  { id: 'sellerCompany', align:'left', translationKey: 'component.isos.sellers.list.header.sellerCompany', walkme: 'isoinvoices-column2-sellercompany', align: 'left' },
  { id: 'financedAmount', translationKey: 'component.isos.sellers.list.header.financedAmount', walkme: 'isoinvoices-column2-financedamount', align: 'right' },
  { id: 'firstInvoiceCreated', align:'left', translationKey: 'component.isos.sellers.list.header.firstInvoiceCreated', walkme: 'isoinvoices-column2-firstinvoicecreated', align: 'left' },
  { id: 'firstInvoiceFinanced', align:'left', translationKey: 'component.isos.sellers.list.header.firstInvoiceFinanced', walkme: 'isoinvoices-column2-firstinvoicefinanced', align: 'left' },
  { id: 'lastInvoiceCreated', translationKey: 'component.isos.sellers.list.header.lastInvoiceCreated', walkme: 'isoinvoices-column2-lastinvoicecreated', align: 'left' },
  { id: 'lastInvoiceFinanced', translationKey: 'component.isos.sellers.list.header.lastInvoiceFinanced', walkme: 'isoinvoices-column2-lastinvoicefinanced', align: 'left' },
  { id: 'financedAmount2YearsAgo', translationKey: moment().subtract(2, 'year').year().toString(), walkme: 'isoinvoices-column2-financedamount1', align: 'right' },
  { id: 'financedAmountLastYear', translationKey: moment().subtract(1, 'year').year().toString(), walkme: 'isoinvoices-column2-financedamount2', align: 'right' },
  { id: 'financedAmountCurrentYear', translationKey: moment().year().toString(), walkme: 'isoinvoices-column2-financedamount3', align: 'right' },
  { id: 'membershipExpiration', translationKey: 'component.isos.sellers.list.header.membershipExpiration', align: 'left' },
  { id: 'statusId', align:'left', translationKey: 'component.isos.sellers.list.header.statusId', walkme: 'isoinvoices-column2-status', align: 'left' }
];

type SellerReportTableComponentProps = {
  sellers: SellerReport[];
}

export default function SellerReportTableComponent(props: SellerReportTableComponentProps) {
  const [state, setState] = useState({
    order: 'desc',
    orderBy: undefined,
    page: 0,
    pageSize: 10,
    dataLength: props.sellers.length
  });

  const handlePageChange = (_, page) => setState(prevState => ({ ...prevState, page }));
  const handlePageSizeChange = event => setState(prevState => ({ ...prevState, pageSize: event.target.value }));

  const handleSortOrderChange = (orderBy: string) => {
    const order = (state.orderBy === orderBy && state.order === 'desc') ? 'asc' : 'desc';
    setState(prevState => ({ ...prevState, order, orderBy }));
  };

  const sortSellers = () => {
    const sellers = state.order === 'desc'
      ? props.sellers.sort((a, b) => customSort(b[state.orderBy], a[state.orderBy]))
      : props.sellers.sort((a, b) => customSort(a[state.orderBy], b[state.orderBy]));
    return sellers;
  }

  const sellers = sortSellers();

  const resetPage = () => {
    if (state.dataLength !== props.sellers.length) {
      setState(prevState => ({ ...prevState, page: 0, dataLength: props.sellers.length }));
    }
  }
  const checkReset = resetPage();

  return (
    <React.Fragment>
      <Box py={5}>
        <Box component="div" overflow="auto">
          <Table>
            <StyledTableHeadComponent>
              <StyledTableRowComponent>
                {columnData.map(column => (
                  <StyledTableCellTextComponent key={column.id} align={column?.align ?? 'right'} padding="none" data-walkme={column.walkme || ''}>
                    <TableSortLabel active={state.orderBy === column.id} direction={state.order} onClick={() => handleSortOrderChange(column.id)}>
                      <IntlMessageComponent id={column.translationKey} />
                    </TableSortLabel>
                  </StyledTableCellTextComponent>
                ))}
              </StyledTableRowComponent>
            </StyledTableHeadComponent>

            <TableBody bgcolor="white">
              {!sellers.length &&
                <StyledTableRowComponent>
                  <StyledTableCellTextComponent colSpan={columnData.length}>
                    <Box textAlign="center">
                      <IntlMessageComponent id="component.isos.sellers.list.noData" />
                    </Box>
                  </StyledTableCellTextComponent>
                </StyledTableRowComponent>
              }

              {sellers.slice(state.page * state.pageSize, (state.page * state.pageSize) + state.pageSize).map((seller, index) => (
                <StyledTableRowComponent key={`invoice-${index}`}>
                  <StyledTableCellTextComponent padding="none">{seller.sellerCompany}</StyledTableCellTextComponent>
                  <StyledTableCellNumbersComponent padding="none"><AmountFormatterComponent amount={seller.financedAmount} minimumFractionDigits={0} maximumFractionDigits={0} /></StyledTableCellNumbersComponent>
                  <StyledTableCellTextComponent padding="none"><DateFormatterComponent date={seller.firstInvoiceCreated} /></StyledTableCellTextComponent>
                  <StyledTableCellTextComponent padding="none"><DateFormatterComponent date={seller.firstInvoiceFinanced} /></StyledTableCellTextComponent>
                  <StyledTableCellTextComponent padding="none"><DateFormatterComponent date={seller.lastInvoiceCreated} /></StyledTableCellTextComponent>
                  <StyledTableCellTextComponent padding="none"><DateFormatterComponent date={seller.lastInvoiceFinanced} /></StyledTableCellTextComponent>
                  <StyledTableCellNumbersComponent padding="none"><AmountFormatterComponent amount={seller.financedAmount2YearsAgo} minimumFractionDigits={0} maximumFractionDigits={0} /></StyledTableCellNumbersComponent>
                  <StyledTableCellNumbersComponent padding="none"><AmountFormatterComponent amount={seller.financedAmountLastYear} minimumFractionDigits={0} maximumFractionDigits={0} /></StyledTableCellNumbersComponent>
                  <StyledTableCellNumbersComponent padding="none"><AmountFormatterComponent amount={seller.financedAmountCurrentYear} minimumFractionDigits={0} maximumFractionDigits={0} /></StyledTableCellNumbersComponent>
                  <StyledTableCellTextComponent padding="none"><DateFormatterComponent date={seller.membershipExpiration} /></StyledTableCellTextComponent>
                  <StyledTableCellTextComponent padding="none"><CompanyStatusComponent id={seller.statusId} /></StyledTableCellTextComponent>
                </StyledTableRowComponent>
              ))}
            </TableBody>
          </Table>

          <Box display="flex" height={60}>
            <Box flexGrow={1} />
            <Box justifyContent="flex-end">
              <TablePagination
                component="div"
                count={sellers.length}
                page={state.page}
                rowsPerPage={state.pageSize}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePageSizeChange}
                labelRowsPerPage={<IntlMessageComponent id="general.sellersPerPage" />}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}
