/* @flow */

import React from 'react';
import {useHistory} from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import PhoneNumberComponent from 'components/formFields/phoneNumber';
import ButtonComponent from 'components/buttons/button';
import CompanyLookupSellerComponent from 'components/companyLookupV2/sellers';
import FormErrorsComponent from 'components/formErrors';
import IntlMessageComponent from 'components/formatters/intlMessage';
import MandatoryFormFieldsComponent from 'components/form/mandatory';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import TitleFieldComponent from 'components/formFields/types/title';
import CompanyLookupForm from 'models/companies/companyLookupForm';

import LeadForm from './model';

import {
  UNKNOWN_COMPANY_OFFER
} from 'constants/pageRoutes';

type OfferTab1LeadViewComponentProps = {
  errors: Map<string, ?string>;
  form: LeadForm;
  formErrors: string[];
  isProcessing: boolean;
  showCompanyNotFound: boolean;
  companyLookUpForm: CompanyLookupForm;
  handleChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void;
  handleCompanyChange: () => void;
  handleTypeChange: (fieldName: string) => (id: number) => void;
  handleMobileChange: (value: string) => void;
  submitForm: () => void;
  companyNotFound: () => void;
}

export default function OfferTab1LeadViewComponent(props: OfferTab1LeadViewComponentProps) {
  const history = useHistory();

  const unknownCompanyButton = () => history.push({pathname: UNKNOWN_COMPANY_OFFER, state: {data: props.form, companyLookUpForm: props.companyLookUpForm}});

  return (
    <React.Fragment>
      <FormErrorsComponent formErrors={props.formErrors} />

      <Typography variant="body1" gutterBottom>
        <IntlMessageComponent id="component.offer.tab1.intro" />
      </Typography>

      <Box mt={3}>
        <Grid container spacing={6} alignItems="stretch">
          <Grid item xs={12} md={6}>
            <PageSubTitleViewComponent
              id="component.offer.tab1.company.title"
              data-walkme="offerlead-title-informationsprofessionnelles"
            />

            <Box mt={3}>
              <CompanyLookupSellerComponent
                company={props.form.company}
                companyErrorLabelId={props.errors.get('company')}
                handleSelect={props.handleCompanyChange}
                companyNotFound={props.companyNotFound}
                companyLookUpForm={props.companyLookUpForm}
                required
              />
            </Box>
            {props.showCompanyNotFound && <Box mt={3}>
              <ButtonComponent
                color="primary"
                variant="contained"
                isProcessing={props.isProcessing}
                onClick={unknownCompanyButton}
              ><IntlMessageComponent id="component.offer.tab1.companynotfoundbutton" /></ButtonComponent>
            </Box>}
          </Grid>

          <Grid item xs={12} md={6}>
            <PageSubTitleViewComponent
              id="component.offer.tab1.person.title"
              data-walkme="offerlead-title-informationspersonnelles"
            />
              
            <Box mt={3} px={4} py={3}>
              <TitleFieldComponent
                label={<IntlMessageComponent id="form.title.label" />}
                error={!!props.errors.get('titleId')}
                helperText={(props.errors.get('titleId')) ?
                  <IntlMessageComponent id={props.errors.get('titleId')} /> : ''}
                value={props.form.titleId}
                changeHandler={props.handleTypeChange('titleId')}
                fullWidth
                margin="normal"
                required
              />

              <TextField
                id="form.name"
                data-walkme="subscription-field-nom"
                label={<IntlMessageComponent id="form.name.label" />}
                error={!!props.errors.get('lastName')}
                helperText={(props.errors.get('lastName')) ?
                  <IntlMessageComponent id={props.errors.get('lastName')} /> : ''}
                value={props.form.lastName}
                onChange={props.handleChange('lastName')}
                fullWidth
                margin="normal"
                required
              />

              <TextField
                id="form.firstName"
                data-walkme="subscription-field-prenom"
                label={<IntlMessageComponent id="form.firstname.label" />}
                error={!!props.errors.get('firstName')}
                helperText={(props.errors.get('firstName')) ?
                  <IntlMessageComponent id={props.errors.get('firstName')} /> : ''}
                value={props.form.firstName}
                onChange={props.handleChange('firstName')}
                fullWidth
                margin="normal"
                required
              />

              <TextField
                id="form.email"
                label={<IntlMessageComponent id="form.email.label" />}
                error={!!props.errors.get('email')}
                helperText={(props.errors.get('email')) ?
                  <IntlMessageComponent id={props.errors.get('email')} /> : ''}
                value={props.form.email}
                onChange={props.handleChange('email')}
                fullWidth
                margin="normal"
                required
              />

              <PhoneNumberComponent
                id="form.mobilePresence"
                label={<IntlMessageComponent id="form.mobile.label" />}
                error={!!props.errors.get('mobilePresence')}
                helperText={(props.errors.get('mobilePresence'))
                  ? <IntlMessageComponent id={props.errors.get('mobilePresence')} />
                  : <IntlMessageComponent id="form.mobile.alreadySubscribed.helperText" />}
                value={props.form.mobilePresence}
                onChange={props.handleMobileChange}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
         
      <Box mt={5}>
        <ButtonComponent
          color="primary"
          variant="contained"
          isProcessing={props.isProcessing}
          disabled={props.showCompanyNotFound}
          onClick={props.submitForm}
        ><IntlMessageComponent id="general.button.validate.label" /></ButtonComponent>

        <MandatoryFormFieldsComponent/>
      </Box>
    </React.Fragment>
  );
}