/* @flow */

import {makeStyles} from '@material-ui/core/styles';

import StyledTableRowComponent from './row';

type StyledTableRowSellerInvoiceComponentProps = {
  isRefused: boolean;
  isWaiting: boolean;
}

const useStyles = makeStyles(_ => ({
  normal: {
    borderLeft: '10px solid #fff'
  },
  refused: {
    borderLeft: '10px solid #fff',
    opacity: '0.5'
  },
  waiting: {
    borderLeft: '10px solid #ed1c24'
  }
}));

export default function StyledTableRowSellerInvoiceComponent(props: StyledTableRowSellerInvoiceComponentProps){
  const classes = useStyles();
  const {isRefused, isWaiting, ...rest} = props;

  let style = classes.normal;
  if (isRefused) style = classes.refused;
  if (isWaiting) style = classes.waiting;

  return (
    <StyledTableRowComponent className={style} {...rest}/>
  );
}
