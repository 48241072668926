/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import LoadingComponent from 'components/loading';

import ISOKPIsViewComponent from './view';

import KPI from 'models/dashboards/kpi';

import ISOService from 'services/IsoService';

type ISOKPIsComponentProps = {
  activeCompanyId: number;
}

type ISOKPIsComponentState = {
  isLoading: boolean;
  kpis: KPI[];
}

class ISOKPIsComponent extends React.Component<ISOKPIsComponentProps, ISOKPIsComponentState> {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      kpis: null
    };
  }

  componentDidMount = async () => {
    try {
      const kpis = await ISOService.getKPIs(this.props.activeCompanyId);
      this.setState({isLoading: false, kpis});
    } catch (e) {
      console.error(e);
    }
  }

  render() {
    if (this.state.isLoading) return (<LoadingComponent />);
    return (<ISOKPIsViewComponent kpis={this.state.kpis} />);
  }
}

const mapStateToProps = ({auth}) => ({
  activeCompanyId: auth.user.activeCompany.id
});

export default connect(mapStateToProps)(ISOKPIsComponent);
