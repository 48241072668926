/* @flow */

import React from 'react';

import LoadingComponent from 'components/loading';

import BestPricingAltFinComponent from './altFinancing';
import BestPricingFormComponent from './form';
import BestPricingIntroductionComponent from './introduction';
import BestPricingListComponent from './list';
import BestPricingNotEligibleComponent from './notEligible';
import BestPricingSummaryComponent from './summary';

import BusinessPotential from 'models/sellers/businessPotential';
import DebtorCompany from 'models/companies/debtorCompany';

import ConfirmBusinessPotentialsRequest from 'models/requests/sellers/confirmBusinessPotentialsRequest';
import DeleteBusinessPotentialRequest from 'models/requests/sellers/deleteBusinessPotentialRequest';

import LeadService from 'services/LeadService';
import {handleApiFormResponse} from 'lib/forms';

import NotificationHelper from 'lib/notifications';

import {history} from 'store/index';
import {PROTECTED_APP} from 'constants/pageRoutes';

type BestPricingComponentProps = {
  companyId: number;
  debtorCompany: DebtorCompany;
  isLead: boolean;
}

type BestPricingComponentState = {
  activePanelId: number;
  businessPotential: BusinessPotential;
  businessPotentials: BusinessPotential[];
  isLoading: boolean;
  minimumAverageInvoiceAmount: number;
  minimumYearlyAmount: number;
}

class BestPricingComponent extends React.Component<BestPricingComponentProps, BestPricingComponentState> {

  static PANEL_INTRO = 0;
  static PANEL_FORM = 1;
  static PANEL_LIST = 2;
  static PANEL_ALT_FIN = 3;
  static PANEL_SUMMARY = 4;
  static PANEL_NOT_ELIGIBLE = 5;

  constructor(props) {
    super(props);

    this.state = {
      activePanelId: BestPricingComponent.PANEL_INTRO,
      businessPotential: null,
      businessPotentials: [],
      isLoading: true
    };
  }

  componentDidMount = async () => {
    try {
      const response = await LeadService.getBusinessPotentials(this.props.companyId);
      this.setState({
        businessPotentials: response.businessPotentials,
        isLoading: false,
        minimumAverageInvoiceAmount: response.minimumAverageInvoiceAmount,
        minimumYearlyAmount: response.minimumYearlyAmount
      });
    } catch (e) {
      console.error(e);
    }
  }

  handleIntroductionButtonClick = () => {
    const len = this.state.businessPotentials.length;
    let activePanelId;
    let businessPotential = null;
    if (len === 0) {
      activePanelId = BestPricingComponent.PANEL_FORM;
      if (this.props.debtorCompany !== null) {
        businessPotential = new BusinessPotential();
        businessPotential.debtorCompany = this.props.debtorCompany;
      }
    } else {
      activePanelId = BestPricingComponent.PANEL_LIST;
    }

    this.setState({activePanelId, businessPotential});
  }

  newBusinessPotential = () => {
    this.setState({activePanelId: BestPricingComponent.PANEL_FORM, businessPotential: null});
  }

  cancelNewBusinessPotential = () => {
    this.setState({activePanelId: BestPricingComponent.PANEL_LIST, businessPotential: null});
  }

  editBusinessPotential = (debtorCompanyId: number) => {
    const businessPotential = this.state.businessPotentials.find(bp => bp.debtorCompany.id === debtorCompanyId);
    this.setState({activePanelId: BestPricingComponent.PANEL_FORM, businessPotential});
  }

  updateBusinessPotential = (form: BusinessPotentialForm) => {
    const bps = this.state.businessPotentials;
    let bp = bps.find(b => b.debtorCompany.id === form.debtorCompany.id);
    if (bp) {
      bp.debtorCompany = form.debtorCompany;
      bp.nbrInvoices = form.nbrInvoices;
      bp.avgInvoiceAmount = form.avgInvoiceAmount;
      bp.paymentTerms = form.paymentTerms;
      bp.yearlyAmount = form.nbrInvoices * form.avgInvoiceAmount;
    } else {
      bp = new BusinessPotential();
      bp.debtorCompany = form.debtorCompany;
      bp.nbrInvoices = form.nbrInvoices;
      bp.avgInvoiceAmount = form.avgInvoiceAmount;
      bp.paymentTerms = form.paymentTerms;
      bp.yearlyAmount = form.nbrInvoices * form.avgInvoiceAmount;
      bps.push(bp);
    }
    
    this.setState({activePanelId: BestPricingComponent.PANEL_LIST, businessPotential: null, businessPotentials: bps});
  }

  disableBusinessPotential = async (debtorCompanyId: number) => {
    this.setState({isProcessing: true});
  
    try {
      const request = new DeleteBusinessPotentialRequest(this.props.companyId, debtorCompanyId);
      await LeadService.deleteBusinessPotential(request);
      NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS);
      const bps = this.state.businessPotentials.filter(bp => bp.debtorCompany.id !== debtorCompanyId);
      this.setState({businessPotentials: bps, isProcessing: false});      
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }
  }

  confirmBusinessPotentials = async () => {
    this.setState({isProcessing: true});

    try {
      const request = new ConfirmBusinessPotentialsRequest(this.props.companyId, this.state.businessPotentials.map(bp => bp.debtorCompany.id));
      const response = await LeadService.confirmBusinessPotentials(request);
      if (response) {
        this.setState({activePanelId: BestPricingComponent.PANEL_ALT_FIN, isProcessing: false});
      } else {
        this.setState({activePanelId: BestPricingComponent.PANEL_NOT_ELIGIBLE, isProcessing: false});
      }
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }
  }

  displaySummary = () => {
    this.setState({activePanelId: BestPricingComponent.PANEL_SUMMARY});
  }

  displayList = () => {
    this.setState({activePanelId: BestPricingComponent.PANEL_LIST});
  }

  redirectToDashboard = () => {
    history.push(`${PROTECTED_APP}`);
  }

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <React.Fragment>
        {this.state.activePanelId === BestPricingComponent.PANEL_INTRO &&
          <BestPricingIntroductionComponent
            minimumAverageInvoiceAmount={this.state.minimumAverageInvoiceAmount}
            minimumYearlyAmount={this.state.minimumYearlyAmount}
            successHandler={this.handleIntroductionButtonClick}
          />
        }
        {this.state.activePanelId === BestPricingComponent.PANEL_FORM &&
          <BestPricingFormComponent
            businessPotential={this.state.businessPotential}
            companyId={this.props.companyId}
            enableCancelButton={this.state.businessPotentials.length > 0}
            successHandler={this.updateBusinessPotential}
            cancelHandler={this.cancelNewBusinessPotential}
          />
        }
        {this.state.activePanelId === BestPricingComponent.PANEL_LIST &&
          <BestPricingListComponent
            businessPotentials={this.state.businessPotentials}
            newBusinessPotential={this.newBusinessPotential}
            editBusinessPotential={this.editBusinessPotential}
            disableBusinessPotential={this.disableBusinessPotential}
            confirmBusinessPotentials={this.confirmBusinessPotentials}
          />
        }
        {this.state.activePanelId === BestPricingComponent.PANEL_ALT_FIN &&
          <BestPricingAltFinComponent
            companyId={this.props.companyId}
            successHandler={this.displaySummary}
          />
        }
        {this.state.activePanelId === BestPricingComponent.PANEL_SUMMARY &&
          <BestPricingSummaryComponent
            businessPotentials={this.state.businessPotentials}
            isLead={this.props.isLead}
            successHandler={this.redirectToDashboard}
          />
        }
        {this.state.activePanelId === BestPricingComponent.PANEL_NOT_ELIGIBLE &&
          <BestPricingNotEligibleComponent
            successHandler={this.displayList}
          />
        }
      </React.Fragment>
    );
  }
}

export default BestPricingComponent;
