/* BUYER SIDE */
export const INVOICE_BUYER_TYPE_TO_PAY = 1; // Exclamation mark, red
export const INVOICE_BUYER_TYPE_TO_BUY_ASSIGNED = 2; // Man, blue
export const INVOICE_BUYER_TYPE_TO_BUY_MARKETPLACE = 3; // Label, blue
export const INVOICE_BUYER_TYPE_TO_BUY_REFUSED = 4;
export const INVOICE_BUYER_TYPE_TO_BUY_WAITING = 5; // Hourglass, grey
export const INVOICE_BUYER_TYPE_TO_BUY_OVERDUE = 6; // Hand, orange
export const INVOICE_BUYER_TYPE_TO_BUY_OVERDUE_FUPBS = 7; // Hand, orange
export const INVOICE_BUYER_TYPE_TO_BUY_PAID = 8; // Transfer, green
export const INVOICE_BUYER_TYPE_TO_BUY_CLOSED = 9; // Check, green

/* DEBTOR SIDE */
export const INVOICE_DEBTOR_TYPE_UNDERWRITING = 'DebtorA';
export const INVOICE_DEBTOR_TYPE_TOAPPROVE = 'DebtorB';
export const INVOICE_DEBTOR_TYPE_APPROVED = 'DebtorC';
export const INVOICE_DEBTOR_TYPE_NOTDUE = "DebtorD";
export const INVOICE_DEBTOR_TYPE_DUE = 'DebtorE';
export const INVOICE_DEBTOR_TYPE_DUE5 = 'DebtorF';
export const INVOICE_DEBTOR_TYPE_DUE15 = 'DebtorG';
export const INVOICE_DEBTOR_TYPE_DUE25 = 'DebtorH';
export const INVOICE_DEBTOR_TYPE_CLAIM = 'DebtorI';
export const INVOICE_DEBTOR_TYPE_CLOSED = 'DebtorJ';
export const INVOICE_DEBTOR_TYPE_REFUSED = 'DebtorL';
export const INVOICE_DEBTOR_TO_PAY_TYPE_NOTDUEMORETHAN7 = 'Debtornotduemorethan7';
export const INVOICE_DEBTOR_TO_PAY_TYPE_NOTDUELESSTHAN7 = 'Debtornotduelessthan7';
export const INVOICE_DEBTOR_TO_PAY_TYPE_DUE = 'Debtordue';
export const INVOICE_DEBTOR_TO_PAY_TYPE_OVERDUE = 'Debtoroverdue';

/* CONFIRMING */
export const INVOICE_CONFIRMING_TYPE_TOSUBMIT = 'ConfirmingA_tosubmit';
export const INVOICE_CONFIRMING_TYPE_MISSINGDOCUMENTS = 'ConfirmingB_missingdocuments';
export const INVOICE_CONFIRMING_TYPE_TOSIGN = 'ConfirmingC_tosign';
export const INVOICE_CONFIRMING_TYPE_DUE = 'ConfirmingD_due';
export const INVOICE_CONFIRMING_TYPE_DUETHISWEEK = 'ConfirmingE_duethisweek';
export const INVOICE_CONFIRMING_TYPE_DUEIN1WEEK = 'ConfirmingF_duein1week';
export const INVOICE_CONFIRMING_TYPE_DUEIN2WEEKS = 'ConfirmingG_duein2weeks';
export const INVOICE_CONFIRMING_TYPE_DUEIN1MONTH = 'ConfirmingH_duein1month';
export const INVOICE_CONFIRMING_TYPE_CLAIM = 'ConfirmingI_claim';
export const INVOICE_CONFIRMING_TYPE_CLOSED = 'ConfirmingJ_closed';
export const INVOICE_CONFIRMING_TYPE_REFUSED = 'ConfirmingK_refused';

/* SELLER SIDE */
export const INVOICE_SELLER_TYPE_PUBLISHED = 0; // Red outlined exclamation mark
export const INVOICE_SELLER_TYPE_PAY_FEES = 1; // Red outlined exclamation mark
export const INVOICE_SELLER_TYPE_FOLLOWUP = 2; // Red outlined exclamation mark
export const INVOICE_SELLER_TYPE_SIGN_DEED = 3; // Won't exists
export const INVOICE_SELLER_TYPE_WAITING_DEBTOR = 4; // Hand orange
export const INVOICE_SELLER_TYPE_CASH_SENT = 5; // Transfer, green
export const INVOICE_SELLER_TYPE_WAITING_EDEBEX = 6; // Hourglass grey
export const INVOICE_SELLER_TYPE_REFUSED = 7;
export const INVOICE_SELLER_TYPE_CANCELLED = 8;
