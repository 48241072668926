/* @flow */

import React from 'react';

import LoadingComponent from 'components/loading';

import BestPricingFormViewComponent from './view';
import BusinessPotentialForm from './model';

import BusinessPotential from 'models/sellers/businessPotential';
import DebtorCompany from 'models/companies/debtorCompany';

import CompanyService from 'services/CompanyService';
import LeadService from 'services/LeadService';
import {handleApiFormResponse, handleFormChange, handleFormValidation} from 'lib/forms';
import loadConstraints from 'lib/validation/loadConstraints';

import NotificationHelper from 'lib/notifications';

type BestPricingFormComponentProps = {
  businessPotential: BusinessPotential;
  companyId: number;
  enableCancelButton: boolean;
  successHandler: (form: BusinessPotentialForm) => void;
  cancelHandler: () => void;
}

type BestPricingFormComponentState = {
  constraints: any;
  errors: Map<string, ?string>;
  form: BusinessPotentialForm;
  formErrors: string[];
  isLoading: boolean;
  isProcessing: boolean;
}

class BestPricingFormComponent extends React.Component<BestPricingFormComponentProps, BestPricingFormComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      constraints: null,
      errors: null,
      form: null,
      formErrors: [],
      isLoading: true,
      isProcessing: false
    };
  }

  componentDidMount = async () => {
    try {
      const constraints = await loadConstraints('BusinessPotential', ['debtorCompany', 'avgInvoiceAmount', 'nbrInvoices', 'paymentTerms']);

      this.setState({
        constraints,
        errors: new Map(Object.keys(constraints).map(e => [e, undefined])),
        form: new BusinessPotentialForm(this.props.businessPotential),
        isLoading: false
      });
    } catch (e) {
      console.error(e);
    }
  }

  handleChange = (fieldName: string) => (event) => handleFormChange.call(this, fieldName, event.target.value);
  validateForm = () => handleFormValidation.call(this);

  handleDebtorCompanyChange = (debtorCompany: ?DebtorCompany) => {
    const form = this.state.form;
    form.debtorCompany = debtorCompany;

    const errors = this.state.errors;
    errors.delete('debtorCompany');

    this.setState({errors, form});
  };

  submitForm = async () => {
    if (!this.validateForm()) return;
    this.setState({formErrors: [], isProcessing: true});

    try {
      const form = this.state.form;
      if (form.debtorCompany.id === 0) {
        form.debtorCompany.id = await CompanyService.addDebtorCompany(form.debtorCompany.insurerId);
      }

      const request = form.toRequest(this.props.companyId);
      await LeadService.upsertBusinessPotential(request);
      NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS);
      this.props.successHandler(form);
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }     
  }

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent h1="page.bestPricing.form.title" />);
    }

    return (
      <BestPricingFormViewComponent
        enableCancelButton={this.props.enableCancelButton}
        errors={this.state.errors}
        form={this.state.form}
        formErrors={this.state.formErrors}
        isProcessing={this.state.isProcessing}
        handleChange={this.handleChange}
        handleDebtorCompanyChange={this.handleDebtorCompanyChange}
        cancelHandler={this.props.cancelHandler}
        submitForm={this.submitForm}
      />
    );
  }
}

export default BestPricingFormComponent;
