/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import customSort from 'lib/sortHelpers';

import LoadingComponent from 'components/loading';
import PaymentDialogComponent from 'components/payments/paymentDialog';

import SellerInvoiceListViewComponent from './view';

import Invoice from 'models/invoices/seller/invoice';
import PaymentInfo from 'models/payments/paymentInfo';

import CancelInvoiceRequest from 'models/requests/sellers/cancelInvoiceRequest';
import InvoiceFeesPaymentRequest from 'models/requests/payments/invoiceFeesPaymentRequest';

import InvoiceSellerService from 'services/InvoiceSellerService';
import PaymentService from 'services/PaymentService';

import NotificationHelper from 'lib/notifications';

type SellerInvoiceListProps = {
  activeCompanyId: number;
}

type SellerInvoiceListState = {
  invoices: Invoice[];
  isLoading: boolean;
  order: string;
  orderBy: string;
  page: number;
  pageSize: number;
  paymentInfo: PaymentInfo;
  paymentIsLoading: boolean;
}

class SellerInvoiceList extends React.Component<SellerInvoiceListProps, SellerInvoiceListState> {

  constructor(props) {
    super(props);
    this.state = {
      invoices: [],
      isLoading: true,
      order: 'asc',
      orderBy: 'price',
      page: 0,
      pageSize: 10,      
      paymentInfo: null,
      paymentIsLoading: false
    };
  }

  componentDidMount = async () => {
    await this.loadInvoices();
  }

  loadInvoices = async () => {
    try {
      const invoices = await InvoiceSellerService.getInvoices(this.props.activeCompanyId);
      invoices.forEach(i => {i.isSelected = false});
      this.setState({invoices, isLoading: false});
    } catch (e) {
      console.error(e);
    }
  }

  handlePageChange = (page: number) => this.setState({page});
  handlePageSizeChange = (pageSize: number) => this.setState({pageSize});

  handleSortOrderChange = (orderBy: string) => {
    const order = this.state.orderBy === orderBy && this.state.order === 'desc' ? 'asc' : 'desc';

    let invoices = null;
    if (orderBy.startsWith('debtorCompany.')) {
      // get exact property name
      let o = orderBy.substring(14);

      // order
      invoices = order === 'desc'
        ? this.state.invoices.sort((a, b) => customSort(b.debtorCompany[o], a.debtorCompany[o]))
        : this.state.invoices.sort((a, b) => customSort(a.debtorCompany[o], b.debtorCompany[o]));
    } 
    else {
      // order
      invoices = order === 'desc'
        ? this.state.invoices.sort((a, b) => customSort(b[orderBy], a[orderBy]))
        : this.state.invoices.sort((a, b) => customSort(a[orderBy], b[orderBy]));
    }

    this.setState({invoices, order, orderBy});
  };

  selectInvoice = (invoiceId: number) => {
    const invoices = this.state.invoices;
    const invoice = invoices.find(i => i.id === invoiceId);
    if (invoice) invoice.isSelected = !invoice.isSelected;
    this.setState({invoices});
  } 

  /* PAY FEES */
  paySelectedFees = async () => {
    const invoiceIds = this.state.invoices.filter(i => i.isSelected).map(i => i.id);
    await this.payFees(invoiceIds);
  }

  payFees = async (invoiceIds: number[]) => {
    this.setState({paymentIsLoading: true});

    try {
      const request = new InvoiceFeesPaymentRequest(this.props.activeCompanyId, invoiceIds);
      const paymentInfo = await PaymentService.requestInvoiceFeesPayment(request);
      this.setState({paymentInfo, paymentIsLoading: false});
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
      this.setState({paymentIsLoading: false});
    }
  };

  handleClosePaymentDialog = () => this.setState({paymentInfo: null});

  cancelInvoice = async (invoiceId: number) => {
    try {
      const request = new CancelInvoiceRequest(invoiceId);
      await InvoiceSellerService.cancelInvoice(request);
      NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS, 'notifications.invoice.cancel.success');
      this.loadInvoices();
    } catch (error) {
      console.error('cancelInvoice', error);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  };

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <React.Fragment>
        <SellerInvoiceListViewComponent
          invoices={this.state.invoices}
          order={this.state.order}
          orderBy={this.state.orderBy}
          page={this.state.page}
          pageSize={this.state.pageSize}
          cancelInvoice={this.cancelInvoice}
          handlePageChange={this.handlePageChange}
          handlePageSizeChange={this.handlePageSizeChange}
          handleSortOrderChange={this.handleSortOrderChange}
          payFees={this.payFees}
          paySelectedFees={this.paySelectedFees}
          selectInvoice={this.selectInvoice}
        />

        {this.state.paymentInfo !== null &&
          <PaymentDialogComponent
            paymentInfo={this.state.paymentInfo}
            handleRequestClose={this.handleClosePaymentDialog}
          />
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps)(SellerInvoiceList);
