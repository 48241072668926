/* @flow */

import React from 'react';

import customSort from 'lib/sortHelpers';

import DebtorInvoicesToApproveTableViewComponent from './view';

import Invoice from 'models/debtors/invoice';

import DebtorService from 'services/DebtorService';
import NotificationHelper from 'lib/notifications';

type DebtorInvoicesToApproveTableComponentProps = {
  invoices: Invoice[];
  isProcessing: boolean;
  token: string;
  refuseInvoice: (invoiceId: number) => void;
  selectInvoice: (invoiceId: number) => void;
}

type DebtorInvoicesToApproveTableComponentState = {
  order: string;
  orderBy: string;
  page: number;
  pageSize: number;
}

class DebtorInvoicesToApproveTableComponent extends React.Component<DebtorInvoicesToApproveTableComponentProps, DebtorInvoicesToApproveTableComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      order: 'asc',
      orderBy: 'debtorReference',
      page: 0,
      pageSize: 10
    };
  }

  handlePageChange = (page: number) => this.setState({page});
  handlePageSizeChange = (pageSize: number) => this.setState({pageSize});

  handleSortOrderChange = (orderBy: string) => {
    let order = this.state.orderBy === orderBy && this.state.order === 'desc' ? 'asc' : 'desc';
    this.setState({order, orderBy});
  }

  getSortedInvoices = () => {
    let invoices = null;
    if (this.state.orderBy.startsWith('sellerCompany.')) {
      // get exact property name
      const o = this.state.orderBy.substring(14);

      // order
      invoices = this.state.order === 'desc'
        ? this.props.invoices.sort((a, b) => customSort(b.sellerCompany[o], a.sellerCompany[o]))
        : this.props.invoices.sort((a, b) => customSort(a.sellerCompany[o], b.sellerCompany[o]));
    }
    else {
      // order
      invoices = this.state.order === 'desc'
        ? this.props.invoices.sort((a, b) => customSort(b[this.state.orderBy], a[this.state.orderBy]))
        : this.props.invoices.sort((a, b) => customSort(a[this.state.orderBy], b[this.state.orderBy]));
    }

    return invoices;
  };

  downloadInvoiceDocument = async (invoiceId: number) => {
    try {
      await DebtorService.downloadInvoiceDocumentToken(this.props.token, invoiceId);
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  }

  downloadInvoiceRelatedDocuments = async (invoiceId: number) => {
    try {
      await DebtorService.downloadInvoiceRelatedDocumentsToken(this.props.token, invoiceId);
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  }


  render() {
    return (
      <DebtorInvoicesToApproveTableViewComponent
        invoices={this.getSortedInvoices()}
        isProcessing={this.props.isProcessing}
        order={this.state.order}
        orderBy={this.state.orderBy}
        page={this.state.page}
        pageSize={this.state.pageSize}
        downloadInvoiceDocument={this.downloadInvoiceDocument}
        downloadInvoiceRelatedDocuments={this.downloadInvoiceRelatedDocuments}
        handleInvoiceSelect={this.props.selectInvoice}
        handlePageChange={this.handlePageChange}
        handlePageSizeChange={this.handlePageSizeChange}
        handleSortOrderChange={this.handleSortOrderChange}
        refuseInvoice={this.props.refuseInvoice}
      />
    );
  }
}

export default DebtorInvoicesToApproveTableComponent;
