import React from 'react';
import { useSelector } from 'react-redux';

import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PageTitleViewComponent from 'components/pageTitle';

import { LOST_PASSWORD, SIGN_UP } from 'constants/pageRoutes';

type SignInFormComponentProps = {
  identifier: string;
  password: string;
  twofactorpin: string;
  hasTwoFactor: Boolean;
  showLoginFailedMessage: boolean;
  showServerErrorMessage: boolean;
  handleLoginChange: (login: string) => void;
  handlePasswordChange: (password: string) => void;
  handleTwoFactorPinChange: (twofactorpin: string) => void;
  onLogin: () => void;
};


export default function SignInFormComponent(props: SignInFormComponentProps) {
  const { loginFailedMessage, showLoginFailedMessage, showServerErrorMessage } = useSelector(state => state.auth);

  const onKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      props.onLogin();
    }
  };

  let errorMessage = '';
  if (showLoginFailedMessage) {
    if (loginFailedMessage === 'registration_not_complete')
      errorMessage = 'appModule.signInFailed.registrationNotCompleted';
    else if (loginFailedMessage === 'locked_out')
      errorMessage = 'appModule.signInFailed.lockedOut';
    else if (loginFailedMessage === 'suspended_user')
      errorMessage = 'appModule.signInFailed.NoActiveCompany'
    else
      errorMessage = 'appModule.signInFailed';

    console.log(errorMessage);
  }

  return (
    <Box mx="auto" maxWidth="350px">
      <Paper elevation={0} square>
        <Box p={4} textAlign="center">
          <PageTitleViewComponent id="page.login.title" />

          {showLoginFailedMessage &&
            <Box>
              <Typography variant="body1">
                <IntlMessageComponent id={errorMessage} />
              </Typography>

              <Link to={LOST_PASSWORD}>
                <IntlMessageComponent id="appModule.lostPassword" />
              </Link>
            </Box>
          }

          {showServerErrorMessage &&
            <Box>
              <Typography variant="body1">
                <IntlMessageComponent id="appModule.signInFailedServer" />
              </Typography>
            </Box>
          }

          <TextField
            id="form.login"
            label={<IntlMessageComponent id="appModule.name" />}
            fullWidth
            margin="normal"
            onChange={props.handleLoginChange}
            onKeyDown={onKeyDown}
            value={props.identifier}
          />

          <TextField
            id="form.password"
            label={<IntlMessageComponent id="appModule.password" />}
            fullWidth
            margin="normal"
            onChange={props.handlePasswordChange}
            onKeyDown={onKeyDown}
            type="password"
            value={props.password}
          />

          {props.hasTwoFactor && <TextField
            id="form.twofactorpin"
            label={<IntlMessageComponent id="appModule.twofactorpin" />}
            fullWidth
            margin="normal"
            onChange={props.handleTwoFactorPinChange}
            onKeyDown={onKeyDown}
            type="password"
            value={props.twofactorpin}
          />}

          <Box mt={3}>
            <ButtonComponent
              color="primary"
              variant="contained"
              onClick={props.onLogin}
              fullWidth
            ><IntlMessageComponent id="appModule.signIn" /></ButtonComponent>
          </Box>

          <Box mt={1}>
            <Link to={LOST_PASSWORD}>
              <IntlMessageComponent id="page.login.passwordForgotten" />
            </Link>
          </Box>

          <Box mt={3}>
            <PageTitleViewComponent id="page.login.subscribe" />
          </Box>

          <ButtonComponent
            color="secondary"
            variant="contained"
            component={Link}
            to={SIGN_UP}
            fullWidth
          ><IntlMessageComponent id="page.login.subscribe.button" /></ButtonComponent>
        </Box>
      </Paper>
    </Box>
  );
}
