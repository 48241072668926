/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';

type SigningPageComponentProps = {
}

export default function SigningPageComponent(props: SigningPageComponentProps) {
  return <Box height="100%" display="flex" alignItems="center">{props.children}</Box>;
}
