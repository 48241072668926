/* @flow */

import moment from 'moment';

export default class SetCompanyInfoRequest {
  companyId: number;

  phone: string;
  isin: string;

  activities: string;
  mainActivityCountryId: number;
  sourceOfFunds: string;

  orias: boolean;

  companyCardOnlineCode: string;
  commercialRegistrationOnlineCode: string;
  commercialRegistrationOnlineCodeExpiry: moment;
}

