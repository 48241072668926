/* @flow */

import Filter from './filter';

export default class FilterOffice {
  id: number;
  name: string;
  isSelected: boolean;
  persons: Filter[];

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
    this.isSelected = false;
    this.persons = [];
  }
}
