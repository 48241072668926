/* @flow */


export default class InvoiceFixPrices {
  financedAmount: number;
  listingFee: number;
  serviceFee: number;
  underwritingFee: number;
  bankCosts: number;
  collectionFee: number;
  insuranceFee: number;
  urgentPaymentFee: number;
  totalFeeToPay: number;
  mustPayListingFee: boolean;
  mustPayServiceFee: boolean;
}
