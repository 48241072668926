/* @flow */

import FilterOffice from './filterOffice';

export default class FilterCompany {
  id: number;
  name: string;
  isSelected: boolean;
  offices: FilterOffice[];

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
    this.isSelected = false;
    this.offices = [];
  }
}
