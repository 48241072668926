/* @flow */

import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import InvoiceReportActionsComponent from './actions';
import CollapseLinkComponent from 'components/isoFilters/collaspsibleLink';
import ComponentWrapperComponent from 'components/websiteStructure/componentWrapper';
import FilterComponent from 'components/isoFilters/filter';
import FilterISOComponent from 'components/isoFilters/iso';

import InvoiceReportFilters from 'models/isos/invoices/invoiceReportFilters';

type InvoiceReportFiltersComponentProps = {
  filters: InvoiceReportFilters;
  sellerCompanyName: string;
  availableSellerCompanyNames: string[];
  selectFilter: (filterId: string, optionId: number) => void;
  selectFilterISO: (filterId: string, optionId: number) => void;
  sellerCompanyNameChange: (name: string) => void;
  clearFilters: () => void;
  downloadInvoiceReport: (format: string) => void;
}

export default function InvoiceReportFiltersComponent(props: InvoiceReportFiltersComponentProps) {
  const [showFilters, setShowFilters] = useState(true);

  const toggleShowFilters = () => setShowFilters(showFilters => !showFilters);

  return (

    <Box mb={showFilters ? 3 : 'auto'}>
      <Box mb={3}>
        <CollapseLinkComponent
          isCollapsed={!showFilters}
          labelClosed="component.isos.invoices.filters.collapse.closed.label"
          labelOpen="component.isos.invoices.filters.collapse.open.label"
          toggle={toggleShowFilters}
          data-walkme="isoinvoices-walkthru-step1"
        />
      </Box>

      {showFilters &&
        <React.Fragment>
          <ComponentWrapperComponent small data-walkme="isoinvoices-walkthru-step2">
            <Grid container>
              <FilterISOComponent
                companyLabel="component.isos.invoices.filters.isoCompany.label"
                officeLabel="component.isos.invoices.filters.isoOffice.label"
                personLabel="component.isos.invoices.filters.isoPerson.label"
                pageName="POS.Invoices"
                filters={props.filters.companies}
                selectFilter={props.selectFilterISO}
              />

              <Grid item xs="auto">
                <Box pl={2} pr={2} minWidth={300}>
                  <FilterComponent
                    filterId="sellerCompanies"
                    filters={props.filters.sellerCompanies}
                    label="component.isos.invoices.filters.sellerCompany.label"
                    selectFilter={props.selectFilter}
                    sellerCompanyNameChange={props.sellerCompanyNameChange}
                    sellerCompanyName={props.sellerCompanyName}
                    sellerCompanyNames={props.availableSellerCompanyNames}
                  />
                </Box>
              </Grid>
            </Grid>
          </ComponentWrapperComponent>
          <Box mt={3}>
            <InvoiceReportActionsComponent
              onExportAsked={props.downloadInvoiceReport}
              clearFilters={props.clearFilters}
            />
          </Box>
        </React.Fragment>
      }
    </Box>
  );
}
