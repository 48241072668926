/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import BusinessPotentialTableComponent from '../table';

type BestPricingSummaryComponentProps = {
  businessPotentials: BusinessPotential[];
  isLead: boolean;
  successHandler: () => void;
}

export default function BestPricingSummaryComponent(props: BestPricingSummaryComponentProps) {
  return (
    <React.Fragment>
      <Paper elevation={0}>
        <Box p={5}>
          <PageSubTitleViewComponent id="page.bestPricing.summary.title" />

          <Typography variant="body1">
            <IntlMessageComponent id="page.bestPricing.summary.text" />
          </Typography>

          <Box mt={5}>
            <PageSubTitleViewComponent id="page.bestPricing.summary.title2" />
            <BusinessPotentialTableComponent businessPotentials={props.businessPotentials} />
          </Box>

          {!props.isLead &&
            <Box mt={5}>
              <ButtonComponent
                color="primary"
                variant="contained"
                onClick={props.successHandler}
              ><IntlMessageComponent id="page.bestPricing.button.backToDashboard" /></ButtonComponent>
            </Box>
          }
        </Box>
      </Paper>
    </React.Fragment>
  );
}
