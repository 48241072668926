/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';

import PageTitleComponent from 'components/pageTitle';

import CompanyOfficeFormComponent from './companyOfficeForm';
import CompanyOfficePersonFormComponent from './companyOfficePersonForm';
import CompanyOfficePersonTableComponent from './companyOfficePersonTable';
import CompanyOfficeTableComponent from './companyOfficeTable';

import Office from 'models/companies/office';
import OfficePerson from 'models/companies/officePerson';

type CompanyOfficesViewComponentProps = {
  displayNewOfficeForm: boolean;
  displayNewOfficePersonForm: boolean;
  office: Office;
  officePerson: OfficePerson;
  newOffice: () => void;
  editOffice: () => void;
  hideNewOfficeForm: () => void;
  unselectOffice: () => void;
  newOfficePerson: () => void;
  editOfficePerson: () => void;
  hideNewOfficePersonForm: () => void;
}

export default function CompanyOfficesViewComponent(props: CompanyOfficesViewComponentProps) {

  return (
    <React.Fragment>
      <PageTitleComponent
        id="page.offices.title"
        data-walkme="myoffices-title-myoffices"
        backButton={props.office && !props.displayNewOfficePersonForm}
        backButtonAction={props.unselectOffice}
      />

      {props.displayNewOfficeForm && props.office === null && !props.displayNewOfficePersonForm &&
        <CompanyOfficeFormComponent successHandler={props.hideNewOfficeForm} cancelHandler={props.hideNewOfficeForm} />
      }

      {!props.displayNewOfficeForm && props.office === null && !props.displayNewOfficePersonForm &&
        <CompanyOfficeTableComponent newOffice={props.newOffice} editOffice={props.editOffice} />
      }

      {!props.displayNewOfficeForm && props.office !== null &&
        <React.Fragment>
          {props.displayNewOfficePersonForm ? (
            <CompanyOfficePersonFormComponent
              officeId={props.office.id}
              officePerson={props.officePerson}
              successHandler={props.hideNewOfficePersonForm}
              cancelHandler={props.hideNewOfficePersonForm}
            />
          ) : (
            <React.Fragment>
              <CompanyOfficeFormComponent office={props.office} />

              <Box mt={5}>
                <CompanyOfficePersonTableComponent
                  officeId={props.office.id}
                  newOfficePerson={props.newOfficePerson}
                  editOfficePerson={props.editOfficePerson}
                />
              </Box>
            </React.Fragment>
          )}
        </React.Fragment>
      }
    </React.Fragment>
  );
}