/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import {isCountryFranceOrDomTom} from 'lib/countryHelpers';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import IntlMessageComponent from 'components/formatters/intlMessage';

import CompanyBankAccountsFormDialogStep1Component from './step1';
import CompanyBankAccountsFormDialogStep2Component from './step2';

import BankAccountFormModel from './step1/model';
import Document from 'models/documents/v2/document';
import DocumentType from 'models/documents/documentType'

import CompanyService from 'services/CompanyService';
import DocumentService from 'services/DocumentService';
import {handleApiFormResponse} from 'lib/forms';
import { DOC_CODE_RIB, DOC_TYPE_RIB, DOC_CODE_BKA, DOC_TYPE_BKA } from 'constants/constants';

type CompanyBankAccountsFormDialogComponentProps = {
  activeCompanyId: number;
  onClose: () => void;
  onSuccess: () => void;
}

type CompanyBankAccountsFormDialogComponentState = {
  form: BankAccountFormModel[];
  formErrors: string[];
  isProcessing: boolean;
  step: number;
  documentType: DocumentType;
}

class CompanyBankAccountsFormDialogComponent extends React.Component<CompanyBankAccountsFormDialogComponentProps, CompanyBankAccountsFormDialogComponentState> {

  constructor(props) {
    super(props);
    this.state = {
      form: null,
      formErrors: [],
      isProcessing: false,
      step: 1
    };
  }

  onSuccessStep1 = async (form: BankAccountFormModel) => {
    if (isCountryFranceOrDomTom(form.bankCountryId)) {
      const documentType = new DocumentType(DOC_TYPE_RIB,DOC_CODE_RIB);
      this.setState({form, formErrors: [], step: 2, documentType: documentType});
    } else {
      const documentType = new DocumentType(DOC_TYPE_BKA,DOC_CODE_BKA);
      this.setState({form, formErrors: [], step: 2, documentType: documentType});
    }
  }

  onSuccessStep2 = async (document: Document) => {
    try {
      this.setState({isProcessing: true});
      await this.submitForm(this.state.form);
      await this.submitDocument(document);
      this.props.onSuccess();
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }
  }

  submitForm = async (form: BankAccountFormModel) => {
    const request = form.toRequest(this.props.activeCompanyId);
    await CompanyService.addCompanyBankAccount(request);
  };

  submitDocument = async (document: Document) => {
    await DocumentService.sendCompanyDocument(this.props.activeCompanyId, document.typeId, document.file);
  }

  render() {
    return (
      <Dialog open onClose={this.props.onClose} fullWidth maxWidth="sm">
        <DialogTitle>
          <IntlMessageComponent id="component.company.bankAccounts.form.title" />
        </DialogTitle>

        {this.state.isProcessing &&
          <DialogContent>
            <Box align="center" p={5}>
              <CircularProgress size={60} />
            </Box>
          </DialogContent>
        }

        {!this.state.isProcessing && this.state.step === 1 &&
          <CompanyBankAccountsFormDialogStep1Component
            formErrors={this.state.formErrors}
            onClose={this.props.onClose}
            onSuccess={this.onSuccessStep1}
          />
        }

        {!this.state.isProcessing && this.state.step === 2 &&
          <CompanyBankAccountsFormDialogStep2Component
            formErrors={this.state.formErrors}
            onClose={this.props.onClose}
            onSuccess={this.onSuccessStep2}
            documentType={this.state.documentType}
          />
        }
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps)(CompanyBankAccountsFormDialogComponent);
