/* @flow */

import LostPasswordRequest from 'models/requests/users/lostPasswordRequest.js';

export default class LostPasswordForm {
  email: string;

  constructor() {
    this.email = '';
  }

  toRequest() {
    const request = new LostPasswordRequest();
    request.email = this.email;
    return request;
  }
}


