/* @flow */

import moment from 'moment/moment';

import BuyerInvestmentProfile from 'models/buyers/investmentProfiles/investmentProfile';
import Company from 'models/companies/company';
import DebtorCompany from 'models/companies/debtorCompany';

export default class BuyerInvoice {
  id: number;
  invoiceName: number;
  reference: string;

  investmentProfile: BuyerInvestmentProfile;
  sellerCompany: Company;
  debtorCompany: DebtorCompany;

  type: number;
  toPurchase: boolean;
  toPay: boolean;
  statusKey: string;

  issueDate: moment;
  dueDate: moment;
  purchasedDate: ?moment;
  paymentDate: ?moment;
  investmentDate: ?moment;
  closedDate: ?moment;
  daysLeft: number;
  daysLate: number;

  insuredPct: number;
  financedAmount: number;
  financedDate: ?moment;
  purchasePrice: number;
  discountPct: number;
  irr: number;
  buyerInsuranceFee: number;

  paymentReference: string;
  amountDue: number;
  amountReceived: number;

  assignedToInvestmentProfileId: number;
  status: number;

  paymentType: number;
  receivedDate: ?moment;
  valueDate: ?moment;

  isBatch: boolean;
  isConfirming: boolean;
}