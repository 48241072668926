/* @flow */

import Filter from 'models/isos/filters/filter';
import FilterCompany from 'models/isos/filters/filterCompany';

export default class InvoiceReportFilters {
  companies: FilterCompany[];
  sellerCompanies: Filter[];
  isFinanced: Filter[];

  constructor() {
    this.companies = [];
    this.sellerCompanies = [];
    this.isFinanced = [];
  }
}
