/* @flow */

import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import SecurityComponent from 'components/security';

import CompanyDocumentsTableComponent from './table';
import CompanyDocumentsUploadComponent from './upload';
import CompanyDocumentsUploadCommissionInvoiceComponent from './uploadCommission';


import ComponentWrapperComponent from 'components/websiteStructure/componentWrapper';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import Typography from '@material-ui/core/Typography';
import IntlMessageComponent from 'components/formatters/intlMessage';


import { ROLE_ISO } from 'constants/companyRoles';

export default function CompanyDocumentsComponent() {
  const activeCompanyRole = useSelector(state => state.auth.user.activeCompany.role);

  // this forces the refresh of the CompanyDocumentsTableComponent
  // using a well known trick with the key parameter
  const [refresh, setRefresh] = useState(0);
  const successHandler = () => setRefresh(refresh + 1);

  return (
    <React.Fragment>
      <CompanyDocumentsTableComponent key={`table-${refresh}`} />

      <Box>
          <ComponentWrapperComponent small>
            <PageSubTitleViewComponent id="documents.disclaimer" />
            
            <Box mt={3}>
              <Typography variant="body1">
                <IntlMessageComponent id="documents.disclaimer.message" />
              </Typography>
            </Box>
          </ComponentWrapperComponent>
        </Box>

      {activeCompanyRole === ROLE_ISO ? (
        <SecurityComponent pageName="POS.YourDocuments" elementName="btnNew">
          <Box mt={5}>
            <Grid container spacing={6} alignItems="stretch">
              <Grid item xs={12} md={6}>
                <CompanyDocumentsUploadCommissionInvoiceComponent successHandler={successHandler} />
              </Grid>
            </Grid>
          </Box>
        </SecurityComponent>
      ) : (
        <SecurityComponent pageName="POS.YourDocuments" elementName="btnNew">
          <Box mt={5}>
            <Grid container spacing={6} alignItems="stretch">
              <Grid item xs={12} md={6}>
                <CompanyDocumentsUploadComponent successHandler={successHandler} />
              </Grid>
            </Grid>
          </Box>
        </SecurityComponent>
      )}
    </React.Fragment>
  );
}
