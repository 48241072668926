/* @flow */
import React from 'react';
import { connect } from 'react-redux';

import LoadingComponent from 'components/loading';

import MandateFormComponent from './form';
import MandateTableComponent from './table';

import Mandate from 'models/isos/mandates/mandate';

import DocumentService from 'services/DocumentService';
import IsoService from 'services/IsoService';
import NotificationHelper from 'lib/notifications';

import { ISO_MANDATES_UPLOAD } from 'constants/apiRoutes';

type ISOMandatesComponentProps = {
  activeCompanyId: number;
  hasMandates: boolean;
}

type ISOMandatesComponentState = {
  isLoading: boolean;
  displayForm: boolean;
  mandateId: number;
  mandates: Mandate[];
}

class ISOMandatesComponent extends React.Component<ISOMandatesComponentProps, ISOMandatesComponentState> {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      mandateId: 0,
      mandates: []
    };
  }

  componentDidMount = async () => {
    try {
      const mandates = await IsoService.getMandates(this.props.activeCompanyId);
      this.setState({isLoading: false, mandates});
    } catch (e) {
      console.error(e);
    }
  }

  downloadDocument = async (companyId: number) => {
    try {
      await IsoService.downloadMandate(this.props.activeCompanyId, companyId);
    } catch (e) {
      console.error(e);
      
    }
  }

  editMandate = (mandateId: number) => {
    this.setState({displayForm: true, mandateId});
  };

  showForm = () => {
    this.setState({displayForm: true});
  };

  onFormCancel = () => {
    this.setState({displayForm: false, mandateId: 0});
  }

  onFormSuccess = async () => {
    this.setState({displayForm: false, isLoading: true, mandateId: 0});
    try {
      const mandates = await IsoService.getMandates(this.props.activeCompanyId);
      this.setState({isLoading: false, mandates});
    } catch (e) {
      console.error(e);
    }
  }

  onDocumentUploadFail = () => {
    NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR, 'validation.file.wrongFormat');
  }

  onDocumentUploadSuccess = async (mandateId: number, file: File) => {
    try {
      await DocumentService.sendDocument2(ISO_MANDATES_UPLOAD, file, { isoCompanyId: this.props.activeCompanyId, mandateId });
      NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS, 'notifications.file.upload.success');
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  }

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <React.Fragment>
        {this.state.displayForm ? (
          <MandateFormComponent
            mandateId={this.state.mandateId}
            onFormCancel={this.onFormCancel}
            onFormSuccess={this.onFormSuccess}
          />
        ) : (
          <MandateTableComponent
            mandates={this.state.mandates}  
            downloadDocument={this.downloadDocument}
            editMandate={this.editMandate}
            showForm={this.showForm}
            onDocumentUploadFail={this.onDocumentUploadFail}
            onDocumentUploadSuccess={this.onDocumentUploadSuccess}
            hasMandates={this.props.hasMandates}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id,
  hasMandates: state.auth.user.activeCompany.roleISO.workingWithMandates
});

export default connect(mapStateToProps)(ISOMandatesComponent);
