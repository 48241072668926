/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import LoadingComponent from 'components/loading';

import ConfirmingInvoiceDetailsDocumentsViewComponent from './view';

import Invoice from 'models/debtors/invoice';
import ProvidedDocument from 'models/documents/providedDocument';

import DocumentService from 'services/DocumentService';
import NotificationHelper from 'lib/notifications';

type ConfirmingInvoiceDetailsDocumentsComponentProps = {
  activeCompanyId: number;
  activeCompanyRole: number;
  invoice: Invoice;
}

type ConfirmingInvoiceDetailsDocumentsComponentState = {
  activeDocumentId: number;
  documents: ProvidedDocument[];
  isLoading: boolean;
}

class ConfirmingInvoiceDetailsDocumentsComponent extends React.Component<ConfirmingInvoiceDetailsDocumentsComponentProps, ConfirmingInvoiceDetailsDocumentsComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      activeDocumentId: 0,
      documents: [],
      isLoading: true
    };
  }

  componentDidMount = async () => {
    try {
      // Get list of all visible invoice documents
      const documents = await DocumentService.getInvoiceDocuments(this.props.activeCompanyId, this.props.activeCompanyRole, this.props.invoice.id);
      
      if  (!documents || documents.length == 0)
      {
        this.setState({isLoading: false});
        return;
      }

      documents.forEach(document => {
        document.fileURL = null;  
      });

      // First document should be the invoice document
      documents[0].fileURL = await DocumentService.getInvoiceDocumentURL(this.props.activeCompanyId, this.props.activeCompanyRole, this.props.invoice.id, documents[0].id);
      
      this.setState({activeDocumentId: documents[0].id, documents, isLoading: false});
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  }

  componentWillUnmount = () => {
    // Revoke document URL to free browser memory
    this.state.documents.forEach(document => {
      if (document.fileURL !== null) {
        URL.revokeObjectURL(document.fileURL);
      }
    });
  }

  selectDocument = async (documentId: number) => {
    if (this.state.activeDocumentId === documentId)
     return;

    const documents = this.state.documents;
    const document = documents.find(d => d.id === documentId);
    if (!document) return;

    if (document.fileURL !== null) {
      this.setState({activeDocumentId: documentId});
      return;
    }

    this.setState({isLoading: true});

    try {
      document.fileURL = await DocumentService.getInvoiceDocumentURL(this.props.activeCompanyId, this.props.activeCompanyRole, this.props.invoice.id, document.id);
      this.setState({activeDocumentId: documentId, documents, isLoading: false});
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  }

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      this.state.activeDocumentId > 0 && <ConfirmingInvoiceDetailsDocumentsViewComponent
        activeDocumentId={this.state.activeDocumentId}
        documents={this.state.documents}
        selectDocument={this.selectDocument}
      />
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id,
  activeCompanyRole: state.auth.user.activeCompany.role
});

export default connect(mapStateToProps)(ConfirmingInvoiceDetailsDocumentsComponent);
