/* @flow */

import React from 'react';
import moment from 'moment';

import { connect } from 'react-redux';
import { setComponentData } from 'store/actions/componentDataActions';

import NotificationHelper from 'lib/notifications';
import customSort from 'lib/sortHelpers';

import LoadingComponent from 'components/loading';

import ConfirmingOpenInvoicesTableViewComponent from './view';

import Invoice from 'models/confirming/invoice';
import CancelInvoiceRequest from 'models/requests/sellers/cancelInvoiceRequest';

import ConfirmingService from 'services/ConfirmingService';
import InvoiceSellerService from 'services/InvoiceSellerService';

type ConfirmingOpenInvoicesTableComponentProps = {
  activeCompanyId: number;
}

type ConfirmingOpenInvoicesTableComponentState = {
  invoices: Invoice[];
  isLoading: boolean;
  order: string;
  orderBy: ?string;
  page: number;
  pageSize: number;
}

class ConfirmingOpenInvoicesTableComponent extends React.Component<ConfirmingOpenInvoicesTableComponentProps, ConfirmingOpenInvoicesTableComponentState> {

  constructor(props) {
    super(props);
    this.state = {
      invoices: [],
      isLoading: true,
      order: 'asc',
      orderBy: undefined,
      page: 0,
      pageSize: 10
    };
  }

  componentDidMount = async () => {
    try {
      const invoices = await ConfirmingService.getOpenInvoices(this.props.activeCompanyId);
      this.setState({ invoices, isLoading: false });
    } catch (e) {
      console.error(e);
    }
  }

  handlePageChange = (page: number) => this.setState({ page });
  handlePageSizeChange = (pageSize: number) => this.setState({ pageSize });

  handleSortOrderChange = (orderBy: string) => {
    let order = this.state.orderBy === orderBy && this.state.order === 'desc' ? 'asc' : 'desc';

    let invoices = null;
    if (orderBy.startsWith('supplierCompany.')) {
      // get exact property name
      const o = orderBy.substring(16);

      // order
      invoices = order === 'desc'
        ? this.state.invoices.sort((a, b) => customSort(b.supplierCompany[o], a.supplierCompany[o]))
        : this.state.invoices.sort((a, b) => customSort(a.supplierCompany[o], b.supplierCompany[o]));
    }
    else {
      // order
      invoices = order === 'desc'
        ? this.state.invoices.sort((a, b) => customSort(b[orderBy], a[orderBy]))
        : this.state.invoices.sort((a, b) => customSort(a[orderBy], b[orderBy]));
    }

    this.setState({ invoices, order, orderBy });
  };

  cancelInvoice = async (invoiceId: number) => {
    try {
      const request = new CancelInvoiceRequest(invoiceId);
      await InvoiceSellerService.cancelInvoice(request);
      NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS, 'notifications.invoice.cancel.success');

      const invoices = await ConfirmingService.getOpenInvoices(this.props.activeCompanyId);
      this.setState({ invoices, isLoading: false });
    } catch (error) {
      console.error('cancelInvoice', error);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  };

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <React.Fragment>
        <ConfirmingOpenInvoicesTableViewComponent
          invoices={this.state.invoices}
          order={this.state.order}
          orderBy={this.state.orderBy}
          page={this.state.page}
          pageSize={this.state.pageSize}
          handlePageChange={this.handlePageChange}
          handlePageSizeChange={this.handlePageSizeChange}
          handleSortOrderChange={this.handleSortOrderChange}
          cancelInvoice={this.cancelInvoice}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps, { setComponentData })(ConfirmingOpenInvoicesTableComponent);
