/* @flow */

import React from 'react';

import LoadingComponent from 'components/loading';
import SellInvoiceTab5ViewComponent from './view';

import InvoiceProposal from 'models/invoices/seller/invoiceProposal';

import SubmitFixInvoiceRequest from 'models/requests/sellers/submitFixInvoiceRequest';
import SubmitFlexInvoiceRequest from 'models/requests/sellers/submitFlexInvoiceRequest';

import InvoiceSellerService from 'services/InvoiceSellerService';
import {handleApiFormResponse} from 'lib/forms';

type SellInvoiceTab5ComponentProps = {
  invoiceId: number;
  successHandler: () => void;
  cancelInvoice: () => void;
}

type SellInvoiceTab5ComponentState = {
  invoice: InvoiceProposal;
  isLoading: boolean;
  isProcessing: boolean;
}

class SellInvoiceTab5Component extends React.Component<SellInvoiceTab5ComponentProps, SellInvoiceTab5ComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      invoice: null,
      isLoading: true,
      isProcessing: false
    };
  }

  componentDidMount = async () => {
    const invoice = await InvoiceSellerService.getInvoiceProposal(this.props.invoiceId);
    this.setState({invoice, isLoading: false});
  }

  cancelInvoice = () => {
    this.setState({isProcessing: true});
    this.props.cancelInvoice();
  }

  submitFlexInvoice = async () => {
    this.setState({isProcessing: true});

    try {
      const request = new SubmitFlexInvoiceRequest(this.props.invoiceId);
      await InvoiceSellerService.submitFlexInvoice(request);
      this.props.successHandler();
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }
  }

  submitFixInvoice = async (isFinanced: boolean) => {
    this.setState({isProcessing: true});

    try {
      const request = new SubmitFixInvoiceRequest(this.props.invoiceId, isFinanced);
      await InvoiceSellerService.submitFixInvoice(request);
      this.props.successHandler();
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }
  }

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <SellInvoiceTab5ViewComponent
        invoice={this.state.invoice}
        isProcessing={this.state.isProcessing}
        cancelInvoice={this.cancelInvoice}
        submitFixInvoice={this.submitFixInvoice}
        submitFlexInvoice={this.submitFlexInvoice}
      />
    );
  }
}

export default SellInvoiceTab5Component;
