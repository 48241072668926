/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import Tooltip from '@material-ui/core/Tooltip';

import AmountFormatterComponent from 'components/formatters/amount';
import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';
import StyledTableCellTextComponent from 'components/table/cellText';
import StyledTableCellNumbersComponent from 'components/table/cellNumbers';
import StyledTableCellNumbersOrangeComponent from 'components/table/cellNumbersOrange';
import StyledTableHeadComponent from 'components/table/head';
import StyledTableRowComponent from 'components/table/row';
import SecurityComponent from 'components/security/index';
import ChoosePaymentMethodComponent from 'components/payments/choosePaymentMethod';

import InvoiceSummary from 'models/invoices/seller/invoiceSummary';
import { Typography } from '../../../../../../node_modules/@material-ui/core/index';

const columnData = [
  { id: 'id', align:'left', translationKey: 'page.sellInvoice.tab6.invoiceList.header.id' },
  { id: 'reference', align:'left', translationKey: 'page.sellInvoice.tab6.invoiceList.header.reference' },
  { id: 'debtor', align:'left', translationKey: 'page.sellInvoice.tab6.invoiceList.header.debtor' },
  { id: 'debtAmount', translationKey: 'page.sellInvoice.tab6.invoiceList.header.debtAmount' },
  { id: 'financedAmount', translationKey: 'page.sellInvoice.tab6.invoiceList.header.financedAmount' },
  { id: 'transactionPrice', translationKey: 'page.sellInvoice.tab6.invoiceList.header.transactionPrice' },
  { id: 'listingFee', translationKey: 'page.sellInvoice.tab6.invoiceList.header.listingFee' },
  { id: 'serviceFee', translationKey: 'page.sellInvoice.tab6.invoiceList.header.serviceFee' },
  { id: 'insuranceFee', translationKey: 'page.sellInvoice.tab6.invoiceList.header.insuranceFee' }
];

type InvoiceToPayTableViewComponentProps = {
  invoices: InvoiceSummary[];
  paymentMethod: string;
  order: string;
  orderBy: string;
  page: number;
  pageSize: number;
  handleNewInvoiceCreation: () => void;
  handlePageChange: (page: number) => void;
  handlePageSizeChange: (pageSize: number) => void;
  handleSelectionDone: () => void;
  handleChoosePayment: (paymentMethod: string) => void;
  handleSortOrderChange: (property: string) => void;
  selectInvoice: (invoiceId: number) => void;
  selectAllInvoices: () => void;
}

export default function InvoiceToPayTableViewComponent(props: InvoiceToPayTableViewComponentProps) {
  const selectedCount = props.invoices.reduce((acc, cur) => cur.isSelected ? ++acc : acc, 0);

  const handlePageChange = (_, page) => props.handlePageChange(page);
  const handlePageSizeChange = event => props.handlePageSizeChange(event.target.value);

  const onClickCheckbox = (event: SyntheticMouseEvent<>, invoiceId: number) => {
    event.stopPropagation();
    props.selectInvoice(invoiceId);
  };

  const onClickAllCheckbox = (event: SyntheticMouseEvent<>) => {
    event.stopPropagation();
    props.selectAllInvoices();
  };

  return (
    <React.Fragment>
      <Box component="div" overflow="auto">
        <Table>
          <StyledTableHeadComponent>
            <StyledTableRowComponent>
              <StyledTableCellTextComponent padding="checkbox">
                <Tooltip title={<IntlMessageComponent id="general.tooltip.selectAll" />}>
                  <Checkbox
                    checked={props.invoices.length > 0 && props.invoices.find(i => !i.isSelected) === undefined}
                    color="primary"
                    disabled={props.invoices.length === 0}
                    onClick={event => onClickAllCheckbox(event)}
                  />
                </Tooltip>
              </StyledTableCellTextComponent>

              {columnData.map(column => (
                <StyledTableCellTextComponent align={column?.align ?? 'right'} key={column.id} padding="none">
                  <TableSortLabel
                    active={props.orderBy === column.id}
                    direction={props.order}
                    onClick={() => props.handleSortOrderChange(column.id)}
                  ><IntlMessageComponent id={column.translationKey} /></TableSortLabel>
                </StyledTableCellTextComponent>
              ))}

              <StyledTableCellTextComponent padding="none" />
            </StyledTableRowComponent>
          </StyledTableHeadComponent>

          <TableBody bgcolor="white">
            {props.invoices.slice(props.page * props.pageSize, (props.page * props.pageSize) + props.pageSize).map(i => (
              <StyledTableRowComponent key={`invList-${i.id}`} hover selected={i.isSelected}>
                <StyledTableCellTextComponent padding="checkbox" onClick={event => onClickCheckbox(event, i.id)}>
                  <Checkbox color="primary" checked={i.isSelected} />
                </StyledTableCellTextComponent>

                <StyledTableCellTextComponent padding="none">{i.id}</StyledTableCellTextComponent>

                <StyledTableCellTextComponent padding="none">{i.reference}</StyledTableCellTextComponent>

                <StyledTableCellTextComponent padding="none">
                  {i.debtorCompany.name}
                </StyledTableCellTextComponent>

                <StyledTableCellNumbersComponent padding="none">
                  <AmountFormatterComponent amount={i.debtAmount} />
                </StyledTableCellNumbersComponent>

                <StyledTableCellNumbersComponent padding="none">
                  {i.flexPrices && <AmountFormatterComponent amount={i.flexPrices.financedAmount} />}
                  {i.fixFinancedPrices && <AmountFormatterComponent amount={i.fixFinancedPrices.financedAmount} />}
                </StyledTableCellNumbersComponent>

                <StyledTableCellNumbersComponent padding="none">
                  {i.flexPrices && <AmountFormatterComponent amount={i.flexPrices.transactionPrice} />}
                  {i.fixFinancedPrices && <AmountFormatterComponent amount={i.fixFinancedPrices.transactionPrice} />}
                  {i.fixUnfinancedPrices && <AmountFormatterComponent amount={i.fixUnfinancedPrices.transactionPrice} />}
                </StyledTableCellNumbersComponent>

                {i.flexPrices.mustPayListingFee && <StyledTableCellNumbersOrangeComponent padding="none">
                  {i.flexPrices && <strong><AmountFormatterComponent amount={i.flexPrices.listingFeeWithVAT} /></strong>}
                  {i.fixFinancedPrices && <strong><AmountFormatterComponent amount={i.fixFinancedPrices.listingFeeWithVAT} /></strong>}
                  {i.fixUnfinancedPrices && <strong><AmountFormatterComponent amount={i.fixUnfinancedPrices.listingFeeWithVAT} /></strong>}
                </StyledTableCellNumbersOrangeComponent>}
                {!i.flexPrices.mustPayListingFee && <StyledTableCellNumbersComponent padding="none">
                  {i.flexPrices && <AmountFormatterComponent amount={i.flexPrices.listingFeeWithVAT} />}
                  {i.fixFinancedPrices && <AmountFormatterComponent amount={i.fixFinancedPrices.listingFeeWithVAT} />}
                  {i.fixUnfinancedPrices && <AmountFormatterComponent amount={i.fixUnfinancedPrices.listingFeeWithVAT} />}
                </StyledTableCellNumbersComponent>}

                {i.flexPrices.mustPayServiceFee && <StyledTableCellNumbersOrangeComponent padding="none">
                  {i.flexPrices && <strong><AmountFormatterComponent amount={i.flexPrices.serviceFeeWithVAT} /></strong>}
                  {i.fixFinancedPrices && <strong><AmountFormatterComponent amount={i.fixFinancedPrices.serviceFeeWithVAT} /></strong>}
                  {i.fixUnfinancedPrices && <strong><AmountFormatterComponent amount={i.fixUnfinancedPrices.serviceFeeWithVAT} /></strong>}
                </StyledTableCellNumbersOrangeComponent>}
                {!i.flexPrices.mustPayServiceFee && <StyledTableCellNumbersComponent padding="none">
                  {i.flexPrices && <AmountFormatterComponent amount={i.flexPrices.serviceFeeWithVAT} />}
                  {i.fixFinancedPrices && <AmountFormatterComponent amount={i.fixFinancedPrices.serviceFeeWithVAT} />}
                  {i.fixUnfinancedPrices && <AmountFormatterComponent amount={i.fixUnfinancedPrices.serviceFeeWithVAT} />}
                </StyledTableCellNumbersComponent>}

                <StyledTableCellNumbersComponent padding="none">
                  {i.flexPrices && <AmountFormatterComponent amount={i.flexPrices.insuranceFeeWithVAT} />}
                  {i.fixFinancedPrices && <AmountFormatterComponent amount={i.fixFinancedPrices.insuranceFeeWithVAT} />}
                  {i.fixUnfinancedPrices && <AmountFormatterComponent amount={i.fixUnfinancedPrices.insuranceFeeWithVAT} />}
                </StyledTableCellNumbersComponent>
              </StyledTableRowComponent>
            ))}
          </TableBody>
        </Table>

        <Box display="flex" height={60}>
          <Box flexGrow={1} />
          <Box justifyContent="flex-end">
            <TablePagination
              component="div"
              count={props.invoices.length}
              rowsPerPage={props.pageSize}
              page={props.page}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePageSizeChange}
              labelRowsPerPage={<IntlMessageComponent id="general.invoicePerPage" />}
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
            />
          </Box>
        </Box>
      </Box>

      <Box mt={3}>
        <ChoosePaymentMethodComponent
          handleChoosePayment={props.handleChoosePayment}
        />
      </Box>

      <Box mt={5}>
        <SecurityComponent pageName="POS.Sellers.SellInvoice" elementName="btnPayFees">
          <ButtonComponent
            color="primary"
            variant="contained"
            disabled={selectedCount < 1 || props.paymentMethod === ""}
            onClick={props.handleSelectionDone}
            data-walkme="sell-t6-payfees"
          ><IntlMessageComponent id="page.sellInvoice.tab6.button.payfees" /></ButtonComponent>
        </SecurityComponent>

        <Box component="span" ml={1}>
          <ButtonComponent
            color="primary"
            variant="contained"
            onClick={props.handleNewInvoiceCreation}
            data-walkme="sell-t6-addInvoice"
          ><IntlMessageComponent id="page.sellInvoice.tab6.button.addInvoice" /></ButtonComponent>
        </Box>
      </Box>
    </React.Fragment>
  );
}