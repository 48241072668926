/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import Box from '@material-ui/core/Box';

import ComponentWrapperComponent from 'components/websiteStructure/componentWrapper';
import Error404Component from 'components/errors/error404';
import LoadingComponent from 'components/loading';
import InvoiceNameComponent from 'components/invoice/name';
import PageTitleComponent from 'components/pageTitle';

import DebtorInvoiceDetailsActionApproveViewComponent from './actions/approveInvoice';
import DebtorInvoiceDetailsActionPayViewComponent from './actions/payInvoice';
import DebtorInvoiceDetailsDocumentsComponent from './documents';
import DebtorInvoiceDetailsStatusViewComponent from './status';
import DebtorInvoiceDetailsSummaryViewComponent from './summary';

import Invoice from 'models/debtors/invoice';

import DebtorService from 'services/DebtorService';

type DebtorInvoiceDetailsComponentProps = {
  activeCompanyId: number;
  invoiceId: number;
}

type DebtorInvoiceDetailsComponentState = {
  errorCode: number;
  invoice: Invoice;
  isLoading: boolean;
  statusKeys: string[];
  titleValues: {id: Element<any>};
}

class DebtorInvoiceDetailsComponent extends React.Component<DebtorInvoiceDetailsComponentProps, DebtorInvoiceDetailsComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      errorCode: 0,
      invoice: null,
      isLoading: true,
      statusKeys: [],
      titleValues: {id: <b>{this.props.invoiceId}</b>}
    };
  }

  componentDidMount = async () => {
    await this.loadInvoice();
  }

  reloadInvoice = async () => {
    this.setState({errorCode: 0, invoice: null, isLoading: true, statusKeys: [], titleValues: {id: <b>{this.props.invoiceId}</b>}});
    await this.loadInvoice();
  }

  loadInvoice = async () => {
    try {
      const response = await DebtorService.getInvoiceDetails(this.props.activeCompanyId, this.props.invoiceId);
      this.setState({
        invoice: response.invoice,
        isLoading: false,
        statusKeys: response.statusKeys,
        titleValues: {
          id: <b><InvoiceNameComponent invoiceId={this.props.invoiceId} invoiceName={response.invoice.invoiceName} /></b>,
          debtorReference: <b>{response.invoice.debtorReference}</b>
        }
      });
    } catch (e) {
      if (e.code === 400) {
        this.setState({isLoading: false, errorCode: 404});
      } else {
        this.setState({isLoading: false, errorCode: 500});
      }
    }
  }

  render() {
    if (this.state.isLoading) {
      return (
        <React.Fragment>
          <PageTitleComponent id="debtor.invoiceDetails.title.loading" values={this.state.titleValues} />
          <LoadingComponent />
        </React.Fragment>
      );
    }

    // Maybe find a best way to do this
    if (this.state.errorCode === 404) {
      return (<Error404Component />);
    } else if (this.state.errorCode === 500) {
      return null;
    }

    return (
      <React.Fragment>
        <PageTitleComponent id="debtor.invoiceDetails.title" values={this.state.titleValues} backButton />

        <ComponentWrapperComponent small>
          <DebtorInvoiceDetailsStatusViewComponent invoice={this.state.invoice} statusKeys={this.state.statusKeys} />

          <Box mt={3}>
            <DebtorInvoiceDetailsSummaryViewComponent invoice={this.state.invoice} />
          </Box>
        </ComponentWrapperComponent>

        <Box mt={3}>      
          <ComponentWrapperComponent small>
            <DebtorInvoiceDetailsDocumentsComponent invoice={this.state.invoice} />

            {this.state.invoice.toApprove &&
              <DebtorInvoiceDetailsActionApproveViewComponent invoiceId={this.state.invoice.id} successHandler={this.reloadInvoice} />
            }

            {this.state.invoice.toPay && !this.state.invoice.paid &&
              <DebtorInvoiceDetailsActionPayViewComponent invoiceId={this.state.invoice.id} successHandler={this.reloadInvoice} />
            }
          </ComponentWrapperComponent>
        </Box>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps)(DebtorInvoiceDetailsComponent);
