/* @flow */
import React from 'react';
import {connect} from 'react-redux';

import customSort from 'lib/sortHelpers';

import LoadingComponent from 'components/loading';

import ISOCommercialDocumentsTableControlsComponent from './controls';
import ISOCommercialDocumentsTableViewComponent from './view';

import CommercialDocument from 'models/documents/commercialDocument';
import CommercialDocumentsActiveFilters from './controls/model';

import DocumentService from 'services/DocumentService';

import NotificationHelper from 'lib/notifications';

type CommercialDocumentsTableComponentProps = {
  activeCompanyId: number;
  activeCompanyRole: number;
}

type CommercialDocumentsTableComponentState = {
  activeFilters: CommercialDocumentsActiveFilters;
  documents: CommercialDocument[];
  isLoading: boolean;
  order: string;
  orderBy: ?string;
  page: number;
  pageSize: number;
}

class CommercialDocumentsTableComponent extends React.Component<CommercialDocumentsTableComponentProps, CommercialDocumentsTableComponentState> {

  constructor(props) {
    super(props);
    const activeFilters = new CommercialDocumentsActiveFilters();
    activeFilters.language = props.userLanguage;

    this.state = {
      activeFilters: activeFilters,
      documents: null,
      isLoading: true,
      order: 'asc',
      orderBy: undefined,
      page: 0,
      pageSize: 10
    };
  }

  componentDidMount = async () => {
    try {
      const documents = await DocumentService.getCommercialDocuments(this.props.activeCompanyId, this.props.activeCompanyRole);
      this.setState({ documents, isLoading: false });
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  }

  handleFiltersChange = (activeFilters: CommercialDocumentsActiveFilters) => this.setState({activeFilters});

  handlePageChange = (page: number) => this.setState({page});
  handlePageSizeChange = (pageSize: number) => this.setState({pageSize});

  handleSortOrderChange = (orderBy: string) => {
    let order = this.state.orderBy === orderBy && this.state.order === 'desc' ? 'asc' : 'desc';

    const documents = order === 'desc'
    ? this.state.documents.sort((a, b) => customSort(b[orderBy], a[orderBy]))
    : this.state.documents.sort((a, b) => customSort(a[orderBy], b[orderBy]));

    this.setState({ documents, order, orderBy });
  };

  getFilteredResults = () => {
    let filteredDocuments = this.state.documents;

    if (this.state.activeFilters.name && this.state.activeFilters.name.length > 2) {
      filteredDocuments = filteredDocuments.filter((d: CommercialDocument) => {
        const query = this.state.activeFilters.name.toUpperCase();
        const fields = [d.name].map(v => (v || '').toString().toUpperCase());
        return !!(fields.filter(field => field.toString().indexOf(query) !== -1)).length;
      });
    }

    if (this.state.activeFilters.language && this.state.activeFilters.language !== '' && this.state.activeFilters.language !== 'All') {
      filteredDocuments = filteredDocuments.filter((d: CommercialDocument) => d.culture === this.state.activeFilters.language);
    }

    return filteredDocuments;
  };

  downloadDocument = async (culture: string, name: string) => {
    try {
      await DocumentService.downloadCommercialDocument(this.props.activeCompanyId, this.props.activeCompanyRole, culture, name);
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  }

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <React.Fragment>
        <ISOCommercialDocumentsTableControlsComponent
          activeFilters={this.state.activeFilters}
          handleFiltersChange={this.handleFiltersChange}
        />
        
        <ISOCommercialDocumentsTableViewComponent
          documents={this.getFilteredResults()}
          order={this.state.order}
          orderBy={this.state.orderBy}
          page={this.state.page}
          pageSize={this.state.pageSize}
          downloadDocument={this.downloadDocument}
          handlePageChange={this.handlePageChange}
          handlePageSizeChange={this.handlePageSizeChange}
          handleSortOrderChange={this.handleSortOrderChange}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id,
  activeCompanyRole: state.auth.user.activeCompany.role,
  userLanguage: state.locale.locale
});

export default connect(mapStateToProps)(CommercialDocumentsTableComponent);
