/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

import CheckboxFieldComponent from 'components/formFields/checkbox';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import TooltipComponent from 'components/toolTips/index';


import CompanyUBOForm from '../model';

type CompanyUBOsFormCompanyInfoPanelComponentProps = {
  errors: Map<string,?string>;
  form: CompanyUBOForm;
  handleChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void;
  handleNumberChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void;
  handleBooleanChange: (fieldName: string) => (value: boolean) => void;
}

export default function CompanyUBOsFormCompanyInfoPanelComponent(props: CompanyUBOsFormCompanyInfoPanelComponentProps) {
  return (
    <React.Fragment>
      <PageSubTitleViewComponent id="company.ubos.form.companyInfo" />

      <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
        <Grid item xs={12} md={6}>
          <Box px={4}>
            <TextField
              id="form.functionName"
              label={<IntlMessageComponent id="form.functionName" />}
              error={!!props.errors.get('functionName')}
              helperText={props.errors.get('functionName') ?
                <IntlMessageComponent id={props.errors.get('functionName')} /> : ''}
              value={props.form.functionName}
              onChange={props.handleChange('functionName')}
              fullWidth
              margin="normal"
              required
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6} pageName="Tooltip.UBO.CompanyInfo" elementName="Shares">
          <Box px={4}>
            
              <TextField
                id="form.shares"
                data-walkme="ubo-field-partsdanslentreprise"
                label={<IntlMessageComponent id="form.shares" />}
                error={!!props.errors.get('shares')}
                helperText={(props.errors.get('shares')) ?
                  <IntlMessageComponent id={props.errors.get('shares')} /> : ''}
                value={props.form.shares}
                onChange={props.handleNumberChange('shares')}
                InputProps={{
                  startAdornment: (<React.Fragment><TooltipComponent pageName="Tooltip.UBO.CompanyInfo" elementName="Shares" /><InputAdornment position="start">&nbsp; %</InputAdornment></React.Fragment>)
                }}
                fullWidth
                margin="normal"
                required
              />
          </Box>
        </Grid>
      </Grid>

      <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
        <Grid item xs={12} md={6}>
          <Box px={4}>
            <CheckboxFieldComponent
              id="form.isDecisionMaker"
              label={<IntlMessageComponent id="form.isDecisionMaker" />}
              error={!!props.errors.get('isDecisionMaker')}
              helperText={(props.errors.get('isDecisionMaker')) ?
                <IntlMessageComponent id={props.errors.get('isDecisionMaker')} /> : ''}
              value={props.form.isDecisionMaker}
              changeHandler={props.handleBooleanChange('isDecisionMaker')}
              fullWidth
              margin="normal"
            />
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
