/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import EditOutlined from '@material-ui/icons/EditOutlined';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Tooltip from '@material-ui/core/Tooltip';

import BooleanFormatterComponent from 'components/formatters/boolean';
import IntlMessageComponent from 'components/formatters/intlMessage';

import StyledTableCellTextComponent from 'components/table/cellText';
import StyledTableHeadComponent from 'components/table/head';
import StyledTableRowComponent from 'components/table/row';
import SecurityComponent from 'components/security/index';

import CompanyUBO from 'models/companies/companyUBO';

import { hasSecurityMenuAccess } from 'lib/securityHelper';
import { useSelector } from 'react-redux';

const columnData = [
  { id: 'name', translationKey: 'company.ubos.name' },
  { id: 'functionName', translationKey: 'company.ubos.functionName' },
  { id: 'isDecisionMaker', translationKey: 'company.ubos.isDecisionMaker', walkme: 'ubo-column-decisionnaire' },
  { id: 'shares', translationKey: 'company.ubos.shares', walkme: 'ubo-column-parts' },
  { id: 'isActive', translationKey: 'company.ubos.isActive', editMode: true }
];

type CompanyUBOsTableComponentProps = {
  editMode: boolean;
  isProcessing: boolean;
  ubos: CompanyUBO[];
  isExpired: boolean;
  sharesExceeded: number;
  editUBO: (id: number) => void;
  toggleUBOActiveStatus: (id: number) => void;
}


export default function CompanyUBOsTableComponent(props: CompanyUBOsTableComponentProps) {

  const { activeCompany, securityMenus } = useSelector(state => ({
    activeCompany: state.auth.user.activeCompany,
    securityMenus: state.locale.securityMenus
  }));

  return (
    <React.Fragment>
      <Box component="div" overflow="auto">
        <Table>
          <StyledTableHeadComponent>
            <StyledTableRowComponent>
              {columnData.filter(column => props.editMode || !column.editMode).map(column => (
                <StyledTableCellTextComponent key={column.id} data-walkme={column.walkme || ''}>
                  <IntlMessageComponent id={column.translationKey} />
                </StyledTableCellTextComponent>
              ))}

              {props.editMode && hasSecurityMenuAccess("POS.Companies.Ubos", "btnEditMode", activeCompany, securityMenus) && <StyledTableCellTextComponent />}
            </StyledTableRowComponent>
          </StyledTableHeadComponent>

          <TableBody bgcolor="white">
            {props.ubos.length === 0 &&
              <StyledTableRowComponent>
                <StyledTableCellTextComponent colSpan={columnData.length + (props.editMode ? 1 : 0)}>
                  <Box textAlign="center">
                    <IntlMessageComponent id="general.noData" />
                  </Box>
                </StyledTableCellTextComponent>
              </StyledTableRowComponent>
            }

            {props.ubos.map((ubo, index) => (
              <StyledTableRowComponent key={`uboList-${index}`}>
                <StyledTableCellTextComponent>
                  <Box component="span" color="primary.main" fontWeight="fontWeightBold">{ubo.firstName} {ubo.lastName}</Box>
                </StyledTableCellTextComponent>

                <StyledTableCellTextComponent>
                  {ubo.functionName}
                </StyledTableCellTextComponent>

                <StyledTableCellTextComponent>
                  <BooleanFormatterComponent value={ubo.isDecisionMaker} />
                </StyledTableCellTextComponent>

                <StyledTableCellTextComponent>
                  {ubo.shares}
                </StyledTableCellTextComponent>

                {props.editMode && hasSecurityMenuAccess("POS.Companies.Ubos", "btnEditMode", activeCompany, securityMenus) &&
                  <StyledTableCellTextComponent>
                    <Tooltip title={<IntlMessageComponent id={(props.sharesExceeded + ubo.shares > 100) && !ubo.isActive ? "company.ubos.tooltip.sharesexceeded" : "company.ubos.tooltip.disable"} />}>
                      <Box>
                        <Switch checked={ubo.isActive} disabled={((props.sharesExceeded + ubo.shares > 100) && !ubo.isActive) || props.isProcessing} onChange={() => props.toggleUBOActiveStatus(ubo.id)} />
                      </Box>
                    </Tooltip>
                  </StyledTableCellTextComponent>
                }
                {props.editMode && !hasSecurityMenuAccess("POS.Companies.Ubos", "btnEditMode", activeCompany, securityMenus) &&
                  <StyledTableCellTextComponent padding="none">
                    <BooleanFormatterComponent value={ubo.isActive} />
                  </StyledTableCellTextComponent>
                }
                {props.editMode &&
                  <SecurityComponent pageName="POS.Companies.Ubos" elementName="btnEditMode">
                    <StyledTableCellTextComponent>
                      {((ubo.hasValidId && !ubo.hasMissingFields()) || !ubo.isActive || !props.isExpired) && <Tooltip title={<IntlMessageComponent id="company.ubos.tooltip.edit" />}>
                        <IconButton disabled={props.isProcessing} onClick={() => props.editUBO(ubo.id)}>
                          <EditOutlined />
                        </IconButton>
                      </Tooltip>}
                      {(!ubo.hasValidId || ubo.hasMissingFields()) && ubo.isActive && props.isExpired && <Tooltip title={<IntlMessageComponent id="company.ubos.tooltip.missingid" />}>
                        <IconButton disabled={props.isProcessing} onClick={() => props.editUBO(ubo.id)}>
                          <EditOutlined
                            style={{ color: 'red' }}
                          />
                        </IconButton>
                      </Tooltip>}
                    </StyledTableCellTextComponent>
                  </SecurityComponent>
                }
              </StyledTableRowComponent>
            ))}
          </TableBody>
        </Table>
      </Box>
    </React.Fragment>
  );
}
