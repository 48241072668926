/* @flow */

export default class SetBusinessPotentialRequest {
  isoCompanyId: number;
  companyId: number;
  debtorCompanyId: number;

  avgInvoiceAmount: number;
  debtorAge: number;
  maxInvoiceAmount: number;
  minInvoiceAmount: number;
  nbrInvoices: number;
  paymentTerms: string;
  yearlyAmount: number;
}
