import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import ButtonComponent from 'components/buttons/button';
import FormErrorsComponent from 'components/formErrors';
import InputAmountComponent from 'components/formatters/inputAmount';
import IntlMessageComponent from 'components/formatters/intlMessage';
import MandatoryFormFieldsComponent from 'components/form/mandatory';
import MarketPlacePickerComponent from 'components/formFields/marketPlaces';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import CountryFieldBuyersComponent from 'components/formFields/countries/buyers';

import InvestmentProfileForm from 'app/users/subscription/tab4/model';

type BlockedBuyerSignUpViewComponentProps = {
  errors: Map<string,?string>;
  form: InvestmentProfileForm;
  formErrors: string[];
  isProcessing: boolean;
  handleChange: (fieldName: string) => (event: object) => void;
  handleTypeChange: (fieldName: string) => (id: number) => void;
  submitForm: () => void;
}

export default function BlockedBuyerSignUpViewComponent(props: BlockedBuyerSignUpViewComponentProps) {
  return (
    <React.Fragment>
      <FormErrorsComponent formErrors={props.formErrors} />

      <PageSubTitleViewComponent id="page.investmentProfile.h1" />

      <Typography variant="body1">
        <IntlMessageComponent id="page.investmentProfile.introduction" />
      </Typography>

      <Box mt={3} mb={3}>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.amount"
                data-walkme="subscription-field-montant"
                label={<IntlMessageComponent id="form.ip.amount" />}
                error={!!props.errors.get('amount')}
                helperText={(props.errors.get('amount')) ?
                  <IntlMessageComponent id={props.errors.get('amount')} values={props.placeholders} /> : ''}
                value={props.form.amount}
                onChange={props.handleChange('amount')}
                InputProps={{
                  inputComponent: InputAmountComponent,
                  startAdornment: <InputAdornment position="start">€</InputAdornment>
                }}
                fullWidth
                margin="normal"
                required
              />

              <TextField
                id="form.minInvoiceAmount"
                data-walkme="subscription-field-montantminimum"
                label={<IntlMessageComponent id="form.ip.minInvoiceAmount" />}
                error={!!props.errors.get('minInvoiceAmount')}
                helperText={(props.errors.get('minInvoiceAmount')) ?
                  <IntlMessageComponent id={props.errors.get('minInvoiceAmount')} values={props.placeholders} /> : ''}
                value={props.form.minInvoiceAmount}
                onChange={props.handleChange('minInvoiceAmount')}
                InputProps={{
                  inputComponent: InputAmountComponent,
                  startAdornment: <InputAdornment position="start">€</InputAdornment>
                }}
                fullWidth
                margin="normal"
                required
              />

              <TextField
                id="form.maxInvoiceAmount"
                data-walkme="subscription-field-montantmaximum"
                label={<IntlMessageComponent id="form.ip.maxInvoiceAmount" />}
                error={!!props.errors.get('maxInvoiceAmount')}
                helperText={(props.errors.get('maxInvoiceAmount')) ?
                  <IntlMessageComponent id={props.errors.get('maxInvoiceAmount')} values={props.placeholders} /> : ''}
                value={props.form.maxInvoiceAmount}
                onChange={props.handleChange('maxInvoiceAmount')}
                InputProps={{
                  inputComponent: InputAmountComponent,
                  startAdornment: <InputAdornment position="start">€</InputAdornment>
                }}
                fullWidth
                margin="normal"
                required
              />

              <TextField
                id="form.pct90"
                data-walkme="subscription-field-partdefactures"
                label={<IntlMessageComponent id="form.ip.pct90" />}
                error={!!props.errors.get('pct90')}
                helperText={(props.errors.get('pct90')) ?
                  <IntlMessageComponent id={props.errors.get('pct90')} values={props.placeholders} /> : ''}
                value={props.form.pct90}
                onChange={props.handleChange('pct90')}
                InputProps={{
                  startAdornment: <InputAdornment position="start">%</InputAdornment>
                }}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <MarketPlacePickerComponent
                error={!!props.errors.get('marketPlace')}
                helperText={props.errors.get('marketPlace') ?
                  <IntlMessageComponent id={props.errors.get('marketPlace')} /> : ''}
                value={props.form.marketPlace}
                handleChange={props.handleChange('marketPlace')}
                fullWidth
                margin="normal"
                required
              />

              <TextField
                id="form.comment"
                label={<IntlMessageComponent id="form.ip.comment" />}
                error={!!props.errors.get('comment')}
                helperText={(props.errors.get('comment')) ?
                  <IntlMessageComponent id={props.errors.get('comment')} values={props.placeholders} /> : ''}
                value={props.form.comment}
                onChange={props.handleChange('comment')}
                fullWidth
                margin="normal"
                multiline
                rows={3}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <PageSubTitleViewComponent mt={3} id="page.subscription.tab1.bank.h2" />

      <Box mt={3}>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <CountryFieldBuyersComponent
                id="form.bankCountry"
                label={<IntlMessageComponent id="form.bankCountry.label" />}
                error={!!props.errors.get('bankCountryId')}
                helperText={props.errors.get('bankCountryId') ?
                  <IntlMessageComponent id={props.errors.get('bankCountryId')} /> : ''}
                value={props.form.bankCountryId}
                changeHandler={props.handleTypeChange('bankCountryId')}
                fullWidth
                margin="normal"
                required={true}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.bic"
                data-walkme="subscription-field-bic"
                label={<IntlMessageComponent id="form.bankIdentifier.label" />}
                error={!!props.errors.get('bankIdentifier')}
                helperText={(props.errors.get('bankIdentifier')) ?
                  <IntlMessageComponent id={props.errors.get('bankIdentifier')} /> : ''}
                value={props.form.bankIdentifier}
                onChange={props.handleChange('bankIdentifier')}
                fullWidth
                margin="normal"
                required={true}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.iban"
                data-walkme="subscription-field-iban"
                label={<IntlMessageComponent id="form.bankAccount.label" />}
                error={!!props.errors.get('bankAccount')}
                helperText={(props.errors.get('bankAccount')) ?
                  <IntlMessageComponent id={props.errors.get('bankAccount')} /> : ''}
                value={props.form.bankAccount}
                onChange={props.handleChange('bankAccount')}
                fullWidth
                margin="normal"
                required={true}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box mt={5}>
        <ButtonComponent
          color="primary"
          variant="contained"
          isProcessing={props.isProcessing}
          onClick={props.submitForm}
        ><IntlMessageComponent id="general.submit" /></ButtonComponent>

        <MandatoryFormFieldsComponent />
      </Box>
    </React.Fragment>
  );
}