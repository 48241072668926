/* @flow */

import React from 'react';
import { useSelector } from 'react-redux';

import { Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';

import AmountFormatterComponent from 'components/formatters/amount';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import PercentageFormatterComponent from 'components/formatters/percentage';

import BuyerInvestmentSummaryOverview from 'models/buyers/dashboards/investmentSummaryOverview';
import green from 'styles/colors/green';
import TooltipComponent from 'components/toolTips/index';

type BuyerInvestmentSummaryOverviewComponentProps = {
  model: BuyerInvestmentSummaryOverview;
}

export default function BuyerInvestmentSummaryOverviewComponent(props: BuyerInvestmentSummaryOverviewComponentProps) {
  const theme = useTheme();

  // Make sure the pie chart appears even if buyer has not invested yet
  let investedAmountInsuredFor70Pct = 50;
  let investedAmountInsuredFor90Pct = 50;
  if (props.model.investedAmountInsuredFor70Pct > 0 || props.model.investedAmountInsuredFor90Pct > 0) {
    investedAmountInsuredFor70Pct = props.model.investedAmountInsuredFor70Pct;
    investedAmountInsuredFor90Pct = props.model.investedAmountInsuredFor90Pct;
  }

  // Determine if investment profile is active
  const activeCompany = useSelector(state => state.auth.user.activeCompany);
  let colorInvestedAmountInsuredFor90Pct;
  let colorInvestedAmountInsuredFor70Pct;
  let colorInvestedAmount;
  let colorInvestedAmountAvailable;
  let colorInvestedAmountPct;
  let colorInvestedAmountTotal;
  let colorInvestedAmountAvailablePct;

  if (activeCompany.investmentProfile.id > 0 && !activeCompany.investmentProfile.isActive) {
    colorInvestedAmountInsuredFor90Pct = theme.palette.grey.main;
    colorInvestedAmountInsuredFor70Pct = theme.palette.grey.main;
    colorInvestedAmount = theme.palette.grey.main;
    colorInvestedAmountPct = theme.palette.grey.main;
    colorInvestedAmountAvailable = theme.palette.grey.main;
    colorInvestedAmountAvailablePct = theme.palette.grey.main;
    colorInvestedAmountTotal = theme.palette.grey.main;
  }
  else {
    colorInvestedAmountInsuredFor90Pct = theme.palette.green.main;
    colorInvestedAmountInsuredFor70Pct = theme.palette.blue.main;
    colorInvestedAmount = theme.palette.darkblue.main;
    colorInvestedAmountPct = theme.palette.darkblue.main;
    colorInvestedAmountAvailable = theme.palette.grey.main;
    colorInvestedAmountAvailablePct = theme.palette.grey.main;
  }

  const pieChartData = [
    { name: 'Insured for 90%', amt: investedAmountInsuredFor90Pct, fill: colorInvestedAmountInsuredFor90Pct },
    { name: 'Insured for 70%', amt: investedAmountInsuredFor70Pct, fill: colorInvestedAmountInsuredFor70Pct },
  ];

  // Make sure the pie chart appears even if buyer has not invested yet
  let investedAmount = 50;
  let investedAmountAvailable = 50;
  if (props.model.investedAmount > 0 || props.model.investedAmountAvailable > 0) {
    investedAmount = props.model.investedAmount;
    investedAmountAvailable = props.model.investedAmountAvailable;
  }

  const moneyChartData = [
    { name: 'Invested', amt: investedAmount, fill: colorInvestedAmount },
    { name: 'Available', amt: investedAmountAvailable, fill: colorInvestedAmountAvailable },
  ];




  return (
    <Paper elevation={0} style={{ height: '100%' }}>
      <Box p={3} pageName="Tooltip.Buyer.Dashboard" elementName="InvestmentOverview">
        <TooltipComponent pageName="Tooltip.Buyer.Dashboard" elementName="InvestmentOverview">
          <PageSubTitleViewComponent id="buyer.is.overview.title"  />
        </TooltipComponent>
        {(activeCompany.investmentProfile.id > 0 && !activeCompany.investmentProfile.isActive) &&
          <span style={{ color: 'red' }}><IntlMessageComponent id="buyer.buy.profileInactive" /></span>
        }

        <Box mt={3}>
          <Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={5}>
            <Grid item xs={12} md={6}>
              <Typography variant="body1" gutterBottom>
                <IntlMessageComponent id="buyer.is.overview.riskManagement" />
              </Typography>

              <Box mt={3}>
                <Grid container direction="row" justify="space-between" alignItems="flex-start">
                  <Grid item xs={4}>
                    <Typography variant="body1">
                      <Box component="span" color={colorInvestedAmountInsuredFor90Pct} fontSize="min(4vw, 30px);" fontWeight="fontWeightBold">
                        <PercentageFormatterComponent value={props.model.investedAmountInsuredFor90Pct} noFractionDigits />
                      </Box>
                    </Typography>
                    <Typography variant="body1">
                      <Box component="span" color={colorInvestedAmountInsuredFor90Pct}>
                        <IntlMessageComponent id="buyer.is.overview.insuredFor90Pct" />
                      </Box>
                    </Typography>
                  </Grid>

                  <Grid item xs={4}>
                    <ResponsiveContainer width="100%" height={80}>
                      <PieChart>
                        <Pie dataKey="amt" data={pieChartData} cx="50%" cy="50%" innerRadius={30} outerRadius={40} />
                      </PieChart>
                    </ResponsiveContainer>
                  </Grid>

                  <Grid item xs={4}>
                    <Typography variant="body1">
                      <Box component="span" color={colorInvestedAmountInsuredFor70Pct} fontSize="min(4vw, 30px);" fontWeight="fontWeightBold">
                        <PercentageFormatterComponent value={props.model.investedAmountInsuredFor70Pct} noFractionDigits />
                      </Box>
                    </Typography>
                    <Typography variant="body1">
                      <Box component="span" color={colorInvestedAmountInsuredFor70Pct}>
                        <IntlMessageComponent id="buyer.is.overview.insuredFor70Pct" />
                      </Box>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="body1" gutterBottom>
                <IntlMessageComponent id="buyer.is.overview.moneyAvailability" />
              </Typography>

              <Box mt={3}>
                <Grid container direction="row" justify="space-between" alignItems="flex-start">
                  <Grid item xs={4}>
                    <Typography variant="body1">
                      <Box component="span" color={colorInvestedAmountPct} fontSize="min(4vw, 30px);" fontWeight="fontWeightBold">
                        <PercentageFormatterComponent value={props.model.investedAmountPct} noFractionDigits />
                      </Box>
                    </Typography>
                    <Typography variant="body1">
                      <Box component="span" color={colorInvestedAmount}>
                        <IntlMessageComponent id="buyer.is.overview.investedAmount" />
                      </Box>
                    </Typography>
                    <Typography variant="body1">
                      <Box component="span" color={colorInvestedAmount} fontWeight="fontWeightBold">
                        <AmountFormatterComponent amount={props.model.investedAmount} minimumFractionDigits={0} />
                      </Box>
                    </Typography>
                    {props.model.investedAmountInactive > 0 && 
                    <Typography variant="body1">
                      <Box component="span" color={colorInvestedAmount}>
                        &#40;&#43;&nbsp;
                        <AmountFormatterComponent amount={props.model.investedAmountInactive} minimumFractionDigits={0} />&nbsp;
                        <IntlMessageComponent id="of inactive profiles" />&#41;
                      </Box>
                    </Typography>}
                  </Grid>

                  <Grid item xs={4}>
                    <ResponsiveContainer width="100%" height={80}>
                      <PieChart>
                        <Pie dataKey="amt" data={moneyChartData} cx="50%" cy="50%" innerRadius={30} outerRadius={40} />
                      </PieChart>
                    </ResponsiveContainer>
                  </Grid>

                  <Grid item xs={4}>
                    <Typography variant="body1">
                      <Box component="span" color={colorInvestedAmountAvailablePct} fontSize="min(4vw, 30px);" fontWeight="fontWeightBold">
                        <PercentageFormatterComponent value={props.model.investedAmountAvailablePct} noFractionDigits />
                      </Box>
                    </Typography>
                    <Typography variant="body1">
                      <Box component="span" color={colorInvestedAmountAvailable}>
                        <IntlMessageComponent id="buyer.is.overview.investedAmountAvailable" />
                      </Box>
                    </Typography>
                    <Typography variant="body1">
                      <Box component="span" color={colorInvestedAmountAvailable} fontWeight="fontWeightBold">
                        <AmountFormatterComponent amount={props.model.investedAmountAvailable} minimumFractionDigits={0} />
                      </Box>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>

              <Box mt={3}>
                <Typography variant="body1">
                  <IntlMessageComponent id="buyer.is.overview.investedAmountTotal" />:&nbsp;
                  <Box component="span" fontWeight="fontWeightBold" color={colorInvestedAmountTotal}>
                    <AmountFormatterComponent amount={props.model.investedAmountTotal} minimumFractionDigits={0} />
                  </Box>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Paper>
  );
}
