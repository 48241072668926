import React from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Error404Page from 'components/errors/error404';
import ImpersonateHintsComponent from 'components/impersonateHints';
import NotificationsComponent from 'components/notifications';
import RestrictedRouteComponent from 'components/route/restrictedRoute';

import BuyerPage from './buyer';
import CompanyPage from './company';
import ContactPage from './contacts';
import DebtorPage from './debtor';
import ConfirmingPage from './confirming';
import ISOPage from './iso';
import ProfilePage from './profile';
import SellerPage from './seller';
import SubscriptionsPage from './subscription';
import TermsAndConditionsPage from './termsAndConditions';

import { history } from 'store/index';
import { SIGN_IN, PROTECTED_APP } from 'constants/pageRoutes';

import { ROLE_BUYER, ROLE_CONFIRMING, ROLE_DEBTOR, ROLE_ISO, ROLE_SELLER } from 'constants/companyRoles';

class ProtectedApp extends React.Component {

  render() {
    // User must be identified
    if (!this.props.user || !this.props.user.activeCompany) {
      history.push(SIGN_IN);
      return 403;
    }

    // Conditions to be redirected / display the pages
    const activeCompany = this.props.user.activeCompany;
    const isBuyer = activeCompany.role === ROLE_BUYER && !activeCompany.isSubscribing;
    const isDebtor = activeCompany.role === ROLE_DEBTOR && !activeCompany.isSubscribing;
    const isISO = activeCompany.role === ROLE_ISO && activeCompany.roleISO.isISOPortalActive && !activeCompany.isSubscribing;
    const isSeller = activeCompany.role === ROLE_SELLER && !activeCompany.isSubscribing;
    const isConfirming = activeCompany.role === ROLE_CONFIRMING && !activeCompany.isSubscribing;

    // redirect the user to the correct page (if needed)
    let redirect = <Redirect to="/app" />;
    if (activeCompany.isSubscribing) {
      redirect = <Redirect to="/app/subscription" />;
    } else if (activeCompany.role === ROLE_ISO && !activeCompany.roleISO.isISOPortalActive) {
      redirect = <Redirect to="/app/contacts" />;
    } else if (activeCompany.role === ROLE_BUYER) {
      redirect = <Redirect to="/app/buyer" />;
    } else if (activeCompany.role === ROLE_DEBTOR) {
      redirect = <Redirect to="/app/debtor" />;
    } else if (activeCompany.role === ROLE_ISO) {
      redirect = <Redirect to="/app/iso" />;
    } else if (activeCompany.role === ROLE_SELLER) {
      redirect = <Redirect to="/app/seller" />;
    } else if (activeCompany.role === ROLE_CONFIRMING) {
      redirect = <Redirect to="/app/confirming" />;
    }

    return (
      <div>
        {this.props.user.isImpersonating && !activeCompany.isSubscribing && !activeCompany.canExecute &&
          <ImpersonateHintsComponent />
        }

        {!activeCompany.isSubscribing &&
          <NotificationsComponent key={`notifications-${activeCompany.id}-${activeCompany.role}-${this.props.locale}`} />
        }

        <Switch>
          <Route exact path={`${this.props.match.url}/`} render={() => redirect} />
          <Route path={`${this.props.match.url}/contacts`} component={ContactPage} />
          <RestrictedRouteComponent path={`${this.props.match.url}/subscription`} condition={activeCompany.isSubscribing} component={SubscriptionsPage} redirectUrl={PROTECTED_APP} />
          <RestrictedRouteComponent path={`${this.props.match.url}/terms-and-conditions`} condition={activeCompany.canSignGeneralConditions} component={TermsAndConditionsPage} redirectUrl={PROTECTED_APP} />
          <RestrictedRouteComponent path={`${this.props.match.url}/buyer`} condition={isBuyer} component={BuyerPage} redirectUrl={PROTECTED_APP} />
          <RestrictedRouteComponent path={`${this.props.match.url}/debtor`} condition={isDebtor} component={DebtorPage} redirectUrl={PROTECTED_APP} />
          <RestrictedRouteComponent path={`${this.props.match.url}/iso`} condition={isISO} component={ISOPage} redirectUrl={PROTECTED_APP} />
          <RestrictedRouteComponent path={`${this.props.match.url}/seller`} condition={isSeller} component={SellerPage} redirectUrl={PROTECTED_APP} />
          <RestrictedRouteComponent path={`${this.props.match.url}/confirming`} condition={isConfirming} component={ConfirmingPage} redirectUrl={PROTECTED_APP} />
          <RestrictedRouteComponent path={`${this.props.match.url}/profile`} condition={!activeCompany.isSubscribing} component={ProfilePage} redirectUrl={PROTECTED_APP} />
          <RestrictedRouteComponent path={`${this.props.match.url}/company`} condition={!activeCompany.isSubscribing} component={CompanyPage} redirectUrl={PROTECTED_APP} />
          <Route component={Error404Page} />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  locale: state.locale.locale,
  user: state.auth.user
});
export default withRouter(connect(mapStateToProps)(ProtectedApp));
