/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import PopupLoadingViewComponent from 'components/loading/popup';

import DebtorInvoicesToApproveConfirmPopupViewComponent from './view';
import ApproveInvoiceForm from './model';

import DebtorService from 'services/DebtorService';
import {handleApiFormResponse, handleFormChange, handleFormValidation} from 'lib/forms';
import loadConstraints from 'lib/validation/loadConstraints';
import NotificationHelper from 'lib/notifications';
import SignDebtorApprovalRequest from 'models/requests/debtors/signDebtorApprovalRequest';
import { PROTECTED_DEBTOR_DASHBOARD } from 'constants/pageRoutes';

type DebtorInvoicesToApproveConfirmPopupComponentProps = {
  activeCompanyId: number;
  invoiceIds: number[];
  handleCancel: () => void;
  handleSuccess: () => void;
}

type DebtorInvoicesToApproveConfirmPopupComponentState = {
  constraints: any;
  errors: Map<string, ?string>;
  form: ApproveInvoiceForm;
  formErrors: string[];
  isLoading: boolean;
  isProcessing: boolean;
}

class DebtorInvoicesToApproveConfirmPopupComponent extends React.Component<DebtorInvoicesToApproveConfirmPopupComponentProps, DebtorInvoicesToApproveConfirmPopupComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      form: null,
      isLoading: true,
      isProcessing: false
    };
  }

  componentDidMount = async () => {
    try {

      this.setState({
        form: new ApproveInvoiceForm(),
        isLoading: false
      });
    } catch (e) {
      console.error(e);
    }
  };

  handleChange = (fieldName: string) => (event) => handleFormChange.call(this, fieldName, event.target.value);
  validateForm = () => handleFormValidation.call(this);

  submitForm = async () => {
    this.setState({ isProcessing: true});

    try {
      const request = new SignDebtorApprovalRequest(this.props.activeCompanyId, this.props.invoiceIds, PROTECTED_DEBTOR_DASHBOARD);
      const transaction = await DebtorService.signDebtorApproval(request);
      window.location.replace(transaction.url);
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }
  };

  render() {
    if (this.state.isLoading) {
      return (<PopupLoadingViewComponent />);
    }

    return (
      <DebtorInvoicesToApproveConfirmPopupViewComponent
        errors={this.state.errors}
        form={this.state.form}
        formErrors={this.state.formErrors}
        isProcessing={this.state.isProcessing}
        handleCancel={this.props.handleCancel}
        handleChange={this.handleChange}
        submitForm={this.submitForm}
      />
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps)(DebtorInvoicesToApproveConfirmPopupComponent);
