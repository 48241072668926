/* @flow */

import React from 'react';

type IbanFormatterComponentProps = {
  iban: string;
}

export default function IbanFormatterComponent(props: IbanFormatterComponentProps) {
  const getFormattedIban = () => {
    let iban = '';
    for(let i=0; i<props.iban.length; i++) {
      iban += props.iban[i];
      if ((i % 4) === 3) iban += ' ';
    }
    return iban;
  }

  return (<React.Fragment>{getFormattedIban()}</React.Fragment>);
}
