/* @flow */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function IconWallet(props) {
  return (
    <SvgIcon viewBox="0 0 67.9 60.03" {...props}>
      <path d="M455,298.07v10.39c-.33.36-.61.93-1,1-1,.27-1,.92-1,1.71,0,4.47,0,8.94,0,13.4,0,1.29-.3,2.21-1.61,2.59H396.23a20,20,0,0,1-2.33-.44,9.43,9.43,0,0,1-6.78-9.28c0-12.2-.06-24.4.06-36.6a11.71,11.71,0,0,1,1.41-5.13c1.78-3.38,5-4.44,8.6-4.44.88,0,1.27-.21,1.36-1.17.11-1.13.25-2.35,1.48-3h.76a2,2,0,0,0,.45.21c4.37.84,8.72,1.72,13.1,2.5a67.14,67.14,0,0,0,9.2,1.35c6.07.21,12.15.06,18.23.06,2.24,0,2.71.43,2.64,2.64,0,1.16.09,1.87,1.5,2a1.54,1.54,0,0,1,1.58,1.94c-.19,1-1,1.51-2.21,1.28-4.08-.77-8.15-1.56-12.23-2.34l-24.72-4.7-6.49-1.22c-.45,2.24-.85,4.33-1.29,6.42a1.73,1.73,0,0,1-2.07,1.49,1.78,1.78,0,0,1-1.2-2.2c.11-.59.21-1.19.35-2l-3.16.29c.87,3.25,2.85,4.73,6.16,4.73h49.25c.42,0,.85,0,1.26,0,1.38.13,1.87.63,1.89,2,0,2,0,4,0,5.95v8.72Zm-5.38-15H400.86a9.5,9.5,0,0,1-7-2.59,32.5,32.5,0,0,1-2.61-3.4,32.1,32.1,0,0,0-.77,4.67c-.08,6.2,0,12.41,0,18.61,0,5.57,0,11.14,0,16.71,0,3.37,1.49,5.6,4.27,6.39a10.12,10.12,0,0,0,2.75.27h52.17v-14H438.19c-1.46,0-2.15-.61-2.16-2,0-2.95,0-5.91,0-8.86a1.85,1.85,0,0,1,2.05-2c1.4,0,2.79,0,4.18,0h7.36Zm-10.14,23.31h12.09c0-1.74,0-3.41,0-5.08,0-.86-.24-1.17-1.14-1.16-3.36.05-6.73,0-10.1,0a7.24,7.24,0,0,0-.86.11Z" transform="translate(-387.1 -267.17)"/>
    </SvgIcon>
  );
}
