/* @flow */


import React from 'react';

import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import ButtonComponent from 'components/buttons/button';
import CompanyLookupDebtorComponent from 'components/companyLookupV2/debtors';
import FormErrorsComponent from 'components/formErrors';
import IntlMessageComponent from 'components/formatters/intlMessage';
import MandatoryFormFieldsComponent from 'components/form/mandatory';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import SellInvoiceTab1Form from './model';

type SellInvoiceTab1ViewComponentProps = {
  form: SellInvoiceTab1Form;
  formErrors: string[];
  isProcessing: boolean;
  handleBooleanChange: () => void;
  handleDebtorCompanyChange: () => void;
  submitForm: () => void;
}

export default function SellInvoiceTab1ViewComponent(props: SellInvoiceTab1ViewComponentProps) {
  return (
    <React.Fragment>
      <FormErrorsComponent formErrors={props.formErrors} />

      <PageSubTitleViewComponent id="page.sellInvoice.tab1.title" data-walkme="sellinvoice-title-informationsdevotreclient" />
      <Alert severity="info">
        <IntlMessageComponent id="seller.sell.checkdebtor" />
      </Alert>
      <Box mt={1}></Box>
      <CompanyLookupDebtorComponent
        company={props.form.debtorCompany}
        required
        twoColumns
        handleSelect={props.handleDebtorCompanyChange}
      />

      <Box mt={5}>
        <FormControl component="fieldset" fullWidth required>
          <FormLabel component="legend">
            <IntlMessageComponent id="page.sellInvoice.tab1.serviceAndGoodsDelivered?" data-walkme="sellinvoice-test-confirmer" />
          </FormLabel>

          <RadioGroup
            aria-label="goods delivered"
            name="deliveredGoods"
            onChange={props.handleBooleanChange('deliveredGoods')}
            row
            value={props.form.deliveredGoods.toString()}
          >
            <FormControlLabel
              control={<Radio color="primary" />}
              label={<IntlMessageComponent id="general.label.yes" />}
              value="true"
            />
            <FormControlLabel
              control={<Radio color="primary" />}
              label={<IntlMessageComponent id="general.label.no" />}
              value="false"
            />
          </RadioGroup>
        </FormControl>
      </Box>

      <Box mt={5}>
        <ButtonComponent
          color="primary"
          variant="contained"
          disabled={props.form.debtorCompany === null || !props.form.deliveredGoods || props.formErrors.length > 0}
          isProcessing={props.isProcessing}
          onClick={props.submitForm}
        ><IntlMessageComponent id="page.sellInvoice.tab1.button.validate" /></ButtonComponent>

        <MandatoryFormFieldsComponent />
      </Box>
    </React.Fragment>
  );
}
