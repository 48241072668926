/* @flow */

import React from 'react';
import { connect } from 'react-redux';

import SubmitInvoiceTab1ViewComponent from './view';

import SupplierCompany from 'models/companies/company';
import LeadOffer from 'models/leads/leadOffer';
import SubmitInvoiceTab1Form from './model';

import CompanyService from 'services/CompanyService';
import CompanyLookupService from 'services/CompanyLookupService';
import { handleApiFormResponse } from 'lib/forms';
import NotificationHelper from 'lib/notifications';

type SubmitInvoiceTab1ComponentProps = {
  activeCompanyId: number;
  leadOffer: LeadOffer;
  successHandler: (supplierCompany: SupplierCompany) => void;
}

type SubmitInvoiceTab1ComponentState = {
  form: SubmitInvoiceTab1Form;
  formErrors: string[];
  isProcessing: boolean;
}

class SubmitInvoiceTab1Component extends React.Component<SubmitInvoiceTab1ComponentProps, SubmitInvoiceTab1ComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      form: new SubmitInvoiceTab1Form(props.leadOffer?.supplierCompany || null),
      formErrors: [],
      isProcessing: false
    };
  }

  handleBooleanChange = (fieldName: string) => (event) => {
    const form = this.state.form;
    form[fieldName] = event.target.value === 'true';
    this.setState({ form });
  };

  handleSupplierCompanyChange = (supplierCompany: SupplierCompany) => {
    const form = this.state.form;
    form.supplierCompany = supplierCompany;
    this.setState({ form, formErrors: [] });
  };

  submitForm = async () => {
    if (this.state.form.supplierCompany === null || !this.state.form.deliveredGoods) return;
    this.setState({ formErrors: [], isProcessing: true });

    let supplierCompanyId = this.state.form.supplierCompany.id;

    if (supplierCompanyId === 0) {
      try {
        supplierCompanyId = await CompanyService.addSupplierCompany(this.state.form.supplierCompany.insurerId);
      } catch (e) {
        handleApiFormResponse.call(this, e);
        return;
      }
    }

    try {
      const supplierCompany = await CompanyLookupService.getDebtorCompany(this.props.activeCompanyId, supplierCompanyId);
      this.props.successHandler(supplierCompany);
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
      this.setState({ isProcessing: false });
    }
  }

  render() {
    return (
      <SubmitInvoiceTab1ViewComponent
        form={this.state.form}
        formErrors={this.state.formErrors}
        isProcessing={this.state.isProcessing}
        handleBooleanChange={this.handleBooleanChange}
        handleSupplierCompanyChange={this.handleSupplierCompanyChange}
        submitForm={this.submitForm}
      />
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps)(SubmitInvoiceTab1Component);