/* @flow */

import Company from 'models/companies/company';

import RegisterCompanyRequest from 'models/requests/register/registerCompanyRequest';

import {COMPANY_TYPE_SELLER} from 'constants/constants';
import IncompleteAlertRequest from 'models/requests/register/incompleteAlertRequest';

export default class RegisterCompanyForm {
  companyType: number;
  company: Company;
  lastName: string;
  firstName: string;
  email: string;
  mobile: string;
  mobilePresence: string;
  functionName: string;

  constructor() {
    this.companyType = COMPANY_TYPE_SELLER;
    this.company = null;
    this.lastName = '';
    this.firstName = '';
    this.email = '';
    this.mobile = '';
    this.mobilePresence = '';
    this.functionName = '';
  }

  toRequest(languageId: number) {
    const request = new RegisterCompanyRequest();
    request.companyType = this.companyType;
    request.insurerId = this.company.insurerId;
    request.companyName = this.company.name;
    request.countryId = this.company.countryId;
    request.postalCode = this.company.zipCode;
    request.city = this.company.city;
    request.phone = this.company.phone;
    request.isoAndCampaign = null;
    
    request.firstName = this.firstName;
    request.lastName = this.lastName;
    request.email = this.email;
    request.mobile = this.mobilePresence.length > 1 ? this.mobilePresence : this.mobile;
    request.languageId = languageId;
    request.functionName = this.functionName;
    return request;
  }

  toIncompleteAlertRequest(languageId: number, flow: string) {
    const request = new IncompleteAlertRequest();
    request.companyType = this.companyType;
    request.insurerId = this.company?.insurerId;
    request.companyName = this.company?.name;
    request.countryId = this.company?.countryId;
    request.postalCode = this.company?.zipCode;
    request.city = this.company?.city;
    request.phone = this.company?.phone;
    request.vatNumber = this.company?.vatNumber;
    
    request.firstName = this.firstName;
    request.lastName = this.lastName;
    request.email = this.email;
    request.mobile = this.mobilePresence?.length > 1 ? this.mobilePresence : this.mobile;
    request.languageId = languageId;
    request.functionName = this.functionName;
    
    request.requestFlow = flow;
    
    return request;
  }
}
