import {
  DOC_TYPE_ID,
  DOC_TYPE_ID_FRONT,
  DOC_TYPE_ID_BACK,
  DOC_TYPE_PASSPORT,
  DOC_TYPE_RESIDENT_BACK,
  DOC_TYPE_RESIDENT_FRONT,
  DOC_ENTITY_TYPE_PERSON,
  REGISTRATTION_TYPE_CITIZEN,
  REGISTRATTION_TYPE_PASSPORT,
  REGISTRATTION_TYPE_RESIDENT
} from 'constants/constants';

export function getDocumentTypeFront(registrationTypeId: number) {
  switch (registrationTypeId) {
    case REGISTRATTION_TYPE_PASSPORT:
      return DOC_TYPE_PASSPORT;
    case REGISTRATTION_TYPE_RESIDENT:
      return DOC_TYPE_RESIDENT_FRONT;
    default:
      return DOC_TYPE_ID_FRONT;
  }
}

export function getDocumentTypeBack(registrationTypeId: number) {
  switch (registrationTypeId) {
    case REGISTRATTION_TYPE_RESIDENT:
      return DOC_TYPE_RESIDENT_BACK;
    default:
      return DOC_TYPE_ID_BACK;
  }
}

export function checkId(docNotExpiredAndNotReplaced: [], registrationTypeId: number) {

  // Check if the ID is valid
  let hasValidId = false;
  let hasValidTypeId = false;
  let hasValidTypeIdFront = false;
  let hasValidTypeIdBack = false;
  let hasValidTypePassport = false;
  let hasValidTypeResidentFront = false;
  let hasValidTypeResidentBack = false;
  for (var i = 0; i < docNotExpiredAndNotReplaced.length; i++) {
    let row = docNotExpiredAndNotReplaced[i];
    let now = new Date();
    let twoDaysFromUpload = new Date(row.upload);
    twoDaysFromUpload = new Date(twoDaysFromUpload.setDate(twoDaysFromUpload.getDate() + 2));
    if (row.entityTypeID !== DOC_ENTITY_TYPE_PERSON) continue;
    if (row.isActive && (!row.expires && twoDaysFromUpload < now)) continue;
    switch (row.typeID) {
      case DOC_TYPE_ID:
        hasValidTypeId = true;
        break;
      case DOC_TYPE_PASSPORT:
        hasValidTypePassport = true;
        break;
      case DOC_TYPE_ID_FRONT:
        hasValidTypeIdFront = true;
        break;
      case DOC_TYPE_RESIDENT_FRONT:
        hasValidTypeResidentFront = true;
        break;
      case DOC_TYPE_ID_BACK:
        hasValidTypeIdBack = true;
        break;
      case DOC_TYPE_RESIDENT_BACK:
        hasValidTypeResidentBack = true;
        break;
      default:
        break;
    }
  }

  if ((registrationTypeId == REGISTRATTION_TYPE_CITIZEN && ((hasValidTypeIdFront && hasValidTypeIdBack) || hasValidTypeId)) ||
    (registrationTypeId == REGISTRATTION_TYPE_PASSPORT && hasValidTypePassport) ||
    (registrationTypeId == REGISTRATTION_TYPE_RESIDENT && (hasValidTypeResidentFront && hasValidTypeResidentBack))) hasValidId = true;

  return hasValidId;
}