/* @flow */

export default class InvestmentProfileDefaults {
  amountMinValue: number;
  minAmountMinValue: number;
  maxAmountMinValue: number;
  maxAmountMultiplier: number;
  pct90MinValue: number;
  pct90MaxValue: number;
}
