/* @flow */

import React from 'react';
import {useDispatch} from 'react-redux';

import IconButton from '@material-ui/core/IconButton';

import IconSignOut from 'components/icons/signOut';

import {userSignOutAction} from 'store/actions/authActions';

export default function WebsiteHeaderSignOutComponent() {
  const dispatch = useDispatch();

  return (
    <IconButton
      color="inherit"
      onClick={() => dispatch(userSignOutAction())}
    >
      <IconSignOut />
    </IconButton>
  );
}
