/* @flow */

import React from 'react';

import SellerMGMComponent from 'app/sellers/mgm';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function SellerMGMPage() {
  return (
    <WebsitePageComponent id="seller.mgm.title" noPaper>
      <SellerMGMComponent />
    </WebsitePageComponent>
  );
}
