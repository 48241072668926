/* @flow */

import moment from 'moment/moment';

export default class DocumentNotExpiredAndNotReplaced {
    id: number;
    entityTypeId: number;
    entityId: number;
    typeId: number;
    type: string;
    upload: moment;
    expires: moment;
    documentDate: moment;
    isActive: boolean;
    toSign: boolean;
    signed: moment;
    companyId: number;
    personId: number;
}
