/* @flow */

import React from 'react';

import CompanyUsersFormComponent from 'app/companies/users/form';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function CompanyUsersNewPage() {
  return (
    <WebsitePageComponent id="page.profile.users.title">
      <CompanyUsersFormComponent companyUserId={0} />
    </WebsitePageComponent>
  );
}
