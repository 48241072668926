/* @flow */

import React from 'react';

import DebtorSupplierBankAccountChangeTableComponent from 'app/debtors/suppliersBankAccount';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function DebtorSuppliersBankAccountPage() {
  return (
    <WebsitePageComponent id="debtor.supplier.bankaccount.title" noPaper>
      <DebtorSupplierBankAccountChangeTableComponent changedToEdebex={true}/>
    </WebsitePageComponent>
  );
}
