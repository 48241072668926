/* @flow */

import DebtorCompany from 'models/companies/debtorCompany';

export default class SellInvoiceTab1Form {
  debtorCompany: DebtorCompany;
  deliveredGoods: boolean;

  constructor(debtorCompany: DebtorCompany) {
    this.debtorCompany = debtorCompany;
    this.deliveredGoods = false;
  }
}
