/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import SellerInvoiceDetailsPricesFlexCashSummaryComponent from './cashSummary';
import SellerInvoiceDetailsPricesFlexValueSummaryComponent from './valueSummary';

import InvoiceBills from 'models/invoices/seller/invoiceBills';
import InvoiceDetails from 'models/invoices/seller/invoiceDetails';

type SellerInvoiceDetailsPricesFlexComponentProps = {
  invoice: InvoiceDetails;
  invoiceBills: InvoiceBills;
  downloadBill: (billId: number) => void;
}

export default function SellerInvoiceDetailsPricesFlexComponent(props: SellerInvoiceDetailsPricesFlexComponentProps) {

  return (
    <Box mt={3} py={3} bgcolor="lightblue.main">
      <Grid container direction="row" justify="space-between" alignItems="flex-start">
        <Grid item xs={12} md={6}>
          <Box px={3}>
            <SellerInvoiceDetailsPricesFlexValueSummaryComponent
              invoice={props.invoice}
              invoiceBills={props.invoiceBills}
              downloadBill={props.downloadBill}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box px={3}>
            <SellerInvoiceDetailsPricesFlexCashSummaryComponent invoice={props.invoice} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
