/* @flow */

import {ROLE_BUYER, ROLE_DEBTOR, ROLE_ISO, ROLE_SELLER} from 'constants/companyRoles';

export function hasSecurityMenuAccess(pageName: string, elementName:string, activeCompany: any, securityMenus: any) {
  // Specific permission
  let hasAccess = true;

  // BUYER
  if (activeCompany.role === ROLE_BUYER) {
    if (hasAccess && securityMenus.length > 0 && pageName && elementName) {
     const menu = securityMenus.find(m => m.pageName === pageName && m.elementName === elementName && m.userRoleId === activeCompany.roleBuyer.role && m.hasAccess);
     if (!menu) hasAccess = false;
    }
  }

  // DEBTOR
  else if (activeCompany.role === ROLE_DEBTOR) {
    if (hasAccess && securityMenus.length > 0 && pageName && elementName) {
      const menu = securityMenus.find(m => m.pageName === pageName && m.elementName === elementName && m.userRoleId === activeCompany.roleDebtor.role && m.hasAccess);
      if (!menu) hasAccess = false;
    }
  }

  // ISO
  else if (activeCompany.role === ROLE_ISO) {
    if (!activeCompany.roleISO.isISOPortalActive) hasAccess = false;
    if (hasAccess && securityMenus.length > 0 && pageName && elementName) {
      const menu = securityMenus.find(m => m.pageName === pageName && m.elementName === elementName && m.userRoleId === activeCompany.roleISO.role && m.hasAccess);
      if (!menu) hasAccess = false;
    }
  }

  // SELLER
  else if (activeCompany.role === ROLE_SELLER) {
    if (hasAccess && securityMenus.length > 0 && pageName && elementName) {
     const menu = securityMenus.find(m => m.pageName === pageName && m.elementName === elementName && m.userRoleId === activeCompany.roleSeller.role && m.hasAccess);
     if (!menu) hasAccess = false;
    }
  }

  // RESULT
  if (!hasAccess) return false;
  return true;
}
