/* @flow */

import React from 'react';
import {useSelector} from 'react-redux';

import Box from '@material-ui/core/Box';

type LeadStatusComponentProps = {
  color: string;
  id: number;
}

export default function LeadStatusComponent(props: LeadStatusComponentProps) {
  const values = useSelector(state => state.locale.leadStatus);
  const value = values.find(v => v.id === props.id);

  if (value) return <Box color={props.color || 'text.primary'} component="span">{value.name}</Box>;
  return null;
}