/* @flow */

import CommissionInvoiceIsoCompany from './commissionInvoiceIsoCompany';

export default class CommissionInvoiceData {
  companies: CommissionInvoiceIsoCompany[];
  debtAmount: number;
  financedAmount: number;
  paidServiceFee: number;
  invoiceCommission: number;

  constructor() {
    this.companies = [];
    this.debtAmount = 0;
    this.financedAmount = 0;
    this.paidServiceFee = 0;
    this.invoiceCommission = 0;
  }
}
