/* @flow */

import React from 'react';
import moment from 'moment';

import { connect } from 'react-redux';
import { setComponentData } from 'store/actions/componentDataActions';

import NotificationHelper from 'lib/notifications';
import customSort from 'lib/sortHelpers';

import ConfirmingInvoiceTableControlsViewComponent from './invoiceTableControls';
import LoadingComponent from 'components/loading';

import ConfirmingInvoicesPortfolioTableViewComponent from './view';

import DownloadPortfolioRequest from 'models/requests/debtors/downloadPortfolioRequest';
import Invoice from 'models/confirming/invoice';
import InvoiceActiveFilters from 'models/confirming/invoiceActiveFilters';
import InvoiceFilters from 'models/confirming/invoiceFilters';
import CancelInvoiceRequest from 'models/requests/sellers/cancelInvoiceRequest';

import ConfirmingService from 'services/ConfirmingService';
import InvoiceSellerService from 'services/InvoiceSellerService';

type ConfirmingInvoicesPortfolioTableComponentProps = {
  activeCompanyId: number;
}

type ConfirmingInvoicesPortfolioTableComponentState = {
  filters: InvoiceFilters;
  invoices: Invoice[];
  isLoading: boolean;
  order: string;
  orderBy: ?string;
  page: number;
  pageSize: number;
  activeFilters: InvoiceActiveFilters;
}

class ConfirmingInvoicesPortfolioTableComponent extends React.Component<ConfirmingInvoicesPortfolioTableComponentProps, ConfirmingInvoicesPortfolioTableComponentState> {

  constructor(props) {
    super(props);
    this.state = {
      filters: null,
      activeFilters: null,
      invoices: [],
      isLoading: true,
      order: 'asc',
      orderBy: undefined,
      page: 0,
      pageSize: 10
    };
  }

  componentDidMount = async () => {
    try {
      const { invoices, filters } = await ConfirmingService.getInvoicesPortfolio(this.props.activeCompanyId);
      const activeFilters = new InvoiceActiveFilters();
      this.setState({ filters, invoices, activeFilters, isLoading: false });
    } catch (e) {
      console.error(e);
    }
  }

  handleExport = async (format: string) => {
    try {
      const request = new DownloadPortfolioRequest(this.props.activeCompanyId, format, this.state.activeFilters);
      await ConfirmingService.downloadPortfolio(request);
    } catch (e) {
      console.error(e);
    }
  };

  handleFiltersChange = (activeFilters: InvoiceActiveFilters) => {
    this.setState(activeFilters);
  }

  handlePageChange = (page: number) => this.setState({ page });
  handlePageSizeChange = (pageSize: number) => this.setState({ pageSize });

  handleSortOrderChange = (orderBy: string) => {
    let order = this.state.orderBy === orderBy && this.state.order === 'desc' ? 'asc' : 'desc';

    let invoices = null;
    if (orderBy.startsWith('supplierCompany.')) {
      // get exact property name
      const o = orderBy.substring(16);

      // order
      invoices = order === 'desc'
        ? this.state.invoices.sort((a, b) => customSort(b.supplierCompany[o], a.supplierCompany[o]))
        : this.state.invoices.sort((a, b) => customSort(a.supplierCompany[o], b.supplierCompany[o]));
    }
    else {
      // order
      invoices = order === 'desc'
        ? this.state.invoices.sort((a, b) => customSort(b[orderBy], a[orderBy]))
        : this.state.invoices.sort((a, b) => customSort(a[orderBy], b[orderBy]));
    }

    this.setState({ invoices, order, orderBy });
  };

  getFilteredResults = () => {
    let filteredInvoices = this.state.invoices;

    // filter on query search
    if (this.state.activeFilters.query && this.state.activeFilters.query.length > 2) {
      filteredInvoices = filteredInvoices.filter((i: Invoice) => {
        const query = this.state.activeFilters.query.toUpperCase();
        const fields = [i.id, i.invoiceName, i.reference, i.debtorReference].map(v => (v || '').toString().toUpperCase());
        return !!(fields.filter(field => field.toString().indexOf(query) !== -1)).length;
      });
    }

    // Filter on seller company
    if (this.state.activeFilters.sellerCompanyId > 0) {
      filteredInvoices = filteredInvoices.filter(i => i.supplierCompany.supplierId === this.state.activeFilters.sellerCompanyId);
    }

    // Filter on invoice status
    if (this.state.activeFilters.statusKey !== '') {
      filteredInvoices = filteredInvoices.filter(i => i.statusKey.toUpperCase() === this.state.activeFilters.statusKey.toUpperCase());
    }

    if (this.state.activeFilters.dateFrom !== null && this.state.activeFilters.dateTo !== null) {
      filteredInvoices = filteredInvoices.filter(i => moment(i.dueDate).isBetween(this.state.activeFilters.dateFrom, this.state.activeFilters.dateTo, 'day', '[]') ||
        moment(i.issueDate).isBetween(this.state.activeFilters.dateFrom, this.state.activeFilters.dateTo, 'day', '[]'));
    }

    // filter on amount
    if (this.state.activeFilters.invoiceAmounts && this.state.activeFilters.invoiceAmounts.length > 0) {
      const min = this.state.activeFilters.invoiceAmounts[0];
      const max = this.state.activeFilters.invoiceAmounts[1];
      if (min !== "" && max === "") filteredInvoices = filteredInvoices.filter(i => i.debtAmount >= min);
      if (min === "" && max !== "") filteredInvoices = filteredInvoices.filter(i => i.debtAmount <= max);
      if (min !== "" && max !== "") filteredInvoices = filteredInvoices.filter(i => i.debtAmount >= min && i.debtAmount <= max);
    }

    return filteredInvoices;
  };

  cancelInvoice = async (invoiceId: number) => {
    try {
      const request = new CancelInvoiceRequest(invoiceId);
      await InvoiceSellerService.cancelInvoice(request);
      NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS, 'notifications.invoice.cancel.success');

      const { invoices, filters } = await ConfirmingService.getInvoicesPortfolio(this.props.activeCompanyId);
      const activeFilters = new InvoiceActiveFilters();
      this.setState({ filters, invoices, activeFilters, isLoading: false });
    } catch (error) {
      console.error('cancelInvoice', error);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  };

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <React.Fragment>
        <ConfirmingInvoiceTableControlsViewComponent
          activeFilters={this.state.activeFilters}
          filters={this.state.filters}
          handleExport={this.handleExport}
          handleFiltersChange={this.handleFiltersChange}
          filterOnAmount
          filterOnInvoiceStatus
          filterOnPeriod
          filterOnSellerCompany
        />

        <ConfirmingInvoicesPortfolioTableViewComponent
          invoices={this.getFilteredResults()}
          order={this.state.order}
          orderBy={this.state.orderBy}
          page={this.state.page}
          pageSize={this.state.pageSize}
          handlePageChange={this.handlePageChange}
          handlePageSizeChange={this.handlePageSizeChange}
          handleSortOrderChange={this.handleSortOrderChange}
          cancelInvoice={this.cancelInvoice}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id,
  activeFilters: state.components.confirmingInvoicesPortfolioTable.activeFilters
});

export default connect(mapStateToProps, { setComponentData })(ConfirmingInvoicesPortfolioTableComponent);
