/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import LoadingComponent from 'components/loading';
import SellInvoiceTab6ViewComponent from './view';

import InvoiceSummary from 'models/invoices/seller/invoiceSummary';
import PaymentInfo from 'models/payments/paymentInfo';

import InvoiceFeesPaymentRequest from 'models/requests/payments/invoiceFeesPaymentRequest';

import InvoiceSellerService from 'services/InvoiceSellerService';
import PaymentService from 'services/PaymentService';

import NotificationHelper from 'lib/notifications';

type SellInvoiceTab6ComponentProps = {
  invoiceId: number;
  handleNewInvoiceCreation: () => void;
}

type SellInvoiceTab6ComponentState = {
  invoice: InvoiceSummary;
  isLoading: boolean;
  isProcessing: boolean;
  paymentInfo: PaymentInfo;
  paymentMethod: string;
  payableInvoices: InvoiceSummary[];
}

class SellInvoiceTab6Component extends React.Component<SellInvoiceTab6ComponentProps, SellInvoiceTab6ComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      invoice: null,
      isLoading: true,
      isProcessing: false,
      paymentInfo: null,
      payableInvoices: null
    };
  }

  componentDidMount = async () => {
    try {
      const invoicePromise = InvoiceSellerService.getInvoiceSummary(this.props.invoiceId);
      const invoicesWithFeesToPayPromise = InvoiceSellerService.getInvoicesWithFeesToPay(this.props.activeCompanyId);

      const results = await Promise.all([invoicePromise, invoicesWithFeesToPayPromise]);
      this.setState({
        invoice: results[0],
        isLoading: false,
        payableInvoices: results[1]
      });
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  }

  handleSelectionDone = async (selectedInvoiceIds: number[], paymentMethod: string) => {
    this.setState({isProcessing: true});
    try {
      const request = new InvoiceFeesPaymentRequest(this.props.activeCompanyId, selectedInvoiceIds, paymentMethod);
      const paymentInfo = await PaymentService.requestInvoiceFeesPayment(request);
      this.setState({paymentInfo, isProcessing: false, paymentMethod});
    } catch (e) {
      this.setState({isProcessing: false});
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  };

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <SellInvoiceTab6ViewComponent
        invoice={this.state.invoice}
        isProcessing={this.state.isProcessing}
        paymentInfo={this.state.paymentInfo}
        paymentMethod={this.state.paymentMethod}
        payableInvoices={this.state.payableInvoices}
        handleNewInvoiceCreation={this.props.handleNewInvoiceCreation}
        handleSelectionDone={this.handleSelectionDone}
      />
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps)(SellInvoiceTab6Component);
