/* @flow */

export default class ImpersonateForm {
  target: string;
  username: string;
  password: string;
  twofactorpin: string;

  constructor(target: string, username: string, password: string, twofactorpin: string) {
    this.target = target;
    this.username = username;
    this.password = password;
    this.twofactorpin = twofactorpin;
  }
}
