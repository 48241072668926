/* @flow */

export default function sort(a: any, b: any) {
  const isEmptyA = (a === '' || a === null || a === undefined);
  const isEmptyB = (b === '' || b === null || b === undefined);
  
  if (isEmptyA && isEmptyB) return 0;
  if (isEmptyA && !isEmptyB) return -1;
  if (!isEmptyA && isEmptyB) return 1;

  if (a === b) return 0;
  return a < b ? -1 : 1;
}
