/* @flow */

import ApproveInvoiceRequest from 'models/requests/debtors/approveInvoiceRequest';

export default class ApproveInvoiceForm {
  password: string;

  constructor() {
    this.password = '';
  }

  toRequest(companyId: number, invoiceIds: number[]){
    const request = new ApproveInvoiceRequest();
    request.companyId = companyId;
    request.invoiceIds = invoiceIds;
    request.password = this.password;
    return request;
  }
}
