/* @flow */

import React from 'react';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import LoadingComponent from 'components/loading';

import ProfileFormComponent from './form';
import PrivateKeyComponent from './privateKey';

import Person from 'models/users/person';

import UserService from 'services/UserService';
import CompanyService from 'services/CompanyService';

import { COMPANY_UBO_STATUS_DRAFT, COMPANY_UBO_STATUS_SUBMITTED, COMPANY_UBO_STATUS_EXPIRED, COMPANY_UBO_STATUS_EXPIRESOON, COMPANY_UBO_STATUS_NEW } from 'constants/constants';

type UserInfoComponentProps = {
  activeCompanyId: number;
}

type UserInfoComponentState = {
  isLoading: boolean;
  person: Person;
  checkFormValidAtLoad: boolean;
}

class UserInfoComponent extends React.Component<UserInfoComponentProps, UserInfoComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      person: null
    };
  }

  componentDidMount = async () => {
    const person = await UserService.getPersonalInfo();
    const response = await CompanyService.getCompanyUBOs(this.props.activeCompanyId);
    const statusToCheck = response.status == COMPANY_UBO_STATUS_NEW || response.status == COMPANY_UBO_STATUS_EXPIRED || response.status == COMPANY_UBO_STATUS_DRAFT || response.status == COMPANY_UBO_STATUS_EXPIRESOON;
    const checkFormValidAtLoad = statusToCheck && response.ubos.filter(u => u.email === person.email && u.isActive === true).length > 0;
    this.setState({isLoading: false,person, checkFormValidAtLoad});
  }

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <React.Fragment>
        <ProfileFormComponent person={this.state.person} checkFormValidAtLoad={this.state.checkFormValidAtLoad} />

        {this.state.person.privateKey &&
          <Box mt={5}>
            <Grid container spacing={6} alignItems="stretch">
              <Grid item xs={12} md={6}>
                <PrivateKeyComponent privateKey={this.state.person.privateKey} />
              </Grid>
            </Grid>
          </Box>
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps)(UserInfoComponent);
