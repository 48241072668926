/* @flow */

import React from 'react';
import {useHistory} from 'react-router-dom';

import Box from '@material-ui/core/Box';

import AmountFormatterComponent from 'components/formatters/amount';
import ButtonComponent from 'components/buttons/button';
import DateFormatterComponent from 'components/formatters/date';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import TimeFormatterComponent from 'components/formatters/time';

import WireTransferPayment from 'models/payments/wireTransferPayment';

import {PROTECTED_APP,PROTECTED_BUYER_PORTFOLIO} from 'constants/pageRoutes';

type BuyerTransferInvoicePaymentDelayedViewComponentProps = {
  wireTransferPayment: WireTransferPayment;
}

export default function BuyerTransferInvoicePaymentDelayedViewComponent(props: BuyerTransferInvoicePaymentDelayedViewComponentProps) {
  const history = useHistory();

  return (
    <React.Fragment>
      <PageSubTitleViewComponent id="buyer.transfer.pay.delay.title" />
      <IntlMessageComponent id="buyer.transfer.pay.delay.description" />

      <Box mt={3}>
        <Box width="70vw" display="flex">
          <Box p={1} flexGrow={1}>
            <IntlMessageComponent id="buyer.transfer.pay.delay.amount" />
          </Box>
          <Box p={1}>
            <Box component="div" textAlign="right">
              <AmountFormatterComponent amount={props.wireTransferPayment.amount} />
            </Box>
          </Box>
        </Box>
        
        <Box width="70vw" display="flex">
          <Box p={1} flexGrow={1}>
            <IntlMessageComponent id="buyer.transfer.pay.delay.date" />
          </Box>
          <Box p={1}>
            <Box component="div" textAlign="right">
              <DateFormatterComponent date={props.wireTransferPayment.dueDate} />
            </Box>
            <Box component="div" textAlign="right">
              <TimeFormatterComponent date={props.wireTransferPayment.dueDate} />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box mt={3}>
        <IntlMessageComponent id="buyer.transfer.pay.delay.warning" />
      </Box>

      <Box mt={3}>
        <div className="hiddenMobile">
          <ButtonComponent color="secondary" variant="contained" onClick={() => history.push(PROTECTED_BUYER_PORTFOLIO)}>
            <IntlMessageComponent id="buyer.transfer.pay.confirm.btn.seePortfolio" />
          </ButtonComponent>
        </div>
        <div className="hiddenDesktop">
          <ButtonComponent color="secondary" variant="contained" onClick={() => history.push(PROTECTED_APP)}>
            <IntlMessageComponent id="buyer.transfer.pay.confirm.btn.backHome" />
          </ButtonComponent>
        </div>
      </Box>
    </React.Fragment>
  );
}
