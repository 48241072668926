/* @flow */

export default class RefuseInvoiceTokenRequest {
  token: string;
  invoiceId: number;

  lastName: string;
  firstName: string;
  email: string;
  functionName: string;

  reason: string;
}
