/* @flow */

import React from 'react';

import DebtorRegisterSupplierComponent from 'app/debtors/registerSupplier';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function DebtorRegisterSupplierPage() {
  return (
    <WebsitePageComponent id="debtor.registerSupplier.title" data-walkme="debtor-registerSupplier-title">
      <DebtorRegisterSupplierComponent />
    </WebsitePageComponent>
  );
}
