/* @flow */

import DebtorCompany from 'models/companies/debtorCompany';

export default class BusinessPotential {
  debtorCompany: DebtorCompany;
  nbrInvoices: number;
  avgInvoiceAmount: number;
  yearlyAmount: number;
  paymentTerms: string;
}
