/* @flow */

import React from 'react';
import moment from 'moment';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import IntlMessageComponent from 'components/formatters/intlMessage';

import FilterPeriodComponent from './period';

import BillActiveFilters from 'models/documents/billActiveFilters';

type BillsPortfolioTableControlsViewComponentProps = {
  activeFilters: BillActiveFilters;
  handleFiltersChange: (activeFilters: BillActiveFilters) => void;
}

export default function BillsPortfolioTableControlsViewComponent(props: BillsPortfolioTableControlsViewComponentProps) {

  const handleQueryChange = (event) => {
    const activeFilters = props.activeFilters;
    activeFilters.query = event.target.value;
    props.handleFiltersChange(activeFilters);
  };

  const handlePeriodChange = (dateFrom: moment, dateTo: moment) => {
    const activeFilters = props.activeFilters;
    activeFilters.dateFrom = dateFrom ;
    activeFilters.dateTo = dateTo ;
    props.handleFiltersChange(activeFilters);
  };

  return (
    <Paper elevation={0}>
      <Box p={3} py={4}>
        <Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={3}>
          <Grid container item xs={12} md={6}>
            <Grid item xs={12} md={6}>
              <Box display="flex" height="100%" alignItems="center">
                <b><IntlMessageComponent id="component.bills.search" /></b>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                value={props.activeFilters.query}
                onChange={handleQueryChange}
                margin="dense"
                fullWidth
              />
            </Grid>
          </Grid>

          <Grid container item xs={12} md={6}>
            <Grid item xs={12} md={6}>
              <Box display="flex" height="100%" alignItems="center">
                <b><IntlMessageComponent id="component.bills.filter" /></b>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <FilterPeriodComponent
                dateFrom={props.activeFilters.dateFrom}
                dateTo={props.activeFilters.dateTo}
                changeHandler={handlePeriodChange}
                margin="dense"
                fullWidth
              />
            </Grid>
          </Grid> 
        </Grid>
      </Box>
    </Paper>
  );
}
