/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';

type InvoiceNameComponentProps = {
  invoiceId: number;
  invoiceName: number;
  marketPlaceId: number;
}

export default function InvoiceNameComponent(props: InvoiceNameComponentProps) {
  return (
    <Box component="span" display="inline-block" style={{verticalAlign: 'middle', textAlign: 'center'}}>
      {props.invoiceId}

      {(props.invoiceName && props.invoiceName !== 0 && props.invoiceName !== props.invoiceId) &&
        <Box component="span" display="block" fontSize='.75em'>({props.invoiceName})</Box>
      }
    </Box>
  );
}
