/* @flow */

import ISOAndCampaignRequest from 'models/requests/marketing/isoAndCampaignRequest';

export default class RegisterCompanyRequest {
  companyType: number;
  insurerId: string;
  companyName: string;
  countryId: number;
  isoAndCampaign: ISOAndCampaignRequest;

  lastName: string;
  firstName: string;
  email: string;
  mobile: string;
  languageId: number;  
  functionName: string;
}
