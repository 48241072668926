/* @flow */

import IconAddFilled from 'components/icons/filled/add';
import IconDocumentFilled from 'components/icons/filled/document';
import IconHomeFilled from 'components/icons/filled/home';
import IconSuitcaseFilled from 'components/icons/filled/suitcase';

import {
  PROTECTED_ISO_DASHBOARD,
  PROTECTED_ISO_OFFER,
  PROTECTED_ISO_COUPONS,
  PROTECTED_ISO_MANDATES,
  PROTECTED_ISO_REGISTER_LEAD,
  PROTECTED_ISO_REGISTER_SELLER,
  PROTECTED_ISO_LEADS,
  PROTECTED_ISO_INVOICES,
  PROTECTED_ISO_SELLERS,
  PROTECTED_ISO_COMMISSIONS,
  PROTECTED_ISO_COMMERCIAL
} from 'constants/pageRoutes';

export const isoMenuData = [
  {pageName: 'POS.MainMenu', elementName: 'mnuHome', link: PROTECTED_ISO_DASHBOARD, icon: <IconHomeFilled color='primary' size='1.5x'/>, messageId: 'sidebar.page.home'},
  {
    id: 'acordion-1',
    collapsed: false,
    type: 'sub-menu',
    icon: <IconAddFilled color='primary' size='1.5x'/>,
    messageId: 'sidebar.page.actions',
    pageName: 'POS.MainMenu',
    elementName: 'mnuActionsAccordion',
    children: [
      {pageName: 'POS.MainMenu', elementName: 'mnuCreateLead', link: PROTECTED_ISO_REGISTER_LEAD, icon: <IconHomeFilled color='primary' size='1.5x'/>, messageId: 'sidebar.page.createLead', checkExecutePermissions: true},
      {pageName: 'POS.MainMenu', elementName: 'mnuGetOffer', link: PROTECTED_ISO_OFFER, icon: <IconHomeFilled color='primary' size='1.5x'/>, messageId: 'sidebar.page.offer', checkExecutePermissions: true},
      {pageName: 'POS.MainMenu', elementName: 'mnuCoupons', link: PROTECTED_ISO_COUPONS, icon: <IconHomeFilled color='primary' size='1.5x'/>, messageId: 'sidebar.page.coupons'},
      {pageName: 'POS.MainMenu', elementName: 'mnuMandates', link: PROTECTED_ISO_MANDATES, icon: <IconHomeFilled color='primary' size='1.5x'/>, messageId: 'sidebar.page.mandates'},
      {pageName: 'POS.MainMenu', elementName: 'mnuRegister', link: PROTECTED_ISO_REGISTER_SELLER, icon: <IconHomeFilled color='primary' size='1.5x'/>, messageId: 'sidebar.page.registerCompany', checkExecutePermissions: true},
    ]
  },
  {
    id: 'acordion-2',
    collapsed: false,
    type: 'sub-menu',
    icon: <IconSuitcaseFilled color='primary' size='1.5x'/>,
    messageId: 'sidebar.page.information',
    pageName: 'POS.MainMenu',
    elementName: 'mnuInformationAccordion',
    children: [
      {pageName: 'POS.MainMenu', elementName: 'mnuLeads', link: PROTECTED_ISO_LEADS, icon: <IconHomeFilled color='primary' size='1.5x'/>, messageId: 'sidebar.page.leads'},
      {pageName: 'POS.MainMenu', elementName: 'mnuInvoices', link: PROTECTED_ISO_INVOICES, icon: <IconHomeFilled color='primary' size='1.5x'/>, messageId: 'sidebar.page.invoices'},
      {pageName: 'POS.MainMenu', elementName: 'mnuSellers', link: PROTECTED_ISO_SELLERS, icon: <IconHomeFilled color='primary' size='1.5x'/>, messageId: 'sidebar.page.sellers'},
      {pageName: 'POS.MainMenu', elementName: 'mnuCommissions', link: PROTECTED_ISO_COMMISSIONS, icon: <IconHomeFilled color='primary' size='1.5x'/>, messageId: 'sidebar.page.commissions'},
    ]
  },
  {pageName: 'POS.MainMenu', elementName: 'mnuCommercialMaterial', link: PROTECTED_ISO_COMMERCIAL, icon: <IconDocumentFilled color='primary' size='1.5x'/>, messageId: 'sidebar.page.documents'},
];
