/* @flow */

import React from 'react';

import { connect } from 'react-redux';

import customSort from 'lib/sortHelpers';
import DebtorSupplierBankAccountChangePopupComponent from './popup';
import LoadingComponent from 'components/loading';
import DebtorSupplierBankAccountChangeTableViewComponent from './view';

import SupplierBankAccountChange from 'models/debtors/supplierBankAccountChange';

import DebtorService from 'services/DebtorService';

type DebtorSupplierBankAccountChangeTableComponentProps = {
  activeCompanyId: number;
  changedToEdebex: boolean;
}

type DebtorSupplierBankAccountChangeTableComponentState = {
  supplierBankAccountChanges: SupplierBankAccountChange[];
  isLoading: boolean;
  order: string;
  orderBy: ?string;
  page: number;
  pageSize: number;
  showConfirmPopupForSupplierIds: number[];
}

class DebtorSupplierBankAccountChangeTableComponent extends React.Component<DebtorSupplierBankAccountChangeTableComponentProps, DebtorSupplierBankAccountChangeTableComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      supplierBankAccountChanges: [],
      isLoading: true,
      order: 'asc',
      orderBy: undefined,
      page: 0,
      pageSize: 10,
      showConfirmPopupForSupplierIds: []
    };
  }

  componentDidMount = async () => {
    try {
      if (this.props.changedToEdebex) {
        const supplierBankAccountChanges = await DebtorService.getAccountsToChangeFromEdebex(this.props.activeCompanyId);
        this.setState({ supplierBankAccountChanges, isLoading: false });
      }
      else {
        const supplierBankAccountChanges = await DebtorService.getAccountsToChangeToEdebex(this.props.activeCompanyId);
        this.setState({ supplierBankAccountChanges, isLoading: false });
      }
    } catch (e) {
      console.error(e);
    }
  }

  handlePageChange = (page: number) => this.setState({ page });
  handlePageSizeChange = (pageSize: number) => this.setState({ pageSize });

  handleSortOrderChange = (orderBy: string) => {
    let order = this.state.orderBy === orderBy && this.state.order === 'desc' ? 'asc' : 'desc';

    let supplierBankAccountChanges = null;
    if (orderBy.startsWith('sellerCompany.')) {
      // get exact property name
      const o = orderBy.substring(14);

      // order
      supplierBankAccountChanges = order === 'desc'
        ? this.state.supplierBankAccountChanges.sort((a, b) => customSort(b.sellerCompany[o], a.sellerCompany[o]))
        : this.state.supplierBankAccountChanges.sort((a, b) => customSort(a.sellerCompany[o], b.sellerCompany[o]));
    }
    else {
      // order
      supplierBankAccountChanges = order === 'desc'
        ? this.state.supplierBankAccountChanges.sort((a, b) => customSort(b[orderBy], a[orderBy]))
        : this.state.supplierBankAccountChanges.sort((a, b) => customSort(a[orderBy], b[orderBy]));
    }

    this.setState({ supplierBankAccountChanges, order, orderBy });
  };


  handleSupplierSelect = (supplierId: number) => {
    const supplierBankAccountChanges = this.state.supplierBankAccountChanges;
    const supplierBankAccountChange = supplierBankAccountChanges.find(s => s.companyId === supplierId);
    if (supplierBankAccountChange) supplierBankAccountChange.isSelected = !supplierBankAccountChange.isSelected;
    this.setState({ supplierBankAccountChanges });
  }

  handleSupplierSelectAll = () => {
    const supplierBankAccountChanges = this.state.supplierBankAccountChanges;
    const selectMode = supplierBankAccountChanges.find(s => s.isSelected) === undefined;
    supplierBankAccountChanges.forEach(s => { s.isSelected = selectMode; });
    this.setState({ supplierBankAccountChanges });
  }

  changeSupplierAccounts = () => {
    const supplierIds = this.state.supplierBankAccountChanges.filter(s => s.isSelected).map(s => s.companyId);
    this.setState({ showConfirmPopupForSupplierIds: supplierIds });
  }

  changeSupplierAccountsSuccess = async () => {
    if (this.props.changedToEdebex) {
      const supplierBankAccountChanges = await DebtorService.getAccountsToChangeFromEdebex(this.props.activeCompanyId);
      this.setState({ supplierBankAccountChanges, isLoading: false, showConfirmPopupForSupplierIds: [] });
    }
    else {
      const supplierBankAccountChanges = await DebtorService.getAccountsToChangeToEdebex(this.props.activeCompanyId);
      this.setState({ supplierBankAccountChanges, isLoading: false, showConfirmPopupForSupplierIds: [] });
    }
  }

  changeSupplierAccountsCancel = () => {
    this.setState({ showConfirmPopupForSupplierIds: [] });
  }

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <React.Fragment>

        <DebtorSupplierBankAccountChangeTableViewComponent
          supplierBankAccountChanges={this.state.supplierBankAccountChanges}
          order={this.state.order}
          orderBy={this.state.orderBy}
          page={this.state.page}
          pageSize={this.state.pageSize}
          handleSupplierSelect={this.handleSupplierSelect}
          handleSupplierSelectAll={this.handleSupplierSelectAll}
          changeSupplierAccounts={this.changeSupplierAccounts}
          handlePageChange={this.handlePageChange}
          handlePageSizeChange={this.handlePageSizeChange}
          handleSortOrderChange={this.handleSortOrderChange}
          changeToEdebex={!this.props.changedToEdebex}
        />

        {this.state.showConfirmPopupForSupplierIds.length > 0 &&
          <DebtorSupplierBankAccountChangePopupComponent
            supplierIds={this.state.showConfirmPopupForSupplierIds}
            handleCancel={this.changeSupplierAccountsCancel}
            handleSuccess={this.changeSupplierAccountsSuccess}
            changeToEdebex={!this.props.changedToEdebex}
          />
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps)(DebtorSupplierBankAccountChangeTableComponent);
