/* @flow */

import SetPasswordRequest from 'models/requests/register/setPasswordRequest';

export default class SetPasswordForm {
  password: string;
  confirmPassword: string;

  constructor() {
    this.password = '';
    this.confirmPassword = '';
  }

  toRequest(email: string, token: string) {
    const request = new SetPasswordRequest();
    request.email = email;
    request.token = token;
    request.password = this.password;
    return request;
  }
}
