/* @flow */

export default class Document {
  typeId: DocumentType;
  code: string;
  isProvided: boolean;
  isRequired: boolean;

  // working variables
  file: ?File;
  filename: ?string;
  isDeleted: boolean;
  isMissing: boolean;
  isOptional: boolean;

  constructor() {
    this.file = null;
    this.filename = '';
    this.isDeleted = false;
    this.isMissing = false;
  }
}
