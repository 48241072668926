/* @flow */

import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import AmountFormatterComponent from 'components/formatters/amount';
import IntlMessageComponent from 'components/formatters/intlMessage';

import InvoiceDetails from 'models/invoices/seller/invoiceDetails';

type SellerInvoiceDetailsPricesFlexCashSummaryComponentProps = {
  invoice: InvoiceDetails;
}

export default function SellerInvoiceDetailsPricesFlexCashSummaryComponent(props: SellerInvoiceDetailsPricesFlexCashSummaryComponentProps) {
  const [displayDetails, setDisplayDetails] = useState(false);

  const toggleDetails = () => setDisplayDetails(!displayDetails);

  const computeTotalFee = () => {
    let totalFee = props.invoice.flexPrices.discount + props.invoice.flexPrices.insuranceFeeWithVAT;
    if (!props.invoice.flexPrices.listingFeePaidByCard) {
      totalFee += props.invoice.flexPrices.listingFeeWithVAT;
    }
    if (!props.invoice.flexPrices.serviceFeePaidByCard) {
      totalFee += props.invoice.flexPrices.serviceFeeWithVAT;
    }
    if (!props.invoice.flexPrices.urgentPaymentFeePaidByCard) {
      totalFee += props.invoice.flexPrices.urgentPaymentFeeWithVAT;
    }
    return totalFee;
  }

  const computeCashValue = (totalFee: number) => {
    let cashValue = props.invoice.flexPrices.financedAmount - totalFee;
    if (props.invoice.flexPrices.compensations) {
      props.invoice.flexPrices.compensations.forEach(c => {
        cashValue -= c.amount;
      });
    }

    return cashValue;
  }

  const totalFee = computeTotalFee();
  const cashValue = computeCashValue(totalFee);

  return (
    <React.Fragment>
      <h4><IntlMessageComponent id="page.seller.invoiceDetails.prices.flex.cash.title" data-walkme="sdetails-cash-title" /></h4>
      <Typography variant="body1">
        <IntlMessageComponent id="page.seller.invoiceDetails.prices.flex.cash.text" data-walkme="sdetails-cash-text" />
      </Typography>

      <Box display="flex" mt={3} fontWeight="fontWeightBold">
        <Box flexGrow={1} mb={1}>
          <IntlMessageComponent id="page.seller.invoiceDetails.prices.flex.cash.financedAmount" data-walkme="sdetails-cash-financedAmount" />
        </Box>
        <Box>
          <AmountFormatterComponent amount={props.invoice.flexPrices.financedAmount} />
        </Box>
      </Box>

      <Box display="flex" fontWeight="fontWeightBold" onClick={toggleDetails}>
        <Box flexGrow={1} mb={1}>
          <IntlMessageComponent id="page.seller.invoiceDetails.prices.flex.cash.totalFee" data-walkme="sdetails-cash-totalFee" />
          {displayDetails ? <span>&nbsp;<ExpandLessIcon /></span> : <span>&nbsp;<ExpandMoreIcon /></span>}
        </Box>
        <Box>
          - <AmountFormatterComponent amount={totalFee} />
        </Box>
      </Box>

      {displayDetails &&
        <React.Fragment>

          <Box display="flex" pl={4} color="grey.main" fontSize={13}>
            <Box flexGrow={1} mb={1}>
              <IntlMessageComponent id="page.seller.invoiceDetails.prices.flex.cash.listingFee" data-walkme="sdetails-cash-listingFee" />
            </Box>
            <Box>
              {props.invoice.flexPrices.listingFeePaidByCard ? (
                <IntlMessageComponent id="general.paidByCard" data-walkme="sdetails-cash-listingFeePaidByCard" />
              ) : (
                <AmountFormatterComponent amount={props.invoice.flexPrices.listingFeeWithVAT} />
              )}
            </Box>
          </Box>

          <Box display="flex" pl={4} color="grey.main" fontSize={13}>
            <Box flexGrow={1} mb={1}>
              <IntlMessageComponent id="page.seller.invoiceDetails.prices.flex.cash.serviceFee" data-walkme="sdetails-cash-serviceFee" />
            </Box>
            <Box>
              {props.invoice.flexPrices.serviceFeePaidByCard ? (
                <IntlMessageComponent id="general.paidByCard" data-walkme="sdetails-cash-serviceFeePaidByCard" />
              ) : (
                <AmountFormatterComponent amount={props.invoice.flexPrices.serviceFeeWithVAT} />
              )}
            </Box>
          </Box>

          <Box display="flex" pl={4} color="grey.main" fontSize={13}>
            <Box flexGrow={1} mb={1}>
              <IntlMessageComponent id="page.seller.invoiceDetails.prices.flex.cash.insuranceFee" data-walkme="sdetails-cash-insuranceFee" />
            </Box>
            <Box>
              <AmountFormatterComponent amount={props.invoice.flexPrices.insuranceFeeWithVAT} />
            </Box>
          </Box>

          <Box display="flex" pl={4} color="grey.main" fontSize={13}>
            <Box flexGrow={1} mb={1}>
              <IntlMessageComponent id="page.seller.invoiceDetails.prices.flex.cash.discount" data-walkme="sdetails-cash-discount" />
            </Box>
            <Box>
              <AmountFormatterComponent amount={props.invoice.flexPrices.discount} />
            </Box>
          </Box>

          {(props.invoice.flexPrices.urgentPaymentFeePaidByCard || props.invoice.flexPrices.urgentPaymentFeeWithVAT > 0) &&
            <Box display="flex" pl={4} color="grey.main" fontSize={13}>
              <Box flexGrow={1} mb={1}>
                <IntlMessageComponent id="page.seller.invoiceDetails.prices.flex.cash.urgentPaymentFee" data-walkme="sdetails-cash-urgentPaymentFee" />
              </Box>
              <Box>
                {props.invoice.flexPrices.urgentPaymentFeePaidByCard ? (
                  <IntlMessageComponent id="general.paidByCard" data-walkme="sdetails-cash-urgentPaymentFeePaidByCard" />
                ) : (
                  <AmountFormatterComponent amount={props.invoice.flexPrices.urgentPaymentFeeWithVAT} />
                )}
              </Box>
            </Box>
          }
        </React.Fragment>
      }

      {props.invoice.flexPrices.compensations && props.invoice.flexPrices.compensations.map(compensation => (
        <Box display="flex" key={`compensation-${compensation.id}`} fontWeight="fontWeightBold">
          <Box flexGrow={1} mb={1}>
            <IntlMessageComponent id="page.seller.invoiceDetails.prices.flex.cash.compensation" values={{ invoiceId: compensation.id }} data-walkme="sdetails-cash-compensation" />
          </Box>
          <Box>
            - <AmountFormatterComponent amount={compensation.amount} />
          </Box>
        </Box>
      ))}

      <Box display="flex" color="primary.main" fontSize={16} fontWeight="fontWeightBold">
        <Box flexGrow={1} mb={1}>
          <IntlMessageComponent id="page.seller.invoiceDetails.prices.flex.cash.total" data-walkme="sdetails-cash-total" />
        </Box>
        <Box>
          <AmountFormatterComponent amount={cashValue} />
        </Box>
      </Box>
    </React.Fragment>
  );
}