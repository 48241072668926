/* @flow */

export default class SetBusinessOpportunityRequest {
  leadId: number;
  questionId: number;
  answerIds: number;

  constructor (leadId: number, questionId: number, answerIds: number[]) {
    this.leadId = leadId;
    this.questionId = questionId;
    this.answerIds = answerIds;
  }
}
