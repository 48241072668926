/* @flow */

import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import {withStyles} from '@material-ui/core/styles';

type RadioFieldComponentProps = {
  id: string;
  label: IntlMessage;
  value: boolean|number|string;
  values: {id: boolean|number|string, label: IntlMessage}[];
  handleChange: () => void;
}

class RadioFieldComponent extends React.Component<RadioFieldComponentProps, {}> {

  render() {
    return (
      <FormControl component="fieldset" fullWidth>
        <FormLabel component="legend" >
          {this.props.label}
        </FormLabel>

        <RadioGroup
          className={this.props.classes.group}
          name={this.props.id}
          value={this.props.value}
          onChange={this.props.handleChange}
        >
          {this.props.values.map(value => (
            <FormControlLabel
              key={`radio-${value.id}`}
              value={value.id}
              control={<Radio color="primary" />}
              label={value.label}
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  }
}

const styles = () => ({
  group: {
    width: 'auto',
    height: 'auto',
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'row',
  }
});

export default withStyles(styles)(RadioFieldComponent);
