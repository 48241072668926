/* @flow */

import Country from 'models/generics/country';

export default class CompanyLookupForm {
  country: Country;
  name: string;
  vat: string;

  constructor() {
    this.country = null;
    this.name = '';
    this.vat = '';
  }
}
