/* @flow */

import React from 'react';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';

import LeadDetailsViewComponent from './view';
import LoadingComponent from 'components/loading';

import LeadDetails from 'models/isos/leads/leadDetails';

import SendLeadToEdebexRequest from 'models/requests/isos/sendLeadToEdebexRequest';
import Office from 'models/companies/office';
import OfficePerson from 'models/companies/officePerson';

import IsoService from 'services/IsoService';
import NotificationHelper from 'lib/notifications';
import ChangeLeadIsoOfficeRequest from 'models/requests/isos/changeLeadIsoOfficeRequest';
import ChangeLeadIsoPersonRequest from 'models/requests/isos/changeLeadIsoPersonRequest';
import SaveIsoLeadNoteRequest from 'models/requests/isos/saveIsoLeadNoteRequest';
import UpdateLeadContactRequest from 'models/requests/isos/updateLeadContactRequest';

import {handleApiFormResponse, handleFormChange, handleFormValidation} from 'lib/forms';
import loadConstraints from 'lib/validation/loadConstraints';

type ISOLeadsComponentProps = {
  activeCompanyId: number;
  leadId: number;
  leadReopenedId: number;
}

type ISOLeadsComponentState = {
  isLoading: boolean;
  leadDetails: LeadDetails;
  offices: Office[];
  officePersons: OfficePerson[];
  editOffice: boolean;
  editPerson: boolean;
  editContact: boolean;
  newNote: string;
  form: UpdateLeadContactRequest;
  constraints: any;
  errors: Map<string, ?string>;
}

class ISOLeadsComponent extends React.Component<ISOLeadsComponentProps, ISOLeadsComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      leads: null,
      editOffice: false,
      editPerson: false,
      editContact: false,
      newNote: ""
    };
  }

  componentDidMount = async () => {
    this.refreshData();
  }

  refreshData = async () => {
    try {
      let leadDetails = await IsoService.getLeadDetails(this.props.activeCompanyId, this.props.leadId, this.props.leadReopenedId);

      let emptyOffice = new Office();
      let emptyOfficePerson = new OfficePerson();

      emptyOffice.id = 0;
      emptyOffice.name = "NONE";
      emptyOffice.isActive = true;
      emptyOfficePerson.id = 0;
      emptyOfficePerson.firstName = "NONE";
      emptyOfficePerson.isActive = true;

      let offices = await IsoService.getOffices(this.props.activeCompanyId);
      offices.push(emptyOffice);
      let officePersons = []
      if (leadDetails.isoOfficeId > 0 && offices.find(o => o.id === leadDetails.isoOfficeId)) {
        officePersons = await IsoService.getOfficePersons(this.props.activeCompanyId, leadDetails.isoOfficeId);
      }
      officePersons.push(emptyOfficePerson);
      const constraintFields = [];
      constraintFields.push('email', 'phone', 'mobile');
      const constraints = await loadConstraints('isoLeadContact', constraintFields);
      this.setState({
        leadDetails,
        offices: offices.filter(o => o.isActive),
        officePersons: officePersons.filter(p => p.isActive),
        isLoading: false,
        errors: new Map(Object.keys(constraints).map(e => [e, undefined])),
        editOffice: false,
        editPerson: false,
        editContact: false,
        form: new UpdateLeadContactRequest(this.props.activeCompanyId, this.props.leadId, leadDetails.leadContactEmail, leadDetails.leadContactPhoneNumber, leadDetails.leadContactMobileNumber),
        newNote: "",
        constraints
      });
    } catch (e) {
      console.error(e);
    }
  }

  sendToEdebex = async () => {
    try {
      const request = new SendLeadToEdebexRequest(this.props.activeCompanyId, this.state.leadDetails.leadId);
      await IsoService.sendLeadToEdebex(request);
      NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS);
      this.refreshData();
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  }

  editOfficeClick = () => {
    if (this.state.editOffice){
      let offices = this.state.offices;
      let leadDetails = this.state.leadDetails;
      let foundOffice = offices.filter(o => o.name === leadDetails.isoOffice)[0];
      leadDetails.isoOfficeId = foundOffice === undefined ? 0 : foundOffice.id
      this.setState({ editOffice: false});
    }
    else {
      this.setState({ editOffice: true});
    }
  }

  editPersonClick = () => {
    if (this.state.editPerson){
      let officePersons = this.state.officePersons;
      let leadDetails = this.state.leadDetails;
      let foundOfficePerson = officePersons.filter(o => o.firstName + " " + o.lastName === leadDetails.isoPerson)[0];
      leadDetails.isoPersonId = foundOfficePerson === undefined ? 0 : foundOfficePerson.id;
      this.setState({ editPerson: false});
    }
    else {
      this.setState({ editPerson: true});
    }
  }

  editContactClick = () => {
    if (this.state.editContact){
      const constraintFields = [];
      constraintFields.push('email', 'phone', 'mobile');
      const constraints = loadConstraints('isoLeadContact', constraintFields);
      let form = new UpdateLeadContactRequest(this.props.activeCompanyId, this.props.leadId, this.state.leadDetails.leadContactEmail, this.state.leadDetails.leadContactPhoneNumber, this.state.leadDetails.leadContactMobileNumber)
      let errors = new Map(Object.keys(constraints).map(e => [e, undefined]));
      this.setState({ editContact: false, form, errors});
    }
    else {
      this.setState({ editContact: true});
    }
  }

  handleOfficeChange = (event) => {
    let leadDetails = this.state.leadDetails;
    leadDetails.isoOfficeId = event.target.value;
    this.setState(leadDetails);
  }

  handlePersonChange = (event) => {
    let leadDetails = this.state.leadDetails;
    leadDetails.isoPersonId = event.target.value;
    this.setState(leadDetails);
  }

  handleContactChange = (fieldName: string) => (event) => handleFormChange.call(this, fieldName, event.target.value);


  updateOffice = async () => {
    try {
      const request = new ChangeLeadIsoOfficeRequest(this.props.activeCompanyId, this.state.leadDetails.leadId, this.state.leadDetails.isoOfficeId);
      await IsoService.changeLeadIsoOffice(request);
      NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS);
      this.refreshData();
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  }

  updatePerson = async () => {
    try {
      const request = new ChangeLeadIsoPersonRequest(this.props.activeCompanyId, this.state.leadDetails.leadId, this.state.leadDetails.isoPersonId);
      await IsoService.changeLeadIsoPerson(request);
      NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS);
      this.refreshData();
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  }

  updateContact = async () => {
    try {
      if (!this.validateForm()) return;
      const request = new UpdateLeadContactRequest(this.props.activeCompanyId, this.state.leadDetails.leadId, this.state.form.email, this.state.form.phone, this.state.form.mobile);
      await IsoService.updateLeadContact(request);
      NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS);
      this.refreshData();
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  }

  handleNoteChange = (event) => {
    this.setState({newNote: event.target.value})
  }

  validateForm = () => handleFormValidation.call(this);

  addNote = async () => {
    try {
      const request = new SaveIsoLeadNoteRequest(this.props.activeCompanyId, this.state.leadDetails.leadId, this.state.newNote);
      await IsoService.saveIsoLeadNote(request);
      NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS);
      this.refreshData();
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  }

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <React.Fragment>
        <Box mt={4}>
          <LeadDetailsViewComponent
            leadDetails={this.state.leadDetails}
            offices={this.state.offices}
            officePersons={this.state.officePersons}
            editOffice={this.state.editOffice}
            editPerson={this.state.editPerson}
            editContact={this.state.editContact}
            editOfficeClick={this.editOfficeClick}
            editPersonClick={this.editPersonClick}
            editContactClick={this.editContactClick}
            handleOfficeChange={this.handleOfficeChange}
            handlePersonChange={this.handlePersonChange}
            handleContactChange={this.handleContactChange}
            updateOffice={this.updateOffice}
            updatePerson={this.updatePerson}
            updateContact={this.updateContact}
            handleNoteChange={this.handleNoteChange}
            addNote={this.addNote}
            sendToEdebex={this.sendToEdebex}
            newNote={this.state.newNote}
            errors={this.state.errors}
            form={this.state.form}
          />
        </Box>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps)(ISOLeadsComponent);
