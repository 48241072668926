/* @flow */

import React from 'react';

import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import IntlMessageComponent from 'components/formatters/intlMessage';

const useStyles = makeStyles(theme => ({
  rootItemListIcon: {
    minWidth: '40px'
  },
}));

type MGMHeaderListComponentProps = {
  id: string;
}

export default function MGMHeaderListComponent(props: MGMHeaderListComponentProps) {
  const classes = useStyles();

  const values = {
    bold: <Box component="span" fontWeight="fontWeightBold"><IntlMessageComponent id={`${props.id}.bold`} /></Box>,
    boldBlue: <Box color="primary.main" component="span" fontWeight="fontWeightBold"><IntlMessageComponent id={`${props.id}.boldBlue`} /></Box>
  }

  return (
    <ListItem alignItems="flex-start">
      <ListItemIcon className={classes.rootItemListIcon}>
        <CheckCircleOutlineIcon color="primary" />
      </ListItemIcon>
      <ListItemText primary={<Typography variant="body1"><IntlMessageComponent id={props.id} values={values}/></Typography>} />
    </ListItem>
  );
}