/* @flow */

import React from 'react';

import OffersComponent from 'app/offers';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function ISOOffersPage() {
  return (
    <WebsitePageComponent id="page.offerPage.description" noPaper>
      <OffersComponent />
    </WebsitePageComponent>
  );
}
