/* @flow */

import OfferUnknownCompanyRequest from 'models/requests/leads/offerUnknownCompanyRequest';
import Company from 'models/companies/company';
import Country from 'models/generics/country';

export default class OfferUCForm {
  financingNeedId: number;
  financingNeedFrequencyId: number;
  invoicesAvgAmountId: number;
  invoicesYearlyAmountId: number;
  country: Country[];
  companyName: string;
  vat: string;
  firstName: string;
  lastName: string;
  mobile: string;
  email: string;
  issueInvoicesTo: string[];
  invoicesConcerning: string[];
  invoicesHigherThan5k: string[];
  titleId: number;

  constructor(firstName:string, lastName:string, mobile:string, email:string, titleId:number, country: Country, companyName:String, vat:number) {
    this.financingNeedId = 0;
    this.financingNeedFrequencyId = 0;
    this.invoicesAvgAmountId = 0;
    this.invoicesYearlyAmountId = 0;
    this.country = country;
    this.companyName = companyName;
    this.vat = vat;
    this.firstName = firstName;
    this.lastName = lastName;
    this.mobile = mobile;
    this.email = email;
    this.issueInvoicesTo = [];
    this.invoicesConcerning = [];
    this.invoicesHigherThan5k = [];
    this.titleId= titleId;
  }

  toRequest() {
    const request = new OfferUnknownCompanyRequest();
    request.financingNeedId = this.financingNeedId;
    request.financingNeedFrequencyId = this.financingNeedFrequencyId;
    request.invoicesAvgAmountId = this.invoicesAvgAmountId;
    request.invoicesYearlyAmountId = this.invoicesYearlyAmountId;
    request.country = this.country;
    request.companyName = this.companyName;
    request.vat = this.vat;
    request.firstName = this.firstName;
    request.lastName = this.lastName;
    request.mobile = this.mobile;
    request.email = this.email;
    request.issueInvoicesTo = this.issueInvoicesTo;
    request.invoicesConcerning = this.invoicesConcerning;
    request.invoicesHigherThan5k = this.invoicesHigherThan5k;
    request.titleId= this.titleId;
    return request;
  }
}
