/* @flow */

export default class OfferUnknownCompanyRequest {
    financingNeedId: number;
    financingNeedFrequencyId: number;
    invoicesAvgAmountId: number;
    invoicesYearlyAmountId: number;
    country: Country[];
    companyName: string;
    vat: string;
    firstName: string;
    lastName: string;
    mobile: string;
    email: string;
    issueInvoicesTo: string;
    invoicesConcerning: string;
    invoicesHigherThan5k: string;
    titleId: number;
  }
  