/* @flow */

import React from 'react';

import BackgroundSliderComponent from 'components/background';
import SignInComponent from 'app/users/signIn';
import SigningPageComponent from 'components/websiteStructure/pages/signin';

export default function SignInPage() {
  return (
    <SigningPageComponent>
      <BackgroundSliderComponent />
      <SignInComponent />
    </SigningPageComponent>
  );
}
