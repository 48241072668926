/* @flow */

import AddLeadNoteRequest from 'models/requests/isos/registerLeads/addLeadNoteRequest';

export default class LeadNoteForm {
  note: string;

  constructor() {
    this.note = '';
  }

  toRequest(isoCompanyId: number, companyId: number){
    const request = new AddLeadNoteRequest();
    request.isoCompanyId = isoCompanyId;
    request.companyId = companyId;
    request.note = this.note;
    return request;
  }
}
