/* @flow */

import NotificationSetting from './notificationSetting';
import NotificationSettings from 'models/users/notificationSettings';

export default class UpdateNotificationSettingsRequest {
  companyId: number;
  role: number;
  enableSMS: bool;
  sendBillEmails: bool;
  sendPaymentEmails: bool;
  settings: NotificationSetting[];

  constructor(cId: number, role: number, notificationSettings: NotificationSettings) {
    this.companyId = cId;
    this.role = role;
    this.enableSMS = notificationSettings.enableSMS;
    this.sendBillEmails = notificationSettings.sendBillEmails;
    this.sendPaymentEmails = notificationSettings.sendPaymentEmails;
    this.settings = notificationSettings.settings.map(s => new NotificationSetting(s.id, s.isChecked));
  }
}
