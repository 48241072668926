/* @flow */

import React from 'react';
import moment from 'moment';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import DateFormatterComponent from 'components/formatters/date';
import IntlMessageComponent from 'components/formatters/intlMessage';

type DashboardPageTitleViewComponentProps = {
  id: string;
}

export default function DashboardPageTitleViewComponent(props: DashboardPageTitleViewComponentProps) {
  const values = {
    dateToday: <DateFormatterComponent date={moment()}/>
  };

  return (
    <Box mb={3}>
      <Typography variant="h1" gutterBottom>
        <Box component="span" fontWeight="fontWeightBold"><IntlMessageComponent id={`${props.id}.bold`} /></Box>
        &nbsp;
        <IntlMessageComponent id={`${props.id}.thin`} values={values} />
      </Typography>
    </Box>
  );
}
