/* @flow */

import {connect} from 'react-redux';
import TypeFieldComponent from './typeImpl';

const mapStateToProps = (state, props) => ({
  ...props,
  isLoading: state.locale.genericDataIsLoading,
  values: state.locale.emailTypes
});

export default connect(mapStateToProps)(TypeFieldComponent);
