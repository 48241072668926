/* @flow */

import React from 'react';

import CompanyBankAccountsPageComponent from 'app/companies/bankAccounts';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function CompanyInfoPage() {
  return (
    <WebsitePageComponent id="component.company.bankAccounts.title" noPaper>
      <CompanyBankAccountsPageComponent />
    </WebsitePageComponent>
  );
}
