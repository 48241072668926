/* @flow */

import moment from 'moment';

import CommissionSummaryIsoCompany from './commissionSummaryIsoCompany';

export default class CommissionSummaryIsoQuarter {
  quarterDate: moment;
  financedInvoiceCommission: number;
  unfinancedInvoiceCommission: number;
  msfCommission: number;
  membershipCommission: number;
  totalCommission: number;
  companies: CommissionSummaryIsoCompany[];

  constructor(quarterDate: moment) {
    this.quarterDate = moment(quarterDate);
    this.companies = [];
    this.financedInvoiceCommission = 0;
    this.unfinancedInvoiceCommission = 0;
    this.msfCommission = 0;
    this.membershipCommission = 0;
    this.totalCommission = 0;  
  }
}
