/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';


import ConfirmingSuppliersTableComponent from './table';

import SupplierCompany from 'models/confirming/supplierCompany';

type ConfirmingSuppliersListComponentProps = {
  editMode: boolean;
  formErrors: string[];
  isProcessing: boolean;
  supplierCompanies: SupplierCompany[];
  editSupplier: (id: number) => void;
  toggleEditMode: () => void;
  order: string;
  orderBy: string;
  page: number;
  pageSize: number;
  handlePageChange: (page: number) => void;
  handlePageSizeChange: (pageSize: number) => void;
  handleSortOrderChange: (property: string) => void;
}

type ConfirmingSuppliersListComponentState = {
  isInformationValid: boolean;
}

class ConfirmingSuppliersListComponent extends React.Component<ConfirmingSuppliersListComponentProps, ConfirmingSuppliersListComponentState> {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>

        <Box mt={5}>
          <ConfirmingSuppliersTableComponent
            editMode={this.props.editMode}
            isProcessing={this.props.isProcessing}
            supplierCompanies={this.props.supplierCompanies}
            editSupplier={this.props.editSupplier}
            order={this.props.order}
            orderBy={this.props.orderBy}
            page={this.props.page}
            pageSize={this.props.pageSize}
            handlePageChange={this.props.handlePageChange}
            handlePageSizeChange={this.props.handlePageSizeChange}
            handleSortOrderChange={this.props.handleSortOrderChange}
          />
        </Box>

      </React.Fragment>
    );
  }
}

export default ConfirmingSuppliersListComponent;
