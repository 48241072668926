/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Download from '@material-ui/icons/CloudDownload';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';

import IntlMessageComponent from 'components/formatters/intlMessage';
import StyledTableCellTextComponent from 'components/table/cellText';
import StyledTableHeadComponent from 'components/table/head';
import StyledTableRowComponent from 'components/table/row';

import CommercialDocument from 'models/documents/commercialDocument';

const columnData = [
  {id: 'name', align:'left', translationKey: 'component.iso.commercialList.header.name', walkme: 'isocommercial-column-name'},
  {id: 'culture', align:'left', translationKey: 'component.iso.commercialList.header.language', walkme: 'isocommercial-column-language'},
  {id: 'download', align:'left', translationKey: 'component.iso.commercialList.header.download', walkme: 'isocommercial-column-download'}
];

type ISOCommercialDocumentsTableViewComponentProps = {
  documents: CommercialDocument[];
  order: string;
  orderBy: string;
  page: number;
  pageSize: number;
  downloadDocument: (culture: string, name: string) => void;
  handlePageChange: (page: number) => void;
  handlePageSizeChange: (pageSize: number) => void;
  handleSortOrderChange: (property: string) => void;
};

export default function ISOCommercialDocumentsTableViewComponent(props: ISOCommercialDocumentsTableViewComponentProps) {

  const handlePageChange = (_, page) => props.handlePageChange(page);
  const handlePageSizeChange = event => props.handlePageSizeChange(event.target.value);

  return (
    <React.Fragment>
      <Box py={5}>
        <Box component="div" overflow="auto">
          <Table>
            <StyledTableHeadComponent data-walkme="isocommercial-table-headline">
              <StyledTableRowComponent>
                {columnData.map(column => (
                  <StyledTableCellTextComponent align={column?.align ?? 'right'} key={column.id} padding="none">
                    <TableSortLabel
                      active={props.orderBy === column.id}
                      direction={props.order}
                      onClick={() => props.handleSortOrderChange(column.id)}
                      data-walkme={`${props.walkmePrefix}-${column.walkme}` || ''}
                    ><IntlMessageComponent id={column.translationKey} /></TableSortLabel>
                  </StyledTableCellTextComponent>
                ))}
              </StyledTableRowComponent>
            </StyledTableHeadComponent>

            <TableBody bgcolor="white">
              {props.documents.length === 0 &&
                <StyledTableRowComponent>
                  <StyledTableCellTextComponent colSpan={columnData.length}>
                    <Box textAlign="center">
                      <IntlMessageComponent id="component.iso.documentList.noData" />
                    </Box>
                  </StyledTableCellTextComponent>
                </StyledTableRowComponent>
              }

              {props.documents.slice(props.page * props.pageSize, (props.page * props.pageSize) + props.pageSize).map(doc => (
                <StyledTableRowComponent hover tabIndex={-1} key={`documentList-${doc.id}`}>
                  <StyledTableCellTextComponent padding="none">
                    <strong>{decodeURI(doc.name)}</strong>
                  </StyledTableCellTextComponent>
                  
                  <StyledTableCellTextComponent padding="none">
                    <IntlMessageComponent id={`general.language.${doc.culture}`} />
                  </StyledTableCellTextComponent>
                  
                  <StyledTableCellTextComponent padding="none">
                    <Tooltip title={<IntlMessageComponent id="component.iso.commercial.table.button.download.tooltip" />}>
                      <IconButton onClick={() => props.downloadDocument(doc.culture, doc.name)}>
                        <Download />
                      </IconButton>
                    </Tooltip>
                  </StyledTableCellTextComponent>
                </StyledTableRowComponent>
              ))}
            </TableBody>
          </Table>
        </Box>

        <Box display="flex" height={60}>
          <Box flexGrow={1}></Box>
          <Box justifyContent="flex-end">
            <TablePagination
              component="div"
              count={props.documents.length}
              data-walkme="isocommercial-field-documentsperpage"
              page={props.page}
              rowsPerPage={props.pageSize}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePageSizeChange}
              labelRowsPerPage={<IntlMessageComponent id="general.commercialPerPage" />}
              labelDisplayedRows={({from, to, count}) => `${from}-${to} / ${count}`}
            />
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}
