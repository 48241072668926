/* @flow */

import SetCompanyInfoRequest from 'models/requests/isos/registerSellers/setCompanyInfoRequest';

export default class SetCompanyInfoForm {
  isin: string;
  phone: string;

  activities: string;
  mainActivityCountryId: number;
  sourceOfFunds: string;

  bankCountryId: number;
  bankIdentifier: string;
  bankAccount: string;

  mandateAmount: number;

  constructor() {
    this.isin = '';
    this.phone = '';

    this.activities = '';
    this.mainActivityCountryId = 0;
    this.sourceOfFunds = '';

    this.bankAccount = '';
    this.bankIdentifier = '';
    this.bankCountryId = 0;

    this.mandateAmount = 0;
  }

  toRequest(isoCompanyId: number, companyId: number) {
    const request = new SetCompanyInfoRequest();
    request.isoCompanyId = isoCompanyId;
    request.companyId = companyId;

    request.phone = this.phone;
    request.isin = this.isin;

    request.activities = this.activities;
    request.mainActivityCountryId = this.mainActivityCountryId;
    request.sourceOfFunds = this.sourceOfFunds;

    request.bankAccount = this.bankAccount;
    request.bankIdentifier = this.bankIdentifier;
    request.bankCountryId = this.bankCountryId;

    request.mandateAmount = this.mandateAmount;
    return request;
  }
}
