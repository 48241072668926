/* @flow */

import React from 'react';
import {useParams} from 'react-router-dom';

import DebtorInvoiceDetailsComponent from 'app/debtors/invoiceDetails';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function DebtorInvoiceDetailsPage() {
  const {id} = useParams();

  return (
    <WebsitePageComponent noPaper noTitle>
      <DebtorInvoiceDetailsComponent invoiceId={id} />
    </WebsitePageComponent>
  );
}
