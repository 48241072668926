/* @flow */

import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';

import {userSwitchCompanyInvestmentProfileAction} from 'store/actions/authActions';

type InvestmentProfileCheckButtonComponentProps = {
  color: string;
  disabled: boolean;
  id: string;
  targetInvestmentProfileId: number;
  action: () => void;
}

export default function InvestmentProfileCheckButtonComponent(props: InvestmentProfileCheckButtonComponentProps) {
  const activeCompany = useSelector(state => state.auth.user.activeCompany);
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);

  let targetProfile = null;
  if (props.targetInvestmentProfileId) {
    targetProfile = activeCompany.roleBuyer.investmentProfiles.find(ip => ip.id === props.targetInvestmentProfileId) || null;
  }

  const currentProfile = activeCompany.investmentProfile;

  const placeholders = {
    currentProfileName: currentProfile.name,
    targetProfileName: targetProfile?.name
  }

  const onClick = () => {
    if (activeCompany.roleBuyer.investmentProfiles.length <= 1 || !targetProfile) {
      props.action();
      return;
    }

    if (activeCompany.investmentProfile.id === targetProfile.id) {
      props.action();
      return;
    }

    setShowPopup(true);
  };

  const onClickAccept = () => {
    dispatch(userSwitchCompanyInvestmentProfileAction(targetProfile));
  }
  
  return (
    <React.Fragment>
      <ButtonComponent
        color={props.color}
        variant="contained"
        disabled={props.disabled}
        onClick={onClick}
      >
        <IntlMessageComponent id={props.id} />
      </ButtonComponent>

      {showPopup && 
        <Dialog open onClose={() => setShowPopup(false)}>
          <DialogTitle><IntlMessageComponent id="buyer.invoiceDetails.wrongIP.title" /></DialogTitle>
          <DialogContent>
            <DialogContentText>
              <IntlMessageComponent id="buyer.invoiceDetails.wrongIP.text" values={placeholders} />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClickAccept} color="primary">
              <IntlMessageComponent id="buyer.invoiceDetails.wrongIP.btn.switch" />
            </Button>
            <Button onClick={() => setShowPopup(false)} color="primary" autoFocus>
              <IntlMessageComponent id="general.cancel" />
            </Button>
          </DialogActions>
        </Dialog>
      }
    </React.Fragment>
  )
}
