/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';

import ComponentWrapperComponent from 'components/websiteStructure/componentWrapper';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import UploadButtonComponent from 'components/buttons/uploadButton';
import IntlMessageComponent from 'components/formatters/intlMessage';

import DocumentType from 'models/documents/documentType';
import MandatoryNotInlineFormFieldsComponent from 'components/form/mandatorynotinline';

type CompanyDocumentsUploadViewComponentProps = {
  isProcessing: boolean;
  documentTypes: DocumentType[];
  selectedDocumentType: DocumentType;
  handleFail: () => void;
  uploadFile: () => void;
  handleDocumentTypeChange: () => void;
}

export default function CompanyDocumentsUploadViewComponent(props: CompanyDocumentsUploadViewComponentProps) {
  return (
    <ComponentWrapperComponent>
      <PageSubTitleViewComponent id="component.company.documents.title" />
      <Grid container direction="row" justify="flex-start" alignItems="flex-start">
        <Grid item xs={12} md={6}>
          <Box mt={2}>
            <InputLabel><IntlMessageComponent id="component.company.documenttype.title" /></InputLabel>
            <Select
              style={{ minWidth: 286 }}
              labelId={<IntlMessageComponent id="component.company.documenttype.title" />}
              id="select-document-type-dropdown"
              value={props.selectedDocumentType}
              onChange={props.handleDocumentTypeChange}
            >
              {props.documentTypes.map((doc) => {
                return (
                  <MenuItem value={doc}><IntlMessageComponent id={`document.type.${doc.code}`} /></MenuItem>
                );
              })}
            </Select>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box mt={3} ml={2}>
            <UploadButtonComponent
              disabled={props.isProcessing || !props.selectedDocumentType}
              label="page.documents.form.button.upload"
              successHandler={props.uploadFile}
              failHandler={props.handleFail}
              hideMandatory={true}
            />
          </Box>
        </Grid>
      </Grid>
      <Box mt={1}>
        <MandatoryNotInlineFormFieldsComponent id="general.file.accept" values={{ accept: "application/pdf, image/jpeg, image/png", size: 5 }} />
      </Box>
    </ComponentWrapperComponent>
  );
}