/* @flow */

import {connect} from 'react-redux';
import TypeFieldComponent from './typeImpl';
import { DEBTOR_ROLE_ADMINISTRATOR, DEBTOR_ROLE_COMMERCIAL } from 'constants/constants';

const mapStateToProps = (state, props) => ({
  ...props,
  isLoading: state.locale.genericDataIsLoading,
  values: props.isAdministrator || props.hasProcuration ? state.locale.rolesForDebtors :
          props.hasAdministratorRole ? state.locale.rolesForDebtors.filter(r => r.id === DEBTOR_ROLE_COMMERCIAL || r.id === DEBTOR_ROLE_ADMINISTRATOR) :
          state.locale.rolesForDebtors.filter(r => r.id === DEBTOR_ROLE_COMMERCIAL)
});

export default connect(mapStateToProps)(TypeFieldComponent);
