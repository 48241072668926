/* @flow */

import axios from 'axios/index';

import {ApiError, ApiSignInError, ApiValidationError} from './apiErrors';

import {API_URL} from 'constants/config';
import {USER_AUTHENTICATE, GENERIC_GET_WEBSITE_LANGUAGES} from 'constants/apiRoutes';

function getLocaleFromLocalStorage() {
  const locale = localStorage.getItem('locale');

  if (!locale) {
    throw new Error('No locale in local storage');
  }

  return locale;
}

// very simple Api methods with header
export class Api {
  _headers() {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    };
  }

  _createRequest(method: string, url: string, parameters: any) {
    const request = Object.assign({
      baseURL: API_URL,
      url: url,
      method: method,
      headers: {}
    }, parameters);
    request.headers = Object.assign({}, this._headers(), parameters.headers);

    if (url !== GENERIC_GET_WEBSITE_LANGUAGES) {
      request.headers['Accept-Language'] = getLocaleFromLocalStorage();
    }

    return request;
  }

  _executeRequest = async (request: any) => {
    try {
      return await axios.request(request);
    } catch (error) {
      if (request.url === USER_AUTHENTICATE) {
        throw new ApiSignInError(error.response.statusText, error.response.status, error.response.data.error);
      }
      else if (error.response.status === 400) {
        const errors = error.response.data.errors.map(e => e.value);
        throw new ApiValidationError(error.response.statusText, error.response.status, error.response.data.exceptionId, errors);
      }

      console.error(`ERROR: ${error.response.statusText} - ${error.response.status}`);
      if (error.response.data.errors && error.response.data.errors.length > 0) {
        console.error(`MESSAGE: ${error.response.data.errors[0].value}`);
        console.error(`STACKTRACE: ${error.response.data.errors[0].description}`);
      }

      throw new ApiError(error.response.statusText, error.response.status, error.response.data.exceptionId);
    }
  }

  // TODO: TEST
  get = async (route: string, parameters: any) => {
    const request = this._createRequest('get', route, parameters);
    return await this._executeRequest(request);
  }

  // TODO: TEST
  getFile = async (route: string) => {
    const request = this._createRequest('get', route, {});
    request.responseType = 'blob';
    request.headers.Accept = 'application/pdf, image/jpeg, image/png';
    return await axios(request);
  }

  post = async (route: string, parameters: any) => {
    const request = this._createRequest('post', route, parameters);
    if (request.url === USER_AUTHENTICATE) {
      request.headers['content-type'] = 'application/x-www-form-urlencoded';
    }
    return await this._executeRequest(request);    
  }

  // TODO: TEST
  postFile = async (route: string, formData: FormData) => {
    const request = this._createRequest('post', route, {});
    request.headers['content-type'] = 'multipart/form-data';
    request.data = formData;
    return await this._executeRequest(request);
  }

  // TODO: TEST
  put = async (route: string, parameters: any) => {
    const request = this._createRequest('put', route, parameters);
    return await this._executeRequest(request);    
  }

  // TODO: TEST
  delete = async (route: string, parameters: any) => {
    const request = this._createRequest('delete', route, parameters);
    return await this._executeRequest(request);    
  }
}

export class ApiToken extends Api {
  _headers() {
    const token = localStorage.getItem('token') || '';
    return {
      ...super._headers(),
      Authorization: `Bearer ${token}`
    };
  }
}
