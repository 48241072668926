/* @flow */

import RefuseInvoiceRequest from 'models/requests/debtors/refuseInvoiceRequest';

export default class RefuseInvoiceForm {
  reason: string;

  constructor() {
    this.reason = '';
  }

  toRequest(companyId: number, invoiceId: number){
    const request = new RefuseInvoiceRequest();
    request.companyId = companyId;
    request.invoiceId = invoiceId;
    request.reason = this.reason;
    return request;
  }
}
