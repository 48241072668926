/* @flow */

import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';

import * as reactGA from 'lib/analytics';

import FirstCallDialogViewComponent from './view';

import PresentationRequest from 'models/requests/register/presentationRequest';

import RegisterService from 'services/registerService';
import NotificationHelper from 'lib/notifications';
import {handleApiFormResponse} from 'lib/forms';

type FirstCallDialogComponentProps = {
  activeCompanyId: number;
  activeCompanyRole: number;
  skipHandler: () => void;
  successHandler: () => void;
}

type FirstCallDialogComponentState = {
  formErrors: string[];
  isProcessing: boolean;
  maxDate: moment;
  presentationDate: moment;
};

class FirstCallDialogComponent extends React.Component<FirstCallDialogComponentProps, FirstCallDialogComponentState> {

  constructor(props) {
    super(props);

    let defaultDate = moment().add(1, 'd').startOf('d').add(10, 'h');
    if (defaultDate.isoWeekday() === 6) {
      defaultDate = defaultDate.add(2, 'd');
    } else if (defaultDate.isoWeekday() === 7) {
      defaultDate = defaultDate.add(1, 'd');
    }

    this.state = {
      formErrors: [],
      isProcessing: false,
      maxDate: moment().startOf('d').add(1, 'M'),
      presentationDate: defaultDate
    };
  }

  handleDateChange = (date: moment) => this.setState({presentationDate: date});

  submitForm = async () => {
    this.setState({isProcessing: true});

    try {
      this.setState({formErrors: []});
      const request = new PresentationRequest(this.props.activeCompanyId, this.props.activeCompanyRole, this.state.presentationDate);
      await RegisterService.requestPresentation(request);

      NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS, 'notifications.firstcall.success');

      reactGA.initializeGA();
      reactGA.logEvent('Subscription', 'First Call Request Sent', `Company ID: ${this.props.activeCompanyId}`);
      
      this.props.successHandler();
    } catch (e) {
      handleApiFormResponse.call(this, e);
      console.error(e);
    }
  };


  shouldDisableDate = (date: moment) => date.isoWeekday() > 5;

  render() {
    return (
      <FirstCallDialogViewComponent
        formErrors={this.state.formErrors}
        isProcessing={this.state.isProcessing}
        maxDate={this.state.maxDate}
        presentationDate={this.state.presentationDate}
        handleDateChange={this.handleDateChange}
        skipHandler={this.props.skipHandler}
        shouldDisableDate={this.shouldDisableDate}
        submitForm={this.submitForm}
      />
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id,
  activeCompanyRole: state.auth.user.activeCompany.role
});

export default connect(mapStateToProps)(FirstCallDialogComponent);
