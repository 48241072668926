/* @flow */

import WebsiteLanguage from 'models/generics/websiteLanguage';

export function getLocale(localeName: string, websiteLanguages: WebsiteLanguage[]) {
    // get language from parameters
    if (localeName) {
      localeName = localeName.replace("_", "-");

      const websiteLanguage = websiteLanguages.find(wsLang => wsLang.locale === localeName);
      if (websiteLanguage) {
        console.debug('Locale from parameters:', websiteLanguage.locale);
        return websiteLanguage.locale;
      }
    }

    // get language from localeStorage
    let localeFromStorage = localStorage.getItem('locale');

    if (localeFromStorage) {
      if (typeof localeFromStorage !== 'string') {
        localStorage.removeItem('locale');
      }
      else if (localeFromStorage.startsWith('{')) {
        localStorage.removeItem('locale');
      }
      else {
        console.debug('Locale from LocalStorage %O', localeFromStorage);
        return localeFromStorage;
      }
    }

    // get language from navigator
    if (navigator.language) {
      const websiteLanguage = websiteLanguages.find(wsLang => wsLang.locale === navigator.language);
      if (websiteLanguage) {
        console.debug('Locale from browser: ', websiteLanguage.locale);
        return websiteLanguage.locale;
      }
    }

    // still not found, let's take the one set to default
    const websiteLanguage = websiteLanguages.find(wsLang => wsLang.isDefault);
    console.debug('Default language: ', websiteLanguage.locale);
    return websiteLanguage.locale;
};
