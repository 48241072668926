/* @flow */

export default class LinkBankAccountToSpecificRequest {
    companyId: number;
    supplierId: number;
    bankAccountId: number;
  
    constructor(companyId: number, supplierId: number, bankAccountId: number) {
      this.companyId = companyId;
      this.supplierId = supplierId;
      this.bankAccountId = bankAccountId;
    }
  }
  
  