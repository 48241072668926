/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import DebtorInvoicesToPayPopupViewComponent from './view';

import PayInvoiceRequest from 'models/requests/debtors/payInvoiceRequest';

import DebtorService from 'services/DebtorService';
import {handleApiFormResponse} from 'lib/forms';
import NotificationHelper from 'lib/notifications';

type DebtorInvoicesToPayPopupComponentProps = {
  activeCompanyId: number;
  invoiceIds: number[];
  handleCancel: () => void;
  handleSuccess: () => void;
}

type DebtorInvoicesToPayPopupComponentState = {
  formErrors: string[];
  isProcessing: boolean;
}

class DebtorInvoicesToPayPopupComponent extends React.Component<DebtorInvoicesToPayPopupComponentProps, DebtorInvoicesToPayPopupComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      formErrors: [],
      isProcessing: false
    };
  }

  submitForm = async () => {
    this.setState({formErrors: [], isProcessing: true});

    try {
      const request =new PayInvoiceRequest(this.props.activeCompanyId, this.props.invoiceIds);
      await DebtorService.payInvoice(request);
      NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS, 'notifications.invoice.pay.success');
      await this.props.handleSuccess();
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }
  };

  render() {
    return (
      <DebtorInvoicesToPayPopupViewComponent
        isProcessing={this.state.isProcessing}
        handleCancel={this.props.handleCancel}
        submitForm={this.submitForm}
      />
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps)(DebtorInvoicesToPayPopupComponent);
