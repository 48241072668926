/* @flow */

import React from 'react';
import {useSelector} from 'react-redux';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';

import ButtonComponent from 'components/buttons/button';
import CheckboxFieldComponent from 'components/formFields/checkbox';
import CountryFieldBuyersComponent from 'components/formFields/countries/buyers';
import CountryFieldSellersComponent from 'components/formFields/countries/sellers';
import FormErrorsComponent from 'components/formErrors';
import IntlMessageComponent from 'components/formatters/intlMessage';

import BankAccountFormModel from './model';

import {ROLE_BUYER} from 'constants/companyRoles';

type CompanyBankAccountsFormDialogStep1ViewComponentProps = {
  errors: Map<string, ?string>;
  form: BankAccountFormModel;
  formErrors: string[];
  requireRibDocument: boolean;
  handleChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void;
  handleBooleanChange: (fieldName: string) => (value: boolean) => void;
  handleTypeChange: (fieldName: string) => (id: number) => void;
  onClose: () => void;
  onSubmit: () => void;
}

export default function CompanyBankAccountsFormDialogStep1ViewComponent(props: CompanyBankAccountsFormDialogStep1ViewComponentProps) {
  const activeCompanyRole = useSelector(state => state.auth.user.activeCompany.role);

  return (
    <React.Fragment>      
      <DialogContent>
        <FormErrorsComponent formErrors={props.formErrors} />

        {activeCompanyRole === ROLE_BUYER ? (
          <CountryFieldBuyersComponent
            id="form.bankCountry"
            label={<IntlMessageComponent id="form.bankCountry.label" />}
            error={!!props.errors.get('bankCountryId')}
            helperText={props.errors.get('bankCountryId') ?
              <IntlMessageComponent id={props.errors.get('bankCountryId')} /> : ''}
            value={props.form.bankCountryId}
            changeHandler={props.handleTypeChange('bankCountryId')}
            fullWidth
            margin="normal"
            required
          />
        ) : (
          <CountryFieldSellersComponent
            id="form.bankCountry"
            label={<IntlMessageComponent id="form.bankCountry.label" />}
            error={!!props.errors.get('bankCountryId')}
            helperText={props.errors.get('bankCountryId') ?
              <IntlMessageComponent id={props.errors.get('bankCountryId')} /> : ''}
            value={props.form.bankCountryId}
            changeHandler={props.handleTypeChange('bankCountryId')}
            fullWidth
            margin="normal"
            required
          />
        )}
            
        <TextField
          id="form.bic"
          data-walkme="subscription-field-bic"
          label={<IntlMessageComponent id="form.bankIdentifier.label" />}
          error={!!props.errors.get('bankIdentifier')}
          helperText={(props.errors.get('bankIdentifier')) ?
            <IntlMessageComponent id={props.errors.get('bankIdentifier')} /> : ''}
          value={props.form.bankIdentifier}
          onChange={props.handleChange('bankIdentifier')}
          fullWidth
          margin="normal"
          required
        />
        
        <TextField
          id="form.iban"
          data-walkme="subscription-field-iban"
          label={<IntlMessageComponent id="form.bankAccount.label" />}
          error={!!props.errors.get('bankAccount')}
          helperText={(props.errors.get('bankAccount')) ?
            <IntlMessageComponent id={props.errors.get('bankAccount')} /> : ''}
          value={props.form.bankAccount}
          onChange={props.handleChange('bankAccount')}
          fullWidth
          margin="normal"
          required
        />
{/* 
        <CheckboxFieldComponent
          id="form.isMain"
          label={<IntlMessageComponent id="form.isMain" />}
          error={!!props.errors.get('isMain')}
          helperText={(props.errors.get('isMain')) ?
            <IntlMessageComponent id={props.errors.get('isMain')} /> : ''}
          value={props.form.isMain}
          changeHandler={props.handleBooleanChange('isMain')}
          fullWidth
          margin="normal"
        /> */}
      </DialogContent>

      <DialogActions>
        <ButtonComponent
          color="default"
          onClick={props.onClose}
        ><IntlMessageComponent id="general.cancel" /></ButtonComponent>

        <ButtonComponent
          color="primary"
          onClick={props.onSubmit}
        ><IntlMessageComponent id="general.submit" /></ButtonComponent>
      </DialogActions>
    </React.Fragment>
  );
}
