import validate from 'validate.js/validate';

import amountValidator from './validators/amountValidator';
import dateInLessThanXDaysValidator from './validators/dateInLessThanXDaysValidator';
import dateInMoreThanXDaysValidator from './validators/dateInMoreThanXDaysValidator';
import futureDateValidator from './validators/futureDateValidator';
import ibanValidator from './validators/ibanValidator';
import isLessThanSiblingValidator from './validators/isLessThanSiblingValidator';
import isMoreThanSiblingValidator from './validators/isMoreThanSiblingValidator';
import isRequiredBySiblingValidator from './validators/isRequiredBySiblingValidator';
import isRequiredByTwoSiblingsValidator from './validators/isRequiredByTwoSiblingsValidator';
import mandateAmountValidator from './validators/mandateAmountValidator';
import mobilePhoneNumberValidator from './validators/mobilePhoneNumberValidator';
import multiVatValidator from './validators/multiVatValidator';
import {oneIsMoreThan, totalLessOrEqualThan} from './validators/multiValuesValidators';
import pastDateValidator from './validators/pastDateValidator';
import percentageValidator from './validators/percentageValidator';
import phoneNumberValidator from './validators/phoneNumberValidator';
import sharesValidator from './validators/sharesValidator';

validate.validators.amountValidator = amountValidator;
validate.validators.dateInLessThanXDays = dateInLessThanXDaysValidator;
validate.validators.dateInMoreThanXDays = dateInMoreThanXDaysValidator;
validate.validators.iban = ibanValidator;
validate.validators.isLessThanSibling = isLessThanSiblingValidator;
validate.validators.isMoreThanSibling = isMoreThanSiblingValidator;
validate.validators.isRequiredBySibling = isRequiredBySiblingValidator;
validate.validators.isRequiredByTwoSiblings = isRequiredByTwoSiblingsValidator;
validate.validators.mandateAmountValidator = mandateAmountValidator;
validate.validators.mobilePhoneNumber = mobilePhoneNumberValidator;
validate.validators.multiVatValidator = multiVatValidator;
validate.validators.oneIsMoreThan = oneIsMoreThan;
validate.validators.pastDate = pastDateValidator;
validate.validators.futureDate = futureDateValidator;
validate.validators.percentageValidator = percentageValidator;
validate.validators.phoneNumber = phoneNumberValidator;
validate.validators.sharesValidator = sharesValidator;
validate.validators.totalLessOrEqualThan = totalLessOrEqualThan;

export const validateField = (value: any, constraint: any) => validate.single(value, constraint);
export const validateForm = (form: any, constraints: any) => validate.validate(form, constraints);
