/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import LoadingComponent from 'components/loading';
import SubmitInvoiceThankYouViewComponent from './view';

import InvoiceSummary from 'models/invoices/seller/invoiceSummary';
import PaymentInfo from 'models/payments/paymentInfo';

import InvoiceFeesPaymentRequest from 'models/requests/payments/invoiceFeesPaymentRequest';

import InvoiceSellerService from 'services/InvoiceSellerService';
import PaymentService from 'services/PaymentService';
import {history} from 'store/index';

import NotificationHelper from 'lib/notifications';
import { PROTECTED_APP } from 'constants/pageRoutes';

type SubmitInvoiceThankYouComponentProps = {
  invoiceId: number;
  handleNewInvoiceCreation: () => void;
}

type SubmitInvoiceThankYouComponentState = {
  invoice: InvoiceSummary;
  isLoading: boolean;
  isProcessing: boolean;
  paymentInfo: PaymentInfo;
  paymentMethod: string;
}

class SubmitInvoiceThankYouComponent extends React.Component<SubmitInvoiceThankYouComponentProps, SubmitInvoiceThankYouComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      invoice: null,
      isLoading: true,
      isProcessing: false,
      paymentInfo: null
    };
  }

  componentDidMount = async () => {
    try {
      const invoicePromise = InvoiceSellerService.getInvoiceSummary(this.props.invoiceId);
      const results = await Promise.all([invoicePromise]);
      this.setState({
        invoice: results[0],
        isLoading: false
      });
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  }

  redirectToDashboard = () => {
    history.push(`${PROTECTED_APP}`);
  }

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <SubmitInvoiceThankYouViewComponent
        invoice={this.state.invoice}
        isProcessing={this.state.isProcessing}
        paymentInfo={this.state.paymentInfo}
        paymentMethod={this.state.paymentMethod}
        redirectToDashboard={this.redirectToDashboard}
        handleNewInvoiceCreation={this.props.handleNewInvoiceCreation}
      />
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps)(SubmitInvoiceThankYouComponent);
