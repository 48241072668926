/* @flow */

import {connect} from 'react-redux';
import TypeFieldComponent from './typeImpl';

function setValues(state, props) {
  let values = state.locale.registrationTypes;
  /* Remove Resident option from list when personCountryId != Belgium, Spain, France, French Guiana, Guadeloupe, Martinique, Mayotte or Réunion */
  if (props.countryId !== 93 &&
      props.countryId !== 144 &&
      props.countryId !== 146 &&
      props.countryId !== 1959 &&
      props.countryId !== 1958 &&
      props.countryId !== 1956 &&
      props.countryId !== 1963 &&
      props.countryId !== 1955) {
    const newValues = values.filter(function(item){ return item["id"] !== 1929 })
    values = newValues;
  };

  return values;
}

const mapStateToProps = (state, props) => ({
  ...props,
  isLoading: state.locale.genericDataIsLoading,
  values: setValues(state, props)
});

export default connect(mapStateToProps)(TypeFieldComponent);
