/* @flow */

import React from 'react';
import {useLocation} from 'react-router-dom';
import * as reactGA from 'lib/analytics';
import * as reactGA4 from 'lib/analyticsga4';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';

import AppWrapperComponent from 'components/websiteStructure/appWrapper';
import PageTitleComponent from 'components/pageTitle';

type WebsitePageComponentProps = {
  backButton: boolean;
  fluidContainer: boolean;
  id: string;
  noPaper: boolean;
  noTitle: boolean;
}

export default function WebsitePageComponent(props: WebsitePageComponentProps) {
  let location = useLocation();
  reactGA.initializeGA();
  reactGA.logPageView(location.pathname);
  reactGA4.initializeGA();
  reactGA4.logPageView(location.pathname);

  return (
    <AppWrapperComponent>
      <Container maxWidth={!props.fluidContainer ? "xl" : false}>
        {!props.noTitle &&
          <PageTitleComponent id={props.id} data-walkme={props['data-walkme'] || ''} backButton={props.backButton} />
        }

        {props.noPaper ? (
          <React.Fragment>
            {props.children}
          </React.Fragment>
        ) : (
          <Paper elevation={0}>
            <Box p={5}>
              {props.children}
            </Box>
          </Paper>
        )}
      </Container>
    </AppWrapperComponent>
  );
}
