/* @flow */

import React from 'react';
import {useSelector} from 'react-redux';

import Box from '@material-ui/core/Box';

import CompanyItemComponent from 'components/company';

import CompanyLookupBaseComponent from '../base';

import {SELLING_TYPE_ISO_BUYER} from 'constants/sellingTypeISO';

type CompanyLookupISOComponentProps = {
  company: Company;
  companyErrorLabelId: string;
  required: boolean;
  handleSelect: (company: Company) => void;
}

export default function CompanyLookupISOComponent(props: CompanyLookupISOComponentProps) {
  const activeCompanySellingTypeISO = useSelector(state => state.auth.user.activeCompany.roleISO.sellingTypeISO);
  const countriesForBuyers = useSelector(state => state.locale.countriesForBuyers);
  const countriesForSellers = useSelector(state => state.locale.countriesForSellers);

  let countries = [];
  if (activeCompanySellingTypeISO === SELLING_TYPE_ISO_BUYER) {
    countries = countriesForBuyers;
  } else {
    countries = countriesForSellers;
  }

  if (props.company) {
    return (
      <Box px={4} py={3} bgcolor="lightblue.main">
        <CompanyItemComponent
          buttonLabelId="component.offer.tab1.company.change"
          company={props.company}
          selected
          selectHandler={() => props.handleSelect(null)}
        />
      </Box>
    );
  }

  return (
    <CompanyLookupBaseComponent
      company={props.company}
      companyErrorLabelId={props.companyErrorLabelId}
      countries={countries}
      required={props.required}
      handleSelect={props.handleSelect}
    />
  );
}
