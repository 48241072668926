/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import IntlMessageComponent from 'components/formatters/intlMessage';
import SecurityComponent from 'components/security';

import FilterCompany from 'models/isos/filters/filterCompany';

type FilterISOComponentProps = {
  filters: FilterCompany[];
  companyLabel: string;
  officeLabel: string;
  pageName: string;
  personLabel: string;
  selectFilter: (filterId: string, optionId: number) => void;
}

type FilterISOComponentState = {
  showFilterCompany: boolean;
  showFilterOffice: boolean;
  showFilterPerson: boolean;
}

class FilterISOComponent extends React.Component<FilterISOComponentProps, FilterISOComponentState> {

  constructor(props) {
    super(props);
    this.state = {
      showFilterCompany: true,
      showFilterOffice: true,
      showFilterPerson: true
    };
  }

  onDisplayFilterCompanyToggle = () => {
    this.setState(state => ({showFilterCompany: !state.showFilterCompany}));
  }

  onDisplayFilterOfficeToggle = () => {
    this.setState(state => ({showFilterOffice: !state.showFilterOffice}));
  }

  onDisplayFilterPersonToggle = () => {
    this.setState(state => ({showFilterPerson: !state.showFilterPerson}));
  }

  getSortedCompanies = () => this.props.filters.sort((a, b) => (a.name < b.name ? -1 : 1));

  getActiveOfficesAndPersons = () => {
    const hasSelectedOneCompany = this.props.filters.find(c => c.isSelected) !== undefined;

    let offices = [];
    for (let i = 0; i < this.props.filters.length; i++) {
      const company = this.props.filters[i];
      if (!hasSelectedOneCompany || company.isSelected) {
        for (let j = 0; j < company.offices.length; j++) {
          if (offices.find(o => o.id === company.offices[j].id) === undefined) {
            offices.push(company.offices[j]);
          }
        }
      }
    }

    const hasSelectedOneOffice = offices.find(o => o.isSelected) !== undefined;
    let persons = [];
    for (let i = 0; i < offices.length; i++) {
      const office = offices[i];
      if (!hasSelectedOneCompany || !hasSelectedOneOffice || office.isSelected) {
        for (let j = 0; j < office.persons.length; j++) {
          if (persons.find(p => p.id === office.persons[j].id) === undefined) {
            persons.push(office.persons[j]);
          }
        }
      }
    }

    offices = offices.sort((a, b) => (a.name < b.name ? -1 : 1));
    persons = persons.sort((a, b) => (a.name < b.name ? -1 : 1));

    return {offices, persons};
  }

  render() {
    const companies = this.getSortedCompanies();
    const activeOfficesAndPersons = this.getActiveOfficesAndPersons();

    return (
      <React.Fragment>
        <SecurityComponent pageName={this.props.pageName} elementName="ISOCompanyFilter">
          <Grid item xs="auto">
            <Box pr={2} minWidth={200}>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  <b>{this.state.showFilterCompany ? '' : <span>&#x25B6;</span>}<IntlMessageComponent id={this.props.companyLabel} /></b>
                </FormLabel>
                <Box mb={5}>
                  <div className={companies.length > 5 ? 'table-responsive-material-vertical' : ''}>
                    {/* {this.state.showFilterCompany && <InputLabel id="demo-mutiple-checkbox-label"><IntlMessageComponent id={this.props.companyLabel} /></InputLabel>} */}
                    {this.state.showFilterCompany &&<Select
                      multiple
                      value={companies.filter(company => company.isSelected)}
                      renderValue={(selected) => selected.length > 1 ? <IntlMessageComponent id={'iso.filters.multipleselected'} /> : selected[0].name}
                      style={{minWidth: "15vw", maxWidth: "15vw"}}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left"
                        },
                        getContentAnchorEl: null
                      }}
                    >
                      {companies.map(company => (
                        <MenuItem
                          onClick={() => {
                            this.props.selectFilter('company', company.id)
                          }}
                          value={company.id}
                        >
                          <Checkbox checked={company.isSelected} />
                          <ListItemText primary={company.name} />
                        </MenuItem>
                      ))}
                    </Select>}
                  </div>
                </Box>
              </FormControl>
            </Box>
          </Grid>
        </SecurityComponent>

        <SecurityComponent pageName={this.props.pageName} elementName="ISOCompanyOfficeFilter">
          <Grid item xs="auto">
            <Box pl={2} pr={2} minWidth={200}>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  <b>{this.state.showFilterOffice ? '' : <span>&#x25B6;</span>}<IntlMessageComponent id={this.props.officeLabel} /></b>
                </FormLabel>
                <Box mb={5}>
                  <div className={activeOfficesAndPersons.offices.length > 5 ? 'table-responsive-material-vertical' : ''}>
                    {/* {this.state.showFilterOffice && <InputLabel id="demo-mutiple-checkbox-label"><IntlMessageComponent id={this.props.officeLabel} /></InputLabel>} */}
                    {this.state.showFilterOffice && <Select
                      multiple
                      value={activeOfficesAndPersons.offices.filter(office => office.isSelected)}
                      renderValue={(selected) => selected.length > 1 ? <IntlMessageComponent id={'iso.filters.multipleselected'} /> : selected[0].name}
                      style={{minWidth: "15vw", maxWidth: "15vw"}}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left"
                        },
                        getContentAnchorEl: null
                      }}
                    >
                      {activeOfficesAndPersons.offices.map(office => (
                        <MenuItem
                          onClick={() => {
                            this.props.selectFilter('office', office.id)
                          }}
                          value={office.id}
                        >
                          <Checkbox checked={office.isSelected} />
                          <ListItemText primary={office.name} />
                        </MenuItem>
                      ))}
                    </Select>}
                  </div>
                </Box>
              </FormControl>
            </Box>
          </Grid>
        </SecurityComponent>

        <SecurityComponent pageName={this.props.pageName} elementName="ISOPersonFilter">
          <Grid item xs="auto">
            <Box pl={2} pr={4} minWidth={200}>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  <b>{this.state.showFilterPerson ? '' : <span>&#x25B6;</span>}<IntlMessageComponent id={this.props.personLabel} /></b>
                </FormLabel>
                <Box mb={5}>
                  <div className={activeOfficesAndPersons.persons.length > 5 ? 'table-responsive-material-vertical' : ''}>
                    {/* {this.state.showFilterPerson && <InputLabel id="demo-mutiple-checkbox-label"><IntlMessageComponent id={this.props.personLabel} /></InputLabel>} */}
                    {this.state.showFilterPerson && <Select
                      multiple
                      value={activeOfficesAndPersons.persons.filter(person => person.isSelected)}
                      renderValue={(selected) => selected.length > 1 ? <IntlMessageComponent id={'iso.filters.multipleselected'} /> : selected[0].name}
                      style={{minWidth: "15vw", maxWidth: "15vw"}}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left"
                        },
                        getContentAnchorEl: null
                      }}
                    >
                      {activeOfficesAndPersons.persons.map(person => (
                        <MenuItem
                          onClick={() => {
                            this.props.selectFilter('person', person.id)
                          }}
                          value={person.id}
                        >
                          <Checkbox checked={person.isSelected} />
                          <ListItemText primary={person.name} />
                        </MenuItem>
                      ))}
                    </Select>}
                  </div>
                </Box>
              </FormControl>
            </Box>
          </Grid>
        </SecurityComponent>
      </React.Fragment>
    );
  }
}

export default FilterISOComponent;
