/* @flow */

export default class RegisterReferralRequest {
  referrerCompanyId: number;

  insurerId: string;

  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  languageId: number;
}
