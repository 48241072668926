/* @flow */

export default class SetCompanyMembershipCouponRequest {
  companyId: number;
  reference: string;

  constructor(companyId: number, reference: string) {
    this.companyId = companyId;
    this.reference = reference;
  }
}
