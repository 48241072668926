/* @flow */

import CommissionMembership from './commissionMembership';

export default class CommissionMembershipIsoPerson {
  id: number;
  name: string;
  memberships: CommissionMembership[];
  membershipFeeCouponAmountUsed: number;
  membershipFee: number;
  membershipCommission: number;

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
    this.memberships = [];
    this.membershipFeeCouponAmountUsed = 0;
    this.membershipFee = 0;
    this.membershipCommission = 0;
  }
}
