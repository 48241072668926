/* @flow */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function IconMarketPlaceDelegatedInsurance(props) {
  return (
    <SvgIcon viewBox="0 0 61.9 70.5" {...props}>
      <path d="M26.7,58l-0.9,0.3c-0.4,0.1-0.8,0-1.1-0.1c-3.9-1.5-7.4-3.9-10.3-7c-4-3.9-7.2-8.6-9.1-13.8
	      c-0.8-2.1-1.2-4.3-1.3-6.5c-0.2-6.9,0-13.7-0.1-20.6c0-1,0.4-1.3,1.2-1.4c3.2-0.7,6.5-1.3,9.7-2.1c3.4-0.8,6.7-1.9,10.1-2.6
	      c1.1-0.2,2.3,0.3,3.4,0.6c4.6,1.2,9.2,2.4,13.8,3.6c1.2,0.3,2.5,0.5,3.7,0.6c0.9,0.1,1.1,0.5,1.1,1.3c0,3.7,0,7.4,0,11
	      c0,2.4-0.1,4.8,0,7.1c0.1,2.3-0.1,4.6-0.6,6.9h0.1c1.3,0,2.5,0.1,3.7,0.4c0-0.1,0.1-0.2,0.1-0.4c0.3-1.6,0.5-3.3,0.5-4.9
	      c0-7.4,0-14.8,0-22.2c0-2.3-0.4-2.7-2.6-2.9C44.1,4.9,40.3,4.2,36.5,3l-9.9-2.6c-0.2-0.1-0.5-0.2-0.7-0.4h-1
	      c-1.5,0.4-3.1,0.9-4.6,1.3C16.9,2.2,13.6,3.2,10.2,4C7.6,4.6,5,4.9,2.4,5.3C0.7,5.5,0.1,6,0.1,7.8c0,7.1,0.1,14.2,0,21.3
	      c0,5,1.2,9.8,3.7,14.1c3,5.5,7.1,10.4,12.1,14.2c2.1,1.5,4.3,2.8,6.6,4c1.6,0.8,3.5,1,5.2,0.3C27.2,60.6,26.9,59.3,26.7,58z"/>
      <g>
	      <path style={{strokeWidth:1,strokeMiterlimit:9.9997}} d="M45.4,70c-8.8,0-16-7.2-16-16s7.2-16,16-16s16,7.2,16,16S54.3,70,45.4,70z M45.4,39.4
  		    c-8.1,0-14.6,6.6-14.6,14.6c0,8.1,6.6,14.6,14.6,14.6S60.1,62.1,60.1,54c0,0,0,0,0,0C60.1,46,53.5,39.4,45.4,39.4
	      	C45.5,39.4,45.4,39.4,45.4,39.4L45.4,39.4z"/>
	      <path style={{strokeWidth:1,strokeMiterlimit:9.9997}} d="M47.4,50.5h-8.7c-0.5,0-0.9-0.1-0.9-0.7c0-0.6,0.3-0.7,0.9-0.7h8.7c0-0.9,0-1.8,0-2.7c0-0.3,0.2-0.7,0.4-0.9
		      c0.2-0.1,0.6,0,0.8,0.2c1.6,1.1,3.2,2.3,4.8,3.4c0.6,0.4,0.6,0.9,0,1.4c-1.6,1.2-3.2,2.3-4.8,3.5c-0.3,0.2-0.6,0.2-0.9,0.1
		      c-0.2-0.2-0.4-0.5-0.4-0.8C47.4,52.4,47.4,51.5,47.4,50.5z M48.9,52.1l3.1-2.3l-3.1-2.3V52.1z"/>
	      <path style={{strokeWidth:1,strokeMiterlimit:9.9997}} d="M42.8,57.6h8.5c0.2,0,0.4,0,0.5,0c0.3,0,0.6,0.3,0.6,0.6c0,0,0,0,0,0c0,0.3-0.2,0.6-0.5,0.7c0,0-0.1,0-0.1,0
		      c-0.6,0-1.2,0-1.9,0c-2.2,0-4.4,0-6.6,0c-0.5,0-0.6,0.1-0.6,0.6c0,0.7,0,1.5,0,2.2c0,0.3-0.2,0.6-0.4,0.8c-0.3,0.1-0.6,0-0.9-0.2
		      c-1.6-1.1-3.1-2.3-4.6-3.4c-0.7-0.5-0.7-0.9,0-1.5c1.5-1.1,3-2.3,4.6-3.4c0.3-0.2,0.6-0.3,0.9-0.2c0.2,0.2,0.4,0.5,0.4,0.8
		      C42.9,55.7,42.8,56.6,42.8,57.6z M41.4,55.9l-3.2,2.3l3.2,2.3V55.9z"/>
      </g>
      <path d="M38.6,22.7l-8.4,9.7c-1.5,1.8-3.1,3.6-4.7,5.3c-1,1.2-2,1.2-3.1,0.1c-2.3-2.3-4.6-4.5-6.8-6.8
	      c-0.8-0.6-0.9-1.8-0.3-2.6c0.1-0.1,0.1-0.1,0.2-0.2c0.7-0.7,1.8-0.7,2.5,0c0.1,0.1,0.1,0.1,0.2,0.2c1.9,1.9,3.8,3.8,5.7,5.8l7.4-8.4
	      c1.3-1.4,2.5-2.9,3.8-4.3c1.4-1.6,2.5-1.5,3.6,0.3V22.7z"/>
    </SvgIcon>
  );
}
