
/* @flow */

import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';

import AmountFormatterComponent from 'components/formatters/amount';
import ButtonComponent from 'components/buttons/button';
import BuyerInvoiceStatusComponent from 'components/invoiceStatus/buyer';
import CountryNameComponent from 'components/country/name';
import CountryFlagComponent from 'components/country/flag';
import DateFormatterComponent from 'components/formatters/date';
import DebtorGradeForBuyerComponent from 'components/debtorGrades/buyer';
import IconTrash from 'components/icons/trash';
import IntlMessageComponent from 'components/formatters/intlMessage';
import InvoiceMarketPlaceComponent from 'components/invoice/marketPlace';
import InvoiceNameComponent from 'components/invoice/name';
import PercentageFormatterComponent from 'components/formatters/percentage';
import StyledTableCellTextComponent from 'components/table/cellText';
import StyledTableCellNumbersComponent from 'components/table/cellNumbers';
import StyledTableHeadComponent from 'components/table/head';
import StyledTableRowComponent from 'components/table/row';
import StyledTableRowBuyerInvoiceComponent from 'components/table/rowBuyerInvoice';
import InvoiceBatchIconTooltipComponent from 'components/invoice/batchIcon';
import TooltipComponent from 'components/toolTips/index';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import { INVOICE_BUYER_TYPE_TO_BUY_ASSIGNED, INVOICE_BUYER_TYPE_TO_PAY } from 'constants/invoiceTypes';
import { PROTECTED_BUYER_INVOICE_DETAILS } from 'constants/pageRoutes';
import SecurityComponent from 'components/security/index';
import StyledTableCellNumbersMobileComponent from 'components/table/cellNumerMobile';
import StyledTableCellTextMobileComponent from 'components/table/cellTextMobile';

const columnData = [
  { id: 'type', align: 'left', translationKey: 'buyer.buy.status', walkme: 'bdashboard-column1-status' },
  { id: 'id', align: 'left', translationKey: 'buyer.buy.invoiceId', walkme: 'bdashboard-column1-nfacture' },
  { id: 'financedAmount', translationKey: 'buyer.buy.financedAmount', walkme: 'bdashboard-column1-montantfinance' },
  { id: 'purchasePrice', translationKey: 'buyer.buy.purchasePrice', walkme: 'bdashboard-column1-prixdachat' },
  { id: 'buyerInsuranceFee', translationKey: 'buyer.buy.buyerInsuranceFee' },
  { id: 'issueDate', align: 'left', translationKey: 'buyer.buy.issueDate', walkme: 'bdashboard-column1-emission' },
  { id: 'dueDate', align: 'left', translationKey: 'buyer.buy.dueDate', walkme: 'bdashboard-column1-echeance' },
  { id: 'discount', translationKey: 'buyer.buy.discountPct', walkme: 'bdashboard-column1-reduction' },
  { id: 'irr', translationKey: 'buyer.buy.irr', walkme: 'bdashboard-column1-retourannualise' },
  { id: 'debtorCompany.insurerRating', align: 'left', translationKey: 'buyer.buy.insurerRating', walkme: 'bdashboard-column1-gradefinancier' },
  { id: 'debtorCompany.countryId', align: 'left', translationKey: 'buyer.buy.debtorCountry', walkme: 'bdashboard-column1-paysclient' },
  { id: 'sellerCompany.countryId', align: 'left', translationKey: 'buyer.buy.sellerCountry', walkme: 'bdashboard-column1-paysvendeur' }
];
const columnMobileData = [
  { id: 'purchasePrice', align: 'left', translationKey: 'buyer.buy.invoice' },
];

type BuyerInvoicesToPurchaseTableViewComponentProps = {
  invoices: Invoice[];
  isProcessing: boolean;
  order: string;
  orderBy: string;
  page: number;
  pageSize: number;
  selected: number[];
  buyInvoices: () => void;
  handlePageChange: (page: number) => void;
  handlePageSizeChange: (pageSize: number) => void;
  handleSortOrderChange: (property: string) => void;
  refuseInvoice: (invoiceId: number) => void;
  selectInvoice: (invoiceId: number) => void;
};


export default function BuyerInvoicesToPurchaseTableViewComponent(props: BuyerInvoicesToPurchaseTableViewComponentProps) {
  const activeCompany = useSelector(state => state.auth.user.activeCompany);
  const isBuyerObserver = useSelector(state => state.auth.user.activeCompany.roleBuyer?.role == 3886);
  const history = useHistory();

  const handlePageChange = (_, page) => props.handlePageChange(page);
  const handlePageSizeChange = event => props.handlePageSizeChange(event.target.value);

  const isSelected = (id: number) => props.selected.indexOf(id) !== -1;
  const navigateToInvoiceDetails = id => history.push({ pathname: `${PROTECTED_BUYER_INVOICE_DETAILS}/${id}` });

  const handleClickCheckbox = (event: SyntheticMouseEvent<>, id: number) => {
    event.stopPropagation();
    props.selectInvoice(id);
  };

  const handleClickRefuse = (event: SyntheticMouseEvent<>, id: number) => {
    event.stopPropagation();
    props.refuseInvoice(id);
  };

  const pageTitle = props.invoices.some(i => i.type === INVOICE_BUYER_TYPE_TO_BUY_ASSIGNED) && props.invoices.some(i => i.type === INVOICE_BUYER_TYPE_TO_PAY) ? "buyer.dashboard.invoiceList.title.assignedandtopay" :
    props.invoices.some(i => i.type === INVOICE_BUYER_TYPE_TO_BUY_ASSIGNED) ? "buyer.dashboard.invoiceList.title.assigned" :
      props.invoices.some(i => i.type === INVOICE_BUYER_TYPE_TO_PAY) ? "buyer.dashboard.invoiceList.title.topay" :
        "buyer.dashboard.invoiceList.title"

  const selectedInvoices = props.invoices.filter(i => props.selected.indexOf(i.id) > -1);
  const selectedContainsBothAssignedAndToPay = selectedInvoices.some(i => i.type !== INVOICE_BUYER_TYPE_TO_PAY) && selectedInvoices.some(i => i.type === INVOICE_BUYER_TYPE_TO_PAY)
  const selectedTotal = selectedInvoices.reduce((total, currentInvoice) => {
    return total + currentInvoice.purchasePrice
  }, 0);

  return (
    <React.Fragment>

      <PageSubTitleViewComponent id={pageTitle} />
      <div className="hiddenMobile">

        <Box mt={3} component="div" overflow="auto">
          <Table>
            <StyledTableHeadComponent>
              <StyledTableRowComponent>
                <StyledTableCellTextComponent padding="checkbox" />

                {columnData.map(column => (
                  <StyledTableCellTextComponent align={column?.align ?? 'right'} key={column.id} padding="none">
                    <TableSortLabel
                      active={props.orderBy === column.id}
                      data-walkme={column.walkme || ''}
                      direction={props.order}
                      onClick={() => props.handleSortOrderChange(column.id)}
                    ><IntlMessageComponent id={column.translationKey} /></TableSortLabel>
                  </StyledTableCellTextComponent>
                ))}

                <StyledTableCellTextComponent padding="checkbox" />
              </StyledTableRowComponent>
            </StyledTableHeadComponent>

            <SecurityComponent checkActiveProfile checkRoleExecute>
              <TableBody bgcolor="white">
                {props.invoices.length === 0 &&
                  <StyledTableRowComponent>
                    <StyledTableCellTextComponent colSpan={columnData.length + 2}>
                      <Box textAlign="center">
                        <IntlMessageComponent id="general.noData" />
                      </Box>
                    </StyledTableCellTextComponent>
                  </StyledTableRowComponent>
                }

                {props.invoices.slice(props.page * props.pageSize, (props.page * props.pageSize) + props.pageSize).map(i => (
                  <StyledTableRowBuyerInvoiceComponent
                    key={`invList-${i.id}`}
                    hover
                    onClick={() => navigateToInvoiceDetails(i.id)}
                    isAssigned={i.type === INVOICE_BUYER_TYPE_TO_BUY_ASSIGNED}
                    isToBePaid={i.type === INVOICE_BUYER_TYPE_TO_PAY}
                  >
                    {!isBuyerObserver ? (
                      <StyledTableCellTextComponent padding="checkbox" onClick={event => handleClickCheckbox(event, i.id)}>
                        <Checkbox color="primary" checked={isSelected(i.id)} />
                      </StyledTableCellTextComponent>
                    ) : (
                      <StyledTableCellTextComponent padding="checkbox" />
                    )}

                    <StyledTableCellTextComponent padding="none">
                      <BuyerInvoiceStatusComponent invoiceType={i.type} />
                    </StyledTableCellTextComponent>

                    <StyledTableCellTextComponent padding="none">
                      <Box component="span" display="flex" alignItems="center">
                        <Box component="span" flexGrow={1} px={0}>
                          <InvoiceNameComponent invoiceId={i.id} invoiceName={i.invoiceName} />
                        </Box>
                        {i.isBatch && <InvoiceBatchIconTooltipComponent />}
                        <InvoiceMarketPlaceComponent marketPlaceId={i.marketPlaceId} />
                      </Box>
                    </StyledTableCellTextComponent>

                    <StyledTableCellNumbersComponent padding="none">
                      <AmountFormatterComponent amount={i.financedAmount} />
                    </StyledTableCellNumbersComponent>

                    <StyledTableCellNumbersComponent padding="none">
                      <b><AmountFormatterComponent amount={i.purchasePrice} /></b>
                    </StyledTableCellNumbersComponent>

                    <StyledTableCellNumbersComponent padding="none" pageName="Tooltip.Buyer.InvoiceColumn" elementName="BuyerInsuranceFee">
                      <TooltipComponent pageName="Tooltip.Buyer.InvoiceColumn" elementName="BuyerInsuranceFee">
                        <b><AmountFormatterComponent amount={i.buyerInsuranceFee} /></b>
                      </TooltipComponent>
                    </StyledTableCellNumbersComponent>

                    <StyledTableCellTextComponent padding="none">
                      <DateFormatterComponent date={i.issueDate} />
                    </StyledTableCellTextComponent>

                    <StyledTableCellTextComponent padding="none">
                      <b><DateFormatterComponent date={i.dueDate} /></b>
                    </StyledTableCellTextComponent>

                    <StyledTableCellNumbersComponent padding="none">
                      <PercentageFormatterComponent value={i.discountPct} />
                    </StyledTableCellNumbersComponent>

                    <StyledTableCellNumbersComponent padding="none">
                      <b><PercentageFormatterComponent value={i.irr} /></b>
                    </StyledTableCellNumbersComponent>

                    <StyledTableCellTextComponent padding="none">
                      <DebtorGradeForBuyerComponent value={i.debtorCompany.insurerRating} shortMessageWhenZero />
                      <b><PercentageFormatterComponent value={i.insuredPct} noFractionDigits /></b>
                    </StyledTableCellTextComponent>

                    <StyledTableCellTextComponent padding="none">
                      <b><CountryNameComponent countryId={i.debtorCompany.countryId} /></b>
                    </StyledTableCellTextComponent>

                    <StyledTableCellTextComponent padding="none">
                      <b><CountryNameComponent countryId={i.sellerCompany.countryId} /></b>
                    </StyledTableCellTextComponent>

                    <StyledTableCellTextComponent padding="checkbox">
                      {i.type === INVOICE_BUYER_TYPE_TO_BUY_ASSIGNED &&
                        <IconButton onClick={event => handleClickRefuse(event, i.id)}>
                          <IconTrash />
                        </IconButton>
                      }
                    </StyledTableCellTextComponent>
                  </StyledTableRowBuyerInvoiceComponent>
                ))}
              </TableBody>
            </SecurityComponent>
          </Table>

          {(!activeCompany.investmentProfile.isActive) &&
            <Box mt={3} px={4} textAlign="center">
              <Typography variant="body1">
                <span style={{ color: 'red' }}><IntlMessageComponent id="buyer.buy.profileInactive" /></span>
              </Typography>
            </Box>
          }

          {(activeCompany.investmentProfile.isActive && !activeCompany.roleBuyer.canExecute && !isBuyerObserver) &&
            <Box mt={3} px={4} textAlign="center">
              <Typography variant="body1">
                <IntlMessageComponent id="buyer.buy.cannotExecute" />
              </Typography>
            </Box>
          }
        </Box>
        {selectedInvoices.length > 0 &&
          <Box mt={1} px={1}>
            <b><IntlMessageComponent id="buyer.buy.total" /> <AmountFormatterComponent amount={selectedTotal} /></b>
          </Box>
        }

        <SecurityComponent pageName="POS.Buyers.InvoicesToPurchase" elementName="btnInvoiceBuy" checkActiveProfile checkRoleExecute>
          <Box display="flex" height={60}>
            <Box flexGrow={1} mt={2}>
              <ButtonComponent
                color="primary"
                variant="contained"
                disabled={props.isProcessing || props.selected.length < 1 || selectedContainsBothAssignedAndToPay}
                onClick={props.buyInvoices}
                data-walkme="bdashboard-button-acheterpayer"
              ><IntlMessageComponent id="buyer.buy.btn.buy" /></ButtonComponent>
            </Box>
            <Box justifyContent="flex-end">
              <TablePagination
                component="div"
                count={props.invoices.length}
                rowsPerPage={props.pageSize}
                page={props.page}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePageSizeChange}
                labelRowsPerPage={<IntlMessageComponent id="general.invoicePerPage" />}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
              />
            </Box>
          </Box>
        </SecurityComponent>
      </div>

      <div className="hiddenDesktop">
        <Box mt={3} component="div" overflow="auto">
          <Table>
            <StyledTableHeadComponent>
              <StyledTableRowComponent>
                <StyledTableCellTextComponent padding="checkbox" />

                {columnMobileData.map(column => (
                  <StyledTableCellTextMobileComponent align={column?.align ?? 'right'} key={column.id} padding="none">
                    <TableSortLabel
                      active={props.orderBy === column.id}
                      data-walkme={column.walkme || ''}
                      direction={props.order}
                      onClick={() => props.handleSortOrderChange(column.id)}
                    ><IntlMessageComponent id={column.translationKey} /></TableSortLabel>
                  </StyledTableCellTextMobileComponent>
                ))}

                <StyledTableCellTextMobileComponent padding="checkbox" />
              </StyledTableRowComponent>
            </StyledTableHeadComponent>

            <SecurityComponent checkActiveProfile checkRoleExecute>
              <TableBody bgcolor="white">
                {props.invoices.length === 0 &&
                  <StyledTableRowComponent>
                    <StyledTableCellTextMobileComponent colSpan={columnData.length + 2}>
                      <Box textAlign="center">
                        <IntlMessageComponent id="general.noData" />
                      </Box>
                    </StyledTableCellTextMobileComponent>
                  </StyledTableRowComponent>
                }

                {props.invoices.slice(props.page * props.pageSize, (props.page * props.pageSize) + props.pageSize).map(i => (
                  <StyledTableRowBuyerInvoiceComponent
                    key={`invList-${i.id}`}
                    hover
                    isAssigned={i.type === INVOICE_BUYER_TYPE_TO_BUY_ASSIGNED}
                    isToBePaid={i.type === INVOICE_BUYER_TYPE_TO_PAY}
                  >
                    {!isBuyerObserver ? (
                      <StyledTableCellTextMobileComponent padding="checkbox" onClick={event => handleClickCheckbox(event, i.id)}>
                        <Checkbox color="primary" checked={isSelected(i.id)} />
                      </StyledTableCellTextMobileComponent>
                    ) : (
                      <StyledTableCellTextMobileComponent padding="checkbox" />
                    )}

                    <StyledTableCellNumbersMobileComponent>
                      <BuyerInvoiceStatusComponent invoiceType={i.type} /><br />
                      <IntlMessageComponent id='buyer.buy.purchasePrice' />: <b><AmountFormatterComponent amount={i.purchasePrice} /></b><br />
                      <IntlMessageComponent id='buyer.buy.buyerInsuranceFee' />: <b><AmountFormatterComponent amount={i.buyerInsuranceFee} /></b><br />
                      <IntlMessageComponent id='buyer.buy.dueDate' />: <b><DateFormatterComponent date={i.dueDate} /></b><br />
                      <IntlMessageComponent id='buyer.buy.irr' />: <b><PercentageFormatterComponent value={i.irr} /></b><br />
                      <IntlMessageComponent id='buyer.buy.debtorCountry' />: <b><CountryFlagComponent countryId={i.debtorCompany.countryId} /></b><br />
                      <IntlMessageComponent id='buyer.buy.sellerCountry' />: <b><CountryFlagComponent countryId={i.sellerCompany.countryId} /></b><br /><br />
                      <IntlMessageComponent id='buyer.buy.insurerRating' />: <DebtorGradeForBuyerComponent value={i.debtorCompany.insurerRating} shortMessageWhenZero />
                      <b><PercentageFormatterComponent value={i.insuredPct} noFractionDigits /></b><br />
                    </StyledTableCellNumbersMobileComponent>

                    <StyledTableCellTextMobileComponent>
                    </StyledTableCellTextMobileComponent>

                    <StyledTableCellTextMobileComponent padding="checkbox">
                      {i.type !== INVOICE_BUYER_TYPE_TO_BUY_ASSIGNED &&
                        <IconButton onClick={event => handleClickRefuse(event, i.id)}>
                          <IconTrash />
                        </IconButton>
                      }
                    </StyledTableCellTextMobileComponent>
                  </StyledTableRowBuyerInvoiceComponent>
                ))}
              </TableBody>
            </SecurityComponent>
          </Table>

          {(!activeCompany.investmentProfile.isActive) &&
            <Box mt={3} px={4} textAlign="center">
              <Typography variant="body1">
                <span style={{ color: 'red' }}><IntlMessageComponent id="buyer.buy.profileInactive" /></span>
              </Typography>
            </Box>
          }

          {(activeCompany.investmentProfile.isActive && !activeCompany.roleBuyer.canExecute && !isBuyerObserver) &&
            <Box mt={3} px={4} textAlign="center">
              <Typography variant="body1">
                <IntlMessageComponent id="buyer.buy.cannotExecute" />
              </Typography>
            </Box>
          }
        </Box>
        {selectedInvoices.length > 0 &&
          <Box mt={1} px={1}>
            <b><IntlMessageComponent id="buyer.buy.total" /> <AmountFormatterComponent amount={selectedTotal} /></b>
          </Box>
        }

        <SecurityComponent pageName="POS.Buyers.InvoicesToPurchase" elementName="btnInvoiceBuy" checkActiveProfile checkRoleExecute>
          <div className="hiddenDesktop">
            <TablePagination
              component="div"
              count={props.invoices.length}
              rowsPerPage={props.pageSize}
              page={props.page}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePageSizeChange}
              labelRowsPerPage={""}
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
            />
            <Box mb={4}>
              <ButtonComponent
                color="primary"
                variant="contained"
                disabled={props.isProcessing || props.selected.length < 1 || selectedContainsBothAssignedAndToPay}
                onClick={props.buyInvoices}
                data-walkme="bdashboard-button-acheterpayer"
              ><IntlMessageComponent id="buyer.buy.btn.buy" /></ButtonComponent>
            </Box>
          </div>
        </SecurityComponent>
      </div>

    </React.Fragment>
  );
}
