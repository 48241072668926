/* @flow */


import React from 'react';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Link from '@material-ui/core/Link';
import Send from '@material-ui/icons/SendOutlined';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import FormErrorsComponent from 'components/formErrors';
import IntlMessageComponent from 'components/formatters/intlMessage';
import LinkMailToConstantComponent from 'components/constants/mailto';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import ChangeEmailForm from './model';

type SignUpTab3ViewComponentProps = {
  email: string;
  errors: Map<string, ?string>;
  form: ChangeEmailForm;
  formErrors: string[];
  isProcessing: boolean;
  showEmailField: boolean;
  displayEmailField: () => void;
  handleChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void;
  resendEmail: () => void;
  submitForm: () => void;
}

export default function SignUpTab3ViewComponent(props: SignUpTab3ViewComponentProps) {
  return (
    <React.Fragment>
      <PageSubTitleViewComponent id="page.signup.tab3.title" />
            
      <Typography variant="body1" gutterBottom>
        <IntlMessageComponent
          id="page.signup.tab3.text"
          values={{email: <Box component="span" color="primary.main">{props.email}</Box>}}
        />
      </Typography>

      <Box mt={3}>
        <Typography variant="body1" gutterBottom>
          <b><IntlMessageComponent id="page.signup.tab3.email.change" /></b>
        </Typography>
  
        <Typography variant="body1" gutterBottom>
          <Link
            component="button"
            variant="body1"
            disabled={props.isProcessing}
            onClick={props.displayEmailField}
          ><b><IntlMessageComponent id="page.signup.tab3.email.change.action" /></b></Link>
        </Typography>

        {props.showEmailField &&
          <Box>
            <FormErrorsComponent formErrors={props.formErrors} />

            <TextField
              id="form.email"
              label={<IntlMessageComponent id="form.email.label" />}
              error={!!props.errors.get('email')}
              helperText={(props.errors.get('email')) ?
                <IntlMessageComponent id={props.errors.get('email')} /> : ''}
              value={props.form.email}
              onChange={props.handleChange('email')}
              margin="normal"
              InputProps={{
                endAdornment: (props.isProcessing ?
                  <CircularProgress size={14} /> :
                  <InputAdornment position="end">
                    <IconButton onClick={props.submitForm}><Send color="primary" /></IconButton>
                  </InputAdornment>
                )}}
            />
          </Box>
        }

        <Box mt={3}>
          <Typography variant="body1" gutterBottom>
            <b><IntlMessageComponent id="page.signup.tab3.email.resend" /></b>
          </Typography>

          <Typography variant="body1" gutterBottom>
            <Link
              component="button"
              variant="body1"
              onClick={props.resendEmail}
            ><b><IntlMessageComponent id="page.signup.tab3.email.resend.action" /></b></Link>
          </Typography>
        </Box>

        <Box mt={5}>
          <h3><IntlMessageComponent id="page.signup.tab3.help" /></h3>

          <Typography variant="body1" gutterBottom>
            <IntlMessageComponent
              id="page.signup.tab3.help.text"
              values={{supportEmail: <LinkMailToConstantComponent id="general.email.support"/>}}
            />
          </Typography>
        </Box>
      </Box>
    </React.Fragment>
  );
}
