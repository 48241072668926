/* @flow */

import React, { useState } from 'react';

import customSort from 'lib/sortHelpers';

import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import AmountFormatterComponent from 'components/formatters/amount';
import DateFormatterComponent from 'components/formatters/date';
import DebtorGradeForBuyerComponent from 'components/debtorGrades/buyer';
import IntlMessageComponent from 'components/formatters/intlMessage';
import StyledTableCellTextComponent from 'components/table/cellText';
import StyledTableCellNumbersComponent from 'components/table/cellNumbers';
import StyledTableHeadComponent from 'components/table/head';
import StyledTableRowComponent from 'components/table/row';

import InvoiceReport from 'models/isos/invoices/invoiceReport';

const columnData = [
  { id: 'statusKey', translationKey: 'component.isos.invoices.list.header.status', walkme: 'isoinvoices-column-status', align: 'left' },
  { id: 'sellerCompany', translationKey: 'component.isos.invoices.list.header.sellerCompany', walkme: 'isoinvoices-column-sellercompany', align: 'left' },
  { id: 'debtorInsurerRating', translationKey: 'component.isos.invoices.list.header.debtorInsurerRating', walkme: 'isoinvoices-column-debtorrating', align: 'left' },
  { id: 'debtAmount', translationKey: 'component.isos.invoices.list.header.debtAmount', walkme: 'isoinvoices-column-amount', align: 'right' },
  { id: 'financedAmount', translationKey: 'component.isos.invoices.list.header.financedAmount', walkme: 'isoinvoices-column-financedamount', align: 'right' },
  { id: 'dueDate', translationKey: 'component.isos.invoices.list.header.dueDate', walkme: 'isoinvoices-column-duedate', align: 'left' },
  { id: 'serviceFee', translationKey: 'component.isos.invoices.list.header.serviceFee', walkme: 'isoinvoices-column-servicefee', align: 'right' },
  { id: 'invoiceId', translationKey: 'component.isos.invoices.list.header.invoiceId', walkme: 'isoinvoices-column-invoiceid', align: 'left' }
];

type InvoiceReportTableComponentProps = {
  invoices: InvoiceReport[];
}

export default function InvoiceReportTableComponent(props: InvoiceReportTableComponentProps) {
  const [state, setState] = useState({
    order: 'desc',
    orderBy: undefined,
    page: 0,
    pageSize: 10,
    dataLength: props.invoices.length
  });

  const handlePageChange = (_, page) => setState(prevState => ({ ...prevState, page }));
  const handlePageSizeChange = event => setState(prevState => ({ ...prevState, pageSize: event.target.value }));

  const handleSortOrderChange = (orderBy: string) => {
    const order = (state.orderBy === orderBy && state.order === 'desc') ? 'asc' : 'desc';
    setState(prevState => ({ ...prevState, order, orderBy }));
  };

  const sortInvoices = () => {
    const invoices = state.order === 'desc'
      ? props.invoices.sort((a, b) => customSort(b[state.orderBy], a[state.orderBy]))
      : props.invoices.sort((a, b) => customSort(a[state.orderBy], b[state.orderBy]));
    return invoices;
  }

  const invoices = sortInvoices();

  const resetPage = () => {
    if (state.dataLength !== props.invoices.length) {
      setState(prevState => ({ ...prevState, page: 0, dataLength: props.invoices.length }));
    }
  }
  const checkReset = resetPage();

  return (
    <React.Fragment>
      <Box py={5}>
        <Box component="div" overflow="auto">
          <Table>
            <StyledTableHeadComponent>
              <StyledTableRowComponent>
                {columnData.map(column => (
                  <StyledTableCellTextComponent key={column.id} align={column?.align ?? 'left'} padding="none" data-walkme={column.walkme || ''}>
                    <TableSortLabel active={state.orderBy === column.id} direction={state.order} onClick={() => handleSortOrderChange(column.id)}>
                      <IntlMessageComponent id={column.translationKey} />
                    </TableSortLabel>
                  </StyledTableCellTextComponent>
                ))}
              </StyledTableRowComponent>
            </StyledTableHeadComponent>

            <TableBody bgcolor="white">
              {!invoices.length &&
                <StyledTableRowComponent>
                  <StyledTableCellTextComponent colSpan={columnData.length}>
                    <Box textAlign="center">
                      <IntlMessageComponent id="component.isos.invoices.list.noData" />
                    </Box>
                  </StyledTableCellTextComponent>
                </StyledTableRowComponent>
              }

              {invoices.slice(state.page * state.pageSize, (state.page * state.pageSize) + state.pageSize).map(invoice => (
                <StyledTableRowComponent key={`invoice-${invoice.invoiceId}`}>
                  <StyledTableCellTextComponent padding="none"><IntlMessageComponent id={`invoice.status.${invoice.statusKey}`} /></StyledTableCellTextComponent>
                  <StyledTableCellTextComponent padding="none">{invoice.sellerCompany}</StyledTableCellTextComponent>
                  <StyledTableCellTextComponent padding="none"><DebtorGradeForBuyerComponent value={invoice.debtorInsurerRating} /></StyledTableCellTextComponent>
                  <StyledTableCellNumbersComponent padding="none"><AmountFormatterComponent amount={invoice.debtAmount} /></StyledTableCellNumbersComponent>
                  <StyledTableCellNumbersComponent padding="none"><AmountFormatterComponent amount={invoice.financedAmount} /></StyledTableCellNumbersComponent>
                  <StyledTableCellTextComponent padding="none"><DateFormatterComponent date={invoice.dueDate} /></StyledTableCellTextComponent>
                  <StyledTableCellNumbersComponent padding="none"><AmountFormatterComponent amount={invoice.serviceFee} /></StyledTableCellNumbersComponent>
                  <StyledTableCellTextComponent padding="none">{invoice.invoiceId}</StyledTableCellTextComponent>
                </StyledTableRowComponent>
              ))}
            </TableBody>
          </Table>

          <Box display="flex" height={60}>
            <Box flexGrow={1} />
            <Box justifyContent="flex-end">
              <TablePagination
                component="div"
                count={invoices.length}
                rowsPerPage={state.pageSize}
                page={state.page}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePageSizeChange}
                labelRowsPerPage={<IntlMessageComponent id="general.invoicesPerPage" />}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}
