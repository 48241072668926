/* @flow */

import React from 'react';

import LoadingComponent from 'components/loading';

import UnknownCompanyOfferViewComponent from './view';
import UnknownCompanyOfferSuccessViewComponent from './successView';
import OfferUCForm from './model';

import LeadService from 'services/LeadService';
import {handleApiFormResponse, handleFormChange, handleFormValidation} from 'lib/forms';
import loadConstraints from 'lib/validation/loadConstraints';

import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

type OfferComponentProps = {
  activeCompanyId: number;
  activeCompanyRole: number;
  languageId: number;
  countries: Country[];
};

type OfferComponentState = {
  constraints: any;
  errors: Map<string,?string>;
  activeStepId: number;
  form: OfferUCForm;
  isLoading: boolean;
  isProcessing: boolean;
  sentWithSuccess: boolean;
};

class CompanyNotFoundComponent extends React.Component<OfferComponentProps, OfferComponentState> {
  constructor(props) {
    super(props);

    this.state = {
      constraints: null,
      errors: null,
      form: new OfferUCForm(props.location.state?.data.firstName, props.location.state?.data.lastName, props.location.state?.data.mobile, props.location.state?.data.email, props.location.state?.data.titleId, props.location.state?.companyLookUpForm.country, props.location.state?.companyLookUpForm.name, props.location.state?.companyLookUpForm.vat),
      formErrors: [],
      isLoading: true,
      isProcessing: false,
      sentWithSuccess: false
    };
  }

  componentDidMount = async () => {
    try {
      const constraints = await loadConstraints('offeruc', 
        ['invoicesAvgAmountId', 'financingNeedId', 'financingNeedFrequencyId', 'invoicesYearlyAmountId', 'lastName', 'firstName', 'mobile', 'email', 'companyName', 'vat', 'country', 'issueInvoicesTo', 'invoicesConcerning', 'invoicesHigherThan5k', 'titleId']);
      
      this.setState({
        constraints,
        errors: new Map(Object.keys(constraints).map(e => [e, undefined])),
        isLoading: false
      });
    } catch (e) {
      console.error(e);
    }
  };

  handleCountryChange = (countryId: number) => {
    const country = this.props.countries.find(c => c.id === countryId);
    
    const form = this.state.form;
    form.country = country || null;

    this.setState({form});
  };

  handleChangeMultiple = (fieldName: string) => (event) => {
    const selected = event.target;
    let field = this.state.form[fieldName];
    if (selected.checked) {
      field.push(selected.value);
    } else {
      const index = field.indexOf(selected.value);
      if (index > -1) {
        field.splice(index, 1);
      }
    }
    handleFormChange.call(this, fieldName, field)
  };

  handleChange = (fieldName: string) => (event) => handleFormChange.call(this, fieldName, event.target.value);
  handleTypeIdChange = (fieldName: string) => (id: number) => handleFormChange.call(this, fieldName, id);
  handleMobileChange = (value: string) => {
    handleFormChange.call(this, 'mobile', value);
  }
  validateForm = () => handleFormValidation.call(this);

  submitForm = async () => {
    if (!this.validateForm()) return;
    this.setState({ formErrors: [], isProcessing: true });

    try {
      const request = this.state.form.toRequest(this.props.leadId);
      await LeadService.sendLeadOfferUnknownCompany(request);
      this.setState({ isProcessing: false, sentWithSuccess: true });
    } catch (e) {
      handleApiFormResponse.call(this, e);
      this.setState({ isProcessing: false });
    }
  };

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    if (this.state.sentWithSuccess) {
      return (<UnknownCompanyOfferSuccessViewComponent />);
    }

    return (
        <UnknownCompanyOfferViewComponent
          errors={this.state.errors}
          form={this.state.form}
          countries={this.props.countries}
          formErrors={this.state.formErrors}
          isProcessing={this.state.isProcessing}
          handleChange={this.handleChange}
          handleTypeIdChange={this.handleTypeIdChange}
          handleCountryChange={this.handleCountryChange}
          handleMobileChange={this.handleMobileChange}
          submitForm={this.submitForm}
          handleChangeMultiple={this.handleChangeMultiple}
        />
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user !== null ? state.auth.user.activeCompany.id : 0,
  activeCompanyRole: state.auth.user !== null ? state.auth.user.activeCompany.role : 0,
  countries: state.locale.countriesForSellers
});

export default connect(mapStateToProps)(withRouter(CompanyNotFoundComponent));
