/* @flow */

import React from 'react';

import Link from '@material-ui/core/Link';

import IntlMessageComponent from 'components/formatters/intlMessage';

type CollapseLinkComponentProps = {
  isCollapsed: boolean;
  labelClosed: string;
  labelOpen: string;
  toggle: () => void;
}

export default function CollapseLinkComponent (props: CollapseLinkComponentProps) {
  return (
    <Link
      color="textPrimary"
      underline="none"
      component="button"
      onClick={props.toggle}
      data-walkme="isocommission-walkthru-step1"
    >
      <b>
        {props.isCollapsed ? <span>&#x25B6;&nbsp;</span> : <span>&#x25BC;&nbsp;</span>}
        <IntlMessageComponent id={`${props.isCollapsed ? props.labelClosed : props.labelOpen}`}/>
      </b>
    </Link>
  );
}
