/* @flow */

export default class AddCompanyContactPersonRequest {
  companyId: number;
  debtorCompanyId: number;
  titleId: number;
  lastName: string;
  firstName: string;
  email: string;
  phone: string;
  languageId: number;
  functionName: string;
}
