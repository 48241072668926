/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet'

import IntlMessageComponent from 'components/formatters/intlMessage';
import LoadingComponent from 'components/loading';
import WizardComponent from 'components/wizard';

import SellInvoiceModalComponent from './modal/index';
import SellInvoiceTab1Component from './tab1/index';
import SellInvoiceTab2Component from './tab2/index';
import SellInvoiceTab3Component from './tab3/index';
import SellInvoiceTab4Component from './tab4/index';
import SellInvoiceTab5Component from './tab5/index';
import SellInvoiceTab6Component from './tab6/index';

import DebtorCompany from 'models/companies/debtorCompany';
import LeadOffer from 'models/leads/leadOffer';

import CancelInvoiceRequest from 'models/requests/sellers/cancelInvoiceRequest';

import InvoiceSellerService from 'services/InvoiceSellerService';

import NotificationHelper from 'lib/notifications';

const SELL_INVOICE_STEP_DEBTOR = 0;
const SELL_INVOICE_STEP_INVOICE = 1;
const SELL_INVOICE_STEP_CONTACTS = 2;
const SELL_INVOICE_STEP_DOCUMENTS = 3;
const SELL_INVOICE_STEP_SUMMARY = 4;
const SELL_INVOICE_STEP_PAYMENT = 5;

type SellerSellInvoicesComponentProps = {
  activeCompanyId: number;
  invoiceId: number;
  leadOffer: LeadOffer;
}

type SellerSellInvoicesComponentState = {
  activeStepId: number;
  debtorCompany: DebtorCompany;
  hasSoldInvoices: boolean;
  invoiceId: number;
  isLoading: boolean;
  leadOffer: LeadOffer;
  showModal: boolean;
  steps: { id: number, title: IntlMessageComponent }[];
};

class SellerSellInvoicesComponent extends React.Component<SellerSellInvoicesComponentProps, SellerSellInvoicesComponentState> {

  constructor(props) {
    super(props);

    // retrieve invoiceId from url or leadOfferId from history state
    let invoiceId = 0;
    let leadOffer = null;
    if (props.invoiceId > 0) {
      invoiceId = props.invoiceId;
    } else if (props.leadOffer) {
      leadOffer = props.leadOffer;
    }

    const steps = this.getSteps();

    this.state = {
      activeStepId: SELL_INVOICE_STEP_DEBTOR,
      debtorCompany: null,
      invoiceId,
      invoiceSummary: null,
      isLoading: invoiceId > 0,
      leadOffer,
      showModal: !this.props.hasSoldInvoices,
      steps
    };
  }

  componentDidMount = async () => {
    if (this.state.invoiceId > 0) {
      // continue invoice -> get debtor & step from db
      const response = await InvoiceSellerService.getInvoiceStatus(this.state.invoiceId);
      this.setState({
        activeStepId: response.lastStep,
        debtorCompany: response.debtorCompany,
        isLoading: false,
        showModal: false
      });
    }
  }

  getSteps = () => {
    const tabsNumber = [
      SELL_INVOICE_STEP_DEBTOR,
      SELL_INVOICE_STEP_INVOICE,
      SELL_INVOICE_STEP_CONTACTS,
      SELL_INVOICE_STEP_DOCUMENTS,
      SELL_INVOICE_STEP_SUMMARY,
      SELL_INVOICE_STEP_PAYMENT
    ];
    return tabsNumber.map(num => ({ id: num, title: <IntlMessageComponent id={`page.sellInvoice.tab${num + 1}`} /> }));
  }

  getStepContent = () => {
    switch (this.state.activeStepId) {
      case SELL_INVOICE_STEP_DEBTOR:
        return (<SellInvoiceTab1Component
          leadOffer={this.state.leadOffer}
          successHandler={this.handleSuccessTab1}
        />);
      case SELL_INVOICE_STEP_INVOICE:
        return (<SellInvoiceTab2Component
          debtorCompanyId={this.state.debtorCompany.id}
          invoiceId={this.state.invoiceId}
          leadOffer={this.state.leadOffer}
          successHandler={this.handleSuccessTab2}
          cancelInvoice={this.cancelInvoice}
        />);
      case SELL_INVOICE_STEP_CONTACTS:
        return (<SellInvoiceTab3Component
          debtorCompany={this.state.debtorCompany}
          invoiceId={this.state.invoiceId}
          successHandler={this.handleSuccessTab3}
          cancelInvoice={this.cancelInvoice}
        />);
      case SELL_INVOICE_STEP_DOCUMENTS:
        return (<SellInvoiceTab4Component
          invoiceId={this.state.invoiceId}
          successHandler={this.handleSuccessTab4}
          cancelInvoice={this.cancelInvoice}
        />);
      case SELL_INVOICE_STEP_SUMMARY:
        return (<SellInvoiceTab5Component
          invoiceId={this.state.invoiceId}
          successHandler={this.handleSuccessTab5}
          cancelInvoice={this.cancelInvoice}
        />);
      case SELL_INVOICE_STEP_PAYMENT:
        return (<SellInvoiceTab6Component
          invoiceId={this.state.invoiceId}
          handleNewInvoiceCreation={this.reset}
        />);
      default:
        return 'Unknown step';
    }
  };

  canNavigateToTab = (step: number) => {
    // can't return to the debtor step if invoice already created (create a new one plz..)
    if (step === SELL_INVOICE_STEP_DEBTOR && this.state.invoiceId > 0) {
      return false;
    }
    // can't go in the future
    if (this.state.activeStepId <= step) {
      return false;
    }
    return true;
  };


  handleTabClick = (stepId: number) => {
    if (this.canNavigateToTab(stepId)) {
      this.setState({ activeStepId: stepId });
    }
  };

  cancelInvoice = async () => {
    if (this.state.invoiceId > 0) {
      const request = new CancelInvoiceRequest(this.state.invoiceId);
      await InvoiceSellerService.cancelInvoice(request);
    }

    NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS, 'notifications.invoice.cancel.success');
    this.setState({ activeStepId: 0, debtorCompany: null, invoiceId: 0, leadOffer: null });
  }

  reset = () => {
    this.setState({
      activeStepId: SELL_INVOICE_STEP_DEBTOR,
      debtorCompany: null,
      invoiceId: 0,
      isLoading: false,
      leadOffer: null
    });
  }

  handleSuccessTab1 = (debtorCompany: DebtorCompany) => this.setState({ activeStepId: SELL_INVOICE_STEP_INVOICE, debtorCompany });
  handleSuccessTab2 = (debtorCompany: DebtorCompany, invoiceId: number) =>
    this.setState({ activeStepId: SELL_INVOICE_STEP_CONTACTS, debtorCompany, invoiceId, leadOffer: null });
  handleSuccessTab3 = () => this.setState({ activeStepId: SELL_INVOICE_STEP_DOCUMENTS });
  handleSuccessTab4 = () => this.setState({ activeStepId: SELL_INVOICE_STEP_SUMMARY });
  handleSuccessTab5 = () => this.setState({ activeStepId: SELL_INVOICE_STEP_PAYMENT });

  hideModal = () => this.setState({ showModal: false });

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <React.Fragment>
        <WizardComponent
          activeStepId={this.state.activeStepId}
          steps={this.state.steps}
          canNavigateToTab={this.canNavigateToTab}
          handleTabClick={this.handleTabClick}
        >
          {this.getStepContent()}
        </WizardComponent>

        {this.state.showModal &&
          <SellInvoiceModalComponent handleClose={this.hideModal} />
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id,
  hasSoldInvoices: state.auth.user.activeCompany.roleSeller.hasSoldInvoices
});

export default connect(mapStateToProps)(SellerSellInvoicesComponent);
