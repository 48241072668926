/* @flow */

import moment from 'moment';

export default class CompanyDetails {
  id: number;
  name: string;
  vatNumber: string;
  legalFormId: number;
  isin: string;
  stockExchange: string;
  activities: string;
  mainActivityCountryId: number;
  sourceOfFunds: string;
  addressLine1: string;
  addressLine2: string;
  zipCode: string;
  city: string;
  countryId: number;
  phone: string;
  ptCompanyCardOnlineCode: string;
  ptCompanyCardOnlineCodeExpiry: moment;
  ptCommercialRegistrationOnlineCode: string;
  ptCommercialRegistrationOnlineCodeExpiry: moment;
  isActive: boolean;
  orias: boolean;
  isBuyer: boolean;
  isSeller: boolean;
  isISO: boolean;
  isDebtor: boolean;
  isConfirming: boolean;
}
