/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';

type LostPasswordFormViewComponentProps = {
  errors: Map<string, ?string>;
  form: LostPasswordForm;
  isProcessing: boolean;
}

export default function LostPasswordFormViewComponent(props: LostPasswordFormViewComponentProps) {
  return (
    <React.Fragment>
      <Typography variant="body1" gutterBottom>
        <IntlMessageComponent id="page.lostPassword.description" />
      </Typography>
      
      <Box mt={3}>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item xs={12} md={6}>
            <TextField
              id="form.email"
              label={<IntlMessageComponent id="form.email.label" />}
              error={!!props.errors.get('email')}
              helperText={(props.errors.get('email')) ?
                <IntlMessageComponent id={props.errors.get('email')} /> : ''}
              value={props.form.email}
              onChange={props.handleChange('email')}
              fullWidth
              margin="normal"
              required
            />
          </Grid>
        </Grid>
      </Box>

      <Box mt={5}>
        <ButtonComponent
          color="primary"
          variant="contained"
          isProcessing={props.isProcessing}
          onClick={props.submitForm}
        ><IntlMessageComponent id="general.submit" /></ButtonComponent>
      </Box>
    </React.Fragment>
  );
}