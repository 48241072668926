/* @flow */

import React from 'react';
import { connect } from 'react-redux';

import moment from 'moment';

import * as reactGA from 'lib/analytics';

import LoadingComponent from 'components/loading';

import OfferTab3ViewComponent from './view';

import DebtorCompany from 'models/companies/debtorCompany';
import LeadOfferDefaults from 'models/leads/leadOfferDefaults';
import LeadOfferForm from 'models/leads/leadOffer';

import CreateLeadOfferRequest from 'models/requests/leads/createLeadOfferRequest';

import CompanyService from 'services/CompanyService';
import LeadService from 'services/LeadService';
import {getCampaignId} from 'lib/marketing';
import {handleApiFormResponse, handleFormChange, handleFormValidation} from 'lib/forms';
import loadConstraints from 'lib/validation/loadConstraints';

import NotificationHelper from 'lib/notifications';

import { ROLE_ISO } from 'constants/companyRoles';

type OfferTab3ComponentProps = {
  activeCompanyRole: number;
  activeCompanyId: number;
  debtorCompany: DebtorCompany;
  enableBackButton: boolean;
  leadId: number;
  successHandler: (leadOfferId: number) => void;
  backHandler: () => void;
};

type OfferTab3ComponentState = {
  constraints: any;
  defaults: LeadOfferDefaults;
  errors: Map<string,?string>;
  form: LeadOfferForm;
  formErrors: string[];
  isLoading: boolean;
  isProcessing: boolean;
};

class OfferTab3Component extends React.Component<OfferTab3ComponentProps, OfferTab3ComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      constraints: null,
      defaults: null,
      errors: null,
      form: new LeadOfferForm(),
      formErrors: [],
      isLoading: true,
      isProcessing: false
    };
  }

  componentDidMount = async () => {
    reactGA.initializeGA();
    reactGA.logEvent('Offer', 'Is Eligible', `Lead Id: ${this.props.leadId}`);

    try {
      // load the constraints
      const constraints = await loadConstraints('Invoice', ['debtAmount', 'dueDate', 'debtorCompany']);

      // load default values for this lead & update the constraints
      const debtorCompanyId = this.props.debtorCompany === null ? 0 : this.props.debtorCompany.id;
      const defaults = await LeadService.getLeadOfferDefaults(this.props.leadId, debtorCompanyId);

      constraints.debtAmount.numericality.greaterThanOrEqualTo = defaults.minDebtAmount;
      constraints.dueDate.dateInLessThanXDays.days = defaults.minDaysToSell;
      constraints.dueDate.dateInMoreThanXDays.days = defaults.maxDaysToSell;

      // copy debtor from props
      const form = this.state.form;
      form.debtorCompany = this.props.debtorCompany;
      form.dueDate = moment.utc().add(defaults.minDaysToSell, 'd').startOf('day');

      this.setState({
        constraints,
        defaults,
        errors: new Map(Object.keys(constraints).map(e => [e, undefined])),
        form,
        isLoading: false
      });
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  };

  componentWillUnmount = async () => {
    reactGA.logEvent('Offer', 'Debtor Info Validated', `Lead Id: ${this.props.leadId}`);
  }

  handleChange = (fieldName: string) => (event) => handleFormChange.call(this, fieldName, event.target.value);
  handleDateChange = (fieldName: string) => (date) => handleFormChange.call(this, fieldName, date);
  validateForm = () => handleFormValidation.call(this);

  handleDebtorCompanyChange = async (debtorCompany: ?DebtorCompany) => {
    try {
      const form = this.state.form;
      form.debtorCompany = debtorCompany;

      const errors = this.state.errors;
      errors.delete('debtorCompany');

      const constraints = this.state.constraints;
      const debtorCompanyId = debtorCompany === null ? 0 : debtorCompany.id;
      const defaults = await LeadService.getLeadOfferDefaults(this.props.leadId, debtorCompanyId);
      constraints.debtAmount.numericality.greaterThanOrEqualTo = defaults.minDebtAmount;
      constraints.dueDate.dateInLessThanXDays.days = defaults.minDaysToSell;
      constraints.dueDate.dateInMoreThanXDays.days = defaults.maxDaysToSell;

      this.setState({ constraints, defaults, errors, form });
    } catch (e) {
      console.error(e);
    }
  };

  submitForm = async () => {
    if (!this.validateForm()) return;

    this.setState({ formErrors: [], isProcessing: true });

    try {
      // if needed, create debtor company
      let debtorCompanyId = this.state.form.debtorCompany.id;
      if (debtorCompanyId === 0) {
        debtorCompanyId = await CompanyService.addDebtorCompany(this.state.form.debtorCompany.insurerId);
      }

      // create lead offer
      const request = new CreateLeadOfferRequest(this.props.leadId, debtorCompanyId, this.state.form.dueDate, this.state.form.debtAmount, this.props.activeCompanyRole === ROLE_ISO, this.props.activeCompanyId);
      request.campaignId = getCampaignId();
      const leadOfferId = await LeadService.createLeadOffer(request);
      this.props.successHandler(leadOfferId);
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }
  }

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <OfferTab3ViewComponent
        defaults={this.state.defaults}
        errors={this.state.errors}
        form={this.state.form}
        formErrors={this.state.formErrors}
        isProcessing={this.state.isProcessing}
        goBack={this.props.backHandler}
        handleChange={this.handleChange}
        handleDateChange={this.handleDateChange}
        handleDebtorCompanyChange={this.handleDebtorCompanyChange}
        submitForm={this.submitForm}
      />
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyRole: state.auth.user !== null ? state.auth.user.activeCompany.role : 0,
  activeCompanyId: state.auth.user !== null ? state.auth.user.activeCompany.id : 0
});

export default connect(mapStateToProps)(OfferTab3Component);
