/* @flow */

import React, {useState} from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CloudDownload from '@material-ui/icons/CloudDownload';

import FileExportDialogComponent from 'components/fileExportDialog';
import IntlMessageComponent from 'components/formatters/intlMessage';
import SecurityComponent from 'components/security';

type SellerReportActionsComponentProps = {
  clearFilters: () => void;
  onExportAsked: (format: string) => void;
}

export default function SellerReportActionsComponent(props: SellerReportActionsComponentProps) {
  const [showDialog, setShowDialog] = useState(false);

  return (
    <React.Fragment>
      <Button
        color="default"
        variant="contained"
        onClick={props.clearFilters}
        style={{textTransform: 'none'}}
      ><IntlMessageComponent id="general.filters.clear" /></Button>

      <SecurityComponent pageName="POS.Sellers" elementName="btnExport">
        <Box component="span" ml={2}>
          <Button
            color="primary"
            variant="contained"
            startIcon={<CloudDownload />}
            onClick={() => setShowDialog(true)}
            style={{textTransform: 'none'}}
          ><IntlMessageComponent id="component.isos.sellers.actions.export" /></Button>
        </Box>
      </SecurityComponent>

      {showDialog &&
        <FileExportDialogComponent
          isOpen
          typesAllowed={['xlsx', 'csv']}
          handleExport={(format: string) => (props.onExportAsked(format) && setShowDialog(false))}
          handleClose={() => setShowDialog(false)}
        />
      }
    </React.Fragment>
  );
}
