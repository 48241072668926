/* @flow */

import React from 'react';

import { connect } from 'react-redux';
import { setComponentData } from 'store/actions/componentDataActions';

import customSort from 'lib/sortHelpers';

import Box from '@material-ui/core/Box';

import DebtorInvoicesToPayPopupComponent from 'app/debtors/invoicesToPayPopup';
import DebtorInvoiceTableControlsViewComponent from 'app/debtors/invoiceTableControls';
import LoadingComponent from 'components/loading';

import DebtorInvoicesToPayTableViewComponent from './view';

import Invoice from 'models/debtors/invoice';
import InvoiceActiveFilters from 'models/debtors/invoiceActiveFilters';
import InvoiceFilters from 'models/debtors/invoiceFilters';

import DebtorService from 'services/DebtorService';

type DebtorInvoicesToPayTableComponentProps = {
  activeCompanyId: number;
  showFilters: boolean;
}

type DebtorInvoicesToPayTableComponentState = {
  activeFilters: InvoiceActiveFilters;
  filters: InvoiceFilters;
  invoices: Invoice[];
  isLoading: boolean;
  order: string;
  orderBy: ?string;
  page: number;
  pageSize: number;
  showConfirmPopupForInvoiceIds: number[];
  totalDebtAmount: number;
  totalSellers: number;
  totalOutstandingBalance: number;
}

class DebtorInvoicesToPayTableComponent extends React.Component<DebtorInvoicesToPayTableComponentProps, DebtorInvoicesToPayTableComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      filters: null,
      invoices: [],
      activeFilters: null,
      isLoading: true,
      order: 'asc',
      orderBy: undefined,
      page: 0,
      pageSize: 10,
      showConfirmPopupForInvoiceIds: []
    };
  }

  componentDidMount = async () => {
    try {
      const { invoices, filters } = await DebtorService.getInvoicesToPay(this.props.activeCompanyId);
      const totalDebtAmount = this.countTotalDebtAmount(invoices);
      const totalSellers = this.countSellerCompanies(invoices);
      const totalOutstandingBalance = this.countTotalOutstandingBalance(invoices);
      const activeFilters = new InvoiceActiveFilters();
      this.setState({ filters, activeFilters, invoices, isLoading: false, totalDebtAmount, totalSellers, totalOutstandingBalance });
    } catch (e) {
      console.error(e);
    }
  }

  countTotalDebtAmount = (invoices: Invoice[]) => invoices.reduce((acc, val) => acc + val.debtAmount, 0);

  countTotalOutstandingBalance = (invoices: Invoice[]) => invoices.reduce((acc, val) => acc + val.outstandingBalance, 0);

  countSellerCompanies = (invoices: Invoice[]) => {
    const sellers = [];
    invoices.forEach(i => {
      if (!sellers.includes(i.sellerCompany.id))
        sellers.push(i.sellerCompany.id);
    });
    return sellers.length;
  }

  handleFiltersChange = (activeFilters: InvoiceActiveFilters) => {
    const invoices = this.state.invoices;
    invoices.forEach(i => { i.isSelected = false; });
    this.setState({activeFilters, invoices});
  }

  handlePageChange = (page: number) => this.setState({ page });
  handlePageSizeChange = (pageSize: number) => this.setState({ pageSize });

  handleSortOrderChange = (orderBy: string) => {
    let order = this.state.orderBy === orderBy && this.state.order === 'desc' ? 'asc' : 'desc';

    let invoices = null;
    if (orderBy.startsWith('sellerCompany.')) {
      // get exact property name
      const o = orderBy.substring(14);

      // order
      invoices = order === 'desc'
        ? this.state.invoices.sort((a, b) => customSort(b.sellerCompany[o], a.sellerCompany[o]))
        : this.state.invoices.sort((a, b) => customSort(a.sellerCompany[o], b.sellerCompany[o]));
    }
    else {
      // order
      invoices = order === 'desc'
        ? this.state.invoices.sort((a, b) => customSort(b[orderBy], a[orderBy]))
        : this.state.invoices.sort((a, b) => customSort(a[orderBy], b[orderBy]));
    }

    this.setState({ invoices, order, orderBy });
  };

  getFilteredResults = () => {
    let filteredInvoices = this.state.invoices;

    // filter on query search
    if (this.state.activeFilters.query && this.state.activeFilters.query.length > 2) {
      filteredInvoices = filteredInvoices.filter((i: Invoice) => {
        const query = this.state.activeFilters.query.toUpperCase();
        const fields = [i.id, i.invoiceName, i.reference, i.debtorReference].map(v => (v || '').toString().toUpperCase());
        return !!(fields.filter(field => field.toString().indexOf(query) !== -1)).length;
      });
    }

    // Filter on seller company
    if (this.state.activeFilters.sellerCompanyId > 0) {
      filteredInvoices = filteredInvoices.filter(i => i.sellerCompany.id === this.state.activeFilters.sellerCompanyId);
    }

    // Filter on invoice status
    if (this.state.activeFilters.statusKey !== '') {
      filteredInvoices = filteredInvoices.filter(i => i.statusKey.toUpperCase() === this.state.activeFilters.statusKey.toUpperCase());
    }

    // filter on amount
    if (this.state.activeFilters.invoiceAmounts && this.state.activeFilters.invoiceAmounts.length > 0) {
      const min = this.state.activeFilters.invoiceAmounts[0];
      const max = this.state.activeFilters.invoiceAmounts[1];
      if (min !== "" && max === "") filteredInvoices = filteredInvoices.filter(i => i.debtAmount >= min);
      if (min === "" && max !== "") filteredInvoices = filteredInvoices.filter(i => i.debtAmount <= max);
      if (min !== "" && max !== "") filteredInvoices = filteredInvoices.filter(i => i.debtAmount >= min && i.debtAmount <= max);
    }

    return filteredInvoices;
  };

  handleInvoiceSelect = (invoiceId: number) => {
    const invoices = this.state.invoices;
    const invoice = invoices.find(i => i.id === invoiceId);
    if (invoice) invoice.isSelected = !invoice.isSelected;
    this.setState({ invoices });
  }

  handleInvoiceSelectAll = () => {
    const invoices = this.state.invoices;
    const filteredInvoices = this.getFilteredResults();
    const selectMode = invoices.find(i => i.isSelected) === undefined;
    invoices.forEach(i => { if (filteredInvoices.find(f => f.id === i.id) !== undefined) i.isSelected = selectMode; });
    this.setState({ invoices });
  }

  payInvoices = () => {
    const invoiceIds = this.state.invoices.filter(i => i.isSelected).map(i => i.id);
    this.setState({ showConfirmPopupForInvoiceIds: invoiceIds });
  }

  payInvoicesSuccess = async () => {
    const { invoices, filters } = await DebtorService.getInvoicesToPay(this.props.activeCompanyId);
    const totalDebtAmount = this.countTotalDebtAmount(invoices);
    const totalSellers = this.countSellerCompanies(invoices);
    const totalOutstandingBalance = this.countTotalOutstandingBalance(invoices);
    const activeFilters = new InvoiceActiveFilters();
    this.setState({ filters, activeFilters, invoices, isLoading: false, totalDebtAmount, totalSellers, totalOutstandingBalance, showConfirmPopupForInvoiceIds: [] });
  }

  payInvoicesCancel = () => {
    this.setState({ showConfirmPopupForInvoiceIds: [] });
  }

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <React.Fragment>
        {this.props.showFilters &&
          <Box pb={5}>
            <DebtorInvoiceTableControlsViewComponent
              activeFilters={this.state.activeFilters}
              filters={this.state.filters}
              handleFiltersChange={this.handleFiltersChange}
              filterOnAmount
              filterOnInvoiceStatus
              filterOnSellerCompany
            />
          </Box>
        }

        <DebtorInvoicesToPayTableViewComponent
          invoices={this.getFilteredResults()}
          order={this.state.order}
          orderBy={this.state.orderBy}
          page={this.state.page}
          pageSize={this.state.pageSize}
          totalDebtAmount={this.state.totalDebtAmount}
          totalSellers={this.state.totalSellers}
          totalOutstandingBalance={this.state.totalOutstandingBalance}
          handleInvoiceSelect={this.handleInvoiceSelect}
          handleInvoiceSelectAll={this.handleInvoiceSelectAll}
          handlePageChange={this.handlePageChange}
          handlePageSizeChange={this.handlePageSizeChange}
          handleSortOrderChange={this.handleSortOrderChange}
          payInvoices={this.payInvoices}

        />

        {this.state.showConfirmPopupForInvoiceIds.length > 0 &&
          <DebtorInvoicesToPayPopupComponent
            invoiceIds={this.state.showConfirmPopupForInvoiceIds}
            handleCancel={this.payInvoicesCancel}
            handleSuccess={this.payInvoicesSuccess}
          />
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id,
  activeFilters: state.components.debtorInvoicesToPayTable.activeFilters
});

export default connect(mapStateToProps, { setComponentData })(DebtorInvoicesToPayTableComponent);
