/* @flow */

import { plainToClass } from 'class-transformer';

import BaseService from './api/baseService';

import {
  COMPANY_GET_EH_BY_NAME,
  COMPANY_GET_EH_BY_VAT,
  COMPANY_GET_DEBTORS_BY_COMPANY_ID,
  COMPANY_DEBTOR_GET,
  COMPANY_DEBTORS_GET,
  COMPANY_SUPPLIER_GET,
  COMPANY_SUPPLIERS_GET
} from 'constants/apiRoutes';

import Company from 'models/companies/company';
import DebtorCompany from 'models/companies/debtorCompany';

export default class CompanyLookupService {

  static lookupByName = (countryId: number, name: string) =>
    BaseService.anonymousGET(`${COMPANY_GET_EH_BY_NAME}?countryId=${countryId}&companyName=${encodeURIComponent(name)}`,
      data => data.map(d => plainToClass(Company, d)));

  static lookupByVat = (countryId: number, vatNumber: string) =>
    BaseService.anonymousGET(`${COMPANY_GET_EH_BY_VAT}?countryId=${countryId}&vatNumber=${vatNumber}`,
      data => data.map(d => plainToClass(Company, d)));

  static getDebtorsByCompanyId = (companyId: number) =>
    BaseService.simpleGET(`${COMPANY_GET_DEBTORS_BY_COMPANY_ID}/${companyId}`,
      data => data.map(d => plainToClass(Company, d)));

  // Get a debtor company
  static getDebtorCompany = (sellerCompanyId: number, debtorCompanyId: number) =>
    BaseService.simpleGET(`${COMPANY_DEBTOR_GET}/${sellerCompanyId}/${debtorCompanyId}`,
      data => plainToClass(DebtorCompany, data));

  // Get Debtors of a seller company
  static getDebtorCompanies = (sellerCompanyId: number) =>
    BaseService.simpleGET(`${COMPANY_DEBTORS_GET}/${sellerCompanyId}`,
      data => data.map(d => plainToClass(DebtorCompany, d)));

  // Get a supplier company
  static getSupplierCompany = (confirmingCompanyId: number, supplierCompanyId: number) =>
    BaseService.simpleGET(`${COMPANY_SUPPLIER_GET}/${confirmingCompanyId}/${supplierCompanyId}`,
      data => plainToClass(Company, data));

  // Get suppliers of a confirming company
  static getSupplierCompanies = (confirmingCompanyId: number) =>
    BaseService.simpleGET(`${COMPANY_SUPPLIERS_GET}/${confirmingCompanyId}`,
      data => data.map(d => plainToClass(Company, d)));
}
