/* @flow */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function IconThumbsUpOutlined(props) {
  return (
    <SvgIcon viewBox="0 0 62.72 62.69" {...props}>
      <path d="M396.66,337.44a31.35,31.35,0,1,1,31.43-31C428.06,323.45,413.85,337.46,396.66,337.44Zm28.64-31.16c-.12-16.16-12.67-28.75-28.53-28.75A28.32,28.32,0,0,0,368.18,305c-.42,17.26,12.78,29.73,28.55,29.68A28.45,28.45,0,0,0,425.3,306.28Z" transform="translate(-365.37 -274.75)"/>
      <g>
          <path d="M396.18,322.06c-.73-.06-1.45-.1-2.17-.2a6.9,6.9,0,0,1-4-1.89,3.05,3.05,0,0,1-2.74,1.11c-2,0-4.08,0-6.12,0s-2.87-.92-2.87-2.88V302.41a2.44,2.44,0,0,1,2.61-2.75c2.3,0,4.61,0,6.91,0a2.48,2.48,0,0,1,2.2,1.13,18.36,18.36,0,0,0,3.47-7.23c.39-2,.56-4,.79-5.95a3.82,3.82,0,0,1,2.54-3.53,3.62,3.62,0,0,1,4.08,1.25,10.1,10.1,0,0,1,2.19,5.26,22.7,22.7,0,0,1,0,7,5.54,5.54,0,0,0,0,.71h11.09a3.65,3.65,0,0,1,3.67,3.55,4.69,4.69,0,0,1-.89,3,1.38,1.38,0,0,0-.2,1,6.44,6.44,0,0,1-1.39,5.42,1.66,1.66,0,0,0-.34.89,6.44,6.44,0,0,1-2,5.13,2,2,0,0,0-.35.86,7.18,7.18,0,0,1-2.6,3.53,1.69,1.69,0,0,1-1,.23c-2,0-4,0-6,.05-.28,0-.56.06-.85.09Zm16.95-10.34c-1.23,0-2.32,0-3.42,0-.62,0-1.12-.16-1.13-.88s.48-.9,1.11-.9c1.28,0,2.56,0,3.84,0a.87.87,0,0,0,.67-.25,4.41,4.41,0,0,0,.73-3.53h-.88c-1.15,0-2.3,0-3.45,0-.83,0-1.35-.58-1-1.16a1.52,1.52,0,0,1,1.11-.64,30.12,30.12,0,0,1,3.68,0,1.12,1.12,0,0,0,1.22-.73,5.55,5.55,0,0,0,.39-1.1,1.92,1.92,0,0,0-2-2.43H402.57c-1.26,0-1.57-.31-1.36-1.56a23.74,23.74,0,0,0,.14-7.09,10.37,10.37,0,0,0-1.47-4.49,2.3,2.3,0,0,0-2.44-1.16A2.23,2.23,0,0,0,396,288c0,.07,0,.15,0,.23-.25,1.81-.38,3.63-.76,5.41a22.52,22.52,0,0,1-4.29,8.95,2.56,2.56,0,0,0-.58,1.37c0,4.47,0,8.95,0,13.42,0,.23,0,.56.13.66a12.94,12.94,0,0,0,2.8,1.77,9.3,9.3,0,0,0,3.09.36c4,0,8.06,0,12.09,0,.2,0,.47,0,.6-.07a4.93,4.93,0,0,0,2-2.75H410c-1.92,0-2.37-.19-2.36-1s.48-.92,2.32-.92h.87c1.51,0,2.15-.56,2.31-2.11C413.18,312.85,413.13,312.33,413.13,311.72Zm-33-1.44c0,2.69,0,5.38,0,8.07,0,.63.15.9.83.89,2.24,0,4.49,0,6.74,0,.56,0,.81-.17.81-.76q0-8.12,0-16.23c0-.57-.23-.77-.8-.77-2.25,0-4.5,0-6.74,0-.66,0-.85.24-.84.88C380.17,305,380.16,307.64,380.16,310.28Z" transform="translate(-365.37 -274.75)"/>
          <path d="M384.32,313.18a1.36,1.36,0,0,1,1.34,1.34,1.38,1.38,0,0,1-1.36,1.4,1.41,1.41,0,0,1-1.31-1.39A1.37,1.37,0,0,1,384.32,313.18Z" transform="translate(-365.37 -274.75)"/>
      </g>
    </SvgIcon>
  );
}
