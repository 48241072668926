/* @flow */

import DebtorInfo from 'models/debtors/debtorInfo';
import RefuseInvoiceTokenRequest from 'models/requests/debtors/refuseInvoiceTokenRequest';

export default class RefuseInvoiceForm {
  reason: string;

  constructor() {
    this.reason = '';
  }

  toRequest(token: string, invoiceId: number, debtorInfo: DebtorInfo){
    const request = new RefuseInvoiceTokenRequest();
    request.token = token;
    request.invoiceId = invoiceId;
    request.lastName = debtorInfo.lastName;
    request.firstName = debtorInfo.firstName;
    request.email = debtorInfo.email;
    request.functionName = debtorInfo.functionName;
    request.reason = this.reason;
    return request;
  }
}
