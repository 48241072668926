/* @flow */

function percentageValidator(value: string, options: {message: ?string}, key: ?string, attributes: ?any) {
  if (!options.mandatory && value === '') return undefined;
  if (options.mandatory && (!value || value === '')) return 'is not a number';

  const num = Number(value);
  if (isNaN(num)) return 'is not a number';
  if (num < 0 || num > 100) return options.message;
  return undefined;
}

export default percentageValidator;
