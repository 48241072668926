/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import LoadingComponent from 'components/loading';

import CompanyOfficeFormViewComponent from './view';

import Office from 'models/companies/office';
import OfficeForm from './model';

import DebtorService from 'services/DebtorService';
import IsoService from 'services/IsoService';
import {handleApiFormResponse, handleFormChange, handleFormValidation} from 'lib/forms';
import loadConstraints from 'lib/validation/loadConstraints';

import NotificationHelper from 'lib/notifications';
import { ROLE_DEBTOR, ROLE_ISO } from 'constants/companyRoles';

type CompanyOfficeFormComponentProps = {
  activeCompanyId: number;
  activeCompanyRole: Number;
  office: Office;
  successHandler: (officeId: number) => void;
  cancelHandler: () => void;
}

type CompanyOfficeFormComponentState = {
  constraints: any;
  errors: Map<string, ?string>;
  form: OfficeForm;
  formErrors: string[];
  isLoading: boolean;
  isProcessing: boolean;
}

class CompanyOfficeFormComponent extends React.Component<CompanyOfficeFormComponentProps, CompanyOfficeFormComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      constraints: null,
      errors: null,
      form: null,
      formErrors: [],
      isLoading: true,
      isProcessing: false
    };
  }

  componentDidMount = async () => {
    try {
      const constraints = await loadConstraints('office', ['name', 'phone', 'officeTypeId', 'addressLine1', 'addressLine2', 'zipCode', 'city', 'countryId']);

      this.setState({
        constraints,
        errors: new Map(Object.keys(constraints).map(e => [e, undefined])),
        form: new OfficeForm(this.props.office),
        isLoading: false
      });
    } catch (error) {
      console.error(error);
    }
  };

  handleChange = (fieldName: string) => (event) => handleFormChange.call(this, fieldName, event.target.value);
  handleTypeChange = (fieldName: string) => (id: number) => handleFormChange.call(this, fieldName, id);
  handlePhoneChange = (value: string) => {
    handleFormChange.call(this, 'phone', value);
  }
  validateForm = () => handleFormValidation.call(this);

  submitForm = async () => {
    if (!this.validateForm()) return;
    this.setState({formErrors: [], isProcessing: true});

    try {
      let officeId = 0;

      if (!this.props.office) {
        const request = this.state.form.toAddOfficeRequest(this.props.activeCompanyId);

        if (this.props.activeCompanyRole === ROLE_DEBTOR) {
          officeId = await DebtorService.addOffice(request);
        } else if (this.props.activeCompanyRole === ROLE_ISO) {
          officeId = await IsoService.addOffice(request);
        }
      } else {
        const request = this.state.form.toUpdateOfficeRequest(this.props.activeCompanyId, this.props.office.id);
        
        if (this.props.activeCompanyRole === ROLE_DEBTOR) {
          officeId = await DebtorService.updateOffice(request);
        } else if (this.props.activeCompanyRole === ROLE_ISO) {
          officeId = await IsoService.updateOffice(request);
        }        
      }

      NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS);

      if (this.props.successHandler) {
        this.props.successHandler(officeId);
      } else {
        this.setState({isProcessing: false});
      }
    } catch (error) {
      handleApiFormResponse.call(this, error);
    }
  };

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <CompanyOfficeFormViewComponent
        errors={this.state.errors}
        form={this.state.form}
        formErrors={this.state.formErrors}
        isProcessing={this.state.isProcessing}
        office={this.props.office}
        handleChange={this.handleChange}
        handlePhoneChange={this.handlePhoneChange}
        handleTypeChange={this.handleTypeChange}
        submitForm={this.submitForm}
        cancelHandler={this.props.cancelHandler}
      />
    );
  }
}

const mapStateToProps = ({auth}) => ({
  activeCompanyId: auth.user.activeCompany.id,
  activeCompanyRole: auth.user.activeCompany.role
});

export default connect(mapStateToProps)(CompanyOfficeFormComponent);
