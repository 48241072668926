/* @flow */

import React from 'react';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Box } from '@material-ui/core';

import ButtonComponent from 'components/buttons/button';
import FormErrorsComponent from 'components/formErrors';
import IntlMessageComponent from 'components/formatters/intlMessage';
import UploadFileFieldComponent from 'components/formFields/upload/index';
import TooltipComponent from 'components/toolTips/index';

import Document from 'models/documents/v2/document';

type CompanyBankAccountsFormDialogStep2ViewComponentProps = {
  document: Document;
  formErrors: string[];
  handleDocumentSelect: (file: File) => void;
  handleDocumentSelectFailure: (file: File) => void;
  handleDocumentRemove: () => void;
  onClose: () => void;
  onSubmit: () => void;
}

export default function CompanyBankAccountsFormDialogStep2ViewComponent(props: CompanyBankAccountsFormDialogStep2ViewComponentProps) {
  return (
    <React.Fragment>
      <DialogContent>
        <FormErrorsComponent formErrors={props.formErrors} />

        <Box pageName="Tooltip.CompanyInfo.BankAccount" elementName={"document." + props.document.code}>
          <TooltipComponent pageName="Tooltip.CompanyInfo.BankAccount" elementName={"document." + props.document.code}>
            <UploadFileFieldComponent
              document={props.document}
              successHandler={props.handleDocumentSelect}
              failHandler={props.handleDocumentSelectFailure}
              removeHandler={props.handleDocumentRemove}
            />
          </TooltipComponent>
        </Box>
      </DialogContent>

      <DialogActions>
        <ButtonComponent
          color="default"
          onClick={props.onClose}
        ><IntlMessageComponent id="general.cancel" /></ButtonComponent>

        <ButtonComponent
          color="primary"
          onClick={props.onSubmit}
        ><IntlMessageComponent id="general.submit" /></ButtonComponent>
      </DialogActions>
    </React.Fragment>
  );
}
