/* @flow */

import moment from 'moment';

export default class Mandate {
  id: number;
  sellerId: number;
  name: string;
  amount: number;
  isPaid: boolean;
  paymentDate: moment;
  isActive: boolean;
  userId: number;
  companyId: number;
}
