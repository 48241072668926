/* @flow */

import React from 'react';

import List from '@material-ui/core/List';

import ContactPersonListItemComponent from './item';

import CompanyContactPerson from 'models/companies/companyContactPerson';

type ContactPersonListComponentProps = {
  contactPersonIds: number[];
  contactPersons: CompanyContactPerson[];
  selectHandler: (contactPersonId: number) => void;
  unselectHandler: (contactPersonId: number) => void;
};

export default function ContactPersonListComponent(props: ContactPersonListComponentProps) {
  if (!props.contactPersons) {
    return null;
  }

  return (
    <List>
      {props.contactPersons.map(contactPerson => (
        <ContactPersonListItemComponent
          key={`contact-${contactPerson.id}`}
          contactPerson={contactPerson}
          selected={props.contactPersonIds.find(c => c === contactPerson.id)}
          selectHandler={props.selectHandler}
          unselectHandler={props.unselectHandler}
        />
      ))}
    </List>
  );
}
