/* @flow */

export default class DisableCompanyUserRequest {
    companyId: number;
    companyUserId: number;
  
    constructor(companyId: number, companyUserId: number) {
      this.companyId = companyId;
      this.companyUserId = companyUserId;
    }
  }
  