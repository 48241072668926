/* @flow */

export default class UpdateOfficePersonRequest {
  companyId: number;
  companyPersonId: number;
  officeId: number;
  titleId: number;
  lastName: string;
  firstName: string;
  email: string;
  mobile: string;
  phone: string;
  countryId: number;
  languageId: number;
  functionName: string;
}
