/* @flow */

import React from 'react';
import {useSelector} from 'react-redux';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import IntlMessageComponent from 'components/formatters/intlMessage';
import LinkButtonComponent from 'components/buttons/linkButton';

import {ROLE_SELLER} from 'constants/companyRoles';
import {PROTECTED_SELLER_SELL} from 'constants/pageRoutes'

export default function WebsiteHeaderSellInvoiceButtonComponent() {
  const user = useSelector(state => state.auth.user);
  if (!user) return null;
  if (user.activeCompany.role !== ROLE_SELLER) return null;
  if (user.activeCompany.isSubscribing) return null;
  if (!user.activeCompany.roleSeller.canExecute) return null;

  return (
    <LinkButtonComponent
      label={<b><IntlMessageComponent id="header.seller.btn"/></b>}
      icon={<AddCircleOutlineIcon />}
      url={PROTECTED_SELLER_SELL}
      disabled={user.activeCompany.roleSeller.isSupplier}
    />
  );
}
