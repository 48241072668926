/* @flow */

import moment from 'moment/moment';

export default class Coupon {
  id: number;
  reference: string;
  value: number;
  companyName: string;
  amountUsed: number;
  reason: string;
  validTo: moment;
}