/* @flow */

import React from 'react';

import PaymentFailedComponent from 'app/payments/failed';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function OgonePaymentFailedPage() {
  return (
    <WebsitePageComponent id="page.payment.title">
      <PaymentFailedComponent />
    </WebsitePageComponent>
  );
}
