/* @flow */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function IconPaymentPending(props) {
  return (
    <SvgIcon viewBox="0 0 69.29 78.06" {...props}>
      <path d="M440.63,322.68v2.38H420.28v-2.49c.42,0,.79,0,1.17,0s.68-.12.65-.63c0-.66,0-1.33,0-2a5,5,0,0,1,1.13-3.54,45.06,45.06,0,0,1,3.44-3.33,2.24,2.24,0,0,0,0-3.66c-.73-.63-1.46-1.24-2.16-1.89a6.6,6.6,0,0,1-2.35-3.93,13.69,13.69,0,0,1-.07-2.31c0-.5-.12-.73-.66-.68a12.07,12.07,0,0,1-1.21,0V298h20.4v2.5l-1.88.15c0,.41,0,.78,0,1.15a6.74,6.74,0,0,1-2.26,5.46c-.79.71-1.56,1.44-2.39,2.11a2.26,2.26,0,0,0,.05,3.71c.33.21.62.49.94.73,2.57,1.93,4,4.4,3.66,7.72a7.43,7.43,0,0,0,0,.94Zm-4.5-.88c0-.67,0-1.21,0-1.74A3.55,3.55,0,0,0,435,317.2a18.64,18.64,0,0,0-2.05-1.82,5.77,5.77,0,0,1-2.16-2.74,1.63,1.63,0,0,0-.37-.47c-.14.15-.35.27-.4.44a5.15,5.15,0,0,1-1.76,2.45c-.85.69-1.69,1.41-2.48,2.18-1.31,1.26-1.24,2.87-1.09,4.56,1.09-.43,2.12-.76,3.09-1.22a5.62,5.62,0,0,1,5.14-.05C433.91,321,435,321.36,436.13,321.8ZM424.5,300.65a25.14,25.14,0,0,0,.38,3.15c.27,1.05.67,1.25,1.75,1.25,2.63,0,5.25,0,7.88,0a1.17,1.17,0,0,0,1.06-.59c.86-1.18.49-2.51.55-3.82Z" transform="translate(-386.27 -258.83)"/>
      <path d="M432.53,286.57V273.73a6,6,0,0,0-2.14-3.44q-4.89-4.92-9.73-9.89a4.89,4.89,0,0,0-3.71-1.56c-8.79,0-17.58.06-26.38,0a4,4,0,0,0-4.3,4.25q.11,25.29,0,50.57a3.83,3.83,0,0,0,3.92,4l15.66,0a25.21,25.21,0,1,0,26.68-31Zm-12.38-20.44,5.64,5.64h-5.64Zm-29.87,47.48V262.87h25.85v8.64a4,4,0,0,0,4.28,4.26c2.67-.08,5.34,0,8.08,0v10.8a25.24,25.24,0,0,0-23.35,25.13c0,.65,0,1.29.08,1.93Zm40.07,19.28a21.21,21.21,0,1,1,21.21-21.21A21.23,21.23,0,0,1,430.35,332.89Z" transform="translate(-386.27 -258.83)"/>
    </SvgIcon>
  );
}