/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import CheckCircle from '@material-ui/icons/CheckCircle';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

import ButtonComponent from 'components/buttons/button';
import CountryNameComponent from 'components/country/name';
import IntlMessageComponent from 'components/formatters/intlMessage';

import CompanyContactPerson from 'models/companies/companyContactPerson';

type ContactPersonListItemComponentProps = {
  contactPerson: CompanyContactPerson;
  selected: boolean;
  selectHandler: (id: number) => void;
  unselectHandler: (id: number) => void;
};

export default function ContactPersonListItemComponent(props: ContactPersonListItemComponentProps) {

  const select = () => props.selectHandler(props.contactPerson.id);
  const unselect = () => props.unselectHandler(props.contactPerson.id);

  const selectable = (typeof props.selectHandler === 'function');

  return (
    <ListItem>
      <ListItemText
        primary={<b>{`${props.contactPerson.lastName} ${props.contactPerson.firstName}`}</b>}
        secondary={
          <Box color="text.secondary">
            <Box color="primary.main">{props.contactPerson.email}</Box>
            {props.contactPerson.functionName && <Box>{props.contactPerson.functionName}</Box>}
            <Box>{props.contactPerson.phone}</Box>
            <Box><CountryNameComponent countryId={props.contactPerson.countryId} /></Box>
          </Box>
        }
      />
      <ListItemSecondaryAction>
        {props.selected ? (
          <ButtonComponent
            color="secondary"
            variant="contained"
            disabled={!selectable}
            onClick={unselect}
            fullWidth
          ><CheckCircle /></ButtonComponent>
        ) : (
          <ButtonComponent
            color="primary"
            variant="contained"
            disabled={!selectable}
            onClick={select}
            fullWidth
          ><IntlMessageComponent id="page.sellInvoice.tab3.contact.button.selectContact.label" /></ButtonComponent>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
}
