/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';

import DebtorSupplierBankAccountChangeTableComponent from 'app/debtors/suppliersBankAccount';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

export default function DebtorSupplierBankAccountChangeTableWithTitleComponent() {
  return (
    <React.Fragment>
      <PageSubTitleViewComponent id="debtor.dashboard.suppliersbankaccountchange.title" />

      <Box mt={3}>
        <DebtorSupplierBankAccountChangeTableComponent changedToEdebex={false}/>
      </Box>
    </React.Fragment>
  );
}
