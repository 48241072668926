/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import ButtonComponent from 'components/buttons/button';
import FormErrorsComponent from 'components/formErrors';
import IntlMessageComponent from 'components/formatters/intlMessage';
import InputAmountComponent from 'components/formatters/inputAmount';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import AlternativeFinancingForm from './model';

type BestPricingAltFinViewComponentProps = {
  errors: Map<string,?string>;
  form: AlternativeFinancingForm;
  formErrors: string[];
  isProcessing: boolean;
  handleBooleanChange: () => void;
  handleChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void;
  submitForm: () => void;
}

export default function BestPricingAltFinViewComponent(props: BestPricingAltFinViewComponentProps) {

  return (
    <React.Fragment>
      <Paper elevation={0}>
        <Box p={5}>
          <FormErrorsComponent formErrors={props.formErrors} />

          <PageSubTitleViewComponent id="page.bestPricing.alternativeFinancing.title" />

          <Typography variant="body1">
            <IntlMessageComponent id="page.bestPricing.alternativeFinancing.line1" />
          </Typography>

          <Box mt={5}>
            <FormControl component="fieldset" fullWidth required>
              <FormLabel component="legend">
                <IntlMessageComponent id="page.bestPricing.alternativeFinancing.line2" />
              </FormLabel>

              <RadioGroup
                aria-label="alternativeFinancing"
                name="alternativeFinancing"
                onChange={props.handleBooleanChange('hasAlternativeFinancing')}
                row
                value={props.form.hasAlternativeFinancing.toString()}
              >
                <FormControlLabel
                  control={<Radio color="primary" />}
                  label={<IntlMessageComponent id="general.label.yes" />}
                  value="true"
                />
                <FormControlLabel
                  control={<Radio color="primary" />}
                  label={<IntlMessageComponent id="general.label.no" />}
                  value="false"
                />
              </RadioGroup>
            </FormControl>
          </Box>

          {props.form.hasAlternativeFinancing &&
            <Box mt={5}>
              <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                <Grid item xs={12} md={6}>
                  <Box px={4}>
                    <TextField
                      id="form.altFin.companyName"
                      label={<IntlMessageComponent id="form.altFin.companyName.label" />}
                      error={!!props.errors.get('companyName')}
                      helperText={props.errors.get('companyName') ?
                        <IntlMessageComponent id={props.errors.get('companyName')} /> : ''}
                      value={props.form.companyName}
                      onChange={props.handleChange('companyName')}
                      fullWidth
                      margin="normal"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box px={4}>
                    <TextField
                      id="form.altFin.type"
                      label={<IntlMessageComponent id="form.altFin.type.label" />}
                      error={!!props.errors.get('type')}
                      helperText={props.errors.get('type') ?
                        <IntlMessageComponent id={props.errors.get('type')} /> : ''}
                      value={props.form.type}
                      onChange={props.handleChange('type')}
                      fullWidth
                      margin="normal"
                    />
                  </Box>
                </Grid>
              </Grid>

              <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                <Grid item xs={12} md={6}>
                  <Box px={4}>
                    <TextField
                      id="form.altFin.costPercentage"
                      label={<IntlMessageComponent id="form.altFin.costPercentage.label" />}
                      error={!!props.errors.get('costPercentage')}
                      helperText={props.errors.get('costPercentage') ?
                        <IntlMessageComponent id={props.errors.get('costPercentage')} /> : ''}
                      value={props.form.costPercentage}
                      onChange={props.handleChange('costPercentage')}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">%</InputAdornment>
                      }}
                      fullWidth
                      margin="normal"
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box px={4}>
                    <TextField
                      id="form.altFin.creditLimit"
                      label={<IntlMessageComponent id="form.altFin.creditLimit.label" />}
                      error={!!props.errors.get('creditLimit')}
                      helperText={(props.errors.get('creditLimit')) ?
                        <IntlMessageComponent id={props.errors.get('creditLimit')} /> : ''}
                      value={props.form.creditLimit}
                      onChange={props.handleChange('creditLimit')}
                      InputProps={{
                        inputComponent: InputAmountComponent,
                        startAdornment: <InputAdornment position="start">€</InputAdornment>
                      }}
                      fullWidth
                      margin="normal"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          }

          <Box mt={5}>
            <ButtonComponent
              color="primary"
              variant="contained"
              onClick={props.submitForm}
            ><IntlMessageComponent id="page.bestPricing.button.finalize" /></ButtonComponent>
          </Box>
        </Box>
      </Paper>
    </React.Fragment>
  );
}
