/* @flow */

import React from 'react';
import { connect } from 'react-redux';

import LoadingComponent from 'components/loading';

import CompanyBankAccountsViewComponent from './view';

import BankAccount from 'models/companies/bankAccount';

import ToggleEnableCompanyBankAccountRequest from 'models/requests/companies/toggleEnableCompanyBankAccountRequest';
import DeleteCompanyBankAccountRequest from 'models/requests/companies/deleteCompanyBankAccountRequest';
import SetMainCompanyBankAccountRequest from 'models/requests/companies/setMainCompanyBankAccountRequest';

import NotificationHelper from 'lib/notifications';
import CompanyService from 'services/CompanyService';

type CompanyBankAccountsComponentProps = {
  activeCompanyId: number;
  activeCompanyIsActive: boolean;
}

type CompanyBankAccountsComponentState = {
  bankAccounts: BankAccount[];
  displayBankAccountToBeValidatedMessage: boolean;
  isDialogOpen: boolean;
  isLoading: boolean;
  isProcessing: boolean;
  bankAccountToDeleteId: number;
}

class CompanyBankAccountsComponent extends React.Component<CompanyBankAccountsComponentProps, CompanyBankAccountsComponentState> {

  constructor(props) {
    super(props);
    this.state = {
      bankAccounts: [],
      displayBankAccountToBeValidatedMessage: false,
      isDialogOpen: false,
      isLoading: true,
      isProcessing: false,
      bankAccountToDeleteId: 0
    };
  }

  componentDidMount() {
    this.getBankAccounts();
  }

  getBankAccounts = async () => {
    try {
      const bankAccounts = await CompanyService.getCompanyBankAccounts(this.props.activeCompanyId);
      this.setState({
        bankAccounts,
        isLoading: false
      });
    } catch (error) {
      console.error(error);
    }
  }

  openDialog = () => {
    this.setState({ isDialogOpen: true });
  }

  closeDialog = () => {
    this.setState({ isDialogOpen: false });
  }

  onAddBankAccountSuccess = () => {
    NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS, 'page.bankaccount.saved');
    this.setState({ isDialogOpen: false, isLoading: true });
    this.getBankAccounts();
  }

  changeMainBankAccount = async (bankAccountId: number) => {
    this.setState({ isProcessing: true });

    try {
      const request = new SetMainCompanyBankAccountRequest(this.props.activeCompanyId, bankAccountId);
      await CompanyService.setMainCompanyBankAccount(request);
      const bankAccounts = this.state.bankAccounts;
      const main = bankAccounts.find(b => b.isMain);
      if (main)
        main.isMain = false;
      const newMain = bankAccounts.find(b => b.id === bankAccountId);
      newMain.isMain = true;
      this.setState({ bankAccounts, isProcessing: false });
    } catch (error) {
      console.error(error);
    }
  }

  toggleEnableBankAccount = async (bankAccountId: number) => {
    this.setState({ isProcessing: true });

    try {
      const request = new ToggleEnableCompanyBankAccountRequest(this.props.activeCompanyId, bankAccountId);
      await CompanyService.toggleEnableCompanyBankAccount(request);
      const bankAccounts = this.state.bankAccounts;
      const toggled = bankAccounts.find(b => b.id === bankAccountId);
      const mainAccount = bankAccounts.find(b => b.isMain);
      if (!mainAccount && !toggled.isActive)
        toggled.isMain = true;
      toggled.isActive = !toggled.isActive;
      this.setState({ bankAccounts, isProcessing: false });
    } catch (error) {
      console.error(error);
    }
  }

  deleteBankAccount = async (bankAccountId: number) => {
    this.setState({ bankAccountToDeleteId: bankAccountId });
  }

  cancelDelete = () => {
    this.setState({ bankAccountToDeleteId: 0 });
  }

  onDeleteSuccess = async () => {
    const bankAccounts = this.state.bankAccounts;
    const deleted = bankAccounts.find(b => b.id === this.state.bankAccountToDeleteId);
    deleted.isDeleted = true;
    NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS, 'page.bankaccount.deleted');
    this.setState({ bankAccounts, bankAccountToDeleteId: 0, isProcessing: false });
  }

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <CompanyBankAccountsViewComponent
        bankAccounts={this.state.bankAccounts}
        displayBankAccountToBeValidatedMessage={this.state.displayBankAccountToBeValidatedMessage}
        isDialogOpen={this.state.isDialogOpen}
        isProcessing={this.state.isProcessing}
        changeMainBankAccount={this.changeMainBankAccount}
        closeDialog={this.closeDialog}
        toggleEnableBankAccount={this.toggleEnableBankAccount}
        onAddBankAccountSuccess={this.onAddBankAccountSuccess}
        openDialog={this.openDialog}
        deleteBankAccount={this.deleteBankAccount}
        companyIsActive={this.props.activeCompanyIsActive}
        bankAccountToDeleteId={this.state.bankAccountToDeleteId}
        onDeleteSuccess={this.onDeleteSuccess}
        cancelDelete={this.cancelDelete}
      />
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id,
  activeCompanyIsActive: state.auth.user.activeCompany.isActive
});

export default connect(mapStateToProps)(CompanyBankAccountsComponent);
