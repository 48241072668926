/* @flow */

export default class SetInvoiceContactPersonsRequest {
  invoiceId: number;
  contactPersonIds: number;

  constructor(invoiceId: number, contactPersonIds: number[]) {
    this.invoiceId = invoiceId;
    this.contactPersonIds = contactPersonIds;
  }
}
