/* @flow */

import React from 'react';

import CompanyUbosComponent from 'app/companies/ubos';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function CompanyUbosPage() {
  return (
    <WebsitePageComponent id="company.ubos.title" data-walkme="ubo-title-beneficiaires" noPaper>
      <CompanyUbosComponent />
    </WebsitePageComponent>
  );
}
