/* @flow */

import React from 'react';
import { connect } from 'react-redux';

import IntlMessageComponent from 'components/formatters/intlMessage';
import WizardComponent from 'components/wizard';

import ISORegisterSellersTab1Component from './tab1/index';
import ISORegisterSellersTab2Component from './tab2/index';
import ISORegisterSellersTab3Component from './tab3/index';
import ISORegisterSellersTab4Component from './tab4/index';
import ISORegisterSellersTab5Component from './tab5/index';

type ISORegisterSellersComponentProps = {
  activeCompanyId: number;
}

type ISORegisterSellersComponentState = {
  activeStepId: number;
  companyId: number;
  companyPersonId: number;
  steps: {id: number, title: IntlMessageComponent}[];
}

class ISORegisterSellersComponent extends React.Component<ISORegisterSellersComponentProps, ISORegisterSellersComponentState> {
  static TAB1 = 0;
  static TAB2 = 1;
  static TAB3 = 2;
  static TAB4 = 3;
  static TAB5 = 4;

  constructor(props) {
    super(props);

    this.state = {
      activeStepId: ISORegisterSellersComponent.TAB1,
      companyId: 0,
      companyPersonId: 0,
      steps: this.getSteps()
    };
  }

  getSteps = () => {
    const tabsNumber = [ISORegisterSellersComponent.TAB1, ISORegisterSellersComponent.TAB2, ISORegisterSellersComponent.TAB3, ISORegisterSellersComponent.TAB4, ISORegisterSellersComponent.TAB5];
    return tabsNumber.map(num => ({id: num, title: <IntlMessageComponent id={`page.iso.company.register.tab${num}.header`} />}));
  }

  getStepContent = () => {
    switch (this.state.activeStepId) {
      case ISORegisterSellersComponent.TAB1:
        return (
          <ISORegisterSellersTab1Component
            successHandler={this.successHandlerTab1}
          />
        );
      case ISORegisterSellersComponent.TAB2:
        return (
          <ISORegisterSellersTab2Component
            companyId={this.state.companyId}
            successHandler={this.successHandler}
          />
        );
      case ISORegisterSellersComponent.TAB3:
        return (
          <ISORegisterSellersTab3Component
            companyId={this.state.companyId}
            companyPersonId={this.state.companyPersonId}  
            successHandler={this.successHandler}
          />
        );
      case ISORegisterSellersComponent.TAB4:
        return (
          <ISORegisterSellersTab4Component
            companyId={this.state.companyId}
            companyPersonId={this.state.companyPersonId}
            successHandler={this.successHandler}
          />
        );
      case ISORegisterSellersComponent.TAB5:
        return (
          <ISORegisterSellersTab5Component />
        );
      default:
        return 'Unknown step';
    }
  };

  successHandlerTab1 = (companyId: number, companyPersonId: number) =>
    this.setState({activeStepId: ISORegisterSellersComponent.TAB2, companyId, companyPersonId});
  successHandler = () => this.setState(prevState => ({activeStepId: prevState.activeStepId + 1 }));

  render() {
    return (
      <WizardComponent
        activeStepId={this.state.activeStepId}
        steps={this.state.steps}
      >
        {this.getStepContent()}
      </WizardComponent>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  activeCompanyId: auth.user.activeCompany.id
});

export default connect(mapStateToProps)(ISORegisterSellersComponent);

