/* @flow */

import React from 'react';
import { useHistory } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import { PROTECTED_DEBTOR_DASHBOARD } from 'constants/pageRoutes';

export default function ConfirmingAddSupplierSuccessViewComponent() {
  const history = useHistory();

  return (
    <React.Fragment>
      <Paper elevation={0}>
        <Box p={5}>
          <PageSubTitleViewComponent id="debtor.registerSupplier.success.title" data-walkme="debtor-registerSupplier-success-title" />

          <Typography variant="body1">
            <IntlMessageComponent id="debtor.registerSupplier.success.text" />
          </Typography>

          <Box mt={5}>
            <ButtonComponent
              color="primary"
              variant="contained"
              onClick={() => history.push(PROTECTED_DEBTOR_DASHBOARD)}
            ><IntlMessageComponent id="debtor.registerSupplier.success.btn" /></ButtonComponent>
          </Box>
        </Box>
      </Paper>
    </React.Fragment>
  );
}
