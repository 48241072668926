/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import PhoneNumberComponent from 'components/formFields/phoneNumber';
import ButtonComponent from 'components/buttons/button';
import CompanyLookupSellerComponent from 'components/companyLookupV2/sellers';
import FormErrorsComponent from 'components/formErrors';
import IntlMessageComponent from 'components/formatters/intlMessage';
import LanguageFieldComponent from 'components/formFields/types/language';
import MandatoryFormFieldsComponent from 'components/form/mandatory';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import TitleFieldComponent from 'components/formFields/types/title';

import RegisterSupplierForm from './model';

type DebtorRegisterSupplierTab1ViewComponentProps = {
  errors: Map<string, ?string>;
  form: RegisterSupplierForm;
  formErrors: string[];
  isProcessing: boolean;
  handleChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void;
  handleCompanyChange: () => void;
  handleTypeChange: (fieldName: string) => (id: number) => void;
  handlePhoneChange: (value: string) => void;
  submitForm: () => void;
}

export default function DebtorRegisterSupplierTab1ViewComponent(props: DebtorRegisterSupplierTab1ViewComponentProps) {
  return (
    <React.Fragment>
      <FormErrorsComponent formErrors={props.formErrors} />

      <Box mt={3}>
        <Grid container spacing={6} alignItems="stretch">
          <Grid item xs={12} md={6}>
            <PageSubTitleViewComponent id="debtor.registerSupplier.tab1.company.title" data-walkme="debtor-registerSupplier-tab1-company-title" />

            <Box mt={3}>
              <CompanyLookupSellerComponent
                company={props.form.company}
                companyErrorLabelId={props.errors.get('company')}
                handleSelect={props.handleCompanyChange}
                required
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <PageSubTitleViewComponent id="debtor.registerSupplier.tab1.person.title" data-walkme="debtor-registerSupplier-tab1-person-title" />

            <Box mt={3} px={4} py={3}>
              <TitleFieldComponent
                data-walkme="debtor-registerSupplier-tab1-person-title"
                label={<IntlMessageComponent id="form.title.label" />}
                error={!!props.errors.get('titleId')}
                helperText={(props.errors.get('titleId')) ?
                  <IntlMessageComponent id={props.errors.get('titleId')} /> : ''}
                value={props.form.titleId}
                changeHandler={props.handleTypeChange('titleId')}
                fullWidth
                margin="normal"
                required
              />

              <TextField
                id="form.name"
                data-walkme="debtor-registerSupplier-tab1-person-lastName"
                label={<IntlMessageComponent id="form.name.label" />}
                error={!!props.errors.get('lastName')}
                helperText={(props.errors.get('lastName')) ?
                  <IntlMessageComponent id={props.errors.get('lastName')} /> : ''}
                value={props.form.lastName}
                onChange={props.handleChange('lastName')}
                fullWidth
                margin="normal"
                required
              />

              <TextField
                id="form.firstName"
                data-walkme="debtor-registerSupplier-tab1-person-firstName"
                label={<IntlMessageComponent id="form.firstname.label" />}
                error={!!props.errors.get('firstName')}
                helperText={(props.errors.get('firstName')) ?
                  <IntlMessageComponent id={props.errors.get('firstName')} /> : ''}
                value={props.form.firstName}
                onChange={props.handleChange('firstName')}
                fullWidth
                margin="normal"
                required
              />

              <TextField
                id="form.email"
                data-walkme="debtor-registerSupplier-tab1-person-email"
                label={<IntlMessageComponent id="form.email.label" />}
                error={!!props.errors.get('email')}
                helperText={(props.errors.get('email')) ?
                  <IntlMessageComponent id={props.errors.get('email')} /> : ''}
                value={props.form.email}
                onChange={props.handleChange('email')}
                fullWidth
                margin="normal"
                required
              />

              <PhoneNumberComponent
                id="form.phone"
                data-walkme="debtor-registerSupplier-tab1-person-phone"
                label={<IntlMessageComponent id="form.phone.label" />}
                error={!!props.errors.get('phone')}
                helperText={(props.errors.get('phone'))
                  ? <IntlMessageComponent id={props.errors.get('phone')} /> : ''}
                value={props.form.phone}
                onChange={props.handlePhoneChange}
                fullWidth
                margin="normal"
                required
              />

              <LanguageFieldComponent
                data-walkme="debtor-registerSupplier-tab1-person-language"
                label={<IntlMessageComponent id="form.language.label" />}
                error={!!props.errors.get('languageId')}
                helperText={(props.errors.get('languageId')) ?
                  <IntlMessageComponent id={props.errors.get('languageId')} /> : ''}
                value={props.form.languageId}
                changeHandler={props.handleTypeChange('languageId')}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
         
      <Box mt={5}>
        <ButtonComponent
          color="primary"
          variant="contained"
          isProcessing={props.isProcessing}
          onClick={props.submitForm}
        ><IntlMessageComponent id="general.button.validate.label" /></ButtonComponent>

        <MandatoryFormFieldsComponent/>
      </Box>
    </React.Fragment>
  );
}