/* @flow */

import Company from 'models/companies/company';
import DebtorCompany from 'models/companies/debtorCompany';

export default class InvoiceInfo {
  debtorCompany: DebtorCompany;
  suppliercompany: Company;
  maxDaysToSell: number;
  minDaysToSell: number;
  minDebtAmount: number;
}
