import ReactGA from 'react-ga';

export const initializeGA = () => {
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING);
};

export const logPageView = (pageUri) => {
  ReactGA.set({ page: pageUri });
  ReactGA.pageview(pageUri);
};

export const logEvent = (category: string, action: string, label: string, value: number) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
    value: value
  });
};