/* @flow */

import React from 'react';
import {useSelector} from 'react-redux';

import OfferTab1ISOComponent from './iso/index';
import OfferTab1LeadComponent from './lead/index';

import {ROLE_ISO} from 'constants/companyRoles';

type OfferTab1ComponentProps = {
  successHandler: (leadId: number) => void;
}


export default function OfferTab1Component(props: OfferTab1ComponentProps) {
  const activeCompanyId = useSelector(state => state.auth.user !== null ? state.auth.user.activeCompany.id : 0);
  const activeCompanyRole = useSelector(state => state.auth.user !== null ? state.auth.user.activeCompany.role : 0);

  if (activeCompanyId > 0 && activeCompanyRole === ROLE_ISO) {
    return (<OfferTab1ISOComponent activeCompanyId={activeCompanyId} successHandler={props.successHandler} />);
  } else {
    return (<OfferTab1LeadComponent successHandler={props.successHandler} />);
  }
}
