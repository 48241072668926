/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';
import UploadFileFieldComponent from 'components/formFields/upload/index';

import CommissionDocumentSelectComponent from './select';

import Document from 'models/documents/v2/document';
import RequiredCommissionDocument from 'models/documents/v2/requiredCommissionDocument';

type CompanyDocumentsUploadCommissionInvoiceDialogViewComponentProps = {
  commissionId: number;
  document: Document;
  documents: RequiredCommissionDocument[];
  isLoading: boolean;
  isProcessing: boolean;
  handleDocumentSelect: (file: File) => void;
  handleDocumentSelectFailure: (file: File) => void;
  handleDocumentRemove: () => void;
  onClose: () => void;
  onCommissionIdChange: (index: number) => void;
  onSubmit: () => void;
}

export default function CompanyDocumentsUploadCommissionInvoiceDialogViewComponent(props: CompanyDocumentsUploadCommissionInvoiceDialogViewComponentProps) {
  return (
    <Dialog open onClose={props.onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <IntlMessageComponent id="component.company.commission.dialog.title" />
      </DialogTitle>

      {(props.isLoading || props.isProcessing) ? (
        <DialogContent>
          <Box align="center" p={5}>
            <CircularProgress size={60} />
          </Box>
        </DialogContent>
      ) : (
        <React.Fragment>
          <DialogContent>
            {props.documents.length === 0 ? (
              <IntlMessageComponent id="component.company.commission.dialog.empty" />
            ) : (
              <React.Fragment>
                <CommissionDocumentSelectComponent
                  commissionId={props.commissionId}
                  documents={props.documents}
                  changeHandler={props.onCommissionIdChange}
                  fullWidth
                  margin="normal"
                  required
                />
                
                {props.commissionId > 0 &&
                  <UploadFileFieldComponent
                    document={props.document}
                    successHandler={props.handleDocumentSelect}
                    failHandler={props.handleDocumentSelectFailure}
                    removeHandler={props.handleDocumentRemove}
                  />
                }
              </React.Fragment>
            )}           
          </DialogContent>

          <DialogActions>
            <ButtonComponent
              color="default"
              onClick={props.onClose}
            ><IntlMessageComponent id="general.cancel" /></ButtonComponent>

            {props.documents.length > 0 &&
              <ButtonComponent
                color="primary"
                disabled={props.commissionId <= 0 || props.document.file === null}
                onClick={props.onSubmit}
              ><IntlMessageComponent id="general.submit" /></ButtonComponent>
            }
          </DialogActions>
        </React.Fragment>
      )}
    </Dialog>
  );
}