/* @flow */

export default class StartSignTosTransactionRequest {
  companyId: number;
  role: number;
  returnSuccessUrl: string;
  returnFailUrl: string;
  returnCancelUrl: string;

  constructor(cId: number, role: number, sUrl: string, fUrl: string, cUrl: string) {
    this.companyId = cId;
    this.role = role;
    this.returnSuccessUrl = sUrl;
    this.returnFailUrl = fUrl;
    this.returnCancelUrl = cUrl;
  }
}
