/* @flow */

import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';

import LoadingComponent from 'components/loading';

import CompanyMembershipsViewComponent from './view';

import CompanyMembership from 'models/companies/companyMembership';
import RenewCompanyMembershipRequest from 'models/requests/companies/renewCompanyMembershipRequest';

import CompanyService from 'services/CompanyService';

import NotificationHelper from 'lib/notifications';

type CompanyMembershipsComponentProps = {
  activeCompanyId: number;
  isActive: boolean;
  isCompliant: boolean;
  hasSignedGeneralConditionsAndHasProcuration: boolean;
}

type CompanyMembershipsComponentState = {
  isLoading: boolean;
  isProcessing: boolean;
  memberships: CompanyMembership[];
  showPayment: boolean;
  showRenewButton: boolean;
  paymentMethod: string;
  isUnpaid: boolean;
  isExpired: boolean;
}

class CompanyMembershipsComponent extends React.Component<CompanyMembershipsComponentProps, CompanyMembershipsComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isProcessing: false,
      memberships: null,
      showPayment: false,
      showRenewButton: false,
      isUnpaid: false,
      isExpired: false
    };
  }

  componentDidMount = async () => {
    this.loadMemberships();
  };

  reloadMemberships = async () => {
    this.setState({isLoading: true, isProcessing: false, memberships: null, showPayment: false, showRenewButton: false});
    this.loadMemberships();
  };

  loadMemberships = async (paymentMethod: string) => {
    try {
      const memberships = await CompanyService.getMemberships(this.props.activeCompanyId, paymentMethod);
      const showPayment = /*this.props.isActive && this.props.isCompliant && */memberships.length > 0 && memberships[memberships.length - 1].paymentInfo;
      const showRenewButton = /*this.props.hasSignedGeneralConditionsAndHasProcuration && */memberships.length === 1
        && (!memberships[0].isValid || (moment().isAfter(moment(memberships[0].validFrom).add(1, 'month'), 'day')));
      const isUnpaid = !memberships[memberships.length - 1].isPaid;
      const isExpired = !memberships[0].isValid || (moment().isAfter(moment(memberships[0].validTo), 'day'));

      this.setState({isLoading: false, memberships, showPayment, showRenewButton, paymentMethod, isUnpaid, isExpired});
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  };

  renewMembership = async () => {
    this.setState({isProcessing: true});

    try {
      const request = new RenewCompanyMembershipRequest(this.props.activeCompanyId);
      await CompanyService.renewCompanyMembership(request);
      this.reloadMemberships();
    } catch (e) {
      this.setState({isProcessing: false});
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  };

  handleChoosePayment = (paymentMethod: string) => {
    this.loadMemberships(paymentMethod)
  };

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <CompanyMembershipsViewComponent
        isProcessing={this.state.isProcessing}
        memberships={this.state.memberships}
        showPayment={this.state.showPayment}
        showRenewButton={this.state.showRenewButton}
        paymentMethod={this.state.paymentMethod}
        isUnpaid={this.state.isUnpaid}
        isExpired={this.state.isExpired}
        handleChoosePayment={this.handleChoosePayment}
        reloadMemberships={this.reloadMemberships}
        renewMembership={this.renewMembership}
      />
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id,
  isActive: state.auth.user.activeCompany.isActive,
  isCompliant: state.auth.user.activeCompany.isCompliant,
  hasSignedGeneralConditionsAndHasProcuration: state.auth.user.activeCompany.hasSignedGeneralConditionsAndHasProcuration
});

export default connect(mapStateToProps)(CompanyMembershipsComponent);
