/* @flow */

import React from 'react';
import moment from 'moment/moment';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import CountryFieldComponent from 'components/formFields/countries';
import DatePickerComponent from 'components/formFields/dates/datePicker';
import IntlMessageComponent from 'components/formatters/intlMessage';
import LanguageFieldComponent from 'components/formFields/types/language';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import TitleFieldComponent from 'components/formFields/types/title';

import CompanyUserForm from '../model';

type CompanyUsersFormPersonalInfoPanelComponentProps = {
  defaultFieldOptions: any;
  errors: Map<string, ?string>;
  form: CompanyUserForm;
  handleChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void;
  handleDateChange: (fieldName: string) => (date: moment) => void;
  handleTypeChange: (fieldName: string) => (id: number) => void;
}

export default function CompanyUsersFormPersonalInfoPanelComponent(props: CompanyUsersFormPersonalInfoPanelComponentProps) {
  const minDateBirth = moment('01-01-1900', 'MM-DD-YYYY');
  const maxDateBirth = moment().subtract(18, 'y').startOf('d');

  return (
    <React.Fragment>
      <PageSubTitleViewComponent id="page.profile.users.personalinfo" />

      <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
        <Grid item xs={12} md={6}>
          <Box px={4}>
            <TitleFieldComponent
              label={<IntlMessageComponent id="form.title.label" />}
              error={!!props.errors.get('titleId')}
              helperText={(props.errors.get('titleId')) ?
                <IntlMessageComponent id={props.errors.get('titleId')} /> : ''}
              value={props.form.titleId}
              changeHandler={props.handleTypeChange('titleId')}
              fullWidth
              margin="normal"
              required
            />
          </Box>
        </Grid>
      </Grid>
      
      <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
        <Grid item xs={12} md={6}>
          <Box px={4}>
            <TextField
              id="form.name"
              data-walkme="subscription-field-nom"
              label={<IntlMessageComponent id="form.name.label" />}
              error={!!props.errors.get('lastName')}
              helperText={props.errors.get('lastName') ?
                <IntlMessageComponent id={props.errors.get('lastName')} /> : ''}
              onChange={props.handleChange('lastName')}
              value={props.form.lastName}
              fullWidth
              margin="normal"
              required
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box px={4}>
            <TextField
              id="form.firstName"
              data-walkme="subscription-field-prenom"
              label={<IntlMessageComponent id="form.firstname.label" />}
              error={!!props.errors.get('firstName')}
              helperText={props.errors.get('firstName') ?
                <IntlMessageComponent id={props.errors.get('firstName')} /> : ''}
              onChange={props.handleChange('firstName')}
              value={props.form.firstName}
              fullWidth
              margin="normal"
              required
            />
          </Box>
        </Grid>
      </Grid>

      <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
        <Grid item xs={12} md={6}>
          <Box px={4}>
            <CountryFieldComponent
              id="form.nationality"
              label={<IntlMessageComponent id="form.nationality.label" />}
              error={!!props.errors.get('nationalityId')}
              helperText={props.errors.get('nationalityId') ?
                <IntlMessageComponent id={props.errors.get('nationalityId')} /> : ''}
              value={props.form.nationalityId}
              changeHandler={props.handleTypeChange('nationalityId')}
              fullWidth
              margin="normal"
              required
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box px={4}>
            <DatePickerComponent
              id="form.birthDate"
              label={<IntlMessageComponent id="form.birthDate.label" />}
              error={!!props.errors.get('birthDate')}
              helperText={props.errors.get('birthDate') ?
                <IntlMessageComponent id={props.errors.get('birthDate')} /> : ''}
              onChange={props.handleDateChange('birthDate')}
              value={props.form.birthDate}
              minDate={minDateBirth}
              maxDate={maxDateBirth}
              fullWidth
              margin="normal"
              required
            />
          </Box>
        </Grid>
      </Grid>

      <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
        <Grid item xs={12} md={6}>
          <Box px={4}>
            <CountryFieldComponent
              id="form.birthCountry"
              label={<IntlMessageComponent id="form.birthCountry.label" />}
              error={!!props.errors.get('birthCountryId')}
              helperText={props.errors.get('birthCountryId') ?
                <IntlMessageComponent id={props.errors.get('birthCountryId')} /> : ''}
              value={props.form.birthCountryId}
              changeHandler={props.handleTypeChange('birthCountryId')}
              fullWidth
              margin="normal"
              required
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box px={4}>
            <TextField
              id="form.birthPlace"
              data-walkme="subscription-field-birthPlace"
              label={<IntlMessageComponent id="form.birthPlace.label" />}
              error={!!props.errors.get('birthPlace')}
              helperText={props.errors.get('birthPlace') ?
                <IntlMessageComponent id={props.errors.get('birthPlace')} /> : ''}
              onChange={props.handleChange('birthPlace')}
              value={props.form.birthPlace}
              fullWidth
              margin="normal"
              required
            />
          </Box>
        </Grid>
      </Grid>

      <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
        <Grid item xs={12} md={6}>
          <Box px={4}>
            <LanguageFieldComponent
              label={<IntlMessageComponent id="form.language.label" />}
              error={!!props.errors.get('languageId')}
              helperText={(props.errors.get('languageId')) ?
                <IntlMessageComponent id={props.errors.get('languageId')} /> : ''}
              value={props.form.languageId}
              changeHandler={props.handleTypeChange('languageId')}
              fullWidth
              margin="normal"
              required
            />
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
