/* @flow */

import React from 'react';
import {useHistory, useLocation} from 'react-router-dom';

import BuyerTransferInvoicesComponent from 'app/buyers/transferInvoices';
import WebsitePageComponent from 'components/websiteStructure/pages';

import {PROTECTED_BUYER_DASHBOARD} from 'constants/pageRoutes';

export default function BuyerInvoicesToPurchasePage() {
  const history = useHistory();
  const location = useLocation();

  const invoiceIds = location.state?.invoiceIds || [];
  if (invoiceIds.length === 0) {
    history.push(PROTECTED_BUYER_DASHBOARD);
    return null;
  }

  return (
    <WebsitePageComponent id="buyer.transfer.title" data-walkme="buyer-transfer-title">
      <BuyerTransferInvoicesComponent invoiceIds={invoiceIds} />
    </WebsitePageComponent>
  );
}
