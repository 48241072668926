const documentlanguageData = [
    {
      languageId: 18,
      locale: 'en-US',
      name: 'English',
      icon: 'us',
      dir: 'USEN'
    },
    {
      languageId: 19,
      locale: 'fr-FR',
      name: 'French',
      icon: 'fr',
      dir: 'FRFR'
    },
    {
      languageId: 19,
      locale: 'fr-BE',
      name: 'French (BE)',
      icon: 'be',
      default: true,
      dir: 'BEFR'
    },
    {
      languageId: 19,
      locale: 'fr-LU',
      name: 'French (LU)',
      icon: 'lu',
      dir: 'LUFR'
    },
    {
      languageId: 17,
      locale: 'nl-NL',
      name: 'Dutch',
      icon: 'nl',
      dir: 'NLNL'
    },
    {
      languageId: 17,
      locale: 'nl-BE',
      name: 'Dutch (BE)',
      icon: 'be',
      dir: 'BENL'
    },
    {
      languageId: 1967,
      locale: 'pt-PT',
      name: 'Portuguese',
      icon: 'pt',
      dir: 'PTPT'
    },
    {
      languageId: 4005,
      locale: 'es-ES',
      name: 'Spanish',
      icon: 'es',
      dir: 'ESES'
    }
  ];
  export default documentlanguageData;
  