/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import LoadingComponent from 'components/loading';

import ISOCouponFormViewComponent from './view';
import IsoCouponForm from './model';

import IsoService from 'services/IsoService';
import {handleApiFormResponse, handleFormChange, handleFormValidation} from 'lib/forms';
import loadConstraints from 'lib/validation/loadConstraints';

import NotificationHelper from 'lib/notifications';

type ISOCouponFormComponentProps = {
  activeCompanyId: number;
  maximumCouponAmount: number;
  successHandler: () => void;
  cancelHandler: () => void;
};

type ISOCouponFormComponentState = {
  constraints: any;
  errors: Map<string,?string>;
  form: IsoCouponForm;
  formErrors: string[];
  isLoading: boolean;
  isProcessing: boolean;
};

class ISOCouponFormComponent extends React.Component<ISOCouponFormComponentProps, ISOCouponFormComponentState> {

  constructor(props) {
    super(props);
    this.state = {
      constraints: null,
      errors: null,
      form: new IsoCouponForm(),
      formErrors: [],
      isLoading: true,
      isProcessing: false
    };
  }

  componentDidMount = async () => {
    try {
      const constraints = await loadConstraints('Coupon', ['company', 'amount', 'reason']);
      constraints.amount.numericality.lessThanOrEqualTo = this.props.maximumCouponAmount;

      this.setState({
        constraints,
        errors: new Map(Object.keys(constraints).map(e => [e, undefined])),
        isLoading: false
      });
    } catch (e) {
      console.error(e);
    }
  }

  handleChange = (fieldName: string) => (event) => handleFormChange.call(this, fieldName, event.target.value);

  handleCompanyChange = (company: Company) => {
    const form = this.state.form;
    form.company = company;

    const errors = this.state.errors;
    errors.delete('company');
    this.setState({errors, form});
  };

  validateForm = () => handleFormValidation.call(this);

  submitForm = async () => {
    if (!this.validateForm()) return;

    this.setState({formErrors: [], isProcessing: true});

    try {
      const request = this.state.form.toRequest(this.props.activeCompanyId);
      await IsoService.createCoupon(request);
      NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS);
      this.props.successHandler();
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }
  }
  
  render() {
    if (this.state.isLoading) return <LoadingComponent />;

    return (
      <ISOCouponFormViewComponent
        errors={this.state.errors}
        form={this.state.form}
        formErrors={this.state.formErrors}
        isProcessing={this.state.isProcessing}
        maximumCouponAmount={this.props.maximumCouponAmount}
        cancelHandler={this.props.cancelHandler}
        handleChange={this.handleChange}
        handleCompanyChange={this.handleCompanyChange}
        submitForm={this.submitForm}
      />
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id,
  maximumCouponAmount: state.auth.user.activeCompany.roleISO.maximumCouponAmount
});

export default connect(mapStateToProps)(ISOCouponFormComponent);
