/* @flow */

export default class InvoiceFeesPaymentRequest {
  companyId: number;
  invoiceIds: number[];
  brand: string;
  paymentMethod: string;

  constructor(companyId: number, invoiceIds: number[], paymentMethod: string) {
    this.companyId = companyId;
    this.invoiceIds = invoiceIds;
    if (paymentMethod) {
      this.brand = paymentMethod;
      if (paymentMethod === 'iDEAL') {
        this.paymentMethod = paymentMethod;
      } else {
        this.paymentMethod = "CreditCard";
      }
    }
  }
}
