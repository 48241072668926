/* @flow */

import React from 'react';
import { useHistory } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

import AmountFormatterComponent from 'components/formatters/amount';
import DateFormatterComponent from 'components/formatters/date';
import DebtorGradeForBuyerComponent from 'components/debtorGrades/buyer';
import IntlMessageComponent from 'components/formatters/intlMessage';
import InvoiceNameComponent from 'components/invoice/name';
import PercentageFormatterComponent from 'components/formatters/percentage';
import StyledTableCellTextComponent from 'components/table/cellText';
import StyledTableCellNumbersComponent from 'components/table/cellNumbers';
import StyledTableHeadComponent from 'components/table/head';
import StyledTableRowComponent from 'components/table/row';
import TooltipComponent from 'components/toolTips/index';

import BuyerInvoice from 'models/buyers/invoices/invoice';

import { PROTECTED_BUYER_INVOICE_DETAILS } from 'constants/pageRoutes';

const transferInvoiceListColumnData = [
  { id: 'invoiceNbr', align: 'left', translationKey: 'buyer.transfer.il.invoiceId' },
  { id: 'financedAmount', translationKey: 'buyer.transfer.il.financedAmount' },
  { id: 'purchasePrice', translationKey: 'buyer.transfer.il.purchasePrice' },
  { id: 'buyerInsuranceFee', translationKey: 'buyer.transfer.il.buyerInsuranceFee' },
  { id: 'dueDate', translationKey: 'buyer.transfer.il.dueDate' },
  { id: 'irr', translationKey: 'buyer.transfer.il.irr' },
  { id: 'discount', translationKey: 'buyer.transfer.il.discountPct' },
  { id: 'insuredPct', align: 'left', translationKey: 'buyer.transfer.il.insuredPct' },
  { id: 'debtorInsurerRating', align: 'left', translationKey: 'buyer.transfer.il.insurerRating' }
];
const transferInvoiceListColumnMobileData = [
  { id: 'invoiceInfo', align: 'left', translationKey: 'buyer.transfer.il.invoiceInfo' },
];

type BuyerTransferInvoicesTableViewComponentProps = {
  invoices: BuyerInvoice[];
}

export default function BuyerTransferInvoicesTableViewComponent(props: BuyerTransferInvoicesTableViewComponentProps) {
  const history = useHistory();

  const handleClickRow = (id: number) => {
    history.push(`${PROTECTED_BUYER_INVOICE_DETAILS}/${id}`);
  };

  return (
    <Box component="div" overflow="auto">
      <div className='hiddenMobile'>
        <Table>
          <StyledTableHeadComponent data-walkme="buyerToPay-table-headline">
            <StyledTableRowComponent>
              {transferInvoiceListColumnData.map(column => (
                <StyledTableCellTextComponent padding="none" align={column?.align ?? 'right'} key={column.id}>
                  <IntlMessageComponent id={column.translationKey} />
                </StyledTableCellTextComponent>
              ))}
            </StyledTableRowComponent>
          </StyledTableHeadComponent>

          <TableBody bgcolor="white">
            {props.invoices.map(invoice => (
              <StyledTableRowComponent
                key={`invList-${invoice.id}`}
                hover
                onClick={() => handleClickRow(invoice.id)}
              >
                <StyledTableCellTextComponent padding="none">
                  <InvoiceNameComponent invoiceId={invoice.id} invoiceName={invoice.invoiceName} />
                </StyledTableCellTextComponent>

                <StyledTableCellNumbersComponent padding="none">
                  <AmountFormatterComponent amount={invoice.financedAmount} />
                </StyledTableCellNumbersComponent>

                <StyledTableCellNumbersComponent padding="none">
                  <b><AmountFormatterComponent amount={invoice.purchasePrice} /></b>
                </StyledTableCellNumbersComponent>

                <StyledTableCellNumbersComponent padding="none" pageName="Tooltip.Buyer.InvoiceColumn" elementName="BuyerInsuranceFee">
                  <TooltipComponent pageName="Tooltip.Buyer.InvoiceColumn" elementName="BuyerInsuranceFee">
                    <b><AmountFormatterComponent amount={invoice.buyerInsuranceFee} /></b>
                  </TooltipComponent>
                </StyledTableCellNumbersComponent>

                <StyledTableCellTextComponent padding="none">
                  <DateFormatterComponent date={invoice.dueDate} />
                </StyledTableCellTextComponent>

                <StyledTableCellNumbersComponent padding="none">
                  <PercentageFormatterComponent value={invoice.irr} />
                </StyledTableCellNumbersComponent>

                <StyledTableCellNumbersComponent padding="none">
                  <PercentageFormatterComponent value={invoice.discountPct} />
                </StyledTableCellNumbersComponent>

                <StyledTableCellTextComponent padding="none">
                  <PercentageFormatterComponent value={invoice.insuredPct} noFractionDigits />
                </StyledTableCellTextComponent>

                <StyledTableCellTextComponent padding="none">
                  <DebtorGradeForBuyerComponent value={invoice.debtorCompany.insurerRating} shortMessageWhenZero />
                </StyledTableCellTextComponent>
              </StyledTableRowComponent>
            ))}
          </TableBody>
        </Table>
      </div>
      <div className="hiddenDesktop">

        <Table>
          <StyledTableHeadComponent data-walkme="buyerToPay-table-headline">
            <StyledTableRowComponent>
              {transferInvoiceListColumnMobileData.map(column => (
                <StyledTableCellTextComponent padding="none" align={column?.align ?? 'right'} key={column.id}>
                  <IntlMessageComponent id={column.translationKey} />
                </StyledTableCellTextComponent>
              ))}
            </StyledTableRowComponent>
          </StyledTableHeadComponent>

          <TableBody bgcolor="white">
            {props.invoices.map(invoice => (
              <StyledTableRowComponent
                key={`invList-${invoice.id}`}
                hover
              >
                <StyledTableCellTextComponent padding="none">
                  <IntlMessageComponent id='buyer.transfer.il.invoiceId' />: <InvoiceNameComponent invoiceId={invoice.id} invoiceName={invoice.invoiceName} /><br/>
                  <IntlMessageComponent id='buyer.transfer.il.financedAmount' />: <AmountFormatterComponent amount={invoice.financedAmount} /><br/>
                  <IntlMessageComponent id='buyer.transfer.il.purchasePrice' />: <b><AmountFormatterComponent amount={invoice.purchasePrice} /></b><br/>
                  <IntlMessageComponent id='buyer.transfer.il.buyerInsuranceFee' />: <b><AmountFormatterComponent amount={invoice.buyerInsuranceFee} /></b><br/>
                  <IntlMessageComponent id='buyer.transfer.il.dueDate' />: <DateFormatterComponent date={invoice.dueDate} /><br/>
                  <IntlMessageComponent id='buyer.transfer.il.irr' />: <PercentageFormatterComponent value={invoice.irr} /><br/>
                  <IntlMessageComponent id='buyer.transfer.il.discountPct' />: <PercentageFormatterComponent value={invoice.discountPct} /><br/>
                  <IntlMessageComponent id='buyer.transfer.il.insuredPct' />: <PercentageFormatterComponent value={invoice.insuredPct} noFractionDigits /><br/>
                  <DebtorGradeForBuyerComponent value={invoice.debtorCompany.insurerRating} shortMessageWhenZero /><br/>
                </StyledTableCellTextComponent>
              </StyledTableRowComponent>
            ))}
          </TableBody>
        </Table>
      </div>
    </Box >
  );
}
