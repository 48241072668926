/* @flow */

import React from 'react';

import ISOCommissionsComponent from 'app/isos/commissions';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function ISOCommissionsPage() {
  return (
    <WebsitePageComponent id="page.iso.commissions.title" data-walkme="isocommission-title-commissions" fluidContainer noPaper>
      <ISOCommissionsComponent />
    </WebsitePageComponent>
  );
}
