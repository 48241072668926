/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import CountryCode from 'components/country/code';
import CountryName from 'components/country/name';
import CountryFlag from 'components/country/flag';

import Company from 'models/companies/company';

type CompanyAddressViewComponentProps = {
  company: Company;
  hideDetails: boolean;
  showFlag: boolean;
  hideIfNeeded: boolean;
}

export default function CompanyAddressViewComponent(props: CompanyAddressViewComponentProps) {
  

  return (
    <address>
      { (props.hideDetails == null || props.hideDetails === false) &&
      <Typography variant="body1">
        <Box component="span" color="success.main" fontWeight="fontWeightBold">
          {props.company.name}
          {props.company.supplierName}
          {process.env.REACT_APP_ENV_NAME !== 'PRO' && props.company.id === 0 &&
            <span>(EH)</span>
          }
          {props.showFlag && <React.Fragment> <CountryFlag countryId={props.company.countryId}/></React.Fragment> }
        </Box>
      </Typography>
      }

      {props.hideDetails == null &&
      <Typography variant="body1">
        <Box component="span" fontWeight="fontWeightBold">
          <CountryCode hideIfNeeded={props.hideIfNeeded} countryId={props.company.countryId} />
          {props.company.vatNumber}{props.company.vatNbr}
        </Box>
      </Typography>
      }

      {(props.company.address && props.hideDetails == null) &&
        <Typography variant="body1">
          <Box component="span" color="text.secondary" fontSize="small">
            {props.company.address}
          </Box>
        </Typography>
      }

      {(props.company.addressLine1 && props.hideDetails == null) &&
        <Typography variant="body1">
          <Box component="span" color="text.secondary" fontSize="small">
            {props.company.addressLine1}
          </Box>
        </Typography>
      }

      {(props.company.addressLine2 && props.hideDetails == null) &&
        <Typography variant="body1">
          <Box component="span" color="text.secondary" fontSize="small">
            {props.company.addressLine2}
          </Box>
        </Typography>
      }

      {((props.company.zipCode || props.company.city) && props.hideDetails == null) &&
        <Typography variant="body1">
          <Box component="span" color="text.secondary" fontSize="small">
            {props.company.zipCode} {props.company.city}
          </Box>
        </Typography>
      }

    {!props.showFlag && 
      <Typography variant="body1">
        <Box component="span" color="text.secondary" fontSize="small">
          <CountryName countryId={props.company.countryId} />
        </Box>
      </Typography>
    }
    </address>
  );
}
