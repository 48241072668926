import React from 'react';
import {Link} from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';

import {PROTECTED_APP} from 'constants/pageRoutes';

export default function WrongUrlComponent() {
  return (
    <Box textAlign="center" pt={10}>
      <Box color="primary.main" fontSize="6rem" fontWeight="fontWeightBold">
        <IntlMessageComponent id="page.error.wrongUrl.title" />
      </Box>

      <Box fontSize="2rem" fontWeight="fontWeightBold">
        <IntlMessageComponent id="page.error.wrongUrl.subtitle" />
      </Box>

      <Typography variant="body1">
        <IntlMessageComponent id="page.error.wrongUrl.text" />
      </Typography>

      <Box mt={5}>
        <ButtonComponent
          color="primary"
          variant="contained"
          component={Link}
          to={PROTECTED_APP}
        ><IntlMessageComponent id="page.error.wrongUrl.button" /></ButtonComponent>
      </Box>
    </Box>
  );
}