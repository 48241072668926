/* @flow */


import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import PhoneNumberComponent from 'components/formFields/phoneNumber';
import ButtonComponent from 'components/buttons/button';
import CountryFieldComponent from 'components/formFields/countries/index';
import DatePickerComponent from 'components/formFields/dates/datePicker';
import FormErrorsComponent from 'components/formErrors';
import IntlMessageComponent from 'components/formatters/intlMessage';
import LanguageFieldComponent from 'components/formFields/types/language';
import MandatoryFormFieldsComponent from 'components/form/mandatory';
import RegistrationTypeFieldComponent from 'components/formFields/types/registrationType';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import TitleFieldComponent from 'components/formFields/types/title';

import CompanyUserInfoForm from './model';

type RegisterSellerCompanyTab3ViewComponentProps = {
  errors: Map<string, ?string>;
  form: CompanyUserInfoForm;
  formErrors: string[];
  isProcessing: boolean;
  maxDateBirth: moment;
  minDateBirth: moment;
  maxRegistrationExpireDate: moment;
  minRegistrationExpireDate: moment;
  handleChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void;
  handleDateChange: () => void;
  handleTypeChange: (fieldName: string) => (id: number) => void;
  handlePhoneChange: (value: string) => void;
  handleMobileChange: (value: string) => void;
  submitForm: () => void;
}

export default function RegisterSellerCompanyTab3ViewComponent(props: RegisterSellerCompanyTab3ViewComponentProps) {
  return (
    <React.Fragment>
      <FormErrorsComponent formErrors={props.formErrors} />

      <PageSubTitleViewComponent
        id="page.iso.company.register.tab2.h2"
        data-walkme="registerseller3-title-personalinfo"
      />

      <Box mt={3}>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TitleFieldComponent
                label={<IntlMessageComponent id="form.title.label" />}
                error={!!props.errors.get('titleId')}
                helperText={(props.errors.get('titleId')) ?
                  <IntlMessageComponent id={props.errors.get('titleId')} /> : ''}
                value={props.form.titleId}
                changeHandler={props.handleTypeChange('titleId')}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>
        </Grid>

        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.name"
                data-walkme="subscription-field-nom"
                label={<IntlMessageComponent id="form.name.label" />}
                error={!!props.errors.get('lastName')}
                helperText={props.errors.get('lastName') ?
                  <IntlMessageComponent id={props.errors.get('lastName')} /> : ''}
                value={props.form.lastName}
                onChange={props.handleChange('lastName')}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.firstName"
                data-walkme="subscription-field-prenom"
                label={<IntlMessageComponent id="form.firstname.label" />}
                error={!!props.errors.get('firstName')}
                helperText={props.errors.get('firstName') ?
                  <IntlMessageComponent id={props.errors.get('firstName')} /> : ''}
                value={props.form.firstName}
                onChange={props.handleChange('firstName')}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>
        </Grid>

        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <CountryFieldComponent
                id="form.nationality"
                label={<IntlMessageComponent id="form.nationality.label" />}
                error={!!props.errors.get('nationalityId')}
                helperText={props.errors.get('nationalityId') ?
                  <IntlMessageComponent id={props.errors.get('nationalityId')} /> : ''}
                value={props.form.nationalityId}
                changeHandler={props.handleTypeChange('nationalityId')}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box px={4}>
              <DatePickerComponent
                id="form.birthDate"
                label={<IntlMessageComponent id="form.birthDate.label" />}
                error={!!props.errors.get('birthDate')}
                helperText={props.errors.get('birthDate') ?
                  <IntlMessageComponent id={props.errors.get('birthDate')} /> : ''}
                onChange={props.handleDateChange('birthDate')}
                value={props.form.birthDate}
                minDate={props.minDateBirth}
                maxDate={props.maxDateBirth}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>
        </Grid>


        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <CountryFieldComponent
                id="form.birthCountry"
                label={<IntlMessageComponent id="form.birthCountry.label" />}
                error={!!props.errors.get('birthCountryId')}
                helperText={props.errors.get('birthCountryId') ?
                  <IntlMessageComponent id={props.errors.get('birthCountryId')} /> : ''}
                value={props.form.birthCountryId}
                changeHandler={props.handleTypeChange('birthCountryId')}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.birthPlace"
                label={<IntlMessageComponent id="form.birthPlace.label" />}
                error={!!props.errors.get('birthPlace')}
                helperText={(props.errors.get('birthPlace')) ?
                  <IntlMessageComponent id={props.errors.get('birthPlace')} /> : ''}
                value={props.form.birthPlace}
                onChange={props.handleChange('birthPlace')}
                fullWidth
                margin="normal"
                required
              />   
            </Box>
          </Grid>
        </Grid>

        
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <LanguageFieldComponent
                label={<IntlMessageComponent id="form.language.label" />}
                error={!!props.errors.get('languageId')}
                helperText={props.errors.get('languageId') ?
                  <IntlMessageComponent id={props.errors.get('languageId')} /> : ''}
                value={props.form.languageId}
                changeHandler={props.handleChange('languageId')}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.functionName"
                data-walkme="inscrire-field-fonction"
                label={<IntlMessageComponent id="form.functionName" />}
                error={!!props.errors.get('functionName')}
                helperText={(props.errors.get('functionName')) ?
                  <IntlMessageComponent id={props.errors.get('functionName')} /> : ''}
                value={props.form.functionName}
                onChange={props.handleChange('functionName')}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box mt={10}>
      <PageSubTitleViewComponent
              id="page.iso.company.register.tab3.contactInfo.h2"
              data-walkme="registerseller3-title-contactinfo"
            />
      <Box mt={3}>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <PhoneNumberComponent
                id="form.mobile"
                data-walkme="registerseller3-title-mobileno"
                label={<IntlMessageComponent id="form.mobile.label" />}
                error={!!props.errors.get('mobile')}
                helperText={props.errors.get('mobile') ?
                  <IntlMessageComponent id={props.errors.get('mobile')} /> :
                  <IntlMessageComponent id="form.mobile.alreadySubscribed.helperText" />}
                value={props.form.mobile}
                onChange={props.handleMobileChange}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box px={4}>
              <PhoneNumberComponent
                id="form.phone"
                data-walkme="registerseller3-title-phonenumber"
                label={<IntlMessageComponent id="form.phone.label" />}
                error={!!props.errors.get('phone')}
                helperText={props.errors.get('phone') ?
                  <IntlMessageComponent id={props.errors.get('phone')} /> :
                  <IntlMessageComponent id="form.phone.helperText" />}
                value={props.form.phone}
                onChange={props.handlePhoneChange}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.email"
                label={<IntlMessageComponent id="form.email.label" />}
                error={!!props.errors.get('email')}
                helperText={props.errors.get('email') ?
                  <IntlMessageComponent id={props.errors.get('email')} /> : ''}
                value={props.form.email}
                onChange={props.handleChange('email')}
                disabled
                fullWidth
                margin="normal"
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.addressLine1"
                data-walkme="registerseller3-field-adress"
                label={<IntlMessageComponent id="form.person.addressLine1.label" />}
                error={!!props.errors.get('addressLine1')}
                helperText={props.errors.get('addressLine1') ?
                  <IntlMessageComponent id={props.errors.get('addressLine1')} /> : ''}
                value={props.form.addressLine1}
                onChange={props.handleChange('addressLine1')}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.addressLine2"
                label={<IntlMessageComponent id="form.person.addressLine2.label" />}
                error={!!props.errors.get('addressLine2')}
                helperText={props.errors.get('addressLine2') ?
                  <IntlMessageComponent id={props.errors.get('addressLine2')} /> : ''}
                value={props.form.addressLine2}
                onChange={props.handleChange('addressLine2')}
                fullWidth
                margin="normal"
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.zipCode"
                label={<IntlMessageComponent id="form.person.zipCode.label" />}
                error={!!props.errors.get('zipCode')}
                helperText={props.errors.get('zipCode') ?
                  <IntlMessageComponent id={props.errors.get('zipCode')} /> : ''}
                value={props.form.zipCode}
                onChange={props.handleChange('zipCode')}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.city"
                label={<IntlMessageComponent id="form.person.city.label" />}
                error={!!props.errors.get('city')}
                helperText={props.errors.get('city') ?
                  <IntlMessageComponent id={props.errors.get('city')} /> : ''}
                value={props.form.city}
                onChange={props.handleChange('city')}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <CountryFieldComponent
                id="form.country"
                label={<IntlMessageComponent id="form.person.country.label" />}
                error={!!props.errors.get('countryId')}
                helperText={props.errors.get('countryId') ?
                  <IntlMessageComponent id={props.errors.get('countryId')} /> : ''}
                value={props.form.countryId}
                changeHandler={props.handleTypeChange('countryId')}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>

      <Box mt={10}>
      <PageSubTitleViewComponent
              id="page.iso.company.register.tab3.identityDocument.h2"
              data-walkme="registerseller3-title-identityDocument"
            />

      <Box mt={3}>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <RegistrationTypeFieldComponent
                id="form.registrationType"
                label={<IntlMessageComponent id="form.registrationType.label" />}
                error={!!props.errors.get('registrationTypeId')}
                helperText={(props.errors.get('registrationTypeId')) ?
                  <IntlMessageComponent id={props.errors.get('registrationTypeId')} /> : ''}
                countryId={props.form.countryId}
                value={props.form.registrationTypeId}
                changeHandler={props.handleTypeChange('registrationTypeId')}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.registrationNumber"
                label={<IntlMessageComponent id="form.registrationNumber.label" />}
                error={!!props.errors.get('registrationNumber')}
                helperText={(props.errors.get('registrationNumber')) ?
                  <IntlMessageComponent id={props.errors.get('registrationNumber')} /> : ''}
                value={props.form.registrationNumber}
                onChange={props.handleChange('registrationNumber')}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>
        </Grid>

        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <DatePickerComponent
                id="form.registrationExpireDate"
                data-walkme="subscription-field-findevalidite"
                label={<IntlMessageComponent id="form.registrationExpireDate.label" />}
                error={!!props.errors.get('registrationExpireDate')}
                helperText={(props.errors.get('registrationExpireDate')) ?
                  <IntlMessageComponent id={props.errors.get('registrationExpireDate')} /> : ''}
                value={props.form.registrationExpireDate}
                onChange={props.handleDateChange('registrationExpireDate')}
                minDate={props.minRegistrationExpireDate}
                maxDate={props.maxRegistrationExpireDate}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
      <Box mt={5}>
        <ButtonComponent
          color="primary"
          variant="contained"
          isProcessing={props.isProcessing}
          onClick={props.submitForm}
        ><IntlMessageComponent id="general.continue" /></ButtonComponent>

        <MandatoryFormFieldsComponent/>
      </Box>
    </React.Fragment>
  );
}
