import React from 'react';

import Typography from '@material-ui/core/Typography';

import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';


export default function BlockedBuyerSignUpSuccessViewComponent() {
  return (
    <React.Fragment>

      <PageSubTitleViewComponent id="page.blockedBuyer.h1" />
      
      <Typography variant="body1">
        <IntlMessageComponent id="page.blockedBuyer.success" />
      </Typography>

    </React.Fragment>
  );
}