/* @flow */

import React from 'react';

import moment from 'moment';

import customSort from 'lib/sortHelpers';

import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

import AmountFormatterComponent from 'components/formatters/amount';
import IntlMessageComponent from 'components/formatters/intlMessage';
import QuarterFormatterComponent from 'components/formatters/quarter';
import StyledTableCellTextComponent from 'components/table/cellText';
import StyledTableCellNumbersComponent from 'components/table/cellNumbers';
import StyledTableHeadComponent from 'components/table/head';
import StyledTableRowComponent from 'components/table/row';

import ISOCommissionPaymentComponent from './commissionPayment';

import CommissionPayment from 'models/isos/commissions/commissionPayment';
import CommissionSummary from 'models/isos/commissions/commissionSummary';
import CommissionSummaryData from 'models/isos/commissions/commissionSummaryData';
import CommissionSummaryIsoCompany from 'models/isos/commissions/commissionSummaryIsoCompany';
import CommissionSummaryIsoOffice from 'models/isos/commissions/commissionSummaryIsoOffice';
import CommissionSummaryIsoPerson from 'models/isos/commissions/commissionSummaryIsoPerson';
import CommissionSummaryIsoQuarter from 'models/isos/commissions/commissionSummaryIsoQuarter';

type CommissionSummaryTableComponentProps = {
  payments: CommissionPayment[];
  summaries: CommissionSummary[];
}

export default function CommissionSummaryTableComponent(props: CommissionSummaryTableComponentProps) {

  const getPaymentForQuarter = (quarterDate: moment) => {
    return props.payments.find(p => quarterDate.isSame(moment(p.quarterDate)));
  }

  const getDataForQuarterInPerson = (person: CommissionSummaryIsoPerson, quarterDate: moment) => {
    return person.quarters.find(q => q.quarterDate.isSame(quarterDate));
  }

  const getDataForOfficeInQuarter = (quarter: CommissionSummaryIsoQuarter, companyId: number, officeid: number) => {
    const company = quarter.companies.find(c => c.id === companyId);
    if (company === undefined) return undefined;
    return company.offices.find(o => o.id === officeid);
  }

  const getDataForCompanyInQuarter = (quarter: CommissionSummaryIsoQuarter, companyId: number) => {
    return quarter.companies.find(c => c.id === companyId);
  }

  const reorganizeData = () => {
    const data = new CommissionSummaryData();

    // Create the data structure for the quarters
    for (let i = 0; i < props.summaries.length; i++) {
      const summary = props.summaries[i];
      const quarterDate = moment(summary.quarterDate);

      let isoQuarter = data.quarters.find(q => q.quarterDate.isSame(quarterDate));
      if (isoQuarter === undefined) {
        isoQuarter = new CommissionSummaryIsoQuarter(quarterDate);
        data.quarters.push(isoQuarter);
      }

      let isoCompany = isoQuarter.companies.find(c => c.id === summary.filterISOId);
      if (isoCompany === undefined) {
        isoCompany = new CommissionSummaryIsoCompany(summary.filterISOId, summary.isoCompany);
        isoQuarter.companies.push(isoCompany);
      }

      let isoOffice = isoCompany.offices.find(o => o.id === summary.filterISOOfficeId);
      if (isoOffice === undefined) {
        isoOffice = new CommissionSummaryIsoOffice(summary.filterISOOfficeId, summary.isoOffice);
        isoCompany.offices.push(isoOffice);
      }

      let isoPerson = isoOffice.persons.find(p => p.id === summary.filterISOPersonId);
      if (isoPerson === undefined) {
        isoPerson = new CommissionSummaryIsoPerson(summary.filterISOPersonId, summary.isoPerson);
        isoOffice.persons.push(isoPerson);
      }

      isoPerson.financedInvoiceCommission += summary.financedInvoiceCommission;
      isoPerson.unfinancedInvoiceCommission += summary.unfinancedInvoiceCommission;
      isoPerson.msfCommission += summary.msfCommission;
      isoPerson.membershipCommission += summary.membershipCommission;
      isoPerson.totalCommission += summary.totalCommission;
    }

    // Create the data structure for the companies
    for (let i = 0; i < props.summaries.length; i++) {
      const summary = props.summaries[i];
      const quarterDate = moment(summary.quarterDate);

      // Get all companies
      let isoCompany = data.companies.find(c => c.id === summary.filterISOId);
      if (isoCompany === undefined) {
        isoCompany = new CommissionSummaryIsoCompany(summary.filterISOId, summary.isoCompany);
        data.companies.push(isoCompany);
      }

      let isoOffice = isoCompany.offices.find(o => o.id === summary.filterISOOfficeId);
      if (isoOffice === undefined) {
        isoOffice = new CommissionSummaryIsoOffice(summary.filterISOOfficeId, summary.isoOffice);
        isoCompany.offices.push(isoOffice);
      }

      let isoPerson = isoOffice.persons.find(p => p.id === summary.filterISOPersonId);
      if (isoPerson === undefined) {
        isoPerson = new CommissionSummaryIsoPerson(summary.filterISOPersonId, summary.isoPerson);
        isoOffice.persons.push(isoPerson);
      }

      let isoQuarter = isoPerson.quarters.find(q => q.quarterDate.isSame(quarterDate));
      if (isoQuarter === undefined) {
        isoQuarter = new CommissionSummaryIsoQuarter(quarterDate);
        isoPerson.quarters.push(isoQuarter);
      }

      isoQuarter.financedInvoiceCommission += summary.financedInvoiceCommission;
      isoQuarter.unfinancedInvoiceCommission += summary.unfinancedInvoiceCommission;
      isoQuarter.msfCommission += summary.msfCommission;
      isoQuarter.membershipCommission += summary.membershipCommission;
      isoQuarter.totalCommission += summary.totalCommission;
    }

    data.companies = data.companies.sort((a, b) => (a.name < b.name ? -1 : 1));
    data.quarters = data.quarters.sort((a, b) => (a.quarterDate > b.quarterDate ? -1 : 1));

    // Subtotals for quarter
    for (let i = 0; i < data.quarters.length; i++) {
      for (let j = 0; j < data.quarters[i].companies.length; j++) {
        for (let k = 0; k < data.quarters[i].companies[j].offices.length; k++) {
          for (let l = 0; l < data.quarters[i].companies[j].offices[k].persons.length; l++) {
            // sub total per person
            data.quarters[i].companies[j].offices[k].financedInvoiceCommission += data.quarters[i].companies[j].offices[k].persons[l].financedInvoiceCommission;
            data.quarters[i].companies[j].offices[k].unfinancedInvoiceCommission += data.quarters[i].companies[j].offices[k].persons[l].unfinancedInvoiceCommission;
            data.quarters[i].companies[j].offices[k].msfCommission += data.quarters[i].companies[j].offices[k].persons[l].msfCommission;
            data.quarters[i].companies[j].offices[k].membershipCommission += data.quarters[i].companies[j].offices[k].persons[l].membershipCommission;
            data.quarters[i].companies[j].offices[k].totalCommission += data.quarters[i].companies[j].offices[k].persons[l].totalCommission;
          }

          // sub total per office
          data.quarters[i].companies[j].financedInvoiceCommission += data.quarters[i].companies[j].offices[k].financedInvoiceCommission;
          data.quarters[i].companies[j].unfinancedInvoiceCommission += data.quarters[i].companies[j].offices[k].unfinancedInvoiceCommission;
          data.quarters[i].companies[j].msfCommission += data.quarters[i].companies[j].offices[k].msfCommission;
          data.quarters[i].companies[j].membershipCommission += data.quarters[i].companies[j].offices[k].membershipCommission;
          data.quarters[i].companies[j].totalCommission += data.quarters[i].companies[j].offices[k].totalCommission;
        }

        // sub total per company
        data.quarters[i].financedInvoiceCommission += data.quarters[i].companies[j].financedInvoiceCommission;
        data.quarters[i].unfinancedInvoiceCommission += data.quarters[i].companies[j].unfinancedInvoiceCommission;
        data.quarters[i].msfCommission += data.quarters[i].companies[j].msfCommission;
        data.quarters[i].membershipCommission += data.quarters[i].companies[j].membershipCommission;
        data.quarters[i].totalCommission += data.quarters[i].companies[j].totalCommission;
      }
    }

    // Subtotals for companies
    for (let i = 0; i < data.companies.length; i++) {
      for (let j = 0; j < data.companies[i].offices.length; j++) {
        for (let k = 0; k < data.companies[i].offices[j].persons.length; k++) {
          for (let l = 0; l < data.companies[i].offices[j].persons[k].quarters.length; l++) {
            // sub total per person
            data.companies[i].offices[j].persons[k].financedInvoiceCommission += data.companies[i].offices[j].persons[k].quarters[l].financedInvoiceCommission;
            data.companies[i].offices[j].persons[k].unfinancedInvoiceCommission += data.companies[i].offices[j].persons[k].quarters[l].unfinancedInvoiceCommission;
            data.companies[i].offices[j].persons[k].msfCommission += data.companies[i].offices[j].persons[k].quarters[l].msfCommission;
            data.companies[i].offices[j].persons[k].membershipCommission += data.companies[i].offices[j].persons[k].quarters[l].membershipCommission;
            data.companies[i].offices[j].persons[k].totalCommission += data.companies[i].offices[j].persons[k].quarters[l].totalCommission;
          }

          // sub total per office
          data.companies[i].offices[j].financedInvoiceCommission += data.companies[i].offices[j].persons[k].financedInvoiceCommission;
          data.companies[i].offices[j].unfinancedInvoiceCommission += data.companies[i].offices[j].persons[k].unfinancedInvoiceCommission;
          data.companies[i].offices[j].msfCommission += data.companies[i].offices[j].persons[k].msfCommission;
          data.companies[i].offices[j].membershipCommission += data.companies[i].offices[j].persons[k].membershipCommission;
          data.companies[i].offices[j].totalCommission += data.companies[i].offices[j].persons[k].totalCommission;
        }

        // sub total per company
        data.companies[i].financedInvoiceCommission += data.companies[i].offices[j].financedInvoiceCommission;
        data.companies[i].unfinancedInvoiceCommission += data.companies[i].offices[j].unfinancedInvoiceCommission;
        data.companies[i].msfCommission += data.companies[i].offices[j].msfCommission;
        data.companies[i].membershipCommission += data.companies[i].offices[j].membershipCommission;
        data.companies[i].totalCommission += data.companies[i].offices[j].totalCommission;
      }

      // grand total
      data.financedInvoiceCommission += data.companies[i].financedInvoiceCommission;
      data.unfinancedInvoiceCommission += data.companies[i].unfinancedInvoiceCommission;
      data.msfCommission += data.companies[i].msfCommission;
      data.membershipCommission += data.companies[i].membershipCommission;
      data.totalCommission += data.companies[i].totalCommission;
    }

    // Sort companies, offices and persons
    for (let i = 0; i < data.companies.length; i++) {
      for (let j = 0; j < data.companies[i].offices.length; j++) {
        data.companies[i].offices[j].persons = data.companies[i].offices[j].persons.sort((a, b) => customSort(a.name, b.name));
      }
      data.companies[i].offices = data.companies[i].offices.sort((a, b) => customSort(a.name, b.name));
    }
    data.companies = data.companies.sort((a, b) => customSort(a.name, b.name));

    return data;
  }

  const data = reorganizeData();
  const borderStyle = {borderLeft: '1px solid rgba(224, 224, 224, 1)'};
  const columnCount = 7 + (data.quarters.length * 4);

  return (
    <React.Fragment>
      <Box py={5}>
        <Box component="div" overflow="auto">
          <Table>
            <StyledTableHeadComponent>
              <StyledTableRowComponent>
                <StyledTableCellTextComponent colSpan={3} padding="none" />

                {data.quarters.map((quarter, index) => (
                  <StyledTableCellTextComponent align="center" key={`quarter-header-${index}`} colSpan={4} padding="none" style={borderStyle}>
                    <QuarterFormatterComponent date={quarter.quarterDate} />
                    <ISOCommissionPaymentComponent payment={getPaymentForQuarter(quarter.quarterDate)} />
                  </StyledTableCellTextComponent>
                ))}

                <StyledTableCellTextComponent align="center" colSpan={4} padding="none" style={borderStyle} data-walkme="isocommission-column-grandtotal">
                  <IntlMessageComponent id="component.isos.commissions.list.header.grandTotal" />
                </StyledTableCellTextComponent>
              </StyledTableRowComponent>

              <StyledTableRowComponent>
                <StyledTableCellTextComponent align="left" padding="none" data-walkme="isocommission-column-isocompany"><IntlMessageComponent id="component.isos.commissions.list.header.isoCompany" /></StyledTableCellTextComponent>
                <StyledTableCellTextComponent align="left" padding="none" data-walkme="isocommission-column-isooffice"><IntlMessageComponent id="component.isos.commissions.list.header.isoOffice" /></StyledTableCellTextComponent>
                <StyledTableCellTextComponent align="left" padding="none" data-walkme="isocommission-column-isoperson"><IntlMessageComponent id="component.isos.commissions.list.header.isoPerson" /></StyledTableCellTextComponent>

                {data.quarters.map((_, index) => (
                  <React.Fragment key={`quarter-header2-${index}`}>
                    <StyledTableCellTextComponent align="right" padding="none" style={borderStyle} data-walkme="isocommission-column-financedinvoice"><IntlMessageComponent id="component.isos.commissions.list.header.financedinvoice" /></StyledTableCellTextComponent>
                    <StyledTableCellTextComponent align="right" padding="none" data-walkme="isocommission-column-msf"><IntlMessageComponent id="component.isos.commissions.list.header.msf" /></StyledTableCellTextComponent>
                    <StyledTableCellTextComponent align="right" padding="none" data-walkme="isocommission-column-membership"><IntlMessageComponent id="component.isos.commissions.list.header.membership" /></StyledTableCellTextComponent>
                    <StyledTableCellTextComponent align="right" padding="none" data-walkme="isocommission-column-total"><IntlMessageComponent id="component.isos.commissions.list.header.total" /></StyledTableCellTextComponent>
                  </React.Fragment>
                ))}

                <StyledTableCellTextComponent align="right" padding="none" style={borderStyle}><IntlMessageComponent id="component.isos.commissions.list.header.financedinvoice" /></StyledTableCellTextComponent>
                <StyledTableCellTextComponent align="right" padding="none"><IntlMessageComponent id="component.isos.commissions.list.header.msf" /></StyledTableCellTextComponent>
                <StyledTableCellTextComponent align="right" padding="none"><IntlMessageComponent id="component.isos.commissions.list.header.membership" /></StyledTableCellTextComponent>
                <StyledTableCellTextComponent align="right" padding="none"><IntlMessageComponent id="component.isos.commissions.list.header.total" /></StyledTableCellTextComponent>
              </StyledTableRowComponent>
            </StyledTableHeadComponent>

            <TableBody bgcolor="white">
              {props.summaries.length === 0 ? (
                <StyledTableRowComponent>
                  <StyledTableCellTextComponent align="center" colSpan={columnCount} padding="none">
                    <IntlMessageComponent id="component.isos.commissions.list.noData" />
                  </StyledTableCellTextComponent>
                </StyledTableRowComponent>
              ) : (
                <React.Fragment>
                  <StyledTableRowComponent selected>
                    <StyledTableCellTextComponent colSpan={3} padding="none"><b><IntlMessageComponent id="component.isos.commissions.list.grandTotal" /></b></StyledTableCellTextComponent>

                    {data.quarters.map((quarter, qIndex) => (
                      <React.Fragment key={`quarter-total-${qIndex}`}>
                        <StyledTableCellNumbersComponent padding="none" style={borderStyle}><b><AmountFormatterComponent amount={quarter.financedInvoiceCommission} /></b></StyledTableCellNumbersComponent>
                        <StyledTableCellNumbersComponent padding="none"><b><AmountFormatterComponent amount={quarter.msfCommission} /></b></StyledTableCellNumbersComponent>
                        <StyledTableCellNumbersComponent padding="none"><b><AmountFormatterComponent amount={quarter.membershipCommission} /></b></StyledTableCellNumbersComponent>
                        <StyledTableCellNumbersComponent padding="none"><b><AmountFormatterComponent amount={quarter.totalCommission} /></b></StyledTableCellNumbersComponent>
                      </React.Fragment>
                    ))}

                    <StyledTableCellNumbersComponent padding="none" style={borderStyle}><b><AmountFormatterComponent amount={data.financedInvoiceCommission} /></b></StyledTableCellNumbersComponent>
                    <StyledTableCellNumbersComponent padding="none"><b><AmountFormatterComponent amount={data.msfCommission} /></b></StyledTableCellNumbersComponent>
                    <StyledTableCellNumbersComponent padding="none"><b><AmountFormatterComponent amount={data.membershipCommission} /></b></StyledTableCellNumbersComponent>
                    <StyledTableCellNumbersComponent padding="none"><b><AmountFormatterComponent amount={data.totalCommission} /></b></StyledTableCellNumbersComponent>
                  </StyledTableRowComponent>

                  {data.companies.map((company, indexCompany) => (
                    <React.Fragment key={`summary-company-${indexCompany}`}>
                      {company.offices.map((office, indexOffice) => (
                        <React.Fragment key={`summary-office-${indexOffice}`}>
                          {office.persons.map((person, indexPerson) => (
                            <StyledTableRowComponent key={`summary-person-${indexPerson}`}>
                              <StyledTableCellTextComponent padding="none">{company.name}</StyledTableCellTextComponent>
                              <StyledTableCellTextComponent padding="none">{office.name}</StyledTableCellTextComponent>
                              <StyledTableCellTextComponent padding="none">{person.name}</StyledTableCellTextComponent>
                              {data.quarters.map((q, qIndex) => {
                                const quarter = getDataForQuarterInPerson(person, q.quarterDate);
                                return (
                                  <React.Fragment key={`quarter-person-${indexPerson}-${qIndex}`}>
                                    <StyledTableCellNumbersComponent padding="none" style={borderStyle}>{quarter && <AmountFormatterComponent amount={quarter.financedInvoiceCommission} />}</StyledTableCellNumbersComponent>
                                    <StyledTableCellNumbersComponent padding="none">{quarter && <AmountFormatterComponent amount={quarter.msfCommission} />}</StyledTableCellNumbersComponent>
                                    <StyledTableCellNumbersComponent padding="none">{quarter && <AmountFormatterComponent amount={quarter.membershipCommission} />}</StyledTableCellNumbersComponent>
                                    <StyledTableCellNumbersComponent padding="none">{quarter && <AmountFormatterComponent amount={quarter.totalCommission} />}</StyledTableCellNumbersComponent>
                                  </React.Fragment>
                                );
                              })}
                              <StyledTableCellNumbersComponent padding="none" style={borderStyle}><AmountFormatterComponent amount={person.financedInvoiceCommission} /></StyledTableCellNumbersComponent>
                              <StyledTableCellNumbersComponent padding="none"><AmountFormatterComponent amount={person.msfCommission} /></StyledTableCellNumbersComponent>
                              <StyledTableCellNumbersComponent padding="none"><AmountFormatterComponent amount={person.membershipCommission} /></StyledTableCellNumbersComponent>
                              <StyledTableCellNumbersComponent padding="none"><AmountFormatterComponent amount={person.totalCommission} /></StyledTableCellNumbersComponent>
                            </StyledTableRowComponent>
                          ))}

                          {office.id > 0 && office.name !== '' &&
                            <StyledTableRowComponent selected>
                              <StyledTableCellTextComponent padding="none" colSpan={3}>
                                <b><IntlMessageComponent id="component.isos.commissions.list.officeSubTotal" values={{name: office.name}} /></b>
                              </StyledTableCellTextComponent>
                              {data.quarters.map((quarter, qIndex) => {
                                const officeQ = getDataForOfficeInQuarter(quarter, company.id, office.id);
                                return (
                                  <React.Fragment key={`quarter-office-${indexOffice}-${qIndex}`}>
                                    <StyledTableCellNumbersComponent padding="none" style={borderStyle}>{officeQ && <b><AmountFormatterComponent amount={officeQ.financedInvoiceCommission} /></b>}</StyledTableCellNumbersComponent>
                                    <StyledTableCellNumbersComponent padding="none">{officeQ && <b><AmountFormatterComponent amount={officeQ.msfCommission} /></b>}</StyledTableCellNumbersComponent>
                                    <StyledTableCellNumbersComponent padding="none">{officeQ && <b><AmountFormatterComponent amount={officeQ.membershipCommission} /></b>}</StyledTableCellNumbersComponent>
                                    <StyledTableCellNumbersComponent padding="none">{officeQ && <b><AmountFormatterComponent amount={officeQ.totalCommission} /></b>}</StyledTableCellNumbersComponent>
                                  </React.Fragment>
                                );
                              })}
                              <StyledTableCellNumbersComponent padding="none" style={borderStyle}><b><AmountFormatterComponent amount={office.financedInvoiceCommission} /></b></StyledTableCellNumbersComponent>
                              <StyledTableCellNumbersComponent padding="none"><b><AmountFormatterComponent amount={office.msfCommission} /></b></StyledTableCellNumbersComponent>
                              <StyledTableCellNumbersComponent padding="none"><b><AmountFormatterComponent amount={office.membershipCommission} /></b></StyledTableCellNumbersComponent>
                              <StyledTableCellNumbersComponent padding="none"><b><AmountFormatterComponent amount={office.totalCommission} /></b></StyledTableCellNumbersComponent>
                            </StyledTableRowComponent>
                          }
                        </React.Fragment>
                      ))}

                      <StyledTableRowComponent selected>
                        <StyledTableCellTextComponent colSpan={3} padding="none">
                          <b><IntlMessageComponent id="component.isos.commissions.list.companySubTotal" values={{name: company.name}} /></b>
                        </StyledTableCellTextComponent>

                        {data.quarters.map((quarter, qIndex) => {
                          const companyQ = getDataForCompanyInQuarter(quarter, company.id);
                          return (
                            <React.Fragment key={`quarter-company-${indexCompany}-${qIndex}`}>
                              <StyledTableCellNumbersComponent padding="none" style={borderStyle}>{companyQ && <b><AmountFormatterComponent amount={companyQ.financedInvoiceCommission} /></b>}</StyledTableCellNumbersComponent>
                              <StyledTableCellNumbersComponent padding="none">{companyQ && <b><AmountFormatterComponent amount={companyQ.msfCommission} /></b>}</StyledTableCellNumbersComponent>
                              <StyledTableCellNumbersComponent padding="none">{companyQ && <b><AmountFormatterComponent amount={companyQ.membershipCommission} /></b>}</StyledTableCellNumbersComponent>
                              <StyledTableCellNumbersComponent padding="none">{companyQ && <b><AmountFormatterComponent amount={companyQ.totalCommission} /></b>}</StyledTableCellNumbersComponent>
                            </React.Fragment>
                          );
                        })}

                        <StyledTableCellNumbersComponent padding="none" style={borderStyle}><b><AmountFormatterComponent amount={company.financedInvoiceCommission} /></b></StyledTableCellNumbersComponent>
                        <StyledTableCellNumbersComponent padding="none"><b><AmountFormatterComponent amount={company.msfCommission} /></b></StyledTableCellNumbersComponent>
                        <StyledTableCellNumbersComponent padding="none"><b><AmountFormatterComponent amount={company.membershipCommission} /></b></StyledTableCellNumbersComponent>
                        <StyledTableCellNumbersComponent padding="none"><b><AmountFormatterComponent amount={company.totalCommission} /></b></StyledTableCellNumbersComponent>
                      </StyledTableRowComponent>
                    </React.Fragment>
                  ))}
                </React.Fragment>
              )}
            </TableBody>
          </Table>
        </Box>
      </Box>
    </React.Fragment>
  );
}
