/* @flow */

import React from 'react';

import CompanyOfficesComponent from 'app/companies/offices';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function CompanyOfficesPage() {
  return (
    <WebsitePageComponent noPaper noTitle>
      <CompanyOfficesComponent />
    </WebsitePageComponent>
  );
}
