/* @flow */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function IconFlagBE(props) {
  return (
    <SvgIcon viewBox="0 0 512 512" {...props}>
      <path style={{fill:'#FFDA44'}} d="M345.043,15.923C317.31,5.633,287.314,0,256,0s-61.31,5.633-89.043,15.923L144.696,256l22.261,240.077C194.69,506.368,224.686,512,256,512s61.31-5.632,89.043-15.923L367.304,256L345.043,15.923z"/>
      <path style={{fill:'#D80027'}} d="M512,256c0-110.07-69.472-203.906-166.957-240.076v480.155C442.528,459.906,512,366.072,512,256z"/>
      <path style={{fill:'#000000'}} d="M0,256c0,110.072,69.472,203.906,166.957,240.078V15.924C69.472,52.094,0,145.93,0,256z"/>
    </SvgIcon>
  );
}
