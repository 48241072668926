/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';

import {history} from 'store/index';
import {PROTECTED_APP_SUBSCRIPTION} from 'constants/pageRoutes';

import * as reactGA from 'lib/analytics';

type SubscriptionTab5GCCancelComponentProps = {
  activeCompanyId: number
}

class SubscriptionTab5GCCancelComponent extends React.PureComponent<SubscriptionTab5GCCancelComponentProps> {

  componentDidMount = async () => {
    reactGA.initializeGA();
    reactGA.logEvent('Subscription', 'General Conditions Canceled', 'Company ID: ' + this.props.activeCompanyId);
  }

  handleButtonClick = () => {
    history.replace(PROTECTED_APP_SUBSCRIPTION);
  }

  render() {
    return (
      <React.Fragment>
        <Typography variant="body1">
          <IntlMessageComponent id="page.subscription.tab5.cancel" />
        </Typography>
      
        <Box mt={5}>
          <ButtonComponent
            color="primary"
            variant="contained"
            onClick={this.handleButtonClick}
          ><IntlMessageComponent id="general.retry" /></ButtonComponent>
        </Box>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps)(SubscriptionTab5GCCancelComponent);
