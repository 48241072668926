/* @flow */


import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

import ButtonComponent from 'components/buttons/button';
import FormErrorsComponent from 'components/formErrors';
import IntlMessageComponent from 'components/formatters/intlMessage';
import InputAmountComponent from 'components/formatters/inputAmount';
import MandatoryFormFieldsComponent from 'components/form/mandatory';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import FinancialInfoForm from './model';

type DebtorRegisterLeadTab2ViewComponentProps = {
  errors: Map<string, ?string>;
  form: FinancialInfoForm;
  formErrors: string[];
  isProcessing: boolean;
  handleChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void;
  submitForm: () => void;
}

export default function DebtorRegisterLeadTab2ViewComponent(props: DebtorRegisterLeadTab2ViewComponentProps) {

  return (
    <React.Fragment>
      <FormErrorsComponent formErrors={props.formErrors} />

      <Box mt={5}>
        <PageSubTitleViewComponent
          id="debtor.registerLead.tab2.title"
          data-walkme="debtor-registerLead-tab2-title"
        />
      </Box>

      <Box mt={3}>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.numberOfSuppliers"
                label={<IntlMessageComponent id="form.numberOfSuppliers.label" />}
                error={!!props.errors.get('numberOfSuppliers')}
                helperText={(props.errors.get('numberOfSuppliers')) ?
                  <IntlMessageComponent id={props.errors.get('numberOfSuppliers')} /> : ''}
                value={props.form.numberOfSuppliers}
                onChange={props.handleChange('numberOfSuppliers')}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.turnOver"
                label={<IntlMessageComponent id="form.turnOver.label" />}
                error={!!props.errors.get('turnOver')}
                helperText={(props.errors.get('turnOver')) ?
                  <IntlMessageComponent id={props.errors.get('turnOver')} /> : ''}
                value={props.form.turnOver}
                onChange={props.handleChange('turnOver')}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.yearlyInvoiceAmount"
                label={<IntlMessageComponent id="form.yearlyInvoiceAmount.label" />}
                error={!!props.errors.get('yearlyInvoiceAmount')}
                helperText={(props.errors.get('yearlyInvoiceAmount')) ?
                  <IntlMessageComponent id={props.errors.get('yearlyInvoiceAmount')} /> : ''}
                value={props.form.yearlyInvoiceAmount}
                onChange={props.handleChange('yearlyInvoiceAmount')}
                InputProps={{
                  inputComponent: InputAmountComponent,
                  startAdornment: <InputAdornment position="start">€</InputAdornment>
                }}
                fullWidth
                margin="normal"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box mt={5}>
        <ButtonComponent
          color="primary"
          variant="contained"
          isProcessing={props.isProcessing}
          onClick={props.submitForm}
        ><IntlMessageComponent id="general.continue" /></ButtonComponent>

        <MandatoryFormFieldsComponent/>
      </Box>
    </React.Fragment>
  );
}
