/* @flow */

import React from 'react';
import {useSelector} from 'react-redux';

import Box from '@material-ui/core/Box';

import BlogComponent from 'components/blogs';

import SellerInvoiceListComponent from './invoices';
import SellerKPIsComponent from './kpis';
import SellerOpenInvoiceListComponent from './openInvoices';
import SellerProfileSummaryComponent from './profileSummary';

export default function SellerDashboardComponent() {
  const languageId = useSelector(state => state.locale.languageId);

  return (
    <React.Fragment>
      <SellerProfileSummaryComponent />
          
      <SellerInvoiceListComponent />
          
      <Box mt={5}>
        <SellerOpenInvoiceListComponent />
      </Box>

      <Box mt={3}>
        <SellerKPIsComponent />
      </Box>

      <Box mt={5}>
        <BlogComponent key={`blogs-${languageId}`} />
      </Box>
    </React.Fragment>
  );
}
