/* @flow */

import InvestmentProfileValues from 'models/buyers/investmentProfiles/investmentProfileValues';
import SetInvestmentProfileRequest from 'models/requests/register/setInvestmentProfileRequest';

export default class BuyerInvestmentProfileForm {
  name: string;
  amount: number;
  minInvoiceAmount: number;
  maxInvoiceAmount: number;
  pct90: number;
  comment: string;
  marketPlace: string;

  constructor(profile: InvestmentProfileValues) {
    this.name = "name";
    this.amount = null;
    this.minInvoiceAmount = null;
    this.maxInvoiceAmount = null;
    this.pct90 = null;
    this.comment = profile.comment;
    this.marketPlace = profile.marketPlace;
  }

  toRequest(companyId: number, buyerBlocked: boolean) {
    const request = new SetInvestmentProfileRequest();
    request.name = null;
    request.companyId = companyId;
    request.amount = this.amount;
    request.minInvoiceAmount = this.minInvoiceAmount;
    request.maxInvoiceAmount = this.maxInvoiceAmount;
    request.pct90 = this.pct90;
    request.comment = this.comment;
    request.marketPlace = this.marketPlace;
    request.buyerBlocked = buyerBlocked;
    return request;
  }
}
