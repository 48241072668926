/* @flow */

export default class SupplierCompany {
    supplierId: number;
    supplierName: string;
    countryId: number;
    created: moment;
    numberOfSubmittedInvoices: number;
    numberOfClosedInvoices: number;

    vatNbr: string;
    address: string;
    bankAccountCountryId: number;
    bankIdentifier: string;
    bankAccount: string;
    hasBankAccountDocument: boolean;
  }
  