/* @flow */

import ResetPasswordRequest from 'models/requests/users/resetPasswordRequest.js';

export default class ResetPasswordForm {
  password: string;
  confirmPassword: string;

  constructor() {
    this.password = '';
    this.confirmPassword = '';
  }

  toRequest(email: string, token: string) {
    const request = new ResetPasswordRequest();
    request.email = email;
    request.password = this.password;
    request.token = token;
    return request;
  }
}
