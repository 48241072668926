/* @flow */

import React from 'react';
import moment from 'moment';

import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';

import DatePickerComponent from 'components/formFields/dates/datePicker';
import IntlMessageComponent from 'components/formatters/intlMessage';

type BuyerPerformanceControlsComponentProps = {
  dateFrom: moment;
  dateTo: moment;
  filter: number;
  handleChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void;
  handleFilterChange: () => void;
}

export default function BuyerPerformanceControlsComponent(props: BuyerPerformanceControlsComponentProps) {
  return (
    <Paper elevation={0} data-walkme="performance-box-filter">
      <Box px={4} py={3}>
        <Grid container justifyContent="flex-start" alignItems="center" spacing={3}>
          <Grid item xs={6} md={3}>
            <Box display="flex" alignItems="center">
              <b><IntlMessageComponent id="buyer.perf.filter" /></b>
            </Box>
          </Grid>

          <Grid item xs={3} md={3}>
            <DatePickerComponent
              label={<IntlMessageComponent id="buyer.perf.picker.from" />}
              value={props.dateFrom}
              onChange={props.handleChange('dateFrom')}
              fullWidth
              margin="none"
            />
          </Grid>

          <Grid item xs={3} md={3}>
            <DatePickerComponent
              label={<IntlMessageComponent id="buyer.perf.picker.to" />}
              value={props.dateTo}
              onChange={props.handleChange('dateTo')}
              fullWidth
              margin="none"
            />
          </Grid>

          {/* <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel htmlFor="invoice-filter">
                <IntlMessageComponent id="buyer.perf.picker.invoiceType" />
              </InputLabel>
          
              <Select
                value={props.filter}
                onChange={props.handleFilterChange('filter')}
              >
                <MenuItem key="All" value={-1}>
                  <IntlMessageComponent id="buyer.perf.picker.invoiceType.all" />
                </MenuItem>
                <MenuItem key="Open" value={0}>
                  <IntlMessageComponent id="buyer.perf.picker.invoiceType.open" />
                </MenuItem>
                <MenuItem key="Closed" value={1}>
                  <IntlMessageComponent id="buyer.perf.picker.invoiceType.closed" />
                </MenuItem>
              </Select>
            </FormControl>
          </Grid> */}
        </Grid>
      </Box>
    </Paper>
  );
}
