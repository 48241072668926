/* @flow */

export default class SellerReport {

  // data
  isoCompany: string;
  isoOffice: string;
  isoPerson: string;
  sellerCompany: string;

  statusId: number;

  financedAmount: number;

  firstInvoiceCreated: moment;
  lastInvoiceCreated: moment;

  firstInvoiceFinanced: moment;
  lastInvoiceFinanced: moment;

  financedAmount2YearsAgo: number;
  financedAmountLastYear: number;
  financedAmountCurrentYear: number;

  membershipExpiration: moment;

  // filters
  filterISOId: number;
  filterISOOfficeId: number;
  filterISOPersonId: number;
  filterSellerCompanyId: number;
}
