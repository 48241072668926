/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import * as reactGA from 'lib/analytics';

import FirstCallDialogComponent from './firstCallDialog/index';
import SubscriptionTab6ViewComponent from './view';

import FinalizeSubscriptionRequest from 'models/requests/register/finalizeSubscriptionRequest';

import RegisterService from 'services/registerService';

import {userSwitchCompanyAction} from 'store/actions/authActions';

type SubscriptionTab6ComponentProps = {
  activeCompanyId: number;
  activeCompanyIsActive: boolean;
  companySubscriptionInfo: CompanySubscriptionInfo;
  userSwitchCompanyAction: (companyId) => void;
};

type SubscriptionTab6ComponentState = {
  isProcessing: boolean;
  presentationDate: moment;
  showDialog: boolean;
  alreadyBeenCalled: boolean;
  callRequested: boolean;
  formErrors: string[]
};

class SubscriptionTab6Component extends React.Component<SubscriptionTab6ComponentProps, SubscriptionTab6ComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      alreadyBeenCalled: false,
      callRequested: false,
      showDialog: !props.activeCompanyIsActive,
    };
  }

  componentDidMount = async () => {
    reactGA.initializeGA();
    reactGA.logEvent('Subscription', 'First Call Request', `Company ID: ${this.props.activeCompanyId}`);
  }

  finalize = async () => {
    const request = new FinalizeSubscriptionRequest(this.props.activeCompanyId);
    await RegisterService.finalizeSubscription(request);
    this.props.userSwitchCompanyAction(this.props.activeCompanyId);
  }
  
  handlePopupSkip = () => this.setState({showDialog: false, alreadyBeenCalled: true});
  handlePopupSuccess = () => this.setState({showDialog: false, callRequested: true});

  render = () => (
    <React.Fragment>
      <SubscriptionTab6ViewComponent
        alreadyBeenCalled={this.state.alreadyBeenCalled}
        callRequested={this.state.callRequested}
        hasPaidMembership={this.props.companySubscriptionInfo.hasPaidMembership}
        finalize={this.finalize}
      />
      {this.state.showDialog &&
        <FirstCallDialogComponent
          skipHandler={this.handlePopupSkip}
          successHandler={this.handlePopupSuccess}
        />
      }
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id,
  activeCompanyIsActive: state.auth.user.activeCompany.isActive
});

export default connect(mapStateToProps, {userSwitchCompanyAction})(SubscriptionTab6Component);
