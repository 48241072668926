/* @flow */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function IconMarketPlaceInsured(props) {
  return (
    <SvgIcon viewBox="0 0 50.7 62.2" {...props}>
      <path d="M24.9,0h1.1c0.2,0.1,0.5,0.3,0.7,0.4L36.5,3c3.7,1.1,7.6,1.9,11.5,2.3c2.2,0.1,2.6,0.6,2.6,2.9
        c0,7.4,0,14.8,0,22.2c0,1.6-0.2,3.3-0.5,4.9c-1,5.2-3.3,10.1-6.6,14.4c-3.9,5.3-8.7,9.5-15,11.8c-2.1,0.8-4.2,1.2-6.2,0
        c-2.3-1.2-4.5-2.5-6.6-4c-5-3.9-9.1-8.7-12.1-14.2C1.3,38.9,0,34,0,29.1C0.2,22,0,14.9,0,7.7C0,6,0.6,5.5,2.3,5.3
        C5,4.9,7.6,4.6,10.2,4c3.4-0.8,6.8-1.8,10.1-2.7C21.8,0.9,23.4,0.5,24.9,0z M46.9,21.3c0-3.7,0-7.4,0-11c0-0.8-0.2-1.2-1.1-1.3
        c-1.2-0.1-2.5-0.3-3.7-0.6C37.5,7.2,32.9,6,28.3,4.8c-1.1-0.3-2.4-0.9-3.4-0.6c-3.4,0.7-6.7,1.8-10.1,2.6c-3.2,0.8-6.5,1.5-9.7,2.1
        c-0.9,0.2-1.2,0.5-1.2,1.4c0,6.9-0.1,13.7,0.1,20.6c0.1,2.2,0.5,4.4,1.3,6.5c2,5.2,5.1,9.9,9.1,13.7c2.9,3.1,6.4,5.5,10.3,7
        c0.3,0.2,0.7,0.2,1.1,0.1c1.4-0.5,2.8-1,4.2-1.7c5.5-3,9.9-7.5,13-13c2.8-4.6,4.2-9.9,4-15.3C46.8,26.1,46.8,23.7,46.9,21.3
        L46.9,21.3z"/>
      <path d="M38.6,22.8c-2.8,3.2-5.6,6.4-8.4,9.7c-1.6,1.8-3.1,3.6-4.7,5.3c-1.1,1.2-2,1.2-3.1,0.1
        c-2.3-2.3-4.6-4.5-6.8-6.8c-0.8-0.6-0.9-1.8-0.3-2.6c0.1-0.1,0.1-0.1,0.2-0.2c0.7-0.7,1.8-0.7,2.5,0c0.1,0.1,0.1,0.1,0.2,0.2
        c1.9,1.9,3.8,3.8,5.7,5.8l7.4-8.5c1.3-1.4,2.5-2.9,3.8-4.3c1.4-1.6,2.5-1.5,3.6,0.3L38.6,22.8z"/>
    </SvgIcon>
  );
}
