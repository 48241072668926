/* @flow */

export default class SignDocumentTransationRequest {
  companyId: number;
  documentId: number;
  returnSuccessUrl: string;

  constructor(companyId: number, documentId: number, returnSuccessUrl: string) {
    this.companyId = companyId;
    this.documentId = documentId;
    this.returnSuccessUrl = returnSuccessUrl;
  }
}
