/* @flow */

export default class SupplierBankAccountChange {
    companyId: string;
    companyName: string;
    changeBankAccountToEdebex: boolean;
    isSelected: boolean;
  
    constructor() {
      this.companyId = 0;
      this.firstName = '';
      this.changeBankAccountToEdebex = false;
      this.isSelected = false;
    }
  }
  