/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import AmountFormatterComponent from 'components/formatters/amount';
import IntlMessageComponent from 'components/formatters/intlMessage';

import PaymentByCardComponent from './paymentByCard';

import PaymentInfo from 'models/payments/paymentInfo';

type PaymentComponentProps = {
  paymentInfo: PaymentInfo;
  paymentMethod: string;
}

export default function PaymentComponent(props: PaymentComponentProps) {
  return (
    <React.Fragment>
      <Box p={3} bgcolor="lightblue.main">
        <Box alignItems="center" display="flex" width={{xs: '100%', md: '50%'}}>
          <Box flexGrow={1}>
            <Typography variant="body1">
              <b><IntlMessageComponent id="component.payment.total" /></b>
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1">
              <Box component="span" fontSize={30} fontWeight="fontWeightBold">
                <AmountFormatterComponent amount={props.paymentInfo.amount} />
              </Box>
            </Typography>
          </Box>
        </Box>

        {props.paymentInfo.details.map(details => (
          <Box 
            alignItems="center"
            display="flex"
            key={`paymentRow-${details.type}`}
            width={{xs: '100%', md: '50%'}}
          >
            <Box flexGrow={1}>
              <IntlMessageComponent id={details.translationKey} />
            </Box>
            <Box>
              <AmountFormatterComponent amount={details.amount} />
            </Box>
          </Box>
        ))}
      </Box>

      {props.paymentInfo.ogone && !props.hidePaymentByCard &&
        <Box mt={3}>
          <PaymentByCardComponent paymentMethod={props.paymentMethod} paymentInfo={props.paymentInfo.ogone} />
        </Box>
      }
    </React.Fragment>
  );
}
