/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import IntlMessageComponent from 'components/formatters/intlMessage';

import MGMReferralCardComponent from './card';

type MGMReferralAvailablePanelComponentProps = {
  referrals: Referral[];
}

export default function MGMReferralAvailablePanelComponent(props: MGMReferralAvailablePanelComponentProps) {

  let nbrCoupons = 0;
  props.referrals.forEach(r => nbrCoupons += r.nbCouponsAvailable);

  return (
    <Paper elevation={0} style={{height: '100%'}}>
      <Box p={3}>
        <Box mt={2} mb={8} textAlign="center">
          <Typography>
            <Box component="span" color="success.main" fontSize="6rem" fontWeight="fontWeightBold" lineHeight={1}>
              {nbrCoupons}
            </Box>
          </Typography>
          <Typography gutterBottom>
            <Box component="span" fontWeight="fontWeightBold">
              <IntlMessageComponent id="seller.mgm.couponsAvailable" />
            </Box>
          </Typography>
        </Box>

        {props.referrals.length > 0 &&
          <Grid container spacing={4}>
            {props.referrals.map(referral => (
              <Grid item xs={6} lg={4}>
                <MGMReferralCardComponent
                  color1="success.main"
                  nbCoupons={referral.nbCouponsAvailable}
                  referral={referral}
                />
              </Grid>
            ))}
          </Grid>
        }
      </Box>
    </Paper>
  );
}
