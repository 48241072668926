/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import ButtonComponent from 'components/buttons/button';
import FormErrorsComponent from 'components/formErrors';
import IntlMessageComponent from 'components/formatters/intlMessage';
import MandatoryFormFieldsComponent from 'components/form/mandatory';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import SecurityComponent from 'components/security/index';

import ContactPersonFormComponent from './contactPersonForm';
import ContactPersonListComponent from './contactPersonList';

import CompanyContactPerson from 'models/companies/companyContactPerson';
import DebtorCompany from 'models/companies/debtorCompany';

type SellInvoiceTab3ComponentViewProps = {
  contactPersonIds: number[];
  contactPersons: CompanyContactPerson[];
  debtorCompany: DebtorCompany;
  displayForm: boolean;
  formErrors: string[];
  isProcessing: boolean;
  cancelInvoice: () => void;
  handleContactCreated: () => void;
  selectContact: (contactPersonId: number) => void;
  submitForm: () => void;
  toggleContactForm: () => void;
  unSelectContact: (contactPersonId: number) => void;
}

export default function SellInvoiceTab3ViewComponent(props: SellInvoiceTab3ComponentViewProps) {
  return (
    <React.Fragment>
      <FormErrorsComponent formErrors={props.formErrors} />

      <PageSubTitleViewComponent
        id="page.sellInvoice.tab3.selectContact.title"
        data-walkme="sellinvoice-title-selectionneruncontact"
        values={{ debtorname: props.debtorCompany.name }}
      />

      <Box mt={5}>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item xs={12} md={6}>
            {props.contactPersons.length > 0 ? (
              <Box px={4}>
                <ContactPersonListComponent
                  contactPersonIds={props.contactPersonIds}
                  contactPersons={props.contactPersons}
                  selectHandler={props.selectContact}
                  unselectHandler={props.unSelectContact}
                />
              </Box>
            ) : (
              <Box m={5}>
                <IntlMessageComponent id="page.sellInvoice.tab3.noContacts" />
              </Box>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            {props.displayForm ? (
              <ContactPersonFormComponent
                activeCompanyId={props.activeCompanyId}
                debtorCompanyId={props.debtorCompany.id}
                successHandler={props.handleContactCreated}
                cancelHandler={props.toggleContactForm}
              />
            ) : (
              <SecurityComponent pageName="POS.Sellers.SellInvoice" elementName="btnAddContact">
                <Box mt={3} textAlign="center">
                  <ButtonComponent
                    color="primary"
                    variant="contained"
                    disabled={props.isProcessing}
                    onClick={props.toggleContactForm}
                  ><IntlMessageComponent id="page.sellInvoice.tab3.addNewContact.button" /></ButtonComponent>
                </Box>
              </SecurityComponent>
            )}
          </Grid>
        </Grid>
      </Box>

      <Box mt={5}>
        <ButtonComponent
          color="default"
          variant="contained"
          disabled={props.isProcessing}
          onClick={props.cancelInvoice}
        ><IntlMessageComponent id="page.sellInvoice.button.cancelInvoice" /></ButtonComponent>

        <Box component="span" ml={1}>
          <ButtonComponent
            color="primary"
            variant="contained"
            disabled={props.contactPersonIds.length <= 0}
            isProcessing={props.isProcessing}
            onClick={props.submitForm}
          ><IntlMessageComponent id="page.sellInvoice.tab2.button.validate" /></ButtonComponent>
        </Box>

        <MandatoryFormFieldsComponent />
      </Box>
    </React.Fragment>
  );
}