/* @flow */

import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import {withStyles} from '@material-ui/core/styles';

import IntlMessageComponent from 'components/formatters/intlMessage';

type PeriodRadioFieldComponentProps = {
  value: string;
  handleChange: () => void;
}

class PeriodRadioFieldComponent extends React.Component<PeriodRadioFieldComponentProps, {}> {

  render() {
    const periods = ['years', 'quarters', 'months'];

    return (
      <FormControl component="fieldset" className={this.props.classes.formControl} fullWidth>
        <RadioGroup
          className={this.props.classes.group}
          name="form.period.radio"
          value={this.props.value}
          onChange={this.props.handleChange}
        >
          {periods.map(p => (
            <FormControlLabel
              key={`radio-${p}`}
              value={p}
              control={<Radio color="primary" />}
              label={<IntlMessageComponent id={`form.period.option.${p}.label`} />} />
          ))}
        </RadioGroup>
      </FormControl>
    );
  }
}

const styles = () => ({
  group: {
    width: 'auto',
    height: 'auto',
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'row',
  }
});

export default withStyles(styles)(PeriodRadioFieldComponent);
