/* @flow */

import Company from 'models/companies/company';
import RegisterLeadRequest from 'models/requests/isos/registerLeads/registerLeadRequest';
import IncompleteAlertRequest from 'models/requests/register/incompleteAlertRequest';

export default class RegisterLeadForm {
  company: Company;
  assignToEdebex: boolean;

  lastName: string;
  firstName: string;
  email: string;
  mobile: string;
  mobilePresence: string;
  languageId: number;
  titleId: number;

  constructor() {
    this.company = null;
    this.assignToEdebex = false;

    this.lastName = '';
    this.firstName = '';
    this.email = '';
    this.mobile = '';
    this.mobilePresence = '';
    this.languageId = 0;
    this.titleId = 0;
  }

  toRequest(isoCompanyId: number){
    const request = new RegisterLeadRequest();
    request.isoCompanyId = isoCompanyId;

    request.insurerId = this.company.insurerId;
    request.assignToEdebex = this.assignToEdebex;

    request.lastName = this.lastName;
    request.firstName = this.firstName;
    request.email = this.email;
    request.mobile = this.mobilePresence.length > 1 ? this.mobilePresence : this.mobile;
    request.languageId = this.languageId;
    request.titleId = this.titleId;
    return request;
  }

  toIncompleteAlertRequest(isoCompanyId: number, flow: string) {
    const request = new IncompleteAlertRequest();
    request.companyType = 1;
    request.insurerId = this.company?.insurerId;
    request.companyName = this.company?.name;
    request.countryId = this.company?.countryId;
    request.postalCode = this.company?.zipCode;
    request.city = this.company?.city;
    request.phone = this.company?.phone;
    request.vatNumber = this.company?.vatNumber;
    request.isoCompanyId = isoCompanyId;
    request.titleId = this.titleId;
    
    request.firstName = this.firstName;
    request.lastName = this.lastName;
    request.email = this.email;
    request.mobile = this.mobilePresence?.length > 1 ? this.mobilePresence : this.mobile;
    request.languageId = this.languageId;
    request.functionName = this.functionName;
    
    request.requestFlow = flow;
    
    return request;
  }
}
