import React from 'react';
import {Link} from 'react-router-dom';

import Box from '@material-ui/core/Box';

import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';

import PaymentConfirmationInvoiceComponent from './invoices';
import PaymentConfirmationMembershipComponent from './memberships';
import PaymentDelayedComponent from './delayed';
import PaymentLoadingComponent from './loading';

import PaymentResult from 'models/payments/paymentResult';

import PaymentService from 'services/PaymentService';

import {PROTECTED_APP} from 'constants/pageRoutes';

type PaymentConfirmationComponentProps = {
  orderId: string;
}

type PaymentConfirmationComponentState = {
  isLoading: boolean;
  orderId: string;
  paymentResult: PaymentResult;
  tryCount: number;
  type: string;
}

class PaymentConfirmationComponent extends React.Component<PaymentConfirmationComponentState, PaymentConfirmationComponentProps> {
  constructor(props) {
    super(props);

    const type = this.getPaymentType(props.orderId);

    // 2020-07-07 there is a bug in react-router-dom 
    // React router rendering twice and URL changes by dropping all query parameters
    // https://stackoverflow.com/questions/61298227/react-router-rendering-twice-and-url-changes-by-dropping-all-query-parameters
    // to avoid this, just recopy orderId in state... stupid but it works

    this.state = {
      isLoading: true,
      orderId: props.orderId,
      paymentResult: null,
      tryCount: 0,
      type
    };
  }

  componentDidMount = async () => {
    if (this.state.orderId) {
      this.interval = setInterval(async () => {
        try {
          const paymentResult = await PaymentService.getPaymentResult(this.state.orderId);
          const tryCount = this.state.tryCount + 1;

          this.setState({
            isLoading: !paymentResult.isComplete && tryCount < 20,
            paymentResult,
            tryCount: tryCount
          });

          if (paymentResult.isComplete || tryCount >= 20) {
            clearInterval(this.interval);
          }
        } catch (e) {
          console.error(e);
          clearInterval(this.interval);
        }
      }, 3000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  interval;

  getPaymentType = (orderId: string) => {
    if (!orderId) return null;

    let temp = orderId;
    if (temp.startsWith("DEV") || temp.startsWith("TST") || temp.startsWith("ACC") || temp.startsWith("API")) {
      temp = temp.substring(3);
    }

    if (temp.startsWith("M"))
      return 'membership';
    if (temp.startsWith("S"))
      return 'invoice';
    return null;
  }

  render() {
    if (this.state.isLoading) {
      return <PaymentLoadingComponent />;
    }

    if (this.state.paymentResult && !this.state.paymentResult.isComplete) {
      return <PaymentDelayedComponent />;
    }

    return (
      <React.Fragment>
        {this.state.paymentResult.membership &&
          <PaymentConfirmationMembershipComponent membership={this.state.paymentResult.membership} />
        }

        {this.state.paymentResult.invoices &&
          <PaymentConfirmationInvoiceComponent invoices={this.state.paymentResult.invoices} />
        }
        
        <Box mt={5}>
          <ButtonComponent
            color="primary"
            variant="contained"
            component={Link}
            to={PROTECTED_APP}
          ><IntlMessageComponent id="page.payment.button.dashboard" /></ButtonComponent>
        </Box>
      </React.Fragment>
    );
  }
}

export default PaymentConfirmationComponent;
