/* @flow */

export default class LinkPersonToOfficeRequest {
  companyId: number;
  officeId: number;
  companyPersonId: number;

  constructor(companyId: number, officeId: number, companyPersonId: number) {
    this.companyId = companyId;
    this.officeId = officeId;
    this.companyPersonId = companyPersonId;
  }
}
