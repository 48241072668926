/* @flow */

import React from 'react';

import DebtorInvoicesPortfolioTableComponent from 'app/debtors/invoicesPortfolio';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function DebtorInvoicesPortfolioPage() {
  return (
    <WebsitePageComponent id="debtor.portfolio.title" data-walkme="debtor-portfolio-title" noPaper>
      <DebtorInvoicesPortfolioTableComponent />
    </WebsitePageComponent>
  );
}
