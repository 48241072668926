/* @flow */

export default class SetBusinessOpportunityRequest {
  isoCompanyId: number;
  companyId: number;

  expectedMonthlyVolume: number;
  invoicesAvgAmount: number;
  invoicesNbrMonthly: number;
  nbrDebtors: number;
  financingNeedId: number;
  financingNeedFrequencyId: number;
  invoicesYearlyAmountId: number;
  revenuesYearly: number;
}
