/* @flow */


import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import ButtonComponent from 'components/buttons/button';
import CompanyLookupDebtorComponent from 'components/companyLookupV2/debtors';
import FormErrorsComponent from 'components/formErrors';
import IntlMessageComponent from 'components/formatters/intlMessage';
import InputAmountComponent from 'components/formatters/inputAmount';
import MandatoryFormFieldsComponent from 'components/form/mandatory';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import BusinessPotentialForm from './model';

type RegisterLeadTab3ViewComponentProps = {
  errors: Map<string, ?string>;
  form: BusinessPotentialForm;
  formErrors: string[];
  isProcessing: boolean;
  handleChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void;
  handleCompanyChange: () => void;
  skip: () => void;
  submitForm: () => void;
}

export default function RegisterLeadTab3ViewComponent(props: RegisterLeadTab3ViewComponentProps) {

  return (
    <React.Fragment>
      <FormErrorsComponent formErrors={props.formErrors} />

      <Typography variant="body1" gutterBottom>
        <IntlMessageComponent id="page.iso.registerLead.tab3.notmandatory" data-walkme="createlead3-label-notmandatory" />
      </Typography>

      <Box mt={5}>
        <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={6}>
          <Grid item xs={12} md={6}>
            <PageSubTitleViewComponent
              id="page.iso.registerLead.tab3.company"
              data-walkme="createlead3-title-company"
            />

            <Box mt={3}>
              <CompanyLookupDebtorComponent
                company={props.form.debtorCompany}
                companyErrorLabelId={props.errors.get('debtorCompany')}
                handleSelect={props.handleCompanyChange}
                required
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <PageSubTitleViewComponent
              id="page.iso.registerLead.tab3.invoices"
              data-walkme="createlead3-title-invoices"
            />

            <Box mt={3} px={4} py={3}>
              <TextField
                id="form.nbrInvoices"
                label={<IntlMessageComponent id="form.nbrInvoices.label" />}
                data-walkme="createlead3-field-numberofinvoices"
                error={!!props.errors.get('nbrInvoices')}
                helperText={(props.errors.get('nbrInvoices')) ?
                  <IntlMessageComponent id={props.errors.get('nbrInvoices')} /> : ''}
                value={props.form.nbrInvoices}
                onChange={props.handleChange('nbrInvoices')}
                fullWidth
                margin="normal"
              />

              <TextField
                id="form.avgInvoiceAmount"
                label={<IntlMessageComponent id="form.avgInvoiceAmount.label" />}
                data-walkme="createlead3-field-averageinvoiceamount"
                error={!!props.errors.get('avgInvoiceAmount')}
                helperText={(props.errors.get('avgInvoiceAmount')) ?
                  <IntlMessageComponent id={props.errors.get('avgInvoiceAmount')} /> : ''}
                value={props.form.avgInvoiceAmount}
                onChange={props.handleChange('avgInvoiceAmount')}
                InputProps={{
                  inputComponent: InputAmountComponent,
                  startAdornment: <InputAdornment position="start">€</InputAdornment>
                }}
                fullWidth
                margin="normal"
              />

              <TextField
                id="form.yearlyAmount"
                label={<IntlMessageComponent id="form.yearlyAmount.label" />}
                data-walkme="createlead3-field-yearlyamount"
                error={!!props.errors.get('yearlyAmount')}
                helperText={(props.errors.get('yearlyAmount')) ?
                  <IntlMessageComponent id={props.errors.get('yearlyAmount')} /> : ''}
                value={props.form.yearlyAmount}
                onChange={props.handleChange('yearlyAmount')}
                InputProps={{
                  inputComponent: InputAmountComponent,
                  startAdornment: <InputAdornment position="start">€</InputAdornment>
                }}
                fullWidth
                margin="normal"
              />

              <TextField
                id="form.paymentTerms"
                label={<IntlMessageComponent id="form.paymentTerms.label" />}
                data-walkme="createlead3-field-paymentterms"
                error={!!props.errors.get('paymentTerms')}
                helperText={(props.errors.get('paymentTerms')) ?
                  <IntlMessageComponent id={props.errors.get('paymentTerms')} /> : ''}
                value={props.form.paymentTerms}
                onChange={props.handleChange('paymentTerms')}
                fullWidth
                margin="normal"
              />

              <TextField
                id="form.minInvoiceAmount"
                label={<IntlMessageComponent id="form.minInvoiceAmount.label" />}
                data-walkme="createlead3-field-minimumamount"
                error={!!props.errors.get('minInvoiceAmount')}
                helperText={(props.errors.get('minInvoiceAmount')) ?
                  <IntlMessageComponent id={props.errors.get('minInvoiceAmount')} /> : ''}
                value={props.form.minInvoiceAmount}
                onChange={props.handleChange('minInvoiceAmount')}
                InputProps={{
                  inputComponent: InputAmountComponent,
                  startAdornment: <InputAdornment position="start">€</InputAdornment>
                }}
                fullWidth
                margin="normal"   
              />
              
              <TextField
                id="form.maxInvoiceAmount"
                label={<IntlMessageComponent id="form.maxInvoiceAmount.label" />}
                data-walkme="createlead3-field-maximumamount"
                error={!!props.errors.get('maxInvoiceAmount')}
                helperText={(props.errors.get('maxInvoiceAmount')) ?
                  <IntlMessageComponent id={props.errors.get('maxInvoiceAmount')} /> : ''}
                value={props.form.maxInvoiceAmount}
                onChange={props.handleChange('maxInvoiceAmount')}
                InputProps={{
                  inputComponent: InputAmountComponent,
                  startAdornment: <InputAdornment position="start">€</InputAdornment>
                }}
                fullWidth
                margin="normal"
              />
              
              <TextField
                id="form.debtorAge"
                label={<IntlMessageComponent id="form.debtorAge.label" />}
                data-walkme="createlead3-field-debtorage"
                error={!!props.errors.get('debtorAge')}
                helperText={(props.errors.get('debtorAge')) ?
                  <IntlMessageComponent id={props.errors.get('debtorAge')} /> : ''}
                value={props.form.debtorAge}
                onChange={props.handleChange('debtorAge')}
                fullWidth
                margin="normal"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box mt={5}>
        <ButtonComponent
          color="default"
          variant="contained"
          disabled={props.isProcessing}
          onClick={props.skip}
        ><IntlMessageComponent id="general.skip" /></ButtonComponent>

        <Box component="span" ml={1}>
          <ButtonComponent
            color="primary"
            variant="contained"
            isProcessing={props.isProcessing}
            onClick={props.submitForm}
          ><IntlMessageComponent id="general.continue" /></ButtonComponent>
        </Box>

      </Box>
    </React.Fragment>
  );
}
