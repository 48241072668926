/* @flow */

import React from 'react';
import {useSelector} from 'react-redux';

import CompanyDocumentsComponent from 'app/companies/documents';
import WebsitePageComponent from 'components/websiteStructure/pages';

import {ROLE_ISO, ROLE_BUYER, ROLE_SELLER, ROLE_DEBTOR} from 'constants/companyRoles';

export default function CompanyDocumentsPage() {
  const activeCompany = useSelector(state => state.auth.user.activeCompany);

  let walkme = '';
  if (activeCompany.role === ROLE_ISO) walkme='isodocuments-title-documents';
  if (activeCompany.role === ROLE_BUYER) walkme='buyerdocuments-title-documents';
  if (activeCompany.role === ROLE_SELLER) walkme='sellerdocuments-title-documents';
  if (activeCompany.role === ROLE_DEBTOR) walkme='debtordocuments-title-documents';

  return (
    <WebsitePageComponent id="company.documents.title" data-walkme={walkme} noPaper>
      <CompanyDocumentsComponent key={`company.documents-${activeCompany.id}-${activeCompany.role}`} />
    </WebsitePageComponent>
  );
}
