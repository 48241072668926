/* @flow */

import React from 'react';

import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

import IntlMessageComponent from 'components/formatters/intlMessage';
import UploadComponent from 'components/upload';
import IconTrash from 'components/icons/trash';

import Document from 'models/documents/v2/document';

type UploadFileFieldComponentProps = {
  id: string;
  document: Document;
  isOptional: boolean;
  successHandler: (file: File) => void;
  failHandler: () => void;
  removeHandler: () => void;
}

export default function UploadFileFieldComponent(props: UploadFileFieldComponentProps) {

  const handleRemoveDocumentField = (event) => {
    event.stopPropagation();
    props.removeHandler();
  }

  let helperText = '';
  if (props.document.isInvalid) {
    helperText = <IntlMessageComponent id="form.file.wrongFormat" />;
  } else if (props.document.isMissing) {
    helperText = <IntlMessageComponent id="form.file.missing" />;
  } else if (props.document.isProvided && props.document.file === null) {
    helperText = <IntlMessageComponent id="form.file.alreadyProvided" />;
  } else if (props.document.file === null) {
    helperText = <IntlMessageComponent id="general.file.accept" values={{accept: "application/pdf, image/jpeg, image/png", size: 5}} />;
  }

  return (
    <UploadComponent
      accept="application/pdf, image/jpeg, image/png"
      successHandler={props.successHandler}
      failHandler={props.failHandler}
    >
      <TextField
        id={props.id}
        color="primary"
        label={<IntlMessageComponent id={`document.type.${props.document.code}`} />}
        error={props.document.isInvalid || props.document.isMissing}
        helperText={helperText}
        value={props.document.filename}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {props.document.isProvided && props.document.file === null &&
                <CheckIcon color="primary" />
              }
              {props.document.file && !props.isOptional &&
                <IconButton onClick={handleRemoveDocumentField}><ClearIcon /></IconButton>
              }
              {props.isOptional &&
                <IconButton onClick={handleRemoveDocumentField}><IconTrash /></IconButton>
              }
            </InputAdornment>
        )}}
        fullWidth
        margin="normal"
        readOnly
        required={props.document.isRequired}
      />
    </UploadComponent>
  );
}

