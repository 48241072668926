/* @flow */

import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import IntlMessageComponent from 'components/formatters/intlMessage';

type BooleanRadioFieldComponentProps = {
  label: IntlMessage;
  name: string;
  value: boolean;
  handleChange: () => void;
}

export default function BooleanRadioFieldComponent(props: BooleanRadioFieldComponentProps) {
  return (
    <FormControl component="fieldset" fullWidth required>
      <FormLabel component="legend">
        {props.label}
      </FormLabel>

      <RadioGroup
        name={props.name}
        onChange={props.onChange}
        row
        value={props.value}
      >
        <FormControlLabel
          control={<Radio color="primary" />}
          label={<IntlMessageComponent id="general.label.yes" />}
          value="true"
        />
        <FormControlLabel
          control={<Radio color="primary" />}
          label={<IntlMessageComponent id="general.label.no" />}
          value="false"
        />
      </RadioGroup>
    </FormControl>
  );
}
