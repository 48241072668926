/* @flow */

export function totalLessOrEqualThan(value: number[], options: {value: number, message: ?string}) {
  console.debug('totalLessOrEqualThan');
  const total = value.reduce((previousValue: number, currentValue: number) => previousValue + currentValue);
  if (total <= options.value) {
    return undefined;
  }

  return options.message ? options.message : 'validation.isMoreThanAllowed';
}


export function oneIsMoreThan(value: number[], options: {value: number, message: ?string}) {
  console.debug('oneIsMoreThan');
  if (value.findIndex(v => v > options.value) !== -1) {
    return undefined;
  }

  return options.message ? options.message : 'validation.isLessThanSibling';
}
