/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';

type SubscriptionTab5GCSignedComponentProps = {
  successHandler: () => void;
}

export default function SubscriptionTab5GCSignedComponent(props: SubscriptionTab5GCSignedComponentProps) {
  return (
    <React.Fragment>
      <Typography variant="body1">
        <IntlMessageComponent id="page.subscription.tab5.signed" />
      </Typography>
    
      <Box mt={5}>
        <ButtonComponent
          color="primary"
          variant="contained"
          onClick={props.successHandler}
        ><IntlMessageComponent id="general.continue" /></ButtonComponent>
      </Box>
    </React.Fragment>
  );
}
