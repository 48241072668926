/* @flow */

import moment from 'moment/moment';
import CompanyUBO from 'models/companies/companyUBO';

import AddCompanyUBORequest from 'models/requests/companies/ubos/addCompanyUBORequest';
import UpdateCompanyUBORequest from 'models/requests/companies/ubos/updateCompanyUBORequest';

export default class CompanyUBOForm {
  id: number;

  titleId: number;

  firstName: string;
  lastName: string;
  
  email: string;

  addressLine1: string;
  addressLine2: string;
  zipCode: string;
  city: string;
  countryId: number;

  nationalityId: number;
  birthCountryId: number;
  birthDate: moment;
  birthPlace: string;

  registrationTypeId: number;
  registrationNumber: string;
  registrationExpireDate: moment;

  functionName: string;
  isDecisionMaker: boolean;
  shares: number;

  idFront: File;
  idBack: File;

  currentShares: number;
  hasValidId: boolean;

  constructor(ubo: CompanyUBO, currentShares: number, hasValidId: boolean) {
    if (ubo === null) {
      this.id = 0;
      this.titleId = 0;
      this.firstName = '';
      this.lastName = '';
      this.email = '';
      this.addressLine1 = '';
      this.addressLine2 = '';
      this.zipCode = '';
      this.city = '';
      this.countryId = 0;
      this.nationalityId = 0;
      this.birthCountryId = 0;
      this.birthDate = moment.utc().subtract(18, 'y').startOf('d');
      this.birthPlace = '';
      this.registrationTypeId = 0;
      this.registrationNumber = '';
      this.registrationExpireDate = moment.utc().add(1, 'd').startOf('d');
      this.functionName = '';
      this.isDecisionMaker = false;
      this.shares = 0;
      this.currentShares = 100 - currentShares + 1;
      this.hasValidId = false;
    } else {
      this.id = ubo.id;
      this.titleId = ubo.titleId;
      this.firstName = ubo.firstName;
      this.lastName = ubo.lastName;      
      this.email = ubo.email;
      this.addressLine1 = ubo.addressLine1;
      this.addressLine2 = ubo.addressLine2;
      this.zipCode = ubo.zipCode;
      this.city = ubo.city;
      this.countryId = ubo.countryId;
      this.nationalityId = ubo.nationalityId;
      this.birthCountryId = ubo.birthCountryId;
      this.birthDate = ubo.birthDate;
      this.birthPlace = ubo.birthPlace;
      this.registrationTypeId = ubo.registrationTypeId;
      this.registrationNumber = ubo.registrationNumber;
      this.registrationExpireDate = ubo.registrationExpireDate;
      this.functionName = ubo.functionName;
      this.isDecisionMaker = ubo.isDecisionMaker;
      this.shares = ubo.shares;
      this.currentShares = 100 - currentShares + 1;
      this.hasValidId = hasValidId;
    }
    this.idFront = null;
    this.idBack = null;
  }

  toAddCompanyUBORequest(companyId: number, addCompanyPersonId: boolean){
    const request = new AddCompanyUBORequest();
    request.companyId = companyId;
    request.titleId = this.titleId;
    request.lastName = this.lastName;
    request.firstName = this.firstName;
    request.email = this.email;
    request.addressLine1 = this.addressLine1;
    request.addressLine2 = this.addressLine2;
    request.zipCode = this.zipCode;
    request.city = this.city;
    request.countryId = this.countryId;
    request.nationalityId = this.nationalityId;
    request.birthCountryId = this.birthCountryId;
    request.birthDate = moment(this.birthDate).format('YYYY/MM/DD');
    request.birthPlace = this.birthPlace;
    request.registrationTypeId = this.registrationTypeId;
    request.registrationNumber = this.registrationNumber;
    request.registrationExpireDate = moment(this.registrationExpireDate).format('YYYY/MM/DD');
    request.functionName = this.functionName;
    request.isDecisionMaker = this.isDecisionMaker;
    request.shares = this.shares;
    request.companyPersonId = addCompanyPersonId && this.id > 0 ? this.id : 0;
    return request;
  }

  toUpdateCompanyUBORequest(companyId: number){
    const request = new UpdateCompanyUBORequest();
    request.companyId = companyId;
    request.companyPersonId = this.id;
    request.titleId = this.titleId;
    request.lastName = this.lastName;
    request.firstName = this.firstName;
    request.email = this.email;
    request.addressLine1 = this.addressLine1;
    request.addressLine2 = this.addressLine2;
    request.zipCode = this.zipCode;
    request.city = this.city;
    request.countryId = this.countryId;
    request.nationalityId = this.nationalityId;
    request.birthCountryId = this.birthCountryId;
    request.birthDate = moment(this.birthDate).format('YYYY/MM/DD');
    request.birthPlace = this.birthPlace;
    request.registrationTypeId = this.registrationTypeId;
    request.registrationNumber = this.registrationNumber;
    request.registrationExpireDate = moment(this.registrationExpireDate).format('YYYY/MM/DD');
    request.functionName = this.functionName;
    request.isDecisionMaker = this.isDecisionMaker;
    request.shares = this.shares;
    return request;
  }
}
