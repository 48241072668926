/* @flow */

import {withStyles} from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';

const StyledStrippedTableRowComponent = withStyles(_ => ({
  root: {
    height: '48px',
    '&:nth-of-type(odd)': {
      backgroundColor: '#fff',
    }
  },
  head: {
    height: '56px'
  }
}))(TableRow);

export default StyledStrippedTableRowComponent;
