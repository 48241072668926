/* @flow */

import React from 'react';

import ISORegisterLeadsComponent from 'app/isos/registerLeads';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function ISORegisterLeadsPage() {
  return (
    <WebsitePageComponent id="page.iso.registerLead.h1" data-walkme="createlead-title-registerlead" noPaper>
      <ISORegisterLeadsComponent />
    </WebsitePageComponent>
  );
}
