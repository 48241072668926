/* @flow */

import React from 'react';
import {useSelector} from 'react-redux';

import Box from '@material-ui/core/Box';

import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import SellInvoiceTab5FixPricesComponent from './fix';
import SellInvoiceTab5FlexPricesComponent from './flex';
import SellInvoiceTab5SummaryComponent from './summary';

import InvoiceProposal from 'models/invoices/seller/invoiceProposal';

type SellInvoiceTab5ViewComponentProps = {
  invoice: InvoiceProposal;
  isProcessing: boolean;
  cancelInvoice: () => void;
  submitFixInvoice: () => void;
  submitFlexInvoice: () => void;
}

export default function SellInvoiceTab5ViewComponent(props: SellInvoiceTab5ViewComponentProps) {
  const hasActiveFixContract = useSelector(state => state.auth.user.activeCompany.roleSeller.hasActiveFixContract);

  return (
    <React.Fragment>
      <PageSubTitleViewComponent id="page.sellInvoice.tab5.title" />

      {hasActiveFixContract ? (
        <Box mt={3}>
          <b><IntlMessageComponent id="page.sellInvoice.tab5.subTitle1.fix" /></b>
          <p><IntlMessageComponent id="page.sellInvoice.tab5.subTitle2.fix" /></p>
        </Box>
      ) : (
        <Box mt={3}>
          <b><IntlMessageComponent id="page.sellInvoice.tab5.subTitle1" /></b>
          <p><IntlMessageComponent id="page.sellInvoice.tab5.subTitle2" /></p>
        </Box>
      )}
      
      <Box mt={5}>
        <SellInvoiceTab5SummaryComponent invoice={props.invoice} />
      </Box>


      {props.invoice.flexPrices ? (
        <SellInvoiceTab5FlexPricesComponent
          invoice={props.invoice}
          isProcessing={props.isProcessing}
          submitInvoice={props.submitFlexInvoice}
          cancelInvoice={props.cancelInvoice}
        />
      ) : (
        <SellInvoiceTab5FixPricesComponent
          invoice={props.invoice}
          isProcessing={props.isProcessing}
          submitInvoice={props.submitFixInvoice}
          cancelInvoice={props.cancelInvoice}
        />
      )}
    </React.Fragment>
  );
}
