/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import PhoneNumberComponent from 'components/formFields/phoneNumber';
import ButtonComponent from 'components/buttons/button';
import CompanyLookupSellerComponent from 'components/companyLookupV2/sellers';
import FormErrorsComponent from 'components/formErrors';
import IntlMessageComponent from 'components/formatters/intlMessage';
import LanguageFieldComponent from 'components/formFields/types/language';
import MandatoryFormFieldsComponent from 'components/form/mandatory';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import RegisterReferralForm from './model';

type ReferralTab1ViewComponentProps = {
  errors: Map<string, ?string>;
  form: RegisterReferralForm;
  formErrors: string[];
  isProcessing: boolean;
  handleChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void;
  handleCompanyChange: () => void;
  handleTypeChange: (fieldName: string) => (id: number) => void;
  handlePhoneChange: (value: string) => void;
  submitForm: () => void;
}

export default function ReferralTab1ViewComponent(props: ReferralTab1ViewComponentProps) {

  return (
    <React.Fragment>
      <FormErrorsComponent formErrors={props.formErrors} />

      <Grid container spacing={6} alignItems="stretch">
        <Grid item xs={12} md={6}>
          <PageSubTitleViewComponent id="seller.referral.tab1.company.title" data-walkme="referral-title-companyinfo" />
            
          <Box mt={3}>
            <CompanyLookupSellerComponent
              company={props.form.company}
              companyErrorLabelId={props.errors.get('company')}
              handleSelect={props.handleCompanyChange}
              required
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <PageSubTitleViewComponent id="seller.referral.tab1.person.title" data-walkme="referral-title-personalinfo" />

          <Box mt={3} px={4} py={3}>
            <TextField
              id="form.lastName"
              label={<IntlMessageComponent id="form.name.label" />}
              error={!!props.errors.get('lastName')}
              helperText={(props.errors.get('lastName')) ?
                <IntlMessageComponent id={props.errors.get('lastName')} /> : ''}
              value={props.form.lastName}
              onChange={props.handleChange('lastName')}
              fullWidth
              margin="normal"
              required
            />
            
            <TextField
              id="form.firstName"
              label={<IntlMessageComponent id="form.firstname.label" />}
              error={!!props.errors.get('firstName')}
              helperText={(props.errors.get('firstName')) ?
                <IntlMessageComponent id={props.errors.get('firstName')} /> : ''}
              value={props.form.firstName}
              onChange={props.handleChange('firstName')}
              fullWidth
              margin="normal"
              required
            />
            
            <TextField
              id="form.email"
              label={<IntlMessageComponent id="form.email.label" />}
              error={!!props.errors.get('email')}
              helperText={(props.errors.get('email')) ?
                <IntlMessageComponent id={props.errors.get('email')} /> : ''}
              value={props.form.email}
              onChange={props.handleChange('email')}
              fullWidth
              margin="normal"
              required
            />

            <PhoneNumberComponent
              id="form.mobilePresence"
              label={<IntlMessageComponent id="form.phone.label" />}
              error={!!props.errors.get('mobilePresence')}
              helperText={(props.errors.get('mobilePresence')) ?
                <IntlMessageComponent id={props.errors.get('mobilePresence')} /> :
                <IntlMessageComponent id="form.phone.helperText" />}
              value={props.form.mobilePresence}
              onChange={props.handlePhoneChange}
              fullWidth
              margin="normal"
              required
            />

            <LanguageFieldComponent
              label={<IntlMessageComponent id="form.language.label" />}
              error={!!props.errors.get('languageId')}
              helperText={(props.errors.get('languageId')) ?
                <IntlMessageComponent id={props.errors.get('languageId')} /> : ''}
              value={props.form.languageId}
              changeHandler={props.handleTypeChange('languageId')}
              fullWidth
              margin="normal"
              required
            />
          </Box>
        </Grid>
      </Grid>

      <Box mt={5}>
        <ButtonComponent
          color="primary"
          variant="contained"
          isProcessing={props.isProcessing}
          onClick={props.submitForm}
        ><IntlMessageComponent id="general.continue" /></ButtonComponent>

        <MandatoryFormFieldsComponent/>
      </Box>
    </React.Fragment>
  );
}