/* @flow */

import React from 'react';
import { useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import ButtonComponent from 'components/buttons/button';

import InvoiceSummaryComponent from './summary';

import InvoiceSummary from 'models/invoices/seller/invoiceSummary';
import PaymentInfo from 'models/payments/paymentInfo';

type SubmitInvoiceThankYouComponentViewProps = {
  invoice: InvoiceSummary;
  isProcessing: boolean;
  paymentInfo: PaymentInfo;
  paymentMethod: string;
  handleNewInvoiceCreation: () => void;
  redirectToDashboard: () => void;
}


export default function SubmitInvoiceThankYouViewComponent(props: SubmitInvoiceThankYouComponentViewProps) {

  return (
    <Paper elevation={0}>
      <Box p={5}>

        <React.Fragment>
          <PageSubTitleViewComponent id="page.submitinvoice.thankyou.titleSubmitted" />
          {!props.paymentInfo && <IntlMessageComponent id="page.submitinvoice.thankyou.textSubmitted" />}
        </React.Fragment>

        <Box mt={5}>
          <InvoiceSummaryComponent invoice={props.invoice} />
        </Box>

        <Box mt={5}>
          <ButtonComponent
            color="primary"
            variant="contained"
            onClick={props.handleNewInvoiceCreation}
            data-walkme="sell-t6-addInvoice"
          ><IntlMessageComponent id="page.submitinvoice.thankyou.button.addInvoice" /></ButtonComponent>

          <Box component="span" ml={1}>
            <ButtonComponent
              color="primary"
              variant="contained"
              onClick={props.redirectToDashboard}
              data-walkme="sell-t6-payfees"
            ><IntlMessageComponent id="page.submitinvoice.thankyou.button.redirect" /></ButtonComponent>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}
