import React from 'react';

import Box from '@material-ui/core/Box';

import ButtonComponent from 'components/buttons/button';
import FormErrorsComponent from 'components/formErrors';
import IntlMessageComponent from 'components/formatters/intlMessage';
import MandatoryFormFieldsComponent from 'components/form/mandatory';

import SubscriptionTab1CompanyInfoViewComponent from './companyInfo';
import SubscriptionTab1BankAccountViewComponent from './bankAccount';

import CompanyInfoForm from './model';

type SubscriptionTab1ViewComponentProps = {
  company: Company;
  companyDetails: CompanyDetailsFormModel;
  errors: Map<string, ?string>;
  form: CompanyInfoForm;
  formErrors: string[];
  isProcessing: boolean;
  minDate: moment;
  handleChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void;
  handleDateChange: () => void;
  handleBooleanChange: () => void;
  handleTypeChange: (fieldName: string) => (id: number) => void;
  handlePhoneChange: (value: string) => void;
  submitForm: () => void;
}

export default function SubscriptionTab1ViewComponent(props: SubscriptionTab1ViewComponentProps) {
  return (
    <React.Fragment>
      <FormErrorsComponent formErrors={props.formErrors} />

      <SubscriptionTab1CompanyInfoViewComponent
        company={props.company}
        companyDetails={props.companyDetails}
        errors={props.errors}
        form={props.form}
        minDate={props.minDate}
        handleChange={props.handleChange}
        handleDateChange={props.handleDateChange}
        handleTypeChange={props.handleTypeChange}
        handleBooleanChange={props.handleBooleanChange}
        handlePhoneChange={props.handlePhoneChange}
      />

      <Box mt={10}>
        <SubscriptionTab1BankAccountViewComponent
          errors={props.errors}
          form={props.form}
          handleChange={props.handleChange}
          handleTypeChange={props.handleTypeChange}
        />
      </Box>
      
      <Box mt={5}>
        <ButtonComponent
          color="primary"
          variant="contained"
          isProcessing={props.isProcessing}
          onClick={props.submitForm}
        ><IntlMessageComponent id="general.continue" /></ButtonComponent>

        <MandatoryFormFieldsComponent/>
      </Box>
    </React.Fragment>
  );
}