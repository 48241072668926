/* @flow */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function IconInvoiceDue(props) {
  return (
    <SvgIcon viewBox="0 0 57.38 61.08" {...props}>
      <path d="M240.47,428.57a20.53,20.53,0,0,1,.55-3.43c.07-.22,1.17-.17,1.79-.15,2.72.11.6,2.34,1.79,3.49.17,0,.72.08,1.28.08,6.06,0,12.11,0,18.17,0,1.44,0,2.09-.34,1.85-1.85a11.5,11.5,0,0,1,0-1.67h3.39v3.49h6.11c5.47,0,8.3,2.85,8.31,8.33q0,20.36,0,40.7a13.21,13.21,0,0,1-.39,3.44A6.73,6.73,0,0,1,277,486c-3.19.11-6.39.07-9.59.07-10.9,0-21.8.05-32.7,0a12.16,12.16,0,0,1-5-1.13,6.08,6.08,0,0,1-3.34-5.42c-.1-1.54-.07-3.1-.07-4.65q0-19.11,0-38.22c0-5.09,2.92-8,8-8h6.11Zm-10.52,18c0,.55-.08,1-.08,1.4,0,10.12.07,20.24,0,30.36a4,4,0,0,0,4.34,4.25c13.89,0,27.79,0,41.68,0,2.91,0,4.34-1.47,4.34-4.39V448.09c0-.51-.05-1-.07-1.54Zm0-3.66h50.28c0-2.57.05-5,0-7.5a3.51,3.51,0,0,0-3-3.09c-2.55-.11-5.11,0-7.78,0l-.18,3.47h-3.3v-1.63c0-1.86,0-1.86-1.93-1.87H244.13v3.39h-3.5v-3.35c-2.69,0-5.25-.08-7.8,0a3.36,3.36,0,0,0-2.91,2.81C229.85,437.68,229.9,440.24,229.9,442.89Z" transform="translate(-226.32 -424.97)"/>
      <path d="M256.8,461.88c0,2.62,0,5.23,0,7.85,0,1-.48,1.52-1.42,1.53s-1.45-.49-1.45-1.49q0-7.9,0-15.8a1.36,1.36,0,0,1,1.4-1.56c.91,0,1.47.59,1.47,1.62C256.81,456.64,256.8,459.26,256.8,461.88Z" transform="translate(-226.32 -424.97)"/>
      <path d="M253.93,476c.11-.73.37-1.45,1.42-1.45s1.4.67,1.43,1.55a1.42,1.42,0,0,1-1.35,1.54A1.53,1.53,0,0,1,253.93,476Z" transform="translate(-226.32 -424.97)"/>
    </SvgIcon>
  );
}
