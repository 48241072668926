/* @flow */


import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

import ButtonComponent from 'components/buttons/button';
import FormErrorsComponent from 'components/formErrors';
import IconCheckOutlined from 'components/icons/outlined/check';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import PasswordRulesComponent from 'components/passwordRules';

import SetPasswordForm from './model';

type SignUpTab5ViewComponentProps = {
  errors: Map<string, ?string>;
  form: SetPasswordForm;
  formErrors: string[];
  isProcessing: boolean;
  handlePasswordChange: () => void;
  handleConfirmPasswordChange: () => void;
  submitForm: () => void;
}

export default function SignUpTab5ViewComponent(props: SignUpTab5ViewComponentProps) {
  return (
    <React.Fragment>
      <FormErrorsComponent formErrors={props.formErrors} />

      <PageSubTitleViewComponent id="page.signup.tab5.title" />
      <PasswordRulesComponent />

      <Box mt={3}>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item xs={12} md={6}>
            <Box px={4}>      
              <TextField
                id="form.password"
                type="password"
                label={<IntlMessageComponent id="form.password.label" />}
                error={!!props.errors.get('password')}
                helperText={props.errors.get('password') ? <IntlMessageComponent id={props.errors.get('password')} /> : ''}
                value={props.form.password}
                onChange={props.handlePasswordChange}
                fullWidth
                margin="normal"
                InputProps={{
                  endAdornment: (props.form.password && !props.errors.get('password') &&
                    <InputAdornment position="end">
                      <IconCheckOutlined color="secondary" />
                    </InputAdornment>
                  )}}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.confirmPassword"
                type="password"
                label={<IntlMessageComponent id="form.confirmPassword.label" />}
                error={!!props.errors.get('confirmPassword')}
                helperText={props.errors.get('confirmPassword') ? <IntlMessageComponent id={props.errors.get('confirmPassword')} /> : ''}
                value={props.form.confirmPassword}
                onChange={props.handleConfirmPasswordChange}
                fullWidth
                margin="normal"
                InputProps={{
                  endAdornment: (props.form.confirmPassword && !props.errors.get('confirmPassword') &&
                    <InputAdornment position="end">
                      <IconCheckOutlined color="secondary" />
                    </InputAdornment>
                  )}}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box mt={3}>
        <ButtonComponent
          color="primary"
          variant="contained"
          isProcessing={props.isProcessing}
          onClick={props.submitForm}
        ><IntlMessageComponent id="general.continue" /></ButtonComponent>
      </Box>
    </React.Fragment>
  );
}
