/* @flow */

import React from 'react';
import {useSelector} from 'react-redux';

import moment from 'moment';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import IntlMessageComponent from 'components/formatters/intlMessage';

import RequiredCommissionDocument from 'models/documents/v2/requiredCommissionDocument';

type CommissionDocumentSelectComponentProps = {
  commissionId: number;
  documents: RequiredCommissionDocument[];
  fullWidth: boolean;
  margin: string;
  required: boolean;
  changeHandler: (commissionId: number) => void;
}

export default function CommissionDocumentSelectComponent(props: CommissionDocumentSelectComponentProps) {
  const translations = useSelector(state => state.locale.translations.filter(t => t.key === 'general.period.quarter'));

  const values = props.documents.map(doc => {
    const name = `${moment(doc.periodFrom).format('YYYY')} ${translations[0].text}${moment(doc.periodFrom).quarter()}`;
    return {id: doc.commissionId, name};
  });

  const value = values.find(v => v.id === props.commissionId) || null;

  const handleSelect = (value) => {
    if (value == null) return props.changeHandler(0);
    return props.changeHandler(value.id);
  };

  return (
    <Autocomplete
      getOptionLabel={(value) => value.name}
      onChange={(_, newValue) => handleSelect(newValue)}
      openOnFocus
      options={values}
      renderInput={(params) =>
        <TextField 
          {...params}
          label={<IntlMessageComponent id="form.commission.period.label" />}
          fullWidth={props.fullWidth}
          margin={props.margin}
          required={props.required}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      }
      value={value}
    />
  );
}
