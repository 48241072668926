import React from 'react';
import {useSelector} from 'react-redux';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import CountryFieldBuyersComponent from 'components/formFields/countries/buyers';
import CountryFieldDebtorsComponent from 'components/formFields/countries/debtors';
import CountryFieldISOComponent from 'components/formFields/countries/isos';
import CountryFieldSellersComponent from 'components/formFields/countries/sellers';
import CountryFieldConfirmingComponent from 'components/formFields/countries/confirming';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import {ROLE_BUYER, ROLE_DEBTOR, ROLE_ISO, ROLE_SELLER, ROLE_CONFIRMING} from 'constants/companyRoles';

import CompanyInfoForm from '../model';

type SubscriptionTab1BankAccountViewComponentProps = {
  errors: Map<string, ?string>;
  form: CompanyInfoForm;
  handleChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void;
  handleTypeChange: (fieldName: string) => (id: number) => void;
}

export default function SubscriptionTab1BankAccountViewComponent(props: SubscriptionTab1BankAccountViewComponentProps) {
  const activeCompanyRole = useSelector(state => state.auth.user.activeCompany.role);

  return (
    <React.Fragment>
      <PageSubTitleViewComponent id="page.subscription.tab1.bank.h2" />
      
      <Box mt={3}>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              {activeCompanyRole === ROLE_BUYER &&
                <CountryFieldBuyersComponent
                  id="form.bankCountry"
                  label={<IntlMessageComponent id="form.bankCountry.label" />}
                  error={!!props.errors.get('bankCountryId')}
                  helperText={props.errors.get('bankCountryId') ?
                    <IntlMessageComponent id={props.errors.get('bankCountryId')} /> : ''}
                  value={props.form.bankCountryId}
                  changeHandler={props.handleTypeChange('bankCountryId')}
                  fullWidth
                  margin="normal"
                  required={activeCompanyRole !== ROLE_DEBTOR}
                />
              }
              {activeCompanyRole === ROLE_DEBTOR &&
                <CountryFieldDebtorsComponent
                  id="form.bankCountry"
                  label={<IntlMessageComponent id="form.bankCountry.label" />}
                  error={!!props.errors.get('bankCountryId')}
                  helperText={props.errors.get('bankCountryId') ?
                    <IntlMessageComponent id={props.errors.get('bankCountryId')} /> : ''}
                  value={props.form.bankCountryId}
                  changeHandler={props.handleTypeChange('bankCountryId')}
                  fullWidth
                  margin="normal"
                  required={activeCompanyRole !== ROLE_DEBTOR}
                />
              }
              {activeCompanyRole === ROLE_ISO &&
                <CountryFieldISOComponent
                  id="form.bankCountry"  
                  label={<IntlMessageComponent id="form.bankCountry.label" />}
                  error={!!props.errors.get('bankCountryId')}
                  helperText={props.errors.get('bankCountryId') ?
                    <IntlMessageComponent id={props.errors.get('bankCountryId')} /> : ''}
                  value={props.form.bankCountryId}
                  changeHandler={props.handleTypeChange('bankCountryId')}
                  fullWidth
                  margin="normal"
                  required={activeCompanyRole !== ROLE_DEBTOR}
                />
              }
              {activeCompanyRole === ROLE_SELLER &&
                <CountryFieldSellersComponent
                  id="form.bankCountry"  
                  label={<IntlMessageComponent id="form.bankCountry.label" />}
                  error={!!props.errors.get('bankCountryId')}
                  helperText={props.errors.get('bankCountryId') ?
                    <IntlMessageComponent id={props.errors.get('bankCountryId')} /> : ''}
                  value={props.form.bankCountryId}
                  changeHandler={props.handleTypeChange('bankCountryId')}
                  fullWidth
                  margin="normal"
                  required={activeCompanyRole !== ROLE_DEBTOR}
                />
              }
              {activeCompanyRole === ROLE_CONFIRMING &&
                <CountryFieldConfirmingComponent
                  id="form.bankCountry"  
                  label={<IntlMessageComponent id="form.bankCountry.label" />}
                  error={!!props.errors.get('bankCountryId')}
                  helperText={props.errors.get('bankCountryId') ?
                    <IntlMessageComponent id={props.errors.get('bankCountryId')} /> : ''}
                  value={props.form.bankCountryId}
                  changeHandler={props.handleTypeChange('bankCountryId')}
                  fullWidth
                  margin="normal"
                  required={activeCompanyRole !== ROLE_DEBTOR}
                />
              }
            </Box>
          </Grid>
        </Grid>
      
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.bic"
                data-walkme="subscription-field-bic"
                label={<IntlMessageComponent id="form.bankIdentifier.label" />}
                error={!!props.errors.get('bankIdentifier')}
                helperText={(props.errors.get('bankIdentifier')) ?
                  <IntlMessageComponent id={props.errors.get('bankIdentifier')} /> : ''}
                value={props.form.bankIdentifier}
                onChange={props.handleChange('bankIdentifier')}
                fullWidth
                margin="normal"
                required={activeCompanyRole !== ROLE_DEBTOR}
              />
            </Box>
          </Grid>
        
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.iban"
                data-walkme="subscription-field-iban"
                label={<IntlMessageComponent id="form.bankAccount.label" />}
                error={!!props.errors.get('bankAccount')}
                helperText={(props.errors.get('bankAccount')) ?
                  <IntlMessageComponent id={props.errors.get('bankAccount')} /> : ''}
                value={props.form.bankAccount}
                onChange={props.handleChange('bankAccount')}
                fullWidth
                margin="normal"
                required={activeCompanyRole !== ROLE_DEBTOR}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}