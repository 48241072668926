/* @flow */

import React from 'react';

import PaymentDeclinedComponent from 'app/payments/declined';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function OgonePaymentDeclinedPage() {
  return (
    <WebsitePageComponent id="page.payment.title">
      <PaymentDeclinedComponent />
    </WebsitePageComponent>
  );
}
