/* @flow */

import CommissionMsfIsoOffice from './commissionMsfIsoOffice';

export default class CommissionMsfIsoCompany {
  id: number;
  name: string;
  offices: CommissionMsfIsoOffice[];
  msfPayment: number;
  revenues: number;
  paidRevenues: number;
  msfCommission: number;


  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
    this.offices = [];
    this.msfPayment = 0;
    this.revenues = 0;
    this.paidRevenues = 0;
    this.msfCommission = 0;
  }
}
