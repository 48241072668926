/* @flow */

import {all, call, fork, put, takeEvery} from 'redux-saga/effects';

import {
  SIGNIN_AFTER_REGISTRATION_EDEBEX_USER_SAGA,
  SIGNIN_EDEBEX_USER_SAGA,
  SIGNIN_RETURNING_EDEBEX_USER_SAGA,
  SIGNOUT_EDEBEX_USER_SAGA,
  IMPERSONATE_EDEBEX_USER_SAGA,
  SWITCH_USER_COMPANY_SAGA,
  RELOAD_USER_COMPANY_SAGA,
  ADD_USER_COMPANY_SAGA,
  ADD_INVESTMENT_PROFILE_SUCCESS,
  SET_IDENTITY_DOCUMENTS_SAGA
} from '../actionTypes';

import {
  showAuthLoader,
  userSignInSuccess,
  userSignInError,
  userSignInServerError,
  userSignOutSuccess,
  userSignInImpersonateError,
  userSwitchCompanySuccess,
  userSwitchCompanyInvestmentProfileAction,
  addCompanySuccess,
  setIdentityDocumentsSuccess
} from '../actions/authActions';

import {ApiSignInError, ApiValidationError} from 'services/api/apiErrors';

import AuthService from 'services/AuthService';
import UserService from 'services/UserService';

import {history} from '../index';
import {PROTECTED_APP, PROTECTED_APP_SUBSCRIPTION, PROTECTED_BUYER_INVESTMENT_PROFILE} from 'constants/pageRoutes';


function* signInUserAfterRegistrationSaga({payload}) {
  const {username, password, twofactorpin} = payload;
  const authService = new AuthService();

  try {
    yield call(authService.signInEdebexUser, username, password, twofactorpin);

    const user = yield call(UserService.retrieveEdebexUserInfo);
    yield put(userSignInSuccess(user));
  } catch (e) {
    authService.clearTokenStorage();
    if (e instanceof ApiSignInError) {
      yield put(userSignInError(e));
    } else {
      yield put(userSignInServerError());
    }
  }
}

function* signInUserSaga({payload}) {
  const {username, password, returnUrl, twofactorpin} = payload;
  const authService = new AuthService();

  try {
    yield put(showAuthLoader());
    yield call(authService.signInEdebexUser, username, password, twofactorpin);

    const user = yield call(UserService.retrieveEdebexUserInfo);
    yield put(userSignInSuccess(user));

    let url = PROTECTED_APP;
    if (returnUrl !== null) {
      url = returnUrl;
    } else if (user.activeCompany.isSubscribing) {
      url = PROTECTED_APP_SUBSCRIPTION;
    }

    yield call(history.push, url);
  } catch (e) {
    authService.clearTokenStorage();
    if (e instanceof ApiSignInError) {
      yield put(userSignInError(e));
    } else {
      yield put(userSignInServerError());
    }
  }
}

function* signInReturningSaga({payload}) {
  const {returnUrl} = payload;
  try {
    const authService = new AuthService();
    if (authService.validateTokenFromUser()) {
      
      yield put(showAuthLoader());

      const user = yield call(UserService.retrieveEdebexUserInfo);
      yield put(userSignInSuccess(user));

      yield call(history.push, returnUrl);
    } else {
      yield put(userSignInSuccess(null));
    }
  } catch (e) {
    console.error(e);
    const authService = new AuthService();
    authService.clearTokenStorage();
  }
}

function* impersonateUserSaga({payload}) {
  const {username, password, target, twofactorpin } = payload;
  const authService = new AuthService();

  // Login to your account
  try {
    yield call(authService.signInEdebexUser, username, password, twofactorpin);
  } catch (e) {
    authService.clearTokenStorage();
    if (e instanceof ApiSignInError) {
      yield put(userSignInError(e));
    } else {
      yield put(userSignInServerError());
    }
    return;
  }

  // Impersonate
  try {
    yield call(authService.impersonateEdebexUser, target);
  } catch (e) {
    authService.clearTokenStorage();
    if (e instanceof ApiValidationError) {
      yield put(userSignInImpersonateError(e.formErrors[0]));
    } else {
      yield put(userSignInImpersonateError(e));
    }
    return;
  }

  // Retrieve impersonated user info
  try {
    const user = yield call(UserService.retrieveEdebexUserInfo);
    yield put(userSignInSuccess(user));

    yield call(history.push, PROTECTED_APP);
  } catch (e) {
    console.error(e);
    authService.clearTokenStorage();
    yield put(userSignInServerError());
  }
}

function* signOutUserSaga() {
  try {
    const authService = new AuthService();
    authService.clearTokenStorage();
    yield put(userSignOutSuccess());
  } catch (e) {
    console.error(e);
  }
}

function* switchUserCompany({payload}) {
  const {companyId} = payload;

  try {
    yield put(showAuthLoader());
    const userCompany = yield call(UserService.getUserCompany, companyId);
    yield put(userSwitchCompanySuccess(userCompany));
    yield call(history.push, PROTECTED_APP);
  } catch (e) {
    console.error(e);
  }
}

function* reloadUserCompany({payload}) {
  const {companyId} = payload;

  try {
    yield put(showAuthLoader());
    const userCompany = yield call(UserService.getUserCompany, companyId);
    yield put(userSwitchCompanySuccess(userCompany));
  } catch (e) {
    console.error(e);
  }
}

function* addNewUserCompany({payload}) {
  const {companyId} = payload;

  try {
    yield put(showAuthLoader());

    const user = yield call(UserService.retrieveEdebexUserInfo);
    const userCompany = yield call(UserService.getUserCompany, companyId);

    // easier to do it here than in redux :|
    user.activeCompany = userCompany;
    user.companies = user.companies.map((c) => {
      if (c.id === userCompany.id) {
        return userCompany;
      }
      return c;
    });

    yield put(addCompanySuccess(user));
    yield call(history.push, PROTECTED_APP_SUBSCRIPTION);
  } catch (e) {
    console.error(e);
  }
}

function* addNewInvestmentProfileSuccess({payload}) {
  const {investmentProfile} = payload;

  try {
    yield put(showAuthLoader());
    const userCompany = yield call(UserService.getUserCompany, investmentProfile.companyId);
    yield put(userSwitchCompanySuccess(userCompany));
    const newInvestmentProfile = userCompany.roleBuyer.investmentProfiles.find(obj => {
      return obj.name === investmentProfile.name
    });
    yield put(userSwitchCompanyInvestmentProfileAction(newInvestmentProfile));
    yield call(history.push, PROTECTED_BUYER_INVESTMENT_PROFILE);
  } catch (e) {
    console.error(e);
  }
}

function* setUserHasProvidedIdentityDocumentsSaga() {
  try {
    yield put(setIdentityDocumentsSuccess());
  } catch (e) {
    console.error(e);
  }
}

export function* authEdebexRegister() {
  yield takeEvery(SIGNIN_AFTER_REGISTRATION_EDEBEX_USER_SAGA, signInUserAfterRegistrationSaga);
  yield takeEvery(SIGNIN_EDEBEX_USER_SAGA, signInUserSaga);
  yield takeEvery(SIGNIN_RETURNING_EDEBEX_USER_SAGA, signInReturningSaga);
  yield takeEvery(SIGNOUT_EDEBEX_USER_SAGA, signOutUserSaga);
  yield takeEvery(IMPERSONATE_EDEBEX_USER_SAGA, impersonateUserSaga);
  yield takeEvery(SWITCH_USER_COMPANY_SAGA, switchUserCompany);
  yield takeEvery(RELOAD_USER_COMPANY_SAGA, reloadUserCompany);
  yield takeEvery(ADD_USER_COMPANY_SAGA, addNewUserCompany);
  yield takeEvery(ADD_INVESTMENT_PROFILE_SUCCESS, addNewInvestmentProfileSuccess);
  yield takeEvery(SET_IDENTITY_DOCUMENTS_SAGA, setUserHasProvidedIdentityDocumentsSaga);
}

export default function* rootSaga() {
  yield all([fork(authEdebexRegister)]);
}
