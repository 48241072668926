/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import LoadingComponent from 'components/loading';

import RegisterSellerCompanyTab4ViewComponent from './view';

import Document from 'models/documents/document';

import DocumentService from 'services/DocumentService';
import IsoService from 'services/IsoService';
import {handleApiFormResponse} from 'lib/forms';

import {ISO_REGISTER_SELLER_DOCUMENT_POST} from 'constants/apiRoutes';

import NotificationHelper from 'lib/notifications';

type RegisterSellerCompanyTab4ComponentProps = {
  activeCompanyId: number;
  companyId: number;
  companyPersonId: number;
  successHandler: () => void;
};

type RegisterSellerCompanyTab4ComponentState = {
  documentId: number;
  documents: Document[];
  isLoading: boolean;
  isProcessing: boolean;
};

class RegisterSellerCompanyTab4Component extends React.Component<RegisterSellerCompanyTab4ComponentProps, RegisterSellerCompanyTab4ComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      documentId: 0,
      documents: [],
      isLoading: true,
      isProcessing: false
    };
  }

  componentDidMount = async () => {
    try {
      const response = await IsoService.getRequiredDocuments(this.props.activeCompanyId, this.props.companyId, this.props.companyPersonId);

      let i=0;
      response.documents.forEach(d => {
        d.id = i;
        i++;
      });

      this.setState({
        documentId: i, 
        documents: response.documents, 
        isLoading: false,
        toClassifyDocumentType: response.toClassifyDocumentType
      });
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  }

  handleDocumentAdd = () => {
    const documents = this.state.documents;
    const document = new Document(this.state.toClassifyDocumentType, false, false);
    document.id = this.state.documentId;
    documents.push(document);
    this.setState(prevState => ({documentId: prevState.documentId + 1, documents}));
  }

  handleDocumentSelect = (documentId: number) => (file: File) => {
    const documents = this.state.documents;
    const document = documents.find(d => d.id === documentId);
    if (!document) return;
    document.file = file;
    document.filename = file.name;
    document.isMissing = false;   
    this.setState({documents});
  };

  handleDocumentRemove = (documentId: number) => () => {
    let documents = this.state.documents;
    const document = documents.find(d => d.id === documentId);
    if (!document) return;
    if (document.isRequired) {
      // can't remove required documents
      document.file = null;
      document.filename = '';
      document.isMissing = false;
    } else {
      // remove optional - not yet submitted - documents
      documents = documents.filter(d => d.id !== documentId);
    }
    this.setState({documents});
  };

  submitForm = async () => {
    if (!this.validate()) return;

    // add documents
    const addPromises = [];
    this.state.documents.forEach(document => {
      if (document.file !== null) {
        addPromises.push(DocumentService.sendDocument2(ISO_REGISTER_SELLER_DOCUMENT_POST, document.file,
          {isoCompanyId: this.props.activeCompanyId, companyId: this.props.companyId, companyPersonId: this.props.companyPersonId, documentTypeId: document.type.typeId}));
      }
    });

    // if nothing, do nothing
    if (addPromises.length === 0) {
      this.props.successHandler();
      return;
    }

    this.setState({isProcessing: true});

    try {
      await Promise.all(addPromises);
      this.props.successHandler();
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }
  }

  validate = () => {
    let isValid = true;
    const documents = this.state.documents;

    documents.forEach(document => {
      if (document.file === null && !document.isProvided) {
        // not yet provided and missing -> issue
        document.isMissing = true;
        isValid = false;
      }
    });

    if (!isValid) this.setState({documents});
    return isValid;
  }

  render = () => {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <RegisterSellerCompanyTab4ViewComponent
        documents={this.state.documents}
        isProcessing={this.state.isProcessing}
        handleDocumentSelect={this.handleDocumentSelect}
        handleDocumentRemove={this.handleDocumentRemove}
        addDocument={this.handleDocumentAdd}
        submitForm={this.submitForm}
      />
    );
  }
}

const mapStateToProps = ({auth}) => ({
  activeCompanyId: auth.user.activeCompany.id
});

export default connect(mapStateToProps)(RegisterSellerCompanyTab4Component);
