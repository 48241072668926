/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment/moment';

import LoadingComponent from 'components/loading';

import CompanyUBOsFormViewComponent from './view';
import CompanyUBOForm from './model';
import CompanyUBOsFormSelectionViewComponent from './selection';

import CompanyUBO from 'models/companies/companyUBO';

import CompanyService from 'services/CompanyService';
import DocumentService from 'services/DocumentService';
import { handleApiFormResponse, handleFormChange, handleFormValidation, getValidationErrors } from 'lib/forms';
import loadConstraints from 'lib/validation/loadConstraints';
import { DOCUMENTS_PERSON_POST } from 'constants/apiRoutes';

import { getDocumentTypeBack, getDocumentTypeFront, checkId } from 'lib/documentHelpers';

type CompanyUBOsFormComponentProps = {
  activeCompanyId: number;
  ubo: CompanyUBO;
  isExpired: boolean;
  currentShares: number;
  onCancel: () => void;
  onSuccess: (id: number, form: CompanyUBOForm) => void;
}

type CompanyUBOsFormComponentState = {
  constraints: any;
  errors: Map<string,?string>;
  form: CompanyUBOForm;
  formErrors: string[];
  isLoading: boolean;
  isProcessing: boolean;
  addExistingMode: boolean;
  possibleUbos: CompanyUBO[];
  selectedUbo: CompanyUBO;
  useSelection: boolean;
  formValidChecked: Boolean;
}

class CompanyUBOsFormComponent extends React.Component<CompanyUBOsFormComponentProps, CompanyUBOsFormComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      constraints: null,
      errors: null,
      formErrors: [],
      form: null,
      docNotExpiredAndNotReplaced: [],
      hasValidId: false,
      isLoading: true,
      isProcessing: false,
      useSelection: false,
      formValidChecked: false
    };
  }

  componentDidMount = async () => {
    try {
      // Load constraints for one UBO
      const constraintsFields = [];

      if (this.props.ubo && this.props.ubo.isCompanyUser) {
        constraintsFields.push('functionName', 'shares');
      } else {
        constraintsFields.push('titleId', 'firstName', 'lastName', 'email',
          'addressLine1', 'addressLine2', 'zipCode', 'city', 'countryId',
          'nationalityId', 'birthCountryId', 'birthDate', 'birthPlace',
          'registrationTypeId', 'registrationNumber', 'registrationExpireDate',
          'functionName', 'shares', 'hasValidId', 'idFront', 'idBack');
      }


      let possibleUbos = null;
      let useSelection = false;
      if (this.props.ubo === null) {
        const response = await CompanyService.getCompanyPersonsWithoutUBO(this.props.activeCompanyId);
        possibleUbos = response.ubos.length > 0 ? response.ubos : null;
        useSelection = true;
      }

      let tmpUBOFormData = new CompanyUBOForm(this.props.ubo, this.props.currentShares);
      const docNotExpiredAndNotReplaced =
        await DocumentService.getPersonNotExpiredAndNotReplacedDocuments(this.props.activeCompanyId, tmpUBOFormData.id);

      // Check if the ID is valid
      let hasValidId = await checkId(docNotExpiredAndNotReplaced, this.props.ubo?.registrationTypeId);
      const constraints = await loadConstraints('Person', constraintsFields);

      this.setState({
        constraints,
        errors: new Map(Object.keys(constraints).map(e => [e, undefined])),
        form: new CompanyUBOForm(this.props.ubo, this.props.currentShares, hasValidId),
        isLoading: false,
        addExistingMode: this.props.ubo === null,
        docNotExpiredAndNotReplaced,
        hasValidId,
        possibleUbos,
        useSelection
      });

      if (/*!this.state.hasValidId &&*/ !this.state.formValidChecked && this.props.ubo.id > 0 && this.props.ubo.isActive && this.props.isExpired) {
        this.checkFormValid();
      }
    } catch (e) {
      console.error(e);
    }
  };

  handleChange = (fieldName: string) => (event) => handleFormChange.call(this, fieldName, event.target.value);
  handleNumberChange = (fieldName: string) => (event) => handleFormChange.call(this, fieldName, Number(event.target.value));
  handleBooleanChange = (fieldName: string) => (value: boolean) => handleFormChange.call(this, fieldName, value);
  handleDateChange = (fieldName: string) => (date) => handleFormChange.call(this, fieldName, date);
  handleFileChange = (fieldName: string) => (file: File) => handleFormChange.call(this, fieldName, file);
  handleTypeChange = (fieldName: string) => (id: number) => {
    handleFormChange.call(this, fieldName, id);
    let ubo = this.props.ubo !== undefined && this.props.ubo !== null ? this.props.ubo : this.state.selectedUbo !== undefined && this.state.selectedUbo !== null ? this.state.selectedUbo : null;
    if (fieldName === 'registrationTypeId' && ubo?.registrationTypeId > 0) {
      let form = this.state.form;
      form.registrationTypeId = id;
      let hasValidId = checkId(this.state.docNotExpiredAndNotReplaced, id);
      this.setState({ hasValidId })
      handleFormChange.call(this, 'hasValidId', hasValidId);

      if (id === ubo?.registrationTypeId) {
        handleFormChange.call(this, 'registrationNumber', ubo.registrationNumber);
        handleFormChange.call(this, 'registrationExpireDate', ubo.registrationExpireDate);
      }
      else {
        //handleFormChange.call(this, 'registrationNumber', '');
        //handleFormChange.call(this, 'registrationExpireDate', moment.utc().startOf('d'));
        form.registrationNumber = '';
        form.registrationExpireDate = moment.utc().startOf('d');
        this.setState({ form });
      } 
    }
  }
  validateForm = () => handleFormValidation.call(this);

  checkFormValid = () => {
    let errors = getValidationErrors.call(this);
    this.setState({ errors: errors, formValidChecked: true });
  }

  submitForm = async () => {
    if (!this.validateForm()) return;

    this.setState({ formErrors: [], isProcessing: true });

    try {
      if (this.state.form.id === 0 || this.state.useSelection) {
        const request = this.state.form.toAddCompanyUBORequest(this.props.activeCompanyId, this.state.useSelection);
        const companyPersonId = await CompanyService.addCompanyUBO(request);
        if (this.state.form.idFront !== null) {
          let documentTypeFront = getDocumentTypeFront(this.state.form.registrationTypeId);
          await DocumentService.sendDocument2(DOCUMENTS_PERSON_POST, this.state.form.idFront, { companyPersonId, documentTypeId: documentTypeFront });
        }
        if (this.state.form.idBack !== null) {
          let documentTypeBack = getDocumentTypeBack(this.state.form.registrationTypeId);
          await DocumentService.sendDocument2(DOCUMENTS_PERSON_POST, this.state.form.idBack, { companyPersonId, documentTypeId: documentTypeBack });
        }
      } else {
        const request = this.state.form.toUpdateCompanyUBORequest(this.props.activeCompanyId);
        await CompanyService.updateCompanyUBO(request);

        if (this.state.form.idFront !== null) {
          let documentTypeFront = getDocumentTypeFront(this.state.form.registrationTypeId);
          await DocumentService.sendDocument2(DOCUMENTS_PERSON_POST, this.state.form.idFront, { companyPersonId: this.state.form.id, documentTypeId: documentTypeFront });
        }
        if (this.state.form.idBack !== null) {
          let documentTypeBack = getDocumentTypeBack(this.state.form.registrationTypeId);
          await DocumentService.sendDocument2(DOCUMENTS_PERSON_POST, this.state.form.idBack, { companyPersonId: this.state.form.id, documentTypeId: documentTypeBack });
        }
      }

      this.props.onSuccess();
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }
  }

  selectionConfirm = async () => {
    if (this.state.useSelection) {
      const docNotExpiredAndNotReplaced = await DocumentService.getPersonNotExpiredAndNotReplacedDocuments(this.props.activeCompanyId, this.state.selectedUbo.id);

      const constraintsFields = [];
      constraintsFields.push('functionName', 'shares', 'titleId', 'firstName', 'lastName', 'email',
        'addressLine1', 'addressLine2', 'zipCode', 'city', 'countryId',
        'nationalityId', 'birthCountryId', 'birthDate', 'birthPlace',
        'registrationTypeId', 'registrationNumber', 'registrationExpireDate',
        'functionName', 'shares', 'hasValidId', 'idFront', 'idBack');

      let hasValidId = await checkId(docNotExpiredAndNotReplaced, this.state.selectedUbo?.registrationTypeId);
      const constraints = await loadConstraints('Person', constraintsFields);

      this.setState({ possibleUbos: null, form: new CompanyUBOForm(this.state.selectedUbo, this.props.currentShares, hasValidId), constraints, errors: new Map(Object.keys(constraints).map(e => [e, undefined])), hasValidId, docNotExpiredAndNotReplaced });
    }
    else
      this.setState({ possibleUbos: null });
  }

  handleSelectedUboChange = (event) => {
    let possibleUbos = this.state.possibleUbos;
    let selectedUbo = possibleUbos.filter(ubo => ubo.id === event.target.value)[0];
    this.setState({ selectedUbo });
  }

  handleUseSelectionChange = (event) => {
    let useSelection = event.target.value === 'true';
    this.setState({ useSelection });
  }

  render() {
    if (this.state.isLoading) {
      return <LoadingComponent />;
    }

    if (this.state.possibleUbos) {
      return (
        <CompanyUBOsFormSelectionViewComponent
          cancel={this.props.onCancel}
          possibleUbos={this.state.possibleUbos}
          selectedUbo={this.state.selectedUbo}
          submitForm={this.selectionConfirm}
          handleSelectedUboChange={this.handleSelectedUboChange}
          handleUseSelectionChange={this.handleUseSelectionChange}
          useSelection={this.state.useSelection}
        />
      );
    }

    return (
      <CompanyUBOsFormViewComponent
        errors={this.state.errors}
        form={this.state.form}
        formErrors={this.state.formErrors}
        isCompanyUser={this.props.ubo && this.props.ubo.isCompanyUser}
        isProcessing={this.state.isProcessing}
        cancel={this.props.onCancel}
        handleChange={this.handleChange}
        handleNumberChange={this.handleNumberChange}
        handleBooleanChange={this.handleBooleanChange}
        handleDateChange={this.handleDateChange}
        handleFileChange={this.handleFileChange}
        handleTypeChange={this.handleTypeChange}
        submitForm={this.submitForm}
        docNotExpiredAndNotReplaced={this.state.docNotExpiredAndNotReplaced}
        hasValidId={this.state.hasValidId}
      />
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id,
  companyPersonId: state.auth.user.activeCompany.companyPersonId
});

export default connect(mapStateToProps)(CompanyUBOsFormComponent);
