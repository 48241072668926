/* @flow */

import CommissionMsfIsoCompany from './commissionMsfIsoCompany';

export default class CommissionMsfData {
  companies: CommissionMsfIsoCompany[];
  msfPayment: number;
  revenues: number;
  paidRevenues: number;
  msfCommission: number;

  constructor() {
    this.companies = [];
    this.msfPayment = 0;
    this.revenues = 0;
    this.paidRevenues = 0;
    this.msfCommission = 0;
  }
}
