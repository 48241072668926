/* @flow */

import React from 'react';
import { useHistory } from 'react-router-dom';

import Box from '@material-ui/core/Box';

import Typography from '@material-ui/core/Typography';

import ButtonComponent from 'components/buttons/button';
import FormErrorsComponent from 'components/formErrors';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import ProcurationPickerComponent from 'components/formFields/procurations';

import CompanyUserProcuration from 'models/companies/companyUserProcuration';
import CompanyUserProcurationForm from './model';

import { PROTECTED_COMPANY_USERS } from 'constants/pageRoutes';

type CompanyUsersProcurationViewComponentProps = {
  errors: Map<string,?string>;
  form: CompanyUserProcurationForm;
  formErrors: string[];
  isProcessing: boolean;
  procurations: CompanyUserProcuration;
  handleChange: (fieldName: string) => (value: any) => void;
  submitForm: () => void;
}

export default function CompanyUsersProcurationViewComponent(props: CompanyUsersProcurationViewComponentProps) {
  const history = useHistory();
  const cancel = () => history.push(PROTECTED_COMPANY_USERS);

  return (
    <React.Fragment>
      <PageSubTitleViewComponent id="page.users.procuration.title" />

      <Typography variant="body1" gutterBottom>
        <IntlMessageComponent id="page.users.procuration.text.notmandatory" />
      </Typography>

      <FormErrorsComponent formErrors={props.formErrors} />

      <Box mt={3}>
        <ProcurationPickerComponent
          companyIsBuyer={props.procurations.companyIsBuyer}
          companyIsDebtor={props.procurations.companyIsDebtor}
          companyIsISO={props.procurations.companyIsISO}
          companyIsSeller={props.procurations.companyIsSeller}
          companyIsConfirming={props.procurations.companyIsConfirming}
          error={!!props.errors.get('hasProcuration')}
          helperText={props.errors.get('hasProcuration') ?
            <IntlMessageComponent id={props.errors.get('hasProcuration')} /> : ''}
          values={props.form.hasProcuration}
          handleChange={props.handleChange('hasProcuration')}
          fullWidth
          margin="normal"
          required={!props.procurations.isObserver}
        />

      </Box>

      <Box mt={5}>
        <ButtonComponent
          color="primary"
          variant="contained"
          isProcessing={props.isProcessing}
          onClick={props.submitForm}
        ><IntlMessageComponent id="page.users.procuration.send" /></ButtonComponent>

        <Box component="span" ml={1}>
          <ButtonComponent
            color="default"
            variant="contained"
            onClick={cancel}
          ><IntlMessageComponent id="page.users.procuration.notneeded" /></ButtonComponent>
        </Box>
      </Box>
    </React.Fragment>
  );
}