/* @flow */

import React from 'react';
import moment from 'moment';

import { connect } from 'react-redux';

import customSort from 'lib/sortHelpers';

import LoadingComponent from 'components/loading';

import BillsPortfolioTableViewComponent from './view';
import BillsPortfolioTableControlsViewComponent from './tableControls';

import Bill from 'models/documents/bill';
import BillActiveFilters from 'models/documents/billActiveFilters';

import DownloadSelectedBillsRequest from 'models/requests/documents/downloadSelectedBillsRequest';

import DocumentService from 'services/DocumentService';

import NotificationHelper from 'lib/notifications';

import { ROLE_BUYER, ROLE_SELLER, ROLE_DEBTOR } from 'constants/companyRoles';

type BillsPortfolioComponentProps = {
  activeCompanyId: number;
  activeCompanyRole: number;
}

type BillsPortfolioComponentState = {
  activeFilters: BillActiveFilters;
  bills: Bill[];
  isLoading: boolean;
  order: string;
  orderBy: string;
  page: number;
  pageSize: number;
}

class BillsPortfolioComponent extends React.Component<BillsPortfolioComponentProps, BillsPortfolioComponentState> {
  constructor(props) {
    super(props);

    this.state = {
      activeFilters: new BillActiveFilters(),
      bills: [],
      isLoading: true,
      order: 'asc',
      orderBy: null,
      page: 0,
      pageSize: 10
    };
  }

  componentDidMount = async () => {
    try {
      const bills = await DocumentService.getBills(this.props.activeCompanyId);
      bills.forEach(b => b.isSelected = false);
      this.setState({ bills, isLoading: false });
    } catch (e) {
      console.error(e);
    }
  }

  handleFiltersChange = (activeFilters: BillActiveFilters) => this.setState({ activeFilters });

  handlePageChange = (page: number) => this.setState({ page });
  handlePageSizeChange = (pageSize: number) => this.setState({ pageSize });

  handleSortOrderChange = (orderBy: string) => {
    let order = this.state.orderBy === orderBy && this.state.order === 'desc' ? 'asc' : 'desc';

    const bills = order === 'desc'
      ? this.state.bills.sort((a, b) => customSort(b[orderBy], a[orderBy]))
      : this.state.bills.sort((a, b) => customSort(a[orderBy], b[orderBy]));

    this.setState({ bills, order, orderBy });
  };

  getFilteredResults = () => {
    let filteredBills = this.state.bills;

    // filter on query search
    if (this.state.activeFilters.query) {
      filteredBills = filteredBills.filter((b: Bill) => {
        const query = this.state.activeFilters.query.toUpperCase();
        let fields = [b.reference, b.amount].map(v => (v || '').toString().toUpperCase());
        if (this.props.activeCompanyRole == ROLE_SELLER) {
          fields = [b.reference, b.amount, b.invoiceReference].map(v => (v || '').toString().toUpperCase());
        }

        if (this.state.activeFilters.query.length > 0) {
          // Search an occurence of the string
          return !!(fields.filter(field => field.toString().indexOf(query) !== -1)).length;
        } else {
          // Search the whole string
          return (!fields.includes(query));
        }
      });
    }

    if (this.state.activeFilters.dateFrom !== null && this.state.activeFilters.dateTo !== null) {
      filteredBills = filteredBills.filter(b => moment(b.issueDate).isBetween(this.state.activeFilters.dateFrom, this.state.activeFilters.dateTo, 'day', '[]'));
    }

    return filteredBills;
  };


  selectBill = (billId: number) => {
    const bills = this.state.bills;
    const bill = bills.find(b => b.id === billId);
    if (bill) {
      bill.isSelected = !bill.isSelected;
      this.setState({ bills });
    }
  }

  selectPageBills = () => {
    const bills = this.state.bills;
    const filteredBills = this.getFilteredResults();
    const billsSlice = filteredBills.slice(this.state.page * this.state.pageSize, (this.state.page * this.state.pageSize) + this.state.pageSize);
    const selectMode = bills.find(i => i.isSelected && billsSlice.find(s => s.id === i.id) !== undefined) === undefined;
    bills.forEach(i => { if (billsSlice.find(s => s.id === i.id) !== undefined) i.isSelected = selectMode; });
    this.setState({ bills });
  }

  unselectDownloadedBills = (bills: any[]) => {
    bills.forEach(billId => {
      this.selectBill(billId);
    });
  }

  downloadSelectedBills = async () => {
    const billIds = this.state.bills.filter(b => b.isSelected).map(b => b.id);
    try {
      const request = new DownloadSelectedBillsRequest(this.props.activeCompanyId, billIds)
      await DocumentService.downloadSelectedBills(request);
      this.unselectDownloadedBills(billIds);
    }
    catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  }


  downloadBill = async (billId: number) => {
    try {
      await DocumentService.downloadBill(this.props.activeCompanyId, billId);
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  }

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <React.Fragment>
        <BillsPortfolioTableControlsViewComponent
          activeFilters={this.state.activeFilters}
          handleFiltersChange={this.handleFiltersChange}
        />

        <BillsPortfolioTableViewComponent
          bills={this.getFilteredResults()}
          order={this.state.order}
          orderBy={this.state.orderBy}
          page={this.state.page}
          pageSize={this.state.pageSize}
          downloadBill={this.downloadBill}
          downloadSelectedBills={this.downloadSelectedBills}
          handlePageChange={this.handlePageChange}
          handlePageSizeChange={this.handlePageSizeChange}
          handleSortOrderChange={this.handleSortOrderChange}
          selectBill={this.selectBill}
          selectPageBills={this.selectPageBills}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  activeCompanyId: auth.user.activeCompany.id,
  activeCompanyRole: auth.user.activeCompany.role
});

export default connect(mapStateToProps)(BillsPortfolioComponent);
