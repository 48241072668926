/* @flow */

export default class Office {
  id: number;
  name: string;
  phone: string;
  officeTypeId: number;
  addressLine1: string;
  addressLine2: string;
  zipCode: string;
  city: string;
  countryId: number;
  isActive: boolean;
  hasActivePersons: boolean;
}
