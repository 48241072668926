/* @flow */

import React, {useState} from 'react';

import LostPasswordFormComponent from './form';
import LostPasswordResultComponent from './results';

export default function LostPasswordComponent() {
  const LOSTPASSWORD_RESULT_START = -1;
  const LOSTPASSWORD_RESULT_RESET = 0;
  const LOSTPASSWORD_RESULT_CONFIRMATION = 1;

  const [state, setState] = useState({result: LOSTPASSWORD_RESULT_START, email: ''});

  const successHandler = (result: number, email: string) => setState({result, email})
  
  if (state.result === LOSTPASSWORD_RESULT_RESET) {
    return (
      <LostPasswordResultComponent
        action="resetemail"
        email={state.email}
      />
    );
  }

  if (state.result === LOSTPASSWORD_RESULT_CONFIRMATION) {
    return (
      <LostPasswordResultComponent
        action="emailConfirmation"
        email={state.email}
      />
    );
  }

  return (
    <LostPasswordFormComponent
      successHandler={successHandler}
    />
  );
}
