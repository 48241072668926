/* @flow */

import React from 'react';
import { useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';

import BlogComponent from 'components/blogs';
import SecurityComponent from 'components/security';

import DebtorKPIsComponent from './kpis';
import DebtorInvoicesToApproveTableWithTitleComponent from './invoicesToApprove';
import DebtorInvoicesToPayTableWithTitleComponent from './invoicesToPay';
import DebtorSupplierBankAccountChangeTableWithTitleComponent from './suppliersBankAccount';
import DebtorProfileSummaryComponent from './profileSummary';

export default function DebtorDashboardComponent() {
  const languageId = useSelector(state => state.locale.languageId);

  return (
    <React.Fragment>
      <DebtorProfileSummaryComponent />

      <Box mt={3}>
        <SecurityComponent pageName={"POS.Debtor.Dashboard"} elementName={"tblInvoiceApproval"} checkRoleExecute={true}>
          <DebtorInvoicesToApproveTableWithTitleComponent />
        </SecurityComponent>
      </Box>

      <Box mt={5}>
        <SecurityComponent pageName={"POS.Debtor.Dashboard"} elementName={"tblInvoiceToPay"} checkRoleExecute={true}>
          <DebtorInvoicesToPayTableWithTitleComponent />
        </SecurityComponent>
      </Box>

      <Box mt={5}>
        <SecurityComponent pageName={"POS.Debtor.Dashboard"} elementName={"tblChangeBankAccount"} checkRoleExecute={true}>
          <DebtorSupplierBankAccountChangeTableWithTitleComponent />
        </SecurityComponent>
      </Box>

      <Box mt={3}>
        <DebtorKPIsComponent />
      </Box>

      <Box mt={5}>
        <BlogComponent key={`blogs-${languageId}`} />
      </Box>
    </React.Fragment>
  );
}
