/* @flow */

import BankAccount from 'models/companies/bankAccount';

export default class InvestmentProfileValues {
  name: string;
  bankAccount: BankAccount;
  amount: number;
  minInvoiceAmount: number;
  maxInvoiceAmount: number;
  pct90: number;
  comment: string;
  marketPlace: string;
}
