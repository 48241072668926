/* @flow */

import React from 'react';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';

import Box from '@material-ui/core/Box';

import ButtonComponent from 'components/buttons/button';
import FormErrorsComponent from 'components/formErrors';
import IntlMessageComponent from 'components/formatters/intlMessage';
import MandatoryFormFieldsComponent from 'components/form/mandatory';

import CompanyUsersFormCompanyInfoISOPanelComponent from './panels/companyInfoISOPanel';
import CompanyUsersFormCompanyInfoDebtorPanelComponent from './panels/companyInfoDebtorPanel';
import CompanyUsersFormCompanyInfoBuyerPanelComponent from './panels/companyInfoBuyerPanel';
import CompanyUsersFormCompanyInfoSellerPanelComponent from './panels/companyInfoSellerPanel';
import CompanyUsersFormContactInfoPanelComponent from './panels/contactInfoPanel';
import CompanyUsersFormDocumentInfoPanelComponent from './panels/documentInfoPanel';
import CompanyUsersFormPersonalInfoPanelComponent from './panels/personalInfoPanel';
import CompanyUsersFormPersonalInfoUpdatePanelComponent from './panels/personalInfoUpdatePanel';

import CompanyUserForm from './model';

import {ROLE_ISO, ROLE_DEBTOR, ROLE_BUYER, ROLE_SELLER, ROLE_CONFIRMING} from 'constants/companyRoles';
import {PROTECTED_COMPANY_USERS} from 'constants/pageRoutes';
import CompanyUsersFormCompanyInfoConfirmingPanelComponent from './panels/companyInfoConfirmingPanel';

type CompanyUsersFormViewComponentProps = {
  errors: Map<string, ?string>;
  form: CompanyUserForm;
  formErrors: string[];
  isProcessing: boolean;
  userPersonRoleIsActive: boolean;
  hasProcuration: boolean;
  hasProcurationUsers: boolean;
  hasProcurationGeneralConditions: boolean;
  handleChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void;
  handleBooleanChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void;
  handleDateChange: (fieldName: string) => (date: moment) => void;
  handleFileChange: () => void;
  handleDocumentSelect: () => void;
  handleTypeChange: (fieldName: string) => (id: number) => void;
  handlePhoneChange: (value: string) => void;
  handleMobileChange: (value: string) => void;
  newOffice: () => void;
  submitForm: () => void;
}

export default function CompanyUsersFormViewComponent(props: CompanyUsersFormViewComponentProps) {
  const history = useHistory();
  const activeCompanyRole = useSelector(state => state.auth.user.activeCompany.role);


  const cancel = () => history.push(PROTECTED_COMPANY_USERS);

  return (
    <React.Fragment>
      <FormErrorsComponent formErrors={props.formErrors} />

      {props.form.companyUserId === 0 ? (
        <React.Fragment>
          <CompanyUsersFormPersonalInfoPanelComponent
            errors={props.errors}
            form={props.form}
            handleChange={props.handleChange}
            handleDateChange={props.handleDateChange}
            handleTypeChange={props.handleTypeChange}
          />
          
          <Box mt={5}>
            <CompanyUsersFormContactInfoPanelComponent
              errors={props.errors}
              form={props.form}
              handleChange={props.handleChange}
              handleTypeChange={props.handleTypeChange}
              handlePhoneChange={props.handlePhoneChange}
              handleMobileChange={props.handleMobileChange}
            />
          </Box>

          <Box mt={5}>
            <CompanyUsersFormDocumentInfoPanelComponent
              errors={props.errors}
              form={props.form}
              handleChange={props.handleChange}
              handleFileChange={props.handleFileChange}
              handleDocumentSelect={props.handleDocumentSelect}
              handleDateChange={props.handleDateChange}
              handleTypeChange={props.handleTypeChange}
            />
          </Box>
        </React.Fragment>
      ) : (
        <CompanyUsersFormPersonalInfoUpdatePanelComponent form={props.form} />
      )}

      {activeCompanyRole === ROLE_ISO &&
        <Box mt={5}>
          <CompanyUsersFormCompanyInfoISOPanelComponent
            errors={props.errors}
            form={props.form}
            isProcessing={props.isProcessing}
            handleChange={props.handleChange}
            handleBooleanChange={props.handleBooleanChange}
            handleTypeChange={props.handleTypeChange}
            newOffice={props.newOffice}
            userPersonRoleIsActive={props.userPersonRoleIsActive}
            hasProcuration={props.hasProcuration}
            hasProcurationUsers={props.hasProcurationUsers}
            hasProcurationGeneralConditions={props.hasProcurationGeneralConditions}
          />
        </Box>
      }
      
      {activeCompanyRole === ROLE_DEBTOR &&
        <Box mt={5}>
          <CompanyUsersFormCompanyInfoDebtorPanelComponent
            errors={props.errors}
            form={props.form}
            isProcessing={props.isProcessing}
            handleChange={props.handleChange}
            handleBooleanChange={props.handleBooleanChange}
            handleTypeChange={props.handleTypeChange}
            newOffice={props.newOffice}
            userPersonRoleIsActive={props.userPersonRoleIsActive}
            hasProcuration={props.hasProcuration}
            hasProcurationUsers={props.hasProcurationUsers}
            hasProcurationGeneralConditions={props.hasProcurationGeneralConditions}
          />
        </Box>
      }

      {activeCompanyRole === ROLE_BUYER &&
        <Box mt={5}>
          <CompanyUsersFormCompanyInfoBuyerPanelComponent
            errors={props.errors}
            form={props.form}
            isProcessing={props.isProcessing}
            handleChange={props.handleChange}
            handleBooleanChange={props.handleBooleanChange}
            handleTypeChange={props.handleTypeChange}
            newOffice={props.newOffice}
            userPersonRoleIsActive={props.userPersonRoleIsActive}
            hasProcuration={props.hasProcuration}
            hasProcurationUsers={props.hasProcurationUsers}
            hasProcurationGeneralConditions={props.hasProcurationGeneralConditions}
          />
        </Box>
      }

      {activeCompanyRole === ROLE_SELLER &&
        <Box mt={5}>
          <CompanyUsersFormCompanyInfoSellerPanelComponent
            errors={props.errors}
            form={props.form}
            isProcessing={props.isProcessing}
            handleChange={props.handleChange}
            handleBooleanChange={props.handleBooleanChange}
            handleTypeChange={props.handleTypeChange}
            newOffice={props.newOffice}
            userPersonRoleIsActive={props.userPersonRoleIsActive}
            hasProcuration={props.hasProcuration}
            hasProcurationUsers={props.hasProcurationUsers}
            hasProcurationGeneralConditions={props.hasProcurationGeneralConditions}
          />
        </Box>
      }

      {activeCompanyRole === ROLE_CONFIRMING &&
        <Box mt={5}>
          <CompanyUsersFormCompanyInfoConfirmingPanelComponent
            errors={props.errors}
            form={props.form}
            isProcessing={props.isProcessing}
            handleChange={props.handleChange}
            handleBooleanChange={props.handleBooleanChange}
            handleTypeChange={props.handleTypeChange}
            newOffice={props.newOffice}
            userPersonRoleIsActive={props.userPersonRoleIsActive}
            hasProcuration={props.hasProcuration}
            hasProcurationUsers={props.hasProcurationUsers}
            hasProcurationGeneralConditions={props.hasProcurationGeneralConditions}
          />
        </Box>
      }


      <Box mt={5}>
        <ButtonComponent
          color="default"
          variant="contained"
          onClick={cancel}
        ><IntlMessageComponent id="general.cancel" /></ButtonComponent>

        <Box component="span" ml={1}>
          <ButtonComponent
            color="primary"
            variant="contained"
            isProcessing={props.isProcessing}
            onClick={props.submitForm}
          ><IntlMessageComponent id="general.submit" /></ButtonComponent>
        </Box>

        <MandatoryFormFieldsComponent/>
      </Box>
    </React.Fragment>
  );
}