/* @flow */

import moment from 'moment';

export default class WireTransferPayment {
  amount: number;
  bic: string;
  iban: string;  
  reference: string;
  dueDate: moment;
}
