/* @flow */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function IconSignOut(props) {
  return (
    <SvgIcon viewBox="0 0 57.34 66.62" {...props}>
      <g>
        <path d="M419.6,331.16l-3.65-.67a28.18,28.18,0,0,1-22.53-22.18,28.46,28.46,0,0,1,12.39-29.87,2.21,2.21,0,0,1,3.21.46,2.38,2.38,0,0,1-.51,3.41c-1.39,1.14-2.86,2.19-4.13,3.44-10.06,9.89-8.77,26.6,2.13,35.38,8,6.43,20,6.87,28.16,1.18,7.28-5.06,11-12.07,10.66-20.92a23.16,23.16,0,0,0-10.49-18.78c-1.41-1-1.8-2-1.22-3.26a2.24,2.24,0,0,1,3.53-.87,28.47,28.47,0,0,1,12.76,21.19,2.81,2.81,0,0,0,.19.53v4.69c-.17,1-.32,2-.51,3a28.67,28.67,0,0,1-22.7,22.61l-3.77.67Z" transform="translate(-392.76 -264.54)"/>
        <path d="M419.13,278.42c0-3.75,0-7.5,0-11.25a2.32,2.32,0,0,1,2.34-2.63,2.4,2.4,0,0,1,2.35,2.64q0,11.31,0,22.62a2.47,2.47,0,0,1-2.37,2.65,2.42,2.42,0,0,1-2.32-2.66C419.12,286,419.13,282.21,419.13,278.42Z" transform="translate(-392.76 -264.54)"/>
      </g>
    </SvgIcon>
  );
}
