/* @flow */

import React from 'react';

import BuyerInvoicesPortfolioTableComponent from 'app/buyers/invoicesPortfolio';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function BuyerInvoicesPortfolioPage() {
  return (
    <WebsitePageComponent id="buyer.portfolio.title" data-walkme="buyer-portfolio-title" noPaper>
      <BuyerInvoicesPortfolioTableComponent />
    </WebsitePageComponent>
  );
}
