/* @flow */

import React from 'react';

import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from 'react-select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { withStyles } from '@material-ui/core/styles';

import IntlMessageComponent from 'components/formatters/intlMessage';

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      <IntlMessageComponent id="general.none" />
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      required={props.selectProps.required}
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      InputLabelProps={{ shrink: true }}
      label={props.selectProps.label}
      helperText={props.selectProps.helperText}
      error={props.selectProps.error}
      {...props.selectProps.textFieldProps}
      {...props.selectProps.displayProperties}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color={props.selectProps.error ? 'error' : 'textSecondary'}
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

type SelectComponentProps = {
  displayProperties: any;
  error: boolean;
  helperText: string;
  label: string;
  placeholder: string;
  required: boolean;
  value: {value: string, label: string};
  values: {value: string, label: string}[];
  handleSelect: (value: string) => void;
}

type SelectComponentState = {
}

class SelectComponent extends React.Component<SelectComponentProps, SelectComponentState> {
  handleSelect = (result: {value: string, label: string}) => {
    if (result == null) return this.props.handleSelect(null);
    return this.props.handleSelect(result.value);
  }

  render() {
    const selectStyles = {
      input: base => ({
        ...base,
        color: this.props.theme.palette.text.primary,
        '& input': {
          font: 'inherit',
        },
      }),
    };

    return (
      <Select
        classes={this.props.classes}
        styles={selectStyles}
        components={components}
        options={this.props.values}
        value={this.props.value}
        onChange={this.handleSelect}
        isClearable
        label={this.props.label}
        error={this.props.error}
        required={this.props.required}
        helperText={this.props.helperText}
        placeholder={this.props.placeholder}
        displayProperties={this.props.displayProperties}
      />
    );
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    bottom: 6,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
});

export default withStyles(styles, { withTheme: true })(SelectComponent);
