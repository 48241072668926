/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';


import ISOCommercialDocumentsComponent from './components/view'
import LoadingComponent from 'components/loading';


import IsoDocumentItem from 'models/documents/isoCommercialDocument';

import DocumentService from 'services/DocumentService';

import NotificationHelper from 'lib/notifications';

type CommercialDocumentsTableComponentProps = {
  activeCompanyId: number;
  activeCompanyRole: number;
}

type CommercialDocumentsTableComponentState = {
  documentsIsos: IsoDocumentItem;
  clientDocuments: IsoDocumentItem;
  isLoading: boolean;
}

class CommercialDocumentsTableComponent extends React.Component<CommercialDocumentsTableComponentProps, CommercialDocumentsTableComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      documents: null,
      isLoading: true,
    };
  }

  componentDidMount = async () => {
    try {
      const documents = await DocumentService.getISOCommercialDocuments(this.props.activeCompanyId, this.props.activeCompanyRole);
      console.log(documents);
      this.setState({ documentsIsos: documents.documentsIsos, clientDocuments: documents.clientDocuments, isLoading: false });
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  }

  downloadDocument = async (culture: string, name: string, path: string) => {
    try {
      await DocumentService.downloadISOCommercialDocument(this.props.activeCompanyId, this.props.activeCompanyRole, culture, name, path);
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  }

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <React.Fragment>

        <Box display="flex" style={{ justifyContent: 'space-between', flexWrap: 'wrap' }}>

          <ISOCommercialDocumentsComponent
            documents={this.state.clientDocuments}
            activeCompanyId={this.props.activeCompanyId}
            activeCompanyRole={this.state.activeCompanyRole}
            path={'clients'}
            downloadDocument={this.downloadDocument}
          />

          <ISOCommercialDocumentsComponent
            documents={this.state.documentsIsos}
            activeCompanyId={this.props.activeCompanyId}
            activeCompanyRole={this.props.activeCompanyRole}
            path={'isos'}
            downloadDocument={this.downloadDocument}
          />
        </Box>


      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id,
  activeCompanyRole: state.auth.user.activeCompany.role,
  userLanguage: state.locale.locale
});

export default connect(mapStateToProps)(CommercialDocumentsTableComponent);
