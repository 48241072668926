/* @flow */

// Auth
export const ON_SHOW_AUTH_LOADER = 'ON_SHOW_AUTH_LOADER';
export const ON_HIDE_AUTH_LOADER = 'ON_HIDE_AUTH_LOADER';
export const SIGNIN_AFTER_REGISTRATION_EDEBEX_USER_SAGA = 'SIGNIN_AFTER_REGISTRATION_EDEBEX_USER_SAGA';
export const SIGNIN_EDEBEX_USER_SAGA = 'SIGNIN_EDEBEX_USER_SAGA';
export const SIGNIN_RETURNING_EDEBEX_USER_SAGA = 'SIGNIN_RETURNING_EDEBEX_USER_SAGA';
export const SIGNIN_EDEBEX_USER_SUCCESS = 'SIGNIN_EDEBEX_USER_SUCCESS';
export const SIGNIN_EDEBEX_USER_ERROR = 'SIGNIN_EDEBEX_USER_ERROR';
export const SIGNIN_EDEBEX_USER_SERVER_ERROR = 'SIGNIN_EDEBEX_USER_SERVER_ERROR';
export const SIGNIN_EDEBEX_IMPERSONATE_ERROR = 'SIGNIN_EDEBEX_IMPERSONATE_ERROR';
export const SIGNOUT_EDEBEX_USER_SAGA = 'SIGNOUT_EDEBEX_USER_SAGA';
export const SIGNOUT_EDEBEX_USER_SUCCESS = 'SIGNOUT_EDEBEX_USER_SUCCESS';
export const IMPERSONATE_EDEBEX_USER_SAGA = 'IMPERSONATE_EDEBEX_USER_SAGA';
export const SWITCH_USER_COMPANY_SAGA = 'SWITCH_USER_COMPANY_SAGA';
export const RELOAD_USER_COMPANY_SAGA = 'RELOAD_USER_COMPANY_SAGA';
export const SWITCH_USER_COMPANY_SUCCESS = 'SWITCH_USER_COMPANY_SUCCESS';
export const SWITCH_USER_COMPANY_ROLE_SUCCESS = 'SWITCH_USER_COMPANY_ROLE_SUCCESS';
export const SWITCH_USER_COMPANY_INVESTMENTPROFILE_SUCCESS = 'SWITCH_USER_COMPANY_INVESTMENTPROFILE_SUCCESS';
export const ADD_USER_COMPANY_SAGA = 'ADD_USER_COMPANY_SAGA';
export const ADD_USER_COMPANY_SUCCESS = 'ADD_USER_COMPANY_SUCCESS';
export const ADD_INVESTMENT_PROFILE_SUCCESS = 'ADD_INVESTMENT_PROFILE_SUCCESS';
export const SET_IDENTITY_DOCUMENTS_SAGA = 'SET_IDENTITY_DOCUMENTS_SAGA';
export const SET_IDENTITY_DOCUMENTS_SUCCESS = 'SET_IDENTITY_DOCUMENTS_SUCCESS';
export const DISABLE_USER_COMPANY_INVESTMENTPROFILE_SUCCESS = 'DISABLE_USER_COMPANY_INVESTMENTPROFILE_SUCCESS';

// Component Data
export const SET_COMPONENT_DATA = 'SET_COMPONENT_DATA';


// Locales
export const INIT_WEBSITE_SAGA = 'INIT_WEBSITE_SAGA';
export const INIT_WEBSITE_ERROR = 'INIT_WEBSITE_ERROR';
export const INIT_WEBSITE_SUCCESS = 'INIT_WEBSITE_SUCCESS';
export const SWITCH_LANGUAGE_SAGA = 'SWITCH_LANGUAGE_SAGA';