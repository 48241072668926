/* @flow */

import React from 'react';

import OfferTab1ISOViewComponent from './view';

import LeadService from 'services/LeadService';
import {handleApiFormResponse} from 'lib/forms';

type OfferTab1ISOComponentProps = {
  successHandler: (leadId: number) => void;
  activeCompanyId: number;
}

type OfferTab1ISOComponentState = {
  company: Company;
  error: boolean;
  formErrors: string[];
  isProcessing: boolean;
}

class OfferTab1ISOComponent extends React.Component<OfferTab1ISOComponentProps, OfferTab1ISOComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      company: null,
      error: false,
      isProcessing: false
    };
  }

  handleCompanyChange = (company: Company) => {
    this.setState({ company, error: false });
  };

  submitForm = async () => {
    if (this.state.company === null) return;

    this.setState({ formErrors: [], isProcessing: true });

    try {
      const leadId = await LeadService.getLeadId(this.state.company.id, this.props.activeCompanyId);
      this.props.successHandler(leadId);
    } catch (e) {
      console.error(e);
      handleApiFormResponse.call(this, e);
    }
  }

  render() {
    return (
      <OfferTab1ISOViewComponent
        company={this.state.company}
        error={this.state.error}
        formErrors={this.state.formErrors}
        isProcessing={this.state.isProcessing}
        handleCompanyChange={this.handleCompanyChange}
        submitForm={this.submitForm}
      />
    );
  }
}

export default OfferTab1ISOComponent;
