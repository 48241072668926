/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import Box from '@material-ui/core/Box';

import ComponentWrapperComponent from 'components/websiteStructure/componentWrapper';
import Error404Component from 'components/errors/error404';
import LoadingComponent from 'components/loading';
import InvoiceNameComponent from 'components/invoice/name';
import PageTitleComponent from 'components/pageTitle';

import ConfirmingInvoiceDetailsDocumentsComponent from './documents';
import ConfirmingInvoiceDetailsStatusViewComponent from './status';
import ConfirmingInvoiceDetailsSummaryViewComponent from './summary';

import Invoice from 'models/confirming/invoice';

import ConfirmingService from 'services/ConfirmingService';

type ConfirmingInvoiceDetailsComponentProps = {
  activeCompanyId: number;
  invoiceId: number;
}

type ConfirmingInvoiceDetailsComponentState = {
  errorCode: number;
  invoice: Invoice;
  isLoading: boolean;
  statusKeys: string[];
  titleValues: {id: Element<any>};
}

class ConfirmingInvoiceDetailsComponent extends React.Component<ConfirmingInvoiceDetailsComponentProps, ConfirmingInvoiceDetailsComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      errorCode: 0,
      invoice: null,
      isLoading: true,
      statusKeys: [],
      titleValues: {id: <b>{this.props.invoiceId}</b>}
    };
  }

  componentDidMount = async () => {
    await this.loadInvoice();
  }

  reloadInvoice = async () => {
    this.setState({errorCode: 0, invoice: null, isLoading: true, statusKeys: [], titleValues: {id: <b>{this.props.invoiceId}</b>}});
    await this.loadInvoice();
  }

  loadInvoice = async () => {
    try {
      const response = await ConfirmingService.getInvoiceDetails(this.props.activeCompanyId, this.props.invoiceId);
      this.setState({
        invoice: response.invoice,
        isLoading: false,
        statusKeys: response.statusKeys,
        titleValues: {
          id: <b><InvoiceNameComponent invoiceId={this.props.invoiceId} /></b>,
          debtorReference: <b>{response.invoice.debtorReference}</b>
        }
      });
    } catch (e) {
      if (e.code === 400) {
        this.setState({isLoading: false, errorCode: 404});
      } else {
        this.setState({isLoading: false, errorCode: 500});
      }
    }
  }

  render() {
    if (this.state.isLoading) {
      return (
        <React.Fragment>
          <PageTitleComponent id="confirming.invoiceDetails.title.loading" values={this.state.titleValues} />
          <LoadingComponent />
        </React.Fragment>
      );
    }

    // Maybe find a best way to do this
    if (this.state.errorCode === 404) {
      return (<Error404Component />);
    } else if (this.state.errorCode === 500) {
      return null;
    }

    return (
      <React.Fragment>
        <PageTitleComponent id="confirming.invoiceDetails.title" values={this.state.titleValues} backButton />

        <ComponentWrapperComponent small>
          <ConfirmingInvoiceDetailsStatusViewComponent invoice={this.state.invoice} statusKeys={this.state.statusKeys} />

          <Box mt={3}>
            <ConfirmingInvoiceDetailsSummaryViewComponent invoice={this.state.invoice} />
          </Box>
        </ComponentWrapperComponent>

        <Box mt={3}>      
          <ComponentWrapperComponent small>
            <ConfirmingInvoiceDetailsDocumentsComponent invoice={this.state.invoice} />

          </ComponentWrapperComponent>
        </Box>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps)(ConfirmingInvoiceDetailsComponent);
