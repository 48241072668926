

/* @flow */

import moment from 'moment';
import CompanyUserInfo from 'models/isos/registerSellers/companyUserInfo';
import SetCompanyUserInfoRequest from 'models/requests/isos/registerSellers/setCompanyUserInfoRequest';

export default class CompanyUserInfoForm {
  titleId: number;

  lastName: string;
  firstName: string;

  email: string;
  mobile: string;
  phone: string;

  addressLine1: string;
  addressLine2: string;
  zipCode: string;
  city: string;
  countryId: number;

  nationalityId: number;
  birthCountryId: number;
  birthDate: moment;
  birthPlace: moment;

  registrationTypeId: number;
  registrationNumber: string;
  registrationExpireDate: moment;

  languageId: number;

  functionName: string;

  constructor(info: CompanyUserInfo) {
    this.titleId = info.titleId;
    this.lastName = info.lastName ? info.lastName : '';
    this.firstName = info.firstName ? info.firstName : '';
    this.email = info.email;
    this.mobile = info.mobile ? info.mobile : '';
    this.phone = info.phone ? info.phone : '';
    this.addressLine1 = info.addressLine1 ? info.addressLine1 : '';
    this.addressLine2 = info.addressLine2 ? info.addressLine2 : '';
    this.zipCode = info.zipCode ? info.zipCode : '';
    this.city = info.city ? info.city : '';
    this.countryId = info.countryId;
    this.nationalityId = info.nationalityId;
    this.birthCountryId = info.birthCountryId;
    this.birthDate = info.birthDate ? info.birthDate : moment().subtract(40, 'y').startOf('d');
    this.birthPlace = info.birthPlace;
    this.registrationTypeId = info.registrationTypeId;
    this.registrationNumber = info.registrationNumber ? info.registrationNumber : '';
    this.registrationExpireDate = info.registrationExpireDate ? info.registrationExpireDate : moment().startOf('d');
    this.languageId = info.languageId;
    this.functionName = info.functionName ? info.functionName : '';
  }

  toRequest(isoCompanyId: number, companyId: number, companyPersonId: number){
    const request = new SetCompanyUserInfoRequest();
    request.isoCompanyId = isoCompanyId;
    request.companyId = companyId;
    request.companyPersonId = companyPersonId;
    request.titleId = this.titleId;
    request.lastName = this.lastName;
    request.firstName = this.firstName;
    request.email = this.email;
    request.phone = this.phone;
    request.mobile = this.mobile;
    request.addressLine1 = this.addressLine1;
    request.addressLine2 = this.addressLine2;
    request.zipCode = this.zipCode;
    request.city = this.city;
    request.countryId = this.countryId;
    request.nationalityId = this.nationalityId;
    request.birthCountryId = this.birthCountryId;
    request.birthDate = moment(this.birthDate).format('YYYY/MM/DD');
    request.birthPlace = this.birthPlace;
    request.functionName = this.functionName;
    request.languageId = this.languageId;
    request.registrationTypeId = this.registrationTypeId;
    request.registrationNumber = this.registrationNumber;
    request.registrationExpireDate = moment(this.registrationExpireDate).format('YYYY/MM/DD');
    return request;
  }
}
