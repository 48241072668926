/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import customSort from 'lib/sortHelpers';

import LoadingComponent from 'components/loading';

import CompanyOfficePersonTableViewComponent from './view';

import OfficePerson from 'models/companies/officePerson';

import IsoService from 'services/IsoService';
import DebtorService from 'services/DebtorService';

import { ROLE_ISO, ROLE_DEBTOR } from 'constants/companyRoles';

type CompanyOfficePersonTableComponentProps = {
  activeCompanyId: number;
  activeCompanyRole: number;
  officeId: number;
  newOfficePerson: () => void;
  editOfficePerson: (officePerson: OfficePerson) => void;
}

type CompanyOfficePersonTableComponentState = {
  isLoading: boolean;
  officePersons: OfficePerson[];
  order: string;
  orderBy: string;
  page: number;
  pageSize: number;
}

class CompanyOfficePersonTableComponent extends React.Component<CompanyOfficePersonTableComponentProps, CompanyOfficePersonTableComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      officePersons: [],
      order: 'asc',
      orderBy: 'name',
      page: 0,
      pageSize: 10
    };
  }

  componentDidMount = async () => {
    try {
      let officePersons = [];

      if (this.props.activeCompanyRole === ROLE_DEBTOR) {
        officePersons = await DebtorService.getOfficePersons(this.props.activeCompanyId, this.props.officeId);
      } else if (this.props.activeCompanyRole === ROLE_ISO) {
        officePersons = await IsoService.getOfficePersons(this.props.activeCompanyId, this.props.officeId);
      }

      this.setState({isLoading: false, officePersons});
    } catch (error) {
      console.error(error);
    }
  }

  handlePageChange = (page: number) => this.setState({page});
  handlePageSizeChange = (pageSize: number) => this.setState({pageSize});

  handleSortOrderChange = (orderBy: string) => {
    let order = this.state.orderBy === orderBy && this.state.order === 'desc' ? 'asc' : 'desc';

    const officePersons = order === 'desc'
      ? this.state.officePersons.sort((a, b) => customSort(b[orderBy], a[orderBy]))
      : this.state.officePersons.sort((a, b) => customSort(a[orderBy], b[orderBy]));

    this.setState({officePersons, order, orderBy});
  };

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <CompanyOfficePersonTableViewComponent
        officePersons={this.state.officePersons}
        order={this.state.order}
        orderBy={this.state.orderBy}
        page={this.state.page}
        pageSize={this.state.pageSize}
        newOfficePerson={this.props.newOfficePerson}
        editOfficePerson={this.props.editOfficePerson}
        handlePageChange={this.handlePageChange}
        handlePageSizeChange={this.handlePageSizeChange}
        handleSortOrderChange={this.handleSortOrderChange}
      />
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id,
  activeCompanyRole: state.auth.user.activeCompany.role
});

export default connect(mapStateToProps)(CompanyOfficePersonTableComponent);
