/* @flow */

import React from 'react';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';

import SupplierCompany from 'models/confirming/supplierCompany';
import Document from 'models/documents/v2/document';
import DocumentType from 'models/documents/documentType';

import ConfirmingService from 'services/ConfirmingService';

import LoadingComponent from 'components/loading';

import { isCountryFranceOrDomTom } from 'lib/countryHelpers';
import NotificationHelper from 'lib/notifications';
import ConfirmingAddSupplierTab3ViewComponent from './view';
import DocumentService from 'services/DocumentService';
import { DOC_CODE_RIB, DOC_TYPE_RIB, DOC_CODE_BKA, DOC_TYPE_BKA } from 'constants/constants';

type ConfirmingAddSupplierTab3ComponentProps = {
  activeCompanyId: number;
  supplierId: number;
  supplierCompany: SupplierCompany;
  successHandler: () => void;
}

type ConfirmingAddSupplierTab3ComponentState = {
  isLoading: boolean;
  isProcessing: boolean;
  document: Document;
  supplierCompany: SupplierCompany;
  hasBankAccountDocument: boolean;
}

class ConfirmingAddSupplierTab3Component extends React.Component<ConfirmingAddSupplierTab3ComponentProps, ConfirmingAddSupplierTab3ComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };
  }

  componentDidMount = async () => {
    this.refreshData();
  }

  refreshData = async () => {
    try {
      let supplierCompany = await ConfirmingService.getConfirmingSupplierDetails(this.props.activeCompanyId, this.props.supplierId);

      let documentType = new DocumentType();
      if (isCountryFranceOrDomTom(supplierCompany.bankAccountCountryId)) {
        documentType = new DocumentType(DOC_TYPE_RIB, DOC_CODE_RIB);
      } else {
        documentType = new DocumentType(DOC_TYPE_BKA, DOC_CODE_BKA);
      }
      const document = new Document();
      document.typeId = documentType.typeId;
      document.code = documentType.code;
      document.isRequired = !supplierCompany.hasBankAccountDocument;

      this.setState({
        supplierCompany,
        isLoading: false,
        isProcessing: false,
        document,
        hasBankAccountDocument: supplierCompany.hasBankAccountDocument
      });
    } catch (e) {
      console.error(e);
    }
  }

  handleDocumentSelect = (file: File) => {
    const document = this.state.document;
    document.file = file;
    document.filename = file.name;
    document.isInvalid = false;
    document.isMissing = false;
    this.setState({ document });
  };
  handleDocumentSelectFailure = (file: File) => {
    const document = this.state.document;
    document.file = null;
    document.filename = file.name;
    document.isInvalid = true;
    document.isMissing = false;
    this.setState({ document });
  }
  handleDocumentRemove = () => {
    const document = this.state.document;
    document.file = null;
    document.filename = '';
    document.isInvalid = false;
    document.isMissing = false;
    this.setState({ document });
  };


  submitForm = async () => {
    try {
      const document = this.state.document;
      if (document.file === null || document.isMissing || document.isInvalid) {
        document.isMissing = true;
        this.setState({ document, isProcessing: false });
        return;
      } else {
        this.submitDocument(document);
      }
      this.props.successHandler();
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
      this.setState({ isProcessing: false });
    }
  }

  submitDocument = async (document: Document) => {
    await DocumentService.sendSupplierDocument(this.props.activeCompanyId, this.props.supplierId, document.typeId, document.file);
  }

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <React.Fragment>
        <Box mt={4}>
          <ConfirmingAddSupplierTab3ViewComponent
            supplierCompanyDetails={this.state.supplierCompanyDetails}
            isProcessing={this.state.isProcessing}
            handleDocumentSelect={this.handleDocumentSelect}
            handleDocumentSelectFailure={this.handleDocumentSelectFailure}
            handleDocumentRemove={this.handleDocumentRemove}
            document={this.state.document}
            submitForm={this.submitForm}
          />
        </Box>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps)(ConfirmingAddSupplierTab3Component);
