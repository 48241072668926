/* @flow */

export default class GetInvoicesToPayRequest {
  companyId: number;
  investmentProfileId: number;
  invoiceIds: number[];

  constructor(companyId: number, investmentProfileId: number, invoiceIds: number[]) {
    this.companyId = companyId;
    this.investmentProfileId = investmentProfileId;
    this.invoiceIds = invoiceIds;
  }

  toRouteParameters() {
    return `companyId=${this.companyId}&investmentProfileId=${this.investmentProfileId}&invoiceids=${this.invoiceIds.join('&invoiceids=')}`;
  }
}