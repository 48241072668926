/* @flow */

import React from 'react';
import { useSelector } from 'react-redux';

import ListIcon from '@material-ui/icons/List';

import IntlMessageComponent from 'components/formatters/intlMessage';
import LinkButtonComponent from 'components/buttons/linkButton';
import SecurityComponent from 'components/security';

import { ROLE_DEBTOR } from 'constants/companyRoles';
import { PROTECTED_DEBTOR_APPROVE_INVOICES } from 'constants/pageRoutes'

export default function WebsiteHeaderDebtorInvoicesButtonComponent() {
  const user = useSelector(state => state.auth.user);
  if (!user) return null;
  if (user.activeCompany.role !== ROLE_DEBTOR) return null;
  if (user.activeCompany.isSubscribing) return null;
  if (!user.activeCompany.roleDebtor.canExecute) return null;

  return (
    <SecurityComponent pageName={"POS.Debtor.Header"} elementName={"btnInvoiceApproval"}>
      <LinkButtonComponent
        label={<b><IntlMessageComponent id="header.debtor.btn" /></b>}
        icon={<ListIcon />}
        url={PROTECTED_DEBTOR_APPROVE_INVOICES}
      />
    </SecurityComponent>
  );
}
