/* @flow */

import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import AmountFormatterComponent from 'components/formatters/amount';
import DateFormatterComponent from 'components/formatters/date';
import ConfirmingInvoiceStatusViewComponent from 'components/invoiceStatus/confirming';
import IntlMessageComponent from 'components/formatters/intlMessage';
import InvoiceNameComponent from 'components/invoice/name';
import NumberFormatterComponent from 'components/formatters/number';
import StyledTableCellTextComponent from 'components/table/cellText';
import StyledTableCellNumbersComponent from 'components/table/cellNumbers';
import StyledTableHeadComponent from 'components/table/head';
import StyledTableRowComponent from 'components/table/row';
import SecurityComponent from 'components/security/index';
import IconEdit from 'components/icons/edit';
import IconTrash from 'components/icons/trash';
import StyledTableRowConfirmingInvoiceComponent from 'components/table/rowConfirmingInvoice';

import Invoice from 'models/debtors/invoice';

import { PROTECTED_CONFIRMING_INVOICE_DETAILS, PROTECTED_CONFIRMING_SUBMIT } from 'constants/pageRoutes';

const columnData = [
  { id: 'statusKey', align: 'left', translationKey: 'debtor.portfolio.table.statusKey', walkme: 'debtor-portfolio-table-statusKey' },
  { id: 'supplierCompany.supplierName', align: 'left', translationKey: 'debtor.portfolio.table.sellerCompany.name', walkme: 'debtor-portfolio-table-sellerCompanyName' },
  { id: 'reference', align: 'left', translationKey: 'debtor.portfolio.table.reference', walkme: 'debtor-portfolio-table-reference' },
  { id: 'debtorReference', align: 'left', translationKey: 'debtor.portfolio.table.debtorReference', walkme: 'debtor-portfolio-table-debtorReference' },
  { id: 'debtAmount', translationKey: 'debtor.portfolio.table.debtAmount', walkme: 'debtor-portfolio-table-debtAmount' },
  { id: 'issueDate', align: 'left', translationKey: 'debtor.portfolio.table.issueDate', walkme: 'debtor-portfolio-table-issueDate' },
  { id: 'dueDate', align: 'left', translationKey: 'debtor.portfolio.table.dueDate', walkme: 'debtor-portfolio-table-dueDate' },
  { id: 'confirmingDueDate', align: 'left', translationKey: 'debtor.portfolio.table.confirmingDueDate' },
  { id: 'daysToDueDate', translationKey: 'debtor.portfolio.table.daysToDueDate', walkme: 'debtor-portfolio-table-daysToDueDate' },
  { id: 'listingFee', translationKey: 'debtor.portfolio.table.listingFee', },
  { id: 'serviceFee', translationKey: 'debtor.portfolio.table.serviceFee', },
  { id: 'insuranceFee', translationKey: 'debtor.portfolio.table.insuranceFee', },
  { id: 'buyerDiscount', translationKey: 'confirming.portfolio.table.interest', },
  { id: 'id', align: 'left', translationKey: 'debtor.portfolio.table.id', walkme: 'debtor-portfolio-table-invoiceId' }
];


type ConfirmingInvoicesPortfolioTableViewComponentProps = {
  invoices: Invoice[];
  order: string;
  orderBy: string;
  page: number;
  pageSize: number;
  handlePageChange: (page: number) => void;
  handlePageSizeChange: (pageSize: number) => void;
  handleSortOrderChange: (property: string) => void;
  cancelInvoice: (id: number) => void;
};


export default function ConfirmingInvoicesPortfolioTableViewComponent(props: ConfirmingInvoicesPortfolioTableViewComponentProps) {
  const history = useHistory();

  const toSubmitInvoice = (id: number) => history.push(`${PROTECTED_CONFIRMING_SUBMIT}/${id}`);
  const handlePageChange = (_, page) => props.handlePageChange(page);
  const handlePageSizeChange = event => props.handlePageSizeChange(event.target.value);
  const navigateToInvoiceDetails = id => history.push({ pathname: `${PROTECTED_CONFIRMING_INVOICE_DETAILS}/${id}` });
  const canExecute = useSelector(state => state.auth.user.activeCompany.roleConfirming.canExecute);

  const isActionRequired = (statusKey: string) => {
    if (statusKey.includes('tosubmit'))
      return true;
    return false;
  };

  return (
    <React.Fragment>
      <Box py={5}>
        <Box component="div" overflow="auto">
          <Table>
            <StyledTableHeadComponent data-walkme="debtorPortfolio-table-headline">
              <StyledTableRowComponent>
                {columnData.map(column => (
                  <StyledTableCellTextComponent align={column?.align ?? 'right'} key={column.id} padding="none">
                    <TableSortLabel
                      active={props.orderBy === column.id}
                      direction={props.order}
                      onClick={() => props.handleSortOrderChange(column.id)}
                      data-walkme={column.walkme || ''}
                    ><IntlMessageComponent id={column.translationKey} /></TableSortLabel>
                  </StyledTableCellTextComponent>
                ))}
                <StyledTableCellTextComponent padding="none" />
              </StyledTableRowComponent>
            </StyledTableHeadComponent>

            <TableBody bgcolor="white">
              {props.invoices.length === 0 &&
                <StyledTableRowComponent>
                  <StyledTableCellTextComponent colSpan={columnData.length}>
                    <Box textAlign="center">
                      <IntlMessageComponent id="general.noData" />
                    </Box>
                  </StyledTableCellTextComponent>
                </StyledTableRowComponent>
              }

              {props.invoices.slice(props.page * props.pageSize, (props.page * props.pageSize) + props.pageSize).map(i => (
                <StyledTableRowConfirmingInvoiceComponent
                  key={`invList-${i.id}`}
                  hover
                  onClick={isActionRequired(i.statusKey) ? () => toSubmitInvoice(i.id) : () => navigateToInvoiceDetails(i.id)}
                  tabIndex={-1}
                  isWaiting={isActionRequired(i.statusKey)}
                >
                  <StyledTableCellTextComponent padding="none">
                    <ConfirmingInvoiceStatusViewComponent statusKey={i.statusKey} />
                  </StyledTableCellTextComponent>

                  <StyledTableCellTextComponent padding="none">
                    {i.supplierCompany.supplierName}
                  </StyledTableCellTextComponent>

                  <StyledTableCellTextComponent padding="none">
                    {i.reference}
                  </StyledTableCellTextComponent>

                  <StyledTableCellTextComponent padding="none">
                    {i.debtorReference}
                  </StyledTableCellTextComponent>

                  <StyledTableCellNumbersComponent padding="none">
                    <AmountFormatterComponent amount={i.debtAmount} />
                  </StyledTableCellNumbersComponent>

                  <StyledTableCellTextComponent padding="none">
                    <DateFormatterComponent date={i.issueDate} />
                  </StyledTableCellTextComponent>

                  <StyledTableCellTextComponent padding="none">
                    <DateFormatterComponent date={i.dueDate} />
                  </StyledTableCellTextComponent>

                  <StyledTableCellTextComponent padding="none">
                    <DateFormatterComponent date={i.confirmingDueDate} />
                  </StyledTableCellTextComponent>

                  <StyledTableCellNumbersComponent padding="none">
                    {i.daysToDueDate > -1 && !i.paidDate ? <Box color="red.main"><NumberFormatterComponent value={i.daysToDueDate} /></Box> : <NumberFormatterComponent value={i.daysToDueDate} />}
                  </StyledTableCellNumbersComponent>

                  <StyledTableCellNumbersComponent padding="none">
                    <NumberFormatterComponent value={i.listingFee} />
                  </StyledTableCellNumbersComponent>

                  <StyledTableCellNumbersComponent padding="none">
                    <NumberFormatterComponent value={i.serviceFee} />
                  </StyledTableCellNumbersComponent>

                  <StyledTableCellNumbersComponent padding="none">
                    <NumberFormatterComponent value={i.insuranceFee} />
                  </StyledTableCellNumbersComponent>

                  <StyledTableCellNumbersComponent padding="none">
                    <NumberFormatterComponent value={i.buyerDiscount} />
                  </StyledTableCellNumbersComponent>

                  <StyledTableCellTextComponent padding="none">
                    <InvoiceNameComponent invoiceId={i.id} invoiceName={i.invoiceName} />
                  </StyledTableCellTextComponent>

                  <StyledTableCellTextComponent padding="none">
                    {canExecute && isActionRequired(i.statusKey) &&
                      <Box display="flex">
                        <SecurityComponent pageName="POS.Confirming.InvoicePortfolio" elementName="btnEditInvoice">
                          <Tooltip title={<IntlMessageComponent id="component.confirming.invoiceList.actions.edit" />}>
                            <IconButton onClick={() => toSubmitInvoice(i.id)}>
                              <IconEdit />
                            </IconButton>
                          </Tooltip>
                        </SecurityComponent>

                        <SecurityComponent pageName="POS.Confirming.InvoicePortfolio" elementName="btnCancelInvoice">
                          <Box component="span" ml={1}>
                            <Tooltip title={<IntlMessageComponent id="component.seller.invoiceList.actions.cancel" />}>
                              <IconButton onClick={() => props.cancelInvoice(i.id)}>
                                <IconTrash />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </SecurityComponent>
                      </Box>
                    }
                  </StyledTableCellTextComponent>
                </StyledTableRowConfirmingInvoiceComponent>
              ))}
            </TableBody>
          </Table>
        </Box>

        <Box display="flex" height={60}>
          <Box flexGrow={1}></Box>
          <Box justifyContent="flex-end">
            <TablePagination
              component="div"
              count={props.invoices.length}
              rowsPerPage={props.pageSize}
              page={props.page}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePageSizeChange}
              labelRowsPerPage={<IntlMessageComponent id="general.invoicePerPage" />}
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
            />
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}
