/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import Box from '@material-ui/core/Box';

import LoadingComponent from 'components/loading';

import BuyerInvestmentSummaryComponent from './investmentSummary';
import BuyerProfileSummaryCarouselComponent from './caroussel';

import BuyerInvestmentSummaryOverview from 'models/buyers/dashboards/investmentSummaryOverview';
import BuyerInvestmentSummaryPerformance from 'models/buyers/dashboards/investmentSummaryPerformance';
import ProfileSummaryCard from 'models/dashboards/profileSummaryCard';

import BuyerService from 'services/BuyerService';

type BuyerProfileSummaryComponentProps = {
  activeCompanyId: number;
  activeCompanyInvestmentProfileId: number;
}

type BuyerProfileSummaryComponentState = {
  cards: ProfileSummaryCard[];
  isLoading: boolean;
  investmentOverview: BuyerInvestmentSummaryOverview;
  investmentPerformance: BuyerInvestmentSummaryPerformance;
}

class BuyerProfileSummaryComponent extends React.Component<BuyerProfileSummaryComponentProps, BuyerProfileSummaryComponentState> {

  constructor(props) {
    super(props);
    this.state = {
      cards: null,
      isLoading: true,
      investmentOverview: null,
      investmentPerformance: null,
    };
  }

  componentDidMount = async () => {
    try {
      const response = await BuyerService.getProfileSummary(this.props.activeCompanyId, this.props.activeCompanyInvestmentProfileId);
      this.setState({cards: response.cards, isLoading: false, investmentOverview: response.investmentOverview, investmentPerformance: response.investmentPerformance});
    } catch (e) {
      console.error(e);
    }
  }

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <React.Fragment>
        <BuyerProfileSummaryCarouselComponent cards={this.state.cards}/>

        <Box mt={3}>
          <BuyerInvestmentSummaryComponent investmentOverview={this.state.investmentOverview} investmentPerformance={this.state.investmentPerformance}/>
        </Box>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id,
  activeCompanyInvestmentProfileId: state.auth.user.activeCompany.investmentProfile.id
});

export default connect(mapStateToProps)(BuyerProfileSummaryComponent);
