/* @flow */

import DebtorInfo from 'models/debtors/debtorInfo';

import ApproveInvoiceTokenRequest from 'models/requests/debtors/approveInvoiceTokenRequest';
import DisputeInvoiceTokenRequest from 'models/requests/debtors/disputeInvoiceTokenRequest';

export default class ApproveOrDisputeInvoiceForm {
  answerInvoiceGenuine: boolean;
  answerInvoiceGenuineText: string;
  answerAmountAndDueDateCorrect: boolean;
  answerAmountAndDueDateCorrectText : string;
  answerServicesOrGoodsDelivered: boolean;
  answerServicesOrGoodsDeliveredText: string;
  answerWillDebtorDisputeInvoice: boolean;
  answerWillDebtorDisputeInvoiceText: string;
  answerGuaranteeDeductionOrCompensation: boolean;
  answerGuaranteeDeductionOrCompensationText: string;

  constructor() {
    this.answerInvoiceGenuine = null;
    this.answerInvoiceGenuineText = '';
    this.answerAmountAndDueDateCorrect = null;
    this.answerAmountAndDueDateCorrectText = '';
    this.answerServicesOrGoodsDelivered = null;
    this.answerServicesOrGoodsDeliveredText = '';
    this.answerWillDebtorDisputeInvoice = null;
    this.answerWillDebtorDisputeInvoiceText = '';
    this.answerGuaranteeDeductionOrCompensation = null;
    this.answerGuaranteeDeductionOrCompensationText = '';
  }

  isFormFilled() {
    return this.answerInvoiceGenuine !== null
      && this.answerAmountAndDueDateCorrect !== null
      && this.answerServicesOrGoodsDelivered !== null
      && this.answerWillDebtorDisputeInvoice !== null
      && this.answerGuaranteeDeductionOrCompensation !== null;
  }

  isFormApprovable() {
    if (!this.isFormFilled()) return false;
    return this.answerInvoiceGenuine
      && this.answerAmountAndDueDateCorrect
      && this.answerServicesOrGoodsDelivered
      && !this.answerWillDebtorDisputeInvoice;
  }

  isFormDisputable() {
    if (!this.isFormFilled()) return false;
    return !this.answerInvoiceGenuine
      || !this.answerAmountAndDueDateCorrect
      || !this.answerServicesOrGoodsDelivered
      || this.answerWillDebtorDisputeInvoice;
  }

  toApproveRequest(token: string, invoiceId: number, debtorInfo: DebtorInfo) {
    const request = new ApproveInvoiceTokenRequest();
    request.token = token;
    request.invoiceId = invoiceId;

    request.lastName = debtorInfo.lastName;
    request.firstName = debtorInfo.firstName;
    request.email = debtorInfo.email;
    request.functionName = debtorInfo.functionName;

    request.answerGuaranteeDeductionOrCompensation = this.answerGuaranteeDeductionOrCompensation;
    request.answerGuaranteeDeductionOrCompensationText = this.answerGuaranteeDeductionOrCompensationText || '';
    return request;
  }

  toDisputeRequest(token: string, invoiceId: number, debtorInfo: DebtorInfo) {
    const request = new DisputeInvoiceTokenRequest();
    request.token = token;
    request.invoiceId = invoiceId;
    
    request.lastName = debtorInfo.lastName;
    request.firstName = debtorInfo.firstName;
    request.email = debtorInfo.email;
    request.functionName = debtorInfo.functionName;
    
    request.answerInvoiceGenuine = this.answerInvoiceGenuine;
    request.answerInvoiceGenuineText = this.answerInvoiceGenuineText || '';
    
    request.answerAmountAndDueDateCorrect = this.answerAmountAndDueDateCorrect;
    request.answerAmountAndDueDateCorrectText = this.answerAmountAndDueDateCorrectText || '';
    
    request.answerServicesOrGoodsDelivered = this.answerServicesOrGoodsDelivered;
    request.answerServicesOrGoodsDeliveredText = this.answerServicesOrGoodsDeliveredText || '';

    request.answerWillDebtorDisputeInvoice = this.answerWillDebtorDisputeInvoice;
    request.answerWillDebtorDisputeInvoiceText = this.answerWillDebtorDisputeInvoiceText || '';

    request.answerGuaranteeDeductionOrCompensation = this.answerGuaranteeDeductionOrCompensation;
    request.answerGuaranteeDeductionOrCompensationText = this.answerGuaranteeDeductionOrCompensationText || '';
    return request;
  }
}
