/* @flow */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function IconPaymentOldCommission(props) {
  return (
    <SvgIcon viewBox="0 0 69.29 78.06" {...props}>
      <g>
        <title>Layer 1</title>
        <path id="svg_4" d="m46.26001,27.74001l0,-12.84a6,6 0 0 0 -2.14,-3.44q-4.89,-4.92 -9.73,-9.89a4.89,4.89 0 0 0 -3.71,-1.56c-8.79,0 -17.58,0.06 -26.38,0a4,4 0 0 0 -4.3,4.25q0.11,25.29 0,50.57a3.83,3.83 0 0 0 3.92,4l15.66,0a25.21,25.21 0 1 0 26.68,-31l0,-0.09zm-12.38,-20.44l5.64,5.64l-5.64,0l0,-5.64zm-29.87,47.48l0,-50.74l25.85,0l0,8.64a4,4 0 0 0 4.28,4.26c2.67,-0.08 5.34,0 8.08,0l0,10.8a25.24,25.24 0 0 0 -23.35,25.13c0,0.65 0,1.29 0.08,1.93l-14.94,-0.02zm40.07,19.28a21.21,21.21 0 1 1 21.21,-21.21a21.23,21.23 0 0 1 -21.21,21.21z" />
        <line stroke-width="4" stroke-linecap="undefined" stroke-linejoin="undefined" id="svg_5" y2="63.28649" x2="54.8616" y1="41.80634" x1="32.83994" stroke="#000"  />
        <line stroke="#000" stroke-width="4" stroke-linecap="undefined" stroke-linejoin="undefined" id="svg_6" y2="41.35508" x2="54.95186" y1="63.28649" x1="32.83994"  />
      </g>
    </SvgIcon>
  );
}
