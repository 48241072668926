/* @flow */

import React, {useState} from 'react';

import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import ButtonComponent from 'components/buttons/button';
import DebtorInvoicesToApproveConfirmPopupComponent from 'app/debtors/invoicesToApproveConfirmPopup';
import DebtorInvoicesToApproveRefusePopupComponent from 'app/debtors/invoicesToApproveRefusePopup';
import IntlMessageComponent from 'components/formatters/intlMessage';

type DebtorInvoiceDetailsActionApproveViewComponentProps = {
  invoiceId: number;
  successHandler: () => void;
}

export default function DebtorInvoiceDetailsActionApproveViewComponent(props: DebtorInvoiceDetailsActionApproveViewComponentProps) {
  const [approve, setApprove] = useState(false);
  const [refuse, setRefuse] = useState(false);

  return (
    <React.Fragment>
      <Box mt={3}>

        <Box mt={1}>
          <ButtonComponent
            color="primary"
            variant="contained"
            onClick={() => setApprove(true)}
          ><IntlMessageComponent id="general.btn.approve" /></ButtonComponent>

          <Box component="span" ml={1}>
            <ButtonComponent
              color="primary"
              variant="contained"
              onClick={() => setRefuse(true)}
            ><IntlMessageComponent id="general.btn.refuse" /></ButtonComponent>
          </Box>
        </Box>
      </Box>

      {approve &&
        <DebtorInvoicesToApproveConfirmPopupComponent
          invoiceIds={[props.invoiceId]}
          handleCancel={() => setApprove(false)}
          handleSuccess={props.successHandler}
        />
      }

      {refuse &&
        <DebtorInvoicesToApproveRefusePopupComponent
          invoiceId={props.invoiceId}
          handleCancel={() => setRefuse(false)}
          handleSuccess={props.successHandler}
        />
      }
    </React.Fragment>
  );
}
