/* @flow */

import React from 'react';

import CommercialDocumentsTableComponent from 'app/documents/commercial';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function DebtorCommercialPage() {
  return (
    <WebsitePageComponent id="debtor.commercial.title" data-walkme="debtor-commercial-title" noPaper>
      <CommercialDocumentsTableComponent />
    </WebsitePageComponent>
  );
}
