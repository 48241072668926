/* @flow */

import React from 'react';
import {useSelector} from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';

import ButtonComponent from 'components/buttons/button';
import FormErrorsComponent from 'components/formErrors';
import IntlMessageComponent from 'components/formatters/intlMessage';

import RefuseInvoiceFormModel from './model';

type BuyerRefuseInvoiceFormDialogViewComponentProps = {
  errors: Map<string,?string>;
  form: RefuseInvoiceFormModel;
  formErrors: string[];
  isProcessing: boolean;
  closeDialog: () => void;
  handleChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void;
  submitForm: () => void;
}

export default function BuyerRefuseInvoiceFormDialogViewComponent(props: BuyerRefuseInvoiceFormDialogViewComponentProps) {
  const translations = useSelector(state => state.locale.translations.filter(t => t.key.startsWith('buyer.refuse.reason')));

  return (
    <Dialog open onClose={props.closeDialog}>
      <DialogTitle>
        <IntlMessageComponent id="component.buyer.invoiceList.dialog.refuse.title" />
      </DialogTitle>

      <DialogContent>
        <FormErrorsComponent formErrors={props.formErrors} />
        <DialogContentText>
          <IntlMessageComponent id="component.buyer.invoiceList.dialog.refuse.contenttext" />
        </DialogContentText>
        <Select
          style={{minWidth: 280}}
          labelId={<IntlMessageComponent id="component.buyer.invoiceList.dialog.refuse.textfieldlabel" />}
          id="reason-dropdown"
          value={props.form.reason}
          label="Reason"
          onChange={props.handleChange('reason')}
          error={!!props.errors.get('reason')}
          fullWidth
        >
          <MenuItem value={translations.find(t => t.key === 'buyer.refuse.reason.notenoughfunds')?.text ?? 'Not enough available funds'}><IntlMessageComponent id="buyer.refuse.reason.notenoughfunds" /></MenuItem>
          <MenuItem value={translations.find(t => t.key === 'buyer.refuse.reason.returntoolow')?.text ?? 'Return too low'}><IntlMessageComponent id="buyer.refuse.reason.returntoolow" /></MenuItem>
          <MenuItem value={translations.find(t => t.key === 'buyer.refuse.reason.gradetoolow')?.text ?? 'Debtor grade too low '}><IntlMessageComponent id="buyer.refuse.reason.gradetoolow" /></MenuItem>
          <MenuItem value={'Other'}><IntlMessageComponent id="buyer.refuse.reason.other" /></MenuItem>
        </Select>
        <FormHelperText error={!!props.errors.get('reason')}>{props.errors.get('reason') ? <IntlMessageComponent id={props.errors.get('reason')} /> : ''}</FormHelperText>
        {props.form.reason === 'Other' && <TextField
          autoFocus
          margin="dense"
          id="reasonFree"
          label={<IntlMessageComponent id="component.buyer.invoiceList.dialog.refuse.textfieldlabel" />}
          helperText={props.errors.get('reasonFree') ? <IntlMessageComponent id={props.errors.get('reasonFree')} /> : ''}
          multiline
          rows="4"
          fullWidth
          value={props.form.reasonFree}
          onChange={props.handleChange('reasonFree')}
          error={!!props.errors.get('reasonFree')}
        />}
      </DialogContent>

      <DialogActions>
        <ButtonComponent
          color="default"
          disabled={props.isProcessing}
          onClick={props.closeDialog}
        ><IntlMessageComponent id="component.buyer.invoiceList.dialog.refuse.actions.cancel" /></ButtonComponent>

        <ButtonComponent
          color="primary"
          isProcessing={props.isProcessing}
          onClick={props.submitForm}
        ><IntlMessageComponent id="component.buyer.invoiceList.dialog.refuse.actions.submit" /></ButtonComponent>
      </DialogActions>
    </Dialog>
  );
}
