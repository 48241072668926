/* @flow */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function IconSuitcaseFilled(props) {
  return (
    <SvgIcon viewBox="0 0 68.95 60.8" {...props}>
      <g>
        <path d="M431.43,276.15c0-3.29-3.12-5.67-5.89-5.75-2.58-.07-5.16-.06-7.73,0a6.29,6.29,0,0,0-6.11,5.71l-.05.43h19.78Zm-13.58-3.95c3-.06,5.38-.06,7.64,0a4.52,4.52,0,0,1,3.8,2.52H413.94A4.49,4.49,0,0,1,417.85,272.2Z" transform="translate(-386.59 -266.75)"/>
        <path d="M455.9,280.35a5.16,5.16,0,0,0-5.46-5.41H435a10,10,0,0,0-9.95-8.18c-2.42,0-4.85,0-7.27,0a10,10,0,0,0-9.35,6.83c-.15.45-.26.9-.36,1.34h-16a5.24,5.24,0,0,0-5.54,5.5c0,13.95,0,28.11,0,42.1a5.25,5.25,0,0,0,5,5.51,13.79,13.79,0,0,0,1.43.06H449.3c.51,0,1,0,1.53-.05a5.31,5.31,0,0,0,5.08-5.48Q455.91,301.46,455.9,280.35Zm-1.8,42.23a3.48,3.48,0,0,1-3.42,3.68c-.46,0-.92,0-1.38,0H393.05c-.42,0-.84,0-1.24,0a3.49,3.49,0,0,1-3.41-3.72q0-21,0-42.1a3.5,3.5,0,0,1,3.75-3.69h17.38l.07-.29c.07-.27.12-.53.18-.78a14.85,14.85,0,0,1,.4-1.5,8.13,8.13,0,0,1,7.65-5.6c2.41,0,4.83,0,7.24,0a8.25,8.25,0,0,1,8.25,7,3.73,3.73,0,0,0,.16.59l.18.58h16.78a3.41,3.41,0,0,1,3.66,3.61Q454.12,301.47,454.1,322.58Z" transform="translate(-386.59 -266.75)"/>
        <path d="M452.31,281.54c0-1.27-.05-2.17-.73-2.64v-.26H451a7.1,7.1,0,0,0-1.61-.13H392.77c-2,0-2.52.57-2.52,2.51v2.21c0,1.64,0,3.28,0,4.91a1.61,1.61,0,0,0,1.16,1.77L395,291.2c2.27.83,4.61,1.68,7,2.38a64.31,64.31,0,0,0,13.87,2.13c1.8.12,3.63.18,5.44.18a84.84,84.84,0,0,0,14.87-1.34,59.9,59.9,0,0,0,15.16-4.68,1.62,1.62,0,0,0,1.06-1.68c0-1.59,0-3.18,0-4.76Z" transform="translate(-386.59 -266.75)"/>
        <path d="M416.32,301.66a30.49,30.49,0,0,0,3.28,3.47l1.42,1.36.27.28.28-.28c.38-.38.74-.78,1.1-1.16a18.2,18.2,0,0,1,2.33-2.25c1.81-1.37,1.65-3.13,1.5-4.83l0-.46H415.84l0,.42a8.5,8.5,0,0,1,0,1.13A3.74,3.74,0,0,0,416.32,301.66Z" transform="translate(-386.59 -266.75)"/>
        <path d="M452.27,292.61l-.07-1-.51.23a68.29,68.29,0,0,1-22.94,5.63l-.36,0V299c0,.93,0,1.79,0,2.65,0,.58-.13.75-.18.8-1.84,1.9-3.71,3.78-5.64,5.71l-1.31,1.33-1.37-1.38c-1.88-1.9-3.71-3.74-5.51-5.61a1.33,1.33,0,0,1-.22-.79c0-.89,0-1.78,0-2.72v-1.5l-.35,0a67.47,67.47,0,0,1-23-5.63l-.55-.24v30.36c0,2,.59,2.55,2.6,2.55h56.85c2.06,0,2.62-.57,2.62-2.66V293.4C452.31,293.15,452.29,292.9,452.27,292.61Z" transform="translate(-386.59 -266.75)"/>
      </g>
    </SvgIcon>
  );
}
