/* @flow */

import React from 'react';
import {Link} from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';

import {SIGN_IN} from 'constants/pageRoutes';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

type LostPasswordResultComponentProps = {
  action: string; // resetemail emailConfirmation
  email: string;
}

export default function LostPasswordResultComponent(props: LostPasswordResultComponentProps) {
  const values = {email: <Box component="span" color="primary.main" fontWeight="fontWeightBold">{props.email}</Box>};

  return (
    <React.Fragment>
      <PageSubTitleViewComponent id={`page.lostPassword.title.${props.action}sent`} />

      <Typography variant="body1">
        <IntlMessageComponent id={`page.lostPassword.description.${props.action}sent`} values={values} />
      </Typography>

      <Box mt={5}>
        <ButtonComponent
          color="primary"
          variant="contained"
          component={Link}
          to={SIGN_IN}
        ><IntlMessageComponent id="general.back" /></ButtonComponent>
      </Box>
    </React.Fragment>
  );
}
