/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import AmountFormatterComponent from 'components/formatters/amount';
import CompanyAddressComponent from 'components/company/address';
import DateFormatterComponent from 'components/formatters/date';
import DebtorGradeForSellerComponent from 'components/debtorGrades/seller';
import IconCancelOutlined from 'components/icons/outlined/cancel';
import IconExclamationMarkOutlined from 'components/icons/outlined/exclamationMark';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import InvoiceDetails from 'models/invoices/seller/invoiceDetails';

type SellerInvoiceDetailsSummaryComponentProps = {
  invoice: InvoiceDetails;
}

export default function SellerInvoiceDetailsSummaryComponent(props: SellerInvoiceDetailsSummaryComponentProps) {

  const isCancelled = props.invoice.isCancelledBySeller || props.invoice.isRefused;

  return (
    <React.Fragment>
      <PageSubTitleViewComponent id="page.seller.invoiceDetails.summary.title" data-walkme="sdetails-summaryTitle" />

      <Box mt={3}>
        <Grid container direction="row" justify="space-between" alignItems="flex-start">
          <Grid item xs={12} md={6}>
            <Box display="flex" px={4}>
              <Box flexGrow={1} mb={1}>
                <Typography variant="body1">
                  <Box component="span" color="grey.main" fontSize={13}>
                    <IntlMessageComponent id="page.seller.invoiceDetails.summary.debtor.name" data-walkme="sdetails-debtorName" />
                  </Box>
                </Typography>
              </Box>
              <Box>
                <CompanyAddressComponent company={props.invoice.debtorCompany}/>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box display="flex" px={4}>
              <Box flexGrow={1} mb={1}>
                <Typography variant="body1">
                  <Box component="span" color="grey.main" fontSize={13}>
                    <IntlMessageComponent id="page.seller.invoiceDetails.summary.dueDate" data-walkme="sdetails-dueDate" />
                  </Box>
                </Typography>
              </Box>
              <Box>
                <DateFormatterComponent date={props.invoice.dueDate} format="DD MMMM YYYY" />
              </Box>
            </Box>
            
            <Box display="flex" px={4}>
              <Box flexGrow={1} mb={1}>
                <Typography variant="body1">
                  <Box component="span" color="grey.main" fontSize={13}>
                    <IntlMessageComponent id="page.seller.invoiceDetails.summary.debtAmount" data-walkme="sdetails-debtAmount" />
                  </Box>
                </Typography>
              </Box>
              <Box>
                <AmountFormatterComponent amount={props.invoice.debtAmount} />
              </Box>
            </Box>

            <Box display="flex" px={4}>
              <Box flexGrow={1} mb={1}>
                <Typography variant="body1">
                  <Box component="span" color="grey.main" fontSize={13}>
                    <IntlMessageComponent id="page.seller.invoiceDetails.summary.debtor.grade" data-walkme="sdetails-debtorGrade" />
                  </Box>
                </Typography>
              </Box>
              <Box>
                <DebtorGradeForSellerComponent insurerRating={props.invoice.debtorCompany.insurerRating} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {isCancelled &&
        <Box mt={3} px={4} py={2} bgcolor="black" color="white" fontWeight="fontWeightBold">
          <Box component="span" mr={4}>
            <IconCancelOutlined fontSize="large" />
          </Box>
          <IntlMessageComponent id={props.invoice.isCancelledBySeller ? "page.seller.invoiceDetails.summary.cancelled" : "page.seller.invoiceDetails.summary.refused"} />
        </Box>
      }

      {!isCancelled && props.invoice.debtorCompany.isWaitingForGrade &&
        <Box mt={3} px={4} py={2} bgcolor="text.secondary" border={1} borderColor="black" fontWeight="fontWeightBold">
          <Box component="span" mr={4}>
            <IconExclamationMarkOutlined fontSize="large" />
          </Box>
          <IntlMessageComponent id="page.seller.invoiceDetails.summary.gradeZero" />
        </Box>
      }
    </React.Fragment>
  );
}
