/* @flow */

import React from 'react';

import CompanyOfficesViewComponent from './view';

import Office from 'models/companies/office';
import OfficePerson from 'models/companies/officePerson';

type CompanyOfficesComponentProps = {
}

type CompanyOfficesComponentState = {
  displayNewOfficeForm: boolean;
  displayNewOfficePersonForm: boolean;
  office: Office;
  officePerson: OfficePerson;
}

class CompanyOfficesComponent extends React.Component<CompanyOfficesComponentProps, CompanyOfficesComponentState> {

  constructor(props) {
    super(props);
    this.state = {
      displayNewOfficeForm: false,
      displayNewOfficePersonForm: false,
      office: null,
      officePerson: null
    };
  }

  newOffice = () => {
    this.setState({ displayNewOfficeForm: true });
  };

  editOffice = (office: Office) => {
    this.setState({ displayNewOfficeForm: false, office });
  };
  
  hideNewOfficeForm = () => {
    this.setState({ displayNewOfficeForm: false });
  };

  unselectOffice = () => {
    this.setState({ displayNewOfficeForm: false, office: null });
  }

  newOfficePerson = () => {
    this.setState({ displayNewOfficePersonForm: true });
  }

  editOfficePerson = (officePerson: OfficePersonModel) => {
    this.setState({ displayNewOfficePersonForm: true, officePerson });
  };

  hideNewOfficePersonForm = () => {
    this.setState({ displayNewOfficePersonForm: false, officePerson: null });
  }

  render() {
    return (
      <CompanyOfficesViewComponent
        displayNewOfficeForm={this.state.displayNewOfficeForm}
        displayNewOfficePersonForm={this.state.displayNewOfficePersonForm}
        office={this.state.office}
        officePerson={this.state.officePerson}
        newOffice={this.newOffice}
        editOffice={this.editOffice}
        hideNewOfficeForm={this.hideNewOfficeForm}
        unselectOffice={this.unselectOffice}
        newOfficePerson={this.newOfficePerson}
        editOfficePerson={this.editOfficePerson}
        hideNewOfficePersonForm={this.hideNewOfficePersonForm}
      />
    );
  }
}

export default CompanyOfficesComponent;
