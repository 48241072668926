/* @flow */

import React from 'react';

import TypeImplComponent from './typeImpl';

import CompanyLegalForm from 'models/companies/companyLegalForm';

import CompanyService from 'services/CompanyService';

type LegalFormFieldComponentProps = {
  countryId: number;
  error: boolean;
  fullWidth: boolean;
  helperText: IntlMessage;
  id: string;
  label: IntlMessage;
  margin: string;
  required: boolean;
  readOnly: boolean;
  value: number;
  changeHandler: (typeId: number) => void;
}

type LegalFormFieldComponentState = {
  isLoading: boolean;
  values: CompanyLegalForm[];
}

class LegalFormFieldComponent extends React.Component<LegalFormFieldComponentProps, LegalFormFieldComponentState> {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      values: []
    };
  }

  componentDidMount() {
    this.fetchLegalForms();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.countryId !== this.props.countryId) {
      this.fetchLegalForms();
    }
  }

  fetchLegalForms = async () => {
    if (!this.props.countryId || this.props.countryId <= 0) {
      console.warn('No country Id found');
      return;
    }

    this.setState({isLoading: true, values: []});

    try {
      const values = await CompanyService.getLegalForms(this.props.countryId);
      this.setState({isLoading: false, values});
    } catch (e) {
      console.error(e);
    }
  }

  render() {
    return (
      <TypeImplComponent
        isLoading={this.state.isLoading}
        values={this.state.values}
        {...this.props}
      />
    );
  }
}

export default LegalFormFieldComponent;
