/* @flow */

function sharesValidator(value: number, options: {greaterThan: number, lessThanOrEqualTo: number, message: string}, key: string, attributes: any) {
  if (key === 'single') return undefined; 
  if (attributes['isDecisionMaker']) return undefined;
  if (value > options.greaterThan && value <= options.lessThanOrEqualTo) return undefined;
  return options.message;
}

export default sharesValidator;
