/* @flow */

import moment from 'moment';

import PaymentInfo from 'models/payments/paymentInfo';

export default class CompanyMembership {
  hasCoupon: boolean;
  isPaid: boolean;
  isValid: boolean;
  paymentInfo: PaymentInfo;
  validFrom: moment;
  validTo: moment;

  constructor() {
    this.hasCoupon = false;
    this.isPaid = false;
    this.isValid = false;
    this.paymentInfo = null;
    this.validFrom = moment();
    this.validTo = moment();
  }
}
