/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import LoadingComponent from 'components/loading';

import ImpersonateViewComponent from './view';
import ImpersonateForm from './model';

import {impersonateUserAction, showAuthLoader} from 'store/actions/authActions';
import {handleFormChange, handleFormValidation} from 'lib/forms';
import loadConstraints from 'lib/validation/loadConstraints';
import UserService from 'services/UserService';

type ImpersonateComponentProps = {
  impersonateFailedMessage: string;
  password: string;
  showLoginFailedMessage: boolean;
  showServerErrorMessage: boolean;
  showImpersonateFailedMessage: boolean;
  target: string;
  username: string;
}

type ImpersonateComponentState = {
  constraints: any;
  errors: Map<string, string>;
  form: ImpersonateForm;
  hasTwoFactor: boolean;
  formErrors: string[];
  isLoading: boolean;
}

class ImpersonateComponent extends React.Component<ImpersonateComponentProps, ImpersonateComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      constraints: null,
      errors: null,
      form: new ImpersonateForm(props.target, props.username, props.password),
      formErrors: [],
      isLoading: true
    };
  }

  componentDidMount = async () => {
    try {
      const constraints = await loadConstraints('Impersonate', ['username', 'target', 'password']);
    
      this.setState({
        constraints,
        errors: new Map(Object.keys(constraints).map(e => [e, undefined])),
        isLoading: false
      });
    } catch (e) {
      console.error(e);
    }
  };

  onKeyDown = (event): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      this.submitForm();
    }
  };

  handleChange = (fieldName: string) => (event) => handleFormChange.call(this, fieldName, event.target.value);
  validateForm = () => handleFormValidation.call(this);

  submitForm = async () => {
    let hasTwoFactor = await UserService.checkUserHasTwoFactor(this.state.form.username);
    if (hasTwoFactor === true && !this.state.hasTwoFactor)
    {
      this.setState({hasTwoFactor});
      return;
    }
    if (!this.validateForm()) return;
    this.setState({formErrors: []});
    this.props.showAuthLoader();
    this.props.impersonateUserAction(this.state.form.username, this.state.form.password, this.state.form.target, this.state.form.twofactorpin);
  };

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <ImpersonateViewComponent
        errors={this.state.errors}
        form={this.state.form}
        formErrors={this.state.formErrors}
        impersonateFailedMessage={this.props.impersonateFailedMessage}
        showImpersonateFailedMessage={this.props.showImpersonateFailedMessage}
        showLoginFailedMessage={this.props.showLoginFailedMessage}
        showServerErrorMessage={this.props.showServerErrorMessage}
        hasTwoFactor={this.state.hasTwoFactor}
        handleChange={this.handleChange}
        onKeyDown={this.onKeyDown}
        submitForm={this.submitForm}
      />
    );
  }
}

const mapStateToProps = state => ({
  impersonateFailedMessage: state.auth.impersonateFailedMessage,
  showLoginFailedMessage: state.auth.showLoginFailedMessage,
  showServerErrorMessage: state.auth.showServerErrorMessage,
  showImpersonateFailedMessage: state.auth.showImpersonateFailedMessage
});

export default connect(mapStateToProps, {impersonateUserAction, showAuthLoader})(ImpersonateComponent);
