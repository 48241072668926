/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Typography from '@material-ui/core/Typography';

import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';
import MandatoryFormFieldsComponent from 'components/form/mandatory';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import EligibilityQuestionModel from 'models/leads/eligibilityQuestion';

type EligibilityQuestionComponentViewProps = {
  answerIds: number[];
  isProcessing: boolean;
  question: EligibilityQuestionModel;
  backHandler: () => void;
  handleChange: (answerId: number) => void;
  submitForm: () => void;
};

export default function EligibilityQuestionViewComponent(props: EligibilityQuestionComponentViewProps) {

  return (
    <React.Fragment>
      <Typography variant="h2" gutterBottom>
        {props.question.title}
      </Typography>

      <Box mt={3}>
        <FormControl component="fieldset" required>
          <FormLabel component="legend">{props.question.text}</FormLabel>

          {props.question.answers.map(answer => (
            <FormGroup key={answer.id}>
              <FormControlLabel
                control={<Checkbox checked={props.answerIds.find(id => id === answer.id)} onChange={() => props.handleChange(answer.id)} value={answer.id} />}
                label={answer.text}
              />
            </FormGroup>
          ))}
        </FormControl>
      </Box>

      <Box mt={5}>
        <ButtonComponent
          color="default"
          variant="outlined"
          disabled={props.isProcessing}
          onClick={props.backHandler}
        ><IntlMessageComponent id="general.button.back.label" /></ButtonComponent>

        <Box component="span" ml={1}>
          <ButtonComponent
            color="primary"
            variant="contained"
            disabled={props.answerIds.length === 0}
            isProcessing={props.isProcessing}
            onClick={props.submitForm}
          ><IntlMessageComponent id="general.button.validate.label" /></ButtonComponent>
        </Box>

        <MandatoryFormFieldsComponent id="form.mcq" />
      </Box>
    </React.Fragment>
  );
}
