/* @flow */

import React from 'react';

import * as reactGA from 'lib/analytics';

import LoadingComponent from 'components/loading';

import SignUpTab5ViewComponent from './view';

import SetPasswordForm from './model';

import RegisterService from 'services/registerService';
import {handleApiFormResponse, handleFormValidation} from 'lib/forms';
import loadConstraints from 'lib/validation/loadConstraints';
import {validateField, validateForm} from 'lib/validation/validator';

type SignUpTab5ComponentProps = {
  email: string;
  token: string;
  successHandler: (string) => void;
}

type SignUpTab5ComponentState = {
  constraints: any;
  errors: Map<string, ?string>;
  form: SetPasswordForm;
  formErrors: string[];
  isLoading: boolean;
  isProcessing: boolean;
}

class SignUpTab5Component extends React.Component<SignUpTab5ComponentProps, SignUpTab5ComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      constraints: null,
      errors: null,
      form: new SetPasswordForm(),
      formErrors: [],
      isLoading: true,
      isProcessing: false
    };
  }

  componentDidMount = async () => {
    reactGA.initializeGA();
    reactGA.logEvent('SignUp', 'Password Selection');

    try {
      const constraints = await loadConstraints('Password', ['password', 'confirmPassword']);
      this.setState({
        constraints,
        errors: new Map(Object.keys(constraints).map(e => [e, undefined])),
        isLoading: false
      });
    } catch (e) {
      console.error(e);
    }
  };

  onKeyDown = (event: SyntheticKeyboardEvent<HTMLDivElement>): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      this.submitPassword();
    }
  };

  handlePasswordChange = (event) => {
    const value = event.target.value;

    const validationResult = validateField(value, this.state.constraints.password);
    const errors = this.state.errors;
    errors.set('password', validationResult ? validationResult[0] : null);

    if (this.state.form.confirmPassword.length > 2) {
      const validationResults = validateForm({password: value, confirmPassword: this.state.form.confirmPassword}, this.state.constraints);
      let validationResult2 = validationResults && validationResults.confirmPassword ? validationResults.confirmPassword[0] : null;
      if (validationResult2 !== null && validationResult2.includes(' ')) {
        const split = validationResult2.split(' ');
        validationResult2 = split.pop();
      }
      errors.set('confirmPassword', validationResult2);
    }

    const form = this.state.form;
    form.password = value;
    this.setState({form, errors});
  };

  handleConfirmPasswordChange = (event) => {
    const value = event.target.value;

    const validationResults = validateForm({password: this.state.form.password, confirmPassword: value}, this.state.constraints);
    let validationResult = validationResults && validationResults.confirmPassword ? validationResults.confirmPassword[0] : null;
    if (validationResult !== null && validationResult.includes(' ')) {
      const split = validationResult.split(' ');
      validationResult = split.pop();
    }

    const errors = this.state.errors;
    errors.set('confirmPassword', validationResult);

    const form = this.state.form;
    form.confirmPassword = value;
    this.setState({form, errors});
  };

  validateForm = () => handleFormValidation.call(this);

  submitForm = async () => {
    if (!this.validateForm()) return;

    this.setState({formErrors: [], isProcessing: true});

    try {
      const request = this.state.form.toRequest(this.props.email, this.props.token);
      await RegisterService.setPassword(request);
      reactGA.logEvent('SignUp', 'Password Validated');
      this.props.successHandler(this.state.form.password);
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }
  };

  render = () => {
    if (this.state.isLoading) return (<LoadingComponent />);
    return (
      <SignUpTab5ViewComponent
        errors={this.state.errors}
        form={this.state.form}
        formErrors={this.state.formErrors}
        isProcessing={this.state.isProcessing}  
        handlePasswordChange={this.handlePasswordChange}
        handleConfirmPasswordChange={this.handleConfirmPasswordChange}
        submitForm={this.submitForm}
      />
    );
  }
}

export default SignUpTab5Component;
