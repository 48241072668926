/**
 * This file centralize the routes in use in the API. Organization is based on what's displayed in Postman
 */

/* Buyer section */
export const BUYER_ACCOUNTING_EXPORT_GET = '/buyer/accounting/export';
export const BUYER_PAYMENT_REPORT_GET = '/buyer/invoices/payment/report';
export const BUYER_INVESTMENT_PROFILES_GET = '/buyer/investmentprofiles';
export const BUYER_INVESTMENT_PROFILES_POST = '/buyer/investmentprofiles';
export const BUYER_INVESTMENT_PROFILES_NAME_POST = '/buyer/investmentprofiles/name';
export const BUYER_INVESTMENT_PROFILES_NEW = '/buyer/investmentprofiles/new';
export const BUYER_INVESTMENT_PROFILES_DISABLE_POST = '/buyer/investmentprofiles/disable';
export const BUYER_INVESTMENT_PROFILES_DEFAULTS_GET = '/buyer/investmentprofiles/defaults';
export const BUYER_INVOICE_DETAILS_GET = '/buyer/invoices/details';
export const BUYER_INVOICES_CONFIRM_PAYMENT_POST = '/buyer/invoices/confirm';
export const BUYER_INVOICES_GET = '/buyer/invoices';
export const BUYER_INVOICES_PAY_GET = '/buyer/invoices/pay';
export const BUYER_INVOICES_PURCHASE_GET = '/buyer/invoices/purchase';
export const BUYER_INVOICES_PURCHASE_POST = '/buyer/invoices/purchase';
export const BUYER_INVOICES_REFUSE_POST = '/buyer/invoices/refuse';
export const BUYER_KPIS_GET = '/buyer/kpis';
export const BUYER_PERFORMANCE_GET = '/buyer/performance';
export const BUYER_PORTFOLIO_GET = '/buyer/invoices/portfolio';
export const BUYER_PORTFOLIO_DATE_GET = '/buyer/invoices/portfolio/snapshot';
export const BUYER_PORTFOLIO_EXPORT_GET = '/buyer/invoices/portfolio/export';
export const BUYER_PROFILE_SUMMARY_GET = '/buyer/profilesummary';


/* Seller section */
export const SELLER_ALTFIN_POST = '/seller/alternativefinancing';
export const SELLER_BUSINESS_POTENTIALS_GET = '/seller/businesspotentials';
export const SELLER_BUSINESS_POTENTIAL_POST = '/seller/businesspotentials';
export const SELLER_BUSINESS_POTENTIAL_DELETE = '/seller/businesspotentials';
export const SELLER_BUSINESS_POTENTIALS_CONFIRM_POST = '/seller/businesspotentials/confirm';
export const SELLER_INVOICES_PORTFOLIO_GET = '/seller/invoices/portfolio';
export const SELLER_INVOICES_PORTFOLIO_EXPORT_GET = '/seller/invoices/portfolio/export';
export const SELLER_INVOICES_PAYMENT_REPORT_GET = '/seller/invoices/payment/report';
export const SELLER_KPIS_GET = '/seller/kpis';
export const SELLER_PROFILE_SUMMARY_GET = '/seller/profilesummary';
export const SELLER_REFERRAL_GET = '/seller/referral';
export const SELLER_REFERRAL_NOTE_POST = '/seller/referral/note';
export const SELLER_REFERRAL_POST = '/seller/referral';

/* Users section */
export const USER_CHECK_TWO_FACTOR = '/user/has2fa';
export const USER_AUTHENTICATE = '/auth/token';
export const USER_COMPANY_GET = '/user/company';
export const USER_GET = '/user';
export const USER_IMPERSONATE_POST = '/user/impersonate';
export const USER_MOBILE_POST = '/user/mobile';
export const USER_NOTIFICATION_SETTINGS_GET = '/user/notifications';
export const USER_NOTIFICATION_SETTINGS_POST = '/user/notifications';
export const USER_PASSWORD_CHANGE_POST = '/user/password';
export const USER_PASSWORD_LOST_POST = '/user/lostpassword';
export const USER_PASSWORD_RESET_POST = '/user/resetpassword';
export const USER_PERSON_INFO_GET = '/user/info';
export const USER_PERSON_INFO_POST = '/user/info';
export const USER_QR_CODE_GET = '/user/getqrcode';
export const USER_GENERATE_SECRET_KEY = '/user/generatesecret';
export const USER_VALIDATE_TWO_FACTOR = '/user/validate2fa';
export const USER_DISABLE_TWO_FACTOR = '/user/disable2fa';

/* Lead section */
export const LEAD_POST = '/lead';
export const LEAD_GET = '/lead';
export const LEAD_ELIGIBILITY_QUESTIONNAIRE_CHECK_GET = '/lead/eligibility/check';
export const LEAD_ELIGIBILITY_QUESTIONNAIRE_QUESTIONS_GET = '/lead/eligibility/questions';
export const LEAD_ELIGIBILITY_QUESTIONNAIRE_ANSWER_POST = '/lead/eligibility/answer';
export const LEAD_ELIGIBILITY_QUESTIONNAIRE_CONFIRM_POST = '/lead/eligibility/confirm';
export const LEAD_CALLBACK_TIME_POST = '/lead/callback';
export const LEAD_BUSINESS_OPPORTUNITY_POST = '/lead/businessOpportunity';
export const LEAD_OFFER_POST = '/lead/offer';
export const LEAD_OFFER_GET = '/lead/offer';
export const LEAD_OFFER_DEFAULTS_GET = '/lead/offer/defaults';
export const LEAD_OFFER_SEND_POST = '/lead/offer/send';
export const LEAD_OFFER_UC_SEND_POST = '/lead/offeruc/send';
export const LEAD_ALTFIN_POST = '/lead/alternativefinancing';
export const LEAD_BUSINESS_POTENTIALS_GET = '/lead/businesspotentials';
export const LEAD_BUSINESS_POTENTIAL_POST = '/lead/businesspotentials';
export const LEAD_BUSINESS_POTENTIAL_DELETE = '/lead/businesspotentials';
export const LEAD_BUSINESS_POTENTIALS_CONFIRM_POST = '/lead/businesspotentials/confirm';

/* Generic section */
export const GENERIC_GET_COUNTRIES = '/generic/countries';
export const GENERIC_GET_COUNTRIES_FOR_DEBTORS = '/generic/countriesfordebtors';
export const GENERIC_GET_COUNTRIES_FOR_SELLERS = '/generic/countriesforsellers';
export const GENERIC_GET_COUNTRIES_FOR_BUYERS = '/generic/countriesforbuyers';
export const GENERIC_GET_COUNTRIES_FOR_ISOS = '/generic/countriesforisos';
export const GENERIC_GET_CULTURES_POST = '/generic/culturename';
export const GENERIC_GET_LANGUAGES = '/generic/languages';
export const GENERIC_GET_REGISTRATION_TYPES = '/generic/registrationtypes';
export const GENERIC_GET_TRANSLATIONS = '/generic/translations';
export const GENERIC_GET_EVERYTHING = '/generic/generics';
export const GENERIC_GET_WEBSITE_LANGUAGES = '/generic/languages';

/* Companies section */
export const COMPANY_DETAILS_GET = '/company/details';
export const COMPANY_DETAILS_POST = '/company/details';
export const COMPANY_DETAILS_ISO_POST = '/company/details/iso'
export const COMPANY_GET_DEBTORS_BY_COMPANY_ID = '/company/debtors';
export const COMPANY_DEBTOR_GET = '/company/debtor';
export const COMPANY_DEBTORS_GET = '/company/debtors';
export const COMPANY_SUPPLIER_GET = '/company/supplier';
export const COMPANY_SUPPLIERS_GET = '/company/suppliers';
export const COMPANY_GET_EH_BY_NAME = '/company/search/name';
export const COMPANY_GET_EH_BY_VAT = '/company/search/vat';
export const COMPANY_POST_DEBTOR = '/company/debtor';
export const COMPANY_POST_SUPPLIER = '/company/supplier';
export const COMPANY_POST_COMPANY = '/company';
export const COMPANY_LEGAL_FORMS_GET = '/company/legalForms';
export const COMPANY_BANK_ACCOUNTS_GET = '/company/bankaccounts';
export const COMPANY_BANK_ACCOUNTS_POST = '/company/bankaccounts';
export const COMPANY_BANK_ACCOUNTS_TOGGLE_ENABLE = '/company/bankaccounts/toggleenable';
export const COMPANY_BANK_ACCOUNTS_DELETE = '/company/bankaccounts/delete';
export const COMPANY_BANK_ACCOUNTS_MAIN_POST = '/company/bankaccounts/main';
export const COMPANY_MEMBERSHIP_GET = '/company/membership';
export const COMPANY_MEMBERSHIP_POST = '/company/membership';
export const COMPANY_MEMBERSHIP_APPLY_COUPON_POST = '/company/membership/coupon';
export const COMPANY_USERS_GET = '/company/users';
export const COMPANY_USERS_ADD_POST = '/company/users';
export const COMPANY_USERS_ROLES_ADD_POST = '/company/users/roles'
export const COMPANY_USERS_UPDATE_POST = '/company/users/update';
export const COMPANY_USERS_ROLES_UPDATE_POST = '/company/users/roles/update';
export const COMPANY_USERS_PROCURATION_GET = '/company/users/procuration';
export const COMPANY_USERS_PROCURATION_POST = '/company/users/procuration';
export const COMPANY_USERS_DISABLE = '/company/users/disable';
export const COMPANY_PERSONS_ISO_WITHOUT_OFFICE_GET = '/company/iso-persons-without-office';
export const COMPANY_PERSONS_DEBTOR_WITHOUT_OFFICE_GET = '/company/debtor-persons-without-office';
export const COMPANY_DOCUMENT_POST = '/company/document';
export const COMPANY_CONTACTS_GET = '/company/contacts';
export const COMPANY_CONTACTS_POST = '/company/contacts';
export const COMPANY_UBOS_GET = '/company/ubos';
export const COMPANY_UBOS_CONFIRM_POST = '/company/ubos/confirm';
export const COMPANY_UBOS_ADD_POST = '/company/ubos';
export const COMPANY_UBOS_UPDATE_PUT = '/company/ubos';
export const COMPANY_UBOS_DISABLE_POST = '/company/ubos/disable';
export const COMPANY_UBOS_ENABLE_POST = '/company/ubos/enable';
export const COMPANY_FIX_CONTRACT_GET = '/company/fixcontract';
export const COMPANY_DOCUMENT_TYPES_GET = '/company/documenttypes';

/* Invoices section */
export const INVOICE_KPIS_GET = '/invoices/kpis';

/* Invoice BUYER section */
export const INVOICE_BUYER_LIST_GET = '/invoices/buyer/list';

/* Invoice BUYER - BUY INVOICE - section */
export const INVOICE_BUYER_LIST_BOUGHT_GET = '/invoices/buyer/list/bought';
export const INVOICE_BUYER_BUY_POST = '/invoices/buyer/buy';
export const INVOICE_BUYER_REFUSE_POST = '/invoices/buyer/refuse';
export const INVOICE_BUYER_TRANSFER_GET = '/invoices/buyer/transfer';
export const INVOICE_BUYER_CONFIRM_POST = '/invoices/buyer/confirm';

/* Invoice SELLER section */
export const INVOICE_SELLER_DETAILS_GET = '/invoices/seller/details';
export const INVOICE_SELLER_NOTES_GET = '/invoices/seller/notes';
export const INVOICE_SELLER_LIST_GET = '/invoices/seller/list';
export const INVOICE_SELLER_LIST_OPEN_GET = '/invoices/seller/list/open';

/* Invoice SELLER - SELL INVOICE - section */
export const INVOICE_SELLER_STATUS_GET = '/invoices/seller/status';
export const INVOICE_SELLER_INFO_GET = '/invoices/seller/info';
export const INVOICE_CONFIRMING_INFO_GET = '/invoices/confirming/info';
export const INVOICE_SELLER_DRAFT_GET = '/invoices/seller/draft';
export const INVOICE_CONFIRMING_DRAFT_GET = '/invoices/confirming/draft';
export const INVOICE_SELLER_INSERT_POST = '/invoices/seller/insert';
export const INVOICE_CONFIRMING_INSERT_POST = '/invoices/confirming/insert';
export const INVOICE_SELLER_UPDATE_POST = '/invoices/seller/update';
export const INVOICE_CONFIRMING_UPDATE_POST = '/invoices/confirming/update';
export const INVOICE_SELLER_CONTACT_PERSONS_GET = '/invoices/seller/contacts';
export const INVOICE_SELLER_CONTACT_PERSONS_POST = '/invoices/seller/contacts';
export const INVOICE_SELLER_DOCUMENTS_GET = '/invoices/seller/documents';
export const INVOICE_SELLER_DOCUMENT_POST = '/invoices/seller/document';
export const INVOICE_SELLER_DOCUMENT_TOC_POST = '/invoices/seller/document/toc';
export const INVOICE_SELLER_DOCUMENT_DELETE_POST = '/invoices/seller/document/delete';
export const INVOICE_SELLER_PROPOSAL_GET = '/invoices/seller/proposal';
export const INVOICE_SELLER_FLEX_SUBMIT_POST = '/invoices/seller/submit/flex';
export const INVOICE_SELLER_FIX_SUBMIT_POST = '/invoices/seller/submit/fix';
export const INVOICE_SELLER_CANCEL_POST = '/invoices/seller/cancel';
export const INVOICE_SELLER_SUMMARY_GET = '/invoices/seller/summary';
export const INVOICE_SELLER_TOPAY_GET = '/invoices/seller/topay';

/* Invoice SELLER - PAYMENT - section */
export const INVOICE_SELLER_REQUEST_PAY_FEE = '/invoices/seller/requestfeepayment';

/* Notification section */
export const NOTIFICATIONS_GET = '/notifications';


/* Dashboard */
export const DASHBOARD_BLOGS_GET = '/dashboard/blogs';
export const DASHBOARD_NOTIFICATIONS_GET = '/dashboard/notifications';
export const DASHBOARD_INFORM_ADMIN_POST = '/dashboard/notifications/informadmin';

/* Subscription section */
export const SIGNUP_COMPANY = '/register/company';
export const SIGNUP_USER = '/register/user';
export const SIGNUP_SEND_VERIFICATION_EMAIL = '/register/sendemailverificationcode';
export const SIGNUP_CONFIRM_EMAIL = '/register/confirmemail';
export const SIGNUP_SET_PASSWORD = '/register/setpassword';
export const SIGNUP_CHANGE_EMAIL = '/register/changeemail';

export const SIGNUP_GET_COMPANY_SUBSCRIPTION_INFO = '/subscribe/company';
export const SIGNUP_GET_COMPANY_INFO = '/subscribe/companyInfo';
export const SIGNUP_SET_COMPANY_INFO = '/subscribe/companyInfo';
export const SIGNUP_SET_COMPANY_INFO_FOR_ISO = '/subscribe/companyInfoforISO';
export const SIGNUP_SET_COMPANY_BANK_ACCOUNT = '/subscribe/bankaccounts';
export const SIGNUP_GET_PERSON_INFO = '/subscribe/personInfo';
export const SIGNUP_SET_PERSON_INFO = '/subscribe/personInfo';
export const SIGNUP_SEND_DOCUMENT = '/subscribe/documents';
export const SIGNUP_GET_DOCUMENTS = '/subscribe/documents';
export const SIGNUP_GET_INVESTMENT_PROFILE = '/subscribe/investment';
export const SIGNUP_SET_INVESTMENT_PROFILE = '/subscribe/investment';
export const SIGNUP_SET_PERSON_LEGAL_INFO = '/subscribe/personLegalInfo';
export const SIGNUP_SIGN_TOS_BEGIN_POST = '/universign/tos';
export const SIGNUP_SIGN_TOS_STATUS_GET = '/universign/tos';
export const SIGNUP_PROCURATION_POST = '/subscribe/procuration';
export const SIGNUP_REQUEST_PRESENTATION = '/subscribe/presentation';
export const SIGNUP_FINALIZE = '/subscribe/finalize';
export const SIGNUP_ISO_NO_COMPANY = '/subscribe/isonocompany'
export const SIGNUP_INCOMPLETE_ALERT = '/subscribe/incompletealert'

/* Payment section */
export const PAYMENT_RESULTS_GET = '/payment';
export const PAYMENT_INVOICE_FEES_GET = '/payment/invoicefees';

/* ISO */
export const ISO_COMMISSIONS_GET = '/iso/commissions';
export const ISO_COMMISSIONS_POST = '/iso/commissions';

export const ISO_COMPANIES_GET = '/iso/companies';

export const ISO_COUPONS_GET = '/iso/coupons';
export const ISO_COUPONS_CREATE = '/iso/coupons/create';

export const ISO_DASHBOARD_KPIS_GET = '/iso/kpis';
export const ISO_DASHBOARD_PROFILE_OVERVIEW_GET = 'iso/profileoverview';
export const ISO_DASHBOARD_PROFILE_SUMMARY_GET = 'iso/profilesummary';

export const ISO_MANDATES_GET = '/iso/mandates';
export const ISO_MANDATES_AVAILABLE_SELLERS_GET = '/iso/mandates/sellercompanies';
export const ISO_MANDATES_CHECK_GET = '/iso/mandates/check';
export const ISO_MANDATES_CREATE = '/iso/mandates/create';
export const ISO_MANDATES_UPDATE = '/iso/mandates/update';
export const ISO_MANDATES_DOWNLOAD = '/iso/mandates/download';
export const ISO_MANDATES_UPLOAD = '/iso/mandates/upload';

export const ISO_OFFICES_GET = '/iso/offices';
export const ISO_OFFICES_ADD_POST = '/iso/offices';
export const ISO_OFFICES_UPDATE_POST = '/iso/offices/update';
export const ISO_OFFICES_DEACTIVATE_POST = '/iso/offices/deactivate';
export const ISO_OFFICES_ACTIVATE_POST = '/iso/offices/activate';
export const ISO_OFFICES_PERSONS_GET = '/iso/offices/persons';
export const ISO_OFFICES_PERSONS_ADD_POST = '/iso/offices/persons';
export const ISO_OFFICES_PERSONS_UPDATE_POST = '/iso/offices/persons/update';
export const ISO_OFFICES_PERSONS_LINK_POST = '/iso/offices/persons/link';

export const ISO_REGISTER_LEAD_POST = '/iso/register/leads';
export const ISO_REGISTER_LEAD_BUSINESS_OPPORTUNITY_POST = '/iso/register/leads/businessOpportunities';
export const ISO_REGISTER_LEAD_BUSINESS_POTENTIAL_POST = '/iso/register/leads/businessPotentials';
export const ISO_REGISTER_LEAD_DOCUMENT_POST = '/iso/register/leads/documents';
export const ISO_REGISTER_LEAD_NOTE_POST = '/iso/register/leads/notes';

export const ISO_REGISTER_SELLER_POST = '/iso/register/sellers';
export const ISO_REGISTER_SELLER_COMPANY_INFO_POST = '/iso/register/sellers/info';
export const ISO_REGISTER_SELLER_DOCUMENTS_GET = '/iso/register/sellers/documents';
export const ISO_REGISTER_SELLER_DOCUMENT_POST = '/iso/register/sellers/documents';
export const ISO_REGISTER_SELLER_USER_GET = '/iso/register/sellers/users';
export const ISO_REGISTER_SELLER_USER_POST = '/iso/register/sellers/users';

export const ISO_REPORTS_COMMISSIONS_GET = '/iso/reports/commissions';
export const ISO_REPORTS_COMMISSIONS_EXPORT_GET = '/iso/reports/commissions/export';
export const ISO_REPORTS_INVOICES_GET = '/iso/reports/invoices';
export const ISO_REPORTS_INVOICES_EXPORT_GET = '/iso/reports/invoices/export';
export const ISO_REPORTS_LEADS_GET = '/iso/reports/leads';
export const ISO_REPORTS_LEADS_EXPORT_GET = '/iso/reports/leads/export';
export const ISO_REPORTS_LEADS_DETAILS_GET = '/iso/reports/leads/details';
export const ISO_REPORTS_LEADS_SEND_TO_EDEBEX_POST = '/iso/reports/leads/send';
export const ISO_REPORTS_LEADS_REOPEN_POST = '/iso/reports/leads/reopen';
export const ISO_REPORTS_LEADS_SAVE_NOTE_POST = '/iso/reports/leads/note';
export const ISO_REPORTS_LEADS_CHANGE_OFFICE_POST = '/iso/reports/leads/isooffice';
export const ISO_REPORTS_LEADS_CHANGE_PERSON_POST = '/iso/reports/leads/isoperson';
export const ISO_REPORTS_LEADS_UPDATE_CONTACT_POST = '/iso/reports/leads/updatecontact';
export const ISO_REPORTS_LEADS_EXTENDS_LIFETIME_POST = '/iso/reports/leads/extends';
export const ISO_REPORTS_LEADS_CLOSE_POST = '/iso/reports/leads/close';
export const ISO_REPORTS_SELLERS_GET = '/iso/reports/sellers';
export const ISO_REPORTS_SELLERS_EXPORT_GET = '/iso/reports/sellers/export';

/* Documents Section */
export const DOCUMENTS_GET = '/documents';
export const DOCUMENTS_BILLS_GET = '/documents/bills';
export const DOCUMENTS_BILLS_LIST_GET = '/documents/bills/list';
export const DOCUMENTS_COMMERCIAL_GET = '/documents/commercial';
export const DOCUMENTS_COMMERCIAL_LIST_GET = '/documents/commercial/list';
export const DOCUMENTS_ISO_COMMERCIAL_LIST_GET = '/documents/commercial/list/iso';
export const DOCUMENTS_COMPANY_GET = '/documents/company';
export const DOCUMENTS_COMPANY_POST = '/documents/company';
export const DOCUMENTS_SUPPLIER_POST = '/documents/supplier';
export const DOCUMENTS_COMPANY_LIST_GET = '/documents/company/list';
export const DOCUMENTS_COMPANY_PORTFOLIO_EXPORT_GET = '/documents/company/portfolioexport';
export const DOCUMENTS_NOT_EXPIRED_AND_NOT_REPLACED_GET = '/documents/personnotexpiredandnotreplaced';
export const DOCUMENTS_INVOICES_GET = '/documents/invoices';
export const DOCUMENTS_INVOICES_LIST_GET = '/documents/invoices/list';
export const DOCUMENTS_PERSON_POST = '/documents/person';
export const DOCUMENTS_SIGN_POST = '/universign/document';

/* Confirming Section */
export const CONFIRMING_PROFILE_SUMMARY_GET = '/confirming/profilesummary';
export const CONFIRMING_SUPPLIERS_GET = '/confirming/suppliers';
export const CONFIRMING_SUPPLIER_DETAILS_GET = '/confirming/suppliers/details';
export const CONFIRMING_SUPPLIER_BANKACCOUNT_POST = '/confirming/suppliers/bankaccount';
export const CONFIRMING_SUPPLIER_BANKACCOUNT_GET = '/confirming/suppliers/bankaccounts';
export const CONFIRMING_SUPPLIER_LINK_BANKACCOUNT_POST = '/confirming/suppliers/linkbankaccount';
export const CONFIRMING_SUPPLIERS_POST = '/confirming/suppliers';
export const CONFIRMING_INVOICES_PORTFOLIO_GET = '/confirming/invoices/portfolio';
export const CONFIRMING_INVOICES_PORTFOLIO_EXPORT = '/confirming/invoices/portfolio/export';
export const CONFIRMING_INVOICES_DETAILS_GET = '/confirming/invoices/details';
export const CONFIRMING_INVOICES_DETAILS_DOCUMENTS_GET = '/confirming/invoices/details/documents';
export const CONFIRMING_OPEN_INVOICES_GET = '/confirming/openinvoices';

/* Debtor Section */
export const DEBTOR_KPIS_GET = '/debtor/kpis';
export const DEBTOR_PORTFOLIO_EXPORT_GET = '/debtor/invoices/portfolio/export';
export const DEBTOR_PROFILE_SUMMARY_GET = '/debtor/profilesummary';
export const DEBTOR_INVOICES_GET = '/debtor/invoices';
export const DEBTOR_INVOICES_APPROVE_GET = '/debtor/invoices/approve';
export const DEBTOR_INVOICES_APPROVE_POST = '/debtor/invoices/approve';
export const DEBTOR_INVOICES_DETAILS_GET = '/debtor/invoices/details';
export const DEBTOR_INVOICES_DETAILS_DOCUMENTS_GET = '/debtor/invoices/details/documents';
export const DEBTOR_INVOICES_OPEN_GET = '/debtor/invoices/open';
export const DEBTOR_INVOICES_PAY_GET = '/debtor/invoices/pay';
export const DEBTOR_INVOICES_PAY_POST = '/debtor/invoices/pay';
export const DEBTOR_INVOICES_PORTFOLIO_GET = '/debtor/invoices/portfolio';
export const DEBTOR_INVOICES_REFUSE_POST = '/debtor/invoices/refuse';
export const DEBTOR_OFFICES_GET = '/debtor/offices';
export const DEBTOR_OFFICES_ADD_POST = '/debtor/offices';
export const DEBTOR_OFFICES_UPDATE_POST = '/debtor/offices/update';
export const DEBTOR_OFFICES_PERSONS_GET = '/debtor/offices/persons';
export const DEBTOR_OFFICES_PERSONS_ADD_POST = '/debtor/offices/persons';
export const DEBTOR_OFFICES_PERSONS_UPDATE_POST = '/debtor/offices/persons/update';
export const DEBTOR_OFFICES_PERSONS_LINK_POST = '/debtor/offices/persons/link';
export const DEBTOR_REGISTER_LEAD_POST = '/debtor/lead';
export const DEBTOR_REGISTER_LEAD_SET_FINANCIAL_INFO_POST = '/debtor/lead/financial';
export const DEBTOR_REGISTER_SUPPLIER_POST = '/debtor/supplier';
export const DEBTOR_SUPPLIER_ACCOUNT_TO_EDEBEX = '/debtor/supplier/bankaccountstochangetoedebex/';
export const DEBTOR_SUPPLIER_ACCOUNT_FROM_EDEBEX = '/debtor/supplier/bankaccountstochangefromedebex/';
export const DEBTOR_SUPPLIER_CHANGE_ACCOUNT = '/debtor/supplier/changebankaccount';
export const DEBTOR_TOKEN_POST = '/debtor/token';
export const DEBTOR_TOKEN_INVOICES_GET = '/debtor/token/invoices';
export const DEBTOR_TOKEN_INVOICES_APPROVE_POST = '/debtor/token/invoices/approve';
export const DEBTOR_TOKEN_INVOICES_DISPUTE_POST = '/debtor/token/invoices/dispute';
export const DEBTOR_TOKEN_INVOICES_DOCUMENTS_GET = '/debtor/token/invoices/documents';
export const DEBTOR_TOKEN_INVOICES_DOCUMENTS_RELATED_GET = '/debtor/token/invoices/documents/related';
export const DEBTOR_TOKEN_INVOICES_REFUSE_POST = '/debtor/token/invoices/refuse';
export const DEBTOR_APPROVAL_SIGN = '/universign/debtorapproval';
