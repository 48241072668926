/* @flow */

import React from 'react';

import AddCompanyComponent from 'app/companies/addCompany';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function AddCompanyPage() {
  return (
    <WebsitePageComponent id="page.addcompany.h1" data-walkme="addacompany-title-addacompany">
      <AddCompanyComponent />
    </WebsitePageComponent>
  );
}
