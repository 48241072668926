/* @flow */

import React from 'react';
import { useHistory } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import RegisterReferralResponse from 'models/responses/sellers/registerReferralResponse';

import { PROTECTED_SELLER_DASHBOARD } from 'constants/pageRoutes';

type ReferralTab3ComponentProps = {
  response: RegisterReferralResponse,
  restartHandler: () => void
}

export default function ReferralTab3Component(props: ReferralTab3ComponentProps) {
  const history = useHistory();

  return (
    <React.Fragment>
      {props.response.referralStatus === RegisterReferralResponse.REFERRAL_STATUS_OK &&
        <React.Fragment>
          <PageSubTitleViewComponent id="seller.referral.tab3.success.title" />
          <Typography variant="body1"><IntlMessageComponent id="seller.referral.tab3.success.text" /></Typography>
        </React.Fragment>
      }

      {props.response.referralStatus === RegisterReferralResponse.REFERRAL_STATUS_NOT_REFERABLE &&
        <React.Fragment>
          <PageSubTitleViewComponent id="seller.referral.tab3.notreferable.title" />
          <Typography variant="body1"><IntlMessageComponent id="seller.referral.tab3.notreferable.text" /></Typography>
        </React.Fragment>
      }

      {props.response.referralStatus === RegisterReferralResponse.REFERRAL_STATUS_ALREADY_REFERRED &&
        <React.Fragment>
          <PageSubTitleViewComponent id="seller.referral.tab3.alreadyreferred.title" />
          <Typography variant="body1"><IntlMessageComponent id="seller.referral.tab3.alreadyreferred.text" /></Typography>
        </React.Fragment>
      }

      {props.response.referralStatus === RegisterReferralResponse.REFERRAL_STATUS_ALREADY_USED_EMAIL &&
        <React.Fragment>
          <PageSubTitleViewComponent id="seller.referral.tab3.alereadyusedemail.title" />
          <Typography variant="body1"><IntlMessageComponent id="seller.referral.tab3.alereadyusedemail.text" /></Typography>
        </React.Fragment>
      }

      <Box mt={5}>
        <ButtonComponent
          color="default"
          variant="contained"
          onClick={props.restartHandler}
        ><IntlMessageComponent id="seller.referral.tab3.btn.restart" /></ButtonComponent>

        <Box component="span" ml={1}>
          <ButtonComponent
            color="primary"
            variant="contained"
            onClick={() => history.push(PROTECTED_SELLER_DASHBOARD)}
          ><IntlMessageComponent id="seller.referral.tab3.btn.dashboard" /></ButtonComponent>
        </Box>
      </Box>
    </React.Fragment>
  );
}
