/* @flow */

import React from 'react';

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

import Box from '@material-ui/core/Box';

import CardComponent from './card';

import ProfileSummaryCard from 'models/dashboards/profileSummaryCard';

type CarouselViewComponentProps = {
  cards: ProfileSummaryCard;
  walkme: string;
  customize: (cardType: number) => {};
}

export default function CarouselViewComponent(props: CarouselViewComponentProps) {
  const items = [];
  props.cards.forEach(card => items.push(<CardComponent card={card} customize={props.customize} key={`card-${card.type}`} />));

  const breakpoints={
    0: {items: 1},
    680: {items: 2},
    1000: {items: 3},
    1200: {items: 4},
    1400: {items: 5}
  };

  return (
    <Box mt={5} mb={5}>
      <AliceCarousel disableButtonsControls items={items} mouseTracking responsive={breakpoints} />
    </Box>
  );
}