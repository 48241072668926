/* @flow */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Popover from '@material-ui/core/Popover';

import IntlMessageComponent from 'components/formatters/intlMessage';

import WebsiteHeaderInvestmentProfileMenuListItemComponent from './item';

import InvestmentProfile from 'models/buyers/investmentProfiles/investmentProfile';
import TooltipComponent from 'components/toolTips/index';

import { userSwitchCompanyInvestmentProfileAction } from 'store/actions/authActions';

const useStyles = makeStyles(theme => ({
  button: {
    textTransform: 'none'
  }
}));

export default function WebsiteHeaderInvestmentProfileMenuComponent() {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const dispatch = useDispatch();
  const classes = useStyles();

  const user = useSelector(state => state.auth.user);
  if (!user) return null;

  const flipOpen = () => setOpen(prevOpen => !prevOpen);

  const toggleIp = (investmentProfile: InvestmentProfile) => {
    flipOpen();
    dispatch(userSwitchCompanyInvestmentProfileAction(investmentProfile));
  };

  const ipAll = new InvestmentProfile();
  ipAll.id = 0;
  ipAll.name = <b><IntlMessageComponent id="header.investmentProfile.all" /></b>;
  ipAll.shortName = <IntlMessageComponent id="header.investmentProfile.all.short" />;

  const ipname = typeof user.activeCompany.investmentProfile.name === 'string' || user.activeCompany.investmentProfile.name instanceof String  ? user.activeCompany.investmentProfile.name?.substring(0, 2)?.toUpperCase() : ipAll.shortName;

  return (
    <React.Fragment>
      <div pageName="Tooltip.Header" elementName="InvestmentProfile">
        <Button
          ref={anchorRef}
          className={classes.button}
          onClick={flipOpen}
        >
          <Box textAlign="left">
            <Box color="primary.main"><b><em>{ipname}</em></b></Box>
          </Box>
          <Box color="primary.main" ml={1}>
            <ArrowDropDownIcon fontSize="small" />
          </Box>
        </Button>

        <Popover
          id='language-popup'
          open={open}
          anchorEl={anchorRef.current}
          onClose={flipOpen}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        >
          <List component="nav" dense>
            <WebsiteHeaderInvestmentProfileMenuListItemComponent
              isActive={ipAll.id === user.activeCompany.investmentProfile.id}
              item={ipAll}
              shortName={ipAll.shortName}
              toggleIp={toggleIp}
            />

            {user.activeCompany.roleBuyer.investmentProfiles.map(item => (
              <WebsiteHeaderInvestmentProfileMenuListItemComponent
                key={`ip-${item.id}`}
                isActive={item.id === user.activeCompany.investmentProfile.id}
                item={item}
                shortName={item.name.substring(0, 2).toUpperCase()}
                toggleIp={toggleIp}
              />
            ))}
          </List>
        </Popover>
      </div>
    </React.Fragment>
  );
}
