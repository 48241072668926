/* @flow */

import CommissionSummaryIsoOffice from './commissionSummaryIsoOffice';

export default class CommissionSummaryIsoCompany {
  id: number;
  name: string;
  offices: CommissionSummaryIsoOffice[];
  financedInvoiceCommission: number;
  unfinancedInvoiceCommission: number;
  msfCommission: number;
  membershipCommission: number;
  totalCommission: number;

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
    this.offices = [];
    this.financedInvoiceCommission = 0;
    this.unfinancedInvoiceCommission = 0;
    this.msfCommission = 0;
    this.membershipCommission = 0;
    this.totalCommission = 0;
  }
}
