/* This component purpose is to make sure we keep the user signed in whenever he comes back on the site (mainly pressing F5)
 * The component will trigger the saga action userSignInReturningAction only once
 * (all the stuff with useEffect is important for React-reasons but in the end the action is the only thing that matters)
 * The action will check if there is a token in the storage and reload the user, if not it won't do anything
 * 
 * initialLoginTestDone is used to block the rendering of children components, since userSignInReturningAction is called async
 */

import React, {useCallback, useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';

import {userSignInReturningAction} from 'store/actions/authActions';

import {PROTECTED_APP} from 'constants/pageRoutes';

export default function AuthenticationReturnComponent(props) {
  const dispatch = useDispatch();
  const location = useLocation();

  const returnUrl = location.pathname || PROTECTED_APP;

  // Get everything we need from the redux store
  const auth = useSelector(state => state.auth, shallowEqual);
  
  const tryLogin = () => {
    if (auth.user === null) {
      dispatch(userSignInReturningAction(returnUrl));
    }
  };

  const stableTryLogin = useCallback(tryLogin, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    stableTryLogin();
  }, [stableTryLogin]);

  if (!auth.initialLoginTestDone) return null;
  return (<React.Fragment>{props.children}</React.Fragment>)
}
