/* @flow */

import Company from 'models/companies/company';
import CreateLeadRequest from 'models/requests/leads/createLeadRequest';
import IncompleteAlertRequest from 'models/requests/register/incompleteAlertRequest';

export default class LeadForm {
  company: Company;
  firstName: string;
  lastName: string;
  mobile: string;
  mobilePresence: string;
  email: string;
  titleId: number;
  isoCompanyId: number;

  constructor() {
    this.company = null;
    this.firstName = '';
    this.lastName = '';
    this.mobile = '';
    this.mobilePresence = '';
    this.email = '';
    this.titleId=0;
    this.isoCompanyId=0;
  }

  toRequest(languageId: number){
    const request = new CreateLeadRequest();
    request.insurerId = this.company.insurerId;
    request.isoAndCampaign = null;
    request.firstName = this.firstName;
    request.lastName = this.lastName;
    request.email = this.email;
    request.mobile = this.mobilePresence.length > 1 ? this.mobilePresence : this.mobile;
    request.languageId = languageId;
    request.titleId= this.titleId;
    return request;
  }

  toIncompleteAlertRequest(languageId: number, flow: string) {
    const request = new IncompleteAlertRequest();
    request.companyType = 1;
    request.insurerId = this.company?.insurerId;
    request.companyName = this.company?.name;
    request.countryId = this.company?.countryId;
    request.postalCode = this.company?.zipCode;
    request.city = this.company?.city;
    request.phone = this.company?.phone;
    request.vatNumber = this.company?.vatNumber;
    
    request.firstName = this.firstName;
    request.lastName = this.lastName;
    request.email = this.email;
    request.mobile = this.mobilePresence?.length > 1 ? this.mobilePresence : this.mobile;
    request.languageId = languageId;
    request.functionName = this.functionName;
    request.titleId= this.titleId;
    
    request.requestFlow = flow;
    
    return request;
  }
}
