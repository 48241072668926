/* @flow */

import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Avatar from '@material-ui/core/Avatar';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {makeStyles} from '@material-ui/core/styles';

import IntlMessageComponent from 'components/formatters/intlMessage';

import {userSwitchCompanyAction} from 'store/actions/authActions';

const useStyles = makeStyles(theme => ({
  avatar: {
    backgroundColor: theme.palette.blue.main,
    color: theme.palette.blue.contrastText,
    fontSize: '0.8rem',
    height: theme.spacing(3),
    width: theme.spacing(3)
  },
  itemIcon: {
    minWidth: '35px'
  }
}));

export default function WebsiteHeaderCompanyMenuListComponent(props) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();

  const user = useSelector(state => state.auth.user);
  if (!user) return null;

  const flipOpen = () => setOpen(!open);

  const toggleActiveCompany = (compnayId) => {
    flipOpen();
    props.flipOpen();
    dispatch(userSwitchCompanyAction(compnayId));
  };

  const label = (
    <span>
      <IntlMessageComponent id="header.company.active"/>&nbsp;
      <em><b>{user.activeCompany.name}</b></em>
    </span>
  );

  return (
    <List component="nav" dense>
      <ListItem button onClick={flipOpen}>
        <ListItemText primary={label} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" dense disablePadding>
          {user.companies.map(company => (
            <React.Fragment key={`company-${company.id}`}>
              {user.activeCompany.id === company.id ? (
                <ListItem>
                  <ListItemIcon className={classes.itemIcon}>
                    <Avatar className={classes.avatar}>{company.name.substring(0, 2).toUpperCase()}</Avatar>
                  </ListItemIcon>
                  <ListItemText primary={company.name} />
                </ListItem>
              ) : (
                <ListItem button onClick={() => toggleActiveCompany(company.id)}>
                  <ListItemIcon className={classes.itemIcon}>
                    <Avatar className={classes.avatar}>{company.name.substring(0, 2).toUpperCase()}</Avatar>
                  </ListItemIcon>
                  <ListItemText primary={company.name} />
                </ListItem>
              )}
            </React.Fragment>

          ))}
        </List>
      </Collapse>
    </List>
  );
}
