/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import {withCookies, Cookies} from 'react-cookie';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import TagManager from 'react-gtm-module';

import LoadingComponent from 'components/loading';

import ContactSuccessViewComponent from './successView';
import ContactViewComponent from './view';
import ContactForm from './model';

import CompanyLookupForm from 'models/companies/companyLookupForm';

import LeadService from 'services/LeadService';
import {getISOAndCampaignRequest} from 'lib/marketing';
import {handleApiFormResponse, handleFormChange, handleFormValidation} from 'lib/forms';
import loadConstraints from 'lib/validation/loadConstraints';
import {validateField} from 'lib/validation/validator';

type ContactComponentProps = {
  cookies: Cookies;
  languageId: number;
}

type ContactComponentState = {
  constraints: any;
  errors: Map<string,?string>;
  form: ContactForm;
  formErrors: string[];
  isLoading: boolean;
  isProcessing: boolean;
  companyNotFound: boolean;
  companyLookUpForm: CompanyLookupForm;
  sentWithSuccess: boolean;
}

class ContactComponent extends React.Component<ContactComponentProps, ContactComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      constraints: null,
      errors: null,
      form: new ContactForm(),
      formErrors: [],
      isLoading: true,
      isProcessing: false,
      companyLookUpForm: new CompanyLookupForm()
    };
  }

  componentDidMount = async () => {
    try {
      // load constraints
      const constraints = await loadConstraints('Person', ['titleId','lastName', 'firstName', 'mobilePresence', 'email', 'company']);

      // Retrieve and validate parameters from querystring
      const form = this.state.form;
      const errors = new Map(Object.keys(constraints).map(e => [e, undefined]));
      const queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
      if (queryParams.lastname !== undefined) {
        const value = queryParams.lastname;
        const validationResult = validateField(value, constraints.lastName);
        errors.set('lastName', validationResult ? validationResult.pop() : null);
        form.lastName = value;
      }
      if (queryParams.firstname !== undefined) {
        const value = queryParams.firstname;
        const validationResult = validateField(value, constraints.firstName);
        errors.set('firstName', validationResult ? validationResult.pop() : null);
        form.firstName = value;
      }
      if (queryParams.email !== undefined) {
        const value = queryParams.email;
        const validationResult = validateField(value, constraints.email);
        errors.set('email', validationResult ? validationResult.pop() : null);
        form.email = value;
      }
      if (queryParams.mobile !== undefined) {
        const value = queryParams.mobile;
        const validationResult = validateField(value, constraints.mobile);
        errors.set('mobilePresence', validationResult ? validationResult.pop() : null);
        form.mobile = value;
      }

      this.setState({ constraints, errors, form, isLoading: false });
    } catch (e) {
      console.error(e);
    }
  };

  companyNotFound = (value:boolean) => {
    this.setState({companyNotFound: value});
  }

  handleChange = (fieldName: string) => (event) => handleFormChange.call(this, fieldName, event.target.value);  
  handleTypeChange = (fieldName: string) => (id: number) => handleFormChange.call(this, fieldName, id);
  validateForm = () => handleFormValidation.call(this);

  handleCompanyChange = (company: ?Company) => {
    const form = this.state.form;
    form.company = company;

    const errors = this.state.errors;
    errors.delete('company');

    this.setState({ errors, form });
  };
  handleMobileChange = (value: string) => {
    handleFormChange.call(this, 'mobilePresence', value);
  }

  submitForm = async () => {
    console.log(this.state.form)
    if (!this.validateForm())
        return;
    this.setState({ formErrors: [], isProcessing: true });

    try {
      // create lead
      const request = this.state.form.toRequest(this.props.languageId);
      request.isoAndCampaign = getISOAndCampaignRequest(this.props.cookies);

      await LeadService.createLead(request);
      TagManager.dataLayer({ dataLayer: { event: 'contactFormCompleted', formType: 'Contact' } });
      this.setState({ isProcessing: false, sentWithSuccess: true });
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }
  }

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    if (this.state.sentWithSuccess) {
      return (<ContactSuccessViewComponent />)
    }

    return (
      <ContactViewComponent
        errors={this.state.errors}
        form={this.state.form}
        companyLookUpForm={this.state.companyLookUpForm}
        formErrors={this.state.formErrors}
        isProcessing={this.state.isProcessing}
        handleChange={this.handleChange}
        handleTypeChange={this.handleTypeChange}
        handleCompanyChange={this.handleCompanyChange}
        handleMobileChange={this.handleMobileChange}
        companyNotFound={this.companyNotFound}
        contactTimeSelected={this.state.form.contactTime === ''}
        submitForm={this.submitForm}
      />
    );
  }
}

const mapStateToProps = state => ({
  languageId: state.locale.languageId
});

export default withRouter(connect(mapStateToProps)(withCookies(ContactComponent)));
