/* @flow */

export default class SetMainCompanyBankAccountRequest {
  companyId: number;
  bankAccountId: number;

  constructor(companyId: number, bankAccountId: number) {
    this.companyId = companyId;
    this.bankAccountId = bankAccountId;
  }
}
