/* @flow */

import React from 'react';
import {Route, Switch, useRouteMatch} from 'react-router-dom';

import Error404Page from 'components/errors/error404';

import TermsAndConditionsBeginComponent from './begin';
import TermsAndConditionsCancelComponent from './cancel';
import TermsAndConditionsFailureComponent from './failure';
import TermsAndConditionsSuccessComponent from './success';

export default function TermsAndConditionsPage() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.url}/`} component={TermsAndConditionsBeginComponent} />
      <Route exact path={`${match.url}/cancel`} component={TermsAndConditionsCancelComponent} />
      <Route exact path={`${match.url}/failure`} component={TermsAndConditionsFailureComponent} />
      <Route exact path={`${match.url}/success`} component={TermsAndConditionsSuccessComponent} />
      <Route component={Error404Page} />
    </Switch>
  );
}