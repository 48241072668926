/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';

import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import CallbackForm from './model';

type CallbackViewComponentProps = {
  form: CallbackForm;
  isProcessing: boolean;
  handleHourChange: (event: SyntheticInputEvent<HTMLInputElement>) => void;
  handleDayChange: (event: SyntheticInputEvent<HTMLInputElement>) => void;
  submitForm: () => void;
}

export default function CallbackViewComponent(props: CallbackViewComponentProps) {
  const mondaySelected = props.form.day.find(v => v === 'Monday') !== undefined;
  const tuesdaySelected = props.form.day.find(v => v === 'Tuesday') !== undefined;
  const wednesdaySelected = props.form.day.find(v => v === 'Wednesday') !== undefined;
  const thursdaySelected = props.form.day.find(v => v === 'Thursday') !== undefined;
  const fridaySelected = props.form.day.find(v => v === 'Friday') !== undefined;

  const nineSelected = props.form.hour.find(v => v === '9 to 11') !== undefined;
  const elevenSelected = props.form.hour.find(v => v === '11 to 13') !== undefined;
  const thirteenSelected = props.form.hour.find(v => v === '13 to 15') !== undefined;
  const fifteenSelected = props.form.hour.find(v => v === '15 to 17') !== undefined;
  const seventeenSelected = props.form.hour.find(v => v === '17 to 18') !== undefined;

  return (
    <React.Fragment>
      <IntlMessageComponent
        id="page.callback.explanation"
      />
      <Box mt={6}>
        <div style={{
          width: "min(250px, 60vw)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}>
          <img src="./images/callback/calendar.png" alt="calendar" style={{ height: 120 }} />
        </div>
      </Box>
      <Box mt={2}>
        <PageSubTitleViewComponent
          id="page.callback.dayoftheweek"
        />
      </Box>
      <Box mt={2}>
        <FormControl component="fieldset" fullWidth required>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={mondaySelected} onChange={props.handleDayChange} name="Monday" />}
              label={<IntlMessageComponent id="component.day.monday" />}
            />
            <FormControlLabel
              control={<Checkbox checked={tuesdaySelected} onChange={props.handleDayChange} name="Tuesday" />}
              label={<IntlMessageComponent id="component.day.tuesday" />}
            />
            <FormControlLabel
              control={<Checkbox checked={wednesdaySelected} onChange={props.handleDayChange} name="Wednesday" />}
              label={<IntlMessageComponent id="component.day.wednesday" />}
            />
            <FormControlLabel
              control={<Checkbox checked={thursdaySelected} onChange={props.handleDayChange} name="Thursday" />}
              label={<IntlMessageComponent id="component.day.thursday" />}
            />
            <FormControlLabel
              control={<Checkbox checked={fridaySelected} onChange={props.handleDayChange} name="Friday" />}
              label={<IntlMessageComponent id="component.day.friday" />}
            />
          </FormGroup>
        </FormControl>
      </Box>
      <Box mt={5}>
        <Box>
          <div style={{
            width: "min(250px, 60vw)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}>
            <img src="./images/callback/clock.png" alt="clock" style={{ height: 120 }} />
          </div>
        </Box>

        <Box mt={2}>
          <PageSubTitleViewComponent
            id="page.callback.time"
          />
        </Box>
        <Box mt={2}>
          <FormControl component="fieldset" fullWidth required>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={nineSelected} onChange={props.handleHourChange} name="9 to 11" />}
                label={<IntlMessageComponent id="component.hour.9to11" />}
              />
              <FormControlLabel
                control={<Checkbox checked={elevenSelected} onChange={props.handleHourChange} name="11 to 13" />}
                label={<IntlMessageComponent id="component.hour.11to13" />}
              />
              <FormControlLabel
                control={<Checkbox checked={thirteenSelected} onChange={props.handleHourChange} name="13 to 15" />}
                label={<IntlMessageComponent id="component.hour.13to15" />}
              />
              <FormControlLabel
                control={<Checkbox checked={fifteenSelected} onChange={props.handleHourChange} name="15 to 17" />}
                label={<IntlMessageComponent id="component.hour.15to17" />}
              />
              <FormControlLabel
                control={<Checkbox checked={seventeenSelected} onChange={props.handleHourChange} name="17 to 18" />}
                label={<IntlMessageComponent id="component.hour.17to18" />}
              />
            </FormGroup>
          </FormControl>
        </Box>
      </Box>
      <Box mt={5}>
        <ButtonComponent
          color="primary"
          variant="contained"
          isProcessing={props.isProcessing}
          onClick={props.submitForm}
          disabled={props.form.day.length <= 0 || props.form.hour.length <= 0}
        ><IntlMessageComponent id="general.button.submit.label" /></ButtonComponent>

      </Box>
    </React.Fragment >
  );
}