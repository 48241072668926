import {Api, ApiToken} from './api';
import { ApiError } from './apiErrors';

export default class BaseService {
  static anonymousGET = async (route: string, transformationFunction: any) => {
    const apiService = new Api();
    const response = await apiService.get(route, {});
    if (transformationFunction)
      return transformationFunction(response.data);
    return response.data;
  }

  static simpleGET = async (route: string, transformationFunction: any) => {
    const apiService = new ApiToken();
    const response = await apiService.get(route, {});
    if (transformationFunction)
      return transformationFunction(response.data);
    return response.data;
  }

  static signInPOST = async (route: string, payload: any) => {
    const apiService = new Api();
    const response = await apiService.post(route, payload);
    return response.data;
  }

  static anonymousPOST = async (route: string, request: any, transformationFunction: any) => {
    const apiService = new Api();
    const response = await apiService.post(route, {data: request});
    if (transformationFunction)
      return transformationFunction(response.data);
    return response.data;
  }

  static simplePOST = async (route: string, request: any, transformationFunction: any) => {
    const apiService = new ApiToken();
    const response = await apiService.post(route, {data: request});
    if (transformationFunction)
      return transformationFunction(response.data);
    return response.data;
  }

  static simplePUT = async (route: string, request: any, transformationFunction: any) => {
    const apiService = new ApiToken();
    const response = await apiService.put(route, {data: request});
    if (transformationFunction)
      return transformationFunction(response.data);
    return response.data;
  }

  static simpleDELETE = async (route: string, request: any, transformationFunction: any) => {
    const apiService = new ApiToken();
    const response = await apiService.delete(route, {data: request});
    if (transformationFunction)
      return transformationFunction(response.data);
    return response.data;
  }

  // Download a file and immediately save it in the user device
  static anonymousFileDownloadGET = async (route: string) => {
    const apiService = new Api();
    const response = await apiService.getFile(route);
    BaseService.downloadFile(response);
  }

  // Download a file and immediately save it in the user device
  static simpleFileDownloadGET = async (route: string) => {
    const apiService = new ApiToken();
    const response = await apiService.getFile(route);
    BaseService.downloadFile(response);
  }

  static downloadFile = (response) => {
    if (response.data.size === 12) {
      throw new ApiError('Unable to load document');
    }

    const disposition = response.request.getResponseHeader('Content-Disposition');
    let filename = 'file.pdf';
    if (filename.indexOf('file.') === 0 && disposition && disposition.indexOf('filename') !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }
  
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(new Blob(filename.endsWith('csv') ? ['\ufeff', response.data] : [response.data]));
    link.setAttribute('download', filename);

    if (!document.body) {
      throw new ApiError('Body not defined');
    }
  
    document.body.appendChild(link);
    link.click();
  }

  // Download a file in memory
  static simpleFileURLGET = async (route: string) => {
    const apiService = new ApiToken();
    const response = await apiService.getFile(route);

    if (response.data.size === 12) {
      throw new ApiError('Unable to load document');
    }

    const contentType = response.headers['content-type'];
    const file = new Blob([response.data], {type: contentType});
    return URL.createObjectURL(file);
  }


  static simpleFilePOST = async (route: string, file: File, options: any) => {
    const formData = new FormData();
    formData.append('file', file, file.name);
    Object.entries(options).forEach(option => formData.append(option[0], option[1]));

    const apiService = new ApiToken();
    return await apiService.postFile(route, formData);
  }
}
