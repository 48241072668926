/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import BlogViewComponent from './view';

import BlogEntry from 'models/dashboards/blogEntry';

import DashboardService from 'services/DashboardService';

type BlogComponentProps = {
  activeCompanyId: number;
  activeCompanyRole: number;
}

type BlogComponentState = {
  isLoading: boolean;
  blogs: BlogEntry[];
}

class BlogComponent extends React.Component<BlogComponentProps, BlogComponentState> {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      blogs: null
    };
  }

  componentDidMount = async () => {
    try {
      const blogs = await DashboardService.getBlogEntries(this.props.activeCompanyId, this.props.activeCompanyRole);
      this.setState({blogs, isLoading: false});
    } catch (e) {
      console.error(e);
    }
  }

  render() {
    if (this.state.isLoading || this.state.blogs.length === 0) return null;
    return (<BlogViewComponent blogs={this.state.blogs}/>);
  }
}

const mapStateToProps = ({auth}) => ({
  activeCompanyId: auth.user.activeCompany.id,
  activeCompanyRole: auth.user.activeCompany.role
});

export default connect(mapStateToProps)(BlogComponent);
