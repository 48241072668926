/* @flow */

export default class InvoiceFilters {
  statusKeyClosed: string;
  statusKeys: string[];

  constructor() {
    this.statusKeyClosed = '';
    this.statusKeys = [];
  }
}
