/* @flow */

import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import IconCopy from '@material-ui/icons/FileCopy';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import ComponentWrapperComponent from 'components/websiteStructure/componentWrapper';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

type PrivateKeyComponentProps = {
  privateKey: string;
}

export default function PrivateKeyComponent(props: PrivateKeyComponentProps) {

  const copyToClipboard = () => {
    // Create new element
    const el = document.createElement('textarea');
    // Set value (string to be copied)
    el.value = props.privateKey;
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute('readonly', '');
    el.style = {position: 'absolute', left: '-9999px'};
    document.body.appendChild(el);
    // Select text inside element
    el.select();
    // Copy text to clipboard
    document.execCommand('copy');
    // Remove temporary element
    document.body.removeChild(el);
  }

  return (
    <ComponentWrapperComponent>
      <PageSubTitleViewComponent id="page.profile.privateKey.title" />
      
      <Typography variant="body1">
        <IntlMessageComponent id="page.profile.privateKey.text" />
      </Typography>
      
      <TextField
        id="privateKey"
        label={<IntlMessageComponent id="page.profile.privateKey.label" />}
        value={props.privateKey}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title={<IntlMessageComponent id="general.copy.clipboard" />}>
                <IconButton
                  aria-label="Copy to clipboard"
                  onClick={copyToClipboard}
                ><IconCopy color="primary" /></IconButton>
              </Tooltip>
            </InputAdornment>
          ),
          readOnly: true
        }}
        fullWidth
      />
    </ComponentWrapperComponent>
  );
}
