/* @flow */

import React from 'react';

import {makeStyles} from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles(theme => ({
  avatar: {
    fontSize: '0.8rem',
    height: theme.spacing(3),
    width: theme.spacing(3),
  },
  itemIcon: {
    minWidth: '35px'
  }
}));

export default function WebsiteHeaderInvestmentProfileMenuListItemComponent(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      {props.isActive ? (
        <ListItem>
          <ListItemIcon className={classes.itemIcon}>
            <Avatar className={classes.avatar}>{props.shortName}</Avatar>
          </ListItemIcon>
          <ListItemText primary={props.item.name} />
        </ListItem>
      ) : (
        <ListItem button onClick={() => props.toggleIp(props.item)}>
          <ListItemIcon className={classes.itemIcon}>
            <Avatar className={classes.avatar}>{props.shortName}</Avatar>
          </ListItemIcon>
          <ListItemText primary={props.item.name} />
        </ListItem>
      )}
    </React.Fragment>
  );
}
