/* @flow */

import React from 'react';

import QuestionAnswerOutlined from '@material-ui/icons/QuestionAnswerOutlined';

import CarouselComponent from 'components/caroussel';
import IconCheckOutlined from 'components/icons/outlined/check';
import IconExclamationMarkOutlined from 'components/icons/outlined/exclamationMark';
import IconHand from 'components/icons/hand';
import IconHome from 'components/icons/home';
import IconHourglass from 'components/icons/hourglass';
import IconMoneyAvailable from 'components/icons/moneyAvailable';
import IconMoneyTransfer from 'components/icons/moneyTransfer';
import IconTransferOutlined from 'components/icons/outlined/transfer';
import IconUser from 'components/icons/user';

import ProfileSummaryCard from 'models/dashboards/profileSummaryCard';

import {
  CARD_TYPE_BUYER_TO_PAY,
  CARD_TYPE_BUYER_TO_BUY_EXCLUSIVE,
  CARD_TYPE_BUYER_TO_BUY_MARKETPLACE,
  CARD_TYPE_BUYER_AMOUNT_AVAILABLE,
  CARD_TYPE_BUYER_TRANSFER_IN_PROGRESS,
  CARD_TYPE_BUYER_WAITING,
  CARD_TYPE_BUYER_OVERDUE,
  CARD_TYPE_BUYER_PAID,
  CARD_TYPE_BUYER_CLOSED
} from 'constants/cardTypes';

type BuyerProfileSummaryCarouselViewComponentProps = {
  cards: ProfileSummaryCard[];
}

export default function BuyerProfileSummaryCarouselViewComponent(props: BuyerProfileSummaryCarouselViewComponentProps) {

  const customize = (cardType: number) => {
    switch (cardType) {
      case CARD_TYPE_BUYER_TO_PAY:
        return { color: 'red.main', icon: <IconExclamationMarkOutlined style={{fontSize: '3em'}} />, translationId: 'buyer.ps.finalize', walkme: 'bdashboard-box-montantdisponible'};
      case CARD_TYPE_BUYER_TO_BUY_EXCLUSIVE:
        return { color: 'blue.main', icon: <IconUser style={{fontSize: '3em'}} />, translationId: 'buyer.ps.assignedToYou', walkme: 'bdashboard-box-facturesexclusivite'};
      case CARD_TYPE_BUYER_TO_BUY_MARKETPLACE:
        return { color: 'blue.main', icon: <IconHome style={{fontSize: '3em'}} />, translationId: 'buyer.ps.marketPlace', walkme: 'bdashboard-box-facturesmarche'};
      case CARD_TYPE_BUYER_AMOUNT_AVAILABLE:
        return { color: 'grey.main', icon: <IconMoneyAvailable style={{fontSize: '3em'}} />, translationId: 'buyer.ps.amountAvailable', walkme: 'bdashboard-box-amountavailable'};
      case CARD_TYPE_BUYER_TRANSFER_IN_PROGRESS:
        return { color: 'green.main', icon: <IconMoneyTransfer style={{fontSize: '3em'}} />, translationId: 'buyer.ps.transferInProgress', walkme: 'bdashboard-box-inprogress'};
      case CARD_TYPE_BUYER_WAITING:
        return { color: 'grey.main', icon: <IconHourglass style={{fontSize: '3em'}} />, translationId: 'buyer.ps.waiting', walkme: 'bdashboard-box-nonechues'};
      case CARD_TYPE_BUYER_OVERDUE:
        return { color: 'grey.main', icon: <IconHand style={{fontSize: '3em'}} />, translationId: 'buyer.ps.overdue', walkme: 'bdashboard-box-facturesechues'};
      case CARD_TYPE_BUYER_PAID:
        return { color: 'green.main', icon: <IconTransferOutlined style={{fontSize: '3em'}} />, translationId: 'buyer.ps.paid', walkme: 'bdashboard-box-virement'};
      case CARD_TYPE_BUYER_CLOSED:
        return { color: 'green.main', icon: <IconCheckOutlined style={{fontSize: '3em'}} />, translationId: 'buyer.ps.closed', walkme: 'bdashboard-box-cloturees'};
      default:
        return { color: 'grey.main', icon: <QuestionAnswerOutlined style={{fontSize: '3em'}} />, translationId: 'general.oops', walkme: 'bdashboard-box-unknown'};
    }
  }

  return (
    <CarouselComponent
      cards={props.cards}
      customize={customize}
      walkme="bdashboard-image-votresituation"
    />
  );
}
