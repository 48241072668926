/* @flow */

import React from 'react';
import { connect } from 'react-redux';

import LoadingComponent from 'components/loading';
import SellInvoiceTab3ViewComponent from './view';

import CompanyContactPerson from 'models/companies/companyContactPerson';
import DebtorCompany from 'models/companies/debtorCompany';

import SetInvoiceContactPersonsRequest from 'models/requests/sellers/setInvoiceContactPersonsRequest';

import CompanyService from 'services/CompanyService';
import InvoiceSellerService from 'services/InvoiceSellerService';
import { handleApiFormResponse } from 'lib/forms';
import NotificationHelper from 'lib/notifications';

type SellInvoiceTab3ComponentProps = {
  debtorCompany: DebtorCompany;
  invoiceId: number;
  successHandler: () => void;
  cancelInvoice: () => void;
}

type SellInvoiceTab3ComponentState = {
  contactPersonIds: number[];
  contactPersonIdsInDB: number[];
  contactPersons: CompanyContactPerson[];
  displayForm: boolean;
  formErrors: string[];
  isLoading: boolean;
  isProcessing: boolean;
}

class SellInvoiceTab3Component extends React.Component<SellInvoiceTab3ComponentProps, SellInvoiceTab3ComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      contactPersonIds: [],
      contactPersonIdsInDB: [],
      contactPersons: null,
      displayForm: false,
      formErrors: [],
      isLoading: true,
      isProcessing: false
    };
  }

  componentDidMount = async () => {
    const contactPersonIds = await InvoiceSellerService.getInvoiceContactPersonIds(this.props.invoiceId);
    const contactPersons = await CompanyService.getContactPersons(this.props.activeCompanyId, this.props.debtorCompany.id);
    this.setState({
      contactPersonIds,
      contactPersonIdInDB: contactPersonIds,
      contactPersons,
      isLoading: false
    });
  }

  selectContact = (contactPersonId: number) => {
    try {
      const contactPersonIds = this.state.contactPersonIds;
      contactPersonIds.push(contactPersonId);
      this.setState({ contactPersonIds });
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR, e.message);
    }
  }

  unSelectContact = (contactPersonId: number) => {
    const contactPersonIds = this.state.contactPersonIds.filter(c => c !== contactPersonId);
    this.setState({ contactPersonIds });
  }

  handleContactCreated = (contactPerson: CompanyContactPerson) => {
    const contactPersons = this.state.contactPersons;
    contactPersons.push(contactPerson);

    const contactPersonIds = this.state.contactPersonIds;
    contactPersonIds.push(contactPerson.id);

    this.setState({
      contactPersonIds: contactPersonIds,
      contactPersons,
      displayForm: false
    });
  }

  submitForm = async () => {
    if (this.state.contactPersonIds.length <= 0) {
      return;
    }

    // no changes, no call
    if (this.state.contactPersonIdsInDB === this.state.contactPersonIds) {
      this.props.successHandler();
      return;
    }

    this.setState({ isProcessing: true });

    try {
      const request = new SetInvoiceContactPersonsRequest(this.props.invoiceId, this.state.contactPersonIds);
      await InvoiceSellerService.setInvoiceContactPersons(request);
      this.props.successHandler();
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }
  }

  cancelInvoice = () => {
    this.setState({ isProcessing: true });
    this.props.cancelInvoice();
  }

  toggleContactForm = () => this.setState(prevState => ({ displayForm: !prevState.displayForm }));

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <SellInvoiceTab3ViewComponent
        contactPersonIds={this.state.contactPersonIds}
        contactPersons={this.state.contactPersons}
        debtorCompany={this.props.debtorCompany}
        displayForm={this.state.displayForm}
        formErrors={this.state.formErrors}
        isProcessing={this.state.isProcessing}
        cancelInvoice={this.cancelInvoice}
        handleContactCreated={this.handleContactCreated}
        selectContact={this.selectContact}
        submitForm={this.submitForm}
        toggleContactForm={this.toggleContactForm}
        unSelectContact={this.unSelectContact}
      />
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps)(SellInvoiceTab3Component);
