/* @flow */

import React from 'react';
import {useLocation} from "react-router-dom";

import ISOLeadsComponent from 'app/isos/leads';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function ISOLeadsPage() {
  let location = useLocation();

  return (
    <WebsitePageComponent id="page.iso.leads.title" data-walkme="isoleads-title-yourleads" fluidContainer noPaper>
      <ISOLeadsComponent filterByLeadsToClean={location.state?.filterByLeadsToClean || false} />
    </WebsitePageComponent>
  );
}
