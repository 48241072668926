/* @flow */

import InvestmentProfileValues from 'models/buyers/investmentProfiles/investmentProfileValues';
import UpdateInvestmentProfileRequest from 'models/requests/buyers/updateInvestmentProfileRequest';
import AddInvestmentProfileRequest from 'models/requests/buyers/addInvestmentProfileRequest';

export default class InvestmentProfileForm {
  name: String;
  nameUpdate: String;
  bankAccountId: number;
  amount: number;
  minInvoiceAmount: number;
  maxInvoiceAmount: number;
  pct90: number;
  comment: string;
  marketPlace: string;

  constructor(ipv: InvestmentProfileValues) {
    this.name = ipv.name;
    this.nameUpdate = ipv.name;
    this.bankAccountId = ipv.bankAccount ? ipv.bankAccount.id : 0;
    this.amount = ipv.amount;
    this.minInvoiceAmount = ipv.minInvoiceAmount;
    this.maxInvoiceAmount = ipv.maxInvoiceAmount;
    this.pct90 = ipv.pct90;
    this.comment = ipv.comment;    
    this.marketPlace = ipv.marketPlace;
  }

  toUpdateRequest(companyId: number, investmentProfileId: number) {
    const request = new UpdateInvestmentProfileRequest();
    request.companyId = companyId;
    request.investmentProfileId = investmentProfileId;
    request.bankAccountId = this.bankAccountId;
    request.amount = this.amount;
    request.minInvoiceAmount = this.minInvoiceAmount;
    request.maxInvoiceAmount = this.maxInvoiceAmount;
    request.pct90 = this.pct90;
    request.comment = this.comment;
    request.marketPlace = this.marketPlace;
    return request;
  }

  toUpdateNameRequest(companyId: number, investmentProfileId: number) {
    const request = new UpdateInvestmentProfileRequest();
    request.name = this.nameUpdate;
    request.companyId = companyId;
    request.investmentProfileId = investmentProfileId;
    return request;
  }

  toAddRequest(companyId: number, investmentProfileId: number) {
    const request = new AddInvestmentProfileRequest();
    request.name = this.name;
    request.companyId = companyId;
    request.investmentProfileId = investmentProfileId;
    request.bankAccountId = this.bankAccountId;
    request.amount = this.amount;
    request.minInvoiceAmount = this.minInvoiceAmount;
    request.maxInvoiceAmount = this.maxInvoiceAmount;
    request.pct90 = this.pct90;
    request.comment = this.comment;
    request.marketPlace = this.marketPlace;
    return request;
  }
}
