/* @flow */

import React from 'react';
import {FormattedNumber} from 'react-intl';
import {IntlProvider} from 'react-intl-redux';

type AmountKMFormatterComponentProps = {
  value: number
}

export default function AmountKMFormatterComponent(props: AmountKMFormatterComponentProps) {
  let suffix = '';
  let value = props.value || 0;

  if (props.value > 1000) {
    value = props.value / 1000;
    suffix = 'K';
  }

  return (
    <IntlProvider>
      <span>
        <FormattedNumber
          minimumFractionDigits={0}
          maximumFractionDigits={0}
          value={value}
        />
        {suffix}
      </span>
    </IntlProvider>
  );
}