/* @flow */
export const API_URL = `${process.env.REACT_APP_API_URL || 'http://localhost:61407'}/api/v1`;

export const ONE_DAY = 86400000; // one day in milliseconds

export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_NUMBERS_COUNT = 1;
export const PASSWORD_UPPERCASE_COUNT = 1;
export const PASSWORD_LOWERCASE_COUNT = 1;
export const PASSWORD_SPECIALCHARS = '!#$%&()*+,-./:;<=>?@[\\]^_{|}~';
export const PASSWORD_MAX_EXPOSED_TIMES = 10;
