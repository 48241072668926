/* @flow */

export default class DeleteCompanyBankAccountRequest {
    companyId: number;
    bankAccountId: number;
  
    constructor(companyId: number, bankAccountId: number) {
      this.companyId = companyId;
      this.bankAccountId = bankAccountId;
    }
  }
  