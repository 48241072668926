/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import moment from 'moment';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import LoadingComponent from 'components/loading';

import BuyerPerformanceChartComponent from './chart';
import BuyerPerformanceControlsComponent from './controls';
import BuyerPerformanceSummaryComponent from './summary';

import GetPerformanceRequest from 'models/requests/buyers/getPerformanceRequest';

import BuyerService from 'services/BuyerService';

type BuyerPerformanceComponentProps = {
  activeCompanyId: number;
  activeCompanyInvestmentProfileId: number;
};

type BuyerPerformanceComponentState = {
  amountInvested: number;
  cashReturn: number;
  data: {cashReturnData: any, investedAmountData: any};
  dateFrom: moment;
  dateTo: moment;
  filter: number;
  filterOptions: string[];
  investmentDays: number;
  irr: number;
  isLoading: boolean;
};

class BuyerPerformanceComponent extends React.Component<BuyerPerformanceComponentProps, BuyerPerformanceComponentState> {

  constructor(props) {
    super(props);
    this.state = {
      amountInvested: 0,
      cashReturn: 0,
      data: {cashReturnData: [], investedAmountData: []},
      dateFrom: moment().subtract('1', 'year'),
      dateTo: moment(),
      filter: 1,
      filterOptions: ['All', 'Open', 'Closed'],
      investmentDays: 0,
      irr: 0,
      isLoading: true
    };
  }

  componentDidMount() {
    this.getBuyerPerformanceData();
  }

  getBuyerPerformanceData = async () => {
    try {
      const request = new GetPerformanceRequest(this.props.activeCompanyId, this.props.activeCompanyInvestmentProfileId, this.state.dateFrom, this.state.dateTo, this.state.filter);
      const resp = await BuyerService.getPerformance(request);

      if (Array.isArray(resp.returnDataSeries)) {
        resp.investedDataSeries = resp.investedDataSeries.map(item => ({ date: item.date, value: item.value }));
      }
      this.setState({
        amountInvested: resp.amountInvested,
        cashReturn: resp.cashReturn,
        data: {
          cashReturnData: resp.returnDataSeries,
          investedAmountData: resp.investedDataSeries
        },
        investmentDays: resp.investmentDays,
        irr: resp.irr,
        isLoading: false,
      });
    } catch (e) {
      console.error(e);
    }
  }

  getFilteredData = dataSet =>
    this.state.data[dataSet].filter((dataPoint) => {
      const parsedDate = moment(dataPoint.date);
      return (parsedDate >= this.state.dateFrom && parsedDate <= this.state.dateTo);
    });

  handleChange = pickerName => (value: any) => {
    const newState = this.state;
    newState[pickerName] = value;
    this.setState(newState);
    this.getBuyerPerformanceData();
  };

  handleFilterChange = pickerName => (event: SyntheticInputEvent<HTMLOptionElement>) => {
    const newState = this.state;
    newState[pickerName] = parseInt(event.target.value, 10);
    this.setState(newState);
    this.getBuyerPerformanceData();
  };

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <React.Fragment>
        <BuyerPerformanceControlsComponent
          dateFrom={this.state.dateFrom}
          dateTo={this.state.dateTo}
          filter={this.state.filter}
          handleChange={this.handleChange}
          handleFilterChange={this.handleFilterChange}
        />

        <Box mt={3}>
          <Paper elevation={0} style={{textTransform: 'uppercase'}}>
            <Box p={5}>
              <BuyerPerformanceSummaryComponent
                amountInvested={this.state.amountInvested}
                cashReturn={this.state.cashReturn}
                investmentDays={this.state.investmentDays}
                irr={this.state.irr}
              />

              {this.state.data.investedAmountData.length > 0 &&
                <Box mt={5}>
                  <BuyerPerformanceChartComponent
                    chartData={this.getFilteredData('investedAmountData')}
                    chartWM="performance-image-historiquedelinvestissement"
                    chartUnitX=""
                    chartUnitY="€"
                    title="buyer.perf.investmentHistory"
                    titleWM="performance-title-historiquedelinvestissement"
                  />
                </Box>
              }

              {this.state.data.cashReturnData.length > 0 &&
                <Box mt={5}>
                  <BuyerPerformanceChartComponent
                    chartData={this.getFilteredData('cashReturnData')}
                    chartWM="performance-image-historiquedurendement"
                    chartUnitX=""
                    chartUnitY="€"
                    title="buyer.perf.cashReturnHistory"
                    titleWM="performance-title-historiquedurendement"
                  />
                </Box>
              }
            </Box>
          </Paper>
        </Box>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id,
  activeCompanyInvestmentProfileId: state.auth.user.activeCompany.investmentProfile.id
});

export default connect(mapStateToProps)(BuyerPerformanceComponent);
