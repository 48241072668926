/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import QuestionAnswerOutlined from '@material-ui/icons/QuestionAnswerOutlined';

import CarouselComponent from 'components/caroussel';
import IconCheckOutlined from 'components/icons/outlined/check';
import IconHourglass from 'components/icons/hourglass';
import IconMoneyAvailable from 'components/icons/moneyAvailable';
import IconMoneyTransfer from 'components/icons/moneyTransfer';
import IconSeller from 'components/icons/seller';
import IconWallet from 'components/icons/wallet';
import LoadingComponent from 'components/loading';

import ProfileSummaryCard from 'models/dashboards/profileSummaryCard';

import ISOService from 'services/IsoService';

import {
  CARD_TYPE_ISO_IN_PROGRESS_LEADS,
  CARD_TYPE_ISO_IN_PROGRESS_FINANCED_AMOUNT,
  CARD_TYPE_ISO_FINANCED_AMOUNT,
  CARD_TYPE_ISO_IN_PROGRESS_UNFINANCED_AMOUNT,
  CARD_TYPE_ISO_UNFINANCED_AMOUNT,
  CARD_TYPE_ISO_LEADS_WON,
  CARD_TYPE_ISO_ACTIVE_SELLERS,
  CARD_TYPE_ISO_COMMISSION
} from 'constants/cardTypes';

type ISOProfileSummaryCarouselComponentProps = {
  activeCompanyId: number;
}

type ISOProfileSummaryCarouselComponentState = {
  cards: ProfileSummaryCard[];
  isLoading: boolean;
}

class ISOProfileSummaryCarouselComponent extends React.Component<ISOProfileSummaryCarouselComponentProps, ISOProfileSummaryCarouselComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      cards: null,
      isLoading: true
    };
  }

  componentDidMount = async () => {
    try {
      const cards = await ISOService.getProfileSummary(this.props.activeCompanyId);
      this.setState({cards, isLoading: false});
    } catch (e) {
      console.error(e);
    }
  }

  customize = (cardType: number) => {
    switch (cardType) {
      case CARD_TYPE_ISO_IN_PROGRESS_LEADS:
        return { color: 'grey.main', icon: <IconHourglass style={{fontSize: '3em'}} />, translationId: 'component.iso.profile.cards.inprogressleads', walkme: 'isodashboard-box-inprogress1'};
      case CARD_TYPE_ISO_IN_PROGRESS_FINANCED_AMOUNT:
        return { color: 'grey.main', icon: <IconMoneyTransfer style={{fontSize: '3em'}} />, translationId: 'component.iso.profile.cards.inprogressfinancedamount', walkme: 'isodashboard-box-inprogress2'};
      case CARD_TYPE_ISO_FINANCED_AMOUNT:
        return { color: 'blue.main', icon: <IconWallet style={{fontSize: '3em'}} />, translationId: 'component.iso.profile.cards.financedamount', walkme: 'isodashboard-box-financed'};
      case CARD_TYPE_ISO_IN_PROGRESS_UNFINANCED_AMOUNT:
        return { color: 'grey.main', icon: <IconMoneyTransfer style={{fontSize: '3em'}} />, translationId: 'component.iso.profile.cards.inprogressunfinancedamount', walkme: 'isodashboard-box-inprogress3'};
      case CARD_TYPE_ISO_UNFINANCED_AMOUNT:
        return { color: 'blue.main', icon: <IconWallet style={{fontSize: '3em'}} />, translationId: 'component.iso.profile.cards.unfinancedamount', walkme: 'isodashboard-box-unfinanced'};
      case CARD_TYPE_ISO_LEADS_WON:
        return { color: 'green.main', icon: <IconCheckOutlined style={{fontSize: '3em'}} />, translationId: 'component.iso.profile.cards.leadswon', walkme: 'isodashboard-box-leads'};
      case CARD_TYPE_ISO_ACTIVE_SELLERS:
        return { color: 'blue.main', icon: <IconSeller style={{fontSize: '3em'}} />, translationId: 'component.iso.profile.cards.activesellers', walkme: 'isodashboard-box-active'};
      case CARD_TYPE_ISO_COMMISSION:
        return { color: 'green.main', icon: <IconMoneyAvailable style={{fontSize: '3em'}} />, translationId: 'component.iso.profile.cards.commission', walkme: 'isodashboard-box-commission'};
      default:
        return { color: 'grey.main', icon: <QuestionAnswerOutlined style={{fontSize: '3em'}} />, translationId: 'component.iso.profile.cards.unknown', walkme: 'isodashboard-box-unknown'};
    }
  }

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <CarouselComponent
        cards={this.state.cards}
        customize={this.customize}
        walkme="isodashboard-walkthru-step1"
      />
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps)(ISOProfileSummaryCarouselComponent);
