/* @flow */

import React from 'react';
import { useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import { makeStyles } from '@material-ui/core/styles';

import IntlMessageComponent from 'components/formatters/intlMessage';

const useStyles = makeStyles(_ => ({
  root: {
    padding: '0 24px',
    backgroundColor: '#f8f9fa',
    boxShadow: '0 -1px 4px 0 rgba(0, 0, 0, 0.15)',
    color: '#2a2a2a',
    lineHeight: '50px',
    minHeight: '50px',
    height: '50px',
    maxHeight: '50px'
  },
  link: {
    color: '#2a2a2a',
    '&:hover': {
      color: '#2a2a2a'
    }
  }
}));

export default function WebsiteFooterComponent() {
  const constants = useSelector(state => state.locale.constants);
  const classes = useStyles();

  const getConstant = (key: string) => {
    const constant = constants.find(c => c.key === key);
    if (!constant) throw new Error(`Missing constants: ${key}`);
    return constant.value;
  }

  const isValidHttpUrl = (urlString: string) => {
    let url;
    
    try {
      url = new URL(urlString);
    } catch (_) {
      return false;  
    }
  
    return url.protocol === "http:" || url.protocol === "https:";
  }

  const getOptionalConstant = (key: string) => {
    const constant = constants.find(c => c.key === key);
    return isValidHttpUrl(constant?.value) ? constant.value : null;
  }

  const companyName = getConstant('general.company.name');
  const facebookUrl = getOptionalConstant('footer.facebook.url');
  const linkedInUrl = getOptionalConstant('footer.linkedIn.url');
  const twitterUrl = getOptionalConstant('footer.twitter.url');
  const generalConditionsUrl = getOptionalConstant('footer.generalConditions.url');
  const particularConditionsUrl = getOptionalConstant('footer.particularConditions.url');
  const privacyUrl = getOptionalConstant('footer.privacy.url');

  return (
    <footer className={classes.root}>
      <div className='hiddenMobile'>
        <Box display="flex">
          {(facebookUrl || twitterUrl || linkedInUrl) &&
            <Box flexGrow={1}>
              {facebookUrl &&
                <a href={facebookUrl} target="_blank" rel="noopener noreferrer" className={classes.link}>
                  <FacebookIcon />
                </a>
              }
              {twitterUrl &&
                <a href={twitterUrl} target="_blank" rel="noopener noreferrer" className={classes.link}>
                  <TwitterIcon />
                </a>
              }
              {linkedInUrl &&
                <a href={linkedInUrl} target="_blank" rel="noopener noreferrer" className={classes.link}>
                  <LinkedInIcon />
                </a>
              }
            </Box>
          }

          <Box mx={1}>
            <Box component="span" mx={1}>{companyName} {(new Date()).getFullYear()}</Box>

            {(generalConditionsUrl || particularConditionsUrl || privacyUrl) &&
              <React.Fragment>
                {generalConditionsUrl &&
                  <Box component="span" mx={1}>
                    <a href={generalConditionsUrl} target="_blank" rel="noopener noreferrer" className={classes.link}>
                      <IntlMessageComponent id="component.footer.conditions.label" />
                    </a>
                  </Box>
                }

                {particularConditionsUrl &&
                  <Box component="span" mx={1}>
                    <a href={particularConditionsUrl} target="_blank" rel="noopener noreferrer" className={classes.link}>
                      <IntlMessageComponent id="component.footer.terms.label" />
                    </a>
                  </Box>
                }

                {privacyUrl &&
                  <Box component="span" mx={1}>
                    <a href={privacyUrl} target="_blank" rel="noopener noreferrer" className={classes.link}>
                      <IntlMessageComponent id="component.footer.privacy.label" />
                    </a>
                  </Box>
                }
              </React.Fragment>
            }
          </Box>
        </Box>
      </div>
      <div className='hiddenDesktop'>
        <Box>
          {(facebookUrl || twitterUrl || linkedInUrl) &&
            <Box flexGrow={1}>
              {facebookUrl &&
                <a href={facebookUrl} target="_blank" rel="noopener noreferrer" className={classes.link}>
                  <FacebookIcon />
                </a>
              }
              {twitterUrl &&
                <a href={twitterUrl} target="_blank" rel="noopener noreferrer" className={classes.link}>
                  <TwitterIcon />
                </a>
              }
              {linkedInUrl &&
                <a href={linkedInUrl} target="_blank" rel="noopener noreferrer" className={classes.link}>
                  <LinkedInIcon />
                </a>
              }
              <Box component="span" mx={1}>{companyName} {(new Date()).getFullYear()}</Box>
            </Box>
          }

          <Box>
            {(generalConditionsUrl || particularConditionsUrl || privacyUrl) &&
              <React.Fragment>
                {generalConditionsUrl &&
                  <Box>
                    <a href={generalConditionsUrl} target="_blank" rel="noopener noreferrer" className={classes.link}>
                      <IntlMessageComponent id="component.footer.conditions.label" />
                    </a>
                  </Box>
                }

                {particularConditionsUrl &&
                  <Box>
                    <a href={particularConditionsUrl} target="_blank" rel="noopener noreferrer" className={classes.link}>
                      <IntlMessageComponent id="component.footer.terms.label" />
                    </a>
                  </Box>
                }

                {privacyUrl &&
                  <Box>
                    <a href={privacyUrl} target="_blank" rel="noopener noreferrer" className={classes.link}>
                      <IntlMessageComponent id="component.footer.privacy.label" />
                    </a>
                  </Box>
                }
              </React.Fragment>
            }
          </Box>
        </Box>
      </div>

    </footer>
  );
}
