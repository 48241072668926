/* @flow */

import React from 'react';
import { useSelector } from 'react-redux';

import { Link } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

import ButtonComponent from 'components/buttons/button';
import CompanyBankAccountFieldComponent from 'components/formFields/bankAccounts';
import ComponentWrapperComponent from 'components/websiteStructure/componentWrapper';
import InputAmountComponent from 'components/formatters/inputAmount';
import IntlMessageComponent from 'components/formatters/intlMessage';
import MarketPlacePickerComponent from 'components/formFields/marketPlaces';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import SecurityComponent from 'components/security/index';

import InvestmentProfileForm from './model';

import { PROTECTED_COMPANY_BANKACCOUNTS } from 'constants/pageRoutes';

type BuyerInvestmentProfileFormViewComponentProps = {
  errors: Map<string,?string>;
  form: InvestmentProfileForm;
  formErrors: string[];
  isActive: boolean;
  isProcessing: boolean;
  placeholders: any;
  isModifyForm: Boolean;
  pageTitle: string;
  hasInvoicesListedTo: boolean;
  canExecute: boolean;
  handleChange: (fieldName: string) => (event: object) => void;
  handleChangeBankAccount: () => void;
  submitForm: () => void;
  submitNameForm: () => void;
}

export default function BuyerInvestmentProfileFormViewComponent(props: BuyerInvestmentProfileFormViewComponentProps) {
  const isBuyerObserver = useSelector(state => state.auth.user.activeCompany.roleBuyer?.role == 3886);
  const isBuyerOperator = useSelector(state => state.auth.user.activeCompany.roleBuyer?.role == 3887);
  return (
    <ComponentWrapperComponent small>
      <PageSubTitleViewComponent id={props.pageTitle} />

      <Box mt={3} px={4}>
        {props.isModifyForm ? <TextField
          id="form.investmentprofile.nameUpdate"
          data-walkme="investment-field-nameUpdate"
          label={<IntlMessageComponent id="form.ip.name" />}
          value={props.form.nameUpdate}
          onChange={props.handleChange('nameUpdate')}
          fullWidth
          margin="normal"
          InputProps={{
            readOnly: isBuyerOperator || isBuyerObserver
          }}
        /> : <TextField
          id="form.investmentprofile.name"
          data-walkme="investment-field-name"
          label={<IntlMessageComponent id="form.ip.name" />}
          error={!!props.errors.get('name')}
          helperText={(props.errors.get('name')) ?
            <IntlMessageComponent id={props.errors.get('name')} /> : ''}
          value={props.form.name}
          onChange={props.handleChange('name')}
          fullWidth
          margin="normal"
          required
          InputProps={{
            readOnly: isBuyerOperator || isBuyerObserver
          }}
        />}

        {props.isModifyForm && props.canExecute ?
          <SecurityComponent pageName="POS.Buyers.InvestmentProfile" elementName="btnUpdateName">
            <Box mt={1} px={1} display="flex" justifyContent="flex-end">
              <ButtonComponent
                size="small"
                color="primary"
                variant="contained"
                isProcessing={props.isProcessing}
                onClick={props.submitNameForm}
              ><IntlMessageComponent id={props.updateNameBtnId} /></ButtonComponent>
            </Box>
          </SecurityComponent> : ''}

        <Box height={100}>
          <CompanyBankAccountFieldComponent
            id="form.bankAccountId"
            label={<IntlMessageComponent id="form.bankAccounts.label" />}
            error={!!props.errors.get('bankAccountId')}
            value={props.form.bankAccountId}
            changeHandler={props.handleChangeBankAccount}
            fullWidth
            margin="normal"
            required
            readOnly={isBuyerOperator || isBuyerObserver}
          />
          <small>
            <Link to={PROTECTED_COMPANY_BANKACCOUNTS}>
              <IntlMessageComponent id="buyer.ip.link.companyConfigPage" />
            </Link>
          </small>
        </Box>

        <TextField
          id="form.investmentprofile.amount"
          data-walkme="investment-field-montant"
          label={<IntlMessageComponent id="form.ip.amount" />}
          error={!!props.errors.get('amount')}
          helperText={(props.errors.get('amount')) ?
            <IntlMessageComponent id={props.errors.get('amount')} values={props.placeholders} /> : ''}
          value={props.form.amount}
          onChange={props.handleChange('amount')}
          InputProps={{
            inputComponent: InputAmountComponent,
            readOnly: isBuyerOperator || isBuyerObserver,
            startAdornment: <InputAdornment position="start">€</InputAdornment>
          }}
          fullWidth
          margin="normal"
          required
        />

        <TextField
          id="form.investmentprofile.minInvoiceAmount"
          data-walkme="investment-field-montantminimum"
          label={<IntlMessageComponent id="form.ip.minInvoiceAmount" />}
          error={!!props.errors.get('minInvoiceAmount')}
          helperText={(props.errors.get('minInvoiceAmount')) ?
            <IntlMessageComponent id={props.errors.get('minInvoiceAmount')} values={props.placeholders} /> : ''}
          value={props.form.minInvoiceAmount}
          onChange={props.handleChange('minInvoiceAmount')}
          InputProps={{
            inputComponent: InputAmountComponent,
            readOnly: isBuyerOperator || isBuyerObserver,
            startAdornment: <InputAdornment position="start">€</InputAdornment>
          }}
          fullWidth
          margin="normal"
          required
        />

        <TextField
          id="form.investmentprofile.maxInvoiceAmount"
          data-walkme="investment-field-montantmaximum"
          label={<IntlMessageComponent id="form.ip.maxInvoiceAmount" />}
          error={!!props.errors.get('maxInvoiceAmount')}
          helperText={props.errors.get('maxInvoiceAmount') ?
            <IntlMessageComponent id={props.errors.get('maxInvoiceAmount')} values={props.placeholders} /> : ''}
          value={props.form.maxInvoiceAmount}
          onChange={props.handleChange('maxInvoiceAmount')}
          InputProps={{
            inputComponent: InputAmountComponent,
            readOnly: isBuyerOperator || isBuyerObserver,
            startAdornment: <InputAdornment position="start">€</InputAdornment>
          }}
          fullWidth
          margin="normal"
          required
        />

        <TextField
          id="form.investmentprofile.pct90"
          data-walkme="investment-field-partdefactures"
          label={<IntlMessageComponent id="form.ip.pct90" />}
          error={!!props.errors.get('pct90')}
          helperText={props.errors.get('pct90') ?
            <IntlMessageComponent id={props.errors.get('pct90')} values={props.placeholders} /> : ''}
          value={props.form.pct90}
          onChange={props.handleChange('pct90')}
          InputProps={{
            readOnly: isBuyerOperator || isBuyerObserver,
            startAdornment: <InputAdornment position="start">%</InputAdornment>
          }}
          fullWidth
          margin="normal"
          required
        />

        <Box mt={3}>
          <MarketPlacePickerComponent
            error={!!props.errors.get('marketPlace')}
            helperText={props.errors.get('marketPlace') ?
              <IntlMessageComponent id={props.errors.get('marketPlace')} /> : ''}
            value={props.form.marketPlace}
            handleChange={props.handleChange('marketPlace')}
            fullWidth
            margin="normal"
            required
            disabled={props.hasInvoicesListedTo}
          />
        </Box>

        {props.isModifyForm && <TextField
          id="form.investmentprofile.comment"
          label={<IntlMessageComponent id="form.ip.comment" />}
          error={!!props.errors.get('comment')}
          helperText={props.errors.get('comment') ?
            <IntlMessageComponent id={props.errors.get('comment')} values={props.placeholders} /> : ''}
          value={props.form.comment}
          onChange={props.handleChange('comment')}
          fullWidth
          margin="normal"
          multiline
          rows={3}
          InputProps={{
            readOnly: isBuyerOperator || isBuyerObserver
          }}
        />}
      </Box>

      <Box mt={3} display="flex" justifyContent="flex-end">
        {props.canExecute && <SecurityComponent pageName="POS.Buyers.InvestmentProfile" elementName="btnUpdate">
          <ButtonComponent
            color="primary"
            variant="contained"
            isProcessing={props.isProcessing}
            onClick={props.submitForm}
          ><IntlMessageComponent id={props.btnId} /></ButtonComponent>
        </SecurityComponent>}
        
      </Box>
    </ComponentWrapperComponent>
  );
}
