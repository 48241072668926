/* @flow */

import SalesPersonPhoneNumber from './salesPersonPhoneNumber';

export default class SalesPerson {
  lastName: string;
  firstName: string;
  email: string;
  phoneNumbers: SalesPersonPhoneNumber[];
}
