/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';
import LoadingComponent from 'components/loading';
import MobileConfirmationComponent from 'components/mobileConfirmation';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import StartSignTosTransactionRequest from 'models/requests/register/startSignTosTransactionRequest';
import UniversignTransaction from 'models/register/universignTransaction';

import RegisterService from 'services/registerService';
import NotificationHelper from 'lib/notifications';
import {validateField} from 'lib/validation/validator';

import {
  PROTECTED_APP_TOS_CANCEL,
  PROTECTED_APP_TOS_FAILURE,
  PROTECTED_APP_TOS_SUCCESS
} from 'constants/pageRoutes';


type TermsAndConditionsBeginComponentProps = {
  activeCompanyId: number;
  activeCompanyRole: number;
}
type TermsAndConditionsBeginComponentState = {
  isLoading: boolean;
  isProcessing: boolean;
  transaction: UniversignTransaction;
  isSignButtonDisabled: boolean;
}

class TermsAndConditionsBeginComponent extends React.Component<TermsAndConditionsBeginComponentProps, TermsAndConditionsBeginComponentState> {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isProcessing: false,
      transaction: null,
      isSignButtonDisabled: false
    };
  }

  componentDidMount = async () => {
    try {
      const transaction = await RegisterService.getTosTransactionStatus(this.props.activeCompanyId, this.props.activeCompanyRole);
      this.setState({isLoading: false, transaction});
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  }

  disableSignButton = () => {
    const isSignButtonDisabled = true;
    if (this.state.isSignButtonDisabled !== isSignButtonDisabled)
      this.setState({isSignButtonDisabled});
  }

  mobileUpdated = (mobile: string) => {
    const transaction = this.state.transaction;
    const isSignButtonDisabled = false;
    transaction.mobile = mobile;
    this.setState({transaction, isSignButtonDisabled});
  };

  beginTransaction = async () => {
    this.setState({isProcessing: true});

    try {
      const request = new StartSignTosTransactionRequest(this.props.activeCompanyId, this.props.activeCompanyRole, PROTECTED_APP_TOS_SUCCESS, PROTECTED_APP_TOS_FAILURE, PROTECTED_APP_TOS_CANCEL);
      const transaction = await RegisterService.startSignTosTransaction(request);
      window.location.replace(transaction.url);
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  }

  continueTransaction = () => {
    window.location.replace(this.state.transaction.url);
  }

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <React.Fragment>
        <Typography variant="body1" gutterBottom>
          <IntlMessageComponent id="page.tos.introduction" />
        </Typography>

        {this.state.transaction.url !== '' ? (
          <Box mt={5}>
            <ButtonComponent
              color="primary"
              variant="contained"
              onClick={this.continueTransaction}
            ><IntlMessageComponent id="general.continue" /></ButtonComponent>
          </Box>
        ) : (
          <Box mt={3}>
            <PageSubTitleViewComponent id="page.tos.mobile.title" />

            <Box width={{xs: '100%', md: '50%'}}>
              <MobileConfirmationComponent
                key={this.state.transaction.mobile}
                mobile={this.state.transaction.mobile}
                successHandler={this.mobileUpdated}
                disableSignButton={this.disableSignButton}
              />
            </Box>

            <Box mt={5}>
              <ButtonComponent
                color="primary"
                variant="contained"
                disabled={this.state.isSignButtonDisabled}
                isProcessing={this.state.isProcessing}
                onClick={this.beginTransaction}
              ><IntlMessageComponent id="page.tos.button.sign" /></ButtonComponent>
            </Box>
          </Box>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id,
  activeCompanyRole: state.auth.user.activeCompany.role
});

export default connect(mapStateToProps)(TermsAndConditionsBeginComponent);
