/* @flow */

import React from 'react';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';

import IntlMessageComponent from 'components/formatters/intlMessage';
import LoadingComponent from 'components/loading';

import InvoiceReportFiltersComponent from './filters';
import InvoiceReportTableComponent from './table';

import Filter from 'models/isos/filters/filter';
import FilterCompany from 'models/isos/filters/filterCompany';
import FilterOffice from 'models/isos/filters/filterOffice';
import InvoiceReport from 'models/isos/invoices/invoiceReport';
import InvoiceReportFilters from 'models/isos/invoices/invoiceReportFilters';

import DownloadInvoiceReportRequest from 'models/requests/isos/downloadInvoiceReportRequest';

import IsoService from 'services/IsoService';

type ISOInvoicesComponentProps = {
  activeCompanyId: number;
}

type ISOInvoicesComponentState = {
  filters: InvoiceFilters;
  invoices: InvoiceReport[];
  isLoading: boolean;
  sellerCompanyName: string;
}

class ISOInvoicesComponent extends React.Component<ISOInvoicesComponentProps, ISOInvoicesComponentState> {

  constructor(props) {
    super(props);
    this.state = {
      filters: null,
      invoices: null,
      isLoading: true,
      sellers: null,
      sellerCompanyName: null,
    };
  }

  componentDidMount = async () => {
    try {
      const invoices = await IsoService.getInvoiceReport(this.props.activeCompanyId);
      const filters = this.createFilterModel(invoices);
      this.setState({
        filters,
        invoices,
        isLoading: false
      });
    } catch (e) {
      console.error(e);
    }
  }

  getFilteredData = (ignoreSellerCompanies: boolean) => {
    let data = [];
    // Apply company/office/person filtering
    const filters = this.filterToObject(ignoreSellerCompanies);
    data = this.multiFilter(this.state.invoices, filters);

    return data;
  }

  createFilterModel = (data: any) => {
    const filters = new InvoiceReportFilters();

    for (let i = 0; i < data.length; i++) {
      let isoCompany = filters.companies.find(c => c.id === data[i].filterISOId);
      if (isoCompany === undefined) {
        let isoCompanyName = data[i].isoCompany.length === 0 ? <IntlMessageComponent id="general.filters.unspecifiedcompany" /> : data[i].isoCompany;
        isoCompany = new FilterCompany(data[i].filterISOId, isoCompanyName);
        filters.companies.push(isoCompany);
      }

      let isoOffice = isoCompany.offices.find(o => o.id === data[i].filterISOOfficeId);
      if (isoOffice === undefined) {
        let isoOfficeName = data[i].isoOffice.length === 0 ? <IntlMessageComponent id="general.filters.unspecifiedoffice" /> : data[i].isoOffice;
        isoOffice = new FilterOffice(data[i].filterISOOfficeId, isoOfficeName);
        isoCompany.offices.push(isoOffice);
      }

      let isoPerson = isoOffice.persons.find(p => p.id === data[i].filterISOPersonId);
      if (isoPerson === undefined) {
        let isoPersonName = data[i].isoPerson.length === 0 ? <IntlMessageComponent id="general.filters.unspecifiedperson" /> : data[i].isoPerson;
        isoPerson = new Filter(data[i].filterISOPersonId, isoPersonName);
        isoOffice.persons.push(isoPerson);
      }

      let sellerCompany = filters.sellerCompanies.find(sc => sc.id === data[i].filterSellerCompanyId);
      if (sellerCompany === undefined) {
        sellerCompany = new Filter(data[i].filterSellerCompanyId, data[i].sellerCompany);
        filters.sellerCompanies.push(sellerCompany);
      }
    }

    // isFinanced - Hardcoded filter
    filters.isFinanced.push(new Filter(1, <IntlMessageComponent id="general.label.yes" />));
    filters.isFinanced.push(new Filter(0, <IntlMessageComponent id="general.label.no" />));

    filters.sellerCompanies = filters.sellerCompanies.sort((a, b) => (a.name < b.name ? -1 : 1));

    return filters;
  }

  filterToObject = (ignoreSellerCompanies: boolean) => {
    const filters = {
      filterISOId: [],
      filterISOOfficeId: [],
      filterISOPersonId: [],
      filterSellerCompanyId: [],
      isFinanced: []
    };

    for (let i = 0; i < this.state.filters.companies.length; i++) {
      if (this.state.filters.companies[i].isSelected) {
        filters.filterISOId.push(this.state.filters.companies[i].id);
      }

      for (let j = 0; j < this.state.filters.companies[i].offices.length; j++) {
        if (this.state.filters.companies[i].offices[j].isSelected) {
          filters.filterISOOfficeId.push(this.state.filters.companies[i].offices[j].id);
        }

        for (let k = 0; k < this.state.filters.companies[i].offices[j].persons.length; k++) {
          if (this.state.filters.companies[i].offices[j].persons[k].isSelected) {
            filters.filterISOPersonId.push(this.state.filters.companies[i].offices[j].persons[k].id);
          }
        }
      }
    }

    if (!ignoreSellerCompanies) {
      for (let i = 0; i < this.state.filters.sellerCompanies.length; i++) {
        if (this.state.filters.sellerCompanies[i].isSelected) {
          filters.filterSellerCompanyId.push(this.state.filters.sellerCompanies[i].id);
        }
      }
    }
    
    // Yes No
    for (let i = 0; i < this.state.filters.isFinanced.length; i++) {
      if (this.state.filters.isFinanced[i].isSelected) {
        if (this.state.filters.isFinanced[i].id === 0) filters.isFinanced.push(false);
        else filters.isFinanced.push(true);
      }
    }

    return filters;
  }

  multiFilter = (array, filters) => {
    const filterKeys = Object.keys(filters);
    // filters all elements passing the criteria
    return array.filter(item => filterKeys.every((key) => {
      // ignores an empty filter
      if (!filters[key].length) return true;
      return filters[key].includes(item[key]);
    }));
  }

  selectFilter = (filterId: string, optionId: number) => {
    const filters = this.state.filters;
    for (let i = 0; i < filters[filterId].length; i++) {
      if (filters[filterId][i].id === optionId) {
        filters[filterId][i].isSelected = !filters[filterId][i].isSelected;
      }
    }
    this.setState({ filters });
  }

  selectFilterISO = (filterId: string, optionId: number) => {
    const filters = this.state.filters;

    if (filterId === 'company') {
      for (let i = 0; i < filters.companies.length; i++) {
        if (filters.companies[i].id === optionId) {
          filters.companies[i].isSelected = !filters.companies[i].isSelected;

          if (!filters.companies[i].isSelected) {
            // if we unselect a company, unselect all its offices & persons
            for (let j = 0; j < filters.companies[i].offices.length; j++) {
              filters.companies[i].offices[j].isSelected = false;
              for (let k = 0; k < filters.companies[i].offices[j].persons.length; k++) {
                filters.companies[i].offices[j].persons[k].isSelected = false;
              }
            }
          }
        }
      }
    }

    if (filterId === 'office') {
      for (let i = 0; i < filters.companies.length; i++) {
        for (let j = 0; j < filters.companies[i].offices.length; j++) {
          if (filters.companies[i].offices[j].id === optionId) {
            filters.companies[i].offices[j].isSelected = !filters.companies[i].offices[j].isSelected;

            if (filters.companies[i].offices[j].isSelected) {
              // if we select an office, select its company
              filters.companies[i].isSelected = true;
            } else {
              // if we unselect an office, unselect all its persons
              for (let k = 0; k < filters.companies[i].offices[j].length; k++) {
                filters.companies[i].offices[j].persons[k].isSelected = false;
              }
            }
          }
        }
      }
    }

    if (filterId === 'person') {
      for (let i = 0; i < filters.companies.length; i++) {
        for (let j = 0; j < filters.companies[i].offices.length; j++) {
          for (let k = 0; k < filters.companies[i].offices[j].persons.length; k++) {
            if (filters.companies[i].offices[j].persons[k].id === optionId) {
              filters.companies[i].offices[j].persons[k].isSelected = !filters.companies[i].offices[j].persons[k].isSelected;

              if (filters.companies[i].offices[j].persons[k].isSelected) {
                // if we select a person, select its company and office
                filters.companies[i].isSelected = true;
                filters.companies[i].offices[j].isSelected = true;
              }
            }
          }
        }
      }
    }

    //Unselect all seller companies to be able to work with available seller companies to select updating automatically
    for (let i = 0; i < filters.sellerCompanies.length; i++) {
      filters.sellerCompanies[i].isSelected = false;
    }

    this.setState({ filters });
  }

  downloadInvoiceReport = async (format: string) => {
    try {
      const request = new DownloadInvoiceReportRequest(this.props.activeCompanyId, format);

      for (let i = 0; i < this.state.filters.companies.length; i++) {
        if (this.state.filters.companies[i].isSelected) {
          request.isoCompanyIds.push(this.state.filters.companies[i].id);
        }

        for (let j = 0; j < this.state.filters.companies[i].offices.length; j++) {
          if (this.state.filters.companies[i].offices[j].isSelected) {
            request.isoOfficeIds.push(this.state.filters.companies[i].offices[j].id);
          }

          for (let k = 0; k < this.state.filters.companies[i].offices[j].persons.length; k++) {
            if (this.state.filters.companies[i].offices[j].persons[k].isSelected) {
              request.isoPersonIds.push(this.state.filters.companies[i].offices[j].persons[k].id);
            }
          }
        }
      }

      this.state.filters.sellerCompanies.forEach(filter => {
        if (filter.isSelected) request.sellerCompanyIds.push(filter.id);
      });

      this.state.filters.isFinanced.forEach(filter => {
        if (filter.isSelected) {
          if (filter.id === 0) request.isFinanced.push(false);
          else request.isFinanced.push(true);
        }
      });

      await IsoService.downloadInvoiceReport(request);
    } catch (e) {
      console.error(e);
    }
  }

  clearFilters = () => {
    if (this.state.filters === null) return;

    const filters = this.state.filters;

    for (let i = 0; i < filters.companies.length; i++) {
      filters.companies[i].isSelected = false;
      for (let j = 0; j < filters.companies[i].offices.length; j++) {
        filters.companies[i].offices[j].isSelected = false;
        for (let k = 0; k < filters.companies[i].offices[j].persons.length; k++) {
          filters.companies[i].offices[j].persons[k].isSelected = false;
        }
      }
    }
    
    for (let i = 0; i < filters.sellerCompanies.length; i++) {
      filters.sellerCompanies[i].isSelected = false;
    }

    for (let i = 0; i < filters.isFinanced.length; i++) {
      filters.isFinanced[i].isSelected = false;
    }

    this.setState({filters, sellerCompanyName: ''});
  }

  sellerCompanyNameChange = (name: string) => this.setState({sellerCompanyName: name});

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <React.Fragment>
        <InvoiceReportFiltersComponent
          filters={this.state.filters}
          selectFilter={this.selectFilter}
          selectFilterISO={this.selectFilterISO}
          sellerCompanyNameChange={this.sellerCompanyNameChange}
          downloadInvoiceReport={this.downloadInvoiceReport}
          clearFilters={this.clearFilters}
          sellerCompanyName={this.state.sellerCompanyName}
          availableSellerCompanyNames={this.getFilteredData(true).map(item => item.sellerCompany).filter((value, index, self) => self.indexOf(value) === index)}
        />

        <Box mt={4} data-walkme="isoinvoices-walkthru-step4">
          <InvoiceReportTableComponent invoices={this.getFilteredData()} />
        </Box>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  activeCompanyId: auth.user.activeCompany.id
});

export default connect(mapStateToProps)(ISOInvoicesComponent);
