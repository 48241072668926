/* @flow */

import React from 'react';


import CompanyDetailsComponent from './companyDetails';

export default function CompanyInfoComponent() {
  return (
    <React.Fragment>
      <CompanyDetailsComponent />
    </React.Fragment>
  );
}
