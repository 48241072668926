/* @flow */

import PaymentOgoneValue from './paymentOgoneValue';

export default class PaymentOgone {
  amount: number;
  formValues : PaymentOgoneValue[];
  reference: string;
  url: string;
}
