/* @flow */

import CompanyUserProcuration from 'models/companies/companyUserProcuration';

import UpdateCompanyUserProcurationRequest from 'models/requests/companies/users/updateCompanyUserProcurationRequest';

export default class CompanyUserProcurationForm {
  hasProcuration: string[];

  constructor(procurations: CompanyUserProcuration) {
    this.hasProcuration = [];
    if (procurations.hasProcurationAsBuyer) this.hasProcuration.push('hasProcurationAsBuyer');
    if (procurations.hasProcurationAsDebtor) this.hasProcuration.push('hasProcurationAsDebtor');
    if (procurations.hasProcurationAsISO) this.hasProcuration.push('hasProcurationAsISO');
    if (procurations.hasProcurationAsSeller) this.hasProcuration.push('hasProcurationAsSeller');
    if (procurations.hasProcurationAsConfirming) this.hasProcuration.push('hasProcurationAsConfirming');
    if (procurations.hasProcurationDebtRecognition) this.hasProcuration.push('hasProcurationDebtRecognition');
    if (procurations.isAdminRole) this.hasProcuration.push('hasProcurationUsers');
    if (procurations.isAdminRole) this.hasProcuration.push('hasProcurationGeneralConditions');
  }

  toRequest = (companyId: number, companyUserId: number) => {
    const request = new UpdateCompanyUserProcurationRequest();
    request.companyId = companyId;
    request.companyUserId = companyUserId;
    request.hasProcurationAsBuyer = this.hasProcuration.find(v => v === 'hasProcurationAsBuyer') !== undefined;
    request.hasProcurationAsDebtor = this.hasProcuration.find(v => v === 'hasProcurationAsDebtor') !== undefined;
    request.hasProcurationAsISO = this.hasProcuration.find(v => v === 'hasProcurationAsISO') !== undefined;
    request.hasProcurationAsSeller = this.hasProcuration.find(v => v === 'hasProcurationAsSeller') !== undefined;
    request.hasProcurationAsConfirming = this.hasProcuration.find(v => v === 'hasProcurationAsConfirming') !== undefined;
    request.hasProcurationDebtRecognition = this.hasProcuration.find(v => v === 'hasProcurationDebtRecognition') !== undefined;
    request.hasProcurationUsers = this.hasProcuration.find(v => v === 'hasProcurationUsers') !== undefined;
    request.hasProcurationGeneralConditions = this.hasProcuration.find(v => v === 'hasProcurationGeneralConditions') !== undefined;
    return request;
  }
}
