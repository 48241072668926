/* @flow */

import React from 'react';

import LoadingComponent from 'components/loading';

import BestPricingAltFinViewComponent from './view';
import AlternativeFinancingForm from './model';

import LeadService from 'services/LeadService';
import {handleApiFormResponse, handleFormChange, handleFormValidation} from 'lib/forms';
import loadConstraints from 'lib/validation/loadConstraints';

import NotificationHelper from 'lib/notifications';

type BestPricingAltFinComponentProps = {
  companyId: number;
  successHandler: () => void;
}

type BestPricingAltFinComponentState = {
  constraints: any;
  errors: Map<string, ?string>;
  form: AlternativeFinancingForm;
  formErrors: string[];
  isLoading: boolean;
  isProcessing: boolean;
}

class BestPricingAltFinComponent extends React.Component<BestPricingAltFinComponentProps, BestPricingAltFinComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      constraints: null,
      errors: null,
      form: new AlternativeFinancingForm(),
      formErrors: [],
      isLoading: true,
      isProcessing: false
    };
  }

  componentDidMount = async () => {
    try {
      const constraints = await loadConstraints('alternativeFinancing', ['companyName', 'costPercentage', 'type', 'creditLimit']);

      this.setState({
        constraints,
        errors: new Map(Object.keys(constraints).map(e => [e, undefined])),
        isLoading: false
      });
    } catch (e) {
      console.error(e);
    }
  };

  handleBooleanChange = (fieldName: string) => (event) => handleFormChange.call(this, fieldName, event.target.value === 'true');
  handleChange = (fieldName: string) => (event) => handleFormChange.call(this, fieldName, event.target.value);
  validateForm = () => handleFormValidation.call(this);

  submitForm = async () => {
    if (!this.state.form.hasAlternativeFinancing) {
      this.props.successHandler();
      return;
    }

    if (!this.validateForm()) return;
    this.setState({formErrors: [], isProcessing: true});

    try {
      const request = this.state.form.toRequest(this.props.companyId);
      await LeadService.setAlternativeFinancing(request);
      NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS);
      this.props.successHandler();
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }     
  }

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <BestPricingAltFinViewComponent
        errors={this.state.errors}
        form={this.state.form}
        formErrors={this.state.formErrors}
        isProcessing={this.state.isProcessing}
        handleBooleanChange={this.handleBooleanChange}
        handleChange={this.handleChange}
        submitForm={this.submitForm}
      />
    );
  }
}

export default BestPricingAltFinComponent;
