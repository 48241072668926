/* @flow */

import React from 'react';
import {useHistory} from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import AmountFormatterComponent from 'components/formatters/amount';
import ButtonComponent from 'components/buttons/button';
import DateFormatterComponent from 'components/formatters/date';
import DebtorInvoiceStatusViewComponent from 'components/invoiceStatus/debtor';
import IntlMessageComponent from 'components/formatters/intlMessage';
import InvoiceNameComponent from 'components/invoice/name';
import NumberFormatterComponent from 'components/formatters/number';
import StyledTableCellTextComponent from 'components/table/cellText';
import StyledTableCellNumbersComponent from 'components/table/cellNumbers';
import StyledTableHeadComponent from 'components/table/head';
import StyledTableRowComponent from 'components/table/row';

import Invoice from 'models/debtors/invoice';

import {PROTECTED_DEBTOR_INVOICE_DETAILS} from 'constants/pageRoutes';

const columnData = [
  {id: 'statusKey', align:'left', translationKey: 'debtor.toPay.table.statusKey', walkme: 'debtor-toPay-table-statusKey'},
  {id: 'sellerCompany.name', align:'left', translationKey: 'debtor.toPay.table.sellerCompany.name', walkme: 'debtor-toPay-table-sellerCompanyName'},
  {id: 'reference', align:'left', translationKey: 'debtor.toPay.table.reference', walkme: 'debtor-toPay-table-reference'},
  {id: 'debtorReference', align:'left', translationKey: 'debtor.toPay.table.debtorReference', walkme: 'debtor-toPay-table-debtorReference'},
  {id: 'debtAmount', translationKey: 'debtor.toPay.table.debtAmount', walkme: 'debtor-toPay-table-debtAmount'},
  {id: 'outstandingBalance', translationKey: 'debtor.toPay.table.outstandingBalance', walkme: 'debtor-toPay-table-outstandingBalance'},
  {id: 'issueDate', align:'left', translationKey: 'debtor.toPay.table.issueDate', walkme: 'debtor-toPay-table-issueDate'},
  {id: 'dueDate', align:'left', translationKey: 'debtor.toPay.table.dueDate', walkme: 'debtor-toPay-table-dueDate'},
  {id: 'id', align:'left', translationKey: 'debtor.toPay.table.id', walkme: 'debtor-toPay-table-invoiceId'}
];


type DebtorInvoicesToPayTableViewComponentProps = {
  invoices: Invoice[];
  order: string;
  orderBy: string;
  page: number;
  pageSize: number;
  totalDebtAmount: number;
  totalSellers: number;
  totalOutstandingBalance: Number;
  handleInvoiceSelect: (invoiceId: number) => void;
  handleInvoiceSelectAll: () => void;
  handlePageChange: (page: number) => void;
  handlePageSizeChange: (pageSize: number) => void;
  handleSortOrderChange: (property: string) => void;
  payInvoices: () => void;
};
  
  
export default function DebtorInvoicesToPayTableViewComponent(props: DebtorInvoicesToPayTableViewComponentProps) {
  const history = useHistory();

  const handlePageChange = (_, page) => props.handlePageChange(page);
  const handlePageSizeChange = event => props.handlePageSizeChange(event.target.value);
  const navigateToInvoiceDetails = id => history.push({pathname: `${PROTECTED_DEBTOR_INVOICE_DETAILS}/${id}`});
  

  const handleClickCheckbox = (event, invoiceId: number) => {
    event.stopPropagation();
    props.handleInvoiceSelect(invoiceId);
  };

  const handleClickCheckboxAll = (event) => {
    event.stopPropagation();
    props.handleInvoiceSelectAll();
  };

  return (
    <React.Fragment>
      <Box component="div" overflow="auto">
        <Table>
          <StyledTableHeadComponent data-walkme="debtorToPay-table-headline">
            <StyledTableRowComponent>
              <StyledTableCellTextComponent padding="checkbox">
                  <Tooltip title={<IntlMessageComponent id="general.tooltip.selectAll" />}>
                  <Checkbox
                      checked={props.invoices.length > 0 && props.invoices.find(i => !i.isSelected) === undefined}
                      color="primary"
                      disabled={props.invoices.length === 0}
                      onClick={event => handleClickCheckboxAll(event)}
                  />
                  </Tooltip>
              </StyledTableCellTextComponent>
              {columnData.map(column => (
                <StyledTableCellTextComponent align={column?.align ?? 'right'} key={column.id} padding="none">
                  <TableSortLabel
                    active={props.orderBy === column.id}
                    direction={props.order}
                    onClick={() => props.handleSortOrderChange(column.id)}
                    data-walkme={column.walkme || ''}
                  ><IntlMessageComponent id={column.translationKey} /></TableSortLabel>
                </StyledTableCellTextComponent>
              ))}
            </StyledTableRowComponent>
          </StyledTableHeadComponent>

          <TableBody bgcolor="white">
            {props.invoices.length === 0 &&
              <StyledTableRowComponent>
                <StyledTableCellTextComponent colSpan={columnData.length + 1}>
                  <Box textAlign="center">
                    <IntlMessageComponent id="general.noData" />
                  </Box>
                </StyledTableCellTextComponent>
              </StyledTableRowComponent>
            }

            {props.invoices.slice(props.page * props.pageSize, (props.page * props.pageSize) + props.pageSize).map(i => (
              <StyledTableRowComponent
                key={`invList-${i.id}`}  
                hover
                onClick={() => navigateToInvoiceDetails(i.id)}
                tabIndex={-1}
              >
              
                <StyledTableCellTextComponent padding="checkbox" >
                  {i.toPay === true && 
                    <Checkbox color="primary" checked={i.isSelected} onClick={event => handleClickCheckbox(event, i.id)} /> 
                  } 
                </StyledTableCellTextComponent>
              
                <StyledTableCellTextComponent padding="none">
                  <DebtorInvoiceStatusViewComponent statusKey={i.statusKey} />
                </StyledTableCellTextComponent>

                <StyledTableCellTextComponent padding="none">
                  {i.sellerCompany.name}
                </StyledTableCellTextComponent>

                <StyledTableCellTextComponent padding="none">
                  {i.reference}
                </StyledTableCellTextComponent>

                <StyledTableCellTextComponent padding="none">
                  {i.debtorReference}
                </StyledTableCellTextComponent>

                <StyledTableCellNumbersComponent padding="none">
                  <AmountFormatterComponent amount={i.debtAmount} />
                </StyledTableCellNumbersComponent>

                <StyledTableCellNumbersComponent padding="none">
                  <AmountFormatterComponent amount={i.outstandingBalance} />
                </StyledTableCellNumbersComponent>

                <StyledTableCellTextComponent padding="none">
                  <DateFormatterComponent date={i.issueDate} />
                </StyledTableCellTextComponent>

                <StyledTableCellTextComponent padding="none">
                  <DateFormatterComponent date={i.dueDate} />
                </StyledTableCellTextComponent>

                <StyledTableCellTextComponent padding="none">
                  <InvoiceNameComponent invoiceId={i.id} invoiceName={i.invoiceName} />
                </StyledTableCellTextComponent>
              </StyledTableRowComponent>
            ))}

            {props.invoices.length > 0 &&
              <StyledTableRowComponent selected>
                <StyledTableCellTextComponent padding="checkbox" />
                <StyledTableCellTextComponent padding="none"/>
                <StyledTableCellTextComponent padding="none">
                  <Typography variant="body1">
                    <b>
                      <IntlMessageComponent id="debtor.toPay.table.totalSellers" />
                      &nbsp;
                      <NumberFormatterComponent value={props.totalSellers} />
                    </b>
                  </Typography>
                </StyledTableCellTextComponent>
                <StyledTableCellTextComponent padding="none"/>
                <StyledTableCellTextComponent padding="none"/>
                <StyledTableCellTextComponent padding="none">
                  <Typography variant="body1">
                    <b>
                      <IntlMessageComponent id="debtor.toPay.table.totalDebtAmount" />
                      &nbsp;
                      <AmountFormatterComponent amount={props.totalDebtAmount} />
                    </b>
                  </Typography>
                </StyledTableCellTextComponent>
                <StyledTableCellTextComponent padding="none">
                  <Typography variant="body1">
                    <b>
                      <IntlMessageComponent id="debtor.toPay.table.totalOutstandingBalance" />
                      &nbsp;
                      <AmountFormatterComponent amount={props.totalOutstandingBalance} />
                    </b>
                  </Typography>
                </StyledTableCellTextComponent>                  
                <StyledTableCellTextComponent padding="none"/>
                <StyledTableCellTextComponent padding="none"/>
                <StyledTableCellTextComponent padding="none"/>
                <StyledTableCellTextComponent padding="none"/>
              </StyledTableRowComponent>
            }
          </TableBody>
        </Table>
      </Box>

      <Box display="flex" height={60}>
        <Box flexGrow={1} mt={2}>
          <ButtonComponent
            color="primary"
            variant="contained"
            disabled={props.invoices.length <= 0 || props.invoices.find(i => i.isSelected) === undefined}
            onClick={props.payInvoices}
          ><IntlMessageComponent id="general.btn.pay" /></ButtonComponent>
        </Box>

        <Box display="flex" height={60}>
          <Box flexGrow={1}></Box>
          <Box justifyContent="flex-end">
            <TablePagination
              component="div"
              count={props.invoices.length}
              rowsPerPage={props.pageSize}
              page={props.page}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePageSizeChange}
              labelRowsPerPage={<IntlMessageComponent id="general.invoicePerPage" />}
              labelDisplayedRows={({from, to, count}) => `${from}-${to} / ${count}`}
            />
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}
