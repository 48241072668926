/* @flow */

import React from 'react';
import moment from 'moment';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import AmountFormatterComponent from 'components/formatters/amount';
import DateFormatterComponent from 'components/formatters/date';

type BuyerPerformanceChartTooltipComponentProps = {
  payload: { value: string, payload: { date: moment } }[]
};

export default function BuyerPerformanceChartTooltipComponent(props: BuyerPerformanceChartTooltipComponentProps) {
  if (!props.active) return null;
  return (
    <Paper elevation={0}>
      <Box p={2} boxShadow={2}>
        <Typography variant="body1">
          <Box color="primary.main" textAlign="center" fontSize="1.25em" fontWeight="fontWeightBold">
            <AmountFormatterComponent value={props.payload[0].value} />
          </Box>
        </Typography>
        <Typography variant="body1">
          <Box color="grey.main" textAlign="center">
            <DateFormatterComponent date={props.payload[0].payload.date} format="MMMM YYYY" />
          </Box>
        </Typography>
      </Box>
    </Paper>
  );
}
