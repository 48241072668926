/* @flow */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function IconFlagPT(props) {
  return (
    <SvgIcon viewBox="0 0 512 512" {...props}>
      <path style={{fill:'#6DA544'}} d="M0,256c0,110.07,69.472,203.905,166.955,240.076l22.262-240.077L166.955,15.923 C69.472,52.095,0,145.929,0,256z"/>
      <path style={{fill:'#D80027'}} d="M512,256C512,114.616,397.384,0,256,0c-31.314,0-61.311,5.633-89.045,15.923v480.154 C194.689,506.368,224.686,512,256,512C397.384,512,512,397.384,512,256z"/>
      <circle style={{fill:'#FFDA44'}} cx="166.957" cy="256" r="89.043"/>
      <path style={{fill:'#D80027'}} d="M116.87,211.478v55.652c0,27.662,22.424,50.087,50.087,50.087s50.087-22.424,50.087-50.087v-55.652 H116.87z"/>
      <path style={{fill:'#F0F0F0'}} d="M166.957,283.826c-9.206,0-16.696-7.49-16.696-16.696V244.87h33.391v22.261 C183.652,276.336,176.162,283.826,166.957,283.826z"/>
    </SvgIcon>
  );
}
