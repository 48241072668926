/* @flow */

import React from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import CountryNameComponent from 'components/country/name';
import IntlMessageComponent from 'components/formatters/intlMessage';

import CompanyLookupBaseWithButtonComponent from '../base/button';
import SecurityComponent from 'components/security/index';

import DebtorCompany from 'models/companies/debtorCompany';

type CompanyLookupDebtorTwoColumnsViewComponentProps = {
  companyErrorLabelId: string;
  countriesForDebtors: Country[];
  knownDebtors: DebtorCompany[];
  required: boolean;
  handleKnownDebtorCompanySelect: (company: DebtorCompany) => void;
  handleSelect: (company: DebtorCompany) => void;
}

export default function CompanyLookupDebtorTwoColumnsViewComponent(props: CompanyLookupDebtorTwoColumnsViewComponentProps) {

  return (
    <React.Fragment>
      <Grid container spacing={6} alignItems="stretch">
        {props.knownDebtors && props.knownDebtors.length > 0 &&
          <Grid item xs={12} md={6}>
            <Box px={4} py={3}>
              <Autocomplete
                getOptionLabel={(option) => option.name}
                onChange={(_, newValue) => props.handleKnownDebtorCompanySelect(newValue)}
                openOnFocus
                options={props.knownDebtors}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    id="select-debtor"
                    label={<IntlMessageComponent id="component.knownDebtors.label" />}
                    fullWidth
                    margin="normal"
                  />
                }
                renderOption={(company) => (
                  <span>{company.name} - <CountryNameComponent countryId={company.countryId} /> [{company.vatNumber}]</span>
                )}
                value={null}
              />
            </Box>
          </Grid>
        }

        <Grid item xs={12} md={6}>
          <SecurityComponent pageName="POS.Offer.DebtorLookUp" elementName="btnAddNewCompanyLookUp">
            <CompanyLookupBaseWithButtonComponent
              companyErrorLabelId={props.companyErrorLabelId}
              countries={props.countriesForDebtors}
              required={props.required}
              handleSelect={props.handleSelect}
            />
          </SecurityComponent>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
