/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import AmountFormatterComponent from 'components/formatters/amount';
import ButtonComponent from 'components/buttons/button';
import CompanyAddressComponent from 'components/company/address';
import DateFormatterComponent from 'components/formatters/date';
import DebtorGradeForBuyerComponent from 'components/debtorGrades/buyer';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import InvalidLeadOfferDetailsComponent from '../invalid';

import DebtorCompanyModel from 'models/companies/debtorCompany';
import LeadOfferModel from 'models/leads/leadOffer';

type OfferTab4ISOComponentProps = {
  isProcessing: boolean;
  leadOffer: LeadOfferModel;
  newSimulationWithSameDebtor: (debtorCompany: DebtorCompanyModel) => void;
  newSimulationWithOtherDebtor: () => void;
  newSimulationWithOtherSeller: () => void;
  sendToISO: () => void;
  sendToISOAndCustomer: () => void;
};

export default function OfferTab4ISOComponent(props: OfferTab4ISOComponentProps) {
  return (
    <React.Fragment>
      <PageSubTitleViewComponent id="component.offer.tab4.summary.title.iso" data-walkme="offer-t4-summaryTitle-iso" />

      <Box mt={3}>
        <Grid container direction="row" justify="space-between" alignItems="flex-start">
          <Grid item xs={12} md={6}>
            <Box display="flex" px={4}>
              <Box flexGrow={1} mb={1}>
                <Typography variant="body1">
                  <Box component="span" color="grey.main" fontSize={13}>
                    <IntlMessageComponent id="component.offer.tab4.summary.seller.name" data-walkme="offer-t4-sellerName"/>
                  </Box>
                </Typography>
              </Box>
              <Box>
                <CompanyAddressComponent company={props.leadOffer.sellerCompany} />
              </Box>
            </Box>

            <Box display="flex" px={4}>
              <Box flexGrow={1} mb={1}>
                <Typography variant="body1">
                  <Box component="span" color="grey.main" fontSize={13}>
                    <IntlMessageComponent id="component.offer.tab4.summary.debtor.name" data-walkme="offer-t4-debtorName" />
                  </Box>
                </Typography>
              </Box>
              <Box>
                <CompanyAddressComponent company={props.leadOffer.debtorCompany} />
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box display="flex" px={4}>
              <Box flexGrow={1} mb={1}>
                <Typography variant="body1">
                  <Box component="span" color="grey.main" fontSize={13}>
                    <IntlMessageComponent id="component.offer.tab4.summary.dueDate" data-walkme="offer-t4-dueDate" />
                  </Box>
                </Typography>
              </Box>
              <Box>
                <DateFormatterComponent date={props.leadOffer.dueDate} format="DD MMMM YYYY" />
              </Box>
            </Box>

            <Box display="flex" px={4}>
              <Box flexGrow={1} mb={1}>
                <Typography variant="body1">
                  <Box component="span" color="grey.main" fontSize={13}>
                    <IntlMessageComponent id="component.offer.tab4.summary.debtAmount" data-walkme="offer-t4-debtAmount" />
                  </Box>
                </Typography>
              </Box>
              <Box>
                <AmountFormatterComponent amount={props.leadOffer.debtAmount} />
              </Box>
            </Box>

            <Box display="flex" px={4}>
              <Box flexGrow={1} mb={1}>
                <Typography variant="body1">
                  <Box component="span" color="grey.main" fontSize={13}>
                    <IntlMessageComponent id="component.offer.tab4.summary.debtor.grade.iso" data-walkme="offer-t4-debtorGrade-iso" />
                  </Box>
                </Typography>
              </Box>
              <Box>
                <DebtorGradeForBuyerComponent value={props.leadOffer.debtorCompany.insurerRating || 0} shortMessageWhenZero />
              </Box>
            </Box>

            {props.leadOffer.debtorCompany.displayWarningClaims &&
              <Box mt={3} px={3} textAlign="center" color="orange.main" fontWeight="fontWeightBold">
                <Typography variant="body1">
                  <IntlMessageComponent id="component.offer.tab4.summary.debtor.warning.claims.iso" />
                </Typography>
              </Box>
            }

            {props.leadOffer.debtorCompany.displayWarningGrade &&
              <Box mt={3} px={3} textAlign="center" color="orange.main" fontWeight="fontWeightBold">
                <Typography variant="body1">
                  <IntlMessageComponent id="component.offer.tab4.summary.debtor.warning.grade.iso" />
                </Typography>
              </Box>
            }
          </Grid>
        </Grid>
      </Box>

      {!props.leadOffer.isValid ? (
        <InvalidLeadOfferDetailsComponent
          isProcessing={props.isProcessing}
          newSimulationWithSameDebtor={props.newSimulationWithSameDebtor}
          newSimulationWithOtherDebtor={props.newSimulationWithOtherDebtor}
        />
      ) : (
        <Box mt={5}>
          <PageSubTitleViewComponent id="component.offer.tab4.prices.title.iso" data-walkme="offer-t4-pricesTitle-iso" />
          
          <Box mt={3} py={3} bgcolor="lightblue.main">
            <Grid container direction="row" justify="space-between" alignItems="flex-start">
              <Grid item xs={12} md={6}>
                <Box display="flex" px={4}>
                  <Box flexGrow={1} mb={1}>
                    <IntlMessageComponent id="component.offer.tab4.prices.financedAmount" data-walkme="offer-t4-financedAmount" />
                  </Box>
                  <Box>
                    <AmountFormatterComponent amount={props.leadOffer.financedAmount} />
                  </Box>
                </Box>

                <Box display="flex" px={4} color="primary.main" fontSize={16} fontWeight="fontWeightBold">
                  <Box flexGrow={1} mb={1}>
                    <IntlMessageComponent id="component.offer.tab4.prices.transactionPrice" data-walkme="offer-t4-transactionPrice" />
                  </Box>
                  <Box>
                    <AmountFormatterComponent amount={props.leadOffer.transactionPrice} />
                  </Box>
                </Box>

                <Box display="flex" px={4} fontWeight="fontWeightBold">
                  <Box flexGrow={1} mb={1}>
                    <IntlMessageComponent id="component.offer.tab4.prices.totalFee" data-walkme="offer-t4-totalFee" />
                  </Box>
                  <Box>
                    - <AmountFormatterComponent amount={props.leadOffer.totalFee} />
                  </Box>
                </Box>

                <Box display="flex" px={4} color="grey.main" fontSize={13}>
                  <Box flexGrow={1} mb={1} pl={2}>
                    <IntlMessageComponent id="component.offer.tab4.prices.listingFee" data-walkme="offer-t4-listingFee" />
                  </Box>
                  <Box>
                    <AmountFormatterComponent amount={props.leadOffer.listingFee} />
                  </Box>
                </Box>

                <Box display="flex" px={4} color="grey.main" fontSize={13}>
                  <Box flexGrow={1} mb={1} pl={2}>
                    <IntlMessageComponent id="component.offer.tab4.prices.variableFee" data-walkme="offer-t4-variableFee" />
                  </Box>
                  <Box>
                    <AmountFormatterComponent amount={props.leadOffer.variableFee} />
                  </Box>
                </Box>
              </Grid>
              
              <Grid item xs={12} md={6}>
                <Box p={3}>
                  <ButtonComponent
                    color="primary"
                    variant="contained"
                    isProcessing={props.isProcessing}
                    onClick={props.sendToISO}
                    data-walkme="offer-t4-sendtoiso"
                  ><IntlMessageComponent id="component.offer.tab4.button.sendToISO" /></ButtonComponent>

                  <Box mt={1}>
                    <ButtonComponent
                      color="primary"
                      variant="contained"
                      isProcessing={props.isProcessing}
                      onClick={props.sendToISOAndCustomer}
                      data-walkme="offer-t4-sendtoisoandcustomer"
                    ><IntlMessageComponent id="component.offer.tab4.button.sendToISOAndCustomer" /></ButtonComponent>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>

          {props.leadOffer.isAwaitingDebtorGrade &&
            <Box mt={3} p={3} bgcolor="lightblue.main" textAlign="center">
              <IntlMessageComponent id="component.offer.tab4.waitingGrade" />
            </Box>
          }

          <Box mt={5}>
            <ButtonComponent
              color="primary"
              variant="outlined"
              isProcessing={props.isProcessing}
              onClick={props.newSimulationWithSameDebtor}
              data-walkme="offer-t4-samedebtor-iso"
            ><IntlMessageComponent id="component.offer.tab4.button.restartSameDebtor.iso" /></ButtonComponent>

            <Box component="span" ml={1}>
              <ButtonComponent
                color="primary"
                variant="outlined"
                isProcessing={props.isProcessing}
                onClick={props.newSimulationWithOtherDebtor}
                data-walkme="offer-t4-differentdebtor-iso"
              ><IntlMessageComponent id="component.offer.tab4.button.restartDifferentDebtor.iso" /></ButtonComponent>
            </Box>

            <Box component="span" ml={1}>
              <ButtonComponent
                color="primary"
                variant="outlined"
                isProcessing={props.isProcessing}
                onClick={props.newSimulationWithOtherSeller}
                data-walkme="offer-t4-differentseller-iso"
              ><IntlMessageComponent id="component.offer.tab4.iso.restartDifferentSeller.iso" /></ButtonComponent>
            </Box>
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
}
