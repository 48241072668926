/* @flow */

import React from 'react';

import CloudUpload from '@material-ui/icons/CloudUpload';
import IconButton from '@material-ui/core/IconButton';

import UploadComponent from 'components/upload';

type UploadFileIconButtonComponentProps = {
  disabled: boolean;
  successHandler: (file: File) => void;
  failHandler: () => void;
}

export default function UploadFileIconButtonComponent(props: UploadFileIconButtonComponentProps) {
  return (
    <UploadComponent
      accept="application/pdf, image/jpeg, image/png"
      disabled={props.disabled}
      successHandler={props.successHandler}
      failHandler={props.failHandler}
    >
      <IconButton
        disabled={props.disabled}
      ><CloudUpload /></IconButton>
    </UploadComponent>
  );
}

