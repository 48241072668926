/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';

import IntlMessageComponent from 'components/formatters/intlMessage';

type MandatoryFormFieldsComponentProps = {
  id: string;
  values: Any;
}

export default function MandatoryFormFieldsComponent(props: MandatoryFormFieldsComponentProps) {
  const id = props.id ?? 'form.mandatoryFields';
  
  return (
    <Box component="div" display="inline" color="text.secondary" fontSize={12} fontStyle="italic" ml={2}>
      <IntlMessageComponent id={id} values={props.values} />
    </Box>
  );
}