/* @flow */

import React from 'react';
import {useHistory} from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';

import {PROTECTED_APP_TOS} from 'constants/pageRoutes';

export default function TermsAndConditionsFailureComponent() {
  const history = useHistory();

  return (
    <React.Fragment>
      <Typography variant="body1">
        <IntlMessageComponent id="page.tos.failure" />
      </Typography>
      
      <Box mt={3}>
        <ButtonComponent
          color="primary"
          variant="contained"
          onClick={() => history.push(PROTECTED_APP_TOS)}
        ><IntlMessageComponent id="general.retry" /></ButtonComponent>
      </Box>
    </React.Fragment>
  );
}
