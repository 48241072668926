/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import Box from '@material-ui/core/Box';

import LoadingComponent from 'components/loading';
import IntlMessageComponent from 'components/formatters/intlMessage';

import CommissionFiltersComponent from './filters';
import CommissionInvoiceTableComponent from './invoiceTable';
import CommissionMembershipTableComponent from './membershipTable';
import CommissionMsfTableComponent from './msfTable';
import CommissionSummaryTableComponent from './summaryTable';

import CommissionFilters from 'models/isos/commissions/commissionFilters';
import Filter from 'models/isos/filters/filter';
import FilterCompany from 'models/isos/filters/filterCompany';
import FilterOffice from 'models/isos/filters/filterOffice';

import CommissionInvoice from 'models/isos/commissions/commissionInvoice';
import CommissionMembership from 'models/isos/commissions/commissionMembership';
import CommissionPayment from 'models/isos/commissions/commissionPayment';
import CommissionSummary from 'models/isos/commissions/commissionSummary';
import CommissionMsf from 'models/isos/commissions/commissionMsf';

import DownloadCommissionReportRequest from 'models/requests/isos/downloadCommissionReportRequest';

import IsoService from 'services/IsoService';

type ISOCommissionsComponentProps = {
  activeCompanyId: number;
}

type ISOCommissionsComponentState = {
  filters: CommissionFilters;
  invoices: CommissionInvoice[];
  isLoading: boolean;
  memberships: CommissionMembership[];
  payments: CommissionPayment[];
  summaries: CommissionSummary[];
  msfs: CommissionMsf[];
}

class ISOCommissionsComponent extends React.Component<ISOCommissionsComponentProps, ISOCommissionsComponentState> {

  constructor(props) {
    super(props);
    this.state = {
      filters: null,
      invoices: null,
      isLoading: true,
      memberships: null,
      msfs: null,
      payments: null,
      summaries: null
    };
  }

  componentDidMount = async () => {
    try {
      const response = await IsoService.getCommissionReport(this.props.activeCompanyId);
      const filters = this.setFilterModel(response.summaries, CommissionFilters.MODE_SUMMARY, true);
      this.setState({
        filters,
        invoices: response.invoices,
        isLoading: false,
        memberships: response.memberships,
        payments: response.payments,
        summaries: response.summaries,
        msfs: response.msfs
      });
    } catch (e) {
      console.error(e);
    }
  }

  getFilteredData = () => {
    let data = [];
    if (this.state.filters.mode === CommissionFilters.MODE_SUMMARY) data = this.state.summaries;
    if (this.state.filters.mode === CommissionFilters.MODE_INVOICES) data = this.state.invoices;
    if (this.state.filters.mode === CommissionFilters.MODE_MEMBERSHIPS) data = this.state.memberships;
    if (this.state.filters.mode === CommissionFilters.MODE_MSFS) data = this.state.msfs;

    // Apply company/office/person filtering
    const filters = this.filterToObject();
    data = this.multiFilter(data, filters);

    // Apply period filtering
    if (this.state.filters.period.dateFrom !== null && this.state.filters.period.dateTo !== null) {
      data = data.filter((d) => {
        const filterPeriod = moment(d.filterPeriod);
        return filterPeriod.isSameOrAfter(this.state.filters.period.dateFrom, 'day') && filterPeriod.isSameOrBefore(this.state.filters.period.dateTo, 'day');
      });
    }

    return data;
  }

  setFilterModel = (data: any, mode: number, firstLoad: boolean) => {
    var filters;

    if(firstLoad) {
      filters = new CommissionFilters(mode);
    }
    else {
      filters = this.state.filters;
      filters.mode = mode;
    }

    for (let i = 0; i < data.length; i++) {
      let isoCompany = filters.companies.find(c => c.id === data[i].filterISOId);
      if (isoCompany === undefined) {
        let isoCompanyName = data[i].isoCompany.length === 0 ? <IntlMessageComponent id="general.filters.unspecifiedcompany" /> : data[i].isoCompany;
        isoCompany = new FilterCompany(data[i].filterISOId, isoCompanyName);
        filters.companies.push(isoCompany);
      }

      let isoOffice = isoCompany.offices.find(o => o.id === data[i].filterISOOfficeId);
      if (isoOffice === undefined) {
        let isoOfficeName = data[i].isoOffice.length === 0 ? <IntlMessageComponent id="general.filters.unspecifiedoffice" /> : data[i].isoOffice;
        isoOffice = new FilterOffice(data[i].filterISOOfficeId, isoOfficeName);
        isoCompany.offices.push(isoOffice);
      }

      let isoPerson = isoOffice.persons.find(p => p.id === data[i].filterISOPersonId);
      if (isoPerson === undefined) {
        let isoPersonName = data[i].isoPerson.length === 0 ? <IntlMessageComponent id="general.filters.unspecifiedperson" /> : data[i].isoPerson;
        isoPerson = new Filter(data[i].filterISOPersonId, isoPersonName);
        isoOffice.persons.push(isoPerson);
      }
    }

    return filters;
  }

  filterToObject = () => {
    const filters = { filterISOId: [], filterISOOfficeId: [], filterISOPersonId: [] };
    for (let i = 0; i < this.state.filters.companies.length; i++) {
      if (this.state.filters.companies[i].isSelected) {
        filters.filterISOId.push(this.state.filters.companies[i].id);
      }

      for (let j = 0; j < this.state.filters.companies[i].offices.length; j++) {
        if (this.state.filters.companies[i].offices[j].isSelected) {
          filters.filterISOOfficeId.push(this.state.filters.companies[i].offices[j].id);
        }

        for (let k = 0; k < this.state.filters.companies[i].offices[j].persons.length; k++) {
          if (this.state.filters.companies[i].offices[j].persons[k].isSelected) {
            filters.filterISOPersonId.push(this.state.filters.companies[i].offices[j].persons[k].id);
          }
        }
      }
    }
    return filters;
  }

  multiFilter = (array, filters) => {
    const filterKeys = Object.keys(filters);
    // filters all elements passing the criteria
    return array.filter(item => filterKeys.every((key) => {
      // ignores an empty filter
      if (!filters[key].length) return true;
      return filters[key].includes(item[key]);
    }));
  }

  selectFilterISO = (filterId: string, optionId: number) => {
    const filters = this.state.filters;

    if (filterId === 'company') {
      for (let i = 0; i < filters.companies.length; i++) {
        if (filters.companies[i].id === optionId) {
          filters.companies[i].isSelected = !filters.companies[i].isSelected;

          if (!filters.companies[i].isSelected) {
            // if we unselect a company, unselect all its offices & persons
            for (let j = 0; j < filters.companies[i].offices.length; j++) {
              filters.companies[i].offices[j].isSelected = false;
              for (let k = 0; k < filters.companies[i].offices[j].persons.length; k++) {
                filters.companies[i].offices[j].persons[k].isSelected = false;
              }
            }
          }
        }
      }
    }

    if (filterId === 'office') {
      for (let i = 0; i < filters.companies.length; i++) {
        for (let j = 0; j < filters.companies[i].offices.length; j++) {
          if (filters.companies[i].offices[j].id === optionId) {
            filters.companies[i].offices[j].isSelected = !filters.companies[i].offices[j].isSelected;

            if (filters.companies[i].offices[j].isSelected) {
              // if we select an office, select its company
              filters.companies[i].isSelected = true;
            } else {
              // if we unselect an office, unselect all its persons
              for (let k = 0; k < filters.companies[i].offices[j].length; k++) {
                filters.companies[i].offices[j].persons[k].isSelected = false;
              }
            }
          }
        }
      }
    }

    if (filterId === 'person') {
      for (let i = 0; i < filters.companies.length; i++) {
        for (let j = 0; j < filters.companies[i].offices.length; j++) {
          for (let k = 0; k < filters.companies[i].offices[j].persons.length; k++) {
            if (filters.companies[i].offices[j].persons[k].id === optionId) {
              filters.companies[i].offices[j].persons[k].isSelected = !filters.companies[i].offices[j].persons[k].isSelected;

              if (filters.companies[i].offices[j].persons[k].isSelected) {
                // if we select a person, select its company and office
                filters.companies[i].isSelected = true;
                filters.companies[i].offices[j].isSelected = true;
              }
            }
          }
        }
      }
    }

    this.setState({ filters });
  }

  selectFilterMode = (mode: number) => {
    if (this.state.filters.mode === mode) return;

    let data = [];
    if (mode === CommissionFilters.MODE_SUMMARY) data = this.state.summaries;
    if (mode === CommissionFilters.MODE_INVOICES) data = this.state.invoices;
    if (mode === CommissionFilters.MODE_MSFS) data = this.state.msfs;
    if (mode === CommissionFilters.MODE_MEMBERSHIPS) data = this.state.memberships;

    const filters = this.setFilterModel(data, mode, false);

    this.setState({ filters });
  }

  selectFilterPeriod = (filterId: string, dateFrom: moment, dateTo: moment) => {
    const filters = this.state.filters;
    filters[filterId].dateFrom = dateFrom;
    filters[filterId].dateTo = dateTo;
    this.setState({ filters });
  }

  downloadCommissionReport = async (format: string) => {
    try {
      let type = 'Summary';
      if (this.state.filters.mode === CommissionFilters.MODE_INVOICES) type = 'Invoices';
      if (this.state.filters.mode === CommissionFilters.MODE_MSFS) type = 'Msfs';
      if (this.state.filters.mode === CommissionFilters.MODE_MEMBERSHIPS) type = 'Memberships';

      const request = new DownloadCommissionReportRequest(this.props.activeCompanyId, format, type);

      for (let i = 0; i < this.state.filters.companies.length; i++) {
        if (this.state.filters.companies[i].isSelected) {
          request.isoCompanyIds.push(this.state.filters.companies[i].id);
        }
  
        for (let j = 0; j < this.state.filters.companies[i].offices.length; j++) {
          if (this.state.filters.companies[i].offices[j].isSelected) {
            request.isoOfficeIds.push(this.state.filters.companies[i].offices[j].id);
          }
  
          for (let k = 0; k < this.state.filters.companies[i].offices[j].persons.length; k++) {
            if (this.state.filters.companies[i].offices[j].persons[k].isSelected) {
              request.isoPersonIds.push(this.state.filters.companies[i].offices[j].persons[k].id);
            }
          }
        }
      }

      if (this.state.filters.period.dateFrom && this.state.filters.period.dateTo) {
        request.periodDateFrom = this.state.filters.period.dateFrom.format('YYYY-MM-DD');
        request.periodDateTo = this.state.filters.period.dateTo.format('YYYY-MM-DD');
      }

      await IsoService.downloadCommissionReport(request);
    } catch (e) {
      console.error(e);
    }
  }

  clearFilters = () => {
    if (this.state.filters === null) return;

    const filters = this.state.filters;

    for (let i = 0; i < filters.companies.length; i++) {
      filters.companies[i].isSelected = false;
      for (let j = 0; j < filters.companies[i].offices.length; j++) {
        filters.companies[i].offices[j].isSelected = false;
        for (let k = 0; k < filters.companies[i].offices[j].persons.length; k++) {
          filters.companies[i].offices[j].persons[k].isSelected = false;
        }
      }
    }

    filters.period.dateFrom = null;
    filters.period.dateTo = null;

    this.setState({filters});
  }

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <React.Fragment>
        <CommissionFiltersComponent
          filters={this.state.filters}
          selectFilterISO={this.selectFilterISO}
          selectFilterMode={this.selectFilterMode}
          selectFilterPeriod={this.selectFilterPeriod}
          downloadCommissionReport={this.downloadCommissionReport}
          clearFilters={this.clearFilters}
        />

        <Box mt={4} data-walkme="isocommission-walkthru-step4">
          {this.state.filters.mode === CommissionFilters.MODE_SUMMARY &&
            <CommissionSummaryTableComponent payments={this.state.payments} summaries={this.getFilteredData()} />
          }
          {this.state.filters.mode === CommissionFilters.MODE_INVOICES &&
              <CommissionInvoiceTableComponent invoices={this.getFilteredData()} />
          }
          {this.state.filters.mode === CommissionFilters.MODE_MSFS &&
            <CommissionMsfTableComponent msfs={this.getFilteredData()} />
          }
          {this.state.filters.mode === CommissionFilters.MODE_MEMBERSHIPS &&
            <CommissionMembershipTableComponent memberships={this.getFilteredData()} />
          }
        </Box>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  activeCompanyId: auth.user.activeCompany.id
});

export default connect(mapStateToProps)(ISOCommissionsComponent);
