/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import AmountFormatterComponent from 'components/formatters/amount';
import CompanyAddressComponent from 'components/company/address';
import DateFormatterComponent from 'components/formatters/date';
import IntlMessageComponent from 'components/formatters/intlMessage';

import InvoiceProposal from 'models/invoices/seller/invoiceProposal';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

type SubmitinvoiceTab4SummaryComponentProps = {
  invoice: InvoiceProposal;
}

export default function SubmitinvoiceTab4SummaryComponent(props: SubmitinvoiceTab4SummaryComponentProps) {
  return (
    <React.Fragment>
      <PageSubTitleViewComponent id="page.submitinvoice.tab4.summary.title" data-walkme="sell-t5-summaryTitle" />

      <Box mt={3}>
        <Grid container direction="row" justify="space-between" alignItems="flex-start">
          <Grid item xs={12} md={6}>
            <Box display="flex" px={4}>
              <Box flexGrow={1} mb={1}>
                <Typography variant="body1">
                  <Box component="span" color="grey.main" fontSize={13}>
                    <IntlMessageComponent id="page.submitinvoice.tab4.summary.supplier.name" data-walkme="sell-t5-supplierName" />
                  </Box>
                </Typography>
              </Box>
              <Box>
                <CompanyAddressComponent company={props.invoice.supplierCompany} />
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box display="flex" px={4}>
              <Box flexGrow={1} mb={1}>
                <Typography variant="body1">
                  <Box component="span" color="grey.main" fontSize={13}>
                    <IntlMessageComponent id="page.submitinvoice.tab4.summary.duedate" data-walkme="sell-t5-dueDate" />
                  </Box>
                </Typography>
              </Box>
              <Box>
                <DateFormatterComponent date={props.invoice.dueDate} format="DD MMMM YYYY" />
              </Box>
            </Box>
            <Box display="flex" px={4}>
              <Box flexGrow={1} mb={1}>
                <Typography variant="body1">
                  <Box component="span" color="grey.main" fontSize={13}>
                    <IntlMessageComponent id="page.submitinvoice.tab4.summary.confirmingduedate" data-walkme="sell-t5-dueDate" />
                  </Box>
                </Typography>
              </Box>
              <Box>
                <DateFormatterComponent date={props.invoice.confirmingDueDate} format="DD MMMM YYYY" />
              </Box>
            </Box>

            <Box display="flex" px={4}>
              <Box flexGrow={1} mb={1}>
                <Typography variant="body1">
                  <Box component="span" color="grey.main" fontSize={13}>
                    <IntlMessageComponent id="page.submitinvoice.tab4.summary.debtamount" data-walkme="sell-t5-debtAmount" />
                  </Box>
                </Typography>
              </Box>
              <Box>
                <AmountFormatterComponent amount={props.invoice.debtAmount} />
              </Box>
            </Box>

          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}