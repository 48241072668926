/* @flow */

import ChangePasswordRequest from 'models/requests/users/changePasswordRequest';

export default class ChangePasswordForm {
  currentPassword: string;
  password: string;
  confirmPassword: string;
  twoFactorPin: String;

  constructor() {
    this.currentPassword = '';
    this.password = '';
    this.confirmPassword = '';
    this.twoFactorPin = '';
  }

  toRequest() {
    const request = new ChangePasswordRequest();
    request.currentPassword = this.currentPassword;
    request.newPassword = this.password;
    return request;
  }
}
