/* @flow */

import moment from 'moment';

export default class LeadDetails {
  // data
  isoCompany: string;
  isoOffice: string;
  isoOfficeId: number;
  isoPerson: string;
  isoPersonId: number;
  ownerClass: string;
  
  leadCompanyName: string;
  leadCompanyVAT: string;
  leadCompanyAddress: string;
  statusId: number;

  creationDate: moment;
  closedDate: moment;
  age: number;

  leadContactFirstName: string;
  leadContactLastName: string;
  leadContactEmail: string;
  leadContactPhoneNumber: string;
  leadContactMobileNumber: string;

  convertible: string;
  lostReasonId: number;
  leadId: number;
  canBeExtended: boolean;
  reopenedForOtherIso: boolean;

  leadReopenedId: number;
}
