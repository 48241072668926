/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import customSort from 'lib/sortHelpers';

import LoadingComponent from 'components/loading';

import CompanyDocumentsTableControlsComponent from './controls';
import CompanyDocumentsTableViewComponent from './view';

import CompanyDocumentActiveFilters from 'models/companies/companyDocumentActiveFilters';
import CompanyDocumentFilters from 'models/companies/companyDocumentFilters';
import DocumentItem from 'models/documents/documentItem';

import SignDocumentTransationRequest from 'models/requests/documents/signDocumentTransationRequest';

import DocumentService from 'services/DocumentService';
import NotificationHelper from 'lib/notifications';

import {PROTECTED_COMPANY_DOCUMENTS} from 'constants/pageRoutes';
import {ROLE_ISO, ROLE_BUYER, ROLE_SELLER} from 'constants/companyRoles';

type CompanyDocumentsTableComponentProps = {
  activeCompanyId: number;
  activeCompanyRole: number;
}

type CompanyDocumentsTableComponentState = {
  activeFilters: CompanyDocumentActiveFilters;
  documents: DocumentItem[];
  filters: CompanyDocumentFilters;
  isLoading: boolean;
  order: string;
  orderBy: ?string;
  page: number;
  pageSize: number;
}

class CompanyDocumentsTableComponent extends React.Component<CompanyDocumentsTableComponentProps, CompanyDocumentsTableComponentState> {
  constructor(props) {
    super(props);
    this.state = {
      activeFilters: new CompanyDocumentActiveFilters(),
      documents: null,
      filters: null,
      isLoading: true,
      order: 'asc',
      orderBy: undefined,
      page: 0,
      pageSize: 10
    };
  }

  componentDidMount = async () => {
    try {
      const response = await DocumentService.getCompanyDocuments(this.props.activeCompanyId, this.props.activeCompanyRole);
      const filters = new CompanyDocumentFilters();
      filters.documentTypeIds = response.documentTypeIds;

      this.setState({
        documents: response.documents,
        filters,
        isLoading: false
      });
    } catch (e) {
      console.error(e);
    }
  }

  reloadDocuments = async () => {
    this.setState({
      activeFilters: new CompanyDocumentActiveFilters(),
      documents: null,
      filters: null,
      isLoading: true,
      order: 'asc',
      orderBy: undefined,
      page: 0,
      pageSize: 10
    });

    try {
      const response = await DocumentService.getCompanyDocuments(this.props.activeCompanyId, this.props.activeCompanyRole);
      const filters = new CompanyDocumentFilters();
      filters.documentTypeIds = response.documentTypeIds;

      this.setState({
        documents: response.documents,
        filters,
        isLoading: false
      });
    } catch (e) {
      console.error(e);
    }
  }

  downloadDocument = async (documentId: number) => {
    try {
      await DocumentService.downloadCompanyDocument(this.props.activeCompanyId, documentId);
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  }

  getFilteredResults = () => {
    let filteredDocuments = this.state.documents;

    if (this.state.activeFilters.documentTypeId > 0) {
      filteredDocuments = filteredDocuments.filter((d: DocumentItem) => d.typeId === this.state.activeFilters.documentTypeId);
    }

    return filteredDocuments;
  };

  getWalkmePrefix = () => {
    if (this.props.activeCompanyRole === ROLE_ISO) return 'isodocuments';
    if (this.props.activeCompanyRole === ROLE_BUYER) return 'buyerdocuments';
    if (this.props.activeCompanyRole === ROLE_SELLER) return 'sellerdocuments';
  }

  handleFiltersChange = (activeFilters: CompanyDocumentActiveFilters) => this.setState({activeFilters});

  handlePageChange = (page: number) => this.setState({page});
  handlePageSizeChange = (pageSize: number) => this.setState({pageSize});

  handleSortOrderChange = (orderBy: string) => {
    let order = this.state.orderBy === orderBy && this.state.order === 'desc' ? 'asc' : 'desc';

    const documents = order === 'desc'
    ? this.state.documents.sort((a, b) => customSort(b[orderBy], a[orderBy]))
    : this.state.documents.sort((a, b) => customSort(a[orderBy], b[orderBy]));

    this.setState({ documents, order, orderBy });
  };

  signDocument = async (documentId: number) => {
    try {
      const request = new SignDocumentTransationRequest(this.props.activeCompanyId, documentId, PROTECTED_COMPANY_DOCUMENTS);
      const transaction = await DocumentService.signDocument(request);
      window.location.replace(transaction.url);
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  }

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <React.Fragment>
        <CompanyDocumentsTableControlsComponent
          activeFilters={this.state.activeFilters}
          filters={this.state.filters}
          handleFiltersChange={this.handleFiltersChange}
        />

        <CompanyDocumentsTableViewComponent
          documents={this.getFilteredResults()}
          order={this.state.order}
          orderBy={this.state.orderBy}
          page={this.state.page}
          pageSize={this.state.pageSize}
          walkmePrefix={this.getWalkmePrefix()}
          downloadDocument={this.downloadDocument}
          handlePageChange={this.handlePageChange}
          handlePageSizeChange={this.handlePageSizeChange}
          handleSortOrderChange={this.handleSortOrderChange}
          signDocument={this.signDocument}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  activeCompanyId: auth.user.activeCompany.id,
  activeCompanyRole: auth.user.activeCompany.role
});

export default connect(mapStateToProps)(CompanyDocumentsTableComponent);
