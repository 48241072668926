/* @flow */

import {connect} from 'react-redux';
import TypeFieldComponent from './typeImpl';
import { BUYER_ROLE_ADMINISTRATOR, BUYER_ROLE_BASIC } from 'constants/constants';

const mapStateToProps = (state, props) => ({
  ...props,
  isLoading: state.locale.genericDataIsLoading,
  values: props.isAdministrator || (props.hasProcurationUsers && props.hasProcurationGeneralConditions) ? state.locale.rolesForBuyers :
          props.hasAdministratorRole ? state.locale.rolesForBuyers.filter(r => r.id !== BUYER_ROLE_BASIC) :
          props.hasProcuration ? state.locale.rolesForBuyers.filter(r => r.id !== BUYER_ROLE_ADMINISTRATOR) :
          state.locale.rolesForBuyers.filter(r => r.id !== BUYER_ROLE_ADMINISTRATOR && r.id !== BUYER_ROLE_BASIC)
});

export default connect(mapStateToProps)(TypeFieldComponent);
