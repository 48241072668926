/* @flow */

import React from 'react';

import CompanyInfoComponent from 'app/companies/info';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function CompanyInfoPage() {
  return (
    <WebsitePageComponent id="page.company.details.h1" noPaper>
      <CompanyInfoComponent />
    </WebsitePageComponent>
  );
}
