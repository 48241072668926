/* @flow */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';

import IconFlagBEComponent from 'components/icons/flags/flagBE.js';
import IconFlagFRComponent from 'components/icons/flags/flagFR.js';
import IconFlagNLComponent from 'components/icons/flags/flagNL.js';
import IconFlagPTComponent from 'components/icons/flags/flagPT.js';
import IconFlagUSComponent from 'components/icons/flags/flagUS.js';
import IconFlagESComponent from 'components/icons/flags/flagES.js';
import IntlMessageComponent from 'components/formatters/intlMessage';

import WebsiteLanguage from 'models/generics/websiteLanguage';

import { switchLanguageAction } from 'store/actions/localeActions';

const useStyles = makeStyles(_ => ({
  menuItem: {
    cursor: 'pointer'
  }
}));

export default function WebsiteHeaderLanguageSwitcherComponent() {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const dispatch = useDispatch();
  const classes = useStyles();

  const locale = useSelector(state => state.locale.locale);
  const websiteLanguages = useSelector(state => state.locale.websiteLanguages);
  const currentLanguage = websiteLanguages.find(l => l.locale === locale);

  const flipOpen = () => setOpen(prevOpen => !prevOpen);

  const getIconFlag = (countryCode: string) => {
    switch (countryCode) {
      case 'be':
        return <IconFlagBEComponent />;
      case 'fr':
        return <IconFlagFRComponent />;
      case 'nl':
        return <IconFlagNLComponent />;
      case 'pt':
        return <IconFlagPTComponent />;
      case 'us':
        return <IconFlagUSComponent />;
      case 'es':
        return <IconFlagESComponent />;
      default:
        return null;
    }
  }

  const switchLanguage = (websiteLanguage: WebsiteLanguage) => {
    flipOpen();
    dispatch(switchLanguageAction(websiteLanguage.locale));
  };

  return (
    <React.Fragment>
      <IconButton
        ref={anchorRef}
        color="inherit"
        aria-controls="language-popup"
        aria-haspopup="true"
        onClick={flipOpen}
      >
        {getIconFlag(currentLanguage.countryCode)}

        <Box color="primary.main" ml={1}>
          <ArrowDropDownIcon fontSize="small" />
        </Box>
      </IconButton>

      <Popover
        id='language-popup'
        open={open}
        anchorEl={anchorRef.current}
        onClose={flipOpen}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
      >
        <MenuList>
          {websiteLanguages.map((websiteLanguage, index) => (
            <MenuItem
              className={classes.menuItem}
              component="a"
              data-no-link="true"
              key={`ls-${index}`}
              onClick={() => switchLanguage(websiteLanguage)}
            >
              <Box>
                {getIconFlag(websiteLanguage.countryCode)}
                <Box component="span" mb={0} ml={2}><IntlMessageComponent id={`general.languages.${websiteLanguage.locale}`} /></Box>
              </Box>
            </MenuItem>
          ))}
        </MenuList>
      </Popover>
    </React.Fragment>
  );
}
