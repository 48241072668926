/* @flow */

export default class DebtorInfo {
  lastName: string;
  firstName: string;
  email: string;
  functionName: string;

  constructor() {
    this.lastName = '';
    this.firstName = '';
    this.email = '';
    this.functionName = '';
  }
}
