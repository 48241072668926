import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import DateFormatterComponent from 'components/formatters/date';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import CompanyMembership from 'models/companies/companyMembership';

type PaymentConfirmationMembershipComponentProps = {
  membership: CompanyMembership;
}

export default function PaymentConfirmationMembershipComponent(props: PaymentConfirmationMembershipComponentProps) {
  return (
    <React.Fragment>
      <PageSubTitleViewComponent id="page.payment.membership.title" />

      <Typography variant="body1" gutterBottom>
        <IntlMessageComponent id="page.payment.membership.description" />
      </Typography>

      <Box mt={3} py={3} bgcolor="lightblue.main">
        <Grid container direction="row" justify="space-between" alignItems="flex-start">
          <Grid item xs={12} md={6}>
            <Box display="flex" px={4}>
              <Box flexGrow={1} mb={1}>
                <Typography variant="body1">
                  <Box component="span" color="grey.main" fontSize={13}>
                    <IntlMessageComponent id="page.payment.membership.validFrom.label" />
                  </Box>
                </Typography>
              </Box>
              <Box>
                <DateFormatterComponent date={props.membership.validFrom} />
              </Box>
            </Box>

            <Box display="flex" px={4}>
              <Box flexGrow={1} mb={1}>
                <Typography variant="body1">
                  <Box component="span" color="grey.main" fontSize={13}>
                    <IntlMessageComponent id="page.payment.membership.validTo.label" />
                  </Box>
                </Typography>
              </Box>
              <Box>
                <DateFormatterComponent date={props.membership.validTo} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}
