/* @flow */


import React from 'react';
import {connect} from 'react-redux';

import LoadingComponent from 'components/loading';

import ReferralTab1ViewComponent from './view';
import RegisterReferralForm from './model';

import Company from 'models/companies/company';
import RegisterReferralResponse from 'models/responses/sellers/registerReferralResponse';

import RegisterService from 'services/registerService';
import SellerService from 'services/sellerService';
import {handleApiFormResponse, handleFormChange, handleFormValidation} from 'lib/forms';
import loadConstraints from 'lib/validation/loadConstraints';

type ReferralTab1ComponentProps = {
  activeCompanyId: number;
  successHandler: (response: RegisterReferralResponse) => void;
}

type ReferralTab1ComponentState = {
  constraints: any;
  errors: Map<string, ?string>;
  form: RegisterReferralForm;
  formErrors: string[];
  isLoading: boolean;
  isProcessing: boolean;
}

class ReferralTab1Component extends React.Component<ReferralTab1ComponentProps, ReferralTab1ComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      constraints: null,
      errors: null,
      form: new RegisterReferralForm(),
      formErrors: [],
      isLoading: true,
      isProcessing: false
    };
  }

  componentDidMount = async () => {
    try {
      const constraints = await loadConstraints('referral', ['company', 'firstName', 'lastName', 'mobilePresence', 'email', 'languageId']);
      this.setState({
        constraints,
        errors: new Map(Object.keys(constraints).map(e => [e, undefined])),
        isLoading: false
      });
    } catch (e) {
      console.error(e);
    }
  };

  handleChange = (fieldName: string) => (event) => handleFormChange.call(this, fieldName, event.target.value);
  handleTypeChange = (fieldName: string) => (id: number) => handleFormChange.call(this, fieldName, id);
  handlePhoneChange = (value: string) => {
    handleFormChange.call(this, 'mobilePresence', value);
  }
  validateForm = () => handleFormValidation.call(this);

  handleCompanyChange = (company: ?Company) => {
    const form = this.state.form;
    form.company = company;

    const errors = this.state.errors;
    errors.delete('company');

    this.setState({errors, form});
  };

  submitForm = async () => {
    if (!this.validateForm())
    {
      const request = this.state.form.toIncompleteAlertRequest(this.props.activeCompanyId, "MGM");
      const response = await RegisterService.incompleteAlert(request);
      return;
    }

    this.setState({formErrors: [], isProcessing: true});

    try {
      const request = this.state.form.toRequest(this.props.activeCompanyId);
      const response = await SellerService.registerReferral(request);
      this.props.successHandler(response);
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }
  };

  render() {
    if (this.state.isLoading) return (<LoadingComponent />);

    return (
      <ReferralTab1ViewComponent
        errors={this.state.errors}
        form={this.state.form}
        formErrors={this.state.formErrors}
        isProcessing={this.state.isProcessing}
        handleChange={this.handleChange}
        handleCompanyChange={this.handleCompanyChange}
        handleTypeChange={this.handleTypeChange}
        handlePhoneChange={this.handlePhoneChange}
        submitForm={this.submitForm}
      />
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps)(ReferralTab1Component);
