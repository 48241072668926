/* @flow */

export default class UpdateInvestmentProfileRequest {
  name: String;
  companyId: number;
  investmentProfileId: number;
  bankAccountId: number;
  amount: number;
  minInvoiceAmount: number;
  maxInvoiceAmount: number;
  pct90: number;
  comment: string;
  marketPlace: string;
}
