/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import SellInvoiceTab1ViewComponent from './view';

import DebtorCompany from 'models/companies/debtorCompany';
import LeadOffer from 'models/leads/leadOffer';
import SellInvoiceTab1Form from './model';

import CompanyService from 'services/CompanyService';
import CompanyLookupService from 'services/CompanyLookupService';
import {handleApiFormResponse} from 'lib/forms';
import NotificationHelper from 'lib/notifications';

type SellInvoiceTab1ComponentProps = {
  activeCompanyId: number;
  leadOffer: LeadOffer;
  successHandler: (debtorCompany: DebtorCompany) => void;
}

type SellInvoiceTab1ComponentState = {
  form: SellInvoiceTab1Form;
  formErrors: string[];
  isProcessing: boolean;
}

class SellInvoiceTab1Component extends React.Component<SellInvoiceTab1ComponentProps, SellInvoiceTab1ComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      form: new SellInvoiceTab1Form(props.leadOffer?.debtorCompany || null),
      formErrors: [],
      isProcessing: false
    };
  }

  handleBooleanChange = (fieldName: string) => (event) => {
    const form = this.state.form;
    form[fieldName] = event.target.value === 'true';
    this.setState({form});
  };

  handleDebtorCompanyChange = (debtorCompany: DebtorCompany) => {
    const form = this.state.form;
    form.debtorCompany = debtorCompany;
    this.setState({form, formErrors:[]});
  };

  submitForm = async () => {
    if (this.state.form.DebtorCompany === null || !this.state.form.deliveredGoods) return;
    this.setState({formErrors: [], isProcessing: true});

    let debtorCompanyId = this.state.form.debtorCompany.id;

    if (debtorCompanyId === 0) {
      try {
        debtorCompanyId = await CompanyService.addDebtorCompany(this.state.form.debtorCompany.insurerId);
      } catch (e) {
        handleApiFormResponse.call(this, e);
        return;
      }
    }

    // we have to load the debtor to make sure he's insurable
    // (CompanyLookup does not return isInsurable info)
    try {
      const debtorCompany = await CompanyLookupService.getDebtorCompany(this.props.activeCompanyId, debtorCompanyId);
      if (debtorCompany.isInsurable) {
        this.props.successHandler(debtorCompany);
      } else {
        this.setState({
          debtorCompany,
          formErrors: ['general.debtor.notInsurable'],
          isProcessing: false
        });
      }
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
      this.setState({isProcessing: false});
    }
  }

  render() {    
    return (
      <SellInvoiceTab1ViewComponent
        form={this.state.form}
        formErrors={this.state.formErrors}
        isProcessing={this.state.isProcessing}
        handleBooleanChange={this.handleBooleanChange}
        handleDebtorCompanyChange={this.handleDebtorCompanyChange}
        submitForm={this.submitForm}
      />
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps)(SellInvoiceTab1Component);