/* @flow */

import React from 'react';

import customSort from 'lib/sortHelpers';

import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

import AmountFormatterComponent from 'components/formatters/amount';
import DateFormatterComponent from 'components/formatters/date';
import IntlMessageComponent from 'components/formatters/intlMessage';
import StyledTableCellTextComponent from 'components/table/cellText';
import StyledTableCellNumbersComponent from 'components/table/cellNumbers';
import StyledTableHeadComponent from 'components/table/head';
import StyledTableRowComponent from 'components/table/row';

import CommissionMsf from 'models/isos/commissions/commissionMsf';
import CommissionMsfData from 'models/isos/commissions/commissionMsfData';
import CommissionMsfIsoCompany from 'models/isos/commissions/commissionMsfIsoCompany';
import CommissionMsfIsoOffice from 'models/isos/commissions/commissionMsfIsoOffice';
import CommissionMsfIsoPerson from 'models/isos/commissions/commissionMsfIsoPerson';

const columnData = [
  {id: 'isoCompany', translationKey: 'component.isos.commissions.msf.list.header.isoCompany', walkme: 'isocommission-column3-isocompany', align: 'left'},
  {id: 'isoOffice', translationKey: 'component.isos.commissions.msf.list.header.isoOffice', walkme: 'isocommission-column3-isooffice', align: 'left'},
  {id: 'isoPerson', translationKey: 'component.isos.commissions.msf.list.header.isoPerson', walkme: 'isocommission-column3-isoperson', align: 'left'},
  {id: 'sellerCompany', translationKey: 'component.isos.commissions.msf.list.header.sellerCompany', walkme: 'isocommission-column3-sellercompany', align: 'left'},
  {id: 'revenueDate', translationKey: 'component.isos.commissions.msf.list.header.revenueDate', walkme: 'isocommission-column3-revenuedate', align: 'left'},
  {id: 'msfPayment', translationKey: 'component.isos.commissions.msf.list.header.msfPayment', walkme: 'isocommission-column3-couponreference', align: 'left'},
  {id: 'msfCommission', translationKey: 'component.isos.commissions.msf.list.header.commission', walkme: 'isocommission-column3-commission', align: 'left'}
];

type CommissionMsfTableComponentProps = {
  msfs: CommissionMsf[];
}

export default function CommissionMsfTableComponent(props: CommissionMsfTableComponentProps) {

  const reorganizeData = () => {
    const data = new CommissionMsfData();

    // Create data structure
    for (let i = 0; i < props.msfs.length; i++) {
      let isoCompany = data.companies.find(c => c.id === props.msfs[i].filterISOId);
      if (isoCompany === undefined) {
        isoCompany = new CommissionMsfIsoCompany(props.msfs[i].filterISOId, props.msfs[i].isoCompany);
        data.companies.push(isoCompany);
      }

      let isoOffice = isoCompany.offices.find(o => o.id === props.msfs[i].filterISOOfficeId);
      if (isoOffice === undefined) {
        isoOffice = new CommissionMsfIsoOffice(props.msfs[i].filterISOOfficeId, props.msfs[i].isoOffice);
        isoCompany.offices.push(isoOffice);
      }

      let isoPerson = isoOffice.persons.find(p => p.id === props.msfs[i].filterISOPersonId);
      if (isoPerson === undefined) {
        isoPerson = new CommissionMsfIsoPerson(props.msfs[i].filterISOPersonId, props.msfs[i].isoPerson);
        isoOffice.persons.push(isoPerson);
      }

      isoPerson.msfs.push(props.msfs[i]);
    }

    // Subtotals
    for (let i = 0; i < data.companies.length; i++) {
      for (let j = 0; j < data.companies[i].offices.length; j++) {
        for (let k = 0; k < data.companies[i].offices[j].persons.length; k++) {
          for (let l = 0; l < data.companies[i].offices[j].persons[k].msfs.length; l++) {
            // sub total per person
            data.companies[i].offices[j].persons[k].msfPayment += data.companies[i].offices[j].persons[k].msfs[l].msfPayment;
            data.companies[i].offices[j].persons[k].msfCommission += data.companies[i].offices[j].persons[k].msfs[l].msfCommission;
          }

          // sub total per office
          data.companies[i].offices[j].msfPayment += data.companies[i].offices[j].persons[k].msfPayment;
          data.companies[i].offices[j].msfCommission += data.companies[i].offices[j].persons[k].msfCommission;
        }

        // sub total per company
        data.companies[i].msfPayment += data.companies[i].offices[j].msfPayment;
        data.companies[i].msfCommission += data.companies[i].offices[j].msfCommission;
      }

      // grand total
      data.msfPayment += data.companies[i].msfPayment;
      data.msfCommission += data.companies[i].msfCommission;
    }

    // Sort companies, offices and persons
    for (let i = 0; i < data.companies.length; i++) {
      for (let j = 0; j < data.companies[i].offices.length; j++) {
        data.companies[i].offices[j].persons = data.companies[i].offices[j].persons.sort((a, b) => customSort(a.name, b.name));
      }
      data.companies[i].offices = data.companies[i].offices.sort((a, b) => customSort(a.name, b.name));
    }
    data.companies = data.companies.sort((a, b) => customSort(a.name, b.name));

    return data;
  }

  const data = reorganizeData();

  return (
    <React.Fragment>
      <Box py={5}>
        <Box component="div" overflow="auto">
          <Table>
            <StyledTableHeadComponent>
              <StyledTableRowComponent>
                {columnData.map(column => (
                  <StyledTableCellTextComponent align={column.align} key={column.id} padding="none" data-walkme={column.walkme || ''}>
                    <IntlMessageComponent id={column.translationKey} />
                  </StyledTableCellTextComponent>
                ))}
                <StyledTableCellNumbersComponent padding="space"/>
              </StyledTableRowComponent>
            </StyledTableHeadComponent>

            <TableBody bgcolor="white">
              {data.companies.length === 0 ? (
                <StyledTableRowComponent>
                  <StyledTableCellTextComponent colSpan={columnData.length}>
                    <Box textAlign="center">
                      <IntlMessageComponent id="component.isos.commissions.msf.list.noData" />
                    </Box>
                  </StyledTableCellTextComponent>
                </StyledTableRowComponent>
              ) : (
                <React.Fragment>
                  <StyledTableRowComponent selected>
                    <StyledTableCellTextComponent padding="none" colSpan={5}>
                      <b><IntlMessageComponent id="component.isos.commissions.msf.list.grandTotal" /></b>
                    </StyledTableCellTextComponent>
                    <StyledTableCellNumbersComponent padding="none"><b><AmountFormatterComponent amount={data.msfPayment} /></b></StyledTableCellNumbersComponent>
                    <StyledTableCellNumbersComponent padding="none"><b><AmountFormatterComponent amount={data.msfCommission} /></b></StyledTableCellNumbersComponent>
                    <StyledTableCellNumbersComponent padding="space"/>
                  </StyledTableRowComponent>

                  {data.companies.map((company, indexCompany) => (
                    <React.Fragment key={`msf-company-${indexCompany}`}>
                      {company.offices.map((office, indexOffice) => (
                        <React.Fragment key={`msf-office-${indexOffice}`}>
                          {office.persons.map((person, indexPerson) => (
                            <React.Fragment key={`msf-person-${indexPerson}`}>
                              {person.msfs.map((msf, index) => (
                                <StyledTableRowComponent key={`msf-${index}`}>
                                  <StyledTableCellTextComponent padding="none">{msf.isoCompany}</StyledTableCellTextComponent>
                                  <StyledTableCellTextComponent padding="none">{msf.isoOffice}</StyledTableCellTextComponent>
                                  <StyledTableCellTextComponent padding="none">{msf.isoPerson}</StyledTableCellTextComponent>
                                  <StyledTableCellTextComponent padding="none">{msf.sellerCompany}</StyledTableCellTextComponent>
                                  <StyledTableCellTextComponent padding="none"><DateFormatterComponent date={msf.revenueDate} /></StyledTableCellTextComponent>
                                  <StyledTableCellNumbersComponent padding="none"><AmountFormatterComponent amount={msf.msfPayment} /></StyledTableCellNumbersComponent>
                                  <StyledTableCellNumbersComponent padding="none"><AmountFormatterComponent amount={msf.msfCommission} /></StyledTableCellNumbersComponent>
                                  <StyledTableCellNumbersComponent padding="space"/>
                                </StyledTableRowComponent>
                              ))}

                              {person.id > 0 && person.name !== '' &&
                                <StyledTableRowComponent selected>
                                  <StyledTableCellTextComponent padding="none" colSpan={5}>
                                    <b><IntlMessageComponent id="component.isos.commissions.msf.list.personSubTotal" values={{name: person.name}} /></b>
                                  </StyledTableCellTextComponent>
                                  <StyledTableCellNumbersComponent padding="none"><b><AmountFormatterComponent amount={person.msfPayment} /></b></StyledTableCellNumbersComponent>
                                  <StyledTableCellNumbersComponent padding="none"><b><AmountFormatterComponent amount={person.msfCommission} /></b></StyledTableCellNumbersComponent>
                                  <StyledTableCellNumbersComponent padding="space"/>
                                </StyledTableRowComponent>
                              }
                            </React.Fragment>
                          ))}

                          {office.id > 0 && office.name !== '' &&
                            <StyledTableRowComponent selected>
                              <StyledTableCellTextComponent padding="none" colSpan={5}>
                                <b><IntlMessageComponent id="component.isos.commissions.msf.list.officeSubTotal" values={{name: office.name}} /></b>
                              </StyledTableCellTextComponent>
                              <StyledTableCellNumbersComponent padding="none"><b><AmountFormatterComponent amount={office.msfPayment} /></b></StyledTableCellNumbersComponent>
                              <StyledTableCellNumbersComponent padding="none"><b><AmountFormatterComponent amount={office.msfCommission} /></b></StyledTableCellNumbersComponent>
                              <StyledTableCellNumbersComponent padding="space"/>
                            </StyledTableRowComponent>
                          }
                        </React.Fragment>
                      ))}

                      <StyledTableRowComponent selected>
                        <StyledTableCellTextComponent padding="none" colSpan={5}>
                          <b><IntlMessageComponent id="component.isos.commissions.msf.list.companySubTotal" values={{name: company.name}} /></b>
                        </StyledTableCellTextComponent>
                        <StyledTableCellNumbersComponent padding="none"><b><AmountFormatterComponent amount={company.msfPayment} /></b></StyledTableCellNumbersComponent>
                        <StyledTableCellNumbersComponent padding="none"><b><AmountFormatterComponent amount={company.msfCommission} /></b></StyledTableCellNumbersComponent>
                        <StyledTableCellNumbersComponent padding="space"/>
                      </StyledTableRowComponent>
                    </React.Fragment>
                  ))}
                </React.Fragment>
              )}
            </TableBody>
          </Table>
        </Box>
      </Box>
    </React.Fragment>
  );
}
