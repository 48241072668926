/* @flow */

import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import Tooltip from '@material-ui/core/Tooltip';

import AmountFormatterComponent from 'components/formatters/amount';
import IconEdit from 'components/icons/edit';
import IconTrash from 'components/icons/trash';
import IntlMessageComponent from 'components/formatters/intlMessage';
import StyledTableCellTextComponent from 'components/table/cellText';
import StyledTableCellNumbersComponent from 'components/table/cellNumbers';
import StyledTableRowComponent from 'components/table/row';
import StyledTableHeadComponent from 'components/table/head';

import BusinessPotential from 'models/sellers/businessPotential';

type BusinessPotentialTableComponentProps = {
  businessPotentials: BusinessPotential[];
  enableActions: boolean;
  editBusinessPotential: (debtorCompanyId: number) => void;
  disableBusinessPotential: (debtorCompanyId: number) => void;
};

export default function BusinessPotentialTableComponent(props: BusinessPotentialTableComponentProps) {

  const handleClickEdit = (event: SyntheticMouseEvent<>, debtorCompanyId: number) => {
    event.stopPropagation();
    props.editBusinessPotential(debtorCompanyId);
  };

  const handleClickDisable = (event: SyntheticMouseEvent<>, debtorCompanyId: number) => {
    event.stopPropagation();
    props.disableBusinessPotential(debtorCompanyId);
  };

  return (
    <Table>
      <StyledTableHeadComponent>
        <StyledTableRowComponent>
          <StyledTableCellTextComponent>
            <IntlMessageComponent id="page.bestPricing.table.head.client" />
          </StyledTableCellTextComponent>

          <StyledTableCellTextComponent align="right">
            <IntlMessageComponent id="page.bestPricing.table.head.yearlyAmount" />
          </StyledTableCellTextComponent>

          <StyledTableCellTextComponent align="right">
            <IntlMessageComponent id="page.bestPricing.table.head.numberOfInvoices" />
          </StyledTableCellTextComponent>

          <StyledTableCellTextComponent align="right">
            <IntlMessageComponent id="page.bestPricing.table.head.averageInvoiceAmount" />
          </StyledTableCellTextComponent>

          {props.enableActions && <StyledTableCellTextComponent />}
        </StyledTableRowComponent>
      </StyledTableHeadComponent>

      <TableBody bgcolor="white">
        {props.businessPotentials.length === 0 &&
          <StyledTableRowComponent>
            <StyledTableCellTextComponent colSpan={5} style={{textAlign: 'center'}}>
              <IntlMessageComponent id="page.bestPricing.table.nodata" />
            </StyledTableCellTextComponent>
          </StyledTableRowComponent>
        }

        {props.businessPotentials.map(bp => (
          <StyledTableRowComponent key={bp.debtorCompany.id}>
            <StyledTableCellTextComponent>
              <b>{bp.debtorCompany.name}</b>
            </StyledTableCellTextComponent>

            <StyledTableCellNumbersComponent>
              <AmountFormatterComponent amount={bp.yearlyAmount} />
            </StyledTableCellNumbersComponent>

            <StyledTableCellNumbersComponent align="right">
              {bp.nbrInvoices}
            </StyledTableCellNumbersComponent>

            <StyledTableCellNumbersComponent>
              <AmountFormatterComponent amount={bp.avgInvoiceAmount} />
            </StyledTableCellNumbersComponent>

            {props.enableActions &&
              <StyledTableCellTextComponent>
                <div style={{display: 'flex'}}>
                  <Tooltip title={<IntlMessageComponent id="page.bestPricing.table.tooltip.actions.edit" />}>
                    <IconButton onClick={event => handleClickEdit(event, bp.debtorCompany.id)}>
                      <IconEdit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={<IntlMessageComponent id="page.bestPricing.table.tooltip.actions.cancel" />}>
                    <IconButton onClick={event => handleClickDisable(event, bp.debtorCompany.id)}>
                      <IconTrash />
                    </IconButton>
                  </Tooltip>
                </div>
              </StyledTableCellTextComponent>
            }
          </StyledTableRowComponent>
        ))}
      </TableBody>
    </Table>
  );
}
