/* @flow */
import React from 'react';
import moment from 'moment';

import Box from '@material-ui/core/Box';

import IconPaymentDone from 'components/icons/paymentDone';
import IconPaymentMissing from 'components/icons/paymentMissing';
import IconPaymentPending from 'components/icons/paymentPending';
import IconPaymentOldCommission from 'components/icons/paymentOldCommission';

import CommissionPayment from 'models/isos/commissions/commissionPayment';
import TooltipComponent from 'components/toolTips/index';

type ISOCommissionPaymentViewComponentProps = {
  payment: CommissionPayment;
}

export default function ISOCommissionPaymentViewComponent(props: ISOCommissionPaymentViewComponentProps) {
  if (!props.payment) return null;
  const today = moment();
  const quartedDate = new moment(props.payment.quarterDate).add(3, 'M').add(-1, 'd');
  const isYearOld = (today - quartedDate) / (1000 * 3600 * 24 * 365) > 1 

  return (
    <Box component="span" ml={1}>
      {isYearOld && !props.payment.isReceived &&
        <Box component="span" color="grey.main" data-walkme="iso-commissions-payment-done" pageName="Tooltip.Iso.Commission" elementName="icon.oldcommission">
          <TooltipComponent pageName="Tooltip.Iso.Commission" elementName="icon.oldcommission">
            <IconPaymentOldCommission fontSize="small" />
          </TooltipComponent>
        </Box>
      }
      {props.payment.isActive && props.payment.isReceived && props.payment.isPaid &&
        <Box component="span" color="green.main" data-walkme="iso-commissions-payment-done" pageName="Tooltip.Iso.Commission" elementName="icon.paymentdone">
          <TooltipComponent pageName="Tooltip.Iso.Commission" elementName="icon.paymentdone">
            <IconPaymentDone fontSize="small" />
          </TooltipComponent>
        </Box>
      }
      {props.payment.isActive && props.payment.isReceived && !props.payment.isPaid &&
        <Box component="span" color="orange.main" data-walkme="iso-commissions-payment-pending" pageName="Tooltip.Iso.Commission" elementName="icon.paymentpending">
          <TooltipComponent pageName="Tooltip.Iso.Commission" elementName="icon.paymentpending">
            <IconPaymentPending fontSize="small" />
          </TooltipComponent>
        </Box>
      }
      {!isYearOld && props.payment.isActive && !props.payment.isReceived && !props.payment.isPaid &&
        <Box component="span" color="red.main" data-walkme="iso-commissions-payment-missing" pageName="Tooltip.Iso.Commission" elementName="icon.paymentmissing">
          <TooltipComponent pageName="Tooltip.Iso.Commission" elementName="icon.paymentmissing">
            <IconPaymentMissing fontSize="small" />
          </TooltipComponent>
        </Box>
      }
    </Box>
  );
}
