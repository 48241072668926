/* @flow */

import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import SellerReportActionsComponent from './actions';
import CollapseLink from 'components/isoFilters/collaspsibleLink';
import ComponentWrapperComponent from 'components/websiteStructure/componentWrapper';
import Filter from 'components/isoFilters/filter';
import FilterISO from 'components/isoFilters/iso';

import SellerReportFilters from 'models/isos/sellers/sellerReportFilters';

type SellerReportFiltersComponentProps = {
  filters: SellerReportFilters;
  selectFilter: (filterId: string, optionId: number) => void;
  selectFilterISO: (filterId: string, optionId: number) => void;
  sellerCompanyName: string,
  availableSellerCompanyNames: string[],
  sellerCompanyNameChange: (name: string) => void;
  clearFilters: () => void;
  downloadSellerReport: (format: string) => void;
}

export default function SellerReportFiltersComponent(props: SellerReportFiltersComponentProps) {
  const [showFilters, setShowFilters] = useState(true);

  const toggleShowFilters = () => setShowFilters(showFilters => !showFilters);

  return (
    <Box mb={showFilters ? 3 : 'auto'}>
      <Box mb={3}>
        <CollapseLink
          isCollapsed={!showFilters}
          labelClosed="component.isos.invoices.filters.collapse.closed.label"
          labelOpen="component.isos.invoices.filters.collapse.open.label"
          toggle={toggleShowFilters}
          data-walkme="isoinvoices-walkthru-step1"
        />
      </Box>

      {showFilters &&
        <React.Fragment>
          <ComponentWrapperComponent small data-walkme="isoinvoices-walkthru-step2">
            <Grid container>
              <FilterISO
                companyLabel="component.isos.invoices.filters.isoCompany.label"
                officeLabel="component.isos.invoices.filters.isoOffice.label"
                personLabel="component.isos.invoices.filters.isoPerson.label"
                pageName="POS.Sellers"
                filters={props.filters.companies}
                selectFilter={props.selectFilterISO}
              />

              <Grid item xs="auto">
                <Box pl={2} pr={2} minWidth={300}>
                  <Filter
                    filterId="sellerCompanies"
                    filters={props.filters.sellerCompanies}
                    label="component.isos.invoices.filters.sellerCompany.label"
                    selectFilter={props.selectFilter}
                    sellerCompanyNameChange={props.sellerCompanyNameChange}
                    sellerCompanyName={props.sellerCompanyName}
                    sellerCompanyNames={props.availableSellerCompanyNames}
                  />
                </Box>
              </Grid>

              <Grid item xs="auto">
                <Box pr={4} minWidth={200}>
                  <Filter
                    filterId="expired"
                    filters={props.filters.expired}
                    label="component.isos.sellers.filters.expired.label"
                    selectFilter={props.selectFilter}
                  />
                </Box>
              </Grid>
            </Grid>
          </ComponentWrapperComponent>
          <Box mt={3}>
            <SellerReportActionsComponent
              onExportAsked={props.downloadSellerReport}
              clearFilters={props.clearFilters}
            />
          </Box>
        </React.Fragment>
      }
    </Box>
  );
}
