/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import PhoneNumberComponent from 'components/formFields/phoneNumber';
import ButtonComponent from 'components/buttons/button';
import FormErrorsComponent from 'components/formErrors';
import IntlMessageComponent from 'components/formatters/intlMessage';

import MobileConfirmationForm from './model';

type MobileConfirmationViewComponentProps = {
  errors: Map<string, ?string>;
  form: MobileConfirmationForm;
  formErrors: string[];
  isProcessing: boolean;
  mobile: string;
  handleChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void;
  handleMobileChange: (value: string) => void;
  isValid: () => boolean;
  submitForm: () => void;
}

export default function MobileConfirmationViewComponent(props: MobileConfirmationViewComponentProps) {

  const getInputEndAdornment = () => {
    if (props.isProcessing) 
      return (<CircularProgress size={14} />);

    if (!props.isValid())
      return null;

    return (
      <InputAdornment position="end">
        <ButtonComponent
          color="primary"
          variant="contained"
          onClick={props.submitForm}
        ><IntlMessageComponent id="general.btn.update" /></ButtonComponent>
      </InputAdornment>
    );
  }

  const inputEndAdornment = getInputEndAdornment();

  return (
    <React.Fragment>
      {props.mobile ? (
        <React.Fragment>
          <Box color="primary.main" fontSize={22} fontWeight="fontWeightBold">
            {props.mobile}
          </Box>
          <Box mt={3} color="error.main" fontWeight="fontWeightBold">
            <IntlMessageComponent id="component.mobile.confirmation.question" />
          </Box>
          <Box>
            <IntlMessageComponent id="component.mobile.confirmation.invite" />
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Box color="error.main" fontWeight="fontWeightBold">
            <IntlMessageComponent id="component.mobile.confirmation.nomobile" />
          </Box>
          <Box>
            <IntlMessageComponent id="component.mobile.confirmation.nomobile.invite" />
          </Box>
        </React.Fragment>
      )}
      
      <Grid container spacing={3}>
        <Grid item xs={9}>
          <Box mt={3}>
            <FormErrorsComponent formErrors={props.formErrors} displaySmall />
            <PhoneNumberComponent
              id="form.mobile"
              label={<IntlMessageComponent id="form.mobile.label" />}
              error={!!props.errors.get('mobile')}
              helperText={(props.errors.get('mobile'))
                ? <IntlMessageComponent id={props.errors.get('mobile')} />
                : <IntlMessageComponent id="form.mobile.helperText" />}
              value={props.form.mobile}
              onChange={props.handleMobileChange}
              fullWidth
              margin="normal"
              required
            />
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box mt={8.4}>
            {inputEndAdornment}
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}