/* @flow */

import moment from 'moment';

import SupplierCompany from './supplierCompany';

export default class Invoice {
  id: string;
  invoiceName: string;

  reference: string;
  debtorReference: string;

  debtorRefusedReason: string;

  supplierCompany: SupplierCompany;

  StatusKey: string;

  debtAmount: number;
  outstandingBalance: number;

  dueDate: moment;
  issueDate: moment;

  daysToDueDate: number;
  validationAge: ?number;

  paymentPromiseDate: ?moment;

  invoiceDocumentId: number;
  hasRelatedDocuments: bool;

  toApprove: boolean;
  approved: boolean;
  refused: boolean;
  toPay: boolean;
  paid: boolean;

  isSelected: boolean;

  approvedBy: string;

  listingFee: number;
  serviceFee: number;
  insuranceFee: number;
  buyerDiscount: number;

  constructor() {
    this.isSelected = false;
  }
}
