/* @flow */

import React from 'react';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';

import AmountKMFormatterComponent from 'components/formatters/amountKM';
import DateFormatterComponent from 'components/formatters/date';
import IntlMessageComponent from 'components/formatters/intlMessage';
import LoadingComponent from 'components/loading';
import NumberFormatterComponent from 'components/formatters/number';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import PeriodRadioFieldComponent from 'components/formFields/periodRadio';
import QuarterFormatterComponent from 'components/formatters/quarter';
import StyledTableCellTextComponent from 'components/table/cellText';
import StyledTableCellNumbersComponent from 'components/table/cellNumbers'
import StyledTableRowComponent from 'components/table/row';
import StyledStrippedTableRowComponent from 'components/table/rowStripped';

import ProfileOverviewEntry from 'models/isos/dashboards/profileOverviewEntry';

import ISOService from 'services/IsoService';

type ISOProfileOverviewComponentProps = {
  activeCompanyId: number;
  languageId: number;
}

type ISOProfileOverviewComponentState = {
  entries: ProfileOverviewEntry[];
  filter: string;
  isLoading: boolean;
}

class ISOProfileOverviewComponent extends React.Component<ISOProfileOverviewComponentProps, ISOProfileOverviewComponentState> {
  constructor(props) {
    super(props);
    this.state = {
      entries: [],
      filter: 'years',
      isLoading: true
    };
  }

  componentDidMount = async () => {
    try {
      const entries = await ISOService.getProfileOverview(this.props.activeCompanyId);
      this.setState({ entries, isLoading: false });
    } catch (e) {
      console.error(e);
    }
  }

  handleFilterChange = (event) => {
    this.setState({ filter: event.target.value });
  }

  render() {
    const entries = this.state.entries.filter(e => e.report === this.state.filter[0]);

    return (
      <React.Fragment>
        <PageSubTitleViewComponent id="component.iso.details.title" data-walkme="isodashboard-title-yourportfolio" />

        {this.state.isLoading && <LoadingComponent />}

        {!this.state.isLoading &&
          <React.Fragment>
            <Box data-walkme="isodashboard-walkthru-step3" >
              <PeriodRadioFieldComponent value={this.state.filter} handleChange={this.handleFilterChange} />
            </Box>

            <Box component="div" mt={3} overflow="auto">
              <Table>
                <TableHead>
                  <StyledTableRowComponent>
                    <StyledTableCellTextComponent padding="none" />
                    {entries.map((e, index) => (
                      <StyledTableCellTextComponent key={`head-date-${index}`} padding="none">
                        {this.state.filter === 'years' && <DateFormatterComponent date={e.reportDate} format="YYYY" />}
                        {this.state.filter === 'quarters' && <QuarterFormatterComponent date={e.reportDate} />}
                        {this.state.filter === 'months' && <DateFormatterComponent date={e.reportDate} format="MMM YYYY" />}
                      </StyledTableCellTextComponent>
                    ))}
                  </StyledTableRowComponent>
                </TableHead>

                <TableBody>
                  <StyledStrippedTableRowComponent>
                    <StyledTableCellTextComponent padding="none" data-walkme="isodashboard-column-leads"><strong><IntlMessageComponent id="component.iso.dashboard.details.leads" /></strong></StyledTableCellTextComponent>
                    {entries.map((e, index) => (
                      <StyledTableCellTextComponent key={`leads-${index}`} padding="none"><NumberFormatterComponent value={e.leads} /></StyledTableCellTextComponent>
                    ))}
                  </StyledStrippedTableRowComponent>

                  <StyledStrippedTableRowComponent>
                    <StyledTableCellTextComponent padding="none" data-walkme="isodashboard-column-leadswon"><strong><IntlMessageComponent id="component.iso.dashboard.details.leadsWon" /></strong></StyledTableCellTextComponent>
                    {entries.map((e, index) => (
                      <StyledTableCellTextComponent key={`leadsWon-${index}`} padding="none"><NumberFormatterComponent value={e.leadsWon} /></StyledTableCellTextComponent>
                    ))}
                  </StyledStrippedTableRowComponent>

                  <StyledStrippedTableRowComponent>
                    <StyledTableCellTextComponent padding="none" data-walkme="isodashboard-column-leadslost"><strong><IntlMessageComponent id="component.iso.dashboard.details.leadsLost" /></strong></StyledTableCellTextComponent>
                    {entries.map((e, index) => (
                      <StyledTableCellTextComponent key={`leadsLost-${index}`} padding="none"><NumberFormatterComponent value={e.leadsLost} /></StyledTableCellTextComponent>
                    ))}
                  </StyledStrippedTableRowComponent>

                  <StyledStrippedTableRowComponent style={{ borderBottomStyle: 'double' }}>
                    <StyledTableCellTextComponent padding="none" data-walkme="isodashboard-column-leadsinprogress"><strong><IntlMessageComponent id="component.iso.dashboard.details.leadsInProgress" /></strong></StyledTableCellTextComponent>
                    {entries.map((e, index) => (
                      <StyledTableCellTextComponent key={`leadsInProgress-${index}`} padding="none"><NumberFormatterComponent value={e.leadsInProgress} /></StyledTableCellTextComponent>
                    ))}
                  </StyledStrippedTableRowComponent>

                  <StyledStrippedTableRowComponent>
                    <StyledTableCellTextComponent padding="none" data-walkme="isodashboard-column-members"><strong><IntlMessageComponent id="component.iso.dashboard.details.members" /></strong></StyledTableCellTextComponent>
                    {entries.map((e, index) => (
                      <StyledTableCellTextComponent key={`members-${index}`} padding="none"><NumberFormatterComponent value={e.members} /></StyledTableCellTextComponent>
                    ))}
                  </StyledStrippedTableRowComponent>

                  <StyledStrippedTableRowComponent>
                    <StyledTableCellTextComponent padding="none" data-walkme="isodashboard-column-sellers"><strong><IntlMessageComponent id="component.iso.dashboard.details.sellers" /></strong></StyledTableCellTextComponent>
                    {entries.map((e, index) => (
                      <StyledTableCellTextComponent key={`sellers-${index}`} padding="none"><NumberFormatterComponent value={e.sellers} /></StyledTableCellTextComponent>
                    ))}
                  </StyledStrippedTableRowComponent>

                  <StyledStrippedTableRowComponent>
                    <StyledTableCellTextComponent padding="none" data-walkme="isodashboard-column-pastsellers"><strong><IntlMessageComponent id="component.iso.dashboard.details.pastSellers" /></strong></StyledTableCellTextComponent>
                    {entries.map((e, index) => (
                      <StyledTableCellTextComponent key={`pastSellers-${index}`} padding="none"><NumberFormatterComponent value={e.pastSellers} /></StyledTableCellTextComponent>
                    ))}
                  </StyledStrippedTableRowComponent>

                  <StyledStrippedTableRowComponent>
                    <StyledTableCellTextComponent padding="none" data-walkme="isodashboard-column-activesellers"><strong><IntlMessageComponent id="component.iso.dashboard.details.activeSellers" /></strong></StyledTableCellTextComponent>
                    {entries.map((e, index) => (
                      <StyledTableCellTextComponent key={`activeSellers-${index}`} padding="none"><NumberFormatterComponent value={e.activeSellers} /></StyledTableCellTextComponent>
                    ))}
                  </StyledStrippedTableRowComponent>

                  <StyledStrippedTableRowComponent>
                    <StyledTableCellTextComponent padding="none" data-walkme="isodashboard-column-financedamount"><strong><IntlMessageComponent id="component.iso.dashboard.details.financedamount" /></strong></StyledTableCellTextComponent>
                    {entries.map((e, index) => (
                      <StyledTableCellTextComponent key={`financedamount-${index}`} padding="none"><NumberFormatterComponent value={e.financedAmount} /></StyledTableCellTextComponent>
                    ))}
                  </StyledStrippedTableRowComponent>

                  {process.env.REACT_APP_ISOPortalEnableFixViews === 'true' &&
                    <StyledStrippedTableRowComponent>
                      <StyledTableCellTextComponent padding="none" data-walkme="isodashboard-column-unfinancedamount"><strong><IntlMessageComponent id="component.iso.dashboard.details.unfinancedamount" /></strong></StyledTableCellTextComponent>
                      {entries.map((e, index) => (
                        <StyledTableCellTextComponent key={`unfinancedamount-${index}`} padding="none"><AmountKMFormatterComponent value={e.unfinancedAmount} /></StyledTableCellTextComponent>
                      ))}
                    </StyledStrippedTableRowComponent>
                  }

                  <StyledStrippedTableRowComponent>
                    <StyledTableCellTextComponent padding="none" data-walkme="isodashboard-column-comission"><strong><IntlMessageComponent id="component.iso.dashboard.details.commission" /></strong></StyledTableCellTextComponent>
                    {entries.map((e, index) => (
                      <StyledTableCellTextComponent key={`commission-${index}`} padding="none"><NumberFormatterComponent value={e.commission} /></StyledTableCellTextComponent>
                    ))}
                  </StyledStrippedTableRowComponent>
                </TableBody>
              </Table>
            </Box>
          </React.Fragment>
        }
      </React.Fragment>
    );
  }
}


const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id,
  languageId: state.locale.languageId
});

export default connect(mapStateToProps)(ISOProfileOverviewComponent);
