/* @flow */

import moment from 'moment/moment';

export default class CompanyUBO {
  id: number;

  titleId: number;

  lastName: string;
  firstName: string;

  email: string;

  addressLine1: string;
  addressLine2: string;
  zipCode: string;
  city: string;
  countryId: number;

  nationalityId: number;
  birthCountryId: number;
  birthDate: moment;
  birthPlace: string;

  registrationTypeId: number;
  registrationNumber: string;
  registrationExpireDate: moment;

  functionName: string;
  isDecisionMaker: boolean;
  shares: number;

  isActive: boolean;
  isCompanyUser: boolean;

  hasValidId: boolean;

  hasMissingFields() {
    if (!this.lastName ||
      !this.firstName ||
      !this.email ||
      !this.addressLine1 ||
      !this.zipCode ||
      !this.city ||
      this.countryId == 0 ||
      this.nationalityId == 0 ||
      this.birthCountryId == 0 ||
      !this.birthDate ||
      !this.birthPlace ||
      this.registrationTypeId == 0 ||
      !this.registrationNumber ||
      !this.registrationExpireDate ||
      moment(this.registrationExpireDate).utc().endOf('day').isBefore(moment().utc().endOf('day')) ||
      !this.functionName)
      return true;
    else
      return false;
  }
}
