/* @flow */

import CallbackRequest from 'models/requests/leads/callbackRequest';

export default class CallBackForm {
  leadId: number;
  day: string[];
  hour: string[];

  constructor() {
    this.leadId = 0;
    this.day=[];
    this.hour=[];
  }

  toRequest(){
    const request = new CallbackRequest();
    request.leadId = this.leadId;
    request.day = this.day;
    request.hour = this.hour;
    return request;
  }
}
