/* @flow */


import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import Tooltip from '@material-ui/core/Tooltip';

import AmountFormatterComponent from 'components/formatters/amount';
import ButtonComponent from 'components/buttons/button';
import DateFormatterComponent from 'components/formatters/date';
import IconEdit from 'components/icons/edit';
import IconTrash from 'components/icons/trash';
import IntlMessageComponent from 'components/formatters/intlMessage';
import InvoiceNameComponent from 'components/invoice/name';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import SellerInvoiceStatusViewComponent from 'components/invoiceStatus/seller';
import StyledTableCellTextComponent from 'components/table/cellText';
import StyledTableCellNumbersComponent from 'components/table/cellNumbers';
import StyledTableHeadComponent from 'components/table/head';
import StyledTableRowComponent from 'components/table/row';
import StyledTableRowSellerInvoiceComponent from 'components/table/rowSellerInvoice';
import SecurityComponent from 'components/security/index';

import Invoice from 'models/invoices/seller/invoice';

import {
  INVOICE_SELLER_TYPE_PUBLISHED,
  INVOICE_SELLER_TYPE_PAY_FEES,
  INVOICE_SELLER_TYPE_FOLLOWUP,
  INVOICE_SELLER_TYPE_SIGN_DEED,
  INVOICE_SELLER_TYPE_REFUSED
} from 'constants/invoiceTypes';

import {
  PROTECTED_SELLER_INVOICE_DETAILS,
  PROTECTED_SELLER_SELL
} from 'constants/pageRoutes';

const columnData = [
  { id: 'type', align: 'left', translationKey: 'component.seller.invoiceList.header.status', walkme: 'sdashboard-column-statut' },
  { id: 'id', align: 'left', translationKey: 'component.seller.invoiceList.header.invoiceNbr', walkme: 'sdashboard-column-refedebex' },
  { id: 'reference', align: 'left', translationKey: 'component.seller.invoiceList.header.reference', walkme: 'sdashboard-column-reforiginale' },
  { id: 'debtorName', align: 'left', translationKey: 'component.seller.invoiceList.header.debtor', walkme: 'sdashboard-column-client' },
  { id: 'debtAmount', translationKey: 'component.seller.invoiceList.header.amount', walkme: 'sdashboard-column-montantinitial' },
  { id: 'debtAmount', translationKey: 'component.seller.invoiceList.header.financedAmount', walkme: 'sdashboard-column-financedAmount' },
  { id: 'transactionPrice', translationKey: 'component.seller.invoiceList.header.transactionPrice', walkme: 'sdashboard-column-transactionPrice' },
  { id: 'cashToTransfer', translationKey: 'component.seller.invoiceList.header.cashToTransfer', walkme: 'sdashboard-column-cashToTransfer' },
  { id: 'estimateDate', align: 'left', translationKey: 'component.seller.invoiceList.header.estimateDate', walkme: 'sdashboard-column-estimation' },
  { id: 'dueDate', align: 'left', translationKey: 'component.seller.invoiceList.header.dueDate', walkme: 'sdashboard-column-echeance' }
];

type SellerInvoiceListViewComponentProps = {
  invoices: Invoice[];
  order: string;
  orderBy: string;
  page: number;
  pageSize: number;
  cancelInvoice: (id: number) => void;
  handlePageChange: (page: number) => void;
  handlePageSizeChange: (pageSize: number) => void;
  handleSortOrderChange: (property: string) => void;
  payFees: (ids: number[]) => void;
  paySelectedFees: () => void;
  selectInvoice: (id: number) => void;
};

export default function SellerInvoiceListViewComponent(props: SellerInvoiceListViewComponentProps) {
  const isSellerObserver = useSelector(state => state.auth.user.activeCompany.roleSeller?.role == 3884);
  const history = useHistory();
  const canExecute = useSelector(state => state.auth.user.activeCompany.roleSeller.canExecute);
  const selectedCount = props.invoices.reduce((acc, cur) => cur.isSelected ? ++acc : acc, 0);

  const handlePageChange = (_, page) => props.handlePageChange(page);
  const handlePageSizeChange = event => props.handlePageSizeChange(event.target.value);

  const isActionRequired = (id: number) => {
    switch (id) {
      case INVOICE_SELLER_TYPE_PUBLISHED:
      case INVOICE_SELLER_TYPE_PAY_FEES:
      case INVOICE_SELLER_TYPE_FOLLOWUP:
      case INVOICE_SELLER_TYPE_SIGN_DEED:
        return true;
      default:
        return false;
    }
  };

  const onClickCheckbox = (event: SyntheticMouseEvent<>, invoiceId: number) => {
    event.stopPropagation();
    props.selectInvoice(invoiceId);
  };

  const onClickEdit = (event: SyntheticMouseEvent<>, invoiceId: number) => {
    event.stopPropagation();
    history.push(`${PROTECTED_SELLER_SELL}/${invoiceId}`);
  };

  const onClickPayFees = (event: SyntheticMouseEvent<>, invoiceId: number) => {
    event.stopPropagation();
    props.payFees([invoiceId]);
  };

  const onClickRow = (invoiceId: number, type: number) => {
    if (canExecute && type === INVOICE_SELLER_TYPE_PUBLISHED) {
      history.push(`${PROTECTED_SELLER_SELL}/${invoiceId}`);
    } else {
      history.push(`${PROTECTED_SELLER_INVOICE_DETAILS}/${invoiceId}`);
    }
  };

  const onClickRemove = (event: SyntheticMouseEvent<>, id: number) => {
    event.stopPropagation();
    props.cancelInvoice(id);
  };

  return (
    <React.Fragment>
      <PageSubTitleViewComponent id="component.seller.invoiceList.title" />

      <Box mt={3}>
        <Box component="div" overflow="auto">
          <Table>
            <StyledTableHeadComponent>
              <StyledTableRowComponent>
                <StyledTableCellTextComponent padding="checkbox" />

                {columnData.map(column => (
                  <StyledTableCellTextComponent align={column?.align ?? 'right'} key={column.id} padding="none">
                    <TableSortLabel
                      active={props.orderBy === column.id}
                      data-walkme={column.walkme || ''}
                      direction={props.order}
                      onClick={() => props.handleSortOrderChange(column.id)}
                    ><IntlMessageComponent id={column.translationKey} /></TableSortLabel>
                  </StyledTableCellTextComponent>
                ))}

                <StyledTableCellTextComponent padding="none" />
              </StyledTableRowComponent>
            </StyledTableHeadComponent>

            <TableBody bgcolor="white">
              {props.invoices.length === 0 &&
                <StyledTableRowComponent>
                  <StyledTableCellTextComponent colSpan={columnData.length + 2}>
                    <Box textAlign="center">
                      <IntlMessageComponent id="seller.invoices.noData" />
                    </Box>
                  </StyledTableCellTextComponent>
                </StyledTableRowComponent>
              }

              {props.invoices.slice(props.page * props.pageSize, (props.page * props.pageSize) + props.pageSize).map(i => (
                <StyledTableRowSellerInvoiceComponent
                  key={`invList-${i.id}`}
                  hover
                  onClick={canExecute && !isSellerObserver ? () => onClickRow(i.id, i.type) : null}
                  isRefused={i.type === INVOICE_SELLER_TYPE_REFUSED}
                  isWaiting={isActionRequired(i.type)}
                >
                  {canExecute && !isSellerObserver && i.requiresPayingFees && i.type === INVOICE_SELLER_TYPE_PAY_FEES ? (
                    <StyledTableCellTextComponent padding="checkbox" onClick={event => onClickCheckbox(event, i.id)}>
                      <Checkbox color="primary" checked={i.isSelected} />
                    </StyledTableCellTextComponent>
                  ) : (
                    <StyledTableCellTextComponent padding="checkbox" />
                  )}

                  <StyledTableCellTextComponent padding="none">
                    <SellerInvoiceStatusViewComponent invoiceType={i.type} />
                  </StyledTableCellTextComponent>

                  <StyledTableCellTextComponent padding="none">
                    <InvoiceNameComponent invoiceId={i.id} invoiceName={i.invoiceName} />
                  </StyledTableCellTextComponent>

                  <StyledTableCellTextComponent padding="none">
                    {i.reference}
                  </StyledTableCellTextComponent>

                  <StyledTableCellTextComponent padding="none">
                    <b>{i.debtorName}</b>
                  </StyledTableCellTextComponent>

                  <StyledTableCellNumbersComponent padding="none">
                    <AmountFormatterComponent amount={i.debtAmount} />
                  </StyledTableCellNumbersComponent>

                  <StyledTableCellNumbersComponent padding="none">
                    <AmountFormatterComponent amount={i.financedAmount} />
                  </StyledTableCellNumbersComponent>

                  <StyledTableCellNumbersComponent padding="none">
                    {i.isConfirming === true ? "" : <b><AmountFormatterComponent amount={i.transactionPrice} /></b>}
                  </StyledTableCellNumbersComponent>

                  <StyledTableCellNumbersComponent padding="none">
                    <AmountFormatterComponent amount={i.cashToTransfer} notApplicableIfMissing />
                  </StyledTableCellNumbersComponent>

                  <StyledTableCellTextComponent padding="none">
                    <DateFormatterComponent date={i.estimateDate} />
                  </StyledTableCellTextComponent>

                  <StyledTableCellTextComponent padding="none">
                    <DateFormatterComponent date={i.dueDate} />
                  </StyledTableCellTextComponent>

                  <StyledTableCellTextComponent padding="none">
                    {canExecute && !isSellerObserver && i.requiresPayingFees && i.type === INVOICE_SELLER_TYPE_PAY_FEES &&
                      <ButtonComponent
                        color="tertiary"
                        variant="contained"
                        onClick={event => onClickPayFees(event, i.id)}
                      ><IntlMessageComponent id="component.seller.invoiceList.actions.payfees" /></ButtonComponent>
                    }
                    {canExecute && i.type === INVOICE_SELLER_TYPE_PUBLISHED &&
                      <Box display="flex">
                        <SecurityComponent pageName="POS.Sellers.DashboardInvoiceList" elementName="btnEditInvoice">
                          <Tooltip title={<IntlMessageComponent id="component.seller.invoiceList.actions.edit" />}>
                            <IconButton onClick={event => onClickEdit(event, i.id)}>
                              <IconEdit />
                            </IconButton>
                          </Tooltip>
                        </SecurityComponent>

                        <SecurityComponent pageName="POS.Sellers.DashboardInvoiceList" elementName="btnCancelInvoice">
                          <Box component="span" ml={1}>
                            <Tooltip title={<IntlMessageComponent id="component.seller.invoiceList.actions.cancel" />}>
                              <IconButton onClick={event => onClickRemove(event, i.id)}>
                                <IconTrash />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </SecurityComponent>
                      </Box>
                    }
                  </StyledTableCellTextComponent>
                </StyledTableRowSellerInvoiceComponent>
              ))}
            </TableBody>
          </Table>

          <Box display="flex" height={60}>
            <SecurityComponent pageName="POS.Sellers.DashboardInvoiceList" elementName="btnPayFees">
              <Box flexGrow={1} mt={2}>
                <ButtonComponent
                  color="tertiary"
                  variant="contained"
                  disabled={selectedCount < 1}
                  onClick={props.paySelectedFees}
                  data-walkme="sdashboard-button-payerlesfrais"
                ><IntlMessageComponent id="component.seller.invoiceList.actions.payfees" /></ButtonComponent>
              </Box>
            </SecurityComponent>
            <Box justifyContent="flex-end">
              <TablePagination
                component="div"
                count={props.invoices.length}
                rowsPerPage={props.pageSize}
                page={props.page}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePageSizeChange}
                labelRowsPerPage={<IntlMessageComponent id="general.invoicePerPage" />}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}
