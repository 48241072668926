/* @flow */

import moment from 'moment';
import InvoiceActiveFilters from 'models/debtors/invoiceActiveFilters';

export default class DownloadDebtorPortfolioRequest {
  companyId: number;
  format: string;
  query: string;
  statusKey: string;
  dateFrom: moment;
  dateTo: moment;
  sellerCompanyId: string;

  constructor(companyId: number, format: string, filterSet: InvoiceActiveFilters) {
    this.companyId = companyId;
    this.format = format;
    this.query = filterSet.query;
    this.statusKey = filterSet.statusKey;
    this.dateFrom = filterSet.dateFrom;
    this.dateTo = filterSet.dateTo;
    this.sellerCompanyId = filterSet.sellerCompanyId;
  }

  toRouteParameters() {
    let route = `companyId=${this.companyId}&format=${this.format}`;
    if (this.query !== null && this.query !== '') {
      route += `&query=${this.query}`;
    }
    if (this.statusKey !== null && this.statusKey !== '') {
      route += `&statusKey=${this.statusKey}`;
    }
    if (this.dateFrom) {
      route += `&dateFrom=${this.dateFrom.format('YYYY-MM-DD')}`;
    } 
    if (this.dateTo) {
      route += `&dateTo=${this.dateTo.format('YYYY-MM-DD')}`;
    } 
    if (this.sellerCompanyId) {
      route += `&sellerCompanyId=${this.sellerCompanyId}`;
    }
    return route;
  }
}
    