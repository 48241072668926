/* @flow */

import React from 'react';

import LoadingComponent from 'components/loading';

import LostPasswordFormViewComponent from './view';

import LostPasswordForm from './model';

import UserService from 'services/UserService';
import {handleApiFormResponse, handleFormChange, handleFormValidation} from 'lib/forms';
import loadConstraints from 'lib/validation/loadConstraints';
import LostPasswordFormViewFailComponent from './viewFail';

type LostPasswordFormComponentProps = {
  successHandler: (result: number, email: string) => void;
}

type LostPasswordFormComponentState = {
  constraints: any;
  errors: Map<string, ?string>;
  form: LostPasswordForm;
  formErrors: string[];
  isLoading: boolean;
  isProcessing: boolean;
};

class LostPasswordFormComponent extends React.Component<LostPasswordFormComponentProps, LostPasswordFormComponentState> {
  constructor(props) {
    super(props);
    this.state = {
      constraints: null,
      errors: null,
      form: new LostPasswordForm(),
      formErrors: [],
      isLoading: true,
      isProcessing: false
    };
  }

  componentDidMount = async () => {
    try {
      const constraints = await loadConstraints('Person', ['email']);
      this.setState({
        constraints,
        errors: new Map(Object.keys(constraints).map(e => [e, undefined])),
        isLoading: false
      });
    } catch (e) {
      console.error(e);
    }
  };

  handleChange = (fieldName: string) => (event) => handleFormChange.call(this, fieldName, event.target.value);
  validateForm = () => handleFormValidation.call(this);

  resetForm = () => this.setState({
    errors: new Map(Object.keys(this.state.constraints).map(e => [e, undefined])),
    form: new LostPasswordForm(),
    formErrors: [],
    isProcessing: false
  });

  submitForm = async () => {
    if (!this.validateForm()) return;

    this.setState({formErrors: [], isProcessing: true});
          
    try {
      const request = this.state.form.toRequest();
      const response = await UserService.lostPassword(request);
      this.props.successHandler(response, this.state.form.email);
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }
  }

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    if (this.state.formErrors.length > 0) {
      return (
        <LostPasswordFormViewFailComponent 
          formErrors={this.state.formErrors}
          resetForm={this.resetForm}
        />
      );
    }

    return (
      <LostPasswordFormViewComponent
        errors={this.state.errors}
        form={this.state.form}
        isProcessing={this.state.isProcessing}
        handleChange={this.handleChange}
        submitForm={this.submitForm}
      />
    );
  }
}

export default LostPasswordFormComponent;
