/* @flow */

import React from 'react';
import {useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';

import BuyerInvoiceDetailsComponent from 'app/buyers/invoiceDetails';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function BuyerInvoiceDetailsPage() {
  const {id, refuse} = useParams();
  const languageId = useSelector(state => state.locale.languageId);

  return (
    <WebsitePageComponent noPaper noTitle>
      <BuyerInvoiceDetailsComponent invoiceId={id} refuseSelected={refuse} key={`invoice-${languageId}`} />
    </WebsitePageComponent>
  );
}
