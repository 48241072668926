/* @flow */

import moment from 'moment';

import RealizedData from './companyFixContractRealizedData';

export default class CompanyFixContract {
  id: number;
  contractId: number;
  startDate: moment;
  engagedMonthlyFinancedAmount: number;
  engagedAvgInvoiceAmount: number;
  engagedMinServiceFee: number;
  realizedPeriodInMonths: number;
  realizedData: RealizedData[];

  constructor() {
    this.id = 0;
    this.contractId = 0;
    this.startDate = moment();
    this.engagedMonthlyFinancedAmount = 0;
    this.engagedAvgInvoiceAmount = 0;
    this.engagedMinServiceFee = 0;
    this.realizedPeriodInMonths = 0;
  }
}
