/* @flow */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Square(props) {
  return (
    <SvgIcon viewBox="0 0 32 32" {...props}>
      <g>
        <path d="M4 4h24v24h-24z"></path>
      </g>
    </SvgIcon>
  );
}
