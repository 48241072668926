/* @flow */

export default class DownloadBuyerAccountingRequest {
  companyId: number;
  investmentProfileId: number;
  format: string;

  constructor(companyId: number, investmentProfileId: number, format: string) {
    this.companyId = companyId;
    this.investmentProfileId = investmentProfileId;
    this.format = format;
  }

  toRouteParameters() {
    return `companyId=${this.companyId}&investmentProfileId=${this.investmentProfileId}&format=${this.format}`;
  }
}
