/* @flow */


import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

type RegisterSellerCompanyTab1AlreadyExistViewComponentProps = {
  resetForm: () => void;
}

export default function RegisterSellerCompanyTab1AlreadyExistViewComponent(props: RegisterSellerCompanyTab1AlreadyExistViewComponentProps) {
  return (
    <React.Fragment>
      <PageSubTitleViewComponent id="page.iso.company.register.tab0.companyTaken.title" />
      
      <Box bgcolor="lightblue.main" mt={5} px={5} py={4}>
        <Typography variant="body1">
          <IntlMessageComponent id="page.iso.company.register.tab0.companyTaken.text" />
        </Typography>
      </Box>
      
      <Box mt={5}>
        <ButtonComponent
          color="primary"
          variant="contained"
          onClick={props.resetForm}
        ><IntlMessageComponent id="general.reset" /></ButtonComponent>
      </Box>
    </React.Fragment>
  );
}
