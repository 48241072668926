/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import BusinessIcon from '@material-ui/icons/Business';

import CountryFieldSellersComponent from 'components/formFields/countries/sellers';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import ButtonComponent from 'components/buttons/button';
import UploadFileFieldComponent from 'components/formFields/upload/index';
import TooltipComponent from 'components/toolTips/index';
import FormErrorsComponent from 'components/formErrors';

import SupplierCompany from 'models/confirming/supplierCompany';

type ConfirmingSuppliersDetailsViewComponentProps = {
  supplierCompanyDetails: SupplierCompany;
  form: any;
  isProcessing: boolean;
  document: Document;
  errors: Map<string,?string>;
  formErrors: string[];
  handleChange: () => void;
  handleTypeChange: () => void;
  updateBankAccount: () => void;
  onCancel: () => void;
  handleDocumentSelect: (file: File) => void;
  handleDocumentSelectFailure: (file: File) => void;
  handleDocumentRemove: () => void;
}

export default function ConfirmingSuppliersDetailsViewComponent(props: ConfirmingSuppliersDetailsViewComponentProps) {

  return (
    <React.Fragment>
      <Paper elevation={0}>

        <Box mt={5} pt={3} pl={3} pr={3}>

          <FormErrorsComponent formErrors={props.formErrors} />
          <Grid container direction="row" justify="flex-start">
            <Grid item xs={6}>
              <PageSubTitleViewComponent id="component.confirming.supplierdetails.companyinformation" />
              <Grid container direction="row" justify="flex-start" alignItems="center">
                <Grid item alignItems="center">
                  <Box p={2}>
                    <BusinessIcon color="primary" fontSize="large" />
                  </Box>
                </Grid>
                <Grid item alignItems="center">
                  <Box pt={1} pb={2} textAlign="left">
                    <Typography variant="body1" gutterBottom>
                      <b>{props.supplierCompanyDetails.supplierName}</b>
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                      {props.supplierCompanyDetails.vatNbr}
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                      {props.supplierCompanyDetails.address}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

            </Grid>

          </Grid>
        </Box>
        <Box p={3}>
          <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
            <Grid item xs={12} md={6}>
              <PageSubTitleViewComponent id="component.confirming.supplierdetails.bankinformation" />
              <Box px={4}>
                <CountryFieldSellersComponent
                  id="form.bankCountry"
                  label={<IntlMessageComponent id="form.bankCountry.label" />}
                  error={!!props.errors.get('bankCountryId')}
                  helperText={props.errors.get('bankCountryId') ?
                    <IntlMessageComponent id={props.errors.get('bankCountryId')} /> : ''}
                  value={props.form.bankCountryId}
                  changeHandler={props.handleTypeChange('bankCountryId')}
                  fullWidth
                  margin="normal"
                  required
                />
              </Box>
            </Grid>
          </Grid>

          <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
            <Grid item xs={12} md={6}>
              <Box px={4}>
                <TextField
                  id="form.bic"
                  data-walkme="subscription-field-bic"
                  label={<IntlMessageComponent id="form.bankIdentifier.label" />}
                  error={!!props.errors.get('bankIdentifier')}
                  helperText={(props.errors.get('bankIdentifier')) ?
                    <IntlMessageComponent id={props.errors.get('bankIdentifier')} /> : ''}
                  value={props.form.bankIdentifier}
                  onChange={props.handleChange('bankIdentifier')}
                  fullWidth
                  margin="normal"
                  required
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box px={4}>
                <TextField
                  id="form.iban"
                  data-walkme="subscription-field-iban"
                  label={<IntlMessageComponent id="form.bankAccount.label" />}
                  error={!!props.errors.get('bankAccount')}
                  helperText={(props.errors.get('bankAccount')) ?
                    <IntlMessageComponent id={props.errors.get('bankAccount')} /> : ''}
                  value={props.form.bankAccount}
                  onChange={props.handleChange('bankAccount')}
                  fullWidth
                  margin="normal"
                  required
                />
              </Box>
            </Grid>
          </Grid>

          {!props.supplierCompanyDetails.hasBankAccountDocument && <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
            <Grid item xs={12} md={6}>
              <Box px={4}>
                <Box pageName="Tooltip.CompanyInfo.BankAccount" elementName={"document." + props.document.code}>
                  <TooltipComponent pageName="Tooltip.CompanyInfo.BankAccount" elementName={"document." + props.document.code}>
                    <UploadFileFieldComponent
                      document={props.document}
                      successHandler={props.handleDocumentSelect}
                      failHandler={props.handleDocumentSelectFailure}
                      removeHandler={props.handleDocumentRemove}
                    />
                  </TooltipComponent>
                </Box>
              </Box>
            </Grid>
          </Grid>}
        </Box>
        <Box p={5}>
          <ButtonComponent
            color="primary"
            variant="contained"
            isProcessing={props.isProcessing}
            onClick={props.updateBankAccount}
          ><IntlMessageComponent id="general.submit" /></ButtonComponent>
        </Box>
      </Paper>
    </React.Fragment >
  );
}
