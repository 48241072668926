/* @flow */

import React from 'react';
import {useSelector} from 'react-redux';

import MaintenanceComponent from 'components/maintenance';
import OffersComponent from 'app/offers';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function LeadOffersPage() {
  const maintenance = useSelector(state => state.locale.maintenance);

  if (maintenance) {
    return <MaintenanceComponent />
  }

  return (
    <WebsitePageComponent id="page.offerPage.description" noPaper>
      <OffersComponent />
    </WebsitePageComponent>
  );
}
