/* @flow */

import Office from 'models/companies/office';

import AddOfficeRequest from 'models/requests/companies/offices/addOfficeRequest';
import UpdateOfficeRequest from 'models/requests/companies/offices/updateOfficeRequest';

export default class OfficeForm {
  name: string;
  phone: string;
  officeTypeId: number;
  addressLine1: string;
  addressLine2: string;
  zipCode: string;
  city: string;
  countryId: number;

  constructor(office: Office) {
    if (office) {
      this.name = office.name ? office.name : '';
      this.phone = office.phone ? office.phone : '';
      this.officeTypeId = office.officeTypeId ? office.officeTypeId : 0;
      this.addressLine1 = office.addressLine1 ? office.addressLine1 : '';
      this.addressLine2 = office.addressLine2 ? office.addressLine2 : '';
      this.zipCode = office.zipCode ? office.zipCode : '';
      this.city = office.city ? office.city : '';
      this.countryId = office.countryId ? office.countryId : 0;
    } else {
      this.name = '';
      this.phone = '';
      this.officeTypeId = 0;
      this.addressLine1 = '';
      this.addressLine2 = '';
      this.zipCode = '';
      this.city = '';
      this.countryId = 0;
    }
  }

  toAddOfficeRequest(companyId: number){
    const request = new AddOfficeRequest();
    request.companyId = companyId;
    request.name = this.name;
    request.phone = this.phone;
    request.officeTypeId = this.officeTypeId;
    request.addressLine1 = this.addressLine1;
    request.addressLine2 = this.addressLine2;
    request.zipCode = this.zipCode;
    request.city = this.city;
    request.countryId = this.countryId;
    return request;
  }

  toUpdateOfficeRequest(companyId: number, officeId: number){
    const request = new UpdateOfficeRequest();
    request.companyId = companyId;
    request.officeId = officeId;    
    request.name = this.name;
    request.phone = this.phone;
    request.officeTypeId = this.officeTypeId;
    request.addressLine1 = this.addressLine1;
    request.addressLine2 = this.addressLine2;
    request.zipCode = this.zipCode;
    request.city = this.city;
    request.countryId = this.countryId;
    return request;
  }
}
