/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';

import AmountFormatterComponent from 'components/formatters/amount';
import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';

import InvoiceProposal from 'models/invoices/seller/invoiceProposal';

type SellInvoiceTab5FixFinancedPricesComponentProps = {
  invoice: InvoiceProposal;
  isProcessing: boolean;
  submitInvoice: (isFinanced: boolean) => void;
}

export default function SellInvoiceTab5FixFinancedPricesComponent(props: SellInvoiceTab5FixFinancedPricesComponentProps) {
  return (
    <React.Fragment>
      <h4><IntlMessageComponent id="component.seller.invoiceDetails.summary.financedInvoice" /></h4>
    
      <Box display="flex">
        <Box flexGrow={1} mb={1}>
          <IntlMessageComponent id="component.seller.invoiceDetails.summary.debtamount" data-walkme="sdetails-list-montantinitial" />
        </Box>
        <Box>
          <AmountFormatterComponent amount={props.invoice.debtAmount} />
        </Box>
      </Box>

      <Box display="flex">
        <Box flexGrow={1} mb={1}>
          <IntlMessageComponent id="component.seller.invoiceDetails.summary.debtamount" data-walkme="sdetails-list-montantprefinance" />
        </Box>
        <Box>
          <AmountFormatterComponent amount={props.invoice.fixFinancedPrices.financedAmount} />
        </Box>
      </Box>

      <Box display="flex" color="grey.main" fontSize={13}>
        <Box flexGrow={1} mb={1}>
          <IntlMessageComponent id="component.seller.invoiceDetails.summary.listingfee" data-walkme="sdetails-list-fraisdemiseenligne" />
        </Box>
        <Box>
          <AmountFormatterComponent amount={props.invoice.fixFinancedPrices.listingFee} />
        </Box>
      </Box>

      <Box display="flex" color="grey.main" fontSize={13}>
        <Box flexGrow={1} mb={1}>
          <IntlMessageComponent id="component.seller.invoiceDetails.summary.servicefee" data-walkme="sdetails-list-fraisdeservices" />
        </Box>
        <Box>
          <AmountFormatterComponent amount={props.invoice.fixFinancedPrices.serviceFee} />
        </Box>
      </Box>

      <Box display="flex" color="grey.main" fontSize={13}>
        <Box flexGrow={1} mb={1}>
          <IntlMessageComponent id="component.seller.invoiceDetails.summary.underwritingFee" data-walkme="sdetails-list-fraisdesouscription" />
        </Box>
        <Box>
          <AmountFormatterComponent amount={props.invoice.fixFinancedPrices.underwritingFee} />
        </Box>
      </Box>

      <Box display="flex" color="grey.main" fontSize={13}>
        <Box flexGrow={1} mb={1}>
          <IntlMessageComponent id="component.seller.invoiceDetails.summary.collectionFee" data-walkme="sdetails-list-fraisdecollection" />
        </Box>
        <Box>
          <AmountFormatterComponent amount={props.invoice.fixFinancedPrices.collectionFee} />
        </Box>
      </Box>

      <Box display="flex" color="grey.main" fontSize={13}>
        <Box flexGrow={1} mb={1}>
          <IntlMessageComponent id="component.seller.invoiceDetails.summary.insuranceFee" data-walkme="sdetails-list-fraisdassurance" />
        </Box>
        <Box>
          <AmountFormatterComponent amount={props.invoice.fixFinancedPrices.insuranceFee} />
        </Box>
      </Box>

      <Box display="flex" color="grey.main" fontSize={13}>
        <Box flexGrow={1} mb={1}>
          <IntlMessageComponent id="component.seller.invoiceDetails.summary.bankCosts" data-walkme="sdetails-list-fraisbancaires" />
        </Box>
        <Box>
          <AmountFormatterComponent amount={props.invoice.fixFinancedPrices.bankCosts} />
        </Box>
      </Box>

      {props.invoice.fixFinancedPrices.mustPayAllFees &&
        <Box mt={3} color="orange.main">
          <IntlMessageComponent id="page.sellInvoice.tab5.explanation.published" />
        </Box>
      }

      {props.invoice.fixFinancedPrices.mustPayFees && !props.invoice.fixFinancedPrices.mustPayAllFees &&
        <Box mt={3} color="orange.main">
          <IntlMessageComponent id="page.sellInvoice.tab5.explanation.published.partial" />
        </Box>
      }

      {!props.invoice.fixFinancedPrices.mustPayFees &&
        <Box mt={3} color="success.main">
          <IntlMessageComponent id="page.sellInvoice.tab5.explanation.submitted" />
        </Box>
      }

      <Box mt={5}>
        <ButtonComponent
          color="primary"
          variant="contained"
          isProcessing={props.isProcessing}
          onClick={() => props.submitInvoice(true)}
        ><IntlMessageComponent id={`${props.invoice.fixFinancedPrices.mustPayFees ? 'page.sellInvoice.tab5.button.toPayNowAndSubmit' : 'page.sellInvoice.tab5.button.putOnline'}`} /></ButtonComponent>
      </Box>
    </React.Fragment>
  );
}
