/* @flow */

import UserCompanyRoleBuyer from './userCompanyRoleBuyer';
import UserCompanyRoleDebtor from './userCompanyRoleDebtor';
import UserCompanyRoleISO from './userCompanyRoleISO';
import UserCompanyRoleSeller from './userCompanyRoleSeller';

import InvestmentProfile from 'models/buyers/investmentProfiles/investmentProfile';
import UserCompanyRoleConfirming from './userCompanyRoleConfirming';

export default class UserCompany {
  id: number;
  companyPersonId: number;
  companyUserId: number;

  name: string;

  canExecute: boolean;
  canManageUsers: boolean;
  canSignGeneralConditions: boolean;
  canSignDebtRecognition: boolean;

  isActive: boolean;
  isCompliant: boolean;
  isMain: boolean;
  isSubscribing: boolean;
  
  role: number;
  isBuyer: boolean;
  isISO: boolean;
  isSeller: boolean;
  isConfirming: boolean;

  roleBuyer: UserCompanyRoleBuyer;
  roleDebtor: UserCompanyRoleDebtor;
  roleISO: UserCompanyRoleISO;
  roleSeller: UserCompanyRoleSeller;
  roleConfirming: UserCompanyRoleConfirming;

  investmentProfile: InvestmentProfile[];

  impersonationHints: string[];
}
