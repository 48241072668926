/* @flow */

import React from 'react';
import {useSelector} from 'react-redux';
import {Route, Switch} from 'react-router-dom';

import Error404 from 'components/errors/error404';
import RestrictedRoute from 'components/route/restrictedRoute';

import ProtectedApp from 'routes/app/index';

import LeadBestPricingPage from './bestPricing';
import DebtorInvoicesToApproveTokenPage from './debtor';
import ImpersonatePage from './impersonate';
import LostPasswordPage from './lostPassword';
import LeadOffersPage from './offers';
import LeadOffersUnkownCompanyPage from './unknowncompanyoffers';
import PaymentConfirmationPage from './payments';
import ResetPasswordPage from './resetPassword';
import ContactPage from './contact';
import CallbackPage from './callback';
import SignInPage from './signIn';
import SignUpPage from './signUp';

import {ROLE_SELLER, ROLE_ISO} from 'constants/companyRoles';
import {SIGN_IN, PROTECTED_APP, PROTECTED_ISO_OFFER, PROTECTED_SELLER_BESTPRICING, PROTECTED_SELLER_OFFER} from 'constants/pageRoutes';


export default function EdebexAppRoutes() {

  // Get everything we need from the redux store
  const user = useSelector(state => state.auth.user);
  const isAuthenticated = user !== null && user.activeCompany !== null;
  const activeCompanyRole = isAuthenticated ? user.activeCompany.role : 0;

  let redirectUrlOffers = PROTECTED_APP;
  if (activeCompanyRole === ROLE_ISO) redirectUrlOffers = PROTECTED_ISO_OFFER;
  if (activeCompanyRole === ROLE_SELLER) redirectUrlOffers = PROTECTED_SELLER_OFFER;

  return (
    <Switch>
      <RestrictedRoute path="/app" component={ProtectedApp} condition={isAuthenticated} redirectUrl={SIGN_IN} />
      <RestrictedRoute path="/bestpricing" component={LeadBestPricingPage} condition={!isAuthenticated} redirectUrl={PROTECTED_SELLER_BESTPRICING} />
      <RestrictedRoute path="/offer" component={LeadOffersPage} condition={!isAuthenticated} redirectUrl={redirectUrlOffers} />
      <RestrictedRoute path="/offeruc" component={LeadOffersUnkownCompanyPage} condition={!isAuthenticated} redirectUrl={redirectUrlOffers} />
      <RestrictedRoute exact path="/" component={SignInPage} condition={!isAuthenticated} redirectUrl={PROTECTED_APP} />
      <RestrictedRoute path="/signin" component={SignInPage} condition={!isAuthenticated} redirectUrl={PROTECTED_APP} />
      <RestrictedRoute path="/signup/:email/:token" component={SignUpPage} condition={!isAuthenticated} redirectUrl={PROTECTED_APP} />
      <RestrictedRoute path="/signup" component={SignUpPage} condition={!isAuthenticated} redirectUrl={PROTECTED_APP} />
      <RestrictedRoute path="/lostpw" component={LostPasswordPage} condition={!isAuthenticated} redirectUrl={PROTECTED_APP} />
      <RestrictedRoute path="/resetpw/:email/:token" component={ResetPasswordPage} condition={!isAuthenticated} redirectUrl={PROTECTED_APP} />
      <RestrictedRoute path="/contact" component={ContactPage} condition={!isAuthenticated} redirectUrl={PROTECTED_APP} />
      <RestrictedRoute path="/callback" component={CallbackPage} condition={!isAuthenticated} redirectUrl={PROTECTED_APP} />
      <Route path="/impersonate/:username/:target/:password" component={ImpersonatePage} />
      <Route path="/impersonate/:username/:target" component={ImpersonatePage} />
      <Route path="/impersonate" component={ImpersonatePage} />
      <Route path="/payment" component={PaymentConfirmationPage} />
      <Route path="/debtor/:token" component={DebtorInvoicesToApproveTokenPage} />
      <Route component={Error404} />
    </Switch>
  );
}
