/* @flow */

import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import IconUsers from 'components/icons/users';
import IntlMessageComponent from 'components/formatters/intlMessage';
import LinkButtonComponent from 'components/buttons/linkButton';
import SecurityComponent from 'components/security/index';

import MGMHeaderListComponent from './list';

import { PROTECTED_SELLER_REFFERAL } from 'constants/pageRoutes'

const useStyles = makeStyles(theme => ({
  card: {
    position: 'relative',
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    backgroundImage: 'url(./images/mgm/background.png)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  cardAvatar: {
    align: 'center',
    backgroundColor: '#9c3',
    height: theme.spacing(22),
    textAlign: 'center',
    width: theme.spacing(22)
  },
  cardAvatarContainer: {
    position: 'relative',
    padding: theme.spacing(3),
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(10),
      paddingTop: theme.spacing(6)
    }
  },
  cardAvatarValue: {
    fontSize: 64,
  },
  cardContentContainer: {
    position: 'relative',
    padding: theme.spacing(3),
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(10),
      paddingRight: 0
    }
  },
  cardContentList: {
    color: '#4e4e4e'
  },
  cardContentListIcon: {
    color: '#4e4e4e'
  },
  rootContainer: {
    padding: theme.spacing(3),
  },
  textBlue: {
    color: theme.palette.primary.main
  }
}));

type MGMHeaderComponentProps = {
}

export default function MGMHeaderComponent(props: MGMHeaderComponentProps) {
  const classes = useStyles();

  const description = {
    bold: <Box component="span" fontWeight="fontWeightBold"><IntlMessageComponent id="seller.mgm.header.description.bold" /></Box>
  }

  return (
    <Paper elevation={0}>
      <Box p={3}>
        <Paper className={classes.card}>
          <Grid container direction="row" justify="space-between" alignItems="flex-start">
            <Grid item md={7}>
              <div className={classes.cardContentContainer}>
                <List className={classes.cardContentList}>
                  <ListItem alignItems="flex-start">
                    <ListItemIcon className={classes.cardContentListIcon}>
                      <IconUsers fontSize="large" />
                    </ListItemIcon>
                    <ListItemText
                      primary={<Typography variant="h4"><IntlMessageComponent id="seller.mgm.header.description" values={description} /></Typography>}
                    />
                  </ListItem>
                </List>
                <SecurityComponent pageName="POS.Sellers.MgmHeader" elementName="btnRefer">
                  <LinkButtonComponent
                    label={<IntlMessageComponent id="seller.mgm.header.btn" />}
                    size="large"
                    url={PROTECTED_SELLER_REFFERAL}
                  />
                </SecurityComponent>
              </div>
            </Grid>
            <Hidden smDown>
              <Grid item>
                <div className={classes.cardAvatarContainer}>
                  <Avatar className={classes.cardAvatar}>
                    <div>
                      <IntlMessageComponent id="seller.mgm.header.avatar.line1" />
                      <br />
                      <span className={classes.cardAvatarValue}><IntlMessageComponent id="seller.mgm.header.avatar.value" /></span>
                      <br />
                      <IntlMessageComponent id="seller.mgm.header.avatar.line2" />
                    </div>
                  </Avatar>
                </div>
              </Grid>
            </Hidden>
          </Grid>
        </Paper>

        <div className={classes.rootContainer}>
          <Typography variant="h4"><IntlMessageComponent id="seller.mgm.header.title" /></Typography>
          <List component="nav">
            <MGMHeaderListComponent id="seller.mgm.header.list.elem1" classes={classes} />
            <MGMHeaderListComponent id="seller.mgm.header.list.elem2" classes={classes} />
            <MGMHeaderListComponent id="seller.mgm.header.list.elem3" classes={classes} />
          </List>
          <SecurityComponent pageName="POS.Sellers.MgmHeader" elementName="btnRefer">
            <LinkButtonComponent
              label={<IntlMessageComponent id="seller.mgm.header.btn" />}
              size="large"
              url={PROTECTED_SELLER_REFFERAL}
            />
          </SecurityComponent>
        </div>
      </Box>
    </Paper>
  );
}
