/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import customSort from 'lib/sortHelpers';

import LoadingComponent from 'components/loading';

import SellerOpenInvoiceListViewComponent from './view';

import Invoice from 'models/invoices/seller/invoice';

import InvoiceSellerService from 'services/InvoiceSellerService';

type SellerOpenInvoiceListProps = {
  activeCompanyId: number;
  activeCompanyHasActiveFixContract: boolean;
}

type SellerOpenInvoiceListState = {
  invoices: Invoice[];
  isLoading: boolean;
  order: string;
  orderBy: string;
  page: number;
  pageSize: number;
}

class SellerOpenInvoiceList extends React.Component<SellerOpenInvoiceListProps, SellerOpenInvoiceListState> {

  constructor(props) {
    super(props);
    this.state = {
      invoices: [],
      isLoading: props.activeCompanyHasActiveFixContract,
      order: 'asc',
      orderBy: 'price',
      page: 0,
      pageSize: 10
    };
  }

  componentDidMount = async () => {
    if (!this.props.activeCompanyHasActiveFixContract) return;

    try {
      const invoices = await InvoiceSellerService.getOpenInvoices(this.props.activeCompanyId);
      this.setState({invoices, isLoading: false});
    } catch (e) {
      console.error(e);
    }
  }

  handlePageChange = (page: number) => this.setState({page});
  handlePageSizeChange = (pageSize: number) => this.setState({pageSize});

  handleSortOrderChange = (orderBy: string) => {
    const order = this.state.orderBy === orderBy && this.state.order === 'desc' ? 'asc' : 'desc';

    const invoices = order === 'desc'
      ? this.state.invoices.sort((a, b) => customSort(b[orderBy], a[orderBy]))
      : this.state.invoices.sort((a, b) => customSort(a[orderBy], b[orderBy]));

    this.setState({invoices, order, orderBy});
  };


  render() {
    if (!this.props.activeCompanyHasActiveFixContract) return null;

    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    if (this.state.invoices.length === 0) {
      return null;
    }

    return (
      <SellerOpenInvoiceListViewComponent
        invoices={this.state.invoices}
        order={this.state.order}
        orderBy={this.state.orderBy}
        page={this.state.page}
        pageSize={this.state.pageSize}
        handlePageChange={this.handlePageChange}
        handlePageSizeChange={this.handlePageSizeChange}
        handleSortOrderChange={this.handleSortOrderChange}
      />
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id,
  activeCompanyHasActiveFixContract: state.auth.user.activeCompany.roleSeller.hasActiveFixContract
});

export default connect(mapStateToProps)(SellerOpenInvoiceList);
