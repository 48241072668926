/* @flow */

import React from 'react';
import {useSelector} from 'react-redux';
import {Route, Switch, useRouteMatch} from 'react-router-dom';

import Error404Page from 'components/errors/error404';
import RestrictedRoute from 'components/route/restrictedRoute';

import DebtorBillsPage from './bills';
import DebtorCommercialPage from './commercial';
import DebtorDashboardPage from './dashboard';
import DebtorInvoiceDetailsPage from './invoiceDetails';
import DebtorInvoicesPortfolioPage from './invoicesPortfolio';
import DebtorInvoicesToApprovePage from './invoicesToApprove';
import DebtorInvoicesToPayPage from './invoicesToPay';
import DebtorRegisterLeadPage from './registerLead';
import DebtorRegisterSupplierPage from './registerSupplier';
import DebtorSuppliersBankAccountPage from './suppliersBankAccount';

import {PROTECTED_DEBTOR_DASHBOARD} from 'constants/pageRoutes';

export default function DebtorPage() {
  const canExecute = useSelector(state => state.auth.user.activeCompany.roleDebtor.canExecute);
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.url}/`} component={DebtorDashboardPage} />
      <Route path={`${match.url}/bills`} component={DebtorBillsPage} />
      <Route path={`${match.url}/commercial`} component={DebtorCommercialPage} />
      <RestrictedRoute path={`${match.url}/approve`} component={DebtorInvoicesToApprovePage} condition={canExecute} redirectUrl={PROTECTED_DEBTOR_DASHBOARD} />
      <RestrictedRoute path={`${match.url}/lead`} component={DebtorRegisterLeadPage} condition={canExecute} redirectUrl={PROTECTED_DEBTOR_DASHBOARD} />
      <RestrictedRoute path={`${match.url}/supplier`} component={DebtorRegisterSupplierPage} condition={canExecute} redirectUrl={PROTECTED_DEBTOR_DASHBOARD} />
      <Route path={`${match.url}/invoice/:id`} component={DebtorInvoiceDetailsPage} />
      <RestrictedRoute path={`${match.url}/pay`} component={DebtorInvoicesToPayPage} condition={canExecute} redirectUrl={PROTECTED_DEBTOR_DASHBOARD} />
      <RestrictedRoute path={`${match.url}/supplierbankaccount`} component={DebtorSuppliersBankAccountPage} condition={canExecute} redirectUrl={PROTECTED_DEBTOR_DASHBOARD} />
      <Route path={`${match.url}/portfolio`} component={DebtorInvoicesPortfolioPage} />
      <Route component={Error404Page} />
    </Switch>
  );
}
