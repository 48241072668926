/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import PhoneNumberComponent from 'components/formFields/phoneNumber';
import ButtonComponent from 'components/buttons/button';
import CompanyLookupSellerComponent from 'components/companyLookupV2/sellers';
import FormErrorsComponent from 'components/formErrors';
import IntlMessageComponent from 'components/formatters/intlMessage';
import LanguageFieldComponent from 'components/formFields/types/language';
import MandatoryFormFieldsComponent from 'components/form/mandatory';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import TitleFieldComponent from 'components/formFields/types/title';

import RegisterSupplierForm from './model';

type ConfirmingAddSupplierTab1ViewComponentProps = {
  errors: Map<string, ?string>;
  form: RegisterSupplierForm;
  formErrors: string[];
  isProcessing: boolean;
  handleChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void;
  handleCompanyChange: () => void;
  submitForm: () => void;
}

export default function ConfirmingAddSupplierTab1ViewComponent(props: ConfirmingAddSupplierTab1ViewComponentProps) {
  return (
    <React.Fragment>
      <FormErrorsComponent formErrors={props.formErrors} />

      <Box mt={3}>
        <Grid container spacing={6} alignItems="stretch">
          <Grid item xs={12} md={6}>
            <PageSubTitleViewComponent id="debtor.registerSupplier.tab1.company.title" data-walkme="debtor-registerSupplier-tab1-company-title" />

            <Box mt={3}>
              <CompanyLookupSellerComponent
                company={props.form.company}
                companyErrorLabelId={props.errors.get('company')}
                handleSelect={props.handleCompanyChange}
                required
              />
            </Box>
          </Grid>

        </Grid>
      </Box>
         
      <Box mt={5}>
        <ButtonComponent
          color="primary"
          variant="contained"
          isProcessing={props.isProcessing}
          onClick={props.submitForm}
        ><IntlMessageComponent id="general.button.validate.label" /></ButtonComponent>

        <MandatoryFormFieldsComponent/>
      </Box>
    </React.Fragment>
  );
}