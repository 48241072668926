/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import PhoneNumberComponent from 'components/formFields/phoneNumber';
import ButtonComponent from 'components/buttons/button';
import CountryFieldComponent from 'components/formFields/countries';
import FormErrorsComponent from 'components/formErrors';
import IntlMessageComponent from 'components/formatters/intlMessage';
import MandatoryFormFieldsComponent from 'components/form/mandatory';
import OfficeTypeFieldComponent from 'components/formFields/types/office';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import SecurityComponent from 'components/security';

import Office from 'models/companies/office';
import OfficeForm from './model';

type CompanyOfficeFormViewComponentProps = {
  errors: Map<string, ?string>;
  form: OfficeForm;
  formErrors: string[];
  isProcessing: boolean;
  office: Office;
  handleChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void;
  handlePhoneChange: (value: string) => void;
  handleTypeChange: (fieldName: string) => (id: number) => void;
  submitForm: () => void;
  cancelHandler: () => void;
};

export default function CompanyOfficeFormViewComponent(props: CompanyOfficeFormViewComponentProps) {

  return (
    <Paper elevation={0}>
      <Box p={3} py={4}>
        <PageSubTitleViewComponent id={`page.offices.form.${props.office ? 'edit' : 'new'}.title`} />

        <FormErrorsComponent formErrors={props.formErrors} />
      
        <Box mt={3}>
          <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
            <Grid item xs={12} md={6}>
              <Box px={4}>
                <TextField
                  id="form.office.name"
                  label={<IntlMessageComponent id="form.office.name.label" />}
                  error={!!props.errors.get('name')}
                  helperText={props.errors.get('name') ?
                    <IntlMessageComponent id={props.errors.get('name')} /> : ''}
                  value={props.form.name}
                  onChange={props.handleChange('name')}                  
                  fullWidth
                  margin="normal"
                  required
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box px={4}>
                <OfficeTypeFieldComponent
                  id="form.officeType"
                  label={<IntlMessageComponent id="form.officeType.label" />}
                  error={!!props.errors.get('officeTypeId')}
                  helperText={props.errors.get('officeTypeId') ?
                    <IntlMessageComponent id={props.errors.get('officeTypeId')} /> : ''}
                  value={props.form.officeTypeId}
                  changeHandler={props.handleTypeChange('officeTypeId')}
                  fullWidth
                  margin="normal"
                />
              </Box>
            </Grid>
          </Grid>

          <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
            <Grid item xs={12} md={6}>
              <Box px={4}>
                <PhoneNumberComponent
                  id="form.office.phone"
                  label={<IntlMessageComponent id="form.office.phone.label" />}
                  error={!!props.errors.get('phone')}
                  helperText={props.errors.get('phone') ?
                    <IntlMessageComponent id={props.errors.get('phone')} /> : ''}
                  value={props.form.phone}
                  onChange={props.handlePhoneChange}
                  fullWidth
                  margin="normal"
                />
              </Box>
            </Grid>
          </Grid>

          <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
            <Grid item xs={12} md={6}>
              <Box px={4}>
                <TextField
                  id="form.addressLine1"
                  label={<IntlMessageComponent id="form.company.addressLine1.label" />}
                  error={!!props.errors.get('addressLine1')}
                  helperText={props.errors.get('addressLine1') ?
                    <IntlMessageComponent id={props.errors.get('addressLine1')} /> : ''}
                  value={props.form.addressLine1}
                  onChange={props.handleChange('addressLine1')}
                  fullWidth
                  margin="normal"
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box px={4}>
                <TextField
                  id="form.addressLine2"
                  label={<IntlMessageComponent id="form.company.addressLine2.label" />}
                  error={!!props.errors.get('addressLine2')}
                  helperText={props.errors.get('addressLine2') ?
                    <IntlMessageComponent id={props.errors.get('addressLine2')} /> : ''}
                  value={props.form.addressLine2}
                  onChange={props.handleChange('addressLine2')}
                  fullWidth
                  margin="normal"
                />
              </Box>
            </Grid>
          </Grid>

          <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
            <Grid item xs={12} md={6}>
              <Box px={4}>
                <TextField
                  id="form.zipCode"
                  label={<IntlMessageComponent id="form.company.zipCode.label" />}
                  error={!!props.errors.get('zipCode')}
                  helperText={props.errors.get('zipCode') ?
                    <IntlMessageComponent id={props.errors.get('zipCode')} /> : ''}
                  value={props.form.zipCode}
                  onChange={props.handleChange('zipCode')}
                  fullWidth
                  margin="normal"
                  required
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box px={4}>
                <TextField
                  id="form.city"
                  label={<IntlMessageComponent id="form.company.city.label" />}
                  error={!!props.errors.get('city')}
                  helperText={props.errors.get('city') ?
                    <IntlMessageComponent id={props.errors.get('city')} /> : ''}
                  value={props.form.city}
                  onChange={props.handleChange('city')}
                  fullWidth
                  margin="normal"
                  required
                />
              </Box>
            </Grid>
          </Grid>

          <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
            <Grid item xs={12} md={6}>
              <Box px={4}>
                <CountryFieldComponent
                  id="form.country"
                  label={<IntlMessageComponent id="form.company.country.label" />}
                  error={!!props.errors.get('countryId')}
                  helperText={props.errors.get('countryId') ?
                    <IntlMessageComponent id={props.errors.get('countryId')} /> : ''}
                  value={props.form.countryId}
                  changeHandler={props.handleTypeChange('countryId')}
                  required
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box mt={5}>
          {props.cancelHandler &&
            <ButtonComponent
              color="default"
              variant="contained"
              onClick={props.cancelHandler}
            ><IntlMessageComponent id="general.cancel" /></ButtonComponent>
          }

          <SecurityComponent
            pageName={props.office ? 'POS.OfficeDetail' : 'POS.Offices'}
            elementName={props.office ? 'btnSave' : 'btnNew'}
          >
            <Box component="span" ml={1}>
              <ButtonComponent
                color="primary"
                variant="contained"
                isProcessing={props.isProcessing}
                onClick={props.submitForm}
              ><IntlMessageComponent id="general.submit" /></ButtonComponent>
              
              <MandatoryFormFieldsComponent/>
            </Box>
          </SecurityComponent>
        </Box>
      </Box>
    </Paper>
  );
}
