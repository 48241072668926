/* @flow */

import React from 'react';

import WebsitePageComponent from 'components/websiteStructure/pages';

import DebtorInvoicesToApproveTableComponent from 'app/debtors/invoicesToApproveTable';

export default function DebtorInvoicesToApprovePage() {
  return (
    <WebsitePageComponent id="debtor.approve.title" data-walkme="debtor-approve-title" noPaper>
      <DebtorInvoicesToApproveTableComponent showFilters />
    </WebsitePageComponent>
  );
}
