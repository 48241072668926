/* @flow */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function IconExclamationMarkOutlined(props) {
  return (
    <SvgIcon viewBox="0 0 62.72 62.69" {...props}>
      <g>
        <path d="M421.57,328.5a31.35,31.35,0,1,1,31.43-31C453,314.52,438.76,328.52,421.57,328.5Zm28.65-31.16c-.12-16.16-12.68-28.75-28.54-28.75A28.31,28.31,0,0,0,393.1,296c-.43,17.25,12.77,29.72,28.55,29.67A28.45,28.45,0,0,0,450.22,297.34Z" transform="translate(-390.28 -265.81)" />
        <path d="M423.1,293.55c0,3.37,0,6.74,0,10.11,0,1.31-.48,2-1.42,2s-1.45-.64-1.46-1.93V283.36c0-1.26.53-2,1.41-2s1.47.77,1.47,2.09C423.11,286.81,423.1,290.18,423.1,293.55Z" transform="translate(-390.28 -265.81)" />
        <path d="M420.22,311.36c.12-.73.38-1.45,1.43-1.45s1.4.67,1.43,1.54a1.43,1.43,0,0,1-1.35,1.55C421,313.1,420.24,312.31,420.22,311.36Z" transform="translate(-390.28 -265.81)" />
      </g>
    </SvgIcon>
  );
}
