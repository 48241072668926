/* @flow */

import moment from 'moment';
import Document from './document';

export default class RequiredCommissionDocument extends Document {
  commissionId: moment;
  periodFrom: moment;
  periodTo: moment;
}
