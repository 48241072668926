/* @flow */

export default class DownloadLeadReportRequest {
  companyId: number;
  format: string;
  isoCompanyIds: number[];
  isoOfficeIds: number[];
  isoPersonIds: number[];
  creationDateFrom: string;
  creationDateTo: string;
  closedDateFrom: string;
  closedDateTo: string;
  statusIds: number[];
  ownerClasses: string[];
  convertibles: string[];
  lostReasonsIds: number[];
  openClosedLeads: number[];

  constructor(companyId: number, format: string) {
    this.companyId = companyId;
    this.format = format;
    this.isoCompanyIds = [];
    this.isoOfficeIds = [];
    this.isoPersonIds = [];
    this.creationDateFrom = null;
    this.creationDateTo = null;
    this.closedDateFrom = null;
    this.closedDateTo = null;
    this.statusIds = [];
    this.ownerClasses = [];
    this.convertibles = [];
    this.lostReasonsIds = [];
    this.openClosedLeads = [];
  }

  toRouteParameters() {
    let route = `companyId=${this.companyId}&format=${this.format}`;
    this.isoCompanyIds.forEach(isoCompanyId => {
      route += `&isoCompanyIds=${isoCompanyId}`;
    });
    this.isoOfficeIds.forEach(isoOfficeId => {
      route += `&isoOfficeIds=${isoOfficeId}`;
    });
    this.isoPersonIds.forEach(isoPersonId => {
      route += `&isoPersonIds=${isoPersonId}`;
    });
    if (this.creationDateFrom && this.creationDateTo) {
      route += `&creationDateFrom=${this.creationDateFrom}&creationDateTo=${this.creationDateTo}`;
    }
    if (this.closedDateFrom && this.closedDateTo) {
      route += `&closedDateFrom=${this.closedDateFrom}&closedDateTo=${this.closedDateTo}`;
    }
    this.statusIds.forEach(statusId => {
      route += `&statusIds=${statusId}`;
    });
    this.ownerClasses.forEach(ownerClass => {
      route += `&ownerClasses=${ownerClass}`;
    });
    this.convertibles.forEach(convertible => {
      route += `&convertibles=${convertible}`;
    });
    this.lostReasonsIds.forEach(lostReasonsId => {
      route += `&lostReasonsIds=${lostReasonsId}`;
    });
    this.openClosedLeads.forEach(openClosedLeadId => {
      route += `&openClosedLeads=${openClosedLeadId}`;
    });
    return route;
  }
}
