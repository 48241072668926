/* @flow */

import React from 'react';
import {useSelector} from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import {
  INVOICE_STATE_FILTER_OPEN,
  INVOICE_STATE_FILTER_CLOSED
} from 'constants/constants';

type BuyerInvoiceStateFilterComponentProps = {
  disabled: boolean;
  fullWidth: boolean;
  margin: string;
  value: string;
  changeHandler: (statusKey: string) => void;
}

const useStyles = makeStyles({
  inputRoot: {
    "& .MuiIconButton-label": {
      color: '#3cf',
    }
  },
});

export default function BuyerInvoiceStateFilterComponent(props: BuyerInvoiceStateFilterComponentProps) {
  const translations = useSelector(state => state.locale.translations.filter(t => t.key.startsWith('buyer.portfolio.filter.state')));

  const options = [];
  [INVOICE_STATE_FILTER_OPEN, INVOICE_STATE_FILTER_CLOSED].forEach(val => {
    const id = val;
    const key = `buyer.portfolio.filter.state.${val.toLowerCase()}`;
    const label = translations.find(t => t.key === key)?.text || key;
    options.push({id, label});
  });

  const value = options.find(o => o.id === props.value);
  
  const classes = useStyles();

  const handleSelect = (value: string) => {
    if (value == null) return props.changeHandler('');
    return props.changeHandler(value);
  }; 

  return (
    <Autocomplete
      disabled={props.disabled}
      getOptionLabel={(value) => value.label}
      onChange={(_, newValue) => handleSelect(newValue?.id || null)}
      openOnFocus
      options={options}
      classes={classes}
      renderInput={(params) =>
        <TextField 
          {...params}
          fullWidth={props.fullWidth}
          margin={props.margin}
        />
      }
      value={value}
    />
  );
}
