/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import LoadingComponent from 'components/loading';

import SellerKPIsViewComponent from './view';

import KPI from 'models/dashboards/kpi';

import SellerService from 'services/sellerService';

type SellerKPIsComponentProps = {
  activeCompanyId: number
}


type SellerKPIsComponentState = {
  isLoading: boolean,
  kpis: KPI[]
}

class SellerKPIsComponent extends React.Component<SellerKPIsComponentProps, SellerKPIsComponentState> {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      kpis: null
    };
  }

  componentDidMount = async () => {
    try {
      const kpis = await SellerService.getKPIs(this.props.activeCompanyId);
      this.setState({isLoading: false, kpis});
    } catch (e) {
      console.error(e);
    }
  }

  render() {
    if (this.state.isLoading) return (<LoadingComponent />);
    return (<SellerKPIsViewComponent kpis={this.state.kpis} />);
  }
}

const mapStateToProps = ({auth}) => ({
  activeCompanyId: auth.user.activeCompany.id
});

export default connect(mapStateToProps)(SellerKPIsComponent);
