/* @flow */

import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {makeStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import IntlMessageComponent from 'components/formatters/intlMessage';

import {userSwitchCompanyRoleAction} from 'store/actions/authActions';
import {ROLE_BUYER, ROLE_CONFIRMING, ROLE_DEBTOR, ROLE_ISO, ROLE_SELLER} from 'constants/companyRoles';

const useStyles = makeStyles(theme => ({
  nested: {
    paddingLeft: theme.spacing(5),
  },
}));

export default function WebsiteHeaderCompanyMenuRoleListComponent(props) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();

  const user = useSelector(state => state.auth.user);
  if (!user) return null;

  const flipOpen = () => setOpen(!open);

  const toggleRole = (roleId) => {
    flipOpen();
    props.flipOpen();
    dispatch(userSwitchCompanyRoleAction(roleId));
  };

  const label = (
    <span>
      <IntlMessageComponent id="header.company.role"/>&nbsp;
      <em>
        <b>
          {user.activeCompany.role === ROLE_BUYER && <IntlMessageComponent id="header.company.buyer" />}
          {user.activeCompany.role === ROLE_DEBTOR && <IntlMessageComponent id="header.company.debtor" />}
          {user.activeCompany.role === ROLE_ISO && <IntlMessageComponent id="header.company.iso"/>}
          {user.activeCompany.role === ROLE_SELLER && <IntlMessageComponent id="header.company.seller" />}
          {user.activeCompany.role === ROLE_CONFIRMING && <IntlMessageComponent id="header.company.confirming" />}
        </b>
      </em>
    </span>
  );

  return (
    <List component="nav" dense>
      <ListItem button onClick={flipOpen}>
        <ListItemText primary={label} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" dense disablePadding>
          {user.activeCompany.isSeller &&
            <React.Fragment>
              {user.activeCompany.role === ROLE_SELLER ? (
                <ListItem className={classes.nested}>
                  <ListItemText primary={<IntlMessageComponent id="header.company.seller" />} />
                </ListItem>
              ) : (
                <ListItem button className={classes.nested} onClick={() => toggleRole(ROLE_SELLER)}>
                  <ListItemText primary={<IntlMessageComponent id="header.company.seller" />} />
                </ListItem>
              )}
            </React.Fragment>
          }

          {user.activeCompany.isBuyer &&
            <React.Fragment>
              {user.activeCompany.role === ROLE_BUYER ? (
                <ListItem className={classes.nested}>
                  <ListItemText primary={<IntlMessageComponent id="header.company.buyer" />} />
                </ListItem>
              ) : (
                <ListItem button className={classes.nested} onClick={() => toggleRole(ROLE_BUYER)}>
                  <ListItemText primary={<IntlMessageComponent id="header.company.buyer" />} />
                </ListItem>
              )}
            </React.Fragment>
          }

          {user.activeCompany.isISO &&
            <React.Fragment>
              {user.activeCompany.role === ROLE_ISO ? (
                <ListItem className={classes.nested}>
                  <ListItemText primary={<IntlMessageComponent id="header.company.iso" />} />
                </ListItem>
              ) : (
                <ListItem button className={classes.nested} onClick={() => toggleRole(ROLE_ISO)}>
                  <ListItemText primary={<IntlMessageComponent id="header.company.iso" />} />
                </ListItem>
              )}
            </React.Fragment>
          }

          {user.activeCompany.isDebtor &&
            <React.Fragment>
              {user.activeCompany.role === ROLE_DEBTOR ? (
                <ListItem className={classes.nested}>
                  <ListItemText primary={<IntlMessageComponent id="header.company.debtor" />} />
                </ListItem>
              ) : (
                <ListItem button className={classes.nested} onClick={() => toggleRole(ROLE_DEBTOR)}>
                  <ListItemText primary={<IntlMessageComponent id="header.company.debtor" />} />
                </ListItem>
              )}
            </React.Fragment>
          }

          {user.activeCompany.isConfirming &&
            <React.Fragment>
              {user.activeCompany.role === ROLE_CONFIRMING ? (
                <ListItem className={classes.nested}>
                  <ListItemText primary={<IntlMessageComponent id="header.company.confirming" />} />
                </ListItem>
              ) : (
                <ListItem button className={classes.nested} onClick={() => toggleRole(ROLE_CONFIRMING)}>
                  <ListItemText primary={<IntlMessageComponent id="header.company.confirming" />} />
                </ListItem>
              )}
            </React.Fragment>
          }
        </List>
      </Collapse>
    </List>
  );
}
