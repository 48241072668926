/* @flow */

import React from 'react';

import TermsAndConditionsFailureComponent from 'app/users/termsAndConditions/failure';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function TermsAndConditionsFailurePage() {
  return (
    <WebsitePageComponent id="page.tos.h1">
      <TermsAndConditionsFailureComponent />
    </WebsitePageComponent>
  );
}
