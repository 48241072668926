/* @flow */

export default class DownloadSelectedBillsRequest {
  companyId: number;
  billIds: number[];
  
  constructor(companyId: number, billIds: number[]) {
    this.companyId = companyId;
    this.billIds = billIds;
  }

  toRouteParameters() {
    return `companyId=${this.companyId}&billIds=${this.billIds.join('&billIds=')}`;
  }
}
  