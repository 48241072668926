import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import PhoneNumberComponent from 'components/formFields/phoneNumber';
import ButtonComponent from 'components/buttons/button';
import CountryFieldComponent from 'components/formFields/countries/index';
import DatePickerComponent from 'components/formFields/dates/datePicker';
import FormErrorsComponent from 'components/formErrors';
import IntlMessageComponent from 'components/formatters/intlMessage';
import MandatoryFormFieldsComponent from 'components/form/mandatory';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import TitleFieldComponent from 'components/formFields/types/title';
import LanguageFieldComponent from 'components/formFields/types/language';


import IsoNoCompanyForm from './model';

type IsoNoCompanySignUpViewComponentProps = {
  errors: Map<string,?string>;
  form: IsoNoCompanyForm;
  formErrors: string[];
  isProcessing: boolean;
  handleChange: (fieldName: string) => (event: object) => void;
  handleTypeChange: (fieldName: string) => (id: number) => void;
  submitForm: () => void;
}

export default function IsoNoCompanySignUpViewComponent(props: IsoNoCompanySignUpViewComponentProps) {
  return (
    <React.Fragment>
      <FormErrorsComponent formErrors={props.formErrors} />

      <Box mb={3}>
        <IntlMessageComponent id="page.subscription.isonocompany.description" />
      </Box>
      <PageSubTitleViewComponent id="page.profile.subtitle.personalinfo" />

      <Box mt={3}>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TitleFieldComponent
                label={<IntlMessageComponent id="form.title.label" />}
                error={!!props.errors.get('titleId')}
                helperText={(props.errors.get('titleId')) ?
                  <IntlMessageComponent id={props.errors.get('titleId')} /> : ''}
                value={props.form.titleId}
                changeHandler={props.handleTypeChange('titleId')}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>
        </Grid>

        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.name"
                data-walkme="subscription-field-nom"
                label={<IntlMessageComponent id="form.name.label" />}
                error={!!props.errors.get('lastName')}
                helperText={(props.errors.get('lastName')) ?
                  <IntlMessageComponent id={props.errors.get('lastName')} /> : ''}
                value={props.form.lastName}
                onChange={props.handleChange('lastName')}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.firstName"
                data-walkme="subscription-field-prenom"
                label={<IntlMessageComponent id="form.firstname.label" />}
                error={!!props.errors.get('firstName')}
                helperText={(props.errors.get('firstName')) ?
                  <IntlMessageComponent id={props.errors.get('firstName')} /> : ''}
                value={props.form.firstName}
                onChange={props.handleChange('firstName')}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>
        </Grid>

        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <LanguageFieldComponent
                id="form.language"
                label={<IntlMessageComponent id="form.language.label" />}
                error={!!props.errors.get('languageId')}
                helperText={(props.errors.get('languageId')) ?
                  <IntlMessageComponent id={props.errors.get('languageId')} /> : ''}
                value={props.form.languageId}
                changeHandler={props.handleTypeChange('languageId')}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.registrationNumber"
                label={<IntlMessageComponent id="form.isonocompany.registrationNumber.label" />}
                error={!!props.errors.get('registrationNumber')}
                helperText={(props.errors.get('registrationNumber')) ?
                  <IntlMessageComponent id={props.errors.get('registrationNumber')} /> : ''}
                value={props.form.registrationNumber}
                onChange={props.handleChange('registrationNumber')}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box mt={3}>

      </Box>
      <PageSubTitleViewComponent id="page.profile.subtitle.contactInfo" />

      <Box mt={3}>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.email"
                label={<IntlMessageComponent id="form.email.label" />}
                error={!!props.errors.get('email')}
                helperText={(props.errors.get('email')) ?
                  <IntlMessageComponent id={props.errors.get('email')} /> : ''}
                value={props.form.email}
                onChange={props.handleChange('email')}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <PhoneNumberComponent
                id="form.mobilePresence"
                label={<IntlMessageComponent id="form.phone.label" />}
                error={!!props.errors.get('mobilePresence')}
                helperText={(props.errors.get('mobilePresence')) ?
                  <IntlMessageComponent id={props.errors.get('mobilePresence')} /> : <IntlMessageComponent id="form.phone.helperText" />}
                value={props.form.mobilePresence}
                onChange={props.handlePhoneChange}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>
        </Grid>

        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.addressLine1"
                data-walkme="subscription-field-adresse"
                label={<IntlMessageComponent id="form.person.addressLine1.label" />}
                error={!!props.errors.get('addressLine1')}
                helperText={(props.errors.get('addressLine1')) ?
                  <IntlMessageComponent id={props.errors.get('addressLine1')} /> : ''}
                value={props.form.addressLine1}
                onChange={props.handleChange('addressLine1')}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.addressLine2"
                label={<IntlMessageComponent id="form.person.addressLine2.label" />}
                error={!!props.errors.get('addressLine2')}
                helperText={(props.errors.get('addressLine2')) ?
                  <IntlMessageComponent id={props.errors.get('addressLine2')} /> : ''}
                value={props.form.addressLine2}
                onChange={props.handleChange('addressLine2')}
                fullWidth
                margin="normal"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.zipCode"
                label={<IntlMessageComponent id="form.person.zipCode.label" />}
                error={!!props.errors.get('zipCode')}
                helperText={(props.errors.get('zipCode')) ?
                  <IntlMessageComponent id={props.errors.get('zipCode')} /> : ''}
                value={props.form.zipCode}
                onChange={props.handleChange('zipCode')}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.city"
                label={<IntlMessageComponent id="form.person.city.label" />}
                error={!!props.errors.get('city')}
                helperText={(props.errors.get('city')) ?
                  <IntlMessageComponent id={props.errors.get('city')} /> : ''}
                value={props.form.city}
                onChange={props.handleChange('city')}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <CountryFieldComponent
                id="form.country"
                label={<IntlMessageComponent id="form.person.country.label" />}
                error={!!props.errors.get('countryId')}
                helperText={(props.errors.get('countryId')) ?
                  <IntlMessageComponent id={props.errors.get('countryId')} /> : ''}
                value={props.form.countryId}
                changeHandler={props.handleTypeChange('countryId')}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>
        </Grid>


      </Box>

      <Box mt={5}>
        <ButtonComponent
          color="primary"
          variant="contained"
          isProcessing={props.isProcessing}
          onClick={props.submitForm}
        ><IntlMessageComponent id="general.continue" /></ButtonComponent>

        <MandatoryFormFieldsComponent />
      </Box>
    </React.Fragment>
  );
}