/* @flow */

import React from 'react';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import ComponentWrapperComponent from 'components/websiteStructure/componentWrapper';
import ConfirmationDialogComponent from 'components/confirmationDialog';
import IntlMessageComponent from 'components/formatters/intlMessage';
import LoadingComponent from 'components/loading';

import BuyerInvestmentProfileCurrentViewComponent from './current';
import BuyerInvestmentProfileFormComponent from './update';

import InvestmentProfileDefaults from 'models/buyers/investmentProfiles/investmentProfileDefaults';
import InvestmentProfileValues from 'models/buyers/investmentProfiles/investmentProfileValues';

import DisableInvestmentProfileRequest from 'models/requests/buyers/disableInvestmentProfileRequest';

import BuyerService from 'services/BuyerService';
import { handleApiFormResponse } from 'lib/forms';
import NotificationHelper from 'lib/notifications';

import { disableCompanyInvestmentProfileAction } from 'store/actions/authActions';
import { addInvestmentProfileSuccess } from 'store/actions/authActions';

import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import { PROTECTED_BUYER_INVESTMENT_PROFILE_NEW } from 'constants/pageRoutes';
import { NavLink } from 'react-router-dom';
import ButtonComponent from 'components/buttons/button';
import SecurityComponent from 'components/security/index';


type BuyerInvestmentProfileComponentProps = {
  activeCompanyId: number;
  activeCompanyInvestmentProfileId: number;
  investmentProfile: InvestmentProfileValues;
  canExecute: boolean;
  hasActiveMembership: boolean;
  disableCompanyInvestmentProfileAction: (investmentProfileId: number) => void;
  addInvestmentProfileSuccess: (companyId: number) => void;
}

type BuyerInvestmentProfileComponentState = {
  currentProfile: InvestmentProfileValues;
  defaults: InvestmentProfileDefaults;
  isActive: boolean;
  isLoading: boolean;
  requestedProfile: InvestmentProfileValues;
  showConfirmationDialog: boolean;
  hasInvoicesListedTo: boolean;
}

class BuyerInvestmentProfileComponent extends React.Component<BuyerInvestmentProfileComponentProps, BuyerInvestmentProfileComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      currentProfile: null,
      defaults: null,
      isActive: false,
      isLoading: true,
      requestedProfile: null,
      showConfirmationDialog: false
    };
  }

  componentDidMount = async () => {
    try {
      // Load defaults
      const defaults = await BuyerService.getInvestmentProfileDefaults();
      // Load profiles
      const response = await BuyerService.getInvestmentProfile(this.props.activeCompanyId, this.props.activeCompanyInvestmentProfileId);

      this.setState({
        currentProfile: response.current,
        defaults,
        isActive: response.isActive,
        isLoading: false,
        requestedProfile: response.requested,
        hasInvoicesListedTo: response.hasInvoicesListedTo
      });
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  };

  disableInvestmentProfile = async () => {
    try {
      const request = new DisableInvestmentProfileRequest();
      request.companyId = this.props.activeCompanyId;
      request.investmentProfileId = this.props.activeCompanyInvestmentProfileId;
      await BuyerService.disableInvestmentProfile(request);
      NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS);
      this.props.disableCompanyInvestmentProfileAction(this.props.activeCompanyInvestmentProfileId);
      this.setState({ isActive: false, showConfirmationDialog: false });
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }
  };

  onUpdateSucess = async () => {
    this.setState({ currentProfile: null, isLoading: true, requestedProfile: null });

    try {
      const response = await BuyerService.getInvestmentProfile(this.props.activeCompanyId, this.props.activeCompanyInvestmentProfileId);
      this.props.addInvestmentProfileSuccess({ id: this.props.investmentProfile.id, name: response.current.name, isActive: this.props.investmentProfile.isActive, isMain: this.props.investmentProfile.isMain, companyId: this.props.activeCompanyId });
      this.setState({ currentProfile: response.current, isLoading: false, requestedProfile: response.requested });
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  }

  onDisableButtonClick = () => this.setState({ showConfirmationDialog: true });
  hideConfirmationDialog = () => this.setState({ showConfirmationDialog: false });

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <React.Fragment>
        <ComponentWrapperComponent small>
          <IntlMessageComponent id="page.investmentProfile.marketPlaces" />
        </ComponentWrapperComponent>

        <Box mt={3}>
          <Grid container spacing={6} alignItems="stretch">
            <Grid item xs={12} md={6}>
              <BuyerInvestmentProfileCurrentViewComponent
                isActive={this.state.isActive}
                profile={this.state.currentProfile}
                onDisableButtonClick={this.onDisableButtonClick}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <BuyerInvestmentProfileFormComponent
                defaults={this.state.defaults}
                isActive={this.state.isActive}
                profile={this.state.requestedProfile}
                onUpdateSucess={this.onUpdateSucess}
                hasInvoicesListedTo={this.state.hasInvoicesListedTo}
                canExecute={this.props.canExecute}
              />
            </Grid>
          </Grid>
        </Box>
        {this.props.canExecute && this.props.hasActiveMembership && <SecurityComponent pageName="POS.Buyers.InvestmentProfile" elementName="btnCreate">
          <Box mt={3} width={1 / 2} pr={3}>
            <ComponentWrapperComponent small>
              <Box display="flex" flexWrap="wrap" height="100%">
                <Box alignContent="flex-start">
                  <PageSubTitleViewComponent id="buyer.ip.newip" />
                  <NavLink style={{ textDecoration: 'none' }} to={PROTECTED_BUYER_INVESTMENT_PROFILE_NEW}>
                    <Box mt={3}>
                      <ButtonComponent
                        color="primary"
                        variant="contained"
                      ><IntlMessageComponent id="general.btn.newip" /></ButtonComponent>
                    </Box>
                  </NavLink>
                </Box>
              </Box>
            </ComponentWrapperComponent>
          </Box>
        </SecurityComponent>}        
        {(this.state.isActive && this.state.showConfirmationDialog) &&
          <ConfirmationDialogComponent
            text="buyer.ip.disable.confirm.dialog.text"
            title="buyer.ip.disable.confirm.dialog.title"
            onAccept={this.disableInvestmentProfile}
            onClose={this.hideConfirmationDialog}
          />
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id,
  activeCompanyInvestmentProfileId: state.auth.user.activeCompany.investmentProfile.id,
  investmentProfile: state.auth.user.activeCompany.investmentProfile,
  canExecute: state.auth.user.activeCompany.canExecute,
  hasActiveMembership: state.auth.user.activeCompany.hasActiveMembership
});

export default connect(mapStateToProps, { disableCompanyInvestmentProfileAction, addInvestmentProfileSuccess })(BuyerInvestmentProfileComponent);
