/* @flow */

import React from 'react';

import BuyerPerformanceComponent from 'app/buyers/performance';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function BuyerPerformancePage() {
  return (
    <WebsitePageComponent id="buyer.perf.title" data-walkme="buyer-performance-title" noPaper>
      <BuyerPerformanceComponent />
    </WebsitePageComponent>
  );
}
