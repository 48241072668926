/* @flow */

import ChangeEmailRequest from 'models/requests/register/changeEmailRequest';

export default class ChangeEmailForm {
  email: string;

  constructor() {
    this.email = '';
  }

  toRequest(email: string) {
    const request = new ChangeEmailRequest();
    request.email = email;
    request.newEmail = this.email;
    return request;
  }
}
