import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import IconClose from '@material-ui/icons/Close';
import IconDownload from '@material-ui/icons/CloudDownload';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import IntlMessageComponent from 'components/formatters/intlMessage';

import DocumentService from 'services/DocumentService';
import NotificationHelper from 'lib/notifications';

const useStyles = makeStyles(theme => ({
  button: {
    borderColor: '#fff',
    color: '#fff',
    top: theme.spacing(3)
  },
  closeButton: {
    color: '#fff',
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1)
  },
  icon: {
    color: '#fff'
  },
  itemIcon: {
    minWidth: '40px'
  },
  paper: {
    backgroundColor: theme.palette['primary'].main,
    color: '#fff',
    padding: theme.spacing(2),
  }
}));

export default function TransitionsModal(props) {
  const classes = useStyles();

  const downloadDocument = async () => {
    try {
      await DocumentService.downloadDocument('sellinvoiceguide.pdf');
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  }

  return (
    <Dialog
      fullWidth  
      maxWidth='md'
      open
      onClose={props.handleClose}
      PaperProps={{ classes: {root: classes.paper } }}
      scroll='body'
    >
      <DialogTitle>
        <IconButton className={classes.closeButton} onClick={props.handleClose}>
          <IconClose />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box mb={5}>
          <Grid container>
            <Grid item xs={12} lg={6}>
              <Box pr={3}>
                <Typography variant="h3" gutterBottom>
                  <IntlMessageComponent id='seller.sell.modal.title' />
                </Typography>
                <Typography variant="h5" gutterBottom>
                  <IntlMessageComponent id='seller.sell.modal.description' />
                </Typography>
                <Button
                  className={classes.button}
                  variant="outlined"
                  startIcon={<IconDownload className={classes.icon} />}
                  onClick={downloadDocument}
                >
                  <IntlMessageComponent id='seller.sell.modal.btn.download' />
                </Button>
              </Box>
            </Grid>
            
            <Grid item xs={12} lg={6}>
              <Box mt={1}>
                <Box mt={3}>
                  <Typography variant="body1" gutterBottom>
                    <b><IntlMessageComponent id='seller.sell.modal.list.title' /></b>
                  </Typography>
                </Box>

                <List component="nav" aria-label="main mailbox folders">
                  <ListItem alignItems="flex-start">
                    <ListItemIcon className={classes.itemIcon}>
                      <CheckCircleOutlineIcon className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="body1"><IntlMessageComponent id="seller.sell.modal.list.elem1"/></Typography>} />
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemIcon className={classes.itemIcon}>
                      <CheckCircleOutlineIcon className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="body1"><IntlMessageComponent id="seller.sell.modal.list.elem2"/></Typography>} />
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemIcon className={classes.itemIcon}>
                      <CheckCircleOutlineIcon className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="body1"><IntlMessageComponent id="seller.sell.modal.list.elem3"/></Typography>} />
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemIcon className={classes.itemIcon}>
                      <CheckCircleOutlineIcon className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="body1"><IntlMessageComponent id="seller.sell.modal.list.elem4"/></Typography>} />
                  </ListItem>
                </List>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
}