/* @flow */

import React, {useState} from 'react';

import Box from '@material-ui/core/Box';

import ButtonComponent from 'components/buttons/button';
import ComponentWrapperComponent from 'components/websiteStructure/componentWrapper';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import CompanyDocumentsUploadCommissionInvoiceDialogComponent from './dialog';

type CompanyDocumentsUploadCommissionInvoiceComponentProps = {
}

export default function CompanyDocumentsUploadCommissionInvoiceComponent(props: CompanyDocumentsUploadCommissionInvoiceComponentProps) {
  const [isOpen, setOpen] = useState(false);

  return (
    <ComponentWrapperComponent small>
      <PageSubTitleViewComponent id="page.iso.documents.title.upload.commissioninginvoice" data-walkme="isodocuments-text-invoice" />
      
      <Box mt={3}>
        <ButtonComponent
          color="primary"
          variant="contained"
          onClick={() => setOpen(true)}
        ><IntlMessageComponent id="page.documents.form.button.upload" /></ButtonComponent>
      </Box>

      {isOpen &&
        <CompanyDocumentsUploadCommissionInvoiceDialogComponent onClose={() => setOpen(false)} />
      }
    </ComponentWrapperComponent>
  );
}