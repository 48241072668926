/* @flow */


import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

type DebtorRegisterLeadTab1LeadAlreadyExistViewComponentProps = {
  resetForm: () => void;
}

export default function DebtorRegisterLeadTab1LeadAlreadyExistViewComponent(props: DebtorRegisterLeadTab1LeadAlreadyExistViewComponentProps) {
  return (
    <React.Fragment>
      <PageSubTitleViewComponent id="debtor.registerLead.tab1.leadTaken.title" />
      
      <Box bgcolor="lightblue.main" mt={5} px={5} py={4}>
        <Typography variant="body1">
          <IntlMessageComponent id="debtor.registerLead.tab1.leadTaken.text" />
        </Typography>
      </Box>

      <Box mt={5}>
        <ButtonComponent
          color="primary"
          variant="contained"
          onClick={props.resetForm}
        ><IntlMessageComponent id="general.reset" /></ButtonComponent>
      </Box>
    </React.Fragment>
  );
}
