/* @flow */

import React from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';

type TypeImplComponentProps = {
  error: boolean;
  fullWidth: boolean;
  helperText: IntlMessage;
  id: string;
  isLoading: boolean;
  label: IntlMessage;
  margin: string;
  required: boolean;
  readOnly: boolean;
  value: number;
  values: { id: number, name: string }[];
  changeHandler: (typeId: number) => void;
}

export default function TypeImplComponent(props: TypeImplComponentProps) {
  const handleSelect = (value) => {
    if (value == null) return props.changeHandler(0);
    return props.changeHandler(value.id);
  };

  const value = props.values.find(c => c.id === props.value) || null;

  return (
    <React.Fragment>
      {!props.readOnly &&
        <Autocomplete
          getOptionLabel={(value) => value.name}
          loading={props.isLoading}
          onChange={(_, newValue) => handleSelect(newValue)}
          openOnFocus
          options={props.values}
          renderInput={(params) =>
            <TextField
              {...params}
              label={props.label}
              error={props.error}
              helperText={props.error ? props.helperText : ''}
              fullWidth={props.fullWidth}
              margin={props.margin}
              required={props.required}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <React.Fragment>
                    {props.InputProps?.startAdornment}
                  </React.Fragment>),
                endAdornment: (
                  <React.Fragment>
                    {props.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          }
          value={value}
        />}
      {props.readOnly &&
        <TextField
          label={props.label}
          error={props.error}
          helperText={props.error ? props.helperText : ''}
          fullWidth={props.fullWidth}
          margin={props.margin}
          required={props.required}
          value={value?.name}
          InputProps={{
            readOnly: true,
            startAdornment: (
              <React.Fragment>
                {props.InputProps?.startAdornment}
              </React.Fragment>),
            endAdornment: (
              <React.Fragment>
                {props.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
              </React.Fragment>
            ),
          }}
        />
      }
    </React.Fragment>
  );
}
