/* @flow */

import Company from 'models/companies/company';

import RegisterSupplierRequest from 'models/requests/debtors/registerSupplierRequest';

export default class RegisterSupplierForm {
  company: Company;

  lastName: string;
  firstName: string;
  titleId: number;

  email: string;
  phone: string;
  languageId: number;

  constructor() {
    this.company = null;
    this.lastName = '';
    this.firstName = '';
    this.titleId = 0;
    this.email = '';
    this.phone = '';
    this.languageId = 0;
  }

  toRequest(companyId: number){
    const request = new RegisterSupplierRequest();
    request.companyId = companyId;
    request.insurerId = this.company.insurerId;
    request.lastName = this.lastName;
    request.firstName = this.firstName;
    request.titleId = this.titleId;
    request.email = this.email;
    request.phone = this.phone;
    request.languageId = this.languageId;
    return request;
  }
}