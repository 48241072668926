/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import EditOutlined from '@material-ui/icons/EditOutlined';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Tooltip from '@material-ui/core/Tooltip';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';

import IntlMessageComponent from 'components/formatters/intlMessage';

import CountryNameComponent from 'components/country/name';
import DateFormatterComponent from 'components/formatters/date';
import StyledTableCellTextComponent from 'components/table/cellText';
import StyledTableCellNumbersComponent from 'components/table/cellNumbers';
import StyledTableHeadComponent from 'components/table/head';
import StyledTableRowComponent from 'components/table/row';
import SecurityComponent from 'components/security/index';

import SupplierCompany from 'models/confirming/supplierCompany';

import { useSelector } from 'react-redux';

const columnData = [
  { id: 'supplierName', translationKey: 'supplier.name' },
  { id: 'countryId', translationKey: 'supplier.country' },
  { id: 'created', translationKey: 'supplier.created' },
  { id: 'numberOfSubmittedInvoices', translationKey: 'supplier.submittedinvoices' },
  { id: 'numberOfClosedInvoices', translationKey: 'supplier.closedinvoices' }
];

type ConfirmingSuppliersTableComponentProps = {
  editMode: boolean;
  isProcessing: boolean;
  supplierCompanies: SupplierCompany[];
  editSupplier: (id: number) => void;
  order: string;
  orderBy: string;
  page: number;
  pageSize: number;
  handlePageChange: (page: number) => void;
  handlePageSizeChange: (pageSize: number) => void;
  handleSortOrderChange: (property: string) => void;
}


export default function ConfirmingSuppliersTableComponent(props: ConfirmingSuppliersTableComponentProps) {

  const { activeCompany, securityMenus } = useSelector(state => ({
    activeCompany: state.auth.user.activeCompany,
    securityMenus: state.locale.securityMenus
  }));

  const handlePageChange = (_, page) => props.handlePageChange(page);
  const handlePageSizeChange = event => props.handlePageSizeChange(event.target.value);

  return (
    <React.Fragment>
      <Box component="div" overflow="auto">
        <Table>
          <StyledTableHeadComponent>
            <StyledTableRowComponent>
              {columnData.filter(column => props.editMode || !column.editMode).map(column => (
                <StyledTableCellTextComponent key={column.id} data-walkme={column.walkme || ''}>
                  <TableSortLabel
                    active={props.orderBy === column.id}
                    direction={props.order}
                    onClick={() => props.handleSortOrderChange(column.id)}
                  ><IntlMessageComponent id={column.translationKey} /></TableSortLabel>
                </StyledTableCellTextComponent>
              ))}

              <StyledTableCellTextComponent />
            </StyledTableRowComponent>
          </StyledTableHeadComponent>

          <TableBody bgcolor="white">
            {props.supplierCompanies.length === 0 &&
              <StyledTableRowComponent>
                <StyledTableCellTextComponent colSpan={columnData.length + (props.editMode ? 1 : 0)}>
                  <Box textAlign="center">
                    <IntlMessageComponent id="general.noData" />
                  </Box>
                </StyledTableCellTextComponent>
              </StyledTableRowComponent>
            }

            {props.supplierCompanies.slice(props.page * props.pageSize, (props.page * props.pageSize) + props.pageSize).map((supplierCompany, index) => (
              <StyledTableRowComponent key={`suppliersList-${index}`}>
                <StyledTableCellTextComponent>
                  <Box component="span" color="primary.main" fontWeight="fontWeightBold">{supplierCompany.supplierName}</Box>
                </StyledTableCellTextComponent>

                <StyledTableCellTextComponent>
                  <CountryNameComponent countryId={supplierCompany.countryId} />
                </StyledTableCellTextComponent>

                <StyledTableCellTextComponent>
                  <DateFormatterComponent date={supplierCompany.created} />
                </StyledTableCellTextComponent>

                <StyledTableCellNumbersComponent>
                  {supplierCompany.numberOfSubmittedInvoices}
                </StyledTableCellNumbersComponent>

                <StyledTableCellNumbersComponent>
                  {supplierCompany.numberOfClosedInvoices}
                </StyledTableCellNumbersComponent>

                <SecurityComponent pageName="POS.Confirming.Suppliers" elementName="btnEditMode" checkExecute>
                  <StyledTableCellTextComponent>
                    <Tooltip title={<IntlMessageComponent id="confirming.suppliers.tooltip.edit" />}>
                      <IconButton disabled={props.isProcessing} onClick={() => props.editSupplier(supplierCompany.supplierId)}>
                        <EditOutlined />
                      </IconButton>
                    </Tooltip>
                  </StyledTableCellTextComponent>
                </SecurityComponent>
              </StyledTableRowComponent>
            ))}
          </TableBody>
        </Table>
      </Box>
      <Box display="flex" height={60}>
        <Box flexGrow={1}></Box>
        <Box justifyContent="flex-end">
          <TablePagination
            component="div"
            count={props.supplierCompanies.length}
            rowsPerPage={props.pageSize}
            page={props.page}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePageSizeChange}
            labelRowsPerPage={<IntlMessageComponent id="general.suppliersperPage" />}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
          />
        </Box>
      </Box>
    </React.Fragment>
  );
}
