/* @flow */

export default class NotificationSetting {
  id: number;
  isChecked: bool;

  constructor(id: number, isChecked: boolean) {
    this.id = id;
    this.isChecked = isChecked;
  }
}
