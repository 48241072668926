/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import { makeStyles } from '@material-ui/core/styles';

import IconCancelOutlined from 'components/icons/outlined/cancel';
import IconCheckOutlined from 'components/icons/outlined/check';
import IconExclamationMarkOutlined from 'components/icons/outlined/exclamationMark';
import IconHourglassOutlined from 'components/icons/outlined/hourglass';
import IconInvoiceNotDue from 'components/icons/invoiceNotDue';
import IconInvoiceDue from 'components/icons/invoiceDue';
import IconInvoiceDue5Days from 'components/icons/invoiceDue5Days';
import IconInvoiceDue15Days from 'components/icons/invoiceDue15Days';
import IconInvoiceDue25Days from 'components/icons/invoiceDue25Days';
import IconThumbsUpOutlined from 'components/icons/outlined/thumbsUp';
import IconHourglass from 'components/icons/hourglass';
import IntlMessageComponent from 'components/formatters/intlMessage';

import {
  INVOICE_DEBTOR_TYPE_UNDERWRITING,
  INVOICE_DEBTOR_TYPE_TOAPPROVE,
  INVOICE_DEBTOR_TYPE_APPROVED,
  INVOICE_DEBTOR_TYPE_DUE,
  INVOICE_DEBTOR_TYPE_DUE5,
  INVOICE_DEBTOR_TYPE_DUE15,
  INVOICE_DEBTOR_TYPE_DUE25,
  INVOICE_DEBTOR_TYPE_CLAIM,
  INVOICE_DEBTOR_TYPE_CLOSED,
  INVOICE_DEBTOR_TYPE_REFUSED,
  INVOICE_DEBTOR_TYPE_NOTDUE,
  INVOICE_DEBTOR_TO_PAY_TYPE_DUE,
  INVOICE_DEBTOR_TO_PAY_TYPE_NOTDUELESSTHAN7,
  INVOICE_DEBTOR_TO_PAY_TYPE_NOTDUEMORETHAN7,
  INVOICE_DEBTOR_TO_PAY_TYPE_OVERDUE
} from 'constants/invoiceTypes';

const useStyles = makeStyles(_ => ({
  label: {
    textTransform: 'uppercase'
  }
}));

type DebtorInvoiceStatusViewComponentProps = {
  statusKey: string;
}

export default function DebtorInvoiceStatusViewComponent(props: DebtorInvoiceStatusViewComponentProps) {
  const classes = useStyles();

  const customize = (statusKey: string) => {
    switch (statusKey) {
      case INVOICE_DEBTOR_TYPE_UNDERWRITING:
        return { color: 'grey.main', icon: <IconHourglassOutlined fontSize="small" />, translationId: 'invoice.status.debtorA' };
      case INVOICE_DEBTOR_TYPE_TOAPPROVE:
        return { color: 'blue.main', icon: <IconThumbsUpOutlined fontSize="small" />, translationId: 'invoice.status.debtorB' };
      case INVOICE_DEBTOR_TYPE_APPROVED:
        return { color: 'green.main', icon: <IconThumbsUpOutlined fontSize="small" />, translationId: 'invoice.status.debtorC' };
      case INVOICE_DEBTOR_TYPE_NOTDUE:
        return { color: 'blue.main', icon: <IconInvoiceNotDue fontSize="small" />, translationId: 'invoice.status.debtorD' };
      case INVOICE_DEBTOR_TYPE_DUE:
        return { color: 'orange.main', icon: <IconInvoiceDue fontSize="small" />, translationId: 'invoice.status.debtorE' };
      case INVOICE_DEBTOR_TYPE_DUE5:
        return { color: 'orange.main', icon: <IconInvoiceDue5Days fontSize="small" />, translationId: 'invoice.status.debtorF' };
      case INVOICE_DEBTOR_TYPE_DUE15:
        return { color: 'orange.main', icon: <IconInvoiceDue15Days fontSize="small" />, translationId: 'invoice.status.debtorG' };
      case INVOICE_DEBTOR_TYPE_DUE25:
        return { color: 'orange.main', icon: <IconInvoiceDue25Days fontSize="small" />, translationId: 'invoice.status.debtorH' };
      case INVOICE_DEBTOR_TYPE_CLAIM:
        return { color: 'red.main', icon: <IconExclamationMarkOutlined fontSize="small" />, translationId: 'invoice.status.debtorI' };
      case INVOICE_DEBTOR_TYPE_CLOSED:
        return { color: 'green.main', icon: <IconCheckOutlined fontSize="small" />, translationId: 'invoice.status.debtorJ' };
      case INVOICE_DEBTOR_TYPE_REFUSED:
        return { color: 'red.main', icon: <IconCancelOutlined fontSize="small" />, translationId: 'invoice.status.debtorL' };
      case INVOICE_DEBTOR_TO_PAY_TYPE_NOTDUEMORETHAN7:
        return { color: 'gray.main', icon: <IconHourglass fontSize="small" />, translationId: 'invoice.status.debtornotduemorethan7' };
      case INVOICE_DEBTOR_TO_PAY_TYPE_NOTDUELESSTHAN7:
        return { color: 'gray.main', icon: <IconHourglassOutlined fontSize="small" />, translationId: 'invoice.status.debtornotduelessthan7' };
      case INVOICE_DEBTOR_TO_PAY_TYPE_DUE:
        return { color: 'orange.main', icon: <IconInvoiceNotDue fontSize="small" />, translationId: 'invoice.status.debtordue' };
      case INVOICE_DEBTOR_TO_PAY_TYPE_OVERDUE:
        return { color: 'red.main', icon: <IconInvoiceDue fontSize="small" />, translationId: 'invoice.status.debtoroverdue' };
      default:
        return { color: 'grey.main', icon: <HelpOutlineOutlinedIcon fontSize="small" />, translationId: 'general.unknown' };
    }
  }

  const data = customize(props.statusKey);

  return (
    <Box
      alignItems="center"
      color={data.color}
      display="flex"
      fontSize="0.8em"
      fontWeight="fontWeightBold"
    >
      {data.icon}
      <Box component="span" className={classes.label} ml={1}>
        <IntlMessageComponent id={data.translationId} />
      </Box>
    </Box>
  );
}