/* @flow */

import React from 'react';
import {useSelector} from 'react-redux';

import BestPricingComponent from 'app/sellers/bestPricing';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function SellerBestPricingPage() {
  const activeCompanyId = useSelector(state => state.auth.user.activeCompany.id);

  return (
    <WebsitePageComponent noPaper id="page.bestPricing.h1" data-walkme="seller-bestPricing-title">
      <BestPricingComponent companyId={activeCompanyId} debtorCompany={null} isLead={false} />
    </WebsitePageComponent>
  );
}
