/* @flow */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function IconListFilled(props) {
  return (
    <SvgIcon viewBox="0 0 71.42 63.91" {...props}>
      <g>
        <path d="M443.18,287.23h0q-4.81-3.72-9.65-7.4t-9.92-7.6a3.37,3.37,0,0,0-2.06-.81,3.47,3.47,0,0,0-2.11.84c-6.52,5-13,10-19.22,14.65a4.39,4.39,0,0,0-1.92,3.84q0,11.17,0,22.34v8.41c0,.37,0,.74,0,1.15l.11,2h14.84V310.76c0-2.36,0-4.73,0-7.09,0-1.36.2-1.37.92-1.37,4.75,0,9.5,0,14.24,0h.4c.53,0,.75,0,.78.84q0,.86,0,1.71v17.08a2.74,2.74,0,0,0,.65,2.12,2.54,2.54,0,0,0,1.91.7c1.57,0,3.15,0,4.73,0h7.23l.39-1.33a2,2,0,0,0,.13-.7v-4.18c0-9.34,0-19,0-28.49A3.88,3.88,0,0,0,443.18,287.23Z" transform="translate(-385.84 -265.67)"/>
        <path d="M425.42,305h-.09l-3.84,0c-1.27,0-2.53,0-3.8,0h-.08c-1,0-1.42.38-1.4,1.48.06,3.07,0,6.15,0,9.23v7.47h4.32c1.85,0,3.68,0,5.5-.07.31,0,.84-.84.84-1.3.06-5.09,0-10.18.06-15.28C427,305.39,426.48,305,425.42,305Z" transform="translate(-385.84 -265.67)"/>
        <path d="M456.78,292.23c-.17-.18-.33-.39-.51-.61a9.68,9.68,0,0,0-1.61-1.68c-10.07-7.76-20.28-15.59-29.89-22.93a4.56,4.56,0,0,0-6.43,0c-10.81,8.27-20.55,15.72-30.4,23.3a6.27,6.27,0,0,0-1.37,1.58,4.78,4.78,0,0,1-.33.47l-.4.52v2.25l.39.51a4.8,4.8,0,0,0,3.75,2.31,5.65,5.65,0,0,0,3.29-1.34l.21-.16v6.28q0,11,0,22.06a4.94,4.94,0,0,0,1.12,3.68,5,5,0,0,0,3.68,1.14h46.39c3.43,0,5-1.56,5-5.07q0-10.86,0-21.74v-6.32l.25.19a5.38,5.38,0,0,0,3.24,1.29c1.62,0,2.93-1,3.91-2.93l.21-.41v-1.81Zm-1.43,1.91c-.64,1.26-1.35,1.88-2.21,1.88a3.67,3.67,0,0,1-2.08-.9c-1-.76-2-1.51-3.33-2.52v31.91c0,2.46-.67,3.15-3.05,3.16H398.29c-2.33,0-2.89-.57-2.89-2.91V292.63c-1.33,1-2.3,1.69-3.27,2.42a3.85,3.85,0,0,1-2.15,1c-.76,0-1.43-.51-2.22-1.55v-1a8.71,8.71,0,0,1,1.35-1.7q15.18-11.69,30.4-23.3a3.47,3.47,0,0,1,2-.92,3.53,3.53,0,0,1,2.07.94q15,11.44,29.88,22.93a15.85,15.85,0,0,1,1.86,2.05Z" transform="translate(-385.84 -265.67)"/>
      </g>
    </SvgIcon>
  );
}
