/* @flow */

import React from 'react';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';

import SetCompanyBankAccountRequest from 'models/requests/register/setCompanyBankAccountRequest';
import SupplierCompany from 'models/confirming/supplierCompany';
import Document from 'models/documents/v2/document';
import DocumentType from 'models/documents/documentType';

import ConfirmingService from 'services/ConfirmingService';

import LoadingComponent from 'components/loading';

import { isCountryFranceOrDomTom } from 'lib/countryHelpers';
import NotificationHelper from 'lib/notifications';
import { handleApiFormResponse, handleFormChange, handleFormValidation } from 'lib/forms';
import loadConstraints from 'lib/validation/loadConstraints';
import ConfirmingSuppliersDetailsViewComponent from './view';
import DocumentService from 'services/DocumentService';
import { DOC_CODE_RIB, DOC_TYPE_RIB, DOC_CODE_BKA, DOC_TYPE_BKA } from 'constants/constants';

type ConfirmingSuppliersDetailsComponentProps = {
  activeCompanyId: number;
  supplierId: number;
  onCancel: () => void;
  onSuccess: () => void;
}

type ConfirmingSuppliersDetailsComponentState = {
  isLoading: boolean;
  isProcessing: boolean;
  supplierCompanyDetails: SupplierCompany;
  form: SetCompanyBankAccountRequest;
  constraints: any;
  errors: Map<string,?string>;
  formErrors: string[];
  document: Document;
  hasBankAccountDocument: boolean;
}

class ConfirmingSuppliersDetailsComponent extends React.Component<ConfirmingSuppliersDetailsComponentProps, ConfirmingSuppliersDetailsComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };
  }

  componentDidMount = async () => {
    this.refreshData();
  }

  refreshData = async () => {
    try {
      let supplierCompanyDetails = await ConfirmingService.getConfirmingSupplierDetails(this.props.activeCompanyId, this.props.supplierId);

      const constraintFields = [];
      constraintFields.push('bankCountryId', 'bankIdentifier', 'bankAccount');
      const constraints = await loadConstraints('companyBankAccount', constraintFields);

      let documentType = new DocumentType();
      if (isCountryFranceOrDomTom(supplierCompanyDetails.bankAccountCountryId)) {
        documentType = new DocumentType(DOC_TYPE_RIB, DOC_CODE_RIB);
      } else {
        documentType = new DocumentType(DOC_TYPE_BKA, DOC_CODE_BKA);
      }
      const document = new Document();
      document.typeId = documentType.typeId;
      document.code = documentType.code;
      document.isRequired = true;

      this.setState({
        supplierCompanyDetails,
        isLoading: false,
        isProcessing: false,
        errors: new Map(Object.keys(constraints).map(e => [e, undefined])),
        form: new SetCompanyBankAccountRequest(this.props.activeCompanyId, this.props.supplierId, supplierCompanyDetails.bankAccountCountryId, supplierCompanyDetails.bankIdentifier, supplierCompanyDetails.bankAccount),
        newNote: "",
        constraints,
        document,
        hasBankAccountDocument: supplierCompanyDetails.hasBankAccountDocument
      });
    } catch (e) {
      console.error(e);
    }
  }


  updateDocument = async () => {
    let documentType = new DocumentType();
    if (isCountryFranceOrDomTom(this.state.form.bankCountryId)) {
      documentType = new DocumentType(DOC_TYPE_RIB, DOC_CODE_RIB);
    } else {
      documentType = new DocumentType(DOC_TYPE_BKA, DOC_CODE_BKA);
    }
    const document = new Document();
    document.typeId = documentType.typeId;
    document.code = documentType.code;
    document.isRequired = true;

    this.setState({document});
  }

  handleChange = (fieldName: string) => (event) => handleFormChange.call(this, fieldName, event.target.value);
  handleTypeChange = (fieldName: string) => (id: number) => { handleFormChange.call(this, fieldName, id); this.updateDocument(); }
  handleDocumentSelect = (file: File) => {
    const document = this.state.document;
    document.file = file;
    document.filename = file.name;
    document.isInvalid = false;
    document.isMissing = false;
    this.setState({ document });
  };
  handleDocumentSelectFailure = (file: File) => {
    const document = this.state.document;
    document.file = null;
    document.filename = file.name;
    document.isInvalid = true;
    document.isMissing = false;
    this.setState({ document });
  }
  handleDocumentRemove = () => {
    const document = this.state.document;
    document.file = null;
    document.filename = '';
    document.isInvalid = false;
    document.isMissing = false;
    this.setState({ document });
  };


  updateBankAccount = async () => {
    try {
      if (!this.validateForm()) return;
      this.setState({ formErrors: [], isProcessing: true });
      const request = new SetCompanyBankAccountRequest(this.props.activeCompanyId, this.props.supplierId, this.state.form.bankCountryId, this.state.form.bankIdentifier, this.state.form.bankAccount);
      if (!this.state.hasBankAccountDocument) {
        const document = this.state.document;
        if (document.file === null || document.isMissing || document.isInvalid) {
          document.isMissing = true;
          this.setState({ document, isProcessing: false });
          return;
        } else {
          this.submitDocument(document);
        }
      }
      
      await ConfirmingService.upsertSupplierBankAccount(request);
      NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS);
      this.props.onSuccess();
    } catch (e) {
      console.error(e);
      handleApiFormResponse.call(this, e);
      this.setState({ isProcessing: false });
    }
  }

  submitDocument = async (document: Document) => {
    await DocumentService.sendSupplierDocument(this.props.activeCompanyId, this.props.supplierId, document.typeId, document.file);
  }

  validateForm = () => handleFormValidation.call(this);

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <React.Fragment>
        <Box mt={4}>
          <ConfirmingSuppliersDetailsViewComponent
            supplierCompanyDetails={this.state.supplierCompanyDetails}
            handleChange={this.handleChange}
            handleTypeChange={this.handleTypeChange}
            updateBankAccount={this.updateBankAccount}
            onCancel={this.props.onCancel}
            errors={this.state.errors}
            form={this.state.form}
            isProcessing={this.state.isProcessing}
            handleDocumentSelect={this.handleDocumentSelect}
            handleDocumentSelectFailure={this.handleDocumentSelectFailure}
            handleDocumentRemove={this.handleDocumentRemove}
            document={this.state.document}
            formErrors={this.state.formErrors}
          />
        </Box>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps)(ConfirmingSuppliersDetailsComponent);
