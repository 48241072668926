/* @flow */

import React from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import Country from 'models/generics/country';

type CountryFieldComponentProps = {
  countries: Country[];
  error: boolean;
  helperText: IntlMessage | string;
  label: IntlMessage;
  required: boolean;
  readOnly: boolean;
  value: number;
  changeHandler: (countryId: number) => void;
}

export default function CountryFieldComponent(props: CountryFieldComponentProps) {

  const handleSelect = (country: Country) => {
    if (country == null) return props.changeHandler(0);
    return props.changeHandler(country.id);
  };

  const value = props.countries.find(c => c.id === props.value) || null;

  return (
    <React.Fragment>
      {!props.readOnly &&
        <Autocomplete
          getOptionLabel={(value) => value.name}
          onChange={(_, newValue) => handleSelect(newValue)}
          openOnFocus
          options={props.countries}
          renderInput={(params) =>
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                readOnly: props.readOnly
              }}
              label={props.label}
              error={props.error}
              helperText={props.error ? props.helperText : ''}
              fullWidth
              margin="normal"
              required={props.required}
            />
          }
          value={value}
        />
      }
      {props.readOnly &&
        <TextField
          label={props.label}
          error={props.error}
          helperText={props.error ? props.helperText : ''}
          fullWidth={props.fullWidth}
          margin={props.margin}
          required={props.required}
          value={value.name}
          InputProps={{
            readOnly: true
          }}
        />
      }
    </React.Fragment>
  );
}
