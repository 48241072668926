/* This component is the base of the application, where we setup and initialize most of the
 * low-level features used throught the code
 */

import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import { store, history } from 'store/index';

import { CookiesProvider } from 'react-cookie';
import { Helmet } from 'react-helmet'

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import edebexTheme from 'styles/themes/edebexTheme';

import EdebexApp from './app';

import AuthenticationReturnComponent from 'components/websiteStructure/authentication';
import LoadingOverlayComponent from 'components/websiteStructure/loadingOverlay';
import ScrollToTop from 'components/websiteStructure/scrollToTop';
import WebsiteInitializer from 'components/websiteStructure/initializer';

import 'moment/locale/fr';
import 'moment/locale/nl';
import 'moment/locale/pt';
import 'moment/locale/es';

export default function EdebexAppBase() {
  const theme = createMuiTheme(edebexTheme);

  return (
    <Provider store={store}>
      <Helmet>
        <html translate="no" />
        {/* PX-6606 - Bing Ads */}
        <script type="application/ld+json">{`
          (function(w,d,t,r,u)
          {
              var f,n,i;
              w[u]=w[u]||[],f=function()
              {
                  var o={ti:"343009970"};
                  o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")
              },
              n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function()
              {
                  var s=this.readyState;
                  s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)
              },
              i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)
          })
          (window,document,"script","//bat.bing.com/bat.js","uetq");
          `}
        </script>
        {/* PX-7266/PX-7338 - Hotjar */}
        <script type="application/ld+json">{`
            (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:3532037,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
          `}
        </script>
      </Helmet>
      <CookiesProvider>
        <MuiThemeProvider theme={theme}>
          <Router history={history}>
            <ScrollToTop />
            <WebsiteInitializer>
              <LoadingOverlayComponent>
                <AuthenticationReturnComponent>
                  <EdebexApp />
                </AuthenticationReturnComponent>
              </LoadingOverlayComponent>
            </WebsiteInitializer>
          </Router>
        </MuiThemeProvider>
      </CookiesProvider>
    </Provider>
  );
}