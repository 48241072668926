/* @flow */

import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import EditOutlined from '@material-ui/icons/EditOutlined';
import IconButton from '@material-ui/core/IconButton';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Switch from '@material-ui/core/Switch';

import BooleanFormatterComponent from 'components/formatters/boolean';
import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';
import SecurityComponent from 'components/security';
import StyledTableCellTextComponent from 'components/table/cellText';
import StyledTableHeadComponent from 'components/table/head';
import StyledTableRowComponent from 'components/table/row';

import CompanyUser from 'models/companies/companyUser';

import { hasSecurityMenuAccess } from 'lib/securityHelper';

import {
  PROTECTED_COMPANY_USERS_NEW,
  PROTECTED_COMPANY_USERS_EDIT,
  PROTECTED_COMPANY_USERS_PROCURATION
} from 'constants/pageRoutes';
import { ROLE_BUYER, ROLE_DEBTOR, ROLE_SELLER, ROLE_ISO, ROLE_CONFIRMING } from 'constants/companyRoles';

const columnData = [
  { id: 'lastName', translationKey: 'component.users.list.header.lastName' },
  { id: 'firstName', translationKey: 'component.users.list.header.firstName' },
  { id: 'functionName', translationKey: 'component.users.list.header.functionName' },
  { id: 'isActive', translationKey: 'component.users.list.header.isActive' },
  { id: 'role', translationKey: 'component.users.list.header.role' }
];

type CompanyUsersTableViewComponentProps = {
  companyUsers: CompanyUser[];
  order: string;
  orderBy: string;
  page: number;
  pageSize: number;
  canExecute: boolean;
  isActive: boolean;
  isISO: boolean;
  activeUserId: number;
  hasActiveMembership: boolean;
  handlePageChange: (page: number) => void;
  handlePageSizeChange: (pageSize: number) => void;
  handleSortOrderChange: (property: string) => void;
  disableActiveUser: (id: number) => void;
};

export default function CompanyUsersTableViewComponent(props: CompanyUsersTableViewComponentProps) {
  const history = useHistory();
  const { activeCompany, securityMenus, activeCompanyRole, buyerRoles, sellerRoles, isoRoles, debtorRoles, confirmingRoles } = useSelector(state => ({
    activeCompany: state.auth.user.activeCompany,
    securityMenus: state.locale.securityMenus,
    activeCompanyRole: state.auth.user.activeCompany.role,
    buyerRoles: state.locale.rolesForBuyers,
    sellerRoles: state.locale.rolesForSellers,
    isoRoles: state.locale.rolesForISOs,
    debtorRoles: state.locale.rolesForDebtors,
    confirmingRoles: state.locale.rolesForConfirming
  }));

  const handlePageChange = (_, page) => props.handlePageChange(page);
  const handlePageSizeChange = event => props.handlePageSizeChange(event.target.value);

  const newUser = () => history.push(PROTECTED_COMPANY_USERS_NEW);
  const editUser = (id: number) => history.push(PROTECTED_COMPANY_USERS_EDIT.replace("{id}", id));
  const editUserProcuration = (id: number) => history.push(PROTECTED_COMPANY_USERS_PROCURATION.replace("{id}", id));


  return (
    <React.Fragment>
      <Box py={4} component="div" overflow="auto">
        <Table>
          <StyledTableHeadComponent>
            <StyledTableRowComponent>
              {columnData.map(column => (
                <StyledTableCellTextComponent key={column.id} padding="none">
                  <TableSortLabel
                    active={props.orderBy === column.id}
                    direction={props.order}
                    onClick={() => props.handleSortOrderChange(column.id)}
                  ><IntlMessageComponent id={column.translationKey} /></TableSortLabel>
                </StyledTableCellTextComponent>
              ))}
              <StyledTableCellTextComponent padding="checkbox" />
            </StyledTableRowComponent>
          </StyledTableHeadComponent>

          <TableBody bgcolor="white">
            {props.companyUsers.length === 0 &&
              <StyledTableRowComponent>
                <StyledTableCellTextComponent colSpan={columnData.length + 1}>
                  <Box textAlign="center">
                    <IntlMessageComponent id="general.noData" />
                  </Box>
                </StyledTableCellTextComponent>
              </StyledTableRowComponent>
            }

            {props.companyUsers.slice(props.page * props.pageSize, (props.page * props.pageSize) + props.pageSize).map(user => (
              <StyledTableRowComponent key={`companyUser-${user.companyUserId}`}>
                <StyledTableCellTextComponent padding="none">
                  <b>{user.lastName}</b>
                </StyledTableCellTextComponent>

                <StyledTableCellTextComponent padding="none">
                  <b>{user.firstName}</b>
                </StyledTableCellTextComponent>

                <StyledTableCellTextComponent padding="none">
                  {user.functionName}
                </StyledTableCellTextComponent>

                {hasSecurityMenuAccess("POS.Users", "btnEdit", activeCompany, securityMenus) && props.canExecute && props.canManageUsers && props.isActive && props.hasActiveMembership ?
                  <StyledTableCellTextComponent padding="none">
                    <Tooltip title={<IntlMessageComponent id={user.companyUserId === props.activeUserId ? "company.users.tooltip.disable.ownuser" : !user.isActive ? "company.users.tooltip.enable.contact" : "company.users.tooltip.disable.possible"} />}>
                      <span>
                        <Switch checked={user.isActive} disabled={props.isProcessing || !user.isActive || user.companyUserId === props.activeUserId} onChange={() => props.disableActiveUser(user.companyUserId)} />
                      </span>
                    </Tooltip>
                  </StyledTableCellTextComponent> :
                  <StyledTableCellTextComponent padding="none">
                    <BooleanFormatterComponent value={user.isActive} />
                  </StyledTableCellTextComponent>
                }

                {activeCompanyRole == ROLE_BUYER &&
                  <StyledTableCellTextComponent padding="none">
                    {buyerRoles.find(r => r.id == user.buyerRole)?.name}
                  </StyledTableCellTextComponent>}

                {activeCompanyRole == ROLE_SELLER &&
                  <StyledTableCellTextComponent padding="none">
                    {sellerRoles.find(r => r.id == user.sellerRole)?.name}
                  </StyledTableCellTextComponent>}

                {activeCompanyRole == ROLE_DEBTOR &&
                  <StyledTableCellTextComponent padding="none">
                    {debtorRoles.find(r => r.id == user.debtorRole)?.name}
                  </StyledTableCellTextComponent>}

                {activeCompanyRole == ROLE_ISO &&
                  <StyledTableCellTextComponent padding="none">
                    {isoRoles.find(r => r.id == user.isoRole)?.name}
                  </StyledTableCellTextComponent>}

                {activeCompanyRole == ROLE_CONFIRMING &&
                  <StyledTableCellTextComponent padding="none">
                    {confirmingRoles.find(r => r.id == user.confirmingRole)?.name}
                  </StyledTableCellTextComponent>}

                {props.canExecute && props.isActive && props.hasActiveMembership &&
                  <StyledTableCellTextComponent padding="checkbox">
                    <SecurityComponent pageName="POS.Users" elementName="btnEdit" checkCanManageUsers>
                      <Tooltip title={<IntlMessageComponent id="component.users.list.edit.tooltip" />}>
                        <IconButton onClick={() => editUser(user.companyUserId)}>
                          <EditOutlined />
                        </IconButton>
                      </Tooltip>
                    </SecurityComponent>

                    {((!user.isAdministrator && user.hasProcuration) ||
                      (!user.isAdministrator && !user.hasProcurationUsers && !user.hasProcurationGeneralConditions)) &&
                      <SecurityComponent pageName="POS.Users" elementName="btnProcuration" checkCanManageUsers={activeCompany.companyUserId !== user.companyUserId}>
                        <Tooltip title={<IntlMessageComponent id="component.users.list.procuration.tooltip" />}>
                          <IconButton onClick={() => editUserProcuration(user.companyUserId)}>
                            <SettingsOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </SecurityComponent>
                    }
                  </StyledTableCellTextComponent>
                }
              </StyledTableRowComponent>
            ))}
          </TableBody>
        </Table>
      </Box>

      <Box display="flex" height={100}>
        {props.canExecute && props.isActive && props.hasActiveMembership &&
          <Box flexGrow={1} mt={2}>
            <SecurityComponent pageName="POS.Users" elementName="btnNew" checkCanManageUsers>
              <ButtonComponent
                color="primary"
                variant="contained"
                onClick={newUser}
              ><IntlMessageComponent id="component.ubo.list.actions.add" /></ButtonComponent>
            </SecurityComponent>
          </Box>
        }
        <Box justifyContent="flex-end">
          <TablePagination
            component="div"
            count={props.companyUsers.length}
            rowsPerPage={props.pageSize}
            page={props.page}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePageSizeChange}
            labelRowsPerPage={<IntlMessageComponent id="general.usersPerPage" />}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
          />
        </Box>
      </Box>
    </React.Fragment>
  );
}