/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

type InvoiceApprovalConfirmationViewComponentProps = {
  restart: () => void;
}

export default function InvoiceApprovalConfirmationViewComponent(props: InvoiceApprovalConfirmationViewComponentProps) {
  return (
    <React.Fragment>
      <PageSubTitleViewComponent id="component.debtor.invoices.approved.title" />

      <Typography variant="body1">
        <IntlMessageComponent id="component.debtor.invoices.approved.description" />
      </Typography>
      
      <Box mt={5}>
        <ButtonComponent
          color="primary"
          variant="contained"
          onClick={props.restart}
        ><IntlMessageComponent id="general.back" /></ButtonComponent>
      </Box>
    </React.Fragment>
  );
}
