/* @flow */

import {Cookies} from 'react-cookie';
import ISOAndCampaignRequest from 'models/requests/marketing/isoAndCampaignRequest';

export function getCampaignId() {
  return sessionStorage.getItem('campaign') || 0;
}

export function getISOAndCampaignRequest(cookies: Cookies) {
  const isoAndCampaign = new ISOAndCampaignRequest();
  
  isoAndCampaign.campaignId = getCampaignId();

  const isoCookie = cookies.get('edebex-iso');
  if (isoCookie) {
    const split = isoCookie.split('-');
    isoAndCampaign.isoCompanyId = split[0];
    isoAndCampaign.isoCompanyOfficeId = split[1];
    isoAndCampaign.isoCompanyPersonId = split[2];
  }
  
  return isoAndCampaign;
};