/* @flow */

import DebtorInfo from 'models/debtors/debtorInfo';

export default class DebtorInfoForm {
  lastName: string;
  firstName: string;
  email: string;
  functionName: string;

  constructor() {
    this.lastName = '';
    this.firstName = '';
    this.email = '';
    this.functionName = '';
  }

  toModel(){
    const model = new DebtorInfo();
    model.lastName = this.lastName;
    model.firstName = this.firstName;
    model.email = this.email;
    model.functionName = this.functionName;
    return model;
  }
}
