/* @flow */

import { plainToClass } from 'class-transformer';

import BaseService from './api/baseService';

import {
  DASHBOARD_BLOGS_GET,
  DASHBOARD_NOTIFICATIONS_GET,
  DASHBOARD_INFORM_ADMIN_POST
} from 'constants/apiRoutes';

import Alert from 'models/dashboards/alert';
import BlogEntry from 'models/dashboards/blogEntry';
import Notification from 'models/dashboards/notification';
import InformAdminRequest from 'models/requests/dashboard/informAdminRequest';

export default class DashboardService {

  static getBlogEntries = (companyId: number, companyRole: number) =>
    BaseService.simpleGET(`${DASHBOARD_BLOGS_GET}/${companyId}/${companyRole}`,
      data => data.map(d => plainToClass(BlogEntry, d)));

  static getNotifications = (companyId: number, companyRole: number) =>
    BaseService.simpleGET(`${DASHBOARD_NOTIFICATIONS_GET}/${companyId}/${companyRole}`,
      data => {
        const alerts = data.alerts.map(a => plainToClass(Alert, a));
        const notifications = data.notifications.map(n => plainToClass(Notification, n));
        return { alerts, notifications };
      });

  static informAdmin = (request: InformAdminRequest) =>
    BaseService.simplePOST(DASHBOARD_INFORM_ADMIN_POST, request, null);
}
