/* @flow */

export default class OfficePerson {
  id: number;
  titleId: number;
  lastName: string;
  firstName: string;
  email: string;
  phone: string;
  mobile: string;
  countryId: number;
  languageId: number;
  functionName: string;
}
