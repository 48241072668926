/* @flow */

export default class DebtorCompany {
  id: number;
  insurerId: string;
  name: string;
  vatNumber: string;
  addressLine1: string;
  addressLine2: string;
  zipCode: string;
  city: string;
  countryId: number;
  insurerRating: number;
  isInsurable: boolean;
  isWaitingForGrade: boolean;
  displayWarningClaims: boolean;
  displayWarningGrade: boolean;
  phone: string;
}
