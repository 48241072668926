/* @flow */

import React from 'react';

import {withStyles} from '@material-ui/core/styles';

import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';

import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import InvoiceDetails from 'models/invoices/seller/invoiceDetails';

type SellerInvoiceDetailsStatusComponentProps = {
  invoice: InvoiceDetails;
  statusKeys: string[];
}

type SellerInvoiceDetailsStatusComponentState = {
  activeStep: number;
  arrowRef: ?any;
  isOpen: number;
  steps: {id: number, label: string}[];
}

class SellerInvoiceDetailsStatusComponent extends React.Component<SellerInvoiceDetailsStatusComponentProps, SellerInvoiceDetailsStatusComponentState> {

  constructor(props) {
    super(props);

    const steps = this.getSteps();
    const activeStep = this.getActiveStep(steps);

    this.state = {
      activeStep,
      arrowRef: null,
      isOpen: activeStep,
      steps
    };
  }

  getActiveStep = (steps) => {
    const step = steps.find(s => s.label === this.props.invoice.statusKey);
    return step ? step.id : 0;
  }

  getSteps = () => this.props.statusKeys.map((label, id) => ({id, label}));
  handleArrowRef = (node) => this.setState({arrowRef: node});
  toggle = (id) => this.setState({isOpen: (this.state.isOpen === id ? -1 : id)});
  

  render() {
    return (
      <React.Fragment>
        <PageSubTitleViewComponent id="component.seller.invoiceList.title" />

        <Stepper activeStep={this.state.activeStep} alternativeLabel>
          {this.state.steps.map(step => (
            <Step key={`step-${step.id}`}>
              <StepLabel id={`Popover-${step.id}`} onClick={() => this.toggle(step.id)}>
                <IntlMessageComponent id={`invoice.status.${step.label.toLowerCase()}`} />
              </StepLabel>
            </Step>
          ), this)}
        </Stepper>

        {this.state.steps.map(step => (
          <Popper
            key={`popper-${step.id}`}
            className={this.props.classes.popper}
            modifiers={{
              offset: {offset: '0, 7em'},
              flip: {
                enabled: true,
              },
              preventOverflow: {
                enabled: true,
                boundariesElement: 'scrollParent',
              },
              arrow: {
                enabled: true,
                element: this.state.arrowRef,
              },
            }}
            id={`Popper-${step.id}`}
            placement="right"
            open={this.state.isOpen === step.id}
            anchorEl={() => document.querySelector(`#Popover-${this.state.isOpen} svg`)}
            onClick={() => this.toggle(step.id)}
          >
            <span className={this.props.classes.arrow} ref={this.handleArrowRef} />
            <Paper elevation={5} style={{maxWidth: '500px'}}>
              <DialogTitle>
                <IntlMessageComponent id={`invoice.status.${step.label.toLowerCase()}`} />
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <IntlMessageComponent id={`invoice.status.description.${step.label.toLowerCase()}`} />
                </DialogContentText>
              </DialogContent>
            </Paper>
          </Popper>
        ))}
      </React.Fragment>
    );
  }
}

const styles = theme => ({
  popper: {
    zIndex: 1,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
      maxHeight: '20em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${theme.palette.common.white} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${theme.palette.common.white} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${theme.palette.common.white} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${theme.palette.common.white}`,
      },
    },
  },
  arrow: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  }
});

export default withStyles(styles)(SellerInvoiceDetailsStatusComponent);
