/* @flow */

import moment from 'moment';

export default class AddCompanyUserRequest {
  companyId: number;
  titleId: number;
  lastName: string;
  firstName: string;
  email: string;
  phone: string;
  mobile: string;
  addressLine1: string;
  addressLine2: string;
  zipCode: string;
  city: string;
  countryId: number;
  nationalityId: number;
  birthCountryId: number;
  birthDate: moment;
  birthPlace: string;
  registrationTypeId: number;
  registrationNumber: string;
  registrationExpireDate: moment;
  functionName: string;
  languageId: number;
  isAdministrator: boolean;
}
