/* @flow */

import React from 'react';

import IntlMessageComponent from 'components/formatters/intlMessage';
import WizardComponent from 'components/wizard';

import ReferralModalComponent from './modal/index';
import ReferralTab1Component from './tab1/index';
import ReferralTab2Component from './tab2/index';
import ReferralTab3Component from './tab3/index';

import RegisterReferralResponse from 'models/responses/sellers/registerReferralResponse';

type SellerReferralComponentProps = {
}

type SellerReferralComponentState = {
  activeStepId: number,
  response: RegisterReferralResponse,
  showModal: boolean,
  steps: {id: number, title: IntlMessageComponent}[]
}

class SellerReferralComponent extends React.Component<SellerReferralComponentProps, SellerReferralComponentState> {
  static REFERRAL_STEP_LEAD = 0;
  static REFERRAL_STEP_NOTE = 1;
  static REFERRAL_STEP_SUMMARY = 2;

  constructor(props) {
    super(props);

    this.state = {
      activeStepId: SellerReferralComponent.REFERRAL_STEP_LEAD,
      response: null,
      showModal: true,
      steps: this.getSteps()
    };
  }

  getSteps = () => {
    const tabsNumber = [
      SellerReferralComponent.REFERRAL_STEP_LEAD,
      SellerReferralComponent.REFERRAL_STEP_NOTE,
      SellerReferralComponent.REFERRAL_STEP_SUMMARY
    ];
    return tabsNumber.map(num => ({id: num, title: <IntlMessageComponent id={`seller.referral.tab${num + 1}`} />}));
  }

  getStepContent = () => {
    switch (this.state.activeStepId) {
      case SellerReferralComponent.REFERRAL_STEP_LEAD:
        return (<ReferralTab1Component
          successHandler={this.handleSuccessTab1}
        />);
      case SellerReferralComponent.REFERRAL_STEP_NOTE:
        return (<ReferralTab2Component
          refereeCompanyId={this.state.response.refereeCompanyId}
          successHandler={this.handleSuccessTab2}
        />);
      case SellerReferralComponent.REFERRAL_STEP_SUMMARY:
        return (<ReferralTab3Component
          response={this.state.response}
          restartHandler={this.handleRestart}
        />);
      default:
        return 'Unknown step';
    }
  };

  handleRestart = () => {
    this.setState({activeStepId: SellerReferralComponent.REFERRAL_STEP_LEAD, response: null});
  }

  handleSuccessTab1 = (response: RegisterReferralResponse) => {
    let activeStepId = response.referralStatus ===  RegisterReferralResponse.REFERRAL_STATUS_OK ?
      SellerReferralComponent.REFERRAL_STEP_NOTE : SellerReferralComponent.REFERRAL_STEP_SUMMARY;

    this.setState({activeStepId, response});
  }

  handleSuccessTab2 = () => {
    this.setState({activeStepId: SellerReferralComponent.REFERRAL_STEP_SUMMARY});
  }

  hideModal = () => this.setState({showModal: false});

  render() {
    return (
      <React.Fragment>
        <WizardComponent
          activeStepId={this.state.activeStepId}
          steps={this.state.steps}
        >
          {this.getStepContent()}
        </WizardComponent>
      
        {this.state.showModal &&
          <ReferralModalComponent handleClose={this.hideModal} />
        }
      </React.Fragment>
    );
  }
}

export default SellerReferralComponent;
