/* @flow */

import React from 'react';
import { connect } from 'react-redux';

import LoadingComponent from 'components/loading';

import RegisterLeadTab2ViewComponent from './view';
import BusinessOpportunityForm from './model';

import IsoService from 'services/IsoService';
import {handleApiFormResponse, handleFormChange, handleFormValidation} from 'lib/forms';
import loadConstraints from 'lib/validation/loadConstraints';
import NotificationHelper from 'lib/notifications';

type RegisterLeadTab2ComponentProps = {
  activeCompanyId: number;
  companyId: number;
  successHandler: () => void;
  skipHandler: () => void;
};

type RegisterLeadTab2ComponentState = {
  constraints: any;
  errors: Map<string,?string>;
  form: BusinessOpportunityForm;
  formErrors: string[];
  isLoading: boolean;
  isProcessing: boolean;
};

class RegisterLeadTab2Component extends React.Component<RegisterLeadTab2ComponentProps, RegisterLeadTab2ComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      constraints: null,
      errors: null,
      form: null,
      formErrors: [],
      isLoading: true,
      isProcessing: false
    };
  }

  componentDidMount = async () => {
    try {
      const constraints = await loadConstraints('businessOpportunity', ['expectedMonthlyVolume', 'invoicesAvgAmount', 'invoicesNbrMonthly',
        'nbrDebtors', 'financingNeedId', 'financingNeedFrequencyId', 'invoicesYearlyAmountId', 'revenuesYearly']);

      this.setState({
        constraints,
        errors: new Map(Object.keys(constraints).map(e => [e, undefined])),
        form: new BusinessOpportunityForm(),
        isLoading: false,
      });
    } catch (e) {
      console.error(e);
    }
  };

  handleChange = (fieldName: string) => (event) => handleFormChange.call(this, fieldName, event.target.value);
  handleTypeIdChange = (fieldName: string) => (id: number) => handleFormChange.call(this, fieldName, id);
  validateForm = () => handleFormValidation.call(this);

  submitForm = async () => {
    if (!this.validateForm()) return;
    this.setState({formErrors: [], isProcessing: true});

    try {
      const request = this.state.form.toRequest(this.props.activeCompanyId, this.props.companyId);
      await IsoService.setBusinessOpportunity(request);
      NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS);
      this.props.successHandler(this.state.form);
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }
  };

  render = () => {
    if (this.state.isLoading) return (<LoadingComponent />);

    return (
      <RegisterLeadTab2ViewComponent
        errors={this.state.errors}
        form={this.state.form}
        formErrors={this.state.formErrors}
        isProcessing={this.state.isProcessing}
        handleChange={this.handleChange}
        handleTypeIdChange={this.handleTypeIdChange}
        skip={this.props.skipHandler}
        submitForm={this.submitForm}
      />
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps)(RegisterLeadTab2Component);
