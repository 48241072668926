/* @flow */
import { withStyles } from '@material-ui/core/styles';
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";
import * as React from 'react';

type HelpHtmlToolTipComponentProps = {
  htmlText: string;
  placement: string;
}

const HtmlTooltip = withStyles((theme) => ({
  arrow: {
    "&:before": {
      border: "1px solid #E6E8ED"
    },
    color: '#ffffff',
    fontSize: "1em",
  },
  tooltip: {
    backgroundColor: '#ffffff',
    color: 'rgba(0, 0, 0, 0.54)',
    maxWidth: 300,
    border: '1px solid #dadde9',
    fontSize: "15px",
  },
}))(Tooltip);

export default function HelpHtmlToolTipComponent(props: HelpHtmlToolTipComponentProps) {
  return (
    <HtmlTooltip arrow interactive title={props.htmlText} placement={props.placement}>
      <HelpIcon color="primary" fontSize="small" />
    </HtmlTooltip>
  );
}
