/* @flow */

const pad = (input: string, filler: char, size: number) => {
  let paddedInput = input;
  while (paddedInput.length < size) {
    paddedInput = `${filler}${paddedInput}`;
  }
  return paddedInput;
};

// Validate Belgian VAT Number
const beVatValidator = (vatNumber: string) => {
  if (isNaN(vatNumber)) return false;

  let formattedVatNumber = vatNumber;
  if (formattedVatNumber.length < 10) {
    formattedVatNumber = pad(formattedVatNumber, '0', 10);
  } else if (formattedVatNumber.length > 10) return false;

  // Modulus 97 check on last nine digits
  return 97 - (parseInt(formattedVatNumber.slice(0, 8), 10) % 97) === parseInt(formattedVatNumber.slice(8, 10), 10);
};

// Validate German COC Number
const deCocValidator = () => true;

// Validate French Siren Number
const frSirenValidator = (sirenNumber: string) => {
  const size = 9;

  if (isNaN(sirenNumber) || sirenNumber.length !== size) {
    return false;
  }

  let bal = 0;
  let total = 0;
  for (let i = size - 1; i >= 0; i--) {
    const step = (sirenNumber.charCodeAt(i) - 48) * (bal + 1);
    total += (step > 9) ? step - 9 : step;
    bal = 1 - bal;
  }

  return (total % 10 === 0);
};

// Validate Lux VAT Number
const luVatValidator = (vatNumber: string) => {
  let formattedVatNumber = vatNumber;
  if (formattedVatNumber.length > 7)
    return false;
  if (formattedVatNumber.length > 0 && !isNaN(vatNumber.charAt(0)))
    return false;

  return true;
};

// Validate Italian xxx Number
const itVatValidator = () => true;

// Validate Dutch KVK Number
const nlKvkValidator = (kvkNumber: string) => (kvkNumber.length === 8 && !isNaN(kvkNumber));

// Validate Portuguese NIPC number.
const ptNipcValidator = (nipcNumber: string) => {
  if (isNaN(nipcNumber)) {
    return false;
  }

  let total = 0;
  const multipliers = [9, 8, 7, 6, 5, 4, 3, 2];

  // Extract the next digit and multiply by the counter.
  for (let i = 0; i < 8; i++) {
    total += Number(nipcNumber.charAt(i)) * multipliers[i];
  }

  // Establish check digits subtracting modulus 11 from 11.
  total = 11 - (total % 11);
  if (total > 9) {
    total = 0;
  }

  // Compare it with the last character of the VAT number. If it's the same, then it's valid.
  return total === parseInt(nipcNumber.slice(8, 9), 10);
};

// Validate UK CRN (Company Registration Number)
const ukCrnValidator = (croNumber: string) => {
  if (croNumber.length !== 8) return false;

  const nbr = croNumber.slice(2, 8);
  if (isNaN(nbr)) return false;

  const prefix = croNumber.slice(0, 2);
  if (!isNaN(prefix)) return true;

  switch (prefix) {
    case 'OC':
    case 'LP':
    case 'SC':
    case 'SO':
    case 'SL':
    case 'NI':
    case 'R0':
    case 'NC':
    case 'NL':
      return true;
    default:
      return false;
  }
}

// Validate Monaco RCI
const mcRciValidator = (rciNumber: string) => {
  if (rciNumber.length !== 8) return false;

  const nbr = rciNumber.slice(0, 2);
  if (isNaN(nbr)) return false;

  const letter = rciNumber.slice(2, 3);
  if (letter.toLowerCase() !== 'p' && letter.toLowerCase() !== 's') return false;

  const nbr2 = rciNumber.slice(3, 8);
  if (isNaN(nbr2)) return false;

  return true;
}

// Validate ES VAT
function esVatValidator (vatNumber: string) {  
  var total = 0; 
  var temp = 0;
  var multipliers = [2,1,2,1,2,1,2];
  var esexp = new Array ();
  esexp[0] = (/^[A-H|J|U|V]\d{8}$/);
  esexp[1] = (/^[A-H|N-S|W]\d{7}[A-J]$/);
  esexp[2] = (/^[0-9|Y|Z]\d{7}[A-Z]$/);
  esexp[3] = (/^[K|L|M|X]\d{7}[A-Z]$/);
  var i = 0;
  
  // National juridical entities
  if (esexp[0].test(vatNumber)) {
  
    // Extract the next digit and multiply by the counter.
    for (i = 0; i < 7; i++) {
      temp = Number(vatNumber.charAt(i+1)) * multipliers[i];
      if (temp > 9) 
        total += Math.floor(temp/10) + temp%10 
      else 
        total += temp;
    }   
    // Now calculate the check digit itself. 
    total = 10 - total % 10;
    if (total == 10) {total = 0;}
    
    // Compare it with the last character of the VAT number. If it's the same, then it's valid.
    if (total == vatNumber.slice (8,9)) 
      return true
    else 
      return false;
  }
  
  // Juridical entities other than national ones
  else if (esexp[1].test(vatNumber)) {
  
    // Extract the next digit and multiply by the counter.
    for (i = 0; i < 7; i++) {
      temp = Number(vatNumber.charAt(i+1)) * multipliers[i];
      if (temp > 9) 
        total += Math.floor(temp/10) + temp%10 
      else 
        total += temp;
    }    
    
    // Now calculate the check digit itself.
    total = 10 - total % 10;
    total = String.fromCharCode(total+64);
    
    // Compare it with the last character of the VAT number. If it's the same, then it's valid.
    if (total == vatNumber.slice (8,9)) 
      return true
    else 
      return false;
  }
	
  // Personal number (NIF) (starting with numeric of Y or Z)
  else if (esexp[2].test(vatNumber)) {
    var tempnumber = vatNumber;
    if (tempnumber.substring(0,1) == 'Y') tempnumber = tempnumber.replace (/Y/, "1");
    if (tempnumber.substring(0,1) == 'Z') tempnumber = tempnumber.replace (/Z/, "2");
  	return tempnumber.charAt(8) == 'TRWAGMYFPDXBNJZSQVHLCKE'.charAt(Number(tempnumber.substring(0,8)) % 23);
  }
	
  // Personal number (NIF) (starting with K, L, M, or X)
  else if (esexp[3].test(vatNumber)) {
  	return vatNumber.charAt(8) == 'TRWAGMYFPDXBNJZSQVHLCKE'.charAt(Number(vatNumber.substring(1,8)) % 23);
  }
	
  else return false;
}

// Using Ids from Wim's database on 28-12-2018
const mapCountriesToValidation:Map<string, (string) => boolean> = new Map([
  ['BE', beVatValidator], // Belgium
  ['NL', nlKvkValidator], // Netherlands
  ['UK', ukCrnValidator], // UK
  ['DE', deCocValidator], // Germany
  ['FR', frSirenValidator], // France
  ['IT', itVatValidator], // Italy
  ['LU', luVatValidator], // Luxembourg
  ['PT', ptNipcValidator], // Portugal
  ['RE', frSirenValidator], // Réunion
  ['MQ', frSirenValidator], // Martinique
  ['GP', frSirenValidator], // Guadeloupe
  ['YT', frSirenValidator], // Mayotte
  ['GF', frSirenValidator], // French Guiana
  ['ES', esVatValidator], // Spain
  ['MC', mcRciValidator] // Monaco
]);

function multiVatValidator(value: string, options: {message: ?string}, key: ?string, attributes: ?any) {

  // make sure message has been provided
  if (options.message === undefined || options.message === null) {
    throw new Error('Message is needed');
  }

  // We need a country in order to know which validation applies
  if (!attributes || !Object.hasOwnProperty.call(attributes, 'country')) {
    throw new Error('Country should be provided for VAT validation');
  }

  const validator = mapCountriesToValidation.get(attributes.country.code);

  if (!validator) {
    return undefined;
  }

  if (!validator.call(this, value)) {
    return options.message;
  }

  return undefined;
}

export default multiVatValidator;
