/* @flow */

import React from 'react';
import {useSelector} from 'react-redux';
import {Route, Switch, useRouteMatch} from 'react-router-dom';

import Error404Page from 'components/errors/error404';
import RestrictedRouteComponent from 'components/route/restrictedRoute';

import AddCompanyPage from './addCompany';
import CompanyDocumentsPage from './documents'
import CompanyInfoPage from './info';
import CompanyMembershipsPage from './memberships';
import CompanyOfficesPage from './offices';
import CompanyUbosPage from './ubos';
import CompanyUsersPage from './users';
import CompanyFixContractPage from './fixcontract';
import CompanyBankAccountsPage from './bankaccounts';

import {ROLE_ISO, ROLE_DEBTOR, ROLE_SELLER} from 'constants/companyRoles';
import {PROTECTED_APP} from 'constants/pageRoutes';

export default function CompanyPage() {
  const activeCompany = useSelector(state => state.auth.user.activeCompany);
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.url}/`} component={CompanyInfoPage} />
      <Route path={`${match.url}/bankaccounts`} component={CompanyBankAccountsPage} />
      <Route path={`${match.url}/add`} component={AddCompanyPage} />
      <Route path={`${match.url}/documents`} component={CompanyDocumentsPage} />
      <RestrictedRouteComponent path={`${match.url}/offices`} condition={activeCompany.role === ROLE_ISO || activeCompany.role === ROLE_DEBTOR} component={CompanyOfficesPage} redirectUrl={PROTECTED_APP} />
      <RestrictedRouteComponent path={`${match.url}/subscriptions`} condition={activeCompany.role !== ROLE_ISO && activeCompany.role !== ROLE_DEBTOR} component={CompanyMembershipsPage} redirectUrl={PROTECTED_APP} />
      <Route path={`${match.url}/ubos`} component={CompanyUbosPage} />
      <Route path={`${match.url}/users`} component={CompanyUsersPage}  />
      <RestrictedRouteComponent path={`${match.url}/fixcontract`} condition={activeCompany.role === ROLE_SELLER} component={CompanyFixContractPage} redirectUrl={PROTECTED_APP} />
      <Route component={Error404Page} />
    </Switch>
  );
}
