/* @flow */

import EligibilityAnswer from './eligibilityAnswer';

export default class EligibilityQuestion {
  id: number;
  title: string;
  text: string;
  answers: EligibilityAnswer[];
}
