/* @flow */

import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import IntlMessageComponent from 'components/formatters/intlMessage';
import LoadingComponent from 'components/loading';
import WizardComponent from 'components/wizard';

import OfferTab1Component from './tab1/index';
import OfferTab2Component from './tab2/index';
import OfferTab3Component from './tab3/index';
import OfferTab4Component from './tab4/index';

import DebtorCompanyModel from 'models/companies/debtorCompany';

import LeadService from 'services/LeadService';

import NotificationHelper from 'lib/notifications';

import {ROLE_ISO} from 'constants/companyRoles';

type OfferComponentProps = {
  activeCompanyId: number;
  activeCompanyRole: number;
  languageId: number;
};

type OfferComponentState = {
  activeStepId: number;
  debtorCompany: DebtorCompanyModel;
  ineligibleReasonIds: number[];
  isLoading: boolean;
  leadId: number;
  leadOfferId: number;
  steps: {id: number, title: IntlMessageComponent}[];
};

class OfferComponent extends React.Component<OfferComponentProps, OfferComponentState> {
  static STEP_LEAD = 0;
  static STEP_ELIGIBILITY = 1;
  static STEP_LEAD_OFFER = 2;
  static STEP_SUMMARY = 3;

  constructor(props) {
    super(props);

    // retrieve invoiceId from url or leadOfferId from history state
    let activeStepId = OfferComponent.STEP_LEAD;
    let debtorCompany = null;
    let leadId = 0;
    
    if (props.activeCompanyId > 0 && props.activeCompanyRole === ROLE_ISO && props.location.state) {
      activeStepId = OfferComponent.STEP_LEAD_OFFER;
      debtorCompany = props.location.state.debtorCompany;
      leadId = props.location.state.leadId;
    }

    this.state = {
      activeStepId,
      debtorCompany,
      ineligibleReasonIds: [],
      isLoading: props.activeCompanyId > 0 && props.activeCompanyRole !== ROLE_ISO,
      leadId,
      leadOfferId: 0,
      steps: this.getSteps(props)
    };
  }

  componentDidMount = async () => {
    if (this.props.activeCompanyId > 0 && this.props.activeCompanyRole !== ROLE_ISO) {
      try {
        const leadId = await LeadService.getLeadId(this.props.activeCompanyId, 0);
        this.setState({
          activeStepId: OfferComponent.STEP_LEAD_OFFER,
          debtorCompany: null,
          isLoading: false,
          leadId,
          leadOfferId: 0
        });
      } catch (e) {
        console.error(e);
        NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
      }
    }
  }

  getSteps = (props) => {
    const tabsNumber = [];
    if (props.activeCompanyId === 0) {
      tabsNumber.push(OfferComponent.STEP_LEAD, OfferComponent.STEP_ELIGIBILITY);
    } else if (props.activeCompanyRole === ROLE_ISO){
      tabsNumber.push(OfferComponent.STEP_LEAD);
    }
    tabsNumber.push(OfferComponent.STEP_LEAD_OFFER, OfferComponent.STEP_SUMMARY);

    return tabsNumber.map(num => ({
      id: num,
      title: <IntlMessageComponent id={`component.offer.step${num + 1}.title${this.props.activeCompanyRole === ROLE_ISO ? '.iso' : ''}`} />
    }));
  }

  getStepContent = () => {
    switch (this.state.activeStepId) {
      case OfferComponent.STEP_LEAD:
        return (<OfferTab1Component
          successHandler={this.handleSuccessTab1}
        />);
      case OfferComponent.STEP_ELIGIBILITY:
        return (<OfferTab2Component
          leadId={this.state.leadId}
          successHandler={this.handleSuccessTab2}
          backHandler={this.handleBackTab2}
        />);
      case OfferComponent.STEP_LEAD_OFFER:
        return (<OfferTab3Component
          debtorCompany={this.state.debtorCompany}
          enableBackButton={this.props.activeCompanyId > 0 && this.props.activeCompanyRole === ROLE_ISO}
          leadId={this.state.leadId}
          successHandler={this.handleSuccessTab3}
          backHandler={this.handleBackTab3}
        />);
      case OfferComponent.STEP_SUMMARY:
        return (<OfferTab4Component
          leadOfferId={this.state.leadOfferId}
          newSimulationWithSameDebtor={this.newSimulationWithSameDebtor}
          newSimulationWithOtherDebtor={this.newSimulationWithOtherDebtor}
          newSimulationWithOtherSeller={this.newSimulationWithOtherSeller}
        />);
      default:
        return 'Unknown step';
    }
  };

  handleSuccessTab1 = (leadId: number) => this.setState({activeStepId: (this.props.activeCompanyId === 0 ? OfferComponent.STEP_ELIGIBILITY : OfferComponent.STEP_LEAD_OFFER), leadId});
  handleSuccessTab2 = () => this.setState({activeStepId: OfferComponent.STEP_LEAD_OFFER});
  handleBackTab2 = () => this.setState({activeStepId: OfferComponent.STEP_LEAD});
  handleSuccessTab3 = (leadOfferId: number) => this.setState({activeStepId: OfferComponent.STEP_SUMMARY, leadOfferId});
  handleBackTab3 = () => this.setState({activeStepId: OfferComponent.STEP_LEAD, leadId: 0});
  newSimulationWithSameDebtor = (debtorCompany: DebtorCompanyModel) => this.setState({activeStepId: OfferComponent.STEP_LEAD_OFFER, debtorCompany, leadOfferId: 0});
  newSimulationWithOtherDebtor = () => this.setState({activeStepId: OfferComponent.STEP_LEAD_OFFER, debtorCompany: null, leadOfferId: 0});
  newSimulationWithOtherSeller = () => this.setState({activeStepId: OfferComponent.STEP_LEAD, debtorCompany: null, leadId: 0, leadOfferId: 0});

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <WizardComponent
        activeStepId={this.state.activeStepId}
        steps={this.state.steps}
      >
        {this.getStepContent()}
      </WizardComponent>
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user !== null ? state.auth.user.activeCompany.id : 0,
  activeCompanyRole: state.auth.user !== null ? state.auth.user.activeCompany.role : 0
});

export default connect(mapStateToProps)(withRouter(OfferComponent));
