/* @flow */

import React from 'react';
import {Link} from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import ButtonComponent from 'components/buttons/button';
import FormErrorsComponent from 'components/formErrors';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PasswordRulesComponent from 'components/passwordRules';

import ResetPasswordForm from './model';

import {SIGN_IN} from 'constants/pageRoutes';

type ResetPasswordViewComponentProps = {
  errors: Map<string, ?string>;
  form: ResetPasswordForm;
  formErrors: string[];
  isProcessing: boolean;
  handlePasswordChange: (value: string) => void;
  handleConfirmPasswordChange: (value: string) => void;
  submitForm: () => void;
}

export default function ResetPasswordViewComponent(props: ResetPasswordViewComponentProps) {
  const onPasswordChange = (event) => props.handlePasswordChange(event.target.value);
  const onConfirmPasswordChange = (event) => props.handleConfirmPasswordChange(event.target.value);

  return (
    <React.Fragment>
      <FormErrorsComponent formErrors={props.formErrors} />
        
      <PasswordRulesComponent />
        
      <Box mt={3}>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item xs={12} md={6}>
            <TextField
              id="form.password"
              label={<IntlMessageComponent id="form.password.label" />}
              error={!!props.errors.get('password')}
              helperText={(props.errors.get('password')) ?
                <IntlMessageComponent id={props.errors.get('password')} /> : ''}
              value={props.form.password}
              onChange={onPasswordChange}
              fullWidth
              margin="normal"
              required
              type="password"
            />
            
            <TextField
              id="form.confirmPassword"
              label={<IntlMessageComponent id="form.confirmpassword.label" />}
              error={!!props.errors.get('confirmPassword')}
              helperText={(props.errors.get('confirmPassword')) ?
                <IntlMessageComponent id={props.errors.get('confirmPassword')} /> : ''}
              value={props.form.confirmPassword}
              onChange={onConfirmPasswordChange}
              fullWidth
              margin="normal"
              required
              type="password"
            />
          </Grid>
        </Grid>
      </Box>

      <Box mt={5}>
        <ButtonComponent
          color="default"
          variant="contained"
          component={Link}
          to={SIGN_IN}
        ><IntlMessageComponent id="general.cancel" /></ButtonComponent>

        <Box component="span" ml={1}>
          <ButtonComponent
            color="primary"
            variant="contained"
            isProcessing={props.isProcessing}
            onClick={props.submitForm}
          ><IntlMessageComponent id="general.submit" /></ButtonComponent>
        </Box>
      </Box>
    </React.Fragment>
  );
}