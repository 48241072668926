/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import IntlMessageComponent from 'components/formatters/intlMessage';
import SelectComponent from 'components/select';

import CompanyPerson from 'models/companies/companyPerson';

import CompanyService from 'services/CompanyService';

import { ROLE_ISO, ROLE_DEBTOR } from 'constants/companyRoles';

type CompanyPersonWithoutOfficeFieldComponentProps = {
  activeCompanyId: number;
  activeCompanyRole: number;
  companyPerson: CompanyPerson;
  error: boolean;
  helperText: string; 
  handleSelect: (companyPerson: CompanyPerson) => void;
}

type CompanyPersonWithoutOfficeFieldComponentState = {
  companyPersons: CompanyPerson[];
}

class CompanyPersonWithoutOfficeFieldComponent extends React.Component<CompanyPersonWithoutOfficeFieldComponentProps, CompanyPersonWithoutOfficeFieldComponentState> {

  constructor(props) {
    super(props);
    this.state = {
      companyPersons: []
    };
  }

  componentDidMount = async () => {
    try {
      let companyPersons;
      if (this.props.activeCompanyRole === ROLE_ISO) 
        companyPersons = await CompanyService.getISOCompanyPersonsWithoutOffice(this.props.activeCompanyId);
      
      if (this.props.activeCompanyRole === ROLE_DEBTOR)
        companyPersons = await CompanyService.getDebtorCompanyPersonsWithoutOffice(this.props.activeCompanyId);

      this.setState({companyPersons});
    } catch (e) {
      console.error(e);
    }
  }

  handleCompanyPersonSelect = (value) => {
    if (value == null) return this.props.handleSelect(null);
    const companyPersonId = parseInt(value, 10);
    const companyPerson = this.state.companyPersons.find(cp => cp.id === companyPersonId);
    return this.props.handleSelect(companyPerson);
  }

  render() {
    const values = this.state.companyPersons.map(cp => ({value: cp.id.toString(), label: `${cp.lastName} ${cp.firstName}`}));

    return (
      <SelectComponent
        required
        error={this.props.error}
        helperText={this.props.helperText}
        label={<IntlMessageComponent id="form.companyPersons.label" />}
        placeholder={<IntlMessageComponent id="form.companyPersons.placeholder" />}
        value={this.props.companyPerson ? {id: this.props.companyPerson.id, label: `${this.props.companyPerson.lastName} ${this.props.companyPerson.firstName}`} : null}
        values={values}
        handleSelect={this.handleCompanyPersonSelect}
      />
    );
  }
}

const mapStateToProps = ({auth}) => ({
  activeCompanyId: auth.user.activeCompany.id,
  activeCompanyRole: auth.user.activeCompany.role
});

export default connect(mapStateToProps)(CompanyPersonWithoutOfficeFieldComponent);
