/* @flow */

import React from 'react';
import {useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(_ => ({
  img: {
    height: '35px',
    '@media screen and (max-width: 575px)': {
      height: '28px'
    },
    '@media screen and (max-width: 500px)': {
      height: '20px'
    },
    '@media screen and (max-width: 420px)': {
      height: '14px'
    }
  }
}));

export default function WebsiteHeaderLogoComponent() {
  const constants = useSelector(state => state.locale.constants);
  const classes = useStyles();

  const companyNameKey = 'general.company.name';
  const companyName = constants.find(c => c.key === companyNameKey);
  if (!companyName) throw new Error(`Missing constants: ${companyNameKey}`);

  const homeUrlKey = 'header.logo.url';
  const homeUrl = constants.find(c => c.key === homeUrlKey);
  if (!homeUrl) throw new Error(`Missing constants: ${homeUrlKey}`);

  return (
    <a href={homeUrl.value}>
      <img src="./images/logo.png" alt={companyName.value} title={companyName.value} className={classes.img} />
    </a>
  );
}
