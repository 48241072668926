/* @flow */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function IconMarketPlacePublic(props) {
  return (
    <SvgIcon viewBox="0 0 50.7 62.2" {...props}>
      <path d="M8.3,24.5V24c0.2-0.3,0.4-0.6,0.7-0.9c5.1-3.9,10.2-7.8,15.3-11.8c0.5-0.6,1.4-0.6,1.9-0.1l0.1,0.1
	      c5,3.9,10.1,7.7,15.1,11.6c0.3,0.3,0.7,0.6,0.9,1v0.3c-0.5,1.1-1.2,1.2-2.2,0.5l-1.7-1.3v16.1c0,1.2-0.3,1.6-1.5,1.6H13.6
	      c-1.2,0-1.5-0.3-1.5-1.5v-16l-1.7,1.2C9.5,25.5,9,25.5,8.3,24.5z M36.2,39c0-0.1,0.1-0.1,0.1-0.2c0-5.5,0-11,0-16.5
	      c0-0.3-0.2-0.6-0.5-0.8c-3.3-2.5-6.6-5-9.9-7.6c-0.3-0.3-0.8-0.3-1.1-0.1c0,0,0,0-0.1,0.1c-3.2,2.5-6.5,4.9-9.7,7.4
	      c-0.4,0.3-0.7,0.8-0.7,1.3c0,5.2,0,10.3,0,15.5c0,0.3,0,0.5,0,0.8h6v-4.8c0-1.7,0-3.4,0-5.1c0-1,0.4-1.4,1.2-1.4c2.5,0,4.9,0,7.4,0
	      c0.5-0.1,1.1,0.3,1.1,0.8c0,0.1,0,0.2,0,0.3c0,0.3,0,0.6,0,0.9v8.5c0,0.4-0.1,0.8,0.5,0.8C32.5,39,34.4,39,36.2,39L36.2,39z
	      M22.7,39c1.7,0,3.3,0,5,0c0.2,0,0.4-0.4,0.4-0.7c0-2.6,0-5.1,0-7.7c0-0.6-0.3-0.8-0.8-0.8c-1.3,0-2.6,0-3.9,0
	      c-0.6,0-0.8,0.2-0.7,0.8c0,1.6,0,3.1,0,4.7V39z"/>
      <path d="M24.9,0H26c0.2,0.1,0.5,0.3,0.7,0.4L36.5,3c3.7,1.1,7.6,1.9,11.5,2.3c2.2,0.1,2.6,0.6,2.6,2.9
	      c0,7.4,0,14.8,0,22.2c0,1.6-0.2,3.3-0.5,4.9c-1,5.2-3.3,10.1-6.6,14.4c-3.9,5.3-8.7,9.5-15,11.8c-2.1,0.8-4.2,1.2-6.2,0
	      c-2.3-1.2-4.5-2.5-6.6-4c-5-3.9-9.1-8.7-12.1-14.2C1.3,38.9,0,34,0,29.1C0.2,22,0,14.9,0,7.7C0,6,0.6,5.5,2.3,5.3
	      C5,4.9,7.6,4.6,10.2,4c3.4-0.8,6.8-1.8,10.1-2.7C21.8,0.9,23.4,0.5,24.9,0z M46.9,21.3c0-3.7,0-7.4,0-11c0-0.8-0.2-1.2-1.1-1.3
	      c-1.2-0.1-2.5-0.3-3.7-0.6C37.5,7.2,32.9,6,28.3,4.8c-1.1-0.3-2.4-0.9-3.4-0.6C21.5,4.9,18.2,6,14.8,6.8c-3.2,0.8-6.5,1.5-9.7,2.1
	      c-0.9,0.2-1.2,0.5-1.2,1.4C3.9,17.2,3.8,24,4,30.9c0.1,2.2,0.5,4.4,1.3,6.5c2,5.2,5.1,9.9,9.1,13.7c2.9,3.1,6.4,5.5,10.3,7
	      c0.3,0.2,0.7,0.2,1.1,0.1c1.4-0.5,2.8-1,4.2-1.7c5.5-3,9.9-7.5,13-13c2.8-4.6,4.2-9.9,4-15.3C46.8,26.1,46.8,23.7,46.9,21.3
	      L46.9,21.3z"/>
    </SvgIcon>
  );
}
