/* @flow */

import React from 'react';
import { connect } from 'react-redux';

import * as reactGA from 'lib/analytics';

import LoadingComponent from 'components/loading';

import SubscriptionTab3ViewComponent from './view';

import Document from 'models/documents/v2/document';
import DocumentType from 'models/documents/documentType';
//import DocumentNotExpiredAndNotReplaced from 'models/documents/documentNotExpiredAndNotReplaced';

import DocumentService from 'services/DocumentService';
import RegisterService from 'services/registerService';
import { handleApiFormResponse } from 'lib/forms';

import NotificationHelper from 'lib/notifications';

import { SIGNUP_SEND_DOCUMENT } from 'constants/apiRoutes';
import {
  DOC_TYPE_ID,
  DOC_TYPE_ID_FRONT,
  DOC_TYPE_ID_BACK,
  DOC_TYPE_RESIDENT_BACK,
  DOC_TYPE_RESIDENT_FRONT,
  DOC_TYPE_PASSPORT,
  ENTITY_USER
} from 'constants/constants';

type SubscriptionTab3ComponentProps = {
  activeCompanyId: number;
  successHandler: () => void;
}

type SubscriptionTab3ComponentState = {
  documents: Document[];
  additionalDocuments: Document[];
  isLoading: boolean;
  isProcessing: Boolean;
  extraDocuments: Document[];
  filteredExtraDocuments: Document[];
  selectedDocumentType: DocumentType;
  toClassifyDocumentType: DocumentType;
}

class SubscriptionTab3Component extends React.Component<SubscriptionTab3ComponentProps, SubscriptionTab3ComponentState> {

  constructor(props) {
    super(props);
    this.state = {
      documents: [],
      DocumentNotExpiredAndNotReplaced: [],
      isLoading: true,
      isProcessing: false
    };
  }

  componentDidMount = async () => {
    reactGA.initializeGA();
    reactGA.logEvent('Subscription', 'Documents Upload', `Company ID: ${this.props.activeCompanyId}`);

    try {
      const response = await RegisterService.getRequiredDocuments(this.props.activeCompanyId);
      const DocumentNotExpiredAndNotReplaced =
        await DocumentService.getPersonNotExpiredAndNotReplacedDocuments(this.props.activeCompanyId, this.props.activeCompanyPersonId);

      const documents = response.documents;
      const additionalDocuments = response.additionalSubscriptionDocuments;

      var isProvided = false;
      var allDocumentsProvided = true;
      // Check if valid ID (front + back side) is provided
      var isIDProvided = false;
      if (DocumentNotExpiredAndNotReplaced.find(row => row.typeID === DOC_TYPE_ID
        && row.entityID === row.personID
        && row.entityTypeID === ENTITY_USER) != null) isIDProvided = true;
      else isIDProvided = false;
      for (let i = 0; i < documents.length; i++) {
        documents[i].file = null;
        documents[i].filename = '';
        documents[i].isInvalid = false;
        documents[i].isMissing = false;
        isProvided = false;
        documents[i].isProvided = isProvided;
        switch (documents[i].typeId) {
          case DOC_TYPE_ID_FRONT:
          case DOC_TYPE_ID_BACK:
            if (isIDProvided) isProvided = true;
            else {
              isProvided = this.checkNotExpiredAndNotReplaced(documents[i], DocumentNotExpiredAndNotReplaced);
            }
            break;
          default:
            isProvided = this.checkNotExpiredAndNotReplaced(documents[i], DocumentNotExpiredAndNotReplaced);
        }
        documents[i].isProvided = isProvided;
        if (allDocumentsProvided && !isProvided) allDocumentsProvided = false;
      }
      this.setState({
        documents: documents,
        additionalDocuments: additionalDocuments,
        extraDocuments: response.extraDocuments,
        filteredExtraDocuments: response.extraDocuments,
        selectedDocumentType: response.extraDocuments[0],
        toClassifyDocumentType: response.toClassifyDocumentType,
        isLoading: false
      });
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  }

  componentWillUnmount = async () => {
    reactGA.logEvent('Subscription', 'Documents Uploaded', `Company ID: ${this.props.activeCompanyId}`);
  }

  checkNotExpiredAndNotReplaced = (document, documentList) => {
    let isProvided = false;
    switch (document.typeId) {
      case DOC_TYPE_ID:
      case DOC_TYPE_ID_FRONT:
      case DOC_TYPE_ID_BACK:
      case DOC_TYPE_PASSPORT:
      case DOC_TYPE_RESIDENT_BACK:
      case DOC_TYPE_RESIDENT_FRONT:
        if (documentList.find(row => row.typeID === document.typeId
          && row.entityID === row.personID
          && row.entityTypeID === ENTITY_USER) != null) isProvided = true;
        break;
      default:
        if (documentList.find(row => row.typeID === document.typeId) != null) isProvided = true;
        break;
    }
    return isProvided;
  }

  handleDocumentSelect = (index: number) => (file: File) => {
    const documents = this.state.documents;
    documents[index].file = file;
    documents[index].filename = file.name;
    documents[index].isInvalid = false;
    documents[index].isMissing = false;
    documents[index].isProvided = true;
    this.setState({ documents });
  };

  handleAdditionalDocumentSelect = (index: number) => (file: File) => {
    const additionalDocuments = this.state.additionalDocuments;
    additionalDocuments[index].file = file;
    additionalDocuments[index].filename = file.name;
    additionalDocuments[index].isInvalid = false;
    additionalDocuments[index].isMissing = false;
    additionalDocuments[index].isProvided = true;
    this.setState({ additionalDocuments });
  };

  handleDocumentSelectFailure = (typeId: number) => (file: File) => {
    const documents = this.state.documents;
    const document = documents.find(doc => doc.typeId === typeId);
    if (!document) return;
    document.file = null;
    document.filename = file.name;
    document.isInvalid = true;
    document.isMissing = false;
    document.isProvided = false;
    this.setState({ documents });
  }

  handleAdditionalDocumentSelectFailure = (typeId: number) => (file: File) => {
    const additionalDocuments = this.state.additionalDocuments;
    const document = additionalDocuments.find(doc => doc.typeId === typeId);
    if (!document) return;
    document.file = null;
    document.filename = file.name;
    document.isInvalid = true;
    document.isMissing = false;
    document.isProvided = false;
    this.setState({ additionalDocuments });
  }

  addDocument = () => {
    const documents = this.state.documents;
    let filteredExtraDocuments = this.state.filteredExtraDocuments;
    let selectedDocumentType = this.state.selectedDocumentType;
    let document = new Document();
    document.isRequired = false;
    document.isProvided = false;
    document.isOptional = true;
    document.typeId = selectedDocumentType.typeId;
    document.code = selectedDocumentType.code;
    documents.push(document);
    if (selectedDocumentType.typeId !== this.state.toClassifyDocumentType.typeId) {
      filteredExtraDocuments = filteredExtraDocuments.filter(d => d !== selectedDocumentType);
      selectedDocumentType = filteredExtraDocuments[0];
    }
    this.setState({ documents, filteredExtraDocuments, selectedDocumentType });
  }

  handleDocumentTypeChange = (event) => {
    this.setState({ selectedDocumentType: event.target.value });
  }

  handleDocumentRemove = (index: number) => () => {
    const documents = this.state.documents;
    let filteredExtraDocuments = this.state.filteredExtraDocuments;
    if (documents[index].isRequired || !documents[index].isOptional) {
      // can't remove required documents
      documents[index].file = null;
      documents[index].filename = '';
      documents[index].isInvalid = false;
      documents[index].isMissing = false;
      documents[index].isProvided = false;
      documents[index].isDeleted = true;
    } else if (documents[index].id > 0) {
      // flag optional documents - but already submitted - to be archived
      documents[index].isDeleted = true;
    } else {
      // remove optional - not yet submitted - documents
      let document = documents[index];
      let foundDocumentType = this.state.extraDocuments.find(d => d.typeId === document.typeId)
      if (foundDocumentType !== undefined && foundDocumentType.typeId !== this.state.toClassifyDocumentType.typeId) {
        filteredExtraDocuments.push(foundDocumentType)
        filteredExtraDocuments = this.state.extraDocuments.filter(item => filteredExtraDocuments.includes(item));
      }

      documents.splice(index, 1);
    }
    this.setState({ documents, filteredExtraDocuments });
  };

  handleAdditionalDocumentRemove = (index: number) => () => {
    const additionalDocuments = this.state.additionalDocuments;

    additionalDocuments[index].file = null;
    additionalDocuments[index].filename = '';
    additionalDocuments[index].isInvalid = false;
    additionalDocuments[index].isMissing = false;
    additionalDocuments[index].isProvided = false;
    additionalDocuments[index].isDeleted = true;

    this.setState({ additionalDocuments });
  };

  submitForm = async () => {
    if (!this.validateDocuments()) return;
    this.setState({ isProcessing: true });

    // add documents
    const addPromises = [];
    this.state.documents.forEach(document => {
      if (document.file !== null) {
        addPromises.push(DocumentService.sendDocument2(SIGNUP_SEND_DOCUMENT, document.file,
          { companyId: this.props.activeCompanyId, documentTypeId: document.typeId }));
      }
    });
    this.state.additionalDocuments.forEach(document => {
      if (document.file !== null) {
        addPromises.push(DocumentService.sendDocument2(SIGNUP_SEND_DOCUMENT, document.file,
          { companyId: this.props.activeCompanyId, documentTypeId: document.typeId }));
      }
    });

    // if nothing, do nothing
    if (addPromises.length === 0) {
      this.props.successHandler();
      return;
    }

    try {
      await Promise.all(addPromises);
      this.props.successHandler();
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }
  }

  validateDocuments = () => {
    let isValid = true;
    const documents = this.state.documents;

    for (let i = 0; i < documents.length; i++) {
      if (documents[i].isRequired && !documents[i].isProvided && documents[i].file === null) {
        // not yet provided and missing -> issue
        documents[i].isMissing = true;
        isValid = false;
      }
    }

    if (!isValid) this.setState({ documents });
    return isValid;
  }

  render = () => {
    if (this.state.isLoading) return (<LoadingComponent />);
    return (
      <SubscriptionTab3ViewComponent
        documents={this.state.documents}
        additionalDocuments={this.state.additionalDocuments}
        isProcessing={this.state.isProcessing}
        handleDocumentSelect={this.handleDocumentSelect}
        handleDocumentSelectFailure={this.handleDocumentSelectFailure}
        handleDocumentRemove={this.handleDocumentRemove}
        handleAdditionalDocumentSelect={this.handleAdditionalDocumentSelect}
        handleAdditionalDocumentSelectFailure={this.handleAdditionalDocumentSelectFailure}
        handleAdditionalDocumentRemove={this.handleAdditionalDocumentRemove}
        handleDocumentTypeChange={this.handleDocumentTypeChange}
        addDocument={this.addDocument}
        extraDocuments={this.state.extraDocuments}
        filteredExtraDocuments={this.state.filteredExtraDocuments}
        selectedDocumentType={this.state.selectedDocumentType}
        toClassifyDocumentType={this.state.toClassifyDocumentType}
        submitForm={this.submitForm}
      />
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id,
  activeCompanyPersonId: state.auth.user.activeCompany.companyPersonId
});

export default connect(mapStateToProps)(SubscriptionTab3Component);
