/* @flow */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function IconInvoiceDue5Days(props) {
  return (
    <SvgIcon viewBox="0 0 57.38 61.08" {...props}>
      <g>
        <path d="M245.32,472a.57.57,0,0,1-.17-.42v-4.8h-4.7a.57.57,0,0,1-.42-.17.54.54,0,0,1-.17-.42v-1.33a.57.57,0,0,1,.17-.42.57.57,0,0,1,.42-.17h4.7v-4.65a.56.56,0,0,1,.17-.43.6.6,0,0,1,.42-.16h1.48a.57.57,0,0,1,.43.16.56.56,0,0,1,.16.43v4.65h4.7a.58.58,0,0,1,.59.59v1.33a.57.57,0,0,1-.16.42.58.58,0,0,1-.43.17h-4.7v4.8a.57.57,0,0,1-.16.42.54.54,0,0,1-.43.17h-1.48A.57.57,0,0,1,245.32,472Z" transform="translate(-225.79 -424.97)"/>
        <path d="M258.06,472.52a5.38,5.38,0,0,1-2.25-1.82,4.59,4.59,0,0,1-.81-2.44v-.05a.41.41,0,0,1,.16-.33.52.52,0,0,1,.35-.14h2.34a.68.68,0,0,1,.72.49,2.81,2.81,0,0,0,1.14,1.6,3.34,3.34,0,0,0,1.88.52,3.21,3.21,0,0,0,2.28-.84,3,3,0,0,0,.9-2.31,2.77,2.77,0,0,0-.9-2.15,3.25,3.25,0,0,0-2.28-.83,3.13,3.13,0,0,0-1.2.2,3.9,3.9,0,0,0-.91.54l-.22.15a1.41,1.41,0,0,1-.31.17.92.92,0,0,1-.28.05h-2.32a.51.51,0,0,1-.38-.16.52.52,0,0,1-.16-.38l.84-8.4a.68.68,0,0,1,.23-.5.72.72,0,0,1,.51-.19h9.25a.6.6,0,0,1,.43.16.57.57,0,0,1,.16.43V458a.6.6,0,0,1-.16.42.56.56,0,0,1-.43.17h-7.11l-.4,4a5.46,5.46,0,0,1,1.31-.61,6.71,6.71,0,0,1,1.92-.23,6.6,6.6,0,0,1,3,.68,5.44,5.44,0,0,1,2.2,1.94,5.37,5.37,0,0,1,.82,2.94,5.78,5.78,0,0,1-.84,3.14,5.42,5.42,0,0,1-2.36,2.07,8.24,8.24,0,0,1-3.55.73A8.33,8.33,0,0,1,258.06,472.52Z" transform="translate(-225.79 -424.97)"/>
      </g>
      <path d="M239.94,428.57a20.47,20.47,0,0,1,.56-3.43c.07-.22,1.17-.17,1.79-.15,2.72.11.6,2.34,1.79,3.49.17,0,.72.08,1.28.08,6,0,12.11,0,18.16,0,1.45,0,2.1-.34,1.86-1.85a11.5,11.5,0,0,1,0-1.67h3.39v3.49h6.11c5.47,0,8.3,2.85,8.3,8.33q0,20.36,0,40.7a13.21,13.21,0,0,1-.39,3.44,6.73,6.73,0,0,1-6.3,4.92c-3.19.11-6.39.07-9.59.07-10.9,0-21.8.05-32.7,0a12.16,12.16,0,0,1-5-1.13,6.08,6.08,0,0,1-3.34-5.42c-.1-1.54-.07-3.1-.07-4.65q0-19.11,0-38.22c0-5.09,2.92-8,8-8h6.1Zm-10.51,18c0,.55-.08,1-.08,1.4,0,10.12.06,20.24,0,30.36a4,4,0,0,0,4.34,4.25c13.89,0,27.79,0,41.68,0,2.91,0,4.34-1.47,4.34-4.39V448.09c0-.51-.05-1-.07-1.54Zm-.05-3.66h50.28c0-2.57,0-5,0-7.5a3.51,3.51,0,0,0-3-3.09c-2.55-.11-5.11,0-7.78,0l-.18,3.47h-3.3v-1.63c0-1.86,0-1.86-1.93-1.87H243.61v3.39h-3.5v-3.35c-2.69,0-5.25-.08-7.8,0a3.36,3.36,0,0,0-2.91,2.81C229.33,437.68,229.38,440.24,229.38,442.89Z" transform="translate(-225.79 -424.97)"/>
    </SvgIcon>
  );
}
