/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';

import IntlMessageComponent from 'components/formatters/intlMessage';
import {FormattedMessage} from 'react-intl';

import Filter from 'models/isos/filters/filter';
import isoLeads from 'components/companyLookupV2/isoLeads/index';

type FilterDropdownComponentProps = {
  filterId: string;
  filters: Filter[];
  label: string;
  sellerCompanyName: string;
  openClosedId: number;
  sellerCompanyNames: string[];
  selectFilter: (optionId: number) => void;
  sellerCompanyNameChange: (name: string) => void;
}

type FilterDropdownComponentState = {
  showFilter: boolean;
  currentFilters: Filter[];
  isObject: boolean;
}

class FilterDropdownComponent extends React.Component<FilterDropdownComponentProps, FilterDropdownComponentState> {

  constructor(props) {
    super(props);
    this.state = {
      showFilter: true,
      currentFilters: this.props.filters,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.filterId === 'sellerCompanies') {
      if (prevProps.sellerCompanyName !== this.props.sellerCompanyName) {
        this.filterSearchSellerCompanyName();
      }
      if (prevProps.sellerCompanyNames !== this.props.sellerCompanyNames) {
        this.filterAvailableSellerCompanies();
      }
    }
    if (this.props.filterId === 'status') {
      if (prevProps.openClosedId !== this.props.openClosedId) {
        this.filterStatus();
      }
    }
  }

  onDisplayFilterCompanyToggle = () => {
    this.setState(state => ({ showFilter: !state.showFilter }));
  }

  filterSearchSellerCompanyName = () => {
    if (this.props.sellerCompanyName !== undefined) {
      const name = this.props.sellerCompanyName;
      let currentFilters = this.props.filters;
      if (name !== null && name.length >= 3) {
        currentFilters = currentFilters.filter(x => { return x.name.includes(name.toUpperCase()) });
      }
      this.setState({ currentFilters });
    }
  }

  filterAvailableSellerCompanies = () => {
    if (this.props.sellerCompanyNames !== undefined) {
      let currentFilters = this.props.filters;
      currentFilters = currentFilters.filter(x => this.props.sellerCompanyNames.find(c => c === x.name) !== undefined);
      this.setState({ currentFilters });
    }
  }

  filterStatus = () => {
    let currentFilters = this.props.filters;
    if (this.props.openClosedId === 0) {
      currentFilters = currentFilters.filter(x => { return !this.isOpenLeadStatus(x.id)});
    }
    if (this.props.openClosedId === 1) {
      currentFilters = currentFilters.filter(x => { return this.isOpenLeadStatus(x.id)});
    }
    this.setState({ currentFilters });
  }

  isOpenLeadStatus = (statusId: number) => {
    switch (statusId) {
      case 1899:
      case 3881:
      case 1900:
      case 1901:
      case 1902:
      case 1903:
      case 1904:
      case 1905:
      case 1907:
        return true;
      default: 
        return false;
    }
  }

  sellerCompanyNameChange = () => (event: SyntheticInputEvent<HTMLInputElement>) => {
    const name = event.target.value;
    this.props.sellerCompanyNameChange(name);
  }

  render() {
    return (
      <FormControl component="fieldset" fullWidth>
        <FormLabel component="legend" >
          <b>{this.state.showFilter ? '' : <span>&#x25B6;</span>}<IntlMessageComponent id={this.props.label} /></b>
        </FormLabel>
        <Box mb={5}>
          {/* {this.state.showFilter && <InputLabel id="demo-mutiple-checkbox-label"><IntlMessageComponent id={this.props.label} /></InputLabel>} */}
          {this.state.showFilter && <Select
            multiple
            value={this.state.currentFilters.filter(filter => filter.isSelected)}
            renderValue={(selected) => selected.length > 1 ? <IntlMessageComponent id={'iso.filters.multipleselected'} /> : selected[0].name}
            style={{minWidth: "15vw", maxWidth: "15vw"}}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              getContentAnchorEl: null
            }}
          >
            {this.state.currentFilters.map(filter => {
              return (
                <MenuItem
                    onClick={() => {
                      this.props.selectFilter(this.props.filterId, filter.id)
                    }}
                    value={filter.id}
                  >
                    <Checkbox checked={filter.isSelected} />
                    <ListItemText primary={filter.name} />
                </MenuItem>
              );
            })}
          </Select>}
        </Box>
        {/* {this.props.filterId === 'sellerCompanies' &&
          <TextField
            id="seller.select"
            label={<IntlMessageComponent id="isofilters.sellers.value" />}
            data-walkme="isofilters-sellers"
            fullWidth
            margin="dense"
            rowsMax="5"
            value={this.props.sellerCompanyName}
            onChange={this.sellerCompanyNameChange()}
          />
        } */}
      </FormControl>
    );
  }
}

export default FilterDropdownComponent;
