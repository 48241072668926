/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import Box from '@material-ui/core/Box';

import CompanyItemComponent from 'components/company';
import CompanyLookupSupplierViewComponent from './view';
import CompanyLookupSupplierTwoColumnsViewComponent from './view2columns';

import SupplierCompany from 'models/companies/company';

import CompanyLookupService from 'services/CompanyLookupService';

import {ROLE_ISO} from 'constants/companyRoles';
import {history} from 'store/index';
import { PROTECTED_CONFIRMING_ADD_SUPPLIER } from 'constants/pageRoutes';

type CompanyLookupSupplierComponentProps = {
  activeCompanyId: number;
  activeCompanyRole: number;
  company: SupplierCompany;
  companyErrorLabelId: string;
  countriesForSuppliers: Country[];
  required: boolean;
  twoColumns: boolean;
  handleSelect: (company: SupplierCompany) => void;
}

type CompanyLookupSupplierComponentState = {
  knownSuppliers: SupplierCompany[];
}

class CompanyLookupSupplierComponent extends React.Component<CompanyLookupSupplierComponentProps, CompanyLookupSupplierComponentState> {

  constructor(props) {
    super(props);
    this.state = {
      knownSuppliers: null
    };
  }

  componentDidMount = async () => {
    if (this.props.activeCompanyId <= 0 || this.props.activeCompanyRole === ROLE_ISO) return;
    const knownSuppliers = await CompanyLookupService.getSupplierCompanies(this.props.activeCompanyId);
    this.setState({knownSuppliers});
  }

  handleKnownSupplierCompanySelect = (company: SupplierCompany) => {
    const supplierCompany = this.state.knownSuppliers.find(d => d.id === company.id);
    this.props.handleSelect(supplierCompany);
  };

  redirectToAddSupplier = () => {
    history.push(`${PROTECTED_CONFIRMING_ADD_SUPPLIER}`);
  }

  render() {
    if (this.props.company) {
      return (
        <Box px={4} py={3} bgcolor="lightblue.main">
          <CompanyItemComponent
            buttonLabelId="component.offer.tab1.company.change"
            company={this.props.company}
            selected
            selectHandler={() => this.props.handleSelect(null)}
            hideVATCountry={/[a-z]/i.test(this.props.company.vatNumber)}
          />
        </Box>
      );
    }

    if (this.props.twoColumns) {
      return (
        <CompanyLookupSupplierTwoColumnsViewComponent
          companyErrorLabelId={this.props.companyErrorLabelId}
          countriesForSuppliers={this.props.countriesForSuppliers}
          knownSuppliers={this.state.knownSuppliers}
          required={this.props.required}
          handleKnownSupplierCompanySelect={this.handleKnownSupplierCompanySelect}
          handleSelect={this.props.handleSelect}
          redirectToAddSupplier={this.redirectToAddSupplier}
        />
      )
    }
    return (
      <CompanyLookupSupplierViewComponent
        companyErrorLabelId={this.props.companyErrorLabelId}
        countriesForSuppliers={this.props.countriesForSuppliers}
        knownSuppliers={this.state.knownSuppliers}
        required={this.props.required}
        handleKnownSupplierCompanySelect={this.handleKnownSupplierCompanySelect}
        handleSelect={this.props.handleSelect}
        redirectToAddSupplier={this.redirectToAddSupplier}
      />
    )
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user !== null ? state.auth.user.activeCompany.id : 0,
  activeCompanyRole: state.auth.user !== null ? state.auth.user.activeCompany.role : 0,
  countriesForSuppliers: state.locale.countriesForSuppliers
});

export default connect(mapStateToProps)(CompanyLookupSupplierComponent);
