/* @flow */

import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';

import * as reactGA from 'lib/analytics';

import LoadingComponent from 'components/loading';

import SubscriptionTab1ViewComponent from './view';

import Company from 'models/companies/company';
import CompanyInfoForm from './model';

import CompanyService from 'services/CompanyService';

import RegisterService from 'services/registerService';
import {handleApiFormResponse, handleFormChange, handleFormValidation} from 'lib/forms';
import loadConstraints from 'lib/validation/loadConstraints';

import NotificationHelper from 'lib/notifications';
import { ROLE_DEBTOR } from 'constants/companyRoles';

type SubscriptionTab1ComponentProps = {
  activeCompanyId: number;
  activeCompanyRole: number;
  company: Company;
  successHandler: () => void;
}

type SubscriptionTab1ComponentState = {
  constraints: any;
  companyInfo: CompanyInfoForm;
  errors: Map<string, ?string>;
  formErrors: string[];
  isLoading: boolean;
  isProcessing: boolean;
}

class SubscriptionTab1Component extends React.Component<SubscriptionTab1ComponentProps, SubscriptionTab1ComponentState> {

  constructor(props) {
    super(props);
    this.state = {
      constraints: null,
      errors: null,
      form: null,
      formErrors: [],
      isLoading: true,
      isProcessing: false,
      companyDetails: null
    };
  }

  componentDidMount = async () => {
    reactGA.initializeGA();
    reactGA.logEvent('Subscription', 'Start Subscription', `Company ID: ${this.props.activeCompanyId}`);

    try {
      // Load company info
      const companyInfo = await RegisterService.getCompanyInfo(this.props.activeCompanyId);

      // Load company details
      const companyDetails = await CompanyService.getCompanyDetails(this.props.activeCompanyId);

      // Load constraints
      var filters = null;
      if (companyDetails.isSeller || companyDetails.isBuyer || companyDetails.isConfirming) {
        filters = ['isin', 'phone', 'activities', 'mainActivityCountryId', 'sourceOfFunds', 'bankCountryId', 'bankIdentifier', 'bankAccount'];
      }
      else {
        filters = ['isin', 'phone', 'bankCountryId', 'bankIdentifier', 'bankAccount'];
      }
      
      if (this.props.company.countryId === 335) {
        filters.push('companyCardOnlineCode');
        filters.push('commercialRegistrationOnlineCode');
        filters.push('commercialRegistrationOnlineCodeExpiry');
      }

      const constraints = await loadConstraints('Company', filters);

      
      if (this.props.activeCompanyRole === ROLE_DEBTOR) {
        constraints.bankAccount.presence.allowEmpty = true;
        constraints.bankAccount.iban.allowEmpty = true;
        constraints.bankIdentifier.presence.allowEmpty = true;
        constraints.bankCountryId.presence.allowEmpty = true;
        constraints.bankCountryId.numericality.allowEmpty = true;
        delete constraints.bankCountryId.numericality.greaterThan;
        constraints.bankCountryId.numericality.greaterThanOrEqualTo = 0;
      }

      this.setState({
        constraints,
        errors: new Map(Object.keys(constraints).map(e => [e, undefined])),
        form: new CompanyInfoForm(companyInfo),
        isLoading: false,
        companyDetails
      });
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  };

  componentWillUnmount = async () => {
    reactGA.logEvent('Subscription', 'Company Information Validated', `Company ID: ${this.props.activeCompanyId}`);
  }

  handleChange = (fieldName: string) => (event) => handleFormChange.call(this, fieldName, event.target.value);
  handleDateChange = (fieldName: string) => (date) => handleFormChange.call(this, fieldName, date);
  handleTypeChange = (fieldName: string) => (id: number) => handleFormChange.call(this, fieldName, id);
  handlePhoneChange = (value: string) => {
    handleFormChange.call(this, 'phone', value);
  }
  validateForm = () => handleFormValidation.call(this);
  handleBooleanChange = (fieldName: string) => (event) => {
    const form = this.state.form;
    form[fieldName] = event.target.value === 'true';
    this.setState({form});
  };

  submitForm = async () => {
    if (!this.validateForm()) return;
    this.setState({formErrors: [], isProcessing: true});

    try {
      const request = this.state.form.toCompanyInfoRequest(this.props.activeCompanyId);
      if (this.state.companyDetails.isISO || this.state.companyDetails.isDebtor)
      {
        await RegisterService.setCompanyInfoForISO(request);        
      }
      else
      {
        await RegisterService.setCompanyInfo(request);
      }

      if (this.state.form.bankAccount !== '') {
        const request = this.state.form.toCompanyBankAccountRequest(this.props.activeCompanyId);
        await RegisterService.setCompanyBankAccount(request);
      }

      this.props.successHandler();
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }
  };

  render = () => {
    if (this.state.isLoading) return <LoadingComponent />;

    return (
      <SubscriptionTab1ViewComponent
        company={this.props.company}
        companyDetails={this.state.companyDetails}
        errors={this.state.errors}
        form={this.state.form}
        formErrors={this.state.formErrors}
        isProcessing={this.state.isProcessing}
        minDate={moment().add(1, 'd').startOf('d')}
        handleChange={this.handleChange}
        handleDateChange={this.handleDateChange}
        handleTypeChange={this.handleTypeChange}
        handlePhoneChange={this.handlePhoneChange}
        handleBooleanChange={this.handleBooleanChange}
        submitForm={this.submitForm}
      />
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id,
  activeCompanyRole: state.auth.user.activeCompany.role
});

export default connect(mapStateToProps)(SubscriptionTab1Component);
