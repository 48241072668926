/* @flow */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function IconDocumentFilled(props) {
  return (
    <SvgIcon viewBox="0 0 48 60.46" {...props}>
      <g>
        <path d="M401.45,327.84a4.9,4.9,0,0,1-3.49-1.42,5,5,0,0,1-1.4-3.54c.07-17.84.07-34.28,0-50.27a5.09,5.09,0,0,1,1.44-3.75,5.22,5.22,0,0,1,3.85-1.48c8.47.06,17.09,0,25.42,0h.81a5.84,5.84,0,0,1,4.38,1.85q4.82,4.93,9.68,9.83a7,7,0,0,1,2.41,4V323.6l-.06.16c-.84,2.43-2,4.05-5,4.05H424.89c-7.81,0-15.62,0-23.43,0Zm.35-58.46a3.37,3.37,0,0,0-2.39.89,3.11,3.11,0,0,0-.85,2.33c.07,16,.07,32.44,0,50.29a2.91,2.91,0,0,0,.82,2.12,3.06,3.06,0,0,0,2.08.83c7.81,0,15.62,0,23.43,0h14.62c1.47,0,2.27-.4,3.05-2.58v-40a5.32,5.32,0,0,0-1.84-2.79q-4.87-4.91-9.69-9.84a3.82,3.82,0,0,0-2.95-1.25h-.8c-8.34,0-17,0-25.44,0Zm38.74,54.46h-40V271.4h27.7V281a3.26,3.26,0,0,0,.92,2.4,3.13,3.13,0,0,0,2.3.83c1.86-.05,3.76,0,5.59,0l2.48,0h1Zm-38-2h36V286.2h-1.49c-1.9,0-3.69,0-5.52,0a5.1,5.1,0,0,1-3.75-1.39,5.26,5.26,0,0,1-1.53-3.84V273.4h-23.7Zm36.72-39.6h-9v-9Zm-7-2h2.2l-2.2-2.19Z" transform="translate(-396.56 -267.38)"/>
        <g>
          <path d="M436.85,281.24h-5.61v-5.61Z" transform="translate(-396.56 -267.38)"/>
          <path d="M439.27,282.24h-9v-9Zm-7-2h2.2l-2.2-2.19Z" transform="translate(-396.56 -267.38)"/>
        </g>
        <path d="M431.5,285.22a4,4,0,0,1-4.25-4.23V272.4h-25.7v50.44h38V285.21C436.81,285.21,434.16,285.14,431.5,285.22Zm.1,29.73H409.42c-1.45,0-2.32-.74-2.31-2s.88-2,2.33-2h22.18c1.5,0,2.28.69,2.28,2S433.11,315,431.6,315Zm-24.49-11.46c0-1.21.83-1.95,2.21-1.95,3.75,0,7.49,0,11.24,0s7.42,0,11.14,0c1.42,0,2.19.68,2.19,1.93s-.77,2-2.17,2q-11.19,0-22.38,0A2,2,0,0,1,407.11,303.49Zm24.7-7.8c-3.77,0-7.55,0-11.33,0s-7.49,0-11.23,0a1.9,1.9,0,0,1-1.88-2.85,1.67,1.67,0,0,1,1.75-1h22.66c1.35,0,2.11.7,2.12,1.9A1.83,1.83,0,0,1,431.81,295.69Z" transform="translate(-396.56 -267.38)"/>
      </g>
    </SvgIcon>
  );
}
