/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import EditOutlined from '@material-ui/icons/EditOutlined';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';

import BooleanFormatterComponent from 'components/formatters/boolean';
import ButtonComponent from 'components/buttons/button';
import CountryNameComponent from 'components/country/name';
import IntlMessageComponent from 'components/formatters/intlMessage';
import SecurityComponent from 'components/security';
import StyledTableCellTextComponent from 'components/table/cellText';
import StyledTableHeadComponent from 'components/table/head';
import StyledTableRowComponent from 'components/table/row';
import Office from 'models/companies/office';

import { hasSecurityMenuAccess } from 'lib/securityHelper';
import { useSelector } from 'react-redux';

const columnData = [
  { id: 'name', translationKey: 'component.offices.list.header.name' },
  { id: 'city', translationKey: 'component.offices.list.header.city' },
  { id: 'zipCode', translationKey: 'component.offices.list.header.zipCode' },
  { id: 'countryId', translationKey: 'component.offices.list.header.country' },
  { id: 'isActive', translationKey: 'component.offices.list.header.isActive' }
];

type CompanyOfficeTableViewComponentProps = {
  offices: Office[];
  order: string;
  orderBy: string;
  page: number;
  pageSize: number;
  newOffice: () => void;
  editOffice: (office: Office) => void;
  handlePageChange: (page: number) => void;
  handlePageSizeChange: (pageSize: number) => void;
  handleSortOrderChange: (property: string) => void;
  toggleOfficeActiveStatus: (office: Office) => void;
}

export default function CompanyOfficeTableViewComponent(props: CompanyOfficeTableViewComponentProps) {

  const handlePageChange = (_, page) => props.handlePageChange(page);
  const handlePageSizeChange = event => props.handlePageSizeChange(event.target.value);

  const { activeCompany, securityMenus } = useSelector(state => ({
    activeCompany: state.auth.user.activeCompany,
    securityMenus: state.locale.securityMenus
  }));

  return (
    <Box py={4}>
      <Box component="div" overflow="auto">
        <Table>
          <StyledTableHeadComponent>
            <StyledTableRowComponent>
              {columnData.map(column => (
                <StyledTableCellTextComponent key={column.id} padding="none" >
                  <TableSortLabel
                    active={props.orderBy === column.id}
                    direction={props.order}
                    onClick={() => props.handleSortOrderChange(column.id)}
                  >
                    <IntlMessageComponent id={column.translationKey} />
                  </TableSortLabel>
                </StyledTableCellTextComponent>
              ))}
              <StyledTableCellTextComponent padding="none" />
            </StyledTableRowComponent>
          </StyledTableHeadComponent>

          <TableBody bgcolor="white">
            {!props.offices.length && (
              <StyledTableRowComponent>
                <StyledTableCellTextComponent colSpan={columnData.length + 1}>
                  <Box textAlign="center">
                    <IntlMessageComponent id="component.offices.list.noData" />
                  </Box>
                </StyledTableCellTextComponent>
              </StyledTableRowComponent>
            )}

            {props.offices.slice(props.page * props.pageSize, (props.page * props.pageSize) + props.pageSize).map(office => (
              <StyledTableRowComponent key={`office-${office.id}`}>
                <StyledTableCellTextComponent padding="none">
                  <b>{office.name}</b>
                </StyledTableCellTextComponent>

                <StyledTableCellTextComponent padding="none">
                  {office.city}
                </StyledTableCellTextComponent>

                <StyledTableCellTextComponent padding="none">
                  {office.zipCode}
                </StyledTableCellTextComponent>

                <StyledTableCellTextComponent padding="none">
                  <CountryNameComponent countryId={office.countryId} />
                </StyledTableCellTextComponent>
                {hasSecurityMenuAccess("POS.Offices", "btnEdit", activeCompany, securityMenus) &&
                  <StyledTableCellTextComponent padding="none">
                    {office.hasActivePersons && office.isActive ?
                      <Tooltip title={<IntlMessageComponent id="company.office.tooltip.hasactivepersons" />}>
                        <div style={{ display: "inline-block" }}>
                          <Switch checked={office.isActive} onClick={() => { }} />
                        </div>
                      </Tooltip> :
                      <Tooltip title={<IntlMessageComponent id="company.ubos.tooltip.disable" />}>
                        <Switch checked={office.isActive} disabled={props.isProcessing} onChange={() => props.toggleOfficeActiveStatus(office)} />
                      </Tooltip>
                    }
                  </StyledTableCellTextComponent>
                }
                {!hasSecurityMenuAccess("POS.Offices", "btnEdit", activeCompany, securityMenus) &&
                  <StyledTableCellTextComponent padding="none">
                    <BooleanFormatterComponent value={office.isActive} />
                  </StyledTableCellTextComponent>
                }
                <StyledTableCellTextComponent padding="none">
                  <SecurityComponent pageName="POS.Offices" elementName="btnEdit">
                    <Tooltip title={<IntlMessageComponent id="company.ubos.tooltip.edit" />}>
                      <IconButton onClick={() => props.editOffice(office)}>
                        <EditOutlined />
                      </IconButton>
                    </Tooltip>
                  </SecurityComponent>
                </StyledTableCellTextComponent>
              </StyledTableRowComponent>
            ))}
          </TableBody>
        </Table>
      </Box>

      <Box display="flex" height={60}>
        <Box flexGrow={1} mt={2}>
          <SecurityComponent pageName="POS.Offices" elementName="btnNew">
            <ButtonComponent
              color="primary"
              variant="contained"
              onClick={props.newOffice}
            ><IntlMessageComponent id="component.offices.list.button.addOffice" /></ButtonComponent>
          </SecurityComponent>
        </Box>

        {props.offices.length > 10 &&
          <Box justifyContent="flex-end">
            <TablePagination
              component="div"
              count={props.offices.length}
              page={props.page}
              rowsPerPage={props.pageSize}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePageSizeChange}
              labelRowsPerPage={<IntlMessageComponent id="general.officesPerPage" />}
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
            />
          </Box>
        }
      </Box>
    </Box>
  );
}
