/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';


type SignUpTab4TokenExpiredViewComponentProps = {
  email: string;
  isProcessing: boolean;
  step: number;
  resendConfirmation: () => void;
}

export default function SignUpTab4TokenExpiredViewComponent(props: SignUpTab4TokenExpiredViewComponentProps) {


  return (
    <React.Fragment>
      <PageSubTitleViewComponent id="page.signup.tab4.tokenExpired.title"/>

      {props.step === 0 ? (
        <React.Fragment>
          <Typography variant="body1" gutterBottom>
            <IntlMessageComponent id="page.signup.tab4.tokenExpired.text" values={{email: <Box component="span" color="primary.main">{props.email}</Box>}} />
          </Typography>

          <Box mt={3}>
            <ButtonComponent
              color="primary"
              variant="contained"
              isProcessing={props.isProcessing}
              onClick={props.resendConfirmation}
            ><IntlMessageComponent id="page.signup.tab4.tokenExpired.btn.send" /></ButtonComponent>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography variant="body1" gutterBottom>
            <IntlMessageComponent id="page.signup.tab4.tokenExpired.text.success" values={{email: <Box component="span" color="primary.main">{props.email}</Box>}} />
          </Typography>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}