/* @flow */

import UserCompany from './userCompany';

export default class User {
    userName: string;
    lastname: string;
    firstname: string;

    activeCompany: UserCompany;
    companies: UserCompany[];

    isImpersonating: boolean;
    originalUsername: ?string;

    firstLogin: boolean;

    constructor() {
      this.companies = [];
    }
}
