/* @flow */

import React from 'react';
import {Route, Switch, useRouteMatch} from 'react-router-dom';

import Error404Page from 'components/errors/error404';

import OgonePaymentAcceptedPage from './accept';
import OgonePaymentCancelledPage from './cancel';
import OgonePaymentDeclinedPage from './decline';
import OgonePaymentFailedPage from './except';

export default function OgonePaymentConfirmationPage() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.url}/accept`} component={OgonePaymentAcceptedPage} />
      <Route exact path={`${match.url}/cancel`} component={OgonePaymentCancelledPage} />
      <Route exact path={`${match.url}/decline`} component={OgonePaymentDeclinedPage} />
      <Route exact path={`${match.url}/except`} component={OgonePaymentFailedPage} />
      <Route component={Error404Page} />
    </Switch>
  );
}