/* @flow */

import React from 'react';
import { useHistory } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import PhoneNumberComponent from 'components/formFields/phoneNumber';
import ButtonComponent from 'components/buttons/button';
import CompanyLookupSellerComponent from 'components/companyLookupV2/sellers';
import FormErrorsComponent from 'components/formErrors';
import IntlMessageComponent from 'components/formatters/intlMessage';
import MandatoryFormFieldsComponent from 'components/form/mandatory';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import TitleFieldComponent from 'components/formFields/types/title';
import CompanyLookupForm from 'models/companies/companyLookupForm';

import ContactForm from './model';

import {
  UNKNOWN_COMPANY_OFFER
} from 'constants/pageRoutes';

type ContactViewComponentProps = {
  errors: Map<string,?string>;
  form: ContactForm;
  formErrors: string[];
  isProcessing: boolean;
  contactTimeSelected: boolean;
  companyLookUpForm: CompanyLookupForm;
  handleChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void;
  handleCompanyChange: () => void;
  handleTypeChange: (fieldName: string) => (id: number) => void;
  handleMobileChange: (value: string) => void;
  submitForm: () => void;
  companyNotFound: () => void;
}

export default function ContactViewComponent(props: ContactViewComponentProps) {
  const history = useHistory();

  const unknownCompanyButton = () => history.push({ pathname: UNKNOWN_COMPANY_OFFER, state: { data: props.form, companyLookUpForm: props.companyLookUpForm } });

  return (
    <React.Fragment>
      <FormErrorsComponent formErrors={props.formErrors} />


      <div className='hiddenMobile'>
        <Typography variant="body1" gutterBottom>
          <IntlMessageComponent id="component.contact.intro" />
        </Typography>
        <Box mt={3}>
          <Grid container spacing={6} alignItems="stretch">
            <Grid item xs={12} md={6}>
              <PageSubTitleViewComponent
                id="component.offer.tab1.company.title"
                data-walkme="offerlead-title-informationsprofessionnelles"
              />

              <Box mt={3}>
                <CompanyLookupSellerComponent
                  company={props.form.company}
                  companyErrorLabelId={props.errors.get('company')}
                  handleSelect={props.handleCompanyChange}
                  companyNotFound={props.companyNotFound}
                  companyLookUpForm={props.companyLookUpForm}
                  required
                />
              </Box>

              <Box mt={5}>
                <FormControl component="fieldset" fullWidth required>
                  <PageSubTitleViewComponent
                    id="page.contact.time"
                  />

                  <RadioGroup
                    onChange={props.handleChange('contactTime')}
                    value={props.form.contactTime}
                    error={!!props.errors.get('contactTime')}
                    helperText={(props.errors.get('contactTime'))
                      ? <IntlMessageComponent id={props.errors.get('contactTime')} />
                      : <IntlMessageComponent id="form.contactTime.helperText" />}
                  >
                    <FormControlLabel
                      control={<Radio color="primary" />}
                      label={<IntlMessageComponent id="component.contact.time.morning" />}
                      value="Morning (8-12)"
                    />
                    <FormControlLabel
                      control={<Radio color="primary" />}
                      label={<IntlMessageComponent id="component.contact.time.lunch" />}
                      value="Lunch (12-14)"
                    />
                    <FormControlLabel
                      control={<Radio color="primary" />}
                      label={<IntlMessageComponent id="component.contact.time.afternoon" />}
                      value="Afternoon (14-18)"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box>
                <TextField
                  id="form.comment"
                  label={<IntlMessageComponent id="form.comment.label" />}
                  error={!!props.errors.get('comment')}
                  helperText={(props.errors.get('comment')) ?
                    <IntlMessageComponent id={props.errors.get('comment')} /> : ''}
                  value={props.form.comment}
                  onChange={props.handleChange('comment')}
                  margin="normal"
                  fullWidth
                  multiline
                  maxRows={5}
                  minRows={5}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <PageSubTitleViewComponent
                id="component.offer.tab1.person.title"
                data-walkme="offerlead-title-informationspersonnelles"
              />

              <Box mt={3} px={4} py={3}>
                <TitleFieldComponent
                  label={<IntlMessageComponent id="form.title.label" />}
                  error={!!props.errors.get('titleId')}
                  helperText={(props.errors.get('titleId')) ?
                    <IntlMessageComponent id={props.errors.get('titleId')} /> : ''}
                  value={props.form.titleId}
                  changeHandler={props.handleTypeChange('titleId')}
                  fullWidth
                  margin="normal"
                  required
                />

                <TextField
                  id="form.name"
                  data-walkme="subscription-field-nom"
                  label={<IntlMessageComponent id="form.name.label" />}
                  error={!!props.errors.get('lastName')}
                  helperText={(props.errors.get('lastName')) ?
                    <IntlMessageComponent id={props.errors.get('lastName')} /> : ''}
                  value={props.form.lastName}
                  onChange={props.handleChange('lastName')}
                  fullWidth
                  margin="normal"
                  required
                />

                <TextField
                  id="form.firstName"
                  data-walkme="subscription-field-prenom"
                  label={<IntlMessageComponent id="form.firstname.label" />}
                  error={!!props.errors.get('firstName')}
                  helperText={(props.errors.get('firstName')) ?
                    <IntlMessageComponent id={props.errors.get('firstName')} /> : ''}
                  value={props.form.firstName}
                  onChange={props.handleChange('firstName')}
                  fullWidth
                  margin="normal"
                  required
                />

                <TextField
                  id="form.email"
                  label={<IntlMessageComponent id="form.email.label" />}
                  error={!!props.errors.get('email')}
                  helperText={(props.errors.get('email')) ?
                    <IntlMessageComponent id={props.errors.get('email')} /> : ''}
                  value={props.form.email}
                  onChange={props.handleChange('email')}
                  fullWidth
                  margin="normal"
                  required
                />

                <PhoneNumberComponent
                  id="form.mobilePresence"
                  label={<IntlMessageComponent id="form.mobile.label" />}
                  error={!!props.errors.get('mobilePresence')}
                  helperText={(props.errors.get('mobilePresence'))
                    ? <IntlMessageComponent id={props.errors.get('mobilePresence')} />
                    : <IntlMessageComponent id="form.mobile.alreadySubscribed.helperText" />}
                  value={props.form.mobilePresence}
                  onChange={props.handleMobileChange}
                  fullWidth
                  margin="normal"
                  required
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>
      <div className="hiddenDesktop">
        <Typography variant="body1" gutterBottom>
          <IntlMessageComponent id="component.contact.intromobile" />
        </Typography>
        <Box mt={3}>
          <Grid container spacing={6} alignItems="stretch">
            <Grid item xs={12} md={6}>
              <PageSubTitleViewComponent
                id="component.offer.tab1.company.title"
                data-walkme="offerlead-title-informationsprofessionnelles"
              />

              <Box mt={3}>
                <CompanyLookupSellerComponent
                  company={props.form.company}
                  companyErrorLabelId={props.errors.get('company')}
                  handleSelect={props.handleCompanyChange}
                  companyNotFound={props.companyNotFound}
                  companyLookUpForm={props.companyLookUpForm}
                  required
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <PageSubTitleViewComponent
                id="component.offer.tab1.person.title"
                data-walkme="offerlead-title-informationspersonnelles"
              />

              <Box mt={3} px={4} py={3}>
                <TitleFieldComponent
                  label={<IntlMessageComponent id="form.title.label" />}
                  error={!!props.errors.get('titleId')}
                  helperText={(props.errors.get('titleId')) ?
                    <IntlMessageComponent id={props.errors.get('titleId')} /> : ''}
                  value={props.form.titleId}
                  changeHandler={props.handleTypeChange('titleId')}
                  fullWidth
                  margin="normal"
                  required
                />

                <TextField
                  id="form.name"
                  data-walkme="subscription-field-nom"
                  label={<IntlMessageComponent id="form.name.label" />}
                  error={!!props.errors.get('lastName')}
                  helperText={(props.errors.get('lastName')) ?
                    <IntlMessageComponent id={props.errors.get('lastName')} /> : ''}
                  value={props.form.lastName}
                  onChange={props.handleChange('lastName')}
                  fullWidth
                  margin="normal"
                  required
                />

                <TextField
                  id="form.firstName"
                  data-walkme="subscription-field-prenom"
                  label={<IntlMessageComponent id="form.firstname.label" />}
                  error={!!props.errors.get('firstName')}
                  helperText={(props.errors.get('firstName')) ?
                    <IntlMessageComponent id={props.errors.get('firstName')} /> : ''}
                  value={props.form.firstName}
                  onChange={props.handleChange('firstName')}
                  fullWidth
                  margin="normal"
                  required
                />

                <TextField
                  id="form.email"
                  label={<IntlMessageComponent id="form.email.label" />}
                  error={!!props.errors.get('email')}
                  helperText={(props.errors.get('email')) ?
                    <IntlMessageComponent id={props.errors.get('email')} /> : ''}
                  value={props.form.email}
                  onChange={props.handleChange('email')}
                  fullWidth
                  margin="normal"
                  required
                />

                <PhoneNumberComponent
                  id="form.mobilePresence"
                  label={<IntlMessageComponent id="form.mobile.label" />}
                  error={!!props.errors.get('mobilePresence')}
                  helperText={(props.errors.get('mobilePresence'))
                    ? <IntlMessageComponent id={props.errors.get('mobilePresence')} />
                    : <IntlMessageComponent id="form.mobile.helperText" />}
                  value={props.form.mobilePresence}
                  onChange={props.handleMobileChange}
                  fullWidth
                  margin="normal"
                  required
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box mt={5}>
          <FormControl component="fieldset" fullWidth required>
            <PageSubTitleViewComponent
              id="page.contact.time"
            />

            <RadioGroup
              onChange={props.handleChange('contactTime')}
              value={props.form.contactTime}
              error={!!props.errors.get('contactTime')}
              helperText={(props.errors.get('contactTime'))
                ? <IntlMessageComponent id={props.errors.get('contactTime')} />
                : <IntlMessageComponent id="form.contactTime.helperText" />}
            >
              <FormControlLabel
                control={<Radio color="primary" />}
                label={<IntlMessageComponent id="component.contact.time.morning" />}
                value="Morning (8-12)"
              />
              <FormControlLabel
                control={<Radio color="primary" />}
                label={<IntlMessageComponent id="component.contact.time.lunch" />}
                value="Lunch (12-14)"
              />
              <FormControlLabel
                control={<Radio color="primary" />}
                label={<IntlMessageComponent id="component.contact.time.afternoon" />}
                value="Afternoon (14-18)"
              />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box>
          <Grid container spacing={6} alignItems="stretch">
            <Grid item xs={12} md={6}>
              <TextField
                id="form.comment"
                label={<IntlMessageComponent id="form.comment.label" />}
                error={!!props.errors.get('comment')}
                helperText={(props.errors.get('comment')) ?
                  <IntlMessageComponent id={props.errors.get('comment')} /> : ''}
                value={props.form.comment}
                onChange={props.handleChange('comment')}
                margin="normal"
                fullWidth
                multiline
                maxRows={5}
                minRows={5}
              />
            </Grid>
          </Grid>
        </Box>
      </div>
      <Box mt={5}>
        <ButtonComponent
          color="primary"
          variant="contained"
          isProcessing={props.isProcessing}
          disabled={props.contactTimeSelected}
          onClick={props.submitForm}
        ><IntlMessageComponent id="general.button.validate.label" /></ButtonComponent>

        <MandatoryFormFieldsComponent />
      </Box>
    </React.Fragment>
  );
}