/* @flow */

import React, {useState} from 'react';
import moment from 'moment';

import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import CustomPeriodDialogComponent from 'components/customPeriodDialog';
import DateFormatterComponent from 'components/formatters/date';
import IntlMessageComponent from 'components/formatters/intlMessage';

import {
  PERIOD_CUSTOM,
  PERIOD_CURRENT_YEAR,
  PERIOD_PREVIOUS_QUARTER,
  PERIOD_CURRENT_QUARTER,
  PERIOD_LIFETIME
} from 'constants/periods';

type BuyerInvoicePeriodFilterComponentProps = {
  dateFrom: moment;
  dateTo: moment;
  disabled: boolean;
  fullWidth: boolean;
  margin: string;
  changeHandler: (dateFrom: moment, dateTo: moment) => void;
}

export default function BuyerInvoicePeriodFilterComponent(props: BuyerInvoicePeriodFilterComponentProps) {

  const [showPopUp, setShowPopUp] = useState(false);  

  const periods = [
    {id: PERIOD_CURRENT_YEAR, dateFrom: moment().startOf('year'), dateTo: moment()}, 
    {id: PERIOD_PREVIOUS_QUARTER, dateFrom: moment().startOf('quarter').subtract(1, 'quarter'), dateTo: moment().startOf('quarter').subtract(1, 'day')},
    {id: PERIOD_CURRENT_QUARTER, dateFrom: moment().startOf('quarter'), dateTo: moment()},
    {id: PERIOD_LIFETIME, dateFrom: null, dateTo: null},
    {id: PERIOD_CUSTOM, dateFrom: null, dateTo: null}
  ];
  
  const handleSelect = (event) => {
    const period = periods.find(p => p.id === event.target.value);
    if(period.id !== PERIOD_CUSTOM) {
      props.changeHandler(period.dateFrom, period.dateTo);
    } else {
      setShowPopUp(true);
    }
  };

  const computePeriod = (dateFrom: moment, dateTo: moment) => {
    if(dateFrom === null && dateTo === null) return PERIOD_LIFETIME;
    let period = periods.find(p => p.dateFrom !== null && p.dateTo !== null && p.dateFrom.isSame(dateFrom, 'day') && p.dateTo.isSame(dateTo, 'day'));
    if(period) return period.id;

    period = periods.find(p => p.id === PERIOD_CUSTOM);
    period.dateFrom = dateFrom;
    period.dateTo = dateTo;
    return period.id;
  };

  const handlePopUpConfirmation = (dateFrom: moment, dateTo: moment) => {
    setShowPopUp(false);
    props.changeHandler(dateFrom, dateTo)
  }
 
  const value = computePeriod(props.dateFrom, props.dateTo);

  return (
    <React.Fragment>
      <TextField
        id="form-periods"
        disabled={props.disabled}
        select
        value={value}
        onChange={handleSelect}
        fullWidth={props.fullWidth}
        margin={props.margin}
      >
        {periods.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            <IntlMessageComponent id={`general.period.${option.id}`}/>
            {option.dateFrom !== null && option.dateTo !== null &&
              <small>
                &nbsp;(<DateFormatterComponent date={option.dateFrom} />&nbsp;-&nbsp;<DateFormatterComponent date={option.dateTo} />)
              </small>
            }
          </MenuItem>
        ))}
      </TextField>

      {showPopUp &&
        <CustomPeriodDialogComponent
          isOpen={true}
          dateFrom={props.dateFrom}
          dateTo={props.dateTo}
          handleChange={handlePopUpConfirmation}
          handleClose={() => setShowPopUp(false)}
        />
      }
    </React.Fragment>
  );
}
