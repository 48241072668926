/* @flow */

import React from 'react';

import LoadingComponent from 'components/loading';

import MobileConfirmationViewComponent from './view';
import MobileConfirmationForm from './model';

import UserService from 'services/UserService';
import {handleApiFormResponse, handleFormChange, handleFormValidation} from 'lib/forms';
import {validateField} from 'lib/validation/validator';
import loadConstraints from 'lib/validation/loadConstraints';

type MobileConfirmationComponentProps = {
  mobile: string;
  successHandler: (mobile: string) => void;
  disableSignButton: () => void;
}
type MobileConfirmationComponentState = {
  constraints: any;
  errors: Map<string, ?string>;
  form: MobileConfirmationForm;
  formErrors: string[];
  isLoading: boolean;
  isProcessing: boolean;
}

class MobileConfirmationComponent extends React.Component<MobileConfirmationComponentProps, MobileConfirmationComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      constraints: null,
      errors: null,
      form: null,
      formErrors: [],
      isLoading: true,
      isProcessing: false
    };
  }

  componentDidMount = async () => {
    try {
      const constraints = await loadConstraints('Person', ['mobile']);
      this.setState({
        constraints,
        errors: new Map(Object.keys(constraints).map(e => [e, undefined])),
        form: new MobileConfirmationForm(this.props.mobile),
        isLoading: false
      });
    } catch (e) {
      console.error(e);
    }
  }

  handleChange = (fieldName: string) => (event) => handleFormChange.call(this, fieldName, event.target.value);
  handleMobileChange = (value: string) => {
    handleFormChange.call(this, 'mobile', value);
  }
  validateForm = () => handleFormValidation.call(this);

  submitForm = async () => {
    if (!this.validateForm()) return;
    this.setState({formErrors: [], isProcessing: true});

    try {
      const request = this.state.form.toRequest();
      await UserService.updateMobile(request);
      this.props.successHandler(this.state.form.mobile);
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }
  }

  isValid = () => {
    if (this.state.form.mobile !== this.props.mobile) {
      this.props.disableSignButton();
      const validationResults = validateField(this.state.form.mobile, this.state.constraints.mobile);
      if (validationResults === undefined || !Object.keys(validationResults).length) {
        return true;
      }
    }
    return false;
  }

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <MobileConfirmationViewComponent
        errors={this.state.errors}
        form={this.state.form}
        formErrors={this.state.formErrors}
        isProcessing={this.state.isProcessing}
        mobile={this.props.mobile}
        handleChange={this.handleChange}
        handleMobileChange={this.handleMobileChange}
        isValid={this.isValid}
        submitForm={this.submitForm}
      />
    );
  }
}

export default MobileConfirmationComponent;
