/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';

import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

type InvalidLeadOfferDetailsComponentProps = {
  isProcessing: boolean;
  newSimulationWithSameDebtor: () => void;
  newSimulationWithOtherDebtor: () => void;
}

export default function InvalidLeadOfferDetailsComponent(props: InvalidLeadOfferDetailsComponentProps) {

  return (
    <React.Fragment>
      <PageSubTitleViewComponent id="component.offer.tab4.invalid.title" />

      <Box mt={3} p={3} bgcolor="lightblue.main">
        <IntlMessageComponent id="component.offer.tab4.invalid.text" />
        
        <Box mt={5}>
          <ButtonComponent
            color="primary"
            variant="contained"
            isProcessing={props.isProcessing}
            onClick={props.newSimulationWithSameDebtor}
            data-walkme="getoffer-invalid-button-samedebtor"
          ><IntlMessageComponent id="component.offer.tab4.button.restartSameDebtor" /></ButtonComponent>

          <Box component="span" ml={4}>
            <ButtonComponent
              color="primary"
              variant="contained"
              isProcessing={props.isProcessing}
              onClick={props.newSimulationWithOtherDebtor}
              data-walkme="getoffer-invalid-button-differentdebtor"
            ><IntlMessageComponent id="component.offer.tab4.button.restartDifferentDebtor" /></ButtonComponent>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}
