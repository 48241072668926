/* @flow */

import React from 'react';
import { useSelector } from 'react-redux';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';

import CountryNameComponent from 'components/country/name';
import IntlMessageComponent from 'components/formatters/intlMessage';

import CompanyLookupBaseComponent from '../base';
import CompanyLookupBaseWithButtonComponent from '../base/button';
import SecurityComponent from 'components/security/index';
import ButtonComponent from 'components/buttons/button';

import SupplierCompany from 'models/companies/company';

type CompanyLookupSupplierViewComponentProps = {
  companyErrorLabelId: string;
  countriesForSuppliers: Country[];
  knownSuppliers: SupplierCompany[];
  required: boolean;
  handleKnownSupplierCompanySelect: (company: SupplierCompany) => void;
  handleSelect: (company: SupplierCompany) => void;
  redirectToAddSupplier: () => void;
}

export default function CompanyLookupSupplierViewComponent(props: CompanyLookupSupplierViewComponentProps) {
  const { user } = useSelector(state => ({
    user: state.auth.user
  }));
  return (
    <React.Fragment>
      {props.knownSuppliers && props.knownSuppliers.length > 0 ? (
        <React.Fragment>
          <Box px={4} py={3}>
            <Autocomplete
              getOptionLabel={(option) => option.name}
              onChange={(_, newValue) => props.handleKnownSupplierCompanySelect(newValue)}
              openOnFocus
              options={props.knownSuppliers}
              renderInput={(params) =>
                <TextField
                  {...params}
                  id="select-Supplier"
                  label={<IntlMessageComponent id="component.knownSuppliers.label" />}
                  fullWidth
                  margin="normal"
                />
              }
              renderOption={(company) => (
                <span>{company.name} - <CountryNameComponent countryId={company.countryId} /> [{company.vatNumber}]</span>
              )}
              value={null}
            />
          </Box>

          <ButtonComponent
            color="primary"
            variant="contained"
            onClick={props.redirectToAddSupplier}
          ><IntlMessageComponent id={props.buttonLabel ? props.buttonLabel : 'component.companyLookup.btn.newSupplier'} /></ButtonComponent>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <ButtonComponent
            color="primary"
            variant="contained"
            onClick={props.redirectToAddSupplier}
          ><IntlMessageComponent id={props.buttonLabel ? props.buttonLabel : 'component.companyLookup.btn.newSupplier'} /></ButtonComponent>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
