/* @flow */

import {connect} from 'react-redux';
import TranslationComponent from './translation';

const mapStateToProps = (state, props) => ({
  ...props,
  isLoading: state.locale.genericDataIsLoading,
  values: state.locale.billTypes,
});

export default connect(mapStateToProps)(TranslationComponent);
