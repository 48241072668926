/* @flow */

import React from 'react';
import {useSelector} from 'react-redux';

import Grid from '@material-ui/core/Grid';

import DateFormatterComponent from 'components/formatters/date';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import CompanyMembershipCouponFormComponent from '../coupon';

import CompanyMembership from 'models/companies/companyMembership';

type CompanyMembershipCardViewComponentProps = {
  membership: CompanyMembership;
  reloadMemberships: () => void;
}

export default function CompanyMembershipCardViewComponent(props: CompanyMembershipCardViewComponentProps) {
  const activeCompany = useSelector(state => state.auth.user.activeCompany);

  return (
    <React.Fragment>
      {props.membership.isValid && props.membership.isPaid && <PageSubTitleViewComponent id="page.membership.active.title" />}
      {props.membership.isValid && !props.membership.isPaid && <PageSubTitleViewComponent id="page.membership.topay.title" />}
      {!props.membership.isValid && <PageSubTitleViewComponent id="page.membership.expired.title" />}
      
      <Grid container spacing={1} alignItems="stretch">
        <Grid item xs={6}>
          <b><IntlMessageComponent id="page.membership.enterprise.label" /></b>
        </Grid>
        <Grid item xs={6}>
          {activeCompany.name}
        </Grid>
      </Grid>

      {props.membership.paymentInfo &&
        <Grid container spacing={1} alignItems="stretch">
          <Grid item xs={6}>
            <b><IntlMessageComponent id="page.membership.from.label" /></b>
          </Grid>
          <Grid item xs={6}>
            <DateFormatterComponent date={props.membership.validFrom} />
          </Grid>
        </Grid>
      }
          
      <Grid container spacing={1} alignItems="stretch">
        <Grid item xs={6}>
          <b><IntlMessageComponent id="page.membership.until.label" data-walkme="abonnement-text-expirele" /></b>
        </Grid>
        <Grid item xs={6}>
          <DateFormatterComponent date={props.membership.validTo} />
        </Grid>
      </Grid>

      <Grid container spacing={1} alignItems="stretch">
        <Grid item xs={6}>
          <b><IntlMessageComponent id="page.membership.status" /></b>
        </Grid>
        <Grid item xs={6}>
          {props.membership.isValid && props.membership.isPaid && <IntlMessageComponent id="page.membership.active" />}
          {props.membership.isValid && !props.membership.isPaid && <IntlMessageComponent id="page.membership.topay" />}
          {!props.membership.isValid && <IntlMessageComponent id="page.membership.expired" />}
        </Grid>
      </Grid>

      {activeCompany.hasSignedGeneralConditionsAndHasProcuration && !props.membership.hasCoupon && props.membership.paymentInfo &&
        <CompanyMembershipCouponFormComponent successHandler={props.reloadMemberships} />
      }
    </React.Fragment>
  );
}
