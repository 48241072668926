/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import IconDownload from '@material-ui/icons/CloudDownload';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import AmountFormatterComponent from 'components/formatters/amount';
import ButtonComponent from 'components/buttons/button';
import DateFormatterComponent from 'components/formatters/date';
import IntlMessageComponent from 'components/formatters/intlMessage';
import InvoiceNameComponent from 'components/invoice/name';
import NumberFormatterComponent from 'components/formatters/number';
import StyledTableCellTextComponent from 'components/table/cellText';
import StyledTableCellNumbersComponent from 'components/table/cellNumbers';
import StyledTableHeadComponent from 'components/table/head';
import StyledTableRowComponent from 'components/table/row';

const columnData = [
  {id: 'debtorReference', align:'left', translationKey: 'component.debtor.invoices.table.header.debtorReference', sortable: true},
  {id: 'sellerCompany', align:'left', translationKey: 'component.debtor.invoices.table.header.sellerCompany', sortable: true},
  {id: 'reference', align:'left', translationKey: 'component.debtor.invoices.table.header.reference', sortable: true},
  {id: 'debtAmount', translationKey: 'component.debtor.invoices.table.header.amount', sortable: true},
  {id: 'issueDate', align:'left', translationKey: 'component.debtor.invoices.table.header.issueDate', sortable: true},
  {id: 'dueDate', align:'left', translationKey: 'component.debtor.invoices.table.header.dueDate', sortable: true},
  {id: 'daysToDueDate', translationKey: 'component.debtor.invoices.table.header.daysToDueDate', sortable: true},
  {id: 'validationAge', translationKey: 'component.debtor.invoices.table.header.validationAge', sortable: true},
  {id: 'id', align:'left', translationKey: 'component.debtor.invoices.table.header.id', sortable: true},
  {id: 'invoiceDocument', align:'left', translationKey: 'component.debtor.invoices.table.header.invoiceDocument', sortable: false},
  {id: 'relatedDocuments', align:'left', translationKey: 'component.debtor.invoices.table.header.relatedDocuments', sortable: false},
];

type DebtorInvoicesToApproveTableViewComponentProps = {
  invoices: Invoice[];
  isProcessing: boolean;
  order: string;
  orderBy: string;
  page: number;
  pageSize: number;
  downloadInvoiceDocument: (invoiceId: number) => void;
  downloadInvoiceRelatedDocuments: (invoiceId: number) => void;
  handleInvoiceSelect: (invoiceId: number) => void;
  handlePageChange: (page: number) => void;
  handlePageSizeChange: (pageSize: number) => void;
  handleSortOrderChange: (property: string) => void;
  refuseInvoice: (invoiceId: number) => void;
};

export default function DebtorInvoicesToApproveTableViewComponent(props: DebtorInvoicesToApproveTableViewComponentProps) {
  const handlePageChange = (_, page) => props.handlePageChange(page);
  const handlePageSizeChange = event => props.handlePageSizeChange(event.target.value);

  const handleClickCheckbox = (event, invoiceId: number) => {
    event.stopPropagation();
    if (props.isProcessing) return;
    props.handleInvoiceSelect(invoiceId);
  };

  const handleClickDownloadInvoiceDocument = (event, invoiceId: number) => {
    event.stopPropagation();
    if (props.isProcessing) return;
    props.downloadInvoiceDocument(invoiceId);
  };

  const handleClickDownloadRelatedDocuments = (event, invoiceId: number) => {
    event.stopPropagation();
    if (props.isProcessing) return;
    props.downloadInvoiceRelatedDocuments(invoiceId);
  };

  const handleClickRefuse = (event, invoiceId: number) => {
    event.stopPropagation();
    if (props.isProcessing) return;
    props.refuseInvoice(invoiceId);
  };

  return (
    <React.Fragment>
      <Box component="div" overflow="auto">
        <Table>
          <StyledTableHeadComponent>
            <StyledTableRowComponent>
              <StyledTableCellTextComponent padding="checkbox" />

              {columnData.map(column => (
                <StyledTableCellTextComponent align={column?.align ?? 'right'} key={column.id} padding="none">
                  {column.sortable ? (
                    <TableSortLabel
                      active={props.orderBy === column.id}
                      direction={props.order}
                      onClick={() => props.handleSortOrderChange(column.id)}
                    >
                      <IntlMessageComponent id={column.translationKey} />
                    </TableSortLabel>
                  ) : (
                    <IntlMessageComponent id={column.translationKey} />
                  )}
                </StyledTableCellTextComponent>
              ))}

              <StyledTableCellTextComponent padding="none" />
            </StyledTableRowComponent>
          </StyledTableHeadComponent>
        
          <TableBody bgcolor="white">
            {props.invoices.length === 0 &&
              <StyledTableRowComponent>
                <StyledTableCellTextComponent colSpan={columnData.length+2}>
                  <Box textAlign="center">
                    <IntlMessageComponent id="component.debtor.invoices.table.noData" />
                  </Box>
                </StyledTableCellTextComponent>
              </StyledTableRowComponent>
            }

            {props.invoices.slice(props.page * props.pageSize, (props.page * props.pageSize) + props.pageSize).map(i => (
              <StyledTableRowComponent hover onClick={event => handleClickCheckbox(event, i.id)} key={`invList-${i.id}`} style={{cursor: 'pointer'}}>
                <StyledTableCellTextComponent padding="checkbox" onClick={event => handleClickCheckbox(event, i.id)}>
                  <Checkbox color="primary" checked={i.isSelected} disabled={props.isProcessing} />
                </StyledTableCellTextComponent>
                
                <StyledTableCellTextComponent padding="none">
                  {i.debtorReference}
                </StyledTableCellTextComponent>

                <StyledTableCellTextComponent padding="none">
                  {i.sellerCompany.name}
                </StyledTableCellTextComponent>

                <StyledTableCellTextComponent padding="none">
                  {i.reference}
                </StyledTableCellTextComponent>

                <StyledTableCellNumbersComponent padding="none">
                  <AmountFormatterComponent amount={i.debtAmount} />
                </StyledTableCellNumbersComponent>

                <StyledTableCellTextComponent padding="none">
                  <DateFormatterComponent date={i.issueDate} />
                </StyledTableCellTextComponent>

                <StyledTableCellTextComponent padding="none">
                  <DateFormatterComponent date={i.dueDate} />
                </StyledTableCellTextComponent>

                <StyledTableCellNumbersComponent padding="none">
                  <NumberFormatterComponent value={i.daysToDueDate} />
                </StyledTableCellNumbersComponent>

                <StyledTableCellNumbersComponent padding="none">
                  <NumberFormatterComponent value={i.validationAge} />
                </StyledTableCellNumbersComponent>

                <StyledTableCellTextComponent padding="none">
                  <InvoiceNameComponent invoiceId={i.id} />
                </StyledTableCellTextComponent>

                <StyledTableCellTextComponent padding="none">
                  {i.invoiceDocumentId > 0 &&
                    <IconButton disabled={props.isProcessing} onClick={event => handleClickDownloadInvoiceDocument(event, i.id)}>
                      <IconDownload />
                    </IconButton>
                  }
                </StyledTableCellTextComponent>

                <StyledTableCellTextComponent padding="none">
                  {i.hasRelatedDocuments &&
                    <IconButton disabled={props.isProcessing} onClick={event => handleClickDownloadRelatedDocuments(event, i.id)}>
                      <IconDownload />
                    </IconButton>
                  }
                </StyledTableCellTextComponent>

                <StyledTableCellTextComponent padding="none">
                  <ButtonComponent
                    color="tertiary"
                    variant="contained"
                    isProcessing={props.isProcessing}
                    onClick={event => handleClickRefuse(event, i.id)}
                  ><IntlMessageComponent id="general.refuse" /></ButtonComponent>
                </StyledTableCellTextComponent>
              </StyledTableRowComponent>
            ))}
          </TableBody>
        </Table>
      </Box>

      <Box display="flex" height={60}>
        <Box flexGrow={1} mt={2}></Box>
        <Box justifyContent="flex-end">
          <TablePagination
            component="div"
            count={props.invoices.length}
            rowsPerPage={props.pageSize}
            page={props.page}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePageSizeChange}
            labelRowsPerPage={<IntlMessageComponent id="general.invoicePerPage" />}
            labelDisplayedRows={({from, to, count}) => `${from}-${to} / ${count}`}
          />
        </Box>
      </Box>
    </React.Fragment>
  );
}
