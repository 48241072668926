/* @flow */

import React from 'react';
import {Link} from 'react-router-dom';

import Box from '@material-ui/core/Box';

import ButtonComponent from 'components/buttons/button';
import FormErrorsComponent from 'components/formErrors';
import IntlMessageComponent from 'components/formatters/intlMessage';

import {SIGN_IN} from 'constants/pageRoutes';

type LostPasswordFormViewFailComponentProps = {
  formErrors: string[];
  resetForm: () => void;
}

export default function LostPasswordFormViewFailComponent(props: LostPasswordFormViewFailComponentProps) {
  return (
    <React.Fragment>
      <FormErrorsComponent formErrors={props.formErrors} />

      <Box mt={5}>
        <ButtonComponent
          color="default"
          variant="contained"
          component={Link}
          to={SIGN_IN}
        ><IntlMessageComponent id="general.back" /></ButtonComponent>

        <Box component="span" ml={1}>
          <ButtonComponent
            color="primary"
            variant="contained"
            onClick={props.resetForm}
          ><IntlMessageComponent id="page.lostPassword.button.sendToOtherAddress" /></ButtonComponent>
        </Box>
      </Box>
    </React.Fragment>
  );
}