/* @flow */

import React from 'react';

import CompanyMembershipsComponent from 'app/companies/membership';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function CompanyMembershipsPage() {
  return (
    <WebsitePageComponent id="page.membership.h1">
      <CompanyMembershipsComponent />
    </WebsitePageComponent>
  );
}
