/* @flow */

import React from 'react';
import moment from 'moment';

import IntlMessageComponent from './intlMessage';

type QuarterFormatterComponentProps = {
  date: moment;
}

export default function QuarterFormatterComponent(props: QuarterFormatterComponentProps) {
  return (<React.Fragment>{moment(props.date).format('YYYY')}&nbsp;<IntlMessageComponent id="general.period.quarter" />{moment(props.date).quarter()}</React.Fragment>);
}
