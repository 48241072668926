/* @flow */

import React from 'react';

import BillPortfolioComponent from 'app/companies/bills';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function SellerBillPortfolioPage() {
  return (
    <WebsitePageComponent id="ps.bi.title" data-walkme="accountancy-title-documents" noPaper>
      <BillPortfolioComponent />
    </WebsitePageComponent>
  );
}
