/* @flow */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function IconMarketPlaceNotInsured(props) {
  return (
    <SvgIcon viewBox="0 0 49.7 61" {...props}>
      <g>
        <path d="M24.4,0h1c0.2,0.1,0.4,0.3,0.7,0.4L35.8,3c3.7,1.1,7.5,1.9,11.3,2.3c2.1,0.1,2.6,0.6,2.6,2.8c0,7.3,0,14.5,0,21.8
          c0,1.6-0.2,3.2-0.5,4.8c-1,5.1-3.2,10-6.4,14.1c-3.8,5.1-8.5,9.3-14.7,11.6c-2.1,0.7-4.1,1.1-6.1,0c-2.3-1.1-4.4-2.5-6.5-3.9
          c-4.9-3.8-8.9-8.6-11.8-14C1.2,38.2,0,33.4,0,28.5c0.1-7,0-14,0-20.9c0-1.7,0.6-2.2,2.2-2.4C4.8,4.8,7.4,4.5,10,3.9
          c3.3-0.8,6.6-1.7,9.9-2.6C21.4,0.9,22.9,0.4,24.4,0z M46,20.9c0-3.6,0-7.2,0-10.8c0-0.8-0.2-1.2-1.1-1.3c-1.2-0.1-2.4-0.3-3.6-0.6
          c-4.5-1.1-9-2.4-13.5-3.5c-1.1-0.3-2.3-0.8-3.4-0.6c-3.3,0.7-6.6,1.8-9.9,2.6C11.3,7.4,8.1,8,4.9,8.7c-0.8,0.2-1.2,0.5-1.2,1.4
          c0,6.7-0.1,13.5,0.1,20.2c0.1,2.2,0.5,4.4,1.3,6.4c2,5.1,5,9.7,9,13.5c2.8,3,6.3,5.4,10.1,6.9c0.3,0.2,0.7,0.2,1.1,0.1
          c1.4-0.4,2.8-1,4.1-1.6c5.4-3,9.8-7.4,12.7-12.7c2.8-4.5,4.1-9.7,3.9-15C45.9,25.6,46,23.2,46,20.9L46,20.9z"/>
	      <g>
		      <g>
			      <line x1="33.1" y1="21.2" x2="16.8" y2="36.8"/>
			      <path d="M16.8,38.4c-0.4,0-0.9-0.2-1.2-0.5c-0.6-0.7-0.6-1.7,0-2.3L32,20c0.6-0.6,1.7-0.6,2.3,0
				      c0.6,0.6,0.6,1.7,0,2.3L17.9,38C17.6,38.3,17.2,38.4,16.8,38.4z"/>
		      </g>
		      <g>
			      <line x1="17.6" y1="20.8" x2="33.2" y2="37.1"/>
			      <path d="M33.2,38.8c-0.4,0-0.9-0.2-1.2-0.5L16.5,21.9c-0.6-0.7-0.5-1.7,0.2-2.3c0.6-0.5,1.6-0.5,2.2,0.1L34.4,36
				      c0.6,0.6,0.6,1.7,0,2.3C34,38.6,33.6,38.8,33.2,38.8z"/>
		      </g>
	      </g>
      </g>
    </SvgIcon>
  );
}
