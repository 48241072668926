/* @flow */

/**
 * Load constraints from sibling files. Should be invoked with await.
 * @param model {string} Validation file name. Should be the same as related model
 * @param subset {string[]} Subset of the validators required
 * @return {Promise<*>}
 */
const loadConstraints = async (model: string, subset: ?string[]) => {

  // Try to import the related constraints file
  try {
    // $FlowFixMe
    const constraints = await import(`lib/validation/constraints/${model}`);

    if (subset) {
      const constraintsSubset = Object.assign({}, constraints);
      const membersImported = Object.keys(constraints);
      // $FlowFixMe
      const membersToRemove = membersImported.filter(key => !subset.includes(key));
      const wrongSubsetDefinition = subset.filter(key => !membersImported.includes(key));

      if (wrongSubsetDefinition.length) {
        console.warn('Tried to import constraints : %s that does not exists in %s', wrongSubsetDefinition.join(', '), model);
      }

      membersToRemove.forEach(key => delete constraintsSubset[key]);
      return constraintsSubset;
    }

    return constraints;
  } catch (e) {
    console.error('Validation constraints import failed. Received : %O', e);
    return {};
  }

};

export default loadConstraints;
