/* @flow */

import IconAddFilled from 'components/icons/filled/add';
import IconArrowUpFilled from 'components/icons/filled/arrowUp';
import IconListFilled from 'components/icons/filled/list';
import IconHomeFilled from 'components/icons/filled/home';
import IconSuitcaseFilled from 'components/icons/filled/suitcase';
import IconRefreshFilled from 'components/icons/filled/refresh';

import {
  PROTECTED_BUYER_BILLS,
  PROTECTED_BUYER_BUY_INVOICES,
  PROTECTED_BUYER_DASHBOARD,
  PROTECTED_BUYER_INVESTMENT_PROFILE,
  PROTECTED_BUYER_PERFORMANCE,
  PROTECTED_BUYER_PORTFOLIO
} from 'constants/pageRoutes';

export const buyerMenuData = [
  {link: PROTECTED_BUYER_DASHBOARD, icon: <IconHomeFilled color='primary' size='1.5x'/>, messageId: 'sidebar.page.home'},
  {link: PROTECTED_BUYER_BUY_INVOICES, icon: <IconAddFilled color='primary' size='1.5x'/>, messageId: 'sidebar.page.buy', pageName: 'POS.Buyers.SidebarMenu', elementName: 'menuBuy', checkExecutePermissions: true},
  {link: PROTECTED_BUYER_PORTFOLIO, icon: <IconSuitcaseFilled color='primary' size='1.5x'/>, messageId: 'sidebar.page.myportfolio', pageName: 'POS.Buyers.SidebarMenu', elementName: 'menuPortfolio'},
  {link: PROTECTED_BUYER_BILLS, icon: <IconListFilled color='primary' size='1.5x'/>, messageId: 'sidebar.page.mybills', pageName: 'POS.Buyers.SidebarMenu', elementName: 'menuBills'},
  {link: PROTECTED_BUYER_INVESTMENT_PROFILE, icon: <IconRefreshFilled color='primary' size='1.5x'/>, messageId: 'sidebar.page.investmentProfile', pageName: 'POS.Buyers.SidebarMenu', elementName: 'menuInvestmentProfile'},
  {link: PROTECTED_BUYER_PERFORMANCE, icon: <IconArrowUpFilled color='primary' size='1.5x'/>, messageId: 'sidebar.page.performance', pageName: 'POS.Buyers.SidebarMenu', elementName: 'menuPerformance'},
];
