/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import LoadingComponent from 'components/loading';
import ContactPersonFormViewComponent from './view';

import CompanyContactPerson from 'models/companies/companyContactPerson';
import CompanyContactPersonForm from './model';

import CompanyService from 'services/CompanyService';
import {handleApiFormResponse, handleFormChange, handleFormValidation} from 'lib/forms';
import loadConstraints from 'lib/validation/loadConstraints';

type ContactPersonFormComponentProps = {
  activeCompanyId: number;
  debtorCompanyId: number;
  successHandler: (contactPerson: CompanyContactPerson) => void;
  cancelHandler: () => void;
}

type ContactPersonFormComponentState = {
  constraints: any;
  errors: Map<string, ?string>;
  form: CompanyContactPersonForm;
  formErrors: string[];
  isLoading: boolean;
  isProcessing: boolean;
}

class ContactPersonFormComponent extends React.Component<ContactPersonFormComponentProps, ContactPersonFormComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      constraints: null,
      errors: null,
      form: new CompanyContactPersonForm(),
      formErrors: [],
      isLoading: true,
      isProcessing: false
    };
  }

  componentDidMount = async () => {
    const constraints = await loadConstraints('Person', ['lastName', 'firstName', 'email', 'phone', 'titleId', 'languageId', 'functionName']);
    this.setState({
      constraints,
      errors: new Map(Object.keys(constraints).map(e => [e, undefined])),
      isLoading: false
    });
  };

  handleChange = (fieldName: string) => (event) => handleFormChange.call(this, fieldName, event.target.value);
  handleTypeChange = (fieldName: string) => (id: number) => handleFormChange.call(this, fieldName, id);
  handlePhoneChange = (value: string) => {
    handleFormChange.call(this, 'phone', value);
  }
  validateForm = () => handleFormValidation.call(this);

  submitForm = async () => {
    if (!this.validateForm()) return;

    try {
      this.setState({formErrors: [], isProcessing: true});
      const request = this.state.form.toRequest(this.props.activeCompanyId, this.props.debtorCompanyId);
      const contactPerson = await CompanyService.addContactPerson(request);
      this.props.successHandler(contactPerson);
    } catch (error) {
      handleApiFormResponse.call(this, error);
    }
  };

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <ContactPersonFormViewComponent
        errors={this.state.errors}
        form={this.state.form}
        formErrors={this.state.formErrors}
        isProcessing={this.state.isProcessing}
        cancel={this.props.cancelHandler}
        handleChange={this.handleChange}
        handleTypeChange={this.handleTypeChange}
        handlePhoneChange={this.handlePhoneChange}
        submitForm={this.submitForm}
      />
    );
  }
}


const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps)(ContactPersonFormComponent);
