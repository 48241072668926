/* @flow */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function IconHand(props) {
  return (
    <SvgIcon viewBox="0 0 49.96 66.5" {...props}>
      <path d="M396.5,276.56c.55-2.56,1.92-4.34,4.63-4.71a14.56,14.56,0,0,1,2.77.15c1.38-3.79,4.34-4.8,7.3-3.71,2.17-3.35,3.86-4.3,6.47-3.55,3.74,1.06,4,4,3.86,7.11a15.1,15.1,0,0,1,3.09,0,4.78,4.78,0,0,1,4.13,5.14c0,7.28,0,14.56,0,21.84v1.67c1.11-.87,2-1.61,2.93-2.31a23,23,0,0,1,8.49-4.45c2.73-.65,5.13.32,5.94,2.4a5,5,0,0,1-.93,5.13,37.81,37.81,0,0,1-2.95,3L440,306.56a11.52,11.52,0,0,0-3.13,6.65c-.75,4.54-2.25,8.79-5.66,12.11a20,20,0,0,1-11.65,5.45,22.14,22.14,0,0,1-16.28-3.89c-4.54-3.21-6.38-7.77-6.45-13.17-.05-4-.08-7.92-.13-11.88,0-.59-.11-1.18-.17-1.77ZM399.59,296h.25c0,6.18,0,12.35,0,18.52a11.62,11.62,0,0,0,4.45,9.35c5.87,4.68,12.45,5.28,19.32,2.77,6.17-2.25,9-7.25,10.16-13.42.46-2.56.84-5.14,2.67-7.15s3.7-3.75,5.49-5.68a7.29,7.29,0,0,0,1.41-2.22,1,1,0,0,0-1-1.46,7.34,7.34,0,0,0-2.74.37c-4.31,1.85-8.14,4.35-10.81,8.38a2.52,2.52,0,0,1-1.92.75c-.85,0-1.08-.81-1-1.61,0-.43,0-.85,0-1.28q0-13,0-25.93c0-1.69-.85-2.65-2.25-2.66s-2,.88-2,2.63v11.37c0,1.27,0,2.55,0,3.83a1.42,1.42,0,0,1-1.55,1.5,1.4,1.4,0,0,1-1.49-1.54c0-.29,0-.59,0-.89q0-10.86,0-21.71c0-1.78-1.22-2.81-2.77-2.33-1.28.39-1.54,1.43-1.54,2.62q0,10.41,0,20.82c0,1.87-.43,2.58-1.52,2.54s-1.43-.63-1.43-2.5c0-5.84,0-11.67,0-17.5,0-1.74-.81-2.66-2.22-2.68s-2,.87-2,2.64v17.5c0,.93,0,1.87,0,2.8a1.5,1.5,0,0,1-2.38,1.24,2.44,2.44,0,0,1-.63-1.66c0-5.37,0-10.73-.05-16.1a2.73,2.73,0,0,0-.68-1.92A3,3,0,0,0,401,275c-1,.25-1.44,1.2-1.44,2.29Q399.6,286.66,399.59,296Z" transform="translate(-396.5 -264.5)"/>
    </SvgIcon>
  );
}
