/* @flow */

import moment from 'moment';
import CommissionSummaryIsoCompany from './commissionSummaryIsoCompany';

export default class CommissionSummaryData {
  companies: CommissionSummaryIsoCompany[];
  quarters: moment[];
  financedInvoiceCommission: number;
  unfinancedInvoiceCommission: number;
  msfCommission: number;
  membershipCommission: number;
  totalCommission: number;

  constructor() {
    this.companies = [];
    this.quarters = [];
    this.financedInvoiceCommission = 0;
    this.unfinancedInvoiceCommission = 0;
    this.msfCommission = 0;
    this.membershipCommission = 0;
    this.totalCommission = 0;
  }
}
