/* @flow */

export default class ConfirmBusinessPotentialsRequest {
  companyId: number;
  debtorCompanyIds: number[];

  constructor(companyId: number, debtorCompanyIds: number[]) {
    this.companyId = companyId;
    this.debtorCompanyIds = debtorCompanyIds;
  }
}
