/* @flow */

import React from 'react';

import IntlMessageComponent from 'components/formatters/intlMessage';
import LoadingComponent from 'components/loading';

import DebtorInfoFormComponent from './debtorInfoForm';
import DebtorInvoicesToApproveComponent from './invoicesToApprove';
import InvoiceApprovalConfirmationViewComponent from './invoiceApprovalConfirmation';
import InvoiceRefusalConfirmationViewComponent from './invoiceRefusalConfirmation';

import Company from 'models/companies/company';
import DebtorInfo from 'models/debtors/debtorInfo';

import CheckDebtorTokenRequest from 'models/requests/debtors/checkDebtorTokenRequest';

import DebtorService from 'services/DebtorService';

const DEBTOR_APPROVAL_STEP_TOKEN_INVALID = -1;
const DEBTOR_APPROVAL_STEP_USER = 0;
const DEBTOR_APPROVAL_STEP_INVOICES = 1;
const DEBTOR_APPROVAL_STEP_INVOICES_APPROVED = 2;
const DEBTOR_APPROVAL_STEP_INVOICES_REFUSED = 3;

type DebtorInvoicesToApproveTokenComponentProps = {
  token: string;
}

type DebtorInvoicesToApproveTokenComponentState = {
  debtorCompany: Company;
  debtorInfo: DebtorInfo;
  isLoading: boolean;
  step: number;
}

class DebtorInvoicesToApproveTokenComponent extends React.Component<DebtorInvoicesToApproveTokenComponentProps, DebtorInvoicesToApproveTokenComponentState> {

  constructor(props) {
    super(props);
  
    this.state = {
      debtorCompany: null,
      debtorInfo: null,
      isLoading: true,
      step: DEBTOR_APPROVAL_STEP_USER
    };
  }

  componentDidMount = async () => {
    try {
      const request = new CheckDebtorTokenRequest(this.props.token);
      const debtorCompany = await DebtorService.checkDebtorToken(request);
      this.setState({debtorCompany, isLoading: false});
    } catch (e) {
      if (e.error.response.status === 403) {
        this.setState({isLoading: false, step: DEBTOR_APPROVAL_STEP_TOKEN_INVALID});
      } else {
        console.error(e);
      }
    }
  }

  restart = () => this.setState({step: DEBTOR_APPROVAL_STEP_INVOICES});
  successApproveInvoices = () => this.setState({step: DEBTOR_APPROVAL_STEP_INVOICES_APPROVED});
  successDebtorInfo = (debtorInfo: DebtorInfo) => this.setState({debtorInfo, step: DEBTOR_APPROVAL_STEP_INVOICES});
  successRefuseInvoices = () => this.setState({step: DEBTOR_APPROVAL_STEP_INVOICES_REFUSED});

  render() {
    if (this.state.isLoading) {
      return <LoadingComponent />;
    }

    switch(this.state.step) {
      case DEBTOR_APPROVAL_STEP_USER:
        return (<DebtorInfoFormComponent successHandler={this.successDebtorInfo} />);
      case DEBTOR_APPROVAL_STEP_INVOICES:
        return (
          <DebtorInvoicesToApproveComponent
            debtorCompany={this.state.debtorCompany}
            debtorInfo={this.state.debtorInfo}
            token={this.props.token}
            successApproveInvoices={this.successApproveInvoices}
            successRefuseInvoices={this.successRefuseInvoices}
          />
        );
      case DEBTOR_APPROVAL_STEP_INVOICES_APPROVED:
        return (<InvoiceApprovalConfirmationViewComponent restart={this.restart} />);
      case DEBTOR_APPROVAL_STEP_INVOICES_REFUSED:
        return (<InvoiceRefusalConfirmationViewComponent restart={this.restart} />);
      default:
        return <p><IntlMessageComponent id="general.token.invalid" /></p>;
    }
  }
}

export default DebtorInvoicesToApproveTokenComponent;
