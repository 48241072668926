/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import IntlMessageComponent from 'components/formatters/intlMessage';

import CompanyDocumentTypeFilterComponent from './documentType';

import CompanyDocumentActiveFilters from 'models/companies/companyDocumentActiveFilters';
import CompanyDocumentFilters from 'models/companies/companyDocumentFilters';

type CompanyDocumentsTableControlsComponentProps = {
  activeFilters: CompanyDocumentActiveFilters;
  filters: CompanyDocumentFilters;
  handleFiltersChange: (filters: CompanyDocumentActiveFilters) => void;
}

export default function CompanyDocumentsTableControlsComponent(props: CompanyDocumentsTableControlsComponentProps) {

  const handleDocumentTypeChange = (documentTypeId: number) => {
    const activeFilters = props.activeFilters;
    activeFilters.documentTypeId = documentTypeId;
    props.handleFiltersChange(activeFilters);
  };

  return (
    <Paper elevation={0}>
      <Box p={3}>
        <Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={3}>
          <Grid container item xs={12} md={6}>
            <Grid item xs={12} md={6}>
              <Box display="flex" height="100%" alignItems="center">
                <b><IntlMessageComponent id="component.documents.search" /></b>
              </Box>
            </Grid>
          
            <Grid item xs={12} md={6}>
              <CompanyDocumentTypeFilterComponent
                value={props.activeFilters.documentTypeId}
                values={props.filters.documentTypeIds}
                changeHandler={handleDocumentTypeChange}
                margin="dense"
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}
