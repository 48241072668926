/* @flow */

import React from 'react';
import {FormattedNumber} from 'react-intl';
import {IntlProvider} from 'react-intl-redux';

type NumberFormatterComponentProps = {
  value: number
}

export default function NumberFormatterComponent(props: NumberFormatterComponentProps) {
  return (
    <IntlProvider>
      <FormattedNumber maximumFractionDigits={0} minimumFractionDigits={0} value={props.value || 0}/>
    </IntlProvider>
  );
}
