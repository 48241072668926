/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import LoadingComponent from 'components/loading';

import RegisterLeadTab1LeadAlreadyExistViewComponent from './alreadyExistView';
import RegisterLeadTab1ViewComponent from './view';

import Company from 'models/companies/company';
import RegisterLeadForm from './model';

import RegisterService from 'services/registerService';
import IsoService from 'services/IsoService';
import {handleApiFormResponse, handleFormChange, handleFormValidation} from 'lib/forms';
import loadConstraints from 'lib/validation/loadConstraints';
import NotificationHelper from 'lib/notifications';

type RegisterLeadTab1ComponentProps = {
  activeCompanyId: number;
  successHandler: (companyId: number, leadId: number) => void;
};

type RegisterLeadTab1ComponentState = {
  constraints: any;
  errors: Map<string, ?string>;
  form: RegisterLeadForm;
  formErrors: string[];
  isLoading: boolean;
  isProcessing: boolean;
  leadAlreadyExist: boolean;
};

class RegisterLeadTab1Component extends React.Component<RegisterLeadTab1ComponentProps, RegisterLeadTab1ComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      constraints: null,
      errors: null,
      form: null,
      formErrors: [],
      isLoading: true,
      isProcessing: false,
      leadAlreadyExist: false
    };
  }

  componentDidMount = async () => {
    try {
      const companyConstraintsPromise = loadConstraints('Company', ['company']);
      const personConstraintsPromise = loadConstraints('Person', ['titleId','lastName', 'firstName', 'email', 'mobilePresence', 'languageId']);

      const results = await Promise.all([companyConstraintsPromise, personConstraintsPromise]);
      const constraints = Object.assign({}, results[0], results[1]);
      this.setState({
        constraints,
        errors: new Map(Object.keys(constraints).map(e => [e, undefined])),
        form: new RegisterLeadForm(),
        isLoading: false
      });
    } catch (e) {
      console.error(e);
    }
  };

  handleChange = (fieldName: string) => (event) => handleFormChange.call(this, fieldName, event.target.value);
  handleBooleanChange = (fieldName: string) => (event) => handleFormChange.call(this, fieldName, event.target.value === 'true');
  handleTypeChange = (fieldName: string) => (id: number) => handleFormChange.call(this, fieldName, id);
  handleCompanyChange = (company: ?Company) => {
    const form = this.state.form;
    form.company = company;

    const errors = this.state.errors;
    errors.delete('company');

    this.setState({errors, form});
  };
  handleMobileChange = (value: string) => {
    handleFormChange.call(this, 'mobilePresence', value);
  }

  validateForm = () => handleFormValidation.call(this);

  submitForm = async () => {
    if (!this.validateForm())
    {
      const request = this.state.form.toIncompleteAlertRequest(this.props.activeCompanyId, "ISO Register Lead");
      const response = await RegisterService.incompleteAlert(request);
      return;
    }
    this.setState({formErrors: [], isProcessing: true});
    
    try {
      const request = this.state.form.toRequest(this.props.activeCompanyId);
      const response = await IsoService.registerLead(request);
      if (response.leadAlreadyExist) {
        this.setState({isProcessing: false, leadAlreadyExist: true});
      } else {
        NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS);
        this.props.successHandler(response.companyId, response.leadId);
      }
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }
  };

  resetForm = () => {
    this.setState(prevState => ({
      errors: new Map(Object.keys(prevState.constraints).map(e => [e, undefined])),
      form: new RegisterLeadForm(),
      formErrors: [],
      leadAlreadyExist: false
    }));
  }

  render = () => {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    if (this.state.leadAlreadyExist) {
      return (<RegisterLeadTab1LeadAlreadyExistViewComponent resetForm={this.resetForm} />);
    }

    return (
      <RegisterLeadTab1ViewComponent
        errors={this.state.errors}
        form={this.state.form}
        formErrors={this.state.formErrors}
        isProcessing={this.state.isProcessing}
        handleChange={this.handleChange}
        handleTypeChange={this.handleTypeChange}
        handleBooleanChange={this.handleBooleanChange}
        handleCompanyChange={this.handleCompanyChange}
        handleMobileChange={this.handleMobileChange}
        submitForm={this.submitForm}
      />
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps)(RegisterLeadTab1Component);
