/* @flow */

import moment from 'moment';

import InvoiceActiveFilters from 'models/buyers/invoices/invoiceActiveFilters';

export default class DownloadBuyerPortfolioRequest {
  companyId: number;
  investmentProfileId: number;
  format: string;
  query: string;
  statusKey: string;
  state: string;
  closedDateFrom: number;
  closedDateTo: number;
  purchasedDateFrom: moment;
  purchasedDateTo: moment;
  yearMonthSelected: moment;

  constructor(companyId: number, investmentProfileId: number, format: string, activeFilters: InvoiceActiveFilters) {
    this.companyId = companyId;
    this.investmentProfileId = investmentProfileId;
    this.format = format;
    this.query = activeFilters.query;
    this.statusKey = activeFilters.statusKey;
    this.state = activeFilters.state;
    this.purchasedDateFrom = activeFilters.purchasedDateFrom;
    this.purchasedDateTo = activeFilters.purchasedDateTo;
    this.closedDateFrom = activeFilters.closedDateFrom;
    this.closedDateTo = activeFilters.closedDateTo;
    this.yearMonthSelected = activeFilters.yearMonth;
  }

  toRouteParameters() {
    let route = `companyId=${this.companyId}&investmentProfileId=${this.investmentProfileId}&format=${this.format}`;
    if (this.query !== null && this.query !== '') {
      route += `&query=${this.query}`;
    }
    if (this.state !== null && this.state !== '') {
      route += `&state=${this.state}`;
    }
    if (this.statusKey !== null && this.statusKey !== '') {
      route += `&statusKey=${this.statusKey}`;
    }
    if (this.purchasedDateFrom && this.purchasedDateTo) {
      route += `&purchasedDateFrom=${this.purchasedDateFrom.format('YYYY-MM-DD')}&purchasedDateTo=${this.purchasedDateTo.format('YYYY-MM-DD')}`;
    } 
    if (this.closedDateFrom && this.closedDateTo) {
      route += `&closedDateFrom=${this.closedDateFrom.format('YYYY-MM-DD')}&closedDateTo=${this.closedDateTo.format('YYYY-MM-DD')}`;
    } 
    if (this.yearMonthSelected) {
      route += `&yearMonthSelected=${this.yearMonthSelected.format('YYYY-MM-DD')}`;
    }
    return route;
  }
}
    