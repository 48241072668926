/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import TypeImplComponent from '../types/typeImpl';

import CompanyService from 'services/CompanyService';

type CompanyBankAccountFieldComponentProps = {
  error: boolean;
  fullWidth: boolean;
  helperText: IntlMessage;
  id: string;
  label: IntlMessage;
  margin: string;
  required: boolean;
  readOnly: boolean;
  value: number;
  changeHandler: (typeId: number) => void;
}

type CompanyBankAccountFieldComponentState = {
  isLoading: boolean;
  values: {id: number, name: string}[];
}

class CompanyBankAccountFieldComponent extends React.Component<CompanyBankAccountFieldComponentProps, CompanyBankAccountFieldComponentState> {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      values: []
    };
  }

  componentDidMount = async () => {
    try {
      const bankAccounts = await CompanyService.getCompanyBankAccounts(this.props.activeCompanyId);
      const values = bankAccounts.map(b => ({id: b.id, name: b.bankIBAN}));
      this.setState({isLoading: false, values});
    } catch (e) {
      console.error(e);
    }
  }

  render() {
    return (
      <TypeImplComponent
        isLoading={this.state.isLoading}
        values={this.state.values}
        readOnly={this.props.readOnly}
        {...this.props}
      />
    );
  }
}

const mapStateToProps = ({auth}) => ({
  activeCompanyId: auth.user.activeCompany.id
});

export default connect(mapStateToProps)(CompanyBankAccountFieldComponent);
