/* @flow */

import BusinessPotential from 'models/sellers/businessPotential';
import DebtorCompany from 'models/companies/debtorCompany';

import UpsertBusinessPotentialRequest from 'models/requests/sellers/upsertBusinessPotentialRequest';

export default class BusinessPotentialForm {
  debtorCompany: DebtorCompany;
  nbrInvoices: number;
  avgInvoiceAmount: number;
  paymentTerms: string;
  updated: boolean;

  constructor(bp: BusinessPotential) {
    if (bp === null) {
      this.debtorCompany = null;
      this.nbrInvoices = '';
      this.avgInvoiceAmount = '';
      this.paymentTerms = '';
      this.updated = false;
    } else {
      this.debtorCompany = bp.debtorCompany;
      this.nbrInvoices = bp.nbrInvoices;
      this.avgInvoiceAmount = bp.avgInvoiceAmount;
      this.paymentTerms = bp.paymentTerms;
      this.updated = false;
    }
  }

  toRequest(companyId: number) {
    const request = new UpsertBusinessPotentialRequest();
    request.companyId = companyId;
    request.debtorCompanyId = this.debtorCompany.id;
    request.nbrInvoices = this.nbrInvoices;
    request.avgInvoiceAmount = this.avgInvoiceAmount;
    request.paymentTerms = this.paymentTerms;
    return request;
  }
}
