/* @flow */

import React from 'react';
import { useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import Radio from '@material-ui/core/Radio';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Switch from '@material-ui/core/Switch';

import IbanFormatterComponent from 'components/formatters/iban';
import IconList from 'components/icons/list';
import IconTrash from 'components/icons/trash';
import IntlMessageComponent from 'components/formatters/intlMessage';
import SecurityComponent from 'components/security';
import StyledTableCellTextComponent from 'components/table/cellText';
import StyledTableCellTextSmallComponent from 'components/table/cellTextSmall';
import StyledTableRowComponent from 'components/table/row';

import BankAccount from 'models/companies/bankAccount';

import { ROLE_BUYER } from 'constants/companyRoles';

type CompanyBankAccountsTableViewComponentProps = {
  bankAccounts: BankAccount[];
  displayBankAccountToBeValidatedMessage: boolean;
  isProcessing: boolean;
  companyIsActive: boolean;
  changeMainBankAccount: () => void;
  toggleEnableBankAccount: () => void;
  deleteBankAccount: () => void;
}

export default function CompanyBankAccountsTableViewComponent(props: CompanyBankAccountsTableViewComponentProps) {
  const activeCompanyRole = useSelector(state => state.auth.user.activeCompany.role);
  const onlyOneActiveBankAccount = props.bankAccounts?.filter(b => b.isActive)?.length === 1;

  return (
    <Box component="div" overflow="auto">
      <Table>
        <TableBody bgcolor="white">
          {props.bankAccounts.length === 0 &&
            <StyledTableRowComponent>
              <StyledTableCellTextComponent colSpan={3}>
                <Box textAlign="center">
                  <IntlMessageComponent id="component.company.bankAccounts.list.noData" />
                </Box>
              </StyledTableCellTextComponent>
            </StyledTableRowComponent>
          }

          {props.bankAccounts.map(bankAccount => (
            <StyledTableRowComponent key={`company-bankAccount-${bankAccount.id}`}>
              <StyledTableCellTextComponent>
                <Box component="span" color={bankAccount.isActive ? "blue.main" : "grey.main"}>
                  <IconList />
                </Box>
                <Box component="span" ml={2}>
                  <IbanFormatterComponent iban={bankAccount.bankIBAN} />
                </Box>
              </StyledTableCellTextComponent>

              {activeCompanyRole === ROLE_BUYER &&
                <StyledTableCellTextSmallComponent align="right">
                  {bankAccount.investmentProfileNames.join(', ')} 
                </StyledTableCellTextSmallComponent>
              }
              <StyledTableCellTextComponent align="right">
                <SecurityComponent pageName="POS.CompanyInformation" elementName="btnMainBankAccount">
                  <Box component="span" mr={2}>
                    <Radio
                      name={`form.radio.bankAccount-${bankAccount.id}`}
                      color="primary"
                      disabled={!bankAccount.isActive || !bankAccount.isMainEligible || props.isProcessing}
                      checked={bankAccount.isMain}
                      onChange={() => props.changeMainBankAccount(bankAccount.id)}
                    />
                    <IntlMessageComponent id="component.company.bankAccounts.useAsMainBankAccount" />
                  </Box>
                </SecurityComponent>
              </StyledTableCellTextComponent>

              <StyledTableCellTextComponent align="right">
                <SecurityComponent pageName="POS.CompanyInformation" elementName="btnMainBankAccount">
                  <Tooltip title={<IntlMessageComponent id={props.companyIsActive && onlyOneActiveBankAccount && bankAccount.isActive ? "company.bankAccounts.tooltip.onlyoneactiveaccount" : bankAccount.isMain && props.companyIsActive && bankAccount.isActive ? "company.bankAccounts.tooltip.isMain" : !bankAccount.complianceDone && !bankAccount.isActive ? "company.bankAccounts.tooltip.complianceNotDone" : "company.bankAccounts.tooltip.toggleenable"} />}>
                    <Box>
                      <Switch checked={bankAccount.isActive} disabled={(props.companyIsActive && onlyOneActiveBankAccount && bankAccount.isActive) || (!bankAccount.isActive && !bankAccount.complianceDone) || (props.companyIsActive && bankAccount.isMain && bankAccount.isActive) || props.isProcessing} onChange={() => props.toggleEnableBankAccount(bankAccount.id)} />
                    </Box>
                  </Tooltip>
                </SecurityComponent>
              </StyledTableCellTextComponent>

              <StyledTableCellTextComponent align="right">
                <SecurityComponent pageName="POS.CompanyInformation" elementName="btnMainBankAccount">
                  {!bankAccount.isMain && <Box component="span" ml={1}>
                    <Tooltip title={<IntlMessageComponent id="company.bankAccounts.tooltip.delete" />}>
                      <IconButton onClick={() => props.deleteBankAccount(bankAccount.id)}>
                        <IconTrash />
                      </IconButton>
                    </Tooltip>
                  </Box>}
                </SecurityComponent>
              </StyledTableCellTextComponent>

            </StyledTableRowComponent>
          ))}

          {props.displayBankAccountToBeValidatedMessage &&
            <StyledTableRowComponent>
              <StyledTableCellTextComponent colSpan={3}>
                <Box textAlign="center">
                  <IntlMessageComponent id="component.company.bankAccounts.toBeValidated" />
                </Box>
              </StyledTableCellTextComponent>
            </StyledTableRowComponent>
          }
        </TableBody>
      </Table>
    </Box>
  );
}
