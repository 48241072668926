/* @flow */

import { USER_LANGUAGE_GERMAN, USER_LANGUAGE_ITALIAN } from 'constants/constants';
import CompanyPerson from 'models/companies/companyPerson';
import OfficePerson from 'models/companies/officePerson';

import AddOfficePersonRequest from 'models/requests/companies/offices/addOfficePersonRequest';
import UpdateOfficePersonRequest from 'models/requests/companies/offices/updateOfficePersonRequest';

export default class OfficePersonForm {
  companyPerson: CompanyPerson;
  titleId: number;
  lastName: string;
  firstName: string;
  email: string;
  mobile: string;
  phone: string;
  countryId: number;
  languageId: number;
  functionName: string;

  constructor(officePerson: OfficePerson) {
    if (officePerson) {
      this.companyPerson = null;
      this.titleId = officePerson.titleId ? officePerson.titleId : 0;
      this.lastName = officePerson.lastName ? officePerson.lastName : '';
      this.firstName = officePerson.firstName ? officePerson.firstName : '';
      this.email = officePerson.email ? officePerson.email : '';
      this.mobile = officePerson.mobile ? officePerson.mobile : '';
      this.phone = officePerson.phone ? officePerson.phone : '';
      this.countryId = officePerson.countryId ? officePerson.countryId : 0;
      this.languageId = officePerson.languageId === USER_LANGUAGE_GERMAN || officePerson.languageId === USER_LANGUAGE_ITALIAN ? 0 : officePerson.languageId; 
      this.functionName = officePerson.functionName ? officePerson.functionName : '';
    } else {
      this.companyPerson = null;
      this.titleId = 0;
      this.lastName = '';
      this.firstName = '';
      this.email = '';
      this.mobile = '';
      this.phone = '';
      this.countryId = 0;
      this.languageId = 0;
      this.functionName = '';
    }
  }

  toAddOfficePersonRequest(companyId: number, officeId: number){
    const request = new AddOfficePersonRequest();
    request.companyId = companyId;
    request.officeId = officeId;
    request.titleId = this.titleId;
    request.lastName = this.lastName;
    request.firstName = this.firstName;
    request.email = this.email;
    request.mobile = this.mobile;
    request.phone = this.phone;
    request.countryId = this.countryId;
    request.languageId = this.languageId;
    request.functionName = this.functionName;
    return request;
  }

  toUpdateOfficePersonRequest(companyId: number, officeId: number, officePersonId: number){
    const request = new UpdateOfficePersonRequest();
    request.companyId = companyId;
    request.companyPersonId = officePersonId;
    request.officeId = officeId;
    request.titleId = this.titleId;
    request.lastName = this.lastName;
    request.firstName = this.firstName;
    request.email = this.email;
    request.mobile = this.mobile;
    request.phone = this.phone;
    request.countryId = this.countryId;
    request.languageId = this.languageId;
    request.functionName = this.functionName;
    return request;
  }
}