/* @flow */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function IconHome(props) {
  return (
    <SvgIcon viewBox="0 0 66.37 59" {...props}>
      <path d="M388,294.89V294a8.8,8.8,0,0,1,1.33-1.67q14.9-11.47,29.85-22.88c1.57-1.2,2.42-1.2,4,0q14.69,11.24,29.34,22.51a15.22,15.22,0,0,1,1.82,2v.63c-1.06,2.1-2.33,2.39-4.21,1l-3.27-2.47V324.4c0,2.41-.66,3.1-3,3.1H398.34c-2.28,0-2.83-.56-2.83-2.85q0-14.79,0-29.59v-2l-3.21,2.37C390.42,296.87,389.4,296.74,388,294.89Zm54.35,28.25a2.91,2.91,0,0,0,.14-.52c0-10.69,0-21.38,0-32.07a2.41,2.41,0,0,0-1-1.59c-6.39-4.93-12.83-9.8-19.22-14.73a1.54,1.54,0,0,0-2.28,0q-9.4,7.25-18.89,14.39a2.89,2.89,0,0,0-1.31,2.59c.05,10.07,0,20.13,0,30.2,0,.5,0,1,.08,1.61h11.7v-9.28c0-3.28,0-6.57,0-9.85,0-2,.7-2.79,2.37-2.8,4.8-.05,9.6,0,14.4,0a2,2,0,0,1,2.23,2.25c0,.57,0,1.15,0,1.72v16.58c0,.73-.15,1.5,1,1.49C435.23,323.12,438.77,323.14,442.35,323.14Zm-26.39,0c3.33,0,6.49,0,9.65-.06.29,0,.81-.83.82-1.28.06-5,0-10,.06-15,0-1.2-.49-1.53-1.59-1.51-2.5,0-5,.05-7.51,0-1.07,0-1.47.35-1.45,1.45.06,3,0,6,0,9.07Z" transform="translate(-388 -268.5)"/>
    </SvgIcon>
  );
}
