/* @flow */

import moment from 'moment';

export default class CommissionPayment {
  quarterDate: moment;
  isActive: boolean;
  isReceived: boolean;
  isPaid: boolean;
}
