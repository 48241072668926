/* @flow */

import React from 'react';

import CompanyFixContractComponent from 'app/companies/fixcontract';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function CompanyFixContractPage() {
  return (
    <WebsitePageComponent noTitle noPaper>
      <CompanyFixContractComponent />
    </WebsitePageComponent>
  );
}
