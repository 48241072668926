/* @flow */

import React from 'react';
import {Route, Switch, useRouteMatch} from 'react-router-dom';

import Error404Page from 'components/errors/error404';

import UserInfoPage from './info';
import UserNotificationsPage from './notifications';
import UserPasswordPage from './password';

export default function UserPage() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.url}/`} component={UserInfoPage} />
      <Route path={`${match.url}/password`} component={UserPasswordPage} />
      <Route path={`${match.url}/notifications`} component={UserNotificationsPage} />
      <Route component={Error404Page} />
    </Switch>
  );
}
