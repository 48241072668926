/* @flow */

import { plainToClass } from 'class-transformer';

import BaseService from './api/baseService';

import {
  DEBTOR_KPIS_GET,
  DEBTOR_PORTFOLIO_EXPORT_GET,
  DEBTOR_PROFILE_SUMMARY_GET,
  DEBTOR_INVOICES_GET,
  DEBTOR_INVOICES_APPROVE_GET,
  DEBTOR_INVOICES_APPROVE_POST,
  DEBTOR_INVOICES_DETAILS_GET,
  DEBTOR_INVOICES_DETAILS_DOCUMENTS_GET,
  DEBTOR_INVOICES_OPEN_GET,
  DEBTOR_INVOICES_PAY_GET,
  DEBTOR_INVOICES_PAY_POST,
  DEBTOR_INVOICES_PORTFOLIO_GET,
  DEBTOR_INVOICES_REFUSE_POST,
  DEBTOR_OFFICES_GET,
  DEBTOR_OFFICES_ADD_POST,
  DEBTOR_OFFICES_UPDATE_POST,
  DEBTOR_OFFICES_PERSONS_GET,
  DEBTOR_OFFICES_PERSONS_ADD_POST,
  DEBTOR_OFFICES_PERSONS_UPDATE_POST,
  DEBTOR_OFFICES_PERSONS_LINK_POST,
  DEBTOR_REGISTER_LEAD_POST,
  DEBTOR_REGISTER_LEAD_SET_FINANCIAL_INFO_POST,
  DEBTOR_REGISTER_SUPPLIER_POST,
  DEBTOR_TOKEN_POST,
  DEBTOR_TOKEN_INVOICES_GET,
  DEBTOR_TOKEN_INVOICES_APPROVE_POST,
  DEBTOR_TOKEN_INVOICES_DISPUTE_POST,
  DEBTOR_TOKEN_INVOICES_DOCUMENTS_GET,
  DEBTOR_TOKEN_INVOICES_DOCUMENTS_RELATED_GET,
  DEBTOR_TOKEN_INVOICES_REFUSE_POST,
  DEBTOR_SUPPLIER_ACCOUNT_TO_EDEBEX,
  DEBTOR_SUPPLIER_ACCOUNT_FROM_EDEBEX,
  DEBTOR_SUPPLIER_CHANGE_ACCOUNT,
  DEBTOR_APPROVAL_SIGN
} from 'constants/apiRoutes';

import Company from 'models/companies/company';
import Invoice from 'models/debtors/invoice';
import InvoiceFilters from 'models/debtors/invoiceFilters';
import Kpi from 'models/dashboards/kpi';
import ProfileSummaryCard from 'models/dashboards/profileSummaryCard';
import Office from 'models/companies/office';
import OfficePerson from 'models/companies/officePerson';

import ApproveInvoiceRequest from 'models/requests/debtors/approveInvoiceRequest';
import ApproveInvoiceTokenRequest from 'models/requests/debtors/approveInvoiceTokenRequest';
import CheckDebtorTokenRequest from 'models/requests/debtors/checkDebtorTokenRequest';
import DisputeInvoiceTokenRequest from 'models/requests/debtors/disputeInvoiceTokenRequest';
import DownloadPortfolioRequest from 'models/requests/debtors/downloadPortfolioRequest';
import PayInvoiceRequest from 'models/requests/debtors/payInvoiceRequest';
import RefuseInvoiceRequest from 'models/requests/debtors/refuseInvoiceRequest';
import RefuseInvoiceTokenRequest from 'models/requests/debtors/refuseInvoiceTokenRequest';
import RegisterLeadRequest from 'models/requests/debtors/registerLeadRequest';
import RegisterSupplierRequest from 'models/requests/debtors/registerSupplierRequest';
import SetFinancialInfoRequest from 'models/requests/debtors/setFinancialInfoRequest';

import AddOfficeRequest from 'models/requests/companies/offices/addOfficeRequest';
import AddOfficePersonRequest from 'models/requests/companies/offices/addOfficePersonRequest';
import LinkPersonToOfficeRequest from 'models/requests/companies/offices/linkPersonToOfficeRequest';
import UpdateOfficePersonRequest from 'models/requests/companies/offices/updateOfficePersonRequest';
import UpdateOfficeRequest from 'models/requests/companies/offices/updateOfficeRequest';

import RegisterLeadResponse from 'models/responses/debtors/registerLeadResponse';
import ChangeSupplierAccountRequest from 'models/requests/debtors/changeSupplierAccountRequest';
import SupplierBankAccountChange from 'models/debtors/supplierBankAccountChange';
import SignDebtorApprovalRequest from 'models/requests/debtors/signDebtorApprovalRequest';
import UniversignTransaction from 'models/register/universignTransaction';

export default class DebtorService {

  // Dashboard
  static getKPIs = (companyId: number) =>
    BaseService.simpleGET(`${DEBTOR_KPIS_GET}/${companyId}`, data => data.map(d => plainToClass(Kpi, d)));

  static getProfileSummary = (companyId: number) =>
    BaseService.simpleGET(`${DEBTOR_PROFILE_SUMMARY_GET}/${companyId}`, data => data.map(d => plainToClass(ProfileSummaryCard, d)));

  // Invoices - Details
  static getInvoiceDetails = (companyId: number, invoiceId: number) =>
    BaseService.simpleGET(`${DEBTOR_INVOICES_DETAILS_GET}/${companyId}/${invoiceId}`,
      data => {
        const invoice = plainToClass(Invoice, data.invoice);
        invoice.sellerCompany = plainToClass(Company, data.invoice.sellerCompany);
        return { invoice, statusKeys: data.statusKeys };
      });

  static getInvoiceDocument = (companyId: number, invoiceId: number) =>
    BaseService.simpleFileURLGET(`${DEBTOR_INVOICES_DETAILS_DOCUMENTS_GET}/${companyId}/${invoiceId}`);

  // Invoices - Lists
  static getInvoicesPortfolio = (companyId: number) =>
    BaseService.simpleGET(`${DEBTOR_INVOICES_PORTFOLIO_GET}/${companyId}`,
      data => {
        const invoices = data.invoices.map(d => {
          const invoice = plainToClass(Invoice, d);
          invoice.sellerCompany = plainToClass(Company, d.sellerCompany);
          return invoice;
        });
        const filters = new InvoiceFilters();
        if (data.filters.statusKeys)
          filters.statusKeys = data.filters.statusKeys;
        if (data.filters.sellerCompanies)
          filters.sellerCompanies = data.filters.sellerCompanies.map(d => plainToClass(Company, d));
        if (data.filters.invoiceMaxAmount)
          filters.invoiceMaxAmount = data.filters.invoiceMaxAmount;
        if (data.filters.invoiceMinAmount)
          filters.invoiceMinAmount = data.filters.invoiceMinAmount;
        return { invoices, filters };
      });

  static downloadPortfolio = (request: DownloadPortfolioRequest) =>
    BaseService.simpleFileDownloadGET(`${DEBTOR_PORTFOLIO_EXPORT_GET}?${request.toRouteParameters()}`);

  static getInvoices = (companyId: number) =>
    BaseService.simpleGET(`${DEBTOR_INVOICES_GET}/${companyId}`,
      data => data.map(d => {
        const invoice = plainToClass(Invoice, d);
        invoice.sellerCompany = plainToClass(Company, d.sellerCompany);
        return invoice;
      }));

  static getOpenInvoices = (companyId: number) =>
    BaseService.simpleGET(`${DEBTOR_INVOICES_OPEN_GET}/${companyId}`,
      data => data.map(d => {
        const invoice = plainToClass(Invoice, d);
        invoice.sellerCompany = plainToClass(Company, d.sellerCompany);
        return invoice;
      }));

  static getInvoicesToApprove = (companyId: number) =>
    BaseService.simpleGET(`${DEBTOR_INVOICES_APPROVE_GET}/${companyId}`,
      data => {
        const invoices = data.invoices.map(d => {
          const invoice = plainToClass(Invoice, d);
          invoice.sellerCompany = plainToClass(Company, d.sellerCompany);
          return invoice;
        });
        const filters = new InvoiceFilters();
        if (data.filters.sellerCompanies)
          filters.sellerCompanies = data.filters.sellerCompanies.map(d => plainToClass(Company, d));
        return { invoices, filters };
      });

  static getInvoicesToPay = (companyId: number) =>
    BaseService.simpleGET(`${DEBTOR_INVOICES_PAY_GET}/${companyId}`,
      data => {
        const invoices = data.invoices.map(d => {
          const invoice = plainToClass(Invoice, d);
          invoice.sellerCompany = plainToClass(Company, d.sellerCompany);
          return invoice;
        });
        const filters = new InvoiceFilters();
        if (data.filters.statusKeys)
          filters.statusKeys = data.filters.statusKeys;
        if (data.filters.sellerCompanies)
          filters.sellerCompanies = data.filters.sellerCompanies.map(d => plainToClass(Company, d));
        return { invoices, filters };
      });

  // Invoices - Actions
  static approveInvoice = (request: ApproveInvoiceRequest) =>
    BaseService.simplePOST(DEBTOR_INVOICES_APPROVE_POST, request, null);

  static payInvoice = (request: PayInvoiceRequest) =>
    BaseService.simplePOST(DEBTOR_INVOICES_PAY_POST, request, null);

  static getInvoicesToPortfolio = (companyId: number) =>
    BaseService.simpleGET(`${DEBTOR_INVOICES_PORTFOLIO_GET}/${companyId}`,
      data => data.map(d => {
        const invoice = plainToClass(Invoice, d);
        invoice.sellerCompany = plainToClass(Company, d.sellerCompany);
        invoice.debtorCompany = plainToClass(Company, d.debtorCompany);
        return invoice;
      }));

  static refuseInvoice = (request: RefuseInvoiceRequest) =>
    BaseService.simplePOST(DEBTOR_INVOICES_REFUSE_POST, request, null);

  // Offices
  static getOffices = (companyId: number) =>
    BaseService.simpleGET(`${DEBTOR_OFFICES_GET}/${companyId}`, data => data.map(d => plainToClass(Office, d)));

  static addOffice = (request: AddOfficeRequest) =>
    BaseService.simplePOST(DEBTOR_OFFICES_ADD_POST, request, null);

  static updateOffice = (request: UpdateOfficeRequest) =>
    BaseService.simplePOST(DEBTOR_OFFICES_UPDATE_POST, request, null);

  static getOfficePersons = (companyId: number, officeId: number) =>
    BaseService.simpleGET(`${DEBTOR_OFFICES_PERSONS_GET}/${companyId}/${officeId}`, data => data.map(d => plainToClass(OfficePerson, d)));

  static addOfficePerson = (request: AddOfficePersonRequest) =>
    BaseService.simplePOST(DEBTOR_OFFICES_PERSONS_ADD_POST, request, null);

  static updateOfficePerson = (request: UpdateOfficePersonRequest) =>
    BaseService.simplePOST(DEBTOR_OFFICES_PERSONS_UPDATE_POST, request, null);

  static linkPersonToOffice = (request: LinkPersonToOfficeRequest) =>
    BaseService.simplePOST(DEBTOR_OFFICES_PERSONS_LINK_POST, request, null);


  // Register Lead
  static registerLead = (request: RegisterLeadRequest) =>
    BaseService.simplePOST(DEBTOR_REGISTER_LEAD_POST, request, r => plainToClass(RegisterLeadResponse, r));

  static setFinancialInfo = (request: SetFinancialInfoRequest) =>
    BaseService.simplePOST(DEBTOR_REGISTER_LEAD_SET_FINANCIAL_INFO_POST, request, null);


  // Register Supplier
  static registerSupplier = (request: RegisterSupplierRequest) =>
    BaseService.simplePOST(DEBTOR_REGISTER_SUPPLIER_POST, request, null);

  // Supplier Bank Account
  static getAccountsToChangeToEdebex = (companyId: number) =>
    BaseService.simpleGET(`${DEBTOR_SUPPLIER_ACCOUNT_TO_EDEBEX}/${companyId}`, data => data.map(d => plainToClass(SupplierBankAccountChange, d)));

  static getAccountsToChangeFromEdebex = (companyId: number) =>
    BaseService.simpleGET(`${DEBTOR_SUPPLIER_ACCOUNT_FROM_EDEBEX}/${companyId}`, data => data.map(d => plainToClass(SupplierBankAccountChange, d)));

  static changeSupplierAccount = (request: ChangeSupplierAccountRequest) =>
    BaseService.simplePOST(DEBTOR_SUPPLIER_CHANGE_ACCOUNT, request, null);

  // TOKEN - Invoices
  static checkDebtorToken = (request: CheckDebtorTokenRequest) =>
    BaseService.anonymousPOST(DEBTOR_TOKEN_POST, request, data => plainToClass(Company, data));

  static getInvoicesToApproveToken = (token: string) =>
    BaseService.anonymousGET(`${DEBTOR_TOKEN_INVOICES_GET}/${token}`,
      data => data.map(d => {
        const invoice = plainToClass(Invoice, d);
        invoice.sellerCompany = plainToClass(Company, d.sellerCompany);
        invoice.debtorCompany = plainToClass(Company, d.debtorCompany);
        return invoice;
      }));

  static downloadInvoiceDocumentToken = (token: string, invoiceId: number) =>
    BaseService.simpleFileDownloadGET(`${DEBTOR_TOKEN_INVOICES_DOCUMENTS_GET}/${token}/${invoiceId}`);

  static downloadInvoiceRelatedDocumentsToken = (token: string, invoiceId: number) =>
    BaseService.simpleFileDownloadGET(`${DEBTOR_TOKEN_INVOICES_DOCUMENTS_RELATED_GET}/${token}/${invoiceId}`);

  static approveInvoiceToken = (request: ApproveInvoiceTokenRequest) =>
    BaseService.anonymousPOST(DEBTOR_TOKEN_INVOICES_APPROVE_POST, request, null);

  static disputeInvoiceToken = (request: DisputeInvoiceTokenRequest) =>
    BaseService.anonymousPOST(DEBTOR_TOKEN_INVOICES_DISPUTE_POST, request, null);

  static refuseInvoiceToken = (request: RefuseInvoiceTokenRequest) =>
    BaseService.anonymousPOST(DEBTOR_TOKEN_INVOICES_REFUSE_POST, request, null);

  // SIGN DEBTOR APPROVAL
  static signDebtorApproval = (request: SignDebtorApprovalRequest) =>
    BaseService.simplePOST(DEBTOR_APPROVAL_SIGN, request, response => plainToClass(UniversignTransaction, response));
}
