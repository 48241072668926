/* @flow */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function IconRefreshOutlined(props) {
  return (
    <SvgIcon viewBox="0 0 62.78 62.8" {...props}>
      <g>
        <path d="M421.57,266.13a31.4,31.4,0,1,0,30.93,31.39A31.51,31.51,0,0,0,421.57,266.13Zm-.49,58.61a27.19,27.19,0,1,1,27.22-27.08A27.16,27.16,0,0,1,421.08,324.74Z" transform="translate(-389.72 -266.13)"/>
        <path d="M403.9,296.9A1.74,1.74,0,0,0,406,295a12.59,12.59,0,0,1,2.52-7.63c3-4,7-5.71,11.92-5.72A15.33,15.33,0,0,1,433.34,288l-4.48,3.77.1.23,10.56,1c.33-3.63.65-7.11,1-10.59l-.27-.07-4,3.31c-.57-.62-1.11-1.18-1.62-1.76-6-6.77-16.27-7.09-22.46-4.18-6,2.84-10,9-9.75,15.28C402.41,296.15,402.94,296.8,403.9,296.9Z" transform="translate(-389.72 -266.13)"/>
        <path d="M438.69,296.33a1.84,1.84,0,0,0-1.92,1.84c0,.25,0,.5,0,.75a12.73,12.73,0,0,1-6.31,10.29c-4.8,2.9-9.85,2.77-14.92.75a15,15,0,0,1-6-4.89c1.63-1.32,3.18-2.57,4.72-3.83l-.09-.24-10.52-1.2c-.4,3.64-.79,7.12-1.17,10.6l.24.08,3.84-3c1.44,1.35,2.73,2.75,4.22,3.9,4.28,3.29,9.27,4.11,14.46,3.42,7-.93,11.92-4.78,14.3-11.54a22.75,22.75,0,0,0,.86-4.82A1.8,1.8,0,0,0,438.69,296.33Z" transform="translate(-389.72 -266.13)"/>
      </g>
    </SvgIcon>
  );
}
