/* @flow */

import React from 'react';
import { useHistory } from 'react-router-dom';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import AmountFormatterComponent from 'components/formatters/amount';
import DateFormatterComponent from 'components/formatters/date';
import DebtorGradeForBuyerComponent from 'components/debtorGrades/buyer';
import IntlMessageComponent from 'components/formatters/intlMessage';
import InvoiceMarketPlaceComponent from 'components/invoice/marketPlace';
import InvoiceNameComponent from 'components/invoice/name';
import PercentageFormatterComponent from 'components/formatters/percentage';
import StyledTableCellTextComponent from 'components/table/cellText';
import StyledTableCellNumbersComponent from 'components/table/cellNumbers';
import StyledTableHeadComponent from 'components/table/head';
import StyledTableRowComponent from 'components/table/row';
import InvoiceBatchIconTooltipComponent from 'components/invoice/batchIcon';
import TooltipComponent from 'components/toolTips/index';

import Invoice from 'models/buyers/invoices/invoice';

import { PROTECTED_BUYER_INVOICE_DETAILS } from 'constants/pageRoutes';

const columnData = [
  { id: 'id', align: 'left', isInvestmentProfile: false, translationKey: 'buyer.portfolio.invoiceId', walkme: 'bportfolio-column-nfacture' },
  { id: 'investmentProfile.name', align: 'left', isInvestmentProfile: true, translationKey: 'buyer.portfolio.investmentProfile', walkme: 'bportfolio-column-investmentProfile' },
  { id: 'statusKey', align: 'left', isInvestmentProfile: false, translationKey: 'buyer.portfolio.status', walkme: 'bportfolio-column-statut' },
  { id: 'debtorCompany.name', align: 'left', isInvestmentProfile: false, translationKey: 'buyer.portfolio.debtor', walkme: 'bportfolio-column-client' },
  { id: 'debtorCompany.insurerRating', align: 'left', isInvestmentProfile: false, translationKey: 'buyer.portfolio.insurerRating', walkme: 'bportfolio-column-gradefinancier' },
  { id: 'insuredPct', isInvestmentProfile: false, translationKey: 'buyer.portfolio.insuredPct', walkme: 'bportfolio-column-volumeassure' },
  { id: 'financedAmount', isInvestmentProfile: false, translationKey: 'buyer.portfolio.financedAmount', walkme: 'bportfolio-column-montantfinance' },
  { id: 'purchasePrice', isInvestmentProfile: false, translationKey: 'buyer.portfolio.purchasePrice', walkme: 'bportfolio-column-prixdachat' },
  { id: 'buyerInsuranceFee', isInvestmentProfile: false, translationKey: 'buyer.portfolio.buyerInsuranceFee' },
  { id: 'amountReceived', isInvestmentProfile: false, translationKey: 'buyer.portfolio.amountToBuyer', walkme: 'bportfolio-column-montanttransfere' },
  { id: 'discountPct', isInvestmentProfile: false, translationKey: 'buyer.portfolio.discountPct', walkme: 'bportfolio-column-reduction' },
  { id: 'purchasedDate', align: 'left', isInvestmentProfile: false, translationKey: 'buyer.portfolio.purchaseDate', walkme: 'bportfolio-column-purchaseDate' },
  { id: 'dueDate', align: 'left', isInvestmentProfile: false, translationKey: 'buyer.portfolio.dueDate', walkme: 'bportfolio-column-echeance' },
  { id: 'paymentReference', align: 'left', isInvestmentProfile: false, translationKey: 'buyer.portfolio.paymentReference', walkme: 'bportfolio-column-qrCodeBuyer' }
];

type BuyerInvoicesPortfolioTableViewComponentProps = {
  invoices: Invoice[];
  order: string;
  orderBy: string;
  page: number;
  pageSize: number;
  showInvestmentProfile: boolean;
  handlePageChange: (page: number) => void;
  handlePageSizeChange: (pageSize: number) => void;
  handleSortOrderChange: (property: string) => void;
};


export default function BuyerInvoicesPortfolioTableViewComponent(props: BuyerInvoicesPortfolioTableViewComponentProps) {
  const history = useHistory();

  const handlePageChange = (_, page) => props.handlePageChange(page);
  const handlePageSizeChange = event => props.handlePageSizeChange(event.target.value);
  const navigateToInvoiceDetails = id => history.push({ pathname: `${PROTECTED_BUYER_INVOICE_DETAILS}/${id}` });

  return (
    <React.Fragment>
      <Box py={5}>
        <Box component="div" overflow="auto">
          <Table>
            <StyledTableHeadComponent data-walkme="sportfolio-table-headline">
              <StyledTableRowComponent>
                {columnData.filter(column => props.showInvestmentProfile || !column.isInvestmentProfile).map(column => (
                  <StyledTableCellTextComponent align={column?.align ?? 'right'} key={column.id} padding="none">
                    <TableSortLabel
                      active={props.orderBy === column.id}
                      direction={props.order}
                      onClick={() => props.handleSortOrderChange(column.id)}
                      data-walkme={column.walkme || ''}
                    ><IntlMessageComponent id={column.translationKey} /></TableSortLabel>
                  </StyledTableCellTextComponent>
                ))}

                <StyledTableCellNumbersComponent padding="space" />
              </StyledTableRowComponent>
            </StyledTableHeadComponent>

            <TableBody bgcolor="white">
              {props.invoices.length === 0 &&
                <StyledTableRowComponent>
                  <StyledTableCellTextComponent colSpan={columnData.length}>
                    <Box textAlign="center">
                      <IntlMessageComponent id="general.noData" />
                    </Box>
                  </StyledTableCellTextComponent>
                </StyledTableRowComponent>
              }

              {props.invoices.slice(props.page * props.pageSize, (props.page * props.pageSize) + props.pageSize).map(i => (
                <StyledTableRowComponent
                  key={`invList-${i.id}`}
                  hover
                  onClick={() => navigateToInvoiceDetails(i.id)}
                  tabIndex={-1}
                >
                  <StyledTableCellTextComponent padding="none">
                    <Box component="span" display="flex" alignItems="center">
                      <Box component="span" flexGrow={1} px={0}>
                        <InvoiceNameComponent invoiceId={i.id} invoiceName={i.invoiceName} />
                      </Box>
                      {i.isBatch && <InvoiceBatchIconTooltipComponent />}
                      <InvoiceMarketPlaceComponent marketPlaceId={i.marketPlaceId} />
                    </Box>
                  </StyledTableCellTextComponent>

                  {props.showInvestmentProfile &&
                    <StyledTableCellTextComponent padding="none">
                      <Avatar style={{ fontSize: '0.8rem', height: '24px', width: '24px' }}>{i.investmentProfile.name.substring(0, 2).toUpperCase()}</Avatar>
                    </StyledTableCellTextComponent>
                  }

                  <StyledTableCellTextComponent padding="none">
                    <IntlMessageComponent id={`invoice.status.${i.statusKey}`} />
                  </StyledTableCellTextComponent>

                  <StyledTableCellTextComponent padding="none">
                    <strong>{i.debtorCompany.name}</strong>
                  </StyledTableCellTextComponent>

                  <StyledTableCellTextComponent padding="none">
                    <DebtorGradeForBuyerComponent value={i.debtorCompany.insurerRating} shortMessageWhenZero />
                  </StyledTableCellTextComponent>

                  <StyledTableCellNumbersComponent padding="none">
                    <PercentageFormatterComponent value={i.insuredPct} noFractionDigits />
                  </StyledTableCellNumbersComponent>

                  <StyledTableCellNumbersComponent padding="none">
                    <AmountFormatterComponent amount={i.financedAmount} />
                  </StyledTableCellNumbersComponent>

                  <StyledTableCellNumbersComponent padding="none">
                    <b><AmountFormatterComponent amount={i.purchasePrice} /></b>
                  </StyledTableCellNumbersComponent>

                  <StyledTableCellNumbersComponent padding="none" pageName="Tooltip.Buyer.InvoiceColumn" elementName="BuyerInsuranceFee">
                    <TooltipComponent pageName="Tooltip.Buyer.InvoiceColumn" elementName="BuyerInsuranceFee">
                      <b><AmountFormatterComponent amount={i.buyerInsuranceFee} /></b>
                    </TooltipComponent>
                  </StyledTableCellNumbersComponent>

                  <StyledTableCellNumbersComponent padding="none">
                    <AmountFormatterComponent amount={i.amountReceived} />
                  </StyledTableCellNumbersComponent>

                  <StyledTableCellNumbersComponent padding="none">
                    <PercentageFormatterComponent value={i.discountPct} />
                  </StyledTableCellNumbersComponent>

                  <StyledTableCellTextComponent padding="none">
                    <DateFormatterComponent date={i.purchasedDate} />
                  </StyledTableCellTextComponent>

                  <StyledTableCellTextComponent padding="none">
                    <DateFormatterComponent date={i.dueDate} />
                  </StyledTableCellTextComponent>

                  <StyledTableCellTextComponent padding="none">
                    {i.paymentReference}
                  </StyledTableCellTextComponent>

                  <StyledTableCellNumbersComponent padding="space" />
                </StyledTableRowComponent>
              ))}
            </TableBody>
          </Table>
        </Box>

        <Box display="flex" height={60}>
          <Box flexGrow={1}></Box>
          <Box justifyContent="flex-end">
            <TablePagination
              component="div"
              count={props.invoices.length}
              rowsPerPage={props.pageSize}
              page={props.page}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePageSizeChange}
              labelRowsPerPage={<IntlMessageComponent id="general.invoicePerPage" />}
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
            />
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}
