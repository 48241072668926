/* @flow */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function IconInvoiceDue15Days(props) {
  return (
    <SvgIcon viewBox="0 0 57.38 61.08" {...props}>
      <g>
        <path d="M237.05,472.79a.57.57,0,0,1-.17-.42v-4.8h-4.7a.55.55,0,0,1-.42-.17.57.57,0,0,1-.17-.42v-1.33a.61.61,0,0,1,.17-.42.59.59,0,0,1,.42-.17h4.7v-4.65a.56.56,0,0,1,.17-.43.6.6,0,0,1,.42-.16H239a.57.57,0,0,1,.43.16.56.56,0,0,1,.16.43v4.65h4.7a.57.57,0,0,1,.42.17.61.61,0,0,1,.17.42V467a.57.57,0,0,1-.16.42.53.53,0,0,1-.43.17h-4.7v4.8a.57.57,0,0,1-.16.42.54.54,0,0,1-.43.17h-1.48A.57.57,0,0,1,237.05,472.79Z" transform="translate(-223.21 -424.97)"/>
        <path d="M251.6,473.6a.57.57,0,0,1-.17-.41V460.48l-3.72,2.86a.57.57,0,0,1-.37.12.54.54,0,0,1-.46-.24l-1.06-1.38a.63.63,0,0,1-.13-.35A.57.57,0,0,1,246,461l5.51-4.28a1.1,1.1,0,0,1,.62-.18h2.21a.58.58,0,0,1,.42.18.57.57,0,0,1,.17.41v16.06a.57.57,0,0,1-.17.41.58.58,0,0,1-.42.18H252A.58.58,0,0,1,251.6,473.6Z" transform="translate(-223.21 -424.97)"/>
        <path d="M260.65,473.36a5.38,5.38,0,0,1-2.25-1.82,4.69,4.69,0,0,1-.82-2.44v-.05a.44.44,0,0,1,.16-.33.56.56,0,0,1,.36-.14h2.34a.66.66,0,0,1,.71.49,2.83,2.83,0,0,0,1.15,1.6,3.34,3.34,0,0,0,1.88.52,3.21,3.21,0,0,0,2.28-.84,3,3,0,0,0,.9-2.31,2.77,2.77,0,0,0-.9-2.15,3.25,3.25,0,0,0-2.28-.83,3.13,3.13,0,0,0-1.2.2,4,4,0,0,0-.92.54l-.22.15a1.17,1.17,0,0,1-.3.17.93.93,0,0,1-.29.05h-2.31a.49.49,0,0,1-.38-.16.51.51,0,0,1-.16-.38l.83-8.4a.72.72,0,0,1,.24-.5.76.76,0,0,1,.5-.19h9.26a.61.61,0,0,1,.43.16.57.57,0,0,1,.16.43v1.68a.6.6,0,0,1-.16.42.58.58,0,0,1-.43.17h-7.12l-.39,4a5.38,5.38,0,0,1,1.3-.61,6.83,6.83,0,0,1,1.93-.23,6.6,6.6,0,0,1,3,.68,5.48,5.48,0,0,1,2.19,1.94,5.29,5.29,0,0,1,.83,2.94,5.78,5.78,0,0,1-.84,3.14,5.48,5.48,0,0,1-2.36,2.07,8.24,8.24,0,0,1-3.55.73A8.33,8.33,0,0,1,260.65,473.36Z" transform="translate(-223.21 -424.97)"/>
      </g>
      <path d="M237.36,428.57a20.53,20.53,0,0,1,.55-3.43c.08-.22,1.17-.17,1.79-.15,2.72.11.6,2.34,1.79,3.49.17,0,.72.08,1.28.08,6.06,0,12.11,0,18.17,0,1.44,0,2.09-.34,1.85-1.85a11.5,11.5,0,0,1,0-1.67h3.39v3.49h6.11c5.47,0,8.31,2.85,8.31,8.33q0,20.36,0,40.7a13.21,13.21,0,0,1-.39,3.44,6.73,6.73,0,0,1-6.3,4.92c-3.19.11-6.39.07-9.59.07-10.9,0-21.8.05-32.69,0a12.17,12.17,0,0,1-5-1.13,6.08,6.08,0,0,1-3.34-5.42c-.09-1.54-.07-3.1-.07-4.65q0-19.11,0-38.22c0-5.09,2.92-8,8-8h6.11Zm-10.52,18c0,.55-.07,1-.07,1.4,0,10.12.06,20.24-.05,30.36a4,4,0,0,0,4.34,4.25c13.89,0,27.79,0,41.68,0,2.91,0,4.34-1.47,4.34-4.39V448.09c0-.51-.05-1-.07-1.54Zm-.05-3.66h50.28c0-2.57.05-5,0-7.5a3.5,3.5,0,0,0-3-3.09c-2.55-.11-5.11,0-7.78,0l-.18,3.47h-3.3v-1.63c0-1.86,0-1.86-1.93-1.87H241v3.39h-3.5v-3.35c-2.69,0-5.25-.08-7.79,0a3.37,3.37,0,0,0-2.92,2.81C226.75,437.68,226.79,440.24,226.79,442.89Z" transform="translate(-223.21 -424.97)"/>
    </SvgIcon>
  );
}
