export const CARD_VALUETYPE_AMOUNT = 1;
export const CARD_VALUETYPE_COUNT = 2;

/* Buyer */
export const CARD_TYPE_BUYER_TO_PAY = 1;
export const CARD_TYPE_BUYER_TO_BUY_EXCLUSIVE = 2;
export const CARD_TYPE_BUYER_TO_BUY_MARKETPLACE = 3;
export const CARD_TYPE_BUYER_AMOUNT_AVAILABLE = 4;
export const CARD_TYPE_BUYER_TRANSFER_IN_PROGRESS = 5;
export const CARD_TYPE_BUYER_WAITING = 6;
export const CARD_TYPE_BUYER_OVERDUE = 7;
export const CARD_TYPE_BUYER_PAID = 8;
export const CARD_TYPE_BUYER_CLOSED = 9;

/* Debtor */
export const CARD_TYPE_DEBTOR_AWAITING_VALIDATION = 1;
export const CARD_TYPE_DEBTOR_OVERDUE = 2;
export const CARD_TYPE_DEBTOR_AMOUNT_DUE = 3;
export const CARD_TYPE_DEBTOR_DUE_SOON = 4;
export const CARD_TYPE_DEBTOR_DUE_LONG = 5;

/* Confirming */
export const CARD_TYPE_CONFIRMING_CLLEFT = 1;
export const CARD_TYPE_CONFIRMING_NOTPURCHASED = 2;
export const CARD_TYPE_CONFIRMING_DUEMORE1MONTH = 3;
export const CARD_TYPE_CONFIRMING_DUEMORE2WEEKS = 4;
export const CARD_TYPE_CONFIRMING_DUEMORE1WEEK = 5;
export const CARD_TYPE_CONFIRMING_DUELESS1WEEK = 6;

/* ISO */
export const CARD_TYPE_ISO_IN_PROGRESS_LEADS = 1;
export const CARD_TYPE_ISO_IN_PROGRESS_FINANCED_AMOUNT = 2;
export const CARD_TYPE_ISO_FINANCED_AMOUNT = 3;
export const CARD_TYPE_ISO_IN_PROGRESS_UNFINANCED_AMOUNT = 4;
export const CARD_TYPE_ISO_UNFINANCED_AMOUNT = 5;
export const CARD_TYPE_ISO_LEADS_WON = 6;
export const CARD_TYPE_ISO_ACTIVE_SELLERS = 7;
export const CARD_TYPE_ISO_COMMISSION = 8;

/* Seller */
export const CARD_TYPE_SELLER_ACTION_SELLER = 1;
export const CARD_TYPE_SELLER_ACTION_DEBTOR = 2;
export const CARD_TYPE_SELLER_SOLD = 3;
export const CARD_TYPE_SELLER_WAITING_EDEBEX = 4;
export const CARD_TYPE_SELLER_TOTAL = 5;
export const CARD_TYPE_SELLER_UNFINANCED = 6;
