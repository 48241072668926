/* @flow */

import moment from 'moment/moment';

import DebtorCompany from 'models/companies/debtorCompany';

export default class InvoicePaymentResult {
  id: number;
  reference: string;

  issueDate: moment;
  dueDate: moment;

  debtorCompany: DebtorCompany;

  debtAmount: number;
  transactionPrice: number;
}
