/* @flow */

import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

import IntlMessageComponent from 'components/formatters/intlMessage';

type ConfirmationDialogComponentProps = {
  text: string;
  title: string;  
  onAccept: () => void;
  onClose: () => void;
}

export default function ConfirmationDialogComponent(props: ConfirmationDialogComponentProps) {
  return (
    <Dialog open onClose={props.onClose}>
      <DialogTitle>
        <IntlMessageComponent id={props.title} />
      </DialogTitle>

      <DialogContent>
        <Typography variant="body1" gutterBottom>
          <IntlMessageComponent id={props.text} />
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button
          color="primary"
          onClick={props.onClose}
        ><IntlMessageComponent id="general.cancel" /></Button>

        <Button
          color="primary"
          onClick={props.onAccept}
        ><IntlMessageComponent id="general.submit" /></Button>
      </DialogActions>
    </Dialog>
  );
}
