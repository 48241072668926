/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';

import Office from 'models/companies/office';

import DebtorService from 'services/DebtorService';
import IsoService from 'services/IsoService';
import {ROLE_DEBTOR,ROLE_ISO} from 'constants/companyRoles';

type OfficeFieldComponentProps = {
  activeCompanyId: number;
  activeCompanyRole: number;
}

type OfficeFieldComponentState = {
  isLoading: boolean;
  values: Office[];
}

class OfficeFieldComponent extends React.Component<OfficeFieldComponentProps, OfficeFieldComponentState> {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      values: []
    };
  }

  componentDidMount = async () => {
    if (this.props.activeCompanyRole !== ROLE_DEBTOR && this.props.activeCompanyRole !== ROLE_ISO)
      return;

    try {
      let offices = this.props.activeCompanyRole === ROLE_DEBTOR
        ? await DebtorService.getOffices(this.props.activeCompanyId)
        : await IsoService.getOffices(this.props.activeCompanyId);
      
      this.setState({values: offices, isLoading: false});
    } catch (error) {
      console.error(error);
    }
  }

  handleSelect = (value) => {
    if (value == null) return this.props.changeHandler(0);
    return this.props.changeHandler(value.id);
  };

  render() {
    const value = this.state.values.find(c => c.id === this.props.value) || null;

    return (
      <Autocomplete
        getOptionLabel={(value) => value.name}
        loading={this.state.isLoading}
        onChange={(_, newValue) => this.handleSelect(newValue)}
        openOnFocus
        options={this.state.values}
        renderInput={(params) => 
          <TextField
            {...params}
            label={this.props.label}
            error={this.props.error}
            helperText={this.props.error ? this.props.helperText : ''}
            fullWidth={this.props.fullWidth}
            margin={this.props.margin}
            required={this.props.required}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {this.state.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        }
        value={value}
      />
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id,
  activeCompanyRole: state.auth.user.activeCompany.role
});

export default connect(mapStateToProps)(OfficeFieldComponent);
