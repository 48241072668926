/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';
import IneligibleReasonTranslatorComponent from 'components/translators/ineligibleReasonTranslator';
import LinkMailToConstantComponent from 'components/constants/mailto';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

type IneligibleLeadComponentProps = {
  ineligibleReasonIds: number[];
  translationPrefix: string;
  successHandler: () => void;
};

export default function IneligibleLeadComponent(props: IneligibleLeadComponentProps) {
  const values = {
    supportEmail: <LinkMailToConstantComponent id="general.email.commercialSupport"/>
  };

  return (
    <React.Fragment>
      <PageSubTitleViewComponent id={`${props.translationPrefix}.ineligible.title`}/>
      
      <Typography variant="body1" gutterBottom>
        <IntlMessageComponent id={`${props.translationPrefix}.ineligible.text`}/>
      </Typography>

      <Box mt={3}>
        <ul>
          {props.ineligibleReasonIds.map(reasonId => (
            <li key={reasonId}><IneligibleReasonTranslatorComponent id={reasonId}/></li>
          ))}
        </ul>
      </Box>

      <Box mt={3} p={3} bgcolor="lightblue.main">
        <Typography variant="body1">
          <IntlMessageComponent id={`${props.translationPrefix}.ineligible.support`} values={values}/>
        </Typography>
      </Box>

      {props.successHandler &&
        <Box mt={5}>
          <ButtonComponent
            color="primary"
            variant="contained"
            onClick={props.successHandler}
          ><IntlMessageComponent id={`${props.translationPrefix}.ineligible.action`}/></ButtonComponent>
        </Box>
      }
    </React.Fragment>
  );
}
