/* @flow */

import Company from 'models/companies/company';

export default class InvoiceFilters {
  statusKeys: string[];

  supplierCompanies: Company[];

  invoiceMaxAmount : ?number;
  invoiceMinAmount : ?number;

  constructor() {
    this.statusKeys = [];
    this.supplierCompanies = [];
    this.invoiceMaxAmount = null;
    this.invoiceMinAmount = null;
  }
}
