/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import {withCookies, Cookies} from 'react-cookie';
import { withRouter } from 'react-router-dom';
import qs from 'qs';

import LoadingComponent from 'components/loading';

import CallbackSuccessViewComponent from './successView';
import CallbackViewComponent from './view';
import CallbackForm from './model';


import LeadService from 'services/LeadService';
import {handleApiFormResponse} from 'lib/forms';

type CallbackComponentProps = {
  cookies: Cookies;
  languageId: number;
}

type CallbackComponentState = {
  form: CallbackForm;
  formErrors: string[];
  isLoading: boolean;
  isProcessing: boolean;
  sentWithSuccess: boolean;
}

class CallbackComponent extends React.Component<CallbackComponentProps, CallbackComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      form: new CallbackForm(),
      formErrors: [],
      isLoading: true,
      isProcessing: false
    };
  }

  componentDidMount = async () => {
    try {
      // Retrieve and validate parameters from querystring
      const form = this.state.form;
      const queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
      if (queryParams.l !== undefined) {
        const value = queryParams.l;
        form.leadId = value;
      }
      this.setState({ form, isLoading: false });
    } catch (e) {
      console.error(e);
    }
  };

  handleHourChange = (event) => {
    const values = [];
    const form = this.state.form;

    form.hour.forEach(v => {
      if (v !== event.target.name) 
        values.push(v);
    });

    if (event.target.checked)
      values.push(event.target.name);
      
    form.hour = values;

    this.setState({ form });
  };

  handleDayChange = (event) => {
    const values = [];
    const form = this.state.form;

    form.day.forEach(v => {
      if (v !== event.target.name) 
        values.push(v);
    });

    if (event.target.checked)
      values.push(event.target.name);
      
    form.day = values;

    this.setState({ form });
  };

  submitForm = async () => {
    this.setState({ isProcessing: true });

    try {
      // create lead
      const request = this.state.form.toRequest();

      await LeadService.setCallbackTime(request);
      this.setState({ isProcessing: false, sentWithSuccess: true });
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }
  }

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    if (this.state.sentWithSuccess) {
      return (<CallbackSuccessViewComponent />)
    }

    return (
      <CallbackViewComponent
        form={this.state.form}
        isProcessing={this.state.isProcessing}
        //callbackTimeSelected={this.state.form.callbackTime === ''}
        submitForm={this.submitForm}
        handleHourChange={this.handleHourChange}
        handleDayChange={this.handleDayChange}
      />
    );
  }
}

const mapStateToProps = state => ({
  languageId: state.locale.languageId
});

export default withRouter(connect(mapStateToProps)(withCookies(CallbackComponent)));
