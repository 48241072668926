/* @flow */

import React from 'react';
import { useHistory } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import AmountFormatterComponent from 'components/formatters/amount';
import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';
import NumberFormatterComponent from 'components/formatters/number';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import PercentageFormatterComponent from 'components/formatters/percentage';
import SecurityComponent from 'components/security/index';

import BuyerInvestmentSummaryPerformance from 'models/buyers/dashboards/investmentSummaryPerformance';

import { PROTECTED_BUYER_PORTFOLIO } from 'constants/pageRoutes';
import TooltipComponent from 'components/toolTips/index';

type BuyerInvestmentSummaryPerformanceComponentProps = {
  model: BuyerInvestmentSummaryPerformance;
}

export default function BuyerInvestmentSummaryPerformanceComponent(props: BuyerInvestmentSummaryPerformanceComponentProps) {
  const history = useHistory();

  return (
    <Paper elevation={0} style={{ height: '100%' }}>
      <Box p={3} pageName="Tooltip.Buyer.Dashboard" elementName="InvestmentPerformance">
        <TooltipComponent pageName="Tooltip.Buyer.Dashboard" elementName="InvestmentPerformance" >
          <PageSubTitleViewComponent id="buyer.is.perf.title" data-walkme="bdashboard-title-performance" />
        </TooltipComponent>
        <Box mt={3}>
          <Grid container spacing={1} alignItems="stretch">
            <Grid item xs={6}>
              <Typography variant="body1">
                <IntlMessageComponent id="buyer.is.perf.investmentReturn" />
              </Typography>
              <Typography variant="body1">
                <Box component="span" color="green.main" fontSize="min(4vw, 30px);" fontWeight="fontWeightBold">
                  <AmountFormatterComponent amount={props.model.investmentReturn} minimumFractionDigits={0} />
                </Box>
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body1">
                <IntlMessageComponent id="buyer.is.perf.irr" />
              </Typography>
              <Typography variant="body1">
                <Box component="span" color="green.main" fontSize="min(4vw, 30px);" fontWeight="fontWeightBold">
                  <PercentageFormatterComponent value={props.model.irr} minimumFractionDigits={1} maximumFractionDigits={2} />
                </Box>
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body1">
                <IntlMessageComponent id="buyer.is.perf.nbrOfInvoices" />
              </Typography>
              <Typography variant="body1">
                <Box component="span" fontSize="min(4vw, 30px);" fontWeight="fontWeightBold">
                  <NumberFormatterComponent value={props.model.nbrOfInvoices} />
                </Box>
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body1">
                <IntlMessageComponent id="buyer.is.perf.avgInvestmentDays" />
              </Typography>
              <Typography variant="body1">
                <Box component="span" fontSize="min(4vw, 30px);" fontWeight="fontWeightBold">
                  <NumberFormatterComponent value={props.model.avgInvestmentDays} />&nbsp;<IntlMessageComponent id="buyer.is.perf.days" />
                </Box>
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <SecurityComponent pageName="POS.Buyers.Dashboard" elementName="btnSeePortfolio">

          <Box mt={3}>
            <ButtonComponent
              color="primary"
              variant="contained"
              onClick={() => history.push(PROTECTED_BUYER_PORTFOLIO)}
            ><IntlMessageComponent id="buyer.is.perf.btn.seePortfolio" /></ButtonComponent>
          </Box>

        </SecurityComponent>
      </Box>
    </Paper>
  );
}
