/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import EditOutlined from '@material-ui/icons/EditOutlined';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';

import ButtonComponent from 'components/buttons/button';
import CountryNameComponent from 'components/country/name';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import SecurityComponent from 'components/security';
import StyledTableCellTextComponent from 'components/table/cellText';
import StyledTableHeadComponent from 'components/table/head';
import StyledTableRowComponent from 'components/table/row';

import OfficePerson from 'models/companies/officePerson';

const columnData = [
  { id: 'lastName', translationKey: 'component.offices.persons.list.header.lastName' },
  { id: 'firstName', translationKey: 'component.offices.persons.list.header.firstName' },
  { id: 'countryId', translationKey: 'component.offices.persons.list.header.country' },
  { id: 'email', translationKey: 'component.offices.persons.list.header.email' },
  { id: 'phone', translationKey: 'component.offices.persons.list.header.phone' },
  { id: 'mobile', translationKey: 'component.offices.persons.list.header.mobile' },
];

type CompanyOfficePersonTableViewComponentProps = {
  officePersons: OfficePerson[];
  order: string;
  orderBy: string;
  page: number;
  pageSize: number;
  newOfficePerson: () => void;
  editOfficePerson: (officePerson: OfficePerson) => void;
  handlePageChange: (page: number) => void;
  handlePageSizeChange: (pageSize: number) => void;
  handleSortOrderChange: (property: string) => void;
}

export default function CompanyOfficePersonTableViewComponent(props: CompanyOfficePersonTableViewComponentProps) {

  const handlePageChange = (_, page) => props.handlePageChange(page);
  const handlePageSizeChange = event => props.handlePageSizeChange(event.target.value);

  return (
    <Box py={4}>
      <PageSubTitleViewComponent id="component.offices.persons.title" />

      <Box component="div" overflow="auto" mt={3}>
        <Table>
          <StyledTableHeadComponent>
            <StyledTableRowComponent>
              {columnData.map(column => (
                <StyledTableCellTextComponent key={column.id} padding="none" >
                  <TableSortLabel active={props.orderBy === column.id} direction={props.order} onClick={() => props.handleSortOrderChange(column.id)}>
                    <IntlMessageComponent id={column.translationKey} />
                  </TableSortLabel>
                </StyledTableCellTextComponent>
              ))}
              <StyledTableCellTextComponent padding="none" />
            </StyledTableRowComponent>
          </StyledTableHeadComponent>

          <TableBody bgcolor="white">
            {!props.officePersons.length && (
              <StyledTableRowComponent>
                <StyledTableCellTextComponent colSpan={columnData.length}>
                  <Box textAlign="center">
                    <IntlMessageComponent id="component.offices.persons.list.noData" />
                  </Box>
                </StyledTableCellTextComponent>
              </StyledTableRowComponent>
            )}

            {props.officePersons.slice(props.page * props.pageSize, (props.page * props.pageSize) + props.pageSize).map(officePerson => (
              <StyledTableRowComponent key={`officePerson-${officePerson.id}`}>
                <StyledTableCellTextComponent padding="none">
                  <b>{officePerson.lastName}</b>
                </StyledTableCellTextComponent>

                <StyledTableCellTextComponent padding="none">
                  <b>{officePerson.firstName}</b>
                </StyledTableCellTextComponent>

                <StyledTableCellTextComponent padding="none">
                  <CountryNameComponent countryId={officePerson.countryId} />
                </StyledTableCellTextComponent>

                <StyledTableCellTextComponent padding="none">
                  {officePerson.email}
                </StyledTableCellTextComponent>

                <StyledTableCellTextComponent padding="none">
                  {officePerson.phone}
                </StyledTableCellTextComponent>

                <StyledTableCellTextComponent padding="none">
                  {officePerson.mobile}
                </StyledTableCellTextComponent>

                <StyledTableCellTextComponent padding="none">
                  <SecurityComponent pageName="POS.OfficeDetail" elementName="btnEdit">
                    <Tooltip title={<IntlMessageComponent id="company.ubos.tooltip.edit" />}>
                      <IconButton onClick={() => props.editOfficePerson(officePerson)}>
                        <EditOutlined />
                      </IconButton>
                    </Tooltip>
                  </SecurityComponent>
                </StyledTableCellTextComponent>
              </StyledTableRowComponent>
            ))}
          </TableBody>
        </Table>
      </Box>

      <Box display="flex" height={60}>
        <Box flexGrow={1} mt={2}>
          <SecurityComponent pageName="POS.OfficeDetail" elementName="btnNew">
            <ButtonComponent
              color="primary"
              variant="contained"
              onClick={props.newOfficePerson}
            ><IntlMessageComponent id="component.offices.persons.list.button.addOfficePerson" /></ButtonComponent>
          </SecurityComponent>
        </Box>

        {props.officePersons.length > 10 &&
          <Box justifyContent="flex-end">
            <TablePagination
              component="div"
              count={props.officePersons.length}
              page={props.page}
              rowsPerPage={props.pageSize}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePageSizeChange}
              labelRowsPerPage={<IntlMessageComponent id="general.officePersonsPerPage" />}
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
            />
          </Box>
        }
      </Box>
    </Box>
  );
}