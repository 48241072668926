/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Paper from '@material-ui/core/Paper';

import ButtonComponent from 'components/buttons/button';
import FormErrorsComponent from 'components/formErrors';
import InputAmountComponent from 'components/formatters/inputAmount';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import SecurityComponent from 'components/security';
import UploadFileFieldComponent from 'components/formFields/upload';

import MandateForm from './model';
import Company from 'models/companies/company';

type MandateFormViewComponentProps = {
  errors: Map<string,?string>;
  form: MandateForm;
  formErrors: string[];
  sellers: Company[];
  isProcessing: boolean;
  mandateId: number;
  file: File;
  handleChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void;
  onCancel: () => void;
  submitForm: () => void;
  uploadFile: () => void;
  handleFail: () => void;
  removeHandler: () => void;
  handleSellerChange: () => void;
}

export default function MandateFormViewComponent(props: MandateFormViewComponentProps) {

  const options = props.sellers.map(seller => {
    const firstLetter = seller.name[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...seller,
    };
  });

  return (
    <React.Fragment>
      <Paper elevation={0}>
        <Box p={5}>
          <FormErrorsComponent formErrors={props.formErrors} />

          {props.mandateId > 0 && <PageSubTitleViewComponent id="page.mandates.form.title" />}
          {!props.mandateId && <PageSubTitleViewComponent id="page.mandates.form.create.title" />}

          <Box mt={3}>
            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
              <Grid item xs={12} md={6}>
                <Box px={4}>
                  {!props.mandateId &&
                    <Autocomplete
                      id="sellercompany"
                      options={options}
                      getOptionLabel={(option) => option.name}
                      openOnFocus
                      fullWidth
                      groupBy={(option) => option.firstLetter}
                      onChange={(_, newValue) => props.handleSellerChange(newValue)}
                      renderInput={(params) => <TextField {...params} required label={<IntlMessageComponent id="page.mandates.form.sellercompany" />} margin="normal" />}
                    />
                  }
                  <TextField
                    id="form.mandate.amount"
                    label={<IntlMessageComponent id="page.mandates.form.amount" />}
                    error={!!props.errors.get('amount')}
                    helperText={props.errors.get('amount') ? <IntlMessageComponent id={props.errors.get('amount')} /> : ''}
                    value={props.form.amount}
                    onChange={props.handleChange('amount')}
                    InputProps={{
                      inputComponent: InputAmountComponent,
                      startAdornment: <InputAdornment position="start">€</InputAdornment>
                    }}
                    fullWidth
                    margin="normal"
                    required
                  />
                  {!props.mandateId &&
                    <UploadFileFieldComponent
                      disabled={props.isProcessing}
                      label="page.mandates.form.button.documentupload"
                      successHandler={props.uploadFile}
                      failHandler={props.handleFail}
                      removeHandler={props.removeHandler}
                      displayProps={{ margin: 'normal', fullWidth: true, required: true }}
                    />}
                </Box>

                <Box mt={3}>
                  <ButtonComponent
                    color="default"
                    variant="contained"
                    onClick={props.onCancel}
                  ><IntlMessageComponent id="general.cancel" /></ButtonComponent>

                  <SecurityComponent pageName="POS.MandateDetail" elementName="btnSave">
                    <Box component="span" ml={1}>
                      <ButtonComponent
                        disabled={(props.mandateId > 0 && !props.form.amount) || (!props.mandateId && (!props.form.amount || !props.form.sellerCompanyId || !props.file))}
                        color="primary"
                        variant="contained"
                        onClick={props.submitForm}
                      ><IntlMessageComponent id="general.save" /></ButtonComponent>
                    </Box>
                  </SecurityComponent>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Paper>
    </React.Fragment>
  );
}
