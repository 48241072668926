/* @flow */

import React from 'react';

import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import {history} from 'store/index';

type LinkButtonComponentProps = {
  color: string,
  icon: any,
  label: IntlMessageComponent,
  url: string,
  variant: string,
  disabled: boolean
};

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
    textTransform: 'none !important'
  }
}));

export default function LinkButtonComponent(props: LinkButtonComponentProps) {
  const classes = useStyles();

  const onClick = () => {
    history.push(props.url);
  };

  return (
    <Button
      className={classes.button}
      color={props.color || "primary"}
      onClick={onClick}
      size={props.size || "medium"}
      variant={props.variant || "contained"}
      startIcon={props.icon || null}
      disabled={props.disabled}
    >{props.label}</Button>
  );
}
