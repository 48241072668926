/* @flow */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function IconUser(props) {
  return (
    <SvgIcon viewBox="0 0 56.37 61.39" {...props}>
      <path d="M449.67,327.75c-.42.84-.89,1.75-2,1.59a3.78,3.78,0,0,1-1.8-1c-.25-.23-.24-.81-.26-1.24a24.58,24.58,0,0,0-5.17-14.43,24.15,24.15,0,0,0-42.68,10.16,34,34,0,0,0-.46,4.41c-.12,1.4-.8,2.18-2,2.18a2,2,0,0,1-2.05-2.26,31,31,0,0,1,1.92-9.83c2.75-7.58,9.84-14.42,18.9-17-7.42-4.5-10.85-10.81-9.05-19.37a15.85,15.85,0,0,1,6.32-9.58,17,17,0,0,1,23,2.62,16.84,16.84,0,0,1,1.92,19.12,19.16,19.16,0,0,1-7.47,7.16c1.2.43,2.2.74,3.16,1.15a27.7,27.7,0,0,1,16.14,17.18,51.52,51.52,0,0,1,1.36,6.58c.07.36.12.72.19,1.08Zm-40.76-44.82a4,4,0,0,0-.26.75,11.73,11.73,0,0,0,3,9.7,12.74,12.74,0,0,0,20.74-1.81,12.08,12.08,0,0,0,1.82-7.71c-.06-.66-.26-1-1-.89-1.7.29-3.41.58-5.13.75-5.3.52-10.6.85-15.88-.13C411.11,283.39,410,283.16,408.91,282.93Zm23.93-4.05A12.73,12.73,0,0,0,420,272.07c-4.45.53-7.75,2.87-10.08,7C417.71,280.72,425.19,280,432.84,278.88Z" transform="translate(-393.3 -268)"/>
    </SvgIcon>
  );
}
