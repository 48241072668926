/* @flow */

import React from 'react';

import SignUpTab1Component from './tab1/index';
import SignUpTab2Component from './tab2/index';
import SignUpTab3Component from './tab3/index';
import SignUpTab4Component from './tab4/index';
import SignUpTab5Component from './tab5/index';
import SignUpTab6Component from './tab6/index';
import BlockedBuyerSignUpComponent from './blockedBuyerSignUp/index';
import IsoNoCompanySignUpComponent from './isoNoCompanySignUp/index';

type SignUpComponentProps = {
  email: string;
  token: string;
}

type SignUpComponentState = {
  email: string;
  emailToken: string;
  leadId: number;
  companyId: number;
  password: string;
  passwordToken: string;
  step: number;
}

class SignUpComponent extends React.Component<SignUpComponentProps, SignUpComponentState> {
  static SIGNUP_TAB1 = 0;
  static SIGNUP_TAB2 = 1;
  static SIGNUP_TAB3 = 2;
  static SIGNUP_TAB4 = 3;
  static SIGNUP_TAB5 = 4;
  static SIGNUP_TAB6 = 5;
  static SIGNUP_BUYERBLOCKED = 6;
  static SIGNUP_BUYERBLOCKED_SUCCESS = 7;
  static SIGNUP_ISONOCOMPANY = 8;
  static SIGNUP_ISONOCOMPANY_SUCCESS = 9;

  constructor(props) {
    super(props);

    this.state = {
      email: props.email,
      emailToken: props.token,
      leadId: 0,
      password: null,
      passwordToken: null,
      step: (props.email !== null && props.token !== null) ? SignUpComponent.SIGNUP_TAB4 : SignUpComponent.SIGNUP_TAB1
    };
  }

  successHandlerTab1 = (email: string, leadId: number, companyId: number, mustAnswerEligibilityTest: boolean, buyerBlocked: boolean) => {
    const step = buyerBlocked ? SignUpComponent.SIGNUP_BUYERBLOCKED : mustAnswerEligibilityTest ? SignUpComponent.SIGNUP_TAB2 : SignUpComponent.SIGNUP_TAB3;
    this.setState({ email, leadId, companyId, step });
  };
  successHandlerTab1NoCompany = () => this.setState({ step: SignUpComponent.SIGNUP_ISONOCOMPANY });

  successHandlerTab2 = () => this.setState({ step: SignUpComponent.SIGNUP_TAB3 });
  successHandlerTab3 = (email: string) => this.setState({ email });
  successHandlerTab4 = (passwordToken: string) => this.setState({ passwordToken, step: SignUpComponent.SIGNUP_TAB5 });
  successHandlerTab5 = (password: string) => this.setState({ password, step: SignUpComponent.SIGNUP_TAB6 });
  successHandlerBlockedBuyer = () => this.setState({ step: SignUpComponent.SIGNUP_BUYERBLOCKED_SUCCESS });
  successHandlerIsoNoCompany = () => this.setState({ step: SignUpComponent.SIGNUP_ISONOCOMPANY_SUCCESS });

  render() {
    return (
      <React.Fragment>
        {this.state.step === SignUpComponent.SIGNUP_TAB1 &&
          <SignUpTab1Component
            successHandler={this.successHandlerTab1}
            successHandlerTab1NoCompany={this.successHandlerTab1NoCompany}
          />
        }
        {this.state.step === SignUpComponent.SIGNUP_TAB2 &&
          <SignUpTab2Component
            email={this.state.email}
            leadId={this.state.leadId}
            successHandler={this.successHandlerTab2}
          />
        }
        {this.state.step === SignUpComponent.SIGNUP_TAB3 &&
          <SignUpTab3Component
            email={this.state.email}
            successHandler={this.successHandlerTab3}
          />
        }
        {this.state.step === SignUpComponent.SIGNUP_TAB4 &&
          <SignUpTab4Component
            email={this.state.email}
            token={this.state.emailToken}
            successHandler={this.successHandlerTab4}
          />
        }
        {this.state.step === SignUpComponent.SIGNUP_TAB5 &&
          <SignUpTab5Component
            email={this.state.email}
            token={this.state.passwordToken}
            successHandler={this.successHandlerTab5}
          />
        }
        {this.state.step === SignUpComponent.SIGNUP_TAB6 &&
          <SignUpTab6Component
            email={this.state.email}
            password={this.state.password}
          />
        }
        {this.state.step === SignUpComponent.SIGNUP_BUYERBLOCKED &&
          <BlockedBuyerSignUpComponent
            activeCompanyId={this.state.companyId}
            successHandler={this.successHandlerBlockedBuyer}
            success={false}
          />
        }
        {this.state.step === SignUpComponent.SIGNUP_BUYERBLOCKED_SUCCESS &&
          <BlockedBuyerSignUpComponent
            activeCompanyId={this.state.companyId}
            successHandler={this.successHandlerBlockedBuyer}
            success={true}
          />
        }
        {this.state.step === SignUpComponent.SIGNUP_ISONOCOMPANY &&
          <IsoNoCompanySignUpComponent
            activeCompanyId={this.state.companyId}
            successHandler={this.successHandlerIsoNoCompany}
            success={false}
          />
        }
        {this.state.step === SignUpComponent.SIGNUP_ISONOCOMPANY_SUCCESS &&
          <IsoNoCompanySignUpComponent
            activeCompanyId={this.state.companyId}
            successHandler={this.successHandlerIsoNoCompany}
            success={true}
          />
        }
      </React.Fragment>
    );
  }
}

export default SignUpComponent;
