/* @flow */

import React from 'react';
import { useParams } from "react-router-dom";

import ISOLeadDetailsComponent from 'app/isos/leads/details';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function ISOLeadDetailsPage() {
  const { id, reopenid } = useParams();

  return (
    <WebsitePageComponent noTitle noPaper>
      <ISOLeadDetailsComponent leadId={id} leadReopenedId={reopenid} />
    </WebsitePageComponent>
  );
}
