/* @flow */

export default class ChangeLeadIsoOfficeRequest {
    companyId: number;
    leadId: number;
    officeId: number;
  
    constructor(companyId: number, leadId: number, officeId: number) {
      this.companyId = companyId;
      this.leadId = leadId;
      this.officeId = officeId;
    }
  }
  