/* @flow */

import React from 'react';
import {useSelector} from 'react-redux';

import MaintenanceComponent from 'components/maintenance';
import CallbackComponent from 'app/callback';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function LeadOffersPage() {
  const maintenance = useSelector(state => state.locale.maintenance);

  if (maintenance) {
    return <MaintenanceComponent />
  }

  return (
    <WebsitePageComponent id="page.callback.title">
      <CallbackComponent />
    </WebsitePageComponent>
  );
}
