/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import LoadingComponent from 'components/loading';

import ConfirmingAddSupplierTab1ViewComponent from './view';
import RegisterSupplierForm from './model';

import {handleApiFormResponse, handleFormChange, handleFormValidation} from 'lib/forms';
import loadConstraints from 'lib/validation/loadConstraints';
import ConfirmingService from 'services/ConfirmingService';

type ConfirmingAddSupplierTab1ComponentProps = {
  activeCompanyId: number;
  successHandler: () => void;
}

type ConfirmingAddSupplierTab1ComponentState = {
  constraints: any;
  errors: Map<string,?string>;
  form: RegisterSupplierForm;
  formErrors: string[];
  isLoading: boolean;
  isProcessing: boolean;
}

class ConfirmingAddSupplierTab1Component extends React.Component<ConfirmingAddSupplierTab1ComponentProps, ConfirmingAddSupplierTab1ComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      constraints: null,
      errors: null,
      form: new RegisterSupplierForm(),
      formErrors: [],
      isLoading: true,
      isProcessing: false
    };
  }

  componentDidMount = async () => {
    try {
      // load constraints
      const constraints = await loadConstraints('Person', [ 'company']);

      this.setState({
        constraints,
        errors: new Map(Object.keys(constraints).map(e => [e, undefined])),
        isLoading: false
      });
    } catch (e) {
      console.error(e);
    }
  };

  handleChange = (fieldName: string) => (event) => handleFormChange.call(this, fieldName, event.target.value);

  handleCompanyChange = (company: ?Company) => {
    const form = this.state.form;
    form.company = company;

    const errors = this.state.errors;
    errors.delete('company');

    this.setState({ errors, form });
  };

  validateForm = () => handleFormValidation.call(this);

  submitForm = async () => {
    if (!this.validateForm()) return;

    this.setState({ formErrors: [], isProcessing: true });

    try {
      const request = this.state.form.toRequest(this.props.activeCompanyId);
      let supplierId = await ConfirmingService.addSupplier(request);
      this.props.successHandler(supplierId);
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }
  }

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <ConfirmingAddSupplierTab1ViewComponent
        errors={this.state.errors}
        form={this.state.form}
        formErrors={this.state.formErrors}
        isProcessing={this.state.isProcessing}
        handleChange={this.handleChange}
        handleCompanyChange={this.handleCompanyChange}
        handleTypeChange={this.handleTypeChange}
        handlePhoneChange={this.handlePhoneChange}
        submitForm={this.submitForm}
      />
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps)(ConfirmingAddSupplierTab1Component);
