/* @flow */

import React from 'react';

import PaymentCancelledComponent from 'app/payments/cancelled';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function OgonePaymentCancelledPage() {
  return (
    <WebsitePageComponent id="page.payment.title">
      <PaymentCancelledComponent />
    </WebsitePageComponent>
  );
}
