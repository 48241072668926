/* @flow */

import React from 'react';

import MGMHeaderComponent from './header/index';
import MGMReferralComponent from './referral/index';

export default function SellerMGMComponent() {
  return (
    <React.Fragment>
      <MGMHeaderComponent />
      <MGMReferralComponent />
    </React.Fragment>
  );
}
