/* @flow */

import React, {useState} from 'react';

import Box from '@material-ui/core/Box';

import ButtonComponent from 'components/buttons/button';
import DebtorInvoicesToPayPopupComponent from 'app/debtors/invoicesToPayPopup';
import IntlMessageComponent from 'components/formatters/intlMessage';

type DebtorInvoiceDetailsActionPayViewComponentProps = {
  invoiceId: number;
  successHandler: () => void;
}

export default function DebtorInvoiceDetailsActionPayViewComponent(props: DebtorInvoiceDetailsActionPayViewComponentProps) {
  const [showPopup, setShowPopup] = useState(false);

  return (
    <React.Fragment>
      <Box mt={3}>
        <ButtonComponent
          color="primary"
          variant="contained"
          onClick={() => setShowPopup(true)}
        ><IntlMessageComponent id="general.btn.pay" /></ButtonComponent>
      </Box>

      {showPopup &&
        <DebtorInvoicesToPayPopupComponent
          invoiceIds={[props.invoiceId]}
          handleCancel={() => setShowPopup(false)}
          handleSuccess={props.successHandler}
        />
      }
    </React.Fragment>
  );
}
