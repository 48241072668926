/* @flow */

import React from 'react';
import { connect } from 'react-redux';

import LoadingComponent from 'components/loading';

import MandateFormViewComponent from './view';

import MandateForm from './model';

import IsoService from 'services/IsoService';
import DocumentService from 'services/DocumentService';
import { handleApiFormResponse, handleFormChange, handleFormValidation } from 'lib/forms';
import { ISO_MANDATES_UPLOAD } from 'constants/apiRoutes';
import loadConstraints from 'lib/validation/loadConstraints';
import NotificationHelper from 'lib/notifications';
import Company from 'models/companies/company';

type MandateFormComponentProps = {
  activeCompanyId: number;
  mandateId: number;
  onFormCancel: () => void;
  onFormSuccess: () => void;
};

type MandateFormComponentState = {
  constraints: any;
  sellers: Company[];
  errors: Map<string,?string>;
  form: MandateForm;
  formErrors: string[];
  isLoading: boolean;
  isProcessing: boolean;
  file: File;
};

class MandateFormComponent extends React.Component<MandateFormComponentProps, MandateFormComponentState> {
  constructor(props) {
    super(props);
    this.state = {
      constraints: null,
      errors: null,
      form: null,
      formErrors: [],
      isLoading: true,
      isProcessing: false
    };
  }

  componentDidMount = async () => {
    try {
      const constraints = await loadConstraints('Mandate', ['amount']);
      const sellers = await IsoService.getAvailableSellers(this.props.activeCompanyId);

      this.setState({
        constraints,
        errors: new Map(Object.keys(constraints).map(e => [e, undefined])),
        form: new MandateForm(),
        isLoading: false,
        sellers: sellers.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
      });
    } catch (e) {
      console.error(e);
    }
  }

  handleChange = (fieldName: string) => (event) => handleFormChange.call(this, fieldName, event.target.value);
  validateForm = () => handleFormValidation.call(this);
  handleSellerChange = (value: Company) => {
    handleFormChange.call(this, "sellerCompanyId", value?.id);
  }

  handleFail = () => {
    NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR, 'notifications.file.upload.wrongformat');
  }
  uploadFile = (file: File) => {
    this.setState({ file });
  }
  removeHandler = () => {
    this.setState({ file: null });
  }

  submitForm = async () => {
    if (!this.validateForm()) return;
    this.setState({ formErrors: [], isProcessing: true });

    try {
      if (this.props.mandateId) {
        const request = this.state.form.toUpdateRequest(this.props.activeCompanyId, this.props.mandateId);
        await IsoService.updateMandate(request);
      } else {
        const request = this.state.form.toCreateRequest(this.props.activeCompanyId);
        let mandateId = await IsoService.createMandate(request);
        await DocumentService.sendDocument2(ISO_MANDATES_UPLOAD, this.state.file, { isoCompanyId: this.props.activeCompanyId, mandateId });
      }
      NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS);
      this.props.onFormSuccess();
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }
  }

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <MandateFormViewComponent
        errors={this.state.errors}
        form={this.state.form}
        formErrors={this.state.formErrors}
        isProcessing={this.state.isProcessing}
        handleChange={this.handleChange}
        onCancel={this.props.onFormCancel}
        submitForm={this.submitForm}
        mandateId={this.props.mandateId}
        uploadFile={this.uploadFile}
        handleFail={this.handleFail}
        removeHandler={this.removeHandler}
        sellers={this.state.sellers}
        handleSellerChange={this.handleSellerChange}
        file={this.state.file}
      />
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps)(MandateFormComponent);
