/* @flow */

import moment from 'moment';
import BankAccount from 'models/companies/bankAccount';

export default class CompanyInfo {
  companyId: number;
  countryId: number;

  phone: string;
  isin: string;

  activities: string;
  mainActivityCountryId: number;
  sourceOfFunds: string;

  orias: boolean;
  isIso: boolean;

  companyCardOnlineCode: string;
  commercialRegistrationOnlineCode: string;
  commercialRegistrationOnlineCodeExpiry: moment;

  bankAccount: BankAccount
}
