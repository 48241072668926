/* @flow */

import { plainToClass } from 'class-transformer';

import BaseService from './api/baseService';

import {
  SELLER_INVOICES_PORTFOLIO_GET,
  SELLER_INVOICES_PORTFOLIO_EXPORT_GET,
  SELLER_INVOICES_PAYMENT_REPORT_GET,
  SELLER_KPIS_GET,
  SELLER_PROFILE_SUMMARY_GET,
  SELLER_REFERRAL_GET,
  SELLER_REFERRAL_NOTE_POST,
  SELLER_REFERRAL_POST
} from 'constants/apiRoutes';

import GetInvoicesPortfolioExportRequest from 'models/requests/sellers/getInvoicesPortfolioExportRequest';
import RegisterReferralNoteRequest from 'models/requests/sellers/registerReferralNoteRequest';
import RegisterReferralRequest from 'models/requests/sellers/registerReferralRequest';

import RegisterReferralResponse from 'models/responses/sellers/registerReferralResponse';

import DebtorCompany from 'models/companies/debtorCompany';
import Invoice from 'models/sellers/invoices/invoice';
import InvoiceFilters from 'models/sellers/invoices/invoiceFilters';
import Kpi from 'models/dashboards/kpi';
import ProfileSummaryCard from 'models/dashboards/profileSummaryCard';
import Referral from 'models/sellers/referral';

export default class SellerService {

  // Dashboard
  static getKPIs = (companyId: number) =>
    BaseService.simpleGET(`${SELLER_KPIS_GET}/${companyId}`, data => data.map(d => plainToClass(Kpi, d)));

  static getProfileSummary = (companyId: number) =>
    BaseService.simpleGET(`${SELLER_PROFILE_SUMMARY_GET}/${companyId}`, data => data.map(d => plainToClass(ProfileSummaryCard, d)));

  // invoices
  static getInvoicesPortfolio = (companyId: number) =>
    BaseService.simpleGET(`${SELLER_INVOICES_PORTFOLIO_GET}/${companyId}`,
      data => {
        const invoices = data.invoices.map(inv => SellerService.mapInvoice(inv));
        const filters = SellerService.mapInvoiceFilters(data.filters);
        return { invoices, filters };
      });

  static getInvoicesPortfolioExport = (request: GetInvoicesPortfolioExportRequest) =>
    BaseService.simpleFileDownloadGET(`${SELLER_INVOICES_PORTFOLIO_EXPORT_GET}?${request.toRouteParameters()}`);


  static getPaymentReport = (request: GetInvoicesPortfolioExportRequest) =>
    BaseService.simpleFileDownloadGET(`${SELLER_INVOICES_PAYMENT_REPORT_GET}?${request.toRouteParameters()}`);

  static mapInvoice = (inv) => {
    const invoice = plainToClass(Invoice, inv);
    invoice.debtorCompany = plainToClass(DebtorCompany, inv.debtorCompany);
    return invoice;
  }

  static mapInvoiceFilters = (data) => {
    const filters = new InvoiceFilters();
    filters.statusKeys = data.statusKeys;
    return filters;
  }

  // Referral
  static getReferral = (companyId: number) =>
    BaseService.simpleGET(`${SELLER_REFERRAL_GET}/${companyId}`, data => data.map(d => plainToClass(Referral, d)));

  static registerReferral = (request: RegisterReferralRequest) =>
    BaseService.simplePOST(SELLER_REFERRAL_POST, request, c => plainToClass(RegisterReferralResponse, c));

  static registerReferralNote = (request: RegisterReferralNoteRequest) =>
    BaseService.simplePOST(SELLER_REFERRAL_NOTE_POST, request, null);
}
