export class ApiError extends Error {
  code: number;
  exceptionId: string;
  
  constructor(message: string, code: number, exceptionId: string) {
    super(message);
    this.code = code;
    this.exceptionId = exceptionId;
  }
}

export class ApiValidationError extends ApiError {
  formErrors: string[];
  
  constructor(message: string, code: number, exceptionId: string, formErrors: Array) {
    super(message, code, exceptionId);
    this.formErrors = formErrors;
  }
}

export class ApiSignInError extends ApiError {
  error: string;
  
  constructor(message: string, code: number, error: string) {
    super(message, code, null);
    this.error = error;
  }
}
