/* @flow */

import CommissionMembershipIsoPerson from './commissionMembershipIsoPerson';

export default class CommissionMembershipIsoOffice {
  id: number;
  name: string;
  persons: CommissionMembershipIsoPerson[];
  membershipFeeCouponAmountUsed: number;
  membershipFee: number;
  membershipCommission: number;

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
    this.persons = [];
    this.membershipFeeCouponAmountUsed = 0;
    this.membershipFee = 0;
    this.membershipCommission = 0;
  }
}
