/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Rating from '@material-ui/lab/Rating';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';

import ComponentWrapperComponent from 'components/websiteStructure/componentWrapper';
import IconSquare from 'components/icons/square';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import PercentageFormatterComponent from 'components/formatters/percentage';

import {kpiData} from './data';

type BuyerInvoiceKPIsViewComponentProps = {
  kpis: KPI[];
}

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.green.main
  }
}));

export default function BuyerInvoiceKPIsViewComponent(props: BuyerInvoiceKPIsViewComponentProps) {
  const classes = useStyles();

  if (!props.kpis) return null;

  return (
    <ComponentWrapperComponent small>
      <PageSubTitleViewComponent id="buyer.invoiceDetails.kpis.title" />

      <Box mt={3}>
        <Grid container direction="row" justify="flex-start" alignItems="stretch" spacing={1}>  
          {props.kpis.map(kpi => {
            const cardInfo = kpiData.find(d => d.name === kpi.name);

            return (
              <Grid item xs={6} key={kpi.name}>
                <Box px={3}>
                  <Typography variant="body1" gutterBottom>
                    <IntlMessageComponent id={cardInfo.translationKey} data-walkme={kpi.walkme} />
                  </Typography>

                  <Rating
                    emptyIcon={<IconSquare color="disabled" fontSize="inherit" />}
                    icon={<IconSquare className={classes.icon} fontSize="inherit" />}
                    max={10}
                    readOnly
                    value={Math.floor(kpi.value / 10)}
                  />

                  <Typography variant="body1">
                    <Box component="span" fontSize={30} fontWeight="fontWeightBold" data-walkme={cardInfo.walkme}>
                      <PercentageFormatterComponent value={kpi.value} noFractionDigits />
                    </Box>
                  </Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
     </ComponentWrapperComponent>
  );
}
