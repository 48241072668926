/* @flow */

import {
  ON_HIDE_AUTH_LOADER,
  ON_SHOW_AUTH_LOADER,
  SIGNIN_EDEBEX_USER_ERROR,
  SIGNIN_EDEBEX_USER_SERVER_ERROR,
  SIGNIN_EDEBEX_IMPERSONATE_ERROR,
  SIGNIN_EDEBEX_USER_SUCCESS,
  SIGNOUT_EDEBEX_USER_SUCCESS,
  SWITCH_USER_COMPANY_SUCCESS,
  SWITCH_USER_COMPANY_ROLE_SUCCESS,
  SWITCH_USER_COMPANY_INVESTMENTPROFILE_SUCCESS,
  ADD_USER_COMPANY_SUCCESS,
  SET_IDENTITY_DOCUMENTS_SUCCESS,
  DISABLE_USER_COMPANY_INVESTMENTPROFILE_SUCCESS
} from '../actionTypes';

import { ROLE_BUYER } from 'constants/companyRoles';


const INIT_STATE = {
  loader: false,
  initialLoginTestDone: false,
  showServerErrorMessage: false,
  showLoginFailedMessage: false,
  loginFailedMessage: '',
  showImpersonateFailedMessage: false,
  impersonateFailedMessage: '',
  user: null
};


const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {

    case ON_HIDE_AUTH_LOADER: {
      return {
        ...state,
        loader: false
      };
    }

    case ON_SHOW_AUTH_LOADER: {
      return {
        ...state,
        loader: true
      };
    }

    case SIGNIN_EDEBEX_USER_ERROR: {
      return {
        ...state,
        loader: false,
        showServerErrorMessage: false,
        showLoginFailedMessage: true,
        loginFailedMessage: action.payload.error.error,
        showImpersonateFailedMessage: false,
        impersonateFailedMessage: '',
        user: null
      };
    }

    case SIGNIN_EDEBEX_USER_SERVER_ERROR: {
      return {
        ...state,
        loader: false,
        showServerErrorMessage: true,
        showLoginFailedMessage: false,
        loginFailedMessage: '',
        showImpersonateFailedMessage: false,
        impersonateFailedMessage: '',
        user: null
      };
    }

    case SIGNIN_EDEBEX_IMPERSONATE_ERROR: {
      return {
        ...state,
        loader: false,
        showServerErrorMessage: false,
        showLoginFailedMessage: false,
        loginFailedMessage: '',
        showImpersonateFailedMessage: true,
        impersonateFailedMessage: action.payload.error,
        user: null
      };
    }

    case SIGNIN_EDEBEX_USER_SUCCESS: {
      if (action.payload.user !== null)
        action.payload.user.updatedCount = 0;

      return {
        ...state,
        loader: false,
        initialLoginTestDone: true,
        showServerErrorMessage: false,
        showLoginFailedMessage: false,
        loginFailedMessage: '',
        showImpersonateFailedMessage: false,
        impersonateFailedMessage: '',
        user: action.payload.user
      };
    }

    case SIGNOUT_EDEBEX_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        showServerErrorMessage: false,
        showLoginFailedMessage: false,
        loginFailedMessage: '',
        showImpersonateFailedMessage: false,
        impersonateFailedMessage: '',
        user: null
      };
    }

    case SWITCH_USER_COMPANY_SUCCESS: {
      return {
        ...state,
        loader: false,
        user: {
          ...state.user,
          activeCompany: action.payload.company,
          updatedCount: state.user.updatedCount + 1,
          companies: state.user.companies.map((c) => {
            if (c.id === action.payload.company.id) {
              return action.payload.company;
            }
            return c;
          })
        }
      };
    }

    case SWITCH_USER_COMPANY_ROLE_SUCCESS: {
      let ip = null;
      if (action.payload.role === ROLE_BUYER) {
        ip = state.user.activeCompany.roleBuyer.investmentProfiles[0];
      }

      return {
        ...state,
        loader: false,
        user: {
          ...state.user,
          activeCompany: {
            ...state.user.activeCompany,
            role: action.payload.role,
            investmentProfile: ip
          }
        }
      };
    }

    case SWITCH_USER_COMPANY_INVESTMENTPROFILE_SUCCESS: {
      return {
        ...state,
        loader: false,
        user: {
          ...state.user,
          activeCompany: {
            ...state.user.activeCompany,
            investmentProfile: action.payload.investmentProfile
          }
        }
      };
    }

    case ADD_USER_COMPANY_SUCCESS: {
      return {
        ...state,
        loader: false,
        user: action.payload.user
      };
    }

    case SET_IDENTITY_DOCUMENTS_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          hasProvidedPersonalDocuments: true
        }
      };
    }

    case DISABLE_USER_COMPANY_INVESTMENTPROFILE_SUCCESS: {
      const newState = {
        ...state,
        loader: false,
        user: {
          ...state.user,
          activeCompany: {
            ...state.user.activeCompany,
            investmentProfile: {
              ...state.user.activeCompany.investmentProfile,
              isActive: false
            }
          }
        }
      };
      const ip = newState.user.activeCompany.roleBuyer.investmentProfiles.find(i => i.id === action.payload.investmentProfileId);
      if (ip) ip.isActive = false;
      return newState;
    }

    default:
      return state;
  }
};

export default reducer;
