/* @flow */

import React from 'react';
import {useSelector} from 'react-redux'

import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';

import IconPhoneComponent from 'components/icons/phone';

export default function WebsiteHeaderPhoneContactComponent() {

  const key = 'general.phone.support';
  const constants = useSelector(state => state.locale.constants);
  const constant = constants.find(c => c.key === key);
  if (!constant) throw new Error(`Missing constants: ${key}`);

  return (
    <IconButton
      component={Link}
      href={`tel:${constant.value}`}
    >
      <IconPhoneComponent />
    </IconButton>
  );
}

