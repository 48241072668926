/* @flow */

import moment from 'moment';
import Person from 'models/users/person';

import SetPersonInfoRequest from 'models/requests/register/setPersonInfoRequest';

export default class PersonalInfoForm {
  titleId: number;

  lastName: string;
  firstName: string;

  email: string;
  mobile: string;
  phone: string;

  languageId: number;

  addressLine1: string;
  addressLine2: string;
  zipCode: string;
  city: string;
  countryId: number;

  nationalityId: number;
  birthCountryId: number;
  birthDate: moment;
  birthPlace: string;

  registrationTypeId: number;
  registrationNumber: string;
  registrationExpireDate: moment;

  constructor(person: Person) {
    this.titleId = person.titleId;

    this.lastName = person.lastName;
    this.firstName = person.firstName;

    this.email = person.email;
    this.mobile = person.mobile;
    this.phone = person.phone;

    this.languageId = person.languageId;

    this.addressLine1 = person.addressLine1;
    this.addressLine2 = person.addressLine2;
    this.zipCode = person.zipCode;
    this.city = person.city;
    this.countryId = person.countryId;

    this.nationalityId = person.nationalityId;
    this.birthCountryId = person.birthCountryId;
    this.birthDate = person.birthDate;
    this.birthPlace = person.birthPlace;

    this.registrationTypeId = person.registrationTypeId;
    this.registrationNumber = person.registrationNumber;
    this.registrationExpireDate = person.registrationExpireDate;
  }

  toRequest(companyId: number) {
    const request = new SetPersonInfoRequest();
    request.companyId = companyId;
    request.titleId = this.titleId;
    request.lastName = this.lastName;
    request.firstName = this.firstName;
    request.mobile = this.mobile;
    request.phone = this.phone;
    request.addressLine1 = this.addressLine1;
    request.addressLine2 = this.addressLine2;
    request.zipCode = this.zipCode;
    request.city = this.city;
    request.countryId = this.countryId;
    request.nationalityId = this.nationalityId;
    request.birthCountryId = this.birthCountryId;
    request.birthDate = moment(this.birthDate).format('YYYY/MM/DD');
    request.birthPlace = this.birthPlace;
    request.registrationTypeId = this.registrationTypeId;
    request.registrationNumber = this.registrationNumber;
    request.registrationExpireDate = moment(this.registrationExpireDate).format('YYYY/MM/DD');
    return request;
  }
}
