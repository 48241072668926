import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import SignInFormComponent from './signInForm';
import SignInBlockedComponent from './signInBlocked';

import {userSignInAction} from 'store/actions/authActions';
import UserService from 'services/UserService';

type SignInComponentProps = {
  showLoginFailedMessage: boolean;
  showServerErrorMessage: boolean;
};

type SignInComponentState = {
  identifier: string;
  password: string;
  twofactorpin: string;
  hasTwoFactor: boolean;
};

class SignInComponent extends React.Component<SignInComponentProps, SignInComponentState> {

  constructor(props) {
    super(props);
    this.state = {
      identifier: '',
      password: '',
    };
  }

  onLogin = async () => {
    let hasTwoFactor = await UserService.checkUserHasTwoFactor(this.state.identifier);
    if (hasTwoFactor === true && !this.state.hasTwoFactor)
    {
      this.setState({hasTwoFactor, showLoginFailedMessage: false});
      return;
    }
    const returnUrl = this.props.location.state === undefined
      ? null : this.props.location.state.from.pathname;
    this.props.userSignInAction(this.state.identifier, this.state.password, returnUrl, this.state.twofactorpin);
  };

  handleLoginChange = (event) => {
    this.setState({identifier: event.target.value});
  }

  handlePasswordChange = (event) => {
    this.setState({password: event.target.value});
  }

  handleTwoFactorPinChange = (event) => {
    this.setState({twofactorpin: event.target.value});
  }

  render() {
    if (this.props.loginFailedMessage === 'blocked_user') {
      return (<SignInBlockedComponent />);
    }
    return (
      <SignInFormComponent
          identifier={this.state.identifier}
          password={this.state.password}
          hasTwoFactor={this.state.hasTwoFactor}
          handleLoginChange={this.handleLoginChange}
          handlePasswordChange={this.handlePasswordChange}
          handleTwoFactorPinChange={this.handleTwoFactorPinChange}
          onLogin={this.onLogin}
        />
    );
  }
}

const mapStateToProps = state => ({
  showLoginFailedMessage: state.auth.showLoginFailedMessage,
  loginFailedMessage: state.auth.loginFailedMessage,
  showServerErrorMessage: state.auth.showServerErrorMessage
});

export default withRouter(connect(mapStateToProps, {userSignInAction})(SignInComponent));
