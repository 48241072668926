/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import DebtorCompany from 'models/companies/debtorCompany';

import {history} from 'store/index';
import {PROTECTED_ISO_OFFER} from 'constants/pageRoutes';

type RegisterLeadTab6ComponentProps = {
  debtorCompany: ?DebtorCompany;
  leadId: number;
};

export default function RegisterLeadTab6Component(props: RegisterLeadTab6ComponentProps) {

  const onButtonClick = () => {
    history.push({
      pathname: PROTECTED_ISO_OFFER,
      state: {
        debtorCompany: props.debtorCompany,
        leadId: props.leadId
      }
    });
  }

  return (
    <React.Fragment>
      <PageSubTitleViewComponent id="page.iso.registerLead.tab6.title" />

      <Typography variant="body1" gutterBottom>
        <IntlMessageComponent id="page.iso.registerLead.tab6.text" />
      </Typography>

      <Box mt={5}>
        <ButtonComponent
          color="primary"
          variant="contained"
          onClick={onButtonClick}
        ><IntlMessageComponent id="page.iso.registerLead.tab6.button" /></ButtonComponent>
      </Box>
    </React.Fragment>
  );
}
