/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import ProvidedDocument from 'models/documents/providedDocument';

type ConfirmingInvoiceDetailsDocumentsViewComponentProps = {
  activeDocumentId: number;
  documents: ProvidedDocument[];
  selectDocument: (documentId: number) => void;
}

export default function ConfirmingInvoiceDetailsDocumentsViewComponent(props: ConfirmingInvoiceDetailsDocumentsViewComponentProps) {

  const activeDocument = props.documents.find(d => d.id === props.activeDocumentId);

  return (
    <React.Fragment>
      <PageSubTitleViewComponent id="debtor.invoiceDetails.documents.title"  data-walkme="debtor-invoiceDetails-documents" />

      <Box mt={3}>
        <ButtonGroup color="primary" aria-label="outlined primary button group">
          {props.documents.map(d => (
            <Button
              variant={d.id === props.activeDocumentId ? 'contained' : 'outlined'}
              onClick={() => props.selectDocument(d.id)}
            ><IntlMessageComponent id={`document.type.${d.code}`} /></Button>
          ))}
        </ButtonGroup>
      </Box>

      <Box mt={3} px={3}>
        <iframe src={activeDocument.fileURL} title="pdf" width="100%" height="500px"></iframe>
      </Box>
    </React.Fragment>
  );
}
