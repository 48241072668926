/* @flow */

import React from 'react';
import { Link } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';

import ButtonComponent from 'components/buttons/button';
import FormErrorsComponent from 'components/formErrors';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PasswordRulesComponent from 'components/passwordRules';

import ResetPasswordForm from './model';

import { PROTECTED_APP } from 'constants/pageRoutes';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import TooltipComponent from 'components/toolTips/index';

type ChangePasswordViewComponentProps = {
  errors: Map<string,?string>;
  form: ResetPasswordForm;
  formErrors: string[];
  isProcessing: boolean;
  twoFactorEnabled: boolean;
  qrCodeUrl: string;
  wrongTwoPin: Boolean;
  handleCurrentPasswordChange: (value: string) => void;
  handlePasswordChange: (value: string) => void;
  handleConfirmPasswordChange: (value: string) => void;
  handleTwoFactorChange: (value: boolean) => void;
  handleTwoFactorPinChange: (value: boolean) => void;
  submitForm: () => void;
  validateTwoFactor: () => void;
}

export default function ChangePasswordViewComponent(props: ChangePasswordViewComponentProps) {
  const onCurrentPasswordChange = (event) => props.handleCurrentPasswordChange(event.target.value);
  const onPasswordChange = (event) => props.handlePasswordChange(event.target.value);
  const onConfirmPasswordChange = (event) => props.handleConfirmPasswordChange(event.target.value);
  const onTwoFactorPinChange = (event) => props.handleTwoFactorPinChange(event.target.value);

  return (
    <React.Fragment>
      <Paper elevation={0}>
        <Box p={5}>
          <PageSubTitleViewComponent id="form.password.header" />
          <FormErrorsComponent formErrors={props.formErrors} />

          <Box mt={3}></Box>

          <PasswordRulesComponent />

          <Box mt={3}>
            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
              <Grid item xs={12} md={6}>
                <TextField
                  id="form.currentPassword"
                  label={<IntlMessageComponent id="form.currentpassword.label" />}
                  error={!!props.errors.get('currentPassword')}
                  helperText={(props.errors.get('currentPassword')) ?
                    <IntlMessageComponent id={props.errors.get('currentPassword')} /> : ''}
                  value={props.form.currentPassword}
                  onChange={onCurrentPasswordChange}
                  fullWidth
                  margin="normal"
                  required
                  type="password"
                />

                <TextField
                  id="form.password"
                  data-walkme="password-field-newpassword"
                  label={<IntlMessageComponent id="form.password.label" />}
                  error={!!props.errors.get('password')}
                  helperText={(props.errors.get('password')) ?
                    <IntlMessageComponent id={props.errors.get('password')} /> : ''}
                  value={props.form.password}
                  onChange={onPasswordChange}
                  fullWidth
                  margin="normal"
                  required
                  type="password"
                />

                <TextField
                  id="form.confirmpassword"
                  label={<IntlMessageComponent id="form.confirmpassword.label" />}
                  error={!!props.errors.get('confirmPassword')}
                  helperText={(props.errors.get('confirmPassword')) ?
                    <IntlMessageComponent id={props.errors.get('confirmPassword')} /> : ''}
                  value={props.form.confirmPassword}
                  onChange={onConfirmPasswordChange}
                  fullWidth
                  margin="normal"
                  required
                  type="password"
                />
              </Grid>
            </Grid>
          </Box>

          <Box mt={5}>
            <ButtonComponent
              color="default"
              variant="contained"
              component={Link}
              to={PROTECTED_APP}
            ><IntlMessageComponent id="general.cancel" /></ButtonComponent>

            <Box component="span" ml={1}>
              <ButtonComponent
                color="primary"
                variant="contained"
                isProcessing={props.isProcessing}
                onClick={props.submitForm}
              ><IntlMessageComponent id="general.submit" /></ButtonComponent>
            </Box>
          </Box>
        </Box>
      </Paper>
      <Box p={3}></Box>
      <Paper elevation={0}>
        <Box p={5} pageName="Tooltip.User.Password" elementName="2faTitle">
          <TooltipComponent pageName="Tooltip.User.Password" elementName="2faTitle">
            <PageSubTitleViewComponent pageName="Tooltip.User.Password" elementName="2faTitle" id="form.2fa.header" />
          </TooltipComponent>
          <Box mt={2}>
            <strong><IntlMessageComponent id="form.2fa.explanation" /></strong>
          </Box>
          <Box mt={1}>
            <IntlMessageComponent id="form.2fa.slider" />
            <span>
              <Switch checked={props.twoFactorEnabled ?? false} disabled={props.isProcessing} onChange={() => props.handleTwoFactorChange(!props.twoFactorEnabled)} />
            </span>
          </Box>
          {props.qrCodeUrl !== null &&
            <React.Fragment>
              <img src={props.qrCodeUrl} alt={"QR Code"} /><br />
              <TextField
                id="form.twofactorpin"
                label={<IntlMessageComponent id="form.twofactorpin.label" />}
                error={!!props.errors.get('twofactorpin')}
                helperText={(props.errors.get('twofactorpin')) ?
                  <IntlMessageComponent id={props.errors.get('twofactorpin')} /> : ''}
                value={props.form.twofactorpin}
                onChange={onTwoFactorPinChange}
                margin="normal"
                required
              /><br />
              <ButtonComponent
                color="primary"
                variant="contained"
                isProcessing={props.isProcessing}
                onClick={props.validateTwoFactor}
              ><IntlMessageComponent id="general.validate" /></ButtonComponent><br/><br/>
              {props.wrongTwoPin && <Box component="span" color="error.main">
                <IntlMessageComponent id="form.twofactorpin.wrongpin" />
              </Box>}
            </React.Fragment>
          }
        </Box>
      </Paper>
    </React.Fragment>
  );
}
