/* @flow */

import IBAN from 'iban';

function ibanValidator(value: string, options: {allowEmpty: boolean, message: ?string}, key: ?string, attributes: ?any) {
  if (options.allowEmpty && value === '') {
    return undefined;
  }

  try {
    if (!IBAN.isValid(value)) {
      // noinspection ExceptionCaughtLocallyJS
      throw new Error('IBAN validation failed');
    }
  } catch (e) {
    // console.warn(e);
    return options.message ? options.message : 'validation.bankAccount.format';
  }

  return undefined;
}

export default ibanValidator;
