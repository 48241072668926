/* @flow */

function isRequiredByTwoSiblingsValidator(value: string, options: {sibling1: string, sibling2: string, requiredIfSibling1: boolean, requiredIfSibling2: int[], messagePresence: string,  messageLength: string, minLength: number, maxLength: number}, key: string, attributes: any) {
    if (key === 'single') {
      return undefined; // Validators doesn't works with validate.single
    }
  
    if (attributes[options.sibling1] !== options.requiredIfSibling1 || !options.requiredIfSibling2.includes(attributes[options.sibling2])) return undefined;
  
    // Actual check
    if (value === '' || value === undefined || value === null) {
      return options.messagePresence;
    } else if (options.maxLength && options.minLength && (value.length > options.maxLength || value.length < options.minLength)) {
      return options.messageLength;
    }
  
    return undefined;
  }
  
  export default isRequiredByTwoSiblingsValidator;