/* @flow */

import React from 'react';
import {useSelector} from 'react-redux';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

type DebtorInvoiceStatusComponentProps = {
  fullWidth: boolean;
  margin: string;
  value: string;
  values: string[];
  changeHandler: (statusKey: string) => void;
}

export default function DebtorInvoiceStatusComponent(props: DebtorInvoiceStatusComponentProps) {
  const translations = useSelector(state => state.locale.translations.filter(t => t.key.startsWith('invoice.status.debtor')));

  const options = [];
  props.values.forEach(val => {
    const id = val;
    const key = `invoice.status.${val.toLowerCase()}`;
    const label = translations.find(t => t.key === key)?.text || key;
    options.push({id, label});
  });

  const handleSelect = (value: string) => {
    if (value == null) return props.changeHandler('');
    return props.changeHandler(value);
  }; 

  return (
    <Autocomplete
      getOptionLabel={(value) => value.label}
      onChange={(_, newValue) => handleSelect(newValue?.id || null)}
      openOnFocus
      options={options}
      renderInput={(params) =>
        <TextField 
          {...params}
          label=" "
          fullWidth={props.fullWidth}
          margin={props.margin}
        />
      }
    />
  );
}
