/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import LoadingComponent from 'components/loading';

import SellerInvoiceDetailsNotesViewComponent from './view';

import InvoiceNotes from 'models/invoices/seller/invoiceNotes';

import InvoiceSellerService from 'services/InvoiceSellerService';

type SellerInvoiceDetailsNotesComponentProps = {
  activeCompanyId: number;
  invoiceId: number;
}

type SellerInvoiceDetailsNotesComponentState = {
  isLoading: boolean;
  notes: InvoiceNotes;
}

class SellerInvoiceDetailsNotesComponent extends React.Component<SellerInvoiceDetailsNotesComponentProps, SellerInvoiceDetailsNotesComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      notes: null
    };
  }

  componentDidMount = async () => {
    try {
      const notes = await InvoiceSellerService.getInvoiceNotes(this.props.activeCompanyId, this.props.invoiceId);
      this.setState({isLoading: false, notes});
    } catch (e) {
      console.error(e);
    }
  }

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    if (this.state.notes.actions.length === 0) return null;

    return (
      <SellerInvoiceDetailsNotesViewComponent notes={this.state.notes} />
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps)(SellerInvoiceDetailsNotesComponent);
