import FileCopyIcon from '@material-ui/icons/FileCopy';
import React from 'react';

import IntlMessageComponent from 'components/formatters/intlMessage';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

export default function BatchIconTooltip() {
  return (
    <Tooltip title={<IntlMessageComponent id="invoice.batchicon.tooltip" />} style={{cursor: 'default'}}>
      <IconButton size="small">
        <FileCopyIcon  />
      </IconButton>
    </Tooltip>
  );
}
