/* @flow */

import ISOAndCampaignRequest from 'models/requests/marketing/isoAndCampaignRequest';

export default class CreateLeadRequest {
  insurerId: string;
  isoAndCampaign: ISOAndCampaignRequest;

  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  languageId: number;
  titleId: number;

  contactTime: string;
  comment: string;
}