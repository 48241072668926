/* @flow */

export default class DownloadSellerReportRequest {
  companyId: number;
  format: string;
  isoCompanyIds: number[];
  isoOfficeIds: number[];
  isoPersonIds: number[];
  sellerCompanyIds: number[];
  expireds: string[];

  constructor(companyId: number, format: string) {
    this.companyId = companyId;
    this.format = format;
    this.isoCompanyIds = [];
    this.isoOfficeIds = [];
    this.isoPersonIds = [];
    this.sellerCompanyIds = [];
    this.expireds = [];
  }

  toRouteParameters() {
    let route = `companyId=${this.companyId}&format=${this.format}`;

    this.isoCompanyIds.forEach(isoCompanyId => {
      route += `&isoCompanyIds=${isoCompanyId}`;
    });
    this.isoOfficeIds.forEach(isoOfficeId => {
      route += `&isoOfficeIds=${isoOfficeId}`;
    });
    this.isoPersonIds.forEach(isoPersonId => {
      route += `&isoPersonIds=${isoPersonId}`;
    });
    this.sellerCompanyIds.forEach(sellerCompanyId => {
      route += `&sellerCompanyIds=${sellerCompanyId}`;
    });
    this.expireds.forEach(expired => {
      route += `&expireds=${expired}`;
    });

    return route;
  }
}
