/* @flow */

import React from 'react';

import BuyerDashboardComponent from 'app/buyers/dashboard';
import WebsiteDashboardPageComponent from 'components/websiteStructure/pages/dashboard';

export default function BuyerDashboardPage() {
  return (
    <WebsiteDashboardPageComponent id="buyer.dashboard.title">
      <BuyerDashboardComponent />
    </WebsiteDashboardPageComponent>
  );
}
