import React from 'react';

import Box from '@material-ui/core/Box';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';
import UploadComponent from 'components/upload';

type UploadLinkComponentProps = {
  disabled: boolean;
  error: boolean;
  file: File;
  helperText: IntlMessageComponent;
  icon: any;
  label: string;
  successHandler: (file: File) => void;
  failHandler: () => void;
}

export default function UploadLinkComponent(props: UploadLinkComponentProps) {

  const deleteDocument = () => props.successHandler(null);

  return (
    <React.Fragment>
      {props.file ? (
        <ButtonComponent
          color="primary"
          variant="contained"
          endIcon={<DeleteOutlinedIcon />}
          onClick={deleteDocument}
        >
          <b>{props.file.name}</b>
        </ButtonComponent>
      ) : (
        <React.Fragment>
          <UploadComponent
            accept="application/pdf, image/jpeg, image/png"
            disabled={props.disabled}
            successHandler={props.successHandler}
            failHandler={props.failHandler}
          >
            <ButtonComponent
              color="default"
              variant="contained"
              endIcon={props.icon}
            >
              <b><IntlMessageComponent id={props.label} /></b>
            </ButtonComponent>
          </UploadComponent>

          {props.error && <Box color="error.main" fontSize={12} mt={1}>{props.helperText}</Box>}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
