/* @flow */

function isRequiredBySiblingValidator(value: string, options: {sibling: string, requiredIf: boolean, messagePresence: string,  messageLength: string, minLength: number, maxLength: number}, key: string, attributes: any) {
  if (key === 'single') {
    return undefined; // Validators doesn't works with validate.single
  }

  if (attributes[options.sibling] !== options.requiredIf) return undefined;

  // Actual check
  if (value === '' || value === undefined || value === null) {
    return options.messagePresence;
  } else if (options.maxLength && options.minLength && (value.length > options.maxLength || value.length < options.minLength)) {
    return options.messageLength;
  }

  return undefined;
}

export default isRequiredBySiblingValidator;