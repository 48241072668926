/* @flow */

import React from 'react';
import {useHistory} from 'react-router-dom';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';

import ButtonComponent from 'components/buttons/button';
import CountryNameComponent from 'components/country/name';
import IntlMessageComponent from 'components/formatters/intlMessage';

import Company from 'models/companies/company';

type CompanyLookupISOLeadComponentViewComponentProps = {
  companies: Company[];
  companyErrorLabelId: string;
  newCompanyPageBtnLabelId: string;
  newCompanyPageUrl: string;
  required: boolean;
  handleSelect: (company: Company) => void;
}

export default function CompanyLookupISOLeadComponentViewComponent(props: CompanyLookupISOLeadComponentViewComponentProps) {
  const history = useHistory();

  const options = props.companies.map(company => {
    const firstLetter = company.name[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...company,
    };
  });

  return (
    <React.Fragment>
      {options.length > 0 ? (
        <React.Fragment>
          <Box px={4} py={3}>
            <Autocomplete
              getOptionLabel={(option) => option.name}
              groupBy={(option) => option.firstLetter}
              onChange={(_, newValue) => props.handleSelect(newValue)}
              openOnFocus
              options={options}
              renderInput={(params) =>
                <TextField
                  {...params}
                  id="select-company"  
                  label={<IntlMessageComponent id="component.iso.companies.label" />}
                  fullWidth
                  margin="normal"
                />
              }
              renderOption={(company) => (
                <span>{company.name} - <CountryNameComponent countryId={company.countryId} /> [{company.vatNumber}]</span>
              )}
              value={null}
            />
          </Box>

          <Box mt={3} px={4} py={3} display="flex" alignItems="center" justifyContent="center" height="100%">
            <ButtonComponent
              color="primary"
              variant="contained"
              onClick={() => history.push(props.newCompanyPageUrl)}
            ><IntlMessageComponent id={props.newCompanyPageBtnLabelId} /></ButtonComponent>
          </Box>
        </React.Fragment>
      ) : (
        <Box mt={3} px={4} py={3} display="flex" alignItems="center" justifyContent="center" height="100%">
          <ButtonComponent
            color="primary"
            variant="contained"
            onClick={() => history.push(props.newCompanyPageUrl)}
          ><IntlMessageComponent id={props.newCompanyPageBtnLabelId} /></ButtonComponent>
        </Box>
      )}
    </React.Fragment>
  );
}
