/* @flow */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function IconUsersFilled(props) {
  return (
    <SvgIcon viewBox="0 0 64.54 64.54" {...props}>
      <path d="M297.5,388.23a32.27,32.27,0,1,0,32.27,32.27A32.31,32.31,0,0,0,297.5,388.23Zm0,62.54a30.27,30.27,0,1,1,30.27-30.27A30.27,30.27,0,0,1,297.5,450.77Z" transform="translate(-265.23 -388.23)"/>
      <g>
        <path d="M297.52,408.38a5.31,5.31,0,1,0,5.31,5.31A5.31,5.31,0,0,0,297.52,408.38Z" transform="translate(-265.23 -388.23)"/>
        <path d="M313.73,410a3.34,3.34,0,1,0,3.33,3.33A3.34,3.34,0,0,0,313.73,410Z" transform="translate(-265.23 -388.23)"/>
        <path d="M281.27,410a3.34,3.34,0,1,0,3.34,3.33A3.34,3.34,0,0,0,281.27,410Z" transform="translate(-265.23 -388.23)"/>
        <path d="M297.5,392.87a27.63,27.63,0,1,0,27.63,27.63A27.63,27.63,0,0,0,297.5,392.87Zm25,33.2a1.06,1.06,0,0,1-1.1.74,1.49,1.49,0,0,1-.79-.43.87.87,0,0,1-.24-.67,6.8,6.8,0,0,0-1.42-4,6.59,6.59,0,0,0-11.41,1.8,14.48,14.48,0,0,1,3.87,6.05,13.3,13.3,0,0,1,.52,2.34c.06.33.11.66.17,1,0,.13.05.25.07.38l0,.34v1l-.11.21a1.92,1.92,0,0,1-2,1.31,2.73,2.73,0,0,1-1.4-.75,1.65,1.65,0,0,1-.45-1.22,11,11,0,0,0-2.3-6.51,10.74,10.74,0,0,0-14.33-2.35,10.49,10.49,0,0,0-4.8,8.34l0,.55a2,2,0,0,1-1.95,2h0a2,2,0,0,1-1.41-.58,2.14,2.14,0,0,1-.58-1.55,15.94,15.94,0,0,1,1-5.08,13.82,13.82,0,0,1,3.68-5.4,6.29,6.29,0,0,0-1-1.83,6.63,6.63,0,0,0-8.86-1.45,6.38,6.38,0,0,0-3,5.15l0,.33a1.06,1.06,0,0,1-1.08,1.1h0a1.06,1.06,0,0,1-.78-.32,1.14,1.14,0,0,1-.32-.86,9.58,9.58,0,0,1,.59-3.06,8.83,8.83,0,0,1,5-5,5.33,5.33,0,0,1-2.07-5.68,5.11,5.11,0,0,1,2.06-3.12,5.53,5.53,0,0,1,7.46.85,5.43,5.43,0,0,1,1.28,3,5.37,5.37,0,0,1-.66,3.19,5.44,5.44,0,0,1-1.54,1.73l0,0a8.66,8.66,0,0,1,4.7,4.51,15.59,15.59,0,0,1,2.53-1.41,8.88,8.88,0,0,1-3.23-9.32,8.6,8.6,0,0,1,3.45-5.25,9.3,9.3,0,0,1,12.52,1.43,9.18,9.18,0,0,1,1,10.41,9,9,0,0,1-2.34,2.73,16.59,16.59,0,0,1,2.49,1.38,8.86,8.86,0,0,1,4.73-4.49,5.33,5.33,0,0,1-2.07-5.68,5.16,5.16,0,0,1,2.06-3.12,5.5,5.5,0,0,1,8.75,3.87,5.42,5.42,0,0,1-2.21,4.92l0,0a8.65,8.65,0,0,1,5,5.37,9.21,9.21,0,0,1,.32,1.41c0,.2.06.4.1.59l.06.42V426Z" transform="translate(-265.23 -388.23)"/>
      </g>
    </SvgIcon>
  );
}
