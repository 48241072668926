/* @flow */

import React from 'react';
import {useSelector} from 'react-redux';

import moment from 'moment';
import MomentUtils from '@date-io/moment';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import IntlMessageComponent from 'components/formatters/intlMessage';

export default function DatePickerComponent(props){
  const locale = useSelector(state => state.locale.locale);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} locale={locale} moment={moment}>
      <DatePicker
        animateYearScrolling={false}
        format="DD MMM YYYY"
        leftArrowIcon={<ArrowBackIosIcon fontSize="small" />}
        rightArrowIcon={<ArrowForwardIosIcon fontSize="small" />}
        okLabel={<IntlMessageComponent id="general.datePicker.label.ok" />}
        cancelLabel={<IntlMessageComponent id="general.datePicker.label.cancel" />}
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
}
