import React from 'react';
import {useSelector} from 'react-redux';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import {ROLE_DEBTOR, ROLE_ISO} from 'constants/companyRoles';

type SubscriptionTab6ViewComponentProps = {
  alreadyBeenCalled: boolean;
  callRequested: boolean;
  hasPaidMembership: boolean;
  finalize: () => void;
}

export default function SubscriptionTab6ViewComponent(props: SubscriptionTab6ViewComponentProps) {
  const activeCompanyRole = useSelector(state => state.auth.user.activeCompany.role);

  let translation = "";
  if (activeCompanyRole === ROLE_DEBTOR) {
    translation = ".debtor";
  } else if (activeCompanyRole === ROLE_ISO) {
    translation = ".iso";
  }

  return (
    <React.Fragment>
      {!props.alreadyBeenCalled && !props.callRequested &&
        <React.Fragment>
          <PageSubTitleViewComponent id={`page.subscription.thanks.h2${translation}`} />

          <Typography variant="body1" gutterBottom>
            <IntlMessageComponent id={`page.subscription.thanks.introduction${translation}`} />
          </Typography>
        </React.Fragment>
      }

      {props.alreadyBeenCalled &&
        <React.Fragment>
          <PageSubTitleViewComponent id={`page.subscription.thanks.h2${translation}.alreadycalled`} />

          {(!props.hasPaidMembership || translation != "") && 
          <Typography variant="body1" gutterBottom>
            <IntlMessageComponent id={`page.subscription.thanks.introduction${translation}.alreadycalled.text1`} />
          </Typography>
          }
          {props.hasPaidMembership && translation == "" && 
          <Typography variant="body1" gutterBottom>
            <IntlMessageComponent id={`page.subscription.thanks.introduction${translation}.alreadycalled.text1paid`} />
          </Typography>
          }

          <Typography variant="body1" gutterBottom>
            <IntlMessageComponent id={`page.subscription.thanks.introduction${translation}.alreadycalled.text2`} />
          </Typography>
        </React.Fragment>
      }

      {props.callRequested &&
        <React.Fragment>
          <PageSubTitleViewComponent id={`page.subscription.thanks.h2${translation}.callrequested`} />

          <Typography variant="body1" gutterBottom>
            <IntlMessageComponent id={`page.subscription.thanks.introduction${translation}.callrequested.text1`} />
          </Typography>

          <Typography variant="body1" gutterBottom>
            <IntlMessageComponent id={`page.subscription.thanks.introduction${translation}.callrequested.text2`} />
          </Typography>
        </React.Fragment>
      }

      <Box mt={5}>
        <ButtonComponent
          color="primary"
          variant="contained"
          onClick={props.finalize}
        ><IntlMessageComponent id="page.subscription.thanks.finalize" /></ButtonComponent>
      </Box>
    </React.Fragment>
  );
}