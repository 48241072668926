/* Entry point of the Edebex Point Of Sale application */

import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { NotificationContainer } from 'react-notifications';

import WalkMeContainer from 'components/walkme';
import WebsiteFooterComponent from 'components/websiteFooter';
import WebsiteHeaderComponent from 'components/websiteHeader';
import WebsiteMenuComponent from 'components/websiteMenu';

import EdebexAppRoutes from 'routes/index';

import TagManager from 'react-gtm-module';


export default function EdebexApp() {
  // Get everything we need from the redux store
  const user = useSelector(state => state.auth.user, shallowEqual);
  const isAuthenticated = user !== null && user.activeCompany !== null;
  const displaySideNavBar = isAuthenticated && !user.activeCompany.isSubscribing;

  // Google Ads TagManager
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GTM_TRACKING
    }
    TagManager.initialize(tagManagerArgs);

  return (
    <div id="app-main" className="app-main">
      <div className="app-container mini-drawer">

        {displaySideNavBar && <WebsiteMenuComponent />}

        <WebsiteHeaderComponent />

        <div className="app-main-container">
          <main className="app-main-content-wrapper">
            <div className="app-main-content">
              <EdebexAppRoutes />
            </div>

            <NotificationContainer />
            <WalkMeContainer />

            <WebsiteFooterComponent />
          </main>
        </div>
      </div>
    </div>
  );
}
