/* @flow */

import moment from 'moment';

export default class BlogEntry {
  title: string;
  publicationDate: moment;
  contentText: string;
  buttonText: string;
  buttonUrl: string;
}
