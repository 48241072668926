/* @flow */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function IconCheckOutlined(props) {
  return (
    <SvgIcon viewBox="0 0 62.5 62.52" {...props}>
      <g>
        <path d="M256,424c1.82.34,3.68.55,5.47,1,10.87,2.86,18.35,9.59,21.94,20.22,3.77,11.18,1.7,21.45-5.9,30.5a29.92,29.92,0,0,1-15.76,9.74,29.45,29.45,0,0,1-15.56,0c-11-3.08-18.42-10.07-21.89-21-.83-2.62-.94-5.48-1.39-8.23,0-.32-.11-.63-.17-.94V453.2a2.92,2.92,0,0,0,.19-.49,53.64,53.64,0,0,1,1.35-6.72c3.64-10.89,11-17.91,22.15-20.89,1.85-.49,3.79-.71,5.68-1.06Zm-30.23,30.85c0,15.85,12.29,28.66,28.27,28.63a28.22,28.22,0,1,0-28.27-28.63Z" transform="translate(-222.78 -424.04)"/>
        <path d="M250.38,462.77l8-8.24,9.45-9.77c.81-.82,1.66-.9,2.33-.25s.71,1.5-.18,2.42l-18,18.6c-1.15,1.19-1.88,1.19-3.11.06-3.54-3.27-7.1-6.51-10.65-9.77-.51-.48-1-1.06-.58-1.77a2.2,2.2,0,0,1,1.36-1,2.53,2.53,0,0,1,1.56.76c3,2.73,6,5.5,9,8.25C249.78,462.31,250.06,462.51,250.38,462.77Z" transform="translate(-222.78 -424.04)"/>
      </g>
    </SvgIcon>
  );
}
