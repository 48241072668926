/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import LoadingComponent from 'components/loading';

import ConfirmingKPIsViewComponent from './view';

import KPI from 'models/dashboards/kpi';

import DebtorService from 'services/DebtorService';

type ConfirmingKPIsComponentProps = {
  activeCompanyId: number
}

type ConfirmingKPIsComponentState = {
  isLoading: boolean,
  kpis: KPI[]
}

class ConfirmingKPIsComponent extends React.Component<ConfirmingKPIsComponentProps, ConfirmingKPIsComponentState> {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      kpis: null
    };
  }

  componentDidMount = async () => {
    try {
      const kpis = await DebtorService.getKPIs(this.props.activeCompanyId);
      this.setState({isLoading: false, kpis});
    } catch (e) {
      console.error(e);
    }
  }

  render() {
    if (this.state.isLoading) return (<LoadingComponent />);    
    return (<ConfirmingKPIsViewComponent kpis={this.state.kpis} />);
  }
}

const mapStateToProps = ({auth}) => ({
  activeCompanyId: auth.user.activeCompany.id
});

export default connect(mapStateToProps)(ConfirmingKPIsComponent);
