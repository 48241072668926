/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import LoadingComponent from 'components/loading';

import BuyerKPIsViewComponent from './view';

import KPI from 'models/dashboards/kpi';
import BuyerService from 'services/BuyerService';

type BuyerKPIsComponentProps = {
  activeCompanyId: number
}

type BuyerKPIsComponentState = {
  isLoading: boolean,
  kpis: KPI[]
}

class BuyerKPIsComponent extends React.Component<BuyerKPIsComponentProps, BuyerKPIsComponentState> {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      kpis: null
    };
  }

  componentDidMount = async () => {
    try {
      const kpis = await BuyerService.getKPIs(this.props.activeCompanyId, this.props.activeCompanyInvestmentProfileId);
      this.setState({isLoading: false, kpis});
    } catch (e) {
      console.error(e);
    }
  }

  render() {
    if (this.state.isLoading) return (<LoadingComponent />);
    return (<BuyerKPIsViewComponent kpis={this.state.kpis} />);
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id,
  activeCompanyInvestmentProfileId: state.auth.user.activeCompany.investmentProfile.id
});

export default connect(mapStateToProps)(BuyerKPIsComponent);
