/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet'

import IntlMessageComponent from 'components/formatters/intlMessage';
import LoadingComponent from 'components/loading';
import WizardComponent from 'components/wizard';

import SubmitInvoiceTab1Component from './tab1/index';
import SubmitInvoiceTab2Component from './tab2/index';
import SubmitInvoiceTab3Component from './tab3/index';
import SubmitInvoiceTab4Component from './tab4/index';
import SubmitInvoiceThankYouComponent from './thankyou/index';

import SupplierCompany from 'models/companies/company';
import LeadOffer from 'models/leads/leadOffer';

import CancelInvoiceRequest from 'models/requests/sellers/cancelInvoiceRequest';

import InvoiceSellerService from 'services/InvoiceSellerService';

import NotificationHelper from 'lib/notifications';

const SELL_INVOICE_STEP_SUPPLIER = 0;
const SELL_INVOICE_STEP_INVOICE = 1;
const SELL_INVOICE_STEP_DOCUMENTS = 2;
const SELL_INVOICE_STEP_SUMMARY = 3;

type ConfirmingSubmitInvoicesComponentProps = {
  activeCompanyId: number;
  invoiceId: number;
  leadOffer: LeadOffer;
}

type ConfirmingSubmitInvoicesComponentState = {
  activeStepId: number;
  supplierCompany: SupplierCompany;
  invoiceId: number;
  isLoading: boolean;
  leadOffer: LeadOffer;
  steps: { id: number, title: IntlMessageComponent }[];
  invoiceSubmitted: boolean;
};

class ConfirmingSubmitInvoicesComponent extends React.Component<ConfirmingSubmitInvoicesComponentProps, ConfirmingSubmitInvoicesComponentState> {

  constructor(props) {
    super(props);

    // retrieve invoiceId from url or leadOfferId from history state
    let invoiceId = 0;
    let leadOffer = null;
    if (props.invoiceId > 0) {
      invoiceId = props.invoiceId;
    } else if (props.leadOffer) {
      leadOffer = props.leadOffer;
    }

    const steps = this.getSteps();

    this.state = {
      activeStepId: SELL_INVOICE_STEP_SUPPLIER,
      supplierCompany: null,
      invoiceId: invoiceId,
      invoiceSummary: null,
      invoiceSubmitted: false,
      isLoading: invoiceId > 0,
      leadOffer,
      steps
    };
  }

  componentDidMount = async () => {
    if (this.state.invoiceId > 0) {
      // continue invoice -> get debtor & step from db
      const response = await InvoiceSellerService.getInvoiceStatus(this.state.invoiceId);
      this.setState({
        activeStepId: response.lastStep,
        supplierCompany: response.supplierCompany,
        isLoading: false
      });
    }
  }

  getSteps = () => {
    const tabsNumber = [
      SELL_INVOICE_STEP_SUPPLIER,
      SELL_INVOICE_STEP_INVOICE,
      SELL_INVOICE_STEP_DOCUMENTS,
      SELL_INVOICE_STEP_SUMMARY
    ];
    return tabsNumber.map(num => ({ id: num, title: <IntlMessageComponent id={`page.submitInvoice.tab${num + 1}`} /> }));
  }

  getStepContent = () => {
    switch (this.state.activeStepId) {
      case SELL_INVOICE_STEP_SUPPLIER:
        return (<SubmitInvoiceTab1Component
          leadOffer={this.state.leadOffer}
          successHandler={this.handleSuccessTab1}
        />);
      case SELL_INVOICE_STEP_INVOICE:
        return (<SubmitInvoiceTab2Component
          supplierCompanyId={this.state.supplierCompany.id}
          invoiceId={this.state.invoiceId}
          leadOffer={this.state.leadOffer}
          successHandler={this.handleSuccessTab2}
          cancelInvoice={this.cancelInvoice}
        />);
      case SELL_INVOICE_STEP_DOCUMENTS:
        return (<SubmitInvoiceTab3Component
          invoiceId={this.state.invoiceId}
          successHandler={this.handleSuccessTab3}
          cancelInvoice={this.cancelInvoice}
        />);
      case SELL_INVOICE_STEP_SUMMARY:
        return (<SubmitInvoiceTab4Component
          invoiceId={this.state.invoiceId}
          successHandler={this.handleSuccessTab4}
          cancelInvoice={this.cancelInvoice}
        />);
      default:
        return 'Unknown step';
    }
  };

  canNavigateToTab = (step: number) => {
    // can't return to the debtor step if invoice already created (create a new one plz..)
    if (step === SELL_INVOICE_STEP_SUPPLIER && this.state.invoiceId > 0) {
      return false;
    }
    // can't go in the future
    if (this.state.activeStepId <= step) {
      return false;
    }
    return true;
  };


  handleTabClick = (stepId: number) => {
    if (this.canNavigateToTab(stepId)) {
      this.setState({ activeStepId: stepId });
    }
  };

  cancelInvoice = async () => {
    if (this.state.invoiceId > 0) {
      const request = new CancelInvoiceRequest(this.state.invoiceId);
      await InvoiceSellerService.cancelInvoice(request);
    }

    NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS, 'notifications.invoice.cancel.success');
    this.setState({ activeStepId: 0, supplierCompany: null, invoiceId: 0, leadOffer: null });
  }

  reset = () => {
    this.setState({
      activeStepId: SELL_INVOICE_STEP_SUPPLIER,
      supplierCompany: null,
      invoiceId: 0,
      isLoading: false,
      leadOffer: null,
      invoiceSubmitted : false
    });
  }

  handleSuccessTab1 = (supplierCompany: SupplierCompany) => this.setState({ activeStepId: SELL_INVOICE_STEP_INVOICE, supplierCompany });
  handleSuccessTab2 = (supplierCompany: SupplierCompany, invoiceId: number) =>
    this.setState({ activeStepId: SELL_INVOICE_STEP_DOCUMENTS, supplierCompany, invoiceId, leadOffer: null });
  handleSuccessTab3 = () => this.setState({ activeStepId: SELL_INVOICE_STEP_SUMMARY });
  handleSuccessTab4 = () => this.setState({ invoiceSubmitted: true });

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    if (this.state.invoiceSubmitted) {
      return (
        <SubmitInvoiceThankYouComponent
          invoiceId={this.state.invoiceId}
          handleNewInvoiceCreation={this.reset}
        />)
    }

    return (
      <React.Fragment>
        <WizardComponent
          activeStepId={this.state.activeStepId}
          steps={this.state.steps}
          canNavigateToTab={this.canNavigateToTab}
          handleTabClick={this.handleTabClick}
        >
          {this.getStepContent()}
        </WizardComponent>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps)(ConfirmingSubmitInvoicesComponent);
