/* @flow */

import React from 'react';
import { useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';

import BlogComponent from 'components/blogs';
import BuyerInvoicesToPurchaseTableComponent from 'app/buyers/invoicesToPurchaseTable';

import BuyerInvoiceListComponent from './invoiceList';
import BuyerKPIsComponent from './kpis';
import BuyerProfileSummaryComponent from './profileSummary';

export default function BuyerDashboardViewComponent() {
  const languageId = useSelector(state => state.locale.languageId);
  const activeCompanyInvestmentProfileId = useSelector(state => state.auth.user.activeCompany.investmentProfile.id);

  return (
    <React.Fragment>
      <BuyerProfileSummaryComponent />

      {activeCompanyInvestmentProfileId > 0 &&
        <Box mt={5}>
          <Box mt={3}>
            <BuyerInvoicesToPurchaseTableComponent includeInvoicesToPay />
          </Box>
        </Box>
      }

      <div className="hiddenMobile">
        <Box mt={5}>
          <BuyerInvoiceListComponent />
        </Box>
      </div>

      <Box mt={3}>
        <BuyerKPIsComponent />
      </Box>

      <Box mt={5}>
        <BlogComponent key={`blogs-${languageId}`} />
      </Box>
    </React.Fragment>
  );
}
