/* @flow */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function IconFlagFR(props) {
  return (
    <SvgIcon viewBox="0 0 512 512" {...props}>
      <circle style={{fill:'#F0F0F0'}} cx="256" cy="256" r="256"/>
      <path style={{fill:'#d90127'}} d="M16 167L496 167C494.597 157.635 489.455 148.395 485.247 140C473.165 115.895 457.033 94.0326 438 75C339.36 -23.6396 174.062 -25.062 75 74C55.5977 93.4023 39.078 115.41 26.7531 140C22.5454 148.395 17.4027 157.635 16 167z" />
      <path style={{fill:'#ff5501'}} d="M15 166L16 167L15 166M496 166L497 167L496 166z" />
      <path style={{fill:'#ffdb45'}} d="M15.7022 168.028C9.10036 172.979 6.95473 193.239 5.39969 201C-1.51414 235.507 -2.51402 271.382 4.39969 306C6.36239 315.827 8.68265 325.45 11.7446 335C12.6409 337.795 13.5503 342.962 16.4298 344.397C23.4206 347.88 38.2107 345 46 345L120 345L385 345L467 345C473.781 345 490.799 348.096 496.298 343.972C502.9 339.021 505.045 318.761 506.6 311C513.51 276.512 514.51 240.597 507.6 206C505.702 196.493 503.299 187.299 500.576 178C499.662 174.88 498.82 169.19 495.581 167.603C488.3 164.036 473.091 167 465 167L391 167L127 167L45 167C38.2193 167 21.2005 163.904 15.7022 168.028z" />
      <path style={{fill:'#ffe829'}} d="M10 181L11 182L10 181M501 181L502 182L501 181M5 200L6 201L5 200M506 200L507 201L506 200M4 205L5 206L4 205M507 205L508 206L507 205M3 210L4 211L3 210M508 210L509 211L508 210M511 233L512 234L511 233M0 278L1 279L0 278M511 278L512 279L511 278z" />
      <path style={{fill:'#ffe829'}} d="M3 301L4 302L3 301M508 301L509 302L508 301M4 306L5 307L4 306M507 306L508 307L507 306M5 311L6 312L5 311M10 330L11 331L10 330M501 330L502 331L501 330M13 339L14 340L13 339z" />
      <path style={{fill:'#ff5501'}} d="M15 345L16 346L15 345z" />
      <path style={{fill:'#d90127'}} d="M16 345C17.4027 354.365 22.5453 363.605 26.7531 372C39.078 396.59 55.5977 418.598 75 438C174.056 537.056 339.367 535.633 438 437C457.033 417.967 473.165 396.105 485.247 372C489.455 363.605 494.597 354.365 496 345L16 345z" />
      <path style={{fill:'#ff5501'}} d="M496 345L497 346L496 345z" />
    </SvgIcon>
  );
}
