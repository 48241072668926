/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';

import LoadingComponent from 'components/loading';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import DebtorInvoicesToApproveFormComponent from './form';
import DebtorInvoicesToApproveRefusePopupComponent from './refusePopup';
import DebotrInvoicesToApproveTableComponent from './table';

import ApproveOrDisputeInvoiceForm from './form/model';
import Company from 'models/companies/company';
import DebtorInfo from 'models/debtors/debtorInfo';
import Invoice from 'models/debtors/invoice';

import DebtorService from 'services/DebtorService';
import NotificationHelper from 'lib/notifications';

type DebtorInvoicesToApproveComponentProps = {
  debtorCompany: Company;
  debtorInfo: DebtorInfo;
  token: string;  
  successApproveInvoices: () => void;
  successRefuseInvoices: () => void;
}

type DebtorInvoicesToApproveComponentState = {
  invoices: Invoice[];
  isLoading: boolean;
  isProcessing: boolean;
}

class DebtorInvoicesToApproveComponent extends React.Component<DebtorInvoicesToApproveComponentProps, DebtorInvoicesToApproveComponentState> {

  constructor(props) {
    super(props);
  
    this.state = {
      invoices: null,
      isLoading: true,
      isProcessing: false
    };
  }

  componentDidMount = async () => {
    try {
      const invoices = await DebtorService.getInvoicesToApproveToken(this.props.token);
      this.setState({invoices, isLoading: false});
    } catch (e) {
      console.error(e);
    }
  }

  approveInvoices = async (form: ApproveOrDisputeInvoiceForm) => {
    this.setState({isProcessing: true});

    const selectedInvoices = this.state.invoices.filter(inv => inv.isSelected);

    const requests = selectedInvoices.map(inv => {
      const request = form.toApproveRequest(this.props.token, inv.id, this.props.debtorInfo);
      return DebtorService.approveInvoiceToken(request);
    });

    try {
      await Promise.all(requests);
      this.props.successApproveInvoices();
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  }

  disputeInvoices = async (form: ApproveOrDisputeInvoiceForm) => {
    this.setState({isProcessing: true});

    const selectedInvoices = this.state.invoices.filter(inv => inv.isSelected);

    const requests = selectedInvoices.map(inv => {
      const request = form.toDisputeRequest(this.props.token, inv.id, this.props.debtorInfo);
      return DebtorService.disputeInvoiceToken(request);
    });

    try {
      await Promise.all(requests);
      this.props.successRefuseInvoices();
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  }

  selectInvoice = (invoiceId: number) => {    
    const invoices = this.state.invoices;
    const invoice = invoices.find(inv => inv.id === invoiceId);
    if (invoice) invoice.isSelected = !invoice.isSelected;
    this.setState({invoices});
  };

  showRefuseInvoicePopup = (invoiceId: number) => this.setState({invoiceId});
  hideRefuseInvoicePopup = () => this.setState({invoiceId: 0});  

  render() {
    if (this.state.isLoading) {
      return <LoadingComponent />;
    }

    const selectedInvoices = this.state.invoices.filter(i => i.isSelected);

    return (
      <React.Fragment>
        <PageSubTitleViewComponent id="component.debtor.invoices.subtitle" />

        <Box mt={3}>
          <DebotrInvoicesToApproveTableComponent
            invoices={this.state.invoices}
            isProcessing={this.state.isProcessing}
            token={this.props.token}
            refuseInvoice={this.showRefuseInvoicePopup}
            selectInvoice={this.selectInvoice}
          />
        </Box>

        {selectedInvoices.length > 0 &&
          <Box mt={5}>
            <DebtorInvoicesToApproveFormComponent
              debtorCompany={this.props.debtorCompany}
              invoices={selectedInvoices}
              isProcessing={this.state.isProcessing}
              approveInvoices={this.approveInvoices}
              disputeInvoices={this.disputeInvoices}
            />
          </Box>
        }
        
        {this.state.invoiceId > 0 &&
          <DebtorInvoicesToApproveRefusePopupComponent
            debtorInfo={this.props.debtorInfo}
            invoiceId={this.state.invoiceId}
            token={this.props.token}
            handleCancel={this.hideRefuseInvoicePopup}
            handleSuccess={this.props.successRefuseInvoices}
          />
        }
      </React.Fragment>
    );
  }
}

export default DebtorInvoicesToApproveComponent;
