/* @flow */

import React from 'react';

import SubscriptionComponent from 'app/users/subscription';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function ISORegisterLeadsPage() {
  return (
    <WebsitePageComponent id="page.subscription.h1" noPaper>
      <SubscriptionComponent />
    </WebsitePageComponent>
  );
}
