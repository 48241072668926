/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import Box from '@material-ui/core/Box';

import CompanyItemComponent from 'components/company';
import LoadingComponent from 'components/loading';

import CompanyLookupISOLeadComponentViewComponent from './view';
import CompanyLookupISOLeadComponentTwoColumnsViewComponent from './view2columns';

import Company from 'models/companies/company';

import IsoService from 'services/IsoService';

type CompanyLookupISOLeadComponentProps = {
  activeCompanyId: number;
  company: Company;
  companyErrorLabelId: string;
  newCompanyPageBtnLabelId: string;
  newCompanyPageUrl: string;
  required: boolean;
  twoColumns: boolean;
  handleSelect: (company: Company) => void;
}

type CompanyLookupISOLeadComponentState = {
  companies: Company[];
  isLoading: boolean;
}

class CompanyLookupISOLeadComponent extends React.Component<CompanyLookupISOLeadComponentProps, CompanyLookupISOLeadComponentState> {

  constructor(props) {
    super(props);
    this.state = {
      companies: null,
      isLoading: true
    };
  }

  componentDidMount = async () => {
    try {
      const companies = await IsoService.getIsoCompanies(this.props.activeCompanyId);
      this.setState({companies, isLoading: false});
    } catch (e) {
      console.error(e);
    }
  }

  render() {
    if (this.props.company) {
      return (
        <Box px={4} py={3} bgcolor="lightblue.main">
          <CompanyItemComponent
            buttonLabelId="component.offer.tab1.company.change"
            company={this.props.company}
            selected
            selectHandler={() => this.props.handleSelect(null)}
          />
        </Box>
      );
    }

    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    if (this.props.twoColumns) {
      return (
        <CompanyLookupISOLeadComponentTwoColumnsViewComponent
          companies={this.state.companies}
          companyErrorLabelId={this.props.companyErrorLabelId}
          newCompanyPageBtnLabelId={this.props.newCompanyPageBtnLabelId}
          newCompanyPageUrl={this.props.newCompanyPageUrl}
          required={this.props.required}
          handleSelect={this.props.handleSelect}
        />
      )
    }

    return (
      <CompanyLookupISOLeadComponentViewComponent
        companies={this.state.companies}
        companyErrorLabelId={this.props.companyErrorLabelId}
        newCompanyPageBtnLabelId={this.props.newCompanyPageBtnLabelId}
        newCompanyPageUrl={this.props.newCompanyPageUrl}
        required={this.props.required}
        handleSelect={this.props.handleSelect}
      />
    )
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user !== null ? state.auth.user.activeCompany.id : 0
});

export default connect(mapStateToProps)(CompanyLookupISOLeadComponent);
