/* @flow */

import React from 'react';

import LostPasswordComponent from 'app/users/lostPassword';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function LostPasswordPage() {

  return (
    <WebsitePageComponent id="page.lostPassword.title">
      <LostPasswordComponent />
    </WebsitePageComponent>
  );
}
