/* @flow */

import moment from 'moment';

import {updateIntl} from 'react-intl-redux';
import {all, call, fork, put, takeEvery} from 'redux-saga/effects';

import {store} from '../index';

import {INIT_WEBSITE_SAGA, SWITCH_LANGUAGE_SAGA} from '../actionTypes';

import {getLocale} from 'lib/localeHelpers';

import {
  initializeWebSiteSuccessAction,
  initializeWebSiteErrorAction
} from '../actions/localeActions';


import GenericService from 'services/genericService';

function* initializeWebSite({locale}) {
  try {
    const websiteLanguages = yield call(GenericService.getWebsiteLanguages);
    
    const usedLocale = getLocale(locale, websiteLanguages);
    localStorage.setItem('locale', usedLocale);

    const genericData = yield call(GenericService.getGenericData);

    // Change redux-stored translations
    const translations = genericData.translations;
    const formattedTranslations = {};

    // Prevent failure on language not found
    translations.forEach((t) => { formattedTranslations[t.key] = t.text; });

    const languageShortName = usedLocale.split('-')[0];

    // Change Walkme Language
    if (typeof window.WalkMeAPI === 'object' && window.WalkMeAPI) {
      console.info('WalkMeAPI.changeLanguage(%s)', languageShortName.toLowerCase());

      // Special case : English lang requires no params
      window.WalkMeAPI.changeLanguage(languageShortName.toLowerCase() === 'en' ? '' : languageShortName.toLowerCase());
    } else {
      console.warn('WALKMEAPI not found');
    }

    moment.locale(usedLocale.slice(0, 2));

    yield store.dispatch(updateIntl({
      locale: usedLocale,
      messages: formattedTranslations,
    }));

    yield put(initializeWebSiteSuccessAction(genericData));
  } catch (error) {
    yield put(initializeWebSiteErrorAction(error));
  }
}

function* switchLanguageAction({locale}) {
  try {
    localStorage.setItem('locale', locale);

    const genericData = yield call(GenericService.getGenericData);

    // Change redux-stored translations
    const translations = genericData.translations;
    const formattedTranslations = {};

    // Prevent failure on language not found
    translations.forEach((t) => { formattedTranslations[t.key] = t.text; });

    const languageShortName = locale.split('-')[0];

    // Change Walkme Language
    if (typeof window.WalkMeAPI === 'object' && window.WalkMeAPI) {
      console.info('WalkMeAPI.changeLanguage(%s)', languageShortName.toLowerCase());

      // Special case : English lang requires no params
      window.WalkMeAPI.changeLanguage(languageShortName.toLowerCase() === 'en' ? '' : languageShortName.toLowerCase());
    } else {
      console.warn('WALKMEAPI not found');
    }

    moment.locale(locale.slice(0, 2));

    yield store.dispatch(updateIntl({
      locale: locale,
      messages: formattedTranslations,
    }));

    yield put(initializeWebSiteSuccessAction(genericData));
  } catch (error) {
    yield put(initializeWebSiteErrorAction(error));
  }
}

export function* LocaleSagaRegister() {
  yield takeEvery(INIT_WEBSITE_SAGA, initializeWebSite);
  yield takeEvery(SWITCH_LANGUAGE_SAGA, switchLanguageAction);
}

export default function* rootSaga() {
  yield all([fork(LocaleSagaRegister)]);
}
