/* @flow */

import React from 'react';
import {useSelector} from 'react-redux';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

type CompanyDocumentTypeFilterComponentProps = {
  fullWidth: boolean;
  margin: string;
  value: number;
  values: number[];
  changeHandler: (documentTypeId: number) => void;
}

export default function CompanyDocumentTypeFilterComponent(props: CompanyDocumentTypeFilterComponentProps) {
  const documentTypes = useSelector(state => state.locale.documentTypes);

  const options = [];
  props.values.forEach(id => {
    const label = documentTypes.find(d => d.id === id)?.name || 'missing';
    options.push({id, label});
  });

  const value = options.find(o => o.id === props.value);

  const handleSelect = (value: number) => {
    if (value == null) return props.changeHandler(0);
    return props.changeHandler(value);
  }; 

  return (
    <Autocomplete
      disabled={props.disabled}
      getOptionLabel={(value) => value.label}
      onChange={(_, newValue) => handleSelect(newValue?.id || null)}
      openOnFocus
      options={options}
      renderInput={(params) =>
        <TextField 
          {...params}
          fullWidth={props.fullWidth}
          margin={props.margin}
        />
      }
      value={value}
    />
  );
}
