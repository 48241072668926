/* @flow */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function IconTransferOutlined(props) {
  return (
    <SvgIcon viewBox="0 0 64.38 64.38" {...props}>
      <g>
        <path d="M421.7,329.67a32.19,32.19,0,1,1,32.14-32.12A32.22,32.22,0,0,1,421.7,329.67Zm0-61.52a29.38,29.38,0,1,0,29.41,29.35A29.35,29.35,0,0,0,421.68,268.15Z" transform="translate(-389.47 -265.29)"/>
        <path d="M425.75,290.39H408.22c-1,0-1.8-.16-1.85-1.37q-.06-1.47,1.89-1.47h17.49c0-1.91-.07-3.71,0-5.5a2.51,2.51,0,0,1,.89-1.74c.34-.23,1.24,0,1.69.36,3.22,2.26,6.4,4.59,9.57,6.92,1.18.87,1.18,1.85,0,2.73-3.2,2.36-6.41,4.72-9.66,7a2,2,0,0,1-1.72.25,2.15,2.15,0,0,1-.76-1.59C425.69,294.19,425.75,292.38,425.75,290.39Zm2.93,3.19,6.32-4.66-6.32-4.55Z" transform="translate(-389.47 -265.29)"/>
        <path d="M416.47,304.67h17.12a9.74,9.74,0,0,1,1.08,0,1.25,1.25,0,0,1,1.23,1.36,1.2,1.2,0,0,1-1.24,1.34c-1.25.06-2.5,0-3.75,0-4.43,0-8.87,0-13.3,0-.92,0-1.19.28-1.15,1.17a37,37,0,0,1,0,4.47,2.3,2.3,0,0,1-.84,1.66,2,2,0,0,1-1.73-.32c-3.15-2.24-6.25-4.54-9.35-6.84-1.48-1.1-1.47-1.9,0-3,3.07-2.28,6.14-4.56,9.26-6.77a1.52,1.52,0,0,1,2.64,1.3C416.53,300.87,416.47,302.69,416.47,304.67Zm-2.86-3.3-6.38,4.68,6.38,4.67Z" transform="translate(-389.47 -265.29)"/>
      </g>
    </SvgIcon>
  );
}
