import React from 'react';
import {FormattedMessage} from 'react-intl';
import {IntlProvider} from 'react-intl-redux';

export default function IntlMessageComponent(props: IntlMessageComponentProps) {
  const {id, walkmeId, ...rest} = props;
  const lowerCaseId = id.toLowerCase();

  if (walkmeId) {
    return (
      <span data-walkme={walkmeId}>
        <IntlProvider>
          <FormattedMessage id={lowerCaseId} {...rest} />
        </IntlProvider>
      </span>
    );
  }

  return (
    <IntlProvider>
      <FormattedMessage id={lowerCaseId} {...rest} />
    </IntlProvider>
  );
};