/* @flow */

import CommissionInvoiceIsoPerson from './commissionInvoiceIsoPerson';

export default class CommissionInvoiceIsoOffice {
  id: number;
  name: string;
  persons: CommissionInvoiceIsoPerson[];
  debtAmount: number;
  financedAmount: number;
  paidServiceFee: number;
  invoiceCommission: number;

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
    this.persons = [];
    this.debtAmount = 0;
    this.financedAmount = 0;
    this.paidServiceFee = 0;
    this.invoiceCommission = 0;
  }
}
