/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import * as reactGA from 'lib/analytics';
import TagManager from 'react-gtm-module';

import LoadingComponent from 'components/loading';

import OfferTab4ISOComponent from './iso';
import OfferTab4LeadComponent from './lead';
import OfferTab4SellerComponent from './seller';

import DebtorCompanyModel from 'models/companies/debtorCompany';
import LeadOfferModel from 'models/leads/leadOffer';
import SendLeadOfferRequest from 'models/requests/leads/sendLeadOfferRequest';

import LeadService from 'services/LeadService';

import NotificationHelper from 'lib/notifications';

import {history} from 'store/index';
import {PROTECTED_SELLER_SELL} from 'constants/pageRoutes';

import {ROLE_ISO, ROLE_SELLER} from 'constants/companyRoles';

type OfferTab4ComponentProps = {
  activeCompanyRole: number;
  leadOfferId: number;
  newSimulationWithSameDebtor: (debtorCompany: DebtorCompanyModel) => void;
  newSimulationWithOtherDebtor: () => void;
  newSimulationWithOtherSeller: () => void;
};

type OfferTab4ComponentState = {
  isLoading: boolean;
  isProcessing: boolean;
  leadOffer: LeadOfferModel;
};

class OfferTab4Component extends React.Component<OfferTab4ComponentProps, OfferTab4ComponentState> {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isProcessing: false,
      leadOffer: null
    };
  }

  componentDidMount = async () => {
    reactGA.initializeGA();
    reactGA.logEvent('Offer', 'Offer Completed', `Lead Offer Id: ${this.props.leadOfferId}`);
    TagManager.dataLayer({ dataLayer: { event: 'offerCompleted', formType: 'Offer' } })

    try {
      const leadOffer = await LeadService.getLeadOffer(this.props.leadOfferId);
      this.setState({leadOffer, isLoading: false});
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }

    try {
      const script = document.createElement("script");

      script.type = "text/jsx";
      script.src = "function uet_report_conversion() {window.uetq = window.uetq || [];window.uetq.push('event', 'Entered Tab 4|Offer Completed', {});}";
      script.async = true;
  
      document.body.appendChild(script);
    } catch (e) {
      console.error(e);
    }
  };

  componentWillUnmount = async () => {
    reactGA.logEvent('Offer', 'Ask for another offer - restart flow', `Lead Offer Id: ${this.props.leadOfferId}`);
  }

  newSimulationWithSameDebtor = () => {
    if (this.state.isProcessing) return;
    this.props.newSimulationWithSameDebtor(this.state.leadOffer.debtorCompany);
  }

  newSimulationWithOtherDebtor = () => {
    if (this.state.isProcessing) return;
    this.props.newSimulationWithOtherDebtor();
  }

  newSimulationWithOtherSeller = () => {
    if (this.state.isProcessing) return;
    this.props.newSimulationWithOtherSeller();
  }

  sellInvoice = () => {
    if (this.state.isProcessing) return;
    history.push({
      pathname: PROTECTED_SELLER_SELL,
      state: {
        leadOffer: this.state.leadOffer
      }
    });
  }

  sendToISO = async () => {
    this.setState({isProcessing: true});
    try {
      const request = new SendLeadOfferRequest(this.props.leadOfferId, true);
      await LeadService.sendLeadOffer(request);
      this.setState({isProcessing: false});
      NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS);
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  }

  sendToISOAndCustomer = async () => {
    this.setState({isProcessing: true});
    try {
      const request = new SendLeadOfferRequest(this.props.leadOfferId, false);
      await LeadService.sendLeadOffer(request);
      this.setState({isProcessing: false});
      NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS);
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  }

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    if (this.props.activeCompanyRole === ROLE_ISO) {
      return (
        <OfferTab4ISOComponent
          isProcessing={this.state.isProcessing}
          leadOffer={this.state.leadOffer}
          newSimulationWithSameDebtor={this.newSimulationWithSameDebtor}
          newSimulationWithOtherDebtor={this.newSimulationWithOtherDebtor}
          newSimulationWithOtherSeller={this.newSimulationWithOtherSeller}
          sendToISO={this.sendToISO}
          sendToISOAndCustomer={this.sendToISOAndCustomer}
        />
      );
    }

    if (this.props.activeCompanyRole === ROLE_SELLER) {
      return (
        <OfferTab4SellerComponent
          isProcessing={this.state.isProcessing}
          leadOffer={this.state.leadOffer}
          newSimulationWithSameDebtor={this.newSimulationWithSameDebtor}
          newSimulationWithOtherDebtor={this.newSimulationWithOtherDebtor}
          sellInvoice={this.sellInvoice}
        />
      );
    }

    return (
      <OfferTab4LeadComponent
        isProcessing={this.state.isProcessing}
        leadOffer={this.state.leadOffer}
        newSimulationWithSameDebtor={this.newSimulationWithSameDebtor}
        newSimulationWithOtherDebtor={this.newSimulationWithOtherDebtor}
      />
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyRole: state.auth.user !== null ? state.auth.user.activeCompany.role : 0
});

export default connect(mapStateToProps)(OfferTab4Component);
