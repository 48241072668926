/* @flow */

import React from 'react';
import {useSelector} from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

type BuyerInvoiceStatusFilterComponentProps = {
  disabled: boolean;
  fullWidth: boolean;
  margin: string;
  value: string;
  values: string[];
  changeHandler: (statusKey: string) => void;
}

const useStyles = makeStyles({
  inputRoot: {
    "& .MuiIconButton-label": {
      color: '#3cf',
    }
  },
});

export default function BuyerInvoiceStatusFilterComponent(props: BuyerInvoiceStatusFilterComponentProps) {
  const translations = useSelector(state => state.locale.translations.filter(t => t.key.startsWith('invoice.status.buyer')));

  const options = [];
  props.values.forEach(val => {
    const id = val;
    const key = `invoice.status.${val.toLowerCase()}`;
    const label = translations.find(t => t.key === key)?.text || key;
    options.push({id, label});
  });

  const classes = useStyles();

  const value = options.find(o => o.id === props.value);

  const handleSelect = (value: string) => {
    if (value == null) return props.changeHandler('');
    return props.changeHandler(value);
  }; 

  return (
    <Autocomplete
      disabled={props.disabled}
      getOptionLabel={(value) => value.label}
      key={props.value}
      onChange={(_, newValue) => handleSelect(newValue?.id || null)}
      openOnFocus
      options={options}
      classes={classes}
      renderInput={(params) =>
        <TextField 
          {...params}
          fullWidth={props.fullWidth}
          margin={props.margin}
          
        />
      }
      value={value}
    />
  );
}
