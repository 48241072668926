/* @flow */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function IconHourglassOutlined(props) {
  return (
    <SvgIcon viewBox="0 0 62.72 62.69" {...props}>
      <path d="M396.66,337.44a31.35,31.35,0,1,1,31.43-31C428.06,323.45,413.85,337.46,396.66,337.44Zm28.64-31.16c-.12-16.16-12.67-28.75-28.53-28.75A28.32,28.32,0,0,0,368.18,305c-.42,17.26,12.78,29.73,28.55,29.68A28.45,28.45,0,0,0,425.3,306.28Z" transform="translate(-365.37 -274.75)"/>
      <g>
        <path d="M386.25,321.18a26.76,26.76,0,0,1,1.69-8.48,14.68,14.68,0,0,1,5-6.87c-4.74-4-6.17-9.47-6.71-15.43-.49,0-1,0-1.52,0a.86.86,0,0,1-1-.81c0-.48.39-.84,1-.84h24.62c.61,0,1.05.18,1.12.83s-.41.82-1.11.83H408c-.52,5.9-2,11.33-6.66,15.38,4.85,3.94,6.18,9.42,6.66,15.38h1.26c.89,0,1.25.25,1.27.82s-.34.83-1.24.83H385.15c-.22,0-.44,0-.65,0a.71.71,0,0,1-.75-.84,1.2,1.2,0,0,1,.77-.75A8.26,8.26,0,0,1,386.25,321.18Zm1.36-30.74c0,.18,0,.29,0,.41a21.17,21.17,0,0,0,1.27,6.59c1.12,3.08,2.61,5.9,5.52,7.72.6.38.55.91,0,1.33a13,13,0,0,0-6,9.08,19.47,19.47,0,0,0-.73,5.56h19a1.8,1.8,0,0,0,0-.37c-.24-1.68-.34-3.39-.74-5-.93-3.78-2.56-7.15-6-9.31-.63-.38-.54-.91.07-1.37a16.27,16.27,0,0,0,2.28-1.93,17.93,17.93,0,0,0,4-8.62c.28-1.32.38-2.67.57-4.06Z" transform="translate(-365.37 -274.75)"/>
        <path d="M405.36,319.82H389c.17-1.07.28-2.1.5-3.12a15.41,15.41,0,0,1,3.66-7.59,8,8,0,0,1,1.85-1.46,2.07,2.07,0,0,0-.05-3.7,8.16,8.16,0,0,1-3.46-4l13.1-4.4a17.35,17.35,0,0,1-2.39,5.52,9.39,9.39,0,0,1-3.11,3,1.88,1.88,0,0,0-.15,3.24c3.44,2,4.84,5.36,5.79,9C405,317.42,405.13,318.57,405.36,319.82Z" transform="translate(-365.37 -274.75)"/>
      </g>        
    </SvgIcon>
  );
}
