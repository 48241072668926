/* @flow */

type AmountValidatorOptions = {
  greaterThanOrEqualTo: ?number;
  notGreaterThanOrEqualTo: number;
  mandatory: ?boolean;
  message: ?string;
}

function amountValidator(value: string, options: AmountValidatorOptions, key: string, attributes: any) {
  if (!options.mandatory && value === '') return undefined;
  if (options.mandatory && (!value || value === '')) return 'is not a number';

  const num = Number(value);
  if (isNaN(num)) return 'is not a number';

  if(options.greaterThanOrEqualTo && options.greaterThanOrEqualTo > num) {
    return options.notGreaterThanOrEqualTo;
  }

  return undefined;
}

export default amountValidator;
