/* @flow */

import React from 'react';
import moment from 'moment';

import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';

import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';
import DatePickerComponent from 'components/formFields/dates/datePicker';

import DocumentItem from 'models/documents/documentItem';

type BuyerInvoicesPortfolioExportViewComponentProps = {
  documents: DocumentItem[];
  selectedDocument: DocumentItem;
  selectedDocumentDate: moment;
  documentsEarliestDate: moment;
  downloadDocument: (documentId: number) => void;
  documentDateChange: (dateTo: moment) => void;
};
  
  
export default function BuyerInvoicesPortfolioExportViewComponent(props: BuyerInvoicesPortfolioExportViewComponentProps) {
  return (
    <React.Fragment>
      <Box maxWidth={300}>
      <Paper elevation={0}>
      <Box px={3} py={5}>
      <IntlMessageComponent id="buyer.portfolio.document.export" />
        <Box component="div" overflow="auto">
          
          <FormControl>
            <DatePickerComponent
              onChange={(value: moment) => props.documentDateChange(value)}
              mode="landscape"
              value={props.selectedDocumentDate}
              floatingLabelText="Date"
              minDate={props.documentsEarliestDate}
              maxDate={new Date()} 
            />
            <Box mt={1}>
              <ButtonComponent
                color="primary"
                variant="contained"
                disabled={props.selectedDocument === undefined}
                onClick={() => props.downloadDocument(props.selectedDocument.id)}
              ><IntlMessageComponent id="general.button.downloadDocument" /></ButtonComponent>
            </Box>
          </FormControl>
        </Box>
      </Box>
      </Paper>
      </Box>
    </React.Fragment>
  );
}
