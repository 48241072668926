/* @flow */

import React, { useState } from 'react';
import moment from 'moment';

import Box from '@material-ui/core/Box';
import Download from '@material-ui/icons/CloudDownload';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import ButtonComponent from 'components/buttons/button';
import FileExportDialogComponent from 'components/fileExportDialog';
import IntlMessageComponent from 'components/formatters/intlMessage';
import SecurityComponent from 'components/security/index';

import InvoicePeriodFilterComponent from './invoicePeriod';
import InvoiceStatusFilterComponent from './invoiceStatus';

import InvoiceActiveFilters from 'models/buyers/invoices/invoiceActiveFilters';
import InvoiceFilters from 'models/buyers/invoices/invoiceFilters';

type SellerInvoiceTableControlsComponentProps = {
  activeFilters: InvoiceActiveFilters;
  filters: InvoiceFilters;
  handleExport: (format: string) => void;
  handlePaymentReport: (format: string) => void;
  handleFiltersChange: (filters: InvoiceFilters) => void;
}

export default function SellerInvoiceTableControlsComponent(props: SellerInvoiceTableControlsComponentProps) {
  const [showExportPopUp, setShowExportPopUp] = useState(false);
  const [showPaymentExportPopUp, setShowPaymentReportPopUp] = useState(false);
  const dateCheck = new Date('2022-01-01');

  const handlePortfolioExport = (format) => {
    setShowExportPopUp(false);
    props.handleExport(format);
  }

  const handlePaymentReportExport = (format) => {
    setShowPaymentReportPopUp(false);
    props.handlePaymentReport(format);
  }

  const handleQueryChange = (event) => {
    const activeFilters = props.activeFilters;
    activeFilters.query = event.target.value;
    props.handleFiltersChange(activeFilters);
  };

  const handleStatusChange = (statusKey: string) => {
    const activeFilters = props.activeFilters;
    activeFilters.statusKey = statusKey;
    props.handleFiltersChange(activeFilters);
  };

  const handlePeriodChange = (dateFrom: moment, dateTo: moment) => {
    const activeFilters = props.activeFilters;
    activeFilters.dateFrom = dateFrom;
    activeFilters.dateTo = dateTo;
    props.handleFiltersChange(activeFilters);
  };

  return (
    <Paper elevation={0}>
      <Box p={3} py={4}>
        <Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={3}>
          <Grid container item xs={12} md={6}>
            <Grid item xs={12} md={6}>
              <Box display="flex" height="100%" alignItems="center">
                <b><IntlMessageComponent id="component.portfolio.search" /></b>
                <span style={{ paddingLeft: "0.5rem" }}><IntlMessageComponent id="component.portfolio.filter.search.help" /></span>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                value={props.activeFilters.query}
                onChange={handleQueryChange}
                margin="dense"
                fullWidth
              />
            </Grid>
          </Grid>

          <Grid container item xs={12} md={6}>
            <Grid item xs={12} md={6}>
              <Box display="flex" height="100%" alignItems="center">
                <b><IntlMessageComponent id="component.portfolio.filter.status" /></b>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <InvoiceStatusFilterComponent
                value={props.activeFilters.statusKey}
                values={props.filters.statusKeys}
                changeHandler={handleStatusChange}
                margin="dense"
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={3}>
          <Grid container item xs={12} md={6}>
            <Grid item xs={12} md={6}>
              <Box display="flex" height="100%" alignItems="center">
                <b><IntlMessageComponent id="component.portfolio.filter.date" /></b>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <InvoicePeriodFilterComponent
                dateFrom={props.activeFilters.dateFrom}
                dateTo={props.activeFilters.dateTo}
                changeHandler={handlePeriodChange}
                margin="dense"
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={3}>
          <Grid container item xs={12} md={6}>
            <Grid item>
              <Box mt={3}>
                <SecurityComponent pageName="POS.Sellers.Portfolio" elementName="btnExport">
                  <ButtonComponent color="primary" variant="contained" onClick={() => setShowExportPopUp(true)} startIcon={<Download />} data-walkme="sportfolio-button-exporter">
                    <IntlMessageComponent id="component.portfolio.export" />
                  </ButtonComponent>
                </SecurityComponent>
              </Box>
            </Grid>
            {/* <Grid item>
              <Box mt={3}>
                <SecurityComponent pageName="POS.Sellers.Portfolio" elementName="btnExport">
                  {props.activeFilters.dateFrom < dateCheck && <Box ml={3}>
                    <ButtonComponent disabled color="primary" variant="contained" onClick={() => setShowPaymentReportPopUp(true)} startIcon={<Download />}>
                      <IntlMessageComponent id="component.portfolio.paymentreport" />
                    </ButtonComponent>
                  </Box>
                  }
                  {props.activeFilters.dateFrom >= dateCheck && <Box ml={3}>
                    <ButtonComponent color="primary" variant="contained" onClick={() => setShowPaymentReportPopUp(true)} startIcon={<Download />}>
                      <IntlMessageComponent id="component.portfolio.paymentreport" />
                    </ButtonComponent>
                  </Box>
                  }
                </SecurityComponent>
              </Box>
            </Grid> */}
          </Grid>
        </Grid>
      </Box>

      {showExportPopUp &&
        <FileExportDialogComponent
          isOpen
          typesAllowed={['xlsx', 'csv']}
          handleExport={(format: string) => handlePortfolioExport(format)}
          handleClose={() => setShowExportPopUp(false)}
        />
      }
      {showPaymentExportPopUp &&
        <FileExportDialogComponent
          isOpen
          typesAllowed={['xlsx', 'csv']}
          handleExport={(format: string) => handlePaymentReportExport(format)}
          handleClose={() => setShowPaymentReportPopUp(false)}
        />
      }
    </Paper>
  );
}
