/* @flow */

import React from 'react';
import { useHistory } from 'react-router-dom';

import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';

import IconBell from 'components/icons/bell';
import ButtonComponent from 'components/buttons/button';

import AlertModel from 'models/dashboards/alert';

type AlertViewComponentProps = {
  alert: AlertModel
}

export default function AlertViewComponent(props: AlertViewComponentProps) {
  const history = useHistory();

  const onClick = () => {
    history.push(props.alert.ctaLink)
  }

  let action = null;
  if (props.alert.ctaText && props.alert.ctaLink) {
    action = (
      <ButtonComponent
        color="tertiary"
        variant="contained"
        onClick={onClick}
      >
        <Box minWidth={120}>{props.alert.ctaText}</Box>
      </ButtonComponent>
    );
  }

  return (
    <Alert action={action} icon={<IconBell color="primary" />} severity="info" style={{ backgroundColor: '#e7f9ff' }}>
      <strong>{props.alert.title}</strong> {props.alert.message}
    </Alert>
  );
}
