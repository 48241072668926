/* @flow */

import { plainToClass } from 'class-transformer';

import BaseService from './api/baseService';

import {
  USER_COMPANY_GET,
  USER_GET,
  USER_MOBILE_POST,
  USER_NOTIFICATION_SETTINGS_GET,
  USER_NOTIFICATION_SETTINGS_POST,
  USER_PASSWORD_CHANGE_POST,
  USER_PASSWORD_LOST_POST,
  USER_PASSWORD_RESET_POST,
  USER_PERSON_INFO_GET,
  USER_PERSON_INFO_POST,
  USER_QR_CODE_GET,
  USER_GENERATE_SECRET_KEY,
  USER_DISABLE_TWO_FACTOR,
  USER_CHECK_TWO_FACTOR,
  USER_VALIDATE_TWO_FACTOR
} from 'constants/apiRoutes';

import ChangePasswordRequest from 'models/requests/users/changePasswordRequest.js';
import LostPasswordRequest from 'models/requests/users/lostPasswordRequest.js';
import ResetPasswordRequest from 'models/requests/users/resetPasswordRequest.js';
import UpdateMobileRequest from 'models/requests/users/updateMobileRequest';
import UpdateNotificationSettingsRequest from 'models/requests/users/updateNotificationSettingsRequest';
import UpdatePersonalInfoRequest from 'models/requests/users/updatePersonalInfoRequest';

import BankAccount from 'models/companies/bankAccount';
import InvestmentProfile from 'models/buyers/investmentProfiles/investmentProfile';
import NotificationSetting from 'models/users/notificationSetting';
import NotificationSettings from 'models/users/notificationSettings';
import Person from 'models/users/person';
import SalesPerson from 'models/isos/salesPerson';
import SalesPersonPhoneNumber from 'models/isos/salesPersonPhoneNumber';
import User from 'models/users/user';
import UserCompany from 'models/users/userCompany';
import UserCompanyRoleBuyer from 'models/users/userCompanyRoleBuyer';
import UserCompanyRoleDebtor from 'models/users/userCompanyRoleDebtor';
import UserCompanyRoleISO from 'models/users/userCompanyRoleISO';
import UserCompanyRoleSeller from 'models/users/userCompanyRoleSeller';

export default class UserService {

  static checkUserHasTwoFactor = (username: string) =>
    BaseService.anonymousPOST(`${USER_CHECK_TWO_FACTOR}`, username,
      data => data
    );

  static retrieveEdebexUserInfo = () =>
    BaseService.simpleGET(`${USER_GET}`,
      data => {
        const user = plainToClass(User, data);
        user.companies = data.companies.map(c => UserService.mapUserCompany(c));
        user.activeCompany = user.companies.find(c => c.isMain);
        return user;
      });

  static getUserCompany = (companyId: number) =>
    BaseService.simpleGET(`${USER_COMPANY_GET}/${companyId}`,
      data => UserService.mapUserCompany(data)
    );

  static mapUserCompany = (c: any) => {
    const company = plainToClass(UserCompany, c);
    if (c.roleBuyer) {
      company.roleBuyer = plainToClass(UserCompanyRoleBuyer, c.roleBuyer);
      if (c.roleBuyer.investmentProfiles) {
        company.roleBuyer.investmentProfiles = c.roleBuyer.investmentProfiles.map(i => plainToClass(InvestmentProfile, i));
        company.investmentProfile = company.roleBuyer.investmentProfiles[0];
      }
    }
    if (c.roleDebtor) {
      company.roleDebtor = plainToClass(UserCompanyRoleDebtor, c.roleDebtor);
      if (c.roleDebtor.salesPerson) {
        company.roleDebtor.salesPerson = plainToClass(SalesPerson, c.roleDebtor.salesPerson);
        company.roleDebtor.salesPerson.phoneNumbers = c.roleDebtor.salesPerson.phoneNumbers.map(p => plainToClass(SalesPersonPhoneNumber, p));
      }
      if (c.roleDebtor.bankAccount) {
        company.roleDebtor.bankAccount = plainToClass(BankAccount, c.roleDebtor.bankAccount);
      }
    }
    if (c.roleISO) {
      company.roleISO = plainToClass(UserCompanyRoleISO, c.roleISO);
      if (c.roleISO.salesPerson) {
        company.roleISO.salesPerson = plainToClass(SalesPerson, c.roleISO.salesPerson);
        company.roleISO.salesPerson.phoneNumbers = c.roleISO.salesPerson.phoneNumbers.map(p => plainToClass(SalesPersonPhoneNumber, p));
      }
    }
    if (c.roleSeller) company.roleSeller = plainToClass(UserCompanyRoleSeller, c.roleSeller);
    return company;
  }

  // Get Personal info
  static getPersonalInfo = () =>
    BaseService.simpleGET(USER_PERSON_INFO_GET,
      data => plainToClass(Person, data));

  static updatePersonalInfo = async (request: UpdatePersonalInfoRequest) =>
    BaseService.simplePOST(USER_PERSON_INFO_POST, request, null);

  static updateMobile = async (request: UpdateMobileRequest) =>
    BaseService.simplePOST(USER_MOBILE_POST, request, null);

  static changePassword = async (request: ChangePasswordRequest) =>
    BaseService.simplePOST(USER_PASSWORD_CHANGE_POST, request, null);

  static lostPassword = async (request: LostPasswordRequest) =>
    BaseService.anonymousPOST(USER_PASSWORD_LOST_POST, request, null);

  static resetPassword = async (request: ResetPasswordRequest) =>
    BaseService.anonymousPOST(USER_PASSWORD_RESET_POST, request, null);

  static getNotificationSettings = (companyId: number, companyRoleId: number) =>
    BaseService.simpleGET(`${USER_NOTIFICATION_SETTINGS_GET}/${companyId}/${companyRoleId}`,
      data => {
        const settings = plainToClass(NotificationSettings, data);
        settings.settings = data.settings.map(n => plainToClass(NotificationSetting, n));
        return settings;
      });

  static getQRCode = () =>
    BaseService.simpleGET(USER_QR_CODE_GET,
      data => {
        const qrCodeUrl = data.item1 ?? null;
        const isValidated = data.item2;
        return [qrCodeUrl, isValidated];
      });

  static generateSecretKey = () =>
    BaseService.simplePOST(USER_GENERATE_SECRET_KEY, null, null);

  static validateTwoFactor = (twoFactorPin: string) =>
    BaseService.simplePOST(`${USER_VALIDATE_TWO_FACTOR}`, twoFactorPin,
      data => data
    );

  static disableTwoFactorAuthentication = () =>
    BaseService.simplePOST(USER_DISABLE_TWO_FACTOR, null, null);

  static setNotificationSettings = async (request: UpdateNotificationSettingsRequest) =>
    BaseService.simplePOST(USER_NOTIFICATION_SETTINGS_POST, request, null);
}
