/* @flow */

import React, { useState } from 'react';
import moment from 'moment';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import CollapseLinkComponent from 'components/isoFilters/collaspsibleLink';
import ComponentWrapperComponent from 'components/websiteStructure/componentWrapper';
import FilterComponent from 'components/isoFilters/filter';
import FilterISOComponent from 'components/isoFilters/iso';
import FilterPeriodComponent from 'components/isoFilters/period';
import LeadActionsComponent from './actions';

import LeadFilters from 'models/isos/leads/leadFilters';

type ISOLeadFiltersComponentProps = {
  filters: LeadFilters;
  selectFilter: (filterId: string, optionId: number) => void;
  selectFilterISO: (filterId: string, optionId: number) => void;
  selectFilterPeriod: (filterId: string, dateFrom: moment, dateTo: moment) => void;
  clearFilters: () => void;
  onExportAsked: (format: string) => void;
}

export default function ISOLeadFiltersComponent(props: ISOLeadFiltersComponentProps) {
  const [showFilters, setShowFilters] = useState(true);

  const toggleShowFilters = () => setShowFilters(showFilters => !showFilters);
  const openClosedId = props.filters.openClosedLeads.find(x => x.id === 0).isSelected &&
    !props.filters.openClosedLeads.find(x => x.id === 1).isSelected ? 0 :
    props.filters.openClosedLeads.find(x => x.id === 1).isSelected &&
      !props.filters.openClosedLeads.find(x => x.id === 0).isSelected ? 1 : 2;

  return (
    <Box mb={showFilters ? 3 : 'auto'}>
      <Box mb={3}>
        <CollapseLinkComponent
          isCollapsed={!showFilters}
          labelClosed="component.isos.leads.filters.collapse.closed.label"
          labelOpen="component.isos.leads.filters.collapse.open.label"
          toggle={toggleShowFilters}
          data-walkme="isoleads-walkthru-step1"
        />
      </Box>

      {showFilters &&
        <React.Fragment>
          <ComponentWrapperComponent small data-walkme="isoleads-walkthru-step2">
            <Grid container>
              <FilterISOComponent
                pageName="POS.Leads"
                companyLabel="component.isos.leads.filters.isoCompany.label"
                officeLabel="component.isos.leads.filters.isoOffice.label"
                personLabel="component.isos.leads.filters.isoPerson.label"
                filters={props.filters.companies}
                selectFilter={props.selectFilterISO}
              />

              <Grid item xs="auto">
                <Box pr={4} minWidth={200}>
                  <FilterComponent
                    filterId="owners"
                    filters={props.filters.owners}
                    label="component.isos.leads.filters.owners.label"
                    selectFilter={props.selectFilter}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs="auto">
                <Box pr={4} minWidth={200}>
                  <FilterComponent
                    filterId="openClosedLeads"
                    filters={props.filters.openClosedLeads}
                    label="component.isos.leads.filters.openclosed.label"
                    selectFilter={props.selectFilter}
                  />
                </Box>
              </Grid>

              <Grid item xs="auto">
                <Box pr={4} minWidth={200}>
                  <FilterComponent
                    filterId="status"
                    filters={props.filters.status}
                    label="component.isos.leads.filters.status.label"
                    selectFilter={props.selectFilter}
                    openClosedId={openClosedId}
                  />
                </Box>
              </Grid>

              <Grid item xs="auto">
                <Box pr={4} minWidth={200}>
                  <FilterComponent
                    filterId="convertible"
                    filters={props.filters.convertible}
                    label="component.isos.leads.filters.convertible.label"
                    selectFilter={props.selectFilter}
                  />
                </Box>
              </Grid>

              <Grid item xs="auto">
                <Box pr={4} minWidth={200}>
                  <FilterComponent
                    filterId="lostReasons"
                    filters={props.filters.lostReasons}
                    label="component.isos.leads.filters.lostReason.label"
                    selectFilter={props.selectFilter}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs="auto">
                <Box pr={4} minWidth={200}>
                  <FilterPeriodComponent
                    filter={props.filters.creationDate}
                    filterId="creationDate"
                    label="component.isos.leads.filters.creationDate.label"
                    selectLabel="component.isos.leads.filters.creationDate.select"
                    selectFilter={props.selectFilterPeriod}
                  />
                </Box>
              </Grid>
              <Grid item xs="auto">
                <Box pr={4} minWidth={200}>
                  <FilterPeriodComponent
                    filter={props.filters.closedDate}
                    filterId="closedDate"
                    label="component.isos.leads.filters.closedDate.label"
                    selectLabel="component.isos.leads.filters.closedDate.select"
                    selectFilter={props.selectFilterPeriod}
                  />
                </Box>
              </Grid>
            </Grid>

          </ComponentWrapperComponent>
          <Box mt={3}>
            <LeadActionsComponent
              onExportAsked={props.onExportAsked}
              clearFilters={props.clearFilters}
            />
          </Box>
        </React.Fragment>
      }
    </Box>

  );
}
