/* @flow */

import moment from 'moment';
import InvoiceActiveFilters from 'models/sellers/invoices/invoiceActiveFilters';

export default class GetInvoicesPortfolioExportRequest {
  companyId: number;
  format: string;
  query:string;
  statusKey: string;
  dateFrom: moment;
  dateTo: moment;

  constructor(companyId: number, format: string, filters: InvoiceActiveFilters) {
    this.companyId = companyId;
    this.format = format;
    this.query = filters.query;
    this.statusKey = filters.statusKey;
    this.dateFrom = filters.dateFrom;
    this.dateTo = filters.dateTo;
  }

  toRouteParameters() {
    let route = `companyId=${this.companyId}&format=${this.format}`;
    if (this.query !== null && this.query !== '') {
      route += `&query=${this.query}`;
    }
    if (this.statusKey !== null && this.statusKey !== '') {
      route += `&statusKey=${this.statusKey}`;
    }
    if (this.dateFrom && this.dateTo) {
      route += `&dateFrom=${this.dateFrom.format('YYYY-MM-DD')}&dateTo=${this.dateTo.format('YYYY-MM-DD')}`;
    } 
    return route;
  }
}
