/* @flow */

import React from 'react';

import LoadingComponent from 'components/loading';

import DebtorInvoicesToApproveFormViewComponent from './view';

import Company from 'models/companies/company';
import Invoice from 'models/debtors/invoice';
import ApproveOrDisputeInvoiceForm from './model';

import {handleFormChange, handleFormValidation} from 'lib/forms';
import loadConstraints from 'lib/validation/loadConstraints';

type DebtorInvoicesToApproveFormComponentProps = {
  debtorCompany: Company;
  invoices: Invoice[];
  isProcessing: boolean;
  approveInvoices: (form: ApproveOrDisputeInvoiceForm) => void;
  disputeInvoices: (form: ApproveOrDisputeInvoiceForm) => void;
}

type DebtorInvoicesToApproveFormComponentState = {
  errors: Map<string, ?string>;
  form: ApproveOrDisputeInvoiceForm;
  isApprovable: boolean;
  isDisputable: boolean;
  isLoading: boolean;
}

class DebtorInvoicesToApproveFormComponent extends React.Component<DebtorInvoicesToApproveFormComponentProps, DebtorInvoicesToApproveFormComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      errors: new Map(),
      form: new ApproveOrDisputeInvoiceForm(),
      isApprovable: false,
      isDisputable: false,
      isLoading: true
    };
  }

  componentDidMount = async () => {
    try {
      const constraints = await loadConstraints('debtor', ['answerInvoiceGenuineText', 'answerAmountAndDueDateCorrectText',
        'answerServicesOrGoodsDeliveredText', 'answerWillDebtorDisputeInvoiceText', 'answerGuaranteeDeductionOrCompensationText']);

      this.setState({
        constraints,
        errors: new Map(Object.keys(constraints).map(e => [e, undefined])),
        isLoading: false
      });
    } catch (e) {
      console.error(e);
    }
  };

  handleBooleanChange = (fieldName: string) => (event) => {
    const form = this.state.form;
    form[fieldName] = event.target.value === "true"

    let isApprovable = form.isFormApprovable();
    let isDisputable = form.isFormDisputable();
    
    this.setState({form, isApprovable, isDisputable});
  };

  handleChange = (fieldName: string) => (event) => handleFormChange.call(this, fieldName, event.target.value);
  validateForm = () => handleFormValidation.call(this);

  submitForm = async () => {
    if (!this.validateForm()) return;

    if (this.state.isApprovable) {
      this.props.approveInvoices(this.state.form);
    } else {
      this.props.disputeInvoices(this.state.form);
    }
  };

  render() {
    if (this.state.isLoading) {
      return <LoadingComponent />;
    }

    return (
      <DebtorInvoicesToApproveFormViewComponent
        debtAmount={this.props.invoices[0].debtAmount}
        debtorCompany={this.props.debtorCompany}
        dueDate={this.props.invoices[0].dueDate}
        errors={this.state.errors}
        form={this.state.form}
        isApprovable={this.state.isApprovable}
        isDisputable={this.state.isDisputable}
        isProcessing={this.props.isProcessing}
        multipleInvoices={this.props.invoices.length > 1}
        handleChange={this.handleChange}
        handleBooleanChange={this.handleBooleanChange}
        submitForm={this.submitForm}
      />
    );
  }
}

export default DebtorInvoicesToApproveFormComponent;