/* @flow */

import React from 'react';
import { useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';

import CompanyFixContractCardViewComponent from './card';
import RealizedDataTableComponent from './realized';

import CompanyFixContract from 'models/companies/companyFixContract';
import PageTitleViewComponent from 'components/pageTitle/index';

type CompanyFixContractViewComponentProps = {
  fixContract: CompanyFixContract;
  showBackButton: boolean;
  downloadDocument: (documentId: number) => void;
  backToSelection: () => void;
}

export default function CompanyFixContractViewComponent(props: CompanyFixContractViewComponentProps) {
  const activeCompany = useSelector(state => state.auth.user.activeCompany);

  return (
    <React.Fragment>
      <PageTitleViewComponent id={"page.fixcontract.h1"} backButtonAction={props.backToSelection} backButton={props.showBackButton}/>
      <Paper elevation={0}>
        <Box p={3}>
          <Grid container direction="row" justify="space-between" alignItems="stretch" spacing={6}>
            <Grid item xs={12} md={6} key={`fixContract-container`}>
              <CompanyFixContractCardViewComponent fixContract={props.fixContract} reloadfixContracts={props.reloadfixContracts} />
            </Grid>

            <Grid item xs={12} md={6}>
              <Box height="100%" display="flex" alignItems="center">
                <ButtonComponent
                  color="primary"
                  variant="contained"
                  isProcessing={props.isProcessing}
                  onClick={() => props.downloadDocument(props.fixContract.contractId)}
                >
                  <IntlMessageComponent id="page.fixcontract.download" />
                </ButtonComponent>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <RealizedDataTableComponent
        realizedData={props.fixContract.realizedData}
      />
    </React.Fragment>
  );
}
