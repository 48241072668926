/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import ButtonComponent from 'components/buttons/button';
import CompanyAddressComponent from 'components/company/address';
import IntlMessageComponent from 'components/formatters/intlMessage';

import Company from 'models/companies/company';
import DebtorCompany from 'models/companies/debtorCompany';

type CompanyItemViewComponentProps = {
  buttonLabelId: string;
  company: Company | DebtorCompany;
  selected: boolean;
  hideVATCountry: boolean;
  selectHandler: ?(company: Company | DebtorCompany) => void;
}

export default function CompanyItemViewComponent(props: CompanyItemViewComponentProps) {
  const selectable = (typeof props.selectHandler === 'function');

  return (
    <Box>
      {props.selected &&
        <Box mb={2}>
          <Typography variant="subtitle1">
            <IntlMessageComponent id="companyItem.label" />
          </Typography>
        </Box>
      }

      <div className="hiddenMobile">
        <Grid
          container
          direction="row"
          justify="space-evenly"
        >
          <Grid item xs>
            <CompanyAddressComponent hideIfNeeded={props.hideVATCountry} company={props.company} />
          </Grid>

          {selectable &&
            <Grid item xs={4}>
              <Box justifyContent="flex-end">
                <ButtonComponent
                  color="primary"
                  variant="contained"
                  onClick={() => props.selectHandler(props.company)}
                ><IntlMessageComponent id={props.buttonLabelId} /></ButtonComponent>
              </Box>
            </Grid>
          }
        </Grid>
      </div>
      <div className="hiddenDesktop">
        <Box>
          <CompanyAddressComponent hideIfNeeded={props.hideVATCountry} company={props.company} />
        </Box>

        {selectable &&
          <Box mb={6}>
            <ButtonComponent
              color="primary"
              variant="contained"
              onClick={() => props.selectHandler(props.company)}
            ><IntlMessageComponent id={props.buttonLabelId} /></ButtonComponent>
          </Box>
        }
      </div>

    </Box >
  );
}

