/* @flow */

import CloseLeadRequest from 'models/requests/isos/closeLeadRequest';

export default class CloseLeadForm {
  leadLostReasonDetailId: number;
  lostToCompetitor: string;
  endOfContract: moment;

  constructor() {
    this.leadLostReasonDetailId = 0;
    this.lostToCompetitor = '';
    this.endOfContract = null;
  }

  toRequest(companyId: number, leadId: number){
    const request = new CloseLeadRequest();
    request.companyId = companyId;
    request.leadId = leadId;
    request.leadLostReasonDetailId = this.leadLostReasonDetailId;
    request.lostToCompetitor = this.lostToCompetitor;
    request.endOfContract = this.endOfContract;
    return request;
  }
}
