/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import CountryFieldComponent from 'components/formFields/countries';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import CompanyUBOForm from '../model';

type CompanyUBOsFormContactInfoPanelComponentProps = {
  errors: Map<string, ?string>;
  form: CompanyUBOForm;
  handleChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void;
  handleTypeChange: (fieldName: string) => (id: number) => void;
}

export default function CompanyUBOsFormContactInfoPanelComponent(props: CompanyUBOsFormContactInfoPanelComponentProps) {
  return (
    <React.Fragment>
      <PageSubTitleViewComponent id="company.ubos.form.contactInfo" />

      <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
        <Grid item xs={12} md={6}>
          <Box px={4}>
            <TextField
              id="form.email"
              label={<IntlMessageComponent id="form.email.label" />}
              error={!!props.errors.get('email')}
              helperText={props.errors.get('email') ?
                <IntlMessageComponent id={props.errors.get('email')} /> : ''}
              value={props.form.email}
              onChange={props.handleChange('email')}
              fullWidth
              margin="normal"
              required
            />
          </Box>
        </Grid>
      </Grid>

      <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
        <Grid item xs={12} md={6}>
          <Box px={4}>
            <TextField
              id="form.addressLine1"
              data-walkme="subscription-field-adresse"
              label={<IntlMessageComponent id="form.person.addressLine1.label" />}
              error={!!props.errors.get('addressLine1')}
              helperText={props.errors.get('addressLine1') ?
                <IntlMessageComponent id={props.errors.get('addressLine1')} /> : ''}
              value={props.form.addressLine1}
              onChange={props.handleChange('addressLine1')}
              fullWidth
              margin="normal"
              required
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box px={4}>
            <TextField
              id="form.addressLine2"
              label={<IntlMessageComponent id="form.person.addressLine2.label" />}
              error={!!props.errors.get('addressLine2')}
              helperText={props.errors.get('addressLine2') ?
                <IntlMessageComponent id={props.errors.get('addressLine2')} /> : ''}
              value={props.form.addressLine2}
              onChange={props.handleChange('addressLine2')}
              fullWidth
              margin="normal"
            />
          </Box>
        </Grid>
      </Grid>

      <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
        <Grid item xs={12} md={6}>
          <Box px={4}>
            <TextField
              id="form.zipCode"
              label={<IntlMessageComponent id="form.person.zipCode.label" />}
              error={!!props.errors.get('zipCode')}
              helperText={props.errors.get('zipCode') ?
                <IntlMessageComponent id={props.errors.get('zipCode')} /> : ''}
              value={props.form.zipCode}
              onChange={props.handleChange('zipCode')}
              fullWidth
              margin="normal"
              required
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box px={4}>
            <TextField
              id="form.city"
              label={<IntlMessageComponent id="form.person.city.label" />}
              error={!!props.errors.get('city')}
              helperText={props.errors.get('city') ?
                <IntlMessageComponent id={props.errors.get('city')} /> : ''}
              value={props.form.city}
              onChange={props.handleChange('city')}
              fullWidth
              margin="normal"
              required
            />
          </Box>
        </Grid>
      </Grid>

      <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
        <Grid item xs={12} md={6}>
          <Box px={4}>
            <CountryFieldComponent
              id="form.country"
              label={<IntlMessageComponent id="form.person.country.label" />}
              error={!!props.errors.get('countryId')}
              helperText={props.errors.get('countryId') ?
                <IntlMessageComponent id={props.errors.get('countryId')} /> : ''}
              value={props.form.countryId}
              changeHandler={props.handleTypeChange('countryId')}
              fullWidth
              margin="normal"
              required
            />
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
