/* @flow */

export default class RefuseInvoiceRequest {
  companyId: number;
  invoiceId: number;
  reason: string;

  constructor(cId: number, iId: number, form: any) {
    this.companyId = cId;
    this.invoiceId = iId;
    this.reason = form.reason === 'Other' ? form.reasonFree : form.reason;
  }
}
