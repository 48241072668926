/* @flow */

import React from 'react';
import { Link } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import AmountFormatterComponent from 'components/formatters/amount';
import ButtonComponent from 'components/buttons/button';
import CompanyAddressComponent from 'components/company/address';
import DateFormatterComponent from 'components/formatters/date';
import DebtorGradeForSellerComponent from 'components/debtorGrades/seller';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import SecurityComponent from 'components/security';

import InvalidLeadOfferDetailsComponent from '../invalid';

import DebtorCompanyModel from 'models/companies/debtorCompany';
import LeadOfferModel from 'models/leads/leadOffer';

import { PROTECTED_SELLER_BESTPRICING } from 'constants/pageRoutes';

type OfferTab4SellerComponentProps = {
  isProcessing: boolean;
  leadOffer: LeadOfferModel;
  newSimulationWithSameDebtor: (debtorCompany: DebtorCompanyModel) => void;
  newSimulationWithOtherDebtor: () => void;
  sellInvoice: () => void;
};

export default function OfferTab4SellerComponent(props: OfferTab4SellerComponentProps) {
  return (
    <React.Fragment>
      <PageSubTitleViewComponent id="component.offer.tab4.summary.title" data-walkme="offer-t4-summaryTitle" />

      <Box mt={3}>
        <Grid container direction="row" justify="space-between" alignItems="flex-start">
          <Grid item xs={12} md={6}>
            <Box display="flex" px={4}>
              <Box flexGrow={1} mb={1}>
                <Typography variant="body1">
                  <Box component="span" color="grey.main" fontSize={13}>
                    <IntlMessageComponent id="component.offer.tab4.summary.debtor.name" data-walkme="offer-t4-debtorName" />
                  </Box>
                </Typography>
              </Box>
              <Box>
                <CompanyAddressComponent company={props.leadOffer.debtorCompany} />
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box display="flex" px={4}>
              <Box flexGrow={1} mb={1}>
                <Typography variant="body1">
                  <Box component="span" color="grey.main" fontSize={13}>
                    <IntlMessageComponent id="component.offer.tab4.summary.dueDate" data-walkme="offer-t4-dueDate" />
                  </Box>
                </Typography>
              </Box>
              <Box>
                <DateFormatterComponent date={props.leadOffer.dueDate} format="DD MMMM YYYY" />
              </Box>
            </Box>

            <Box display="flex" px={4}>
              <Box flexGrow={1} mb={1}>
                <Typography variant="body1">
                  <Box component="span" color="grey.main" fontSize={13}>
                    <IntlMessageComponent id="component.offer.tab4.summary.debtAmount" data-walkme="offer-t4-debtAmount" />
                  </Box>
                </Typography>
              </Box>
              <Box>
                <AmountFormatterComponent amount={props.leadOffer.debtAmount} />
              </Box>
            </Box>

            <Box display="flex" px={4}>
              <Box flexGrow={1} mb={1}>
                <Typography variant="body1">
                  <Box component="span" color="grey.main" fontSize={13}>
                    <IntlMessageComponent id="component.offer.tab4.summary.debtor.grade" data-walkme="offer-t4-debtorGrade" />
                  </Box>
                </Typography>
              </Box>
              <Box>
                <DebtorGradeForSellerComponent
                  insurerRating={props.leadOffer.debtorCompany.insurerRating || 0}
                  widthPercentage={90}
                  shortMessageWhenZero
                />
              </Box>
            </Box>

            {props.leadOffer.debtorCompany.displayWarningClaims &&
              <Box mt={3} px={3} textAlign="center" color="orange.main" fontWeight="fontWeightBold">
                <Typography variant="body1">
                  <IntlMessageComponent id="component.offer.tab4.summary.debtor.warning.claims" />
                </Typography>
              </Box>
            }

            {props.leadOffer.debtorCompany.displayWarningGrade &&
              <Box mt={3} px={3} textAlign="center" color="orange.main" fontWeight="fontWeightBold">
                <Typography variant="body1">
                  <IntlMessageComponent id="component.offer.tab4.summary.debtor.warning.grade" />
                </Typography>
              </Box>
            }
          </Grid>
        </Grid>
      </Box>

      {!props.leadOffer.isValid ? (
        <InvalidLeadOfferDetailsComponent
          isProcessing={props.isProcessing}
          newSimulationWithSameDebtor={props.newSimulationWithSameDebtor}
          newSimulationWithOtherDebtor={props.newSimulationWithOtherDebtor}
        />
      ) : (
        <Box mt={5}>
          <PageSubTitleViewComponent id="component.offer.tab4.prices.title" data-walkme="offer-t4-pricesTitle" />

          <Box mt={3} py={3} bgcolor="lightblue.main">
            <Grid container direction="row" justify="space-between" alignItems="flex-start">
              <Grid item xs={12} md={6}>
                <Box display="flex" px={4}>
                  <Box flexGrow={1} mb={1}>
                    <IntlMessageComponent id="component.offer.tab4.prices.financedAmount" data-walkme="offer-t4-financedAmount" />
                  </Box>
                  <Box>
                    <AmountFormatterComponent amount={props.leadOffer.financedAmount} />
                  </Box>
                </Box>

                <Box display="flex" px={4} color="primary.main" fontSize={16} fontWeight="fontWeightBold">
                  <Box flexGrow={1} mb={1}>
                    <IntlMessageComponent id="component.offer.tab4.prices.transactionPrice" data-walkme="offer-t4-transactionPrice" />
                  </Box>
                  <Box>
                    <AmountFormatterComponent amount={props.leadOffer.transactionPrice} />
                  </Box>
                </Box>

                <Box display="flex" px={4} fontWeight="fontWeightBold">
                  <Box flexGrow={1} mb={1}>
                    <IntlMessageComponent id="component.offer.tab4.prices.totalFee" data-walkme="offer-t4-totalFee" />
                  </Box>
                  <Box>
                    - <AmountFormatterComponent amount={props.leadOffer.totalFee} />
                  </Box>
                </Box>

                <Box display="flex" px={4} color="grey.main" fontSize={13}>
                  <Box flexGrow={1} mb={1} pl={2}>
                    <IntlMessageComponent id="component.offer.tab4.prices.listingFee" data-walkme="offer-t4-listingFee" />
                  </Box>
                  <Box>
                    <AmountFormatterComponent amount={props.leadOffer.listingFee} />
                  </Box>
                </Box>

                <Box display="flex" px={4} color="grey.main" fontSize={13}>
                  <Box flexGrow={1} mb={1} pl={2}>
                    <IntlMessageComponent id="component.offer.tab4.prices.variableFee" data-walkme="offer-t4-variableFee" />
                  </Box>
                  <Box>
                    <AmountFormatterComponent amount={props.leadOffer.variableFee} />
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box pl={3}>
                  <Box pb={1}>
                    <IntlMessageComponent id="component.offer.tab4.getabetterprice.label" />
                  </Box>
                  <SecurityComponent pageName="POS.Offer.Sellers" elementName="btnBestPrice">
                    <ButtonComponent
                      style={{minWidth: '200px'}}
                      color="primary"
                      variant="contained"
                      component={Link}
                      to={PROTECTED_SELLER_BESTPRICING}
                    ><IntlMessageComponent id="component.offer.tab4.button.bestPricing" data-walkme="offer-t4-bestPricing" /></ButtonComponent>
                  </SecurityComponent>

                  <SecurityComponent pageName="POS.Offer.Sellers" elementName="btnSellInvoice" checkRoleExecute>
                    <Box mt={1}>
                      <ButtonComponent
                        style={{minWidth: '200px'}}
                        color="primary"
                        variant="contained"
                        onClick={props.sellInvoice}
                      ><IntlMessageComponent id="component.offer.tab4.button.sellInvoice" data-walkme="offer-t4-sellInvoice" /></ButtonComponent>
                    </Box>
                  </SecurityComponent>
                </Box>
              </Grid>
            </Grid>
          </Box>

          {props.leadOffer.isAwaitingDebtorGrade &&
            <Box mt={3} p={3} bgcolor="lightblue.main" textAlign="center">
              <IntlMessageComponent id="component.offer.tab4.waitingGrade" />
            </Box>
          }

          <Box mt={5}>
            <ButtonComponent
              color="primary"
              variant="outlined"
              isProcessing={props.isProcessing}
              onClick={props.newSimulationWithSameDebtor}
              data-walkme="offer-t4-samedebtor"
            ><IntlMessageComponent id="component.offer.tab4.button.restartSameDebtor" /></ButtonComponent>

            <Box component="span" ml={1}>
              <ButtonComponent
                color="primary"
                variant="outlined"
                isProcessing={props.isProcessing}
                onClick={props.newSimulationWithOtherDebtor}
                data-walkme="offer-t4-differentdebtor"
              ><IntlMessageComponent id="component.offer.tab4.button.restartDifferentDebtor" /></ButtonComponent>
            </Box>
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
}
