/* @flow */

import React from 'react';
import {useParams} from 'react-router-dom';

import ConfirmingInvoiceDetailsComponent from 'app/confirming/invoiceDetails';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function ConfirmingInvoiceDetailsPage() {
  const {id} = useParams();

  return (
    <WebsitePageComponent noPaper noTitle>
      <ConfirmingInvoiceDetailsComponent invoiceId={id} />
    </WebsitePageComponent>
  );
}
