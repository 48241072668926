/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import ButtonComponent from 'components/buttons/button';
import FormErrorsComponent from 'components/formErrors';
import IntlMessageComponent from 'components/formatters/intlMessage';
import SecurityComponent from 'components/security/index';

import CouponFormModel from './model';

type CompanyMembershipCouponFormViewComponentProps = {
  errors: Map<string,?string>;
  form: CouponFormModel;
  formErrors: string[];
  isProcessing: boolean;
  handleChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void;
  submitForm: () => void;
}

export default function CompanyMembershipCouponFormViewComponent(props: CompanyMembershipCouponFormViewComponentProps) {
  return (
    <React.Fragment>
      <SecurityComponent pageName="POS.Companies.Membership" elementName="applyCoupon">
        <Grid container spacing={1} alignItems="stretch">
          <Grid item xs={6}>
            <TextField
              id="form.coupon.reference"
              label={<IntlMessageComponent id="form.coupon.label" />}
              error={!!props.errors.get('reference')}
              helperText={(props.errors.get('reference')) ?
                <IntlMessageComponent id={props.errors.get('reference')} /> : ''}
              value={props.form.reference}
              onChange={props.handleChange('reference')}
              fullWidth
              margin="normal"
              required
            />
          </Grid>
          <Grid item xs={6}>
            <Box height="100%" display="flex" alignItems="center">
              <ButtonComponent
                color="secondary"
                variant="contained"
                isProcessing={props.isProcessing}
                onClick={props.submitForm}
              ><IntlMessageComponent id="general.apply" /></ButtonComponent>
            </Box>
          </Grid>
        </Grid>
      </SecurityComponent>

      <FormErrorsComponent formErrors={props.formErrors} displaySmall />
    </React.Fragment>
  );
}