/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import LoadingComponent from 'components/loading';

import RegisterSellerCompanyTab2ViewComponent from './view';
import SetCompanyInfoForm from './model';

import IsoService from 'services/IsoService';
import {handleApiFormResponse, handleFormChange, handleFormValidation} from 'lib/forms';
import loadConstraints from 'lib/validation/loadConstraints';

type RegisterSellerCompanyTab2ComponentProps = {
  activeCompanyId: number;
  companyId: number;
  workingWithMandates: boolean;
  successHandler: () => void;
};

type RegisterSellerCompanyTab2ComponentState = {
  constraints: any;
  errors: Map<string, ?string>;
  form: SetCompanyInfoForm;
  formErrors: string[];
  isLoading: boolean;
  isProcessing: boolean;
};

class RegisterSellerCompanyTab2Component extends React.Component<RegisterSellerCompanyTab2ComponentProps, RegisterSellerCompanyTab2ComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      constraints: null,
      errors: null,
      form: null,
      formErrors: [],
      isLoading: true,
      isProcessing: false
    };
  }

  componentDidMount = async () => {
    try {
      const constraints = await loadConstraints('Company', ['isin', 'phone', 'activities', 'mainActivityCountryId', 'sourceOfFunds', 'mandateAmount', 'bankCountryId', 'bankIdentifier', 'bankAccount']);
      constraints.mandateAmount.mandateAmountValidator.mandatory = this.props.workingWithMandates;

      this.setState({
        constraints,
        errors: new Map(Object.keys(constraints).map(e => [e, undefined])),
        form: new SetCompanyInfoForm(),
        isLoading: false
      });
    } catch (e) {
      console.error(e);
    }
  };

  handleChange = (fieldName: string) => (event) => handleFormChange.call(this, fieldName, event.target.value);
  handleTypeChange = (fieldName: string) => (id: number) => handleFormChange.call(this, fieldName, id);
  handlePhoneChange = (value: string) => {
    handleFormChange.call(this, 'phone', value);
  }
  validateForm = () => handleFormValidation.call(this);

  submitForm = async () => {
    if (!this.validateForm()) return;
    this.setState({formErrors: [], isProcessing: true});

    try {
      const request = this.state.form.toRequest(this.props.activeCompanyId, this.props.companyId);
      await IsoService.setCompanyInfo(request);
      this.props.successHandler();
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }
  };

  render = () => {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <RegisterSellerCompanyTab2ViewComponent
        errors={this.state.errors}
        form={this.state.form}
        formErrors={this.state.formErrors}
        isProcessing={this.state.isProcessing}
        workingWithMandates={this.props.workingWithMandates}
        handleChange={this.handleChange}
        handleTypeChange={this.handleTypeChange}
        handlePhoneChange={this.handlePhoneChange}
        submitForm={this.submitForm}
      />
    );
  }
}

const mapStateToProps = ({auth}) => ({
  activeCompanyId: auth.user.activeCompany.id,
  workingWithMandates: auth.user.activeCompany.roleISO.workingWithMandates
});

export default connect(mapStateToProps)(RegisterSellerCompanyTab2Component);
