
/* @flow */

export default class CommercialDocumentsActiveFilters {
  name: string;
  language: string;

  constructor() {
    this.name = '';
    this.language = '';
  }
}
