/* @flow */

import React from 'react';

import BackgroundSlider from 'react-background-slider';

export default function BackgroundSliderComponent() {
  const shuffle = (array) => {
    const arr = array;
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [arr[i], arr[j]] = [arr[j], arr[i]];
    }
    return arr;
  }

  const images = shuffle(['./images/login/login-bgr-3.jpg', './images/login/login-bgr-2.jpg', './images/login/login-bgr-1.jpg']);
  return (<BackgroundSlider images={images} duration={8} transition={2} />);
}
