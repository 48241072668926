/* @flow */

export default class SetInvestmentProfileRequest {
  companyId: number;
  amount: number;
  minInvoiceAmount: number;
  maxInvoiceAmount: number;
  pct90: number;
  comment: string;
  marketPlace: string;
  buyerBlocked: boolean;
}
