/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Rating from '@material-ui/lab/Rating';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import IconSquare from 'components/icons/square';
import IntlMessageComponent from 'components/formatters/intlMessage';
import LinkButtonComponent from 'components/buttons/linkButton';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import PercentageFormatterComponent from 'components/formatters/percentage';
import SecurityComponent from 'components/security/index';

import { kpiData } from './data';
import TooltipComponent from 'components/toolTips/index';

type SellerKPIsViewComponentProps = {
  kpis: KPI[];
}

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.green.main
  }
}));

export default function SellerKPIsViewComponent(props: SellerKPIsViewComponentProps) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <PageSubTitleViewComponent id="component.seller.kpis.title" data-walkme="sdashboard-title-reactivite" />

      <Box mt={3}>
        <Paper elevation={0}>
          <Box>
            <Grid container direction="row" justify="flex-start" alignItems="stretch" spacing={1}>
              {props.kpis.map(kpi => {
                const cardInfo = kpiData.find(d => d.name === kpi.name);

                return (
                  <Grid item xs={12} sm={6} md={3} key={kpi.name} pageName="Tooltip.Dashboard.KPI" elementName={cardInfo.translationKey}>

                    <Box p={3}>
                      <Typography variant="body1" gutterBottom>
                        <TooltipComponent pageName="Tooltip.Dashboard.KPI" elementName={cardInfo.translationKey}>
                          <IntlMessageComponent id={cardInfo.translationKey} data-walkme={kpi.walkme} />
                        </TooltipComponent>
                      </Typography>

                      {cardInfo.type === 1 ? (
                        <React.Fragment>
                          <Rating
                            emptyIcon={<IconSquare color="disabled" fontSize="inherit" />}
                            icon={<IconSquare className={classes.icon} fontSize="inherit" />}
                            max={10}
                            readOnly
                            value={Math.floor(kpi.value / 10)}
                          />

                          <Typography variant="body1">
                            <Box component="span" fontSize={30} fontWeight="fontWeightBold" data-walkme={cardInfo.walkme}>
                              <PercentageFormatterComponent value={kpi.value} noFractionDigits />
                            </Box>
                          </Typography>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <Typography variant="body1">
                            <Box component="span" fontSize={30} fontWeight="fontWeightBold" data-walkme={cardInfo.walkme}>{kpi.value}</Box>

                            {/* <SecurityComponent pageName="POS.Sellers.Dashboard" elementName="btnCredit">
                              <Box component="span" ml={3}>
                                <LinkButtonComponent
                                  label={<IntlMessageComponent id={cardInfo.btnTranslationKey} />}
                                  url={cardInfo.btnURL}
                                />
                              </Box>
                            </SecurityComponent> */}
                          </Typography>
                        </React.Fragment>
                      )}
                    </Box>

                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Paper>
      </Box>
    </React.Fragment>
  );
}
