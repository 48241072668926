/* @flow */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function IconLabel(props) {
  return (
    <SvgIcon viewBox="0 0 70.54 70.5" {...props}>
      <path d="M393.55,262c3.85.11,6.93,2,9.77,4.34a7.08,7.08,0,0,1,1.82,1.8c.92,1.71,2.3,1.9,4,1.86,4.19-.1,8.39,0,12.58-.05a3.73,3.73,0,0,1,2.88,1.16q15.39,15.4,30.84,30.77c1.38,1.38,1.38,2.14,0,3.52l-26.13,26.1c-1.34,1.33-2,1.34-3.28,0q-15.49-15.5-31-31a3.74,3.74,0,0,1-1-2.35c-.1-4.11-.07-8.21,0-12.32a2.5,2.5,0,0,0-.9-2.1,22.22,22.22,0,0,1-6.71-10.56c-.89-3.17-.85-6.25,1.47-8.9A6.8,6.8,0,0,1,393.55,262Zm59.07,41.5c-.07-.07-.2-.25-.36-.41q-14.64-14.64-29.3-29.25a2.73,2.73,0,0,0-1.76-.67c-7.58-.05-15.16,0-22.74,0h-1.32v8.34a3,3,0,0,0,0,.67c.08.33.13.8.36.93,1.13.66,2.32,1.23,3.57,1.87.15-.78.27-1.31.36-1.84a7.24,7.24,0,0,1,6.79-5.9,7.17,7.17,0,0,1,3.51,13.55,7,7,0,0,1-8.69-1.68,2.22,2.22,0,0,0-.9-.75c-1.64-.53-3.31-1-5-1.47,0,3.35,0,6.73,0,10.11a2.62,2.62,0,0,0,.9,2.1q14.33,14.28,28.62,28.6c.34.34.71.66.93.87ZM402.48,270a14.43,14.43,0,0,0-8.51-4.88,3.89,3.89,0,0,0-4.64,3.29,12.86,12.86,0,0,0,.26,4,16.67,16.67,0,0,0,4.51,7.83v-8c0-1.79.45-2.24,2.23-2.26C398.26,270,400.2,270,402.48,270Zm3.89,17.92c2.31,1,4.22.5,5.43-1.23a3.55,3.55,0,0,0-.32-4.63A10.21,10.21,0,0,1,406.37,287.89ZM404.53,285a5.24,5.24,0,0,0,4-4.53C405.91,280.38,404.26,282.23,404.53,285Z" transform="translate(-385.97 -262)"/>
    </SvgIcon>
  );
}
