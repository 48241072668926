/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import PopupLoadingViewComponent from 'components/loading/popup';

import DebtorInvoicesToApproveRefusePopupViewComponent from './view';
import RefuseInvoiceForm from './model';

import DebtorService from 'services/DebtorService';
import {handleApiFormResponse, handleFormChange, handleFormValidation} from 'lib/forms';
import loadConstraints from 'lib/validation/loadConstraints';
import NotificationHelper from 'lib/notifications';

type DebtorInvoicesToApproveRefusePopupComponentProps = {
  activeCompanyId: number;
  invoiceId: number;
  handleCancel: () => void;
  handleSuccess: () => void;
}

type DebtorInvoicesToApproveRefusePopupComponentState = {
  constraints: any;
  errors: Map<string, ?string>;
  form: RefuseInvoiceForm;
  formErrors: string[];
  isLoading: boolean;
  isProcessing: boolean;
}

class DebtorInvoicesToApproveRefusePopupComponent extends React.Component<DebtorInvoicesToApproveRefusePopupComponentProps, DebtorInvoicesToApproveRefusePopupComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      constraints: null,
      errors: null,
      form: null,
      formErrors: [],
      isLoading: true,
      isProcessing: false
    };
  }

  componentDidMount = async () => {
    try {
      const constraints = await loadConstraints('debtor', ['reason']);

      this.setState({
        constraints,
        errors: new Map(Object.keys(constraints).map(e => [e, undefined])),
        form: new RefuseInvoiceForm(),
        isLoading: false
      });
    } catch (e) {
      console.error(e);
    }
  };

  handleChange = (fieldName: string) => (event) => handleFormChange.call(this, fieldName, event.target.value);
  validateForm = () => handleFormValidation.call(this);

  submitForm = async () => {
    if (!this.validateForm()) return;
    this.setState({formErrors: [], isProcessing: true});

    try {
      const request = this.state.form.toRequest(this.props.activeCompanyId, this.props.invoiceId);
      await DebtorService.refuseInvoice(request);
      NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS, 'notifications.invoice.refuse.success');
      this.props.handleSuccess();
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }
  };

  render() {
    if (this.state.isLoading) {
      return (<PopupLoadingViewComponent />);
    }

    return (
      <DebtorInvoicesToApproveRefusePopupViewComponent
        errors={this.state.errors}
        form={this.state.form}
        formErrors={this.state.formErrors}
        isProcessing={this.state.isProcessing}
        handleCancel={this.props.handleCancel}
        handleChange={this.handleChange}
        submitForm={this.submitForm}
      />
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps)(DebtorInvoicesToApproveRefusePopupComponent);
