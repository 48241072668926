/* @flow */

import React, { useState } from 'react';

import SubscriptionTab5GCComponent from './generalConditions';
import SubscriptionTab5PersonLegalFormComponent from './form';
import SubscriptionTab5ProcurationComponent from './procuration';

import CompanySubscriptionInfo from 'models/register/companySubscriptionInfo';

type SubscriptionTab5ComponentProps = {
  activeCompanyId: number;
  companySubscriptionInfo: CompanySubscriptionInfo;
  successHandler: () => void;
}

export default function SubscriptionTab5Component(props: SubscriptionTab5ComponentProps) {
  const [isAdministrator, setIsAdministrator] = useState(props.companySubscriptionInfo.isAdministrator);

  if (isAdministrator === undefined || isAdministrator === null) {
    return (<SubscriptionTab5PersonLegalFormComponent successHandler={setIsAdministrator} />);
  }

  if (isAdministrator) {
    return (
      <SubscriptionTab5GCComponent
        generalConditionsSigned={props.companySubscriptionInfo.generalConditionsSigned}
        generalConditionsNotNeeded={props.companySubscriptionInfo.generalConditionsNotNeeded}
        successHandler={props.successHandler}
      />
    );
  }

  return (
    <SubscriptionTab5ProcurationComponent
      successHandler={props.successHandler}
      hasSignedGC={props.companySubscriptionInfo.generalConditionsSigned}
    />
  );
}
