/* @flow */

import React from 'react';
import {useSelector} from 'react-redux';

import Box from '@material-ui/core/Box';

import CompanyItemComponent from 'components/company';

import CompanyLookupBaseComponent from '../base';
import CompanyLookupForm from 'models/companies/companyLookupForm';

import Company from 'models/companies/company';

type CompanyLookupSellerComponentProps = {
  company: Company;
  companyErrorLabelId: string;
  required: boolean;
  companyLookUpForm: CompanyLookupForm;
  handleSelect: (company: Company) => void;
  companyNotFound: () => void;
}

export default function CompanyLookupSellerComponent(props: CompanyLookupSellerComponentProps) {
  const countries = useSelector(state => state.locale.countriesForSellers);

  if (props.company) {
    return (
      <Box px={4} py={3} bgcolor="lightblue.main">
        <CompanyItemComponent
          buttonLabelId="component.offer.tab1.company.change"
          company={props.company}
          selected
          selectHandler={() => props.handleSelect(null)}
        />
      </Box>
    );
  }

  return (
    <CompanyLookupBaseComponent
      company={props.company}
      companyErrorLabelId={props.companyErrorLabelId}
      countries={countries}
      required={props.required}
      handleSelect={props.handleSelect}
      companyNotFound={props.companyNotFound}
      companyLookUpForm={props.companyLookUpForm}
    />
  );
}
