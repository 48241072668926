/* @flow */

export default class UpdateLeadContactRequest {
    companyId: number;
    leadId: number;
    email: string;
    phone: string;
    mobile: string;
  
    constructor(companyId: number, leadId: number, email: string, phoneNumber: string, mobileNumber: string) {
      this.companyId = companyId;
      this.leadId = leadId;
      this.email = email;
      this.phone = phoneNumber;
      this.mobile = mobileNumber;
    }
  }
  