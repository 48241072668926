/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Typography from '@material-ui/core/Typography';

import ComponentWrapperComponent from 'components/websiteStructure/componentWrapper';
import DateFormatterComponent from 'components/formatters/date';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import InvoiceNotes from 'models/invoices/seller/invoiceNotes';

type SellerInvoiceDetailsNotesViewComponentProps = {
  notes: InvoiceNotes;
}

export default function SellerInvoiceDetailsNotesViewComponent(props: SellerInvoiceDetailsNotesViewComponentProps) {

  return (
    <React.Fragment>
      <ComponentWrapperComponent small>
        <PageSubTitleViewComponent id="component.seller.invoiceDetails.fup.actions" />
        
        <Stepper activeStep={props.notes.actions.length} orientation="vertical" nonLinear={false}>
          {props.notes.actions.map((action, index) => (
            <Step key={`action-${index}`}>
              <StepLabel>
                <b><DateFormatterComponent date={action.date} /></b>
                <Typography variant="body1"><div dangerouslySetInnerHTML={{__html: action.text}} /></Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </ComponentWrapperComponent>

      {props.notes.disclaimer &&
        <Box mt={5}>
          <ComponentWrapperComponent small>
            <PageSubTitleViewComponent id="component.seller.invoiceDetails.fup.disclaimer" />
                  
            <Box mt={3}>
              <Typography variant="body1">
                <div dangerouslySetInnerHTML={{__html: props.notes.disclaimer}} />
              </Typography>
            </Box>
          </ComponentWrapperComponent>
        </Box>
      }
    </React.Fragment>
  );
}