/* @flow */

export default class SetCompanyBankAccountRequest {
  companyId: number;
  bankCountryId: number;
  bankIdentifier: string;
  bankAccount: string;
  buyerBlocked: boolean;
  supplierId: number;

  constructor(companyId: number, supplierId: number, bankCountryId: number, bankIdentifier: string, bankAccount: string) {
    this.companyId = companyId;
    this.bankCountryId = bankCountryId;
    this.bankIdentifier = bankIdentifier;
    this.bankAccount = bankAccount;
    this.supplierId = supplierId;
  }
}

