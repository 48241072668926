/* @flow */

export default class SetCompanyInfoRequest {
  isoCompanyId: number;
  companyId: number;

  isin: string;
  phone: string;

  activities: string;
  mainActivityCountryId: number;
  sourceOfFunds: string;

  bankCountryId: number;
  bankIdentifier: string;
  bankAccount: string;

  mandateAmount: number;
}
