/* @flow */

import React from 'react';
import {useParams} from 'react-router-dom';

import CompanyUsersProcurationComponent from 'app/companies/users/procuration';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function CompanyUsersProcurationPage() {
  const { id } = useParams();

  return (
    <WebsitePageComponent id="page.profile.users.title">
      <CompanyUsersProcurationComponent companyUserId={id} />
    </WebsitePageComponent>
  );
}
