/* @flow */

import moment from 'moment';
import DebtorCompany from 'models/companies/debtorCompany';

import InvoiceFixPrices from './invoiceFixPrices';
import InvoiceFlexPrices from './invoiceFlexPrices';
import Company from 'models/companies/company';

export default class InvoiceSummary {
  id: number;

  debtorCompany: DebtorCompany;
  supplierCompany: Company;
  dueDate: moment;

  debtAmount: number;
  
  statusKey: string;

  flexPrices: InvoiceFlexPrices;
  fixFinancedPrices: InvoiceFixPrices;
  fixUnfinancedPrices: InvoiceFixPrices;
}
