/* @flow */

import React from 'react';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core/styles';

import PhoneNumberComponent from 'components/formFields/phoneNumber';
import ButtonComponent from 'components/buttons/button';
import FormErrorsComponent from 'components/formErrors';
import IconCancelOutlined from 'components/icons/outlined/cancel';
import IntlMessageComponent from 'components/formatters/intlMessage';
import LanguageFieldComponent from 'components/formFields/types/language';
import TitleFieldComponent from 'components/formFields/types/title';

import CompanyContactPersonForm from './model';

type ContactPersonFormViewComponentProps = {
  errors: Map<string, ?string>;
  form: CompanyContactPersonForm;
  formErrors: string[];
  isProcessing: boolean;
  cancel: () => void;
  handleChange: () => void;
  handlePhoneChange: (value: string) => void;
  handleTypeChange: (fieldName: string) => (id: number) => void;
  submitForm: () => void;
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.lightgrey.main,
    marginTop: '2em',
    border: `1px solid ${theme.palette.lightgrey.dark}`
  },
  icon: {
    color: theme.palette.grey.main
  }
}));

export default function ContactPersonFormViewComponent(props: ContactPersonFormViewComponentProps) {
  const classes = useStyles();

  return (
    <Card className={classes.root} square>
      <CardHeader
        action={
          <IconButton onClick={props.cancel}>
            <IconCancelOutlined className={classes.icon} />
          </IconButton>
        }
        title={<IntlMessageComponent id="contactperson.form.title" />}
      />

      <CardContent>
        <FormErrorsComponent formErrors={props.formErrors} />

        <TitleFieldComponent
          label={<IntlMessageComponent id="form.title.label" />}
          error={!!props.errors.get('titleId')}
          helperText={(props.errors.get('titleId')) ?
            <IntlMessageComponent id={props.errors.get('titleId')} /> : ''}
          value={props.form.titleId}
          changeHandler={props.handleTypeChange('titleId')}
          fullWidth
          margin="normal"
          required
        />

        <TextField
          id="form.lastName"
          label={<IntlMessageComponent id="page.sellInvoice.tab3.lastName.label" />}
          error={!!props.errors.get('lastName')}
          helperText={(props.errors.get('lastName')) ?
            <IntlMessageComponent id={props.errors.get('lastName')} /> : ''}
          value={props.form.lastName}
          onChange={props.handleChange('lastName')}
          fullWidth
          margin="normal"
          required
        />

        <TextField
          id="form.firstName"
          label={<IntlMessageComponent id="page.sellInvoice.tab3.firstName.label" />}
          error={!!props.errors.get('firstName')}
          helperText={(props.errors.get('firstName')) ?
            <IntlMessageComponent id={props.errors.get('firstName')} /> : ''}
          value={props.form.firstName}
          onChange={props.handleChange('firstName')}
          fullWidth
          margin="normal"
          required
        />

        <TextField
          id="form.email"
          data-walkme="sellinvoice-field-email"
          label={<IntlMessageComponent id="page.sellInvoice.tab3.email.label" />}
          error={!!props.errors.get('email')}
          helperText={(props.errors.get('email')) ?
            <IntlMessageComponent id={props.errors.get('email')} /> : ''}
          value={props.form.email}
          onChange={props.handleChange('email')}
          fullWidth
          margin="normal"
          required
        />

        <PhoneNumberComponent
          id="form.phone"
          data-walkme="sellinvoice-field-telephone"
          label={<IntlMessageComponent id="page.sellInvoice.tab3.phone.label" />}
          error={!!props.errors.get('phone')}
          helperText={(props.errors.get('phone')) ?
            <IntlMessageComponent id={props.errors.get('phone')} /> :
            <IntlMessageComponent id="form.phone.helperText" />
          }
          value={props.form.phone}
          onChange={props.handlePhoneChange}
          fullWidth
          margin="normal"
          required
        />

        <LanguageFieldComponent
          label={<IntlMessageComponent id="form.language.label" />}
          error={!!props.errors.get('languageId')}
          helperText={(props.errors.get('languageId')) ?
            <IntlMessageComponent id={props.errors.get('languageId')} /> : ''}
          value={props.form.languageId}
          changeHandler={props.handleTypeChange('languageId')}
          fullWidth
          margin="normal"
          required
        />

        <TextField
          id="form.functionName"
          data-walkme="sellinvoice-field-fonction"
          label={<IntlMessageComponent id="page.sellInvoice.tab3.function.label" />}
          error={!!props.errors.get('functionName')}
          helperText={(props.errors.get('functionName')) ? 
            <IntlMessageComponent id={props.errors.get('functionName')} /> : ''}
          value={props.form.functionName}
          onChange={props.handleChange('functionName')}
          fullWidth
          margin="normal"
          required
        />
      </CardContent>

      <CardActions>
        <ButtonComponent
          color="primary"
          variant="contained"
          isProcessing={props.isProcessing}
          onClick={props.submitForm}
        ><IntlMessageComponent id="general.submit" /></ButtonComponent>
      </CardActions>
    </Card>
  );
}
