/* @flow */

export default class ChangeSupplierAccountRequest {
    companyId: number;
    sellerIds: number[];
    changeToEdebex: boolean;

    constructor(companyId: number, sellerIds: number[], changeToEdebex: boolean) {
      this.companyId = companyId;
      this.sellerIds = sellerIds;
      this.changeToEdebex = changeToEdebex;
    }
  }