/* @flow */

import React from 'react';
import {Route, Switch, useRouteMatch} from 'react-router-dom';

import Box from '@material-ui/core/Box';

import Error404Component from 'components/errors/error404';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import SubscriptionTab5GCBeginComponent from './tab5begin';
import SubscriptionTab5GCCancelComponent from './tab5cancel';
import SubscriptionTab5GCFailureComponent from './tab5failure';
import SubscriptionTab5GCNotNeddedComponent from './tab5notNeeded';
import SubscriptionTab5GCSignedComponent from './tab5signed';
import SubscriptionTab5GCSuccessComponent from './tab5success';

type SubscriptionTab5GCComponentProps = {
  generalConditionsSigned: boolean;
  generalConditionsNotNeeded: Boolean;
  successHandler: () => void;
}

const SignTosWithHandlerComponent = ({component: Component, successHandler, ...rest}) => (<Route {...rest} render={props => <Component {...props} successHandler={successHandler} />} />);


export default function SubscriptionTab5GCComponent(props: SubscriptionTab5GCComponentProps) {
  const match = useRouteMatch();

  return (
    <React.Fragment>
      <PageSubTitleViewComponent id="page.subscription.tab5.title" />

      <Box mt={3}>
        {props.generalConditionsSigned && 
          <SubscriptionTab5GCSignedComponent successHandler={props.successHandler} />
        }

        {props.generalConditionsNotNeeded && 
          <SubscriptionTab5GCNotNeddedComponent successHandler={props.successHandler} />
        }
       
        {(!props.generalConditionsSigned && !props.generalConditionsNotNeeded) && 
          <Switch>
            <SignTosWithHandlerComponent exact path={`${match.url}/`} component={SubscriptionTab5GCBeginComponent} successHandler={props.successHandler} />
            <Route exact path={`${match.url}/terms-and-conditions/cancel`} component={SubscriptionTab5GCCancelComponent} />
            <Route exact path={`${match.url}/terms-and-conditions/failure`} component={SubscriptionTab5GCFailureComponent} />
            <SignTosWithHandlerComponent exact path={`${match.url}/terms-and-conditions/success`} component={SubscriptionTab5GCSuccessComponent} successHandler={props.successHandler} />
            <Route component={Error404Component} />
          </Switch>
        }
      </Box>
    </React.Fragment>
  );
}
