/* @flow */

import React from 'react';
import moment from 'moment';

type DateFormatterComponentProps = {
  date: moment,
  format: string
}

export default function DateFormatterComponent(props: DateFormatterComponentProps) {
  if (!props.date) return null;
  return <React.Fragment>{moment.utc(props.date).local().format(props.format || 'DD/MM/YYYY')}</React.Fragment>;
}
