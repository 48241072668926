/* @flow */

export default class SendLeadOfferRequest {
  leadOfferId: number;
  sendOnlyToISO: boolean;

  constructor(leadOfferId: number, sendOnlyToISO: boolean) {
    this.leadOfferId = leadOfferId;
    this.sendOnlyToISO = sendOnlyToISO;
  }
}
