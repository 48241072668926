/* @flow */
import React from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

type CheckboxFieldComponentProps = {
  disabled: boolean;
  displayProperties: any;
  error: boolean;
  label: IntlMessageComponent;
  value: ?boolean;
  changeHandler: (value: boolean) => void;
}

export default function CheckboxFieldComponent(props: CheckboxFieldComponentProps) {

  const onChange = (event) => props.changeHandler(event.target.checked);

  return (
    <FormControl
      {...props.displayProperties}
      error={props.error}
      fullWidth
    >
      <FormControlLabel
        control={
          <Checkbox
            onChange={onChange}
            checked={props.value}
            color="primary"
            disabled={props.disabled}
          />
        }
        label={props.label}
      />
    </FormControl>
  );
}
