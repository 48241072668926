/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import IntlMessageComponent from 'components/formatters/intlMessage';

type LoadingDialogComponentProps = {
  id: string;
  closeDialog: () => void;
}

export default function LoadingDialogComponent(props: LoadingDialogComponentProps) {
  return (
    <Dialog open onClose={props.closeDialog}>
      <DialogTitle>
        <IntlMessageComponent id={props.id} />
      </DialogTitle>
      
      <DialogContent>
        <Box align="center" p={5}>
          <Box><CircularProgress size={60} /></Box>
          <strong><IntlMessageComponent id="general.loading" /></strong>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
