/* @flow */

import CompanyMembership from 'models/companies/companyMembership';
import InvoicePaymentResult from 'models/invoices/seller/invoicePaymentResult';

export default class PaymentResult {
  isComplete: boolean;
  membership: CompanyMembership;
  invoices: InvoicePaymentResult[];
}
