/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import {history} from 'store/index';
import {PROTECTED_APP} from 'constants/pageRoutes';

export default function RegisterSellerCompanyTab5Component() {
  const onButtonClick = () => history.push(PROTECTED_APP);

  return (
    <React.Fragment>
      <PageSubTitleViewComponent id="page.iso.company.register.tab4.h2" />

      <Typography variant="body1" gutterBottom>
        <IntlMessageComponent id="page.iso.company.register.tab4.introduction" />
      </Typography>

      <Box mt={5}>
        <ButtonComponent
          color="primary"
          variant="contained"
          onClick={onButtonClick}
        ><IntlMessageComponent id="page.iso.company.register.tab4.button" /></ButtonComponent>
      </Box>
    </React.Fragment>
  );
}
