/* @flow */

import React from 'react';

import {withStyles} from '@material-ui/core/styles';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import IntlMessageComponent from 'components/formatters/intlMessage';

type DebtorGradeForAverageOverdueDaysComponentProps = {
  averageOverdueDays: number,
  shortMessageWhenZero: boolean,
  widthPercentage: number
}

class DebtorGradeForAverageOverdueDaysComponent extends React.PureComponent<DebtorGradeForAverageOverdueDaysComponentProps> {

  static defaultProps = {
    widthPercentage: 100
  };

  _gradeToPixel = (grade: number, width: number, shift: number) => (((grade - 1) * (10 / 9)) * (width / 10)) + shift;

  gradeToPixel = (averageOverdueDays: number, width: number, shift: number) => {
    let arbitraryGrade = 0;

    if (averageOverdueDays < 1) {
      arbitraryGrade = 11;
    } else if (averageOverdueDays>=1 && averageOverdueDays <=5){
      arbitraryGrade= 6;
    } else if (averageOverdueDays >5) {
      arbitraryGrade = 1.3;
    }

    return this._gradeToPixel(arbitraryGrade, width, shift);
  };

  render() {
    return (
      <div className={this.props.classes.meter} style={{width: `${247 * (this.props.widthPercentage / 100)}px`}}>
        <div
          className={this.props.classes.needle}
          style={
            {left: `${this.gradeToPixel(this.props.averageOverdueDays, 204 * (this.props.widthPercentage / 100), -5)}px`}
          }>
          <ArrowDropUpIcon />
        </div>
      </div>
    );
  }
}

const styles = _ => ({
  meter: {
    marginTop: '12px',
    height: '24px',
    position: 'relative',
    paddingBottom: '14px',

    backgroundRepeat: 'repeat-y',
    backgroundImage: 'url("./images/grades/rating-gradient.png")',
    backgroundPositionX: 'center',
    backgroundSize: 'contain',
    backgroundClip: 'content-box'
  },
  needle: {
    position: 'absolute',
    bottom: 0
  }
});

export default withStyles(styles)(DebtorGradeForAverageOverdueDaysComponent);
