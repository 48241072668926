/* @flow */

import React from 'react';

import CompanyBankAccountsFormDialogStep2ViewComponent from './view';

import Document from 'models/documents/v2/document';
import DocumentType from 'models/documents/documentType';

type CompanyBankAccountsFormDialogStep2ComponentProps = {
  formErrors: string[];
  documentType: DocumentType;
  onClose: () => void;
  onSuccess: (document: Document) => void;
}

type CompanyBankAccountsFormDialogStep2ComponentState = {
  document: Document;
}

class CompanyBankAccountsFormDialogStep2Component extends React.Component<CompanyBankAccountsFormDialogStep2ComponentProps, CompanyBankAccountsFormDialogStep2ComponentState> {

  constructor(props) {
    super(props);

    const document = new Document();
    document.typeId = this.props.documentType.typeId;
    document.code = this.props.documentType.code;
    document.isRequired = true;

    this.state = {
      document
    };
  }

  handleDocumentSelect = (file: File) => {
    const document = this.state.document;
    document.file = file;
    document.filename = file.name;
    document.isInvalid = false;
    document.isMissing = false;
    this.setState({document});
  };

  handleDocumentSelectFailure = (file: File) => {
    const document = this.state.document;
    document.file = null;
    document.filename = file.name;
    document.isInvalid = true;
    document.isMissing = false;
    this.setState({document});
  }

  handleDocumentRemove = () => {
    const document = this.state.document;
    document.file = null;
    document.filename = '';
    document.isInvalid = false;
    document.isMissing = false;
    this.setState({document});
  };

  onSubmit = async () => {
    const document = this.state.document;
    if (document.file === null || document.isMissing || document.isInvalid) {
      document.isMissing = true;
      this.setState({document});
    } else {
      this.props.onSuccess(document);
    }    
  };

  render() {
    return (
      <CompanyBankAccountsFormDialogStep2ViewComponent
        document={this.state.document}
        formErrors={this.props.formErrors}
        handleDocumentSelect={this.handleDocumentSelect}
        handleDocumentSelectFailure={this.handleDocumentSelectFailure}
        handleDocumentRemove={this.handleDocumentRemove}
        onClose={this.props.onClose}
        onSubmit={this.onSubmit}
      />
    );
  }
}

export default CompanyBankAccountsFormDialogStep2Component;
