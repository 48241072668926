/* @flow */

import React from 'react';
import {FormattedNumber} from 'react-intl';
import {IntlProvider} from 'react-intl-redux';

type AmountSmallUnitFormatterComponentProps = {
  amount: number,
  maximumFractionDigits: number,
  minimumFractionDigits: number,
  isCurrency: boolean
}

export default function AmountSmallUnitFormatterComponent(props: AmountSmallUnitFormatterComponentProps) {
  const {amount, currency, maximumFractionDigits, minimumFractionDigits, isCurrency, ...rest} = props;

  let valueCurrency = '';
  if ( isCurrency !== false ) {
    valueCurrency = '€';
  }

  return (
    <IntlProvider>
      <span>
        <FormattedNumber
          minimumFractionDigits={minimumFractionDigits || 0}
          maximumFractionDigits={maximumFractionDigits || 0}
          value={amount || 0}
          {...rest}
        />
        
        <sup style={{fontSize: '.5em'}}>{valueCurrency}</sup>
      </span>
    </IntlProvider>
  );
}
