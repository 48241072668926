/* @flow */

import moment from 'moment';
import DebtorCompany from 'models/companies/debtorCompany';
import InvoiceFixPrices from './invoiceFixPrices';
import InvoiceFlexPrices from './invoiceFlexPrices';

export default class Invoice {
  id: number;
  invoiceName: number;
  debtorCompany: DebtorCompany;
  reference: string;
  debtAmount: number;
  issueDate: moment;
  dueDate: moment;
  overdueDays: number;
  invoiceRating: number;
  statusKey: string; 
  
  flexPrices: InvoiceFlexPrices;
  fixFinancedPrices: InvoiceFixPrices;
  fixUnfinancedPrices: InvoiceFixPrices;
}
