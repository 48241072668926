/* @flow */

import React from 'react';
import { connect } from 'react-redux';

import QuestionAnswerOutlined from '@material-ui/icons/QuestionAnswerOutlined';

import CarouselComponent from 'components/caroussel';
import IconHourglassOutlined from 'components/icons/outlined/hourglass';
import IconHourglass from 'components/icons/hourglass';
import IconMinus15Outlined from 'components/icons/outlined/minus15';
import IconPlus15Outlined from 'components/icons/outlined/plus15';
import IconThumbsUpOutlined from 'components/icons/outlined/thumbsUp';
import LoadingComponent from 'components/loading';
import IconInvoiceDue from 'components/icons/invoiceDue';
import IconInvoiceNotDue from 'components/icons/invoiceNotDue';

import ProfileSummaryCard from 'models/dashboards/profileSummaryCard';

import DebtorService from 'services/DebtorService';

import {
  CARD_TYPE_DEBTOR_AWAITING_VALIDATION,
  CARD_TYPE_DEBTOR_OVERDUE,
  CARD_TYPE_DEBTOR_AMOUNT_DUE,
  CARD_TYPE_DEBTOR_DUE_SOON,
  CARD_TYPE_DEBTOR_DUE_LONG
} from 'constants/cardTypes';


type DebtorProfileSummaryCarouselComponentProps = {
  activeCompanyId: number;
}

type DebtorProfileSummaryCarouselComponentState = {
  cards: ProfileSummaryCard[];
  isLoading: boolean;
}

class DebtorProfileSummaryCarouselComponent extends React.Component<DebtorProfileSummaryCarouselComponentProps, DebtorProfileSummaryCarouselComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      cards: null,
      isLoading: true
    };
  }

  componentDidMount = async () => {
    try {
      const cards = await DebtorService.getProfileSummary(this.props.activeCompanyId);
      this.setState({ cards, isLoading: false });
    } catch (e) {
      console.error(e);
    }
  }

  customize = (cardType: number) => {
    switch (cardType) {
      case CARD_TYPE_DEBTOR_AWAITING_VALIDATION:
        return { color: 'blue.main', icon: <IconThumbsUpOutlined style={{ fontSize: '3em' }} />, translationId: 'component.debtor.profile.cards.awaitingvalidation', walkme: 'sdashboard-box-awaitingvalidation' };
      case CARD_TYPE_DEBTOR_OVERDUE:
        return { color: 'red.main', icon: <IconInvoiceDue style={{ fontSize: '3em' }} />, translationId: 'component.debtor.profile.cards.overdue', walkme: 'sdashboard-box-overdue' };
      case CARD_TYPE_DEBTOR_AMOUNT_DUE:
        return { color: 'orange.main', icon: <IconInvoiceNotDue style={{ fontSize: '3em' }} />, translationId: 'component.debtor.profile.cards.amountdue', walkme: 'sdashboard-box-amountdue' };
      case CARD_TYPE_DEBTOR_DUE_LONG:
        return { color: 'grey.main', icon: <IconHourglass style={{ fontSize: '3em' }} />, translationId: 'component.debtor.profile.cards.duelong', walkme: 'sdashboard-box-duelong' };
      case CARD_TYPE_DEBTOR_DUE_SOON:
        return { color: 'grey.main', icon: <IconHourglassOutlined style={{ fontSize: '3em' }} />, translationId: 'component.debtor.profile.cards.duesoon', walkme: 'sdashboard-box-duesoon' };
      default:
        return { color: 'grey.main', icon: <QuestionAnswerOutlined style={{ fontSize: '3em' }} />, translationId: 'component.debtor.profile.cards.unknown', walkme: 'sdashboard-box-unknown' };
    }
  }

  render() {
    if (this.state.isLoading) return <LoadingComponent />;

    return (
      <CarouselComponent
        cards={this.state.cards}
        customize={this.customize}
        walkme="sdashboard-image-votresituation"
      />
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  activeCompanyId: auth.user.activeCompany.id
});

export default connect(mapStateToProps)(DebtorProfileSummaryCarouselComponent);
