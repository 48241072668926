/* @flow */

export default class Alert {
  id: number;
  title: string;
  message: string;
  pages: string;
  ctaText: string;
  ctaLink: String;
}
