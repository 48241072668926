/* @flow */

import React from 'react';
import {Route, Switch, useRouteMatch} from 'react-router-dom';

import Error404Page from 'components/errors/error404';

import OgonePaymentConfirmationPage from './ogone';

export default function PaymentConfirmationPage() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.url}/ogone`} component={OgonePaymentConfirmationPage} />
      <Route component={Error404Page} />
    </Switch>
  );
}