/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Typography from '@material-ui/core/Typography';

import ComponentWrapperComponent from 'components/websiteStructure/componentWrapper';
import DateFormatterComponent from 'components/formatters/date';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import InvoiceNotes from 'models/buyers/invoices/invoiceNotes';

type BuyerInvoiceDetailsNotesViewComponentProps = {
  notes: InvoiceNotes;
}

export default function BuyerInvoiceDetailsNotesViewComponent(props: BuyerInvoiceDetailsNotesViewComponentProps) {
  if (!props.notes || (props.notes.actions.length === 0 && !props.notes.outlook && !props.notes.disclaimer)) return null;

  return (
    <React.Fragment>
      <Grid container spacing={4} alignItems="stretch">

        {props.notes.actions.length > 0 &&
          <Grid item xs={12} md={6}>
            <ComponentWrapperComponent small>
              <PageSubTitleViewComponent id="buyer.invoiceDetails.fup.actions" />

              <Stepper activeStep={props.notes.actions.length} orientation="vertical" nonLinear={false}>
                {props.notes.actions.map((action, index) => (
                  <Step key={`action-${index}`}>
                    <StepLabel>
                      <b><DateFormatterComponent date={action.date} /></b>
                      <Typography variant="body1"><div dangerouslySetInnerHTML={{__html: action.text}} /></Typography>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </ComponentWrapperComponent>
          </Grid>
        }

        {props.notes.outlook &&
          <Grid item xs={12} md={6}>
            <ComponentWrapperComponent small>
              <PageSubTitleViewComponent id="buyer.invoiceDetails.fup.outlook" />

              <Box mt={3}>
                <Typography variant="body1">
                  <div dangerouslySetInnerHTML={{__html: props.notes.outlook.text}} />
                </Typography>
              </Box>
            </ComponentWrapperComponent>
          </Grid>
        }
      </Grid>

      {props.notes.disclaimer &&
        <Box mt={5}>
          <ComponentWrapperComponent small>
            <PageSubTitleViewComponent id="buyer.invoiceDetails.fup.disclaimer" />
            
            <Box mt={3}>
              <Typography variant="body1">
                <div dangerouslySetInnerHTML={{__html: props.notes.disclaimer}} />
              </Typography>
            </Box>
          </ComponentWrapperComponent>
        </Box>
      }
    </React.Fragment>
  );
}
