/* @flow */

import React from 'react';
import { connect } from 'react-redux';

import LoadingComponent from 'components/loading';
import ChangePasswordViewComponent from './view';

import ChangePasswordForm from './model';

import UserService from 'services/UserService';
import { handleApiFormResponse, handleFormValidation } from 'lib/forms';
import loadConstraints from 'lib/validation/loadConstraints';
import { validateField, validateForm } from 'lib/validation/validator';
import NotificationHelper from 'lib/notifications';
import {history} from 'store/index';

import { PROTECTED_APP } from 'constants/pageRoutes';

type ChangePasswordComponentProps = {
}

type ChangePasswordComponentState = {
  constraints: any;
  errors: Map<string,?string>;
  form: ChangePasswordForm;
  formErrors: string[];
  isLoading: boolean;
  isProcessing: boolean;
  twoFactorEnabled: boolean;
  qrCodeUrl: string;
  wrongTwoPin: boolean;
}

class ChangePasswordComponent extends React.Component<ChangePasswordComponentProps, ChangePasswordComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      constraints: null,
      errors: null,
      form: new ChangePasswordForm(),
      formErrors: [],
      isLoading: true,
      isProcessing: false,
      twoFactorEnabled: false,
      wrongTwoPin: false
    };
  }

  componentDidMount = async () => {
    try {
      const constraints = await loadConstraints('Password', ['currentPassword', 'password', 'confirmPassword']);
      const [qrCodeUrl, isValidated] = await UserService.getQRCode();
      this.setState({
        constraints,
        errors: new Map(Object.keys(constraints).map(e => [e, undefined])),
        isLoading: false,
        twoFactorEnabled: qrCodeUrl !== null || isValidated,
        qrCodeUrl: qrCodeUrl
      });
    } catch (e) {
      console.error(e);
    }
  };

  handleCurrentPasswordChange = (value: string) => {
    const validationResult = validateField(value, this.state.constraints.currentPassword);
    const errors = this.state.errors;
    errors.set('currentPassword', validationResult ? validationResult[0] : null);

    const form = this.state.form;
    form.currentPassword = value;
    this.setState({ form, errors });
  };

  handlePasswordChange = (value: string) => {
    const validationResult = validateField(value, this.state.constraints.password);
    const errors = this.state.errors;
    errors.set('password', validationResult ? validationResult[0] : null);

    if (this.state.form.confirmPassword.length > 2) {
      const validationResults = validateForm({ currentPassword: this.state.form.currentPassword, password: value, confirmPassword: this.state.form.confirmPassword }, this.state.constraints);
      let validationResult2 = validationResults && validationResults.confirmPassword ? validationResults.confirmPassword[0] : null;
      if (validationResult2 !== null && validationResult2.includes(' ')) {
        const split = validationResult2.split(' ');
        validationResult2 = split.pop();
      }
      errors.set('confirmPassword', validationResult2);
    }

    const form = this.state.form;
    form.password = value;
    this.setState({ form, errors });
  };

  handleConfirmPasswordChange = (value: string) => {
    const validationResults = validateForm({ currentPassword: this.state.form.currentPassword, password: this.state.form.password, confirmPassword: value }, this.state.constraints);
    let validationResult = validationResults && validationResults.confirmPassword ? validationResults.confirmPassword[0] : null;
    if (validationResult !== null && validationResult.includes(' ')) {
      const split = validationResult.split(' ');
      validationResult = split.pop();
    }

    const errors = this.state.errors;
    errors.set('confirmPassword', validationResult);

    const form = this.state.form;
    form.confirmPassword = value;
    this.setState({ form, errors });
  };

  handleTwoFactorPinChange = async (value: string) => {
    const form = this.state.form;
    form.twoFactorPin = value;
    this.setState({ form });
  }

  handleTwoFactorChange = async (value: boolean) => {
    let qrCodeUrl = null;
    let isValidated = false;
    if (value) {
      await UserService.generateSecretKey();
      [qrCodeUrl, isValidated] = await UserService.getQRCode();
    }
    else {
      await UserService.disableTwoFactorAuthentication();
    }
    this.setState({ twoFactorEnabled: qrCodeUrl !== null, qrCodeUrl: qrCodeUrl });
  }

  validateForm = () => handleFormValidation.call(this);

  validateTwoFactor = async () => {
    try {
      await UserService.validateTwoFactor(this.state.form.twoFactorPin);
      const [qrCodeUrl, isValidated] = await UserService.getQRCode();
      this.setState({qrCodeUrl, wrongTwoPin: false});
      NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS, 'notifications.2fa.success');
    } catch (e) {
      this.setState({wrongTwoPin: true});
    }
  }

  submitForm = async () => {
    if (!this.validateForm()) return;
    this.setState({ formErrors: [], isProcessing: true });

    try {
      const request = this.state.form.toRequest();
      await UserService.changePassword(request);
      this.setState({ isProcessing: false });
      NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS, 'notifications.password.succes');
      history.push(`${PROTECTED_APP}`);
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }
  };

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <ChangePasswordViewComponent
        errors={this.state.errors}
        form={this.state.form}
        formErrors={this.state.formErrors}
        isProcessing={this.state.isProcessing}
        handleCurrentPasswordChange={this.handleCurrentPasswordChange}
        handlePasswordChange={this.handlePasswordChange}
        handleConfirmPasswordChange={this.handleConfirmPasswordChange}
        handleTwoFactorChange={this.handleTwoFactorChange}
        handleTwoFactorPinChange={this.handleTwoFactorPinChange}
        validateTwoFactor={this.validateTwoFactor}
        submitForm={this.submitForm}
        twoFactorEnabled={this.state.twoFactorEnabled}
        qrCodeUrl={this.state.qrCodeUrl}
        wrongTwoPin={this.state.wrongTwoPin}
      />
    );
  }
}

const mapStateToProps = state => ({
  twoFactorEnabled: state.auth.user.twoFactorEnabled
});

export default connect(mapStateToProps)(ChangePasswordComponent);
