/* @flow */

import {applyMiddleware, compose, createStore} from 'redux';
import createSagaMiddleware from 'redux-saga';

import { createHashHistory } from 'history';

import reducers from './reducers/index';
import rootSaga from './sagas/index';

const history = createHashHistory();
const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];
// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


export default function configureStore(initialState) {
  const store = createStore(reducers, initialState,
    composeEnhancers(applyMiddleware(...middlewares)));

  sagaMiddleware.run(rootSaga);

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers/index', () => {
      const nextRootReducer = require('./reducers/index');
      store.replaceReducer(nextRootReducer);
    });
  }
  return store;
}

const initialStoreState = {
  intl: {
    defaultLocale: 'fr-FR',
    locale: 'fr-FR',
    messages: {
    }
  }
};


const store = configureStore(initialStoreState);

export {history, store};
