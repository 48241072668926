/* @flow */

import React from 'react';
import ReactDOMServer from 'react-dom/server';

import Box from '@material-ui/core/Box';
import {useTheme} from '@material-ui/core/styles';

import {Area, AreaChart, XAxis, YAxis, ResponsiveContainer, Tooltip} from 'recharts';

import DateFormatterComponent from 'components/formatters/date';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import BuyerPerformanceChartTooltipComponent from './tooltip';

type BuyerPerformanceChartComponentProps = {
  chartData: any;
  chartWM: string;
  chartUnitX: string;
  chartUnitY: string;
  title: string;
  titleWM: string;  
}

export default function BuyerPerformanceChartComponent(props: BuyerPerformanceChartComponentProps) {
  const theme = useTheme();

  const axisStyles = {fontSize: '.8em', fill: theme.palette.grey.main};

  const formatXAxis = (date: moment) => ReactDOMServer.renderToString(<DateFormatterComponent date={date} format="MMM YYYY" />);
  const formatYAxis = (value: number) => parseInt(value).toLocaleString();

  return (
    <React.Fragment>
      <PageSubTitleViewComponent id={props.title} data-walkme={props.titleWM} />
        
      <Box mt={3} data-walkme={props.chartWM}>
        <ResponsiveContainer width="100%" height={340}>

          <AreaChart data={props.chartData} margin={{left:35}}>
            <XAxis
              dataKey="date"
              tick={axisStyles}
              tickFormatter={formatXAxis}
              tickMargin={15}
              unit={props.chartUnitX}
            />

            <YAxis 
              tick={axisStyles}
              tickFormatter={formatYAxis}
              tickMargin={3} 
              unit={props.chartUnitY}
            />

            <Tooltip content={<BuyerPerformanceChartTooltipComponent />} />

            <defs>
              <linearGradient id="colorvalue" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={theme.palette.blue.main} stopOpacity={0.9} />
                <stop offset="95%" stopColor={theme.palette.blue.main} stopOpacity={0.3} />
              </linearGradient>
            </defs>

            <Area
              dataKey="value"
              dot={{ stroke: theme.palette.blue.contrastText, strokeWidth: 1 }}
              fill="url(#colorvalue)"
              fillOpacity={1}
              key="area-value"
              stroke={theme.palette.blue.dark}
              type="monotone"
            />
          </AreaChart>
        </ResponsiveContainer>
      </Box>
    </React.Fragment>
  );
}
