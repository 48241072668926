/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';


import CountryFieldSellersComponent from 'components/formFields/countries/sellers';
import IntlMessageComponent from 'components/formatters/intlMessage';
import MandatoryFormFieldsComponent from 'components/form/mandatory';
import ButtonComponent from 'components/buttons/button';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import FormErrorsComponent from 'components/formErrors';

import SupplierCompany from 'models/confirming/supplierCompany';
import SupplierBankAccounts from 'components/formFields/supplierBankAccounts/index';

type ConfirmingAddSupplierTab2ViewComponentProps = {
  supplierCompanyDetails: SupplierCompany;
  form: any;
  isProcessing: boolean;
  errors: Map<string,?string>;
  displayNew: boolean;
  selectedBankAccountId: number;
  formErrors: string[];
  handleChange: () => void;
  handleTypeChange: () => void;
  updateBankAccount: () => void;
  handleChangeBankAccount: () => void;
  handleDisplayNewChange: () => void;
}

export default function ConfirmingAddSupplierTab2ViewComponent(props: ConfirmingAddSupplierTab2ViewComponentProps) {

  return (
    <React.Fragment>
      <FormErrorsComponent formErrors={props.formErrors} />
      <PageSubTitleViewComponent id="debtor.addSuppiler.tab2.title" />
      {!props.displayNew &&
        <Grid container spacing={6} alignItems="stretch">
          <Grid item xs={12} md={6}>
            <Box mt={3}>
              <Box height={100} >
                <SupplierBankAccounts
                  id="form.bankAccountId"
                  label={<IntlMessageComponent id="form.supplierBankAccounts.label" />}
                  error={!!props.errors.get('bankAccountId')}
                  value={props.selectedBankAccountId}
                  changeHandler={props.handleChangeBankAccount}
                  fullWidth
                  margin="normal"
                  required
                  supplierId={props.supplierCompanyDetails.supplierId}
                />
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box mt={2} px={4} display="flex" alignItems="center" justifyContent="center" height="100%">
              <ButtonComponent
                color="primary"
                variant="contained"
                isProcessing={props.isProcessing}
                onClick={props.handleDisplayNewChange}
              ><IntlMessageComponent id="suppliers.newbankaccount" /></ButtonComponent>
            </Box>
          </Grid>
        </Grid>}
      {props.displayNew &&
        <Grid container spacing={6} alignItems="stretch">
          <Grid item xs={9} md={9}>
            <Box mt={3}>
              <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                <Grid item xs={12} md={6}>
                  <Box pr={4}>
                    <CountryFieldSellersComponent
                      id="form.bankCountry"
                      label={<IntlMessageComponent id="form.bankCountry.label" />}
                      error={!!props.errors.get('bankCountryId')}
                      helperText={props.errors.get('bankCountryId') ?
                        <IntlMessageComponent id={props.errors.get('bankCountryId')} /> : ''}
                      value={props.form.bankCountryId}
                      changeHandler={props.handleTypeChange('bankCountryId')}
                      fullWidth
                      margin="normal"
                      required
                    />
                  </Box>
                </Grid>
              </Grid>

              <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                <Grid item xs={12} md={6}>
                  <Box pr={4}>
                    <TextField
                      id="form.bic"
                      data-walkme="subscription-field-bic"
                      label={<IntlMessageComponent id="form.bankIdentifier.label" />}
                      error={!!props.errors.get('bankIdentifier')}
                      helperText={(props.errors.get('bankIdentifier')) ?
                        <IntlMessageComponent id={props.errors.get('bankIdentifier')} /> : ''}
                      value={props.form.bankIdentifier}
                      onChange={props.handleChange('bankIdentifier')}
                      fullWidth
                      margin="normal"
                      required
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box pl={4}>
                    <TextField
                      id="form.iban"
                      data-walkme="subscription-field-iban"
                      label={<IntlMessageComponent id="form.bankAccount.label" />}
                      error={!!props.errors.get('bankAccount')}
                      helperText={(props.errors.get('bankAccount')) ?
                        <IntlMessageComponent id={props.errors.get('bankAccount')} /> : ''}
                      value={props.form.bankAccount}
                      onChange={props.handleChange('bankAccount')}
                      fullWidth
                      margin="normal"
                      required
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={3} md={3}>
            <Box mt={4} display="flex" alignItems="center" height="100%">
              <ButtonComponent
                color="primary"
                variant="contained"
                isProcessing={props.isProcessing}
                onClick={props.handleDisplayNewChange}
              ><IntlMessageComponent id="suppliers.selectexisting" /></ButtonComponent>
            </Box>
          </Grid>
        </Grid>
      }

      <Box mt={5}>
        <ButtonComponent
          color="primary"
          variant="contained"
          isProcessing={props.isProcessing}
          onClick={props.updateBankAccount}
        ><IntlMessageComponent id="general.continue" /></ButtonComponent>

        <MandatoryFormFieldsComponent />
      </Box>
    </React.Fragment >
  );
}
