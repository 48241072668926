/* @flow */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function IconEdit(props) {
  return (
    <SvgIcon viewBox="0 0 53.98 54.01" {...props}>
      <g>
        <path d="M400.86,325.58a23.1,23.1,0,0,1-3-1.18,7,7,0,0,1-3.75-6.45c0-12.27,0-24.55,0-36.82a7.3,7.3,0,0,1,7-7.36c7.21-.12,14.43,0,21.78,0v3h-1.23c-6.44,0-12.89.08-19.33,0a4.91,4.91,0,0,0-5.21,5.22c.13,11.77.11,23.54,0,35.31a4.93,4.93,0,0,0,5.28,5.29q17.55-.09,35.08,0a5,5,0,0,0,5.36-5.35c-.14-6.25-.05-12.5-.05-18.76,0-1.8,0-1.8,1.83-1.79.34,0,.69,0,1.19,0v1.31c0,6.45-.09,12.9,0,19.34a7.87,7.87,0,0,1-6.39,8.12c-.13,0-.25.15-.37.22Z" transform="translate(-394.12 -271.57)" />
        <path d="M448.11,277.63c-.22,2.58-1.87,4.26-3.59,6-6.06,6-12.06,12.06-18.12,18a5.12,5.12,0,0,1-2.14,1.21c-2.36.68-4.75,1.23-7.13,1.83-1.94.49-2.8-.39-2.32-2.34.61-2.46,1.19-4.92,1.87-7.36a4.17,4.17,0,0,1,1-1.76c6.6-6.61,13.19-13.22,19.84-19.77A6,6,0,0,1,447.76,276c.14.47.24.95.35,1.43Zm-11.53,1.1a2.5,2.5,0,0,0-.36.27q-8.07,8-16.14,16.11a1.78,1.78,0,0,0-.49.74c-.48,1.76-.91,3.53-1.42,5.5,1.75-.45,3.31-.81,4.84-1.26a3.68,3.68,0,0,0,1.56-.86q7.77-7.69,15.48-15.46c.26-.26.5-.53.73-.77Zm6.49,2c1.28-1.07,2.33-2.23,1.77-4.05a2.77,2.77,0,0,0-2.29-2c-1.7-.29-2.83.57-3.75,1.89Z" transform="translate(-394.12 -271.57)" />
      </g>
    </SvgIcon>
  );
}
