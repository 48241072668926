/* @flow */

import React from 'react';
import * as reactGA from 'lib/analytics';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Error from '@material-ui/icons/Error';
import Typography from '@material-ui/core/Typography';

import IntlMessageComponent from 'components/formatters/intlMessage';

import SignUpTab4TokenExpiredComponent from './tokenExpired';

import ConfirmEmailRequest from 'models/requests/register/confirmEmailRequest';

import RegisterService from 'services/registerService';
import {getValidationError} from 'lib/forms';

import {history} from 'store/index';
import {SIGN_IN} from 'constants/pageRoutes';

type SignUpTab4ComponentProps = {
  email: string;
  token: string;
  successHandler: (string) => void;
}

type SignUpTab4ComponentState = {
  isLoading: boolean;
  tokenExpired: boolean;
}

class SignUpTab4Component extends React.Component<SignUpTab4ComponentProps, SignUpTab4ComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      tokenExpired: false
    };
  }

  componentDidMount = async () => {
    reactGA.initializeGA();
    reactGA.logPageView('/signup/email');

    try {
      const request = new ConfirmEmailRequest(this.props.email, this.props.token);
      const token = await RegisterService.confirmEmail(request);
      reactGA.logEvent('SignUp', 'Email Confirmed');
      if (token === '') {
        history.push(SIGN_IN);
      } else {
        this.props.successHandler(token);
      }
    } catch (e) {
      const errorCode = getValidationError(e);
      
      if (errorCode === 'EDEBEX_API_TOKEN_EXPIRED') {
        this.setState({isLoading: false, tokenExpired: true});
      } else {
        reactGA.logEvent('SignUp', 'Email Confirmation Failed');
        this.setState({isLoading: false});
      }
    }
  }
 
  render() {
    if (this.state.isLoading) {
      return (
        <Box textAlign="center">
          <CircularProgress size={60} />
            
          <Box mt={1}>
            <Typography variant="body1">
              <IntlMessageComponent id="page.signup.tab4.emailcheck" />
            </Typography>
          </Box>
        </Box>
      );
    }
    else if (this.state.tokenExpired) {
      return (
        <SignUpTab4TokenExpiredComponent email={this.props.email} />
      );
    }
    else {
      return (
        <Box textAlign="center">
          <Error color="error" fontSize="large" />
          <Box mt={1}>
            <Typography variant="body1">
              <IntlMessageComponent id="page.signup.tab4.emailcheck.failed" />
            </Typography>
          </Box>
        </Box>
      );
    }
  }
}

export default SignUpTab4Component;
