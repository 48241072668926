/* @flow */

import {
  PROTECTED_PROFILE,
  PROTECTED_PROFILE_CHANGEPASSWORD,
  PROTECTED_PROFILE_NOTIFICATIONS
} from 'constants/pageRoutes';

import {ROLE_BUYER, ROLE_ISO, ROLE_SELLER, ROLE_CONFIRMING} from 'constants/companyRoles';

export const userMenuDropdownData = [
  {
    id: 1,
    link: PROTECTED_PROFILE,
    translationKey: 'header.user.infos'
  },
  {
    id: 2,
    link: PROTECTED_PROFILE_NOTIFICATIONS,
    translationKey: 'header.user.notifications',
    visibleBy: [ROLE_BUYER, ROLE_ISO, ROLE_SELLER, ROLE_CONFIRMING]
  },
  {
    id: 3,
    link: PROTECTED_PROFILE_CHANGEPASSWORD,
    translationKey: 'header.user.changepassword'
  }
];
