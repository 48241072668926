/* @flow */

import React from 'react';
import { connect } from 'react-redux';


import LoadingComponent from 'components/loading';
import PageTitleViewComponent from 'components/pageTitle/index';

import ConfirmingSuppliersListComponent from './list';
import ConfirmingSuppliersDetailsComponent from './details'

import customSort from 'lib/sortHelpers';

import SupplierCompany from 'models/confirming/supplierCompany';
import ConfirmingService from 'services/ConfirmingService';

type ConfirmingSuppliersComponentProps = {
  activeCompanyId: number;
}

type ConfirmingSuppliersComponentState = {
  displayForm: boolean;
  editMode: boolean;
  formErrors: string[];
  isLoading: boolean;
  isProcessing: boolean;
  supplierCompany: SupplierCompany;
  supplierCompanies: SupplierCompany[];
}

class ConfirmingSuppliersComponent extends React.Component<ConfirmingSuppliersComponentProps, ConfirmingSuppliersComponentState> {

  constructor(props) {
    super(props);
    this.state = {
      displayForm: false,
      editMode: true,
      formErrors: [],
      isLoading: true,
      isProcessing: false,
      supplierCompany: null,
      supplierCompanies: null,
      page: 0,
      pageSize: 10
    };
  }

  componentDidMount = async () => {
    try {
      // Load ubos and make them into an array of UBOForm
      const supplierCompanies = await ConfirmingService.getConfirmingSuppliers(this.props.activeCompanyId);
      this.setState({ isLoading: false, supplierCompanies: supplierCompanies });
    } catch (e) {
      console.error(e);
    }
  };

  toggleEditMode = () => {
    this.setState({ editMode: true });
  }

  editSupplier = (id: number) => {
    const supplier = this.state.supplierCompanies.find(s => s.supplierId === id);
    if (!supplier) return;
    this.setState({ displayForm: true, formErrors: [], supplierCompany: supplier });
  }

  handleFormCancel = () => {
    this.setState({ displayForm: false, formErrors: [], supplierCompany: null });
  }

  handleFormSuccess = async () => {
    try {
      this.setState({ displayForm: false, formErrors: [], isLoading: true, supplierCompany: null });

      // Load ubos and make them into an array of UBOForm
      const supplierCompanies = await ConfirmingService.getConfirmingSuppliers(this.props.activeCompanyId);

      this.setState({ isLoading: false, supplierCompanies: supplierCompanies });
    } catch (e) {
      console.error(e);
    }
  }

  handlePageChange = (page: number) => this.setState({ page });
  handlePageSizeChange = (pageSize: number) => this.setState({ pageSize });

  handleSortOrderChange = (orderBy: string) => {
    let order = this.state.orderBy === orderBy && this.state.order === 'desc' ? 'asc' : 'desc';

    let supplierCompanies = null;
    // order
    supplierCompanies = order === 'desc'
      ? this.state.supplierCompanies.sort((a, b) => customSort(b[orderBy], a[orderBy]))
      : this.state.supplierCompanies.sort((a, b) => customSort(a[orderBy], b[orderBy]));

    this.setState({ supplierCompanies, order, orderBy });
  };

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <React.Fragment>
        <PageTitleViewComponent id="confirming.suppliers.title" backButton={this.state.displayForm} backButtonAction={this.handleFormCancel} />
        {this.state.displayForm ? (
          <ConfirmingSuppliersDetailsComponent
            activeCompanyId={this.props.activeCompanyId}
            supplierId={this.state.supplierCompany.supplierId}
            onSuccess={this.handleFormSuccess}
            onCancel={this.handleFormCancel}
          />
        ) : (
          <React.Fragment>
            <ConfirmingSuppliersListComponent
              editMode={this.state.editMode}
              formErrors={this.state.formErrors}
              isProcessing={this.state.isProcessing}
              supplierCompanies={this.state.supplierCompanies}
              editSupplier={this.editSupplier}
              toggleEditMode={this.toggleEditMode}
              order={this.state.order}
              orderBy={this.state.orderBy}
              page={this.state.page}
              pageSize={this.state.pageSize}
              handlePageChange={this.handlePageChange}
              handlePageSizeChange={this.handlePageSizeChange}
              handleSortOrderChange={this.handleSortOrderChange}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps)(ConfirmingSuppliersComponent);
