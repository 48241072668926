/* @flow */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import {grey} from '@material-ui/core/colors';

export default function IconMoneyAvailable(props) {
  return (
    <SvgIcon viewBox="0 0 79 67.36" {...props}>
      <circle cx="53.91" cy="42.26" r="23.34" fill="none" stroke={props.color ? props.color : grey[500]} strokeLinecap="round" strokeLinejoin="round" strokeWidth="3.5"/>
      <path d="M428.59,302.13c1.94-.4,3.51-.8,5.1-1.05,1.23-.19,2.66-.39,3,1.36s-.88,2.11-2.22,2.4c-2.1.45-4.19,1-6.29,1.47l-.1.79a5.76,5.76,0,0,0,1.84.4,34.82,34.82,0,0,0,4.76-1.05c1.33-.39,2.37,0,2.43,1.26a3.09,3.09,0,0,1-1.48,2.35,20.36,20.36,0,0,1-4.27,1.05c2.14,2.81,7,2.75,9.71-.15a9,9,0,0,0,1.34-1.89c.64-1.23,1.46-2.19,2.91-1.44,1.65.84,1.05,2.15.41,3.38a11.22,11.22,0,0,1-16.21,4.1,7.65,7.65,0,0,1-1.82-1.45,2.46,2.46,0,0,0-3.18-1c-1.14.42-2.7.46-3.1-1.14s.86-2.28,2.28-2.57a9.85,9.85,0,0,0,1.07-.46c-.57-.48-1-1.12-1.37-1.11-1.31,0-2.63-.18-2.46-1.65.1-.84,1.37-1.53,2.08-2.31a6.75,6.75,0,0,0,1.58-1.92c2.64-7.26,10.7-10.23,17.08-6.21.19.13.41.22.59.37,1,.8,2.12,1.68,1.22,3.08s-2.21.9-3.44.15C435.49,296.13,431.5,297.13,428.59,302.13Z" transform="translate(-380.86 -263.75)"/>
      <ellipse cx="25.2" cy="9.57" rx="23.45" ry="7.82" fill="none" stroke={props.color ? props.color : grey[500]} strokeLinecap="round" strokeLinejoin="round" strokeWidth="3.5"/>
      <path d="M382.67,284.88a3.55,3.55,0,0,0-.06.56c0,4.32,9.76,7.82,21.79,7.82" transform="translate(-380.86 -263.75)" fill="none" stroke={props.color ? props.color : grey[500]} strokeLinecap="round" strokeLinejoin="round" strokeWidth="3.5"/>
      <path d="M382.67,297.17a3.43,3.43,0,0,0-.06.55c0,4.32,9.76,7.82,21.79,7.82" transform="translate(-380.86 -263.75)" fill="none" stroke={props.color ? props.color : grey[500]} strokeLinecap="round" strokeLinejoin="round" strokeWidth="3.5"/>
      <path d="M382.67,308.89a3.55,3.55,0,0,0-.06.56c0,4.32,9.76,7.82,21.79,7.82" transform="translate(-380.86 -263.75)" fill="none" stroke={props.color ? props.color : grey[500]} strokeLinecap="round" strokeLinejoin="round" strokeWidth="3.5"/>
    </SvgIcon>
  );
}
