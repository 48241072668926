/* @flow */

import React from 'react';
import {IntlProvider} from 'react-intl-redux';

import InputAmountBaseComponent from './inputAmountBase';

export default function InputAmountComponent(props) {
  return (
    <IntlProvider>
      <InputAmountBaseComponent {...props} />
    </IntlProvider>
  );
}