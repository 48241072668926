/* @flow */

export default class ChangeLeadIsoPersonRequest {
    companyId: number;
    leadId: number;
    personId: number;
  
    constructor(companyId: number, leadId: number, personId: number) {
      this.companyId = companyId;
      this.leadId = leadId;
      this.personId = personId;
    }
  }
  