/* @flow */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import Download from '@material-ui/icons/CloudDownload';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';
import StyledTableCellTextComponent from 'components/table/cellText';
import StyledTableHeadComponent from 'components/table/head';
import StyledTableRowComponent from 'components/table/row';

import IsoDocumentItem from 'models/documents/isoCommercialDocument';

type CommercialDocumentsDisplayComponentProps = {
  document: IsoDocumentItem;
  activeCompanyId: number;
  activeCompanyRole: number;
  path: String;
  downloadDocument: (culture: string, name: string, path: string) => void;
}

export default function CommercialDocumentsDisplayComponent(props: CommercialDocumentsDisplayComponentProps) {
  const [showDocumentsVersion, setShowDocumentVersion] = useState(false);
  const toggleShowDocumentVersion = () => setShowDocumentVersion(!showDocumentsVersion);

  const locale = useSelector(state => state.locale.locale);

  const image = props.document.thumbnails.find(x => x.title.includes(locale));

  const name = decodeURI(props.document.name);

  return (
    <Box display="flex" component="div" mt={1} overflow="auto">
      <Paper elevation={0} style={{ width: "600px", padding: "1.5rem" }} xs={6} md={6} >
        <Grid container direction="row" justify="space-around" alignItems="center"  >
          <Grid item xs={12} md={6}>
            <Grid>
              {image !== undefined ? (
                <img src={`data:image/jpeg;base64,${image.image}`} alt={props.document.thumbnail} style={{ height: 120 }} />
              ) : (
                <img src="./images/commercialDocuments.png" style={{ height: 120 }} />
              )}
            </Grid>
          </Grid>

          <Grid direction="column" item xs={12} md={6} >
            <Grid>
              <Box>
                <Typography variant="body1" gutterBottom>
                  <b><IntlMessageComponent id={name} /></b>
                </Typography>
              </Box>
            </Grid>

            <Grid>
              <ButtonComponent
                color={showDocumentsVersion ? "default" : "primary"}
                variant="contained"
                startIcon={showDocumentsVersion ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                onClick={toggleShowDocumentVersion}
                data-walkme="commercialDocuments-button-chooseVersion"
              >
                <IntlMessageComponent id="iso.commercialDocuments.btn.chooseVersion" />
              </ButtonComponent>

            </Grid>
          </Grid>
        </Grid>
        {showDocumentsVersion &&
          <Table>
            <StyledTableHeadComponent data-walkme="isocommercial-table-headline">
              <StyledTableRowComponent>
                <StyledTableCellTextComponent key={`$title-${props.document.id}`} padding="none">
                  <IntlMessageComponent id="component.iso.documentsDisplay.header.title" />
                </StyledTableCellTextComponent>
                <StyledTableCellTextComponent key={`download-${props.document.id}`} padding="none">
                </StyledTableCellTextComponent>
              </StyledTableRowComponent>
            </StyledTableHeadComponent>

            <TableBody bgcolor="white">
              {props.document.cultures.map(culture => (
                <StyledTableRowComponent>
                  <StyledTableCellTextComponent padding="none">
                    <Tooltip title={<IntlMessageComponent id="component.iso.commercial.table.button.download.tooltip" />}>
                      <strong><IntlMessageComponent id={`component.iso.commercial.version.${culture}`} /></strong>
                    </Tooltip>
                  </StyledTableCellTextComponent>

                  <StyledTableCellTextComponent padding="none">
                    <Tooltip title={<IntlMessageComponent id="component.iso.commercial.table.button.download.tooltip" />}>
                      <IconButton onClick={() => props.downloadDocument(culture, name, props.path)}>
                        <Download />
                      </IconButton>
                    </Tooltip>
                  </StyledTableCellTextComponent>
                </StyledTableRowComponent>
              ))}
            </TableBody>
          </Table>
        }
      </Paper>
    </Box>
  );
}
