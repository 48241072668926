/* @flow */

import React from 'react';
import {FormattedNumber} from 'react-intl';
import {IntlProvider} from 'react-intl-redux';

type PercentageFormatterComponentProps = {
  noFractionDigits: boolean;
  maximumFractionDigits: number;
  minimumFractionDigits: number;
  value: number;
}

export default function PercentageFormatterComponent(props: PercentageFormatterComponentProps) {
  const {noFractionDigits, maximumFractionDigits, minimumFractionDigits, value, ...rest} = props;

  return (
    <IntlProvider>
      <span>
        <FormattedNumber
          // eslint-disable-next-line react/style-prop-object
          style="decimal"
          maximumFractionDigits={noFractionDigits ? 0 : (maximumFractionDigits || 2)}
          minimumFractionDigits={noFractionDigits ? 0 : (minimumFractionDigits || 2)}
          value={value || 0}
          {...rest}
        />&nbsp;%
      </span>
    </IntlProvider>
  );
}
