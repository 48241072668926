/* @flow */

import moment from 'moment';
import Person from 'models/users/person';

import SetPersonInfoRequest from 'models/requests/register/setPersonInfoRequest';

export default class IsoNoCompanyForm {
  titleId: number;

  lastName: string;
  firstName: string;

  email: string;
  mobile: string;
  mobilePresence: string;
  phone: string;

  languageId: number;

  addressLine1: string;
  addressLine2: string;
  zipCode: string;
  city: string;
  countryId: number;

  nationalityId: number;
  birthCountryId: number;
  birthDate: moment;
  birthPlace: string;

  registrationTypeId: number;
  registrationNumber: string;
  registrationExpireDate: moment;

  constructor() {
    this.lastName = null;
    this.firstName = null;

    this.email = null;
    this.mobile = null;
    this.mobilePresence = '';
    this.phone = null;

    this.titleId = 0;
    this.languageId = 0;
    this.countryId = 0;
  }

  toRequest() {
    const request = new SetPersonInfoRequest();
    request.companyId = 2;
    request.titleId = this.titleId;
    request.lastName = this.lastName;
    request.firstName = this.firstName;
    request.mobile = this.mobilePresence.length > 1 ? this.mobilePresence : this.mobile;
    request.phone = this.mobilePresence.length > 1 ? this.mobilePresence : this.phone;
    request.addressLine1 = this.addressLine1;
    request.addressLine2 = this.addressLine2;
    request.zipCode = this.zipCode;
    request.city = this.city;
    request.email = this.email;
    request.languageId = this.languageId;
    request.countryId = this.countryId;
    request.nationalityId = this.nationalityId;
    request.birthCountryId = this.birthCountryId;
    request.birthDate = moment(this.birthDate).format('YYYY/MM/DD');
    request.birthPlace = this.birthPlace;
    request.registrationTypeId = this.registrationTypeId;
    request.registrationNumber = this.registrationNumber;
    request.registrationExpireDate = moment(this.registrationExpireDate).format('YYYY/MM/DD');
    return request;
  }
}
