/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import SecurityComponent from 'components/security/index';

import ButtonComponent from 'components/buttons/button';
import ComponentWrapperComponent from 'components/websiteStructure/componentWrapper';
import InputAmountComponent from 'components/formatters/inputAmount';
import IntlMessageComponent from 'components/formatters/intlMessage';
import MarketPlacePickerComponent from 'components/formFields/marketPlaces';

import InvestmentProfileValues from 'models/buyers/investmentProfiles/investmentProfileValues';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

type BuyerInvestmentProfileCurrentViewComponentProps = {
  isActive: boolean;
  profile: InvestmentProfileValues;
  onDisableButtonClick: () => void;
}

export default function BuyerInvestmentProfileCurrentViewComponent(props: BuyerInvestmentProfileCurrentViewComponentProps) {
  return (
    <ComponentWrapperComponent small>
      <Box display="flex" flexWrap="wrap" height="100%">
        <Box alignContent="flex-start">
          <PageSubTitleViewComponent id={`buyer.ip.current${props.isActive ? '' : '.disabled'}`} />

          <Box mt={3} px={4}>
            <TextField
              id="ip.name"
              label={<IntlMessageComponent id="form.ip.name" />}
              value={props.profile.name}
              fullWidth
              margin="normal"
              readOnly
              InputProps={{
                readOnly: true,
              }}
            />

            <Box mt={5.4} height={100}>
              <TextField
                id="ip.bankAccount"
                label={<IntlMessageComponent id="form.ip.bankAccount" />}
                value={props.profile.bankAccount.bankIBAN}
                disabled={!props.isActive}
                fullWidth
                margin="normal"
                InputProps={{
                  readOnly: true,
                }}
                readOnly
              />
            </Box>

            <TextField
              id="ip.amount"
              label={<IntlMessageComponent id="form.ip.amount" />}
              value={props.profile.amount}
              InputProps={{
                inputComponent: InputAmountComponent,
                readOnly: true,
                startAdornment: <InputAdornment position="start">€</InputAdornment>
              }}
              disabled={!props.isActive}
              fullWidth
              margin="normal"
              readOnly
            />

            <TextField
              id="ip.minInvoiceAmount"
              label={<IntlMessageComponent id="form.ip.minInvoiceAmount" />}
              value={props.profile.minInvoiceAmount}
              InputProps={{
                inputComponent: InputAmountComponent,
                readOnly: true,
                startAdornment: <InputAdornment position="start">€</InputAdornment>
              }}
              disabled={!props.isActive}
              fullWidth
              margin="normal"
              readOnly
            />

            <TextField
              id="ip.maxInvoiceAmount"
              label={<IntlMessageComponent id="form.ip.maxInvoiceAmount" />}
              value={props.profile.maxInvoiceAmount}
              InputProps={{
                inputComponent: InputAmountComponent,
                readOnly: true,
                startAdornment: <InputAdornment position="start">€</InputAdornment>
              }}
              disabled={!props.isActive}
              fullWidth
              margin="normal"
            />

            <TextField
              id="ip.pct90"
              label={<IntlMessageComponent id="form.ip.pct90" />}
              value={props.profile.pct90}
              InputProps={{
                readOnly: true,
                startAdornment: <InputAdornment position="start">%</InputAdornment>
              }}
              disabled={!props.isActive}
              fullWidth
              margin="normal"
              readOnly
            />

            <Box mt={3}>
              <MarketPlacePickerComponent
                value={props.profile.marketPlace}
                disabled={!props.isActive}
                fullWidth
                margin="normal"
                readOnly
              />
            </Box>
          </Box>
        </Box>

        {props.isActive &&
          <SecurityComponent pageName="POS.Buyers.InvestmentProfile" elementName="btnDisable">
            <Box alignContent="flex-end" mt={10}>
              <PageSubTitleViewComponent id="buyer.ip.disable" />

              <Box mt={3}>
                <ButtonComponent
                  color="tertiary"
                  variant="contained"
                  onClick={props.onDisableButtonClick}
                ><IntlMessageComponent id="general.btn.disable" /></ButtonComponent>

              </Box>
            </Box>
          </SecurityComponent>
        }
      </Box>
    </ComponentWrapperComponent>
  );
}
