/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

type ComponentWrapperComponentProps = {
  small: Boolean;
}

export default function ComponentWrapperComponent(props: ComponentWrapperComponentProps) {
  return (
    <Paper elevation={0} style={{height: '100%'}}>
      <Box p={props.small ? 4 : 5} height="100%">
        {props.children}
      </Box>
    </Paper>
  );
}
