/* @flow */

import { plainToClass } from 'class-transformer';

import BaseService from './api/baseService';

import {
  SIGNUP_USER,
  SIGNUP_CONFIRM_EMAIL,
  SIGNUP_CHANGE_EMAIL,
  SIGNUP_SEND_VERIFICATION_EMAIL,
  SIGNUP_SET_PASSWORD,

  SIGNUP_GET_COMPANY_SUBSCRIPTION_INFO,
  SIGNUP_GET_COMPANY_INFO,
  SIGNUP_SET_COMPANY_INFO,
  SIGNUP_SET_COMPANY_INFO_FOR_ISO,
  SIGNUP_SET_COMPANY_BANK_ACCOUNT,
  SIGNUP_GET_PERSON_INFO,
  SIGNUP_SET_PERSON_INFO,
  SIGNUP_GET_DOCUMENTS,
  SIGNUP_GET_INVESTMENT_PROFILE,
  SIGNUP_SET_INVESTMENT_PROFILE,
  SIGNUP_REQUEST_PRESENTATION,
  SIGNUP_SET_PERSON_LEGAL_INFO,
  SIGNUP_SIGN_TOS_BEGIN_POST,
  SIGNUP_SIGN_TOS_STATUS_GET,
  SIGNUP_PROCURATION_POST,
  SIGNUP_FINALIZE,
  SIGNUP_ISO_NO_COMPANY,
  SIGNUP_INCOMPLETE_ALERT
} from 'constants/apiRoutes';

import ChangeEmailRequest from 'models/requests/register/changeEmailRequest';
import ConfirmEmailRequest from 'models/requests/register/confirmEmailRequest';
import FinalizeSubscriptionRequest from 'models/requests/register/finalizeSubscriptionRequest';
import PresentationRequest from 'models/requests/register/presentationRequest';
import RegisterCompanyRequest from 'models/requests/register/registerCompanyRequest';
import SendProcurationEmailRequest from 'models/requests/register/sendProcurationEmailRequest';
import SendVerificationEmailRequest from 'models/requests/register/sendVerificationEmailRequest';
import SetCompanyBankAccountRequest from 'models/requests/register/setCompanyBankAccountRequest';
import SetCompanyInfoRequest from 'models/requests/register/setCompanyInfoRequest';
import SetInvestmentProfileRequest from 'models/requests/register/setInvestmentProfileRequest';
import SetPasswordRequest from 'models/requests/register/setPasswordRequest';
import SetPersonInfoRequest from 'models/requests/register/setPersonInfoRequest';
import SetPersonLegalInfoRequest from 'models/requests/register/setPersonLegalInfoRequest';
import StartSignTosTransactionRequest from 'models/requests/register/startSignTosTransactionRequest';
import IncompleteAlertRequest from 'models/requests/register/incompleteAlertRequest';

import RegisterCompanyResponse from 'models/responses/register/registerCompanyResponse';

import BankAccount from 'models/companies/bankAccount';
import Company from 'models/companies/company';
import CompanyInfo from 'models/register/companyInfo';
import CompanySubscriptionInfo from 'models/register/companySubscriptionInfo';
import Document from 'models/documents/v2/document';
import DocumentType from 'models/documents/documentType';
import InvestmentProfileValues from 'models/buyers/investmentProfiles/investmentProfileValues';
import Person from 'models/users/person';
import UniversignTransaction from 'models/register/universignTransaction';
import ProvidedDocument from 'models/documents/providedDocument';

export default class RegisterService {

  static registerCompany = (request: RegisterCompanyRequest) =>
    BaseService.anonymousPOST(SIGNUP_USER, request, data => plainToClass(RegisterCompanyResponse, data));

  static confirmEmail = (request: ConfirmEmailRequest) =>
    BaseService.anonymousPOST(SIGNUP_CONFIRM_EMAIL, request, null);

  static setPassword = (request: SetPasswordRequest) =>
    BaseService.anonymousPOST(SIGNUP_SET_PASSWORD, request, null);

  static sendVerificationEmail = (request: SendVerificationEmailRequest) =>
    BaseService.anonymousPOST(SIGNUP_SEND_VERIFICATION_EMAIL, request, data => data);

  static changeEmail = (request: ChangeEmailRequest) =>
    BaseService.anonymousPOST(SIGNUP_CHANGE_EMAIL, request, null);


  // Subscribe
  static getCompanySubscriptionInfo = (companyId: number, roleId: number) =>
    BaseService.simpleGET(`${SIGNUP_GET_COMPANY_SUBSCRIPTION_INFO}/${companyId}/${roleId}`,
      data => {
        const companyInfo = plainToClass(CompanySubscriptionInfo, data);
        companyInfo.company = plainToClass(Company, data.company);
        return companyInfo;
      });

  // Subscribe company tab
  static getCompanyInfo = (companyId: number) =>
    BaseService.simpleGET(`${SIGNUP_GET_COMPANY_INFO}/${companyId}`,
      data => {
        const companyInfo = plainToClass(CompanyInfo, data);
        companyInfo.bankAccount = plainToClass(BankAccount, data.bankAccount);
        return companyInfo;
      });

  static setCompanyInfo = (request: SetCompanyInfoRequest) =>
    BaseService.simplePOST(SIGNUP_SET_COMPANY_INFO, request, null);

  static setCompanyInfoForISO = (request: SetCompanyInfoRequest) =>
    BaseService.simplePOST(SIGNUP_SET_COMPANY_INFO_FOR_ISO, request, null);

  static setCompanyBankAccount = (request: SetCompanyBankAccountRequest) =>
    BaseService.simplePOST(SIGNUP_SET_COMPANY_BANK_ACCOUNT, request, null);

  static isoNoCompany = (request: SetPersonInfoRequest) =>
    BaseService.simplePOST(SIGNUP_ISO_NO_COMPANY, request, null);

  static incompleteAlert = (request: IncompleteAlertRequest) =>
    BaseService.simplePOST(SIGNUP_INCOMPLETE_ALERT, request, null);

  // Subscribe person tab
  static getPersonInfo = (companyId: number) =>
    BaseService.simpleGET(`${SIGNUP_GET_PERSON_INFO}/${companyId}`, data => plainToClass(Person, data));

  static setPersonInfo = (request: SetPersonInfoRequest) =>
    BaseService.simplePOST(SIGNUP_SET_PERSON_INFO, request, null);


  // Subscribe document tab
  // static getRequiredDocuments = (companyId: number) =>
  //   BaseService.simpleGET(`${SIGNUP_GET_DOCUMENTS}/${companyId}`, data => data.map(d => plainToClass(Document, d)));

  static getRequiredDocuments = (companyId: number) =>
    BaseService.simpleGET(`${SIGNUP_GET_DOCUMENTS}/${companyId}`,
      data => {
        const toClassifyDocumentType = plainToClass(Document, data.toClassifyDocumentType);

        const extraDocuments = data.extraDocuments.map(d =>
          plainToClass(Document, d)
        );

        const documents = data.requiredDocuments.map(d =>
          plainToClass(Document, d)
        );

        const additionalSubscriptionDocuments = data.additionalSubscriptionDocuments.map(d =>
          plainToClass(Document, d)
        );

        // providedDocuments -> map to required or add it
        // data.providedDocuments.forEach((d) => {
        //   const providedDocument = plainToClass(ProvidedDocument, d);

        //   const docFound = documents.find(doc => doc.type.typeId === providedDocument.typeId);
        //   if (docFound !== undefined) {
        //     docFound.id = providedDocument.id;
        //     docFound.filename = providedDocument.filename;
        //   } else {
        //     const docType = new DocumentType(providedDocument.typeId, providedDocument.code);
        //     const document = new Document(docType, false);
        //     document.id = providedDocument.id;
        //     document.filename = providedDocument.filename;
        //     documents.push(document);
        //   }
        // });
        return { documents, extraDocuments, toClassifyDocumentType, additionalSubscriptionDocuments };
      });

  // Subscribe buyer investment profile tab
  static getInvestmentProfile = (companyId: number) =>
    BaseService.simpleGET(`${SIGNUP_GET_INVESTMENT_PROFILE}/${companyId}`, data => plainToClass(InvestmentProfileValues, data));

  static setInvestmentProfile = (request: SetInvestmentProfileRequest) =>
    BaseService.simplePOST(SIGNUP_SET_INVESTMENT_PROFILE, request, null);


  // Legal
  static setPersonLegalInfo = (request: SetPersonLegalInfoRequest) =>
    BaseService.simplePOST(SIGNUP_SET_PERSON_LEGAL_INFO, request, null);

  // Subscribe general conditions tab
  static startSignTosTransaction = (request: StartSignTosTransactionRequest) =>
    BaseService.simplePOST(SIGNUP_SIGN_TOS_BEGIN_POST, request, data => plainToClass(UniversignTransaction, data));

  static getTosTransactionStatus = (companyId: number, role: number) =>
    BaseService.simpleGET(`${SIGNUP_SIGN_TOS_STATUS_GET}/${companyId}/${role}`,
      data => plainToClass(UniversignTransaction, data));

  // Subscribe procuration
  static sendProcurationEmail = (request: SendProcurationEmailRequest) =>
    BaseService.simplePOST(SIGNUP_PROCURATION_POST, request, null);


  // Finalize subscription tab
  static requestPresentation = (request: PresentationRequest) =>
    BaseService.simplePOST(SIGNUP_REQUEST_PRESENTATION, request, null);

  static finalizeSubscription = (request: FinalizeSubscriptionRequest) =>
    BaseService.simplePOST(SIGNUP_FINALIZE, request, null);
}
