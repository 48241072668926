/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import IntlMessageComponent from 'components/formatters/intlMessage';
import DateFormatterComponent from 'components/formatters/date';

type MGMReferralCardComponentProps = {
  color1: string;
  nbCoupons: string;
  referral: Referral;
}

export default function MGMReferralCardComponent(props: MGMReferralCardComponentProps) {
  return (
    <Box textAlign="center">
      <Box mb={2}>
        <Typography variant="body1" noWrap>{props.referral.firstName}</Typography>
        <Typography variant="body1" noWrap>{props.referral.lastName}</Typography>
        <Typography variant="body1" noWrap>
          <Box component="span" fontWeight="fontWeightBold">
            {props.referral.companyName}
          </Box>
        </Typography>
      </Box>

      <Box mb={2}>
        <Typography variant="body1">
          <Box component="span" color="text.disabled">
            <IntlMessageComponent id="seller.mgm.coupons" />
          </Box>
        </Typography>

        <Typography variant="body1">
          <Box component="span" color={props.color1} fontSize="2.5rem" fontWeight="fontWeightBold" lineHeight={1}>
            {props.nbCoupons}
          </Box>
        </Typography>
      </Box>

      <Typography variant="body1">
        <Box component="span" color="text.disabled">
          <IntlMessageComponent id="seller.mgm.expirationDate" />
        </Box>
      </Typography>

      <Typography variant="body1" gutterBottom>
        <DateFormatterComponent date={props.referral.expirationDate} />
      </Typography>
    </Box>
  );
}
