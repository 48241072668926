/* @flow */


import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import ButtonComponent from 'components/buttons/button';
import FinancingNeedComponent from 'components/formFields/types/financingNeed';
import FinancingNeedFrequencyComponent from 'components/formFields/types/financingNeedFrequency';
import FormErrorsComponent from 'components/formErrors';
import IntlMessageComponent from 'components/formatters/intlMessage';
import InputAmountComponent from 'components/formatters/inputAmount';
import InvoicesYearlyAmountComponent from 'components/formFields/types/invoicesYearlyAmount';
import MandatoryFormFieldsComponent from 'components/form/mandatory';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import BusinessOpportunityForm from './model';

type RegisterLeadTab2ViewComponentProps = {
  errors: Map<string, ?string>;
  form: BusinessOpportunityForm;
  formErrors: string[];
  isProcessing: boolean;
  handleChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void;
  handleTypeIdChange: (fieldName: string) => (id: number) => void;
  skip: () => void;
  submitForm: () => void;
}

export default function RegisterLeadTab2ViewComponent(props: RegisterLeadTab2ViewComponentProps) {

  return (
    <React.Fragment>
      <FormErrorsComponent formErrors={props.formErrors} />

      <Typography variant="body1" gutterBottom>
        <IntlMessageComponent id="page.iso.registerLead.tab2.notmandatory" data-walkme="createlead2-label-notmandatory" />
      </Typography>

      <Box mt={5}>
        <PageSubTitleViewComponent
          id="page.iso.registerLead.tab2.title"
          data-walkme="createlead2-title-registerlead"
        />
      </Box>

      <Box mt={3}>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.expectedMonthlyVolume"
                data-walkme="createlead2-field-monthlyvolume"
                label={<IntlMessageComponent id="form.expectedMonthlyVolume.label" />}
                error={!!props.errors.get('expectedMonthlyVolume')}
                helperText={(props.errors.get('expectedMonthlyVolume')) ?
                  <IntlMessageComponent id={props.errors.get('expectedMonthlyVolume')} /> : ''}
                value={props.form.expectedMonthlyVolume}
                onChange={props.handleChange('expectedMonthlyVolume')}
                InputProps={{
                  inputComponent: InputAmountComponent,
                  startAdornment: <InputAdornment position="start">€</InputAdornment>
                }}
                fullWidth
                margin="normal"
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.invoicesAvgAmount"
                data-walkme="createlead2-field-avginvoiceamount"
                label={<IntlMessageComponent id="form.invoicesAvgAmount.label" />}
                error={!!props.errors.get('invoicesAvgAmount')}
                helperText={(props.errors.get('invoicesAvgAmount')) ?
                  <IntlMessageComponent id={props.errors.get('invoicesAvgAmount')} /> : ''}
                value={props.form.invoicesAvgAmount}
                onChange={props.handleChange('invoicesAvgAmount')}
                InputProps={{
                  inputComponent: InputAmountComponent,
                  startAdornment: <InputAdornment position="start">€</InputAdornment>
                }}
                fullWidth
                margin="normal"
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.invoicesNbrMonthly"
                data-walkme="createlead2-field-monthlyinvoices"
                label={<IntlMessageComponent id="form.invoicesNbrMonthly.label" />}
                error={!!props.errors.get('invoicesNbrMonthly')}
                helperText={(props.errors.get('invoicesNbrMonthly')) ?
                  <IntlMessageComponent id={props.errors.get('invoicesNbrMonthly')} /> : ''}
                value={props.form.invoicesNbrMonthly}
                onChange={props.handleChange('invoicesNbrMonthly')}
                fullWidth
                margin="normal"
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.nbrDebtors"
                data-walkme="createlead2-field-debtornumber"
                label={<IntlMessageComponent id="form.nbrDebtors.label" />}
                error={!!props.errors.get('nbrDebtors')}
                helperText={(props.errors.get('nbrDebtors')) ?
                  <IntlMessageComponent id={props.errors.get('nbrDebtors')} /> : ''}
                value={props.form.nbrDebtors}
                onChange={props.handleChange('nbrDebtors')}
                fullWidth
                margin="normal"
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box px={4}>
              <div data-walkme="createlead2-field-financingneed">
                <FinancingNeedComponent
                  id="form.financingNeed"
                  label={<IntlMessageComponent id="form.financingNeed.label" />}
                  error={!!props.errors.get('financingNeedId')}
                  helperText={props.errors.get('financingNeedId') ?
                    <IntlMessageComponent id={props.errors.get('financingNeedId')} /> : ''}
                  value={props.form.financingNeedId}
                  changeHandler={props.handleTypeIdChange('financingNeedId')}
                  fullWidth
                  margin="normal"
                />
              </div>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box px={4}>
              <div data-walkme="createlead2-field-financingfrequency">
                <FinancingNeedFrequencyComponent
                  id="form.financingNeedFrequency"
                  label={<IntlMessageComponent id="form.financingNeedFrequency.label" />}
                  error={!!props.errors.get('financingNeedFrequencyId')}
                  helperText={props.errors.get('financingNeedFrequencyId') ?
                    <IntlMessageComponent id={props.errors.get('financingNeedFrequencyId')} /> : ''}
                  value={props.form.financingNeedFrequencyId}
                  changeHandler={props.handleTypeIdChange('financingNeedFrequencyId')}
                  fullWidth
                  margin="normal"
                />
              </div>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box px={4}>
              <div data-walkme="createlead2-field-yearlyinvoiceamount">
                <InvoicesYearlyAmountComponent
                  id="form.invoicesYearlyAmount"
                  label={<IntlMessageComponent id="form.invoicesYearlyAmount.label" />}
                  error={!!props.errors.get('invoicesYearlyAmountId')}
                  helperText={props.errors.get('invoicesYearlyAmountId') ?
                    <IntlMessageComponent id={props.errors.get('invoicesYearlyAmountId')} /> : ''}
                  value={props.form.invoicesYearlyAmountId}
                  changeHandler={props.handleTypeIdChange('invoicesYearlyAmountId')}
                  fullWidth
                  margin="normal"
                />
              </div>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.revenuesYearly"
                data-walkme="createlead2-field-yearlyrevenues"
                label={<IntlMessageComponent id="form.revenuesYearly.label" />}
                error={!!props.errors.get('revenuesYearly')}
                helperText={(props.errors.get('revenuesYearly')) ?
                  <IntlMessageComponent id={props.errors.get('revenuesYearly')} /> : ''}
                value={props.form.revenuesYearly}
                onChange={props.handleChange('revenuesYearly')}
                InputProps={{
                  inputComponent: InputAmountComponent,
                  startAdornment: <InputAdornment position="start">€</InputAdornment>
                }}
                fullWidth
                margin="normal"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box mt={5}>
        <ButtonComponent
          color="default"
          variant="contained"
          disabled={props.isProcessing}
          onClick={props.skip}
        ><IntlMessageComponent id="general.skip" /></ButtonComponent>

        <Box component="span" ml={1}>
          <ButtonComponent
            color="primary"
            variant="contained"
            isProcessing={props.isProcessing}
            onClick={props.submitForm}
          ><IntlMessageComponent id="general.continue" /></ButtonComponent>
        </Box>

      </Box>
    </React.Fragment>
  );
}
