/* @flow */

import React from 'react';

import ISODashboardComponent from 'app/isos/dashboard';
import WebsiteDashboardPageComponent from 'components/websiteStructure/pages/dashboard';

export default function ISODashboardPage() {
  return (
    <WebsiteDashboardPageComponent id="iso.dashboard.title">
      <ISODashboardComponent />
    </WebsiteDashboardPageComponent>
  );
}
