/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import CompanyMembershipCouponFormViewComponent from './view';

import CouponFormModel from './model';
import SetCompanyMembershipCouponRequest from 'models/requests/companies/setCompanyMembershipCouponRequest';

import CompanyService from 'services/CompanyService';
import {handleApiFormResponse, handleFormChange, handleFormValidation} from 'lib/forms';
import loadConstraints from 'lib/validation/loadConstraints';

type CompanyMembershipCouponFormComponentProps = {
  activeCompanyId: number;
  successHandler: () => void;
}

type CompanyMembershipCouponFormComponentState = {
  constraints: any;
  errors: Map<string, ?string>;
  form: CouponFormModel;
  formErrors: string[];
  isLoading: boolean;
  isProcessing: boolean;
}

class CompanyMembershipCouponFormComponent extends React.Component<CompanyMembershipCouponFormComponentProps, CompanyMembershipCouponFormComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      constraints: null,
      errors: null,
      form: new CouponFormModel(),
      formErrors: [],
      isLoading: true,
      isProcessing: false
    };
  }

  componentDidMount = async () => {
    try {
      const constraints = await loadConstraints('Coupon', ['reference']);
      this.setState({
        constraints,
        errors: new Map(Object.keys(constraints).map(e => [e, undefined])),
        isLoading: false
      });
    } catch (e) {
      console.error(e);
    }
  }

  handleChange = (fieldName: string) => (event) => handleFormChange.call(this, fieldName, event.target.value);
  validateForm = () => handleFormValidation.call(this);

  submitForm = async () => {
    if (!this.validateForm()) return;
    this.setState({formErrors: [], isProcessing: true});

    try {
      const request = new SetCompanyMembershipCouponRequest(this.props.activeCompanyId, this.state.form.reference);
      await CompanyService.setCompanyMembershipCoupon(request);
      this.props.successHandler();
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }
  };

  render() {
    if (this.state.isLoading) return null;

    return (
      <CompanyMembershipCouponFormViewComponent
        errors={this.state.errors}
        form={this.state.form}
        formErrors={this.state.formErrors}
        isProcessing={this.state.isProcessing}
        handleChange={this.handleChange}
        submitForm={this.submitForm}
      />
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps)(CompanyMembershipCouponFormComponent);
