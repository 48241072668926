/* @flow */

import SetAlternativeFinancingRequest from 'models/requests/companies/setAlternativeFinancingRequest';

export default class AlternativeFinancingForm {
  hasAlternativeFinancing: boolean;
  companyName: string;
  costPercentage: number;
  type: string;
  creditLimit: number;

  constructor() {
    this.hasAlternativeFinancing = false;
    this.companyName = '';
    this.costPercentage = '';
    this.type = '';
    this.creditLimit = '';
  }

  toRequest(companyId: number) {
    const request = new SetAlternativeFinancingRequest();
    request.companyId = companyId;
    request.alternativeFinancingCompanyName = this.companyName;
    request.alternativeFinancingType = this.type;
    request.alternativeFinancingCost = this.costPercentage;
    request.alternativeFinancingAmount = this.creditLimit;
    return request;
  }
}
