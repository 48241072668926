/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

import StyledTableCellTextComponent from 'components/table/cellText';
import StyledTableCellNumbersComponent from 'components/table/cellNumbers';
import StyledTableHeadComponent from 'components/table/head';
import StyledTableRowComponent from 'components/table/row';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import AmountFormatterComponent from 'components/formatters/amount';
import DateFormatterComponent from 'components/formatters/date';
import BooleanFormatterComponent from 'components/formatters/boolean';

import BuyerInvoiceBatch from 'models/buyers/invoices/invoiceBatch';

let columnData = [
    // {id: 'id', translationKey: 'invoice.batch.id', walkme: 'bibatch-column-id'},
    {id: 'reference', translationKey: 'invoice.batch.reference', walkme: 'bibatch-column-reference'},
    {id: 'debtAmount', translationKey: 'invoice.batch.debtAmount', walkme: 'bibatch-column-debtAmount'},
    {id: 'dueDate', translationKey: 'invoice.batch.dueDate', walkme: 'bibatch-column-dueDate'},
    {id: 'batchDebtorReference', translationKey: 'invoice.batch.batchDebtorReference', walkme: 'bibatch-column-batchDebtorReference'},
    {id: 'batchBalance', translationKey: 'invoice.batch.batchBalance', walkme: 'bibatch-column-batchBalance'},
  ];

type BuyerInvoiceBatchComponentProps = {
  batch: BuyerInvoiceBatch[];
  showBalance: boolean;
}

export default function BuyerInvoiceBatchComponent(props: BuyerInvoiceBatchComponentProps) {

  const debtTotal = props.batch.reduce(function(prev, cur) {
    return prev + cur.debtAmount;
  }, 0);
  const balanceTotal = props.batch.reduce(function(prev, cur) {
    return prev + cur.batchBalance;
  }, 0);
  columnData = props.showBalance ? columnData : columnData.filter(c => c.id !== "batchBalance");

  return (
    <React.Fragment>
      <Box p={3}>
        <PageSubTitleViewComponent id="buyer.invoiceDetails.batch" />
        <Box component="div" overflow="auto">
          <Table>
            <StyledTableHeadComponent data-walkme="sportfolio-table-headline">
              <StyledTableRowComponent>
                {columnData.map(column => (
                  <StyledTableCellTextComponent align={column?.align ?? 'right'} key={column.id} padding="none">
                    <IntlMessageComponent id={column.translationKey} />
                  </StyledTableCellTextComponent>
                ))}
                
                <StyledTableCellNumbersComponent padding ="space"/>
              </StyledTableRowComponent>
            </StyledTableHeadComponent>

            <TableBody bgcolor="white">
              {props.batch.length === 0 &&
                <StyledTableRowComponent>
                  <StyledTableCellTextComponent colSpan={columnData.length}>
                    <Box textAlign="center">
                      <IntlMessageComponent id="general.noData" />
                    </Box>
                  </StyledTableCellTextComponent>
                </StyledTableRowComponent>
              }

              {props.batch.map(i => (
                <StyledTableRowComponent
                  key={`invList-${i.id}`}  
                  hover
                  tabIndex={-1}
                >
                  <StyledTableCellTextComponent padding="none">
                    <strong>{i.reference}</strong>
                  </StyledTableCellTextComponent>

                  <StyledTableCellNumbersComponent padding="none">
                    <AmountFormatterComponent amount={i.debtAmount} />
                  </StyledTableCellNumbersComponent>

                  <StyledTableCellTextComponent padding="none">
                    <DateFormatterComponent date={i.dueDate} />
                  </StyledTableCellTextComponent>

                  <StyledTableCellTextComponent padding="none">
                    <strong>{i.batchDebtorReference}</strong>
                  </StyledTableCellTextComponent>

                  {props.showBalance && <StyledTableCellNumbersComponent padding="none">
                    <AmountFormatterComponent amount={i.batchBalance} />
                  </StyledTableCellNumbersComponent>}

                  <StyledTableCellNumbersComponent padding ="space"/>
                </StyledTableRowComponent>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Box>
        <Box px={3} pt={1} pb={props.showBalance ? 0 : 3}>
          <IntlMessageComponent id="invoice.batch.debttotal" />: <AmountFormatterComponent amount={debtTotal} />
        </Box>
        {props.showBalance && <Box px={3} pb={3}>
          <IntlMessageComponent id="invoice.batch.balancetotal" />: <AmountFormatterComponent amount={balanceTotal} />
        </Box>}
    </React.Fragment>
  );
}
