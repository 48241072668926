/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';

import IntlMessageComponent from 'components/formatters/intlMessage';

export default function PaymentLoadingComponent() {
  return (
    <Paper elevation={0}>
      <Box align="center" p={5}>
        <Box><CircularProgress size={60} /></Box>
        <strong><IntlMessageComponent id="page.payment.loading" /></strong>
      </Box>
    </Paper>
  );
}
