import React from 'react';
import {NavLink} from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import AmountFormatterComponent from 'components/formatters/amount';
import CompanyAddressComponent from 'components/company/address';
import DateFormatterComponent from 'components/formatters/date';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import InvoicePaymentResult from 'models/invoices/seller/invoicePaymentResult';

import {PROTECTED_SELLER_DASHBOARD} from 'constants/pageRoutes';

type PaymentConfirmationInvoiceComponentProps = {
  invoices: InvoicePaymentResult[];
}

export default function PaymentConfirmationInvoiceComponent(props: PaymentConfirmationInvoiceComponentProps) {
  return (
    <React.Fragment>
      <PageSubTitleViewComponent id="page.payment.invoice.title" />
      
      <Typography variant="body1" gutterBottom>
        <IntlMessageComponent id="page.payment.invoice.description1" />
        <br />
        <IntlMessageComponent id="page.payment.invoice.description2" />
      </Typography>

      {props.invoices.map((invoice, index) => (
        <Box mt={3} py={3} bgcolor="lightblue.main" key={`invoice-${index}`}>
          <Grid container direction="row" justify="space-between" alignItems="flex-start">
            <Grid item xs={12} md={6}>
              <Box display="flex" px={4}>
                <Box flexGrow={1} mb={1}>
                  <Typography variant="body1">
                    <Box component="span" color="grey.main" fontSize={13}>
                      <IntlMessageComponent id="page.payment.invoice.debtor.name" data-walkme="payment-inv-debtorName" />
                    </Box>
                  </Typography>
                </Box>
                <Box>
                  <CompanyAddressComponent company={invoice.debtorCompany} />
                </Box>
              </Box>
            </Grid>
          
            <Grid item xs={12} md={6}>
              <Box display="flex" px={4}>
                <Box flexGrow={1} mb={1}>
                  <Typography variant="body1">
                    <Box component="span" color="grey.main" fontSize={13}>
                      <IntlMessageComponent id="page.payment.invoice.reference" data-walkme="payment-inv-reference" />
                    </Box>
                  </Typography>
                </Box>
                <Box>
                  {invoice.reference}
                </Box>
              </Box>

              <Box display="flex" px={4}>
                <Box flexGrow={1} mb={1}>
                  <Typography variant="body1">
                    <Box component="span" color="grey.main" fontSize={13}>
                      <IntlMessageComponent id="page.payment.invoice.dueDate" data-walkme="payment-inv-dueDate" />
                    </Box>
                  </Typography>
                </Box>
                <Box>
                  <DateFormatterComponent date={invoice.dueDate} format="DD MMMM YYYY" />
                </Box>
              </Box>

              <Box display="flex" px={4}>
                <Box flexGrow={1} mb={1}>
                  <Typography variant="body1">
                    <Box component="span" color="grey.main" fontSize={13}>
                      <IntlMessageComponent id="page.payment.invoice.debtAmount" data-walkme="payment-inv-debtAmount" />
                    </Box>
                  </Typography>
                </Box>
                <Box>
                  <AmountFormatterComponent amount={invoice.debtAmount} />
                </Box>
              </Box>

              <Box display="flex" px={4}>
                <Box flexGrow={1} mb={1}>
                  <Typography variant="body1">
                    <Box component="span" color="grey.main" fontSize={13}>
                      <IntlMessageComponent id="page.payment.invoice.transactionPrice" data-walkme="payment-inv-transactionPrice" />
                    </Box>
                  </Typography>
                </Box>
                <Box>
                  <AmountFormatterComponent amount={invoice.transactionPrice} />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ))}
            
      <Box mt={5}>
        <Typography variant="body1" gutterBottom>
          <IntlMessageComponent id="page.payment.invoice.description3" />
          <br />
          <IntlMessageComponent id="page.payment.invoice.description4" />
          &nbsp;
          <NavLink to={PROTECTED_SELLER_DASHBOARD}>
            <IntlMessageComponent id="page.payment.invoice.link" />
          </NavLink>
        </Typography>
      </Box>

      <Box mt={5}>
        <PageSubTitleViewComponent id="page.payment.invoice.faq.title" />
        
        <Typography variant="body1" gutterBottom>
          <IntlMessageComponent id="page.payment.invoice.faq.description" />
        </Typography>
      </Box>
    </React.Fragment>
  );
}
