/* @flow */

import SetBusinessOpportunityRequest from 'models/requests/isos/registerLeads/setBusinessOpportunityRequest';

export default class BusinessOpportunityForm {
  expectedMonthlyVolume: number;
  invoicesAvgAmount: number;
  invoicesNbrMonthly: number;
  nbrDebtors: number;
  financingNeedId: number;
  financingNeedFrequencyId: number;
  invoicesYearlyAmountId: number;
  revenuesYearly: number;

  constructor() {
    this.expectedMonthlyVolume = null;
    this.invoicesAvgAmount = null;
    this.invoicesNbrMonthly = null;
    this.nbrDebtors = null;
    this.financingNeedId = 0;
    this.financingNeedFrequencyId = 0;
    this.invoicesYearlyAmountId = 0;
    this.revenuesYearly = null;
  }

  toRequest(isoCompanyId: number, companyId: number){
    const request = new SetBusinessOpportunityRequest();
    request.isoCompanyId = isoCompanyId;
    request.companyId = companyId;
    request.expectedMonthlyVolume = this.expectedMonthlyVolume;
    request.invoicesAvgAmount = this.invoicesAvgAmount;
    request.invoicesNbrMonthly = this.invoicesNbrMonthly;
    request.nbrDebtors = this.nbrDebtors;
    request.financingNeedId = this.financingNeedId;
    request.financingNeedFrequencyId = this.financingNeedFrequencyId;
    request.invoicesYearlyAmountId = this.invoicesYearlyAmountId;
    request.revenuesYearly = this.revenuesYearly;
    return request;
  }
}
