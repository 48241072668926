/* @flow */

import {combineReducers} from 'redux';
import {intlReducer} from 'react-intl-redux/lib/index';

import Auth from './authReducer';
import ComponentData from './componentDataReducer';
import Locale from './localeReducer';

const reducers = combineReducers({
  auth: Auth,
  components: ComponentData,
  locale: Locale,
  intl: intlReducer,
});

export default reducers;
