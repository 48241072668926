/* @flow */

import React from 'react';
import customSort from 'lib/sortHelpers';

import InvoiceToPayTableViewComponent from './view';

import InvoiceSummary from 'models/invoices/seller/invoiceSummary';

type InvoiceToPayTableComponentProps = {
  invoices: InvoiceSummary[];
  handleNewInvoiceCreation: () => void;
  handleSelectionDone: (invoiceIds: number[]) => void;
}

type InvoiceToPayTableComponentState = {
  invoices: InvoiceSummary[];
  paymentMethod: string;
  order: string;
  orderBy: string;
  page: number;
  pageSize: number;
}

class InvoiceToPayTableComponent extends React.Component<InvoiceToPayTableComponentProps, InvoiceToPayTableComponentState> {

  constructor(props) {
    super(props);

    const invoices = props.invoices;
    invoices.forEach(i => {i.isSelected = false});

    this.state = {
      invoices,
      paymentMethod: "",
      order: 'asc',
      orderBy: 'price',
      page: 0,
      pageSize: 10
    };
  }

  handlePageChange = (page: number) => this.setState({page});
  handlePageSizeChange = (pageSize: number) => this.setState({pageSize});

  handleSortOrderChange = (orderBy: string) => {
    const order = this.state.orderBy === orderBy && this.state.order === 'desc' ? 'asc' : 'desc';

    let invoices = order === 'desc'
      ? this.state.invoices.sort((a, b) => customSort(b[orderBy], a[orderBy]))
      : this.state.invoices.sort((a, b) => customSort(a[orderBy], b[orderBy]));
    
    this.setState({invoices, order, orderBy});
  };

  handleChoosePayment = (paymentMethod: string) => {
    this.setState({paymentMethod})
  }

  selectInvoice = (invoiceId: number) => {
    const invoices = this.state.invoices;
    const invoice = invoices.find(i => i.id === invoiceId);
    if (invoice) invoice.isSelected = !invoice.isSelected;
    this.setState({invoices});
  }

  selectAllInvoices = () => {
    const invoices = this.state.invoices;
    const selectMode = invoices.find(i => i.isSelected) === undefined;
    invoices.forEach(i => { i.isSelected = selectMode; });
    this.setState({invoices});
  }

  handleSelectionDone = () => {
    const invoiceIds = this.state.invoices.filter(i => i.isSelected).map(i => i.id); 
    this.props.handleSelectionDone(invoiceIds, this.state.paymentMethod);
  }

  render() {
    return (
      <InvoiceToPayTableViewComponent
        invoices={this.state.invoices}
        order={this.state.order}
        orderBy={this.state.orderBy}
        page={this.state.page}
        pageSize={this.state.pageSize}
        paymentMethod={this.state.paymentMethod}
        handleNewInvoiceCreation={this.props.handleNewInvoiceCreation}
        handlePageChange={this.handlePageChange}
        handlePageSizeChange={this.handlePageSizeChange}
        handleSelectionDone={this.handleSelectionDone}
        handleSortOrderChange={this.handleSortOrderChange}
        handleChoosePayment={this.handleChoosePayment}
        selectInvoice={this.selectInvoice}
        selectAllInvoices={this.selectAllInvoices}
      />
    );
  }
}

export default InvoiceToPayTableComponent;
