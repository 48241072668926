
/* @flow */
import moment from 'moment';

export default class BillActiveFilters {

  query: string;
  dateFrom: moment;
  dateTo: moment;

  constructor() {
    this.query = '';
    this.dateFrom = moment().startOf('year');
    this.dateTo = moment();
  }
}
