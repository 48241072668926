/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';

import AmountFormatterComponent from 'components/formatters/amount';
import ButtonComponent from 'components/buttons/button';
import DateFormatterComponent from 'components/formatters/date';
import IntlMessageComponent from 'components/formatters/intlMessage';
import MandatoryFormFieldsComponent from 'components/form/mandatory';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import Company from 'models/companies/company';
import ApproveOrDisputeInvoiceForm from './model';

type DebtorInvoicesToApproveFormViewComponentProps = {
  debtAmount: number;
  debtorCompany: Company;
  dueDate: moment;
  errors: Map<string, ?string>;
  form: ApproveOrDisputeInvoiceForm;
  formErrors: string[];
  isApprovable: boolean;
  isDisputable: boolean;
  isProcessing: boolean;
  multipleInvoices: boolean;
  submitForm: () => void;
  handleChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void;
  handleBooleanChange: () => void;
}

const useStyles = makeStyles(_ => ({
  group: {
    width: 'auto',
    height: 'auto',
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'row',
  }
}));

export default function DebtorInvoicesToApproveFormViewComponent(props: DebtorInvoicesToApproveFormViewComponentProps) {
  const classes = useStyles();

  const placeHolderValues = {
    debtorCompany: props.debtorCompany.name,
    debtAmount: <AmountFormatterComponent amount={props.debtAmount} />,
    dueDate: <DateFormatterComponent date={props.dueDate} />
  };

  return (
    <React.Fragment>
      <PageSubTitleViewComponent id="component.debtor.invoices.questionnaire.title" />

      <Box mt={3}>
        <Box>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">
              {props.multipleInvoices ? (
                <IntlMessageComponent id="component.debtor.invoices.questionnaire.question.genuine.plural" values={placeHolderValues} />
              ) : (
                <IntlMessageComponent id="component.debtor.invoices.questionnaire.question.genuine.singular" values={placeHolderValues} />
              )}
            </FormLabel>
            <RadioGroup
              className={classes.group}
              name="question-invoice-genuine"
              value={props.form.answerInvoiceGenuine?.toString() || ''}
              onChange={props.handleBooleanChange('answerInvoiceGenuine')}
            >
              <FormControlLabel control={<Radio color="primary" />} label={<IntlMessageComponent id="general.label.no" />} value="false" disabled={props.isProcessing} />
              <FormControlLabel control={<Radio color="primary" />} label={<IntlMessageComponent id="general.label.yes" />} value="true" disabled={props.isProcessing} />
            </RadioGroup>
          </FormControl>

          {props.form.answerInvoiceGenuine !== null && !props.form.answerInvoiceGenuine &&
            <Box mb={3}>
              <TextField
                id="form.answerInvoiceGenuine"
                label={<IntlMessageComponent id="component.debtor.invoices.questionnaire.question.genuineText" />}
                error={!!props.errors.get('answerInvoiceGenuineText')}
                helperText={(props.errors.get('answerInvoiceGenuineText')) ?
                  <IntlMessageComponent id={props.errors.get('answerInvoiceGenuineText')} /> : ''}
                value={props.form.answerInvoiceGenuineText}
                onChange={props.handleChange('answerInvoiceGenuineText')}
                disabled={props.isProcessing}
                fullWidth
                margin="normal"
                multiline
                required
                rows="6"
              />
            </Box>
          }
        </Box>

        <Box mt={3}>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">
              {props.multipleInvoices ? (
                <IntlMessageComponent id="component.debtor.invoices.questionnaire.question.correctAmountAndDueDate.plural" values={placeHolderValues} />
              ) : (
                <IntlMessageComponent id="component.debtor.invoices.questionnaire.question.correctAmountAndDueDate.singular" values={placeHolderValues} />
              )}
            </FormLabel>
            <RadioGroup
              className={classes.group}
              name="question-invoice-genuine"
              value={props.form.answerAmountAndDueDateCorrect?.toString() || ''}
              onChange={props.handleBooleanChange('answerAmountAndDueDateCorrect')}
            >
              <FormControlLabel control={<Radio color="primary" />} label={<IntlMessageComponent id="general.label.no" />} value="false" disabled={props.isProcessing} />
              <FormControlLabel control={<Radio color="primary" />} label={<IntlMessageComponent id="general.label.yes" />} value="true" disabled={props.isProcessing} />
            </RadioGroup>
          </FormControl>
          
          {props.form.answerAmountAndDueDateCorrect !== null && !props.form.answerAmountAndDueDateCorrect &&
            <Box mb={3}>
              <TextField
                id="form.answerAmountAndDueDateCorrect"
                label={<IntlMessageComponent id="component.debtor.invoices.questionnaire.question.amountduedateText" />}
                error={!!props.errors.get('answerAmountAndDueDateCorrectText')}
                helperText={(props.errors.get('answerAmountAndDueDateCorrectText')) ?
                  <IntlMessageComponent id={props.errors.get('answerAmountAndDueDateCorrectText')} /> : ''}
                value={props.form.answerAmountAndDueDateCorrectText}
                onChange={props.handleChange('answerAmountAndDueDateCorrectText')}
                disabled={props.isProcessing}
                fullWidth
                margin="normal"
                multiline
                required
                rows="6"
              />
            </Box>
          }
        </Box>

        <Box mt={3}>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">
              {props.multipleInvoices ? (
                <IntlMessageComponent id="component.debtor.invoices.questionnaire.question.goodsDelivered.plural" values={placeHolderValues} />
              ) : (
                <IntlMessageComponent id="component.debtor.invoices.questionnaire.question.goodsDelivered.singular" values={placeHolderValues} />
              )}
            </FormLabel>
            <RadioGroup
              className={classes.group}
              name="question-invoice-genuine"
              value={props.form.answerServicesOrGoodsDelivered?.toString() || ''}
              onChange={props.handleBooleanChange('answerServicesOrGoodsDelivered')}
            >
              <FormControlLabel control={<Radio color="primary" />} label={<IntlMessageComponent id="general.label.no" />} value="false" disabled={props.isProcessing} />
              <FormControlLabel control={<Radio color="primary" />} label={<IntlMessageComponent id="general.label.yes" />} value="true" disabled={props.isProcessing} />
            </RadioGroup>
          </FormControl>
        
          {props.form.answerServicesOrGoodsDelivered !== null && !props.form.answerServicesOrGoodsDelivered &&
            <Box mb={3}>
              <TextField
                id="form.answerServicesOrGoodsDeliveredText"
                label={<IntlMessageComponent id="component.debtor.invoices.questionnaire.question.serviceorgoodsText" />}
                error={!!props.errors.get('answerServicesOrGoodsDeliveredText')}
                helperText={(props.errors.get('answerServicesOrGoodsDeliveredText')) ?
                  <IntlMessageComponent id={props.errors.get('answerServicesOrGoodsDeliveredText')} /> : ''}
                value={props.form.answerServicesOrGoodsDeliveredText}
                onChange={props.handleChange('answerServicesOrGoodsDeliveredText')}
                disabled={props.isProcessing}
                fullWidth
                margin="normal"
                multiline
                required
                rows="6"
              />
            </Box>
          }
        </Box>

        <Box mt={3}>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">
              {props.multipleInvoices ? (
                <IntlMessageComponent id="component.debtor.invoices.questionnaire.question.dispute.plural" values={placeHolderValues} />
              ) : (
                <IntlMessageComponent id="component.debtor.invoices.questionnaire.question.dispute.singular" values={placeHolderValues} />
              )}
            </FormLabel>
            <RadioGroup
              className={classes.group}
              name="question-invoice-genuine"
              value={props.form.answerWillDebtorDisputeInvoice?.toString() || ''}
              onChange={props.handleBooleanChange('answerWillDebtorDisputeInvoice')}
            >
              <FormControlLabel control={<Radio color="primary" />} label={<IntlMessageComponent id="general.label.no" />} value="false" disabled={props.isProcessing} />
              <FormControlLabel control={<Radio color="primary" />} label={<IntlMessageComponent id="general.label.yes" />} value="true" disabled={props.isProcessing} />
            </RadioGroup>
          </FormControl>

          {props.form.answerWillDebtorDisputeInvoice !== null && props.form.answerWillDebtorDisputeInvoice &&
            <Box mb={3}>
              <TextField
                id="form.answerWillDebtorDisputeInvoiceText"
                label={<IntlMessageComponent id="component.debtor.invoices.questionnaire.question.disputeText" />}
                error={!!props.errors.get('answerWillDebtorDisputeInvoiceText')}
                helperText={(props.errors.get('answerWillDebtorDisputeInvoiceText')) ?
                  <IntlMessageComponent id={props.errors.get('answerWillDebtorDisputeInvoiceText')} /> : ''}
                value={props.form.answerWillDebtorDisputeInvoiceText}
                onChange={props.handleChange('answerWillDebtorDisputeInvoiceText')}
                disabled={props.isProcessing}
                fullWidth
                margin="normal"
                multiline
                required
                rows="6"
              />
            </Box>
          }
        </Box>

        <Box mt={3}>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">
              {props.multipleInvoices ? (
                <IntlMessageComponent id="component.debtor.invoices.questionnaire.question.guarantee.plural" values={placeHolderValues} />
              ) : (
                <IntlMessageComponent id="component.debtor.invoices.questionnaire.question.guarantee.singular" values={placeHolderValues} />
              )}
            </FormLabel>
            <RadioGroup
              className={classes.group}
              name="question-invoice-genuine"
              value={props.form.answerGuaranteeDeductionOrCompensation?.toString() || ''}
              onChange={props.handleBooleanChange('answerGuaranteeDeductionOrCompensation')}
            >
              <FormControlLabel control={<Radio color="primary" />} label={<IntlMessageComponent id="general.label.no" />} value="false" disabled={props.isProcessing} />
              <FormControlLabel control={<Radio color="primary" />} label={<IntlMessageComponent id="general.label.yes" />} value="true" disabled={props.isProcessing} />
            </RadioGroup>
          </FormControl>
        
          {props.form.answerGuaranteeDeductionOrCompensation !== null && props.form.answerGuaranteeDeductionOrCompensation &&
            <Box mb={3}>
              <TextField
                id="form.guaranteeDeductionOrCompensation"
                label={<IntlMessageComponent id="component.debtor.invoices.questionnaire.question.guaranteeText" />}
                error={!!props.errors.get('answerGuaranteeDeductionOrCompensationText')}
                helperText={(props.errors.get('answerGuaranteeDeductionOrCompensationText')) ?
                  <IntlMessageComponent id={props.errors.get('answerGuaranteeDeductionOrCompensationText')} /> : ''}
                value={props.form.answerGuaranteeDeductionOrCompensationText}
                onChange={props.handleChange('answerGuaranteeDeductionOrCompensationText')}
                disabled={props.isProcessing}
                fullWidth
                margin="normal"
                multiline
                required
                rows="6"
              />
            </Box>
          }
        </Box>

        <Box mt={5}>
          <ButtonComponent
            color={props.isDisputable ? "tertiary" : "primary"}
            variant="contained"
            disabled={(!props.isApprovable && !props.isDisputable)}
            isProcessing={props.isProcessing}
            onClick={props.submitForm}
          >
            {props.isDisputable ? (
              <IntlMessageComponent id="general.dispute" />
            ) : (
              <IntlMessageComponent id="general.approve" />
            )}
          </ButtonComponent> 
          
          <MandatoryFormFieldsComponent/>
        </Box>

        <Box mt={3}>
          <Typography variant="body1">
            <IntlMessageComponent id="component.debtor.invoices.questionnaire.legal" />
          </Typography>
        </Box>
      </Box>
    </React.Fragment>
  );
}
