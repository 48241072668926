/* @flow */

import React from 'react';

import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';

import IntlMessageComponent from 'components/formatters/intlMessage';

import {
  COMPANY_UBO_STATUS_NEW,
  COMPANY_UBO_STATUS_DRAFT,
  COMPANY_UBO_STATUS_SUBMITTED,
  COMPANY_UBO_STATUS_APPROVED,
  COMPANY_UBO_STATUS_EXPIRESOON,
  COMPANY_UBO_STATUS_EXPIRED,
  COMPANY_UBO_STATUS_REJECTED
} from 'constants/constants';

type CompanyUBOsStatusComponentProps = {
  status: number;
  hasAnyInvalidId: boolean;
  hasAnyActiveUbo: boolean;
  hasAnyMissingField: boolean;
}

export default function CompanyUBOsStatusComponent(props: CompanyUBOsStatusComponentProps) {
  if (props.status === COMPANY_UBO_STATUS_APPROVED) {
    return null;
  }

  return (
    <Box mt={5}>
      {props.status === COMPANY_UBO_STATUS_DRAFT &&
        <Alert severity="info">
          <IntlMessageComponent id="company.ubos.draft" />
        </Alert>
      }
      {props.status === COMPANY_UBO_STATUS_SUBMITTED &&
        <Alert severity="success">
          <IntlMessageComponent id="company.ubos.submitted" />
        </Alert>
      }
      {props.status === COMPANY_UBO_STATUS_EXPIRESOON && !props.hasAnyInvalidId && !props.hasAnyMissingField && props.hasAnyActiveUbo &&
        <Alert severity="info">
          <IntlMessageComponent id="company.ubos.expiredallvalid" />
        </Alert>
      }
      {props.status === COMPANY_UBO_STATUS_EXPIRESOON && (props.hasAnyInvalidId || props.hasAnyMissingField || !props.hasAnyActiveUbo) &&
        <Alert severity="warning">
          <IntlMessageComponent id="company.ubos.expireSoon" />
        </Alert>
      }
      {props.status === COMPANY_UBO_STATUS_EXPIRED && !props.hasAnyInvalidId && !props.hasAnyMissingField && props.hasAnyActiveUbo &&
        <Alert severity="info">
          <IntlMessageComponent id="company.ubos.expiredallvalid" />
        </Alert>
      }
      {(props.status === COMPANY_UBO_STATUS_NEW || props.status === COMPANY_UBO_STATUS_EXPIRED) && (props.hasAnyInvalidId || props.hasAnyMissingField || !props.hasAnyActiveUbo) &&
        <Alert severity="warning">
          <IntlMessageComponent id="company.ubos.expired" />
        </Alert>
      }
      {props.status === COMPANY_UBO_STATUS_REJECTED &&
        <Alert severity="error">
          <IntlMessageComponent id="company.ubos.rejected" />
        </Alert>
      }
    </Box>
  );
}
