/* @flow */

import React from 'react';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import Popover from '@material-ui/core/Popover';

import IntlMessageComponent from 'components/formatters/intlMessage';

import WebsiteHeaderCompanyMenuItemLinkComponent from './itemLink';
import WebsiteHeaderCompanyMenuListComponent from './companyList';
import WebsiteHeaderCompanyMenuRoleListComponent from './companyRoleList';

import { companyMenuLinksData } from './data';
import { ROLE_BUYER, ROLE_CONFIRMING, ROLE_DEBTOR, ROLE_ISO, ROLE_SELLER } from 'constants/companyRoles';

import { hasSecurityMenuAccess } from 'lib/securityHelper';

const useStyles = makeStyles(theme => ({
  button: {
    textTransform: 'none'
  },
  disabled: {
    cursor: 'default',
    pointerEvents: 'none!important'
  }
}));

export default function WebsiteHeaderCompanyMenuComponent() {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const classes = useStyles();
  const { activeCompany, securityMenus, user } = useSelector(state => ({
    activeCompany: state.auth.user.activeCompany,
    securityMenus: state.locale.securityMenus,
    user: state.auth.user
  }));

  if (!user) return null;

  let nbRoles = 0;
  if (user.activeCompany.isBuyer) nbRoles += 1;
  if (user.activeCompany.isDebtor) nbRoles += 1;
  if (user.activeCompany.isISO) nbRoles += 1;
  if (user.activeCompany.isSeller) nbRoles += 1;
  if (user.activeCompany.isConfirming) nbRoles += 1;

  const flipOpen = () => {
    if (user.activeCompany.isSubscribing) { return; }
    setOpen(prevOpen => !prevOpen);
  }

  const hasMenu = () => {
    if (user.companies.length > 1 || nbRoles > 1 || companyMenuLinksData.some(item => hasSecurityMenuAccess(item.pageName, item.elementName, activeCompany, securityMenus))) {
      return true;
    }
    return false;
  }

  const getChipLabel = (role: number) => {
    switch (role) {
      case ROLE_BUYER:
        return 'header.company.buyer';
      case ROLE_DEBTOR:
        return 'header.company.debtor';
      case ROLE_ISO:
        return 'header.company.iso';
      case ROLE_SELLER:
        return 'header.company.seller';
      case ROLE_CONFIRMING:
        return 'header.company.confirming';
      default:
        return 'oops!';
    }
  }

  return (
    <React.Fragment>
      <Button
        ref={anchorRef}
        className={hasMenu() ? classes.button : classes.disabled}
        onClick={hasMenu() ? flipOpen : undefined}
      >
        <Box textAlign="left">
          <Box color="grey.main"><small><IntlMessageComponent id="header.company.title" /></small></Box>
          <Box color="primary.main"><b><em>{user.activeCompany.name}</em></b></Box>
        </Box>
        <Box ml={2}>
          <Chip label={<IntlMessageComponent id={getChipLabel(user.activeCompany.role)} />} />
        </Box>
        <Box color="primary.main" ml={1}>
          {hasMenu() && <ArrowDropDownIcon fontSize="small" />}
        </Box>
      </Button>

      <Popover
        id='language-popup'
        open={open}
        anchorEl={anchorRef.current}
        onClose={flipOpen}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
      >
        {user.companies.length > 1 &&
          <React.Fragment>
            <WebsiteHeaderCompanyMenuListComponent flipOpen={flipOpen} />
            <Divider />
          </React.Fragment>
        }

        {nbRoles > 1 &&
          <React.Fragment>
            <WebsiteHeaderCompanyMenuRoleListComponent flipOpen={flipOpen} />
            <Divider />
          </React.Fragment>
        }

        <List component="nav" dense>
          {companyMenuLinksData.map(item => (<WebsiteHeaderCompanyMenuItemLinkComponent item={item} user={user} key={item.id} />))}
        </List>
      </Popover>
    </React.Fragment>
  );
}
