/* @flow */

import React from 'react';

import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

import AmountFormatterComponent from 'components/formatters/amount';
import DateFormatterComponent from 'components/formatters/date';
import IntlMessageComponent from 'components/formatters/intlMessage';
import StyledTableCellTextComponent from 'components/table/cellText';
import StyledTableCellNumbersComponent from 'components/table/cellNumbers';
import StyledTableHeadComponent from 'components/table/head';
import StyledTableRowComponent from 'components/table/row';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import RealizedData from 'models/companies/companyFixContractRealizedData';
import fixcontract from '../index';
import PageTitleViewComponent from 'components/pageTitle/index';

const columnData = [
  { id: 'startDate', translationKey: 'component.fixcontract.header.startDate' },
  { id: 'engagedMonthlyFinancedAmount', translationKey: 'component.fixcontract.header.engagedMonthlyFinancedAmount' }
];

type FixContractTableComponentProps = {
  fixContracts: CompanyFixContract[];
  selectContract: (fixContract: CompanyFixContract) => void;
}

export default function FixContractTableComponent(props: FixContractTableComponentProps) {
  return (
    <React.Fragment>
      <PageTitleViewComponent id={"page.fixcontract.h1"} />
      <Box component="div" overflow="auto" pt={3}>
        <PageSubTitleViewComponent id="page.fixContract.table.title" />
        <Table>
          <StyledTableHeadComponent>
            <StyledTableRowComponent>
              {columnData.map(column => (
                <StyledTableCellTextComponent key={column.id} padding="none">
                  <IntlMessageComponent id={column.translationKey} />
                </StyledTableCellTextComponent>
              ))}
              <StyledTableCellTextComponent padding="checkbox" />
            </StyledTableRowComponent>
          </StyledTableHeadComponent>

          <TableBody bgcolor="white">
            {props.fixContracts.length === 0 &&
              <StyledTableRowComponent>
                <StyledTableCellTextComponent colSpan={columnData.length + 1}>
                  <Box textAlign="center">
                    <IntlMessageComponent id="general.noData" />
                  </Box>
                </StyledTableCellTextComponent>
              </StyledTableRowComponent>
            }

            {props.fixContracts.map(fixContract => (
              <StyledTableRowComponent key={`fixcontract-${fixContract.contractId}`} hover onClick={() => props.selectContract(fixContract)}>
                <StyledTableCellNumbersComponent padding="none">
                  <DateFormatterComponent date={fixContract.startDate} />
                </StyledTableCellNumbersComponent>

                <StyledTableCellNumbersComponent padding="none">
                  <AmountFormatterComponent amount={fixContract.engagedMonthlyFinancedAmount} />
                </StyledTableCellNumbersComponent>

              </StyledTableRowComponent>
            ))}
          </TableBody>
        </Table>
      </Box>
    </React.Fragment >
  );
}
