/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import LoadingComponent from 'components/loading';
import CompanyUsersProcurationViewComponent from './view';

import CompanyUserProcuration from 'models/companies/companyUserProcuration';
import CompanyUserProcurationForm from './model';

import CompanyService from 'services/CompanyService';
import {handleApiFormResponse, handleFormChange, handleFormValidation} from 'lib/forms';
import loadConstraints from 'lib/validation/loadConstraints';
import NotificationHelper from 'lib/notifications';

import {history} from 'store/index';
import {PROTECTED_COMPANY_USERS} from 'constants/pageRoutes';

type CompanyUsersProcurationComponentProps = {
  activeCompanyId: number;
  companyUserId: number;
}

type CompanyUsersProcurationComponentState = {
  constraints: any;
  errors: Map<string, ?string>;
  form: CompanyUserProcurationForm;
  formErrors: string[];
  isLoading: boolean;
  isProcessing: boolean;
  procurations: CompanyUserProcuration;
}

class CompanyUsersProcurationComponent extends React.Component<CompanyUsersProcurationComponentProps, CompanyUsersProcurationComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      constraints: null,
      errors: null,
      form: null,
      formErrors: [],
      isLoading: true,
      isProcessing: false,
      procurations: null
    };
  }

  componentDidMount = async () => {
    try {
      const procurations = await CompanyService.getCompanyUserProcuration(this.props.activeCompanyId, this.props.companyUserId);
      const form = new CompanyUserProcurationForm(procurations);

      const constraints = await loadConstraints('companyUserProcuration');

      this.setState({
        constraints: constraints,
        errors: new Map(Object.keys(constraints).map(e => [e, undefined])),
        form,
        isLoading: false,
        procurations
      });
    } catch (error) {
      console.error(error);
    }
  };

  handleChange = (fieldName: string) => (value: any) => handleFormChange.call(this, fieldName, value);
  validateForm = () => handleFormValidation.call(this);

  submitForm = async () => {
    if (!this.validateForm()) return;
    this.setState({formErrors: [], isProcessing: true});

    try {
      const request = this.state.form.toRequest(this.props.activeCompanyId, this.props.companyUserId);
      await CompanyService.updateCompanyUserProcuration(request);
      NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS, 'notifications.users.procuration.success');
      history.push(PROTECTED_COMPANY_USERS);
    } catch (error) {
      handleApiFormResponse.call(this, error);
    }
  };

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <CompanyUsersProcurationViewComponent
        errors={this.state.errors}
        form={this.state.form}
        formErrors={this.state.formErrors}
        isProcessing={this.state.isProcessing}
        procurations={this.state.procurations}
        handleChange={this.handleChange}
        submitForm={this.submitForm}
      />
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps)(CompanyUsersProcurationComponent);
