/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import IntlMessageComponent from 'components/formatters/intlMessage';
import MandatoryFormFieldsComponent from 'components/form/mandatory';
import ButtonComponent from 'components/buttons/button';
import UploadFileFieldComponent from 'components/formFields/upload/index';
import TooltipComponent from 'components/toolTips/index';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import SupplierCompany from 'models/confirming/supplierCompany';

type ConfirmingAddSupplierTab3ViewComponentProps = {
  supplierCompanyDetails: SupplierCompany;
  isProcessing: boolean;
  document: Document;
  handleDocumentSelect: (file: File) => void;
  handleDocumentSelectFailure: (file: File) => void;
  handleDocumentRemove: () => void;
  submitForm: () => void;
}

export default function ConfirmingAddSupplierTab3ViewComponent(props: ConfirmingAddSupplierTab3ViewComponentProps) {

  return (
    <React.Fragment>
      <PageSubTitleViewComponent id="debtor.addSuppiler.tab3.title" />
      <Box mt={3}>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item xs={12} md={6}>
            <Box>
              <Box pageName="Tooltip.CompanyInfo.BankAccount" elementName={"document." + props.document.code}>
                <TooltipComponent pageName="Tooltip.CompanyInfo.BankAccount" elementName={"document." + props.document.code}>
                  <UploadFileFieldComponent
                    document={props.document}
                    successHandler={props.handleDocumentSelect}
                    failHandler={props.handleDocumentSelectFailure}
                    removeHandler={props.handleDocumentRemove}
                  />
                </TooltipComponent>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box mt={5}>
        <ButtonComponent
          color="primary"
          variant="contained"
          isProcessing={props.isProcessing}
          onClick={props.submitForm}
        ><IntlMessageComponent id="general.continue" /></ButtonComponent>

        <MandatoryFormFieldsComponent />
      </Box>
    </React.Fragment >
  );
}
