/* @flow */

export default class RefuseInvoiceFormModel {
  reason: string;
  reasonFree: String;

  constructor() {
    this.reason = null;
    this.reasonFree = null;
  }
}
