import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';


import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';
import MandatoryFormFieldsComponent from 'components/form/mandatory';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import UploadFileFieldComponent from 'components/formFields/upload/index';
import TooltipComponent from 'components/toolTips/index';

import Document from 'models/documents/v2/document';
import DocumentType from 'models/documents/documentType';

type SubscriptionTab3ViewComponentProps = {
  documents: Document[];
  additionalDocuments: Document[];
  isProcessing: boolean;
  extraDocuments: Document[];
  filteredExtraDocuments: Document[];
  isProcessing: boolean;
  selectedDocumentType: DocumentType;
  toClassifyDocumentType: DocumentType;
  handleDocumentSelect: () => void;
  handleDocumentSelectFailure: () => void;
  handleDocumentRemove: () => void;
  handleAdditionalDocumentSelect: () => void;
  handleAdditionalDocumentSelectFailure: () => void;
  handleAdditionalDocumentRemove: () => void;
  handleDocumentTypeChange: () => void;
  addDocument: () => void;
  submitForm: () => void;
}

export default function SubscriptionTab3ViewComponent(props: SubscriptionTab3ViewComponentProps) {
  return (
    <React.Fragment>
      <PageSubTitleViewComponent id="page.subscription.tab3.h2" data-walkme="subscription-title-documents" />

      <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1} key={`documents`}>
        <Grid item xs={12} md={6}>
          <Box mt={3}>
            {props.documents.map((doc, index) => (doc.isProvided === false || (doc.isProvided && doc.file !== null)) && (
              <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1} key={`row-upload-${index}`}>
                <Grid item xs={10}>
                  <Box mt={1} pageName="Subscription.Tab.Document" elementName={doc.code}>
                    <TooltipComponent pageName="Subscription.Tab.Document" elementName={doc.code} boxWidth={"100%"}>
                      <UploadFileFieldComponent
                        id={doc.code}
                        document={doc}
                        filename={doc.filename}
                        isMissing={doc.isMissing}
                        isRequired={doc.isRequired}
                        isOptional={doc.isOptional}
                        successHandler={props.handleDocumentSelect(index)}
                        failHandler={props.handleDocumentSelectFailure(index)}
                        removeHandler={props.handleDocumentRemove(index)}
                      />
                    </TooltipComponent>
                  </Box>
                </Grid>
              </Grid>
            ))}
          </Box>
          {props.filteredExtraDocuments.length > 0 &&
            <Box mt={5}>
              <FormControl>
                <InputLabel><IntlMessageComponent id="component.company.documentList.header.documenttype.optional" /></InputLabel>
                <Select
                  style={{ minWidth: 286 }}
                  labelId="select-document-type-dropdown-label"
                  id="select-document-type-dropdown"
                  value={props.selectedDocumentType}
                  onChange={props.handleDocumentTypeChange}
                >
                  {props.filteredExtraDocuments.map((doc) => {
                    return (
                      <MenuItem value={doc}><IntlMessageComponent id={`document.type.${doc.code}`} /></MenuItem>
                    );
                  })}
                </Select>
                <Box mt={1}>
                  <ButtonComponent
                    color="secondary"
                    variant="contained"
                    disabled={props.isProcessing}
                    onClick={props.addDocument}
                  ><IntlMessageComponent id="general.button.addDocument" /></ButtonComponent>
                </Box>
              </FormControl>
            </Box>
          }
        </Grid>

        <Grid item xs={12} md={6}>
          <Box mt={3}>
            {props.additionalDocuments.map((doc, index) => (doc.isProvided === false || (doc.isProvided && doc.file !== null)) && (
              <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1} key={`row-upload-${index}`}>
                <Grid item xs={10}>
                  <Box mt={1} pageName="Subscription.Tab.Document" elementName={doc.code}>
                    <TooltipComponent pageName="Subscription.Tab.Document" elementName={doc.code} boxWidth={"100%"}>
                      <UploadFileFieldComponent
                        id={doc.code}
                        document={doc}
                        filename={doc.filename}
                        isMissing={doc.isMissing}
                        isRequired={doc.isRequired}
                        isOptional={doc.isOptional}
                        successHandler={props.handleAdditionalDocumentSelect(index)}
                        failHandler={props.handleAdditionalDocumentSelectFailure(index)}
                        removeHandler={props.handleAdditionalDocumentRemove(index)}
                      />
                    </TooltipComponent>
                  </Box>
                </Grid>
              </Grid>
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box mt={5}>
            <ButtonComponent
              color="primary"
              variant="contained"
              isProcessing={props.isProcessing}
              onClick={props.submitForm}
            ><IntlMessageComponent id="general.continue" /></ButtonComponent>

            <MandatoryFormFieldsComponent />
          </Box>
        </Grid>
      </Grid>


    </React.Fragment>
  );
}