/* @flow */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function IconPaymentDone(props) {
  return (
    <SvgIcon viewBox="0 0 69.29 78.06" {...props}>
      <g>
        <path d="M427.47,317.51l5.88-6.06q3.48-3.6,6.95-7.19c.59-.61,1.22-.67,1.72-.18s.52,1.1-.14,1.78l-13.24,13.68c-.85.87-1.38.87-2.29,0l-7.83-7.19c-.38-.35-.77-.78-.43-1.3a1.63,1.63,0,0,1,1-.73,1.87,1.87,0,0,1,1.14.56c2.22,2,4.4,4.05,6.6,6.07C427,317.17,427.23,317.32,427.47,317.51Z" transform="translate(-386.27 -258.83)"/>
        <path d="M427.51,321.19a2.81,2.81,0,0,1-1.84-.87l-4.12-3.79-3.71-3.4c-1.17-1.08-1-2-.59-2.58a2.5,2.5,0,0,1,1.78-1.18,2.68,2.68,0,0,1,1.87.81c1.55,1.4,3.08,2.81,4.61,4.23l1.88,1.74,8-8.21q2.11-2.19,4.24-4.37a2.34,2.34,0,0,1,1.68-.81,1.81,1.81,0,0,1,1.44.6,2.08,2.08,0,0,1-.11,3.19l-13.25,13.69A2.61,2.61,0,0,1,427.51,321.19ZM419,311.5l.15.16q1.86,1.69,3.71,3.4l4.13,3.78a1.6,1.6,0,0,0,.49.36,5.3,5.3,0,0,0,.4-.35l13.25-13.69a1,1,0,0,0,.22-.29l-.07-.08s-.12,0-.3.17l-4.23,4.37-9.24,9.53-.91-.73c-.18-.14-.34-.26-.49-.4l-2-1.85c-1.53-1.41-3-2.82-4.59-4.21a1.54,1.54,0,0,0-.39-.28Z" transform="translate(-386.27 -258.83)"/>
      </g>
      <path d="M432.53,286.57V273.73a6,6,0,0,0-2.14-3.44q-4.89-4.92-9.73-9.89a4.89,4.89,0,0,0-3.71-1.56c-8.79,0-17.58.06-26.38,0a4,4,0,0,0-4.3,4.25q.11,25.29,0,50.57a3.83,3.83,0,0,0,3.92,4l15.66,0a25.21,25.21,0,1,0,26.68-31Zm-12.38-20.44,5.64,5.64h-5.64Zm-29.87,47.48V262.87h25.85v8.64a4,4,0,0,0,4.28,4.26c2.67-.08,5.34,0,8.08,0v10.8a25.24,25.24,0,0,0-23.35,25.13c0,.65,0,1.29.08,1.93Zm40.07,19.28a21.21,21.21,0,1,1,21.21-21.21A21.23,21.23,0,0,1,430.35,332.89Z" transform="translate(-386.27 -258.83)"/>
    </SvgIcon>
  );
}
