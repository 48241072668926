/* @flow */

export default class InvoiceBills {
  listingFeeBillId: number;
  serviceFeeBillId: number;
  insuranceFeeBillId: number;
  bankCostsBillId: number;
  urgentPaymentFeeBillId: number;
  discountAttestationId: number;
}
