/* @flow */

import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

import FormErrorsComponent from 'components/formErrors';
import IntlMessageComponent from 'components/formatters/intlMessage';

import RefuseInvoiceForm from './model';

type DebtorInvoicesToApproveRefusePopupViewComponentProps = {
  errors: Map<string, ?string>;
  form: RefuseInvoiceForm;
  formErrors: string[];
  isProcessing: boolean;
  handleCancel: () => void;
  handleChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void;
  submitForm: () => void;
}

export default function DebtorInvoicesToApproveRefusePopupViewComponent(props: DebtorInvoicesToApproveRefusePopupViewComponentProps) {
  return (
    <React.Fragment>
      <Dialog open onClose={props.handleCancel}>
        <DialogTitle><IntlMessageComponent id="debtor.approve.refusePopup.title" /></DialogTitle>

        <DialogContent>
          <DialogContentText>
            <IntlMessageComponent id="debtor.approve.refusePopup.text" />
          </DialogContentText>

          <FormErrorsComponent formErrors={props.formErrors} />

          <TextField
            id="form.reason"
            label={<IntlMessageComponent id="form.reason.label" />}
            error={!!props.errors.get('reason')}
            helperText={(props.errors.get('reason')) ? <IntlMessageComponent id={props.errors.get('reason')} /> : ''}
            value={props.form.reason}
            onChange={props.handleChange('reason')}
            fullWidth
            margin="normal"
            required
          />
        </DialogContent>
        
        <DialogActions>
          <Button onClick={props.handleCancel} color="primary">
            <IntlMessageComponent id="debtor.approve.refusePopup.btn.cancel" />
          </Button>
          <Button onClick={props.submitForm} color="primary">
            <IntlMessageComponent id="debtor.approve.refusePopup.btn.confirm" />
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
