/* @flow */

import React from 'react';
import {connect} from 'react-redux';

type CompanyStatusComponentProps = {
  id: number;
  values: {id: number, name: string}[];
}

class CompanyStatusComponent extends React.Component<CompanyStatusComponentProps, {}> {

  render() {
    if (this.props.isLoading) return null;
    const value = this.props.values.find(v => v.id === this.props.id);
    if (value) return (<span>{value.name}</span>);
    return (<span>{this.props.id}</span>);
  }
}

const mapStateToProps = state => ({
  isLoading: state.locale.genericDataIsLoading,
  values: state.locale.companyStatus,
});

export default connect(mapStateToProps)(CompanyStatusComponent);
