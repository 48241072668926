/* @flow */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function IconMail(props) {
  return (
    <SvgIcon viewBox="0 0 81.72 57.22" {...props}>
      <path d="M383.6,271.38a7.55,7.55,0,0,0-3.49,6.66c-.07,12.68-.07,26,0,39.67,0,4.85,4.13,8.68,9.35,8.72,4,0,7.92,0,11.87,0h50.94c4.59,0,7.87-2.35,9.24-6.6a4.22,4.22,0,0,0,.23-1.26q0-10,0-20,0-10.38,0-20.76a8.82,8.82,0,0,0-7.79-8.25,4.4,4.4,0,0,1-.92-.24l-.16-.05h-64c-.54.21-1.1.4-1.66.59A18,18,0,0,0,383.6,271.38Zm69.48,2.7-5.21,4.09c-2.24,1.76-4.39,3.45-6.55,5.13l-5.39,4.22-13.3,10.42a2.91,2.91,0,0,1-1.75.78,2.36,2.36,0,0,1-1.49-.59q-10.51-8.33-21.08-16.58l-6.53-5.13c-.12-.09-.23-.2-.41-.36l-2.13-2ZM394.17,284.2l24.41,19.18c1.82,1.43,2.88,1.43,4.7,0l31-24.25c.23-.19.47-.35.77-.56l1.82-1.29.18,1.64q0,.26.06.48a6.25,6.25,0,0,1,.08.92q0,18.07,0,36.15c0,3.82-1.62,5.45-5.41,5.45H390.06c-3.47,0-5.16-1.68-5.16-5.13V276.92Z" transform="translate(-380.05 -269.24)"/>
    </SvgIcon>
  );
}
