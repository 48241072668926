/* @flow */

import CommissionMembershipIsoCompany from './commissionMembershipIsoCompany';

export default class CommissionMembershipData {
  companies: CommissionMembershipIsoCompany[];
  membershipFeeCouponAmountUsed: number;
  membershipFee: number;
  membershipCommission: number;

  constructor() {
    this.companies = [];
    this.membershipFeeCouponAmountUsed = 0;
    this.membershipFee = 0;
    this.membershipCommission = 0;
  }
}
