/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import LoadingComponent from 'components/loading';

import RegisterLeadTab4ViewComponent from './view';

import Document from 'models/documents/document';

import DocumentService from 'services/DocumentService';
import {handleApiFormResponse} from 'lib/forms';
import NotificationHelper from 'lib/notifications';

import {ISO_REGISTER_LEAD_DOCUMENT_POST} from 'constants/apiRoutes';

type RegisterLeadTab4ComponentProps = {
  activeCompanyId: number;
  companyId: number;
  successHandler: () => void;
  skipHandler: () => void;
};

type RegisterLeadTab4ComponentState = {
  documentId: number;
  documents: Document[];
  isProcessing: boolean;
};

class RegisterLeadTab4Component extends React.Component<RegisterLeadTab4ComponentProps, RegisterLeadTab4ComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      documentId: 1,
      documents: [new Document(null, false)],
      isProcessing: false
    };
  }

  addDocument = () => {
    const documents = this.state.documents;
    const document = new Document(null, false);
    document.id = this.state.documentId;
    documents.push(document);
    this.setState(prevState => ({documentId: prevState.documentId + 1, documents}));
  }

  handleDocumentSelect = (documentId: number) => (file: File) => {
    const documents = this.state.documents;
    const document = documents.find(d => d.id === documentId);
    if (!document) return;
    document.file = file;
    document.filename = file.name;
    document.isMissing = false;   
    this.setState({documents});
  };

  handleDocumentRemove = (documentId: number) => () => {
    let documents = this.state.documents;
    const document = documents.find(d => d.id === documentId);
    if (!document) return;
    documents = documents.filter(d => d.id !== documentId);
    this.setState({documents});
  };

  submitForm = async () => {
    if (!this.validate()) return;

    // add documents
    const addPromises = [];
    this.state.documents.forEach(document => {
      if (document.file !== null) {
        addPromises.push(DocumentService.sendDocument2(ISO_REGISTER_LEAD_DOCUMENT_POST, document.file, {isoCompanyId: this.props.activeCompanyId, companyId: this.props.companyId}));
      }
    });

    // if nothing, do nothing
    if (addPromises.length === 0) {
      this.props.successHandler();
      return;
    }

    this.setState({isProcessing: true});

    try {
      await Promise.all(addPromises);
      NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS);
      this.props.successHandler();
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }
  }

  validate = () => {
    let isValid = true;
    const documents = this.state.documents;

    documents.forEach(document => {
      if (document.file === null) {
        // not yet provided and missing -> issue
        document.isMissing = true;
        isValid = false;
      }
    });

    if (!isValid) this.setState({documents});
    return isValid;
  }

  render = () => {
    if (this.state.isLoading) return (<LoadingComponent />);

    return (
      <RegisterLeadTab4ViewComponent
        documents={this.state.documents}
        isProcessing={this.state.isProcessing}
        handleDocumentSelect={this.handleDocumentSelect}
        handleDocumentRemove={this.handleDocumentRemove}
        addDocument={this.addDocument}
        skip={this.props.skipHandler}
        submitForm={this.submitForm}
      />
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps)(RegisterLeadTab4Component);
