/* @flow */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function IconArrowUpFilled(props) {
  return (
    <SvgIcon viewBox="0 0 64.54 64.54" {...props}>
      <g>
        <path d="M421.23,265.26a32.27,32.27,0,1,0,32.27,32.27A32.3,32.3,0,0,0,421.23,265.26Zm0,62.54a30.27,30.27,0,1,1,30.27-30.27A30.27,30.27,0,0,1,421.23,327.8Z" transform="translate(-388.96 -265.26)"/>
        <path d="M421.23,269.9a27.64,27.64,0,1,0,27.63,27.63A27.64,27.64,0,0,0,421.23,269.9ZM437.5,293a18.26,18.26,0,0,1-1.79,1.93c-.56.47-1.19.32-1.76-.25-3-3-5.94-6-8.92-8.94-.2-.2-.41-.38-.75-.69v1c0,9.11,0,18.22,0,27.33a2.86,2.86,0,0,1-1.58,3H421.6a2.86,2.86,0,0,1-1.58-3c0-9.15,0-18.31,0-27.47v-1c-.33.3-.54.48-.74.68l-9,9c-1.05,1-1.75,1.07-2.81,0a10.83,10.83,0,0,1-1-1.4v-.8c4.17-4.16,8.33-8.33,12.53-12.47.79-.78,1.71-1.44,2.57-2.15h1.12a13.33,13.33,0,0,1,1.33,1.05q6.74,6.69,13.45,13.41Z" transform="translate(-388.96 -265.26)"/>
      </g>
    </SvgIcon>
  );
}
