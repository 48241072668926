/* @flow */

export default class UpdateCompanyUserProcurationRequest {
  companyId: number;
  companyUserId: number;

  hasProcurationAsBuyer: boolean;
  hasProcurationAsDebtor: boolean;
  hasProcurationAsISO: boolean;
  hasProcurationAsSeller: boolean;
  hasProcurationUsers: boolean;
  hasProcurationGeneralConditions: boolean;
}
