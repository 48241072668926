/* @flow */

import React, {useState} from 'react';

import Box from '@material-ui/core/Box';

import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';

import CompanyLookupBaseComponent from './index';

type CompanyLookupBaseWithButtonComponentProps = {
  companyErrorLabelId: string;
  countries: CountryModel[];
  required: boolean;
  buttonLabel: string;
  handleSelect: (company: CompanyModel) => void;
}

export default function CompanyLookupBaseWithButtonComponent(props: CompanyLookupBaseWithButtonComponentProps) {
  const [display, setDisplay] = useState(false);

  if (display) {
    return (
      <CompanyLookupBaseComponent
        companyErrorLabelId={props.companyErrorLabelId}
        countries={props.countries}
        required={props.required}
        handleSelect={props.handleSelect}
      />
    );
  }

  return (
    <Box px={4} py={3} display="flex" alignItems="center" justifyContent="center" height="100%">
      <ButtonComponent
        color="primary"
        variant="contained"
        onClick={() => setDisplay(true)}
      ><IntlMessageComponent id={props.buttonLabel ? props.buttonLabel : 'component.companyLookup.btn.new'} /></ButtonComponent>
    </Box>
  )
}