/* @flow */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function IconBell(props) {
  return (
    <SvgIcon viewBox="0 0 52.71 59.25" {...props}>
      <path d="M420.3,327.25c-1.62-.75-3.55-1.18-4.77-2.35a12.52,12.52,0,0,1-3-5c-.4-1.17-.83-1.37-1.84-1.36-3.87,0-7.75,0-11.62,0-3,0-4-1.09-4.11-4-.12-4.92.95-6.39,6.15-8.35,0-3.64,0-7.34,0-11,0-9,4-15.54,12.13-19.49a2.91,2.91,0,0,0,1.12-1.82,7.2,7.2,0,0,1,5.93-5.82c3.36-.39,6.59,1,7.36,4.18.67,2.77,2.41,3.77,4.47,5.07,6.18,3.91,9.23,9.7,9.39,17,.08,4,0,8,0,12.06,3.34.28,5.2,2.2,6.14,5.16v4.79a4,4,0,0,1-4.13,2.3c-3.87-.12-7.74,0-11.61-.05-1,0-1.45.21-1.83,1.39a11,11,0,0,1-2.74,4.74c-1.33,1.22-3.31,1.74-5,2.57Zm-2.13-49.05c-9.65,2.65-13.63,10.08-13.18,18.67.17,3.18.06,6.38,0,9.57a3.63,3.63,0,0,1-3.17,3.83c-2.79.69-3.07,1.14-2.53,4.21h44.17c.32-3.28.19-3.47-2.76-4.25a3.62,3.62,0,0,1-3-3.78c-.06-3.94.05-7.87-.05-11.8-.17-6.62-3-11.7-8.95-14.86a38.82,38.82,0,0,0-4.19-1.66c-.31-4.94-.91-6.1-3.17-6.17S418.46,273.23,418.17,278.2Zm-1.73,40.44a4.85,4.85,0,0,0,5.29,4.49,4.54,4.54,0,0,0,4.4-4.49Z" transform="translate(-394.96 -268)"/>
    </SvgIcon>
  );
}
