/* @flow */

import moment from 'moment';

import CompanyUserDetails from 'models/companies/companyUserDetails';

import AddCompanyUserRequest from 'models/requests/companies/users/addCompanyUserRequest';
import AddCompanyUserWithOfficeRequest from 'models/requests/companies/users/addCompanyUserWithOfficeRequest';
import UpdateCompanyUserRequest from 'models/requests/companies/users/updateCompanyUserRequest';
import UpdateCompanyUserWithOfficeRequest from 'models/requests/companies/users/updateCompanyUserWithOfficeRequest';

export default class CompanyUserForm {
  companyUserId: number;
  titleId: number;
  lastName: string;
  firstName: string;
  email: string;
  phone: string;
  mobile: string;
  addressLine1: string;
  addressLine2: string;
  zipCode: string;
  city: string;
  countryId: number;
  nationalityId: number;
  birthCountryId: number;
  birthDate: moment;
  birthPlace: string;
  registrationTypeId: number;
  registrationNumber: string;
  registrationExpireDate: moment;
  languageId: number;
  functionName: string;
  isAdministrator: boolean;
  officeId: number;
  isoRoleId: number;
  debtorRoleId: number;
  buyerRoleId: number;
  sellerRoleId: number;
  confirmingRoleId: number;
  isoCommissionEmailTypeId: number;

  idFront: File;
  idBack: File;

  constructor(companyUserDetails: CompanyUserDetails) {
    if (!companyUserDetails) {
      this.companyUserId = 0;
      this.titleId = 0;
      this.lastName = '';
      this.firstName = '';
      this.email = '';
      this.phone = '';
      this.mobile = '';
      this.addressLine1 = '';
      this.addressLine2 = '';
      this.zipCode = '';
      this.city = '';
      this.countryId = 0;
      this.nationalityId = 0;
      this.birthCountryId = 0;
      this.birthDate = moment.utc().subtract(40, 'y').startOf('d');
      this.birthPlace = '';
      this.registrationTypeId = 0;
      this.registrationNumber = '';
      this.registrationExpireDate = moment.utc().add(1, 'd').startOf('d');
      this.languageId = 0;
      this.functionName = '';
      this.isAdministrator = false;
      this.officeId = 0;
      this.isoRoleId = 0;
      this.debtorRoleId = 0;
      this.buyerRoleId = 0; 
      this.sellerRoleId = 0;
      this.confirmingRoleId = 0;
    } else {
      this.companyUserId = companyUserDetails.companyUserId;
      this.lastName = companyUserDetails.lastName;
      this.firstName = companyUserDetails.firstName;
      this.email = companyUserDetails.email;
      this.functionName = companyUserDetails.functionName;
      this.isAdministrator = companyUserDetails.isAdministrator;
      this.officeId = companyUserDetails.officeId;
      this.isoRoleId = companyUserDetails.isoRoleId;
      this.debtorRoleId = companyUserDetails.debtorRoleId;
      this.buyerRoleId = companyUserDetails.buyerRoleId;
      this.sellerRoleId = companyUserDetails.sellerRoleId;
      this.confirmingRoleId = companyUserDetails.confirmingRoleId;
      this.isoCommissionEmailTypeId = companyUserDetails.isoCommissionEmailTypeId;
    }
    this.idFront = null;
    this.idBack = null;
  }

  toAddCompanyUserRequest(companyId: number) {
    const request = new AddCompanyUserRequest();
    request.companyId = companyId;
    request.titleId = this.titleId;
    request.lastName = this.lastName;
    request.firstName = this.firstName;
    request.email = this.email;
    request.phone = this.phone;
    request.mobile = this.mobile;
    request.addressLine1 = this.addressLine1;
    request.addressLine2 = this.addressLine2;
    request.zipCode = this.zipCode;
    request.city = this.city;
    request.countryId = this.countryId;
    request.nationalityId = this.nationalityId;
    request.birthCountryId = this.birthCountryId;
    request.birthDate = moment(this.birthDate).format('YYYY/MM/DD');
    request.birthPlace = this.birthPlace;
    request.registrationTypeId = this.registrationTypeId;
    request.registrationNumber = this.registrationNumber;
    request.registrationExpireDate = moment(this.registrationExpireDate).format('YYYY/MM/DD');
    request.languageId = this.languageId;
    request.functionName = this.functionName;
    request.isAdministrator = this.isAdministrator;
    return request;
  }

  toAddCompanyUserWithOfficeRequest(companyId: number) {
    const request = new AddCompanyUserWithOfficeRequest();
    request.companyId = companyId;
    request.titleId = this.titleId;
    request.lastName = this.lastName;
    request.firstName = this.firstName;
    request.email = this.email;
    request.phone = this.phone;
    request.mobile = this.mobile;
    request.addressLine1 = this.addressLine1;
    request.addressLine2 = this.addressLine2;
    request.zipCode = this.zipCode;
    request.city = this.city;
    request.countryId = this.countryId;
    request.nationalityId = this.nationalityId;
    request.birthCountryId = this.birthCountryId;
    request.birthDate = moment(this.birthDate).format('YYYY/MM/DD');
    request.birthPlace = this.birthPlace;
    request.registrationTypeId = this.registrationTypeId;
    request.registrationNumber = this.registrationNumber;
    request.registrationExpireDate = moment(this.registrationExpireDate).format('YYYY/MM/DD');
    request.languageId = this.languageId;
    request.functionName = this.functionName;
    request.isAdministrator = this.isAdministrator;
    request.debtorRoleId = this.debtorRoleId !== 0 ? this.debtorRoleId : null;
    request.isoRoleId = this.isoRoleId !== 0 ? this.isoRoleId : null;
    request.officeId = this.officeId !== 0 ? this.officeId : null;
    request.buyerRoleId = this.buyerRoleId !== 0 ? this.buyerRoleId : null;
    request.sellerRoleId = this.sellerRoleId !== 0 ? this.sellerRoleId : null;
    request.confirmingRoleId = this.confirmingRoleId !== 0 ? this.confirmingRoleId : null;
    request.isoCommissionEmailTypeId = this.isoCommissionEmailTypeId !== 0 ? this.isoCommissionEmailTypeId : null;
    return request;
  }

  toUpdateCompanyUserRequest(companyId: number) {
    const request = new UpdateCompanyUserRequest();
    request.companyId = companyId;
    request.companyUserId = this.companyUserId;
    request.functionName = this.functionName;
    request.isAdministrator = this.isAdministrator;
    return request;
  }

  toUpdateCompanyUserWithOfficeRequest(companyId: number) {
    const request = new UpdateCompanyUserWithOfficeRequest();
    request.companyId = companyId;
    request.companyUserId = this.companyUserId;
    request.functionName = this.functionName;
    request.isAdministrator = this.isAdministrator;
    request.debtorRoleId = this.debtorRoleId !== 0 ? this.debtorRoleId : null;
    request.isoRoleId = this.isoRoleId !== 0 ? this.isoRoleId : null;
    request.officeId = this.officeId !== 0 ? this.officeId : null;
    request.buyerRoleId = this.buyerRoleId !== 0 ? this.buyerRoleId : null;
    request.sellerRoleId = this.sellerRoleId !== 0 ? this.sellerRoleId : null;
    request.confirmingRoleId = this.confirmingRoleId !== 0 ? this.confirmingRoleId : null;
    request.isoCommissionEmailTypeId = this.isoCommissionEmailTypeId !== 0 ? this.isoCommissionEmailTypeId : null;
    return request;
  }
}
