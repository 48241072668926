/* @flow */

import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';

import LoadingComponent from 'components/loading';

import RegisterSellerCompanyTab3ViewComponent from './view';
import CompanyUserInfoForm from './model';

import IsoService from 'services/IsoService';
import {handleApiFormResponse, handleFormChange, handleFormValidation} from 'lib/forms';
import loadConstraints from 'lib/validation/loadConstraints';

type RegisterSellerCompanyTab3ComponentProps = {
  activeCompanyId: number,
  companyId: number,
  companyPersonId: number,
  successHandler: () => number
};

type RegisterSellerCompanyTab3ComponentState = {
  constraints: any;
  errors: Map<string, ?string>,
  form: CompanyUserInfoForm,
  formErrors: string[],
  isLoading: boolean,
  isProcessing: boolean,
  maxDateBirth: moment,
  minDateBirth: moment,
  maxRegistrationExpireDate: moment,
  minRegistrationExpireDate: moment
};

class RegisterSellerCompanyTab3Component extends React.Component<RegisterSellerCompanyTab3ComponentProps, RegisterSellerCompanyTab3ComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      constraints: null,
      errors: null,
      form: null,
      formErrors: [],
      isLoading: true,
      isProcessing: false,
      maxDateBirth: moment().subtract(18, 'y').startOf('d'),
      minDateBirth: moment('01-01-1900', 'MM-DD-YYYY'),
      maxRegistrationExpireDate: moment().add(100, 'y').startOf('d'),
      minRegistrationExpireDate: moment().add(1, 'd').startOf('d')
    };
  }

  componentDidMount = async () => {
    try {
      const constraints = await loadConstraints('Person', ['titleId', 'lastName', 'firstName', 'email',
        'phone', 'mobile', 'addressLine1', 'addressLine2', 'zipCode', 'city', 'countryId', 'birthCountryId', 'birthDate', 'birthPlace',
        'nationalityId', 'registrationTypeId', 'registrationNumber', 'registrationExpireDate', 'functionName', 'languageId']);

      const companyUserInfo = await IsoService.getCompanyUserInfo(this.props.activeCompanyId, this.props.companyId, this.props.companyPersonId);

      this.setState({
        constraints,
        errors: new Map(Object.keys(constraints).map(e => [e, undefined])),
        form: new CompanyUserInfoForm(companyUserInfo),
        isLoading: false
      });
    } catch (e) {
      console.error(e);
    }
  };

  handleChange = (fieldName: string) => (event) => handleFormChange.call(this, fieldName, event.target.value);
  handleDateChange = (fieldName: string) => (date) => handleFormChange.call(this, fieldName, date);
  handleTypeChange = (fieldName: string) => (id: number) => handleFormChange.call(this, fieldName, id);
  handlePhoneChange = (value: string) => {
    handleFormChange.call(this, 'phone', value);
  }
  handleMobileChange = (value: string) => {
    handleFormChange.call(this, 'mobile', value);
  }
  
  validateForm = () => handleFormValidation.call(this);

  submitForm = async () => {
    if (!this.validateForm()) return;
    this.setState({formErrors: [], isProcessing: true});

    try {
      const request = this.state.form.toRequest(this.props.activeCompanyId, this.props.companyId, this.props.companyPersonId);
      await IsoService.setCompanyUserInfo(request);
      this.props.successHandler();
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }
  };

  render = () => {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <RegisterSellerCompanyTab3ViewComponent
        errors={this.state.errors}
        form={this.state.form}
        formErrors={this.state.formErrors}
        isProcessing={this.state.isProcessing}
        maxDateBirth={this.state.maxDateBirth}
        minDateBirth={this.state.minDateBirth}
        maxRegistrationExpireDate={this.state.maxRegistrationExpireDate}
        minRegistrationExpireDate={this.state.minRegistrationExpireDate}
        handleChange={this.handleChange}
        handleDateChange={this.handleDateChange}
        handleTypeChange={this.handleTypeChange}
        handlePhoneChange={this.handlePhoneChange}
        handleMobileChange={this.handleMobileChange}
        submitForm={this.submitForm}
      />
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps)(RegisterSellerCompanyTab3Component);
