/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import LoadingComponent from 'components/loading';

import SubscriptionTabLegalFormViewComponent from './view';

import PersonLegalInfoForm from './model';

import RegisterService from 'services/registerService';
import {handleApiFormResponse, handleFormChange, handleFormValidation} from 'lib/forms';
import loadConstraints from 'lib/validation/loadConstraints';

import NotificationHelper from 'lib/notifications';

type SubscriptionTab5PersonLegalFormComponentProps = {
  activeCompanyId: number;
  successHandler: (isAdministrator: boolean) => void;
}

type SubscriptionTab5PersonLegalFormComponentState = {
  constraints: any;
  errors: Map<string, ?string>;
  form: PersonLegalInfoForm;
  formErrors: string[];
  isLoading: boolean;
  isProcessing: boolean;
}

class SubscriptionTab5PersonLegalFormComponent extends React.Component<SubscriptionTab5PersonLegalFormComponentProps, SubscriptionTab5PersonLegalFormComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      constraints: null,
      errors: null,
      form: null,
      formErrors: [],
      isLoading: true,
      isProcessing: false
    };
  }

  componentDidMount = async () => {
    try {
      // Load constraints
      const constraints = await loadConstraints('Person', ['isAdministrator']);

      this.setState({
        constraints,
        errors: new Map(Object.keys(constraints).map(e => [e, undefined])),
        form: new PersonLegalInfoForm(),
        isLoading: false
      });
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  };

  handleBooleanChange = (fieldName: string) => (event) => handleFormChange.call(this, fieldName, event.target.value === 'true');
  validateForm = () => handleFormValidation.call(this);

  submitForm = async () => {
    if (!this.validateForm()) return;
    this.setState({formErrors: [], isProcessing: true});

    try {
      const request = this.state.form.toRequest(this.props.activeCompanyId);
      await RegisterService.setPersonLegalInfo(request);
      this.props.successHandler(this.state.form.isAdministrator);
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }
  };

  render = () => {
    if (this.state.isLoading) return <LoadingComponent />;

    return (
      <SubscriptionTabLegalFormViewComponent
        errors={this.state.errors}
        form={this.state.form}
        formErrors={this.state.formErrors}
        isProcessing={this.state.isProcessing}
        handleBooleanChange={this.handleBooleanChange}
        submitForm={this.submitForm}
      />
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps)(SubscriptionTab5PersonLegalFormComponent);
