/* @flow */

import moment from 'moment';

export default class PresentationRequest {
  companyId: number;
  roleId: number;
  presentationDate: moment;

  constructor(companyId: number, roleId: number, presentationDate: moment) {
    this.companyId = companyId;
    this.roleId = roleId;
    this.presentationDate = presentationDate;
  }
}
