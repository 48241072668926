/* @flow */

import DebtorCompany from 'models/companies/debtorCompany';

import SetBusinessPotentialRequest from 'models/requests/isos/registerLeads/setBusinessPotentialRequest';

export default class BusinessPotentialForm {
  debtorCompany: DebtorCompany;
  avgInvoiceAmount: number;
  debtorAge: number;
  maxInvoiceAmount: number;
  minInvoiceAmount: number;
  nbrInvoices: number;
  paymentTerms: string;
  yearlyAmount: number;

  constructor() {
    this.debtorCompany = null;
    this.avgInvoiceAmount = null;
    this.debtorAge = null;
    this.maxInvoiceAmount = null;
    this.minInvoiceAmount = null;
    this.nbrInvoices = null;
    this.paymentTerms = '';
    this.yearlyAmount = null;
  }

  toRequest(isoCompanyId: number, companyId: number) {
    const request = new SetBusinessPotentialRequest();
    request.isoCompanyId = isoCompanyId;
    request.companyId = companyId;
    request.debtorCompanyId = this.debtorCompany.id;
    request.avgInvoiceAmount = this.avgInvoiceAmount;
    request.debtorAge = this.debtorAge;
    request.maxInvoiceAmount = this.maxInvoiceAmount;
    request.minInvoiceAmount = this.minInvoiceAmount;
    request.nbrInvoices = this.nbrInvoices;
    request.paymentTerms = this.paymentTerms;
    request.yearlyAmount = this.yearlyAmount;
    return request;
  }
}
