/* @flow */

import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';

import LoadingComponent from 'components/loading';
import SellInvoiceTab2ViewComponent from './view';

import DebtorCompany from 'models/companies/debtorCompany';
import InvoiceDraft from 'models/invoices/seller/invoiceDraft';
import InvoiceToSellInfo from 'models/invoices/seller/invoiceInfo';
import LeadOffer from 'models/leads/leadOffer';
import SellInvoiceForm from './model';

import InvoiceSellerService from 'services/InvoiceSellerService';
import {handleApiFormResponse, handleFormChange, handleFormValidation} from 'lib/forms';
import loadConstraints from 'lib/validation/loadConstraints';

type SellInvoiceTab2ComponentProps = {
  activeCompanyId: number;
  debtorCompanyId: number;
  invoiceId: number;
  leadOffer: LeadOffer;
  successHandler: (debtorCompany: DebtorCompany, invoiceId: number) => void;
  cancelInvoice: () => void;
}

type SellInvoiceTab2ComponentState = {
  constraints: null;
  errors: Map<string, ?string>;
  form: SellInvoiceForm;
  formErrors: string[];
  invoiceInDB: InvoiceDraft;
  invoiceInfo: InvoiceToSellInfo;
  isLoading: boolean;
  isProcessing: boolean;
}

class SellInvoiceTab2Component extends React.Component<SellInvoiceTab2ComponentProps, SellInvoiceTab2ComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      constraints: null,
      errors: null,
      form: null,
      formErrors: [],
      invoiceInDB: null,
      invoiceInfo: null,
      isLoading: true,
      isProcessing: false
    };
  }

  componentDidMount = async () => {
    // Load defaults & constraint values for that invoice
    const invoiceInfo = await InvoiceSellerService.getInvoiceInfo(this.props.activeCompanyId, this.props.debtorCompanyId);
    
    // Load constraints & set values
    const constraints = await loadConstraints('Invoice', ['debtAmount', 'reference', 'dueDate', 'issueDate']);
    constraints.debtAmount.numericality.greaterThanOrEqualTo = invoiceInfo.minDebtAmount;
    constraints.dueDate.dateInMoreThanXDays.days = invoiceInfo.maxDaysToSell;
    constraints.dueDate.dateInLessThanXDays.days = invoiceInfo.minDaysToSell;

    // Initialize invoiceForm
    const form = new SellInvoiceForm();
    let invoiceInDB = null;

    // Existing invoice
    if (this.props.invoiceId > 0) {
      // load the invoice
      invoiceInDB = await InvoiceSellerService.getInvoiceDraft(this.props.invoiceId);
      
      // fill the form
      form.debtorCompany = invoiceInDB.debtorCompany;
      form.reference = invoiceInDB.reference;
      form.debtAmount = invoiceInDB.debtAmount;
      form.dueDate = invoiceInDB.dueDate;
      form.issueDate = invoiceInDB.issueDate;
    }
    else {
      // New invoice
      if (this.props.leadOffer) {
        // get values from leadOffer
        form.debtorCompany = this.props.leadOffer.debtorCompany;
        form.debtAmount = this.props.leadOffer.debtAmount;
        form.dueDate = this.props.leadOffer.dueDate;
      } else {
        // get values from default
        form.debtorCompany = invoiceInfo.debtorCompany;
        form.dueDate = moment.utc().add(invoiceInfo.minDaysToSell, 'd').startOf('day');
      }

      // set default values
      form.issueDate = moment.utc().startOf('day');
    }

    this.setState({
      constraints,
      errors: new Map(Object.keys(constraints).map(e => [e, undefined])),
      form,
      invoiceInDB,
      invoiceInfo,
      isLoading: false
    });
  }

  handleChange = (fieldName: string) => (event) => handleFormChange.call(this, fieldName, event.target.value);
  handleDateChange = (fieldName: string) => (date) => handleFormChange.call(this, fieldName, date);
  validateForm = () => handleFormValidation.call(this);

  submitForm = async () => {
    if (!this.validateForm()) return;
    this.setState({isProcessing: true});

    try {
      let invoiceId = 0;
      if (this.state.invoiceInDB === null) {
        const request = this.state.form.toInsertRequest(this.props.activeCompanyId);
        invoiceId = await InvoiceSellerService.insertInvoice(request);
      } else if (this.mustUpdateInvoice()) {
        const request = this.state.form.toUpdateRequest(this.props.activeCompanyId, this.state.invoiceInDB.id);
        invoiceId = await InvoiceSellerService.updateInvoice(request);
      } else {
        invoiceId = this.state.invoiceInDB.id;
      }
      this.props.successHandler(this.state.form.debtorCompany, invoiceId);
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }
  }

  // Prevent update call if no modifications
  mustUpdateInvoice = () => {
    if (this.state.invoiceInDB.reference !== this.state.form.reference) return true;
    if (this.state.invoiceInDB.debtAmount !== this.state.form.debtAmount) return true;
    if (this.state.invoiceInDB.dueDate !== this.state.form.dueDate) return true;
    if (this.state.invoiceInDB.issueDate !== this.state.form.issueDate) return true;
    return false;
  }

  cancelInvoice = () => {
    this.setState({isProcessing: true});
    this.props.cancelInvoice();
  }

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <SellInvoiceTab2ViewComponent
        errors={this.state.errors}
        form={this.state.form}
        formErrors={this.state.formErrors}
        invoiceInfo={this.state.invoiceInfo}
        isProcessing={this.state.isProcessing}
        cancelInvoice={this.cancelInvoice}
        handleChange={this.handleChange}
        handleDateChange={this.handleDateChange}
        submitForm={this.submitForm}
      />
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps)(SellInvoiceTab2Component);
