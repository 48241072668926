/* @flow */

import AddCompanyBankAccountRequest from 'models/requests/companies/addCompanyBankAccountRequest';

export default class BankAccountFormModel {
  bankCountryId: number;
  bankIdentifier: string;
  bankAccount: string;
  isMain: boolean;

  constructor() {
    this.bankAccount = '';
    this.bankIdentifier = '';
    this.bankCountryId = 0;
    this.isMain = false;
  }

  toRequest(companyId: number) {
    const request = new AddCompanyBankAccountRequest();
    request.companyId = companyId;
    request.bankAccount = this.bankAccount;
    request.bankIdentifier = this.bankIdentifier;
    request.bankCountryId = this.bankCountryId;
    request.isMain = this.isMain;
    return request;
  }
}
