/* @flow */

import {plainToClass} from 'class-transformer';

import BaseService from './api/baseService';

import {
  GENERIC_GET_EVERYTHING,
  GENERIC_GET_WEBSITE_LANGUAGES
} from 'constants/apiRoutes';

import Country from 'models/generics/country';
import Language from 'models/generics/language';
import SecurityMenu from 'models/generics/securityMenu';
import WebsiteLanguage from 'models/generics/websiteLanguage';

export default class GenericService {

  static getGenericData = () => 
    BaseService.anonymousGET(GENERIC_GET_EVERYTHING,
    data => {
      const fullResp = {};
      Object.keys(data).forEach((key) => {
        // Define target object
        let objectType;
        if (['countries', 'countriesForDebtors', 'countriesForSellers', 'countriesForISOs'].includes(key)) {
          objectType = Country;
        } else if (key === 'languages') {
          objectType = Language;
        } else if (key === 'securityMenus') {
          objectType = SecurityMenu;
        } else if (key === 'websiteLanguages') {
          objectType = WebsiteLanguage;
        }

        Object.defineProperty(fullResp, key, { enumerable: true, value: objectType ? plainToClass(objectType, data[key]) : data[key]});
      });
      return fullResp;
    });

  static getWebsiteLanguages = () =>
    BaseService.anonymousGET(GENERIC_GET_WEBSITE_LANGUAGES,
    data => data.map(d => plainToClass(WebsiteLanguage, d)));
}
