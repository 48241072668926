/* @flow */

export default class SaveIsoLeadNoteRequest {
    companyId: number;
    leadId: number;
    note: string;
  
    constructor(companyId: number, leadId: number, note: string) {
      this.companyId = companyId;
      this.leadId = leadId;
      this.note = note;
    }
  }
  