/* @flow */

import moment from 'moment';
import DebtorCompany from 'models/companies/debtorCompany';

export default class InvoiceDraft {
  id: number;

  debtorCompany: DebtorCompany;

  reference: string;

  debtAmount: number;

  dueDate: moment;
  issueDate: moment;
}
