/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import Box from '@material-ui/core/Box';

import BlogComponent from 'components/blogs';

import IsoKPIsComponent from './kpis';
import ISOProfileOverviewComponent from './profileOverview';
import ISOProfileSummaryComponent from './profileSummary';

type ISODashboardComponentProps = {
  languageId: number;
}

class ISODashboardComponent extends React.Component<ISODashboardComponentProps, {}> {
  render() {
    return (
      <React.Fragment>
        <ISOProfileSummaryComponent />

        <ISOProfileOverviewComponent />
        
        <Box mt={5}>
          <IsoKPIsComponent />
        </Box>

        <Box mt={5}>
          <BlogComponent key={`blogs-${this.props.languageId}`} />
        </Box>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  languageId: state.locale.languageId
});

export default connect(mapStateToProps)(ISODashboardComponent);
