/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';
import SecurityComponent from 'components/security/index';

import CompanyUBOForm from './model';
import CompanyUBO from 'models/companies/companyUBO';

type CompanyUBOsFormSelectionViewComponentProps = {
  possibleUbos: CompanyUBO[];
  selectedUbo: CompanyUBO;
  useSelection: boolean;
  cancel: () => void;
  submitForm: () => void;
  handleSelectedUboChange: () => void;
  handleUseSelectionChange: () => void;
}

export default function CompanyUBOsFormSelectionViewComponent(props: CompanyUBOsFormSelectionViewComponentProps) {
  return (
    <React.Fragment>
      <Paper elevation={0}>
        <Box p={3}>
          <IntlMessageComponent id="companies.ubos.addexistingcontact" />
          <RadioGroup
            onChange={props.handleUseSelectionChange}
            row
            value={props.useSelection}
          >
            <FormControlLabel
              control={<Radio color="primary" />}
              label={<IntlMessageComponent id="general.label.yes" />}
              value={true}
            />
            <FormControlLabel
              control={<Radio color="primary" />}
              label={<IntlMessageComponent id="general.label.no" />}
              value={false}
            />
          </RadioGroup>

          <Box>
            <Select
              value={props.selectedUbo}
              onChange={props.handleSelectedUboChange}
              style={{ width: 400 }}
              disabled={!props.useSelection}
            >
              {props.possibleUbos.map(ubo => (
                <MenuItem value={ubo.id}>
                  {ubo.firstName + " " + ubo.lastName + " (" + (ubo.email !== "" && ubo.email !== null ? ubo.email : "-") + ")"}
                </MenuItem>
              ))}
            </Select>
          </Box>

          <Box mt={5}>
            <ButtonComponent
              color="default"
              variant="contained"
              onClick={props.cancel}
            ><IntlMessageComponent id="general.cancel" /></ButtonComponent>

            <SecurityComponent pageName="POS.Companies.Ubos" elementName="btnConfirm">
              <Box component="span" ml={1}>
                <ButtonComponent
                  color="primary"
                  variant="contained"
                  isProcessing={props.isProcessing}
                  disabled={!props.selectedUbo && props.useSelection}
                  onClick={props.submitForm}
                ><IntlMessageComponent id="general.submit" /></ButtonComponent>
              </Box>
            </SecurityComponent>

          </Box>
        </Box>
      </Paper>
    </React.Fragment>
  );
}