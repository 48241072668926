/* @flow */

import React from 'react';
import {FormattedNumber} from 'react-intl';
import {IntlProvider} from 'react-intl-redux';

import IntlMessageComponent from './intlMessage';

type AmountFormatterComponentProps = {
  amount: number;
  currency: string;
  maximumFractionDigits: number;
  minimumFractionDigits: number;
  notApplicableIfMissing: boolean;
}

export default function AmountFormatterComponent(props: AmountFormatterComponentProps) {
  const {amount, currency, maximumFractionDigits, minimumFractionDigits, notApplicableIfMissing, ...rest} = props;

  if (notApplicableIfMissing && (!amount || amount <= 0)) {
    return <IntlMessageComponent id="general.notApplicable" />;
  }

  return (
    <IntlProvider>
      <FormattedNumber
        textAlign="right"
        // eslint-disable-next-line react/style-prop-object
        style="currency"
        currency={currency || 'EUR'}
        minimumFractionDigits={minimumFractionDigits !== undefined ? minimumFractionDigits : 2}
        maximumFractionDigits={maximumFractionDigits !== undefined ? maximumFractionDigits : 2}
        value={amount || 0}
        {...rest}
      />
    </IntlProvider>
  );
}
