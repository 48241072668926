/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import ButtonComponent from 'components/buttons/button';
import FinancingNeedComponent from 'components/formFields/types/financingNeed';
import FinancingNeedFrequencyComponent from 'components/formFields/types/financingNeedFrequency';
import FormErrorsComponent from 'components/formErrors';
import IntlMessageComponent from 'components/formatters/intlMessage';
import InvoicesAvgAmountComponent from 'components/formFields/types/invoicesAvgAmount';
import InvoicesYearlyAmountComponent from 'components/formFields/types/invoicesYearlyAmount';
import MandatoryFormFieldsComponent from 'components/form/mandatory';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import BusinessOpportunityForm from './model';

type BusinessOpportunityViewComponentProps = {
  errors: Map<string,?string>;
  form: BusinessOpportunityForm;
  formErrors: string[];
  isProcessing: boolean;
  translationPrefix: string;
  backHandler: () => void;
  handleTypeIdChange: (fieldName: string) => (id: number) => void;
  submitForm: () => void;
};

export default function BusinessOpportunityViewComponent(props: BusinessOpportunityViewComponentProps) {

  return (
    <React.Fragment>
      <FormErrorsComponent formErrors={props.formErrors} />

      <PageSubTitleViewComponent id={`${props.translationPrefix}.businessopportunity.title`}/>
      
      <Box mt={3}>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item xs={12} md={6}>
            <Box px={4} data-walkme="getoffer2-field-yearlyinvoiceamount">
              <InvoicesYearlyAmountComponent
                id="form.invoicesYearlyAmount"
                label={<IntlMessageComponent id="form.invoicesYearlyAmount.label" />}
                error={!!props.errors.get('invoicesYearlyAmountId')}
                helperText={props.errors.get('invoicesYearlyAmountId') ?
                  <IntlMessageComponent id={props.errors.get('invoicesYearlyAmountId')} /> : ''}
                value={props.form.invoicesYearlyAmountId}
                changeHandler={props.handleTypeIdChange('invoicesYearlyAmountId')}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box px={4} data-walkme="getoffer2-field-financingfrequency">
              <FinancingNeedFrequencyComponent
                id="form.financingNeedFrequency"
                label={<IntlMessageComponent id="form.financingNeedFrequency.label" />}
                error={!!props.errors.get('financingNeedFrequencyId')}
                helperText={props.errors.get('financingNeedFrequencyId') ?
                  <IntlMessageComponent id={props.errors.get('financingNeedFrequencyId')} /> : ''}
                  value={props.form.financingNeedFrequencyId}
                changeHandler={props.handleTypeIdChange('financingNeedFrequencyId')}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box px={4} data-walkme="getoffer2-field-invoicesavgamount">
              <InvoicesAvgAmountComponent
                id="form.invoicesAvgAmount"
                label={<IntlMessageComponent id="form.invoicesAvgAmount.label" />}
                error={!!props.errors.get('invoicesAvgAmountId')}
                helperText={props.errors.get('invoicesAvgAmountId') ?
                  <IntlMessageComponent id={props.errors.get('invoicesAvgAmountId')} /> : ''}
                value={props.form.invoicesAvgAmountId}
                changeHandler={props.handleTypeIdChange('invoicesAvgAmountId')}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box px={4} data-walkme="getoffer2-field-financingneed">
              <FinancingNeedComponent
                id="form.financingNeed"
                label={<IntlMessageComponent id="form.financingNeed.label" />}
                error={!!props.errors.get('financingNeedId')}
                helperText={props.errors.get('financingNeedId') ?
                  <IntlMessageComponent id={props.errors.get('financingNeedId')} /> : ''}
                value={props.form.financingNeedId}
                changeHandler={props.handleTypeIdChange('financingNeedId')}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box mt={5}>
        {props.backHandler &&
          <Box component="span" mr={1}>
            <ButtonComponent
              color="default"
              variant="outlined"
              disabled={props.isProcessing}
              onClick={props.backHandler}
            ><IntlMessageComponent id="general.button.back.label" /></ButtonComponent>
          </Box>
        }

        <ButtonComponent
          color="primary"
          variant="contained"
          isProcessing={props.isProcessing}
          onClick={props.submitForm}
        ><IntlMessageComponent id="general.continue" /></ButtonComponent>

        <MandatoryFormFieldsComponent/>
      </Box>
    </React.Fragment>
  );
}
