/* @flow */

export default class BankAccount {
  id: number;
  bankBIC: string;
  bankIBAN: string;
  bankCountryId: number;
  isMain: boolean;
  isMainEligible: boolean;
  isActive: boolean;
  investmentProfileNames: string[];
  complianceDone: boolean;
  isDeleted: boolean;
}
