/* @flow */

import React from 'react';

import customSort from 'lib/sortHelpers';

import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

import AmountFormatterComponent from 'components/formatters/amount';
import DateFormatterComponent from 'components/formatters/date';
import IntlMessageComponent from 'components/formatters/intlMessage';
import StyledTableCellTextComponent from 'components/table/cellText';
import StyledTableCellNumbersComponent from 'components/table/cellNumbers';
import StyledTableHeadComponent from 'components/table/head';
import StyledTableRowComponent from 'components/table/row';

import CommissionMembership from 'models/isos/commissions/commissionMembership';
import CommissionMembershipData from 'models/isos/commissions/commissionMembershipData';
import CommissionMembershipIsoCompany from 'models/isos/commissions/commissionMembershipIsoCompany';
import CommissionMembershipIsoOffice from 'models/isos/commissions/commissionMembershipIsoOffice';
import CommissionMembershipIsoPerson from 'models/isos/commissions/commissionMembershipIsoPerson';

const columnData = [
  {id: 'isoCompany', translationKey: 'component.isos.commissions.membership.list.header.isoCompany', walkme: 'isocommission-column3-isocompany', align: 'left'},
  {id: 'isoOffice', translationKey: 'component.isos.commissions.membership.list.header.isoOffice', walkme: 'isocommission-column3-isooffice', align: 'left'},
  {id: 'isoPerson', translationKey: 'component.isos.commissions.membership.list.header.isoPerson', walkme: 'isocommission-column3-isoperson', align: 'left'},
  {id: 'sellerCompany', translationKey: 'component.isos.commissions.membership.list.header.sellerCompany', walkme: 'isocommission-column3-sellercompany', align: 'left'},
  {id: 'revenueDate', translationKey: 'component.isos.commissions.membership.list.header.revenueDate', walkme: 'isocommission-column3-revenuedate', align: 'left'},
  {id: 'membershipFeeCouponReference', translationKey: 'component.isos.commissions.membership.list.header.couponReference', walkme: 'isocommission-column3-couponreference', align: 'left'},
  {id: 'membershipFeeCouponAmountUsed', translationKey: 'component.isos.commissions.membership.list.header.couponAmountUsed', walkme: 'isocommission-column3-couponamountused', align: 'left'},
  {id: 'membershipFee', translationKey: 'component.isos.commissions.membership.list.header.fee', walkme: 'isocommission-column3-fee', align: 'left'},
  {id: 'membershipCommission', translationKey: 'component.isos.commissions.membership.list.header.commission', walkme: 'isocommission-column3-commission', align: 'left'}
];

type CommissionMembershipTableComponentProps = {
  memberships: CommissionMembership[];
}

export default function CommissionMembershipTableComponent(props: CommissionMembershipTableComponentProps) {

  const reorganizeData = () => {
    const data = new CommissionMembershipData();

    // Create data structure
    for (let i = 0; i < props.memberships.length; i++) {
      let isoCompany = data.companies.find(c => c.id === props.memberships[i].filterISOId);
      if (isoCompany === undefined) {
        isoCompany = new CommissionMembershipIsoCompany(props.memberships[i].filterISOId, props.memberships[i].isoCompany);
        data.companies.push(isoCompany);
      }

      let isoOffice = isoCompany.offices.find(o => o.id === props.memberships[i].filterISOOfficeId);
      if (isoOffice === undefined) {
        isoOffice = new CommissionMembershipIsoOffice(props.memberships[i].filterISOOfficeId, props.memberships[i].isoOffice);
        isoCompany.offices.push(isoOffice);
      }

      let isoPerson = isoOffice.persons.find(p => p.id === props.memberships[i].filterISOPersonId);
      if (isoPerson === undefined) {
        isoPerson = new CommissionMembershipIsoPerson(props.memberships[i].filterISOPersonId, props.memberships[i].isoPerson);
        isoOffice.persons.push(isoPerson);
      }

      isoPerson.memberships.push(props.memberships[i]);
    }

    // Subtotals
    for (let i = 0; i < data.companies.length; i++) {
      for (let j = 0; j < data.companies[i].offices.length; j++) {
        for (let k = 0; k < data.companies[i].offices[j].persons.length; k++) {
          for (let l = 0; l < data.companies[i].offices[j].persons[k].memberships.length; l++) {
            // sub total per person
            data.companies[i].offices[j].persons[k].membershipFeeCouponAmountUsed += data.companies[i].offices[j].persons[k].memberships[l].membershipFeeCouponAmountUsed;
            data.companies[i].offices[j].persons[k].membershipFee += data.companies[i].offices[j].persons[k].memberships[l].membershipFee;
            data.companies[i].offices[j].persons[k].membershipCommission += data.companies[i].offices[j].persons[k].memberships[l].membershipCommission;
          }

          // sub total per office
          data.companies[i].offices[j].membershipFeeCouponAmountUsed += data.companies[i].offices[j].persons[k].membershipFeeCouponAmountUsed;
          data.companies[i].offices[j].membershipFee += data.companies[i].offices[j].persons[k].membershipFee;
          data.companies[i].offices[j].membershipCommission += data.companies[i].offices[j].persons[k].membershipCommission;
        }

        // sub total per company
        data.companies[i].membershipFeeCouponAmountUsed += data.companies[i].offices[j].membershipFeeCouponAmountUsed;
        data.companies[i].membershipFee += data.companies[i].offices[j].membershipFee;
        data.companies[i].membershipCommission += data.companies[i].offices[j].membershipCommission;
      }

      // grand total
      data.membershipFeeCouponAmountUsed += data.companies[i].membershipFeeCouponAmountUsed;
      data.membershipFee += data.companies[i].membershipFee;
      data.membershipCommission += data.companies[i].membershipCommission;
    }

    // Sort companies, offices and persons
    for (let i = 0; i < data.companies.length; i++) {
      for (let j = 0; j < data.companies[i].offices.length; j++) {
        data.companies[i].offices[j].persons = data.companies[i].offices[j].persons.sort((a, b) => customSort(a.name, b.name));
      }
      data.companies[i].offices = data.companies[i].offices.sort((a, b) => customSort(a.name, b.name));
    }
    data.companies = data.companies.sort((a, b) => customSort(a.name, b.name));

    return data;
  }

  const data = reorganizeData();

  return (
    <React.Fragment>
      <Box py={5}>
        <Box component="div" overflow="auto">
          <Table>
            <StyledTableHeadComponent>
              <StyledTableRowComponent>
                {columnData.map(column => (
                  <StyledTableCellTextComponent align={column.align} key={column.id} padding="none" data-walkme={column.walkme || ''}>
                    <IntlMessageComponent id={column.translationKey} />
                  </StyledTableCellTextComponent>
                ))}
                <StyledTableCellNumbersComponent padding="space"/>
              </StyledTableRowComponent>
            </StyledTableHeadComponent>

            <TableBody bgcolor="white">
              {data.companies.length === 0 ? (
                <StyledTableRowComponent>
                  <StyledTableCellTextComponent colSpan={columnData.length}>
                    <Box textAlign="center">
                      <IntlMessageComponent id="component.isos.commissions.membership.list.noData" />
                    </Box>
                  </StyledTableCellTextComponent>
                </StyledTableRowComponent>
              ) : (
                <React.Fragment>
                  <StyledTableRowComponent selected>
                    <StyledTableCellTextComponent padding="none" colSpan={6}>
                      <b><IntlMessageComponent id="component.isos.commissions.membership.list.grandTotal" /></b>
                    </StyledTableCellTextComponent>
                    <StyledTableCellNumbersComponent padding="none"><b><AmountFormatterComponent amount={data.membershipFeeCouponAmountUsed} /></b></StyledTableCellNumbersComponent>
                    <StyledTableCellNumbersComponent padding="none"><b><AmountFormatterComponent amount={data.membershipFee} /></b></StyledTableCellNumbersComponent>
                    <StyledTableCellNumbersComponent padding="none"><b><AmountFormatterComponent amount={data.membershipCommission} /></b></StyledTableCellNumbersComponent>
                    <StyledTableCellNumbersComponent padding="space"/>
                  </StyledTableRowComponent>

                  {data.companies.map((company, indexCompany) => (
                    <React.Fragment key={`membership-company-${indexCompany}`}>
                      {company.offices.map((office, indexOffice) => (
                        <React.Fragment key={`membership-office-${indexOffice}`}>
                          {office.persons.map((person, indexPerson) => (
                            <React.Fragment key={`membership-person-${indexPerson}`}>
                              {person.memberships.map((membership, index) => (
                                <StyledTableRowComponent key={`membership-${index}`}>
                                  <StyledTableCellTextComponent padding="none">{membership.isoCompany}</StyledTableCellTextComponent>
                                  <StyledTableCellTextComponent padding="none">{membership.isoOffice}</StyledTableCellTextComponent>
                                  <StyledTableCellTextComponent padding="none">{membership.isoPerson}</StyledTableCellTextComponent>
                                  <StyledTableCellTextComponent padding="none">{membership.sellerCompany}</StyledTableCellTextComponent>
                                  <StyledTableCellTextComponent padding="none"><DateFormatterComponent date={membership.revenueDate} /></StyledTableCellTextComponent>
                                  <StyledTableCellTextComponent padding="none">{membership.membershipFeeCouponReference}</StyledTableCellTextComponent>
                                  <StyledTableCellNumbersComponent padding="none"><AmountFormatterComponent amount={membership.membershipFeeCouponAmountUsed} /></StyledTableCellNumbersComponent>
                                  <StyledTableCellNumbersComponent padding="none"><AmountFormatterComponent amount={membership.membershipFee} /></StyledTableCellNumbersComponent>
                                  <StyledTableCellNumbersComponent padding="none"><AmountFormatterComponent amount={membership.membershipCommission} /></StyledTableCellNumbersComponent>
                                  <StyledTableCellNumbersComponent padding="space"/>
                                </StyledTableRowComponent>
                              ))}

                              {person.id > 0 && person.name !== '' &&
                                <StyledTableRowComponent selected>
                                  <StyledTableCellTextComponent padding="none" colSpan={6}>
                                    <b><IntlMessageComponent id="component.isos.commissions.membership.list.personSubTotal" values={{name: person.name}} /></b>
                                  </StyledTableCellTextComponent>
                                  <StyledTableCellNumbersComponent padding="none"><b><AmountFormatterComponent amount={person.membershipFeeCouponAmountUsed} /></b></StyledTableCellNumbersComponent>
                                  <StyledTableCellNumbersComponent padding="none"><b><AmountFormatterComponent amount={person.membershipFee} /></b></StyledTableCellNumbersComponent>
                                  <StyledTableCellNumbersComponent padding="none"><b><AmountFormatterComponent amount={person.membershipCommission} /></b></StyledTableCellNumbersComponent>
                                  <StyledTableCellNumbersComponent padding="space"/>
                                </StyledTableRowComponent>
                              }
                            </React.Fragment>
                          ))}

                          {office.id > 0 && office.name !== '' &&
                            <StyledTableRowComponent selected>
                              <StyledTableCellTextComponent padding="none" colSpan={6}>
                                <b><IntlMessageComponent id="component.isos.commissions.membership.list.officeSubTotal" values={{name: office.name}} /></b>
                              </StyledTableCellTextComponent>
                              <StyledTableCellNumbersComponent padding="none"><b><AmountFormatterComponent amount={office.membershipFeeCouponAmountUsed} /></b></StyledTableCellNumbersComponent>
                              <StyledTableCellNumbersComponent padding="none"><b><AmountFormatterComponent amount={office.membershipFee} /></b></StyledTableCellNumbersComponent>
                              <StyledTableCellNumbersComponent padding="none"><b><AmountFormatterComponent amount={office.membershipCommission} /></b></StyledTableCellNumbersComponent>
                              <StyledTableCellNumbersComponent padding="space"/>
                            </StyledTableRowComponent>
                          }
                        </React.Fragment>
                      ))}

                      <StyledTableRowComponent selected>
                        <StyledTableCellTextComponent padding="none" colSpan={6}>
                          <b><IntlMessageComponent id="component.isos.commissions.membership.list.companySubTotal" values={{name: company.name}} /></b>
                        </StyledTableCellTextComponent>
                        <StyledTableCellNumbersComponent padding="none"><b><AmountFormatterComponent amount={company.membershipFeeCouponAmountUsed} /></b></StyledTableCellNumbersComponent>
                        <StyledTableCellNumbersComponent padding="none"><b><AmountFormatterComponent amount={company.membershipFee} /></b></StyledTableCellNumbersComponent>
                        <StyledTableCellNumbersComponent padding="none"><b><AmountFormatterComponent amount={company.membershipCommission} /></b></StyledTableCellNumbersComponent>
                        <StyledTableCellNumbersComponent padding="space"/>
                      </StyledTableRowComponent>
                    </React.Fragment>
                  ))}
                </React.Fragment>
              )}
            </TableBody>
          </Table>
        </Box>
      </Box>
    </React.Fragment>
  );
}
