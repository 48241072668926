/* @flow */

import React from 'react';

import CompanyBankAccountsComponent from './companyBankAccounts';

export default function CompanyBankAccountsPageComponent() {
  return (
    <React.Fragment>
      <CompanyBankAccountsComponent />
    </React.Fragment>
  );
}
