/* @flow */

import qs from 'qs';

import BaseService from './api/baseService';

import {
  USER_AUTHENTICATE,
  USER_IMPERSONATE_POST
} from 'constants/apiRoutes';

export default class AuthService {

  validateTokenFromUser = () => {
    const token = localStorage.getItem('token');
    if (token === null) {
      console.info('No token stored');
      return false;
    }

    const tokenExpiresDateTxt = localStorage.getItem('tokenExpiresDate');
    if (tokenExpiresDateTxt == null) {
      console.error('No token expiration date stored');
      localStorage.removeItem('token');
      return false;
    }

    const tokenExpiresDate = new Date(tokenExpiresDateTxt);
    const dateNow = new Date();

    if (dateNow.getTime() >= tokenExpiresDate.getTime()) {
      console.warn('User token has expired');
      this.clearTokenStorage();
      return false;
    }

    return true;
  };

  clearTokenStorage = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpiresDate');
  };

  setTokenStorage = (token: string, tokenExpiresDate: Date) => {
    localStorage.setItem('token', token);
    localStorage.setItem('tokenExpiresDate', tokenExpiresDate.toString());
  };

  signInEdebexUser = async (username: string, password: string, twofactorpin: string) => {
    this.clearTokenStorage();

    const payload = {
      data: qs.stringify({
        grant_type: 'password',
        username,
        password,
        twofactorpin
      })
    };

    const response = await BaseService.signInPOST(USER_AUTHENTICATE, payload);
    const token = response.access_token;
    const tokenExpiresDate = new Date();
    tokenExpiresDate.setSeconds(tokenExpiresDate.getSeconds() + response.expires_in);
    this.setTokenStorage(token, tokenExpiresDate);
    return token;
  };

  impersonateEdebexUser = async (username: string) => {
    const request = { username };
    const token = await BaseService.simplePOST(USER_IMPERSONATE_POST, request, null);
    const tokenExpiresDate = new Date();
    tokenExpiresDate.setSeconds(tokenExpiresDate.getSeconds() + 3600); // set 1h by default
    this.setTokenStorage(token, tokenExpiresDate);
    return token;
  };
}
