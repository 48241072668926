/* @flow */

import React from 'react';
import { connect } from 'react-redux';

import * as reactGA from 'lib/analytics';

import AmountFormatterComponent from 'components/formatters/amount';

import NumberFormatterComponent from 'components/formatters/number';
import PercentageFormatterComponent from 'components/formatters/percentage';
import LoadingComponent from 'components/loading';

import BlockedBuyerSignUpViewComponent from './view';
import BlockedBuyerSignUpSuccessViewComponent from './successView';

import InvestmentProfileForm from './model';

import BuyerService from 'services/BuyerService';
import RegisterService from 'services/registerService';
import { handleApiFormResponse, handleFormChange, handleFormValidation } from 'lib/forms';
import loadConstraints from 'lib/validation/loadConstraints';

import NotificationHelper from 'lib/notifications';

type BlockerBuyerSignUpComponentProps = {
  activeCompanyId: number;
  success: boolean;
  successHandler: () => void;
}

type BlockerBuyerSignUpComponentState = {
  constraints: any;
  errors: Map<string,?string>;
  form: InvestmentProfileForm;
  formErrors: string[];
  isLoading: boolean;
  isProcessing: boolean;
  placeholders: any;
}

class BlockerBuyerSignUpComponent extends React.Component<BlockerBuyerSignUpComponentProps, BlockerBuyerSignUpComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      constraints: null,
      errors: null,
      form: null,
      formErrors: [],
      isLoading: true,
      isProcessing: false,
      placeholders: null
    };
  }

  componentDidMount = async () => {

    try {
      // Fetch profile & defaults
      const defaults = await BuyerService.getInvestmentProfileDefaults(this.props.activeCompanyId);
      
      // Fetch profile & defaults
      const profile = await RegisterService.getInvestmentProfile(this.props.activeCompanyId);

      // Load & build constraints
      const constraints = await loadConstraints('buyerBlockedForm');
      constraints.amount.numericality.greaterThanOrEqualTo = defaults.amountMinValue;
      constraints.minInvoiceAmount.numericality.greaterThanOrEqualTo = defaults.minAmountMinValue;
      constraints.maxInvoiceAmount.numericality.greaterThanOrEqualTo = defaults.maxAmountMinValue;
      constraints.maxInvoiceAmount.isLessThanSibling.multiplier = defaults.maxAmountMultiplier;
      constraints.pct90.numericality.greaterThanOrEqualTo = defaults.pct90MinValue;
      constraints.pct90.numericality.lessThanOrEqualTo = defaults.pct90MaxValue;

      // Build placeholders
      const placeholders = {
        amount: <AmountFormatterComponent amount={defaults.amountMinValue} />,
        minAmount: <AmountFormatterComponent amount={defaults.minAmountMinValue} />,
        maxAmount: <AmountFormatterComponent amount={defaults.maxAmountMinValue} />,
        multiplier: <PercentageFormatterComponent noFractionDigits value={(defaults.maxAmountMultiplier * 100)} />,
        minPct: <NumberFormatterComponent value={defaults.pct90MinValue} />,
        maxPct: <NumberFormatterComponent value={defaults.pct90MaxValue} />
      };

      this.setState({
        constraints,
        errors: new Map(Object.keys(constraints).map(e => [e, undefined])),
        form: new InvestmentProfileForm(profile),
        isLoading: false,
        placeholders
      });
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  };

  handleChange = (fieldName: string) => (event) => handleFormChange.call(this, fieldName, event.target.value);
  handleTypeChange = (fieldName: string) => (id: number) => handleFormChange.call(this, fieldName, id);

  validateForm = () => handleFormValidation.call(this);

  submitForm = async () => {
    if (!this.validateForm()) return;
    this.setState({ formErrors: [], isProcessing: true });

    try {
      const bankRequest = this.state.form.toCompanyBankAccountRequest(this.props.activeCompanyId, true);
      await RegisterService.setCompanyBankAccount(bankRequest);
      const request = this.state.form.toRequest(this.props.activeCompanyId, true);
      await RegisterService.setInvestmentProfile(request);
      this.props.successHandler();
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }
  };

  render() {
    if (this.state.isLoading) return <LoadingComponent />;

    return (
      <React.Fragment>
        {this.props.success && <BlockedBuyerSignUpSuccessViewComponent
        />}
        {!this.props.success && <BlockedBuyerSignUpViewComponent
          errors={this.state.errors}
          form={this.state.form}
          formErrors={this.state.formErrors}
          isProcessing={this.state.isProcessing}
          handleChange={this.handleChange}
          handleTypeChange={this.handleTypeChange}
          submitForm={this.submitForm}
        />}
      </React.Fragment>
    );
  }
}

export default connect()(BlockerBuyerSignUpComponent);

