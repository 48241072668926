/* @flow */

import {
  INIT_WEBSITE_ERROR,
  INIT_WEBSITE_SUCCESS
} from '../actionTypes';

const INIT_STATE = {
  websiteInit: false,
  websiteInitError: null,
  locale: '',
  billTypes: [],
  billedTypes: [],
  companyStatus: [],
  constants: [],
  countries: [],
  countriesForBuyers: [],
  countriesForDebtors: [],
  countriesForISOs: [],
  countriesForSellers: [],
  documentTypes: [],
  financingNeedFrequencies: [],
  financingNeeds: [],
  invoicesYearlyAmounts: [],
  languages: [],
  leadStatus: [],
  officeTypes: [],
  phoneCountriesMapping: [],
  registrationTypes: [],
  rolesForISOs: [],
  rolesForBuyers: [],
  rolesForSellers: [],
  securityMenus: [],
  titles: [],
  translations: {},
  websiteLanguages: [],
  rolesForDebtors: []
};

const reducer = (state: Locale = INIT_STATE, action: {type: string, payload: any}) => {
  switch (action.type) {

    case INIT_WEBSITE_SUCCESS: {
      const stateProxy = {
        get(obj, prop) {
          if (prop in obj && !['type'].includes(prop)) {
            return obj[prop];
          }
          return undefined;
        }
      };

      return {
        ...state,
        ...new Proxy(action, stateProxy),
        websiteInit: true,
        websiteInitError: null
      };
    }

    case INIT_WEBSITE_ERROR: {
      return {
        ...state,
        websiteInit: false,
        websiteInitError: action.payload
      };
    }

    default:
      return state;
  }
};

export default reducer;
