/* @flow */

import moment from 'moment';

export default class CommissionSummary {
  // data
  isoCompany: string;
  isoOffice: string;
  isoPerson: string;
  sellerCompany: string;
  quarterDate: moment;
  financedInvoiceCommission: number;
  unfinancedInvoiceCommission: number;
  msfCommission: number;
  membershipCommission: number;
  totalCommission: number;

  // filters
  filterISOId: number;
  filterISOOfficeId: number;
  filterISOPersonId: number;
  filterPeriod: moment;
}
