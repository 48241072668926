/* @flow */

export default class SubmitFixInvoiceRequest {
  invoiceId: number;
  isFinanced: boolean;

  constructor(invoiceId: number, isFinanced: boolean) {
    this.invoiceId = invoiceId;
    this.isFinanced = isFinanced;
  }
}
