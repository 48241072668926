/* @flow */

import React from 'react';
import {useHistory} from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import {PROTECTED_DEBTOR_DASHBOARD} from 'constants/pageRoutes';

export default function DebtorRegisterSupplierTab2ViewComponent() {
  const history = useHistory();

  return (
    <React.Fragment>
      <PageSubTitleViewComponent id="debtor.registerSupplier.tab2.title" data-walkme="debtor-registerSupplier-tab2-title" />

      <Typography variant="body1">
        <IntlMessageComponent id="debtor.registerSupplier.tab2.text" />
      </Typography>

      <Box mt={5}>
        <ButtonComponent
          color="primary"
          variant="contained"
          onClick={() => history.push(PROTECTED_DEBTOR_DASHBOARD)}
        ><IntlMessageComponent id="debtor.registerSupplier.tab2.btn" /></ButtonComponent>
      </Box>
    </React.Fragment>
  );
}
