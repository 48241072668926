/* @flow */

import FilterCompany from 'models/isos/filters/filterCompany';
import FilterPeriod from 'models/isos/filters/filterPeriod';

export default class CommissionFilters {
  static MODE_SUMMARY = 0;
  static MODE_INVOICES = 1;
  static MODE_MEMBERSHIPS = 2;
  static MODE_MSFS = 3;

  mode: number;
  companies: FilterCompany[];
  period: FilterPeriod;

  constructor(mode: number) {
    this.mode = mode;
    this.companies = [];
    this.period = new FilterPeriod();
  }
}
