/* @flow */

import React from 'react';
import {useSelector} from 'react-redux';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import IntlMessageComponent from 'components/formatters/intlMessage';
import PaymentComponent from 'components/payments/payment';
import LoadingComponent from 'components/loading';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import InvoiceSummaryComponent from './summary';
import InvoiceToPayTableComponent from './table';

import InvoiceSummary from 'models/invoices/seller/invoiceSummary';
import PaymentInfo from 'models/payments/paymentInfo';

type SellInvoiceTab6ComponentViewProps = {
  invoice: InvoiceSummary;
  isProcessing: boolean;
  paymentInfo: PaymentInfo;
  payableInvoices: InvoiceSummary[];
  paymentMethod: string;
  handleNewInvoiceCreation: () => void;
  handleSelectionDone: (selectedInvoiceIds: number[]) => void;
}


export default function SellInvoiceTab6ViewComponent(props: SellInvoiceTab6ComponentViewProps) {
  const hasActiveFixContract = useSelector(state => state.auth.user.activeCompany.roleSeller.hasActiveFixContract);


  let mustPayFees = false;
  let mustPayListingFee = (props.invoice.flexPrices?.mustPayListingFee || props.invoice.fixFinancedPrices?.mustPayListingFee || props.invoice.fixUnfinancedPrices?.mustPayListingFee);
  let mustPayServiceFee = (props.invoice.flexPrices?.mustPayServiceFee || props.invoice.fixFinancedPrices?.mustPayServiceFee || props.invoice.fixUnfinancedPrices?.mustPayServiceFee);
  if (props.invoice.flexPrices && props.invoice.flexPrices.mustPayFees)
    mustPayFees = true;
  if (props.invoice.fixFinancedPrices && props.invoice.fixFinancedPrices.mustPayFees) 
    mustPayFees = true;
  if (props.invoice.fixUnfinancedPrices && props.invoice.fixUnfinancedPrices.mustPayFees) 
    mustPayFees = true;

  return (
    <React.Fragment>
      {!props.paymentInfo && mustPayFees ? (
        <React.Fragment>
          <PageSubTitleViewComponent id="page.sellInvoice.tab6.titleNotSubmitted" />
          {mustPayListingFee && !mustPayServiceFee && <b><IntlMessageComponent id="page.sellInvoice.tab6.textNotSubmitted.listingfee" /></b>}
          {!mustPayListingFee && mustPayServiceFee && <b><IntlMessageComponent id="page.sellInvoice.tab6.textNotSubmitted.servicefee" /></b>}
          {mustPayListingFee && mustPayServiceFee && <b><IntlMessageComponent id="page.sellInvoice.tab6.textNotSubmitted" /></b>}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <PageSubTitleViewComponent id="page.sellInvoice.tab6.titleSubmitted" />
          <b>{!props.paymentInfo && <IntlMessageComponent id="page.sellInvoice.tab6.textSubmitted" />}</b>
        </React.Fragment>
      )}

      <Box mt={5}>
        <InvoiceSummaryComponent invoice={props.invoice} />
      </Box>

      <Box mt={5}>
        <PageSubTitleViewComponent id="page.sellInvoice.tab6.title" />

        {props.isProcessing ? (
          <LoadingComponent />
        ) : (
          <React.Fragment>  
            {props.paymentInfo ? (
              <React.Fragment>
                <Typography variant="body1" gutterBottom>
                  <IntlMessageComponent id="page.sellinvoice.tab6.text.totalpayment" />
                </Typography>

                <Box mt={3}>
                  <PaymentComponent paymentMethod={props.paymentMethod} paymentInfo={props.paymentInfo} />
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Typography variant="body1" gutterBottom>
                  <IntlMessageComponent id={hasActiveFixContract ? "page.sellInvoice.tab6.text.fix" : "page.sellInvoice.tab6.text"} />
                </Typography>

                <Box mt={3}>
                  <InvoiceToPayTableComponent
                    invoices={props.payableInvoices}
                    handleNewInvoiceCreation={props.handleNewInvoiceCreation}
                    handleSelectionDone={props.handleSelectionDone}
                  />
                </Box>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </Box>
    </React.Fragment>
  );
}
