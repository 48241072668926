/* @flow */

import React from 'react';
import NumberFormat from 'react-number-format';
import {useIntl} from 'react-intl';

type InputAmountBaseComponentProps = {
  inputRef: any,
  onChange: ({target:{value: number}}) => void,
  allowNegative: boolean,
  prefix: string,
  intl: IntlShape,
  decimalSeparator: string,
  thousandSeparator: string
}

export default function InputAmountBaseComponent(props: InputAmountBaseComponentProps) {
  const intl = useIntl();
  const {inputRef, onChange, allowNegative, prefix, decimalSeparator, thousandSeparator, ...rest} = props;

  // MAN, this is the only way I found to do it...
  const myDecimalSeparator = decimalSeparator || intl.formatNumber(456.78).replace('456', '').replace('78', '');
  const myThousandSeparator = thousandSeparator || intl.formatNumber(123456).replace('123', '').replace('456', '');

  return (
    <NumberFormat
      {...rest}
      allowNegative={allowNegative || false}
      decimalScale={2}
      decimalSeparator={myDecimalSeparator}
      fixedDecimalScale
      getInputRef={inputRef}
      thousandSeparator={myThousandSeparator}
      onValueChange={values => onChange({target: {value: values.floatValue}})}
    />
  );
}
