/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';

import AmountFormatterComponent from 'components/formatters/amount';
import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';

import InvoiceProposal from 'models/invoices/seller/invoiceProposal';

type SellInvoiceTab5FixUnfinancedPricesComponentProps = {
  invoice: InvoiceProposal;
  isProcessing: boolean;
  submitInvoice: (isFinanced: boolean) => void;
}

export default function SellInvoiceTab5FixUnfinancedPricesComponent(props: SellInvoiceTab5FixUnfinancedPricesComponentProps) {
  return (
    <React.Fragment>
      <h4><IntlMessageComponent id="component.seller.invoiceDetails.summary.unfinancedInvoice" /></h4>
    
      <Box display="flex">
        <Box flexGrow={1} mb={1}>
          <IntlMessageComponent id="component.seller.invoiceDetails.summary.debtamount" data-walkme="sdetails-list-montantinitial" />
        </Box>
        <Box>
          <AmountFormatterComponent amount={props.invoice.debtAmount} />
        </Box>
      </Box>

      <Box display="flex">
        <Box flexGrow={1} mb={1}>
          <IntlMessageComponent id="component.seller.invoiceDetails.summary.debtamount" data-walkme="sdetails-list-montantprefinance" />
        </Box>
        <Box>
          &nbsp;
        </Box>
      </Box>

      <Box display="flex" color="grey.main" fontSize={13}>
        <Box flexGrow={1} mb={1}>
          <IntlMessageComponent id="component.seller.invoiceDetails.summary.listingfee" data-walkme="sdetails-list-fraisdemiseenligne" />
        </Box>
        <Box>
          <AmountFormatterComponent amount={props.invoice.fixUnfinancedPrices.listingFee} />
        </Box>
      </Box>

      <Box display="flex" color="grey.main" fontSize={13}>
        <Box flexGrow={1} mb={1}>
          <IntlMessageComponent id="component.seller.invoiceDetails.summary.servicefee" data-walkme="sdetails-list-fraisdeservices" />
        </Box>
        <Box>
          <AmountFormatterComponent amount={props.invoice.fixUnfinancedPrices.serviceFee} />
        </Box>
      </Box>

      <Box display="flex" color="grey.main" fontSize={13}>
        <Box flexGrow={1} mb={1}>
          <IntlMessageComponent id="component.seller.invoiceDetails.summary.underwritingFee" data-walkme="sdetails-list-fraisdesouscription" />
        </Box>
        <Box>
          <AmountFormatterComponent amount={props.invoice.fixUnfinancedPrices.underwritingFee} />
        </Box>
      </Box>

      <Box display="flex" color="grey.main" fontSize={13}>
        <Box flexGrow={1} mb={1}>
          <IntlMessageComponent id="component.seller.invoiceDetails.summary.collectionFee" data-walkme="sdetails-list-fraisdecollection" />
        </Box>
        <Box>
          <AmountFormatterComponent amount={props.invoice.fixUnfinancedPrices.collectionFee} />
        </Box>
      </Box>

      <Box display="flex" color="grey.main" fontSize={13}>
        <Box flexGrow={1} mb={1}>
          <IntlMessageComponent id="component.seller.invoiceDetails.summary.insuranceFee" data-walkme="sdetails-list-fraisdassurance" />
        </Box>
        <Box>
          <AmountFormatterComponent amount={props.invoice.fixUnfinancedPrices.insuranceFee} />
        </Box>
      </Box>

      <Box display="flex" color="grey.main" fontSize={13}>
        <Box flexGrow={1} mb={1}>
          <IntlMessageComponent id="component.seller.invoiceDetails.summary.bankCosts" data-walkme="sdetails-list-fraisbancaires" />
        </Box>
        <Box>
          <AmountFormatterComponent amount={props.invoice.fixUnfinancedPrices.bankCosts} />
        </Box>
      </Box>

      <Box mt={5}>
        <ButtonComponent
          color="primary"
          variant="contained"
          isProcessing={props.isProcessing}
          onClick={() => props.submitInvoice(false)}
        ><IntlMessageComponent id="page.sellInvoice.tab5.button.putOnline.fix.unfinanced" /></ButtonComponent>
      </Box>
    </React.Fragment>
  );
}
