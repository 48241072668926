/* @flow */

import { plainToClass } from 'class-transformer';

import BaseService from './api/baseService';

import {
  ISO_COMMISSIONS_GET,
  ISO_COMMISSIONS_POST,
  ISO_COMPANIES_GET,
  ISO_COUPONS_CREATE,
  ISO_COUPONS_GET,
  ISO_DASHBOARD_KPIS_GET,
  ISO_DASHBOARD_PROFILE_OVERVIEW_GET,
  ISO_DASHBOARD_PROFILE_SUMMARY_GET,
  ISO_MANDATES_GET,
  ISO_MANDATES_CHECK_GET,
  ISO_MANDATES_UPDATE,
  ISO_MANDATES_DOWNLOAD,
  ISO_OFFICES_GET,
  ISO_OFFICES_ADD_POST,
  ISO_OFFICES_UPDATE_POST,
  ISO_OFFICES_DEACTIVATE_POST,
  ISO_OFFICES_ACTIVATE_POST,
  ISO_OFFICES_PERSONS_GET,
  ISO_OFFICES_PERSONS_ADD_POST,
  ISO_OFFICES_PERSONS_UPDATE_POST,
  ISO_OFFICES_PERSONS_LINK_POST,
  ISO_REGISTER_LEAD_POST,
  ISO_REGISTER_LEAD_BUSINESS_OPPORTUNITY_POST,
  ISO_REGISTER_LEAD_BUSINESS_POTENTIAL_POST,
  ISO_REGISTER_LEAD_NOTE_POST,
  ISO_REGISTER_SELLER_POST,
  ISO_REGISTER_SELLER_COMPANY_INFO_POST,
  ISO_REGISTER_SELLER_DOCUMENTS_GET,
  ISO_REGISTER_SELLER_USER_GET,
  ISO_REGISTER_SELLER_USER_POST,
  ISO_REPORTS_COMMISSIONS_GET,
  ISO_REPORTS_COMMISSIONS_EXPORT_GET,
  ISO_REPORTS_INVOICES_GET,
  ISO_REPORTS_INVOICES_EXPORT_GET,
  ISO_REPORTS_LEADS_GET,
  ISO_REPORTS_LEADS_EXPORT_GET,
  ISO_REPORTS_LEADS_DETAILS_GET,
  ISO_REPORTS_LEADS_SEND_TO_EDEBEX_POST,
  ISO_REPORTS_LEADS_SAVE_NOTE_POST,
  ISO_REPORTS_LEADS_EXTENDS_LIFETIME_POST,
  ISO_REPORTS_SELLERS_GET,
  ISO_REPORTS_SELLERS_EXPORT_GET,
  ISO_REPORTS_LEADS_CHANGE_PERSON_POST,
  ISO_REPORTS_LEADS_CHANGE_OFFICE_POST,
  ISO_REPORTS_LEADS_UPDATE_CONTACT_POST,
  ISO_MANDATES_AVAILABLE_SELLERS_GET,
  ISO_MANDATES_CREATE,
  ISO_REPORTS_LEADS_REOPEN_POST,
  ISO_REPORTS_LEADS_CLOSE_POST
} from 'constants/apiRoutes';

import AddLeadNoteRequest from 'models/requests/isos/registerLeads/addLeadNoteRequest';
import CreateCouponRequest from 'models/requests/isos/coupons/createCouponRequest';
import DownloadCommissionReportRequest from 'models/requests/isos/downloadCommissionReportRequest';
import DownloadInvoiceReportRequest from 'models/requests/isos/downloadInvoiceReportRequest';
import DownloadLeadReportRequest from 'models/requests/isos/downloadLeadReportRequest';
import DownloadSellerReportRequest from 'models/requests/isos/downloadSellerReportRequest';
import ExtendsLeadLifetimeRequest from 'models/requests/isos/extendsLeadLifetimeRequest';
import RegisterLeadRequest from 'models/requests/isos/registerLeads/registerLeadRequest';
import RegisterSellerCompanyRequest from 'models/requests/isos/registerSellers/registerSellerCompanyRequest';
import SendLeadToEdebexRequest from 'models/requests/isos/sendLeadToEdebexRequest';
import SaveIsoLeadNoteRequest from 'models/requests/isos/saveIsoLeadNoteRequest';
import SetBusinessOpportunityRequest from 'models/requests/isos/registerLeads/setBusinessOpportunityRequest';
import SetBusinessPotentialRequest from 'models/requests/isos/registerLeads/setBusinessPotentialRequest';
import SetCompanyInfoRequest from 'models/requests/isos/registerSellers/setCompanyInfoRequest';
import SetCompanyUserInfoRequest from 'models/requests/isos/registerSellers/setCompanyUserInfoRequest';
import UpdateMandateRequest from 'models/requests/isos/updateMandateRequest';

import AddOfficeRequest from 'models/requests/companies/offices/addOfficeRequest';
import AddOfficePersonRequest from 'models/requests/companies/offices/addOfficePersonRequest';
import LinkPersonToOfficeRequest from 'models/requests/companies/offices/linkPersonToOfficeRequest';
import UpdateOfficePersonRequest from 'models/requests/companies/offices/updateOfficePersonRequest';
import UpdateOfficeRequest from 'models/requests/companies/offices/updateOfficeRequest';
import ActivateOfficeRequest from 'models/requests/companies/offices/activateOfficeRequest';

import RegisterLeadResponse from 'models/responses/isos/registerLeadResponse';
import RegisterSellerCompanyResponse from 'models/responses/isos/registerSellerCompanyResponse';

import CommissionInvoice from 'models/isos/commissions/commissionInvoice';
import CommissionMembership from 'models/isos/commissions/commissionMembership';
import CommissionPayment from 'models/isos/commissions/commissionPayment';
import CommissionSummary from 'models/isos/commissions/commissionSummary';
import Company from 'models/companies/company';
import CompanyUserInfo from 'models/isos/registerSellers/companyUserInfo';
import Coupon from 'models/coupons/coupon';
import Document from 'models/documents/document';
import DocumentType from 'models/documents/documentType';
import InvoiceReport from 'models/isos/invoices/invoiceReport';
import Kpi from 'models/dashboards/kpi';
import LeadReport from 'models/isos/leads/leadReport';
import LeadDetails from 'models/isos/leads/leadDetails';
import Mandate from 'models/isos/mandates/mandate';
import Office from 'models/companies/office';
import OfficePerson from 'models/companies/officePerson';
import ProfileOverviewEntry from 'models/isos/dashboards/profileOverviewEntry';
import ProfileSummaryCard from 'models/dashboards/profileSummaryCard';
import RequiredCommissionDocument from 'models/documents/v2/requiredCommissionDocument';

import SellerReport from 'models/isos/sellers/sellerReport';
import ChangeLeadIsoOfficeRequest from 'models/requests/isos/changeLeadIsoOfficeRequest';
import ChangeLeadIsoPersonRequest from 'models/requests/isos/changeLeadIsoPersonRequest';
import UpdateLeadContactRequest from 'models/requests/isos/updateLeadContactRequest';
import CreateMandateRequest from 'models/requests/isos/createMandateRequest';
import CommissionMsf from 'models/isos/commissions/commissionMsf';
import CloseLeadRequest from 'models/requests/isos/closeLeadRequest';

export default class IsoService {

  // Commissions
  static getRequiredCommissionDocuments = (isoCompanyId: number) =>
    BaseService.simpleGET(`${ISO_COMMISSIONS_GET}/${isoCompanyId}`,
      data => data.map(d => plainToClass(RequiredCommissionDocument, d)));

  static sendCommissionDocument = (companyId: number, commissionId: number, file: File) =>
    BaseService.simpleFilePOST(ISO_COMMISSIONS_POST, file, { companyId, commissionId });


  // Companies
  static getIsoCompanies = (isoCompanyId: number) =>
    BaseService.simpleGET(`${ISO_COMPANIES_GET}/${isoCompanyId}`,
      data => data.map(d => plainToClass(Company, d)));


  // Coupons
  static getCoupons = (isoCompanyId: number) =>
    BaseService.simpleGET(`${ISO_COUPONS_GET}/${isoCompanyId}`,
      data => data.map(d => plainToClass(Coupon, d)));

  static createCoupon = (request: CreateCouponRequest) =>
    BaseService.simplePOST(ISO_COUPONS_CREATE, request, null);


  // Dashboard
  static getKPIs = (companyId: number) =>
    BaseService.simpleGET(`${ISO_DASHBOARD_KPIS_GET}/${companyId}`,
      data => data.map(d => plainToClass(Kpi, d)));

  static getProfileOverview = (companyId: number) =>
    BaseService.simpleGET(`${ISO_DASHBOARD_PROFILE_OVERVIEW_GET}/${companyId}`,
      data => data.map(d => plainToClass(ProfileOverviewEntry, d)));

  static getProfileSummary = (companyId: number) =>
    BaseService.simpleGET(`${ISO_DASHBOARD_PROFILE_SUMMARY_GET}/${companyId}`,
      data => data.map(d => plainToClass(ProfileSummaryCard, d)));


  // Mandates
  static getMandates = (companyId: number) =>
    BaseService.simpleGET(`${ISO_MANDATES_GET}/${companyId}`,
      data => data.map(d => plainToClass(Mandate, d)));

  static getAvailableSellers = (companyId: number) =>
    BaseService.simpleGET(`${ISO_MANDATES_AVAILABLE_SELLERS_GET}/${companyId}`,
      data => data.map(d => plainToClass(Company, d)));

  static hasMandates = (companyId: number) =>
    BaseService.simpleGET(`${ISO_MANDATES_CHECK_GET}/${companyId}`,
      data => data);

  static createMandate = (request: CreateMandateRequest) =>
    BaseService.simplePOST(ISO_MANDATES_CREATE, request, null);

  static updateMandate = (request: UpdateMandateRequest) =>
    BaseService.simplePOST(ISO_MANDATES_UPDATE, request, null);

  static downloadMandate = (isoCompanyId: number, companyId: number) =>
    BaseService.simpleFileDownloadGET(`${ISO_MANDATES_DOWNLOAD}/${isoCompanyId}/${companyId}`);


  // Offices
  static getOffices = (companyId: number) =>
    BaseService.simpleGET(`${ISO_OFFICES_GET}/${companyId}`,
      data => data.map(d => plainToClass(Office, d)));

  static addOffice = (request: AddOfficeRequest) =>
    BaseService.simplePOST(ISO_OFFICES_ADD_POST, request, null);

  static updateOffice = (request: UpdateOfficeRequest) =>
    BaseService.simplePOST(ISO_OFFICES_UPDATE_POST, request, null);

  static deactivateOffice = (request: ActivateOfficeRequest) =>
    BaseService.simplePOST(ISO_OFFICES_DEACTIVATE_POST, request, null);

  static activateOffice = (request: ActivateOfficeRequest) =>
    BaseService.simplePOST(ISO_OFFICES_ACTIVATE_POST, request, null);

  static getOfficePersons = (companyId: number, officeId: number) =>
    BaseService.simpleGET(`${ISO_OFFICES_PERSONS_GET}/${companyId}/${officeId}`,
      data => data.map(d => plainToClass(OfficePerson, d)));

  static addOfficePerson = (request: AddOfficePersonRequest) =>
    BaseService.simplePOST(ISO_OFFICES_PERSONS_ADD_POST, request, null);

  static updateOfficePerson = (request: UpdateOfficePersonRequest) =>
    BaseService.simplePOST(ISO_OFFICES_PERSONS_UPDATE_POST, request, null);

  static linkPersonToOffice = (request: LinkPersonToOfficeRequest) =>
    BaseService.simplePOST(ISO_OFFICES_PERSONS_LINK_POST, request, null);


  // Register Leads
  static registerLead = (request: RegisterLeadRequest) =>
    BaseService.simplePOST(ISO_REGISTER_LEAD_POST, request, r => plainToClass(RegisterLeadResponse, r));

  static setBusinessOpportunity = (request: SetBusinessOpportunityRequest) =>
    BaseService.simplePOST(ISO_REGISTER_LEAD_BUSINESS_OPPORTUNITY_POST, request, null);

  static setBusinessPotential = (request: SetBusinessPotentialRequest) =>
    BaseService.simplePOST(ISO_REGISTER_LEAD_BUSINESS_POTENTIAL_POST, request, null);

  static addLeadNote = (request: AddLeadNoteRequest) =>
    BaseService.simplePOST(ISO_REGISTER_LEAD_NOTE_POST, request, null);


  // Register Sellers
  static registerSellerCompany = (request: RegisterSellerCompanyRequest) =>
    BaseService.simplePOST(ISO_REGISTER_SELLER_POST, request, c => plainToClass(RegisterSellerCompanyResponse, c));

  static setCompanyInfo = (request: SetCompanyInfoRequest) =>
    BaseService.simplePOST(ISO_REGISTER_SELLER_COMPANY_INFO_POST, request, null);

  static getCompanyUserInfo = (isoCompanyId: number, companyId: number, companyPersonId: number) =>
    BaseService.simpleGET(`${ISO_REGISTER_SELLER_USER_GET}/${isoCompanyId}/${companyId}/${companyPersonId}`,
      data => plainToClass(CompanyUserInfo, data));

  static setCompanyUserInfo = (request: SetCompanyUserInfoRequest) =>
    BaseService.simplePOST(ISO_REGISTER_SELLER_USER_POST, request, null);

  static getRequiredDocuments = (isoCompanyId: number, companyId: number, companyPersonId: number) =>
    BaseService.simpleGET(`${ISO_REGISTER_SELLER_DOCUMENTS_GET}/${isoCompanyId}/${companyId}/${companyPersonId}`,
      data => {
        const toClassifyDocumentType = plainToClass(DocumentType, data.toClassifyDocumentType);
        const documents = data.requiredDocuments.map((d) => {
          const docType = plainToClass(DocumentType, d);
          return new Document(docType, true, d.isProvided);
        });
        return { documents, toClassifyDocumentType };
      });


  // Reports - Commissions
  static getCommissionReport = (companyId: number) =>
    BaseService.simpleGET(`${ISO_REPORTS_COMMISSIONS_GET}/${companyId}`,
      data => {
        const payments = data.payments.map(p => plainToClass(CommissionPayment, p));
        const invoices = data.invoices.map(i => plainToClass(CommissionInvoice, i));
        const msfs = data.msfs.map(m => plainToClass(CommissionMsf, m));
        const memberships = data.memberships.map(m => plainToClass(CommissionMembership, m));
        const summaries = data.summaries.map(s => plainToClass(CommissionSummary, s));
        return { payments, invoices, msfs, memberships, summaries };
      });

  static downloadCommissionReport = (request: DownloadCommissionReportRequest) =>
    BaseService.simpleFileDownloadGET(`${ISO_REPORTS_COMMISSIONS_EXPORT_GET}?${request.toRouteParameters()}`);


  // Reports - Invoices
  static getInvoiceReport = (companyId: number) =>
    BaseService.simpleGET(`${ISO_REPORTS_INVOICES_GET}/${companyId}`,
      data => data.map(d => plainToClass(InvoiceReport, d)));

  static downloadInvoiceReport = (request: DownloadInvoiceReportRequest) =>
    BaseService.simpleFileDownloadGET(`${ISO_REPORTS_INVOICES_EXPORT_GET}?${request.toRouteParameters()}`);


  // Reports - Leads
  static getLeadReport = (companyId: number) =>
    BaseService.simpleGET(`${ISO_REPORTS_LEADS_GET}/${companyId}`,
      data => data.map(d => plainToClass(LeadReport, d)));

  static getLeadDetails = (companyId: number, leadId: number, leadReopenedId: number) =>
    BaseService.simpleGET(`${ISO_REPORTS_LEADS_DETAILS_GET}/${leadId}/${companyId}/${leadReopenedId}`,
      data => plainToClass(LeadDetails, data));

  static downloadLeadReport = (request: DownloadLeadReportRequest) =>
    BaseService.simpleFileDownloadGET(`${ISO_REPORTS_LEADS_EXPORT_GET}?${request.toRouteParameters()}`);

  static sendLeadToEdebex = (request: SendLeadToEdebexRequest) =>
    BaseService.simplePOST(ISO_REPORTS_LEADS_SEND_TO_EDEBEX_POST, request, null);

  static reopenLead = (request: SendLeadToEdebexRequest) =>
    BaseService.simplePOST(ISO_REPORTS_LEADS_REOPEN_POST, request, null);

  static saveIsoLeadNote = (request: SaveIsoLeadNoteRequest) =>
    BaseService.simplePOST(ISO_REPORTS_LEADS_SAVE_NOTE_POST, request, null);

  static changeLeadIsoOffice = (request: ChangeLeadIsoOfficeRequest) =>
    BaseService.simplePOST(ISO_REPORTS_LEADS_CHANGE_OFFICE_POST, request, null);

  static changeLeadIsoPerson = (request: ChangeLeadIsoPersonRequest) =>
    BaseService.simplePOST(ISO_REPORTS_LEADS_CHANGE_PERSON_POST, request, null);

  static updateLeadContact = (request: UpdateLeadContactRequest) =>
    BaseService.simplePOST(ISO_REPORTS_LEADS_UPDATE_CONTACT_POST, request, null);

  static extendsLeadLifetime = (request: ExtendsLeadLifetimeRequest) =>
    BaseService.simplePOST(ISO_REPORTS_LEADS_EXTENDS_LIFETIME_POST, request, null);

  static closeLead = (request: CloseLeadRequest) =>
    BaseService.simplePOST(ISO_REPORTS_LEADS_CLOSE_POST, request, null);


  // Reports - Sellers
  static getSellerReport = (companyId: number) =>
    BaseService.simpleGET(`${ISO_REPORTS_SELLERS_GET}/${companyId}`,
      data => data.map(d => plainToClass(SellerReport, d)));

  static downloadSellerReport = (request: DownloadSellerReportRequest) =>
    BaseService.simpleFileDownloadGET(`${ISO_REPORTS_SELLERS_EXPORT_GET}?${request.toRouteParameters()}`);
}
