/* @flow */

import IconAddFilled from 'components/icons/filled/add';
import IconArrowUpFilled from 'components/icons/filled/arrowUp';
import IconListFilled from 'components/icons/filled/list';
import IconHomeFilled from 'components/icons/filled/home';
import IconSuitcaseFilled from 'components/icons/filled/suitcase';
import IconRefreshFilled from 'components/icons/filled/refresh';
import IconUsersFilled from 'components/icons/filled/users';

import {
  PROTECTED_CONFIRMING_ADD_SUPPLIER,
  PROTECTED_CONFIRMING_BILLS,
  PROTECTED_CONFIRMING_DASHBOARD, PROTECTED_CONFIRMING_PORTFOLIO, PROTECTED_CONFIRMING_SUBMIT, PROTECTED_CONFIRMING_SUPPLIERS,
} from 'constants/pageRoutes';

export const confirmingMenuData = [
  {link: PROTECTED_CONFIRMING_DASHBOARD, icon: <IconHomeFilled color='primary' size='1.5x'/>, messageId: 'sidebar.page.home'},
  {link: PROTECTED_CONFIRMING_SUBMIT, icon: <IconAddFilled color='primary' size='1.5x'/>, messageId: 'sidebar.page.submit', checkExecutePermissions: true, pageName: 'POS.Confirming.SidebarMenu', elementName: 'menuSubmit'},
  {link: PROTECTED_CONFIRMING_PORTFOLIO, icon: <IconSuitcaseFilled color='primary' size='1.5x'/>, messageId: 'sidebar.page.myPortfolio', pageName: 'POS.Confirming.SidebarMenu', elementName: 'mnuMyPortfolio'},
  {link: PROTECTED_CONFIRMING_BILLS, icon: <IconListFilled color='primary' size='1.5x'/>, messageId: 'sidebar.page.mybills', pageName: 'POS.Confirming.SidebarMenu', elementName: 'menuBills'},
  {
    id: 'acordion-1',
    collapsed: false,
    type: 'sub-menu',
    icon: <IconUsersFilled color='primary' size='1.5x'/>,
    messageId: 'sidebar.page.suppliers',
    pageName: 'POS.MainMenu',
    elementName: 'mnuSuppliersAccordion',
    children: [
      {link: PROTECTED_CONFIRMING_SUPPLIERS, icon: <IconSuitcaseFilled color='primary' size='1.5x'/>, messageId: 'sidebar.page.managesuppliers', pageName: 'POS.Confirming.SidebarMenu', elementName: 'menuSuppliers'},
      {link: PROTECTED_CONFIRMING_ADD_SUPPLIER, icon: <IconAddFilled color='primary' size='1.5x'/>, messageId: 'sidebar.page.addsupplier', checkExecutePermissions: true, pageName: 'POS.Confirming.SidebarMenu', elementName: 'menuAddSupplier'},
    ]
  },
];
