/* @flow */

import React from 'react';

import TermsAndConditionsCancelComponent from 'app/users/termsAndConditions/cancel';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function TermsAndConditionsCancelPage() {
  return (
    <WebsitePageComponent id="page.tos.h1">
      <TermsAndConditionsCancelComponent />
    </WebsitePageComponent>
  );
}
