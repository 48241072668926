/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import MGMReferralAvailablePanelComponent from './panelAvailable';
import MGMReferralUsedPanelComponent from './panelUsed';

import Referral from 'models/sellers/referral';

import SellerService from 'services/sellerService';
import NotificationHelper from 'lib/notifications';

type MGMReferralComponentProps = {
  activeCompanyId: number
}

type MGMReferralComponentState = {
  isLoading: boolean;
  referrals: Referral[];
}

class MGMReferralComponent extends React.Component<MGMReferralComponentProps, MGMReferralComponentState> {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      referrals: null
    };
  }

  componentDidMount = async () => {
    try {
      const referrals = await SellerService.getReferral(this.props.activeCompanyId);
      this.setState({isLoading: false, referrals});
    } catch (e) {
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
      console.error(e);
    }
  }

  render() {
    if (this.state.isLoading) return null;

    return (
      <Box mt={5}>
        <Grid container spacing={4} alignItems="stretch">
          <Grid item xs={12} md={6}>
            <MGMReferralAvailablePanelComponent
              referrals={this.state.referrals.filter(r => r.nbCouponsAvailable > 0)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <MGMReferralUsedPanelComponent
              referrals={this.state.referrals.filter(r => r.nbCouponsUsed > 0)}
            />
          </Grid>
        </Grid>
      </Box>
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps)(MGMReferralComponent);
