/* @flow */

import React from 'react';

import SignUpTab4TokenExpiredViewComponent from './view';

import SendVerificationEmailRequest from 'models/requests/register/sendVerificationEmailRequest';

import RegisterService from 'services/registerService';
import {handleApiFormResponse} from 'lib/forms';
import NotificationHelper from 'lib/notifications';

type SignUpTab4TokenExpiredComponentProps = {
  email: string;
}

type SignUpTab4TokenExpiredComponentState = {
  isProcessing: boolean;
  step: number;
}

class SignUpTab4TokenExpiredComponent extends React.Component<SignUpTab4TokenExpiredComponentProps, SignUpTab4TokenExpiredComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      isProcessing: false,
      step: 0
    };
  }

  resendConfirmation = async () => {          
    try {
      this.setState({isProcessing: true});
      const request = new SendVerificationEmailRequest(this.props.email);
      await RegisterService.sendVerificationEmail(request);
      NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS);
      this.setState({isProcessing: false, step: 1});
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }
  }
 
  render() {
    return (
      <SignUpTab4TokenExpiredViewComponent
        email={this.props.email}
        isProcessing={this.state.isProcessing}
        step={this.state.step}
        resendConfirmation={this.resendConfirmation}
      />
    );
  }
}

export default SignUpTab4TokenExpiredComponent;
