/* @flow */

export default class BuyerPerformance {
  amountInvested: number;
  cashReturn: string;
  investedDataSeries: [];
  investmentDays: number;
  irr: number;
  returnDataSeries: [];
}
