/* @flow */

import React from 'react';

import IntlMessageComponent from 'components/formatters/intlMessage';

type TranslationComponentProps = {
  id: number;
  isLoading: boolean;
  values: {id: number, name: string}[];
}

export default function TranslationComponent(props: TranslationComponentProps) {
  if (props.isLoading) {
    return <span><IntlMessageComponent id="general.loading" /></span>;
  }
    
  const val = props.values.find(v => v.id === props.id);
  if (!val) {
    return <span>Missing translation</span>;
  }

  return <span>{val.name}</span>;
}
