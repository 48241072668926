/* @flow */

import React from 'react';

import UserPasswordComponent from 'app/users/password';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function UserPasswordPage() {
  return (
    <WebsitePageComponent noPaper id="page.profile.password.title">
      <UserPasswordComponent />
    </WebsitePageComponent>
  );
}
