/* @flow */

import React from 'react';
import { useHistory } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import AmountFormatterComponent from 'components/formatters/amount';
import ButtonComponent from 'components/buttons/button';
import DateFormatterComponent from 'components/formatters/date';
import DebtorInvoiceStatusViewComponent from 'components/invoiceStatus/debtor';
import IntlMessageComponent from 'components/formatters/intlMessage';
import InvoiceNameComponent from 'components/invoice/name';
import NumberFormatterComponent from 'components/formatters/number';
import StyledTableCellTextComponent from 'components/table/cellText';
import StyledTableCellNumbersComponent from 'components/table/cellNumbers';
import StyledTableHeadComponent from 'components/table/head';
import StyledTableRowComponent from 'components/table/row';

import SupplierBankAccountChange from 'models/debtors/supplierBankAccountChange';

import { PROTECTED_DEBTOR_INVOICE_DETAILS } from 'constants/pageRoutes';

const columnData = [
  { id: 'companyName', translationKey: 'debtor.supplieraccount.table.companyname' },
  { id: 'changedToEdebex', translationKey: 'debtor.supplieraccount.table.currentaccount' }
];


type DebtorSupplierBankAccountChangeTableViewComponentProps = {
  supplierBankAccountChanges: SupplierBankAccountChange[];
  order: string;
  orderBy: string;
  page: number;
  pageSize: number;
  changeToEdebex: boolean;
  handleSupplierSelect: (supplierId: number) => void;
  handleSupplierSelectAll: () => void;
  handlePageChange: (page: number) => void;
  handlePageSizeChange: (pageSize: number) => void;
  handleSortOrderChange: (property: string) => void;
  changeSupplierAccounts: () => void;
};


export default function DebtorSupplierBankAccountChangeTableViewComponent(props: DebtorSupplierBankAccountChangeTableViewComponentProps) {
  const history = useHistory();

  const handlePageChange = (_, page) => props.handlePageChange(page);
  const handlePageSizeChange = event => props.handlePageSizeChange(event.target.value);

  const handleClickCheckbox = (event, invoiceId: number) => {
    event.stopPropagation();
    props.handleSupplierSelect(invoiceId);
  };

  const handleClickCheckboxAll = (event) => {
    event.stopPropagation();
    props.handleSupplierSelectAll();
  };

  return (
    <React.Fragment>
      <Box component="div" overflow="auto">
        <Table>
          <StyledTableHeadComponent data-walkme="debtorToPay-table-headline">
            <StyledTableRowComponent>
              <StyledTableCellTextComponent padding="checkbox">
                <Tooltip title={<IntlMessageComponent id="general.tooltip.selectAll" />}>
                  <Checkbox
                    checked={props.supplierBankAccountChanges.length > 0 && props.supplierBankAccountChanges.find(s => !s.isSelected) === undefined}
                    color="primary"
                    disabled={props.supplierBankAccountChanges.length === 0}
                    onClick={event => handleClickCheckboxAll(event)}
                  />
                </Tooltip>
              </StyledTableCellTextComponent>
              {columnData.map(column => (
                <StyledTableCellTextComponent key={column.id} padding="none">
                  <TableSortLabel
                    active={props.orderBy === column.id}
                    direction={props.order}
                    onClick={() => props.handleSortOrderChange(column.id)}
                    data-walkme={column.walkme || ''}
                  ><IntlMessageComponent id={column.translationKey} /></TableSortLabel>
                </StyledTableCellTextComponent>
              ))}
            </StyledTableRowComponent>
          </StyledTableHeadComponent>

          <TableBody bgcolor="white">
            {props.supplierBankAccountChanges.length === 0 &&
              <StyledTableRowComponent>
                <StyledTableCellTextComponent colSpan={columnData.length + 1}>
                  <Box textAlign="center">
                    <IntlMessageComponent id="general.noData" />
                  </Box>
                </StyledTableCellTextComponent>
              </StyledTableRowComponent>
            }

            {props.supplierBankAccountChanges.slice(props.page * props.pageSize, (props.page * props.pageSize) + props.pageSize).map(s => (
              <StyledTableRowComponent
                key={`supplierList-${s.id}`}
                hover
                tabIndex={-1}
              >

                <StyledTableCellTextComponent padding="checkbox" >
                  <Checkbox color="primary" checked={s.isSelected} onClick={event => handleClickCheckbox(event, s.companyId)} />
                </StyledTableCellTextComponent>

                <StyledTableCellTextComponent padding="none">
                  {s.companyName}
                </StyledTableCellTextComponent>

                <StyledTableCellTextComponent padding="none">
                  {s.changeBankAccountToEdebex ? <IntlMessageComponent id="debtor.supplierbankaccount.edebex" /> : <IntlMessageComponent id="debtor.supplierbankaccount.supplier" />}
                </StyledTableCellTextComponent>
              </StyledTableRowComponent>
            ))}

          </TableBody>
        </Table>
      </Box>

      <Box display="flex" height={60}>
        <Box flexGrow={1} mt={2}>
          <ButtonComponent
            color="primary"
            variant="contained"
            disabled={props.supplierBankAccountChanges.length <= 0 || props.supplierBankAccountChanges.find(s => s.isSelected) === undefined}
            onClick={props.changeSupplierAccounts}
          >{props.changeToEdebex ? <IntlMessageComponent id="debtor.btn.changetoedebex" /> : <IntlMessageComponent id="debtor.btn.changefromedebex" />}</ButtonComponent>
        </Box>

        <Box display="flex" height={60}>
          <Box flexGrow={1}></Box>
          <Box justifyContent="flex-end">
            <TablePagination
              component="div"
              count={props.supplierBankAccountChanges.length}
              rowsPerPage={props.pageSize}
              page={props.page}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePageSizeChange}
              labelRowsPerPage={<IntlMessageComponent id="general.suppliersperpage" />}
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
            />
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}
