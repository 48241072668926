import React from 'react';
import {useSelector} from 'react-redux';

import moment from 'moment';

import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

import DateFormatterComponent from 'components/formatters/date';
import IntlMessageComponent from 'components/formatters/intlMessage';

export default function MaintenanceSmallComponent() {
  const maintenance = useSelector(state => state.locale.maintenance);

  if (!maintenance)
    return null;

  const dateTo = moment.utc(maintenance.dateTo).local();

  const values = {
    date: <b><DateFormatterComponent date={dateTo} format="dddd DD MMMM YYYY" /></b>,
    time: <b><DateFormatterComponent date={dateTo} format="HH:mm" /></b>,
    timeUS: <b><DateFormatterComponent date={dateTo} format="LT" /></b>,    
    supportEmail: <a href="mailto:support@edebex.com">support@edebex.com</a>
  };

  return (
    <Alert variant="outlined" severity="warning">
      <AlertTitle><IntlMessageComponent id="component.maintenance.small.title" /></AlertTitle>
      <IntlMessageComponent id="component.maintenance.small.text" values={values} />
    </Alert>
  );
}
