/* @flow */

import React from 'react';
import moment from 'moment/moment';

import BackupOutlinedIcon from '@material-ui/icons/BackupOutlined';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import DatePickerComponent from 'components/formFields/dates/datePicker';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import RegistrationTypeFieldComponent from 'components/formFields/types/registrationType';
import UploadLinkComponent from 'components/formFields/uploadLink';

import CompanyUBOForm from '../model';

import NotificationHelper from 'lib/notifications';

import {
  REGISTRATTION_TYPE_PASSPORT
} from 'constants/constants';


type CompanyUBOsFormDocumentInfoPanelComponentProps = {
  errors: Map<string,?string>;
  form: CompanyUBOForm;
  isProcessing: boolean;
  docNotExprinedAndNotReplaced: [];
  hasValidId: boolean;
  handleChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void;
  handleDateChange: (fieldName: string) => (date: moment) => void;
  handleFileChange: (fieldName: string) => (file: File) => void;
  handleTypeChange: (fieldName: string) => (id: number) => void;
}

export default function CompanyUBOsFormDocumentInfoPanelComponent(props: CompanyUBOsFormDocumentInfoPanelComponentProps) {
  const failHandler = () => NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR, 'notifications.file.upload.wrongformat');

  const minRegistrationExpireDate = moment().add(1, 'd').startOf('d');
  const maxRegistrationExpireDate = moment().add(100, 'y').startOf('d');

  return (
    <React.Fragment>
      <PageSubTitleViewComponent id="company.ubos.form.documentInfo" />

      <Grid container spacing={6} alignItems="stretch">
        <Grid item xs={12} md={6}>
          <Box px={4}>
            <RegistrationTypeFieldComponent
              id="form.registrationType"
              label={<IntlMessageComponent id="form.registrationType.label" />}
              error={!!props.errors.get('registrationTypeId')}
              helperText={props.errors.get('registrationTypeId') ?
                <IntlMessageComponent id={props.errors.get('registrationTypeId')} /> : ''}
              countryId={props.form.countryId}
              value={props.form.registrationTypeId}
              changeHandler={props.handleTypeChange('registrationTypeId')}
              fullWidth
              margin="normal"
              required
            />

            <TextField
              id="form.registrationNumber"
              label={<IntlMessageComponent id="form.registrationNumber.label" />}
              error={!!props.errors.get('registrationNumber')}
              helperText={props.errors.get('registrationNumber') ?
                <IntlMessageComponent id={props.errors.get('registrationNumber')} /> : ''}
              value={props.form.registrationNumber}
              onChange={props.handleChange('registrationNumber')}
              fullWidth
              margin="normal"
              required
            />

            <DatePickerComponent
              id="form.registrationExpireDate"
              data-walkme="subscription-field-findevalidite"
              label={<IntlMessageComponent id="form.registrationExpireDate.label" />}
              error={!!props.errors.get('registrationExpireDate')}
              helperText={props.errors.get('registrationExpireDate') ?
                <IntlMessageComponent id={props.errors.get('registrationExpireDate')} /> : ''}
              value={props.form.registrationExpireDate}
              onChange={props.handleDateChange('registrationExpireDate')}
              minDate={minRegistrationExpireDate}
              maxDate={maxRegistrationExpireDate}
              fullWidth
              margin="normal"
              required
            />
          </Box>
        </Grid>

        {props.hasValidId === false && (
          <Grid item xs={12} md={6}>
            <Grid container spacing={6} alignItems="stretch">
              <Grid item xs={6}>
                <Box textAlign="center">
                  <img src="./images/documents/idcard-recto.png" alt="idcard-recto" style={{ height: 120 }} />
                  <Box>
                    <UploadLinkComponent
                      error={!!props.errors.get('idFront')}
                      file={props.form.idFront}
                      helperText={props.errors.get('idFront') ?
                        <IntlMessageComponent id={props.errors.get('idFront')} /> : ''}
                      icon={<BackupOutlinedIcon size="1.3x" />}
                      label={props.form.id > 0 ? "general.button.updatedocument" : "general.btn.uploadDocument"}
                      successHandler={props.handleFileChange('idFront')}
                      failHandler={failHandler}
                    />
                  </Box>
                </Box>
              </Grid>
              {props.form.registrationTypeId !== REGISTRATTION_TYPE_PASSPORT &&
                <Grid item xs={6}>
                  <Box textAlign="center">
                    <img src="./images/documents/idcard-verso.png" alt="idcard-verso" style={{ height: 120 }} />
                    <Box>
                      <UploadLinkComponent
                        error={!!props.errors.get('idBack')}
                        file={props.form.idBack}
                        helperText={props.errors.get('idBack') ?
                          <IntlMessageComponent id={props.errors.get('idBack')} /> : ''}
                        icon={<BackupOutlinedIcon size="1.3x" />}
                        label={props.form.id > 0 && props.form.registrationTypeId == REGISTRATTION_TYPE_PASSPORT ? "general.button.optionalupdatedocument" : props.form.id > 0 ? "general.button.updatedocument" :
                          props.form.registrationTypeId == REGISTRATTION_TYPE_PASSPORT ? "general.btn.optionaluploadDocument" : "general.btn.uploadDocument"}
                        successHandler={props.handleFileChange('idBack')}
                        failHandler={failHandler}
                      />
                    </Box>
                  </Box>
                </Grid>
              }
            </Grid>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
}
