/* @flow */

import DocumentType from './documentType';

export default class Document {
  id: number;
  type: DocumentType;
  file: ?File;
  filename: ?string;
  isDeleted: boolean;
  isMissing: boolean;
  isProvided: boolean;
  isRequired: boolean;

  constructor(docType: DocumentType, isRequired: boolean, isProvided: boolean) {
    this.id = 0;
    this.type = docType;
    this.file = null;
    this.filename = '';
    this.isDeleted = false;
    this.isMissing = false;
    this.isRequired = isRequired;
    this.isProvided = isProvided;
  }
}
