/* @flow */

import React from 'react';
import { useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';

import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import SubmitinvoiceTab4PricesComponent from './prices';
import SubmitinvoiceTab4SummaryComponent from './summary';

import InvoiceProposal from 'models/invoices/seller/invoiceProposal';

type SubmitinvoiceTab4ViewComponentProps = {
  invoice: InvoiceProposal;
  isProcessing: boolean;
  cancelInvoice: () => void;
  submitFlexInvoice: () => void;
}

export default function SubmitinvoiceTab4ViewComponent(props: SubmitinvoiceTab4ViewComponentProps) {
  return (
    <React.Fragment>
      <PageSubTitleViewComponent id="page.submitinvoice.tab4.title" />

      <Box mt={3}>
        {/* <b><IntlMessageComponent id="page.submitinvoice.tab4.subTitle1" /></b> */}
        <p><IntlMessageComponent id="page.submitinvoice.tab4.subTitle2" /></p>
      </Box>

      <Box mt={5}>
        <SubmitinvoiceTab4SummaryComponent invoice={props.invoice} />
      </Box>


      <SubmitinvoiceTab4PricesComponent
        invoice={props.invoice}
        isProcessing={props.isProcessing}
        submitInvoice={props.submitFlexInvoice}
        cancelInvoice={props.cancelInvoice}
      />
    </React.Fragment>
  );
}
