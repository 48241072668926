/* @flow */

import React from 'react';

import LoadingComponent from 'components/loading';

import BusinessOpportunityComponent from './businessOpportunity';
import EligibilityQuestionComponent from './eligibilityQuestion';

import EligibilityQuestionModel from 'models/leads/eligibilityQuestion';

import ConfirmEligibilityAnswersRequest from 'models/requests/leads/confirmEligibilityAnswersRequest';

import LeadService from 'services/LeadService';

type EligibilityTestComponentProps = {
  leadId: number;
  mustAnswerBusinessOpportunityQuestions: boolean;
  mustAnswerEligibilityQuestions: boolean;
  translationPrefix: string;
  successHandler: () => void;
  failHandler: (ineligibleReasonIds: number[]) => void;
  backHandler: () => void;
};

type EligibilityTestComponentState = {
  currentQuestionIndex: number;
  isLoading: boolean;
  isProcessing: boolean;
  questions: EligibilityQuestionModel[];
};

class EligibilityTestComponent extends React.Component<EligibilityTestComponentProps, EligibilityTestComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      currentQuestionIndex: props.mustAnswerBusinessOpportunityQuestions ? -1 : 0,
      isLoading: this.props.mustAnswerEligibilityQuestions,
      isProcessing: false,
      questions: null
    };
  }

  componentDidMount = async () => {
    try {
      if (this.props.mustAnswerEligibilityQuestions) {
        const questions = await LeadService.getEligibilityQuestions(this.props.leadId);
        this.setState({isLoading: false, questions});
      }      
    } catch (e) {
      console.error(e);
    }
  }

  onBackButton = () => {
    if (this.state.currentQuestionIndex >= 0) {
      const prevQuestionIndex = this.state.currentQuestionIndex - 1;
      this.setState({currentQuestionIndex: prevQuestionIndex});
    }
  }

  onBusinessOpportunitySuccess = () => {
    if (!this.props.mustAnswerEligibilityQuestions || this.state.questions.length <= 0) {
      // no question (or already answered) -> next step
      this.props.successHandler();
    } else {
      // otherwise, first question
      this.setState({currentQuestionIndex: 0});
    }
  }

  onEligibilityQuestionSuccess = async (answerId: number) => {
    const nextQuestionIndex = this.state.currentQuestionIndex + 1;

    if (nextQuestionIndex < this.state.questions.length) {
      // still some questions to answer
      const questions = this.state.questions;

      // remember the state of the question (because back button lol)
      for (let i = 0; i < questions[this.state.currentQuestionIndex].answers.length; i++) {
        if (questions[this.state.currentQuestionIndex].answers[i].id === answerId) {
          questions[this.state.currentQuestionIndex].answers[i].isSelected = true;
        } else {
          questions[this.state.currentQuestionIndex].answers[i].isSelected = false;
        }
      }

      this.setState({currentQuestionIndex: nextQuestionIndex, questions});
    } else {
      // confirm the eligibility of the lead
      try {
        this.setState({isProcessing: true});
        const request = new ConfirmEligibilityAnswersRequest(this.props.leadId);
        const response = await LeadService.confirmEligibilityAnswers(request);
        if (response.isEligible) {
          this.props.successHandler();
        } else {
          this.props.failHandler(response.ineligibleReasonIds);
        }
      } catch (e) {
        console.error(e);
      }
    }
  }

  render() {
    if (this.state.isLoading || this.state.isProcessing) {
      return (<LoadingComponent />);
    }

    return (
      <React.Fragment>
        {this.state.currentQuestionIndex === -1 ? (
          <BusinessOpportunityComponent
            leadId={this.props.leadId}
            translationPrefix={this.props.translationPrefix}
            successHandler={this.onBusinessOpportunitySuccess}
            backHandler={this.props.backHandler}
          />
        ) : (
          <EligibilityQuestionComponent
            key={this.state.currentQuestionIndex}
            leadId={this.props.leadId}
            question={this.state.questions[this.state.currentQuestionIndex]}
            successHandler={this.onEligibilityQuestionSuccess}
            backHandler={this.onBackButton}
          />
        )}
      </React.Fragment>
    );
  }
}

export default EligibilityTestComponent;
