/* @flow */

import {
  INIT_WEBSITE_SAGA,
  INIT_WEBSITE_ERROR,
  INIT_WEBSITE_SUCCESS,
  SWITCH_LANGUAGE_SAGA
} from '../actionTypes';

export const initializeWebSiteAction = (locale: string) => ({
  type: INIT_WEBSITE_SAGA,
  locale
});

export const initializeWebSiteSuccessAction = payload =>
  Object.assign(payload, {type: INIT_WEBSITE_SUCCESS});

export const initializeWebSiteErrorAction = payload => ({
  type: INIT_WEBSITE_ERROR,
  payload
});

export const switchLanguageAction = (locale: string) => ({
  type: SWITCH_LANGUAGE_SAGA,
  locale
});
