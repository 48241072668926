/* @flow */

import moment from 'moment';

export default class UpdateInvoiceRequest {
  sellerCompanyId: number;
  invoiceId: number;

  reference: string;
  debtAmount: number;
  dueDate: moment;
  issueDate: moment;
}
