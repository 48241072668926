/* @flow */

import moment from 'moment';

export default class Referral {
  companyName: string;
  firstName: string;
  lastName: string;
  nbCouponsAvailable: number;
  nbCouponsUsed: number;
  expirationDate: moment;
}
