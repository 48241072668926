/* @flow */

import React from 'react';
import {useLocation} from 'react-router-dom';
import * as reactGA from 'lib/analytics';
import * as reactGA4 from 'lib/analyticsga4';

import Container from '@material-ui/core/Container';

import AppWrapperComponent from 'components/websiteStructure/appWrapper';
import DashboardPageTitleViewComponent from 'components/pageTitle/dashboard';

type WebsiteDashboardPageComponentProps = {
  id: string;
}

export default function WebsiteDashboardPageComponent(props: WebsiteDashboardPageComponentProps) {
  let location = useLocation();
  reactGA.initializeGA();
  reactGA.logPageView(location.pathname);
  reactGA4.initializeGA();
  reactGA4.logPageView(location.pathname);

  return (
    <AppWrapperComponent>
      <Container maxWidth="xl">
        <DashboardPageTitleViewComponent id={props.id} />

        {props.children}
      </Container>
    </AppWrapperComponent>
  );
}
