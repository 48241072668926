/* @flow */

import moment from 'moment';
import DebtorCompany from 'models/companies/debtorCompany';

import InvoiceFixPrices from './invoiceFixPrices';
import InvoiceFlexPrices from './invoiceFlexPrices';

export default class InvoiceProposal {
  id: number;

  debtorCompany: DebtorCompany;
  dueDate: moment;
  confirmingDueDate: moment;

  debtAmount: number;
  
  statusKey: string;

  flexPrices: InvoiceFlexPrices;
  fixFinancedPrices: InvoiceFixPrices;
  fixUnfinancedPrices: InvoiceFixPrices;
}
