/* @flow */

import React from 'react';
import {useLocation, useParams} from "react-router-dom";

import ConfirmingSubmitInvoiceComponent from 'app/confirming/submit';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function ConfirmingSubmitInvoicePage() {
  const location = useLocation();
  const {id} = useParams();

  return (
    <WebsitePageComponent id="page.submitinvoice.description" noPaper>
      <ConfirmingSubmitInvoiceComponent invoiceId={id || 0} leadOffer={location.state?.leadOffer} />
    </WebsitePageComponent>
  );
}
