/* @flow */

import React from 'react';
import { useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PaymentComponent from 'components/payments/payment';
import ChoosePaymentMethodComponent from 'components/payments/choosePaymentMethod'
import SecurityComponent from 'components/security/index';

import CompanyMembershipCardViewComponent from './card';

import CompanyMembership from 'models/companies/companyMembership';

type CompanyMembershipsViewComponentProps = {
  isProcessing: boolean;
  memberships: CompanyMembership[];
  showPayment: boolean;
  showRenewButton: boolean;
  paymentMethod: string;
  isUnpaid: boolean;
  isExpired: boolean;
  reloadMemberships: () => void;
  renewMembership: () => void;
  handleChoosePayment: () => void;
}

export default function CompanyMembershipsViewComponent(props: CompanyMembershipsViewComponentProps) {
  const activeCompany = useSelector(state => state.auth.user.activeCompany);

  return (
    <React.Fragment>
      {props.memberships && props.memberships.length > 0 &&
        <Grid container direction="row" justify="space-between" alignItems="stretch" spacing={6}>
          {props.memberships.map((membership, i) => (
            <Grid item xs={12} md={6} key={`membership-container-${i}`}>
              <CompanyMembershipCardViewComponent membership={membership} reloadMemberships={props.reloadMemberships} />
            </Grid>
          ))}

          {activeCompany.hasSignedGeneralConditionsAndHasProcuration && props.showRenewButton &&
            <SecurityComponent pageName="POS.Companies.Membership" elementName="btnRenew">
              <Grid item xs={12} md={6}>
                <Box height="100%" display="flex" alignItems="center">
                  <ButtonComponent
                    color="tertiary"
                    variant="contained"
                    isProcessing={props.isProcessing}
                    onClick={props.renewMembership}
                  >
                    <IntlMessageComponent id="page.membership.renew" />
                  </ButtonComponent>
                </Box>
              </Grid>
            </SecurityComponent>
          }
        </Grid>
      }

      {!activeCompany.hasSignedGeneralConditionsAndHasProcuration && (props.isUnpaid || props.isExpired) && (props.showRenewButton || props.showPayment )&&
        <Box mt={5}>
          <b><IntlMessageComponent id="page.membership.waiting.generalconditionsprocuration" /></b>
        </Box>
      }

      {/* {!activeCompany.isActive && !props.showRenewButton &&
        <Box mt={5}>
          <b><IntlMessageComponent id="page.membership.waiting.approval" /></b>
        </Box>
      } */}

      {/* {activeCompany.isActive && !activeCompany.isCompliant &&
        <Box mt={5}>
          <b><IntlMessageComponent id="page.membership.waiting.compliance" /></b>
        </Box>
      } */}

      {activeCompany.hasSignedGeneralConditionsAndHasProcuration && props.showPayment &&
        <Box mt={5}>
          <PaymentComponent paymentInfo={props.memberships[props.memberships.length - 1].paymentInfo} paymentMethod={props.paymentMethod} hidePaymentByCard />
        </Box>
      }

      {activeCompany.hasSignedGeneralConditionsAndHasProcuration && props.showPayment &&
        <Box mt={5}>
          <ChoosePaymentMethodComponent paymentInfo={props.memberships[props.memberships.length - 1].paymentInfo.ogone} paymentMethod={props.paymentMethod} handleChoosePayment={props.handleChoosePayment} />
        </Box>
      }
    </React.Fragment>
  );
}
