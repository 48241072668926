/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import CompanyDocumentsUploadCommissionInvoiceDialogViewComponent from './view';

import Document from 'models/documents/v2/document';
import RequiredCommissionDocument from 'models/documents/v2/requiredCommissionDocument';

import IsoService from 'services/IsoService';
import NotificationHelper from 'lib/notifications';

type CompanyDocumentsUploadCommissionInvoiceDialogComponentProps = {
  activeCompanyId: number;
  onClose: () => void;
}

type CompanyDocumentsUploadCommissionInvoiceDialogComponentState = {
  commissionId: number;
  document: Document;
  documents: RequiredCommissionDocument[];
  isLoading: boolean;
  isProcessing: boolean;
}

class CompanyDocumentsUploadCommissionInvoiceDialogComponent extends React.Component<CompanyDocumentsUploadCommissionInvoiceDialogComponentProps, CompanyDocumentsUploadCommissionInvoiceDialogComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      commissionId: -1,
      documents: [],
      isLoading: true,
      isProcessing: false
    };
  }

  componentDidMount = async () => {
    try {
      const documents = await IsoService.getRequiredCommissionDocuments(this.props.activeCompanyId);
      const document = new Document();
      if (documents.length > 0) {
        document.code = documents[0].code;
        document.typeId = documents[0].typeId;
      }

      this.setState({document, documents, isLoading: false});
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  }

  handleDocumentSelect = (file: File) => {
    const document = this.state.document;
    document.file = file;
    document.filename = file.name;
    document.isInvalid = false;
    document.isMissing = false;
    this.setState({document});
  };

  handleDocumentSelectFailure = () => {
    const document = this.state.document;
    document.file = null;
    document.filename = '';
    document.isInvalid = true;
    document.isMissing = false;
    this.setState({document});
  }

  handleDocumentRemove = () => {
    const document = this.state.document;
    document.file = null;
    document.filename = '';
    document.isInvalid = false;
    document.isMissing = false;
    this.setState({document});
  };

  onCommissionIdChange = async (commissionId: number) => {
    const document = this.state.document;
    if (commissionId <= 0) {
      document.file = null;
      document.filename = '';
      document.isInvalid = false;
      document.isMissing = false;
    }
    this.setState({commissionId, document});
  }

  onSubmit = async () => {
    try {
      this.setState({isProcessing: true});
      await IsoService.sendCommissionDocument(this.props.activeCompanyId, this.state.commissionId, this.state.document.file);
      NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS);
      this.props.onClose();
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  }

  render() {
    return (
      <CompanyDocumentsUploadCommissionInvoiceDialogViewComponent
        commissionId={this.state.commissionId}  
        document={this.state.document}
        documents={this.state.documents}
        isLoading={this.state.isLoading}
        isProcessing={this.state.isProcessing}
        handleDocumentSelect={this.handleDocumentSelect}
        handleDocumentSelectFailure={this.handleDocumentSelectFailure}
        handleDocumentRemove={this.handleDocumentRemove}
        onClose={this.props.onClose}
        onCommissionIdChange={this.onCommissionIdChange}
        onSubmit={this.onSubmit}
      />
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps)(CompanyDocumentsUploadCommissionInvoiceDialogComponent);
