/* @flow */

import UpdateMobileRequest from 'models/requests/users/updateMobileRequest';

export default class MobileConfirmationForm {
  mobile: string;

  constructor(mobile: string) {
    this.mobile = mobile || '';
  }

  toRequest(){
    const request = new UpdateMobileRequest();
    request.mobile = this.mobile;
    return request;
  }
}
