/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import {makeStyles} from '@material-ui/core/styles';

import IconCancelOutlined from 'components/icons/outlined/cancel';
import IconExclamationMarkOutlined from 'components/icons/outlined/exclamationMark';
import IconHand from 'components/icons/hand';
import IconHourglass from 'components/icons/hourglass';
import IconTransferOutlined from 'components/icons/outlined/transfer';
import IntlMessageComponent from 'components/formatters/intlMessage';

import {
  INVOICE_SELLER_TYPE_CANCELLED,
  INVOICE_SELLER_TYPE_CASH_SENT,
  INVOICE_SELLER_TYPE_FOLLOWUP,
  INVOICE_SELLER_TYPE_PAY_FEES,
  INVOICE_SELLER_TYPE_PUBLISHED,
  INVOICE_SELLER_TYPE_REFUSED,
  INVOICE_SELLER_TYPE_SIGN_DEED,
  INVOICE_SELLER_TYPE_WAITING_DEBTOR,
  INVOICE_SELLER_TYPE_WAITING_EDEBEX
} from 'constants/invoiceTypes';

const useStyles = makeStyles(_ => ({
  label: {
    textTransform: 'uppercase'
  }
}));

type SellerInvoiceStatusViewComponentProps = {
  invoiceType: number
}

export default function SellerInvoiceStatusViewComponent(props: SellerInvoiceStatusViewComponentProps) {
  const classes = useStyles();

  const customize = (invoiceType: number) => {
    switch (invoiceType) {
      case INVOICE_SELLER_TYPE_PUBLISHED:
        return { color: 'red.main', icon: <IconExclamationMarkOutlined fontSize="small"/>, translationId: 'component.seller.invoiceList.status.published'};
      case INVOICE_SELLER_TYPE_PAY_FEES:
        return { color: 'red.main', icon: <IconExclamationMarkOutlined fontSize="small"/>, translationId: 'component.seller.invoiceList.status.payfees'};
      case INVOICE_SELLER_TYPE_FOLLOWUP:
        return { color: 'red.main', icon: <IconExclamationMarkOutlined fontSize="small"/>, translationId: 'component.seller.invoiceList.status.followup'};
      case INVOICE_SELLER_TYPE_SIGN_DEED:
        return { color: 'red.main', icon: <IconExclamationMarkOutlined fontSize="small"/>, translationId: 'component.seller.invoiceList.status.signdeed'};
      case INVOICE_SELLER_TYPE_WAITING_DEBTOR:
        return { color: 'orange.main', icon: <IconHand fontSize="small"/>, translationId: 'component.seller.invoiceList.status.waitingdebtor'};
      case INVOICE_SELLER_TYPE_CASH_SENT:
        return { color: 'green.main', icon: <IconTransferOutlined fontSize="small"/>, translationId: 'component.seller.invoiceList.status.cashsent'};
      case INVOICE_SELLER_TYPE_WAITING_EDEBEX:
        return { color: 'grey.main', icon: <IconHourglass fontSize="small"/>, translationId: 'component.seller.invoiceList.status.waitingedebex'};
      case INVOICE_SELLER_TYPE_REFUSED:
        return { color: 'grey.main', icon: <IconCancelOutlined fontSize="small"/>, translationId: 'component.seller.invoiceList.status.refused'};
      case INVOICE_SELLER_TYPE_CANCELLED:
        return { color: 'grey.main', icon: <IconCancelOutlined fontSize="small"/>, translationId: 'component.seller.invoiceList.status.cancelled'};
      default:
        return { color: 'grey.main', icon: <HelpOutlineOutlinedIcon fontSize="small"/>, translationId: 'component.seller.invoiceList.status.unknown'};
    }
  }

  const data = customize(props.invoiceType);

  return (
    <Box
      alignItems="center"
      color={data.color}
      display="flex"
      fontSize="0.8em"
      fontWeight="fontWeightBold"
    >
      {data.icon}
      <Box component="span" className={classes.label} ml={1}>
        <IntlMessageComponent id={data.translationId} />
      </Box>
    </Box>
  );
}