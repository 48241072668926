/* @flow */

import {withStyles} from '@material-ui/core/styles';
import TableHead from '@material-ui/core/TableHead';

const StyledTableHeadComponent = withStyles(_ => ({
  root: {
    textTransform: 'uppercase'
  }
}))(TableHead);

export default StyledTableHeadComponent;
