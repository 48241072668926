/* @flow */

import React from 'react';
import { useHistory } from 'react-router';

import Box from '@material-ui/core/Box';

import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';
import SecurityComponent from 'components/security/index';

import InvoiceDetails from 'models/invoices/seller/invoiceDetails';

import { PROTECTED_SELLER_SELL } from 'constants/pageRoutes';

type SellerInvoiceDetailsActionsViewComponentProps = {
  invoice: InvoiceDetails;
  cancelInvoice: (invoiceId: number) => void;
  payInvoiceFees: (invoiceId: number) => void;
}

export default function SellerInvoiceDetailsActionsViewComponent(props: SellerInvoiceDetailsActionsViewComponentProps) {
  const history = useHistory();

  const handleClickEdit = (event: SyntheticMouseEvent<>, invoiceId: number) => {
    event.stopPropagation();
    history.push(`${PROTECTED_SELLER_SELL}/${invoiceId}`);
  };

  const handleClickRemove = async (event: SyntheticMouseEvent<>, invoiceId: number) => {
    event.stopPropagation();
    props.cancelInvoice(invoiceId);
  };

  const handleClickPayFees = async (event: SyntheticMouseEvent<>, invoiceId: number) => {
    event.stopPropagation();
    props.payInvoiceFees(invoiceId);
  };

  return (
    <Box mt={3}>
      {props.invoice.hasFeesToPay &&
        <SecurityComponent pageName="POS.Sellers.InvoiceDetails" elementName="btnPayFees" checkRoleExecute>
          <Box component="span" mr={3}>
            <ButtonComponent
              color="primary"
              variant="contained"
              onClick={event => handleClickPayFees(event, props.invoice.id)}
            ><IntlMessageComponent id="component.seller.invoiceList.actions.payfees" /></ButtonComponent>
          </Box>
        </SecurityComponent>
      }

      {props.invoice.isDraft &&
        <React.Fragment>
          <SecurityComponent pageName="POS.Sellers.InvoiceDetails" elementName="btnEdit" checkRoleExecute>
            <ButtonComponent
              color="primary"
              variant="contained"
              onClick={event => handleClickEdit(event, props.invoice.id)}
            ><IntlMessageComponent id="component.invoice.documents.action.edit" /></ButtonComponent>
          </SecurityComponent>

          <SecurityComponent pageName="POS.Sellers.InvoiceDetails" elementName="btnCancel" checkRoleExecute>
            <Box component="span" ml={3}>
              <ButtonComponent
                color="primary"
                variant="contained"
                onClick={event => handleClickRemove(event, props.invoice.id)}
              ><IntlMessageComponent id="component.invoice.documents.action.cancelInvoice" /></ButtonComponent>
            </Box>
          </SecurityComponent>
        </React.Fragment>
      }
    </Box>
  );
}
