/* @flow */

import React from 'react';
import moment from 'moment';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import BackupOutlinedIcon from '@material-ui/icons/BackupOutlined';


import PhoneNumberComponent from 'components/formFields/phoneNumber';
import ButtonComponent from 'components/buttons/button';
import ComponentWrapperComponent from 'components/websiteStructure/componentWrapper';
import CountryFieldComponent from 'components/formFields/countries';
import DatePickerComponent from 'components/formFields/dates/datePicker';
import FormErrorsComponent from 'components/formErrors';
import IntlMessageComponent from 'components/formatters/intlMessage';
import LanguageFieldComponent from 'components/formFields/types/language';
import MandatoryFormFieldsComponent from 'components/form/mandatory';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import RegistrationTypeFieldComponent from 'components/formFields/types/registrationType';
import TitleFieldComponent from 'components/formFields/types/title';
import UploadLinkComponent from 'components/formFields/uploadLink';

import NotificationHelper from 'lib/notifications';
import PersonalInfoForm from './model';
import { useSelector } from 'react-redux';

import {
  REGISTRATTION_TYPE_PASSPORT
} from 'constants/constants';

type ProfileFormViewComponentProps = {
  docToBeValidatedCount: number;
  errors: Map<string,?string>;
  form: PersonalInfoForm;
  formErrors: string[];
  isProcessing: boolean;
  docNotExpiredAndNotReplaced: [],
  hasValidId: boolean;
  handleChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void;
  handleDateChange: (fieldName: string) => (date: moment) => void;
  handleTypeChange: () => void;
  handleMobileChange: (value: string) => void;
  handlePhoneChange: (value: string) => void;
  handleFileChange: (fieldName: string) => (file: File) => void;
  submitForm: () => void;
}

export default function ProfileFormViewComponent(props: ProfileFormViewComponentProps) {
  const minDateBirth = moment('01-01-1900', 'MM-DD-YYYY');
  const maxDateBirth = moment().subtract(18, 'y').startOf('d');
  const failHandler = () => NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR, 'notifications.file.upload.wrongformat');

  const minRegistrationExpireDate = moment().utc().startOf('d');
  const maxRegistrationExpireDate = moment().add(100, 'y').startOf('d');

  return (
    <ComponentWrapperComponent>
      <FormErrorsComponent formErrors={props.formErrors} />

      <Box>
        <PageSubTitleViewComponent id="page.profile.subtitle.personalinfo" />

        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TitleFieldComponent
                label={<IntlMessageComponent id="form.title.label" />}
                error={!!props.errors.get('titleId')}
                helperText={(props.errors.get('titleId')) ?
                  <IntlMessageComponent id={props.errors.get('titleId')} /> : ''}
                value={props.form.titleId}
                changeHandler={props.handleTypeChange('titleId')}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>
        </Grid>


        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.name"
                data-walkme="subscription-field-nom"
                label={<IntlMessageComponent id="form.name.label" />}
                error={!!props.errors.get('lastName')}
                helperText={props.errors.get('lastName') ?
                  <IntlMessageComponent id={props.errors.get('lastName')} /> : ''}
                value={props.form.lastName}
                onChange={props.handleChange('lastName')}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.firstName"
                data-walkme="subscription-field-prenom"
                label={<IntlMessageComponent id="form.firstname.label" />}
                error={!!props.errors.get('firstName')}
                helperText={props.errors.get('firstName') ?
                  <IntlMessageComponent id={props.errors.get('firstName')} /> : ''}
                value={props.form.firstName}
                onChange={props.handleChange('firstName')}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box px={4}>
              <CountryFieldComponent
                id="form.nationality"
                label={<IntlMessageComponent id="form.nationality.label" />}
                error={!!props.errors.get('nationalityId')}
                helperText={props.errors.get('nationalityId') ?
                  <IntlMessageComponent id={props.errors.get('nationalityId')} /> : ''}
                value={props.form.nationalityId}
                changeHandler={props.handleTypeChange('nationalityId')}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box px={4}>
              <DatePickerComponent
                id="form.birthDate"
                label={<IntlMessageComponent id="form.birthDate.label" />}
                error={!!props.errors.get('birthDate')}
                helperText={props.errors.get('birthDate') ?
                  <IntlMessageComponent id={props.errors.get('birthDate')} /> : ''}
                value={props.form.birthDate}
                onChange={props.handleDateChange('birthDate')}
                minDate={minDateBirth}
                maxDate={maxDateBirth}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box px={4}>
              <CountryFieldComponent
                id="form.birthCountry"
                label={<IntlMessageComponent id="form.birthCountry.label" />}
                error={!!props.errors.get('birthCountryId')}
                helperText={props.errors.get('birthCountryId') ?
                  <IntlMessageComponent id={props.errors.get('birthCountryId')} /> : ''}
                value={props.form.birthCountryId}
                changeHandler={props.handleTypeChange('birthCountryId')}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.birthPlace"
                data-walkme="subscription-field-birthPlace"
                label={<IntlMessageComponent id="form.birthPlace.label" />}
                error={!!props.errors.get('birthPlace')}
                helperText={props.errors.get('birthPlace') ?
                  <IntlMessageComponent id={props.errors.get('birthPlace')} /> : ''}
                value={props.form.birthPlace}
                onChange={props.handleChange('birthPlace')}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box px={4}>
              <LanguageFieldComponent
                label={<IntlMessageComponent id="form.language.label" />}
                error={!!props.errors.get('languageId')}
                helperText={(props.errors.get('languageId')) ?
                  <IntlMessageComponent id={props.errors.get('languageId')} /> : ''}
                value={props.form.languageId}
                changeHandler={props.handleTypeChange('languageId')}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box mt={5}>
        <PageSubTitleViewComponent id="page.profile.subtitle.contactInfo" />

        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <PhoneNumberComponent
                id="form.mobile"
                label={<IntlMessageComponent id="form.mobile.label" />}
                error={!!props.errors.get('mobile')}
                helperText={props.errors.get('mobile') ?
                  <IntlMessageComponent id={props.errors.get('mobile')} /> :
                  <IntlMessageComponent id="form.mobile.alreadySubscribed.helperText" />}
                value={props.form.mobile}
                onChange={props.handleMobileChange}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box px={4}>
              <PhoneNumberComponent
                id="form.phone"
                label={<IntlMessageComponent id="form.phone.label" />}
                error={!!props.errors.get('phone')}
                helperText={props.errors.get('phone') ?
                  <IntlMessageComponent id={props.errors.get('phone')} /> :
                  <IntlMessageComponent id="form.phone.helperText" />}
                value={props.form.phone}
                onChange={props.handlePhoneChange}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>
        </Grid>

        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.email"
                label={<IntlMessageComponent id="form.email.label" />}
                error={!!props.errors.get('email')}
                helperText={props.errors.get('email') ?
                  <IntlMessageComponent id={props.errors.get('email')} /> : ''}
                value={props.form.email}
                onChange={props.handleChange('email')}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>
        </Grid>

        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.addressLine1"
                data-walkme="subscription-field-adresse"
                label={<IntlMessageComponent id="form.person.addressLine1.label" />}
                error={!!props.errors.get('addressLine1')}
                helperText={props.errors.get('addressLine1') ?
                  <IntlMessageComponent id={props.errors.get('addressLine1')} /> : ''}
                value={props.form.addressLine1}
                onChange={props.handleChange('addressLine1')}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.addressLine2"
                label={<IntlMessageComponent id="form.person.addressLine2.label" />}
                error={!!props.errors.get('addressLine2')}
                helperText={props.errors.get('addressLine2') ?
                  <IntlMessageComponent id={props.errors.get('addressLine2')} /> : ''}
                value={props.form.addressLine2}
                onChange={props.handleChange('addressLine2')}
                fullWidth
                margin="normal"
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.zipCode"
                label={<IntlMessageComponent id="form.person.zipCode.label" />}
                error={!!props.errors.get('zipCode')}
                helperText={props.errors.get('zipCode') ?
                  <IntlMessageComponent id={props.errors.get('zipCode')} /> : ''}
                value={props.form.zipCode}
                onChange={props.handleChange('zipCode')}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.city"
                label={<IntlMessageComponent id="form.person.city.label" />}
                error={!!props.errors.get('city')}
                helperText={props.errors.get('city') ?
                  <IntlMessageComponent id={props.errors.get('city')} /> : ''}
                value={props.form.city}
                onChange={props.handleChange('city')}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box px={4}>
              <CountryFieldComponent
                id="form.country"
                label={<IntlMessageComponent id="form.person.country.label" />}
                helperText={props.errors.get('countryId') ?
                  <IntlMessageComponent id={props.errors.get('countryId')} /> : ''}
                error={!!props.errors.get('countryId')}
                value={props.form.countryId}
                changeHandler={props.handleTypeChange('countryId')}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>
        </Grid>
      </Box>


      <Box mt={5}>
        <PageSubTitleViewComponent id="page.profile.subtitle.documents" />

        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <RegistrationTypeFieldComponent
                id="form.registrationType"
                label={<IntlMessageComponent id="form.registrationType.label" />}
                error={!!props.errors.get('registrationTypeId')}
                helperText={props.errors.get('registrationTypeId') ?
                  <IntlMessageComponent id={props.errors.get('registrationTypeId')} /> : ''}
                countryId={props.form.countryId}
                value={props.form.registrationTypeId}
                changeHandler={props.handleTypeChange('registrationTypeId')}
                fullWidth
                margin="normal"
                required
              />

              <TextField
                id="form.registrationNumber"
                label={<IntlMessageComponent id="form.registrationNumber.label" />}
                error={!!props.errors.get('registrationNumber')}
                helperText={props.errors.get('registrationNumber') ?
                  <IntlMessageComponent id={props.errors.get('registrationNumber')} /> : ''}
                value={props.form.registrationNumber}
                onChange={props.handleChange('registrationNumber')}
                fullWidth
                margin="normal"
                required
              />

              <DatePickerComponent
                id="form.registrationExpireDate"
                data-walkme="subscription-field-findevalidite"
                label={<IntlMessageComponent id="form.registrationExpireDate.label" />}
                error={!!props.errors.get('registrationExpireDate')}
                helperText={props.errors.get('registrationExpireDate') ?
                  <IntlMessageComponent id={props.errors.get('registrationExpireDate')} /> : ''}
                value={props.form.registrationExpireDate}
                onChange={props.handleDateChange('registrationExpireDate')}
                minDate={minRegistrationExpireDate}
                maxDate={maxRegistrationExpireDate}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>

          {props.hasValidId === false &&
            <Grid item xs={12} md={6}>
              <Grid container spacing={6} alignItems="stretch">
                <Grid item xs={6}>
                  <Box textAlign="center">
                    <img src="./images/documents/idcard-recto.png" alt="idcard-recto" style={{ height: 120 }} />
                    <Box>
                      <UploadLinkComponent
                        error={!!props.errors.get('idFront')}
                        file={props.form.idFront}
                        helperText={props.errors.get('idFront') ?
                          <IntlMessageComponent id={props.errors.get('idFront')} /> : ''}
                        icon={<BackupOutlinedIcon size="1.3x" />}
                        label={props.form.id > 0 ? "general.button.updatedocument" : "general.btn.uploadDocument"}
                        successHandler={props.handleFileChange('idFront')}
                        failHandler={failHandler}
                      />
                    </Box>
                  </Box>
                </Grid>
                {props.form.registrationTypeId !== REGISTRATTION_TYPE_PASSPORT &&
                  <Grid item xs={6}>
                    <Box textAlign="center">
                      <img src="./images/documents/idcard-verso.png" alt="idcard-verso" style={{ height: 120 }} />
                      <Box>
                        <UploadLinkComponent
                          error={!!props.errors.get('idBack')}
                          file={props.form.idBack}
                          helperText={props.errors.get('idBack') ?
                            <IntlMessageComponent id={props.errors.get('idBack')} /> : ''}
                          icon={<BackupOutlinedIcon size="1.3x" />}
                          label={props.form.id > 0 && props.form.registrationTypeId == REGISTRATTION_TYPE_PASSPORT ? "general.button.optionalupdatedocument" : props.form.id > 0 ? "general.button.updatedocument" :
                            props.form.registrationTypeId == REGISTRATTION_TYPE_PASSPORT ? "general.btn.optionaluploadDocument" : "general.btn.uploadDocument"}
                          successHandler={props.handleFileChange('idBack')}
                          failHandler={failHandler}
                        />
                      </Box>
                    </Box>
                  </Grid>
                }
              </Grid>
            </Grid>
          }
        </Grid>
      </Box>

      <Box mt={5}>
        <ButtonComponent
          color="primary"
          variant="contained"
          isProcessing={props.isProcessing}
          onClick={props.submitForm}
        ><IntlMessageComponent id="page.modifyProfile.save" /></ButtonComponent>

        <MandatoryFormFieldsComponent />
      </Box>
    </ComponentWrapperComponent>
  );
}
