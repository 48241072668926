/* @flow */

import React from 'react';
import qs from 'qs';
import {useLocation} from 'react-router-dom';

import PaymentConfirmationComponent from 'app/payments/accepted';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function OgonePaymentAcceptedPage() {
  const location = useLocation();

  const queryParams = qs.parse(location.search.toLowerCase(), { ignoreQueryPrefix: true });
  const orderId = queryParams.orderid;

  return (
    <WebsitePageComponent id="page.payment.title">
      <PaymentConfirmationComponent orderId={orderId} />
    </WebsitePageComponent>
  );
}
