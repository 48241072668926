/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import PaymentDialogComponent from 'components/payments/paymentDialog';

import SellerInvoiceDetailsActionsViewComponent from './view';

import InvoiceDetails from 'models/invoices/seller/invoiceDetails';
import PaymentInfo from 'models/payments/paymentInfo';

import CancelInvoiceRequest from 'models/requests/sellers/cancelInvoiceRequest';
import InvoiceFeesPaymentRequest from 'models/requests/payments/invoiceFeesPaymentRequest';

import InvoiceSellerService from 'services/InvoiceSellerService';
import PaymentService from 'services/PaymentService';

import NotificationHelper from 'lib/notifications';

type SellerInvoiceDetailsActionsComponentProps = {
  activeCompanyId: number;
  invoice: InvoiceDetails;
  reloadAction: () => void;
  payFees: () => void;
}

type SellerInvoiceDetailsActionsComponentState = {
  isProcessing: boolean;
  paymentInfo: PaymentInfo;
}

class SellerInvoiceDetailsActionsComponent extends React.Component<SellerInvoiceDetailsActionsComponentProps, SellerInvoiceDetailsActionsComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      isProcessing: false,
      paymentInfo: null
    };
  }

  cancelInvoice = async (invoiceId: number) => {
    this.setState({isProcessing: true});

    try {
      const request = new CancelInvoiceRequest(invoiceId);
      await InvoiceSellerService.cancelInvoice(request);
      NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS, 'notifications.invoice.cancel.success');
      this.props.reloadAction();
    } catch (error) {
      console.error('cancelInvoice', error);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  };

  payInvoiceFees = async (invoiceId: number) => {
    this.setState({isProcessing: true});

    try {
      const request = new InvoiceFeesPaymentRequest(this.props.activeCompanyId, [invoiceId]);
      const paymentInfo = await PaymentService.requestInvoiceFeesPayment(request);
      this.setState({paymentInfo, isProcessing: false});
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
      this.setState({isProcessing: false});
    }
  };

  handleClosePaymentDialog = () => {
    this.setState({paymentInfo: null});
  };

  render() {
    return (
      <React.Fragment>
        <SellerInvoiceDetailsActionsViewComponent
          invoice={this.props.invoice}
          cancelInvoice={this.cancelInvoice}
          payInvoiceFees={this.payInvoiceFees}
        />

        {this.state.paymentInfo !== null &&
          <PaymentDialogComponent
            isOpen={true}
            paymentInfo={this.state.paymentInfo}
            handleRequestClose={this.handleClosePaymentDialog}
          />
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps)(SellerInvoiceDetailsActionsComponent);
