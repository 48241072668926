/* @flow */

import React from 'react';

import customSort from 'lib/sortHelpers';

import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

import AmountFormatterComponent from 'components/formatters/amount';
import BooleanFormatterComponent from 'components/formatters/boolean';
import DateFormatterComponent from 'components/formatters/date';
import DebtorGradeForBuyerComponent from 'components/debtorGrades/buyer';
import IntlMessageComponent from 'components/formatters/intlMessage';
import StyledTableCellTextComponent from 'components/table/cellText';
import StyledTableCellNumbersComponent from 'components/table/cellNumbers';
import StyledTableHeadComponent from 'components/table/head';
import StyledTableRowComponent from 'components/table/row';

import CommissionInvoice from 'models/isos/commissions/commissionInvoice';
import CommissionInvoiceData from 'models/isos/commissions/commissionInvoiceData';
import CommissionInvoiceIsoCompany from 'models/isos/commissions/commissionInvoiceIsoCompany';
import CommissionInvoiceIsoOffice from 'models/isos/commissions/commissionInvoiceIsoOffice';
import CommissionInvoiceIsoPerson from 'models/isos/commissions/commissionInvoiceIsoPerson';

const columnData = [
  {id: 'isoCompany', translationKey: 'component.isos.commissions.invoice.list.header.isoCompany', walkme: 'isocommission-column2-isocompany', align: 'left'},
  {id: 'isoOffice', translationKey: 'component.isos.commissions.invoice.list.header.isoOffice', walkme: 'isocommission-column2-isocoffice', align: 'left'},
  {id: 'isoPerson', translationKey: 'component.isos.commissions.invoice.list.header.isoPerson', walkme: 'isocommission-column2-isoperson', align: 'left'},
  {id: 'sellerCompany', translationKey: 'component.isos.commissions.invoice.list.header.sellerCompany', walkme: 'isocommission-column2-sellercompany', align: 'left'},
  {id: 'invoiceId', translationKey: 'component.isos.commissions.invoice.list.header.invoiceId', walkme: 'isocommission-column2-invoiceid', align: 'left'},
  {id: 'debtorInsurerRating', translationKey: 'component.isos.commissions.invoice.list.header.debtorInsurerRating', walkme: 'isocommission-column2-debtorrating', align: 'left'},
  {id: 'createdDate', translationKey: 'component.isos.commissions.invoice.list.header.createdDate', walkme: 'isocommission-column2-createddate', align: 'left'},
  {id: 'listedDate', translationKey: 'component.isos.commissions.invoice.list.header.listedDate', walkme: 'isocommission-column2-listeddate', align: 'left'},
  {id: 'financedDate', translationKey: 'component.isos.commissions.invoice.list.header.financedDate', walkme: 'isocommission-column2-financeddate', align: 'left'},
  {id: 'closedDate', translationKey: 'component.isos.commissions.invoice.list.header.closedDate', walkme: 'isocommission-column2-closeddate', align: 'left'},
  {id: 'isClaim', translationKey: 'component.isos.commissions.invoice.list.header.isClaim', walkme: 'isocommission-column2-isclaim', align: 'left'},
  {id: 'isFirstInvoice', translationKey: 'component.isos.commissions.invoice.list.header.isFirstInvoice', walkme: 'isocommission-column2-isfirstinvoice', align: 'left'},
  {id: 'debtAmount', translationKey: 'component.isos.commissions.invoice.list.header.debtAmount', walkme: 'isocommission-column2-debtamount', align: 'right'},
  {id: 'financedAmount', translationKey: 'component.isos.commissions.invoice.list.header.financedAmount', walkme: 'isocommission-column2-financedamount', align: 'right'},
  {id: 'paidServiceFee', translationKey: 'component.isos.commissions.invoice.list.header.paidServiceFee', walkme: 'isocommission-column2-paidservicefee', align: 'right'},
  {id: 'invoiceCommission', translationKey: 'component.isos.commissions.invoice.list.header.commission', walkme: 'isocommission-column2-commission', align: 'right'}
];

type CommissionInvoiceTableComponentProps = {
  invoices: CommissionInvoice[];
}

export default function CommissionInvoiceTableComponent(props: CommissionInvoiceTableComponentProps) {

  const reorganizeData = () => {
    const data = new CommissionInvoiceData();

    // Create data structure
    for (let i = 0; i < props.invoices.length; i++) {
      let isoCompany = data.companies.find(c => c.id === props.invoices[i].filterISOId);
      if (isoCompany === undefined) {
        isoCompany = new CommissionInvoiceIsoCompany(props.invoices[i].filterISOId, props.invoices[i].isoCompany);
        data.companies.push(isoCompany);
      }

      let isoOffice = isoCompany.offices.find(o => o.id === props.invoices[i].filterISOOfficeId);
      if (isoOffice === undefined) {
        isoOffice = new CommissionInvoiceIsoOffice(props.invoices[i].filterISOOfficeId, props.invoices[i].isoOffice);
        isoCompany.offices.push(isoOffice);
      }

      let isoPerson = isoOffice.persons.find(p => p.id === props.invoices[i].filterISOPersonId);
      if (isoPerson === undefined) {
        isoPerson = new CommissionInvoiceIsoPerson(props.invoices[i].filterISOPersonId, props.invoices[i].isoPerson);
        isoOffice.persons.push(isoPerson);
      }

      isoPerson.invoices.push(props.invoices[i]);
    }

    // Subtotals
    for (let i = 0; i < data.companies.length; i++) {
      for (let j = 0; j < data.companies[i].offices.length; j++) {
        for (let k = 0; k < data.companies[i].offices[j].persons.length; k++) {
          for (let l = 0; l < data.companies[i].offices[j].persons[k].invoices.length; l++) {
            // sub total per person
            data.companies[i].offices[j].persons[k].debtAmount += data.companies[i].offices[j].persons[k].invoices[l].debtAmount;
            data.companies[i].offices[j].persons[k].financedAmount += data.companies[i].offices[j].persons[k].invoices[l].financedAmount;
            data.companies[i].offices[j].persons[k].paidServiceFee += data.companies[i].offices[j].persons[k].invoices[l].paidServiceFee;
            data.companies[i].offices[j].persons[k].invoiceCommission += data.companies[i].offices[j].persons[k].invoices[l].invoiceCommission;
          }

          // sub total per office
          data.companies[i].offices[j].debtAmount += data.companies[i].offices[j].persons[k].debtAmount;
          data.companies[i].offices[j].financedAmount += data.companies[i].offices[j].persons[k].financedAmount;
          data.companies[i].offices[j].paidServiceFee += data.companies[i].offices[j].persons[k].paidServiceFee;
          data.companies[i].offices[j].invoiceCommission += data.companies[i].offices[j].persons[k].invoiceCommission;
        }

        // sub total per company
        data.companies[i].debtAmount += data.companies[i].offices[j].debtAmount;
        data.companies[i].financedAmount += data.companies[i].offices[j].financedAmount;
        data.companies[i].paidServiceFee += data.companies[i].offices[j].paidServiceFee;
        data.companies[i].invoiceCommission += data.companies[i].offices[j].invoiceCommission;
      }

      // grand total
      data.debtAmount += data.companies[i].debtAmount;
      data.financedAmount += data.companies[i].financedAmount;
      data.paidServiceFee += data.companies[i].paidServiceFee;
      data.invoiceCommission += data.companies[i].invoiceCommission;
    }

    // Sort companies, offices and persons
    for (let i = 0; i < data.companies.length; i++) {
      for (let j = 0; j < data.companies[i].offices.length; j++) {
        data.companies[i].offices[j].persons = data.companies[i].offices[j].persons.sort((a, b) => customSort(a.name, b.name));
      }
      data.companies[i].offices = data.companies[i].offices.sort((a, b) => customSort(a.name, b.name));
    }
    data.companies = data.companies.sort((a, b) => customSort(a.name, b.name));

    return data;
  }

  const data = reorganizeData();

  return (
    <React.Fragment>
      <Box py={5}> 
        <Box component="div" overflow="auto">
          <Table>
            <StyledTableHeadComponent>
              <StyledTableRowComponent>
                {columnData.map(column => (
                  <StyledTableCellTextComponent align={column.align} key={column.id} padding="none" data-walkme={column.walkme || ''}>
                    <IntlMessageComponent id={column.translationKey} />
                  </StyledTableCellTextComponent>
                ))}
              </StyledTableRowComponent>
              <StyledTableCellNumbersComponent padding="space"/>
            </StyledTableHeadComponent>

            <TableBody bgcolor="white">
              {data.companies.length === 0 ? (
                <StyledTableRowComponent>
                  <StyledTableCellTextComponent colSpan={columnData.length}>
                    <Box textAlign="center">
                      <IntlMessageComponent id="component.isos.commissions.invoice.list.noData" />
                    </Box>
                  </StyledTableCellTextComponent>
                </StyledTableRowComponent>
              ) : (
                <React.Fragment>
                  <StyledTableRowComponent selected>
                    <StyledTableCellTextComponent padding="none" colSpan={12}>
                      <b><IntlMessageComponent id="component.isos.commissions.invoice.list.grandTotal" /></b>
                    </StyledTableCellTextComponent>
                    <StyledTableCellNumbersComponent  padding="none"><b><AmountFormatterComponent amount={data.debtAmount} /></b></StyledTableCellNumbersComponent>
                    <StyledTableCellNumbersComponent  padding="none"><b><AmountFormatterComponent amount={data.financedAmount} /></b></StyledTableCellNumbersComponent>
                    <StyledTableCellNumbersComponent  padding="none"><b><AmountFormatterComponent amount={data.paidServiceFee} /></b></StyledTableCellNumbersComponent>
                    <StyledTableCellNumbersComponent  padding="none"><b><AmountFormatterComponent amount={data.invoiceCommission} /></b></StyledTableCellNumbersComponent>
                    <StyledTableCellNumbersComponent padding="space"/>
                  </StyledTableRowComponent>

                  {data.companies.map((company, indexCompany) => (
                    <React.Fragment key={`invoice-company-${indexCompany}`}>
                      {company.offices.map((office, indexOffice) => (
                        <React.Fragment key={`invoice-office-${indexOffice}`}>
                          {office.persons.map((person, indexPerson) => (
                            <React.Fragment key={`invoice-person-${indexPerson}`}>
                              {person.invoices.map((invoice, index) => (
                                <StyledTableRowComponent key={`invoice-${index}`}>
                                  <StyledTableCellTextComponent padding="none">{invoice.isoCompany}</StyledTableCellTextComponent>
                                  <StyledTableCellTextComponent padding="none">{invoice.isoOffice}</StyledTableCellTextComponent>
                                  <StyledTableCellTextComponent padding="none">{invoice.isoPerson}</StyledTableCellTextComponent>
                                  <StyledTableCellTextComponent padding="none">{invoice.sellerCompany}</StyledTableCellTextComponent>
                                  <StyledTableCellTextComponent padding="none">{invoice.invoiceId}</StyledTableCellTextComponent>
                                  <StyledTableCellTextComponent padding="none"><DebtorGradeForBuyerComponent value={invoice.debtorInsurerRating} /></StyledTableCellTextComponent>
                                  <StyledTableCellTextComponent padding="none"><DateFormatterComponent date={invoice.createdDate} /></StyledTableCellTextComponent>
                                  <StyledTableCellTextComponent padding="none"><DateFormatterComponent date={invoice.listedDate} /></StyledTableCellTextComponent>
                                  <StyledTableCellTextComponent padding="none"><DateFormatterComponent date={invoice.financedDate} /></StyledTableCellTextComponent>
                                  <StyledTableCellTextComponent padding="none"><DateFormatterComponent date={invoice.closedDate} /></StyledTableCellTextComponent>
                                  <StyledTableCellTextComponent padding="none"><BooleanFormatterComponent value={invoice.isClaim} /></StyledTableCellTextComponent>
                                  <StyledTableCellTextComponent padding="none"><BooleanFormatterComponent value={invoice.isFirstInvoice} /></StyledTableCellTextComponent>
                                  <StyledTableCellNumbersComponent  padding="none"><AmountFormatterComponent amount={invoice.debtAmount} /></StyledTableCellNumbersComponent>
                                  <StyledTableCellNumbersComponent  padding="none"><AmountFormatterComponent amount={invoice.financedAmount} /></StyledTableCellNumbersComponent>
                                  <StyledTableCellNumbersComponent  padding="none"><AmountFormatterComponent amount={invoice.paidServiceFee} /></StyledTableCellNumbersComponent>
                                  <StyledTableCellNumbersComponent  padding="none"><AmountFormatterComponent amount={invoice.invoiceCommission} /></StyledTableCellNumbersComponent>
                                  <StyledTableCellNumbersComponent padding="space"/>
                                </StyledTableRowComponent>
                              ))}

                              {person.id > 0 && person.name !== '' &&
                                <StyledTableRowComponent selected>
                                  <StyledTableCellTextComponent padding="none" colSpan={12}>
                                    <b><IntlMessageComponent id="component.isos.commissions.invoice.list.personSubTotal" values={{name: person.name}} /></b>
                                  </StyledTableCellTextComponent>
                                  <StyledTableCellNumbersComponent padding="none"><b><AmountFormatterComponent amount={person.debtAmount} /></b></StyledTableCellNumbersComponent>
                                  <StyledTableCellNumbersComponent padding="none"><b><AmountFormatterComponent amount={person.financedAmount} /></b></StyledTableCellNumbersComponent>
                                  <StyledTableCellNumbersComponent padding="none"><b><AmountFormatterComponent amount={person.paidServiceFee} /></b></StyledTableCellNumbersComponent>
                                  <StyledTableCellNumbersComponent padding="none"><b><AmountFormatterComponent amount={person.invoiceCommission} /></b></StyledTableCellNumbersComponent>
                                  <StyledTableCellNumbersComponent padding="space"/>
                                </StyledTableRowComponent>
                              }
                            </React.Fragment>
                          ))}

                          {office.id > 0 && office.name !== '' &&
                            <StyledTableRowComponent selected>
                              <StyledTableCellTextComponent padding="none" colSpan={12}>
                                <b><IntlMessageComponent id="component.isos.commissions.invoice.list.officeSubTotal" values={{name: office.name}} /></b>
                              </StyledTableCellTextComponent>
                              <StyledTableCellNumbersComponent padding="none"><b><AmountFormatterComponent amount={office.debtAmount} /></b></StyledTableCellNumbersComponent>
                              <StyledTableCellNumbersComponent padding="none"><b><AmountFormatterComponent amount={office.financedAmount} /></b></StyledTableCellNumbersComponent>
                              <StyledTableCellNumbersComponent padding="none"><b><AmountFormatterComponent amount={office.paidServiceFee} /></b></StyledTableCellNumbersComponent>
                              <StyledTableCellNumbersComponent padding="none"><b><AmountFormatterComponent amount={office.invoiceCommission} /></b></StyledTableCellNumbersComponent>
                              <StyledTableCellNumbersComponent padding="space"/>
                            </StyledTableRowComponent>
                          }
                        </React.Fragment>
                      ))}

                      <StyledTableRowComponent selected>
                        <StyledTableCellTextComponent padding="none" colSpan={12}>
                          <b><IntlMessageComponent id="component.isos.commissions.invoice.list.companySubTotal" values={{name: company.name}} /></b>
                        </StyledTableCellTextComponent>
                        <StyledTableCellNumbersComponent padding="none"><b><AmountFormatterComponent amount={company.debtAmount} /></b></StyledTableCellNumbersComponent>
                        <StyledTableCellNumbersComponent padding="none"><b><AmountFormatterComponent amount={company.financedAmount} /></b></StyledTableCellNumbersComponent>
                        <StyledTableCellNumbersComponent padding="none"><b><AmountFormatterComponent amount={company.paidServiceFee} /></b></StyledTableCellNumbersComponent>
                        <StyledTableCellNumbersComponent padding="none"><b><AmountFormatterComponent amount={company.invoiceCommission} /></b></StyledTableCellNumbersComponent>
                        <StyledTableCellNumbersComponent padding="space"/>                      
                      </StyledTableRowComponent>
                    </React.Fragment>
                  ))}
                </React.Fragment>
              )}
            </TableBody>
          </Table>
        </Box>
      </Box>
    </React.Fragment>
  );
}
