/* @flow */

export default class UserCompanyRoleISO {
  canExecute: boolean;
  isISOPortalActive: boolean;
  maximumCouponAmount: number;
  role: number;  
  sellingTypeISO: number;
  workingWithMandates: boolean;
}
