/* @flow */

export default class DocumentType {
  typeId: number;
  code: string;

  constructor(typeId: number, code: string) {
    this.typeId = typeId;
    this.code = code;
  }
}
