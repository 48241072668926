/* @flow */

import React from 'react';

import ISORegisterSellersComponent from 'app/isos/registerSellers';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function ISORegisterSellersPage() {
  return (
    <WebsitePageComponent id="page.iso.company.register.h1" data-walkme="registerseller-title-registernewseller" noPaper>
      <ISORegisterSellersComponent />
    </WebsitePageComponent>
  );
}
