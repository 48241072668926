/* @flow */

import React from 'react';
import { connect } from 'react-redux';

import IntlMessageComponent from 'components/formatters/intlMessage';
import WizardComponent from 'components/wizard';

import ISORegisterLeadsTab1Component from './tab1/index';
import ISORegisterLeadsTab2Component from './tab2/index';
import ISORegisterLeadsTab3Component from './tab3/index';
import ISORegisterLeadsTab4Component from './tab4/index';
import ISORegisterLeadsTab5Component from './tab5/index';
import ISORegisterLeadsTab6Component from './tab6/index';

import DebtorCompany from 'models/companies/debtorCompany';

type ISORegisterLeadsComponentProps = {
  activeCompanyId: number;
}

type ISORegisterLeadsComponentState = {
  activeStepId: number;
  companyId: number;
  debtorCompany: DebtorCompany;
  leadId: number;
  steps: {id: number, title: IntlMessageComponent}[];
}

class ISORegisterLeadsComponent extends React.Component<ISORegisterLeadsComponentProps, ISORegisterLeadsComponentState> {
  static TAB1 = 0;
  static TAB2 = 1;
  static TAB3 = 2;
  static TAB4 = 3;
  static TAB5 = 4;
  static TAB6 = 5;

  constructor(props) {
    super(props);

    this.state = {
      activeStepId: ISORegisterLeadsComponent.TAB1,
      companyId: 0,
      debtorCompany: null,
      leadId: 0,
      steps: this.getSteps()
    };
  }

  getSteps = () => {
    const tabsNumbers = [ISORegisterLeadsComponent.TAB1, ISORegisterLeadsComponent.TAB2, ISORegisterLeadsComponent.TAB3, ISORegisterLeadsComponent.TAB4, ISORegisterLeadsComponent.TAB5, ISORegisterLeadsComponent.TAB6];
    return tabsNumbers.map(num => ({id: num, title: <IntlMessageComponent id={`page.iso.registerLead.tab${num + 1}.header`} />}));
  }

  getStepContent = () => {
    switch (this.state.activeStepId) {
      case ISORegisterLeadsComponent.TAB1:
        return (
          <ISORegisterLeadsTab1Component
            successHandler={this.successHandlerTab1}
          />
        );
      case ISORegisterLeadsComponent.TAB2:
        return (
          <ISORegisterLeadsTab2Component
            companyId={this.state.companyId}
            successHandler={this.successHandler}
            skipHandler={this.successHandler}
          />
        );
      case ISORegisterLeadsComponent.TAB3:
        return (
          <ISORegisterLeadsTab3Component
            companyId={this.state.companyId}
            successHandler={this.successHandlerTab3}
            skipHandler={this.successHandler}
          />
        );
      case ISORegisterLeadsComponent.TAB4:
        return (
          <ISORegisterLeadsTab4Component
            companyId={this.state.companyId}
            successHandler={this.successHandler}
            skipHandler={this.successHandler}
          />
        );
      case ISORegisterLeadsComponent.TAB5:
        return (
          <ISORegisterLeadsTab5Component
            companyId={this.state.companyId}
            successHandler={this.successHandler}
            skipHandler={this.successHandler}
          />
        );
      case ISORegisterLeadsComponent.TAB6:
        return (
          <ISORegisterLeadsTab6Component
            debtorCompany={this.state.debtorCompany}
            leadId={this.state.leadId}
          />
        );
      default:
        return 'Unknown step';
    }
  };

  successHandlerTab1 = (companyId: number, leadId: number) => this.setState({activeStepId: ISORegisterLeadsComponent.TAB2, companyId, leadId});
  successHandlerTab3 = (debtorCompany: DebtorCompany) => this.setState({activeStepId: ISORegisterLeadsComponent.TAB4, debtorCompany});
  successHandler = () => this.setState(prevState => ({activeStepId: prevState.activeStepId + 1 }));

  render() {
    return (
      <WizardComponent
        activeStepId={this.state.activeStepId}
        steps={this.state.steps}
      >
        {this.getStepContent()}
      </WizardComponent>
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps)(ISORegisterLeadsComponent);

