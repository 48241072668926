/* @flow */

import React from 'react';
import {useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import LoadingOverlay from 'react-loading-overlay';

import IntlMessageComponent from 'components/formatters/intlMessage';

const useStyles = makeStyles(_ => ({
  root: {
    height: '100%',
    width: '100%',
    position: 'fixed',
    left: 0,
    top: 0,
    overflowX: 'hidden'
  }
}));

export default function LoadingOverlayComponent(props) {
  const isLoading = useSelector(state => state.auth.loader);
  const classes = useStyles();

  return (
    <LoadingOverlay 
      active={isLoading}
      className={classes.root}
      spinner
      styles={{overlay: base => ({...base, zIndex: 1400})}}
      text={<IntlMessageComponent id="general.loading" />}
    >
      {props.children}
    </LoadingOverlay>
  );
}
