/* @flow */

import React from 'react';

import ISOMandatesComponent from 'app/isos/mandates';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function ISOMandatesPage() {
  return (
    <WebsitePageComponent noPaper id="page.iso.mandates.title" data-walkme="isomandates-title-mandates">
      <ISOMandatesComponent />
    </WebsitePageComponent>
  );
}
