/* @flow */

import { plainToClass } from 'class-transformer';

import BaseService from './api/baseService';

import {
  INVOICE_SELLER_DETAILS_GET,
  INVOICE_SELLER_TOPAY_GET,
  INVOICE_SELLER_PROPOSAL_GET,
  INVOICE_SELLER_LIST_GET,
  INVOICE_SELLER_LIST_OPEN_GET,
  INVOICE_SELLER_STATUS_GET,
  INVOICE_SELLER_INFO_GET,
  INVOICE_SELLER_DRAFT_GET,
  INVOICE_SELLER_INSERT_POST,
  INVOICE_SELLER_UPDATE_POST,
  INVOICE_SELLER_CONTACT_PERSONS_GET,
  INVOICE_SELLER_CONTACT_PERSONS_POST,
  INVOICE_SELLER_DOCUMENTS_GET,
  INVOICE_SELLER_NOTES_GET,
  INVOICE_SELLER_SUMMARY_GET,
  INVOICE_SELLER_FLEX_SUBMIT_POST,
  INVOICE_SELLER_FIX_SUBMIT_POST,
  INVOICE_SELLER_CANCEL_POST,
  INVOICE_CONFIRMING_INFO_GET,
  INVOICE_CONFIRMING_DRAFT_GET,
  INVOICE_CONFIRMING_INSERT_POST,
  INVOICE_CONFIRMING_UPDATE_POST
} from 'constants/apiRoutes';

import Company from 'models/companies/company';
import DebtorCompany from 'models/companies/debtorCompany';
import Document from 'models/documents/document';
import DocumentType from 'models/documents/documentType';
import Invoice from 'models/invoices/seller/invoice';
import InvoiceBills from 'models/invoices/seller/invoiceBills';
import InvoiceCompensation from 'models/invoices/seller/invoiceCompensation';
import InvoiceDetails from 'models/invoices/seller/invoiceDetails';
import InvoiceBatch from 'models/invoices/seller/invoiceBatch';
import InvoiceDraft from 'models/invoices/seller/invoiceDraft';
import InvoiceFixPrices from 'models/invoices/seller/invoiceFixPrices';
import InvoiceFlexPrices from 'models/invoices/seller/invoiceFlexPrices';
import InvoiceInfo from 'models/invoices/seller/invoiceInfo';
import InvoiceNotes from 'models/invoices/seller/invoiceNotes';
import InvoiceProposal from 'models/invoices/seller/invoiceProposal';
import InvoiceStatus from 'models/invoices/seller/invoiceStatus';
import InvoiceSummary from 'models/invoices/seller/invoiceSummary';
import Kpi from 'models/dashboards/kpi';
import Note from 'models/generics/note';
import ProvidedDocument from 'models/documents/providedDocument';

import CancelInvoiceRequest from 'models/requests/sellers/cancelInvoiceRequest';
import InsertInvoiceRequest from 'models/requests/sellers/insertInvoiceRequest';
import SetInvoiceContactPersonsRequest from 'models/requests/sellers/setInvoiceContactPersonsRequest';
import SubmitFixInvoiceRequest from 'models/requests/sellers/submitFixInvoiceRequest';
import SubmitFlexInvoiceRequest from 'models/requests/sellers/submitFlexInvoiceRequest';
import UpdateInvoiceRequest from 'models/requests/sellers/updateInvoiceRequest';

export default class InvoiceSellerService {

  // Load details of an invoice
  static getInvoiceDetails = (companyId: number, invoiceId: number) =>
    BaseService.simpleGET(`${INVOICE_SELLER_DETAILS_GET}/${companyId}/${invoiceId}`,
      data => {
        if (!data.invoice) return null;

        const invoice = plainToClass(InvoiceDetails, data.invoice);
        const batch = this.mapInvoiceBatch(data.batch);
        invoice.debtorCompany = plainToClass(DebtorCompany, data.invoice.debtorCompany);
        if (data.invoice.flexPrices) {
          invoice.flexPrices = plainToClass(InvoiceFlexPrices, data.invoice.flexPrices);
          if (data.invoice.flexPrices.compensations) {
            invoice.flexPrices.compensations = data.invoice.flexPrices.compensations.map(c => plainToClass(InvoiceCompensation, c));
          }
        }
        if (data.invoice.fixFinancedPrices) invoice.fixFinancedPrices = plainToClass(InvoiceFixPrices, data.invoice.fixFinancedPrices);
        if (data.invoice.fixUnfinancedPrices) invoice.fixUnfinancedPrices = plainToClass(InvoiceFixPrices, data.invoice.fixUnfinancedPrices);

        const bills = plainToClass(InvoiceBills, data.bills);

        let kpis = null;
        if (data.kpis) {
          kpis = data.kpis.map(d => plainToClass(Kpi, d));
        }

        return { bills, invoice, batch, kpis };
      });

  static getInvoiceNotes = (companyId: number, invoiceId: number) =>
    BaseService.simpleGET(`${INVOICE_SELLER_NOTES_GET}/${companyId}/${invoiceId}`,
      data => {
        const notes = plainToClass(InvoiceNotes, data);
        notes.actions = data.actions.map(n => plainToClass(Note, n));
        return notes;
      });

  static getInvoicesWithFeesToPay = (companyId: number) =>
    BaseService.simpleGET(`${INVOICE_SELLER_TOPAY_GET}/${companyId}`,
      data => data.map(d => {
        const summary = plainToClass(InvoiceSummary, d);
        summary.debtorCompany = plainToClass(DebtorCompany, d.debtorCompany);
        if (d.flexPrices) summary.flexPrices = plainToClass(InvoiceFlexPrices, d.flexPrices);
        if (d.fixFinancedPrices) summary.fixFinancedPrices = plainToClass(InvoiceFixPrices, d.fixFinancedPrices);
        if (d.fixUnfinancedPrices) summary.fixUnfinancedPrices = plainToClass(InvoiceFixPrices, d.fixUnfinancedPrices);
        return summary;
      }));

  // Load invoices
  static getInvoices = (companyId: number) =>
    BaseService.simpleGET(`${INVOICE_SELLER_LIST_GET}/${companyId}`,
      data => data.map(d => plainToClass(Invoice, d)));

  static getOpenInvoices = (companyId: number) =>
    BaseService.simpleGET(`${INVOICE_SELLER_LIST_OPEN_GET}/${companyId}`,
      data => data.map(d => plainToClass(Invoice, d)));

  static getInvoiceStatus = (invoiceId: number) =>
    BaseService.simpleGET(`${INVOICE_SELLER_STATUS_GET}/${invoiceId}`,
      data => {
        const status = plainToClass(InvoiceStatus, data);
        status.debtorCompany = plainToClass(DebtorCompany, data.debtorCompany);
        return status;
      });

  static getInvoiceInfo = (sellerCompanyId: number, debtorCompanyId: number) =>
    BaseService.simpleGET(`${INVOICE_SELLER_INFO_GET}/${sellerCompanyId}/${debtorCompanyId}`,
      data => {
        const info = plainToClass(InvoiceInfo, data);
        info.debtorCompany = plainToClass(DebtorCompany, data.debtorCompany);
        info.supplierCompany = plainToClass(Company, data.supplierCompany);
        return info;
      });

  static getInvoiceInfoConfirming = (confirmingCompanyId: number, supplierCompanyId: number) =>
    BaseService.simpleGET(`${INVOICE_CONFIRMING_INFO_GET}/${confirmingCompanyId}/${supplierCompanyId}`,
      data => {
        const info = plainToClass(InvoiceInfo, data);
        info.suppliercompany = plainToClass(Company, data.supplierCompany);
        return info;
      });

  // Load information about an invoice to sell
  static getInvoiceDraft = (invoiceId: number) =>
    BaseService.simpleGET(`${INVOICE_SELLER_DRAFT_GET}/${invoiceId}`,
      data => {
        const draft = plainToClass(InvoiceDraft, data);
        draft.debtorCompany = plainToClass(DebtorCompany, data.debtorCompany);
        return draft;
      });

  // Load information about an invoice to sell
  static getInvoiceDraftConfirming = (invoiceId: number) =>
    BaseService.simpleGET(`${INVOICE_CONFIRMING_DRAFT_GET}/${invoiceId}`,
      data => {
        const draft = plainToClass(InvoiceDraft, data);
        draft.supplierCompany = plainToClass(Company, data.supplierCompany);
        return draft;
      });

  static insertInvoice = (request: InsertInvoiceRequest) =>
    BaseService.simplePOST(INVOICE_SELLER_INSERT_POST, request, null);
  static insertInvoiceConfirming = (request: InsertInvoiceRequest) =>
    BaseService.simplePOST(INVOICE_CONFIRMING_INSERT_POST, request, null);

  // Insert or update invoice to sell
  static updateInvoice = (request: UpdateInvoiceRequest) =>
    BaseService.simplePOST(INVOICE_SELLER_UPDATE_POST, request, null);
  static updateInvoiceConfirming = (request: UpdateInvoiceRequest) =>
    BaseService.simplePOST(INVOICE_CONFIRMING_UPDATE_POST, request, null);

  static getInvoiceContactPersonIds = (invoiceId: number) =>
    BaseService.simpleGET(`${INVOICE_SELLER_CONTACT_PERSONS_GET}/${invoiceId}`,
      data => data);

  static setInvoiceContactPersons = (request: SetInvoiceContactPersonsRequest) =>
    BaseService.simplePOST(INVOICE_SELLER_CONTACT_PERSONS_POST, request, null);

  static getInvoiceProposal = (invoiceId: number) =>
    BaseService.simpleGET(`${INVOICE_SELLER_PROPOSAL_GET}/${invoiceId}`,
      data => {
        const invoice = plainToClass(InvoiceProposal, data);
        invoice.debtorCompany = plainToClass(DebtorCompany, data.debtorCompany);
        if (data.flexPrices) invoice.flexPrices = plainToClass(InvoiceFlexPrices, data.flexPrices);
        if (data.fixFinancedPrices) invoice.fixFinancedPrices = plainToClass(InvoiceFixPrices, data.fixFinancedPrices);
        if (data.fixUnfinancedPrices) invoice.fixUnfinancedPrices = plainToClass(InvoiceFixPrices, data.fixUnfinancedPrices);
        return invoice;
      });

  static submitFlexInvoice = (request: SubmitFlexInvoiceRequest) =>
    BaseService.simplePOST(INVOICE_SELLER_FLEX_SUBMIT_POST, request, null);

  static submitFixInvoice = (request: SubmitFixInvoiceRequest) =>
    BaseService.simplePOST(INVOICE_SELLER_FIX_SUBMIT_POST, request, null);

  static cancelInvoice = (request: CancelInvoiceRequest) =>
    BaseService.simplePOST(INVOICE_SELLER_CANCEL_POST, request, null);

  static getInvoiceSummary = (invoiceId: number) =>
    BaseService.simpleGET(`${INVOICE_SELLER_SUMMARY_GET}/${invoiceId}`,
      data => {
        const summary = plainToClass(InvoiceSummary, data);
        summary.debtorCompany = plainToClass(DebtorCompany, data.debtorCompany);
        summary.supplierCompany = plainToClass(Company, data.supplierCompany);
        if (data.flexPrices) summary.flexPrices = plainToClass(InvoiceFlexPrices, data.flexPrices);
        if (data.fixFinancedPrices) summary.fixFinancedPrices = plainToClass(InvoiceFixPrices, data.fixFinancedPrices);
        if (data.fixUnfinancedPrices) summary.fixUnfinancedPrices = plainToClass(InvoiceFixPrices, data.fixUnfinancedPrices);
        return summary;
      });

  static getInvoiceDocuments = (invoiceId: number) =>
    BaseService.simpleGET(`${INVOICE_SELLER_DOCUMENTS_GET}/${invoiceId}`,
      data => {
        const toClassifyDocumentType = plainToClass(DocumentType, data.toClassifyDocumentType);

        const extraDocuments = data.extraDocuments.map(d => {
          const docType = plainToClass(DocumentType, d);
          return new Document(docType, false);
        });

        const documents = data.requiredDocuments.map(d => {
          const docType = plainToClass(DocumentType, d);
          return new Document(docType, true);
        });

        // providedDocuments -> map to required or add it
        data.providedDocuments.forEach((d) => {
          const providedDocument = plainToClass(ProvidedDocument, d);

          const docFound = documents.find(doc => doc.type.typeId === providedDocument.typeId);
          if (docFound !== undefined) {
            docFound.id = providedDocument.id;
            docFound.filename = providedDocument.filename;
          } else {
            const docType = new DocumentType(providedDocument.typeId, providedDocument.code);
            const document = new Document(docType, false);
            document.id = providedDocument.id;
            document.filename = providedDocument.filename;
            documents.push(document);
          }
        });
        return { documents, extraDocuments, toClassifyDocumentType };
      });

  static mapInvoiceBatch = (batch) => {
    const batchArray = [];
    batch.forEach((invoiceBatch) => {
      const mappedInvoiceBatch = plainToClass(InvoiceBatch, invoiceBatch);
      batchArray.push(mappedInvoiceBatch);
    })
    return batchArray;
  }
}
