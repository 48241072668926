/* @flow */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function IconInvoiceDue25Days(props) {
  return (
    <SvgIcon viewBox="0 0 57.38 61.08" {...props}>
      <g>
        <path d="M234.17,471.32a.61.61,0,0,1-.17-.42v-4.8h-4.7a.58.58,0,0,1-.59-.59v-1.33a.58.58,0,0,1,.59-.59H234v-4.65a.56.56,0,0,1,.17-.43.6.6,0,0,1,.42-.16h1.48a.57.57,0,0,1,.43.16.56.56,0,0,1,.16.43v4.65h4.7a.57.57,0,0,1,.42.17.57.57,0,0,1,.17.42v1.33a.6.6,0,0,1-.16.42.56.56,0,0,1-.43.17h-4.7v4.8a.6.6,0,0,1-.16.42.58.58,0,0,1-.43.17h-1.48A.61.61,0,0,1,234.17,471.32Z" transform="translate(-222.22 -424.97)"/>
        <path d="M243.63,472.13a.54.54,0,0,1-.17-.42v-1.28a1.39,1.39,0,0,1,.61-1.15l3.84-3.82c1.17-.92,2.06-1.67,2.7-2.25a8.1,8.1,0,0,0,1.41-1.62,2.7,2.7,0,0,0,.47-1.45,2.57,2.57,0,0,0-.62-1.82,2.43,2.43,0,0,0-1.87-.66,2.57,2.57,0,0,0-1.93.72,3.3,3.3,0,0,0-.87,1.89.71.71,0,0,1-.29.43.81.81,0,0,1-.45.13h-2.22a.51.51,0,0,1-.37-.15.48.48,0,0,1-.14-.37,5.58,5.58,0,0,1,3-4.76,6.68,6.68,0,0,1,3.3-.77,7.58,7.58,0,0,1,3.35.67,4.63,4.63,0,0,1,2.06,1.86,5.23,5.23,0,0,1,.69,2.69,5.15,5.15,0,0,1-.92,3,13.53,13.53,0,0,1-2.87,2.86l-3.42,3.48h7a.58.58,0,0,1,.61.61v1.77a.53.53,0,0,1-.17.43.6.6,0,0,1-.44.16H244.05A.54.54,0,0,1,243.63,472.13Z" transform="translate(-222.22 -424.97)"/>
        <path d="M261.64,471.88a5.21,5.21,0,0,1-2.26-1.82,4.53,4.53,0,0,1-.81-2.43v-.05a.39.39,0,0,1,.16-.33.51.51,0,0,1,.36-.14h2.34a.66.66,0,0,1,.71.49,2.85,2.85,0,0,0,1.14,1.6,3.39,3.39,0,0,0,1.89.52,3.21,3.21,0,0,0,2.28-.84,3,3,0,0,0,.89-2.31,2.82,2.82,0,0,0-.89-2.16,3.24,3.24,0,0,0-2.28-.82,3.18,3.18,0,0,0-1.21.2,4.11,4.11,0,0,0-.91.54,1,1,0,0,0-.22.15,1.41,1.41,0,0,1-.31.17.87.87,0,0,1-.28.05h-2.32a.55.55,0,0,1-.54-.54l.84-8.4a.72.72,0,0,1,.74-.69h9.25a.62.62,0,0,1,.44.16.61.61,0,0,1,.16.43v1.68a.6.6,0,0,1-.16.41.56.56,0,0,1-.44.18H263.1l-.39,4a5.21,5.21,0,0,1,1.3-.61,6.71,6.71,0,0,1,1.92-.23,6.6,6.6,0,0,1,3,.67,5.5,5.5,0,0,1,2.19,1.95,5.26,5.26,0,0,1,.82,2.94,5.86,5.86,0,0,1-.83,3.14,5.58,5.58,0,0,1-2.37,2.07,8.2,8.2,0,0,1-3.54.72A8.33,8.33,0,0,1,261.64,471.88Z" transform="translate(-222.22 -424.97)"/>
      </g>
      <path d="M236.37,428.57a19.49,19.49,0,0,1,.56-3.43c.07-.22,1.16-.17,1.78-.15,2.72.11.6,2.34,1.79,3.49.17,0,.73.08,1.29.08,6.05,0,12.11,0,18.16,0,1.45,0,2.09-.34,1.86-1.85a10.16,10.16,0,0,1,0-1.67h3.4v3.49h6.11c5.47,0,8.3,2.85,8.3,8.33q0,20.36,0,40.7a13.65,13.65,0,0,1-.39,3.44,6.74,6.74,0,0,1-6.3,4.92c-3.2.11-6.4.07-9.59.07-10.9,0-21.8.05-32.7,0a12.2,12.2,0,0,1-5-1.13,6.07,6.07,0,0,1-3.33-5.42c-.1-1.54-.07-3.1-.07-4.65q0-19.11,0-38.22c0-5.09,2.92-8,8-8h6.11Zm-10.52,18c0,.55-.07,1-.07,1.4,0,10.12.06,20.24,0,30.36a4,4,0,0,0,4.33,4.25c13.9,0,27.79,0,41.69,0,2.9,0,4.33-1.47,4.34-4.39q0-15,0-30.06c0-.51,0-1-.07-1.54Zm0-3.66h50.27c0-2.57.05-5,0-7.5a3.52,3.52,0,0,0-3-3.09c-2.55-.11-5.11,0-7.77,0l-.19,3.47h-3.3v-1.63c0-1.86,0-1.86-1.93-1.87H240v3.39h-3.5v-3.35c-2.68,0-5.24-.08-7.79,0a3.35,3.35,0,0,0-2.91,2.81C225.76,437.68,225.81,440.24,225.81,442.89Z" transform="translate(-222.22 -424.97)"/>
    </SvgIcon>
  );
}
