/* @flow */

import React from 'react';

import ConfirmingAddSupplierComponent from 'app/confirming/addSupplier';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function ConfirmingBillPortfolioPage() {
  return (
    <WebsitePageComponent id="confirming.addsupplier.title" noPaper>
      <ConfirmingAddSupplierComponent />
    </WebsitePageComponent>
  );
}
