/* @flow */

import CommissionMsfIsoPerson from './commissionMsfIsoPerson';

export default class CommissionMsfIsoOffice {
  id: number;
  name: string;
  persons: CommissionMsfIsoPerson[];
  msfPayment: number;
  revenues: number;
  paidRevenues: number;
  msfCommission: number;

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
    this.persons = [];
    this.msfPayment = 0;
    this.revenues = 0;
    this.paidRevenues = 0;
    this.msfCommission = 0;
  }
}
