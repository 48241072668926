/* @flow */

import React from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';

import IntlMessageComponent from 'components/formatters/intlMessage';

type ProcurationPickerComponentProps = {
  companyIsBuyer: boolean;
  companyIsDebtor: boolean;
  companyIsISO: boolean;
  companyIsSeller: boolean;
  companyIsConfirming: boolean;
  error: boolean;
  fullWidth: boolean;
  helperText: IntlMessageComponent;
  margin: string;
  required: boolean;
  values: string[];
  handleChange: (fieldName: string) => (values: string[]) => void;
}

export default function ProcurationPickerComponent(props: ProcurationPickerComponentProps) {
  const hasProcurationAsBuyer = props.values.find(v => v === 'hasProcurationAsBuyer') !== undefined;
  const hasProcurationAsDebtor = props.values.find(v => v === 'hasProcurationAsDebtor') !== undefined;
  const hasProcurationAsISO = props.values.find(v => v === 'hasProcurationAsISO') !== undefined;
  const hasProcurationAsSeller = props.values.find(v => v === 'hasProcurationAsSeller') !== undefined;
  const hasProcurationAsConfirming = props.values.find(v => v === 'hasProcurationAsConfirming') !== undefined;

  const onChange = (event) => {    
    const values = [];

    props.values.forEach(v => {
      if (v !== event.target.name) 
        values.push(v);
    });

    if (event.target.checked)
      values.push(event.target.name);

    props.handleChange(values);
  };

  return (
    <FormControl component="fieldset" error={props.error} fullWidth margin="normal" required={props.required}>
      <FormLabel component="legend">{<IntlMessageComponent id="form.hasProcuration"/>}</FormLabel>
      
      <FormGroup>
        {props.companyIsBuyer &&
          <FormControlLabel
            control={<Checkbox checked={hasProcurationAsBuyer} onChange={onChange} name="hasProcurationAsBuyer" />}
            label={<IntlMessageComponent id="form.hasProcurationAsBuyer"/>}
          />
        }
        {props.companyIsDebtor &&
          <FormControlLabel
            control={<Checkbox checked={hasProcurationAsDebtor} onChange={onChange} name="hasProcurationAsDebtor" />}
            label={<IntlMessageComponent id="form.hasProcurationAsDebtor"/>}
          />
        }
        {props.companyIsISO &&
          <FormControlLabel
            control={<Checkbox checked={hasProcurationAsISO} onChange={onChange} name="hasProcurationAsISO" />}
            label={<IntlMessageComponent id="form.hasProcurationAsISO"/>}
          />
        }
        {props.companyIsSeller &&
          <FormControlLabel
            control={<Checkbox checked={hasProcurationAsSeller} onChange={onChange} name="hasProcurationAsSeller" />}
            label={<IntlMessageComponent id="form.hasProcurationAsSeller"/>}
          />
        }
        {props.companyIsConfirming &&
          <FormControlLabel
            control={<Checkbox checked={hasProcurationAsConfirming} onChange={onChange} name="hasProcurationAsConfirming" />}
            label={<IntlMessageComponent id="form.hasProcurationAsConfirming"/>}
          />
        }
      </FormGroup>
      {props.error && <FormHelperText>{props.helperText}</FormHelperText>}
    </FormControl>
  );
}