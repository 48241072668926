/* @flow */

import React from 'react';

import SellerDashboardComponent from 'app/sellers/dashboard';
import WebsiteDashboardPageComponent from 'components/websiteStructure/pages/dashboard';

export default function SellerDashboardPage() {
  return (
    <WebsiteDashboardPageComponent id="seller.dashboard.title">
      <SellerDashboardComponent />
    </WebsiteDashboardPageComponent>
  );
}
