/* @flow */

import React from 'react';
import {compose} from 'redux'
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import qs from 'qs';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import {initializeWebSiteAction} from 'store/actions/localeActions';

type WebsiteInitializerProps = {
  websiteInit: boolean;
  websiteInitError: string;
  initializeWebSiteAction: (string) => void;
}

class WebsiteInitializer extends React.Component<WebsiteInitializerProps, {}> {

  componentDidMount = async () => {
    if (this.props.websiteInit) return;
    
    // Parse Query parameters
    const queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

    if (queryParams.campaign !== undefined) { sessionStorage.setItem('campaign', queryParams.campaign); }

    // Load website languages
    const locale = queryParams.locale !== undefined ? queryParams.locale : null;
    this.props.initializeWebSiteAction(locale);
  }

  render() {
    if (this.props.websiteInit && this.props.websiteInitError === null) {
      return <React.Fragment>{this.props.children}</React.Fragment>;
    }

    return (
      <Box height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
        {!this.props.websiteInit && this.props.websiteInitError === null && <CircularProgress />}
        {this.props.websiteInitError !== null && <span>ERROR LOADING WEBSITE</span>}
      </Box>
    );
  }
}

const mapStateToProps = state => ({
  websiteInit: state.locale.websiteInit,
  websiteInitError: state.locale.websiteInitError
});

const enhance = compose(
  withRouter,
  connect(mapStateToProps, {initializeWebSiteAction})
);

export default enhance(WebsiteInitializer);
