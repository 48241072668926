/* @flow */

export default class RegisterReferralResponse {
  static REFERRAL_STATUS_OK = 0;
  static REFERRAL_STATUS_NOT_REFERABLE = 1;
  static REFERRAL_STATUS_ALREADY_REFERRED = 2;
  static REFERRAL_STATUS_ALREADY_USED_EMAIL = 3;

  refereeCompanyId: number;
  referralStatus: number;
}
