/* @flow */

import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import {useSelector} from 'react-redux';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import IntlMessageComponent from 'components/formatters/intlMessage';
import SecurityComponent from 'components/security';

import { debtorMenuData } from './data';

const useStyles = makeStyles(theme => ({
  menuText: {
    marginLeft: '.5em',
    verticalAlign: 'middle',
    color: 'black',
  },
  menuIcon: {
    verticalAlign: 'middle',
    marginLeft: '.4em',
  },
  navMenu: {
    position: 'relative',
    fontSize: '14px',
    listStyle: 'outside none none',
    margin: '0',
    padding: '16px 20px',
    borderTop: `1px solid ${theme.palette.lightgrey.main}`,
  },
  menuTextChildren: {
    fontWeight: 'lighter',
    color: 'black',
    marginLeft: '3em'
  }
}));

export default function DebtorWebsiteMenuComponent() {
  const [openMenus, setOpenMenus] = useState(['acordion-1', 'acordion-2']);
  const roleDebtor = useSelector(state => state.auth.user.activeCompany.roleDebtor);
  const classes = useStyles();

  const onChange = (menus) => setOpenMenus(menus);

  const isOpen = (key) => {
    const menu = openMenus.find(m => m === key);
    return menu !== undefined;
  }

  const notcollapsed = [];
  for (let i = 0; i < debtorMenuData.length; i++) {
    if (debtorMenuData[i].type === 'sub-menu' && !debtorMenuData[i].collapsed) {
      notcollapsed.push(debtorMenuData[i].id);
    }
  }

  return (
    <Accordion
      allowMultipleExpanded
      allowZeroExpanded
      preExpanded={notcollapsed}
      onChange={onChange}
    >
      {debtorMenuData.map((menu, index) => {
        if (menu.type && menu.type === 'sub-menu') {
          return (
            <SecurityComponent key={`sidenav-element-${index}`} pageName={menu.pageName} elementName={menu.elementName}>
              <AccordionItem key={`sidenav-element-${index}`} uuid={menu.id} className={classes.navMenu}>
                <AccordionItemHeading className={`menu no-arrow ${isOpen(menu.id) ? 'up' : 'down'} `}>
                  <AccordionItemButton>
                    <Box>
                      {menu.icon}
                      <Box component="span" className={`nav-text menu-debtor ${classes.menuText}`}>
                        <b><IntlMessageComponent id={menu.messageId} /></b>
                      </Box>
                    </Box>
                  </AccordionItemButton>
                </AccordionItemHeading>

                {menu.children && menu.children.map((element, index2) => (
                  <SecurityComponent checkExecute={element.checkExecute} checkRoleExecute={element.checkRoleExecute} key={`sidenav-element-${index}-${index2}`} pageName={element.pageName} elementName={element.elementName}>
                    <AccordionItemPanel>
                      <NavLink to={element.link}>
                        <Box component="span" className={`nav-text menu-debtor ${classes.menuTextChildren}`}>
                          <ArrowForwardIosOutlinedIcon style={{ fontSize: 8 }} color="primary" />
                          <Box component="span" ml={1}><b><IntlMessageComponent id={element.messageId} /></b></Box>
                        </Box>
                      </NavLink>
                    </AccordionItemPanel>
                  </SecurityComponent>
                ))}
              </AccordionItem>
            </SecurityComponent>
          );
        }

        return (
          <SecurityComponent pageName={menu.pageName} elementName={menu.elementName} key={`sidenav-element-${index}`}>
            <AccordionItem className={`menu no-arrow ${classes.navMenu}`} uuid={menu.id}>
              <NavLink to={menu.link}>
                {menu.icon}
                <Box component="span" className={`nav-text menu-iso ${classes.menuText}`}>
                  <b><IntlMessageComponent id={menu.messageId} /></b>
                </Box>
              </NavLink>
            </AccordionItem>
          </SecurityComponent>
        );
      })}
    </Accordion>
  );
}
