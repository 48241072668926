/* @flow */

import {withStyles} from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';

const StyledTableRowComponent = withStyles(_ => ({
  root: {
    height: '48px'
  },
  head: {
    height: '56px'
  }
}))(TableRow);

export default StyledTableRowComponent;
