/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Search from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';

import CompanyItemComponent from 'components/company';
import CountryFieldComponent from 'components/formFields/countries/countryImpl';
import IntlMessageComponent from 'components/formatters/intlMessage';
import MaintenanceSmallComponent from 'components/maintenance/small';

type CompanyLookupBaseViewComponentProps = {
  companies: Company[];
  companyErrorLabelId: string;
  companyNotFound: boolean;
  countries: Country[];
  countriesLoading: boolean;
  error: boolean;
  form: CompanyLookupForm;
  isProcessing: boolean;
  required: boolean;
  vatInvalid: boolean;
  handleCompanyNameChange: () => void;
  handleCompanySelect: () => void;
  handleCountryChange: () => void;
  handleVATChange: () => void;
  lookForCompanyByVATNumber: () => void;
}

export default function CompanyLookupBaseViewComponent(props: CompanyLookupBaseViewComponentProps) {
  const showVATAdornment = props.form.country && ['BE', 'NL', 'PT'].includes(props.form.country.code);
  const hideVATCountry = props.form.country && ['US'].includes(props.form.country.code);

  return (
    <Box px={4} py={3} bgcolor="lightblue.main">
      <MaintenanceSmallComponent />

      <CountryFieldComponent
        countries={props.countries}
        isLoading={props.countriesLoading}
        label={<IntlMessageComponent id="form.company.country.label" />}
        required={props.required}
        value={props.form.country ? props.form.country.id : 0}
        changeHandler={props.handleCountryChange}
      />

      {props.form.country &&
        <Box>
          <TextField
            id="form.company.name"
            label={<IntlMessageComponent id="component.companyLookup.name.label" />}
            data-walkme="inscrire-field-entreprise"
            value={props.form.name}
            onChange={props.handleCompanyNameChange}
            fullWidth
            margin="normal"
          />
          
          {props.form.country && props.form.country.code !== 'DE' &&
            <TextField
              id="adornment-vat"
              data-walkme="sellinvoice-field-tva"
              label={<IntlMessageComponent id={`Component.companyLookup.VAT.label-${props.form.country.id}`} />}
              error={props.vatInvalid}
              helperText={props.vatInvalid ? <IntlMessageComponent id={`component.companyLookup.vat.format-${props.form.country.id}`} /> : ''}
              value={props.form.vat}
              onChange={props.handleVATChange}
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: (showVATAdornment || props.form.vat.length > 0) }}
              InputProps={{
                startAdornment: (showVATAdornment && <InputAdornment position="start">{props.form.country.code}</InputAdornment>),
                endAdornment: ((props.form.vat.length > 0 && !props.vatInvalid) &&
                  <InputAdornment position="end">
                    <IconButton aria-label="Send request" onClick={props.lookForCompanyByVATNumber}><Search color="primary" /></IconButton>
                  </InputAdornment>
                )
              }}
            />
          }

          {props.isProcessing &&
            <Box mt={5} align="center">
              <CircularProgress />
            </Box>
          }

          {!props.isProcessing && props.companies.length > 0 &&
            <Box mt={5}>
              {props.companies && props.companies.map((company, index) => (
                <CompanyItemComponent
                  buttonLabelId="general.select"
                  company={company}
                  key={`companyItem-${index}`}
                  selectHandler={props.handleCompanySelect}
                  hideVATCountry={hideVATCountry}
                />
              ))}
            </Box>
          }

          {props.companyNotFound &&
            <Box mt={2} color="error.main">
              <IntlMessageComponent id="component.companyLookup.companyNotFound" />
            </Box>
          }
        </Box>
      }

      {props.error &&
        <Box mt={2} color="error.main">
          <IntlMessageComponent id="components.companyLookup.lookupFailed" />
        </Box>
      }

      {!!props.companyErrorLabelId &&
        <Box mt={2} color="error.main">
          <IntlMessageComponent id={props.companyErrorLabelId} />
        </Box>
      }
    </Box>
  );
}
