/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';

import PageTitleViewComponent from 'components/pageTitle/index';

import AlertComponent from './alerts';
import NotificationComponent from './notifications';

type NotificationContainerViewComponentProps = {
  activeCompanyRole: number,
  activeBuyerRole: number,
  activeSellerRole: number,
  activeConfirmingRole: number,
  activeCompanyHasBuyerAdmins: boolean,
  activeCompanyHasSellerAdmins: boolean,
  activeCompanyHasConfirmingAdmins: boolean,
  alerts: Alert[];
  notifications: Notification[];
  isProcessing: boolean;
  contactAdmin: () => void;
}

export default function NotificationContainerViewComponent(props: NotificationContainerViewComponentProps) {
  return (
    <React.Fragment>
      <Box px={2} pt={4} pb={2} bgcolor="lightblue.dark">
        <PageTitleViewComponent id="component.notificationContainer.title" />

        {props.notifications.map((notification, index) => (
          <Box key={`notification-${index}`} style={{ borderBottom: '1px solid #dfe1e3' }}>
            <NotificationComponent
              activeCompanyRole={props.activeCompanyRole}
              activeBuyerRole={props.activeBuyerRole}
              activeSellerRole={props.activeSellerRole}
              activeConfirmingRole={props.activeConfirmingRole}
              activeCompanyHasBuyerAdmins={props.activeCompanyHasBuyerAdmins}
              activeCompanyHasSellerAdmins={props.activeCompanyHasSellerAdmins}
              activeCompanyHasConfirmingAdmins={props.activeCompanyHasConfirmingAdmins}
              notification={notification}
              isProcessing={props.isProcessing}
              contactAdmin={props.contactAdmin}
            />
          </Box>
        ))}

        {props.alerts.map((alert, index) => (
          <Box key={`alert-${index}`} style={{ borderBottom: '1px solid #dfe1e3' }}>
            <AlertComponent alert={alert} />
          </Box>
        ))}
      </Box>
    </React.Fragment>
  );
}
