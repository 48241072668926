/* @flow */

import React from 'react';
import Tooltip from "@material-ui/core/Tooltip";
import { useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import HelpHtmlToolTipComponent from './helpHtmlToolTip';
import Box from '@material-ui/core/Box'

type TooltipComponentProps = {
  elementName: string;
  pageName: string;
  boxWidth: string;
}

const HtmlTooltip = withStyles((theme) => ({
  arrow: {
    "&:before": {
      border: "1px solid #E6E8ED",
      boxShadow: "1px 1px 5px #888888"
    },
    color: '#ffffff',
    fontSize: "15px",
  },
    tooltip: {
      backgroundColor: '#ffffff',
      color: 'rgba(0, 0, 0, 0.54)',
      maxWidth: 300,
      border: '1px solid #E6E8ED',
      fontSize: "15px",
      boxShadow: "2px 2px 5px #888888",
    },
}))(Tooltip);

export default function TooltipComponent(props: TooltipComponentProps) {
  const { tooltips } = useSelector(state => ({
    tooltips: state.locale.tooltips
  }));

  const tooltip = tooltips.find(t => t.pageName === props.pageName && t.elementName === props.elementName);

  // RESULT
  if (!tooltip) return (<React.Fragment>{props.children}</React.Fragment>);
  if (tooltip.hasIcon) return (
    <React.Fragment>
      <React.Fragment>{props.children}</React.Fragment> <HelpHtmlToolTipComponent arrow htmlText={<div dangerouslySetInnerHTML={{ __html: tooltip.translatedText }} />} placement={tooltip.direction} />
    </React.Fragment>)
  return (
    <HtmlTooltip arrow interactive title={<div dangerouslySetInnerHTML={{ __html: tooltip.translatedText }} />} placement={tooltip.direction}>
      <Box display="inline-block" width={props.boxWidth !== undefined ? props.boxWidth : "auto"}>
        {props.children}
      </Box>
    </HtmlTooltip>
  );
}
