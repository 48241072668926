/* @flow */

export default class Company {
  id: number;
  name: string;
  vatNumber: string;
  addressLine1: string;
  addressLine2: string;
  zipCode: string;
  city: string;
  countryId: number;
  phone: string;

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
  }
}
