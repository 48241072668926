/* @flow */

import Filter from 'models/isos/filters/filter';
import FilterCompany from 'models/isos/filters/filterCompany';

export default class SellerReportFilters {
  companies: FilterCompany[];
  sellerCompanies: Filter[];
  expired: Filter[];

  constructor() {
    this.companies = [];
    this.sellerCompanies = [];
    this.expired = [];
  }
}
