/* @flow */

import React from 'react';

import SellerReferralComponent from 'app/sellers/referral';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function SellerReferralPage() {
  return (
    <WebsitePageComponent id="seller.referral.title" noPaper>
      <SellerReferralComponent />
    </WebsitePageComponent>
  );
}
