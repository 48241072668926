/* @flow */

import React, {useState} from 'react';

import Download from '@material-ui/icons/CloudDownload';

import ButtonComponent from 'components/buttons/button';
import FileExportDialogComponent from 'components/fileExportDialog';
import IntlMessageComponent from 'components/formatters/intlMessage';

type DebtorExportInvoicesButtonComponentProps = {
  handleExport: (format: string) => void;
}

export default function DebtorExportInvoicesButtonComponent(props: DebtorExportInvoicesButtonComponentProps) {
  const [showPopUp, setShowPopUp] = useState(false); 

  const handlePortfolioExport = (format) => {
    setShowPopUp(false);
    props.handleExport(format);
  }
  
  return (   
    <React.Fragment>
      <ButtonComponent color="primary" variant="contained" onClick={() => setShowPopUp(true)} startIcon={<Download />} data-walkme="sportfolio-button-exporter">
        <IntlMessageComponent id="debtor.portfolio.btn.export" />
      </ButtonComponent>   
    
      {showPopUp &&
        <FileExportDialogComponent
          isOpen
          typesAllowed={['xlsx', 'csv']}
          handleExport={(format: string) => handlePortfolioExport(format)}
          handleClose={() => setShowPopUp(false)}
        />
      }
    </React.Fragment>
  );
}
