/* @flow */

import React from 'react';

import ConfirmingSuppliersComponent from 'app/confirming/suppliers';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function ConfirmingBillPortfolioPage() {
  return (
    <WebsitePageComponent noTitle noPaper>
      <ConfirmingSuppliersComponent />
    </WebsitePageComponent>
  );
}
