/* @flow */

import React from 'react';

import Typography from '@material-ui/core/Typography';

import IntlMessageComponent from 'components/formatters/intlMessage';
import {PASSWORD_MIN_LENGTH, PASSWORD_NUMBERS_COUNT, PASSWORD_UPPERCASE_COUNT, PASSWORD_LOWERCASE_COUNT, PASSWORD_SPECIALCHARS, PASSWORD_MAX_EXPOSED_TIMES} from 'constants/config';

export default function PasswordRulesComponent() {
  return (
    <React.Fragment>
      <Typography variant="body1" gutterBottom>
        <IntlMessageComponent id="component.password.rules.introduction" values={{passwordLength: PASSWORD_MIN_LENGTH}} />
      </Typography>
      
      <Typography variant="body1" gutterBottom>
        <ul>
          <li><IntlMessageComponent id="component.password.rules.uppercase" values={{uppercase: PASSWORD_UPPERCASE_COUNT}} /></li>
          <li><IntlMessageComponent id="component.password.rules.lowercase" values={{lowercase: PASSWORD_LOWERCASE_COUNT}} /></li>
          <li><IntlMessageComponent id="component.password.rules.numbers" values={{numbers: PASSWORD_NUMBERS_COUNT}} /></li>
          <li><IntlMessageComponent id="component.password.rules.specialchars" values={{specialChars: PASSWORD_SPECIALCHARS}} /></li>
        </ul>
      </Typography>
      
      <Typography variant="body1" gutterBottom>
        <IntlMessageComponent id="component.password.rules.pwnd" values={{passwordFound: PASSWORD_MAX_EXPOSED_TIMES}} />
      </Typography>
    </React.Fragment>
  );
}
