/* @flow */

import { makeStyles } from '@material-ui/core/styles';

import StyledTableRowComponent from './row';

type StyledTableRowConfirmingInvoiceComponentProps = {
  isWaiting: boolean;
}

const useStyles = makeStyles(_ => ({
  normal: {
    borderLeft: '10px solid #fff'
  },
  waiting: {
    borderLeft: '10px solid #ff8000'
  }
}));

export default function StyledTableRowConfirmingInvoiceComponent(props: StyledTableRowConfirmingInvoiceComponentProps) {
  const classes = useStyles();
  const { isRefused, isWaiting, ...rest } = props;

  let style = classes.normal;
  if (isWaiting) style = classes.waiting;

  return (
    <StyledTableRowComponent className={style} {...rest} />
  );
}
