import React from 'react';

import Box from '@material-ui/core/Box';

import BooleanRadioFieldComponent from 'components/formFields/booleanRadio';
import ButtonComponent from 'components/buttons/button';
import FormErrorsComponent from 'components/formErrors';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import PersonLegalInfoForm from './model';

type SubscriptionTabLegalFormViewComponentProps = {
  errors: Map<string, ?string>;
  form: PersonLegalInfoForm;
  formErrors: string[];
  isProcessing: boolean;
  handleBooleanChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void;
  submitForm: () => void;
}

export default function SubscriptionTabLegalFormViewComponent(props: SubscriptionTabLegalFormViewComponentProps) {
  return (
    <React.Fragment>
      <FormErrorsComponent formErrors={props.formErrors} />

      <PageSubTitleViewComponent id="page.subscription.tab5.legal.title" />

      <Box mt={3} px={4}>
        <BooleanRadioFieldComponent
          id="form.isAdministrator"
          error={!!props.errors.get('isAdministrator')}
          label={<IntlMessageComponent id="form.isAdministrator" data-walkme="subscription-field-isAdministrator" />}
          name="isAdministrator"
          value={props.form.isAdministrator?.toString() || 'false'}
          onChange={props.handleBooleanChange('isAdministrator')}
        />
      </Box>

      <Box mt={5}>
        <ButtonComponent
          color="primary"
          variant="contained"
          isProcessing={props.isProcessing}
          onClick={props.submitForm}
        ><IntlMessageComponent id="general.continue" /></ButtonComponent>
      </Box>
    </React.Fragment>
  );
}