/* @flow */

import React from 'react';
import {useSelector} from 'react-redux';

import Country from 'models/generics/country';

type CountryNameComponentProps = {
  countryId: number;
}

export default function CountryNameComponent(props: CountryNameComponentProps) {
  const {countries, genericDataIsLoading} = useSelector(state => state.locale);

  const getCountryById = (id: number) => {
    if (!genericDataIsLoading && Array.isArray(countries) && countries.length > 0) {
      const country = countries.find((c: Country) => c.id === id);
      if (country !== undefined && country !== null) return country;
    }
    return null;
  };

  const country = getCountryById(props.countryId);
  if (country === null) return null;
  return (<span>{country.name}</span>);
}