/* @flow */

import React from 'react';
import {useSelector} from 'react-redux';
import {Route, Switch, useRouteMatch} from 'react-router-dom';

import Error404Page from 'components/errors/error404';
import RestrictedRouteComponent from 'components/route/restrictedRoute';

import BuyerBillsPage from './bills';
import BuyerInvoicesToPurchasePage from './purchase';
import BuyerDashboardPage from './dashboard';
import BuyerInvestmentProfilesPage from './investmentProfiles';
import BuyerInvoiceDetailsPage from './invoiceDetails';
import BuyerPerformancePage from './performance';
import BuyerPortfolioPage from './invoicesPortfolio';
import BuyerTransferInvoicePage from './transfer';

import {PROTECTED_BUYER_DASHBOARD} from 'constants/pageRoutes';

export default function BuyerPage() {
  const activeCompany = useSelector(state => state.auth.user.activeCompany);
  const match = useRouteMatch();

  return (
    <Switch key={`ip-${activeCompany.investmentProfile.id}`}>
      <RestrictedRouteComponent path={`${match.url}/buy`} component={BuyerInvoicesToPurchasePage} condition={activeCompany.roleBuyer.canExecute} redirectUrl={PROTECTED_BUYER_DASHBOARD}  />
      <Route exact path={`${match.url}/`} component={BuyerDashboardPage} />
      <Route path={`${match.url}/bills`} component={BuyerBillsPage} />
      <Route path={`${match.url}/invoice/:id/:refuse?`} component={BuyerInvoiceDetailsPage} />
      <Route path={`${match.url}/performance`} component={BuyerPerformancePage} />
      <Route path={`${match.url}/profile`} component={BuyerInvestmentProfilesPage} />
      <Route path={`${match.url}/portfolio`} component={BuyerPortfolioPage} />
      <Route path={`${match.url}/transfer`} component={BuyerTransferInvoicePage} />
      <Route component={Error404Page} />
    </Switch>
  );
}
