/* @flow */

import React from 'react';

import ConfirmingDashboardComponent from 'app/confirming/dashboard';
import WebsiteDashboardPageComponent from 'components/websiteStructure/pages/dashboard';

export default function ConfirmingDashboardPage() {
  return (
    <WebsiteDashboardPageComponent id="confirming.dashboard.title">
      <ConfirmingDashboardComponent />
    </WebsiteDashboardPageComponent>
  );
}
