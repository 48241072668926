/* @flow */

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import customSort from 'lib/sortHelpers';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';

import DateFormatterComponent from 'components/formatters/date';
import IconRefreshOutlined from 'components/icons/outlined/refresh';
import IconUser from 'components/icons/user';
import IntlMessageComponent from 'components/formatters/intlMessage';
import LeadStatusComponent from 'components/leads/status';
import StyledTableCellTextComponent from 'components/table/cellText';
import StyledTableCellNumbersComponent from 'components/table/cellNumbers';
import StyledTableHeadComponent from 'components/table/head';
import StyledTableRowComponent from 'components/table/row';
import { PROTECTED_ISO_LEAD_DETAILS } from 'constants/pageRoutes';

import ExtendsLeadFormDialogComponent from './dialog';
import CloseLeadFormDialogComponent from './closeLeadDialog';

import LeadReport from 'models/isos/leads/leadReport';
import IconRefuseOutlined from 'components/icons/outlined/refuse';
import IconAddOutlined from 'components/icons/outlined/add';

const columnData = [
  { id: 'sellerCompany', translationKey: 'component.isos.leads.list.header.sellerCompany', walkme: 'isoleads-column-sellercompany' },
  { id: 'statusId', translationKey: 'component.isos.leads.list.header.statusId', walkme: 'isoleads-column-status' },
  { id: 'ownerClass', translationKey: 'component.isos.leads.list.header.owner', walkme: 'isoleads-column-managedby' },
  { id: 'creationDate', translationKey: 'component.isos.leads.list.header.creationDate', walkme: 'isoleads-column-creationdate' },
  { id: 'age', align:'right', translationKey: 'component.isos.leads.list.header.age', walkme: 'isoleads-column-age' },
  { id: 'closedDate', translationKey: 'component.isos.leads.list.header.closedDate', walkme: 'isoleads-column-closedate' },
  { id: 'convertible', translationKey: 'component.isos.leads.list.header.convertible', walkme: 'isoleads-column-convertible' },
  { id: 'lostReasonId', translationKey: 'component.isos.leads.list.header.lostReasonId', walkme: 'isoleads-column-lostreason' },
  { id: 'leadId', translationKey: 'component.isos.leads.list.header.leadId', walkme: 'isoleads-column-reference' },
];

type ISOLeadTableComponentProps = {
  filterByLeadsToClean: boolean;
  leads: LeadReport[];
  refreshData: () => void;
  sendToEdebex: (leadId: number) => void;
  reopenLead: (leadId: number) => void;
}

export default function ISOLeadTableComponent(props: ISOLeadTableComponentProps) {
  const [state, setState] = useState({
    extendsLifetimeLeadId: 0,
    clloseLeadId: 0,
    order: 'desc',
    orderBy: props.filterByLeadsToClean ? 'age' : undefined,
    page: 0,
    pageSize: 10,
    dataLength: props.leads.length
  });

  const history = useHistory();
  const handlePageChange = (_, page) => setState(prevState => ({ ...prevState, page }));
  const handlePageSizeChange = event => setState(prevState => ({ ...prevState, pageSize: event.target.value }));

  const handleSortOrderChange = (orderBy: string) => {
    const order = (state.orderBy === orderBy && state.order === 'desc') ? 'asc' : 'desc';
    setState(prevState => ({ ...prevState, order, orderBy }));
  };

  const sortLeads = () => {
    const leads = state.order === 'desc'
      ? props.leads.sort((a, b) => customSort(b[state.orderBy], a[state.orderBy]))
      : props.leads.sort((a, b) => customSort(a[state.orderBy], b[state.orderBy]));
    return leads;
  }

  const getColor = (lead: ISOLead) => {
    if (lead.statusId === 1906) return 'green.main';
    if (lead.statusId === 1908 && lead.convertible.toLowerCase() === 'yes') return 'orange.main';
    if (lead.statusId === 1908 && lead.convertible.toLowerCase() === 'no') return 'red.main';
    if (lead.ownerClass === 'ISO') return 'darkgrey.main';
    return null;
  }

  const handleClickSendToEdebex = async (event, leadId: number) => {
    event.stopPropagation();
    props.sendToEdebex(leadId);
  }

  const handleClickReopenLead = async (event, leadId: number) => {
    event.stopPropagation();
    event.preventDefault();
    props.reopenLead(leadId);
  }

  const handleClickExtendsLifetime = (event, leadId: number) => {
    event.stopPropagation();
    setState(prevState => ({ ...prevState, extendsLifetimeLeadId: leadId }));
  }

  const handleExtendsLifetimeSuccess = () => {
    setState(prevState => ({ ...prevState, extendsLifetimeLeadId: 0 }));
    props.refreshData();
  }

  const handleExtendsLifetimeCancel = () => {
    setState(prevState => ({ ...prevState, extendsLifetimeLeadId: 0 }));
  }

  const handleClickClose = (event, leadId: number) => {
    event.stopPropagation();
    setState(prevState => ({ ...prevState, closeLeadId: leadId }));
  }

  const handleCloseSuccess = () => {
    setState(prevState => ({ ...prevState, closeLeadId: 0 }));
    props.refreshData();
  }

  const handleCloseCancel = () => {
    setState(prevState => ({ ...prevState, closeLeadId: 0 }));
  }

  const navigateToLeadDetails = (id, leadReopenedId) => history.push({ pathname: `${PROTECTED_ISO_LEAD_DETAILS}/${id}#${leadReopenedId}` });

  const leads = sortLeads();

  const resetPage = () => {
    if (state.dataLength !== props.leads.length) {
      setState(prevState => ({ ...prevState, page: 0, dataLength: props.leads.length }));
    }
  }
  const checkReset = resetPage();

  return (
    <React.Fragment>
      <Box py={5}>
        <Box component="div" overflow="auto">
          <Table>
            <StyledTableHeadComponent>
              <StyledTableRowComponent>
                {columnData.map(column => (
                  <StyledTableCellTextComponent key={column.id} align={column?.align ?? 'left'} padding="none" data-walkme={column.walkme || ''}>
                    <TableSortLabel active={state.orderBy === column.id} direction={state.order} onClick={() => handleSortOrderChange(column.id)}>
                      <IntlMessageComponent id={column.translationKey} />
                    </TableSortLabel>
                  </StyledTableCellTextComponent>
                ))}
                <StyledTableCellTextComponent padding="none" />
              </StyledTableRowComponent>
            </StyledTableHeadComponent>

            <TableBody bgcolor="white">
              {!leads.length &&
                <StyledTableRowComponent>
                  <StyledTableCellTextComponent colSpan={columnData.length}>
                    <Box textAlign="center">
                      <IntlMessageComponent id="component.isos.leads.list.noData" />
                    </Box>
                  </StyledTableCellTextComponent>
                </StyledTableRowComponent>
              }

              {leads.slice(state.page * state.pageSize, (state.page * state.pageSize) + state.pageSize).map(lead => (
                <StyledTableRowComponent key={`lead-${lead.leadId}-${lead.leadReopenedId}`}>
                  <StyledTableCellTextComponent padding="none">
                    {lead.sellerCompany}
                  </StyledTableCellTextComponent>

                  <StyledTableCellTextComponent padding="none">
                    <b><LeadStatusComponent id={lead.statusId} color={getColor(lead)} /></b>
                  </StyledTableCellTextComponent>

                  <StyledTableCellTextComponent padding="none">
                    <IntlMessageComponent id={`general.leads.owner.${lead.ownerClass}`} />
                    {lead.ownerClass === 'ISO' && !lead.closedDate &&
                      <Tooltip title={<IntlMessageComponent id="component.isos.leads.list.actions.tooltip.sendToEdebex" />}>
                        <IconButton onClick={event => handleClickSendToEdebex(event, lead.leadId)}>
                          <IconUser fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    }
                  </StyledTableCellTextComponent>

                  <StyledTableCellTextComponent padding="none">
                    <DateFormatterComponent date={lead.creationDate} />
                  </StyledTableCellTextComponent>

                  <StyledTableCellNumbersComponent padding="none">
                    {lead.age}
                    {lead.canBeExtended &&
                      <Tooltip title={<IntlMessageComponent id="component.isos.leads.list.actions.tooltip.extendsLifetime" />}>
                        <IconButton style={{paddingRight:"0", paddingLeft:"6px", paddingBottom:"14px", paddingTop:"10px"}} onClick={event => handleClickExtendsLifetime(event, lead.leadId)}>
                          <IconRefreshOutlined fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    }
                    {lead.canBeClosed &&
                      <Tooltip title={<IntlMessageComponent id="component.isos.leads.list.actions.tooltip.close" />}>
                        <IconButton style={{paddingRight:"0", paddingLeft:"6px", paddingBottom:"14px", paddingTop:"10px"}} onClick={event => handleClickClose(event, lead.leadId)}>
                          <IconRefuseOutlined fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    }
                    {lead.leadReopenedId == 0 && lead.ownerClass == 'ISO' && lead.statusId == 1908 &&
                      <Tooltip title={<IntlMessageComponent id="component.isos.leads.list.actions.tooltip.reopenLead" />}>
                        <IconButton style={{paddingRight:"0", paddingLeft:"6px", paddingBottom:"14px", paddingTop:"10px"}} onClick={event => {event.target.style.display = "none"; handleClickReopenLead(event, lead.leadId);}}>
                          <IconAddOutlined color="secondary" fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    }
                  </StyledTableCellNumbersComponent>

                  <StyledTableCellTextComponent padding="none">
                    {lead.closedDate && <DateFormatterComponent date={lead.closedDate} />}
                  </StyledTableCellTextComponent>

                  <StyledTableCellTextComponent padding="none">
                    <IntlMessageComponent id={`general.leads.convertible.${lead.convertible}`} />
                  </StyledTableCellTextComponent>

                  <StyledTableCellTextComponent padding="none">
                    {lead.lostReasonId > 0 && <IntlMessageComponent id={`leadlostreason${lead.lostReasonId}`} />}
                  </StyledTableCellTextComponent>

                  <StyledTableCellTextComponent padding="none">{lead.leadId}</StyledTableCellTextComponent>
                  <StyledTableCellTextComponent padding="none" >
                    <IconButton onClick={() => navigateToLeadDetails(lead.leadId, lead.leadReopenedId)}>
                      <EditIcon />
                    </IconButton></StyledTableCellTextComponent>
                </StyledTableRowComponent>
              ))}
            </TableBody>
          </Table>

          <Box display="flex" height={60}>
            <Box flexGrow={1} />
            <Box justifyContent="flex-end">
              <TablePagination
                component="div"
                count={leads.length}
                data-walkme="isoleads-walkthru-step5"
                page={state.page}
                rowsPerPage={state.pageSize}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePageSizeChange}
                labelRowsPerPage={<IntlMessageComponent id="general.leadsPerPage" />}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
              />
            </Box>
          </Box>
        </Box>

        {state.extendsLifetimeLeadId > 0 &&
          <ExtendsLeadFormDialogComponent
            leadId={state.extendsLifetimeLeadId}
            successHandler={handleExtendsLifetimeSuccess}
            cancelHandler={handleExtendsLifetimeCancel}
          />
        }
        {state.closeLeadId > 0 &&
          <CloseLeadFormDialogComponent
            leadId={state.closeLeadId}
            successHandler={handleCloseSuccess}
            cancelHandler={handleCloseCancel}
          />
        }
      </Box>
    </React.Fragment>
  );
}
