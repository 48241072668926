/* @flow */
import React, { useState } from 'react';
import moment from 'moment';

import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import CommissionActionsComponent from './actions';
import CollapseLinkComponent from 'components/isoFilters/collaspsibleLink';
import ComponentWrapperComponent from 'components/websiteStructure/componentWrapper';
import FilterISOComponent from 'components/isoFilters/iso';
import FilterPeriodComponent from 'components/isoFilters/period';
import IntlMessageComponent from 'components/formatters/intlMessage';

import CommissionFilters from 'models/isos/commissions/commissionFilters';

type ISOCommissionFiltersComponentProps = {
  filters: CommissionFilters;
  selectFilterISO: (filterId: string, optionId: number) => void;
  selectFilterMode: (mode: number) => void;
  selectFilterPeriod: (dateFrom: moment, dateTo: moment) => void;
  clearFilters: () => void;
  downloadCommissionReport: (format: string) => void;
}

export default function ISOCommissionFiltersComponent(props: ISOCommissionFiltersComponentProps) {
  const [showFilters, setShowFilters] = useState(true);

  const toggleShowFilters = () => setShowFilters(showFilters => !showFilters);

  const onChangeMode = (event) => {
    if (props.filters.mode === event.target.value) return;
    props.selectFilterMode(event.target.value);
  }

  return (
    <Box mb={showFilters ? 3 : 'auto'}>
      <Box mb={3}>
        <CollapseLinkComponent
          isCollapsed={!showFilters}
          labelClosed="component.isos.commissions.filters.collapse.closed.label"
          labelOpen="component.isos.commissions.filters.collapse.open.label"
          toggle={toggleShowFilters}
          data-walkme="isocommission-walkthru-step1"
        />
      </Box>

      {showFilters &&
        <React.Fragment>
          <ComponentWrapperComponent small data-walkme="isocommission-walkthru-step2">
            <Grid container>
              <FilterISOComponent
                companyLabel="component.isos.commissions.filters.isoCompany.label"
                officeLabel="component.isos.commissions.filters.isoOffice.label"
                personLabel="component.isos.commissions.filters.isoPerson.label"
                pageName="POS.Commissions"
                filters={props.filters.companies}
                selectFilter={props.selectFilterISO}
              />

              <Grid item xs="auto">
                <Box pl={2} pr={2} minWidth={200}>
                  <FilterPeriodComponent
                    colSize={2}
                    filter={props.filters.period}
                    filterId="period"
                    label="component.isos.commissions.filters.period.label"
                    selectLabel="component.isos.commissions.filters.period.select"
                    selectFilter={props.selectFilterPeriod}
                  />
                </Box>
              </Grid>

              <Grid item xs="auto">
                <Box pl={2} pr={2} minWidth={200}>
                  <FormControl component="fieldset" fullWidth>
                    <FormLabel component="legend">
                      <b><IntlMessageComponent id="page.iso.commissions.filters.mode.label" /></b>
                    </FormLabel>
                    <Select
                      value={props.filters.mode}
                      onChange={onChangeMode}
                      input={<Input name="mode" id="mode-selection" />}
                      autoWidth
                      data-walkme="isocommission-walkthru-step3"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left"
                        },
                        getContentAnchorEl: null
                      }}
                    >
                      <MenuItem value={CommissionFilters.MODE_SUMMARY}><IntlMessageComponent id="page.iso.commissions.filters.mode.summary" /></MenuItem>
                      <MenuItem value={CommissionFilters.MODE_INVOICES}><IntlMessageComponent id="page.iso.commissions.filters.mode.invoices" /></MenuItem>
                      <MenuItem value={CommissionFilters.MODE_MSFS}><IntlMessageComponent id="page.iso.commissions.filters.mode.msfs" /></MenuItem>
                      <MenuItem value={CommissionFilters.MODE_MEMBERSHIPS}><IntlMessageComponent id="page.iso.commissions.filters.mode.memberships" /></MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </ComponentWrapperComponent>
          <Box mt={3}>
            <CommissionActionsComponent
              onExportAsked={props.downloadCommissionReport}
              clearFilters={props.clearFilters}
            />
          </Box>
        </React.Fragment>
      }
    </Box>
  );
}
