/* @flow */

import moment from 'moment/moment';

class Note {
  date: moment;
  text: string;
}

export default Note;
