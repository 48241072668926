/* @flow */

import React from 'react';
import moment from 'moment';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import CustomPeriodDialogComponent from 'components/customPeriodDialog';
import DateFormatterComponent from 'components/formatters/date';
import IntlMessageComponent from 'components/formatters/intlMessage';

import FilterPeriod from 'models/isos/filters/filterPeriod';

type FilterPeriodComponentProps = {
  filter: FilterPeriod;
  filterId: string;
  label: string;
  selectLabel: string;
  selectFilter: (filterId: string, dateFrom: moment, dateTo: moment) => void;
}

type FilterPeriodComponentState = {
  showDialog: boolean;
}

class FilterPeriodComponent extends React.Component<FilterPeriodComponentProps, FilterPeriodComponentState> {

  constructor(props) {
    super(props);
    this.state = {
      showDialog: false
    };
  }

  showDialog = () => {
    this.setState({showDialog: true});
  }

  hideDialog = () => {
    this.setState({showDialog: false});
  }

  updateFilters = (dateFrom: moment, dateTo: moment) => {
    this.props.selectFilter(this.props.filterId, dateFrom, dateTo);
    this.setState({showDialog: false});
  }

  render() {
    return (
      <React.Fragment>
        <FormControl component="fieldset">
          <FormLabel component="legend">
            <b><IntlMessageComponent id={this.props.label} /></b>
          </FormLabel>

          <Button onClick={() => this.showDialog()}>
            <div style={{textAlign: 'left'}}>
              <div><IntlMessageComponent id={this.props.selectLabel} /></div>
              {this.props.filter.dateFrom !== null && this.props.filter.dateTo !== null &&
                <div><small>(<DateFormatterComponent date={this.props.filter.dateFrom} /> - <DateFormatterComponent date={this.props.filter.dateTo} />)</small></div>
              }
            </div>
          </Button>
        </FormControl>

        <CustomPeriodDialogComponent
          dateFrom={this.props.filter.dateFrom}
          dateTo={this.props.filter.dateTo}
          enableRemoveFilter
          isOpen={this.state.showDialog}
          handleClose={this.hideDialog}
          handleChange={this.updateFilters}
        />
      </React.Fragment>
    );
  }
}

export default FilterPeriodComponent;
