/* @flow */

import React from 'react';
import {useSelector} from 'react-redux';

import {makeStyles} from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Popover from '@material-ui/core/Popover';

import IntlMessageComponent from 'components/formatters/intlMessage';

import WebsiteHeaderUserMenuItemLinkComponent from './itemLink';

import {userMenuDropdownData} from './data';

const useStyles = makeStyles(theme => ({
  button: {
    textTransform: 'none'
  }
}));

export default function WebsiteHeaderUserMenuComponent() {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const classes = useStyles();

  const user = useSelector(state => state.auth.user);
  if (!user) return null;

  const flipOpen = () => {
    if (user.activeCompany.isSubscribing) { return; }
    setOpen(prevOpen => !prevOpen);
  }
  
  return (
    <React.Fragment>
      <Button
        ref={anchorRef}
        className={classes.button}
        onClick={flipOpen}
      >
        <Box textAlign="left">
          <Box color="primary.main"><b>{user.firstname} {user.lastname}</b></Box>
        </Box>

        {!user.activeCompany.isSubscribing &&
          <Box color="primary.main">
            <ArrowDropDownIcon fontSize="small" />
          </Box>
        }
      </Button>
      
      <Popover
        id='language-popup'
        open={open}
        anchorEl={anchorRef.current}
        onClose={flipOpen}
        anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}
        transformOrigin={{horizontal: 'center', vertical: 'top'}}
      >
        <List component="nav" dense>
          <ListItem>
            <ListItemText
              primary={<Box color="primary.main" component="span"><b>{user.firstname} {user.lastname}</b></Box>}
              secondary={user.userName}
            />
          </ListItem>
          {user.isImpersonating &&
            <ListItem>
              <ListItemText primary={<b>({user.originalUsername})</b>} />
            </ListItem>
          }
        </List>
        <Divider />
        <List component="nav" dense>
          {userMenuDropdownData.map(item => (
            <WebsiteHeaderUserMenuItemLinkComponent item={item} user={user} key={item.id} />
          ))}
        </List>
      </Popover>
    </React.Fragment>
  );
}
