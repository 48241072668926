/* @flow */

import React from 'react';

import * as reactGA from 'lib/analytics';
import TagManager from 'react-gtm-module';

import LoadingComponent from 'components/loading';

import SignUpTab3ViewComponent from './view';

import ChangeEmailForm from './model';
import SendVerificationEmailRequest from 'models/requests/register/sendVerificationEmailRequest';

import RegisterService from 'services/registerService';
import {handleApiFormResponse, handleFormChange, handleFormValidation} from 'lib/forms';
import loadConstraints from 'lib/validation/loadConstraints';

import NotificationHelper from 'lib/notifications';

import {history} from 'store/index';
import {SIGN_IN} from 'constants/pageRoutes';

type SignUpTab3ComponentProps = {
  email: string;
  successHandler: (string) => void
}

type SignUpTab3ComponentState = {
  constraints: any;
  errors: Map<string, ?string>;
  form: ChangeEmailForm;
  formErrors: string[];
  isLoading: boolean;
  isProcessing: boolean;
  showEmailField: boolean;
}

class SignUpTab3Component extends React.Component<SignUpTab3ComponentProps, SignUpTab3ComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      constraints: null,
      errors: null,
      form: new ChangeEmailForm(),
      formErrors: [],
      isLoading: true,
      isProcessing: false,
      showEmailField: false
    };
  }

  componentDidMount = async () => {
    reactGA.initializeGA();
    reactGA.logEvent('SignUp', 'Email Validation Pending');
    TagManager.dataLayer({ dataLayer: { event: 'subscribed', formType: 'Subscription' } });

    try {
      const constraints = await loadConstraints('Person', ['email']);
      this.setState({
        constraints,
        errors: new Map(Object.keys(constraints).map(e => [e, undefined])),
        isLoading: false
      });
    } catch (e) {
      console.error(e);
    }
  }

  displayEmailField = () => {
    this.setState({showEmailField: true});
  };

  handleChange = (fieldName: string) => (event) => handleFormChange.call(this, fieldName, event.target.value);
  validateForm = () => handleFormValidation.call(this);

  submitForm = async () => {
    if (!this.validateForm()) return;

    this.setState({formErrors: [], isProcessing: true});

    try {
      const request = this.state.form.toRequest(this.props.email);
      await RegisterService.changeEmail(request);

      const request2 = new SendVerificationEmailRequest(this.state.form.email);
      const response2 = await RegisterService.sendVerificationEmail(request2);
      if (response2) {
        reactGA.logEvent('SignUp', 'Email Validation Sent');
        NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS, 'notifications.email.success');
        this.props.successHandler(this.state.form.email);
      } else {
        history.push(SIGN_IN);
      }
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }
  };

  resendEmail = async () => {
    this.setState({isProcessing: true});

    try {
      const request = new SendVerificationEmailRequest(this.props.email);
      const response = await RegisterService.sendVerificationEmail(request);
      if (response) {
        NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS, 'notifications.email.confirmation.success');
        this.setState({isProcessing: false});
      } else {
        history.push(SIGN_IN);
      }
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }
  };

  render = () => {
    if (this.state.isLoading) return (<LoadingComponent />);
    return (
      <SignUpTab3ViewComponent
        email={this.props.email}
        errors={this.state.errors}
        form={this.state.form}
        formErrors={this.state.formErrors}
        isProcessing={this.state.isProcessing}
        showEmailField={this.state.showEmailField}
        displayEmailField={this.displayEmailField}
        handleChange={this.handleChange}
        resendEmail={this.resendEmail}
        submitForm={this.submitForm}
      />
    );
  }
}

export default SignUpTab3Component;
