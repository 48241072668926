/* @flow */

import React from 'react';

import TermsAndConditionsBeginComponent from 'app/users/termsAndConditions/begin';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function TermsAndConditionsBeginPage() {
  return (
    <WebsitePageComponent id="page.tos.h1">
      <TermsAndConditionsBeginComponent />
    </WebsitePageComponent>
  );
}
