/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';

import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';
import SecurityComponent from 'components/security/index';

import PaymentOgone from 'models/payments/paymentOgone';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

type PaymentByCardComponentProps = {
  paymentInfo: PaymentOgone;
  paymentMethod: String;
}

export default function PaymentByCardComponent(props: PaymentByCardComponentProps) {
  return (
    <React.Fragment>
      <SecurityComponent pageName="POS.Payment.Card" elementName="btnSubmit">
        <PageSubTitleViewComponent id="component.payViaCard.title" />
        <p><IntlMessageComponent id="component.payViaCard.description" /></p>

        {(props.paymentMethod === undefined || props.paymentMethod === 'VISA') &&
          <Box component="span" mr={1}>
            <img alt="card-VISA" src="./images/payments/VISA.png" />
          </Box>
        }

        {(props.paymentMethod === undefined || props.paymentMethod === 'MASTERCARD') &&
          <Box component="span" mr={1}>
            <img alt="card-MasterCard" src="./images/payments/MasterCard.png" />
          </Box>
        }

        {(props.paymentMethod === undefined || props.paymentMethod === 'MAESTRO') &&
          <Box component="span" mr={1}>
            <img alt="card-maestro" src="./images/payments/maestro.png" />
          </Box>
        }
        {(props.paymentMethod === undefined || props.paymentMethod === 'BCMC') &&
          <Box component="span" mr={1}>
            <img alt="card-bancontact" src="./images/payments/bancontact.png" />
          </Box>
        }
        {(props.paymentMethod === undefined || props.paymentMethod === 'iDEAL') &&
          <Box component="span" mr={1}>
            <img alt="card-ideal" src="./images/payments/ideal.png" />
          </Box>
        }
        <form name="form1" id="form1" action={props.paymentInfo.url} method={(props.paymentInfo.url.includes("localhost") ? "get" : "post")}>
          {props.paymentInfo && props.paymentInfo.formValues.map(fv => (
            <input type="hidden" name={fv.key} value={fv.value} key={`ogoneProp-${fv.key}`} />
          ))}
          <Box mt={3}>
            <ButtonComponent
              color="primary"
              variant="contained"
              type="submit"
              disabled={!props.paymentInfo}
            ><IntlMessageComponent id="component.payViaCard.submit" /></ButtonComponent>
          </Box>
        </form>
      </SecurityComponent>
    </React.Fragment>
  );
}
