/* @flow */

import {plainToClass} from 'class-transformer';

import BaseService from './api/baseService';

import {
  DOCUMENTS_GET,
  DOCUMENTS_BILLS_GET,
  DOCUMENTS_BILLS_LIST_GET,
  DOCUMENTS_COMMERCIAL_GET,
  DOCUMENTS_COMMERCIAL_LIST_GET,
  DOCUMENTS_ISO_COMMERCIAL_LIST_GET,
  DOCUMENTS_COMPANY_GET,
  DOCUMENTS_COMPANY_POST,
  DOCUMENTS_COMPANY_LIST_GET,
  DOCUMENTS_COMPANY_PORTFOLIO_EXPORT_GET,
  DOCUMENTS_NOT_EXPIRED_AND_NOT_REPLACED_GET,
  DOCUMENTS_INVOICES_GET,
  DOCUMENTS_INVOICES_LIST_GET,
  DOCUMENTS_SIGN_POST,
  DOCUMENTS_SUPPLIER_POST,
} from 'constants/apiRoutes';

import DownloadSelectedBillsRequest from 'models/requests/documents/downloadSelectedBillsRequest';
import SignDocumentTransationRequest from 'models/requests/documents/signDocumentTransationRequest';

import Bill from 'models/documents/bill';
import CommercialDocument from 'models/documents/commercialDocument';
import IsoCommercialDocuments from 'models/documents/IsoCommercialDocuments';
import DocumentItem from 'models/documents/documentItem';
import ProvidedDocument from 'models/documents/providedDocument';
import UniversignTransaction from 'models/register/universignTransaction';
import DocumentNotExpiredAndNotReplaced from 'models/documents/documentNotExpiredAndNotReplaced';

export default class DocumentService {
  // GENERAL
  static downloadDocument = (documentName: string) =>
    BaseService.simpleFileDownloadGET(`${DOCUMENTS_GET}?documentName=${documentName}`);
  
  static getPersonNotExpiredAndNotReplacedDocuments = (activeCompanyId: number, companyPersonId: number) =>
    BaseService.simpleGET(`${DOCUMENTS_NOT_EXPIRED_AND_NOT_REPLACED_GET}/${activeCompanyId}/${companyPersonId}`,
    data => data.map(d => plainToClass(DocumentNotExpiredAndNotReplaced, d)));


  // UPLOAD
  static sendDocument2 = (url: string, file: File, options: any) =>
    BaseService.simpleFilePOST(url, file, options);


  // DELETE
  static deleteDocument = (url: string, documentId: number) =>
    BaseService.simplePOST(`${url}/${documentId}`, {}, null);


  // BILLS
  static getBills = (companyId: number) =>
    BaseService.simpleGET(`${DOCUMENTS_BILLS_LIST_GET}/${companyId}`,
    data => data.map(d => plainToClass(Bill, d)));

  static downloadBill = (companyId: number, billId: number) =>
    BaseService.simpleFileDownloadGET(`${DOCUMENTS_BILLS_GET}/${companyId}/${billId}`);

  static downloadSelectedBills = (request: DownloadSelectedBillsRequest) =>
    BaseService.simpleFileDownloadGET(`${DOCUMENTS_BILLS_GET}?${request.toRouteParameters()}`);


  // COMMERCIAL DOCUMENTS
  static getISOCommercialDocuments = (companyId: number, companyRole: number) => 
    BaseService.simpleGET(`${DOCUMENTS_ISO_COMMERCIAL_LIST_GET}/${companyId}/${companyRole}`,
    data => {
      const documentsIsos = data.isoDocuments.map(d => plainToClass(DocumentItem, d));
      const clientDocuments = data.clientDocuments.map(d => plainToClass(DocumentItem, d));
      return {documentsIsos, clientDocuments};
    }); 
  
  static getCommercialDocuments = (companyId: number, companyRole: number) =>
  BaseService.simpleGET(`${DOCUMENTS_COMMERCIAL_LIST_GET}/${companyId}/${companyRole}`,
  data => data.map(d => plainToClass(CommercialDocument, d)));

  static downloadCommercialDocument = (companyId: number, companyRole: number, culture: string, name: string) =>
    BaseService.simpleFileDownloadGET(`${DOCUMENTS_COMMERCIAL_GET}/${companyId}/${companyRole}/${culture}/${name}/`);

  static downloadISOCommercialDocument = (companyId: number, companyRole: number, culture: string, name: string, path: string) =>
  BaseService.simpleFileDownloadGET(`${DOCUMENTS_COMMERCIAL_GET}/${companyId}/${companyRole}/${culture}/${name}/${path}`);


  // COMPANY DOCUMENTS
  static getCompanyDocuments = (companyId: number, companyRole: number) =>
    BaseService.simpleGET(`${DOCUMENTS_COMPANY_LIST_GET}/${companyId}/${companyRole}`,
    data => {
      const documents = data.documents.map(d => plainToClass(DocumentItem, d));
      return {documents, documentTypeIds: data.documentTypeIds};
    });

  static getCompanyPortfolioDocuments = (companyId: number) =>
    BaseService.simpleGET(`${DOCUMENTS_COMPANY_PORTFOLIO_EXPORT_GET}/${companyId}`,
    data => {
      const documents = data.map(d => plainToClass(DocumentItem, d));
      return {documents};
    });

  static downloadCompanyDocument = (companyId: number, documentId: number) =>
    BaseService.simpleFileDownloadGET(`${DOCUMENTS_COMPANY_GET}/${companyId}/${documentId}`);

  static sendCompanyDocument = (companyId: number, documentTypeId: number, file: File) => {
    return this.sendDocument2(DOCUMENTS_COMPANY_POST, file, {companyId, documentTypeId});
  }
  static sendCompanyMyDocument = (companyId: number, documentTypeClientId: number, file: File) => {
    return this.sendDocument2(DOCUMENTS_COMPANY_POST, file, {companyId, documentTypeClientId});
  }

  static sendSupplierDocument = (companyId: number, supplierId:number, documentTypeId: number, file: File) => {
    return this.sendDocument2(DOCUMENTS_SUPPLIER_POST, file, {companyId, supplierId, documentTypeId});
  }


  // INVOICE DOCUMENTS
  static getInvoiceDocuments = (companyId: number, role: number, invoiceId: number) =>
    BaseService.simpleGET(`${DOCUMENTS_INVOICES_LIST_GET}/${companyId}/${role}/${invoiceId}`,
    data => data.map(d => plainToClass(ProvidedDocument, d)));

  static getInvoiceDocument = (companyId: number, role: number, invoiceId: number, documentId: number) =>
    BaseService.simpleFileDownloadGET(`${DOCUMENTS_INVOICES_GET}/${companyId}/${role}/${invoiceId}/${documentId}`);

  static getInvoiceDocumentURL = (companyId: number, role: number, invoiceId: number, documentId: number) =>
    BaseService.simpleFileURLGET(`${DOCUMENTS_INVOICES_GET}/${companyId}/${role}/${invoiceId}/${documentId}`);


  // SIGN DOCUMENTS
  static signDocument = (request: SignDocumentTransationRequest) =>
    BaseService.simplePOST(DOCUMENTS_SIGN_POST, request, response => plainToClass(UniversignTransaction, response));
}
