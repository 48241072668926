/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';

import ButtonComponent from 'components/buttons/button';
import CheckboxFieldComponent from 'components/formFields/checkbox';
import FormErrorsComponent from 'components/formErrors';
import IntlMessageComponent from 'components/formatters/intlMessage';
import SecurityComponent from 'components/security/index';

import CompanyUBOsStatusComponent from './status';
import CompanyUBOsTableComponent from './table';

import CompanyUBO from 'models/companies/companyUBO';

import { COMPANY_UBO_STATUS_DRAFT, COMPANY_UBO_STATUS_EXPIRED, COMPANY_UBO_STATUS_EXPIRESOON, COMPANY_UBO_STATUS_NEW } from 'constants/constants';

type CompanyUBOsListComponentProps = {
  editMode: boolean;
  formErrors: string[];
  isProcessing: boolean;
  status: number;
  ubos: CompanyUBO[];
  hasAnyInvalidId: boolean;
  hasAnyMissingField: boolean;
  confirmUBOs: () => void;
  newUBO: () => void;
  editUBO: (id: number) => void;
  toggleEditMode: () => void;
  toggleUBOActiveStatus: (id: number) => void;
}

type CompanyUBOsListComponentState = {
  isInformationValid: boolean;
}

class CompanyUBOsListComponent extends React.Component<CompanyUBOsListComponentProps, CompanyUBOsListComponentState> {

  constructor(props) {
    super(props);
    this.state = {
      isInformationValid: false
    };
  }

  toggleCheckBox = () => this.setState(prevState => ({ isInformationValid: !prevState.isInformationValid }));

  sharesExceeded = () => {
    let totalShares = 0;
    this.props.ubos.forEach(function(ubo){
      totalShares+= ubo.isActive ? ubo.shares : 0;
    })
    return totalShares;
  }

  render() {
    return (
      <React.Fragment>
        <SecurityComponent pageName="POS.Companies.Ubos" elementName="btnEditMode">
          <CompanyUBOsStatusComponent
            status={this.props.status}
            hasAnyInvalidId={this.props.hasAnyInvalidId}
            hasAnyMissingField={this.props.hasAnyMissingField}
            hasAnyActiveUbo={this.props.ubos.filter(u => u.isActive === true).length > 0}
          />
        </SecurityComponent>

        <Box mt={5}>
          <FormErrorsComponent formErrors={this.props.formErrors} />

          <CompanyUBOsTableComponent
            editMode={this.props.editMode}
            sharesExceeded={this.sharesExceeded()}
            isProcessing={this.props.isProcessing}
            isExpired={(this.props.status === COMPANY_UBO_STATUS_NEW || this.props.status === COMPANY_UBO_STATUS_DRAFT || this.props.status == COMPANY_UBO_STATUS_EXPIRED || this.props.status == COMPANY_UBO_STATUS_EXPIRESOON)}
            ubos={this.props.ubos}
            editUBO={this.props.editUBO}
            toggleUBOActiveStatus={this.props.toggleUBOActiveStatus}
          />
        </Box>

        {!this.props.editMode &&
          <SecurityComponent pageName="POS.Companies.Ubos" elementName="btnEditMode">
            <Box mt={3}>
              <ButtonComponent
                color="primary"
                variant="contained"
                onClick={this.props.toggleEditMode}
              ><IntlMessageComponent id="company.ubos.btn.editMode" /></ButtonComponent>
            </Box>
          </SecurityComponent>
        }

        {this.props.editMode &&
          <SecurityComponent pageName="POS.Companies.Ubos" elementName="btnAddUBO">
            <Box mt={3}>
              <ButtonComponent
                color="primary"
                variant="contained"
                onClick={this.props.newUBO}
              ><IntlMessageComponent id="company.ubos.btn.addUBO" /></ButtonComponent>
            </Box>
          </SecurityComponent>
        }

        {this.props.editMode && (this.props.status === COMPANY_UBO_STATUS_NEW || this.props.status === COMPANY_UBO_STATUS_DRAFT || this.props.status == COMPANY_UBO_STATUS_EXPIRED || this.props.status == COMPANY_UBO_STATUS_EXPIRESOON) && !this.props.hasAnyMissingField && !this.props.hasAnyInvalidId && this.props.ubos.filter(u => u.isActive === true).length > 0 &&
          <SecurityComponent pageName="POS.Companies.Ubos" elementName="btnEditMode">
            <React.Fragment>
              <Box mt={5}>
                <CheckboxFieldComponent
                  label={<IntlMessageComponent id="company.ubos.informationValid" />}
                  value={this.state.isInformationValid}
                  changeHandler={this.toggleCheckBox}
                />
              </Box>

              <ButtonComponent
                color="secondary"
                variant="contained"
                disabled={!this.state.isInformationValid}
                isProcessing={this.props.isProcessing}
                onClick={this.props.confirmUBOs}
              ><IntlMessageComponent id="company.ubos.btn.confirm" /></ButtonComponent>
            </React.Fragment>
          </SecurityComponent>
        }
      </React.Fragment>
    );
  }
}

export default CompanyUBOsListComponent;
