/* @flow */

import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

import FormErrorsComponent from 'components/formErrors';
import IntlMessageComponent from 'components/formatters/intlMessage';
import LoadingComponent from 'components/loading';

import ApproveInvoiceForm from './model';

type DebtorInvoicesToApproveConfirmPopupViewComponentProps = {
  errors: Map<string,?string>;
  form: ApproveInvoiceForm;
  formErrors: string[];
  isProcessing: boolean;
  handleCancel: () => void;
  handleChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void;
  submitForm: () => void;
}

export default function DebtorInvoicesToApproveConfirmPopupViewComponent(props: DebtorInvoicesToApproveConfirmPopupViewComponentProps) {
  return (
    <React.Fragment>
      <Dialog open onClose={props.handleCancel}>
        {props.isProcessing && <LoadingComponent />}
        {!props.isProcessing && <React.Fragment>
          <DialogTitle><IntlMessageComponent id="debtor.approve.approvePopup.title" /></DialogTitle>

          <DialogContent>
            <DialogContentText>
              <IntlMessageComponent id="debtor.approve.approvePopup.signtext" />
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={props.handleCancel} disabled={props.isProcessing} color="primary">
              <IntlMessageComponent id="debtor.approve.approvePopup.btn.cancel" />
            </Button>
            <Button onClick={props.submitForm} disabled={props.isProcessing} color="primary">
              <IntlMessageComponent id="debtor.approve.approvePopup.btn.confirm" />
            </Button>
          </DialogActions>
        </React.Fragment>}
      </Dialog>
    </React.Fragment>
  );
}
