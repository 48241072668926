/* @flow */

import React from 'react';

import DebtorInvoicesToPayTableComponent from 'app/debtors/invoicesToPayTable';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function DebtorInvoicesToPayPage() {
  return (
    <WebsitePageComponent id="debtor.pay.title" data-walkme="debtor-pay-title" noPaper>
      <DebtorInvoicesToPayTableComponent showFilters />
    </WebsitePageComponent>
  );
}
