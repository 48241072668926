/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import IntlMessageComponent from 'components/formatters/intlMessage';
import WizardComponent from 'components/wizard';

import DebtorRegisterLeadTab1Component from './tab1/index';
import DebtorRegisterLeadTab2Component from './tab2/index';
import DebtorRegisterLeadTab3Component from './tab3/index';

type DebtorRegisterLeadComponentProps = {
  activeCompanyId: number;
}

type DebtorRegisterLeadComponentState = {
  activeStepId: number;
  leadId: number;
  steps: {id: number, title: IntlMessageComponent}[];
}

class DebtorRegisterLeadComponent extends React.Component<DebtorRegisterLeadComponentProps, DebtorRegisterLeadComponentState> {
  static TAB1 = 0;
  static TAB2 = 1;
  static TAB3 = 2;

  constructor(props) {
    super(props);

    this.state = {
      activeStepId: DebtorRegisterLeadComponent.TAB1,
      leadId: 0,
      steps: this.getSteps()
    };
  }

  getSteps = () => {
    const tabsNumbers = [DebtorRegisterLeadComponent.TAB1, DebtorRegisterLeadComponent.TAB2, DebtorRegisterLeadComponent.TAB3];
    return tabsNumbers.map(num => ({id: num, title: <IntlMessageComponent id={`debtor.registerLead.tab${num + 1}.header`} />}));
  }

  getStepContent = () => {
    switch (this.state.activeStepId) {
      case DebtorRegisterLeadComponent.TAB1:
        return (
          <DebtorRegisterLeadTab1Component
            onSuccess={this.onSuccessTab1}
          />
        );
      case DebtorRegisterLeadComponent.TAB2:
        return (
          <DebtorRegisterLeadTab2Component
            leadId={this.state.leadId}
            onSuccess={this.onSuccess}
          />
        );
      case DebtorRegisterLeadComponent.TAB3:
        return (<DebtorRegisterLeadTab3Component />);
      default:
        return 'Unknown step';
    }
  };

  onSuccess = () => this.setState(prevState => ({activeStepId: prevState.activeStepId + 1 }));
  onSuccessTab1 = (leadId: number) => this.setState({activeStepId: DebtorRegisterLeadComponent.TAB2, leadId});  

  render() {
    return (
      <WizardComponent
        activeStepId={this.state.activeStepId}
        steps={this.state.steps}
      >
        {this.getStepContent()}
      </WizardComponent>
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps)(DebtorRegisterLeadComponent);

