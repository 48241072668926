/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import LoadingComponent from 'components/loading';

import BuyerTransferInvoicesMandateViewComponent from './mandate';
import BuyerTransferInvoicesPaymentComponent from './payment';

import BuyerInvoice from 'models/buyers/invoices/invoice';
import WireTransferPayment from 'models/payments/wireTransferPayment';

import GetInvoicesToPayRequest from 'models/requests/buyers/getInvoicesToPayRequest';

import BuyerService from 'services/BuyerService';

type BuyerTransferInvoicesComponentProps = {
  activeCompanyId: number;
  activeCompanyInvestmentProfileId: number;
  invoiceIds: number[];
}

type BuyerTransferInvoicesComponentState = {
  invoices: BuyerInvoice[];
  isLoading: boolean;
  isMandated: boolean;
  step: number;
  wireTransferPayment: WireTransferPayment;
}

class BuyerTransferInvoicesComponent extends React.Component<BuyerTransferInvoicesComponentProps, BuyerTransferInvoicesComponentState> {

  constructor(props) { 
    super(props);

    this.state = {
      invoices: [],
      isLoading: true,
      isMandated: false,
      step: 0,
      wireTransferPayment: null
    };
  }

  componentDidMount = async () => {
    try {
      const request = new GetInvoicesToPayRequest(this.props.activeCompanyId, this.props.activeCompanyInvestmentProfileId, this.props.invoiceIds);
      const response = await BuyerService.getInvoicesToPay(request);
      this.setState({
        invoices: response.invoices,
        isLoading: false,
        isMandated: response.isMandated,
        wireTransferPayment: response.wireTransferPayment
      });
    } catch (e) {
      console.error(e);
    }
  }

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <React.Fragment>
        {this.state.isMandated ? (
          <BuyerTransferInvoicesMandateViewComponent
            invoices={this.state.invoices}
          />
        ) : (
          <BuyerTransferInvoicesPaymentComponent
            invoices={this.state.invoices}
            wireTransferPayment={this.state.wireTransferPayment}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id,
  activeCompanyInvestmentProfileId: state.auth.user.activeCompany.investmentProfile.id
});

export default connect(mapStateToProps)(BuyerTransferInvoicesComponent);
