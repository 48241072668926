/* @flow */

export default class PayInvoiceRequest {
  companyId: number;
  invoiceId: number[];

  constructor(companyId: number, invoiceIds: number[]) {
    this.companyId = companyId;
    this.invoiceIds = invoiceIds;
  }
}
