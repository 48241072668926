/* @flow */

import React from 'react';
import {useSelector} from 'react-redux';

import Country from 'models/generics/country';

type CountryCodeComponentProps = {
  countryId: number;
  hideIfNeeded: boolean;
}

export default function CountryCodeComponent(props: CountryCodeComponentProps) {
  const {countries, genericDataIsLoading} = useSelector(state => state.locale);

  const getCountryById = (id: number) => {
    if (!genericDataIsLoading && Array.isArray(countries) && countries.length > 0) {
      const country = countries.find((c: Country) => c.id === id);
      if (country !== undefined && country !== null) return country;
    }
    return null;
  };

  const isCountryToHide = (code: string) => {
    if (code == "LU")
      return true;
    return false;
  };

  const country = getCountryById(props.countryId);
  if (country === null) return null;
  if (props.hideIfNeeded) return null;
  return (<span>{country.code.toUpperCase()}</span>);
}