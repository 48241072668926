/* @flow */


import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DescriptionOutlined from '@material-ui/icons/DescriptionOutlined';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import ComponentWrapperComponent from 'components/websiteStructure/componentWrapper';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import SecurityComponent from 'components/security/index';
import UploadButtonComponent from 'components/buttons/uploadButton';

import Document from 'models/documents/document';
import TooltipComponent from 'components/toolTips/index';

type InvoiceDocumentsViewComponentProps = {
  allowAdd: boolean;
  documents: Document[];
  isProcessing: boolean;
  downloadDocument: (documentId: number) => void;
  uploadDocument: (file: File) => void;
  failHandler: () => void;
}

const useStyles = makeStyles((theme) => ({
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
}));

export default function InvoiceDocumentsViewComponent(props: InvoiceDocumentsViewComponentProps) {
  const classes = useStyles();

  return (
    <ComponentWrapperComponent small>
      <PageSubTitleViewComponent id="component.invoice.documents.title" />

      <Box mt={3}>
        {props.documents.length === 0 ? (
          <Typography variant="body1">
            <IntlMessageComponent id="component.invoice.documents.empty" />
          </Typography>
        ) : (
          <List>
            {props.documents.map(document => (
              <ListItem key={`doc-${document.id}`}>
                <ListItemAvatar>
                  <Avatar className={classes.primary}>
                    <DescriptionOutlined />
                  </Avatar>
                </ListItemAvatar>

                <ListItemText
                  primary={<IntlMessageComponent id={`document.type.${document.code}`} />}
                />

                <ListItemSecondaryAction  pageName="Tooltip.Invoice.Document" elementName="btnDownload">
                  <TooltipComponent pageName="Tooltip.Invoice.Document" elementName="btnDownload">
                    <IconButton edge="end" aria-label="delete" onClick={() => props.downloadDocument(document.id)}>
                      <CloudDownloadIcon />
                    </IconButton>
                  </TooltipComponent>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        )}
      </Box>

      {props.allowAdd &&
        <SecurityComponent pageName="POS.InvoiceDetails.Documents" elementName="btnUpload" checkRoleExecute>
          <Box mt={3}>
            <UploadButtonComponent
              disabled={props.isProcessing}
              label="component.invoice.documents.action.showDialog"
              successHandler={props.uploadDocument}
              failHandler={props.failHandler}
            />
          </Box>
        </SecurityComponent>
      }
    </ComponentWrapperComponent>
  );
}
