/* @flow */

function mandateAmountValidator(value: number, options: {mandatory: boolean, message: ?string}, key: string, attributes: any) {
  if (options.mandatory && (!value || value <= 0)) {
    return options.message;
  }

  // success
  return undefined;
}

export default mandateAmountValidator;
