/* @flow */

import React from 'react';

import UserNotificationsComponent from 'app/users/notifications';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function UserNotificationsPage() {
  return (
    <WebsitePageComponent id="page.profile.notifications.title">
      <UserNotificationsComponent />
    </WebsitePageComponent>
  );
}
