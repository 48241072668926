/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';

import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import SecurityComponent from 'components/security/index';

import BusinessPotentialTableComponent from '../table';

type BestPricingListComponentProps = {
  businessPotentials: BusinessPotential[];
  newBusinessPotential: () => void;
  editBusinessPotential: (debtorCompanyId: number) => void;
  disableBusinessPotential: (debtorCompanyId: number) => void;
  confirmBusinessPotentials: () => void;
}

export default function BestPricingListComponent(props: BestPricingListComponentProps) {
  return (
    <React.Fragment>
      <Box mt={3}>
        <BusinessPotentialTableComponent
          businessPotentials={props.businessPotentials}
          enableActions
          editBusinessPotential={props.editBusinessPotential}
          disableBusinessPotential={props.disableBusinessPotential}
        />
      </Box>

      <Box mt={5}>
        <SecurityComponent pageName="POS.Sellers.BestPricing" elementName="btnAddCustomer">
          <ButtonComponent
            color="secondary"
            variant="contained"
            onClick={props.newBusinessPotential}
          ><IntlMessageComponent id="page.bestPricing.button.addCustomer" /></ButtonComponent>
        </SecurityComponent>

        <Box component="span" ml={1}>
          <ButtonComponent
            color="primary"
            variant="contained"
            disabled={props.businessPotentials.length === 0}
            onClick={props.confirmBusinessPotentials}
          ><IntlMessageComponent id="page.bestPricing.button.finalize" /></ButtonComponent>
        </Box>
      </Box>
    </React.Fragment>
  );
}
