/* @flow */

import React from 'react';

import LoadingComponent from 'components/loading';

import BusinessOpportunityViewComponent from './view';
import BusinessOpportunityForm from './model';

import LeadService from 'services/LeadService';
import {handleApiFormResponse, handleFormChange, handleFormValidation} from 'lib/forms';
import loadConstraints from 'lib/validation/loadConstraints';

type BusinessOpportunityComponentProps = {
  leadId: number;
  translationPrefix: string;
  successHandler: () => void;
  backHandler: () => void;
};

type BusinessOpportunityComponentState = {
  constraints: any;
  errors: Map<string,?string>;
  form: BusinessOpportunityForm;
  formErrors: string[];
  isLoading: boolean;
  isProcessing: boolean;
};

class BusinessOpportunityComponent extends React.Component<BusinessOpportunityComponentProps, BusinessOpportunityComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      constraints: null,
      errors: null,
      form: new BusinessOpportunityForm(),
      formErrors: [],
      isLoading: true,
      isProcessing: false
    };
  }

  componentDidMount = async () => {
    try {
      const constraints = await loadConstraints('businessOpportunity.mandatory', 
        ['invoicesAvgAmountId', 'financingNeedId', 'financingNeedFrequencyId', 'invoicesYearlyAmountId']);
      
      this.setState({
        constraints,
        errors: new Map(Object.keys(constraints).map(e => [e, undefined])),
        isLoading: false
      });
    } catch (e) {
      console.error(e);
    }
  };

  handleTypeIdChange = (fieldName: string) => (id: number) => handleFormChange.call(this, fieldName, id);
  validateForm = () => handleFormValidation.call(this);

  submitForm = async () => {
    if (!this.validateForm()) return;
    this.setState({ formErrors: [], isProcessing: true });

    try {
      const request = this.state.form.toRequest(this.props.leadId);
      await LeadService.setBusinessOpportunity(request);
      this.props.successHandler();
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }
  };

  render = () => {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <BusinessOpportunityViewComponent
        errors={this.state.errors}
        form={this.state.form}
        formErrors={this.state.formErrors}
        isProcessing={this.state.isProcessing}
        translationPrefix={this.props.translationPrefix}
        backHandler={this.props.backHandler}
        handleTypeIdChange={this.handleTypeIdChange}
        submitForm={this.submitForm}
      />
    );
  }
}


export default BusinessOpportunityComponent;
