/* @flow */

import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import CloudDownload from '@material-ui/icons/CloudDownload';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import AmountFormatterComponent from 'components/formatters/amount';
import IntlMessageComponent from 'components/formatters/intlMessage';

import InvoiceBills from 'models/invoices/seller/invoiceBills';
import InvoiceDetails from 'models/invoices/seller/invoiceDetails';

type SellerInvoiceDetailsPricesFlexValueSummaryComponentProps = {
  invoice: InvoiceDetails;
  invoiceBills: InvoiceBills;
  downloadBill: (billId: number) => void;
}

export default function SellerInvoiceDetailsPricesFlexValueSummaryComponent(props: SellerInvoiceDetailsPricesFlexValueSummaryComponentProps) {
  const [displayDetails, setDisplayDetails] = useState(false);

  const toggleDetails = () => setDisplayDetails(!displayDetails);

  const totalFee = props.invoice.flexPrices.discount 
    + props.invoice.flexPrices.listingFee
    + props.invoice.flexPrices.serviceFee
    + props.invoice.flexPrices.insuranceFee
    + props.invoice.flexPrices.urgentPaymentFee;
  
  return (
    <React.Fragment>
      <h4><IntlMessageComponent id="page.seller.invoiceDetails.prices.flex.value.title" data-walkme="sdetails-value-title" /></h4>
      <Typography variant="body1">
        <IntlMessageComponent id="page.seller.invoiceDetails.prices.flex.value.text" data-walkme="sdetails-value-text" />
      </Typography>

      {(props.invoice.debtAmount - props.invoice.flexPrices.financedAmount) > 0 &&
        <Box display="flex" pl={4} color="grey.main" fontSize={13} fontStyle="italic">
          <Box flexGrow={1} mb={1}>
            <IntlMessageComponent id="page.seller.invoiceDetails.prices.flex.value.unfinancedAmount" data-walkme="sdetails-value-unfinancedAmount" />
          </Box>
          <Box>
            <AmountFormatterComponent amount={(props.invoice.debtAmount - props.invoice.flexPrices.financedAmount)} />
          </Box>
        </Box>
      }

      <Box display="flex" mt={3} fontWeight="fontWeightBold">
        <Box flexGrow={1} mb={1}>
          <IntlMessageComponent id="page.seller.invoiceDetails.prices.flex.value.financedAmount" data-walkme="sdetails-value-financedAmount" />
        </Box>
        <Box>
          <AmountFormatterComponent amount={props.invoice.flexPrices.financedAmount} />
        </Box>
      </Box>

      <Box display="flex" fontWeight="fontWeightBold" onClick={toggleDetails}>
        <Box flexGrow={1} mb={1}>
          <IntlMessageComponent id="page.seller.invoiceDetails.prices.flex.value.totalFee" data-walkme="sdetails-value-totalFee" />
          {displayDetails ? <span>&nbsp;<ExpandLessIcon /></span> : <span>&nbsp;<ExpandMoreIcon /></span>}
        </Box>
        <Box>
          - <AmountFormatterComponent amount={totalFee} />
        </Box>
      </Box>

      {displayDetails &&
        <React.Fragment>
          <Box display="flex" pl={4} color="grey.main" fontSize={13}>
            <Box flexGrow={1} mb={1}>
              <IntlMessageComponent id="page.seller.invoiceDetails.prices.flex.value.listingFee" data-walkme="sdetails-value-listingFee" />
            </Box>
            <Box>
              <AmountFormatterComponent amount={props.invoice.flexPrices.listingFee} />
              {props.invoiceBills.listingFeeBillId > 0 &&
                <Box component="span" ml={1}>
                  <IconButton size="small" onClick={() => props.downloadBill(props.invoiceBills.listingFeeBillId)}>
                    <CloudDownload fontSize="inherit" />
                  </IconButton>
                </Box>
              }
            </Box>
          </Box>

          <Box display="flex" pl={4} color="grey.main" fontSize={13}>
            <Box flexGrow={1} mb={1}>
              <IntlMessageComponent id="page.seller.invoiceDetails.prices.flex.value.serviceFee" data-walkme="sdetails-value-serviceFee" />
            </Box>
            <Box>
              <AmountFormatterComponent amount={props.invoice.flexPrices.serviceFee} />
              {props.invoiceBills.serviceFeeBillId > 0 &&
                <Box component="span" ml={1}>
                  <IconButton size="small" onClick={() => props.downloadBill(props.invoiceBills.serviceFeeBillId)}>
                    <CloudDownload fontSize="inherit" />
                  </IconButton>
                </Box>
              }
            </Box>
          </Box>

          <Box display="flex" pl={4} color="grey.main" fontSize={13}>
            <Box flexGrow={1} mb={1}>
              <IntlMessageComponent id="page.seller.invoiceDetails.prices.flex.value.insuranceFee" data-walkme="sdetails-value-insuranceFee" />
            </Box>
            <Box>
              <AmountFormatterComponent amount={props.invoice.flexPrices.insuranceFee} />
              {props.invoiceBills.insuranceFeeBillId > 0 &&
                <Box component="span" ml={1}>
                  <IconButton size="small" onClick={() => props.downloadBill(props.invoiceBills.insuranceFeeBillId)}>
                    <CloudDownload fontSize="inherit" />
                  </IconButton>
                </Box>
              }
            </Box>
          </Box>

          <Box display="flex" pl={4} color="grey.main" fontSize={13}>
            <Box flexGrow={1} mb={1}>
              <IntlMessageComponent id="page.seller.invoiceDetails.prices.flex.value.discount" data-walkme="sdetails-value-discount" />
            </Box>
            <Box>
              <AmountFormatterComponent amount={props.invoice.flexPrices.discount} />
              {props.invoiceBills.discountAttestationId > 0 &&
                  <Box component="span" ml={1}>
                  <IconButton size="small" onClick={() => props.downloadBill(props.invoiceBills.discountAttestationId)}>
                    <CloudDownload fontSize="inherit" />
                  </IconButton>
                </Box>
              }
            </Box>
          </Box>

          {props.invoice.flexPrices.urgentPaymentFee > 0 &&
            <Box display="flex" pl={4} color="grey.main" fontSize={13}>
              <Box flexGrow={1} mb={1}>
                <IntlMessageComponent id="page.seller.invoiceDetails.prices.flex.value.urgentPaymentFee" data-walkme="sdetails-value-urgentPaymentFee" />
              </Box>
              <Box>
                <AmountFormatterComponent amount={props.invoice.flexPrices.urgentPaymentFee} />
                {props.invoiceBills.urgentPaymentFeeBillId > 0 &&
                  <Box component="span" ml={1}>
                    <IconButton size="small" onClick={() => props.downloadBill(props.invoiceBills.urgentPaymentFeeBillId)}>
                      <CloudDownload fontSize="inherit" />
                    </IconButton>
                  </Box>
                }
              </Box>
            </Box>
          }
        </React.Fragment>
      }

      <Box display="flex" color="primary.main" fontSize={16} fontWeight="fontWeightBold">
        <Box flexGrow={1} mb={1}>
          <IntlMessageComponent id="page.seller.invoiceDetails.prices.flex.value.total" data-walkme="sdetails-value-total" />
        </Box>
        <Box>
          <AmountFormatterComponent amount={(props.invoice.flexPrices.financedAmount - totalFee)} />
        </Box>
      </Box>
    </React.Fragment>
  );
}