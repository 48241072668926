/* @flow */

import UpdateMandateRequest from 'models/requests/isos/updateMandateRequest';
import CreateMandateRequest from 'models/requests/isos/createMandateRequest';

export default class MandateForm {
  amount: number;
  sellerCompanyId: number;

  toUpdateRequest(isoCompanyId: number, mandateId: number) {
    const request = new UpdateMandateRequest();
    request.isoCompanyId = isoCompanyId;
    request.mandateId = mandateId;
    request.amount = this.amount;
    return request;
  }

  toCreateRequest(isoCompanyId: number) {
    const request = new CreateMandateRequest();
    request.isoCompanyId = isoCompanyId;
    request.amount = this.amount;
    request.sellerCompanyId = this.sellerCompanyId;
    return request;
  }
}
