/* @flow */

import React from 'react';


import IsoDocumentItem from 'models/documents/isoCommercialDocument';

import DisplayDocuments from './documentsDisplay';

import Box from '@material-ui/core/Box';
import IntlMessageComponent from 'components/formatters/intlMessage';
import Typography from '@material-ui/core/Typography';

type ISOCommercialDocumentsComponentProps = {
  documents: IsoDocumentItem[];
  activeCompanyId: number;
  activeCompanyRole: number;
  path: string;
  downloadDocument: (culture: string, name: string, path: string) => void;
};

export default function ISOCommercialDocumentsComponent(props: ISOCommercialDocumentsComponentProps) {

  return (
    <React.Fragment>
      <Box py={5}>
        <Box component="div" overflow="auto">

          <Typography variant="h4" style={{ marginBottom: "2rem" }}>
            <b><IntlMessageComponent id={`commercialDocuments.title.${props.path}`} /></b>
          </Typography>

          {props.documents.map(doc => (
            <DisplayDocuments
              document={doc}
              activeCompanyId={props.activeCompanyId}
              activeCompanyRole={props.activeCompanyRole}
              path={props.path}
              downloadDocument={props.downloadDocument}
            />
          ))}

        </Box>
      </Box>
    </React.Fragment>
  );
}
