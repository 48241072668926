/* @flow */

import React from 'react';
import { connect } from 'react-redux';

import DebtorSupplierBankAccountChangePopupViewComponent from './view';
import ChangeSupplierAccountRequest from 'models/requests/debtors/changeSupplierAccountRequest';

import DebtorService from 'services/DebtorService';
import { handleApiFormResponse } from 'lib/forms';
import NotificationHelper from 'lib/notifications';

type DebtorSupplierBankAccountChangePopupComponentProps = {
  activeCompanyId: number;
  supplierIds: number[];
  changeToEdebex: boolean;
  handleCancel: () => void;
  handleSuccess: () => void;
}

type DebtorSupplierBankAccountChangePopupComponentState = {
  formErrors: string[];
  isProcessing: boolean;
}

class DebtorSupplierBankAccountChangePopupComponent extends React.Component<DebtorSupplierBankAccountChangePopupComponentProps, DebtorSupplierBankAccountChangePopupComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      formErrors: [],
      isProcessing: false
    };
  }

  submitForm = async () => {
    this.setState({ formErrors: [], isProcessing: true });

    try {
      const request = new ChangeSupplierAccountRequest(this.props.activeCompanyId, this.props.supplierIds, this.props.changeToEdebex);
      await DebtorService.changeSupplierAccount(request);

      NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS, 'notifications.supplier.changeaccount.success');
      this.props.handleSuccess();
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }
  };

  render() {
    return (
      <DebtorSupplierBankAccountChangePopupViewComponent
        isProcessing={this.state.isProcessing}
        handleCancel={this.props.handleCancel}
        changeToEdebex={this.props.changeToEdebex}
        submitForm={this.submitForm}
      />
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps)(DebtorSupplierBankAccountChangePopupComponent);
