/* @flow */

import Company from 'models/companies/company';

import RegisterSupplierRequest from 'models/requests/debtors/registerSupplierRequest';

export default class RegisterSupplierForm {
  company: Company;


  constructor() {
    this.company = null;
  }

  toRequest(companyId: number){
    const request = new RegisterSupplierRequest();
    request.companyId = companyId;
    request.insurerId = this.company.insurerId;
    return request;
  }
}