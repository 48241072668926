import React from 'react';
import {Link} from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';


import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';

import {PROTECTED_APP} from 'constants/pageRoutes';

const useStyles = makeStyles(_ => ({
  root: {
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundImage: `url(${"./images/404/background_404.jpg"})`
  },
  image: {
    width: "100%",
    height: "auto"
  }
}));

export default function Error404Component() {
  const classes = useStyles();

  return (
    <Box height="100%" className={classes.root}>
      <Container maxWidth="sm">
        <Box textAlign="center" pt={10} mx="auto">
          <img src="./images/404/cosmonaute_404.png" alt="404" title="404" className={classes.image} />
          
          <Box color="primary.main" fontSize="6rem" fontWeight="fontWeightBold">
            <IntlMessageComponent id="component.error404.title" />
          </Box>

          <Box fontSize="2rem" fontWeight="fontWeightBold">
            <IntlMessageComponent id="component.error404.subtitle" />
          </Box>

          <Typography variant="body1">
            <IntlMessageComponent id="component.error404.text" />
          </Typography>

          <Box mt={5}>
            <ButtonComponent
              color="primary"
              variant="contained"
              component={Link}
              to={PROTECTED_APP}
            ><IntlMessageComponent id="component.error404.button" /></ButtonComponent>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
