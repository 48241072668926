/* @flow */

import React from 'react';

import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import CompanyUBOForm from '../model';

type CompanyUBOsFormUserInfoPanelComponentProps = {
  form: CompanyUBOForm;
}

export default function CompanyUBOsFormUserInfoPanelComponent(props: CompanyUBOsFormUserInfoPanelComponentProps) {
  return (
    <React.Fragment>
      <PageSubTitleViewComponent id="company.ubos.form.userInfo" />

      <Box mt={3} mb={3}>
        <Alert severity="info">
          <IntlMessageComponent id="company.ubos.form.userInfo.description" />
        </Alert>
      </Box>
        
      <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
        <Grid item xs={12} md={6}>
          <Box px={4}>
            <TextField
              id="form.name"
              label={<IntlMessageComponent id="form.name.label" />}
              value={props.form.lastName}
              InputProps={{readOnly: true}}
              fullWidth
              margin="normal"
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box px={4}>
            <TextField
              id="form.firstName"
              label={<IntlMessageComponent id="form.firstname.label" />}
              value={props.form.firstName}
              InputProps={{readOnly: true}}
              fullWidth
              margin="normal"
            />
          </Box>
        </Grid>
      </Grid>

      <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
        <Grid item xs={12} md={6}>
          <Box px={4}>
            <TextField
              id="form.email"
              label={<IntlMessageComponent id="form.email.label" />}
              value={props.form.email}
              InputProps={{readOnly: true}}
              fullWidth
              margin="normal"
            />
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
