/* @flow */

export default class AddOfficeRequest {
  companyId: number;
  name: string;
  phone: string;
  officeTypeId: number;
  addressLine1: string;
  addressLine2: string;
  zipCode: string;
  city: string;
  countryId: number;
}
