/* @flow */

const PNF = require('google-libphonenumber').PhoneNumberFormat;
const phoneNumberType = require('google-libphonenumber').PhoneNumberType;
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

function mobilePhoneNumberValidator(value: any, options: {message: ?string}, key: string, attributes: any) {
  if (value === '') return undefined;

  try {
    const number = phoneUtil.parseAndKeepRawInput(value);

    if (!phoneUtil.isPossibleNumber(number) || !phoneUtil.isValidNumber(number)) {
      return 'validation.mobile.format';
    }

    const e164format = phoneUtil.format(number, PNF.E164);
    if (value !== e164format) {
      return 'validation.mobile.format';
    }

    if (phoneUtil.getNumberType(number) !== phoneNumberType.MOBILE) {
      return 'validation.mobile.notamobile';
    }
  } catch (e) {
    return options.message ? options.message : 'validation.phone.format';
  }

  return undefined;
}

export default mobilePhoneNumberValidator;
