/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import QuestionAnswerOutlined from '@material-ui/icons/QuestionAnswerOutlined';

import CarouselComponent from 'components/caroussel';
import IconBell from 'components/icons/bell';
import IconHand from 'components/icons/hand';
import IconHourglass from 'components/icons/hourglass';
import IconTransferOutlined from 'components/icons/outlined/transfer';
import IconWallet from 'components/icons/wallet';
import LoadingComponent from 'components/loading';

import ProfileSummaryCard from 'models/dashboards/profileSummaryCard';

import SellerService from 'services/sellerService';

import {
  CARD_TYPE_SELLER_ACTION_SELLER,
  CARD_TYPE_SELLER_ACTION_DEBTOR,
  CARD_TYPE_SELLER_SOLD,
  CARD_TYPE_SELLER_WAITING_EDEBEX,
  CARD_TYPE_SELLER_TOTAL,
  CARD_TYPE_SELLER_UNFINANCED
} from 'constants/cardTypes';

type SellerProfileSummaryCarouselComponentProps = {
  activeCompanyId: number;
}

type SellerProfileSummaryCarouselComponentState = {
  cards: ProfileSummaryCard[];
  isLoading: boolean;
}

class SellerProfileSummaryCarouselComponent extends React.Component<SellerProfileSummaryCarouselComponentProps, SellerProfileSummaryCarouselComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      cards: null,
      isLoading: true
    };
  }

  componentDidMount = async () => {
    try {
      const cards = await SellerService.getProfileSummary(this.props.activeCompanyId);
      this.setState({cards, isLoading: false});
    } catch (e) {
      console.error(e);
    }
  }

  customize = (cardType: number) => {
    switch (cardType) {
      case CARD_TYPE_SELLER_ACTION_SELLER:
        return { color: 'red.main', icon: <IconBell style={{fontSize: '3em'}} />, translationId: 'component.seller.profile.cards.actionseller', walkme: 'sdashboard-box-attenteaction'};
      case CARD_TYPE_SELLER_ACTION_DEBTOR:
        return { color: 'orange.main', icon: <IconHand style={{fontSize: '3em'}} />, translationId: 'component.seller.profile.cards.actiondebtor', walkme: 'sdashboard-box-attenteretour'};
      case CARD_TYPE_SELLER_SOLD:
        return { color: 'green.main', icon: <IconTransferOutlined style={{fontSize: '3em'}} />, translationId: 'component.seller.profile.cards.sold', walkme: 'sdashboard-box-montanttransfere'};
      case CARD_TYPE_SELLER_TOTAL:
        return { color: 'green.main', icon: <IconWallet style={{fontSize: '3em'}} />, translationId: 'component.seller.profile.cards.total', walkme: 'sdashboard-box-traitement'};
      case CARD_TYPE_SELLER_WAITING_EDEBEX:
        return { color: 'grey.main', icon: <IconHourglass style={{fontSize: '3em'}} />, translationId: 'component.seller.profile.cards.waiting', walkme: 'sdashboard-box-cardstotal'};
      case CARD_TYPE_SELLER_UNFINANCED:
        return { color: 'green.main', icon: <QuestionAnswerOutlined style={{fontSize: '3em'}} />, translationId: 'component.seller.profile.cards.unfinanced', walkme: 'sdashboard-box-unfinanced'};
      default:
        return { color: 'grey.main', icon: <QuestionAnswerOutlined style={{fontSize: '3em'}} />, translationId: 'component.seller.profile.cards.unknown', walkme: 'sdashboard-box-unknown'};
    }
  }

  render() {
    if (this.state.isLoading) return <LoadingComponent />;

    return (
      <CarouselComponent
        cards={this.state.cards}
        customize={this.customize}
        walkme="sdashboard-image-votresituation"
      />
    );
  }
}

const mapStateToProps = ({auth}) => ({
  activeCompanyId: auth.user.activeCompany.id
});

export default connect(mapStateToProps)(SellerProfileSummaryCarouselComponent);
