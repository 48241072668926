/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

import BooleanRadioFieldComponent from 'components/formFields/booleanRadio';
import IntlMessageComponent from 'components/formatters/intlMessage';
import OfficeFieldComponent from 'components/formFields/offices';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import RoleFieldDebtorComponent from 'components/formFields/types/roleDebtors';
import ToolTipComponent from 'components/toolTips';

import CompanyUserForm from '../model';
import { DEBTOR_ROLE_ADMINISTRATOR, DEBTOR_ROLE_ACCOUNTANT, DEBTOR_ROLE_APPROVER, DEBTOR_ROLE_COMMERCIAL, DEBTOR_ROLE_OPERATIONS } from 'constants/constants';

type CompanyUsersFormCompanyInfoDebtorPanelComponentProps = {
  errors: Map<string,?string>;
  form: CompanyUserForm;
  isProcessing: boolean;
  userPersonRoleIsActive: boolean;
  hasProcuration: boolean;
  hasProcurationUsers: boolean;
  hasProcurationGeneralConditions: boolean;
  handleChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void;
  handleBooleanChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void;
  handleTypeChange: (fieldName: string) => (id: number) => void;
  newOffice: () => void;
}

export default function CompanyUsersFormCompanyInfoDebtorPanelComponent(props: CompanyUsersFormCompanyInfoDebtorPanelComponentProps) {
  return (
    <React.Fragment>
      <PageSubTitleViewComponent id="page.profile.users.role" />
      <Box p={2} bgcolor="lightblue.dark">
        <IntlMessageComponent id="page.profile.debtor.rolebox" />
      </Box>
      {props.form.companyUserId === 0 && (<Box mt={3} px={4}>
        <BooleanRadioFieldComponent
          id="form.isAdministrator"
          error={!!props.errors.get('isAdministrator')}
          label={<IntlMessageComponent id="form.isAdministrator.addUser" />}
          name="isAdministrator"
          value={props.form.isAdministrator?.toString() || 'false'}
          onChange={props.handleBooleanChange('isAdministrator')}
        />
      </Box>
      )}

      <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
        <Grid item xs={12} md={6}>
          <Box px={4}>
            <OfficeFieldComponent
              id="office"
              label={<IntlMessageComponent id={`form.office.label`} />}
              error={!!props.errors.get('officeId')}
              helperText={props.errors.get('officeId') ?
                <IntlMessageComponent id={props.errors.get('officeId')} /> : ''}
              value={props.form.officeId}
              changeHandler={props.handleTypeChange('officeId')}
              fullWidth
              margin="normal"
              required
            />
            <Link
              component="button"
              variant="body2"
              disabled={props.isProcessing}
              onClick={props.newOffice}
            ><b><IntlMessageComponent id="page.profile.users.newoffice" /></b></Link>
          </Box>
        </Grid>

        {props.userPersonRoleIsActive && <Grid item xs={12} md={6}>
          <Box px={4}>
            <RoleFieldDebtorComponent
              id="form.debtorRole"
              label={<IntlMessageComponent id="form.debtorRole.label" />}
              error={!!props.errors.get('debtorRoleId')}
              helperText={props.errors.get('debtorRoleId') ?
                <IntlMessageComponent id={props.errors.get('debtorRoleId')} /> : ''}
              value={props.form.debtorRoleId}
              changeHandler={props.handleTypeChange('debtorRoleId')}
              fullWidth
              margin="normal"
              required
              hasProcuration={props.hasProcuration}
              hasProcurationUsers={props.hasProcurationUsers}
              hasProcurationGeneralConditions={props.hasProcurationGeneralConditions}
              isAdministrator={props.form.isAdministrator}
              hasAdministratorRole={props.form.debtorRoleId == DEBTOR_ROLE_ADMINISTRATOR}
              InputProps={{
                startAdornment: (props.form.debtorRoleId ?
                  <InputAdornment position="start">
                    <ToolTipComponent
                      pageName={"Tooltip"}
                      elementName={"debtor.roles.explanation"}
                    />
                  </InputAdornment> : "")
              }}
            />
          </Box>
        </Grid>}
      </Grid>

      <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
        <Grid item xs={12} md={6}>
          <Box px={4}>
            <TextField
              id="form.functionName"
              label={<IntlMessageComponent id="form.functionName" />}
              error={!!props.errors.get('functionName')}
              helperText={props.errors.get('functionName') ?
                <IntlMessageComponent id={props.errors.get('functionName')} /> : ''}
              value={props.form.functionName}
              onChange={props.handleChange('functionName')}
              fullWidth
              margin="normal"
              required
            />
          </Box>
        </Grid>
      </Grid>

    </React.Fragment>
  );
}
