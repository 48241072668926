/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import { makeStyles } from '@material-ui/core/styles';

import IconCancelOutlined from 'components/icons/outlined/cancel';
import IconCheckOutlined from 'components/icons/outlined/check';
import IconExclamationMarkOutlined from 'components/icons/outlined/exclamationMark';
import IconHourglassOutlined from 'components/icons/outlined/hourglass';
import IconInvoiceNotDue from 'components/icons/invoiceNotDue';
import IconInvoiceDue from 'components/icons/invoiceDue';
import IconInvoiceDue5Days from 'components/icons/invoiceDue5Days';
import IconInvoiceDue15Days from 'components/icons/invoiceDue15Days';
import IconInvoiceDue25Days from 'components/icons/invoiceDue25Days';
import IconThumbsUpOutlined from 'components/icons/outlined/thumbsUp';
import IconHourglass from 'components/icons/hourglass';
import IntlMessageComponent from 'components/formatters/intlMessage';
import IconMoneyAvailable from 'components/icons/moneyAvailable';

import { INVOICE_CONFIRMING_TYPE_CLAIM, INVOICE_CONFIRMING_TYPE_CLOSED, INVOICE_CONFIRMING_TYPE_DUE, INVOICE_CONFIRMING_TYPE_DUEIN1MONTH, INVOICE_CONFIRMING_TYPE_DUEIN1WEEK, INVOICE_CONFIRMING_TYPE_DUEIN2WEEKS, INVOICE_CONFIRMING_TYPE_DUETHISWEEK, INVOICE_CONFIRMING_TYPE_MISSINGDOCUMENTS, INVOICE_CONFIRMING_TYPE_REFUSED, INVOICE_CONFIRMING_TYPE_TOSIGN, INVOICE_CONFIRMING_TYPE_TOSUBMIT } from 'constants/invoiceTypes';

const useStyles = makeStyles(_ => ({
  label: {
    textTransform: 'uppercase'
  }
}));

type ConfirmingInvoiceStatusViewComponentProps = {
  statusKey: string;
}

export default function ConfirmingInvoiceStatusViewComponent(props: ConfirmingInvoiceStatusViewComponentProps) {
  const classes = useStyles();

  const customize = (statusKey: string) => {
    switch (statusKey) {
      case INVOICE_CONFIRMING_TYPE_TOSUBMIT:
        return { color: 'orange.main', icon: <IconExclamationMarkOutlined fontSize="small" />, translationId: 'invoice.status.confirmingA_tosubmit' };
      case INVOICE_CONFIRMING_TYPE_MISSINGDOCUMENTS:
        return { color: 'red.main', icon: <IconExclamationMarkOutlined fontSize="small" />, translationId: 'invoice.status.confirmingB_missingdocuments' };
      case INVOICE_CONFIRMING_TYPE_TOSIGN:
        return { color: 'blue.main', icon: <IconThumbsUpOutlined fontSize="small" />, translationId: 'invoice.status.confirmingC_tosign' };
      case INVOICE_CONFIRMING_TYPE_REFUSED:
        return { color: 'black.main', icon: <IconCancelOutlined fontSize="small" />, translationId: 'invoice.status.confirmingK_refused' };
      case INVOICE_CONFIRMING_TYPE_DUEIN1MONTH:
        return { color: 'grey.main', icon: <IconInvoiceNotDue fontSize="small" />, translationId: 'invoice.status.confirmingH_duein1month' };
      case INVOICE_CONFIRMING_TYPE_DUEIN2WEEKS:
        return { color: 'orange.main', icon: <IconInvoiceNotDue fontSize="small" />, translationId: 'invoice.status.confirmingG_duein2weeks' };
      case INVOICE_CONFIRMING_TYPE_DUEIN1WEEK:
        return { color: 'red.main', icon: <IconInvoiceNotDue fontSize="small" />, translationId: 'invoice.status.confirmingF_duein1week' };
      case INVOICE_CONFIRMING_TYPE_DUETHISWEEK:
        return { color: 'red.main', icon: <IconInvoiceDue fontSize="small" />, translationId: 'invoice.status.confirmingE_duethisweek' };
      case INVOICE_CONFIRMING_TYPE_DUE:
        return { color: 'red.main', icon: <IconInvoiceDue fontSize="small" />, translationId: 'invoice.status.confirmingD_due' };
      case INVOICE_CONFIRMING_TYPE_CLAIM:
        return { color: 'blue.main', icon: <IconMoneyAvailable fontSize="small" />, translationId: 'invoice.status.confirmingI_claim' };
      case INVOICE_CONFIRMING_TYPE_CLOSED:
        return { color: 'green.main', icon: <IconCheckOutlined fontSize="small" />, translationId: 'invoice.status.confirmingJ_closed' };
      default:
        return { color: 'grey.main', icon: <HelpOutlineOutlinedIcon fontSize="small" />, translationId: 'general.unknown' };
    }
  }

  const data = customize(props.statusKey);

  return (
    <Box
      alignItems="center"
      color={data.color}
      display="flex"
      fontSize="0.8em"
      fontWeight="fontWeightBold"
    >
      {data.icon}
      <Box component="span" className={classes.label} ml={1}>
        <IntlMessageComponent id={data.translationId} />
      </Box>
    </Box>
  );
}