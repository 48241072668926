/* @flow */

import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment/moment';

import CompanyOfficeFormComponent from 'app/companies/offices/companyOfficeForm';
import LoadingComponent from 'components/loading';

import CompanyUsersFormViewComponent from './view';

import CompanyUserForm from './model';

import CompanyService from 'services/CompanyService';
import DocumentService from 'services/DocumentService';

import {handleApiFormResponse, handleFormChange, handleFormValidation} from 'lib/forms';
import loadConstraints from 'lib/validation/loadConstraints';
import NotificationHelper from 'lib/notifications';

import {ROLE_DEBTOR, ROLE_ISO, ROLE_SELLER, ROLE_BUYER, ROLE_CONFIRMING} from 'constants/companyRoles';
import {DOCUMENTS_PERSON_POST} from 'constants/apiRoutes';

import {history} from 'store/index';
import {
  PROTECTED_COMPANY_USERS,
  PROTECTED_COMPANY_USERS_PROCURATION
} from 'constants/pageRoutes';
import { ConsoleView } from 'react-device-detect';
import { getDocumentTypeBack, getDocumentTypeFront } from 'lib/documentHelpers';

type CompanyUsersFormComponentProps = {
  activeCompanyId: number;
  activeCompanyRole: number;
  companyUserId: number;
}

type CompanyUsersFormComponentState = {
  constraints: any;
  errors: Map<string, ?string>;
  form: CompanyUserForm;
  user: CompanyUserDetails;
  formErrors: string[];
  isLoading: boolean;
  isProcessing: boolean;
  showOfficeForm: boolean;
  userPersonRoleIsActive: boolean;
  hasProcuration: boolean;
  hasProcurationUsers: boolean;
  hasProcurationGeneralConditions: boolean;
}

class CompanyUsersFormComponent extends React.Component<CompanyUsersFormComponentProps, CompanyUsersFormComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      constraints: null,
      errors: null,
      form: null,
      user: null,
      formErrors: [],
      isLoading: true,
      isProcessing: false,
      showOfficeForm: false
    };
  }

  componentDidMount = async () => {
    try {
      const constraintFields = [];
      let form = null;
      let userPersonRoleIsActive = true;
      let hasProcuration = false;
      let hasProcurationUsers = false;
      let hasProcurationGeneralConditions = false;
      let user = null;
      if (this.props.companyUserId > 0) {
        constraintFields.push('functionName');

        user = await CompanyService.getCompanyUserDetails(this.props.activeCompanyId, this.props.activeCompanyRole, this.props.companyUserId);
        form = new CompanyUserForm(user);
        userPersonRoleIsActive = user.userPersonRoleIsActive;
        hasProcuration = user.hasProcuration;
        hasProcurationUsers = user.hasProcurationUsers;
        hasProcurationGeneralConditions = user.hasProcurationGeneralConditions;
      } else {
        constraintFields.push('titleId', 'lastName', 'firstName', 'email', 'phone', 'mobile',
          'addressLine1', 'addressLine2', 'zipCode', 'city', 'countryId', 'birthCountryId', 'birthDate', 'birthPlace', 'nationalityId',
          'registrationTypeId', 'registrationNumber', 'registrationExpireDate', 'languageId', 'functionName');
        
        form = new CompanyUserForm();
      }

      if (this.props.activeCompanyRole === ROLE_ISO) constraintFields.push('isoRoleId', 'officeId', 'isoCommissionEmailTypeId');
      if (this.props.activeCompanyRole === ROLE_DEBTOR) constraintFields.push('debtorRoleId', 'officeId');
      if (this.props.activeCompanyRole === ROLE_BUYER) constraintFields.push('buyerRoleId');
      if (this.props.activeCompanyRole === ROLE_SELLER) constraintFields.push('sellerRoleId');
      if (this.props.activeCompanyRole === ROLE_CONFIRMING) constraintFields.push('confirmingRoleId');

      const constraints = await loadConstraints('Person', constraintFields);
      this.setState({
        constraints,
        errors: new Map(Object.keys(constraints).map(e => [e, undefined])),
        form,
        user,
        isLoading: false,
        userPersonRoleIsActive: userPersonRoleIsActive,
        hasProcuration: hasProcuration,
        hasProcurationUsers: hasProcurationUsers,
        hasProcurationGeneralConditions: hasProcurationGeneralConditions
      });
    } catch (error) {
      console.error(error);
    }
  };

  handleChange = (fieldName: string) => (event) => handleFormChange.call(this, fieldName, event.target.value);
  handleBooleanChange = (fieldName: string) => (event) => handleFormChange.call(this, fieldName, event.target.value === 'true');
  handleDateChange = (fieldName: string) => (date) => handleFormChange.call(this, fieldName, date);
  handleFileChange = (fieldName: string) => (file: File) => handleFormChange.call(this, fieldName, file);
  handleTypeChange = (fieldName: string) => (id: number) => {
    handleFormChange.call(this, fieldName, id);
    if (fieldName === 'registrationTypeId' && this.state.user?.registrationTypeId > 0) {
      let form = this.state.form;
      if (id === this.state.user?.registrationTypeId) {
        handleFormChange.call(this, 'registrationNumber', this.state.user.registrationNumber);
        handleFormChange.call(this, 'registrationExpireDate', this.state.user.registrationExpireDate);
      }
      else {
        //handleFormChange.call(this, 'registrationNumber', '');
        //handleFormChange.call(this, 'registrationExpireDate', moment.utc().startOf('d'));
        form.registrationNumber = '';
        form.registrationExpireDate = moment.utc().startOf('d');
        this.setState({ form });
      } 
    }
  }
  handleDocumentSelect = (documentId: number) => (file: File) => {
    const state = this.state;
    if (documentId == state.form.idFront.id) {
      state.form.idFront.file = file;
      state.form.idFront.filename = file.name;
      state.form.idFront.isMissing = false;
    } else if (documentId == state.form.idBack.id) {
      state.form.idBack.file = file;
      state.form.idBack.filename = file.name;
      state.form.idBack.isMissing = false;
    }
    this.setState({state});
  };
  handlePhoneChange = (value: string) => {
    handleFormChange.call(this, 'phone', value);
  }
  handleMobileChange = (value: string) => {
    handleFormChange.call(this, 'mobile', value);
  }

  validateForm = () => handleFormValidation.call(this);

  newOffice = () => {
    this.setState({showOfficeForm: true});
  }

  newOfficeCancel = () => {
    this.setState({showOfficeForm: false});
  }

  newOfficeSuccess = (officeId: number) => {
    this.handleChange('officeId', officeId);
    this.setState({showOfficeForm: false});
  }

  submitForm = async () => {
    if (!this.validateForm()) return;
    this.setState({formErrors: [], isProcessing: true});

    try {
      if (this.props.companyUserId === 0) {
        // create user
        let companyUserId = 0;

        if (this.props.activeCompanyRole === ROLE_ISO || this.props.activeCompanyRole == ROLE_DEBTOR || this.props.activeCompanyRole == ROLE_SELLER || this.props.activeCompanyRole == ROLE_BUYER || this.props.activeCompanyRole == ROLE_CONFIRMING) {
          const request = this.state.form.toAddCompanyUserWithOfficeRequest(this.props.activeCompanyId);
          companyUserId = await CompanyService.addCompanyUserRoles(request);
        }
        else {
          const request = this.state.form.toAddCompanyUserRequest(this.props.activeCompanyId);
          companyUserId = await CompanyService.addCompanyUser(request);
        }

        let documentTypeFront = getDocumentTypeFront(this.state.form.registrationTypeId);
        let documentTypeBack = getDocumentTypeBack(this.state.form.registrationTypeId);
        // add documents
        if (this.state.form.idFront !== null) {
          await DocumentService.sendDocument2(DOCUMENTS_PERSON_POST, this.state.form.idFront, {companyUserId, documentTypeId: documentTypeFront});
        }
        if (this.state.form.idBack !== null) {
          await DocumentService.sendDocument2(DOCUMENTS_PERSON_POST, this.state.form.idBack, {companyUserId, documentTypeId: documentTypeBack});
        }

        if (!this.state.form.isAdministrator) {
          history.push(PROTECTED_COMPANY_USERS_PROCURATION.replace("{id}", companyUserId));
        } else {
          history.push(PROTECTED_COMPANY_USERS);
        }
      } 
      else {
        // update user
        if (this.props.activeCompanyRole === ROLE_ISO || this.props.activeCompanyRole === ROLE_DEBTOR || this.props.activeCompanyRole === ROLE_BUYER || this.props.activeCompanyRole === ROLE_SELLER || this.props.activeCompanyRole === ROLE_CONFIRMING) {
          const request = this.state.form.toUpdateCompanyUserWithOfficeRequest(this.props.activeCompanyId);
          await CompanyService.updateCompanyUserRoles(request);
        }
        else {
          const request = this.state.form.toUpdateCompanyUserRequest(this.props.activeCompanyId);
          await CompanyService.updateCompanyUser(request);
        }

        NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS, 'notifications.users.success');
        history.push(PROTECTED_COMPANY_USERS);
      }
    } catch (error) {
      handleApiFormResponse.call(this, error);
    }
  };

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    if (this.state.showOfficeForm) {
      return (<CompanyOfficeFormComponent successHandler={this.newOfficeSuccess} cancelHandler={this.newOfficeCancel} />);
    }

    return (
      <CompanyUsersFormViewComponent
        errors={this.state.errors}
        form={this.state.form}
        formErrors={this.state.formErrors}
        isProcessing={this.state.isProcessing}
        handleChange={this.handleChange}
        handleBooleanChange={this.handleBooleanChange}
        handleDateChange={this.handleDateChange}
        handleFileChange={this.handleFileChange}
        handleDocumentSelect={this.handleDocumentSelect}
        handleTypeChange={this.handleTypeChange}
        handlePhoneChange={this.handlePhoneChange}
        handleMobileChange={this.handleMobileChange}
        newOffice={this.newOffice}
        submitForm={this.submitForm}
        userPersonRoleIsActive={this.state.userPersonRoleIsActive}
        hasProcuration={this.state.hasProcuration}
        hasProcurationUsers={this.state.hasProcurationUsers}
        hasProcurationGeneralConditions={this.state.hasProcurationGeneralConditions}
      />
    );
  }
}

const mapStateToProps = ({auth}) => ({
  activeCompanyId: auth.user.activeCompany.id,
  activeCompanyRole: auth.user.activeCompany.role
});

export default connect(mapStateToProps)(CompanyUsersFormComponent);
