/* @flow */

import moment from 'moment';

export default class InsertInvoiceRequest {
  sellerCompanyId: number;
  debtorCompanyId: number;

  reference: string;
  debtAmount: number;
  dueDate: moment;
  issueDate: moment;
}
