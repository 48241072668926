/* @flow */

import React from 'react';
import { useSelector } from 'react-redux';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';

import CountryNameComponent from 'components/country/name';
import IntlMessageComponent from 'components/formatters/intlMessage';

import CompanyLookupBaseComponent from '../base';
import CompanyLookupBaseWithButtonComponent from '../base/button';
import SecurityComponent from 'components/security/index';

import DebtorCompany from 'models/companies/debtorCompany';

type CompanyLookupDebtorViewComponentProps = {
  companyErrorLabelId: string;
  countriesForDebtors: Country[];
  knownDebtors: DebtorCompany[];
  required: boolean;
  handleKnownDebtorCompanySelect: (company: DebtorCompany) => void;
  handleSelect: (company: DebtorCompany) => void;
}

export default function CompanyLookupDebtorViewComponent(props: CompanyLookupDebtorViewComponentProps) {
  const { user } = useSelector(state => ({
    user: state.auth.user
  }));
  return (
    <React.Fragment>
      {props.knownDebtors && props.knownDebtors.length > 0 ? (
        <React.Fragment>
          <Box px={4} py={3}>
            <Autocomplete
              getOptionLabel={(option) => option.name}
              onChange={(_, newValue) => props.handleKnownDebtorCompanySelect(newValue)}
              openOnFocus
              options={props.knownDebtors}
              renderInput={(params) =>
                <TextField
                  {...params}
                  id="select-debtor"
                  label={<IntlMessageComponent id="component.knownDebtors.label" />}
                  fullWidth
                  margin="normal"
                />
              }
              renderOption={(company) => (
                <span>{company.name} - <CountryNameComponent countryId={company.countryId} /> [{company.vatNumber}]</span>
              )}
              value={null}
            />
          </Box>

          {user ? <SecurityComponent pageName="POS.Offer.DebtorLookUp" elementName="btnAddNewCompanyLookUp">
            <CompanyLookupBaseWithButtonComponent
              companyErrorLabelId={props.companyErrorLabelId}
              countries={props.countriesForDebtors}
              required={props.required}
              handleSelect={props.handleSelect}
            />
          </SecurityComponent> :
            <CompanyLookupBaseWithButtonComponent
              companyErrorLabelId={props.companyErrorLabelId}
              countries={props.countriesForDebtors}
              required={props.required}
              handleSelect={props.handleSelect}
            />
          }
        </React.Fragment>
      ) : (
        <React.Fragment>
          {user ? <SecurityComponent pageName="POS.Offer.DebtorLookUp" elementName="btnAddNewCompanyLookUp">
            <CompanyLookupBaseComponent
              companyErrorLabelId={props.companyErrorLabelId}
              countries={props.countriesForDebtors}
              required={props.required}
              handleSelect={props.handleSelect}
            />
          </SecurityComponent> :
            <CompanyLookupBaseComponent
              companyErrorLabelId={props.companyErrorLabelId}
              countries={props.countriesForDebtors}
              required={props.required}
              handleSelect={props.handleSelect}
            />
          }
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
