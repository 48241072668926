/* @flow */

import React from 'react';

import LoadingComponent from 'components/loading';

import CompanyBankAccountsFormDialogStep1ViewComponent from './view';

import BankAccountFormModel from './model';

import {handleFormChange, handleFormValidation} from 'lib/forms';
import loadConstraints from 'lib/validation/loadConstraints';

type CompanyBankAccountsFormDialogStep1ComponentProps = {
  formErrors: string[];
  onClose: () => void;
  onSuccess: (form: BankAccountFormModel) => void;
}

type CompanyBankAccountsFormDialogStep1ComponentState = {
  constraints: any;
  errors: Map<string, ?string>;
  form: BankAccountFormModel[];
  isLoading: boolean;
}

class CompanyBankAccountsFormDialogStep1Component extends React.Component<CompanyBankAccountsFormDialogStep1ComponentProps, CompanyBankAccountsFormDialogStep1ComponentState> {

  constructor(props) {
    super(props);
    this.state = {
      constraints: null,
      errors: null,
      form: new BankAccountFormModel(),
      isLoading: true,
      requireRibDocument: false
    };
  }

  componentDidMount = async () => {
    try {
      const constraints = await loadConstraints('companyBankAccount', ['bankCountryId', 'bankIdentifier', 'bankAccount']);

      this.setState({
        constraints,
        errors: new Map(Object.keys(constraints).map(e => [e, undefined])),
        isLoading: false
      });
    } catch (e) {
      console.error(e);
    }
  };

  handleChange = (fieldName: string) => (event) => handleFormChange.call(this, fieldName, event.target.value);
  handleBooleanChange = (fieldName: string) => (value: boolean) => handleFormChange.call(this, fieldName, value);
  handleTypeChange = (fieldName: string) => (id: number) => handleFormChange.call(this, fieldName, id);
  validateForm = () => handleFormValidation.call(this);

  onSubmit = async () => {
    if (!this.validateForm()) return;
    this.props.onSuccess(this.state.form);
  };

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />)
    }

    return (
      <CompanyBankAccountsFormDialogStep1ViewComponent
        errors={this.state.errors}
        form={this.state.form}
        formErrors={this.props.formErrors}
        handleChange={this.handleChange}
        handleBooleanChange={this.handleBooleanChange}
        handleTypeChange={this.handleTypeChange}
        onClose={this.props.onClose}
        onSubmit={this.onSubmit}
      />
    );
  }
}

export default CompanyBankAccountsFormDialogStep1Component;
