
/* @flow */
import moment from 'moment';


export default class InvoiceActiveFilters {

  query: string;
  sellerCompanyId: string;
  statusKey: string;
  dateFrom: moment;
  dateTo: moment;
  invoiceAmounts : number[];
  constructor() {
    this.query = '';
    this.sellerCompanyId = 0;
    this.statusKey = '';
    this.dateFrom = moment().startOf('year');
    this.dateTo = moment();
    this.invoiceAmounts = null;
  }
}
