/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import PhoneNumberComponent from 'components/formFields/phoneNumber';
import ButtonComponent from 'components/buttons/button';
import FinancingNeedComponent from 'components/formFields/types/financingNeed';
import FinancingNeedFrequencyComponent from 'components/formFields/types/financingNeedFrequency';
import FormErrorsComponent from 'components/formErrors';
import IntlMessageComponent from 'components/formatters/intlMessage';
import InvoicesAvgAmountComponent from 'components/formFields/types/invoicesAvgAmount';
import InvoicesYearlyAmountComponent from 'components/formFields/types/invoicesYearlyAmount';
import MandatoryFormFieldsComponent from 'components/form/mandatory';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import CountryFieldComponent from 'components/formFields/countries/countryImpl';
import TitleFieldComponent from 'components/formFields/types/title';

import OfferUCForm from './model';

type UnknownCompanyOfferViewComponentProps = {
  errors: Map<string,?string>;
  form: OfferUCForm;
  formErrors: string[];
  isProcessing: boolean;
  translationPrefix: string;
  countries: Country[];
  backHandler: () => void;
  handleChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void;
  handleCountryChange: () => void;
  handleTypeIdChange: (fieldName: string) => (id: number) => void;
  handleMobileChange: (value: string) => void;
  submitForm: () => void;
  handleChangeMultiple: (fieldName: string) => (event: any) => void;
};

export default function UnknownCompanyOfferViewComponent(props: UnknownCompanyOfferViewComponentProps) {
  return (
    <React.Fragment>
      <Paper elevation={0}>
        <Box mt={3} p={3}>
          <FormErrorsComponent formErrors={props.formErrors} />
          <PageSubTitleViewComponent id={`unknowncompany.businessopportunity.companyandpersonalinfo`}/>
          <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
            <Grid item xs={12} md={6}>
              <Box px={4} data-walkme="getofferuc-field-country">
                <CountryFieldComponent
                  countries={props.countries}
                  isLoading={props.countriesLoading}
                  error={!!props.errors.get('country')}
                  helperText={(props.errors.get('country')) ?
                    <IntlMessageComponent id={props.errors.get('country')} /> : ''}
                  label={<IntlMessageComponent id="form.company.country.label" />}
                  required
                  value={props.form.country ? props.form.country.id : 0}
                  changeHandler={props.handleCountryChange}
                />
              </Box>
              <Box px={4} data-walkme="getofferuc-field-companyname">
                <TextField
                  id="form.company.name"
                  label={<IntlMessageComponent id="component.companyLookup.name.label" />}
                  data-walkme="inscrire-field-entreprise"
                  error={!!props.errors.get('companyName')}
                  helperText={(props.errors.get('companyName')) ? <IntlMessageComponent id={props.errors.get('companyName')} /> : ''}
                  value={props.form.companyName}
                  onChange={props.handleChange('companyName')}
                  fullWidth
                  required
                  margin="normal"
                />  
              </Box>
              <Box px={4} data-walkme="getofferuc-field-vat">
                <TextField
                  id="adornment-vat"
                  data-walkme="sellinvoice-field-tva"
                  label={<IntlMessageComponent id={`Component.companyLookup.VAT.label-93`} />}
                  error={!!props.errors.get('vat')}
                  helperText={(props.errors.get('vat')) ? <IntlMessageComponent id={props.errors.get('vat')} /> : ''}
                  value={props.form.vat}
                  onChange={props.handleChange('vat')}
                  fullWidth
                  required
                  margin="normal"
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box px={4} data-walkme="getofferuc-field-titleid">
                <TitleFieldComponent
                  label={<IntlMessageComponent id="form.title.label" />}
                  error={!!props.errors.get('titleId')}
                  helperText={(props.errors.get('titleId')) ?
                    <IntlMessageComponent id={props.errors.get('titleId')} /> : ''}
                  value={props.form.titleId}
                  changeHandler={props.handleTypeIdChange('titleId')}
                  fullWidth
                  margin="normal"
                  required
                />
              </Box>
              <Box px={4} data-walkme="getofferuc-field-lastname">
                <TextField
                  id="form.name"
                  data-walkme="subscription-field-nom"
                  label={<IntlMessageComponent id="form.name.label" />}
                  error={!!props.errors.get('lastName')}
                  helperText={(props.errors.get('lastName')) ?
                    <IntlMessageComponent id={props.errors.get('lastName')} /> : ''}
                  value={props.form.lastName}
                  onChange={props.handleChange('lastName')}
                  fullWidth
                  margin="normal"
                  required
                />
              </Box>
              <Box px={4} data-walkme="getofferuc-field-firstname">    
                <TextField
                  id="form.firstName"
                  data-walkme="subscription-field-prenom"
                  label={<IntlMessageComponent id="form.firstname.label" />}
                  error={!!props.errors.get('firstName')}
                  helperText={(props.errors.get('firstName')) ?
                    <IntlMessageComponent id={props.errors.get('firstName')} /> : ''}
                  value={props.form.firstName}
                  onChange={props.handleChange('firstName')}
                  fullWidth
                  margin="normal"
                  required
                />
              </Box>
              <Box px={4} data-walkme="getofferuc-field-email">
                <TextField
                  id="form.email"
                  label={<IntlMessageComponent id="form.email.label" />}
                  error={!!props.errors.get('email')}
                  helperText={(props.errors.get('email')) ?
                    <IntlMessageComponent id={props.errors.get('email')} /> : ''}
                  value={props.form.email}
                  onChange={props.handleChange('email')}
                  fullWidth
                  margin="normal"
                  required
                />
              </Box>
              <Box px={4} data-walkme="getofferuc-field-mobile">
                <PhoneNumberComponent
                  id="form.mobile"
                  label={<IntlMessageComponent id="form.mobile.label" />}
                  error={!!props.errors.get('mobile')}
                  helperText={(props.errors.get('mobile'))
                    ? <IntlMessageComponent id={props.errors.get('mobile')} />
                    : <IntlMessageComponent id="form.mobile.helperText" />}
                  value={props.form.mobile}
                  onChange={props.handleMobileChange}
                  fullWidth
                  margin="normal"
                  required
                />
              </Box>
            </Grid>
          </Grid>
          <Box mt={5}>
            <PageSubTitleViewComponent id={`unknowncompany.businessopportunity.financingneed`}/>
          </Box>
          <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
            <Grid item xs={12} md={6}>
              <Box px={4} data-walkme="getofferuc-field-yearlyinvoiceamount">
                <InvoicesYearlyAmountComponent
                  id="form.invoicesYearlyAmount"
                  label={<IntlMessageComponent id="form.invoicesYearlyAmount.label" />}
                  error={!!props.errors.get('invoicesYearlyAmountId')}
                  helperText={props.errors.get('invoicesYearlyAmountId') ?
                    <IntlMessageComponent id={props.errors.get('invoicesYearlyAmountId')} /> : ''}
                  value={props.form.invoicesYearlyAmountId}
                  changeHandler={props.handleTypeIdChange('invoicesYearlyAmountId')}
                  fullWidth
                  margin="normal"
                  required
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box px={4} data-walkme="getofferuc-field-financingfrequency">
                <FinancingNeedFrequencyComponent
                  id="form.financingNeedFrequency"
                  label={<IntlMessageComponent id="form.financingNeedFrequency.label" />}
                  error={!!props.errors.get('financingNeedFrequencyId')}
                  helperText={props.errors.get('financingNeedFrequencyId') ?
                    <IntlMessageComponent id={props.errors.get('financingNeedFrequencyId')} /> : ''}
                    value={props.form.financingNeedFrequencyId}
                  changeHandler={props.handleTypeIdChange('financingNeedFrequencyId')}
                  fullWidth
                  margin="normal"
                  required
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box px={4} data-walkme="getofferuc-field-invoicesavgamount">
                <InvoicesAvgAmountComponent
                  id="form.invoicesAvgAmount"
                  label={<IntlMessageComponent id="form.invoicesAvgAmount.label" />}
                  error={!!props.errors.get('invoicesAvgAmountId')}
                  helperText={props.errors.get('invoicesAvgAmountId') ?
                    <IntlMessageComponent id={props.errors.get('invoicesAvgAmountId')} /> : ''}
                  value={props.form.invoicesAvgAmountId}
                  changeHandler={props.handleTypeIdChange('invoicesAvgAmountId')}
                  fullWidth
                  margin="normal"
                  required
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box px={4} data-walkme="getofferuc-field-financingneed">
                <FinancingNeedComponent
                  id="form.financingNeed"
                  label={<IntlMessageComponent id="form.financingNeed.label" />}
                  error={!!props.errors.get('financingNeedId')}
                  helperText={props.errors.get('financingNeedId') ?
                    <IntlMessageComponent id={props.errors.get('financingNeedId')} /> : ''}
                  value={props.form.financingNeedId}
                  changeHandler={props.handleTypeIdChange('financingNeedId')}
                  fullWidth
                  margin="normal"
                  required
                />
              </Box>
            </Grid>
          </Grid>
          <Box mt={5}>
            <PageSubTitleViewComponent id={`unknowncompany.businessopportunity.yourinvoices`}/>
          </Box>
          <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
            <Grid item xs={12} md={6}>
              <Box p={4} data-walkme="getofferuc-field-issueinvoicesto">
                <FormControl component="fieldset" required error={!!props.errors.get('issueInvoicesTo')}
                  helperText={props.errors.get('issueInvoicesTo') ?
                    <IntlMessageComponent id={props.errors.get('issueInvoicesTo')} /> : ''}>
                  <FormLabel component="legend"><IntlMessageComponent id="offeruc.issueinvoicesto.title" /></FormLabel>
                  <FormGroup aria-label="issueinvoicesto" name="issueinvoicesto" value={props.form.issueInvoicesTo} >
                    <FormControlLabel value="offeruc.issueinvoicesto.b2b" control={<Checkbox onChange={props.handleChangeMultiple('issueInvoicesTo')}/>} label={<IntlMessageComponent id="offeruc.issueinvoicesto.b2b" />} />
                    <FormControlLabel value="offeruc.issueinvoicesto.b2c" control={<Checkbox onChange={props.handleChangeMultiple('issueInvoicesTo')}/>} label={<IntlMessageComponent id="offeruc.issueinvoicesto.b2c" />} />
                    <FormControlLabel value="offeruc.issueinvoicesto.publicorganizations" control={<Checkbox onChange={props.handleChangeMultiple('issueInvoicesTo')}/>} label={<IntlMessageComponent id="offeruc.issueinvoicesto.publicorganizations" />} />
                  </FormGroup>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box p={4} data-walkme="getofferuc-field-invoicesconcerning">
                <FormControl component="fieldset" required error={!!props.errors.get('invoicesConcerning')}
                  helperText={props.errors.get('invoicesConcerning') ?
                    <IntlMessageComponent id={props.errors.get('invoicesConcerning')} /> : ''}>
                  <FormLabel component="legend"><IntlMessageComponent id="offeruc.invoicesconcerning.title" /></FormLabel>
                  <FormGroup aria-label="invoicesconcerning" name="invoicesconcerning" value={props.form.invoicesConcerning} >
                    <FormControlLabel value="offeruc.invoicesconcerning.deliveredgoodsorservices" control={<Checkbox onChange={props.handleChangeMultiple('invoicesConcerning')} />} label={<IntlMessageComponent id="offeruc.invoicesconcerning.deliveredgoodsorservices" />} />
                    <FormControlLabel value="offeruc.invoicesconcerning.ongoingservices" control={<Checkbox onChange={props.handleChangeMultiple('invoicesConcerning')} />} label={<IntlMessageComponent id="offeruc.invoicesconcerning.ongoingservices" />} />
                    <FormControlLabel value="offeruc.invoicesconcerning.notdeliveredgoodsorservices" control={<Checkbox onChange={props.handleChangeMultiple('invoicesConcerning')} />} label={<IntlMessageComponent id="offeruc.invoicesconcerning.notdeliveredgoodsorservices" />} />
                  </FormGroup>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box px={4} data-walkme="getofferuc-field-invoiceshigherthan5k">
                <FormControl component="fieldset" required error={!!props.errors.get('invoicesHigherThan5k')}
                  helperText={props.errors.get('invoicesHigherThan5k') ?
                    <IntlMessageComponent id={props.errors.get('invoicesHigherThan5k')} /> : ''}>
                  <FormLabel component="legend"><IntlMessageComponent id="offeruc.invoiceshigherthan5k.title" /></FormLabel>
                  <FormGroup aria-label="invoiceshigherthan5k" name="invoiceshigherthan5k" value={props.form.invoicesHigherThan5k} >
                    <FormControlLabel value="Yes" control={<Checkbox onChange={props.handleChangeMultiple('invoicesHigherThan5k')} />} label={<IntlMessageComponent id="general.label.yes" />} />
                    <FormControlLabel value="No" control={<Checkbox onChange={props.handleChangeMultiple('invoicesHigherThan5k')} />} label={<IntlMessageComponent id="general.label.no" />} />
                  </FormGroup>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box mt={5} p={4}>
          <ButtonComponent
            color="primary"
            variant="contained"
            isProcessing={props.isProcessing}
            onClick={props.submitForm}
          ><IntlMessageComponent id="general.continue" /></ButtonComponent>
          <MandatoryFormFieldsComponent/>
        </Box>
      </Paper>

      
    </React.Fragment>
  );
}
