/* @flow */

import React from 'react';
import {Route, Switch, useRouteMatch} from 'react-router-dom';

import Error404Page from 'components/errors/error404';

import ISOCommercialPage from './commercial';
import ISOCommissionsPage from './commissions';
import ISOCouponsPage from './coupons';
import ISODashboardPage from './dashboard';
import ISOInvoicesPage from './invoices';
import ISOLeadsPage from './leads';
import ISOMandatesPage from './mandates';
import ISOOffersPage from './offers';
import ISORegisterLeadsPage from './registerLeads';
import ISORegisterSellersPage from './registerSellers';
import ISOSellersPage from './sellers';
import ISOLeadDetailsPage from './leaddetails';

export default function IsoPage() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.url}/`} component={ISODashboardPage} />
      <Route path={`${match.url}/commercial`} component={ISOCommercialPage} />
      <Route path={`${match.url}/commissions`} component={ISOCommissionsPage} />
      <Route path={`${match.url}/coupons`} component={ISOCouponsPage} />
      <Route path={`${match.url}/invoices`} component={ISOInvoicesPage} />
      <Route path={`${match.url}/sellers`} component={ISOSellersPage} />
      <Route path={`${match.url}/leads`} component={ISOLeadsPage} />
      <Route path={`${match.url}/leaddetails/:id#:reopenid`} component={ISOLeadDetailsPage} />
      <Route path={`${match.url}/mandates`} component={ISOMandatesPage} />
      <Route path={`${match.url}/offer`} component={ISOOffersPage} />
      <Route path={`${match.url}/register-lead`} component={ISORegisterLeadsPage} />
      <Route path={`${match.url}/register-seller`} component={ISORegisterSellersPage} />
      <Route component={Error404Page} />
    </Switch>
  );
}
