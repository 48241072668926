/* @flow */

import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';

import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

type DebtorInvoiceDetailsStatusViewComponentProps = {
  invoice: Invoice;
  statusKeys: string[];
}

const useStyles = makeStyles(theme => ({
  popper: {
    zIndex: 1,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
      maxHeight: '20em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${theme.palette.common.white} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${theme.palette.common.white} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${theme.palette.common.white} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${theme.palette.common.white}`,
      },
    },
  },
  arrow: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  }
}));

export default function DebtorInvoiceDetailsStatusViewComponent(props: DebtorInvoiceDetailsStatusViewComponentProps) {
  const classes = useStyles();

  const steps = props.statusKeys.map((statusKey, index) => ({id: index, label: statusKey}));
  let activeStep = steps.find(s => s.label === props.invoice.statusKey) || {id: 0, label: null};

  const [popup, setPopup] = useState(activeStep.id);
  const [arrowRef, setArrowRef] = useState(null);

  const toggle = (stepId) => {
    if (popup === stepId) {
      setPopup(-1);
    } else {
      setPopup(stepId);
    }
  }

  return (
    <React.Fragment>
      <PageSubTitleViewComponent id="debtor.invoiceDetails.status.title"  data-walkme="debtor-invoiceDetails-status" />

        <Stepper activeStep={activeStep.id} alternativeLabel>
          {steps.map(step => (
            <Step key={`step-${step.id}`}>
              <StepLabel id={`Popover-${step.id}`} onClick={() => toggle(step.id)}>
                <IntlMessageComponent id={`invoice.status.${step.label}`} />
              </StepLabel>
            </Step>
          ))}
        </Stepper>

        {steps.map(step => (
          <Popper
            key={`popper-${step.id}`}
            className={classes.popper}
            modifiers={{
              offset: {offset: '0, 7em'},
              flip: {
                enabled: true,
              },
              preventOverflow: {
                enabled: true,
                boundariesElement: 'scrollParent',
              },
              arrow: {
                enabled: true,
                element: arrowRef,
              },
            }}
            id={`Popper-${step.id}`}
            placement="right"
            open={popup === step.id}
            anchorEl={() => document.querySelector(`#Popover-${popup} svg`)}
            onClick={() => toggle(step.id)}
          >
            <span className={classes.arrow} ref={setArrowRef} />
            <Paper elevation={5} style={{maxWidth: '500px'}}>
              <DialogTitle>
                <IntlMessageComponent id={`invoice.status.${step.label}`} />
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <IntlMessageComponent id={`invoice.status.description.${step.label}`} />
                </DialogContentText>
              </DialogContent>
            </Paper>
          </Popper>
        ))}
    </React.Fragment>
  );
}
