/* @flow */

import PaymentDetail from './paymentDetail';
import PaymentOgone from './paymentOgone';

export default class PaymentInfo {
  amount: number;
  details: PaymentDetail[];
  ogone: PaymentOgone;
  reference: string;
}
