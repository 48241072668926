/* @flow */

import React from 'react';
import {useSelector} from 'react-redux';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import FormErrorsComponent from 'components/formErrors';
import IntlMessageComponent from 'components/formatters/intlMessage';

type DebtorInvoicesToPayPopupViewComponentProps = {
  formErrors: string[];
  isProcessing: boolean;
  handleCancel: () => void;
  submitForm: () => void;
}

export default function DebtorInvoicesToPayPopupViewComponent(props: DebtorInvoicesToPayPopupViewComponentProps) {
  const bankAccount = useSelector(state => state.auth.user.activeCompany.roleDebtor.bankAccount);

  const values = {
    iban: <b>{bankAccount.bankIBAN}</b>,
    bic: <b>{bankAccount.bankBIC}</b>
  }

  return (
    <React.Fragment>
      <Dialog open onClose={props.handleCancel}>
        <DialogTitle><IntlMessageComponent id="debtor.pay.popup.title" /></DialogTitle>

        <DialogContent>
          <DialogContentText>
            <IntlMessageComponent id="debtor.pay.popup.text" values={values} />
          </DialogContentText>

          <FormErrorsComponent formErrors={props.formErrors} />
        </DialogContent>
        
        <DialogActions>
          <Button onClick={props.handleCancel} color="primary">
            <IntlMessageComponent id="debtor.pay.popup.btn.cancel" />
          </Button>
          <Button onClick={props.submitForm} color="primary">
            <IntlMessageComponent id="debtor.pay.popup.btn.confirm" />
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
