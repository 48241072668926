/* @flow */

import moment from 'moment';

function futureDateValidator(value: moment, options: {message: ?string}, key: string, attributes: any) {
  // format value as moment
  let dateValue = value;
  if (typeof dateValue === 'undefined') {
    return 'value required';
  } else if (typeof dateValue === 'string') {
    dateValue = moment(value);
  }

  // make sure message has been provided
  if (options.message === undefined || options.message === null) {
    throw new Error('Message is needed');
  }

  // check if the date is in the future
  const now = dateValue.utc().endOf('day');
  const today = moment().utc().endOf('day');
  if (now.isBefore(today)) {
    return options.message;
  }

  // success
  return undefined;
}

export default futureDateValidator;
