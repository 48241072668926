/* @flow */

import Company from 'models/companies/company';
import RegisterLeadRequest from 'models/requests/debtors/registerLeadRequest';

export default class RegisterLeadForm {
  company: Company;
  titleId: number;
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  languageId: number;

  constructor() {
    this.company = null;
    this.titleId = 0;
    this.firstName = '';
    this.lastName = '';
    this.email = '';
    this.mobile = '';
    this.languageId = 0;
  }

  toRequest(companyId: number) {
    const request = new RegisterLeadRequest();
    request.debtorCompanyId = companyId;
    request.insurerId = this.company.insurerId;
    request.titleId = this.titleId;
    request.firstName = this.firstName;
    request.lastName = this.lastName;
    request.email = this.email;
    request.mobile = this.mobile;
    request.languageId = this.languageId;
    return request;
  }
}
