/* @flow */

import InvoiceCompensation from './invoiceCompensation';

export default class InvoiceFlexPrices {
  financedAmount: number;
  transactionPrice: number;
  cashToTransfer: number;

  discount: number;
  discountPct: number;
  buyerDiscount: number;

  listingFee: number;
  listingFeeWithVAT: number;
  listingFeePaidByCard: boolean;

  serviceFee: number;
  serviceFeeWithVAT: number;
  serviceFeePaidByCard: boolean;

  urgentPaymentFee: number;
  urgentPaymentFeeWithVAT: number;
  urgentPaymentFeePaidByCard: boolean;

  insuranceFee: number;
  insuranceFeeWithVAT: number;
  
  mustPayListingFee: boolean;
  mustPayServiceFee: boolean;
  mustPayFees: boolean;
  mustPayAllFees: boolean;
  totalFeeToPay: number;

  compensations: InvoiceCompensation[];
}
