/* @flow */
import React from 'react';
import moment from 'moment';

import {connect} from 'react-redux';
import {setComponentData} from 'store/actions/componentDataActions';

import customSort from 'lib/sortHelpers';

import LoadingComponent from 'components/loading';

import SellerInvoicesPortfolioControlsComponent from './controls/index';
import SellerInvoicesPortfolioTableViewComponent from './view';

import GetInvoicesPortfolioExportRequest from 'models/requests/sellers/getInvoicesPortfolioExportRequest';
import Invoice from 'models/sellers/invoices/invoice';
import InvoiceFilters from 'models/sellers/invoices/invoiceFilters';
import InvoiceActiveFilters from 'models/sellers/invoices/invoiceActiveFilters';

import SellerService from 'services/sellerService';

type SellerInvoicesPortfolioTableComponentProps = {
  activeCompanyId: number;
  activeFilters: InvoiceActiveFilters;
}

type SellerInvoicesPortfolioTableComponentState = {
  filters: InvoiceFilters;
  invoices: Invoice[];
  isLoading: boolean;
  order: string;
  orderBy: ?string;
  page: number;
  pageSize: number;
}

class SellerInvoicesPortfolioTableComponent extends React.Component<SellerInvoicesPortfolioTableComponentProps, SellerInvoicesPortfolioTableComponentState> {

  constructor(props) {
    super(props);
    this.state = {
      filters: null,
      invoices: [],
      isLoading: true,
      order: 'asc',
      orderBy: undefined,
      page: 0,
      pageSize: 10
    };
  }

  componentDidMount = async () => {
    try {
      const {filters, invoices} = await SellerService.getInvoicesPortfolio(this.props.activeCompanyId);
      this.setState({filters, invoices, isLoading: false});
    } catch (e) {
      console.error(e);
    }
  }

  getFilteredResults = () => {
    let filteredInvoices = this.state.invoices;

    if (this.props.activeFilters.query && this.props.activeFilters.query.length > 2) {
      filteredInvoices = filteredInvoices.filter((i: Invoice) => {
        const query = this.props.activeFilters.query.toUpperCase();
        const fields = [i.id, i.invoiceName, i.debtorName, i.reference].map(v => (v || '').toString().toUpperCase());
        return !!(fields.filter(field => field.toString().indexOf(query) !== -1)).length;
      });
    }

    // Filter on invoice status
    if (this.props.activeFilters.statusKey !== '') {
      filteredInvoices = filteredInvoices.filter(i => i.statusKey.toUpperCase() === this.props.activeFilters.statusKey.toUpperCase());
    } 

    if (this.props.activeFilters.dateFrom !== null && this.props.activeFilters.dateTo !== null) {
      filteredInvoices = filteredInvoices.filter(i => {
        return moment(i.createdDate).isBetween(this.props.activeFilters.dateFrom, this.props.activeFilters.dateTo, 'day', '[]');
      });
    } 

    return filteredInvoices;
  };


  handleExport = async (format: string) => {
    try {
      const request = new GetInvoicesPortfolioExportRequest(this.props.activeCompanyId, format, this.props.activeFilters);
      await SellerService.getInvoicesPortfolioExport(request);
    } catch (e) {
      console.error(e);
    }
  };

  handlePaymentReport = async (format: string) => {
    try {
      const request = new GetInvoicesPortfolioExportRequest(this.props.activeCompanyId, format, this.props.activeFilters);
      await SellerService.getPaymentReport(request);
    } catch (e) {
      console.error(e);
    }
  };

  handleFiltersChange = (activeFilters: InvoiceActiveFilters) => {
    const clone = Object.assign({}, activeFilters);
    this.props.setComponentData('sellerInvoicesPortfolioTable', {activeFilters: clone});
  }
  
  handlePageChange = (page: number) => this.setState({page});
  handlePageSizeChange = (pageSize: number) => this.setState({pageSize});

  handleSortOrderChange = (orderBy: string) => {
    let order = this.state.orderBy === orderBy && this.state.order === 'desc' ? 'asc' : 'desc';

    let invoices = null;
    if (orderBy.startsWith('debtorCompany.')) {
      // get exact property name
      let o = orderBy.substring(14);

      // order
      invoices = order === 'desc'
        ? this.state.invoices.sort((a, b) => customSort(b.debtorCompany[o], a.debtorCompany[o]))
        : this.state.invoices.sort((a, b) => customSort(a.debtorCompany[o], b.debtorCompany[o]));
    }
    else if (orderBy.startsWith('flexPrices.')) {
      // get exact property name
      let o = orderBy.substring(11);

      // order
      invoices = order === 'desc'
        ? this.state.invoices.sort((a, b) => customSort(b.flexPrices[o], a.flexPrices[o]))
        : this.state.invoices.sort((a, b) => customSort(a.flexPrices[o], b.flexPrices[o]));
    } 
    else {
      // order
      invoices = order === 'desc'
        ? this.state.invoices.sort((a, b) => customSort(b[orderBy], a[orderBy]))
        : this.state.invoices.sort((a, b) => customSort(a[orderBy], b[orderBy]));
    }

    this.setState({ invoices, order, orderBy });
  };

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <React.Fragment>
        <SellerInvoicesPortfolioControlsComponent
          activeFilters={this.props.activeFilters}
          filters={this.state.filters}
          handleExport={this.handleExport}
          handlePaymentReport={this.handlePaymentReport}
          handleFiltersChange={this.handleFiltersChange}
        />

        <SellerInvoicesPortfolioTableViewComponent
          invoices={this.getFilteredResults()}
          order={this.state.order}
          orderBy={this.state.orderBy}
          page={this.state.page}
          pageSize={this.state.pageSize}
          handlePageChange={this.handlePageChange}
          handlePageSizeChange={this.handlePageSizeChange}
          handleSortOrderChange={this.handleSortOrderChange}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id,
  activeFilters: state.components.sellerInvoicesPortfolioTable.activeFilters
});

export default connect(mapStateToProps, {setComponentData})(SellerInvoicesPortfolioTableComponent);
