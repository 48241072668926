/* @flow */

import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useSelector} from 'react-redux'

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';

import CountryCodeComponent from 'components/country/code';
import IconSeller from 'components/icons/seller';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
    textAlign: 'center',
    verticalAlign: 'middle'
  },
  cover: {
    height: 150,
    width: 150,
  }
}));

export default function WebsiteHeaderSalesContactComponent() {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const classes = useStyles();

  const flipOpen = () => setOpen(prevOpen => !prevOpen);

  const salesPerson = useSelector(state => {
    if (state.auth.user && state.auth.user.activeCompany && state.auth.user.activeCompany.roleISO)
      return state.auth.user.activeCompany.roleISO.salesPerson;
    if (state.auth.user && state.auth.user.activeCompany && state.auth.user.activeCompany.roleDebtor)
      return state.auth.user.activeCompany.roleDebtor.salesPerson;
    return null;
  });

  if (!salesPerson) return null;

  const firstName = salesPerson.firstName.toLowerCase().replace(/ /g, '');
  const lastName = salesPerson.lastName.toLowerCase().replace(/ /g, '');
  const imageUrl = `./images/contacts/${firstName}-${lastName}.png`;
  const name = `${salesPerson.firstName} ${salesPerson.lastName}`;

  return (
    <React.Fragment>
      <IconButton
        ref={anchorRef}
        color="inherit"
        aria-controls="sales-popup"
        aria-haspopup="true"
        onClick={flipOpen}
      >
        <IconSeller />
        
        <Box color="primary.main" ml={1}>
          <ArrowDropDownIcon fontSize="small" />
        </Box>
      </IconButton>
      
      <Popover
        id='sales-popup'
        open={open}
        anchorEl={anchorRef.current}
        onClose={flipOpen}
        anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}
        transformOrigin={{horizontal: 'center', vertical: 'top'}}
      >
        <Card className={classes.root}>
          <CardMedia
            className={classes.cover}
            image={imageUrl}
            title={name}
          />
          <CardContent className={classes.content}>
            <br/>
            <Box><b>{name}</b></Box>
            {salesPerson.phoneNumbers.map((p, i) => (
              <Box key={`phone-${i}`}><b>T:</b> {p.number} (<CountryCodeComponent countryId={p.countryId} />)</Box>
            ))}
            <Box><a href={`mailto:${salesPerson.email}`}><b>{salesPerson.email}</b></a></Box>
        </CardContent>
        </Card>
      </Popover>        
    </React.Fragment>
  );
}
