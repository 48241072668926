/* @flow */

import React from 'react';

import UserInfoComponent from 'app/users/info';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function UserInfoPage() {
  return (
    <WebsitePageComponent id="page.profile.title" noPaper>
      <UserInfoComponent />
    </WebsitePageComponent>
  );
}
