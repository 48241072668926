/* @flow */

import React from 'react';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Toolbar from '@material-ui/core/Toolbar';

import WebsiteHeaderCompanyMenuComponent from './companyMenu';
import WebsiteHeaderCompanyMenuMobileComponent from './companyMenuMobile';
import WebsiteHeaderInvestmentProfileMenuComponent from './investmentProfileMenu';
import WebsiteHeaderInvestmentProfileMenuMobileComponent from './investmentProfileMenuMobile';
import WebsiteHeaderLanguageSwitcherComponent from './languageSwitcher';
import WebsiteHeaderLogoComponent from './logo';
import WebsiteHeaderMailContactComponent from './contact/mail';
import WebsiteHeaderPhoneContactComponent from './contact/phone';
import WebsiteHeaderSalesContactComponent from './contact/sales';
import WebsiteHeaderSellInvoiceButtonComponent from './seller/sellInvoiceButton';
import WebsiteHeaderDebtorInvoicesButtonComponent from './debtor/debtorInvoicesButton';
import WebsiteHeaderSignOutComponent from './signOut';
import WebsiteHeaderUserMenuComponent from './userMenu';
import WebsiteHeaderUserMenuMobileComponent from './userMenuMobile';
import SecurityComponent from 'components/security/index';

import { ROLE_ISO, ROLE_BUYER, ROLE_SELLER, ROLE_DEBTOR } from 'constants/companyRoles';

const useStyles = makeStyles({
  appBar: {
    boxShadow: 'unset !important'
  },
  logo: {
    flexGrow: 1,
  },
  logout: {
    floxGrow: 0,
  }
});

export default function WebsiteHeaderComponent() {
  const classes = useStyles();
  const user = useSelector(state => state.auth.user);

  return (
    <div>
      <div className="hiddenMobile">
        <AppBar className={classes.appBar} color="default" position="fixed">
          <Toolbar>

            <div className={classes.logo}>
              <WebsiteHeaderLogoComponent />
            </div>

            {user ? (
              <React.Fragment>
                {user.activeCompany.role === ROLE_SELLER &&
                  <SecurityComponent pageName="POS.Sellers.Header" elementName="btnSellInvoice">
                    <WebsiteHeaderSellInvoiceButtonComponent />
                  </SecurityComponent>
                }
                {user.activeCompany.role === ROLE_DEBTOR &&
                  <WebsiteHeaderDebtorInvoicesButtonComponent />
                }

                <Divider orientation="vertical" flexItem />
                <WebsiteHeaderUserMenuComponent />
                <Divider orientation="vertical" flexItem />
                <WebsiteHeaderCompanyMenuComponent />
                <Divider orientation="vertical" flexItem />

                {user.activeCompany.role === ROLE_BUYER && user.activeCompany.roleBuyer.investmentProfiles.length > 1 &&
                  <React.Fragment>
                    <WebsiteHeaderInvestmentProfileMenuComponent />
                    <Divider orientation="vertical" flexItem />
                  </React.Fragment>
                }

                {(user.activeCompany.role === ROLE_BUYER || user.activeCompany.role === ROLE_SELLER) &&
                  <React.Fragment>
                    <WebsiteHeaderMailContactComponent />
                    <Divider orientation="vertical" flexItem />
                    <WebsiteHeaderPhoneContactComponent />
                    <Divider orientation="vertical" flexItem />
                  </React.Fragment>
                }

                {(user.activeCompany.role === ROLE_ISO || user.activeCompany.role === ROLE_DEBTOR) &&
                  <React.Fragment>
                    <WebsiteHeaderSalesContactComponent />
                    <Divider orientation="vertical" flexItem />
                  </React.Fragment>
                }

              </React.Fragment>
            ) : (
              <React.Fragment>
                <WebsiteHeaderMailContactComponent />
                <Divider orientation="vertical" flexItem />
                <WebsiteHeaderPhoneContactComponent />
                <Divider orientation="vertical" flexItem />
              </React.Fragment>
            )}

            <WebsiteHeaderLanguageSwitcherComponent />

            {user &&
              <React.Fragment>
                <Divider orientation="vertical" flexItem />
                <WebsiteHeaderSignOutComponent />
              </React.Fragment>
            }
          </Toolbar>
        </AppBar>
      </div>
      <div className="hiddenDesktop">
        <AppBar className={classes.appBar} color="default" position="fixed">
          <Toolbar>

            <div className={classes.logo}>
              <WebsiteHeaderLogoComponent />
            </div>
            {user &&
              <React.Fragment>
                {/* <div className={classes.logo}>
                  <WebsiteHeaderUserMenuMobileComponent />
                </div>*/}
                <Divider orientation="vertical" flexItem /> 
                <div className={classes.logo}>
                  <WebsiteHeaderCompanyMenuMobileComponent />
                </div>
                <Divider orientation="vertical" flexItem />

                {user.activeCompany.role === ROLE_BUYER && user.activeCompany.roleBuyer.investmentProfiles.length > 1 &&
                  <React.Fragment>
                    <WebsiteHeaderInvestmentProfileMenuMobileComponent />
                    <Divider orientation="vertical" flexItem />
                  </React.Fragment>
                }
              </React.Fragment>
            }

            <WebsiteHeaderLanguageSwitcherComponent />
            {user &&
              <React.Fragment>
                <Divider orientation="vertical" flexItem />
                <WebsiteHeaderSignOutComponent />
              </React.Fragment>
            }
          </Toolbar>
        </AppBar>
      </div>
    </div>
  );
}
