/* @flow */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function IconRefreshFilled(props) {
  return (
    <SvgIcon viewBox="0 0 64.54 64.54" {...props}>
      <g>
        <path d="M421.23,265.26a32.27,32.27,0,1,0,32.27,32.27A32.3,32.3,0,0,0,421.23,265.26Zm0,62.54a30.27,30.27,0,1,1,30.27-30.27A30.27,30.27,0,0,1,421.23,327.8Z" transform="translate(-388.96 -265.26)"/>
        <path d="M421.23,269.9a27.64,27.64,0,1,0,27.63,27.63A27.64,27.64,0,0,0,421.23,269.9Zm19.43,28.84a23.32,23.32,0,0,1-.87,4.88c-2.41,6.83-7.35,10.73-14.46,11.67-5.25.7-10.29-.13-14.62-3.46-1.51-1.16-2.81-2.58-4.27-3.94L402.56,311l-.25-.08,1.19-10.72,10.63,1.21.1.25-4.78,3.87a15.3,15.3,0,0,0,6.06,4.95c5.12,2,10.23,2.17,15.09-.76A12.85,12.85,0,0,0,437,299.26c0-.25,0-.5.06-.75a1.85,1.85,0,0,1,1.94-1.86A1.82,1.82,0,0,1,440.66,298.74Zm-.86-5.43-10.67-1-.11-.23,4.53-3.81a15.48,15.48,0,0,0-13.08-6.48c-5,0-9.06,1.75-12.06,5.78a12.73,12.73,0,0,0-2.55,7.72,1.76,1.76,0,0,1-2.07,1.94c-1-.1-1.5-.76-1.55-1.9C402,289,406,282.74,412.1,279.87c6.26-2.95,16.68-2.62,22.71,4.23.52.58,1.06,1.14,1.64,1.77l4.06-3.34.27.07C440.46,286.12,440.14,289.64,439.8,293.31Z" transform="translate(-388.96 -265.26)"/>
      </g>
    </SvgIcon>
  );
}
