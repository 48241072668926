/* @flow */

import moment from 'moment';

export default class CreateLeadOfferRequest {
  leadId: number;
  debtorCompanyId: number;
  dueDate: moment;
  debtAmount: number;
  createdByISO: boolean;
  campaignId: number;
  requesterCompanyId: number;

  constructor(id: number, debtorCompanyId: number, dueDate: moment, debtAmount: number, createdByISO: boolean, requesterCompanyId: number) {
    this.leadId = id;
    this.debtorCompanyId = debtorCompanyId;
    this.dueDate = moment(dueDate).format('YYYY/MM/DD');
    this.debtAmount = debtAmount;
    this.createdByISO = createdByISO;
    this.requesterCompanyId = requesterCompanyId;
  }
}
