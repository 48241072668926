/* @flow */

export function isCountryFranceOrDomTom(countryId: number) {
  switch (countryId) {
    case 146:
    case 1955:
    case 1956:
    case 1958:
    case 1959:
    case 1963:
      return true;
    default:
      return false;
  }
}
