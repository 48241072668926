/* @flow */

import React from 'react';
import moment from 'moment';

type TimeFormatterComponentProps = {
  date: moment
}

export default function TimeFormatterComponent(props: TimeFormatterComponentProps) {
  return (<React.Fragment>{moment.utc(props.date).local().format('HH:mm')}</React.Fragment>);
}
