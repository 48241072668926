/* @flow */

import React from 'react';

import TermsAndConditionsSuccessComponent from 'app/users/termsAndConditions/success';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function TermsAndConditionsSuccessPage() {
  return (
    <WebsitePageComponent id="page.tos.h1">
      <TermsAndConditionsSuccessComponent />
    </WebsitePageComponent>
  );
}
