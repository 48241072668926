/* @flow */

import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import IntlMessageComponent from 'components/formatters/intlMessage';
import TooltipComponent from 'components/toolTips/index';

type MarketPlacePickerComponentProps = {
  disabled: boolean;
  error: boolean;
  fullWidth: boolean;
  helperText: IntlMessageComponent;
  margin: string;
  required: boolean;
  value: string[];
  handleChange: (fieldName: string) => (event: object) => void;
}

export default function MarketPlacePickerComponent(props: MarketPlacePickerComponentProps) {
  return (
    <FormControl component="fieldset" disabled={props.disabled} error={props.error} fullWidth={props.fullWidth} margin={props.margin} required={props.required}>
      <FormLabel component="legend">{<IntlMessageComponent id="general.marketPlace" />}</FormLabel>
      <RadioGroup value={props.value} disabled={props.disabled}  onChange={props.handleChange}>
        <FormControlLabel pageName="Tooltip.Buyer.InvestmentProfile" elementName="PremiumMarketplace"
          control={<Radio />}
          label={<TooltipComponent pageName="Tooltip.Buyer.InvestmentProfile" elementName="PremiumMarketplace"> <IntlMessageComponent id="general.marketPlace.insured" /></TooltipComponent>}
          value="MarketPlaceInsured"
        />
        {/*<FormControlLabel
          control={<Radio />}
          disabled
          label={<IntlMessageComponent id="general.marketPlace.notInsured"/>}
          value="MarketPlaceNotInsured"
        />*/}
        <FormControlLabel pageName="Tooltip.Buyer.InvestmentProfile" elementName="BasicMarketplace"
          control={<Radio />}
          label={<TooltipComponent pageName="Tooltip.Buyer.InvestmentProfile" elementName="BasicMarketplace"> <IntlMessageComponent id="general.marketPlace.delegatedInsurance" /></TooltipComponent>}
          value="MarketPlaceDelegatedInsurance"
        />
        {/*<FormControlLabel
          control={<Radio />}
          disabled
          label={<IntlMessageComponent id="general.marketPlace.public"/>}
          value="MarketPlacePublic"
        />*/}
        {/* <FormControlLabel pageName="Tooltip.Buyer.InvestmentProfile" elementName="EDOFMarketPlace"
          control={<Radio />}
          label={<TooltipComponent pageName="Tooltip.Buyer.InvestmentProfile" elementName="EDOFMarketPlace"> <IntlMessageComponent id="general.marketPlace.edof" /></TooltipComponent>}
          value="MarketPlaceEDOF"
        /> */}
        <FormControlLabel pageName="Tooltip.Buyer.InvestmentProfile" elementName="ConfirmingMarketplace"
          control={<Radio />}
          label={<TooltipComponent pageName="Tooltip.Buyer.InvestmentProfile" elementName="ConfirmingMarketplace"> <IntlMessageComponent id="general.marketPlace.confirming" /></TooltipComponent>}
          value="MarketPlaceConfirming"
        />
      </RadioGroup>
      {props.error && <FormHelperText>{props.helperText}</FormHelperText>}
    </FormControl>
  );
}