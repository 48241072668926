/* @flow */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function IconHourglass(props) {
  return (
    <SvgIcon viewBox="0 0 48.33 61.47" {...props}>
      <g>
        <path d="M401.67,325.51a48,48,0,0,1,3-15.29c2-4.88,4.62-9.26,9-12.41-8.55-7.29-11.13-17.08-12.11-27.83-.87,0-1.8,0-2.73,0a1.55,1.55,0,0,1-1.79-1.46c-.1-.87.7-1.51,1.85-1.51q22.2,0,44.42,0c1.11,0,1.9.33,2,1.5.1.9-.73,1.47-2,1.49h-2.51c-.94,10.64-3.63,20.44-12,27.75,8.75,7.12,11.16,17,12,27.76h2.29c1.59,0,2.25.44,2.28,1.48s-.6,1.49-2.23,1.49H399.68c-.39,0-.78,0-1.17,0a1.3,1.3,0,0,1-1.36-1.52,2.15,2.15,0,0,1,1.39-1.35A14.74,14.74,0,0,1,401.67,325.51Zm2.45-55.46c0,.32-.05.53-.05.74a37.88,37.88,0,0,0,2.29,11.88c2,5.57,4.72,10.66,10,13.95,1.08.67,1,1.64-.07,2.38a31.7,31.7,0,0,0-3,2.22c-4.2,3.84-6.41,8.81-7.83,14.18a34.22,34.22,0,0,0-1.32,10h34.24a2.83,2.83,0,0,0,.07-.67,90.68,90.68,0,0,0-1.33-9.06c-1.68-6.82-4.62-12.91-10.9-16.8-1.13-.7-1-1.64.12-2.47a30.2,30.2,0,0,0,4.13-3.5c4-4.43,6-9.83,7.16-15.55.49-2.38.68-4.81,1-7.32Z" transform="translate(-397.09 -267)"/>
        <path d="M436.14,323.06H406.58c.3-1.93.5-3.8.89-5.63,1.09-5.09,3-9.83,6.6-13.69a14.51,14.51,0,0,1,3.35-2.64,3.73,3.73,0,0,0-.09-6.68,14.7,14.7,0,0,1-6.25-7.2l23.65-7.94a31.32,31.32,0,0,1-4.31,10,17.15,17.15,0,0,1-5.61,5.47,3.37,3.37,0,0,0-.27,5.84c6.19,3.7,8.73,9.68,10.44,16.2C435.5,318.74,435.74,320.8,436.14,323.06Z" transform="translate(-397.09 -267)"/>
      </g>
    </SvgIcon>
  );
}
