/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import ButtonComponent from 'components/buttons/button';
import CompanyLookupISOLeadComponent from 'components/companyLookupV2/isoLeads';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import FormErrorsComponent from 'components/formErrors';

import {PROTECTED_ISO_REGISTER_LEAD} from 'constants/pageRoutes';

type OfferTab1ISOViewComponentProps = {
  company: Company,
  error: boolean,
  formErrors: string[],
  isProcessing: boolean,
  handleCompanyChange: (company: Company) => void,
  submitForm: () => void
}

export default function OfferTab1ISOViewComponent(props: OfferTab1ISOViewComponentProps) {
  return (
    <React.Fragment>
      <FormErrorsComponent formErrors={props.formErrors} />
      <PageSubTitleViewComponent id="component.offer.tab1.iso.title" data-walkme="isogetoffer-title-step1" />

      <Typography variant="body1" gutterBottom>
        <IntlMessageComponent id="component.offer.tab1.iso.text" />
      </Typography>

      <Box mt={3}>
        <CompanyLookupISOLeadComponent
          company={props.company}
          newCompanyPageBtnLabelId="component.offer.tab1.iso.newCustomer.button"
          newCompanyPageUrl={PROTECTED_ISO_REGISTER_LEAD}
          required
          twoColumns
          handleSelect={props.handleCompanyChange}
        />
      </Box>

      <Box mt={5}>
        <ButtonComponent
          color="primary"
          variant="contained"
          disabled={props.company === null}
          isProcessing={props.isProcessing}
          onClick={props.submitForm}
        ><IntlMessageComponent id="component.offer.tab1.iso.button.validate" /></ButtonComponent>
      </Box>
    </React.Fragment>
  );
}
