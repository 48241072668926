/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import LoadingComponent from 'components/loading';

import CompanyOfficePersonFormViewComponent from './view';

import OfficePerson from 'models/companies/officePerson';
import OfficePersonForm from './model';

import LinkPersonToOfficeRequest from 'models/requests/companies/offices/linkPersonToOfficeRequest';

import IsoService from 'services/IsoService';
import DebtorService from 'services/DebtorService';

import { ROLE_ISO, ROLE_DEBTOR } from 'constants/companyRoles';

import {handleApiFormResponse, handleFormChange, handleFormValidation} from 'lib/forms';
import loadConstraints from 'lib/validation/loadConstraints';
import NotificationHelper from 'lib/notifications';

type CompanyOfficePersonFormComponentProps = {
  activeCompanyId: number;
  activeCompanyRole: number;
  officeId: number;
  officePerson: OfficePerson;
  successHandler: () => void;
  cancelHandler: () => void;
}

type CompanyOfficePersonFormComponentState = {
  constraints: any;
  errors: Map<string, ?string>;
  form: OfficePersonForm;
  formErrors: string[];
  isLoading: boolean;
  isProcessing: boolean;
}

class CompanyOfficePersonFormComponent extends React.Component<CompanyOfficePersonFormComponentProps, CompanyOfficePersonFormComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      constraints: null,
      errors: null,
      form: null,
      formErrors: [],
      isLoading: true,
      isProcessing: false
    };
  }

  componentDidMount = async () => {
    try {
      const constraints = await loadConstraints('officePerson', ['titleId', 'lastName', 'firstName',
        'email', 'mobile', 'phone', 'countryId', 'languageId', 'functionName']);

      this.setState({
        constraints,
        errors: new Map(Object.keys(constraints).map(e => [e, undefined])),
        form: new OfficePersonForm(this.props.officePerson),
        isLoading: false
      });
    } catch (error) {
      console.error(error);
    }
  };

  handleChange = (fieldName: string) => (event) => handleFormChange.call(this, fieldName, event.target.value);
  handleTypeChange = (fieldName: string) => (id: number) => handleFormChange.call(this, fieldName, id);

  handlePhoneChange = (value: string) => {
    handleFormChange.call(this, 'phone', value);
  }
  handleMobileChange = (value: string) => {
    handleFormChange.call(this, 'mobile', value);
  }
  handleCompanyPersonChange = (companyPerson: CompanyPerson) => {
    const form = this.state.form;
    form.companyPerson = companyPerson;
    this.setState({form});
  };

  validateForm = () => {
    if (this.state.form.companyPerson !== null) return true;
    return handleFormValidation.call(this);
  }

  submitForm = async () => {
    if (!this.validateForm()) return;
    this.setState({formErrors: [], isProcessing: true});

    try {
      if (this.state.form.companyPerson === null) {

        if (this.props.officePerson) {
          const request = this.state.form.toUpdateOfficePersonRequest(this.props.activeCompanyId, this.props.officeId, this.props.officePerson.id);
        
          if (this.props.activeCompanyRole === ROLE_DEBTOR) { 
            await DebtorService.updateOfficePerson(request);
          } else if (this.props.activeCompanyRole === ROLE_ISO) {
            await IsoService.updateOfficePerson(request);
          }
        } else {
          const request = this.state.form.toAddOfficePersonRequest(this.props.activeCompanyId, this.props.officeId);

          if (this.props.activeCompanyRole === ROLE_DEBTOR) {
            await DebtorService.addOfficePerson(request);
          } else if (this.props.activeCompanyRole === ROLE_ISO) {
            await IsoService.addOfficePerson(request);
          }
        }
      } else {
        const request = new LinkPersonToOfficeRequest(this.props.activeCompanyId, this.props.officeId, this.state.form.companyPerson.id);

        if (this.props.activeCompanyRole === ROLE_DEBTOR) {  
          await DebtorService.linkPersonToOffice(request);
        } else if (this.props.activeCompanyRole === ROLE_ISO) {
          await IsoService.linkPersonToOffice(request);
        }
      }
      NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS);
      this.props.successHandler();
    } catch (error) {
      handleApiFormResponse.call(this, error);
    }
  };

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <CompanyOfficePersonFormViewComponent
        errors={this.state.errors}
        form={this.state.form}
        formErrors={this.state.formErrors}
        isProcessing={this.state.isProcessing}
        officePerson={this.props.officePerson}
        handleChange={this.handleChange}
        handleCompanyPersonChange={this.handleCompanyPersonChange}
        handleTypeChange={this.handleTypeChange}
        handlePhoneChange={this.handlePhoneChange}
        handleMobileChange={this.handleMobileChange}
        submitForm={this.submitForm}
        cancelHandler={this.props.cancelHandler}
      />
    );
  }
}

const mapStateToProps = ({auth}) => ({
  activeCompanyId: auth.user.activeCompany.id,
  activeCompanyRole: auth.user.activeCompany.role
});

export default connect(mapStateToProps)(CompanyOfficePersonFormComponent);
