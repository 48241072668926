/* @flow */

import moment from 'moment/moment';

import BuyerInvestmentProfile from 'models/buyers/investmentProfiles/investmentProfile';
import Company from 'models/companies/company';
import DebtorCompany from 'models/companies/debtorCompany';

export default class BuyerInvoiceBatch {
  id: number;
  reference: string;
  debtAmount: number;
  dueDate: ?moment;
  batchDebtorReference: string;
  batchIsExcluded: boolean;
  batchBalance: number;
}