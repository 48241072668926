/* @flow */

import React, {useState} from 'react';

import customSort from 'lib/sortHelpers';

import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import AmountFormatterComponent from 'components/formatters/amount';
import ButtonComponent from 'components/buttons/button';
import DateFormatterComponent from 'components/formatters/date';
import IntlMessageComponent from 'components/formatters/intlMessage';
import SecurityComponent from 'components/security';
import StyledTableCellTextComponent from 'components/table/cellText';
import StyledTableCellNumbersComponent from 'components/table/cellNumbers';
import StyledTableHeadComponent from 'components/table/head';
import StyledTableRowComponent from 'components/table/row';

import Coupon from 'models/coupons/coupon';

const columnData = [
  {id: 'reference', translationKey: 'component.iso.couponList.header.reference', walkme: 'isocoupons-column-reference', align: 'left'},
  {id: 'value', translationKey: 'component.iso.couponList.header.value', walkme: 'isocoupons-column-value', align: 'right'},
  {id: 'companyName', translationKey: 'component.iso.couponList.header.companyName', walkme: 'isocoupons-column-companyname', align: 'left'},
  {id: 'amountUsed', translationKey: 'component.iso.couponList.header.amountUsed', walkme: 'isocoupons-column-amountused', align: 'right'},
  {id: 'reason', translationKey: 'component.iso.couponList.header.reason', walkme: 'isocoupons-column-reason', align: 'left'},
  {id: 'validTo', translationKey: 'component.iso.couponList.header.validTo', walkme: 'isocoupons-column-validto', align: 'left'}
];

type ISOCouponTableComponentProps = {
  coupons: Coupon[];
  newCoupon: () => void;
};

export default function ISOCouponTableComponent(props: ISOCouponTableComponentProps) {
  const [state, setState] = useState({
    order: 'desc',
    orderBy: undefined,
    page: 0,
    pageSize: 10
  });

  const handlePageChange = (_, page) => setState(prevState => ({...prevState, page}));
  const handlePageSizeChange = event => setState(prevState => ({...prevState, pageSize: event.target.value}));

  const handleSortOrderChange = (orderBy: string) => {
    const order = (state.orderBy === orderBy && state.order === 'desc') ? 'asc' : 'desc';
    setState(prevState => ({...prevState, order, orderBy}));
  };

  const sortCoupons = () => {
    const coupons = state.order === 'desc'
      ? props.coupons.sort((a, b) => customSort(b[state.orderBy], a[state.orderBy]))
      : props.coupons.sort((a, b) => customSort(a[state.orderBy], b[state.orderBy]));
    return coupons;
  }

  const coupons = sortCoupons();

  return (
    <React.Fragment>
      <Box>
        <Box component="div" overflow="auto">
          <Table>
            <StyledTableHeadComponent data-walkme="isomandates-table-headline">
              <StyledTableRowComponent>
                {columnData.map(column => (
                  <StyledTableCellTextComponent align={column.align} key={column.id} padding="none">
                    <TableSortLabel
                      active={state.orderBy === column.id}
                      data-walkme={column.walkme || ''}
                      direction={state.order}
                      onClick={() => handleSortOrderChange(column.id)}
                    ><IntlMessageComponent id={column.translationKey} /></TableSortLabel>
                  </StyledTableCellTextComponent>
                ))}
              </StyledTableRowComponent>
            </StyledTableHeadComponent>

            <TableBody bgcolor="white">
              {!coupons.length && (
                <StyledTableRowComponent>
                  <StyledTableCellTextComponent colSpan={columnData.length}>
                    <Box textAlign="center">
                      <IntlMessageComponent id="component.iso.couponList.noData" />
                    </Box>
                  </StyledTableCellTextComponent>
                </StyledTableRowComponent>
              )}

              {coupons.slice(state.page * state.pageSize, (state.page * state.pageSize) + state.pageSize).map(c => (
                <StyledTableRowComponent hover tabIndex={-1} key={`coupList-${c.id}`}>

                  <StyledTableCellTextComponent padding="none">
                    {c.reference}
                  </StyledTableCellTextComponent>

                  <StyledTableCellNumbersComponent padding="none">
                    <AmountFormatterComponent amount={c.value} />
                  </StyledTableCellNumbersComponent>

                  <StyledTableCellTextComponent padding="none">
                    <b>{c.companyName}</b>
                  </StyledTableCellTextComponent>

                  <StyledTableCellNumbersComponent padding="none">
                    <AmountFormatterComponent amount={c.amountUsed} />
                  </StyledTableCellNumbersComponent>

                  <StyledTableCellTextComponent padding="none">
                    {c.reason}
                  </StyledTableCellTextComponent>

                  <StyledTableCellTextComponent padding="none">
                    <DateFormatterComponent date={c.validTo} />
                  </StyledTableCellTextComponent>
                </StyledTableRowComponent>
              ))}
            </TableBody>
          </Table>

          <Box display="flex" height={60}>
            <Box flexGrow={1} mt={2}>
              <SecurityComponent pageName="POS.Coupons" elementName="btnNew" checkRoleExecute>
                <ButtonComponent
                  color="primary"
                  variant="contained"
                  onClick={props.newCoupon}
                ><IntlMessageComponent id="page.coupons.create" /></ButtonComponent>
              </SecurityComponent>
            </Box>
            <Box justifyContent="flex-end">
              <TablePagination
                component="div"
                count={coupons.length}
                data-walkme="isocoupons-field-couponsperpage"
                rowsPerPage={state.pageSize}
                page={state.page}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePageSizeChange}
                labelRowsPerPage={<IntlMessageComponent id="general.couponPerPage" />}
                labelDisplayedRows={({from, to, count}) => `${from}-${to} / ${count}`}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}
