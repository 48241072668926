/* @flow */

import React from 'react';
import {useSelector} from 'react-redux';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import ButtonComponent from 'components/buttons/button';
import CompanyLookupDebtorComponent from 'components/companyLookupV2/debtors';
import DatePickerComponent from 'components/formFields/dates/datePicker';
import FormErrorsComponent from 'components/formErrors';
import InputAmountComponent from 'components/formatters/inputAmount';
import IntlMessageComponent from 'components/formatters/intlMessage';
import MandatoryFormFieldsComponent from 'components/form/mandatory';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import { ROLE_ISO } from 'constants/companyRoles';

type OfferTab3ViewComponentProps = {
  defaults: LeadOfferDefaults,
  errors: Map<string, ?string>,
  form: LeadOfferForm,
  formErrors: string[],
  isProcessing: boolean,
  goBack: () => void,
  handleChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void,
  handleDateChange: () => void,
  handleDebtorCompanyChange: () => void,
  submitForm: () => void
}

export default function OfferTab3ViewComponent(props: OfferTab3ViewComponentProps) {
  const activeCompanyRole = useSelector(state => state.auth.user !== null ? state.auth.user.activeCompany.role : 0);

  return (
    <React.Fragment>
      <FormErrorsComponent formErrors={props.formErrors} />

      <Typography variant="body1" gutterBottom>
        <IntlMessageComponent id="component.offer.tab3.intro" />
      </Typography>

      <Box mt={5}>
        <Grid container spacing={6} alignItems="stretch">
          <Grid item xs={12} md={6}>
            <PageSubTitleViewComponent
              id={activeCompanyRole === ROLE_ISO ? 'component.offer.tab3.debtor.title.iso' : 'component.offer.tab3.debtor.title'}
              data-walkme={activeCompanyRole === ROLE_ISO ? 'isoofferlead-title-informationsclient' : 'offerlead-title-informationsclient'}
            />
        
            <Box mt={3}>
              <CompanyLookupDebtorComponent
                company={props.form.debtorCompany}
                companyErrorLabelId={props.errors.get('debtorCompany')}
                handleSelect={props.handleDebtorCompanyChange}
                required
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <PageSubTitleViewComponent
              id={activeCompanyRole === ROLE_ISO ? 'component.offer.tab3.invoice.title.iso' : 'component.offer.tab3.invoice.title'}
              data-walkme={activeCompanyRole === ROLE_ISO ? 'isoofferlead-title-informationsfacture' : 'offerlead-title-informationsfacture'}
            />

            <Box mt={3} px={4} py={3}>
              <TextField
                id="form.amount"
                data-walkme="offerlead-field-montantdelafacture"
                label={<IntlMessageComponent id="form.invoiceAmount.label" />}
                error={!!props.errors.get('debtAmount')}
                helperText={(props.errors.get('debtAmount'))
                  ? <IntlMessageComponent id={props.errors.get('debtAmount')} values={{ minDebtAmount: props.defaults.minDebtAmount }} /> : ''}
                value={props.form.debtAmount}
                onChange={props.handleChange('debtAmount')}
                InputProps={{
                  inputComponent: InputAmountComponent,
                  startAdornment: (<InputAdornment position="start">€</InputAdornment>)
                }}
                fullWidth
                margin="normal"
                required
              />
            
              <DatePickerComponent
                id="form.dueDate"
                data-walkme="offerlead-field-datedecheance"
                label={<IntlMessageComponent id="form.dueDate.label" data-walkme="offerlead-title-datedecheance" />}
                error={!!props.errors.get('dueDate')}
                helperText={(props.errors.get('dueDate')) ?
                  <IntlMessageComponent
                    id={props.errors.get('dueDate')}
                    values={{ minDaysToSell: props.defaults.minDaysToSell, maxDaysToSell: props.defaults.maxDaysToSell }} /> : ''}
                value={props.form.dueDate}
                onChange={props.handleDateChange('dueDate')}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box mt={5}>
        {props.enableBackButton &&
          <Box component="span" mr={1}>
            <ButtonComponent
              color="primary"
              variant="outlined"
              isProcessing={props.isProcessing}
              onClick={props.goBack}
            ><IntlMessageComponent id="general.button.back.label" /></ButtonComponent>
          </Box>
        }

        <ButtonComponent
          color="primary"
          variant="contained"
          isProcessing={props.isProcessing}
          onClick={props.submitForm}
        ><IntlMessageComponent id="general.button.validate.label" /></ButtonComponent>

        <MandatoryFormFieldsComponent/>
      </Box>
    </React.Fragment>
  );
}