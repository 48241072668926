/* @flow */

import React from 'react';
import {useHistory} from 'react-router-dom';
import Link from '@material-ui/core/Link';

type BackButtonComponentProps = {
  action: () => void;
}

export default function BackButtonComponent(props: BackButtonComponentProps) {  
  const history = useHistory();

  const onClick = () => {
    if (props.action) {
      props.action();
    } else {
      history.goBack();
    }
  }

  return (<Link component="button" variant="body2" onClick={onClick}>{props.children}</Link>);
}
