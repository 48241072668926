/* @flow */

import NotificationSetting from './notificationSetting';

export default class NotificationSettings {
  enableSMS: bool;
  sendBillEmails: bool;
  sendPaymentEmails: bool;
  settings: NotificationSetting[];
}
