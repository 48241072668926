/* @flow */

import React from 'react';

import Slider from '@material-ui/core/Slider';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';

import IntlMessageComponent from 'components/formatters/intlMessage';

type DebtorInvoiceAmountComponentProps = {
  max: ?number;
  min: ?number;
  changeHandler: (values: number[]) => void;
}


export default function DebtorInvoiceAmountComponent(props: DebtorInvoiceAmountComponentProps) {
  var isDisabled = false;
  if (!props.min && !props.max)
    isDisabled = true;

  return (
    <React.Fragment>
      <Grid container item xs={12} md={6}>
        <Grid item xs={6} md={6}>
          <Box display="flex" height="100%" alignItems="center" pt={2}>
            <b><IntlMessageComponent id="debtor.portfolio.table.filters.amount" /></b>
          </Box>
        </Grid>
      </Grid>
      <Grid container item xs={12} md={6}>
        <Grid item xs={6} md={6}>
          <Box pr={1}>
            <TextField
              id="form-amount-min"
              value={props.min}
              label={<IntlMessageComponent id="debtor.filters.amountmin" />}
              onChange={(val) => props.changeHandler([val.target.value, props.max])}
              fullWidth
              margin="dense"
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box pl={1}>
            <TextField
              id="form-amount-max"
              value={props.max}
              label={<IntlMessageComponent id="debtor.filters.amountmax" />}
              onChange={(val) => props.changeHandler([props.min, val.target.value])}
              fullWidth
              margin="dense"
            />
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
