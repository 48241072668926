/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

import AmountFormatterComponent from 'components/formatters/amount';
import DateFormatterComponent from 'components/formatters/date';
import IntlMessageComponent from 'components/formatters/intlMessage';
import StyledTableCellTextComponent from 'components/table/cellText';
import StyledTableCellNumbersComponent from 'components/table/cellNumbers';
import StyledTableHeadComponent from 'components/table/head';
import StyledTableRowComponent from 'components/table/row';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import RealizedData from 'models/companies/companyFixContractRealizedData';

const columnData = [
  { id: 'age', translationKey: 'component.fixcontract.realized.header.age' },
  { id: 'period', translationKey: 'component.fixcontract.realized.header.period' },
  { id: 'financedAmount', translationKey: 'component.fixcontract.realized.header.financedAmount' },
  { id: 'financedAmountDelta', translationKey: 'component.fixcontract.realized.header.financedAmountDelta' },
  { id: 'avgInvoiceAmount', align:'left' ,translationKey: 'component.fixcontract.realized.header.avgInvoiceAmount' },
  { id: 'avgInvoiceAmountDelta', translationKey: 'component.fixcontract.realized.header.avgInvoiceAmountDelta' },
  { id: 'serviceFee', translationKey: 'component.fixcontract.realized.header.serviceFee' },
  { id: 'minServiceFeeDelta', translationKey: 'component.fixcontract.realized.header.minServiceFeeDelta' },
  { id: 'cummFinancedAmount', translationKey: 'component.fixcontract.realized.header.cummFinancedAmount' },
  { id: 'cummAvgFinancedAmount', translationKey: 'component.fixcontract.realized.header.cummAvgFinancedAmount' },
  { id: 'cummServiceFee', translationKey: 'component.fixcontract.realized.header.cummServiceFee' },
  { id: 'cummMinServiceFee', translationKey: 'component.fixcontract.realized.header.cummMinServiceFee' },
  { id: 'cummMinServiceFeeDelta', translationKey: 'component.fixcontract.realized.header.cummMinServiceFeeDelta' },
];

type RealizedDataTableComponentProps = {
  realizedData: RealizedData[];
}

export default function RealizedDataTableComponent(props: RealizedDataTableComponentProps) {
  return (
    <React.Fragment>
      <Box component="div" overflow="auto" pt={3}>
        <PageSubTitleViewComponent id="page.fixContract.realizedtitle" />
        <Table>
          <StyledTableHeadComponent>
            <StyledTableRowComponent>
              {columnData.map(column => (
                <StyledTableCellTextComponent align={column?.align ?? 'right'} key={column.id} padding="none">
                  <IntlMessageComponent id={column.translationKey} />
                </StyledTableCellTextComponent>
              ))}
              <StyledTableCellNumbersComponent padding="none" />
            </StyledTableRowComponent>
          </StyledTableHeadComponent>

          <TableBody bgcolor="white">
            {props.realizedData.length === 0 &&
              <StyledTableRowComponent>
                <StyledTableCellTextComponent colSpan={columnData.length + 1}>
                  <Box textAlign="center">
                    <IntlMessageComponent id="general.noData" />
                  </Box>
                </StyledTableCellTextComponent>
              </StyledTableRowComponent>
            }

            {props.realizedData.map(realized => (
              <StyledTableRowComponent key={`realized-${realized.period}`}>
                <StyledTableCellNumbersComponent padding="none">
                  {realized.age}
                </StyledTableCellNumbersComponent>

                <StyledTableCellNumbersComponent padding="none">
                  <DateFormatterComponent date={realized.period} />
                </StyledTableCellNumbersComponent>

                <StyledTableCellNumbersComponent padding="none">
                  <AmountFormatterComponent amount={realized.financedAmount} />
                </StyledTableCellNumbersComponent>

                <StyledTableCellNumbersComponent padding="none">
                  <AmountFormatterComponent amount={realized.financedAmountDelta} />
                </StyledTableCellNumbersComponent>

                <StyledTableCellNumbersComponent padding="none">
                  <AmountFormatterComponent amount={realized.avgInvoiceAmount} />
                </StyledTableCellNumbersComponent>

                <StyledTableCellNumbersComponent padding="none">
                  <AmountFormatterComponent amount={realized.avgInvoiceAmountDelta} />
                </StyledTableCellNumbersComponent>

                <StyledTableCellNumbersComponent padding="none">
                  <AmountFormatterComponent amount={realized.serviceFee} />
                </StyledTableCellNumbersComponent>

                <StyledTableCellNumbersComponent padding="none">
                  <AmountFormatterComponent amount={realized.minServiceFeeDelta} />
                </StyledTableCellNumbersComponent>

                <StyledTableCellNumbersComponent padding="none">
                  <AmountFormatterComponent amount={realized.cummFinancedAmount} />
                </StyledTableCellNumbersComponent>

                <StyledTableCellNumbersComponent padding="none">
                  <AmountFormatterComponent amount={realized.cummAvgFinancedAmount} />
                </StyledTableCellNumbersComponent>

                <StyledTableCellNumbersComponent padding="none">
                  <AmountFormatterComponent amount={realized.cummServiceFee} />
                </StyledTableCellNumbersComponent>

                <StyledTableCellNumbersComponent padding="none">
                  <AmountFormatterComponent amount={realized.cummMinServiceFee} />
                </StyledTableCellNumbersComponent>

                <StyledTableCellNumbersComponent padding="none">
                  <AmountFormatterComponent amount={realized.cummMinServiceFeeDelta} />
                </StyledTableCellNumbersComponent>

                <StyledTableCellNumbersComponent padding="none" />
                
              </StyledTableRowComponent>
            ))}
          </TableBody>
        </Table>
      </Box>
    </React.Fragment>
  );
}
