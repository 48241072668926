/* @flow */

import React from 'react';
import { connect } from 'react-redux';

import LoadingComponent from 'components/loading';

import ISOCouponFormComponent from './form';
import ISOCouponTableComponent from './table';

import Coupon from 'models/coupons/coupon';

import IsoService from 'services/IsoService';

type ISOCouponsComponentProps = {
  activeCompanyId: number;
}

type ISOCouponsComponentState = {
  coupons: Coupon[];
  isLoading: boolean;
  showForm: boolean;
}

class ISOCouponsComponent extends React.Component<ISOCouponsComponentProps, ISOCouponsComponentState> {

  constructor(props) {
    super(props);
    this.state = {
      coupons: [],
      isLoading: true,
      showForm: false
    };
  }

  componentDidMount = async () => {
    try {
      const coupons = await IsoService.getCoupons(this.props.activeCompanyId);

      this.setState({coupons, isLoading: false});
    } catch (e) {
      console.error(e);
    }
  }

  toggleForm = () => this.setState({showForm: !this.state.showForm});

  successHandler = async () => {
    this.setState({isLoading: true, showForm: false});

    try {
      const coupons = await IsoService.getCoupons(this.props.activeCompanyId);
      this.setState({coupons, isLoading: false});
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <React.Fragment>
        {this.state.showForm ? (
          <ISOCouponFormComponent
            successHandler={this.successHandler}
            cancelHandler={this.toggleForm}
          />
        ) : (
          <ISOCouponTableComponent
            coupons={this.state.coupons}
            newCoupon={this.toggleForm}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps)(ISOCouponsComponent);
