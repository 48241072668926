/* @flow */

import React from 'react';
import {useParams} from 'react-router-dom';

import CompanyUsersFormComponent from 'app/companies/users/form';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function CompanyUsersEditPage() {
  const { id } = useParams();

  return (
    <WebsitePageComponent id="page.profile.users.title">
      <CompanyUsersFormComponent companyUserId={id} />
    </WebsitePageComponent>
  );
}
