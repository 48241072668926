/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import customSort from 'lib/sortHelpers';

import LoadingComponent from 'components/loading';

import BuyerInvoicesToPurchaseTableViewComponent from './view';
import BuyerRefuseInvoiceFormDialogComponent from 'components/refuseInvoiceDialog';

import BuyerInvoice from 'models/buyers/invoices/invoice';
import PurchaseInvoiceRequest from 'models/requests/buyers/purchaseInvoiceRequest';

import BuyerService from 'services/BuyerService';
import {handleApiFormResponse} from 'lib/forms';

import {history} from 'store/index';
import {PROTECTED_BUYER_TRANSFER_INVOICES} from 'constants/pageRoutes';

import {INVOICE_BUYER_TYPE_TO_BUY_REFUSED, INVOICE_BUYER_TYPE_TO_BUY_ASSIGNED, INVOICE_BUYER_TYPE_TO_PAY} from 'constants/invoiceTypes';

type BuyerInvoicesToPurchaseTableComponentProps = {
  activeCompanyId: number;
  activeCompanyInvestmentProfileId: number;
  activeCompanyInvestmentProfileIsActive: boolean;
  includeInvoicesToPay: boolean;
}

type BuyerInvoicesToPurchaseTableComponentState = {
  invoices: BuyerInvoice[];
  isLoading: boolean;
  isProcessing: boolean;
  order: string;
  orderBy: string;
  page: number;
  pageSize: number;
  selected: number[];
  refuseInvoicesFormDialogOpen: boolean;
  refuseInvoiceId: number;
}

class BuyerInvoicesToPurchaseTableComponent extends React.Component<BuyerInvoicesToPurchaseTableComponentProps, BuyerInvoicesToPurchaseTableComponentState> {

  constructor(props) {
    super(props);
    this.state = {
      invoices: [],
      isLoading: this.props.activeCompanyInvestmentProfileIsActive,
      isProcessing: false,
      order: 'asc',
      orderBy: 'price',
      page: 0,
      pageSize: 10,
      selected: [],
      refuseInvoiceId: 0
    };
  }

  componentDidMount = async () => {
    if (!this.props.activeCompanyInvestmentProfileIsActive)
      return;

    try {
      let invoices = await BuyerService.getInvoicesToPurchase(this.props.activeCompanyId, this.props.activeCompanyInvestmentProfileId, this.props.includeInvoicesToPay);
      invoices = invoices.some(i => i.type === INVOICE_BUYER_TYPE_TO_BUY_ASSIGNED) ? invoices.filter(a => a.type === INVOICE_BUYER_TYPE_TO_BUY_ASSIGNED || a.type === INVOICE_BUYER_TYPE_TO_PAY) : invoices;
      this.setState({invoices, selected: invoices.filter(a => a.type === INVOICE_BUYER_TYPE_TO_BUY_ASSIGNED).map(i => i.id), isLoading: false});
    } catch (e) {
      console.error(e);
    }
  }

  handleSortOrderChange = (orderBy: string) => {
    let order = this.state.orderBy === orderBy && this.state.order === 'desc' ? 'asc' : 'desc';

    let invoices = null;
    if (orderBy.startsWith('debtorCompany.')) {
      // get exact property name
      let o = orderBy.substring(14);

      // order
      invoices = order === 'desc'
        ? this.state.invoices.sort((a, b) => customSort(b.debtorCompany[o], a.debtorCompany[o]))
        : this.state.invoices.sort((a, b) => customSort(a.debtorCompany[o], b.debtorCompany[o]));
    } 
    else if (orderBy.startsWith('investmentProfile.')) {
      // get exact property name
      let o = orderBy.substring(18);

      // order
      invoices = order === 'desc'
        ? this.state.invoices.sort((a, b) => customSort(b.investmentProfile[o], a.investmentProfile[o]))
        : this.state.invoices.sort((a, b) => customSort(a.investmentProfile[o], b.investmentProfile[o]));
    }
    else if (orderBy.startsWith('sellerCompany.')) {
      // get exact property name
      let o = orderBy.substring(14);

      // order
      invoices = order === 'desc'
      ? this.state.invoices.sort((a, b) => customSort(b.sellerCompany[o], a.sellerCompany[o]))
      : this.state.invoices.sort((a, b) => customSort(a.sellerCompany[o], b.sellerCompany[o]));
    }
    else {
      // order
      invoices = order === 'desc'
        ? this.state.invoices.sort((a, b) => customSort(b[orderBy], a[orderBy]))
        : this.state.invoices.sort((a, b) => customSort(a[orderBy], b[orderBy]));
    }

    this.setState({ invoices, order, orderBy });
  };

  handlePageChange = (page: number) => this.setState({page});
  handlePageSizeChange = (pageSize: number) => this.setState({pageSize});
  
  selectInvoice = (id: number) => {
    if (this.state.isProcessing) { return; }

    const selectedIndex = this.state.selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state.selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.state.selected.slice(1));
    } else if (selectedIndex === this.state.selected.length - 1) {
      newSelected = newSelected.concat(this.state.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(this.state.selected.slice(0, selectedIndex), this.state.selected.slice(selectedIndex + 1));
    }

    this.setState({selected: newSelected});
  };

  handleBuyButtonClick = async () => {
    if (!this.checkBuyable()) {
      // user only selected invoices to pay
      history.push({
        pathname: PROTECTED_BUYER_TRANSFER_INVOICES,
        state: {invoiceIds: this.state.selected}
      });
    } else {
      this.setState({isProcessing: true});
      try {
        const promises = this.state.selected.map(id => {
          const request = new PurchaseInvoiceRequest(this.props.activeCompanyId, this.props.activeCompanyInvestmentProfileId, id);
          return BuyerService.purchaseInvoice(request);
        });

        await Promise.all(promises);
        
        history.push({
          pathname: PROTECTED_BUYER_TRANSFER_INVOICES,
          state: {invoiceIds: this.state.selected}
        });
      } catch (e) {
        handleApiFormResponse.call(this, e);
      }
    }
  };

  checkBuyable() {
    for (let i = 0, len = this.state.selected.length; i < len; i++) {
      const invoice = this.state.invoices.find(inv => inv.id === this.state.selected[i]);
      if (invoice && invoice.toPurchase) {
        return true;
      }
    }
    return false;
  }

  refuseInvoice = id => this.setState({refuseInvoiceId: id});
  closeRefuseInvoiceDialog = () => this.setState({refuseInvoiceId: 0});

  handleRefuseInvoiceSuccess = async () => {
    const invoices = this.state.invoices;
    const inv = invoices.find(i => i.id === this.state.refuseInvoiceId);
    if (inv) { inv.type = INVOICE_BUYER_TYPE_TO_BUY_REFUSED; }
    this.setState({invoices, refuseInvoiceId: 0});
  }

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <React.Fragment>
        <BuyerInvoicesToPurchaseTableViewComponent
          invoices={this.state.invoices}
          isProcessing={this.state.isProcessing}
          order={this.state.order}
          orderBy={this.state.orderBy}
          page={this.state.page}
          pageSize={this.state.pageSize}
          selected={this.state.selected}
          buyInvoices={this.handleBuyButtonClick}
          handlePageChange={this.handlePageChange}
          handlePageSizeChange={this.handlePageSizeChange}
          handleSortOrderChange={this.handleSortOrderChange}
          refuseInvoice={this.refuseInvoice}
          selectInvoice={this.selectInvoice}
        />

        {this.state.refuseInvoiceId > 0 &&
          <BuyerRefuseInvoiceFormDialogComponent
            invoiceId={this.state.refuseInvoiceId}
            closeDialog={this.closeRefuseInvoiceDialog}
            handleSuccess={this.handleRefuseInvoiceSuccess}
          />
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id,
  activeCompanyInvestmentProfileId: state.auth.user.activeCompany.investmentProfile.id,
  activeCompanyInvestmentProfileIsActive: state.auth.user.activeCompany.investmentProfile.isActive
});

export default connect(mapStateToProps)(BuyerInvoicesToPurchaseTableComponent);
