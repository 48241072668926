/* @flow */

export default class CompanyUserDetails {
  companyUserId: number;
  lastName: string;
  firstName: string;
  email: string;
  functionName: string;
  languageId: number;
  isoRoleId: number;
  debtorRoleId: number;
  isAdministrator: boolean;
  buyerRoleId: number;
  sellerRoleId: number;
  userPersonRoleIsActive: boolean;
  isoCommissionEmailTypeId: Number;
  hasProcuration: boolean;
  hasProcurationUsers: boolean;
  hasProcurationGeneralConditions: boolean;
}
