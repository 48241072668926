/* @flow */

import React from 'react';
import {useSelector} from 'react-redux';

import Box from '@material-ui/core/Box';

import CompanyItemComponent from 'components/company';

import CompanyLookupBaseComponent from '../base';

import Company from 'models/companies/company';

import {
  COMPANY_TYPE_BUYER,
  COMPANY_TYPE_CONFIRMING,
  COMPANY_TYPE_DEBTOR,
  COMPANY_TYPE_ISO,
  COMPANY_TYPE_SELLER
} from 'constants/constants';

type CompanyLookupWithTypeComponentProps = {
  company: Company;
  companyErrorLabelId: string;
  companyType: number;
  required: boolean;
  handleSelect: (company: Company) => void;
  handleCountry: (countryId: number) => void;
}

export default function CompanyLookupWithTypeComponent(props: CompanyLookupWithTypeComponentProps) {
  const countriesForBuyers = useSelector(state => state.locale.countriesForBuyers);
  const countriesForDebtors = useSelector(state => state.locale.countriesForDebtors);
  const countriesForISOs = useSelector(state => state.locale.countriesForISOs);
  const countriesForSellers = useSelector(state => state.locale.countriesForSellers);
  const countriesForConfirming = useSelector(state => state.locale.countriesForConfirming);

  let countries = [];
  if (props.companyType === COMPANY_TYPE_BUYER) {
    countries = countriesForBuyers;
  } else if (props.companyType === COMPANY_TYPE_DEBTOR) {
    countries = countriesForDebtors;
  } else if (props.companyType === COMPANY_TYPE_ISO) {
    countries = countriesForISOs;
  } else if (props.companyType === COMPANY_TYPE_SELLER) {
    countries = countriesForSellers;
  } else if (props.companyType === COMPANY_TYPE_CONFIRMING) {
    countries = countriesForConfirming;
  }

  if (props.company) {
    return (
      <Box px={4} py={3} bgcolor="lightblue.main">
        <CompanyItemComponent
          buttonLabelId="component.offer.tab1.company.change"
          company={props.company}
          selected
          selectHandler={() => props.handleSelect(null)}
        />
      </Box>
    );
  }

  return (
    <CompanyLookupBaseComponent
      company={props.company}
      companyErrorLabelId={props.companyErrorLabelId}
      countries={countries}
      required={props.required}
      handleSelect={props.handleSelect}
      handleCountry={props.handleCountry}
    />
  );
}
