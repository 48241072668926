/* @flow */

import React from 'react';

import CommercialDocumentsTableComponent from 'app/documents/commercial/iso';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function ISOCommercialPage() {
  return (
    <WebsitePageComponent id="page.iso.commercial.title" data-walkme="isocommercial-title-commercial" noPaper>
      <CommercialDocumentsTableComponent />
    </WebsitePageComponent>
  );
}
