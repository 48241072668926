/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import ButtonComponent from 'components/buttons/button';
import SecurityComponent from 'components/security/index';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';


type ChoosePaymentMethodComponentProps = {
  handleChoosePayment: () => void;
  paymentInfo: PaymentOgone;
}

export default function ChoosePaymentMethodComponent(props: ChoosePaymentMethodComponentProps) {
  return (
    <React.Fragment>
      <SecurityComponent pageName="POS.Payment.Card" elementName="btnSubmit">
        <PageSubTitleViewComponent id="component.choosepayment.title" />
        <p><IntlMessageComponent id="component.choosepayment.description" /></p>

        <Box>
          <RadioGroup row aria-label="position" name="position" defaultValue="top">
            <FormControlLabel
              value="VISA"
              control={<Radio color="primary" />}
              label={
                <img alt="card-VISA" src="./images/payments/VISA.png" />
              }
              onChange={() => props.handleChoosePayment("VISA")}
            />
            <FormControlLabel
              value="MASTERCARD"
              control={<Radio color="primary" />}
              label={
                <img alt="card-MasterCard" src="./images/payments/MasterCard.png" />
              }
              onChange={() => props.handleChoosePayment("MASTERCARD")}
            />
            <FormControlLabel
              value="MAESTRO"
              control={<Radio color="primary" />}
              label={
                <img alt="card-maestro" src="./images/payments/maestro.png" />
              }
              onChange={() => props.handleChoosePayment("MAESTRO")}
            />
            <FormControlLabel
              value="BCMC"
              control={<Radio color="primary" />}
              label={
                <img alt="card-bancontact" src="./images/payments/bancontact.png" />
              }
              onChange={() => props.handleChoosePayment("BCMC")}
            />
            <FormControlLabel
              value="iDEAL"
              control={<Radio color="primary" />}
              label={
                <img alt="card-ideal" src="./images/payments/ideal.png" />
              }
              onChange={() => props.handleChoosePayment("iDEAL")}
            />
          </RadioGroup>
        </Box>
        {props.paymentInfo &&
          <form name="form1" id="form1" action={props.paymentInfo.url} method={(props.paymentInfo.url.includes("localhost") ? "get" : "post")}>
            {props.paymentInfo && props.paymentInfo.formValues.map(fv => (
              <input type="hidden" name={fv.key} value={fv.value} key={`ogoneProp-${fv.key}`} />
            ))}
            <Box mt={3}>
              <ButtonComponent
                color="primary"
                variant="contained"
                type="submit"
                disabled={!props.paymentMethod}
              ><IntlMessageComponent id="component.payViaCard.submit" /></ButtonComponent>
            </Box>
          </form>
        }
      </SecurityComponent>
    </React.Fragment>
  );
}
