/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import LensIcon from '@material-ui/icons/Lens';

import IntlMessageComponent from 'components/formatters/intlMessage';

type DebtorGradeForBuyerComponentProps = {
  shortMessageWhenZero: boolean;
  value: number;  
}

export default function DebtorGradeForBuyerComponent(props: DebtorGradeForBuyerComponentProps) {
  const value = props.value || 0;

  if ((value === 0) && !props.shortMessageWhenZero) {
    return (<IntlMessageComponent id="component.grade.grade_zero" />);
  }

  if ((value === 0) && props.shortMessageWhenZero) {
    return (<span>?</span>);
  }

  const dotColor = value >= 4 ? "green.main" : "blue.main";

  return (
    <Box>
      <Box component="span" color={dotColor}><LensIcon style={{fontSize: 13}} /></Box>
      <Box component="span" ml={1}>{value}</Box>
    </Box>
  );
}
