/* @flow */

import {withStyles} from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';

const StyledTableCellNumbersMobileComponent = withStyles(_ => ({
  root: {
    padding: '8px 2px 8px 2px'
  },
  head: {
    lineHeight: '1.5'
  },
  paddingSpace: {
    padding: 0,
    width: 'auto'
  },
  paddingNone: {
    padding: '0 2px',
    width: 'auto'
  },
  paddingLarger: {
    padding: '0 2px',
    minWidth: '120px'
  },
  body: {
    fontSize: '0.8125rem',
    textAlign: 'left'
  },

}))(TableCell);

export default StyledTableCellNumbersMobileComponent;
