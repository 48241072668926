/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import AmountFormatterComponent from 'components/formatters/amount';
import CompanyAddressComponent from 'components/company/address';
import DateFormatterComponent from 'components/formatters/date';
import DebtorGradeForSellerComponent from 'components/debtorGrades/seller';
import IntlMessageComponent from 'components/formatters/intlMessage';

import InvoiceProposal from 'models/invoices/seller/invoiceProposal';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

type SellInvoiceTab5SummaryComponentProps = {
  invoice: InvoiceProposal;
}

export default function SellInvoiceTab5SummaryComponent(props: SellInvoiceTab5SummaryComponentProps) {
  return (
    <React.Fragment>
      <PageSubTitleViewComponent id="page.sellInvoice.tab5.summary.title" data-walkme="sell-t5-summaryTitle" />

      <Box mt={3}>
        <Grid container direction="row" justify="space-between" alignItems="flex-start">
          <Grid item xs={12} md={6}>
            <Box display="flex" px={4}>
              <Box flexGrow={1} mb={1}>
                <Typography variant="body1">
                  <Box component="span" color="grey.main" fontSize={13}>
                    <IntlMessageComponent id="page.sellInvoice.tab5.summary.debtor.name" data-walkme="sell-t5-debtorName" />
                  </Box>
                </Typography>
              </Box>
              <Box>
                <CompanyAddressComponent company={props.invoice.debtorCompany} />
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box display="flex" px={4}>
              <Box flexGrow={1} mb={1}>
                <Typography variant="body1">
                  <Box component="span" color="grey.main" fontSize={13}>
                    <IntlMessageComponent id="page.sellInvoice.tab5.summary.duedate" data-walkme="sell-t5-dueDate" />
                  </Box>
                </Typography>
              </Box>
              <Box>
                <DateFormatterComponent date={props.invoice.dueDate} format="DD MMMM YYYY" />
              </Box>
            </Box>

            <Box display="flex" px={4}>
              <Box flexGrow={1} mb={1}>
                <Typography variant="body1">
                  <Box component="span" color="grey.main" fontSize={13}>
                    <IntlMessageComponent id="page.sellInvoice.tab5.summary.debtamount" data-walkme="sell-t5-debtAmount" />
                  </Box>
                </Typography>
              </Box>
              <Box>
                <AmountFormatterComponent amount={props.invoice.debtAmount} />
              </Box>
            </Box>

            {!props.invoice.debtorCompany.isWaitingForGrade &&
              <React.Fragment>
                <Box display="flex" px={4}>
                  <Box flexGrow={1} mb={1}>
                    <Typography variant="body1">
                      <Box component="span" color="grey.main" fontSize={13}>
                        <IntlMessageComponent id="page.sellInvoice.tab5.summary.debtor.grade" data-walkme="sell-t5-debtorGrade" />
                      </Box>
                    </Typography>
                  </Box>
                  <Box>
                    <DebtorGradeForSellerComponent insurerRating={props.invoice.debtorCompany.insurerRating} />
                  </Box>
                </Box>

                {props.invoice.debtorCompany.displayWarningClaims &&
                  <Box mt={3} px={3} textAlign="center" color="orange.main" fontWeight="fontWeightBold">
                    <Typography variant="body1">
                      <IntlMessageComponent id="page.sellInvoice.tab5.summary.debtor.warning.claims" />
                    </Typography>
                  </Box>
                }

                {props.invoice.debtorCompany.displayWarningGrade &&
                  <Box mt={3} px={3} textAlign="center" color="orange.main" fontWeight="fontWeightBold">
                    <Typography variant="body1">
                      <IntlMessageComponent id="page.sellInvoice.tab5.summary.debtor.warning.grade" />
                    </Typography>
                  </Box>
                }
              </React.Fragment>
            }
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}