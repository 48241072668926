/* @flow */


import React from 'react';
import {useHistory} from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';

import AmountFormatterComponent from 'components/formatters/amount';
import BooleanFormatterComponent from 'components/formatters/boolean';
import DateFormatterComponent from 'components/formatters/date';
import IntlMessageComponent from 'components/formatters/intlMessage';
import InvoiceNameComponent from 'components/invoice/name';
import StyledTableCellTextComponent from 'components/table/cellText';
import StyledTableCellNumbersComponent from 'components/table/cellNumbers';
import StyledTableHeadComponent from 'components/table/head';
import StyledTableRowComponent from 'components/table/row';
import StyledTableRowSellerInvoiceComponent from 'components/table/rowSellerInvoice';

import Invoice from 'models/invoices/seller/invoice';

import {PROTECTED_SELLER_INVOICE_DETAILS} from 'constants/pageRoutes';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

const columnData = [
  {id: 'statusKey', align:'left', translationKey: 'component.seller.openInvoiceList.header.status', walkme: 'sdashboard-column-open-statut'},
  {id: 'closedForDebtor', align:'left', translationKey: 'component.seller.openInvoiceList.header.closed', walkme: 'sdashboard-column-open-closed'},
  {id: 'id', align:'left', translationKey: 'component.seller.openInvoiceList.header.invoiceNbr', walkme: 'sdashboard-column-open-refedebex'},
  {id: 'reference', align:'left', translationKey: 'component.seller.openInvoiceList.header.reference', walkme: 'sdashboard-column-open-reforiginale'},
  {id: 'debtorName', align:'left', translationKey: 'component.seller.openInvoiceList.header.debtor', walkme: 'sdashboard-column-open-client'},
  {id: 'debtAmount', translationKey: 'component.seller.openInvoiceList.header.amount', walkme: 'sdashboard-column-open-montantinitial'},
  {id: 'outstandingDebtAmountBalance', translationKey: 'component.seller.openInvoiceList.header.outstanding', walkme: 'sdashboard-column-open-balance'},
  {id: 'isForFinancing', align:'left', translationKey: 'component.seller.openInvoiceList.header.financed', walkme: 'sdashboard-column-open-finance'},
  {id: 'dueDate', align:'left', translationKey: 'component.seller.openInvoiceList.header.dueDate', walkme: 'sdashboard-column-open-echeance'}
];

type SellerOpenInvoiceListViewComponentProps = {
  invoices: Invoice[];
  order: string;
  orderBy: string;
  page: number;
  pageSize: number;
  handlePageChange: (page: number) => void;
  handlePageSizeChange: (pageSize: number) => void;
  handleSortOrderChange: (property: string) => void;
};

export default function SellerOpenInvoiceListViewComponent(props: SellerOpenInvoiceListViewComponentProps) {
  const history = useHistory();

  const onClickRow = (id: number) => history.push(`${PROTECTED_SELLER_INVOICE_DETAILS}/${id}`);
  const handlePageChange = (_, page) => props.handlePageChange(page);
  const handlePageSizeChange = event => props.handlePageSizeChange(event.target.value);
  
  return (
    <React.Fragment>
      <PageSubTitleViewComponent id="component.seller.openInvoiceList.title" />

      <Box mt={3}>
        <Box component="div" overflow="auto">
          <Table>
            <StyledTableHeadComponent>
              <StyledTableRowComponent>
                {columnData.map(column => (
                  <StyledTableCellTextComponent align={column?.align ?? 'right'} key={column.id} padding="none">
                    <TableSortLabel
                      active={props.orderBy === column.id}
                      data-walkme={column.walkme || ''}
                      direction={props.order}
                      onClick={() => props.handleSortOrderChange(column.id)}
                    ><IntlMessageComponent id={column.translationKey} /></TableSortLabel>
                  </StyledTableCellTextComponent>
                ))}
              </StyledTableRowComponent>
            </StyledTableHeadComponent>

            <TableBody bgcolor="white">
              {props.invoices.length === 0 &&
                <StyledTableRowComponent>
                  <StyledTableCellTextComponent colSpan={columnData.length}>
                    <Box textAlign="center">
                      <IntlMessageComponent id="seller.openInvoices.noData" />
                    </Box>
                  </StyledTableCellTextComponent>
                </StyledTableRowComponent>
              }
              
              {props.invoices.slice(props.page * props.pageSize, (props.page * props.pageSize) + props.pageSize).map(i => (
                <StyledTableRowSellerInvoiceComponent
                  key={`openInvList-${i.id}`}
                  hover
                  onClick={() => onClickRow(i.id)}
                >
                  <StyledTableCellTextComponent padding="none">
                    <IntlMessageComponent id={`invoice.status.${i.statusKey}`} />
                  </StyledTableCellTextComponent>

                  <StyledTableCellTextComponent padding="none">
                    {i.closedForDebtor && <DateFormatterComponent date={i.closedForDebtor} />}
                  </StyledTableCellTextComponent>

                  <StyledTableCellTextComponent padding="none">
                    <InvoiceNameComponent invoiceId={i.id} />
                  </StyledTableCellTextComponent>

                  <StyledTableCellTextComponent padding="none">
                    {i.reference}
                  </StyledTableCellTextComponent>

                  <StyledTableCellTextComponent padding="none">
                    <strong>{i.debtorName}</strong>
                  </StyledTableCellTextComponent>

                  <StyledTableCellNumbersComponent padding="none">
                    <AmountFormatterComponent amount={i.debtAmount} />
                  </StyledTableCellNumbersComponent>

                  <StyledTableCellNumbersComponent padding="none">
                    <strong><AmountFormatterComponent amount={i.outstandingDebtAmountBalance} /></strong>
                  </StyledTableCellNumbersComponent>

                  <StyledTableCellTextComponent padding="none">
                    <BooleanFormatterComponent value={i.isForFinancing} />
                  </StyledTableCellTextComponent>

                  <StyledTableCellTextComponent padding="none">
                    <DateFormatterComponent date={i.dueDate} />
                  </StyledTableCellTextComponent>
                </StyledTableRowSellerInvoiceComponent>
              ))}
            </TableBody>
          </Table>

          <Box display="flex" height={60}>
            <Box flexGrow={1}></Box>
            <Box justifyContent="flex-end">
              <TablePagination
                component="div"
                count={props.invoices.length}
                rowsPerPage={props.pageSize}
                page={props.page}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePageSizeChange}
                labelRowsPerPage={<IntlMessageComponent id="general.invoicePerPage" />}
                labelDisplayedRows={({from, to, count}) => `${from}-${to} / ${count}`}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}
