/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CloudDownload from '@material-ui/icons/CloudDownload';

import IntlMessageComponent from 'components/formatters/intlMessage';
import SecurityComponent from 'components/security';

type ISOCommissionActionsComponentProps = {
  clearFilters: () => void;
  onExportAsked: (format: string) => void;
}

export default function ISOCommissionActionsComponent(props: ISOCommissionActionsComponentProps) {
  return (
    <React.Fragment>
      <Button
        color="default"
        variant="contained"
        onClick={props.clearFilters}
        style={{textTransform: 'none'}}
      ><IntlMessageComponent id="general.filters.clear" /></Button>

      <SecurityComponent pageName="POS.Commissions" elementName="btnExport">
        <Box component="span" ml={2}>
          <Button
            color="primary"
            variant="contained"
            startIcon={<CloudDownload />}
            onClick={() => props.onExportAsked('xlsx')}            
            style={{textTransform: 'none'}}
          ><IntlMessageComponent id="component.isos.commissions.actions.export" /></Button>
        </Box>
      </SecurityComponent>
    </React.Fragment>
  );
}
