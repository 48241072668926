/* @flow */


import React from 'react';

import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import ButtonComponent from 'components/buttons/button';
import FormErrorsComponent from 'components/formErrors';
import IntlMessageComponent from 'components/formatters/intlMessage';
import MandatoryFormFieldsComponent from 'components/form/mandatory';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import LeadNoteForm from './model';

type RegisterLeadTab5ViewComponentProps = {
  errors: Map<string, ?string>;
  form: LeadNoteForm;
  formErrors: string[];
  isProcessing: boolean;
  handleChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void;
  skip: () => void;
  submitForm: () => void;
}

export default function RegisterLeadTab5ViewComponent(props: RegisterLeadTab5ViewComponentProps) {

  return (
    <React.Fragment>
      <FormErrorsComponent formErrors={props.formErrors} />

      <Typography variant="body1" gutterBottom>
        <IntlMessageComponent id="page.iso.registerLead.tab5.notmandatory" data-walkme="createlead5-label-notmandatory" />
      </Typography>

      <Box mt={5}>
        <PageSubTitleViewComponent id="page.iso.registerLead.tab5.title" data-walkme="createlead5-title-note" />
      </Box>

      <Box mt={3}>
        <TextField
          id="form.lead.note"
          label={<IntlMessageComponent id="form.lead.note.label" />}
          error={!!props.errors.get('note')}
          helperText={props.errors.get('note') ?
            <IntlMessageComponent id={props.errors.get('note')} /> : ''}
          value={props.form.note}
          onChange={props.handleChange('note')}
          fullWidth
          margin="normal"
          multiline
          rows={5}
        />
      </Box>

      <Box mt={5}>
        <ButtonComponent
          color="default"
          variant="contained"
          disabled={props.isProcessing}
          onClick={props.skip}
        ><IntlMessageComponent id="general.skip" /></ButtonComponent>

        <Box component="span" ml={1}>
          <ButtonComponent
            color="primary"
            variant="contained"
            isProcessing={props.isProcessing}
            onClick={props.submitForm}
          ><IntlMessageComponent id="general.continue" /></ButtonComponent>
        </Box>

      </Box>
    </React.Fragment>
  );
}
