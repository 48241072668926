/* @flow */

import React from 'react';
import {useSelector} from 'react-redux';
import {Route, Switch, useRouteMatch} from 'react-router-dom';

import Error404Page from 'components/errors/error404';
import RestrictedRouteComponent from 'components/route/restrictedRoute';

import CompanyUsersEditPage from './edit';
import CompanyUsersListPage from './list';
import CompanyUsersNewPage from './new';
import CompanyUserProcurationPage from './procuration';

import {PROTECTED_COMPANY_USERS} from 'constants/pageRoutes';

export default function CompanyUsersPage() {
  const canManageUsers = useSelector(state => state.auth.user.activeCompany.canManageUsers);
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.url}/`} component={CompanyUsersListPage} />
      <RestrictedRouteComponent path={`${match.url}/new`} component={CompanyUsersNewPage} condition={canManageUsers} redirectUrl={PROTECTED_COMPANY_USERS} />
      <RestrictedRouteComponent path={`${match.url}/:id/edit`} component={CompanyUsersEditPage} condition={canManageUsers} redirectUrl={PROTECTED_COMPANY_USERS} />
      <Route path={`${match.url}/:id/procuration`} component={CompanyUserProcurationPage} />
      <Route component={Error404Page} />
    </Switch>
  );
}
