/* @flow */


import React from 'react';
import {connect} from 'react-redux';

import InvoiceDocumentsViewComponent from './view';

import ProvidedDocument from 'models/documents/providedDocument';

import DocumentService from 'services/DocumentService';


import NotificationHelper from 'lib/notifications';

import {INVOICE_SELLER_DOCUMENT_TOC_POST} from 'constants/apiRoutes';

type InvoiceDocumentsComponentProps = {
  activeCompanyId: number;
  activeCompanyRole: number;
  allowAdd: boolean;
  invoiceId: number;
}

type InvoiceDocumentsComponentState = {
  documents: ProvidedDocument[];
  isLoading: boolean;
  isProcessing: boolean;
}

class InvoiceDocumentsComponent extends React.Component<InvoiceDocumentsComponentProps, InvoiceDocumentsComponentState> {

  constructor(props) {
    super(props);
    this.state = {
      documents: [],
      isLoading: true,
      isProcessing: false
    };
  }

  componentDidMount() {
    this.loadDocuments();
  }

  loadDocuments = async () => {
    try {
      const documents = await DocumentService.getInvoiceDocuments(this.props.activeCompanyId, this.props.activeCompanyRole, this.props.invoiceId);
      this.setState({documents, isLoading: false});
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  }

  downloadDocument = async (documentId: number) => {
    try {
      await DocumentService.getInvoiceDocument(this.props.activeCompanyId, this.props.activeCompanyRole, this.props.invoiceId, documentId);
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  }

  uploadFile = async (file: File) => {
    this.setState({isProcessing: true});

    try {
      await DocumentService.sendDocument2(INVOICE_SELLER_DOCUMENT_TOC_POST, file, {invoiceId: this.props.invoiceId});
      this.setState({documents: [], isLoading: true, isProcessing: false});
      NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS, 'notifications.file.upload.success');
      await this.loadDocuments();
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  }

  failHandler = () => {
    NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR, 'notifications.file.upload.wrongformat');
  }

  render() {
    return (
      <InvoiceDocumentsViewComponent
        allowAdd={this.props.allowAdd}
        documents={this.state.documents}
        isProcessing={this.state.isProcessing}
        downloadDocument={this.downloadDocument}
        uploadDocument={this.uploadFile}
        failHandler={this.failHandler}
      />
    );
  }
}

const mapStateToProps = ({auth}) => ({
  activeCompanyId: auth.user.activeCompany.id,
  activeCompanyRole: auth.user.activeCompany.role
});

export default connect(mapStateToProps)(InvoiceDocumentsComponent);
