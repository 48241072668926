/* @flow */

export default class RegisterSupplierRequest {
  companyId: number;
  insurerId: string;

  lastName: string;
  firstName: string;
  titleId: number;
  email: string;
  phone: string;
  languageId: number;
}