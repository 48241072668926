/* @flow */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function IconPaymentMissing(props) {
  return (
    <SvgIcon viewBox="0 0 69.29 78.06" {...props}>
      <g>
        <g>
          <path d="M431.56,307.71v8.75c0,1.13-.42,1.7-1.23,1.71s-1.26-.56-1.26-1.67q0-8.81,0-17.61c0-1.1.45-1.73,1.22-1.74s1.27.66,1.27,1.8Z" transform="translate(-386.27 -258.83)"/>
          <path d="M430.3,318.92a1.87,1.87,0,0,1-1.33-.51,2.6,2.6,0,0,1-.65-1.91q0-8.81,0-17.62c0-1.81,1.05-2.47,1.95-2.48a2,2,0,0,1,1.33.51,2.81,2.81,0,0,1,.71,2v17.51c0,2.19-1.38,2.45-2,2.46Zm0-21h0c-.4,0-.48.54-.48,1q0,8.81,0,17.61c0,.59.15.79.2.84a.52.52,0,0,0,.3.08c.19,0,.49,0,.49-1V299a1.46,1.46,0,0,0-.26-1A.32.32,0,0,0,430.31,297.9Z" transform="translate(-386.27 -258.83)"/>
        </g>
        <g>
          <path d="M429.07,324.13c.1-.63.32-1.25,1.23-1.26s1.21.59,1.24,1.34a1.24,1.24,0,0,1-1.17,1.34C429.71,325.64,429.08,325,429.07,324.13Z" transform="translate(-386.27 -258.83)"/>
          <path d="M430.25,326.31a1.77,1.77,0,0,1-1.15-.45,2.34,2.34,0,0,1-.78-1.72V324c.2-1.25.86-1.88,2-1.89h0a1.93,1.93,0,0,1,2,2.07,2,2,0,0,1-1.82,2.1Zm-.43-2.13a.79.79,0,0,0,.26.54.28.28,0,0,0,.19.09c.47-.07.53-.32.52-.57,0-.62-.23-.6-.49-.62S429.92,323.62,429.82,324.18Z" transform="translate(-386.27 -258.83)"/>
        </g>
      </g>
      <path d="M432.53,286.57V273.73a6,6,0,0,0-2.14-3.44q-4.89-4.92-9.73-9.89a4.89,4.89,0,0,0-3.71-1.56c-8.79,0-17.58.06-26.38,0a4,4,0,0,0-4.3,4.25q.11,25.29,0,50.57a3.83,3.83,0,0,0,3.92,4l15.66,0a25.21,25.21,0,1,0,26.68-31Zm-12.38-20.44,5.64,5.64h-5.64Zm-29.87,47.48V262.87h25.85v8.64a4,4,0,0,0,4.28,4.26c2.67-.08,5.34,0,8.08,0v10.8a25.24,25.24,0,0,0-23.35,25.13c0,.65,0,1.29.08,1.93Zm40.07,19.28a21.21,21.21,0,1,1,21.21-21.21A21.23,21.23,0,0,1,430.35,332.89Z" transform="translate(-386.27 -258.83)"/>
    </SvgIcon>
  );
}
