/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import AmountFormatterComponent from 'components/formatters/amount';
import CompanyAddressComponent from 'components/company/address';
import DateFormatterComponent from 'components/formatters/date';
import IntlMessageComponent from 'components/formatters/intlMessage';

import InvoiceSummary from 'models/invoices/seller/invoiceSummary';

type SubmitInvoiceThankYouSummaryComponentProps = {
  invoice: InvoiceSummary;
}

export default function SubmitInvoiceThankYouSummaryComponent(props: SubmitInvoiceThankYouSummaryComponentProps) {

  return (
    <Box mt={3} py={3} bgcolor="lightblue.main">
      <Grid container direction="row" justify="space-between" alignItems="flex-start">
        <Grid item xs={12} md={6}>
          <Box px={3}>
            <Box display="flex">
              <Box flexGrow={1} mb={1}>
                <IntlMessageComponent id="page.submitinvoice.thankyou.summary.supplier.name" data-walkme="sell-t6-supplierName" />
              </Box>
              <Box>
                <CompanyAddressComponent company={props.invoice.supplierCompany} />
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box px={3}>
            <Box display="flex">
              <Box flexGrow={1} mb={1}>
                <IntlMessageComponent id="page.sellInvoice.tab6.summary.duedate" data-walkme="sell-t6-dueDate" />
              </Box>
              <Box>
                <DateFormatterComponent date={props.invoice.dueDate} format="DD MMMM YYYY" />
              </Box>
            </Box>
            <Box display="flex">
              <Box flexGrow={1} mb={1}>
                <IntlMessageComponent id="page.submitinvoice.thankyou.summary.confirmingduedate" data-walkme="sell-t6-dueDate" />
              </Box>
              <Box>
                <DateFormatterComponent date={props.invoice.confirmingDueDate} format="DD MMMM YYYY" />
              </Box>
            </Box>

            <Box display="flex">
              <Box flexGrow={1} mb={1}>
                <IntlMessageComponent id="page.sellInvoice.tab6.summary.debtamount" data-walkme="sell-t6-debtamount" />
              </Box>
              <Box>
                <AmountFormatterComponent amount={props.invoice.debtAmount} />
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
