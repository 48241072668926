import React from 'react';
import InputFiles from 'react-input-files';

type UploadComponentProps = {
  accept: string;
  disabled: boolean;
  successHandler: (file: File) => void;
  failHandler: () => void;
}

export default function UploadComponent(props: UploadComponentProps) {
  const onChange = (files, e) => {
    if (files.length === 0) return;

    let firstFile = files[0];
    // check file
    if (!firstFile.type || firstFile.type === '') {
      return props.failHandler();
    }

    // check accept type
    if (props.accept && props.accept !== '') {
      let acceptFileTypes = [];
      if(props.accept.includes(",")) {
        const split = props.accept.split(",");
        acceptFileTypes = split.map(s => s.trim());
      } else {
        acceptFileTypes.push(props.accept);
      }

      const result = acceptFileTypes.find(t => t === files[0].type);
      if (result === undefined) {
        return props.failHandler();
      }
    }

    // check file size
    if (firstFile.size > 5242880) {
      return props.failHandler();
    }

    // file should be ok
    e.target.value = null; // setting event target value to null so that it will always trigger onChange even if same file is selected again
    props.successHandler(firstFile);
  };

  if (props.disabled) {
    return <React.Fragment>{props.children}</React.Fragment>;
  }

  return (
    <InputFiles
      accept={props.accept || ''}
      onChange={onChange}
      style={{display: 'inline', width: '100%'}}
    >
      {props.children}
    </InputFiles>
  );
}
