/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import AmountFormatterComponent from 'components/formatters/amount';
import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import SecurityComponent from 'components/security/index';

type BestPricingIntroductionComponentProps = {
  minimumAverageInvoiceAmount: number;
  minimumYearlyAmount: number;
  successHandler: () => void;
}

export default function BestPricingIntroductionComponent(props: BestPricingIntroductionComponentProps) {

  const placeholderValues = {
    minimumAverageInvoiceAmount: <AmountFormatterComponent amount={props.minimumAverageInvoiceAmount} />,
    minimumYearlyAmount: <AmountFormatterComponent amount={props.minimumYearlyAmount} />
  };

  return (
    <React.Fragment>
      <Paper elevation={0}>
        <Box p={5}>
          <PageSubTitleViewComponent id="page.bestPricing.introduction.title" />

          <Box mt={3}>
            <Typography variant="body1">
              <IntlMessageComponent id="page.bestPricing.introduction.text" />
            </Typography>
            <ul>
              <li><IntlMessageComponent id="page.bestPricing.introduction.list1" values={placeholderValues} /></li>
              <li><IntlMessageComponent id="page.bestPricing.introduction.list2" values={placeholderValues} /></li>
            </ul>
          </Box>

          <SecurityComponent pageName="POS.Sellers.BestPricing" elementName="btnContinue">
            <Box mt={5}>
              <ButtonComponent
                color="secondary"
                variant="contained"
                onClick={props.successHandler}
              ><IntlMessageComponent id="page.bestPricing.button.continue" /></ButtonComponent>
            </Box>
          </SecurityComponent>
        </Box>
      </Paper>
    </React.Fragment>
  );
}
