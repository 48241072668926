/* @flow */

import React from 'react';
import {useSelector} from 'react-redux';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import BuyerInvestmentProfilesComponent from 'app/buyers/investmentProfiles';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function BuyerInvestmentProfilesEditPage() {
  const activeCompanyInvestmentProfileId = useSelector(state => state.auth.user.activeCompany.investmentProfile.id);

  return (
    <WebsitePageComponent id="buyer.ip.title" noPaper>
      {activeCompanyInvestmentProfileId === 0 ? (
        <Paper elevation={0}>
          <Box p={5}>
            <PageSubTitleViewComponent id="buyer.ip.notselected.title" />
            <IntlMessageComponent id="buyer.ip.notselected.description" />
          </Box>
        </Paper>
      ) : (
        <BuyerInvestmentProfilesComponent />
      )}
    </WebsitePageComponent>
  );
}
