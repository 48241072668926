/* @flow */

export default class SetAlternativeFinancingRequest {
  companyId: number;

  alternativeFinancingCompanyName: string;
  alternativeFinancingType: string;
  alternativeFinancingCost: number;
  alternativeFinancingAmount: number;
}
