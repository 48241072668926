/* @flow */

import React from 'react';
import {useSelector} from 'react-redux';

import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

export default function ImpersonateHintsViewComponent() {
  const impersonationHints = useSelector(state => state.auth.user.activeCompany.impersonationHints);

  return (
    <Paper elevation={0}>
      <Box px={5} py={3}>
        <Alert variant="outlined" severity="warning">
          <AlertTitle><Box fontWeight="fontWeightBold">Impersonate Info:</Box></AlertTitle>
          <ul>
            {impersonationHints.map((hint, index) => (<li key={`imp-hint-${index}`}>{hint}</li>))}
          </ul>
        </Alert>
      </Box>
    </Paper>
  );
}
