/* @flow */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function IconMoneyTransfer(props) {
  return (
    <SvgIcon viewBox="0 0 97.07 49.4" {...props}>
      <g>
        <path d="M405.69,316.62h-6.35c-2.24,0-2.63-.4-2.64-2.7,0-3.18,0-6.35,0-9.52V276.33c0-2.68.37-3.05,3-3.05h43c2.38,0,2.73.35,2.76,2.73q0,2,.06,4.08c0,.1.09.2.23.48h8.87c8.06,0,13.37,4.44,14.07,12.53.53,6.13.29,12.33.37,18.5.07,5.44.31,5.09-5.07,5-1.47,0-2.25.27-2.82,1.81a6.53,6.53,0,0,1-6.52,4.26,6.73,6.73,0,0,1-6.29-4.62,1.8,1.8,0,0,0-2-1.45c-8.39.06-16.78,0-25.17,0-1,0-1.55.18-1.91,1.33a6.91,6.91,0,0,1-6.57,4.73,7.11,7.11,0,0,1-6.56-4.77Zm36.2-3.39V276.78H400.14v36.38H406c.06-.1.18-.28.28-.48a7.13,7.13,0,0,1,12.81-.25,2.12,2.12,0,0,0,1.58.77c3.91.06,7.82,0,11.74,0Zm23.8,0v-1.82c0-5.1,0-10.2,0-15.3a18.6,18.6,0,0,0-.37-3.87c-.84-3.74-2.65-7.06-6.66-7.73-4.23-.71-8.6-.58-12.93-.82v29.94c1.14-.47,2.43-.59,2.86-1.25,3.34-5.18,9.27-5.24,12.53,0a1.83,1.83,0,0,0,1.06.84C463.28,313.3,464.4,313.23,465.69,313.23Zm-10.93-1.36a3.81,3.81,0,0,0-3.52,3.64,3.72,3.72,0,0,0,3.59,3.74,3.78,3.78,0,0,0,3.71-3.64A3.74,3.74,0,0,0,454.76,311.87Zm-38.31,3.64a3.62,3.62,0,1,0-3.6,3.74A3.84,3.84,0,0,0,416.45,315.51Z" transform="translate(-372.04 -273.28)"/>
        <path d="M381.82,286.68c-2.6,0-5.2,0-7.8,0-1.12,0-1.95-.37-2-1.57s.75-1.64,1.9-1.63q7.89,0,15.79,0c1.17,0,2.11.42,2.07,1.67s-1,1.56-2.17,1.54C387,286.64,384.43,286.68,381.82,286.68Z" transform="translate(-372.04 -273.28)"/>
        <path d="M383.15,293.37c2.21,0,4.42,0,6.63,0,1.11,0,2,.41,2,1.55a1.68,1.68,0,0,1-1.88,1.75c-4.54.05-9.07.1-13.6,0a2.48,2.48,0,0,1-1.69-1.68c-.15-1.05.69-1.63,1.88-1.59C378.72,293.41,380.93,293.37,383.15,293.37Z" transform="translate(-372.04 -273.28)"/>
        <path d="M384.21,306.4c-1.81,0-3.62,0-5.42,0-1.07,0-1.79-.39-1.87-1.48a1.61,1.61,0,0,1,1.73-1.86c3.73-.06,7.45-.05,11.18,0,1.09,0,2,.65,1.74,1.76a2.54,2.54,0,0,1-1.78,1.51A51.88,51.88,0,0,1,384.21,306.4Z" transform="translate(-372.04 -273.28)"/>
      </g>
      <path d="M415.58,290.41c1.5-.32,2.71-.62,3.94-.81,1-.15,2.06-.31,2.28,1s-.67,1.63-1.71,1.85c-1.62.35-3.24.76-4.85,1.14l-.08.61a4.13,4.13,0,0,0,1.42.31,26.67,26.67,0,0,0,3.67-.81c1-.3,1.83,0,1.88,1a2.39,2.39,0,0,1-1.14,1.81,14.74,14.74,0,0,1-3.3.8c1.65,2.18,5.41,2.13,7.5-.11a6.86,6.86,0,0,0,1-1.46c.5-.95,1.13-1.69,2.25-1.11s.81,1.66.31,2.61a8.64,8.64,0,0,1-12.51,3.16,6.18,6.18,0,0,1-1.41-1.11,1.88,1.88,0,0,0-2.45-.75c-.88.32-2.08.35-2.39-.88s.66-1.76,1.75-2a5.21,5.21,0,0,0,.83-.35c-.44-.37-.76-.87-1.06-.86-1,0-2-.14-1.89-1.27.07-.65,1.05-1.18,1.6-1.79a5.13,5.13,0,0,0,1.22-1.48c2-5.61,8.26-7.9,13.19-4.79.15.1.32.17.46.28.76.62,1.63,1.3.94,2.38s-1.71.7-2.66.11C420.91,285.77,417.83,286.54,415.58,290.41Z" transform="translate(-372.04 -273.28)"/>
    </SvgIcon>
  );
}