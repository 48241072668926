/* @flow */

import React from 'react';

import CompanyUsersTableComponent from 'app/companies/users/table';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function CompanyUsersListPage() {
  return (
    <WebsitePageComponent id="page.profile.users.title" noPaper>
      <CompanyUsersTableComponent />
    </WebsitePageComponent>
  );
}
