/* @flow */
import React from 'react';

import moment from 'moment';

import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import ButtonComponent from 'components/buttons/button';
import DatePickerComponent from 'components/formFields/dates/datePicker';
import IntlMessageComponent from 'components/formatters/intlMessage';

type CustomPeriodDialogComponentProps = {
  dateFrom: moment;
  dateTo: moment;
  enableRemoveFilter: boolean;
  isOpen: boolean;
  handleClose : () => void;
  handleChange : (dateFrom: moment, dateTo: moment) => void;
}

type CustomPeriodDialogComponentState = {
  dateFrom: moment;
  dateTo: moment;
}

class CustomPeriodDialogComponent extends React.Component<CustomPeriodDialogComponentProps, CustomPeriodDialogComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      dateFrom: this.props.dateFrom,
      dateTo: this.props.dateTo
    };
  }

  handleChange = (name: string) => (value: moment) => {
    const state = this.state;
    state[name] = value;
    this.setState(state);
  };

  submit = () => {
    this.props.handleChange(this.state.dateFrom, this.state.dateTo);
  };

  handleClose = () => {
    if (this.props.dateFrom === null || this.props.dateTo === null) {
      this.setState({dateFrom: null, dateTo: null});
    }
    this.props.handleClose();
  }

  handleRemoveFilter = () => {
    this.setState({dateFrom: null, dateTo: null});
    this.props.handleChange(null, null);
  }

  render() {
    return (
      <Dialog open={this.props.isOpen} onClose={this.props.handleClose}>
        <DialogTitle>
          <IntlMessageComponent id="component.periodpicker.title" />
        </DialogTitle>

        <DialogContent>
          <DatePickerComponent
            id="form.dateFrom"
            label={<IntlMessageComponent id="component.periodpicker.dateFrom" />}
            fullWidth
            margin="none"
            value={this.state.dateFrom}
            onChange={this.handleChange('dateFrom')}
          />

          <Box mt={3}>
            <DatePickerComponent
              id="form.dateTo"
              label={<IntlMessageComponent id="component.periodpicker.dateTo" />}
              fullWidth
              margin="none"
              value={this.state.dateTo}
              onChange={this.handleChange('dateTo')}
            />
          </Box>
        </DialogContent>

        <DialogActions>
          {this.props.enableRemoveFilter && this.props.dateFrom !== null && this.props.dateTo !== null &&
            <ButtonComponent
              color="default"
              onClick={this.handleRemoveFilter}
            ><IntlMessageComponent id="component.periodpicker.removeFilter" /></ButtonComponent>
          }
          <ButtonComponent
            color="default"
            onClick={this.handleClose}
          ><IntlMessageComponent id="general.cancel" /></ButtonComponent>

          <ButtonComponent
            color="primary"
            disabled={this.state.dateFrom === null || this.state.dateTo === null}
            onClick={this.submit}
          ><IntlMessageComponent id="general.submit" /></ButtonComponent>
        </DialogActions>
      </Dialog>
    );
  }
}

export default CustomPeriodDialogComponent;
