/* @flow */

import moment from 'moment';

export default class SetPersonInfoRequest {
  companyId: number;

  titleId: number;

  lastName: string;
  firstName: string;

  mobile: string;
  phone: string;

  languageId: number;

  addressLine1: string;
  addressLine2: string;
  zipCode: string;
  city: string;
  countryId: number;

  nationalityId: number;
  birthCountryId: number;
  birthDate: moment;
  birthPlace: string;

  registrationTypeId: number;
  registrationNumber: string;
  registrationExpireDate: moment;
}
