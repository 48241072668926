/* @flow */

import React from 'react';
import {useSelector} from 'react-redux';
import {Route, Switch, useRouteMatch} from 'react-router-dom';

import Error404Page from 'components/errors/error404';
import RestrictedRoute from 'components/route/restrictedRoute';

import ConfirmingSubmitPage from './submit';
import ConfirmingDashboardPage from './dashboard';
import ConfirmingBillsPage from './bills';
import ConfirmingSuppliersPage from './suppliers';
import ConfirmingAddSupplierPage from './addsupplier';
import ConfirmingInvoicePortfolioPage from './invoicePortfolio';
import ConfirmingInvoiceDetailsPage from './invoiceDetails';

import {PROTECTED_CONFIRMING_DASHBOARD} from 'constants/pageRoutes';

export default function ConfirmingPage() {
  const canExecute = useSelector(state => state.auth.user.activeCompany.roleConfirming.canExecute);
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.url}/`} component={ConfirmingDashboardPage} />
      <Route path={`${match.url}/suppliers`} component={ConfirmingSuppliersPage} />
      <Route path={`${match.url}/addsupplier`} component={ConfirmingAddSupplierPage} condition={canExecute} redirectUrl={PROTECTED_CONFIRMING_DASHBOARD}/>
      <Route path={`${match.url}/bills`} component={ConfirmingBillsPage} />
      <Route path={`${match.url}/invoice/:id`} component={ConfirmingInvoiceDetailsPage} />
      <RestrictedRoute path={`${match.url}/submit/:id?`} component={ConfirmingSubmitPage} condition={canExecute} redirectUrl={PROTECTED_CONFIRMING_DASHBOARD} />
      <Route path={`${match.url}/portfolio`} component={ConfirmingInvoicePortfolioPage} />
      <Route component={Error404Page} />
    </Switch>
  );
}
