/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';

import ButtonComponent from 'components/buttons/button';
import FormErrorsComponent from 'components/formErrors';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import RegisterReferralNoteForm from './model';

type ReferralTab2ViewComponentProps = {
  errors: Map<string, ?string>;
  form: RegisterReferralNoteForm;
  formErrors: string[];
  isProcessing: boolean;
  handleChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void;
  submitForm: () => void;
  successHandler: () => void;
}

export default function ReferralTab2ViewComponent(props: ReferralTab2ViewComponentProps) {

  return (
    <React.Fragment>
      <FormErrorsComponent formErrors={props.formErrors} />

      <PageSubTitleViewComponent id="seller.referral.tab2.title" />
            
      <Box mt={3}>
        <TextField
          id="form.referral.note"
          label={<IntlMessageComponent id="form.referral.note.label" />}
          error={!!props.errors.get('note')}
          helperText={props.errors.get('note') ?
            <IntlMessageComponent id={props.errors.get('note')} /> : ''}
          value={props.form.note}
          onChange={props.handleChange('note')}
          fullWidth
          margin="normal"
          multiline
          rows={5}
        />
      </Box>

      <Box mt={5}>
        <ButtonComponent
          color="default"
          variant="contained"
          disabled={props.isProcessing}
          onClick={props.successHandler}
        ><IntlMessageComponent id="general.skip" /></ButtonComponent>

        <Box component="span" ml={1}>
          <ButtonComponent
            color="primary"
            variant="contained"
            isProcessing={props.isProcessing}
            onClick={props.submitForm}
          ><IntlMessageComponent id="general.continue" /></ButtonComponent>
        </Box>
      </Box>
    </React.Fragment>
  );
}