/* @flow */

import React from 'react';
import moment from 'moment';

import {connect} from 'react-redux';
import {setComponentData} from 'store/actions/componentDataActions';

import customSort from 'lib/sortHelpers';

import DebtorInvoiceTableControlsViewComponent from 'app/debtors/invoiceTableControls';
import LoadingComponent from 'components/loading';

import DebtorInvoicesPortfolioTableViewComponent from './view';

import DownloadPortfolioRequest from 'models/requests/debtors/downloadPortfolioRequest';
import Invoice from 'models/debtors/invoice';
import InvoiceActiveFilters from 'models/debtors/invoiceActiveFilters';
import InvoiceFilters from 'models/debtors/invoiceFilters';

import DebtorService from 'services/DebtorService';

type DebtorInvoicesPortfolioTableComponentProps = {
  activeCompanyId: number;
}

type DebtorInvoicesPortfolioTableComponentState = {
  filters: InvoiceFilters;
  invoices: Invoice[];
  isLoading: boolean;
  order: string;
  orderBy: ?string;
  page: number;
  pageSize: number;
  activeFilters: InvoiceActiveFilters;
}

class DebtorInvoicesPortfolioTableComponent extends React.Component<DebtorInvoicesPortfolioTableComponentProps, DebtorInvoicesPortfolioTableComponentState> {

  constructor(props) {
    super(props);
    this.state = {
      filters: null,
      activeFilters: null,
      invoices: [],
      isLoading: true,
      order: 'asc',
      orderBy: undefined,
      page: 0,
      pageSize: 10
    };
  }

  componentDidMount = async () => {
    try {
      const {invoices, filters} = await DebtorService.getInvoicesPortfolio(this.props.activeCompanyId);
      const activeFilters = new InvoiceActiveFilters();
      this.setState({filters, invoices, activeFilters, isLoading: false});
    } catch (e) {
      console.error(e);
    }
  }

  handleExport = async (format: string) => {
    try {
      const request = new DownloadPortfolioRequest(this.props.activeCompanyId, format, this.state.activeFilters);
      await DebtorService.downloadPortfolio(request);
    } catch (e) {
      console.error(e);
    }
  };

  handleFiltersChange = (activeFilters: InvoiceActiveFilters) => {
    this.setState(activeFilters);
  }
  
  handlePageChange = (page: number) => this.setState({page});
  handlePageSizeChange = (pageSize: number) => this.setState({pageSize});

  handleSortOrderChange = (orderBy: string) => {
    let order = this.state.orderBy === orderBy && this.state.order === 'desc' ? 'asc' : 'desc';

    let invoices = null;
    if (orderBy.startsWith('sellerCompany.')) {
      // get exact property name
      const o = orderBy.substring(14);

      // order
      invoices = order === 'desc'
        ? this.state.invoices.sort((a, b) => customSort(b.sellerCompany[o], a.sellerCompany[o]))
        : this.state.invoices.sort((a, b) => customSort(a.sellerCompany[o], b.sellerCompany[o]));
    }
    else {
      // order
      invoices = order === 'desc'
        ? this.state.invoices.sort((a, b) => customSort(b[orderBy], a[orderBy]))
        : this.state.invoices.sort((a, b) => customSort(a[orderBy], b[orderBy]));
    }

    this.setState({ invoices, order, orderBy });
  };

  getFilteredResults = () => {
    let filteredInvoices = this.state.invoices;

    // filter on query search
    if (this.state.activeFilters.query && this.state.activeFilters.query.length > 2) {
      filteredInvoices = filteredInvoices.filter((i: Invoice) => {
        const query = this.state.activeFilters.query.toUpperCase();
        const fields = [i.id, i.invoiceName, i.reference, i.debtorReference].map(v => (v || '').toString().toUpperCase());
        return !!(fields.filter(field => field.toString().indexOf(query) !== -1)).length;
      });
    }

    // Filter on seller company
    if (this.state.activeFilters.sellerCompanyId > 0) {
      filteredInvoices = filteredInvoices.filter(i => i.sellerCompany.id === this.state.activeFilters.sellerCompanyId);
    } 

    // Filter on invoice status
    if (this.state.activeFilters.statusKey !== '') {
      filteredInvoices = filteredInvoices.filter(i => i.statusKey.toUpperCase() === this.state.activeFilters.statusKey.toUpperCase());
    } 

    if (this.state.activeFilters.dateFrom !== null && this.state.activeFilters.dateTo !== null) {
      filteredInvoices = filteredInvoices.filter(i => moment(i.dueDate).isBetween(this.state.activeFilters.dateFrom, this.state.activeFilters.dateTo, 'day', '[]') || 
                                                      moment(i.issueDate).isBetween(this.state.activeFilters.dateFrom, this.state.activeFilters.dateTo, 'day', '[]'));
    }

    // filter on amount
    if (this.state.activeFilters.invoiceAmounts && this.state.activeFilters.invoiceAmounts.length > 0) {
      const min = this.state.activeFilters.invoiceAmounts[0];
      const max = this.state.activeFilters.invoiceAmounts[1];
      if (min !== "" && max === "") filteredInvoices = filteredInvoices.filter(i => i.debtAmount >= min);
      if (min === "" && max !== "") filteredInvoices = filteredInvoices.filter(i => i.debtAmount <= max);
      if (min !== "" && max !== "") filteredInvoices = filteredInvoices.filter(i => i.debtAmount >= min && i.debtAmount <= max);
    }

    return filteredInvoices;
  };

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <React.Fragment>
        <DebtorInvoiceTableControlsViewComponent
          activeFilters={this.state.activeFilters}
          filters={this.state.filters}
          handleExport={this.handleExport}
          handleFiltersChange={this.handleFiltersChange}
          filterOnAmount
          filterOnInvoiceStatus
          filterOnPeriod
          filterOnSellerCompany
        />

        <DebtorInvoicesPortfolioTableViewComponent
          invoices={this.getFilteredResults()}
          order={this.state.order}
          orderBy={this.state.orderBy}
          page={this.state.page}
          pageSize={this.state.pageSize}
          handlePageChange={this.handlePageChange}
          handlePageSizeChange={this.handlePageSizeChange}
          handleSortOrderChange={this.handleSortOrderChange}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id,
  activeFilters: state.components.debtorInvoicesPortfolioTable.activeFilters
});

export default connect(mapStateToProps, {setComponentData})(DebtorInvoicesPortfolioTableComponent);
