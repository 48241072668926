/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import CompanyDocumentsUploadViewComponent from './view';

import DocumentService from 'services/DocumentService';
import NotificationHelper from 'lib/notifications';

import DocumentType from 'models/documents/documentType';
import CompanyService from 'services/CompanyService';

type CompanyDocumentsUploadComponentProps = {
  activeCompanyId: number;
  successHandler: () => void;
}

type CompanyDocumentsUploadComponentState = {
  isProcessing: boolean;
  documentTypes: DocumentType[];
  selectedDocumentType: DocumentType;
}

class CompanyDocumentsUploadComponent extends React.Component<CompanyDocumentsUploadComponentProps, CompanyDocumentsUploadComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      isProcessing: false,
      documentTypes: [],
      selectedDocumentType: null
    };
  }

  componentDidMount = async () => {
    try {
      const response = await CompanyService.getCompanyDocumentTypes(this.props.activeCompanyId);

      this.setState({
        documentTypes: response,
      });
    } catch (e) {
      console.error(e);
    }
  }

  handleFail = () => {
    NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR, 'notifications.file.upload.wrongformat');
  }

  uploadFile = async (file: File) => {
    this.setState({isProcessing: true});

    try {
      await DocumentService.sendCompanyMyDocument(this.props.activeCompanyId, this.state.selectedDocumentType.typeId, file);      
      this.setState({isProcessing: false, selectedDocumentType: null});
      NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS, 'notifications.file.upload.success');
      this.props.successHandler();
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  }

  handleDocumentTypeChange = (event) => {
    this.setState({ selectedDocumentType: event.target.value });
  }

  render() {
    return (
      <CompanyDocumentsUploadViewComponent
        isProcessing={this.state.isProcessing}
        handleFail={this.handleFail}
        uploadFile={this.uploadFile}
        handleDocumentTypeChange={this.handleDocumentTypeChange}
        selectedDocumentType={this.state.selectedDocumentType}
        documentTypes={this.state.documentTypes}
      />
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps)(CompanyDocumentsUploadComponent);
