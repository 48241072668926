/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet'

import IntlMessageComponent from 'components/formatters/intlMessage';
import LoadingComponent from 'components/loading';
import WizardComponent from 'components/wizard';

import AddSupplierTab1Component from './tab1/index';
import AddSupplierTab2Component from './tab2/index';
import AddSupplierTab3Component from './tab3/index';
import AddSupplierSuccessComponent from './success/index';

import SupplierCompany from 'models/confirming/supplierCompany';
import ConfirmingService from 'services/ConfirmingService';

const ADD_SUPPLIER_STEP_COMPANY = 0;
const ADD_SUPPLIER_STEP_BANKACCOUNT = 1;
const ADD_SUPPLIER_STEP_DOCUMENTS = 2;

type ConfirmingAddSuppliersComponentProps = {
  activeCompanyId: number;
  supplierId: number;
}

type ConfirmingAddSuppliersComponentState = {
  activeStepId: number;
  supplierCompany: SupplierCompany;
  supplierId: number;
  isLoading: boolean;
  steps: { id: number, title: IntlMessageComponent }[];
  addSupplierCompleted: boolean;
};

class ConfirmingAddSuppliersComponent extends React.Component<ConfirmingAddSuppliersComponentProps, ConfirmingAddSuppliersComponentState> {

  constructor(props) {
    super(props);

    // retrieve invoiceId from url or leadOfferId from history state
    let supplierId = 0;
    if (props.supplierId > 0) {
      supplierId = props.supplierId;
    }

    const steps = this.getSteps();

    this.state = {
      activeStepId: ADD_SUPPLIER_STEP_COMPANY,
      supplierCompany: null,
      supplierId: 0,
      addSupplierCompleted: false,
      isLoading: supplierId > 0,
      steps
    };
  }

  componentDidMount = async () => {
    if (this.state.supplierId > 0) {
      // continue invoice -> get debtor & step from db
      const response = await ConfirmingService.getConfirmingSupplierDetails(this.state.supplierId);
      this.setState({
        activeStepId: ADD_SUPPLIER_STEP_BANKACCOUNT,
        supplierCompany: response,
        isLoading: false
      });
    }
  }

  getSteps = () => {
    const tabsNumber = [
      ADD_SUPPLIER_STEP_COMPANY,
      ADD_SUPPLIER_STEP_BANKACCOUNT,
      ADD_SUPPLIER_STEP_DOCUMENTS
    ];
    return tabsNumber.map(num => ({ id: num, title: <IntlMessageComponent id={`page.addSupplier.tab${num + 1}`} /> }));
  }

  getStepContent = () => {
    switch (this.state.activeStepId) {
      case ADD_SUPPLIER_STEP_COMPANY:
        return (<AddSupplierTab1Component
          successHandler={this.handleSuccessTab1}
        />);
      case ADD_SUPPLIER_STEP_BANKACCOUNT:
        return (<AddSupplierTab2Component
          supplierId={this.state.supplierId}
          successHandler={this.handleSuccessTab2}
        />);
      case ADD_SUPPLIER_STEP_DOCUMENTS:
        return (<AddSupplierTab3Component
          supplierId={this.state.supplierId}
          successHandler={this.handleSuccessTab3}
        />);
      default:
        return 'Unknown step';
    }
  };

  canNavigateToTab = (step: number) => {
    return false;
  };


  handleTabClick = (stepId: number) => {
    if (this.canNavigateToTab(stepId)) {
      this.setState({ activeStepId: stepId });
    }
  };

  reset = () => {
    this.setState({
      activeStepId: ADD_SUPPLIER_STEP_COMPANY,
      supplierCompany: null,
      supplierId: 0,
      isLoading: false,
      addSupplierCompleted: false
    });
  }

  handleSuccessTab1 = (supplierId: number) => this.setState({ activeStepId: ADD_SUPPLIER_STEP_BANKACCOUNT, supplierId: supplierId });
  handleSuccessTab2 = (supplierCompany: SupplierCompany, supplierId: number) => {
    if (supplierCompany.hasBankAccountDocument)
      this.setState({ addSupplierCompleted: true });
    else
      this.setState({ activeStepId: ADD_SUPPLIER_STEP_DOCUMENTS, supplierCompany, supplierId });
  }
  handleSuccessTab3 = () => this.setState({ addSupplierCompleted: true });

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    if (this.state.addSupplierCompleted) {
      return (
        <AddSupplierSuccessComponent
          supplierId={this.state.supplierId}
        />)
    }

    return (
      <React.Fragment>
        <WizardComponent
          activeStepId={this.state.activeStepId}
          steps={this.state.steps}
          canNavigateToTab={this.canNavigateToTab}
          handleTabClick={this.handleTabClick}
        >
          {this.getStepContent()}
        </WizardComponent>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps)(ConfirmingAddSuppliersComponent);
