/* @flow */

import React from 'react';
import moment from 'moment';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import IntlMessageComponent from 'components/formatters/intlMessage';
import TypeImplComponent from 'components/formFields/types/typeImpl';

import DebtorExportInvoicesButtonComponent from './debtorExportInvoicesButton';
import DebtorInvoiceAmountComponent from './debtorInvoiceAmount';
import DebtorInvoicePeriodComponent from './debtorInvoicePeriod';
import ConfirmingInvoiceStatusComponent from './confirmingInvoiceStatus';

import InvoiceActiveFilters from 'models/debtors/invoiceActiveFilters';
import InvoiceFilters from 'models/confirming/invoiceFilters';

type DebtorInvoiceTableControlsViewComponentProps = {
  activeFilters: InvoiceActiveFilters;
  filterOnAmount: boolean;
  filterOnInvoiceStatus: boolean;
  filterOnPeriod: boolean;
  filterOnSellerCompany: boolean;
  filters: InvoiceFilters;
  handleExport: (format: string) => void;
  handleFiltersChange: (filters: InvoiceFilters) => void;
}

export default function DebtorInvoiceTableControlsViewComponent(props: DebtorInvoiceTableControlsViewComponentProps) {

  const handleSellerCompanyChange = (sellerCompanyId: number) => {
    const activeFilters = props.activeFilters;
    activeFilters.sellerCompanyId = sellerCompanyId;
    props.handleFiltersChange(activeFilters);
  };

  const handleQueryChange = (event) => {
    const activeFilters = props.activeFilters;
    activeFilters.query = event.target.value;
    props.handleFiltersChange(activeFilters);
  };

  const handleAmountChange = (val) => {
    const activeFilters = props.activeFilters;
    activeFilters.invoiceAmounts = val;
    props.handleFiltersChange(activeFilters);
  };

  const handleStatusChange = (statusKey: string) => {
    const activeFilters = props.activeFilters;
    activeFilters.statusKey = statusKey;
    props.handleFiltersChange(activeFilters);
  };

  const handlePeriodChange = (dateFrom: moment, dateTo: moment) => {
    const activeFilters = props.activeFilters;
    activeFilters.dateFrom = dateFrom;
    activeFilters.dateTo = dateTo;
    props.handleFiltersChange(activeFilters);
  };
  return (
    <Paper elevation={0}>
      <Box p={3} py={4}>
        <Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={3}>
          <Grid container item xs={12} md={6}>
            <Grid item xs={12} md={6}>
              <Box display="flex" height="100%" alignItems="center" pt={2}>
                <b><IntlMessageComponent id="debtor.portfolio.table.filters.search" /></b>
                <span style={{ paddingLeft: "0.5rem" }}><IntlMessageComponent id="component.portfolio.filter.search.help" /></span>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                value={props.activeFilters.query}
                onChange={handleQueryChange}
                margin="dense"
                label=" "
                fullWidth
              />
            </Grid>
          </Grid>

          {props.filterOnSellerCompany &&
            <Grid container item xs={12} md={6}>
              <Grid item xs={12} md={6}>
                <Box display="flex" height="100%" alignItems="center" pt={2}>
                  <b><IntlMessageComponent id="debtor.portfolio.table.filters.sellerCompany" /></b>
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <TypeImplComponent
                  value={props.activeFilters.sellerCompanyId}
                  values={props.filters.supplierCompanies}
                  changeHandler={handleSellerCompanyChange}
                  margin="dense"
                  label=" "
                  fullWidth
                />
              </Grid>
            </Grid>
          }

          {props.filterOnAmount &&
            <Grid container item xs={12} md={6}>
              <DebtorInvoiceAmountComponent
                changeHandler={handleAmountChange}
                min={props.activeFilters.invoiceAmounts !== null ? props.activeFilters.invoiceAmounts[0] : ""}
                max={props.activeFilters.invoiceAmounts !== null ? props.activeFilters.invoiceAmounts[1] : ""}
              />
            </Grid>
          }

          {props.filterOnInvoiceStatus &&
            <Grid container item xs={12} md={6}>
              <Grid item xs={12} md={6}>
                <Box display="flex" height="100%" alignItems="center" pt={2}>
                  <b><IntlMessageComponent id="debtor.portfolio.table.filters.debtorStatus" /></b>
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <ConfirmingInvoiceStatusComponent
                  value={props.activeFilters.statusKey}
                  values={props.filters.statusKeys}
                  changeHandler={handleStatusChange}
                  margin="dense"
                  fullWidth
                />
              </Grid>
            </Grid>
          }

          {props.filterOnPeriod &&
            <Grid container item xs={12} md={6}>
              <Grid item xs={12} md={6}>
                <Box display="flex" height="100%" alignItems="center" pt={2}>
                  <b><IntlMessageComponent id="debtor.portfolio.table.filters.period" /></b>
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <DebtorInvoicePeriodComponent
                  dateFrom={props.activeFilters.dateFrom}
                  dateTo={props.activeFilters.dateTo}
                  changeHandler={handlePeriodChange}
                  margin="dense"
                  fullWidth
                />
              </Grid>
            </Grid>
          }
        </Grid>

        {props.handleExport &&
          <Box mt={3}>
            <DebtorExportInvoicesButtonComponent handleExport={props.handleExport} />
          </Box>
        }
      </Box>
    </Paper >
  );
}
