/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import ButtonComponent from 'components/buttons/button';
import FormErrorsComponent from 'components/formErrors';
import IntlMessageComponent from 'components/formatters/intlMessage';
import MandatoryFormFieldsComponent from 'components/form/mandatory';
import SecurityComponent from 'components/security/index';

import CompanyUBOsFormCompanyInfoPanelComponent from './panels/companyInfoPanel';
import CompanyUBOsFormContactInfoPanelComponent from './panels/contactInfoPanel';
import CompanyUBOsFormDocumentInfoPanelComponent from './panels/documentInfoPanel';
import CompanyUBOsFormPersonalInfoPanelComponent from './panels/personalInfoPanel';
import CompanyUBOsFormUserInfoPanelComponent from './panels/userInfoPanel';

import CompanyUBOForm from './model';

type CompanyUBOsFormViewComponentProps = {
  errors: Map<string,?string>;
  form: CompanyUBOForm;
  formErrors: string[];
  isCompanyUser: boolean;
  isProcessing: boolean;
  docNotExpiredAndNotReplaced: [];
  hasValidId: boolean;
  cancel: () => void;
  handleChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void;
  handleNumberChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void;
  handleBooleanChange: (fieldName: string) => (value: boolean) => void;
  handleDateChange: (fieldName: string) => (date: moment) => void;
  handleFileChange: () => void;
  handleTypeChange: (fieldName: string) => (id: number) => void;
  submitForm: () => void;
}

export default function CompanyUBOsFormViewComponent(props: CompanyUBOsFormViewComponentProps) {
  return (
    <React.Fragment>
      <Paper elevation={0}>
        <Box p={3}>

          <FormErrorsComponent formErrors={props.formErrors} />

          {props.isCompanyUser ? (
            <CompanyUBOsFormUserInfoPanelComponent form={props.form} />
          ) : (
            <React.Fragment>
              <CompanyUBOsFormPersonalInfoPanelComponent
                errors={props.errors}
                form={props.form}
                handleChange={props.handleChange}
                handleDateChange={props.handleDateChange}
                handleTypeChange={props.handleTypeChange}
              />

              <Box mt={5}>
                <CompanyUBOsFormContactInfoPanelComponent
                  errors={props.errors}
                  form={props.form}
                  handleChange={props.handleChange}
                  handleTypeChange={props.handleTypeChange}
                />
              </Box>

              <Box mt={5}>
                <CompanyUBOsFormDocumentInfoPanelComponent
                  errors={props.errors}
                  form={props.form}
                  isProcessing={props.isProcessing}
                  handleChange={props.handleChange}
                  handleDateChange={props.handleDateChange}
                  handleFileChange={props.handleFileChange}
                  handleTypeChange={props.handleTypeChange}
                  docNotExpiredAndNotReplaced={props.docNotExpiredAndNotReplaced}
                  hasValidId={props.hasValidId}
                />
              </Box>
            </React.Fragment>
          )}

          <Box mt={5}>
            <CompanyUBOsFormCompanyInfoPanelComponent
              errors={props.errors}
              form={props.form}
              handleChange={props.handleChange}
              handleNumberChange={props.handleNumberChange}
              handleBooleanChange={props.handleBooleanChange}
            />
          </Box>

          <Box mt={5}>
            <ButtonComponent
              color="default"
              variant="contained"
              onClick={props.cancel}
            ><IntlMessageComponent id="general.cancel" /></ButtonComponent>

            <SecurityComponent pageName="POS.Companies.Ubos" elementName="btnConfirm">
              <Box component="span" ml={1}>
                <ButtonComponent
                  color="primary"
                  variant="contained"
                  isProcessing={props.isProcessing}
                  onClick={props.submitForm}
                ><IntlMessageComponent id="general.submit" /></ButtonComponent>
              </Box>
            </SecurityComponent>

            <MandatoryFormFieldsComponent />
          </Box>
        </Box>
      </Paper>
    </React.Fragment>
  );
}