/* @flow */

import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';

type FileExportDialogComponentProps = {
  isOpen: boolean;
  typesAllowed: string[];
  handleClose: () => void;
  handleExport: (value: string) => void;
}

type FileExportDialogComponentState = {
  value: ?string;
}

class FileExportDialogComponent extends React.Component<FileExportDialogComponentProps, FileExportDialogComponentState> {

  constructor(props) {
    super(props);
    this.state = {
      value: ''
    };
  }

  handleChange = (event: SyntheticInputEvent<>) => this.setState({ value: event.target.value });

  submit = () => this.state.value && this.props.handleExport(this.state.value);

  render() {
    return (
      <Dialog open={this.props.isOpen} onClose={this.props.handleClose}>
        <DialogTitle>
          <IntlMessageComponent id="component.fileExportDialog.title" />
        </DialogTitle>

        <DialogContent>
          <FormControl component="fieldset" required>
            <FormLabel component="legend">
              <IntlMessageComponent id="component.fileExportDialog.label" />
            </FormLabel>

            <RadioGroup
              required
              aria-label={<IntlMessageComponent id="component.fileExportDialog.label" />}
              name="exportFormat"
              value={this.state.value}
              onChange={this.handleChange}
            >
              {this.props.typesAllowed.map(elem => (
                <FormControlLabel
                  control={<Radio />}
                  key={`radiolabel-${elem}`}
                  label={<IntlMessageComponent id={`export.format.${elem}`} />}
                  value={elem}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </DialogContent>

        <DialogActions>
          <ButtonComponent
            color="default"
            onClick={this.props.handleClose}
          ><IntlMessageComponent id="general.cancel" /></ButtonComponent>

          <ButtonComponent
            color="primary"
            disabled={this.state.value === ''}
            onClick={this.submit}
          ><IntlMessageComponent id="general.submit" /></ButtonComponent>
        </DialogActions>
      </Dialog>
    );
  }
}

export default FileExportDialogComponent;
