/* @flow */

import React from 'react';

import {Link} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import IntlMessageComponent from 'components/formatters/intlMessage';

const useStyles = makeStyles(() => ({
  itemIcon: {
    minWidth: '35px'
  }
}));

export default function WebsiteHeaderUserMenuItemLinkComponent(props) {
  const classes = useStyles();

  const renderLink = React.useMemo(() => React.forwardRef((linkProps, ref) => (<Link ref={ref} to={props.item.link} {...linkProps} />)), [props.item.link]);

  if (props.item.visibleBy && props.item.visibleBy.find(vb => vb === props.user.activeCompany.role) === undefined)
    return null;

  return (
    <ListItem button component={renderLink}>
      <ListItemIcon className={classes.itemIcon}>
        <ArrowForwardIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText primary={<IntlMessageComponent id={props.item.translationKey} />} />
    </ListItem>
  );
}
