/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import Box from '@material-ui/core/Box';

import CompanyItemComponent from 'components/company';
import CompanyLookupDebtorViewComponent from './view';
import CompanyLookupDebtorTwoColumnsViewComponent from './view2columns';

import DebtorCompany from 'models/companies/debtorCompany';

import CompanyLookupService from 'services/CompanyLookupService';

import {ROLE_ISO} from 'constants/companyRoles';

type CompanyLookupDebtorComponentProps = {
  activeCompanyId: number;
  activeCompanyRole: number;
  company: DebtorCompany;
  companyErrorLabelId: string;
  countriesForDebtors: Country[];
  required: boolean;
  twoColumns: boolean;
  handleSelect: (company: DebtorCompany) => void;
}

type CompanyLookupDebtorComponentState = {
  knownDebtors: DebtorCompany[];
}

class CompanyLookupDebtorComponent extends React.Component<CompanyLookupDebtorComponentProps, CompanyLookupDebtorComponentState> {

  constructor(props) {
    super(props);
    this.state = {
      knownDebtors: null
    };
  }

  componentDidMount = async () => {
    if (this.props.activeCompanyId <= 0 || this.props.activeCompanyRole === ROLE_ISO) return;
    const knownDebtors = await CompanyLookupService.getDebtorCompanies(this.props.activeCompanyId);
    this.setState({knownDebtors});
  }

  handleKnownDebtorCompanySelect = (company: DebtorCompany) => {
    const debtorCompany = this.state.knownDebtors.find(d => d.id === company.id);
    this.props.handleSelect(debtorCompany);
  };

  render() {
    if (this.props.company) {
      return (
        <Box px={4} py={3} bgcolor="lightblue.main">
          <CompanyItemComponent
            buttonLabelId="component.offer.tab1.company.change"
            company={this.props.company}
            selected
            selectHandler={() => this.props.handleSelect(null)}
            hideVATCountry={/[a-z]/i.test(this.props.company.vatNumber)}
          />
        </Box>
      );
    }

    if (this.props.twoColumns) {
      return (
        <CompanyLookupDebtorTwoColumnsViewComponent
          companyErrorLabelId={this.props.companyErrorLabelId}
          countriesForDebtors={this.props.countriesForDebtors}
          knownDebtors={this.state.knownDebtors}
          required={this.props.required}
          handleKnownDebtorCompanySelect={this.handleKnownDebtorCompanySelect}
          handleSelect={this.props.handleSelect}
        />
      )
    }
    return (
      <CompanyLookupDebtorViewComponent
        companyErrorLabelId={this.props.companyErrorLabelId}
        countriesForDebtors={this.props.countriesForDebtors}
        knownDebtors={this.state.knownDebtors}
        required={this.props.required}
        handleKnownDebtorCompanySelect={this.handleKnownDebtorCompanySelect}
        handleSelect={this.props.handleSelect}
      />
    )
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user !== null ? state.auth.user.activeCompany.id : 0,
  activeCompanyRole: state.auth.user !== null ? state.auth.user.activeCompany.role : 0,
  countriesForDebtors: state.locale.countriesForDebtors
});

export default connect(mapStateToProps)(CompanyLookupDebtorComponent);
