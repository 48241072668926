/* @flow */

import React from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import {fade} from '@material-ui/core/styles/colorManipulator';
import {makeStyles} from '@material-ui/core/styles';

type ButtonComponentProps = {
  color: string;
  disabled: boolean;
  fullWidth: boolean;
  isProcessing: boolean;
  variant: string;
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: '.5em 2em',
    textTransform: 'none !important'
  },
  textTertiary: {
    color: theme.palette.tertiary.main,
    '&:hover': {
      backgroundColor: fade(theme.palette.tertiary.dark, theme.palette.action.hoverOpacity)
    }
  },
  containedTertiary: {
    backgroundColor: theme.palette.tertiary.main,
    color: theme.palette.tertiary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.tertiary.dark
    }
  },
  outlinedTertiary: {
    borderColor: fade(theme.palette.tertiary.main, 0.5),
    color: theme.palette.tertiary.main,
    '&:hover': {
      borderColor: theme.palette.tertiary.main,  
      color: theme.palette.tertiary.main
    }
  }
}));

export default function ButtonComponent(props: ButtonComponentProps) {
  const classes = useStyles();
  const {color, disabled, endIcon, isProcessing, variant, ...rest } = props;

  const colorProp = ["default", "primary", "secondary"].indexOf(color) > -1 ? color : "default";
  const variantProp = ["contained", "outlined", "text"].indexOf(variant) > -1 ? variant : "text";

  let additionalClass = '';
  if (color === "tertiary")
    additionalClass = classes[`${variantProp}Tertiary`];

  let endIconProp = undefined;
  if (endIcon) endIconProp = endIcon;
  if (isProcessing) endIconProp = <CircularProgress size={14} />;

  return (
    <Button
      className={`${classes.root} ${additionalClass}`}
      color={colorProp}
      disabled={disabled || isProcessing}
      endIcon={endIconProp}
      variant={variantProp}
      {...rest}
    >
      {props.children}
    </Button>
  );
}
