/* @flow */

import moment from 'moment';

export default class Notification {
  id: string;
  amount: number;
  msfAmount: number;
  count: number;
  dateFrom: moment;
  dateTo: moment;
  invoiceIds: number[];
  generalSubstitution: string;
}
