/* @flow */

import Company from 'models/companies/company';

import CreateCouponRequest from 'models/requests/isos/coupons/createCouponRequest';

export default class IsoCouponForm {
  company: Company;
  amount: number;
  reason: string;

  constructor() { 
    this.company = null;
    this.amount = null;
    this.reason = '';
  }

  toRequest(isoCompanyId: number) {
    const request = new CreateCouponRequest();
    request.isoCompanyId = isoCompanyId;
    request.companyId = this.company.id;
    request.value = this.amount;
    request.reason = this.reason;
    return request;
  }
}