/* @flow */

/**
 * Function shared among validators
 * @param value
 * @param options
 * @param key
 * @param attributes
 */
function checkTypes(value: number, options: {sibling: string, message: ?string}, key: string, attributes: any) {
  if (typeof value === 'undefined') {
    throw new Error('value required');
  }

  if (typeof value !== 'number') {
    throw new Error('Value is not a number');
  }

  if (typeof options.sibling !== 'string') {
    throw new Error('Sibling name required');
  }

  if (!Object.hasOwnProperty.call(attributes, options.sibling)) {
    throw new Error(`Sibling is missing (required ${options.sibling}, keys : ${Object.keys(attributes).join(', ')})`);
  }

  if (typeof attributes[options.sibling] !== 'number') {
    throw new Error('Requested sibling is not a number');
  }
}

function isLessThanSiblingValidator(value: number, options: {sibling: string, message: ?string, multiplier: ?number}, key: string, attributes: any) {
  if (key === 'single') {
    return undefined; // Validators doesn't works with validate.single
  }

  try {
    checkTypes(value, options, key, attributes);
  } catch (e) {
    console.error(e);
    return options.message;
  }

  const checkAgainst = options.multiplier ? attributes[options.sibling] * options.multiplier : attributes[options.sibling];

  // Actual check
  if (value < checkAgainst) {
    return undefined;
  }

  return options.message;
}

export default isLessThanSiblingValidator;