/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import AmountFormatterComponent from 'components/formatters/amount';
import CompanyAddressComponent from 'components/company/address';
import DateFormatterComponent from 'components/formatters/date';
import IntlMessageComponent from 'components/formatters/intlMessage';
import NumberFormatterComponent from 'components/formatters/number';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

type DebtorInvoiceDetailsSummaryViewComponentProps = {
  invoice: Invoice;
  statuses: string[];
}

export default function DebtorInvoiceDetailsSummaryViewComponent(props: DebtorInvoiceDetailsSummaryViewComponentProps) {

  return (
    <React.Fragment>
      <PageSubTitleViewComponent id="debtor.invoiceDetails.summary.title" data-walkme="debtor-invoiceDetails-summary" />

      <Box mt={3}>
        <Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={3}>
          <Grid item xs={12} md={6}>
            <Box px={2}>
              <Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={3}>
                <Grid item xs={4} md={6}>
                  <Typography variant="body1">
                    <IntlMessageComponent id="debtor.invoiceDetails.summary.sellerCompany" data-walkme="debtor-invoiceDetails-summary-sellerCompany" />
                  </Typography>
                </Grid>
                <Grid item xs={8} md={6}>
                  <CompanyAddressComponent company={props.invoice.sellerCompany} />
                </Grid>

                <Grid item xs={4} md={6}>
                  <Typography variant="body1">
                    <IntlMessageComponent id="debtor.invoiceDetails.summary.reference" data-walkme="debtor-invoiceDetails-summary-reference" />
                  </Typography>
                </Grid>
                <Grid item xs={8} md={6}>
                  <Typography variant="body1">{props.invoice.reference}</Typography>
                </Grid>

                <Grid item xs={4} md={6}>
                  <Typography variant="body1">
                    <IntlMessageComponent id="debtor.invoiceDetails.summary.debtorReference" data-walkme="debtor-invoiceDetails-summary-debtorReference" />
                  </Typography>
                </Grid>
                <Grid item xs={8} md={6}>
                  <Typography variant="body1">{props.invoice.debtorReference}</Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box px={2}>
              <Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={3}>
                <Grid item xs={4} md={6}>
                  <Typography variant="body1">
                    <IntlMessageComponent id="debtor.invoiceDetails.summary.issueDate" data-walkme="debtor-invoiceDetails-summary-issueDate" />
                  </Typography>
                </Grid>
                <Grid item xs={8} md={6}>
                  <DateFormatterComponent date={props.invoice.issueDate} format="DD MMMM YYYY" />
                </Grid>

                <Grid item xs={4} md={6}>
                  <Typography variant="body1">
                    <IntlMessageComponent id="debtor.invoiceDetails.summary.dueDate" data-walkme="debtor-invoiceDetails-summary-dueDate" />
                  </Typography>
                </Grid>
                <Grid item xs={8} md={6}>
                  <DateFormatterComponent date={props.invoice.dueDate} format="DD MMMM YYYY" />
                </Grid>

                <Grid item xs={4} md={6}>
                  <Typography variant="body1">
                    <IntlMessageComponent id="debtor.invoiceDetails.summary.debtAmount" data-walkme="debtor-invoiceDetails-summary-debtAmount" />
                  </Typography>
                </Grid>
                <Grid item xs={8} md={6}>
                  <AmountFormatterComponent amount={props.invoice.debtAmount} />
                </Grid>

                {props.invoice.refused &&
                  <React.Fragment>
                    <Grid item xs={4} md={6}>
                      <Typography variant="body1">
                        <IntlMessageComponent id="debtor.invoiceDetails.summary.debtorRefusedReason" data-walkme="debtor-invoiceDetails-summary-debtorRefusedReason" />
                      </Typography>
                    </Grid>
                    <Grid item xs={8} md={6}>
                      <Typography variant="body1">{props.invoice.debtorRefusedReason}</Typography>
                    </Grid>
                  </React.Fragment>
                }
                {props.invoice.toApprove &&
                  <React.Fragment>
                    <Grid item xs={4} md={6}>
                      <Typography variant="body1">
                        <IntlMessageComponent id="debtor.invoiceDetails.summary.validationAge" data-walkme="debtor-invoiceDetails-summary-validationAge" />
                      </Typography>
                    </Grid>
                    <Grid item xs={8} md={6}>
                      <NumberFormatterComponent value={props.invoice.validationAge} />&nbsp;<IntlMessageComponent id="general.days" />
                    </Grid>
                  </React.Fragment>
                }

                {props.invoice.approved &&
                  <React.Fragment>
                    <Grid item xs={4} md={6}>
                      <Typography variant="body1">
                        <IntlMessageComponent id="debtor.invoiceDetails.summary.approvedby" />
                      </Typography>
                    </Grid>
                    <Grid item xs={8} md={6}>
                      {props.invoice.approvedBy}
                    </Grid>

                    <Grid item xs={4} md={6}>
                      <Typography variant="body1">
                        <IntlMessageComponent id="debtor.invoiceDetails.summary.outstandingBalance" data-walkme="debtor-invoiceDetails-summary-outstandingBalance" />
                      </Typography>
                    </Grid>
                    <Grid item xs={8} md={6}>
                      <AmountFormatterComponent amount={props.invoice.outstandingBalance} />
                    </Grid>

                    <Grid item xs={4} md={6}>
                      <Typography variant="body1">
                        <IntlMessageComponent id="debtor.invoiceDetails.summary.daysToDueDate" data-walkme="debtor-invoiceDetails-summary-daysToDueDate" />
                      </Typography>
                    </Grid>
                    <Grid item xs={8} md={6}>
                      {props.invoice.daysToDueDate > -1 && !props.invoice.paidDate ?
                        <Box color="red.main"><NumberFormatterComponent value={props.invoice.daysToDueDate} />&nbsp;<IntlMessageComponent id="general.days" /></Box> :
                        <React.Fragment><NumberFormatterComponent value={props.invoice.daysToDueDate} />&nbsp;<IntlMessageComponent id="general.days" /></React.Fragment>}
                    </Grid>

                    {props.invoice.paid &&
                      <React.Fragment>
                        <Grid item xs={4} md={6}>
                          <Typography variant="body1">
                            <IntlMessageComponent id="debtor.invoiceDetails.summary.paymentPromiseDate" data-walkme="debtor-invoiceDetails-summary-paymentPromiseDate" />
                          </Typography>
                        </Grid>
                        <Grid item xs={8} md={6}>
                          <DateFormatterComponent date={props.invoice.paymentPromiseDate} format="DD MMMM YYYY" />
                        </Grid>
                      </React.Fragment>
                    }
                  </React.Fragment>
                }
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}
