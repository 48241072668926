/* @flow */

import React from 'react';

import {withStyles} from '@material-ui/core/styles';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import IntlMessageComponent from 'components/formatters/intlMessage';

type DebtorGradeForSellerComponentProps = {
  insurerRating: number,
  shortMessageWhenZero: boolean,
  widthPercentage: number
}

class DebtorGradeForSellerComponent extends React.PureComponent<DebtorGradeForSellerComponentProps> {

  static defaultProps = {
    widthPercentage: 100
  };

  _gradeToPixel = (grade: number, width: number, shift: number) => (((grade - 1) * (10 / 9)) * (width / 10)) + shift;

  gradeToPixel = (grade: number, width: number, shift: number) => {
    let arbitraryGrade = 0;

    if (grade < 5) {
      arbitraryGrade = 3;
    } else if (grade>=5 && grade <7){
      arbitraryGrade=6;
    } else if (grade>=7 && grade <9) {
      arbitraryGrade = 8;
    } else if (grade >=9) {
      arbitraryGrade = 10;
    }

    return this._gradeToPixel(arbitraryGrade, width, shift);
  };

  render() {
    if (this.props.insurerRating === 0) {
      return (
        <div>
          <IntlMessageComponent id="component.grade.grade_zero" />
          {!this.props.shortMessageWhenZero &&
            <br />
          }
          {!this.props.shortMessageWhenZero &&
            <IntlMessageComponent id="component.grade.grade_zero_explaination" />
          }
        </div>
      );
    }

    return (
      <div className={this.props.classes.meter} style={{width: `${247 * (this.props.widthPercentage / 100)}px`}}>
        <div
          className={this.props.classes.needle}
          style={
            {left: `${this.gradeToPixel(this.props.insurerRating, 204 * (this.props.widthPercentage / 100), -5)}px`}
          }>
          <ArrowDropUpIcon />
        </div>
      </div>
    );
  }
}

const styles = _ => ({
  meter: {
    marginTop: '10px',
    height: '20px',
    position: 'relative',
    paddingBottom: '14px',

    backgroundRepeat: 'repeat-y',
    backgroundImage: 'url("./images/grades/rating-gradient.png")',
    backgroundPositionX: 'center',
    backgroundSize: 'contain',
    backgroundClip: 'content-box'
  },
  needle: {
    position: 'absolute',
    bottom: 0
  }
});

export default withStyles(styles)(DebtorGradeForSellerComponent);
