/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Download from '@material-ui/icons/CloudDownload';
import EditOutlined from '@material-ui/icons/EditOutlined';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';

import DateFormatterComponent from 'components/formatters/date';
import IntlMessageComponent from 'components/formatters/intlMessage';
import StyledTableCellTextComponent from 'components/table/cellText';
import StyledTableCellNumbersComponent from 'components/table/cellNumbers';
import StyledTableHeadComponent from 'components/table/head';
import StyledTableRowComponent from 'components/table/row';

import DocumentItem from 'models/documents/documentItem';

const columnData = [
  { id: 'typeId', align:'left', translationKey: 'component.company.documentList.header.documenttype', walkme: "column-documenttype" },
  { id: 'created', align:'left', translationKey: 'component.company.documentList.header.createdDate', walkme: "column-createddate" },
  { id: 'download', align:'left', translationKey: 'component.company.documentList.header.download', walkme: "column-download" },
  { id: 'toSign', align:'left', translationKey: 'component.company.documentList.header.sign', walkme: "column-sign" }
];

type CompanyDocumentsTableViewComponentProps = {
  documents: DocumentItem[];
  order: string;
  orderBy: string;
  page: number;
  pageSize: number;
  walkmePrefix: string;
  downloadDocument: (documentId: number) => void;
  handlePageChange: (page: number) => void;
  handlePageSizeChange: (pageSize: number) => void;
  handleSortOrderChange: (property: string) => void;
  signDocument: (documentId: number) => void;
};

export default function CompanyDocumentsTableViewComponent(props: CompanyDocumentsTableViewComponentProps) {

  const handlePageChange = (_, page) => props.handlePageChange(page);
  const handlePageSizeChange = event => props.handlePageSizeChange(event.target.value);

  return (
    <React.Fragment>
      <Box py={5}>
        <Box component="div" overflow="auto">
          <Table>
            <StyledTableHeadComponent data-walkme={`${props.walkmePrefix}-table-headline`}>
              <StyledTableRowComponent>
                {columnData.map(column => (
                  <StyledTableCellTextComponent key={column.id} padding="none">
                    <TableSortLabel
                      active={props.orderBy === column.id}
                      direction={props.order}
                      onClick={() => props.handleSortOrderChange(column.id)}
                      data-walkme={`${props.walkmePrefix}-${column.walkme}` || ''}
                    ><IntlMessageComponent id={column.translationKey} /></TableSortLabel>
                  </StyledTableCellTextComponent>
                ))}
              </StyledTableRowComponent>
            </StyledTableHeadComponent>

            <TableBody bgcolor="white">
              {props.documents.length === 0 &&
                <StyledTableRowComponent>
                  <StyledTableCellTextComponent colSpan={columnData.length}>
                    <Box textAlign="center">
                      <IntlMessageComponent id="component.company.documentList.noData" />
                    </Box>
                  </StyledTableCellTextComponent>
                </StyledTableRowComponent>
              }

              {props.documents.slice(props.page * props.pageSize, (props.page * props.pageSize) + props.pageSize).map(doc => (
                <StyledTableRowComponent hover tabIndex={-1} key={`documentList-${doc.id}`}>
                  <StyledTableCellTextComponent padding="none">
                    {doc.personName ?
                      <strong><IntlMessageComponent id={`document.type.${doc.code}`} /> ({doc.personName})</strong> :
                      <strong><IntlMessageComponent id={`document.type.${doc.code}`} /></strong>
                    }
                  </StyledTableCellTextComponent>

                  <StyledTableCellTextComponent padding="none">
                    <DateFormatterComponent date={doc.created} />
                  </StyledTableCellTextComponent>

                  <StyledTableCellTextComponent padding="none">
                    <Tooltip title={<IntlMessageComponent id="component.company.documents.table.button.download.tooltip" />}>
                      <IconButton onClick={() => props.downloadDocument(doc.id)}>
                        <Download />
                      </IconButton>
                    </Tooltip>
                  </StyledTableCellTextComponent>

                  <StyledTableCellTextComponent padding="none">
                    {doc.toSign === true ? (
                      <IconButton onClick={() => props.signDocument(doc.id)}>
                        <EditOutlined />
                      </IconButton>
                    ) : (
                      <DateFormatterComponent date={doc.signed} />
                    )}
                  </StyledTableCellTextComponent>
                </StyledTableRowComponent>
              ))}
            </TableBody>
          </Table>
        </Box>

        <Box display="flex" height={60}>
          <Box flexGrow={1}></Box>
          <Box justifyContent="flex-end">
            <TablePagination
              component="div"
              count={props.documents.length}
              rowsPerPage={props.pageSize}
              page={props.page}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePageSizeChange}
              labelRowsPerPage={<IntlMessageComponent id="component.bills.billsPerPage" />}
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
            />
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}
