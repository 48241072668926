/* @flow */

export default class ConfirmInvoicePaymentRequest {
  companyId: number;
  investmentProfileId: number;
  invoiceId: number;

  constructor(companyId: number, investmentProfileId: number, invoiceId: number) {
    this.companyId = companyId;
    this.investmentProfileId = investmentProfileId;
    this.invoiceId = invoiceId;
  }
}
