/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import BackButtonComponent from 'components/buttons/backButton';
import IntlMessageComponent from 'components/formatters/intlMessage';

type PageTitleViewComponentProps = {
  backButton: boolean;
  id: string;
  values: any;
  noBottomMargin: boolean;
  backButtonAction: () => void;
}

export default function PageTitleViewComponent(props: PageTitleViewComponentProps) {
  return (
    <Box display="flex" mb={props.noBottomMargin ? 0 : 3}>
      <Box flexGrow={1}>
        <Typography component="span" variant="h1" gutterBottom>
          <IntlMessageComponent id={props.id} values={props.values} data-walkme={props['data-walkme'] || ''} />
        </Typography>
      </Box>
      
      {props.backButton &&
        <Box color="primary.main">
          <BackButtonComponent action={props.backButtonAction}>
            <b>&lt; <IntlMessageComponent id="general.back" /></b>
          </BackButtonComponent>
        </Box>
      }
    </Box>
  );
}
