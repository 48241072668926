/* @flow */

const PNF = require('google-libphonenumber').PhoneNumberFormat;
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

function phoneNumberValidator(value: any, options: {message: ?string}, key: string, attributes: any) {
  if (value === '') return undefined;

  try {
    const number = phoneUtil.parseAndKeepRawInput(value);

    if (!phoneUtil.isPossibleNumber(number) || !phoneUtil.isValidNumber(number)) {
      // noinspection ExceptionCaughtLocallyJS
      throw new Error('Phone number validation failed');
    }

    const e164format = phoneUtil.format(number, PNF.E164);
    if (value !== e164format) {
      throw new Error('Phone number validation failed');
    }
  } catch (e) {
    // console.warn(e);
    return options.message ? options.message : 'validation.phone.format';
  }

  return undefined;
}

export default phoneNumberValidator;
