/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import PhoneNumberComponent from 'components/formFields/phoneNumber';
import ButtonComponent from 'components/buttons/button';
import CompanyPersonWithoutOfficeFieldComponent from 'components/formFields/companyPersonWithoutOffice';
import CountryFieldComponent from 'components/formFields/countries';
import FormErrorsComponent from 'components/formErrors';
import IntlMessageComponent from 'components/formatters/intlMessage';
import LanguageFieldComponent from 'components/formFields/types/language';
import MandatoryFormFieldsComponent from 'components/form/mandatory';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import SecurityComponent from 'components/security';
import TitleFieldComponent from 'components/formFields/types/title';

import OfficePerson from 'models/companies/officePerson';
import OfficePersonForm from './model';

type CompanyOfficePersonFormViewComponentProps = {
  errors: Map<string, ?string>;
  form: OfficePersonForm;
  formErrors: string[];
  isProcessing: boolean;
  officePerson: OfficePerson;
  handleChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void;
  handleCompanyPersonChange: () => void;
  handlePhoneChange: (value: string) => void;
  handleTypeChange: (fieldName: string) => (id: number) => void;
  handleMobileChange: (value: string) => void;
  submitForm: () => void;
  cancelHandler: () => void;
}

export default function CompanyOfficePersonFormViewComponent(props: CompanyOfficePersonFormViewComponentProps) {

  return (
    <Paper elevation={0}>
      <Box p={3} py={4}>
        <FormErrorsComponent formErrors={props.formErrors} />

        {!props.officePerson &&
          <React.Fragment>
            <PageSubTitleViewComponent id="page.offices.persons.form.find.title" />

            <Box mt={3} mb={5}>
              <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                <Grid item xs={12} md={6}>
                  <Box px={4}>
                    <CompanyPersonWithoutOfficeFieldComponent
                      companyPerson={props.form.companyPerson}
                      error={false}
                      helperText=""
                      handleSelect={props.handleCompanyPersonChange}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </React.Fragment>
        }

        {props.form.companyPerson === null &&
          <React.Fragment>
            <PageSubTitleViewComponent id={`page.offices.persons.form.${props.officePerson ? 'edit' : 'new'}.title`} />

            <Box mt={3}>
              <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                <Grid item xs={12} md={6}>
                  <Box px={4}>
                    <TitleFieldComponent
                      label={<IntlMessageComponent id="form.title.label" />}
                      error={!!props.errors.get('titleId')}
                      helperText={(props.errors.get('titleId')) ?
                        <IntlMessageComponent id={props.errors.get('titleId')} /> : ''}
                      value={props.form.titleId}
                      changeHandler={props.handleTypeChange('titleId')}
                      fullWidth
                      margin="normal"
                      required
                    />
                  </Box>
                </Grid>
              </Grid>

              <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                <Grid item xs={12} md={6}>
                  <Box px={4}>
                    <TextField
                      id="form.name"
                      label={<IntlMessageComponent id="form.name.label" />}
                      error={!!props.errors.get('lastName')}
                      helperText={props.errors.get('lastName') ?
                        <IntlMessageComponent id={props.errors.get('lastName')} /> : ''}
                      onChange={props.handleChange('lastName')}
                      value={props.form.lastName}
                      fullWidth
                      margin="normal"
                      required
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box px={4}>
                    <TextField
                      id="form.firstName"
                      label={<IntlMessageComponent id="form.firstname.label" />}
                      error={!!props.errors.get('firstName')}
                      helperText={props.errors.get('firstName') ?
                        <IntlMessageComponent id={props.errors.get('firstName')} /> : ''}
                      onChange={props.handleChange('firstName')}
                      value={props.form.firstName}
                      fullWidth
                      margin="normal"
                      required
                    />
                  </Box>
                </Grid>
              </Grid>

              <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                <Grid item xs={12} md={6}>
                  <Box px={4}>
                    <PhoneNumberComponent
                      id="form.mobile"
                      label={<IntlMessageComponent id="form.mobile.label" />}
                      error={!!props.errors.get('mobile')}
                      helperText={props.errors.get('mobile') ?
                        <IntlMessageComponent id={props.errors.get('mobile')} /> : <IntlMessageComponent id="form.mobile.alreadySubscribed.helperText" />}
                      value={props.form.mobile}
                      onChange={props.handleMobileChange}
                      fullWidth
                      margin="normal"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box px={4}>
                    <PhoneNumberComponent
                      id="form.phone"
                      label={<IntlMessageComponent id="form.phone.label" />}
                      error={!!props.errors.get('phone')}
                      helperText={props.errors.get('phone') ?
                        <IntlMessageComponent id={props.errors.get('phone')} /> : <IntlMessageComponent id="form.phone.helperText" />}
                      value={props.form.phone}
                      onChange={props.handlePhoneChange}
                      fullWidth
                      margin="normal"
                      required
                    />
                  </Box>
                </Grid>
              </Grid>

              <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                <Grid item xs={12} md={6}>
                  <Box px={4}>
                    <TextField
                      id="form.email"
                      label={<IntlMessageComponent id="form.email.label" />}
                      error={!!props.errors.get('email')}
                      helperText={props.errors.get('email') ?
                        <IntlMessageComponent id={props.errors.get('email')} /> : ''}
                      value={props.form.email}
                      onChange={props.handleChange('email')}
                      fullWidth
                      margin="normal"
                      required
                    />
                  </Box>
                </Grid>
              </Grid>

              <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                <Grid item xs={12} md={6}>
                  <Box px={4}>
                    <CountryFieldComponent
                      id="form.country"
                      label={<IntlMessageComponent id="form.person.country.label" />}
                      error={!!props.errors.get('countryId')}
                      helperText={props.errors.get('countryId') ?
                        <IntlMessageComponent id={props.errors.get('countryId')} /> : ''}
                      value={props.form.countryId}
                      changeHandler={props.handleTypeChange('countryId')}
                      required
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box px={4}>
                    <LanguageFieldComponent
                      label={<IntlMessageComponent id="form.language.label" />}
                      error={!!props.errors.get('languageId')}
                      helperText={(props.errors.get('languageId')) ?
                        <IntlMessageComponent id={props.errors.get('languageId')} /> : ''}
                      value={props.form.languageId}
                      changeHandler={props.handleTypeChange('languageId')}
                      fullWidth
                      margin="normal"
                      required
                    />
                  </Box>
                </Grid>
              </Grid>

              <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                <Grid item xs={12} md={6}>
                  <Box px={4}>
                    <TextField
                      id="form.functionName"
                      label={<IntlMessageComponent id="form.functionName" />}
                      error={!!props.errors.get('functionName')}
                      helperText={props.errors.get('functionName') ?
                        <IntlMessageComponent id={props.errors.get('functionName')} /> : ''}
                      value={props.form.functionName}
                      onChange={props.handleChange('functionName')}
                      fullWidth
                      margin="normal"
                      required
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </React.Fragment>
        }

        <Box mt={5}>
          {props.cancelHandler &&
            <ButtonComponent
              color="default"
              variant="contained"
              onClick={props.cancelHandler}
            ><IntlMessageComponent id="general.cancel" /></ButtonComponent>
          }

          <SecurityComponent pageName="POS.OfficePersonDetail" elementName="btnSave">
            <Box component="span" ml={1}>
              <ButtonComponent
                color="primary"
                variant="contained"
                isProcessing={props.isProcessing}
                onClick={props.submitForm}
              ><IntlMessageComponent id="general.submit" /></ButtonComponent>

              <MandatoryFormFieldsComponent/>
            </Box>
          </SecurityComponent>
        </Box>
      </Box>
    </Paper>
  );
}