import React from 'react';
import ReactDOM from 'react-dom';

import 'styles/app.scss';

import {isIE} from 'react-device-detect';
import EdebexAppBase from './baseApp';

if (isIE) {
  document.getElementById('outdated').style.display = 'inline';
} else {
  ReactDOM.render(<EdebexAppBase />, document.getElementById('app-site'));
}
