/* @flow */


import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';
import MandatoryFormFieldsComponent from 'components/form/mandatory';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import UploadFieldComponent from 'components/formFields/upload';

type RegisterSellerCompanyTab4ViewComponentProps = {
  documents: Document[];
  isProcessing: boolean;
  handleDocumentSelect: () => void;
  handleDocumentRemove: () => void;
  addDocument: () => void;
  submitForm: () => void;
}

export default function RegisterSellerCompanyTab4ViewComponent(props: RegisterSellerCompanyTab4ViewComponentProps) {

  return (
    <React.Fragment>
      <Box mt={5}>
        <PageSubTitleViewComponent id="page.iso.company.register.tab3.h2" data-walkme="registerseller4-title-documents" />
      </Box>

      <Box mt={3}>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={6}>
          {props.documents.map((doc) => (doc.isProvided === false) && (
            <Grid item xs={12} md={6} lg={3} key={`doc-${doc.id}`}>
              <UploadFieldComponent
                disabled={props.isProcessing}
                displayProps={{margin: 'normal', fullWidth: true}}
                filename={doc.filename}
                isMissing={doc.isMissing}
                isRequired={doc.isRequired}
                label={`document.type.${doc.type.code}`}
                successHandler={props.handleDocumentSelect(doc.id)}
                removeHandler={props.handleDocumentRemove(doc.id)}
              />
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box mt={3}>
        <ButtonComponent
          color="secondary"
          variant="contained"
          disabled={props.isProcessing}
          onClick={props.addDocument}
        ><IntlMessageComponent id="general.button.addDocument" /></ButtonComponent>
      </Box>

      <Box mt={5}>
        <ButtonComponent
          color="primary"
          variant="contained"
          isProcessing={props.isProcessing}
          onClick={props.submitForm}
        ><IntlMessageComponent id="general.continue" /></ButtonComponent>

        <MandatoryFormFieldsComponent/>
      </Box>
    </React.Fragment>
  );
}
