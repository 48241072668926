/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Rating from '@material-ui/lab/Rating';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import IconSquare from 'components/icons/square';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import PercentageFormatterComponent from 'components/formatters/percentage';

import { kpiData } from './data';
import TooltipComponent from 'components/toolTips/index';

type BuyerKPIsViewComponentProps = {
  kpis: KPI[];
}

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.blue.main
  }
}));

export default function BuyerKPIsViewComponent(props: BuyerKPIsViewComponentProps) {
  const classes = useStyles();

  const breakpoints = {
    0: { items: 1 },
    680: { items: 2 },
    1000: { items: 3 },
    1200: { items: 4 },
    1400: { items: 5 }
  };
  const items = [];
  props.kpis.forEach(kpi => {
    const cardInfo = kpiData.find(d => d.name === kpi.name);
    items.push(
      <Box p={3} justifyContent='center'>
        <Typography variant="body1" gutterBottom>
          <TooltipComponent pageName="Tooltip.Dashboard.KPI" elementName={cardInfo.translationKey}>
            <IntlMessageComponent id={cardInfo.translationKey} data-walkme={kpi.walkme} />
          </TooltipComponent>
        </Typography>
        <Rating
          emptyIcon={<IconSquare color="disabled" fontSize="inherit" />}
          icon={<IconSquare className={classes.icon} fontSize="inherit" />}
          max={10}
          readOnly
          value={Math.floor(kpi.value / 10)}
        />

        <Typography variant="body1">
          <Box component="span" fontSize="min(4vw, 30px);" fontWeight="fontWeightBold" data-walkme={cardInfo.walkme}>
            <PercentageFormatterComponent value={kpi.value} noFractionDigits />
          </Box>
        </Typography>
      </Box>
    );
  });
  return (
    <React.Fragment>
      <PageSubTitleViewComponent id="buyer.kpis.title" data-walkme="bdashboard-title-votrereactivite" />

      <Box mt={3}>
        <Paper elevation={0}>
          <div className="hiddenMobile">
            <Box>
              <Grid container direction="row" justify="flex-start" alignItems="stretch" spacing={1}>
                {items.map(item => {
                  return (
                    <Grid item xs={12} sm={6} md={3}>
                      {item}
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </div>
          <div className="hiddenDesktop">
            <Box mt={3}>
              <AliceCarousel disableButtonsControls items={items} mouseTracking responsive={breakpoints} />
            </Box>
          </div>
        </Paper>
      </Box>
    </React.Fragment>
  );
}
