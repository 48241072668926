/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import * as reactGA from 'lib/analytics';

import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

import IntlMessageComponent from 'components/formatters/intlMessage';

import RegisterService from 'services/registerService';

type SubscriptionTab5GCSuccessComponentProps = {
  successHandler: () => void,
  activeCompanyId: number
}

class SubscriptionTab5GCSuccessComponent extends React.PureComponent<SubscriptionTab5GCSuccessComponentProps> {

  componentDidMount = async () => {
    reactGA.initializeGA();
    reactGA.logEvent('Subscription', 'General Conditions Signed', 'Company ID: ' + this.props.activeCompanyId);

    this.interval = setInterval(async () => {
      try {
        const transaction = await RegisterService.getTosTransactionStatus(this.props.activeCompanyId, this.props.activeCompanyRole);
        if (transaction.isSigned) {
          clearInterval(this.interval);
          this.props.successHandler();
        }
      } catch (e) {
        console.error(e);
        clearInterval(this.interval);
      }
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <Box height="100%" textAlign="center">
        <CircularProgress size={60} />
        <Box mt={1}>
          <strong><IntlMessageComponent id="universign.loading" /></strong>
        </Box>
      </Box>
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id,
  activeCompanyRole: state.auth.user.activeCompany.role
});

export default connect(mapStateToProps)(SubscriptionTab5GCSuccessComponent);
