/* @flow */

import React from 'react';

import DebtorDashboardComponent from 'app/debtors/dashboard';
import WebsiteDashboardPageComponent from 'components/websiteStructure/pages/dashboard';

export default function DebtorDashboardPage() {
  return (
    <WebsiteDashboardPageComponent id="debtor.dashboard.title">
      <DebtorDashboardComponent />
    </WebsiteDashboardPageComponent>
  );
}
