/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';

import IntlMessageComponent from 'components/formatters/intlMessage';

type MandatoryNotInlineFormFieldsComponentProps = {
  id: string;
  values: Any;
}

export default function MandatoryNotInlineFormFieldsComponent(props: MandatoryNotInlineFormFieldsComponentProps) {
  const id = props.id ?? 'form.mandatoryFields';
  
  return (
    <Box component="div" color="text.secondary" fontSize={12} fontStyle="italic">
      <IntlMessageComponent id={id} values={props.values} />
    </Box>
  );
}