/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';

import IntlMessageComponent from 'components/formatters/intlMessage';

type FormErrorsComponentProps = {
  formErrors: string[],
  displaySmall: boolean
}

export default function FormErrorsComponent(props: FormErrorsComponentProps) {
  
  if (!props.formErrors || props.formErrors.length <= 0)
    return null;
  
  if (props.displaySmall) {
    return (
      <Box component="span" color="error.main">
        <IntlMessageComponent id={props.formErrors[0]} />
      </Box>
    );
  }

  return (
    <Box mb={3} p={2} pt={3} border={1} bgcolor="error.main" color="white">
      <ul>
        {props.formErrors.map((e, i) => (
          <li key={`formError-${i}`}><IntlMessageComponent id={e} /></li>
        ))}
      </ul>
    </Box>
  );
}
