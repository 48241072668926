import React from 'react';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import ButtonComponent from 'components/buttons/button';
import IconExclamationMarkOutlined from 'components/icons/outlined/exclamationMark';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PageTitleViewComponent from 'components/pageTitle';

export default function SignInBlockedComponent() {
  const onClick = () => window.location.href = 'mailto:support@edebex.com';

  return (
    <Box mx="auto" maxWidth="350px">
      <Paper elevation={0} square>
        <Box p={4} textAlign="center">
          <PageTitleViewComponent id="page.login.blocked.title" />

          <Box color="error.main" mt={4}>
            <IconExclamationMarkOutlined fontSize="large" />
          </Box>

          <Box mt={4}>
            <strong><IntlMessageComponent id="page.login.blocked.description1" /></strong>
            <br />
            <IntlMessageComponent id="page.login.blocked.description2" />
          </Box>

          <Box mt={4}>
            <ButtonComponent
              color="primary"
              variant="contained"
              onClick={onClick}
              fullWidth
            >support@edebex.com</ButtonComponent>
          </Box>

          <Box mt={3}>
            <strong><a href="tel:+3222454545">+32 2 245 45 45</a></strong>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}
