/* @flow */

import React from 'react';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

import IntlMessageComponent from 'components/formatters/intlMessage';
import ButtonComponent from 'components/buttons/button';

import DeleteCompanyBankAccountRequest from 'models/requests/companies/deleteCompanyBankAccountRequest';

import CompanyService from 'services/CompanyService';
import { handleApiFormResponse } from 'lib/forms';

type CompanyBankACcountsConfirmDeleteComponentProps = {
  activeCompanyId: number;
  bankAccountId: number;
  onClose: () => void;
  onSuccess: () => void;
}

type CompanyBankACcountsConfirmDeleteComponentState = {
  isProcessing: boolean;
}

class CompanyBankACcountsConfirmDeleteComponent extends React.Component<CompanyBankACcountsConfirmDeleteComponentProps, CompanyBankACcountsConfirmDeleteComponentState> {

  constructor(props) {
    super(props);
    this.state = {
      form: null,
      formErrors: [],
      isProcessing: false,
      step: 1
    };
  }

  onSubmit = async () => {
    try {
      this.setState({ isProcessing: true });
      await this.deleteBankAccount();
      await this.props.onSuccess();
    } catch (e) {
      console.error(e);
      handleApiFormResponse.call(this, e);
    }
  }

  deleteBankAccount = async () => {
    const request = new DeleteCompanyBankAccountRequest(this.props.activeCompanyId, this.props.bankAccountId);
    await CompanyService.deleteCompanyBankAccount(request);
  };

  render() {
    return (
      <Dialog open onClose={this.props.onClose} fullWidth maxWidth="sm">
        <DialogTitle>
          <IntlMessageComponent id="component.company.bankAccounts.popup.delete" />
        </DialogTitle>

        {this.state.isProcessing &&
          <DialogContent>
            <Box align="center" p={5}>
              <CircularProgress size={60} />
            </Box>
          </DialogContent>
        }

        {!this.state.isProcessing &&
          <React.Fragment>
            <DialogContent>
              <IntlMessageComponent id="component.company.bankAccounts.popup.deleteconfirm" />
            </DialogContent>
            <DialogActions>
              <ButtonComponent
                color="default"
                onClick={this.props.onClose}
              ><IntlMessageComponent id="general.cancel" /></ButtonComponent>

              <ButtonComponent
                color="primary"
                onClick={this.onSubmit}
              ><IntlMessageComponent id="general.submit" /></ButtonComponent>
            </DialogActions>
          </React.Fragment>
        }


      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps)(CompanyBankACcountsConfirmDeleteComponent);
