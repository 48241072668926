/* @flow */

import React from 'react';
import Typography from '@material-ui/core/Typography';
import IntlMessageComponent from 'components/formatters/intlMessage';

type PageSubTitleViewComponentProps = {
  id: string;
  values: any;
}

export default function PageSubTitleViewComponent(props: PageSubTitleViewComponentProps) {
  return (
    <Typography variant="h2" gutterBottom>
      <IntlMessageComponent id={props.id} values={props.values} data-walkme={props['data-walkme'] || ''} />
    </Typography>
  );
}
