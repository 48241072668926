/* @flow */

import React, {useState} from 'react';

import DebtorRegisterSupplierTab1Component from './tab1';
import DebtorRegisterSupplierTab2ViewComponent from './tab2';

export default function DebtorRegisterSupplierComponent() {
  const [step, setStep] = useState(1);

  const successHandlerTab1 = () => setStep(2);

  if (step === 1) {
    return (<DebtorRegisterSupplierTab1Component successHandler={successHandlerTab1} />);
  }

  if (step === 2) {
    return (<DebtorRegisterSupplierTab2ViewComponent />);
  }
}
