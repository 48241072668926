/* @flow */

import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import IconMarketPlaceDelegatedInsurance from 'components/icons/marketPlaceDelegatedInsurance';
import IconMarketPlaceInsured from 'components/icons/marketPlaceInsured';
import IconMarketPlaceNotInsured from 'components/icons/marketPlaceNotInsured';
import IconMarketPlacePublic from 'components/icons/marketPlacePublic';
import IconMarketPlaceEdof from 'components/icons/marketPlaceEdof';
import IconMarketPlaceConfirming from 'components/icons/marketPlaceConfirming';
import IntlMessageComponent from 'components/formatters/intlMessage';

import {
  MARKETPLACE_INSURED,
  MARKETPLACE_NOT_INSURED,
  MARKETPLACE_DELEGATED_INSURED,
  MARKETPLACE_PUBLIC,
  MARKETPLACE_EDOF,
  MARKETPLACE_CONFIRMING
} from 'constants/constants';

type InvoiceMarketPlaceComponentProps = {
  marketPlaceId: number;
}

export default function InvoiceMarketPlaceComponent(props: InvoiceMarketPlaceComponentProps) {
  if (!props.marketPlaceId) return null;

  let icon = null;
  let tooltip = null;

  switch (props.marketPlaceId) {
    case MARKETPLACE_INSURED:
      icon = <IconMarketPlaceInsured style={{ color: '#9c3' }} />
      tooltip = <IntlMessageComponent id="general.marketPlace.insured.tooltip" />
      break;
    case MARKETPLACE_NOT_INSURED:
      icon = <IconMarketPlaceNotInsured />
      tooltip = <IntlMessageComponent id="general.marketPlace.notInsured.tooltip" />
      break;
    case MARKETPLACE_DELEGATED_INSURED:
      icon = <IconMarketPlaceDelegatedInsurance style={{ color: '#9c3' }} />
      tooltip = <IntlMessageComponent id="general.marketPlace.delegatedInsurance.tooltip" />
      break;
    case MARKETPLACE_PUBLIC:
      icon = <IconMarketPlacePublic style={{ color: '#3cf' }} />
      tooltip = <IntlMessageComponent id="general.marketPlace.public.tooltip" />
      break;
    case MARKETPLACE_EDOF:
      icon = <IconMarketPlaceEdof style={{ color: '#9c3' }} />
      tooltip = <IntlMessageComponent id="general.marketPlace.edof.tooltip" />
      break;
    case MARKETPLACE_CONFIRMING:
      icon = <IconMarketPlaceConfirming style={{ color: '#9c3' }} />
      tooltip = <IntlMessageComponent id="general.marketPlace.confirming.tooltip" />
      break;
    default:
      icon = null;
      tooltip = null;
  }

  return (
    <Tooltip title={tooltip} style={{ cursor: 'default' }}>
      <IconButton size="small">
        {icon}
      </IconButton>
    </Tooltip>
  );
}
