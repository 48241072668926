import React, {Component} from 'react';
import Dropzone from 'react-dropzone';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';

import IconTrash from 'components/icons/trash';
import IntlMessageComponent from 'components/formatters/intlMessage';

type UploadFieldComponentProps = {
  disabled: boolean;
  displayProps: any;
  filename: string;
  isMissing: boolean;
  isRequired: boolean;
  disableRemove: Boolean;
  label: string;
  successHandler: (file: File) => void;
  removeHandler: () => void;
}

type UploadFieldComponentState = {
  error: boolean;
  filename: string;
}

class UploadFieldComponent extends Component<UploadFieldComponentProps, UploadFieldComponentState> {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      filename: props.filename || ''
    };
  }

  onDrop = (acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length === 1) {
      this.setState({error: false, filename: acceptedFiles[0].name});
      this.props.successHandler(acceptedFiles[0]);
    } else {
      this.setState({error: true, filename: rejectedFiles[0].name});
    }
  };

  handleRemoveDocumentField = (event) => {
    event.stopPropagation();
    this.setState({error: false, filename: ''});
    this.props.removeHandler();
  }

  render() {
    let helperText = <IntlMessageComponent id="general.file.accept" values={{accept: "application/pdf, image/jpeg, image/png", size: 5}} />;
    if (this.state.error) helperText = <IntlMessageComponent id="form.file.wrongFormat" />;
    else if (this.props.isMissing) helperText = <IntlMessageComponent id="form.file.missing" />;

    return (
      <Dropzone
        accept="application/pdf, image/jpeg, image/png"
        disabled={this.props.disabled}
        maxSize={5242880}
        noDrag
        noKeyboard
        onDrop={this.onDrop}
        style={{borderColor: '#ffffff'}}
      >
        {({getRootProps, getInputProps}) => (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <TextField
              readOnly
              required={this.props.isRequired}
              label={<IntlMessageComponent id={this.props.label} />}
              error={this.state.error || this.props.isMissing}
              helperText={helperText}
              value={this.state.filename}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {!this.props.isRequired && !this.props.disableRemove &&
                      <Tooltip title={<IntlMessageComponent id="form.file.remove" />}>
                        <IconButton onClick={this.handleRemoveDocumentField}>
                          <Box component="span" color="red.main">
                            <IconTrash />
                          </Box>
                        </IconButton>
                      </Tooltip>
                    }
                  </InputAdornment>
                )}}
              {...this.props.displayProps}
            />
          </div>
        )}
      </Dropzone>
    );
  }
}

export default UploadFieldComponent;
