/* @flow */

import React from 'react';

import EligibilityQuestionViewComponent from './view';

import EligibilityQuestionModel from 'models/leads/eligibilityQuestion';
import SetEligibilityAnswerRequest from 'models/requests/leads/setEligibilityAnswerRequest';

import LeadService from 'services/LeadService';

import NotificationHelper from 'lib/notifications';

type EligibilityQuestionComponentProps = {
  leadId: number;
  question: EligibilityQuestionModel;
  successHandler: () => void;
  backHandler: () => void;
};

type EligibilityQuestionComponentState = {
  answerIds: number[];
  isProcessing: boolean;
};

class EligibilityQuestionComponent extends React.Component<EligibilityQuestionComponentProps, EligibilityQuestionComponentState> {
  constructor(props) {
    super(props);

    let answerIds = props.question.answers.filter(a => a.isSelected).map(a => a.id);

    this.state = {
      answerIds,
      isProcessing: false
    };
  }

  handleChange = (answerId: number) => {
    let answerIds = this.state.answerIds;

    if (answerIds.find(a => a === answerId)) {
      answerIds = answerIds.filter(a => a !== answerId);
    } else {
      answerIds.push(answerId);
    }
    
    this.setState({answerIds});
  }

  submitForm = async () => {
    if (this.state.answerIds.length === 0) return;
    this.setState({isProcessing: true});

    try {
      const request = new SetEligibilityAnswerRequest(this.props.leadId, this.props.question.id, this.state.answerIds);
      await LeadService.setEligibilityAnswer(request);
      this.props.successHandler(this.state.answerIds);
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
      this.setState({isProcessing: false});
    }
  }

  render() {
    return (
      <EligibilityQuestionViewComponent
        answerIds={this.state.answerIds}
        isProcessing={this.state.isProcessing}
        question={this.props.question}
        backHandler={this.props.backHandler}
        handleChange={this.handleChange}
        submitForm={this.submitForm}
      />
    );
  }
}

export default EligibilityQuestionComponent;
