/* @flow */

import React from 'react';
import {useSelector} from 'react-redux';

type LinkMailToConstantComponentProps = {
  id: string;
}

export default function LinkMailToConstantComponent(props: LinkMailToConstantComponentProps) {
  const constants = useSelector(state => state.locale.constants);

  const constant = constants.find(c => c.key === props.id);
  if (!constant) throw new Error(`Missing constants: ${props.id}`);

  return <a href={`mailto:${constant.value}`}>{`${constant.value}`}</a>;
}