/* @flow */

import moment from 'moment';

import InvoiceActiveFilters from 'models/buyers/invoices/invoiceActiveFilters';

export default class DownloadPaymentReportRequest {
  companyId: number;
  investmentProfileId: number;
  format: string;
  dateFrom: moment;
  dateTo: moment;

  constructor(companyId: number, investmentProfileId: number, format: string, activeFilters: InvoiceActiveFilters) {
    this.companyId = companyId;
    this.investmentProfileId = investmentProfileId;
    this.format = format;
    this.dateFrom = activeFilters.paymentReportDateFrom;
    this.dateTo = activeFilters.paymentReportDateTo;
  }

  toRouteParameters() {
    let route = `companyId=${this.companyId}&investmentProfileId=${this.investmentProfileId}&format=${this.format}`;
    if (this.dateFrom) {
      route += `&dateFrom=${this.dateFrom.format('YYYY-MM-DD')}`;
    } 
    if (this.dateTo) {
      route += `&dateTo=${this.dateTo.format('YYYY-MM-DD')}`;
    }
    return route;
  }
}
    