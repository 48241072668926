/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import Box from '@material-ui/core/Box';

import IntlMessageComponent from 'components/formatters/intlMessage';
import LeadStatusComponent from 'components/leads/status';
import LoadingComponent from 'components/loading';

import LeadFiltersComponent from './filters';
import LeadTableComponent from './table';

import LeadFilters from 'models/isos/leads/leadFilters';
import Filter from 'models/isos/filters/filter';
import FilterCompany from 'models/isos/filters/filterCompany';
import FilterOffice from 'models/isos/filters/filterOffice';

import LeadReport from 'models/isos/leads/leadReport';

import DownloadLeadReportRequest from 'models/requests/isos/downloadLeadReportRequest';
import SendLeadToEdebexRequest from 'models/requests/isos/sendLeadToEdebexRequest';

import IsoService from 'services/IsoService';
import NotificationHelper from 'lib/notifications';

type ISOLeadsComponentProps = {
  activeCompanyId: number;
  filterByLeadsToClean: boolean;
  leadStatus: { id: number, name: string }[];
}

type ISOLeadsComponentState = {
  filters: LeadFilters[];
  isLoading: boolean;
  leads: LeadReport[];
}

class ISOLeadsComponent extends React.Component<ISOLeadsComponentProps, ISOLeadsComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      filters: null,
      isLoading: true,
      leads: null
    };
  }

  componentDidMount = async () => {
    try {
      const leads = await IsoService.getLeadReport(this.props.activeCompanyId);
      const filters = this.createFilterModel(leads);
      this.setState({
        filters,
        leads,
        isLoading: false
      });
    } catch (e) {
      console.error(e);
    }
  }

  getFilteredData = () => {
    let filteredLeads = this.state.leads;

    // Apply company/office/person/other filtering
    const filters = this.filterToObject();
    filteredLeads = this.multiFilter(filteredLeads, filters);

    // Apply period filtering - creationDate
    if (this.state.filters.creationDate.dateFrom !== null && this.state.filters.creationDate.dateTo !== null) {
      filteredLeads = filteredLeads.filter((l) => {
        const filterPeriod = moment(l.creationDate);
        return filterPeriod.isSameOrAfter(this.state.filters.creationDate.dateFrom, 'day') && filterPeriod.isSameOrBefore(this.state.filters.creationDate.dateTo, 'day');
      });
    }

    // Apply period filtering - closedDate
    if (this.state.filters.closedDate.dateFrom !== null && this.state.filters.closedDate.dateTo !== null) {
      filteredLeads = filteredLeads.filter((l) => {
        if (!l.closedDate) return false;
        const filterPeriod = moment(l.closedDate);
        return filterPeriod.isSameOrAfter(this.state.filters.closedDate.dateFrom, 'day') && filterPeriod.isSameOrBefore(this.state.filters.closedDate.dateTo, 'day');
      });
    }

    // Apply open closed lead filters
    if (this.state.filters.openClosedLeads.find(f => !f.isSelected) !== undefined) {
      if (this.state.filters.openClosedLeads.find(f => f.id == 0).isSelected){
        filteredLeads = filteredLeads.filter((i) => {
          if (!this.isOpenLeadStatus(i.statusId))
            return true;
        });
      }
      if (this.state.filters.openClosedLeads.find(f => f.id == 1).isSelected){
        filteredLeads = filteredLeads.filter((i) => {
          if (this.isOpenLeadStatus(i.statusId))
            return true;
        });
      }
    }    

    return filteredLeads;
  }

  refreshData = async () => {
    this.setState({ isLoading: true, leads: null });

    try {
      const leads = await IsoService.getLeadReport(this.props.activeCompanyId);
      this.setState({
        leads,
        isLoading: false
      });
    } catch (e) {
      console.error(e);
    }
  }

  isOpenLeadStatus = (statusId: number) => {
    switch (statusId) {
      case 1899:
      case 3881:
      case 1900:
      case 1901:
      case 1902:
      case 1903:
      case 1904:
      case 1905:
      case 1907:
        return true;
      default: 
        return false;
    }
  }

  statusSelectedForFilterByLeadsToClean = (statusId: number) => {
    switch (statusId) {
      case 1899:
      case 3881:
      case 1900:
      case 1901:
      case 1902:
      case 1903:
      case 1904:
      case 1905:
      case 1907:
        return true;
      default: 
        return false;
    }
  }

  createFilterModel = (data: any) => {
    const filters = new LeadFilters();

    for (let i = 0; i < data.length; i++) {
      let isoCompany = filters.companies.find(c => c.id === data[i].filterISOId);
      if (isoCompany === undefined) {
        let isoCompanyName = data[i].isoCompany.length === 0 ? <IntlMessageComponent id="general.filters.unspecifiedcompany" /> : data[i].isoCompany;
        isoCompany = new FilterCompany(data[i].filterISOId, isoCompanyName);
        if (this.props.filterByLeadsToClean && isoCompany.id === this.props.activeCompanyId) {
          isoCompany.isSelected = true;
        }
        filters.companies.push(isoCompany);
      }

      let isoOffice = isoCompany.offices.find(o => o.id === data[i].filterISOOfficeId);
      if (isoOffice === undefined) {
        let isoOfficeName = data[i].isoOffice.length === 0 ? <IntlMessageComponent id="general.filters.unspecifiedoffice" /> : data[i].isoOffice;
        isoOffice = new FilterOffice(data[i].filterISOOfficeId, isoOfficeName);
        isoCompany.offices.push(isoOffice);
      }

      let isoPerson = isoOffice.persons.find(p => p.id === data[i].filterISOPersonId);
      if (isoPerson === undefined) {
        let isoPersonName = data[i].isoPerson.length === 0 ? <IntlMessageComponent id="general.filters.unspecifiedperson" /> : data[i].isoPerson;
        isoPerson = new Filter(data[i].filterISOPersonId, isoPersonName);
        isoOffice.persons.push(isoPerson);
      }
    }

    // status
    for (let i = 0; i < this.props.leadStatus.length; i++) {
      const statusFilter = new Filter(this.props.leadStatus[i].id, <LeadStatusComponent id={this.props.leadStatus[i].id} />);
      if (this.props.filterByLeadsToClean && this.statusSelectedForFilterByLeadsToClean(statusFilter.id)) {
        statusFilter.isSelected = true;
      }
      filters.status.push(statusFilter);
    }

    // owner - Hardcoded filter
    let managedByISOFilter = new Filter(0, <IntlMessageComponent id="general.leads.owner.iso" />);
    if (this.props.filterByLeadsToClean) managedByISOFilter.isSelected = true;
    filters.owners.push(managedByISOFilter);
    filters.owners.push(new Filter(1, <IntlMessageComponent id="general.leads.owner.Edebex" />));

    // convertible - Hardcoded filter
    filters.convertible.push(new Filter(1, <IntlMessageComponent id="general.leads.convertible.yes" />));
    filters.convertible.push(new Filter(0, <IntlMessageComponent id="general.leads.convertible.no" />));

    // lost reasons
    for (let i = 0; i < data.length; i++) {
      const lostReason = filters.lostReasons.find(lr => lr.id === data[i].lostReasonId);
      if (lostReason === undefined && data[i].lostReasonId !== 0) {
        filters.lostReasons.push(new Filter(data[i].lostReasonId, <IntlMessageComponent id={`leadlostreason${data[i].lostReasonId}`} />));
      }
    }
    filters.lostReasons = filters.lostReasons.sort((a, b) => (a.id < b.id ? -1 : 1));

    // open closed leads
    filters.openClosedLeads.push(new Filter(1, <IntlMessageComponent id="general.leads.openclosed.yes" />));
    filters.openClosedLeads.push(new Filter(0, <IntlMessageComponent id="general.leads.openclosed.no" />));

    return filters;
  }

  filterToObject = () => {
    const filters = {
      filterISOId: [],
      filterISOOfficeId: [],
      filterISOPersonId: [],
      statusId: [],
      ownerClass: [],
      convertible: [],
      lostReasonId: [],
      openClosedLeads: []
    };

    for (let i = 0; i < this.state.filters.companies.length; i++) {
      if (this.state.filters.companies[i].isSelected) {
        filters.filterISOId.push(this.state.filters.companies[i].id);
      }

      for (let j = 0; j < this.state.filters.companies[i].offices.length; j++) {
        if (this.state.filters.companies[i].offices[j].isSelected) {
          filters.filterISOOfficeId.push(this.state.filters.companies[i].offices[j].id);
        }

        for (let k = 0; k < this.state.filters.companies[i].offices[j].persons.length; k++) {
          if (this.state.filters.companies[i].offices[j].persons[k].isSelected) {
            filters.filterISOPersonId.push(this.state.filters.companies[i].offices[j].persons[k].id);
          }
        }
      }
    }

    for (let i = 0; i < this.state.filters.status.length; i++) {
      if (this.state.filters.status[i].isSelected) {
        filters.statusId.push(this.state.filters.status[i].id);
      }
    }

    // ISO, Edebex, KPD
    for (let i = 0; i < this.state.filters.owners.length; i++) {
      if (this.state.filters.owners[i].isSelected) {
        if (this.state.filters.owners[i].id === 0) filters.ownerClass.push('ISO');
        else if (this.state.filters.owners[i].id === 1) filters.ownerClass.push('Edebex');
        else filters.ownerClass.push('KPD');
      }
    }

    // Yes No
    for (let i = 0; i < this.state.filters.convertible.length; i++) {
      if (this.state.filters.convertible[i].isSelected) {
        if (this.state.filters.convertible[i].id === 0) filters.convertible.push('No');
        else filters.convertible.push('Yes');
      }
    }

    for (let i = 0; i < this.state.filters.lostReasons.length; i++) {
      if (this.state.filters.lostReasons[i].isSelected) {
        filters.lostReasonId.push(this.state.filters.lostReasons[i].id);
      }
    }

    return filters;
  }

  multiFilter = (array, filters) => {
    const filterKeys = Object.keys(filters);
    // filters all elements passing the criteria
    return array.filter(item => filterKeys.every((key) => {
      // ignores an empty filter
      if (!filters[key].length) return true;
      return filters[key].includes(item[key]);
    }));
  }

  selectFilter = (filterId: string, optionId: number) => {
    const filters = this.state.filters;
    for (let i = 0; i < filters[filterId].length; i++) {
      if (filters[filterId][i].id === optionId) {
        filters[filterId][i].isSelected = !filters[filterId][i].isSelected;
      }
    }
    if (filterId === 'openClosedLeads') {
      for (let i = 0; i < filters['status'].length; i++) {
        filters['status'][i].isSelected = false;
      }
    }
    this.setState({ filters });
  }

  selectFilterISO = (filterId: string, optionId: number) => {
    const filters = this.state.filters;

    if (filterId === 'company') {
      for (let i = 0; i < filters.companies.length; i++) {
        if (filters.companies[i].id === optionId) {
          filters.companies[i].isSelected = !filters.companies[i].isSelected;

          if (!filters.companies[i].isSelected) {
            // if we unselect a company, unselect all its offices & persons
            for (let j = 0; j < filters.companies[i].offices.length; j++) {
              filters.companies[i].offices[j].isSelected = false;
              for (let k = 0; k < filters.companies[i].offices[j].persons.length; k++) {
                filters.companies[i].offices[j].persons[k].isSelected = false;
              }
            }
          }
        }
      }
    }

    if (filterId === 'office') {
      for (let i = 0; i < filters.companies.length; i++) {
        for (let j = 0; j < filters.companies[i].offices.length; j++) {
          if (filters.companies[i].offices[j].id === optionId) {
            filters.companies[i].offices[j].isSelected = !filters.companies[i].offices[j].isSelected;

            if (filters.companies[i].offices[j].isSelected) {
              // if we select an office, select its company
              filters.companies[i].isSelected = true;
            } else {
              // if we unselect an office, unselect all its persons
              for (let k = 0; k < filters.companies[i].offices[j].length; k++) {
                filters.companies[i].offices[j].persons[k].isSelected = false;
              }
            }
          }
        }
      }
    }

    if (filterId === 'person') {
      for (let i = 0; i < filters.companies.length; i++) {
        for (let j = 0; j < filters.companies[i].offices.length; j++) {
          for (let k = 0; k < filters.companies[i].offices[j].persons.length; k++) {
            if (filters.companies[i].offices[j].persons[k].id === optionId) {
              filters.companies[i].offices[j].persons[k].isSelected = !filters.companies[i].offices[j].persons[k].isSelected;

              if (filters.companies[i].offices[j].persons[k].isSelected) {
                // if we select a person, select its company and office
                filters.companies[i].isSelected = true;
                filters.companies[i].offices[j].isSelected = true;
              }
            }
          }
        }
      }
    }

    this.setState({ filters });
  }

  selectFilterPeriod = (filterId: string, dateFrom: moment, dateTo: moment) => {
    const filters = this.state.filters;
    filters[filterId].dateFrom = dateFrom;
    filters[filterId].dateTo = dateTo;
    this.setState({ filters });
  }

  downloadLeadReport = async (format: string) => {
    try {
      const request = new DownloadLeadReportRequest(this.props.activeCompanyId, format);

      for (let i = 0; i < this.state.filters.companies.length; i++) {
        if (this.state.filters.companies[i].isSelected) {
          request.isoCompanyIds.push(this.state.filters.companies[i].id);
        }

        for (let j = 0; j < this.state.filters.companies[i].offices.length; j++) {
          if (this.state.filters.companies[i].offices[j].isSelected) {
            request.isoOfficeIds.push(this.state.filters.companies[i].offices[j].id);
          }

          for (let k = 0; k < this.state.filters.companies[i].offices[j].persons.length; k++) {
            if (this.state.filters.companies[i].offices[j].persons[k].isSelected) {
              request.isoPersonIds.push(this.state.filters.companies[i].offices[j].persons[k].id);
            }
          }
        }
      }

      if (this.state.filters.creationDate.dateFrom && this.state.filters.creationDate.dateTo) {
        request.creationDateFrom = this.state.filters.creationDate.dateFrom.format('YYYY-MM-DD');
        request.creationDateTo = this.state.filters.creationDate.dateTo.format('YYYY-MM-DD');
      }

      if (this.state.filters.closedDate.dateFrom && this.state.filters.closedDate.dateTo) {
        request.closedDateFrom = this.state.filters.closedDate.dateFrom.format('YYYY-MM-DD');
        request.closedDateTo = this.state.filters.closedDate.dateTo.format('YYYY-MM-DD');
      }

      this.state.filters.status.forEach(filter => {
        if (filter.isSelected) request.statusIds.push(filter.id);
      });

      this.state.filters.owners.forEach(filter => {
        if (filter.isSelected) {
          if (filter.id === 0) request.ownerClasses.push('ISO');
          else if (filter.id === 1) request.ownerClasses.push('Edebex');
          else request.ownerClasses.push('KPD');
        }
      });

      this.state.filters.convertible.forEach(filter => {
        if (filter.isSelected) {
          if (filter.id === 0) request.convertibles.push('No');
          else request.convertibles.push('Yes');
        }
      });

      this.state.filters.lostReasons.forEach(filter => {
        if (filter.isSelected) request.lostReasonsIds.push(filter.id);
      });

      this.state.filters.openClosedLeads.forEach(filter => {
        if (filter.isSelected) request.openClosedLeads.push(filter.id);
      })

      await IsoService.downloadLeadReport(request);
    } catch (e) {
      console.error(e);
    }
  }

  clearFilters = () => {
    if (this.state.filters === null) return;

    const filters = this.state.filters;

    for (let i = 0; i < filters.companies.length; i++) {
      filters.companies[i].isSelected = false;
      for (let j = 0; j < filters.companies[i].offices.length; j++) {
        filters.companies[i].offices[j].isSelected = false;
        for (let k = 0; k < filters.companies[i].offices[j].persons.length; k++) {
          filters.companies[i].offices[j].persons[k].isSelected = false;
        }
      }
    }

    filters.creationDate.dateFrom = null;
    filters.creationDate.dateTo = null;
    filters.closedDate.dateFrom = null;
    filters.closedDate.dateTo = null;

    for (let i = 0; i < filters.status.length; i++) {
      filters.status[i].isSelected = false;
    }

    for (let i = 0; i < filters.owners.length; i++) {
      filters.owners[i].isSelected = false;
    }

    for (let i = 0; i < filters.convertible.length; i++) {
      filters.convertible[i].isSelected = false;
    }

    for (let i = 0; i < filters.lostReasons.length; i++) {
      filters.lostReasons[i].isSelected = false;
    }

    this.setState({filters});
  }

  sendToEdebex = async (leadId: number) => {
    try {
      const request = new SendLeadToEdebexRequest(this.props.activeCompanyId, leadId);
      await IsoService.sendLeadToEdebex(request);
      NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS);
      this.refreshData();
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  }

  reopenLead = async (leadId: number) => {
    try {
      const request = new SendLeadToEdebexRequest(this.props.activeCompanyId, leadId);
      await IsoService.reopenLead(request);
      NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS);
      this.refreshData();
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  }

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <React.Fragment>
        <LeadFiltersComponent
          filters={this.state.filters}
          selectFilter={this.selectFilter}
          selectFilterISO={this.selectFilterISO}
          selectFilterPeriod={this.selectFilterPeriod}
          onExportAsked={this.downloadLeadReport}
          clearFilters={this.clearFilters}
        />


        <Box mt={4}>
          <LeadTableComponent
            filterByLeadsToClean={this.props.filterByLeadsToClean}
            leads={this.getFilteredData()}
            refreshData={this.refreshData}
            sendToEdebex={this.sendToEdebex}
            reopenLead={this.reopenLead}
          />
        </Box>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id,
  leadStatus: state.locale.leadStatus.filter(ls => ls.id != 3368)
});

export default connect(mapStateToProps)(ISOLeadsComponent);
