/* @flow */

import moment from 'moment';

export default class Person {
  titleId: number;

  lastName: string;
  firstName: string;

  email: string;
  mobile: string;
  phone: string;

  addressLine1: string;
  addressLine2: string;
  zipCode: string;
  city: string;
  countryId: number;

  nationalityId: number;
  birthCountryId: number;
  birthDate: moment;
  birthPlace: moment;

  registrationTypeId: number;
  registrationNumber: string;
  registrationExpireDate: moment;

  languageId: number;

  personDocumentsToClassifyCount: number;

  privateKey: string;
}
