/* @flow */

import {
  SET_COMPONENT_DATA
} from '../actionTypes';


export const setComponentData = (componentName: string, data: any) => ({
  type: SET_COMPONENT_DATA,
  payload: {componentName, data}
});
