/* @flow */

import SetFinancialInfoRequest from 'models/requests/debtors/setFinancialInfoRequest';

export default class FinancialInfoForm {
  debtorCompanyId: number;
  leadId: number;

  numberOfSuppliers: number;
  yearlyInvoiceAmount: number;
  turnOver: string;

  constructor() {
    this.debtorCompanyId = 0;
    this.leadId = 0;
    this.numberOfSuppliers = 0;
    this.yearlyInvoiceAmount = 0;
    this.turnOver = '';
  }

  toRequest(companyId: number, leadId: number) {
    const request = new SetFinancialInfoRequest();
    request.debtorCompanyId = companyId;
    request.leadId = leadId;
    request.numberOfSuppliers = this.numberOfSuppliers;
    request.yearlyInvoiceAmount = this.yearlyInvoiceAmount;
    request.turnOver = this.turnOver;
    return request;
  }
}
