/* @flow */

import React from 'react';
import { useSelector } from 'react-redux';
import styles from './styles.css'

import Drawer from '@material-ui/core/Drawer';

import BuyerWebsiteMenuComponent from './buyer/index';
import DebtorWebsiteMenuComponent from './debtor/index';
import ISOWebsiteMenuComponent from './iso/index';
import SellerWebsiteMenuComponent from './seller/index';
import ConfirmingWebsiteMenuComponent from './confirming/index';

import { ROLE_BUYER, ROLE_CONFIRMING, ROLE_DEBTOR, ROLE_ISO, ROLE_SELLER } from 'constants/companyRoles';

export default function WebsiteMenuComponent() {
  const activeCompanyRole = useSelector(state => state.auth.user.activeCompany.role);

  const getWalkmePrefix = (activeCompanyRole) => {
    if (activeCompanyRole === ROLE_SELLER) {
      return 's';
    } else if (activeCompanyRole === ROLE_BUYER) {
      return 'b';
    } else if (activeCompanyRole === ROLE_ISO) {
      return 'i';
    } else if (activeCompanyRole === ROLE_DEBTOR) {
      return 'd';
    } return 'u';
  };

  //const isMobile = window.screen.width < 600

  return (
    <div className="hiddenMobile app-sidebar d-none d-flex">
      <Drawer classes={{ paper: 'side-nav' }} variant="permanent">
        <div className="sidenav-vert-spacer" />

        <ul className="nav-menu" data-walkme={`${getWalkmePrefix(activeCompanyRole)}dashboard-sidebar`}>
          {activeCompanyRole === ROLE_BUYER &&
            <BuyerWebsiteMenuComponent />
          }
          {activeCompanyRole === ROLE_DEBTOR &&
            <DebtorWebsiteMenuComponent />
          }
          {activeCompanyRole === ROLE_ISO &&
            <ISOWebsiteMenuComponent />
          }
          {activeCompanyRole === ROLE_SELLER &&
            <SellerWebsiteMenuComponent />
          }
          {activeCompanyRole === ROLE_CONFIRMING &&
            <ConfirmingWebsiteMenuComponent />
          }
        </ul>
      </Drawer>
    </div>
  );
}
