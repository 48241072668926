/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import BusinessIcon from '@material-ui/icons/Business';
import PersonIcon from '@material-ui/icons/Person';
import SmartphoneIcon from '@material-ui/icons/Smartphone';

import IntlMessageComponent from 'components/formatters/intlMessage';
import PageTitleViewComponent from 'components/pageTitle/index';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import ButtonComponent from 'components/buttons/button';
import LeadStatusComponent from 'components/leads/status';

import LeadDetails from 'models/isos/leads/leadDetails';
import Office from 'models/companies/office';
import OfficePerson from 'models/companies/officePerson';
import DateFormatterComponent from 'components/formatters/date';

type LeadDetailsViewComponentProps = {
  leadDetails: LeadDetails;
  offices: Office[];
  officePersons: OfficePerson[];
  editOffice: boolean;
  editPerson: boolean;
  newNote: string;
  form: any;
  editOfficeClick: () => void;
  editPersonClick: () => void;
  editContactClick: () => void;
  handleOfficeChange: () => void;
  handlePersonChange: () => void;
  handleNoteChange: () => void;
  handleContactChange: () => void;
  updateOffice: () => void;
  updatePerson: () => void;
  updateContact: () => void;
  addNote: () => void;
  sendToEdebex: () => void;
}

export default function LeadDetailsViewComponent(props: LeadDetailsViewComponentProps) {

  return (
    <React.Fragment>
      <PageTitleViewComponent id="component.iso.leaddetails.title" backButton />

      <Paper elevation={0}>

        <Box mt={5} pt={3} pl={3} pr={3}>

          <Grid container direction="row" justify="flex-start">
            <Grid item xs={6}>
              <PageSubTitleViewComponent id="component.iso.leaddetais.companyinformation" />
              <Grid container direction="row" justify="flex-start" alignItems="center">
                <Grid item alignItems="center">
                  <Box p={2}>
                    <BusinessIcon color="primary" fontSize="large" />
                  </Box>
                </Grid>
                <Grid item alignItems="center">
                  <Box pt={1} pb={2} textAlign="left">
                    <Typography variant="body1" gutterBottom>
                      <b>{props.leadDetails.leadCompanyName}</b>
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                      {props.leadDetails.leadCompanyVAT}
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                      {props.leadDetails.leadCompanyAddress}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              <PageSubTitleViewComponent id="component.iso.leaddetais.contactinformation" />
              <Grid container direction="row" justify="flex-start" alignItems="center">
                <Grid item alignItems="center">
                  <Box p={2}>
                    <PersonIcon color="primary" fontSize="large" />
                  </Box>
                </Grid>
                <Grid item alignItems="center">
                  <Box pt={1} textAlign="left">
                    <Typography variant="body1" gutterBottom>
                      <b>{props.leadDetails.leadContactFirstName} {props.leadDetails.leadContactLastName}</b>
                    </Typography>
                    {props.editContact ?
                      <React.Fragment>
                        <Typography variant="body1" gutterBottom>
                          <EmailIcon /> <TextField error={!!props.errors.get('email')} helperText={<IntlMessageComponent id="form.email.helperText" />} onChange={props.handleContactChange('email')} value={props.form.email} />
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <PhoneIcon /> <TextField error={!!props.errors.get('phone')} helperText={<IntlMessageComponent id="form.phone.helperText" />} onChange={props.handleContactChange('phone')} value={props.form.phone} />
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <SmartphoneIcon /> <TextField error={!!props.errors.get('mobile')} helperText={<IntlMessageComponent id="form.phone.helperText" />} onChange={props.handleContactChange('mobile')} value={props.form.mobile} />
                        </Typography>
                        <small>&nbsp;
                            <Link to="" component="button" onClick={() => props.updateContact()}>
                            <IntlMessageComponent id="component.iso.leaddetails.updatecontact" />
                          </Link>
                        </small><br />
                        <small>&nbsp;
                            <Link to="" color="textSecondary" component="button" onClick={() => props.editContactClick()}>
                            <IntlMessageComponent id="component.iso.leaddetails.cancel" />
                          </Link>
                        </small>
                      </React.Fragment> :
                      <React.Fragment>
                        <Typography variant="body1" gutterBottom>
                          <EmailIcon /> {props.leadDetails.leadContactEmail}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <PhoneIcon /> {props.leadDetails.leadContactPhoneNumber}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <SmartphoneIcon /> {props.leadDetails.leadContactMobileNumber}
                        </Typography>
                        {!props.leadDetails.reopenedForOtherIso && <React.Fragment><small>&nbsp;
                            <Link to="" component="button" onClick={() => props.editContactClick()}>
                            <IntlMessageComponent id="component.iso.leaddetails.editcontact" />
                          </Link>
                        </small></React.Fragment>}
                      </React.Fragment>
                    }
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <Box textAlign="left" bgcolor="lightblue.dark" p={2}>
                <Typography variant="body1" gutterBottom>
                  <IntlMessageComponent id="component.iso.leaddetails.edebexid" />: {props.leadDetails.leadId}
                </Typography>

                <Typography variant="body1" gutterBottom>
                  <IntlMessageComponent id="component.iso.leaddetails.managedby" />: <IntlMessageComponent id={`general.leads.owner.${props.leadDetails.ownerClass}`} />
                  {props.leadDetails.ownerClass !== "Edebex" && !props.leadDetails.closedDate &&
                    <React.Fragment>
                      <small>&nbsp;
                        <Link to="" component="button" onClick={() => props.sendToEdebex()}>
                          <IntlMessageComponent id="component.iso.leaddetails.sendtoedebex" />
                        </Link>
                      </small>
                    </React.Fragment>
                  }
                </Typography>

                <Typography variant="body1" gutterBottom>
                  <IntlMessageComponent id="component.iso.leaddetails.status" />: <LeadStatusComponent id={props.leadDetails.statusId} />
                </Typography>

                <Typography variant="body1" gutterBottom>
                  <IntlMessageComponent id="component.iso.leaddetails.convertible" /> <IntlMessageComponent id={`general.leads.convertible.${props.leadDetails.convertible}`} /> {props.leadDetails.lostReasonId !== 0 && <React.Fragment>(<IntlMessageComponent id={`leadlostreason${props.leadDetails.lostReasonId}`} />)</React.Fragment>}
                </Typography>
                <br />

                <Typography variant="body1" gutterBottom>
                  {props.editOffice ?
                    <React.Fragment>
                      <IntlMessageComponent id="component.iso.leaddetails.linkedoffice" />:
                      <Select
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        value={props.leadDetails.isoOfficeId}
                        onChange={props.handleOfficeChange}
                        autoWidth
                      >
                        {props.offices.map(office => (
                          <MenuItem value={office.id}>
                            {office.name === "NONE" ? <IntlMessageComponent id="component.iso.leaddetails.nooffice" /> : office.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <small>&nbsp;
                        <Link to="" component="button" onClick={() => props.updateOffice()}>
                          <IntlMessageComponent id="component.iso.leaddetails.updateoffice" />
                        </Link>
                      </small><br />
                      <small>
                        <Link to="" color="textSecondary" component="button" onClick={() => props.editOfficeClick()}>
                          <IntlMessageComponent id="component.iso.leaddetails.cancel" />
                        </Link>
                      </small>
                    </React.Fragment>
                    :
                    <React.Fragment>
                      <IntlMessageComponent id="component.iso.leaddetails.linkedoffice" />: {props.leadDetails.isoOffice ? props.leadDetails.isoOffice : <IntlMessageComponent id="component.iso.leaddetails.nooffice" />}
                      {((props.offices && props.offices.length > 1) || props.leadDetails.isoOffice) && !props.leadDetails.reopenedForOtherIso &&
                        <small>&nbsp;
                          <Link to="" component="button" onClick={() => props.editOfficeClick()}>
                            <IntlMessageComponent id="component.iso.leaddetails.changeoffice" />
                          </Link>
                        </small>
                      }
                    </React.Fragment>
                  }
                </Typography>

                <Typography variant="body1" gutterBottom>
                  {props.editPerson ?
                    <React.Fragment>
                      <IntlMessageComponent id="component.iso.leaddetails.linkedcontact" />:
                      <Select
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        value={props.leadDetails.isoPersonId}
                        onChange={props.handlePersonChange}
                        autoWidth
                      >
                        {props.officePersons.map(officePerson => (
                          <MenuItem value={officePerson.id}>
                            {officePerson.firstName === "NONE" ? <IntlMessageComponent id="component.iso.leaddetails.noperson" /> : <React.Fragment>{officePerson.firstName} {officePerson.lastName}</React.Fragment>}
                          </MenuItem>
                        ))}
                      </Select>
                      <small>&nbsp;
                        <Link to="" component="button" onClick={() => props.updatePerson()}>
                          <IntlMessageComponent id="component.iso.leaddetails.updatePerson" />
                        </Link>
                      </small><br />
                      <small>
                        <Link to="" color="textSecondary" component="button" onClick={() => props.editPersonClick()}>
                          <IntlMessageComponent id="component.iso.leaddetails.cancel" />
                        </Link>
                      </small>
                    </React.Fragment>
                    :
                    <React.Fragment>
                      <IntlMessageComponent id="component.iso.leaddetails.linkedcontact" />: {props.leadDetails.isoPerson ? props.leadDetails.isoPerson : <IntlMessageComponent id="component.iso.leaddetails.noperson" />}
                      {((props.officePersons && props.officePersons.length > 1) || props.leadDetails.isoPerson) && !props.leadDetails.reopenedForOtherIso &&
                        <small>&nbsp;
                          <Link to="" component="button" onClick={() => props.editPersonClick()}>
                            <IntlMessageComponent id="component.iso.leaddetails.changeperson" />
                          </Link>
                        </small>
                      }
                    </React.Fragment>
                  }
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box p={3}>
          <PageSubTitleViewComponent id="component.iso.leaddetais.notes" />
          <Box pt={2}>
            <Grid container direction="row" justify="flex-start" style={{ maxHeight: '200px', overflow: 'auto' }} alignItems="center">
              {props.leadDetails.notes.map(note => (
                <React.Fragment>
                  <Grid container direction="row" justify="flex-start" alignItems="center">
                    <Box pb={1}>
                      <Grid item alignItems="center">
                        <Typography color="textSecondary"><DateFormatterComponent format="DD/MM/YYYY HH:mm" date={note.date} /> - {note.user}</Typography>
                      </Grid>
                      <Grid item alignItems="center">
                        {note.text}
                      </Grid>
                    </Box>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Box>
          {!props.leadDetails.reopenedForOtherIso && <Box pt={2}>
            <IntlMessageComponent id="component.iso.leaddetails.addnote" />
            <Grid container direction="row" justify="flex-start" alignItems="center">
              <TextField
                variant="outlined"
                multiline
                rows={4}
                rowsMax={6}
                fullWidth={true}
                onChange={props.handleNoteChange}
                value={props.newNote}
              />
            </Grid>
          </Box>}
          {!props.leadDetails.reopenedForOtherIso && <Box pt={2}>
            <ButtonComponent
              color="primary"
              variant="contained"
              onClick={props.addNote}
              disabled={!props.newNote || props.newNote.trim().length === 0}
            >
              <IntlMessageComponent id="component.iso.leaddetails.addnotebutton" />
            </ButtonComponent>
          </Box>}
        </Box>
      </Paper>
    </React.Fragment >
  );
}
