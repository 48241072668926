/* @flow */

import React from 'react';
import { connect } from 'react-redux';

import customSort from 'lib/sortHelpers';

import LoadingComponent from 'components/loading';

import CompanyUsersTableViewComponent from './view';
import ActiveFilterComponent from 'components/filters/activeFilter';

import Paper from '@material-ui/core/Paper';

import CompanyUser from 'models/companies/companyUser';
import DisableCompanyUserRequest from 'models/requests/companies/users/disableCompanyUserRequest';

import CompanyService from 'services/CompanyService';
import { handleApiFormResponse } from 'lib/forms';

type CompanyUsersTableComponentProps = {
  activeUserId: number;
  activeCompanyId: number;
  activeCompanyRole: number;
  activeCompanyCanExecute: boolean;
  activeCompanyCanManageUsers: boolean;
  activeCompanyIsActive: boolean;
  activeCompanyHasActiveMembership: boolean;
  activeCompanyIsISO: boolean;
}

type CompanyUsersTableComponentState = {
  companyUsers: CompanyUser[];
  filteredCompanyUsers: CompanyUser[];
  isLoading: boolean;
  order: string;
  orderBy: string;
  page: number;
  pageSize: number;
  isActiveFilter: boolean;
}

class CompanyUsersTableComponent extends React.Component<CompanyUsersTableComponentProps, CompanyUsersTableComponentState> {

  constructor(props) {
    super(props);
    this.state = {
      companyUsers: [],
      isLoading: true,
      order: 'asc',
      orderBy: 'lastName',
      page: 0,
      pageSize: 10,
      isActiveFilter: true
    };
  }

  componentDidMount = async () => {
    try {
      const companyUsers = await CompanyService.getCompanyUsers(this.props.activeCompanyId, this.props.activeCompanyRole);
      const filteredCompanyUsers = companyUsers.filter(companyUser => companyUser.isActive);
      this.setState({ companyUsers, filteredCompanyUsers, isLoading: false });
    } catch (error) {
      console.error(error);
    }
  }

  handlePageChange = (page: number) => this.setState({ page });
  handlePageSizeChange = (pageSize: number) => this.setState({ pageSize });

  handleSortOrderChange = (orderBy: string) => {
    let order = this.state.orderBy === orderBy && this.state.order === 'desc' ? 'asc' : 'desc';

    const filteredCompanyUsers = order === 'desc'
      ? this.state.filteredCompanyUsers.sort((a, b) => customSort(b[orderBy], a[orderBy]))
      : this.state.filteredCompanyUsers.sort((a, b) => customSort(a[orderBy], b[orderBy]));

    this.setState({ filteredCompanyUsers, order, orderBy });
  };

  handleActiveFilterChange = (event) => {
    let filteredCompanyUsers = [];
    if (event.target.value) {
      filteredCompanyUsers = this.state.companyUsers.filter(companyUser => companyUser.isActive);
    } else {
      filteredCompanyUsers = this.state.companyUsers;
    }
    this.setState({ isActiveFilter: event.target.value, filteredCompanyUsers });
  }

  disableActiveUser = async (id: number) => {
    const companyUser = this.state.companyUsers.find(u => u.companyUserId === id);
    if (!companyUser) return null;

    try {
      this.setState({ formErrors: [], isProcessing: true });

      const request = new DisableCompanyUserRequest(this.props.activeCompanyId, id);
      await CompanyService.disableCompanyUser(request);
      companyUser.isActive = false;

      let filteredCompanyUsers = [];
      if (this.state.isActiveFilter) {
        filteredCompanyUsers = this.state.companyUsers.filter(companyUser => companyUser.isActive);
      } else {
        filteredCompanyUsers = this.state.companyUsers;
      }
      this.setState({ filteredCompanyUsers, isProcessing: false });
    } catch (error) {
      handleApiFormResponse.call(this, error);
    }
  }

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <React.Fragment>
        <ActiveFilterComponent
          isActiveFilter={this.state.isActiveFilter}
          handleActiveFilterChange={this.handleActiveFilterChange}
        />
        <CompanyUsersTableViewComponent
          companyUsers={this.state.filteredCompanyUsers}
          order={this.state.order}
          orderBy={this.state.orderBy}
          page={this.state.page}
          pageSize={this.state.pageSize}
          canExecute={this.props.activeCompanyCanExecute}
          canManageUsers={this.props.activeCompanyCanManageUsers}
          isActive={this.props.activeCompanyIsActive}
          activeUserId={this.props.activeUserId}
          hasActiveMembership={this.props.activeCompanyHasActiveMembership}
          isISO={this.props.activeCompanyIsISO}
          handlePageChange={this.handlePageChange}
          handlePageSizeChange={this.handlePageSizeChange}
          handleSortOrderChange={this.handleSortOrderChange}
          disableActiveUser={this.disableActiveUser}
        />
      </React.Fragment>

    );
  }
}

const mapStateToProps = state => ({
  activeUserId: state.auth.user.activeCompany.companyUserId,
  activeCompanyId: state.auth.user.activeCompany.id,
  activeCompanyRole: state.auth.user.activeCompany.role,
  activeCompanyCanExecute: state.auth.user.activeCompany.canExecute,
  activeCompanyCanManageUsers: state.auth.user.activeCompany.canManageUsers,
  activeCompanyIsActive: state.auth.user.activeCompany.isActive,
  activeCompanyHasActiveMembership: state.auth.user.activeCompany.hasActiveMembership,
  activeCompanyIsISO: state.auth.user.activeCompany.isISO
});

export default connect(mapStateToProps)(CompanyUsersTableComponent);
