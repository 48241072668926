/* @flow */

import React from 'react';
import {useHistory, useLocation} from "react-router-dom";

import BestPricingComponent from 'app/sellers/bestPricing';
import WebsitePageComponent from 'components/websiteStructure/pages';

import {PROTECTED_APP} from 'constants/pageRoutes';

export default function LeadBestPricingPage() {
  const history = useHistory();
  const location = useLocation();

  const debtorCompany = location.state?.debtorCompany || null;
  const leadCompanyId = location.state?.leadCompanyId || 0;
  
  if (leadCompanyId === 0) {
    history.push(PROTECTED_APP);
    return;
  }

  return (
    <WebsitePageComponent id="page.bestPricing.h1" data-walkme="lead-bestPricing-title">
      <BestPricingComponent companyId={leadCompanyId} debtorCompany={debtorCompany} isLead={true} />
    </WebsitePageComponent>
  );
}
