/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import {userSwitchCompanyAction} from 'store/actions/authActions';

import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

import IntlMessageComponent from 'components/formatters/intlMessage';

import RegisterService from 'services/registerService';

type TermsAndConditionsSuccessComponentProps = {
  activeCompanyId: number;
  activeCompanyRole: number;
  userSwitchCompanyAction: (number) => void;
}

class TermsAndConditionsSuccessComponent extends React.Component<TermsAndConditionsSuccessComponentProps, {}> {

  componentDidMount = async () => {
    this.interval = setInterval(async () => {
      try {
        const transaction = await RegisterService.getTosTransactionStatus(this.props.activeCompanyId, this.props.activeCompanyRole);
        if (transaction.isSigned) {
          clearInterval(this.interval);
          this.props.userSwitchCompanyAction(this.props.activeCompanyId);
        }
      } catch (e) {
        console.error(e);
        clearInterval(this.interval);
      }
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  interval;

  render() {
    return (
      <Box height="100%" textAlign="center">
        <CircularProgress size={60} />
        <Box mt={1}>
          <strong><IntlMessageComponent id="universign.loading" /></strong>
        </Box>
      </Box>
    );
  }
}

const mapStateToProps = ({auth}) => ({
  activeCompanyId: auth.user.activeCompany.id,
  activeCompanyRole: auth.user.activeCompany.role
});

export default connect(mapStateToProps, {userSwitchCompanyAction})(TermsAndConditionsSuccessComponent);
