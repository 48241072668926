/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import {makeStyles} from '@material-ui/core/styles';

import IconCancelOutlined from 'components/icons/outlined/cancel';
import IconCheckOutlined from 'components/icons/outlined/check';
import IconExclamationMarkOutlined from 'components/icons/outlined/exclamationMark';
import IconHand from 'components/icons/hand';
import IconHourglass from 'components/icons/hourglass';
import IconLabel from 'components/icons/label';
import IconSeller from 'components/icons/seller';
import IconTransferOutlined from 'components/icons/outlined/transfer';
import IntlMessageComponent from 'components/formatters/intlMessage';

import {
  INVOICE_BUYER_TYPE_TO_PAY,
  INVOICE_BUYER_TYPE_TO_BUY_ASSIGNED,
  INVOICE_BUYER_TYPE_TO_BUY_MARKETPLACE,
  INVOICE_BUYER_TYPE_TO_BUY_REFUSED,
  INVOICE_BUYER_TYPE_TO_BUY_WAITING,
  INVOICE_BUYER_TYPE_TO_BUY_OVERDUE,
  INVOICE_BUYER_TYPE_TO_BUY_OVERDUE_FUPBS,
  INVOICE_BUYER_TYPE_TO_BUY_PAID,
  INVOICE_BUYER_TYPE_TO_BUY_CLOSED
} from 'constants/invoiceTypes';

const useStyles = makeStyles(_ => ({
  label: {
    textTransform: 'uppercase'
  }
}));

type BuyerInvoiceStatusViewComponentProps = {
  invoiceType: number;
}

export default function BuyerInvoiceStatusViewComponent(props: BuyerInvoiceStatusViewComponentProps) {
  const classes = useStyles();

  const customize = (invoiceType: number) => {
    switch (invoiceType) {
      case INVOICE_BUYER_TYPE_TO_PAY:
        return { color: 'red.main', icon: <IconExclamationMarkOutlined fontSize="small"/>, translationId: 'component.buyer.invoiceList.status.topay'};
      case INVOICE_BUYER_TYPE_TO_BUY_ASSIGNED:
        return { color: 'blue.main', icon: <IconSeller fontSize="small"/>, translationId: 'component.buyer.invoiceList.status.assigned'};
      case INVOICE_BUYER_TYPE_TO_BUY_MARKETPLACE:
        return { color: 'blue.main', icon: <IconLabel fontSize="small"/>, translationId: 'component.buyer.invoiceList.status.marketplace'};
      case INVOICE_BUYER_TYPE_TO_BUY_WAITING:
        return { color: 'grey.main', icon: <IconHourglass fontSize="small"/>, translationId: 'component.buyer.invoiceList.status.waiting'};
      case INVOICE_BUYER_TYPE_TO_BUY_OVERDUE:
        return { color: 'orange.main', icon: <IconHand fontSize="small"/>, translationId: 'component.buyer.invoiceList.status.overdue'};
      case INVOICE_BUYER_TYPE_TO_BUY_OVERDUE_FUPBS:
        return { color: 'orange.main', icon: <IconHand fontSize="small"/>, translationId: 'component.buyer.invoiceList.status.overdue_fups'};
      case INVOICE_BUYER_TYPE_TO_BUY_PAID:
        return { color: 'green.main', icon: <IconTransferOutlined fontSize="small"/>, translationId: 'component.buyer.invoiceList.status.paid'};
      case INVOICE_BUYER_TYPE_TO_BUY_CLOSED:
        return { color: 'green.main', icon: <IconCheckOutlined fontSize="small"/>, translationId: 'component.buyer.invoiceList.status.closed'};
      case INVOICE_BUYER_TYPE_TO_BUY_REFUSED:
        return { color: 'grey.main', icon: <IconCancelOutlined fontSize="small"/>, translationId: 'component.buyer.invoiceList.status.refused'};
      default:
        return { color: 'grey.main', icon: <HelpOutlineOutlinedIcon fontSize="small"/>, translationId: 'component.buyer.invoiceList.status.unknown'};
    }
  }

  const data = customize(props.invoiceType);

  return (
    <Box
      alignItems="center"
      color={data.color}
      display="flex"
      fontSize="0.8em"
      fontWeight="fontWeightBold"
    >
      {data.icon}
      <Box component="span" className={classes.label} ml={1}>
        <IntlMessageComponent id={data.translationId} />
      </Box>
    </Box>
  );
}