/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import PhoneNumberComponent from 'components/formFields/phoneNumber';
import ButtonComponent from 'components/buttons/button';
import CompanyLookupWithTypeComponent from 'components/companyLookupV2/types';
import CompanyTypeFormFieldComponent from 'components/formFields/companyType';
import FormErrorsComponent from 'components/formErrors';
import IntlMessageComponent from 'components/formatters/intlMessage';
import MandatoryFormFieldsComponent from 'components/form/mandatory';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import AddNewCompanyForm from './model';

type AddCompanyViewComponentProps = {
  errors: Map<string, ?string>;
  form: AddNewCompanyForm;
  formErrors: string[];
  isProcessing: boolean;
  handleChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void;
  handleCompanyChange: () => void;
  handleCompanyTypeChange: () => void;
  handleMobileChange: (value: string) => void;
  submitForm: () => void;
}

export default function AddCompanyViewComponent(props: AddCompanyViewComponentProps) {
  return (        
    <React.Fragment>
      <FormErrorsComponent formErrors={props.formErrors} />

      <PageSubTitleViewComponent id="page.addcompany.h1" />
      
      <Box px={4} py={3}>
        <CompanyTypeFormFieldComponent
          allowCompanyTypeISO
          companyType={props.form.companyType}
          handleCompanyTypeChange={props.handleCompanyTypeChange}
        />
      </Box>

      <Box mt={5}>
        <Grid container spacing={6} alignItems="stretch">
          <Grid item xs={12} md={6}>
            <PageSubTitleViewComponent id="company.addCompany.companyInfo" />
            
            <Box mt={3}>
              <CompanyLookupWithTypeComponent
                company={props.form.company}
                companyErrorLabelId={props.errors.get('company')}
                companyType={props.form.companyType}
                required
                handleSelect={props.handleCompanyChange}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <PageSubTitleViewComponent id="company.addCompany.personalInfo" />

            <Box mt={3} px={4} py={3}>
              <TextField
                id="form.functionName"
                label={<IntlMessageComponent id="form.functionName" />}
                data-walkme="inscrire-field-fonction"
                error={!!props.errors.get('functionName')}
                helperText={(props.errors.get('functionName')) ?
                  <IntlMessageComponent id={props.errors.get('functionName')} /> : ''}
                value={props.form.functionName}
                onChange={props.handleChange('functionName')}
                fullWidth
                margin="normal"
                required
              />

              <PhoneNumberComponent
                id="form.mobile"
                label={<IntlMessageComponent id="form.mobile.label" />}
                data-walkme="inscrire-field-ndetelephone"
                error={!!props.errors.get('mobile')}
                helperText={(props.errors.get('mobile')) ?
                  <IntlMessageComponent id={props.errors.get('mobile')} /> :
                  <IntlMessageComponent id="form.mobile.helperText" />}
                value={props.form.mobile}
                onChange={props.handleMobileChange}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box mt={5}>
        <ButtonComponent
          color="primary"
          variant="contained"
          isProcessing={props.isProcessing}
          onClick={props.submitForm}
        ><IntlMessageComponent id="general.continue" /></ButtonComponent>

        <MandatoryFormFieldsComponent/>
      </Box>
    </React.Fragment>
  );
}
