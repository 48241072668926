/* @flow */

export default class CompanyUser {
  companyUserId: number;
  firstName: string;
  lastName: string;
  functionName: string;
  isAdministrator: boolean;
  hasProcurationUsers: boolean;
  hasProcurationGeneralConditions: boolean;
  hasProcuration: boolean;
  hasAdministratorRole: boolean;
}
