/* @flow */

import React from 'react';
import { connect } from 'react-redux';

import SubscriptionTab5ProcurationViewComponent from './view';

import SendProcurationEmailRequest from 'models/requests/register/sendProcurationEmailRequest';
import Document from 'models/documents/v2/document';

import RegisterService from 'services/registerService';
import DocumentService from 'services/DocumentService';
import NotificationHelper from 'lib/notifications';
import { DOCUMENTS_PERSON_POST } from 'constants/apiRoutes';
import {
  DOC_TYPE_PROCURATION,
  DOC_CODE_PROCURATION
} from 'constants/constants';

type SubscriptionTab5ProcurationComponentProps = {
  activeCompanyId: number;
  activeCompanyPersonId: number;
  hasSignedGC: boolean;
  successHandler: () => void;
}

type SubscriptionTab5ProcurationComponentState = {
  isProcessing: boolean;
  doc: Document;
}

class SubscriptionTab5ProcurationComponent extends React.Component<SubscriptionTab5ProcurationComponentProps, SubscriptionTab5ProcurationComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      isProcessing: false,
      doc: new Document()
    };
  }

  componentDidMount = async () => {
    let doc = new Document();
    doc.code = DOC_CODE_PROCURATION;
    doc.typeId = DOC_TYPE_PROCURATION;

    this.setState({
      doc: doc
    });
  }

  sendProcurationEmail = async () => {
    this.setState({ isProcessing: true });
    try {
      const request = new SendProcurationEmailRequest();
      request.companyId = this.props.activeCompanyId;
      await RegisterService.sendProcurationEmail(request);
      this.props.successHandler();
    } catch (e) {
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
      this.setState({ isProcessing: false });
    }
  };

  uploadProcuration = async () => {
    this.setState({ isProcessing: true });
    try {
      // add documents
      const document = this.state.doc;
      await DocumentService.sendDocument2(DOCUMENTS_PERSON_POST, document.file, { documentTypeId: document.typeId, companyPersonId: this.props.activeCompanyPersonId });
      this.props.successHandler();
    } catch (e) {
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
      this.setState({ isProcessing: false });
    }
  };

  procurationUnneeded = async () => {
    this.props.successHandler();
  }

  handleDocumentSelect = (file: File) => {
    const document = this.state.doc;
    document.file = file;
    document.filename = file.name;
    this.setState({ doc: document });
  };

  handleDocumentSelectFailure = (file: File) => {
    const document = this.state.doc;
    document.file = null;
    document.filename = null;
    this.setState({ doc: document });
  }

  handleDocumentRemove = () => {
    const document = this.state.doc;
    document.file = null;
    document.filename = "";
    this.setState({ doc: document });
  };

  render = () => {
    return (
      <SubscriptionTab5ProcurationViewComponent
        isProcessing={this.state.isProcessing}
        hasSignedGC={this.props.hasSignedGC}
        sendProcurationEmail={this.sendProcurationEmail}
        procurationUnneeded={this.procurationUnneeded}
        handleDocumentSelect={this.handleDocumentSelect}
        handleDocumentSelectFailure={this.handleDocumentSelectFailure}
        handleDocumentRemove={this.handleDocumentRemove}
        uploadProcuration={this.uploadProcuration}
        doc={this.state.doc}
      />
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id,
  activeCompanyPersonId: state.auth.user.activeCompany.companyPersonId
});

export default connect(mapStateToProps)(SubscriptionTab5ProcurationComponent);
