/* @flow */

import React from 'react';
import {useRouteMatch} from 'react-router-dom';
import {useSelector} from 'react-redux';

import MaintenanceComponent from 'components/maintenance';
import SignUpComponent from 'app/users/signUp/index';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function SignUpPage() {
  const maintenance = useSelector(state => state.locale.maintenance);
  const match = useRouteMatch();
  
  if (maintenance) {
    return <MaintenanceComponent />
  }

  const email = match.params.email || null;
  const token = match.params.token || null;

  return (
    <WebsitePageComponent id="page.signup.h1">
      <SignUpComponent email={email} token={token} />
    </WebsitePageComponent>
  );
}
