/* @flow */

import React from 'react';
import * as reactGA from 'lib/analytics';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import EligibilityTestComponent from 'components/eligibilityTest';
import IneligibleLeadComponent from 'components/eligibilityTest/ineligibleLead';
import IntlMessageComponent from 'components/formatters/intlMessage';
import LoadingComponent from 'components/loading';

import EligibilityQuestionnaireModel from 'models/leads/eligibilityQuestionnaire';

import LeadService from 'services/LeadService';

type OfferTab2ComponentProps = {
  leadId: number;
  successHandler: () => void;
  backHandler: () => void;
};

type OfferTab2ComponentState = {
  ineligibleReasonIds: number[];
  isLoading: boolean;
  questionnaire: EligibilityQuestionnaireModel;
};

class OfferTab2Component extends React.Component<OfferTab2ComponentProps, OfferTab2ComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      ineligibleReasonIds: [],
      isLoading: true,
      questionnaire: null
    };
  }

  componentDidMount = async () => {
    reactGA.initializeGA();
    reactGA.logEvent('Offer', 'Start Eligibility', `Lead Id: ${this.props.leadId}`);

    try {
      const questionnaire = await LeadService.checkEligibility(this.props.leadId);
      
      if (!questionnaire.mustAnswerBusinessOpportunityQuestions && !questionnaire.mustAnswerEligibilityQuestions) {
        return this.props.successHandler();
      }

      this.setState({isLoading: false, questionnaire});
    } catch (e) {
      console.error(e);
    }
  }

  componentWillUnmount = async () => {
    reactGA.logEvent('Offer', 'Eligibility Completed', `Lead Id: ${this.props.leadId}`);
  }

  failHandler = (ineligibleReasonIds: number[]) => {
    reactGA.initializeGA();
    reactGA.logEvent('Offer', 'Not Eligible');
    this.setState({ineligibleReasonIds});
  }

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    if (this.state.ineligibleReasonIds.length > 0) {
      return (
        <IneligibleLeadComponent
          ineligibleReasonIds={this.state.ineligibleReasonIds}
          translationPrefix="component.offer.tab2"
        />
      );
    }

    return (
      <React.Fragment>
        <Typography variant="body1" gutterBottom>
          <IntlMessageComponent id="component.offer.tab2.intro" />
        </Typography>
        
        <Box mt={3}>
          <EligibilityTestComponent
            leadId={this.props.leadId}
            mustAnswerBusinessOpportunityQuestions={this.state.questionnaire.mustAnswerBusinessOpportunityQuestions}
            mustAnswerEligibilityQuestions={this.state.questionnaire.mustAnswerEligibilityQuestions}
            translationPrefix="component.offer.tab2"
            successHandler={this.props.successHandler}
            failHandler={this.failHandler}
            backHandler={this.props.backHandler}
          />
        </Box>
      </React.Fragment>
    );
  }
}

export default OfferTab2Component;
