/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import LoadingComponent from 'components/loading';

import IsoNoCompanySignUpViewComponent from './view';
import IsoNoCompanySignUpSuccessViewComponent from './successView';

import IsoNoCompanyForm from './model';

import RegisterService from 'services/registerService';
import { handleApiFormResponse, handleFormChange, handleFormValidation } from 'lib/forms';
import loadConstraints from 'lib/validation/loadConstraints';

import NotificationHelper from 'lib/notifications';

type IsoNoCompanySignUpComponentProps = {
  activeCompanyId: number;
  success: boolean;
  successHandler: () => void;
}

type IsoNoCompanySignUpComponentState = {
  constraints: any;
  errors: Map<string,?string>;
  form: IsoNoCompanyForm;
  formErrors: string[];
  isLoading: boolean;
  isProcessing: boolean;
  placeholders: any;
}

class IsoNoCompanySignUpComponent extends React.Component<IsoNoCompanySignUpComponentProps, IsoNoCompanySignUpComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      constraints: null,
      errors: null,
      form: null,
      formErrors: [],
      isLoading: true,
      isProcessing: false,
      placeholders: null
    };
  }

  componentDidMount = async () => {

    try {
      // Load constraints
      const constraints = await loadConstraints('Person', ['titleId', 'lastName', 'firstName', 'email', 'mobilePresence',
        'addressLine1', 'zipCode', 'city', 'countryId', 'languageId',
        'registrationNumber']);

      this.setState({
        constraints,
        errors: new Map(Object.keys(constraints).map(e => [e, undefined])),
        form: new IsoNoCompanyForm(),
        isLoading: false
      });
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  };

  handleChange = (fieldName: string) => (event) => handleFormChange.call(this, fieldName, event.target.value);
  handleDateChange = (fieldName: string) => (date) => handleFormChange.call(this, fieldName, date);
  handleTypeChange = (fieldName: string) => (id: number) => handleFormChange.call(this, fieldName, id);
  handlePhoneChange = (value: string) => {
    handleFormChange.call(this, 'mobilePresence', value);
  }
  handleMobileChange = (value: string) => {
    handleFormChange.call(this, 'mobilePresence', value);
  }
  validateForm = () => handleFormValidation.call(this);

  submitForm = async () => {
    if (!this.validateForm()) return;
    this.setState({ formErrors: [], isProcessing: true });

    try {
      const request = this.state.form.toRequest();
      await RegisterService.isoNoCompany(request);
      this.props.successHandler();
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }
  };

  render() {
    if (this.state.isLoading) return <LoadingComponent />;

    return (
      <React.Fragment>
        {this.props.success && <IsoNoCompanySignUpSuccessViewComponent
        />}
        {!this.props.success && <IsoNoCompanySignUpViewComponent
          errors={this.state.errors}
          form={this.state.form}
          formErrors={this.state.formErrors}
          isProcessing={this.state.isProcessing}
          maxDateBirth={moment().subtract(18, 'y').startOf('d')}
          minDateBirth={moment('01-01-1900', 'MM-DD-YYYY')}
          maxRegistrationExpireDate={moment().add(100, 'y').startOf('d')}
          minRegistrationExpireDate={moment().add(1, 'd').startOf('d')}
          handleChange={this.handleChange}
          handleDateChange={this.handleDateChange}
          handleTypeChange={this.handleTypeChange}
          handlePhoneChange={this.handlePhoneChange}
          handleMobileChange={this.handleMobileChange}
          submitForm={this.submitForm}
        />}
      </React.Fragment>
    );
  }
}

export default connect()(IsoNoCompanySignUpComponent);

