/* @flow */

import {
  SET_COMPONENT_DATA
} from '../actionTypes';

import BuyerInvoiceActiveFilters from 'models/buyers/invoices/invoiceActiveFilters';
import DebtorInvoiceActiveFilters from 'models/debtors/invoiceActiveFilters';
import SellerInvoiceActiveFilters from 'models/sellers/invoices/invoiceActiveFilters';
import ConfirmingInvoiceActiveFilters from 'models/confirming/invoiceActiveFilters';

const INIT_STATE = {
  buyerInvoicesPortfolioTable: {
    activeFilters: new BuyerInvoiceActiveFilters()
  },
  debtorInvoicesPortfolioTable: {
    activeFilters: new DebtorInvoiceActiveFilters()
  },
  debtorInvoicesToApproveTable: {
    activeFilters: new DebtorInvoiceActiveFilters()
  },
  debtorInvoicesToPayTable: {
    activeFilters: new DebtorInvoiceActiveFilters()
  },
  sellerInvoicesPortfolioTable: {
    activeFilters: new SellerInvoiceActiveFilters()
  },
  confirmingInvoicesPortfolioTable: {
    activeFilters: new ConfirmingInvoiceActiveFilters()
  }
};

const reducer = (state = INIT_STATE, action) => {

  if (action.type === SET_COMPONENT_DATA) {
    // TODO: make it more generic ?
    switch (action.payload.componentName) {
      case 'buyerInvoicesPortfolioTable':
        return { ...state, buyerInvoicesPortfolioTable: action.payload.data };
      case 'debtorInvoicesPortfolioTable':
        return { ...state, debtorInvoicesPortfolioTable: action.payload.data };
      case 'debtorInvoicesToApproveTable':
        return { ...state, debtorInvoicesToApproveTable: action.payload.data };
      case 'debtorInvoicesToPayTable':
        return { ...state, debtorInvoicesToPayTable: action.payload.data };
      case 'sellerInvoicesPortfolioTable':
        return { ...state, sellerInvoicesPortfolioTable: action.payload.data };
      case 'confirmingInvoicesPortfolioTable':
        return { ...state, confirmingInvoicesPortfolioTable: action.payload.data };
      default:
        break;
    }
  }

  return state;
};

export default reducer;
