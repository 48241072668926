/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import LoadingDialogComponent from 'components/loading/dialog';

import BuyerRefuseInvoiceFormDialogViewComponent from './view';

import BuyerService from 'services/BuyerService';
import {handleApiFormResponse, handleFormChange, handleFormValidation} from 'lib/forms';
import loadConstraints from 'lib/validation/loadConstraints';

import RefuseInvoiceRequest from 'models/requests/buyers/refuseInvoiceRequest';

import RefuseInvoiceFormModel from './model';

type BuyerRefuseInvoiceFormDialogComponentProps = {
  activeCompanyId: number;
  invoiceId: number;
  closeDialog: () => void;
  handleSuccess: () => void;
}

type BuyerRefuseInvoiceFormDialogComponentState = {
  constraints: any;
  errors: Map<string, ?string>;
  form: RefuseInvoiceFormModel;
  formErrors: string[];
  isLoading: boolean;
  isProcessing: boolean;
}

class BuyerRefuseInvoiceFormDialogComponent extends React.Component<BuyerRefuseInvoiceFormDialogComponentProps, BuyerRefuseInvoiceFormDialogComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      constraints: null,
      errors: null,
      form: new RefuseInvoiceFormModel(),
      formErrors: [],
      isLoading: true,
      isProcessing: false
    };
  }

  componentDidMount = async () => {
    try {
      const constraints = await loadConstraints('refuseinvoice');
      this.setState({
        constraints,
        errors: new Map(Object.keys(constraints).map(e => [e, undefined])),
        isLoading: false
      });
    } catch (e) {
      console.error(e);
    }
  };

  handleChange = (fieldName: string) => (event) => handleFormChange.call(this, fieldName, event.target.value);
  validateForm = () => handleFormValidation.call(this);

  submitForm = async () => {
    if ((this.state.form.reason === 'Other' || this.state.form.reason === null)&& !this.validateForm()) return;
    this.setState({formErrors: [], isProcessing: true});
    try {
      const request = new RefuseInvoiceRequest(this.props.activeCompanyId, this.props.invoiceId, this.state.form);
      await BuyerService.refuseInvoice(request);
      this.props.handleSuccess();
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }
  }

  render() {
    if (this.state.isLoading) {
      return (<LoadingDialogComponent id="component.buyer.invoiceList.dialog.refuse.title" closeDialog={this.props.closeDialog} />)
    }
    
    return (
      <BuyerRefuseInvoiceFormDialogViewComponent
        errors={this.state.errors}
        form={this.state.form}
        formErrors={this.state.formErrors}
        isProcessing={this.state.isProcessing}
        closeDialog={this.props.closeDialog}
        handleChange={this.handleChange}
        submitForm={this.submitForm}
      />
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps)(BuyerRefuseInvoiceFormDialogComponent);
