/* @flow */

export default class IncompleteAlertRequest {
  companyType: number;
  insurerId: string;
  companyName: string;
  countryId: number;
  postalCode: string;
  city: string;
  phone: string;
  vatNumber: string;

  lastName: string;
  firstName: string;
  email: string;
  mobile: string;
  languageId: number;  
  functionName: string;
  titleId: number;

  isoCompanyId: number;
  referrerCompanyId: number;
  requestFlow: string;
}
