/* @flow */

import moment from 'moment';

export default class CommissionMsf {
  // data
  isoCompany: string;
  isoOffice: string;
  isoPerson: string;
  sellerCompany: string;
  revenueDate: moment;
  msfPayment: number;
  revenues: number;
  paidRevenues: number;
  msfCommission: number;

  // filters
  filterISOId: number;
  filterISOOfficeId: number;
  filterISOPersonId: number;
  filterPeriod: moment;
}
