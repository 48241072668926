/* @flow */
import React from 'react';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import AmountSmallUnitFormatterComponent from 'components/formatters/amountSmallUnit';
import IntlMessageComponent from 'components/formatters/intlMessage';
import NumberFormatterComponent from 'components/formatters/number';

import ProfileSummaryCard from 'models/dashboards/profileSummaryCard';

import {
  CARD_VALUETYPE_AMOUNT,
  CARD_VALUETYPE_COUNT
} from 'constants/cardTypes';
import TooltipComponent from 'components/toolTips/index';

type CarousselCardViewComponentProps = {
  card: ProfileSummaryCard;
  customize: (cardType: number) => {};
}

export default function CarousselCardViewComponent(props: CarousselCardViewComponentProps) {
  const data = props.customize(props.card.type);

  return (
    <Box display="flex" justifyContent="center" width="100%">
      <Box textAlign="center" height={220} width={220}>

        <Paper elevation={0} data-walkme={data.walkme} style={{ height: '100%' }} pageName="Tooltip.Dashboard.Carousel" elementName={data.translationId}>
          <TooltipComponent pageName="Tooltip.Dashboard.Carousel" elementName={data.translationId}>
            <Box pt={3} px={1}>
              <Box color={data.color}>
                {data.icon}
              </Box>

              <Box color="grey.dark" fontSize="min(4vw, 30px);" fontWeight="fontWeightBold" mt={1}>
                {props.card.valueType === CARD_VALUETYPE_AMOUNT &&
                  <AmountSmallUnitFormatterComponent amount={props.card.value} />
                }
                {props.card.valueType === CARD_VALUETYPE_COUNT &&
                  <NumberFormatterComponent value={props.card.value} />
                }
              </Box>

              <Box mt={2}>
                <IntlMessageComponent id={data.translationId} />
              </Box>
            </Box>
          </TooltipComponent>
        </Paper>

      </Box>
    </Box>
  );
}
