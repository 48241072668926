/* @flow */

import moment from 'moment/moment';

export default class DocumentItem {
    id: number;
    typeId: number;
    code: string;
    codeName: string;
    upload: moment;
    name: string;
    toSign: boolean;
    signed: moment;
    personName: string;
    created: moment;
}
