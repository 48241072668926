/* @flow */

import React, { useState } from 'react';

import customSort from 'lib/sortHelpers';

import Box from '@material-ui/core/Box';
import CloudDownload from '@material-ui/icons/CloudDownload';
import EditOutlined from '@material-ui/icons/EditOutlined';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';

import AmountFormatterComponent from 'components/formatters/amount';
import DateFormatterComponent from 'components/formatters/date';
import IntlMessageComponent from 'components/formatters/intlMessage';
import SecurityComponent from 'components/security';
import StyledTableCellTextComponent from 'components/table/cellText';
import StyledTableCellNumbersComponent from 'components/table/cellNumbers';
import StyledTableHeadComponent from 'components/table/head';
import StyledTableRowComponent from 'components/table/row';
import UploadFileIconButtonComponent from 'components/buttons/uploadIconButton';

import Mandate from 'models/isos/mandates/mandate';
import ButtonComponent from 'components/buttons/button';

const columnData = [
  { id: 'name', translationKey: 'component.iso.mandateList.header.name', walkme: 'isomandates-column-name' },
  { id: 'amount', align: 'right', translationKey: 'component.iso.mandateList.header.amount', walkme: 'isomandates-column-amount' },
  { id: 'document', translationKey: 'component.iso.mandateList.header.document', walkme: 'isomandates-column-document' },
  { id: 'paymentDate', translationKey: 'component.iso.mandateList.header.paymentDate', walkme: 'isomandates-column-paymentDate' },
  { id: 'edit', translationKey: 'component.iso.mandateList.header.edit', walkme: 'isomandates-column-edit' }
];

type MandateTableComponentProps = {
  mandates: Mandate[];
  hasMandates: boolean;
  downloadDocument: (companyId: number) => void;
  editMandate: (mandateId: number) => void;
  showForm: () => void;
  onDocumentUploadFail: () => void;
  onDocumentUploadSuccess: (mandateId: number, file: File) => void;
};

export default function MandateTableComponent(props: MandateTableComponentProps) {
  const [state, setState] = useState({
    order: 'desc',
    orderBy: 'id',
    page: 0,
    pageSize: 10
  });

  const handlePageChange = (_, page) => setState(prevState => ({ ...prevState, page }));
  const handlePageSizeChange = event => setState(prevState => ({ ...prevState, pageSize: event.target.value }));

  const handleSortOrderChange = (orderBy: string) => {
    const order = (state.orderBy === orderBy && state.order === 'desc') ? 'asc' : 'desc';
    setState(prevState => ({ ...prevState, order, orderBy }));
  };

  const sortMandates = () => {
    const mandates = state.order === 'desc'
      ? props.mandates.sort((a, b) => customSort(b[state.orderBy], a[state.orderBy]))
      : props.mandates.sort((a, b) => customSort(a[state.orderBy], b[state.orderBy]));
    return mandates;
  }

  const mandates = sortMandates();

  return (
    <React.Fragment>
      <Box component="div" overflow="auto">
        <Table>
          <StyledTableHeadComponent data-walkme="isomandates-table-headline">
            <StyledTableRowComponent>
              {columnData.map(column => (
                <StyledTableCellTextComponent align={column?.align ?? 'left'} key={column.id} padding="none">
                  <TableSortLabel
                    active={state.orderBy === column.id}
                    data-walkme={column.walkme || ''}
                    direction={state.order}
                    onClick={() => handleSortOrderChange(column.id)}
                  ><IntlMessageComponent id={column.translationKey} /></TableSortLabel>
                </StyledTableCellTextComponent>
              ))}
            </StyledTableRowComponent>
          </StyledTableHeadComponent>

          <TableBody bgcolor="white">
            {!mandates.length &&
              <StyledTableRowComponent>
                <StyledTableCellTextComponent colSpan={columnData.length}>
                  <Box textAlign="center">
                    <IntlMessageComponent id="component.iso.mandateList.noData" />
                  </Box>
                </StyledTableCellTextComponent>
              </StyledTableRowComponent>
            }

            {mandates.slice(state.page * state.pageSize, (state.page * state.pageSize) + state.pageSize).map(n => {
              const onDocumentUploaded = (file: File) => props.onDocumentUploadSuccess(n.id, file);

              return (
                <StyledTableRowComponent hover tabIndex={-1} key={`mandateList-${n.id}`}>
                  <StyledTableCellTextComponent padding="none">
                    <b>{n.name}</b>
                  </StyledTableCellTextComponent>

                  <StyledTableCellNumbersComponent padding="none">
                    <AmountFormatterComponent amount={n.amount} />
                  </StyledTableCellNumbersComponent>

                  <StyledTableCellTextComponent padding="none">
                    <Tooltip title={<IntlMessageComponent id="component.iso.mandates.table.button.download.tooltip" />}>
                      <IconButton onClick={() => props.downloadDocument(n.sellerId)}>
                        <CloudDownload />
                      </IconButton>
                    </Tooltip>
                  </StyledTableCellTextComponent>

                  <StyledTableCellTextComponent padding="none">
                    <DateFormatterComponent date={n.paymentDate} />
                  </StyledTableCellTextComponent>

                  <StyledTableCellTextComponent padding="none">
                    <SecurityComponent pageName="POS.Mandates" elementName="btnEdit" checkRoleExecute>
                      <IconButton
                        onClick={() => props.editMandate(n.id)}
                        disabled={n.paymentDate !== undefined}
                      ><EditOutlined /></IconButton>
                    </SecurityComponent>

                    <SecurityComponent pageName="POS.MandateDetail" elementName="btnUpload">
                      <UploadFileIconButtonComponent
                        disabled={n.paymentDate !== undefined}
                        failHandler={props.onDocumentUploadFail}
                        successHandler={onDocumentUploaded}
                      />
                    </SecurityComponent>
                  </StyledTableCellTextComponent>
                </StyledTableRowComponent>
              );
            })}
          </TableBody>
        </Table>

        <Box display="flex" height={60}>
          <Box flexGrow={1}></Box>
          <Box justifyContent="flex-end">
            <TablePagination
              component="div"
              count={mandates.length}
              data-walkme="isomandates-field-mandatesperpage"
              rowsPerPage={state.pageSize}
              page={state.page}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePageSizeChange}
              labelRowsPerPage={<IntlMessageComponent id="general.mandatePerPage" />}
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
            />
          </Box>
        </Box>
      </Box>
      {props.hasMandates && <Box component="div" >
        <ButtonComponent
          color="primary"
          variant="contained"
          onClick={() => props.showForm()}
        >
          <IntlMessageComponent id="component.iso.mandates.addmandate" />
        </ButtonComponent>
      </Box>}
    </React.Fragment>
  );
}
