/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import AmountFormatterComponent from 'components/formatters/amount';
import IntlMessageComponent from 'components/formatters/intlMessage';
import NumberFormatterComponent from 'components/formatters/number';
import PercentageFormatterComponent from 'components/formatters/percentage';

type BuyerPerformanceSummaryComponentProps = {
  amountInvested: number;
  cashReturn: number;
  investmentDays: number;
  irr: number;
}

export default function BuyerPerformanceSummaryComponent(props: BuyerPerformanceSummaryComponentProps) {
  return (
    <Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={3}>
      <Grid item xs={12} sm={6} md={3}>
        <Typography variant="body1">
          <Box textAlign="center" data-walkme="performance-box-retour">
            <IntlMessageComponent id="buyer.perf.irr" data-walkme="performance-text-retour" />
          </Box>
          <Box color="grey.main" textAlign="center" fontSize={30} fontWeight="fontWeightBold">
            <PercentageFormatterComponent value={props.irr * 100} />
          </Box>
        </Typography>
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <Typography variant="body1">
          <Box textAlign="center" data-walkme="performance-box-prevision">
            <IntlMessageComponent id="buyer.perf.cashReturn" data-walkme="performance-text-prevision" />
          </Box>
          <Box color="grey.main" textAlign="center" fontSize={30} fontWeight="fontWeightBold">
            <AmountFormatterComponent amount={props.cashReturn} />
          </Box>
        </Typography>
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <Typography variant="body1">
          <Box textAlign="center" data-walkme="performance-box-investissementmoyen">
            <IntlMessageComponent id="buyer.perf.investmentDays" data-walkme="performance-text-investissementmoyen" />
          </Box>
          <Box color="grey.main" textAlign="center" fontSize={30} fontWeight="fontWeightBold">
            <NumberFormatterComponent value={props.investmentDays} />
          </Box>
        </Typography>
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <Typography variant="body1">
          <Box textAlign="center" data-walkme="performance-box-montantinvesti">
            <IntlMessageComponent id="buyer.perf.amountInvested" data-walkme="performance-text-montantinvesti" />
          </Box>
          <Box color="grey.main" textAlign="center" fontSize={30} fontWeight="fontWeightBold">
            <AmountFormatterComponent amount={props.amountInvested} />
          </Box>
        </Typography>
      </Grid>
    </Grid>
  );
}