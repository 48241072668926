/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import LoadingComponent from 'components/loading';

import CompanyDetailsViewComponent from './view';

import CompanyDetailsFormModel from './model';

import CompanyService from 'services/CompanyService';
import {handleApiFormResponse, handleFormChange, handleFormValidation} from 'lib/forms';
import loadConstraints from 'lib/validation/loadConstraints';

import NotificationHelper from 'lib/notifications';

type CompanyDetailsComponentProps = {
  activeCompanyId: number;
}

type CompanyDetailsComponentState = {
  constraints: any;
  errors: Map<string, ?string>;
  form: CompanyDetailsFormModel;
  formErrors: Array<string>;
  isLoading: boolean;
  isProcessing: boolean;
}

class CompanyDetailsComponent extends React.Component<CompanyDetailsComponentProps, CompanyDetailsComponentState> {

  constructor(props) {
    super(props);
    this.state = {
      constraints: null,
      errors: null,
      form: null,
      formErrors: [],
      isLoading: true,
      isProcessing: false
    };
  }

  componentDidMount = async () => {
    try {
      const companyDetails = await CompanyService.getCompanyDetails(this.props.activeCompanyId);
      const form = new CompanyDetailsFormModel(companyDetails)
      var constraints = null;

      if (form.isSeller || form.isBuyer) {
        constraints = await loadConstraints('Company', [
          'name', 'legalFormId', 'vatNumber', 'isin', 'stockExchange', 'activities', 'mainActivityCountryId', 'sourceOfFunds',
          'phone', 'addressLine1', 'addressLine2', 'zipCode', 'city', 'countryId'
        ]);
    }
      else {
        constraints = await loadConstraints('Company', [
          'name', 'legalFormId', 'vatNumber', 'isin', 'stockExchange', 'phone', 'addressLine1', 'addressLine2', 'zipCode', 'city', 'countryId'
        ]);
      }

      this.setState({
        constraints,
        errors: new Map(Object.keys(constraints).map(e => [e, undefined])),
        form: form,
        isLoading: false
      });
    } catch (error) {
      console.error(error);
    }
  };

  handleChange = (fieldName: string) => (event) => handleFormChange.call(this, fieldName, event.target.value);
  handleTypeIdChange = (fieldName: string) => (id: number) => handleFormChange.call(this, fieldName, id);
  validateForm = () => handleFormValidation.call(this);
  handlePhoneChange = (value: string) => {
    handleFormChange.call(this, 'phone', value);
  }
  handleBooleanChange = (fieldName: string) => (event) => {
    const form = this.state.form;
    form[fieldName] = event.target.value === 'true';
    this.setState({form});
  };

  submitForm = async () => {
    if (!this.validateForm()) return;
    this.setState({formErrors: [], isProcessing: true});

    try {
      const request = this.state.form.toRequest(this.props.activeCompanyId);
      if (this.state.form.isISO || this.state.form.isDebtor) {
        await CompanyService.updateISOCompanyDetails(request);
      } else {
        await CompanyService.updateCompanyDetails(request);
      }
      NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS, 'page.company.saved');
      this.setState({isProcessing: false});
    } catch (error) {
      handleApiFormResponse.call(this, error);
    }
  };

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <CompanyDetailsViewComponent
        errors={this.state.errors}
        form={this.state.form}
        formErrors={this.state.formErrors}
        isProcessing={this.state.isProcessing}
        handleChange={this.handleChange}
        handleTypeIdChange={this.handleTypeIdChange}
        handleBooleanChange={this.handleBooleanChange}
        handlePhoneChange={this.handlePhoneChange}
        submitForm={this.submitForm}
      />
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});


export default connect(mapStateToProps)(CompanyDetailsComponent);
