/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

import ButtonComponent from 'components/buttons/button';
import FormErrorsComponent from 'components/formErrors';
import IntlMessageComponent from 'components/formatters/intlMessage';

import ExtendLeadLifetimeForm from './model';

import IsoService from 'services/IsoService';
import {handleApiFormResponse, handleFormChange, handleFormValidation} from 'lib/forms';
import loadConstraints from 'lib/validation/loadConstraints';

import NotificationHelper from 'lib/notifications';

type ExtendsLeadFormDialogProps = {
  activeCompanyId: number;
  leadId: number;
  successHandler: () => void;
  cancelHandler: () => void;
}

type ExtendsLeadFormDialogState = {
  errors: Map<string, ?string>;
  form: ExtendLeadLifetimeForm;
  formErrors: string[];
  isLoading: boolean;
  isProcessing: boolean;
}

class ExtendsLeadFormDialog extends React.Component<ExtendsLeadFormDialogProps, ExtendsLeadFormDialogState> {

  constructor(props: RefuseInvoicesFormDialogProps) {
    super(props);

    this.state = {
      constraints: null,
      errors: null,
      form: new ExtendLeadLifetimeForm(),
      formErrors: [],
      isLoading: true,
      isProcessing: false
    };
  }

  componentDidMount = async () => {
    try {
      const constraints = await loadConstraints('lead', ['lifetimeExtensionReason']);
      
      this.setState({
        constraints,
        errors: new Map(Object.keys(constraints).map(e => [e, undefined])),
        isLoading: false
      });
    } catch (e) {
      console.error(e);
    }
  };

  handleChange = (fieldName: string) => (event) => handleFormChange.call(this, fieldName, event.target.value);
  validateForm = () => handleFormValidation.call(this);

  submit = async () => {
    if (!this.validateForm()) return;
    this.setState({formErrors: [], isProcessing: true});

    try {
      const request = this.state.form.toRequest(this.props.activeCompanyId, this.props.leadId);
      await IsoService.extendsLeadLifetime(request);
      NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS);
      this.props.successHandler();
    } catch (error) {
      handleApiFormResponse.call(this, error);
    }
  };

  render() {
    if (this.state.isLoading) return null;

    return (
      <Dialog open onClose={this.props.cancelHandler}>

        <DialogTitle>
          <IntlMessageComponent id="component.isos.leads.extendsLifetimeDialog.title" />
        </DialogTitle>

        <DialogContent>
          <FormErrorsComponent formErrors={this.state.formErrors} />

          <DialogContentText>
            <IntlMessageComponent id="component.isos.leads.extendsLifetimeDialog.contentText" />
          </DialogContentText>

          <TextField
            id="form.lifetimeExtensionReason"
            required
            multiline
            label={<IntlMessageComponent id="component.isos.leads.extendsLifetimeDialog.reason.label" />}
            helperText={this.state.errors.get('lifetimeExtensionReason') ?
              <IntlMessageComponent id={this.state.errors.get('lifetimeExtensionReason')} /> : ''}
            error={!!this.state.errors.get('lifetimeExtensionReason')}
            onChange={this.handleChange('lifetimeExtensionReason')}
            value={this.state.form.lifetimeExtensionReason}
            autoFocus
            fullWidth
            margin="dense"
            rows="4"
          />
        </DialogContent>

        <DialogActions>
          <ButtonComponent
            color="default"
            disabled={this.state.isProcessing}
            onClick={this.props.cancelHandler}
          ><IntlMessageComponent id="component.isos.leads.extendsLifetimeDialog.actions.cancel" /></ButtonComponent>

          <ButtonComponent
            color="primary"
            isProcessing={this.state.isProcessing}
            onClick={this.submit}
          ><IntlMessageComponent id="component.isos.leads.extendsLifetimeDialog.actions.submit" /></ButtonComponent>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  activeCompanyId: auth.user.activeCompany.id
});

export default connect(mapStateToProps)(ExtendsLeadFormDialog);
