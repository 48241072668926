/* @flow */

import React from 'react';
import { useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

const useStyles = makeStyles({
  root: {
    height: 430,
    display: "block",
    verticalAlign: "top"
  },
  media: {
    height: 250,
    width: 250,
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
});

export default function IsoContactsComponent() {
  const classes = useStyles();

  const { roleISO, countryId } = useSelector(state => ({
    roleISO: state.auth.user.activeCompany.roleISO,
    countryId: state.auth.user.activeCompany.countryId
  }));

  return (
    <React.Fragment>
      <PageSubTitleViewComponent id="component.iso.contacts.title" />

      <Typography variant="body1" gutterBottom>
        <IntlMessageComponent id="component.iso.contacts.introduction" />
      </Typography>

      <Box mt={3}>
        <Typography variant="body1" gutterBottom>
          <b><IntlMessageComponent id="component.iso.contacts.questions" /></b>
        </Typography>

        <Typography variant="body1" gutterBottom>
          <IntlMessageComponent id="component.iso.contacts.contacts" />
        </Typography>
      </Box>

      <Box mt={5}>

        {(roleISO && roleISO.salesPerson?.firstName === "Charlotte") &&
          <Grid container direction="column" justify="center" alignItems="center">
            <Grid item xs={12} md={6} lg={4}>
              <Card className={classes.root}>
                <CardMedia
                  className={classes.media}
                  image="./images/contacts/charlotte-escoffier.png"
                  title="Charlotte Escoffier"
                />
                <CardContent>
                  <Box textAlign="center">
                    <Typography variant="body1" gutterBottom>
                      <b>Charlotte Escoffier</b>
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                      <b>T:</b> +33 6 44 26 57 02
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                      <a href="mailto:charlotte.escoffier@edebex.com"><b>charlotte.escoffier@edebex.com</b></a>
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        }

        {(roleISO && roleISO.salesPerson?.firstName === "Tony") &&
          <Grid container direction="column" justify="center" alignItems="center">
            <Grid item xs={12} md={6} lg={4}>
              <Card className={classes.root}>
                <CardMedia
                  className={classes.media}
                  image="./images/contacts/tony-oger.png"
                  title="Tony Oger"
                />
                <CardContent>
                  <Box textAlign="center">
                    <Typography variant="body1" gutterBottom>
                      <b>Tony Oger</b>
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                      <b>T:</b> +32 2 880 82 62 (Be)
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                      <b>T:</b> +33 178 90 04 43 (Fr)
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                      <b>T:</b> +352 661 105 233 (Lu)
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                      <a href="mailto:tony@edebex.com"><b>tony@edebex.com</b></a>
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        }
        {(roleISO && roleISO.salesPerson?.firstName === "David") &&
          <Grid container direction="column" justify="center" alignItems="center">
            <Grid item xs={12} md={6} lg={4}>
              <Card className={classes.root}>
                <CardMedia
                  className={classes.media}
                  image="./images/contacts/david-vanderlooven.png"
                  title="David Van der Looven"
                />
                <CardContent>
                  <Box textAlign="center">
                    <Typography variant="body1" gutterBottom>
                      <b>David Van der Looven</b>
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                      <b>T:</b> +32 486 71 60 27
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                      <a href="mailto:david@edebex.com"><b>david@edebex.com</b></a>
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        }
        {(roleISO && roleISO.salesPerson?.firstName === "Fabien") &&
          <Grid container direction="column" justify="center" alignItems="center">
            <Grid item xs={12} md={6} lg={4}>
              <Card className={classes.root}>
                <CardMedia
                  className={classes.media}
                  image="./images/contacts/fabien-enilorac.png"
                  title="Fabien Enilorac"
                />
                <CardContent verticalAlign="top">
                  <Box textAlign="center">
                    <Typography variant="body1" gutterBottom>
                      <b>Fabien Enilorac</b>
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                      <b>T:</b> +32 2 842 00 76
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      <b>T:</b> +32 4 722 06 124
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                      <a href="mailto:fabien.enilorac@edebex.com"><b>fabien.enilorac@edebex.com</b></a>
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        }
        {(roleISO && roleISO.salesPerson?.firstName === "Esther") &&
          <Grid container direction="column" justify="center" alignItems="center">
            <Grid item xs={12} md={6} lg={4}>
              <Card className={classes.root}>
                <CardMedia
                  className={classes.media}
                  image="./images/contacts/esther-vandijk.png"
                  title="Esther Van Dijk"
                />
                <CardContent verticalAlign="top">
                  <Box textAlign="center">
                    <Typography variant="body1" gutterBottom>
                      <b>Esther Van Dijk</b>
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                      <b>T:</b> +32 495 18 35 66
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                      <a href="mailto:esther.van.dijk@edebex.com"><b>esther.van.dijk@edebex.com</b></a>
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        }
        {(roleISO && roleISO.salesPerson?.firstName === "Azza") &&
          <Grid container direction="column" justify="center" alignItems="center">
            <Grid item xs={12} md={6} lg={4}>
              <Card className={classes.root}>
                <CardMedia
                  className={classes.media}
                  image="./images/contacts/azza-lejri.png"
                  title="Azza Lejri"
                />
                <CardContent verticalAlign="top">
                  <Box textAlign="center">
                    <Typography variant="body1" gutterBottom>
                      <b>Azza Lejri</b>
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                      <b>T:</b> +34 657 855 519
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                      <a href="mailto:azza.lejri@edebex.com"><b>azza.lejri@edebex.com</b></a>
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        }
        {(roleISO && roleISO.salesPerson?.firstName === "Enric") &&
          <Grid container direction="column" justify="center" alignItems="center">
            <Grid item xs={12} md={6} lg={4}>
              <Card className={classes.root}>
                <CardMedia
                  className={classes.media}
                  image="./images/contacts/enric-farre.png"
                  title="Enric Farré"
                />
                <CardContent verticalAlign="top">
                  <Box textAlign="center">
                    <Typography variant="body1" gutterBottom>
                      <b>Enric Farré</b>
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                      <b>T:</b> +34 660 055 334
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                      <a href="mailto:enric.farre@edebex.com"><b>enric.farre@edebex.com</b></a>
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        }
        {(!roleISO ||
          !roleISO.salesPerson ||
          (roleISO.salesPerson.firstName !== "Charlotte" && roleISO.salesPerson.firstName !== "Tony" && roleISO.salesPerson.firstName !== "David" && roleISO.salesPerson.firstName !== "Esther" && roleISO.salesPerson.firstName !== "Fabien" && roleISO.salesPerson.firstName !== "Azza" && roleISO.salesPerson.firstName !== "Enric"))
          && countryId !== 144 //spain
          &&
          <React.Fragment>
            <Grid container display="flex" justifyContent="center" alignItems="center">
              <Grid item xs display="flex" justifyContent="center" alignItems="center">
                <Card className={classes.root}>
                  <CardMedia
                    className={classes.media}
                    image="./images/contacts/charlotte-escoffier.png"
                    title="Charlotte Escoffier"
                  />
                  <CardContent verticalAlign="top">
                    <Box textAlign="center">
                      <Typography variant="body1" gutterBottom>
                        <b>Charlotte Escoffier</b>
                      </Typography>

                      <Typography variant="body1" gutterBottom>
                        <b>T:</b> +33 6 44 26 57 02
                      </Typography>

                      <Typography variant="body1" gutterBottom>
                        <a href="mailto:charlotte.escoffier@edebex.com"><b>charlotte.escoffier@edebex.com</b></a>
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs>
                <Card className={classes.root}>
                  <CardMedia
                    className={classes.media}
                    image="./images/contacts/fabien-enilorac.png"
                    title="Fabien Enilorac"
                  />
                  <CardContent verticalAlign="top">
                    <Box textAlign="center">
                      <Typography variant="body1" gutterBottom>
                        <b>Fabien Enilorac</b>
                      </Typography>

                      <Typography variant="body1" gutterBottom>
                        <b>T:</b> +32 2 842 00 76
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        <b>T:</b> +32 4 722 06 124
                      </Typography>

                      <Typography variant="body1" gutterBottom>
                        <a href="mailto:fabien.enilorac@edebex.com"><b>fabien.enilorac@edebex.com</b></a>
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs>
                <Card className={classes.root}>
                  <CardMedia
                    className={classes.media}
                    image="./images/contacts/tony-oger.png"
                    title="Tony Oger"
                  />
                  <CardContent verticalAlign="top">
                    <Box textAlign="center">
                      <Typography variant="body1" gutterBottom>
                        <b>Tony Oger</b>
                      </Typography>

                      <Typography variant="body1" gutterBottom>
                        <b>T:</b> +32 2 880 82 62 (Be)
                      </Typography>

                      <Typography variant="body1" gutterBottom>
                        <b>T:</b> +33 178 90 04 43 (Fr)
                      </Typography>

                      <Typography variant="body1" gutterBottom>
                        <b>T:</b> +352 661 105 233 (Lu)
                      </Typography>

                      <Typography variant="body1" gutterBottom>
                        <a href="mailto:tony@edebex.com"><b>tony@edebex.com</b></a>
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

            </Grid>
            <Grid container display="flex" justifyContent="center" alignItems="center">

              <Grid item xs>
                <Card className={classes.root}>
                  <CardMedia
                    className={classes.media}
                    image="./images/contacts/david-vanderlooven.png"
                    title="David Van der Looven"
                  />
                  <CardContent verticalAlign="top">
                    <Box textAlign="center">
                      <Typography variant="body1" gutterBottom>
                        <b>David Van der Looven</b>
                      </Typography>

                      <Typography variant="body1" gutterBottom>
                        <b>T:</b> +32 486 71 60 27
                      </Typography>

                      <Typography variant="body1" gutterBottom>
                        <a href="mailto:david@edebex.com"><b>david@edebex.com</b></a>
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs>
                <Card className={classes.root}>
                  <CardMedia
                    className={classes.media}
                    image="./images/contacts/esther-vandijk.png"
                    title="Esther Van Dijk"
                  />
                  <CardContent verticalAlign="top">
                    <Box textAlign="center">
                      <Typography variant="body1" gutterBottom>
                        <b>Esther Van Dijk</b>
                      </Typography>

                      <Typography variant="body1" gutterBottom>
                        <b>T:</b> +32 495 18 35 66
                      </Typography>

                      <Typography variant="body1" gutterBottom>
                        <a href="mailto:esther.van.dijk@edebex.com"><b>esther.van.dijk@edebex.com</b></a>
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

            </Grid>
          </React.Fragment>}
        {(!roleISO ||
          !roleISO.salesPerson ||
          (roleISO.salesPerson.firstName !== "Charlotte" && roleISO.salesPerson.firstName !== "Tony" && roleISO.salesPerson.firstName !== "David" && roleISO.salesPerson.firstName !== "Esther" && roleISO.salesPerson.firstName !== "Fabien" && roleISO.salesPerson.firstName !== "Azza" && roleISO.salesPerson.firstName !== "Enric"))
          && countryId === 144 //spain
          &&
          <React.Fragment>
            <Grid container display="flex" justifyContent="center" alignItems="center">
              <Grid item xs display="flex" justifyContent="center" alignItems="center">
                <Card className={classes.root}>
                  <CardMedia
                    className={classes.media}
                    image="./images/contacts/azza-lejri.png"
                    title="Azza Lejri"
                  />
                  <CardContent verticalAlign="top">
                    <Box textAlign="center">
                      <Typography variant="body1" gutterBottom>
                        <b>Azza Lejri</b>
                      </Typography>

                      <Typography variant="body1" gutterBottom>
                        <b>T:</b> +34 657 855 519
                      </Typography>

                      <Typography variant="body1" gutterBottom>
                        <a href="mailto:azza.lejri@edebex.com"><b>azza.lejri@edebex.com</b></a>
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs>
                <Card className={classes.root}>
                  <CardMedia
                    className={classes.media}
                    image="./images/contacts/enric-farre.png"
                    title="Enric Farré"
                  />
                  <CardContent verticalAlign="top">
                    <Box textAlign="center">
                      <Typography variant="body1" gutterBottom>
                        <b>Enric Farré</b>
                      </Typography>

                      <Typography variant="body1" gutterBottom>
                        <b>T:</b> +34 660 055 334
                      </Typography>

                      <Typography variant="body1" gutterBottom>
                        <a href="mailto:enric.farre@edebex.com"><b>enric.farre@edebex.com</b></a>
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </React.Fragment>}
      </Box>
    </React.Fragment >
  );
}
