/* @flow */

import React from 'react';

import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';

import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';
import SecurityComponent from 'components/security';

import CompanyBankAccountsFormDialogComponent from './dialog';
import CompanyBankACcountsConfirmDeleteComponent from './deleteConfirm';
import CompanyBankAccountsTableViewComponent from './table';

import BankAccount from 'models/companies/bankAccount';

type CompanyBankAccountsViewComponentProps = {
  bankAccounts: BankAccount[];
  displayBankAccountToBeValidatedMessage: boolean;
  isDialogOpen: boolean;
  isProcessing: boolean;
  companyIsActive: boolean;
  bankAccountToDeleteId: number;
  changeMainBankAccount: () => void;
  closeDialog: () => void;
  toggleEnableBankAccount: () => void;
  onAddBankAccountSuccess: () => void;
  openDialog: () => void;
  deleteBankAccount: () => void;
  onDeleteSuccess: () => void;
  cancelDelete: () => void;
}

export default function CompanyBankAccountsViewComponent(props: CompanyBankAccountsViewComponentProps) {
  return (
    <React.Fragment>
      <Box>
        <Alert severity="info">
          <IntlMessageComponent id="company.bankaccounts.explanation" />
        </Alert>
      </Box>
      <Box mt={3}>
        <CompanyBankAccountsTableViewComponent
          bankAccounts={props.bankAccounts.filter(b => !b.isDeleted)}
          displayBankAccountToBeValidatedMessage={props.displayBankAccountToBeValidatedMessage}
          isProcessing={props.isProcessing}
          changeMainBankAccount={props.changeMainBankAccount}
          toggleEnableBankAccount={props.toggleEnableBankAccount}
          deleteBankAccount={props.deleteBankAccount}
          companyIsActive={props.companyIsActive}
        />
      </Box>

      <SecurityComponent pageName="POS.CompanyInformation" elementName="btnNewBankAccount">
        <Box mt={3}>
          <ButtonComponent
            color="primary"
            variant="contained"
            isProcessing={props.isProcessing}
            onClick={props.openDialog}
          ><IntlMessageComponent id="component.company.bankAccounts.add" /></ButtonComponent>
        </Box>

        {props.isDialogOpen &&
          <CompanyBankAccountsFormDialogComponent
            onClose={props.closeDialog}
            onSuccess={props.onAddBankAccountSuccess}
          />
        }
        {props.bankAccountToDeleteId > 0 &&
          <CompanyBankACcountsConfirmDeleteComponent
            bankAccountId={props.bankAccountToDeleteId}
            onClose={props.cancelDelete}
            onSuccess={props.onDeleteSuccess}
          />
        }
      </SecurityComponent>
    </React.Fragment>
  );
}
