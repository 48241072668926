/* @flow */

import React from 'react';

import ConfirmingInvoicesPortfolioComponent from 'app/confirming/invoicesPortfolio';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function ConfirmingInvoicesPortfolioPage() {
  return (
    <WebsitePageComponent id="confirming.portfolio.title" noPaper>
      <ConfirmingInvoicesPortfolioComponent />
    </WebsitePageComponent>
  );
}
