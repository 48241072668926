/* @flow */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function IconCancelOutlined(props) {
  return (
    <SvgIcon viewBox="0 0 62.72 62.69" {...props}>
      <g>
        <path d="M255.21,486.83a31.34,31.34,0,1,1,31.43-31C286.61,472.85,272.4,486.86,255.21,486.83Zm28.65-31.15c-.13-16.16-12.68-28.75-28.54-28.76a28.32,28.32,0,0,0-28.58,27.45c-.43,17.25,12.77,29.72,28.55,29.67A28.45,28.45,0,0,0,283.86,455.68Z" transform="translate(-223.92 -424.15)"/>
        <path d="M243.94,468.11a1.47,1.47,0,0,1-1.06-.44,1.49,1.49,0,0,1,0-2.12l22.39-22.39a1.51,1.51,0,0,1,2.12,0,1.49,1.49,0,0,1,0,2.12L245,467.67A1.47,1.47,0,0,1,243.94,468.11Z" transform="translate(-223.92 -424.15)"/>
        <path d="M266.52,468.21a1.53,1.53,0,0,1-1.06-.44l-22.39-22.39a1.5,1.5,0,0,1,2.12-2.13l22.39,22.4a1.5,1.5,0,0,1-1.06,2.56Z" transform="translate(-223.92 -424.15)"/>
      </g>
    </SvgIcon>
  );
}
