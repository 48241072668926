/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import LoadingComponent from 'components/loading';

import RegisterSellerCompanyTab1AlreadyExistViewComponent from './alreadyExistView';
import RegisterSellerCompanyTab1ViewComponent from './view';

import Company from 'models/companies/company';
import RegisterSellerCompanyForm from './model';

import RegisterService from 'services/registerService';
import IsoService from 'services/IsoService';
import {handleApiFormResponse, handleFormChange, handleFormValidation} from 'lib/forms';
import loadConstraints from 'lib/validation/loadConstraints';

type RegisterSellerCompanyTab1ComponentProps = {
  activeCompanyId: number;
  successHandler: (companyId: number, personId: number) => void;
};

type RegisterSellerCompanyTab1ComponentState = {
  companyAlreadyExist: boolean;
  constraints: any;
  errors: Map<string, ?string>;
  form: RegisterSellerCompanyForm;
  formErrors: string[];
  isLoading: boolean;
  isProcessing: boolean;
};

class RegisterSellerCompanyTab1Component extends React.Component<RegisterSellerCompanyTab1ComponentProps, RegisterSellerCompanyTab1ComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      companyAlreadyExist: false,
      constraints: null,
      errors: null,
      form: null,
      formErrors: [],
      isLoading: true,
      isProcessing: false
    };
  }

  componentDidMount = async () => {
    try {
      const companyConstraintsPromise = loadConstraints('Company', ['company']);
      const personConstraintsPromise = loadConstraints('Person', ['lastName', 'firstName', 'email', 'mobilePresence', 'functionName', 'languageId']);

      const results = await Promise.all([companyConstraintsPromise, personConstraintsPromise]);
      const constraints = Object.assign({}, results[0], results[1]);

      this.setState({
        constraints,
        errors: new Map(Object.keys(constraints).map(e => [e, undefined])),
        form: new RegisterSellerCompanyForm(),
        isLoading: false
      });
    } catch (e) {
      console.error(e);
    }
  };

  handleChange = (fieldName: string) => (event) => handleFormChange.call(this, fieldName, event.target.value);
  handleTypeChange = (fieldName: string) => (id: number) => handleFormChange.call(this, fieldName, id);
  handleMobileChange = (value: string) => {
    handleFormChange.call(this, 'mobilePresence', value);
  }

  handleCompanyChange = (company: ?Company) => {
    const form = this.state.form;
    form.company = company;

    const errors = this.state.errors;
    errors.delete('company');

    this.setState({errors, form});
  };

  validateForm = () => handleFormValidation.call(this);

  submitForm = async () => {
    if (!this.validateForm())
    {
      const request = this.state.form.toIncompleteAlertRequest(this.props.activeCompanyId, "ISO Register Seller");
      const response = await RegisterService.incompleteAlert(request);
      return;
    }
    this.setState({formErrors: [], isProcessing: true});

    try {
      const request = this.state.form.toRequest(this.props.activeCompanyId);
      const response = await IsoService.registerSellerCompany(request);

      if (response.companyAlreadyExist) {
        this.setState({isProcessing: false, companyAlreadyExist: true});
      } else {
        this.props.successHandler(response.companyId, response.companyPersonId);
      }      
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }
  };

  resetForm = () => {
    this.setState(prevState => ({
      companyAlreadyExist: false,
      errors: new Map(Object.keys(prevState.constraints).map(e => [e, undefined])),
      form: new RegisterSellerCompanyForm(),
      formErrors: []
    }));
  }

  render = () => {
    if (this.state.isLoading) return (<LoadingComponent />);

    if (this.state.companyAlreadyExist) {
      return (<RegisterSellerCompanyTab1AlreadyExistViewComponent resetForm={this.resetForm} />);
    }

    
    return (
      <RegisterSellerCompanyTab1ViewComponent
        errors={this.state.errors}
        form={this.state.form}
        formErrors={this.state.formErrors}
        isProcessing={this.state.isProcessing}
        handleChange={this.handleChange}
        handleCompanyChange={this.handleCompanyChange}
        handleTypeChange={this.handleTypeChange}
        handleMobileChange={this.handleMobileChange}
        submitForm={this.submitForm}
      />
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps)(RegisterSellerCompanyTab1Component);
