/* @flow */


import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';
import MandatoryFormFieldsComponent from 'components/form/mandatory';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import UploadFieldComponent from 'components/formFields/upload';

type RegisterLeadTab4ViewComponentProps = {
  documents: Document[];
  isProcessing: boolean;
  handleDocumentSelect: () => void;
  handleDocumentRemove: () => void;
  addDocument: () => void;
  skip: () => void;
  submitForm: () => void;
}

export default function RegisterLeadTab4ViewComponent(props: RegisterLeadTab4ViewComponentProps) {

  return (
    <React.Fragment>
      <Typography variant="body1" gutterBottom>
        <IntlMessageComponent id="page.iso.registerLead.tab4.notmandatory" data-walkme="createlead4-label-notmandatory" />
      </Typography>

      <Box mt={5}>
        <PageSubTitleViewComponent id="page.iso.registerLead.tab4.title" data-walkme="createlead4-title-documents" />
      </Box>

      <Box mt={3}>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={6}>
          {props.documents.map(doc => (
            <Grid item xs={12} md={6} lg={3} key={`doc-${doc.id}`}>
              <UploadFieldComponent
                disabled={props.isProcessing}
                displayProps={{margin: 'normal', fullWidth: true}}
                filename={doc.filename}
                isMissing={doc.isMissing}
                label="document.type.toc"
                successHandler={props.handleDocumentSelect(doc.id)}
                removeHandler={props.handleDocumentRemove(doc.id)}
              />
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box mt={3}>
        <ButtonComponent
          color="secondary"
          variant="contained"
          disabled={props.isProcessing}
          onClick={props.addDocument}
        ><IntlMessageComponent id="general.button.addDocument" /></ButtonComponent>
      </Box>

      <Box mt={5}>
        <ButtonComponent
          color="default"
          variant="contained"
          disabled={props.isProcessing}
          onClick={props.skip}
        ><IntlMessageComponent id="general.skip" /></ButtonComponent>

        <Box component="span" ml={1}>
          <ButtonComponent
            color="primary"
            variant="contained"
            isProcessing={props.isProcessing}
            onClick={props.submitForm}
          ><IntlMessageComponent id="general.continue" /></ButtonComponent>
        </Box>

      </Box>
    </React.Fragment>
  );
}
