/* @flow */

import React from 'react';

import SellerInvoicesPortfolioTableComponent from 'app/sellers/invoicesPortfolio';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function SellerInvoicesPortfolioPage() {
  return (
    <WebsitePageComponent id="page.seller.portfolio.title" data-walkme="sportfolio-title-votreportfolio" noPaper>
      <SellerInvoicesPortfolioTableComponent />
    </WebsitePageComponent>
  );
}
