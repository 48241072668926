/* @flow */

export default class DisputeInvoiceTokenRequest {
  token: string;
  invoiceId: number;

  lastName: string;
  firstName: string;
  email: string;
  functionName: string;

  answerInvoiceGenuine: string;
  answerInvoiceGenuineText: string;
  answerAmountAndDueDateCorrect: string;
  answerAmountAndDueDateCorrectText: string;
  answerServicesOrGoodsDelivered: string;
  answerServicesOrGoodsDeliveredText: String;
  answerWillDebtorDisputeInvoice: string;
  answerWillDebtorDisputeInvoiceText: string;
  answerGuaranteeDeductionOrCompensation: string;
  answerGuaranteeDeductionOrCompensationText: string;
}
