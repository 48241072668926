/* @flow */

import React from 'react';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';

import SetCompanyBankAccountRequest from 'models/requests/register/setCompanyBankAccountRequest';
import SupplierCompany from 'models/confirming/supplierCompany';

import ConfirmingService from 'services/ConfirmingService';

import LoadingComponent from 'components/loading';

import { handleApiFormResponse, handleFormChange, handleFormValidation } from 'lib/forms';
import loadConstraints from 'lib/validation/loadConstraints';
import ConfirmingAddSupplierTab2ViewComponent from './view';
import LinkBankAccountToSpecificRequest from 'models/requests/confirming/LinkBankAccountToSpecificRequest';

type ConfirmingAddSupplierTab2ComponentProps = {
  activeCompanyId: number;
  supplierId: number;
  successHandler: () => void;
}

type ConfirmingAddSupplierTab2ComponentState = {
  isLoading: boolean;
  isProcessing: boolean;
  supplierCompanyDetails: SupplierCompany;
  form: SetCompanyBankAccountRequest;
  constraints: any;
  errors: Map<string,?string>;
  hasBankAccountDocument: boolean;
  displayNew: boolean;
  selectedBankAccountId: number;
  formErrors: string[];
}

class ConfirmingAddSupplierTab2Component extends React.Component<ConfirmingAddSupplierTab2ComponentProps, ConfirmingAddSupplierTab2ComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };
  }

  componentDidMount = async () => {
    this.refreshData();
  }

  refreshData = async () => {
    try {
      let supplierCompanyDetails = await ConfirmingService.getConfirmingSupplierDetails(this.props.activeCompanyId, this.props.supplierId);

      const constraintFields = [];
      constraintFields.push('bankCountryId', 'bankIdentifier', 'bankAccount');
      const constraints = await loadConstraints('companyBankAccount', constraintFields);

      this.setState({
        supplierCompanyDetails,
        isLoading: false,
        isProcessing: false,
        errors: new Map(Object.keys(constraints).map(e => [e, undefined])),
        form: new SetCompanyBankAccountRequest(this.props.activeCompanyId, this.props.supplierId, supplierCompanyDetails.bankAccountCountryId, supplierCompanyDetails.bankIdentifier, supplierCompanyDetails.bankAccount),
        constraints,
        hasBankAccountDocument: supplierCompanyDetails.hasBankAccountDocument,
        displayNew: false,
        selectedBankAccountId: 0
      });
    } catch (e) {
      console.error(e);
    }
  }

  handleChange = (fieldName: string) => (event) => handleFormChange.call(this, fieldName, event.target.value);
  handleTypeChange = (fieldName: string) => (id: number) => handleFormChange.call(this, fieldName, id);

  handleChangeBankAccount = (bankAccountId: number) => {
    this.setState({ selectedBankAccountId: bankAccountId });
  };

  handleDisplayNewChange = () => {
    this.setState({ displayNew: !this.state.displayNew });
  }

  updateBankAccount = async () => {
    try {
      if (this.state.displayNew) {
        if (!this.validateForm()) return;
        this.setState({ isProcessing: true });
        const request = new SetCompanyBankAccountRequest(this.props.activeCompanyId, this.props.supplierId, this.state.form.bankCountryId, this.state.form.bankIdentifier, this.state.form.bankAccount);

        await ConfirmingService.upsertSupplierBankAccount(request);
      } else {
        if (this.state.selectedBankAccountId <= 0 || this.state.selectedBankAccountId === undefined) {
          this.setState({ errors: this.state.errors.set('bankAccountId', 'validation.bankaccount.presence'), isProcessing: false })
          return;
        }
        this.setState({ isProcessing: true });
        const request = new LinkBankAccountToSpecificRequest(this.props.activeCompanyId, this.props.supplierId, this.state.selectedBankAccountId);

        await ConfirmingService.LinkBankAccountToSpecific(request);
      }
      let supplierCompanyDetails = await ConfirmingService.getConfirmingSupplierDetails(this.props.activeCompanyId, this.props.supplierId);
      this.props.successHandler(supplierCompanyDetails, this.props.supplierId);
    } catch (e) {
      console.error(e);
      handleApiFormResponse.call(this, e);
      this.setState({ isProcessing: false });
    }
  }

  validateForm = () => handleFormValidation.call(this);

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <React.Fragment>
        <Box mt={4}>
          <ConfirmingAddSupplierTab2ViewComponent
            supplierCompanyDetails={this.state.supplierCompanyDetails}
            handleChange={this.handleChange}
            handleTypeChange={this.handleTypeChange}
            updateBankAccount={this.updateBankAccount}
            onCancel={this.props.onCancel}
            errors={this.state.errors}
            form={this.state.form}
            isProcessing={this.state.isProcessing}
            displayNew={this.state.displayNew}
            selectedBankAccountId={this.state.selectedBankAccountId}
            handleChangeBankAccount={this.handleChangeBankAccount}
            handleDisplayNewChange={this.handleDisplayNewChange}
            formErrors={this.state.formErrors}
          />
        </Box>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps)(ConfirmingAddSupplierTab2Component);
