
/* @flow */

import moment from 'moment';

export default class InvoiceActiveFilters {
  dateFrom: moment;
  dateTo: moment;  
  query: string;
  statusKey: string;

  constructor() {
    this.dateFrom = moment().startOf('year');
    this.dateTo = moment();
    this.query = '';
    this.statusKey = '';
  }
}
