/* @flow */

export default class DeleteBusinessPotentialRequest {
  companyId: number;
  debtorCompanyId: number;

  constructor(companyId: number, debtorCompanyId: number) {
    this.companyId = companyId;
    this.debtorCompanyId = debtorCompanyId;
  }
}
