import React from 'react';
import {useSelector} from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import ButtonComponent from 'components/buttons/button';
import DateTimePickerComponent from 'components/formFields/dates/dateTimePicker';
import IntlMessageComponent from 'components/formatters/intlMessage';
import FormErrorsComponent from 'components/formErrors';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import {ROLE_DEBTOR} from 'constants/companyRoles';

type FirstCallDialogViewComponentProps = {
  formErrors: string[];
  isProcessing: boolean;
  maxDate: moment;
  presentationDate: moment;
  handleDateChange: () => void;
  skipHandler: () => void;
  shouldDisableDate: () => void;
  submitForm: () => void;
}

export default function FirstCallDialogViewComponent(props: FirstCallDialogViewComponentProps) {
  const activeCompanyRole = useSelector(state => state.auth.user.activeCompany.role);
  let translation = "";
  if (activeCompanyRole === ROLE_DEBTOR) {
    translation = ".debtor";
  }

  return (
    <Dialog open onClose={props.skipHandler}>
      <DialogContent>
        <Box textAlign="center" mb={1}>
          <PageSubTitleViewComponent id="page.subscription.firstCall.header" />

          <Typography variant="body1">
            <IntlMessageComponent id={`page.subscription.firstCall.introduction${translation}`} />
          </Typography>

          <Box mt={3}>
            <ButtonComponent
              color="primary"
              variant="contained"
              onClick={props.skipHandler}
            ><b><IntlMessageComponent id="page.subscription.firstCall.iHaveBeenCalled" /></b></ButtonComponent>
          </Box>

          <Box mt={5}>
            <PageSubTitleViewComponent id="page.subscription.firstCall.header.2" />

            <Typography variant="body1">
              <IntlMessageComponent id="page.subscription.firstCall.openhours" />
            </Typography>

            <Box mt={3}>
              <FormErrorsComponent formErrors={props.formErrors} />

              <DateTimePickerComponent
                label={<IntlMessageComponent id="page.subscription.firstCall.when.label" />}
                fullWidth
                value={props.presentationDate}
                onChange={props.handleDateChange}
                disablePast
                maxDate={props.maxDate}
                shouldDisableDate={props.shouldDisableDate}
              />

              <Box mt={3}>
                <ButtonComponent
                  color="secondary"
                  variant="contained"
                  isProcessing={props.isProcessing}
                  onClick={props.submitForm}
                ><b><IntlMessageComponent id="page.subscription.firstCall.sendRequest" /></b></ButtonComponent>
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}