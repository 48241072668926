/* @flow */

export default class CompanyContactPerson {
  id: number;
  titleId: number;
  lastName: string;
  firstName: string;
  email: string;
  phone: string;
  languageId: number;
  functionName: string;
}
