/* @flow */

import IconAddFilled from 'components/icons/filled/add';
import IconArrowUpFilled from 'components/icons/filled/arrowUp';
import IconListFilled from 'components/icons/filled/list';
import IconHomeFilled from 'components/icons/filled/home';
import IconSuitcaseFilled from 'components/icons/filled/suitcase';
import IconRefreshFilled from 'components/icons/filled/refresh';
import IconUsersFilled from 'components/icons/filled/users';

import {
  PROTECTED_SELLER_BESTPRICING,
  PROTECTED_SELLER_BILLS,
  PROTECTED_SELLER_DASHBOARD,
  PROTECTED_SELLER_MGM,
  PROTECTED_SELLER_OFFER,
  PROTECTED_SELLER_PORTFOLIO,
  PROTECTED_SELLER_SELL
} from 'constants/pageRoutes';

export const sellerMenuData = [
  {link: PROTECTED_SELLER_DASHBOARD, icon: <IconHomeFilled color='primary' size='1.5x'/>, messageId: 'sidebar.page.home'},
  {link: PROTECTED_SELLER_SELL, icon: <IconAddFilled color='primary' size='1.5x'/>, messageId: 'sidebar.page.sell', checkExecutePermissions: true, pageName: 'POS.Sellers.SidebarMenu', elementName: 'menuSell'},
  {link: PROTECTED_SELLER_PORTFOLIO, icon: <IconSuitcaseFilled color='primary' size='1.5x'/>, messageId: 'sidebar.page.myportfolio', pageName: 'POS.Sellers.SidebarMenu', elementName: 'menuPortfolio'},
  {link: PROTECTED_SELLER_BILLS, icon: <IconListFilled color='primary' size='1.5x'/>, messageId: 'sidebar.page.mybills', pageName: 'POS.Sellers.SidebarMenu', elementName: 'menuBills'},
  {link: PROTECTED_SELLER_OFFER, icon: <IconRefreshFilled color='primary' size='1.5x'/>, messageId: 'sidebar.page.offer', pageName: 'POS.Sellers.SidebarMenu', elementName: 'menuOffer'},
  {link: PROTECTED_SELLER_BESTPRICING, icon: <IconArrowUpFilled color='primary' size='1.5x'/>, messageId: 'sidebar.page.betterprice', pageName: 'POS.Sellers.SidebarMenu', elementName: 'menuBetterPrice'},
  {link: PROTECTED_SELLER_MGM, icon: <IconUsersFilled color='primary' size='1.5x'/>, messageId: 'sidebar.page.mgm', pageName: 'POS.Sellers.SidebarMenu', elementName: 'menuMgm'}
];
