/* @flow */

import React from 'react';

import {connect} from 'react-redux';
import {setComponentData} from 'store/actions/componentDataActions';

import customSort from 'lib/sortHelpers';

import Box from '@material-ui/core/Box';

import ConfirmingInvoicesToSignConfirmPopupComponent from 'app/confirming/invoicesToSignTable/invoicesToSignConfirmPopup';
import ConfirmingInvoicesToSignRefusePopupComponent from 'app/confirming/invoicesToSignTable/invoicesToSignRefusePopup';
import DebtorInvoiceTableControlsViewComponent from 'app/debtors/invoiceTableControls';
import LoadingComponent from 'components/loading';

import ConfirmingInvoicesToSignTableViewComponent from './view';

import Invoice from 'models/debtors/invoice';
import InvoiceActiveFilters from 'models/debtors/invoiceActiveFilters';
import InvoiceFilters from 'models/debtors/invoiceFilters';

import {userSwitchCompanyAction} from 'store/actions/authActions';

import DebtorService from 'services/DebtorService';

type ConfirmingInvoicesToSignTableComponentProps = {
  activeCompanyId: number;
  showFilters: boolean;
  activeCompanyCanSignDebtRecognition: boolean;
  isConfirming: boolean;
  userSwitchCompanyAction: (companyId) => void;
}

type ConfirmingInvoicesToSignTableComponentState = {
  filters: InvoiceFilters;
  activeFilters: InvoiceActiveFilters;
  invoices: Invoice[];
  isLoading: boolean;
  order: string;
  orderBy: ?string;
  page: number;
  pageSize: number;
  showConfirmPopupForInvoiceIds: number[];
  showRefusePopupForInvoiceId: number;
  totalDebtAmount: number;
  totalSellers: number;
}

class ConfirmingInvoicesToSignTableComponent extends React.Component<ConfirmingInvoicesToSignTableComponentProps, ConfirmingInvoicesToSignTableComponentState> {

  constructor(props) {
    super(props);
    this.state = {
      filters: null,
      activeFilters: null,
      invoices: [],
      isLoading: true,
      order: 'asc',
      orderBy: null,
      showConfirmPopupForInvoiceIds: [],
      showRefusePopupForInvoiceId: 0,
      page: 0,
      pageSize: 10
    };
  }

  componentDidMount = async () => {
    try {
      const {invoices, filters} = await DebtorService.getInvoicesToApprove(this.props.activeCompanyId);
      const totalDebtAmount = this.countTotalDebtAmount(invoices);
      const totalSellers = this.countSellerCompanies(invoices);
      const activeFilters = new InvoiceActiveFilters()
      this.setState({filters, activeFilters, invoices, isLoading: false, totalDebtAmount, totalSellers});
    } catch (e) {
      console.error(e);
    }
  }

  countTotalDebtAmount = (invoices: Invoice[]) => invoices.reduce((acc, val) => acc + val.debtAmount, 0);

  countSellerCompanies = (invoices: Invoice[]) => {
    const sellers = [];
    invoices.forEach(i => {
      if (!sellers.includes(i.sellerCompany.id))
        sellers.push(i.sellerCompany.id);
    });
    return sellers.length;
  }

  handleFiltersChange = (activeFilters: InvoiceActiveFilters) => {
    this.setState(activeFilters);
  }

  handlePageChange = (page: number) => this.setState({page});
  handlePageSizeChange = (pageSize: number) => this.setState({pageSize});  

  handleSortOrderChange = (orderBy: string) => {
    let order = this.state.orderBy === orderBy && this.state.order === 'desc' ? 'asc' : 'desc';

    let invoices = null;
    if (orderBy.startsWith('sellerCompany.')) {
      // get exact property name
      const o = orderBy.substring(14);

      // order
      invoices = order === 'desc'
        ? this.state.invoices.sort((a, b) => customSort(b.sellerCompany[o], a.sellerCompany[o]))
        : this.state.invoices.sort((a, b) => customSort(a.sellerCompany[o], b.sellerCompany[o]));
    }
    else {
      // order
      invoices = order === 'desc'
        ? this.state.invoices.sort((a, b) => customSort(b[orderBy], a[orderBy]))
        : this.state.invoices.sort((a, b) => customSort(a[orderBy], b[orderBy]));
    }

    this.setState({ invoices, order, orderBy });
  };

  getFilteredResults = () => {
    let filteredInvoices = this.state.invoices;

    // filter on query search
    if (this.state.activeFilters.query && this.state.activeFilters.query.length > 2) {
      filteredInvoices = filteredInvoices.filter((i: Invoice) => {
        const query = this.state.activeFilters.query.toUpperCase();
        const fields = [i.id, i.invoiceName, i.reference, i.debtorReference].map(v => (v || '').toString().toUpperCase());
        return !!(fields.filter(field => field.toString().indexOf(query) !== -1)).length;
      });
    }

    // If filter on seller company
    if (this.state.activeFilters.sellerCompanyId > 0) {
      filteredInvoices = filteredInvoices.filter(i => i.sellerCompany.id === this.state.activeFilters.sellerCompanyId);
    }

    // filter on amount
    if (this.state.activeFilters.invoiceAmounts && this.state.activeFilters.invoiceAmounts.length > 0) {
      const min = this.state.activeFilters.invoiceAmounts[0];
      const max = this.state.activeFilters.invoiceAmounts[1];
      if (min !== "" && max === "") filteredInvoices = filteredInvoices.filter(i => i.debtAmount >= min);
      if (min === "" && max !== "") filteredInvoices = filteredInvoices.filter(i => i.debtAmount <= max);
      if (min !== "" && max !== "") filteredInvoices = filteredInvoices.filter(i => i.debtAmount >= min && i.debtAmount <= max);
    }

    return filteredInvoices;
  };

  handleInvoiceSelect = (invoiceId: number) => {
    const invoices = this.state.invoices;
    const invoice = invoices.find(i => i.id === invoiceId);
    if (invoices.find(i => i.isSelected) && invoices.find(i => i.isSelected)?.sellerCompany.id !== invoice.sellerCompany.id)
      return;
    
    if (invoice) invoice.isSelected = !invoice.isSelected;
    this.setState({invoices});
  }

  handleInvoiceSelectAll = () => {
    const invoices = this.state.invoices;
    const selectMode = invoices.find(i => i.isSelected) === undefined;
    invoices.forEach(i => { i.isSelected = selectMode; });
    this.setState({invoices});
  }

  approveInvoices = () => {
    const invoiceIds = this.state.invoices.filter(i => i.isSelected).map(i => i.id);
    this.setState({showConfirmPopupForInvoiceIds: invoiceIds});
  }

  approveInvoicesSuccess = () => {
    const invoices = this.state.invoices.filter(i => !i.isSelected);
    const totalDebtAmount = this.countTotalDebtAmount(invoices);
    const totalSellers = this.countSellerCompanies(invoices);
    this.props.userSwitchCompanyAction(this.props.activeCompanyId);
    this.setState({invoices, isLoading: false, showConfirmPopupForInvoiceIds: [], totalDebtAmount, totalSellers});    
  }

  approveInvoicesCancel = () => {
    this.setState({showConfirmPopupForInvoiceIds: []});
  }

  refuseInvoice = (invoiceId: number) => {
    this.setState({showRefusePopupForInvoiceId: invoiceId});
  }

  refuseInvoiceSuccess = () => {
    const invoices = this.state.invoices.filter(i => i.id !== this.state.showRefusePopupForInvoiceId);
    const totalDebtAmount = this.countTotalDebtAmount(invoices);
    const totalSellers = this.countSellerCompanies(invoices);
    this.props.userSwitchCompanyAction(this.props.activeCompanyId);
    this.setState({invoices, isLoading: false, showRefusePopupForInvoiceId: 0, totalDebtAmount, totalSellers});    
  }

  refuseInvoiceCancel = () => {
    this.setState({showRefusePopupForInvoiceId: 0});
  }

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <React.Fragment>
        {this.props.showFilters &&
          <Box pb={5}>
            <DebtorInvoiceTableControlsViewComponent
              activeFilters={this.state.activeFilters}
              filters={this.state.filters}
              handleFiltersChange={this.handleFiltersChange}
              filterOnAmount
              filterOnSellerCompany
            />
          </Box>
        }

        <ConfirmingInvoicesToSignTableViewComponent
          invoices={this.getFilteredResults()}
          order={this.state.order}
          orderBy={this.state.orderBy}
          page={this.state.page}
          pageSize={this.state.pageSize}
          totalDebtAmount={this.state.totalDebtAmount}
          totalSellers={this.state.totalSellers}
          approveInvoices={this.approveInvoices}
          handleInvoiceSelect={this.handleInvoiceSelect}
          handleInvoiceSelectAll={this.handleInvoiceSelectAll}
          handlePageChange={this.handlePageChange}
          handlePageSizeChange={this.handlePageSizeChange}
          handleSortOrderChange={this.handleSortOrderChange}
          refuseInvoice={this.refuseInvoice}
          canSignDebtRecognition={this.props.activeCompanyCanSignDebtRecognition}
        />

        {this.state.showConfirmPopupForInvoiceIds.length > 0 &&
          <ConfirmingInvoicesToSignConfirmPopupComponent
            invoiceIds={this.state.showConfirmPopupForInvoiceIds}
            handleCancel={this.approveInvoicesCancel}
            handleSuccess={this.approveInvoicesSuccess}
          />
        }

        {this.state.showRefusePopupForInvoiceId > 0 &&
          <ConfirmingInvoicesToSignRefusePopupComponent
            invoiceId={this.state.showRefusePopupForInvoiceId}
            handleCancel={this.refuseInvoiceCancel}
            handleSuccess={this.refuseInvoiceSuccess}
          />
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id,
  activeFilters: state.components.debtorInvoicesToApproveTable.activeFilters,
  activeCompanyCanSignDebtRecognition: state.auth.user.activeCompany.canSignDebtRecognition,
});

export default connect(mapStateToProps, {setComponentData, userSwitchCompanyAction})(ConfirmingInvoicesToSignTableComponent);
