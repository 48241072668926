/* @flow */

import AddCompanyContactPersonRequest from 'models/requests/companies/addCompanyContactPersonRequest';

export default class CompanyContactPersonForm {
  titleId: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  languageId: number;
  functionName: string;

  constructor() {
    this.titleId = 0;
    this.firstName = '';
    this.lastName = '';
    this.email = '';
    this.phone = '';
    this.languageId = 0;
    this.functionName = '';
  }

  toRequest(companyId: number, debtorCompanyId: number) {
    const request = new AddCompanyContactPersonRequest();
    request.companyId = companyId;
    request.debtorCompanyId = debtorCompanyId;
    request.titleId = this.titleId;
    request.firstName = this.firstName;
    request.lastName = this.lastName;
    request.email = this.email;
    request.phone = this.phone;
    request.languageId = this.languageId;
    request.functionName = this.functionName;
    return request;
  }
}
