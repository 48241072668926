/* @flow */


import React from 'react';
import {connect} from 'react-redux';

import LoadingComponent from 'components/loading';

import ReferralTab2ViewComponent from './view';
import RegisterReferralNoteForm from './model';

import SellerService from 'services/sellerService';
import {handleApiFormResponse, handleFormChange, handleFormValidation} from 'lib/forms';
import loadConstraints from 'lib/validation/loadConstraints';

type ReferralTab2ComponentProps = {
  activeCompanyId: number;
  refereeCompanyId: number;
  successHandler: () => void;
}

type ReferralTab2ComponentState = {
  constraints: any;
  errors: Map<string, ?string>;
  form: RegisterReferralNoteForm;
  formErrors: string[];
  isLoading: boolean;
  isProcessing: boolean;
}

class ReferralTab2Component extends React.Component<ReferralTab2ComponentProps, ReferralTab2ComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      constraints: null,
      errors: null,
      form: new RegisterReferralNoteForm(),
      formErrors: [],
      isLoading: true,
      isProcessing: false
    };
  }

  componentDidMount = async () => {
    try {
      const constraints = await loadConstraints('referral', ['note']);

      this.setState({
        constraints,
        errors: new Map(Object.keys(constraints).map(e => [e, undefined])),
        isLoading: false
      });
    } catch (e) {
      console.error(e);
    }
  };

  handleChange = (fieldName: string) => (event) => handleFormChange.call(this, fieldName, event.target.value);
  validateForm = () => handleFormValidation.call(this);

  submitForm = async () => {
    if (!this.validateForm()) return;

    this.setState({formErrors: [], isProcessing: true});

    try {
      const request = this.state.form.toRequest(this.props.activeCompanyId, this.props.refereeCompanyId);
      await SellerService.registerReferralNote(request);
      this.props.successHandler();
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }
  };

  render() {
    if (this.state.isLoading) return (<LoadingComponent />);

    return (
      <ReferralTab2ViewComponent
        errors={this.state.errors}
        form={this.state.form}
        formErrors={this.state.formErrors}
        isProcessing={this.state.isProcessing}
        handleChange={this.handleChange}
        submitForm={this.submitForm}
        successHandler={this.props.successHandler}
      />
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps)(ReferralTab2Component);
