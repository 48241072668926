/* @flow */

import React from 'react';
import {shallowEqual, useSelector} from 'react-redux';

import {ROLE_BUYER, ROLE_DEBTOR, ROLE_ISO, ROLE_SELLER} from 'constants/companyRoles';

export default function WalkMeContainer() {
  const user = useSelector(state => state.auth.user, shallowEqual);
  const isAuthenticated = user !== null && user.activeCompany !== null;
  const activeCompanyRole = isAuthenticated ? user.activeCompany.role : 0;

  const firstLogin = isAuthenticated && user.firstLogin;
  
  const username = isAuthenticated ? user.userName : 'none';
  window.walkmeDataUsername = username;

  let userType = 'none';
  if (activeCompanyRole === ROLE_SELLER) userType = 'seller';
  else if (activeCompanyRole === ROLE_BUYER) userType = 'buyer';
  else if (activeCompanyRole === ROLE_ISO) userType = 'iso';
  else if (activeCompanyRole === ROLE_DEBTOR) userType = 'debtor';

  return (
    <React.Fragment>
      <div id="walkme-newuser" style={{display: 'none'}}>{firstLogin ? 'true' : 'false'}</div>
      <div id="walkme-username" style={{display: 'none'}}>{username}</div>
      <div id="walkme-usertype" style={{display: 'none'}}>{userType}</div>
    </React.Fragment>
  );
}
