/* @flow */

import React from 'react';
import { connect } from 'react-redux';

import QuestionAnswerOutlined from '@material-ui/icons/QuestionAnswerOutlined';

import CarouselComponent from 'components/caroussel';
import IconHourglassOutlined from 'components/icons/outlined/hourglass';
import IconHourglass from 'components/icons/hourglass';
import IconMinus15Outlined from 'components/icons/outlined/minus15';
import IconPlus15Outlined from 'components/icons/outlined/plus15';
import IconThumbsUpOutlined from 'components/icons/outlined/thumbsUp';
import LoadingComponent from 'components/loading';
import IconInvoiceDue from 'components/icons/invoiceDue';
import IconInvoiceNotDue from 'components/icons/invoiceNotDue';

import ProfileSummaryCard from 'models/dashboards/profileSummaryCard';


import {
  CARD_TYPE_CONFIRMING_CLLEFT,
  CARD_TYPE_CONFIRMING_DUELESS1WEEK,
  CARD_TYPE_CONFIRMING_DUEMORE1MONTH,
  CARD_TYPE_CONFIRMING_DUEMORE1WEEK,
  CARD_TYPE_CONFIRMING_DUEMORE2WEEKS,
  CARD_TYPE_CONFIRMING_NOTPURCHASED
} from 'constants/cardTypes';
import IconMoneyAvailable from 'components/icons/moneyAvailable';
import ConfirmingService from 'services/ConfirmingService';


type ConfirmingProfileSummaryCarouselComponentProps = {
  activeCompanyId: number;
}

type ConfirmingProfileSummaryCarouselComponentState = {
  cards: ProfileSummaryCard[];
  isLoading: boolean;
}

class ConfirmingProfileSummaryCarouselComponent extends React.Component<ConfirmingProfileSummaryCarouselComponentProps, ConfirmingProfileSummaryCarouselComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      cards: null,
      isLoading: true
    };
  }

  componentDidMount = async () => {
    try {
      const cards = await ConfirmingService.getConfirmingProfileSummary(this.props.activeCompanyId);
      this.setState({ cards, isLoading: false });
    } catch (e) {
      console.error(e);
    }
  }

  customize = (cardType: number) => {
    switch (cardType) {
      case CARD_TYPE_CONFIRMING_CLLEFT:
        return { color: 'blue.main', icon: <IconMoneyAvailable style={{ fontSize: '3em' }} />, translationId: 'confirming.profile.cards.clleft' };
      case CARD_TYPE_CONFIRMING_NOTPURCHASED:
        return { color: 'grey.main', icon: <IconHourglass style={{ fontSize: '3em' }} />, translationId: 'confirming.profile.cards.notpurchased' };
      case CARD_TYPE_CONFIRMING_DUEMORE1MONTH:
        return { color: 'grey.main', icon: <IconInvoiceNotDue style={{ fontSize: '3em' }} />, translationId: 'confirming.profile.cards.duemore1month' };
      case CARD_TYPE_CONFIRMING_DUEMORE2WEEKS:
        return { color: 'orange.main', icon: <IconInvoiceNotDue style={{ fontSize: '3em' }} />, translationId: 'confirming.profile.cards.duemore2weeks' };
      case CARD_TYPE_CONFIRMING_DUEMORE1WEEK:
        return { color: 'red.main', icon: <IconInvoiceNotDue style={{ fontSize: '3em' }} />, translationId: 'confirming.profile.cards.duemore1week' };
      case CARD_TYPE_CONFIRMING_DUELESS1WEEK:
        return { color: 'red.main', icon: <IconInvoiceDue style={{ fontSize: '3em' }} />, translationId: 'confirming.profile.cards.dueless1week' };
      default:
        return { color: 'grey.main', icon: <QuestionAnswerOutlined style={{ fontSize: '3em' }} />, translationId: 'confirming.profile.cards.unknown', walkme: 'sdashboard-box-unknown' };
    }
  }

  render() {
    if (this.state.isLoading) return <LoadingComponent />;

    return (
      <CarouselComponent
        cards={this.state.cards}
        customize={this.customize}
        walkme="sdashboard-image-votresituation"
      />
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  activeCompanyId: auth.user.activeCompany.id
});

export default connect(mapStateToProps)(ConfirmingProfileSummaryCarouselComponent);
