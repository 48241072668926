/* @flow */

import moment from 'moment';

export default class CommissionInvoice {
  // data
  isoCompany: string;
  isoOffice: string;
  isoPerson: string;
  sellerCompany: string;

  invoiceId: number;
  debtorInsurerRating: number;

  createdDate: moment;
  listedDate: moment;
  financedDate: moment;
  closedDate: moment;

  isClaim: boolean;
  isFirstInvoice: boolean;
  isFinanced: boolean;

  debtAmount: number;
  financedAmount: number;
  paidServiceFee: number;
  invoiceCommission: number;

  // filters
  filterISOId: number;
  filterISOOfficeId: number;
  filterISOPersonId: number;
  filterPeriod: moment;
}
