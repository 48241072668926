/* @flow */

export default class ApproveInvoiceTokenRequest {
  token: string;
  invoiceId: number;

  lastName: string;
  firstName: string;
  email: string;
  functionName: string;

  answerGuaranteeDeductionOrCompensation: string;
  answerGuaranteeDeductionOrCompensationText: string;
}
