/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import LoadingComponent from 'components/loading';

import RegisterLeadTab3ViewComponent from './view';
import BusinessPotentialForm from './model';

import CompanyService from 'services/CompanyService';
import IsoService from 'services/IsoService';
import {handleApiFormResponse, handleFormChange, handleFormValidation} from 'lib/forms';
import loadConstraints from 'lib/validation/loadConstraints';
import NotificationHelper from 'lib/notifications';

type RegisterLeadTab3ComponentProps = {
  activeCompanyId: number;
  companyId: number;
  form: BusinessPotentialForm;
  successHandler: (form: BusinessPotentialForm) => void;
  skipHandler: () => void;
};

type RegisterLeadTab3ComponentState = {
  constraints: any;
  errors: Map<string, ?string>;
  form: BusinessPotentialForm;
  formErrors: string[];
  isLoading: boolean;
  isProcessing: boolean;
};

class RegisterLeadTab3Component extends React.Component<RegisterLeadTab3ComponentProps, RegisterLeadTab3ComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      constraints: null,
      errors: null,
      form: null,
      formErrors: [],
      isLoading: true,
      isProcessing: false
    };
  }

  componentDidMount = async () => {
    try {
      const constraints = await loadConstraints('BusinessPotential', ['debtorCompany', 'avgInvoiceAmount',
        'debtorAge', 'maxInvoiceAmount', 'minInvoiceAmount', 'nbrInvoices', 'paymentTerms', 'yearlyAmount']);

      this.setState({
        constraints,
        errors: new Map(Object.keys(constraints).map(e => [e, undefined])),
        form: new BusinessPotentialForm(),
        isLoading: false
      });
    } catch (e) {
      console.error(e);
    }
  };

  handleChange = (fieldName: string) => (event) => handleFormChange.call(this, fieldName, event.target.value);
  validateForm = () => handleFormValidation.call(this);

  handleDebtorCompanyChange = (debtorCompany: ?DebtorCompany) => {
    const form = this.state.form;
    form.debtorCompany = debtorCompany;

    const errors = this.state.errors;
    errors.delete('debtorCompany');

    this.setState({errors, form});
  };

  submitForm = async () => {
    if (!this.validateForm()) return;
    this.setState({formErrors: [], isProcessing: true});

    try {
      const form = this.state.form;
      if (form.debtorCompany.id === 0) {
        form.debtorCompany.id = await CompanyService.addDebtorCompany(form.debtorCompany.insurerId);
      }

      const request = form.toRequest(this.props.activeCompanyId, this.props.companyId);
      await IsoService.setBusinessPotential(request);
      NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS);
      this.props.successHandler(form.debtorCompany);
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }
  };

  render = () => {
    if (this.state.isLoading) return (<LoadingComponent />);

    return (
      <RegisterLeadTab3ViewComponent
        errors={this.state.errors}
        form={this.state.form}
        formErrors={this.state.formErrors}
        isProcessing={this.state.isProcessing}
        handleChange={this.handleChange}
        handleCompanyChange={this.handleDebtorCompanyChange}
        skip={this.props.skipHandler}
        submitForm={this.submitForm}
      />
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps)(RegisterLeadTab3Component);
