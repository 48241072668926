/* @flow */

import React from 'react';
import debounce from 'lodash.debounce';

import CompanyLookupBaseViewComponent from './view';

import CompanyLookupForm from 'models/companies/companyLookupForm';
import Company from 'models/companies/company';
import Country from 'models/generics/country';

import CompanyLookupService from 'services/CompanyLookupService';
import {validateForm} from 'lib/validation/validator';
import loadConstraints from 'lib/validation/loadConstraints';

type CompanyLookupBaseComponentProps = {
  companyErrorLabelId: string;
  countries: Country[];
  required: boolean;
  handleSelect: (company: Company) => void;
  handleCountry: (countryId: number) => void;
  companyNotFound: () => void;
  companyLookUpForm: CompanyLookupForm;
}

type CompanyLookupBaseComponentState = {
  companies: Company[];
  companyNotFound: boolean;
  constraints: any;
  error: boolean;
  form: CompanyLookupForm;
  isProcessing: boolean;
  vatInvalid: boolean;
}

class CompanyLookupBaseComponent extends React.Component<CompanyLookupBaseComponentProps, CompanyLookupBaseComponentState> {

  static LOOKUP_DELAY = 1000;
  static LOOKUP_MIN_CHARS = 3;

  constructor(props) {
    super(props);
    this.state = {
      companies: [],
      companyNotFound: false,
      constraints: null,
      error: false,
      form: props.companyLookUpForm ? props.companyLookUpForm : new CompanyLookupForm(),
      isProcessing: false,
      vatInvalid: false
    };

    this.emitChangeDebounced = debounce(this.emitCompanyNameChange, CompanyLookupBaseComponent.LOOKUP_DELAY);
  }

  componentDidMount = async () => {
    const constraints = await loadConstraints('CompanyLookup', ['vat']);
    this.setState({constraints});
  };

  componentWillUnmount() {
    this.emitChangeDebounced.cancel();
  }

  emitChangeDebounced: (string) => void;

  handleCountryChange = (countryId: number) => {
    this.emitChangeDebounced.cancel();

    const country = this.props.countries.find(c => c.id === countryId);
    
    const form = this.state.form;
    form.country = country || null;
    form.name = '';
    form.vat = '';

    this.setState({
      companies: [],
      companyNotFound: false,
      error: false,
      form,
      isProcessing: false,
      vatInvalid: false
    });

    if (this.props.handleCountry !== null && this.props.handleCountry !== undefined)
    {
      this.props.handleCountry(countryId);
    }
  };

  handleCompanyNameChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const form = this.state.form;
    form.name = event.target.value;
    form.vat = '';

    this.setState({companies: [], companyNotFound: false, error: false, form});
    this.emitChangeDebounced(event.target.value);
  };

  emitCompanyNameChange = async (value: string) => {
    if (value.length < CompanyLookupBaseComponent.LOOKUP_MIN_CHARS || this.state.form.country === null) {
      return;
    }

    try {
      if (this.props.companyNotFound) this.props.companyNotFound(false);
      this.setState({companies: [], companyNotFound: false, error: false, isProcessing: true});
        const companies = await CompanyLookupService.lookupByName(this.state.form.country.id, value);

      if (companies.length === 0) {
        this.setState({companyNotFound: true, isProcessing: false});
        if (this.props.companyNotFound) this.props.companyNotFound(true);
      } else {
        this.setState({companies, isProcessing: false});
      }
    } catch (e) {
      console.error(e);
      this.setState({error: true, isProcessing: false});
    }
  }

  handleVATChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const form = this.state.form;
    form.name = '';
    form.vat = event.target.value;

    const validationResults = validateForm(form, this.state.constraints);
    this.setState({companies: [], form, vatInvalid: validationResults !== undefined});
  }

  lookForCompanyByVATNumber = async () => {
    if (this.state.form.country === null || this.state.vatInvalid) {
      return;
    }

    try {
      if (this.props.companyNotFound) this.props.companyNotFound(false);
      this.setState({companies: [], companyNotFound: false, error: false, isProcessing: true});
        const companies = await CompanyLookupService.lookupByVat(this.state.form.country.id, `${this.state.form.country.code}${this.state.form.vat}`);

      if (companies.length === 0) {
        this.setState({companyNotFound: true, isProcessing: false});
        if (this.props.companyNotFound) this.props.companyNotFound(true);
      } else {
        this.setState({companies, isProcessing: false});
      }
    } catch (e) {
      console.error(e);
      this.setState({error: true, isProcessing: false});
    }
  }

  handleResetCompany = () => {
    const form = this.state.form;
    form.country = null;
    form.name = '';
    form.vat = '';

    this.setState({companies: [], companyNotFound: false, error: false, form, isProcessing: false, vatInvalid: false});
    this.props.handleSelect(null);
  };

  render() {
    return (
      <CompanyLookupBaseViewComponent
        companies={this.state.companies}
        companyErrorLabelId={this.props.companyErrorLabelId}
        companyNotFound={this.state.companyNotFound}
        countries={this.props.countries}
        error={this.state.error}
        form={this.state.form}
        isProcessing={this.state.isProcessing}
        required={this.state.required}
        vatInvalid={this.state.vatInvalid}
        handleCountryChange={this.handleCountryChange}
        handleCompanyNameChange={this.handleCompanyNameChange}
        handleCompanySelect={this.props.handleSelect}
        handleVATChange={this.handleVATChange}
        lookForCompanyByVATNumber={this.lookForCompanyByVATNumber}
      />
    );
  }
}

export default CompanyLookupBaseComponent;
