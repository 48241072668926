/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';

import PaymentComponent from './payment';

import PaymentInfo from 'models/payments/paymentInfo';

type PaymentDialogComponentProps = {
  paymentInfo: PaymentInfo;
  handleRequestClose: () => void;
}

export default function PaymentDialogComponent (props: PaymentDialogComponentProps) {
  return (
    <Dialog open onClose={props.handleRequestClose} maxWidth="sm" fullWidth>

      <DialogTitle>
        <IntlMessageComponent id="component.invoice.payment.dialog.title" />
      </DialogTitle>

      <DialogContent>
        <Box pb={5}>
          <PaymentComponent paymentInfo={props.paymentInfo} />
        </Box>
      </DialogContent>

      <DialogActions>
        <ButtonComponent
          color="default"
          onClick={props.handleRequestClose}
        ><IntlMessageComponent id="component.invoice.documents.dialog.actions.cancel" /></ButtonComponent>
      </DialogActions>
    </Dialog>
  );
}
