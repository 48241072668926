/* @flow */

import React from 'react';

import ISOCouponsComponent from 'app/isos/coupons';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function ISOCouponsPage() {
  return (
    <WebsitePageComponent noPaper id="page.iso.coupons.title" data-walkme="isocoupons-title-couponsmanager">
      <ISOCouponsComponent />
    </WebsitePageComponent>
  );
}
