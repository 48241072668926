/* @flow */

import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import LoadingComponent from 'components/loading';

import NotificationContainerViewComponent from './view';

import Alert from 'models/dashboards/alert';
import Notification from 'models/dashboards/notification';

import DashboardService from 'services/DashboardService';

import {
  PROTECTED_APP_CONTACTS,
  PROTECTED_BUYER_DASHBOARD,
  PROTECTED_CONFIRMING_DASHBOARD,
  PROTECTED_DEBTOR_DASHBOARD,
  PROTECTED_ISO_DASHBOARD,
  PROTECTED_SELLER_DASHBOARD  
} from 'constants/pageRoutes';
import NotificationHelper from 'lib/notifications/index';
import InformAdminRequest from 'models/requests/dashboard/informAdminRequest';

type NotificationContainerComponentProps = {
  activeCompanyId: number;
  activeCompanyRole: number;
  activeBuyerRole: number;
  activeSellerRole: number;
  activeConfirmingRole: number;
  activeCompanyHasAdmins: boolean;
  activeCompanyHasBuyerAdmins: boolean;
  activeCompanyHasSellerAdmins: boolean;
  activeCompanyHasConfirmingAdmins: boolean;
}

type NotificationContainerComponentState = {
  alerts: Alert[];
  isLoading: boolean;
  isProcessing: boolean;
  notifications: Notification[];
  refreshSeconds: number;
}

class NotificationContainerComponent extends React.Component<NotificationContainerComponentProps, NotificationContainerComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      alerts: [],
      isLoading: true,
      notifications: [],
      refreshSeconds: new Date().getTime()
    };
  }

  refreshComponent = async () => {
    const result = await DashboardService.getNotifications(this.props.activeCompanyId, this.props.activeCompanyRole);
    this.setState({alerts: result.alerts, isLoading: false, notifications: result.notifications, refreshSeconds: new Date().getTime()});
  }

  componentDidMount = async () => {
    try {
      const result = await DashboardService.getNotifications(this.props.activeCompanyId, this.props.activeCompanyRole);
      this.setState({alerts: result.alerts, isLoading: false, notifications: result.notifications, refreshSeconds: new Date().getTime()});
      this.interval = setInterval(async () => await this.refreshComponent(), 60000);
    } catch (e) {
      console.error(e);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  showNotifications = () => {
    return this.state.notifications.length > 0 && (
      this.props.location.pathname === PROTECTED_BUYER_DASHBOARD ||
      this.props.location.pathname === PROTECTED_DEBTOR_DASHBOARD ||
      this.props.location.pathname === PROTECTED_ISO_DASHBOARD ||
      this.props.location.pathname === PROTECTED_SELLER_DASHBOARD ||
      this.props.location.pathname === PROTECTED_CONFIRMING_DASHBOARD ||
      this.props.location.pathname === PROTECTED_APP_CONTACTS);
  }

  alertsToShow = () => {
    return this.state.alerts.filter(alert => this.showAlert(alert));
  }

  showAlert = (alert: Alert) => {
    if (!alert.pages) return true;
    const pages = alert.pages.split(";");
    
    for (let i=0; i<pages.length; i++) {
      if (this.matchRuleShort(this.props.location.pathname, pages[i])) return true;
    }

    return false;
  }

  contactAdmin = async () => {
    this.setState({isProcessing: true});
    try {
      const request = new InformAdminRequest(this.props.activeCompanyId);
      const result = await DashboardService.informAdmin(request);
      NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS, 'notifications.informadmin.success');
      this.setState({isProcessing: false});
    } catch (e) {
      console.error(e);
      this.setState({isProcessing: false});
    }
  }

  matchRuleShort = (str, rule) => {
    var escapeRegex = (str) => str.replace(/([.*+?^=!:${}()|[\]/\\])/g, "\\$1");
    return new RegExp("^" + rule.split("*").map(escapeRegex).join(".*") + "$").test(str);
  }

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    if (new Date().getTime() - this.state.refreshSeconds > 5000)
    {
      this.refreshComponent();
    }

    const showNotifications = this.showNotifications();
    const alertsToShow = this.alertsToShow();

    if (alertsToShow.length <= 0 && !showNotifications)
      return null;

    return (
      <NotificationContainerViewComponent
        activeCompanyRole={this.props.activeCompanyRole}
        activeBuyerRole={this.props.activeBuyerRole}
        activeSellerRole={this.props.activeSellerRole}
        activeConfirmingRole={this.props.activeConfirmingRole}
        alerts={alertsToShow}
        notifications={showNotifications ? this.state.notifications : []}
        isProcessing={this.state.isProcessing}
        contactAdmin={this.contactAdmin}
        activeCompanyHasBuyerAdmins={this.props.activeCompanyHasBuyerAdmins}
        activeCompanyHasSellerAdmins={this.props.activeCompanyHasSellerAdmins}
        activeCompanyHasConfirmingAdmins={this.props.activeCompanyHasConfirmingAdmins}
      />
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id,
  activeCompanyRole: state.auth.user.activeCompany.role,
  activeSellerRole: state.auth.user.activeCompany.roleSeller?.role,
  activeBuyerRole: state.auth.user.activeCompany.roleBuyer?.role,
  activeConfirmingRole: state.auth.user.activeCompany.roleConfirming?.role,
  activeCompanyHasAdmins: state.auth.user.activeCompany.hasAdministratorUsers,
  activeCompanyHasBuyerAdmins: state.auth.user.activeCompany.roleBuyer?.hasAdministratorUsers,
  activeCompanyHasSellerAdmins: state.auth.user.activeCompany.roleSeller?.hasAdministratorUsers,
  activeCompanyHasConfirmingAdmins: state.auth.user.activeCompany.roleConfirming?.hasAdministratorUsers
});

export default withRouter(connect(mapStateToProps)(NotificationContainerComponent));
