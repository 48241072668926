/* @flow */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function IconTrash(props) {
  return (
    <SvgIcon viewBox="0 0 54.34 61.37" {...props}>
      <path d="M447,276.55c.83-.06,1.27-.47,1.29-1.23a1.13,1.13,0,0,0-1.15-1.32,9,9,0,0,0-1.17-.07H433.34l-.07-.41c0-.21-.08-.41-.11-.6-.07-.38-.14-.75-.19-1.11l-.06-.4c-.29-2-.54-3.7-2.72-4.22h-17.7a3,3,0,0,0-2.63,3.12,13.63,13.63,0,0,1-.34,2.23c-.07.32-.14.66-.19,1l-.08.4h-7.69c-2.24,0-3.95,0-5.55,0a3,3,0,0,0-1.74.46,1.48,1.48,0,0,0-.25,1.11,2.23,2.23,0,0,0,1.38,1,8.93,8.93,0,0,0,1.47.1l1.1,0,1.14,0h.43l.06.43c0,.16,0,.3.07.43a5.09,5.09,0,0,1,.1.73c.41,6,.83,12.44,1.25,18.73.62,9.6,1.24,19.16,1.76,27.24a4.48,4.48,0,0,0,4.33,4.38c4.69,0,9.43,0,14.07,0s9.38,0,14,0a4.27,4.27,0,0,0,4.24-4.25c.06-1.25.15-2.52.23-3.75l.06-.82.48-6.77c.31-4.41.63-9,.93-13.43.21-3.18.41-6.42.6-9.55.15-2.41.3-4.89.46-7.34.08-1.26.18-2.49.29-3.79,0-.59.1-1.18.15-1.79l0-.45h2.88C446.12,276.59,446.6,276.58,447,276.55Zm-34.9-3.26.33-2.54,0-.32.05-.43H430l.07.41.47,2.86.09.58H412.05Zm27.81,3.87c0,.7-.08,1.4-.11,2.1-.09,1.53-.17,3.11-.27,4.66s-.24,3.34-.36,5c-.1,1.35-.2,2.76-.3,4.14-.43,6.32-.82,12.08-1.18,17.61l-.08,1.21c-.25,3.72-.5,7.56-.71,11.35-.12,2.17-.64,2.67-2.78,2.67H408c-2,0-2.57-.57-2.7-2.66-.2-3.3-.43-6.67-.65-9.92-.17-2.48-.34-5-.51-7.56-.14-2.19-.27-4.4-.4-6.55s-.27-4.59-.42-6.88c-.16-2.45-.33-4.94-.5-7.34-.14-1.87-.27-3.81-.4-5.71,0-.46,0-.92,0-1.4,0-.23,0-.47,0-.72v-.5H440Z" transform="translate(-393.99 -267.18)" />
      <g>
        <path d="M417.1,299.67q0,5.06,0,10.11c0,1.3-.48,2-1.41,2s-1.46-.64-1.46-1.93q0-10.17,0-20.34c0-1.27.52-2,1.41-2s1.46.77,1.46,2.09Q417.11,294.62,417.1,299.67Z" transform="translate(-393.99 -267.18)" />
        <path d="M428.1,299.67q0,5.06,0,10.11c0,1.3-.48,2-1.41,2s-1.46-.64-1.46-1.93q0-10.17,0-20.34c0-1.27.52-2,1.41-2s1.46.77,1.46,2.09Q428.11,294.62,428.1,299.67Z" transform="translate(-393.99 -267.18)" />
      </g>
    </SvgIcon>
  );
}
