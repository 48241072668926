/* @flow */

import {makeStyles} from '@material-ui/core/styles';

import StyledTableRowComponent from './row';

type StyledTableRowBuyerInvoiceComponentProps = {
  isAssigned: boolean;
  isToBePaid: boolean;
}

const useStyles = makeStyles(_ => ({
  normal: {
    backgroundColor: '#e6ffff',
  },
  assigned: {
    backgroundColor: '#e6ffe6',
  },
  toBePaid: {
    backgroundColor: '#ffffff',
  }
}));

export default function StyledTableRowBuyerInvoiceComponent(props: StyledTableRowBuyerInvoiceComponentProps){
  const classes = useStyles();
  const {isAssigned, isToBePaid, ...rest} = props;

  let style = classes.normal;
  if (isAssigned) style = classes.assigned;
  if (isToBePaid) style = classes.toBePaid;

  return (
    <StyledTableRowComponent className={style} {...rest}/>
  );
}
