/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';

import DebtorInvoicesToPayTableComponent from 'app/debtors/invoicesToPayTable';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

export default function DebtorInvoicesToApproveTableWithTitleComponent() {
  return (
    <React.Fragment>
      <PageSubTitleViewComponent id="debtor.dashboard.invoicesToPay.title" data-walkme="debtor-invoicesToPay" />

      <Box mt={3}>
        <DebtorInvoicesToPayTableComponent />
      </Box>
    </React.Fragment>
  );
}
