/* @flow */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function IconAddOutlined(props) {
  return (
    <SvgIcon viewBox="0 0 62.78 62.8" {...props}>
      <g>
        <path d="M421.57,266.13a31.4,31.4,0,1,0,30.93,31.39A31.51,31.51,0,0,0,421.57,266.13Zm-.49,58.61a27.19,27.19,0,1,1,27.22-27.08A27.16,27.16,0,0,1,421.08,324.74Z" transform="translate(-389.72 -266.13)" fill="#9c3" />
        <path d="M437.19,295.43h-14V281.65c0-.31-.05-.62-.06-.93a2.06,2.06,0,0,0-2.24-1.94,2.11,2.11,0,0,0-1.87,2.24c.05,4.5,0,9,0,13.5v.91h-13.9a6.32,6.32,0,0,0-1.23.1,2,2,0,0,0-1.5,2.59c.37,1.11,1.07,1.53,2.62,1.54h13c.3,0,.6,0,1,0v1c0,4.52,0,9.05,0,13.57a2,2,0,0,0,1.73,2.05,2.09,2.09,0,0,0,2.25-1.38,4.92,4.92,0,0,0,.16-1.38q0-6.48,0-12.95v-.94h13.62a9.5,9.5,0,0,0,1.31-.09,2,2,0,0,0,1.61-2.64C439.41,295.85,438.7,295.44,437.19,295.43Z" transform="translate(-389.72 -266.13)" fill="#9c3" />
      </g>
    </SvgIcon>
  );
}
