/* @flow */

import React from 'react';
import { useSelector } from 'react-redux';

import MuiPhoneNumber from 'material-ui-phone-number';

export default function PhoneNumberComponent(props) {
  const { countryMapping, locale } = useSelector(state => ({
    countryMapping: state.locale.phoneCountriesMapping,
    locale: state.locale.locale
  }));
  const defaultCountry = locale.slice(-2).toLowerCase();
  var stringified = JSON.stringify(countryMapping);
  stringified = stringified.split('"key":').join('');
  stringified = stringified.split(',"value"').join('');
  stringified = stringified.split('{').join('');
  stringified = stringified.split('}').join('');
  stringified = stringified.split('"').join('');
  stringified = stringified.replace('[', '');
  stringified = stringified.replace(']', '');
  var properties = stringified.split(',');
  var obj = {};
  properties.forEach(function (property) {
    var tup = property.split(':');
    obj[tup[0]] = tup[1];
  });
  const countryMappingFixed = obj;
  return (
    <MuiPhoneNumber
      disableAreaCodes={true}
      countryCodeEditable={true}
      autoFormat={false}
      placeholder=''
      defaultCountry={defaultCountry}
      preferredCountries={['be', 'fr', 'nl', 'pt', 'es' , 'lu']}
      localization={countryMappingFixed}
      {...props}
    />
  );
}
