/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';

import SellInvoiceTab5FixFinancedPricesComponent from './fixFinancedPrices';
import SellInvoiceTab5FixUnfinancedPricesComponent from './fixUnfinancedPrices';

import InvoiceProposal from 'models/invoices/seller/invoiceProposal';

type SellInvoiceTab5FixPricesComponentProps = {
  invoice: InvoiceProposal;
  isProcessing: boolean;
  submitInvoice: (isFinanced: boolean) => void;
  cancelInvoice: () => void;
}

export default function SellInvoiceTab5FixPricesComponent(props: SellInvoiceTab5FixPricesComponentProps) {

  return (
    <React.Fragment>
      <Box mt={3} py={3} bgcolor="lightblue.main">
        <Grid container direction="row" justify="space-between" alignItems="flex-start">
          <Grid item xs={12} md={6}>
            <Box px={3}>
              <SellInvoiceTab5FixFinancedPricesComponent
                invoice={props.invoice}
                isProcessing={props.isProcessing}
                submitInvoice={props.submitInvoice}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box px={3}>
              <SellInvoiceTab5FixUnfinancedPricesComponent
                invoice={props.invoice}
                isProcessing={props.isProcessing}
                submitInvoice={props.submitInvoice}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box mt={5}>
        <ButtonComponent
          color="default"
          variant="contained"
          isProcessing={props.isProcessing}
          onClick={props.cancelInvoice}
        ><IntlMessageComponent id="page.sellInvoice.button.cancelInvoice" /></ButtonComponent>
      </Box>
    </React.Fragment>
  );
}
