/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import {makeStyles} from '@material-ui/core/styles';

type WizardComponentProps = {
  activeStepId: number;
  steps: {id: number, title: IntlMessageComponent}[];
  canNavigateToTab: (id: number) => void;
  handleTabClick: (id: number) => void;
}

const useStyles = makeStyles(_ => ({
  stepperRoot: {
    padding: 0
  },
  stepRoot: {
    flex: '1 1 auto',
    padding: 24,
    backgroundColor: '#eaeaea',
    borderRight: '1px solid white',
  },
  stepRootActive: {
    flex: '1 1 auto',
    padding: 24,
    backgroundColor: 'white',
    borderRight: '1px solid white',
  },
  stepRootCompleted: {
    flex: '1 1 auto',
    padding: 24,
    backgroundColor: '#ebf5d6',
    borderRight: '1px solid white',
  },
  stepLabelCompleted: {
    color: '#9c3 !important'
  }
}));

export default function WizardComponent(props: WizardComponentProps) {
  const classes = useStyles();

  const onTabClick = (stepId) => {
    if (props.handleTabClick) {
      props.handleTabClick(stepId);
    }
  }

  const activeStep = props.steps.findIndex(s => s.id === props.activeStepId);

  const stepLabelClasses = {
    completed: classes.stepLabelCompleted
  };

  return (
    <Paper elevation={0}>
      <Box pb={5}>
        <Stepper
          activeStep={activeStep}
          className={classes.stepperRoot}
          connector={null}
        >
          {props.steps.map(step => {

            const stepClasses = { root: classes.stepRoot};
            if (props.activeStepId === step.id) stepClasses.root = classes.stepRootActive;
            else if (props.activeStepId > step.id) stepClasses.root = classes.stepRootCompleted;

            return (
              <Step
                key={`step-${step.id}`}
                classes={stepClasses}
                onClick={() => onTabClick(step.id)}
                style={{cursor: props.canNavigateToTab && props.canNavigateToTab(step.id) ? 'pointer' : 'initial'}}
              >
                <StepLabel classes={stepLabelClasses}>{step.title}</StepLabel>
              </Step>
            );
          })}
        </Stepper>

        <Box px={8} py={6}>
          {props.children}
        </Box>
      </Box>
    </Paper>
  );
}
