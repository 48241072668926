/* @flow */

import {
  ON_SHOW_AUTH_LOADER,
  ON_HIDE_AUTH_LOADER,
  SIGNIN_AFTER_REGISTRATION_EDEBEX_USER_SAGA,
  SIGNIN_EDEBEX_USER_SAGA,
  SIGNIN_RETURNING_EDEBEX_USER_SAGA,
  SIGNIN_EDEBEX_USER_SUCCESS,
  SIGNIN_EDEBEX_USER_ERROR,
  SIGNIN_EDEBEX_USER_SERVER_ERROR,
  SIGNIN_EDEBEX_IMPERSONATE_ERROR,
  SIGNOUT_EDEBEX_USER_SAGA,
  SIGNOUT_EDEBEX_USER_SUCCESS,
  IMPERSONATE_EDEBEX_USER_SAGA,
  SWITCH_USER_COMPANY_SAGA,
  RELOAD_USER_COMPANY_SAGA,
  SWITCH_USER_COMPANY_SUCCESS,
  SWITCH_USER_COMPANY_ROLE_SUCCESS,
  SWITCH_USER_COMPANY_INVESTMENTPROFILE_SUCCESS,
  ADD_USER_COMPANY_SUCCESS,
  ADD_USER_COMPANY_SAGA,
  ADD_INVESTMENT_PROFILE_SUCCESS,
  SET_IDENTITY_DOCUMENTS_SAGA,
  SET_IDENTITY_DOCUMENTS_SUCCESS,
  DISABLE_USER_COMPANY_INVESTMENTPROFILE_SUCCESS
} from '../actionTypes';

export const showAuthLoader = () => ({
  type: ON_SHOW_AUTH_LOADER
});

export const hideAuthLoader = () => ({
  type: ON_HIDE_AUTH_LOADER
});

export const userSignInAfterRegistrationAction = (username, password, twofactorpin) => ({
  type: SIGNIN_AFTER_REGISTRATION_EDEBEX_USER_SAGA,
  payload: {username, password, twofactorpin}
});

export const userSignInAction = (username, password, returnUrl, twofactorpin) => ({
  type: SIGNIN_EDEBEX_USER_SAGA,
  payload: {
    username,
    password,
    returnUrl,
    twofactorpin
  }
});

export const userSignInReturningAction = returnUrl => ({
  type: SIGNIN_RETURNING_EDEBEX_USER_SAGA,
  payload: {
    returnUrl
  }
});

export const userSignInSuccess = user => ({
  type: SIGNIN_EDEBEX_USER_SUCCESS,
  payload: {
    user
  }
});

export const userSignInError = error => ({
  type: SIGNIN_EDEBEX_USER_ERROR,
  payload: {
    error
  }
});

export const userSignInServerError = () => ({
  type: SIGNIN_EDEBEX_USER_SERVER_ERROR
});

export const userSignInImpersonateError = error => ({
  type: SIGNIN_EDEBEX_IMPERSONATE_ERROR,
  payload: {
    error
  }
});

export const userSignOutAction = () => ({
  type: SIGNOUT_EDEBEX_USER_SAGA
});

export const userSignOutSuccess = () => ({
  type: SIGNOUT_EDEBEX_USER_SUCCESS
});

export const impersonateUserAction = (username, password, target, twofactorpin) => ({
  type: IMPERSONATE_EDEBEX_USER_SAGA,
  payload: { username, password, target, twofactorpin }
});


export const userSwitchCompanyAction = companyId => ({
  type: SWITCH_USER_COMPANY_SAGA,
  payload: { companyId }
});

export const userReloadCompanyAction = companyId => ({
  type: RELOAD_USER_COMPANY_SAGA,
  payload: { companyId }
})

export const userSwitchCompanySuccess = company => ({
  type: SWITCH_USER_COMPANY_SUCCESS,
  payload: { company }
});

export const userSwitchCompanyRoleAction = role => ({
  type: SWITCH_USER_COMPANY_ROLE_SUCCESS,
  payload: { role }
});

export const userSwitchCompanyInvestmentProfileAction = investmentProfile => ({
  type: SWITCH_USER_COMPANY_INVESTMENTPROFILE_SUCCESS,
  payload: { investmentProfile }
});

export const addCompanyAction = companyId => ({
  type: ADD_USER_COMPANY_SAGA,
  payload: { companyId }
});

export const addCompanySuccess = user => ({
  type: ADD_USER_COMPANY_SUCCESS,
  payload: {user}
});

export const addInvestmentProfileSuccess = investmentProfile => ({
  type: ADD_INVESTMENT_PROFILE_SUCCESS,
  payload: { investmentProfile }
});

export const setIdentityDocumentsAction = () => ({
  type: SET_IDENTITY_DOCUMENTS_SAGA
});

export const setIdentityDocumentsSuccess = () => ({
  type: SET_IDENTITY_DOCUMENTS_SUCCESS
});

export const disableCompanyInvestmentProfileAction = investmentProfileId => ({
  type: DISABLE_USER_COMPANY_INVESTMENTPROFILE_SUCCESS,
  payload: { investmentProfileId }
});