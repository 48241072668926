/* @flow */

export default class FilterPeriod {
  dateFrom: moment;
  dateTo: moment;

  constructor() {
    this.dateFrom = null;
    this.dateTo = null;
  }
}
