/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import customSort from 'lib/sortHelpers';

import LoadingComponent from 'components/loading';
import ActiveFilterComponent from 'components/filters/activeFilter';

import CompanyOfficeTableViewComponent from './view';

import Office from 'models/companies/office';

import IsoService from 'services/IsoService';
import DebtorService from 'services/DebtorService';

import ActivateOfficeRequest from 'models/requests/companies/offices/activateOfficeRequest';

import { ROLE_ISO, ROLE_DEBTOR } from 'constants/companyRoles';

type CompanyOfficeTableComponentProps = {
  activeCompanyId: number;
  activeCompanyRole: number;
  newOffice: () => void;
  editOffice: (office: Office) => void;
}

type CompanyOfficeTableComponentState = {
  offices: Office[];
  filteredOffices: Office[];
  isLoading: boolean;
  order: string;
  orderBy: string;
  page: number;
  pageSize: number;
  isActiveFilter: boolean;
}

class CompanyOfficeTableComponent extends React.Component<CompanyOfficeTableComponentProps, CompanyOfficeTableComponentState> {

  constructor(props) {
    super(props);
    this.state = {
      offices: [],
      isLoading: true,
      order: 'asc',
      orderBy: 'name',
      page: 0,
      pageSize: 10,
      isActiveFilter: true
    };
  }

  componentDidMount = async () => {
    try {
      let offices = [];
      let filteredOffices = [];
    
      if (this.props.activeCompanyRole === ROLE_DEBTOR) {
        offices = await DebtorService.getOffices(this.props.activeCompanyId);
      } else if (this.props.activeCompanyRole === ROLE_ISO) {
        offices = await IsoService.getOffices(this.props.activeCompanyId);
      }

      filteredOffices = offices.filter(office => office.isActive);

      this.setState({isLoading: false, offices, filteredOffices});
    } catch (error) {
      console.error(error);
    }
  }

  handlePageChange = (page: number) => this.setState({page});
  handlePageSizeChange = (pageSize: number) => this.setState({pageSize});
  
  handleSortOrderChange = (orderBy: string) => {
    let order = this.state.orderBy === orderBy && this.state.order === 'desc' ? 'asc' : 'desc';

    const filteredOffices = order === 'desc'
      ? this.state.filteredOffices.sort((a, b) => customSort(b[orderBy], a[orderBy]))
      : this.state.filteredOffices.sort((a, b) => customSort(a[orderBy], b[orderBy]));

    this.setState({filteredOffices, order, orderBy});
  };

  toggleOfficeActiveStatus = async (office: Office) => {
    try {
      const request = new ActivateOfficeRequest();
      request.companyId = this.props.activeCompanyId;
      request.officeId = office.id;
      if (office.isActive) {
        await IsoService.deactivateOffice(request)
      } else {
        await IsoService.activateOffice(request);
      }

      let offices = [];
      let filteredOffices = [];
    
      if (this.props.activeCompanyRole === ROLE_DEBTOR) {
        offices = await DebtorService.getOffices(this.props.activeCompanyId);
      } else if (this.props.activeCompanyRole === ROLE_ISO) {
        offices = await IsoService.getOffices(this.props.activeCompanyId);
      }

      if (this.state.isActiveFilter) {
        filteredOffices = offices.filter(office => office.isActive);
      } else {
        filteredOffices = offices;
      }

      this.setState({offices, filteredOffices});
    } catch (error) {
      console.error(error);
    }
  };

  handleActiveFilterChange = (event) => {
    let filteredOffices = [];
    if (event.target.value) {
      filteredOffices = this.state.offices.filter(office => office.isActive);
    } else {
      filteredOffices = this.state.offices;
    }
    this.setState({isActiveFilter: event.target.value, filteredOffices});
  }

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <React.Fragment>
        <ActiveFilterComponent 
          isActiveFilter={this.state.isActiveFilter}
          handleActiveFilterChange={this.handleActiveFilterChange}
        />
        <CompanyOfficeTableViewComponent
          offices={this.state.filteredOffices}
          order={this.state.order}
          orderBy={this.state.orderBy}
          page={this.state.page}
          pageSize={this.state.pageSize}
          newOffice={this.props.newOffice}
          editOffice={this.props.editOffice}
          handlePageChange={this.handlePageChange}
          handlePageSizeChange={this.handlePageSizeChange}
          handleSortOrderChange={this.handleSortOrderChange}
          toggleOfficeActiveStatus={this.toggleOfficeActiveStatus}
        />
      </React.Fragment>
      
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id,
  activeCompanyRole: state.auth.user.activeCompany.role
});

export default connect(mapStateToProps)(CompanyOfficeTableComponent);
