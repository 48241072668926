/* @flow */

export default class DownloadInvoiceReportRequest {
  companyId: number;
  format: string;
  isoCompanyIds: number[];
  isoOfficeIds: number[];
  isoPersonIds: number[];
  sellerCompanyIds: number[];
  isFinanced: boolean[];

  constructor(companyId: number, format: string) {
    this.companyId = companyId;
    this.format = format;
    this.isoCompanyIds = [];
    this.isoOfficeIds = [];
    this.isoPersonIds = [];
    this.sellerCompanyIds = [];
    this.isFinanced = [];
  }

  toRouteParameters() {
    let route = `companyId=${this.companyId}&format=${this.format}`;

    this.isoCompanyIds.forEach(isoCompanyId => {
      route += `&isoCompanyIds=${isoCompanyId}`;
    });
    this.isoOfficeIds.forEach(isoOfficeId => {
      route += `&isoOfficeIds=${isoOfficeId}`;
    });
    this.isoPersonIds.forEach(isoPersonId => {
      route += `&isoPersonIds=${isoPersonId}`;
    });
    this.sellerCompanyIds.forEach(sellerCompanyId => {
      route += `&sellerCompanyIds=${sellerCompanyId}`;
    });
    this.isFinanced.forEach(option => {
      route += `&isFinanced=${option}`;
    });
    return route;
  }
}
