/* @flow */

import CommissionInvoiceIsoOffice from './commissionInvoiceIsoOffice';

export default class CommissionInvoiceIsoCompany {
  id: number;
  name: string;
  offices: CommissionInvoiceIsoOffice[];
  debtAmount: number;
  financedAmount: number;
  paidServiceFee: number;
  invoiceCommission: number;

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
    this.offices = [];
    this.debtAmount = 0;
    this.financedAmount = 0;
    this.paidServiceFee = 0;
    this.invoiceCommission = 0;
  }
}
