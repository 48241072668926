/* @flow */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  imageIcon: {
    display: 'flex',
    height: 'inherit',
    width: 'inherit',
  },
  iconRoot: {
    textAlign: 'center'
  }
});


export default function IconMarketPlaceConfirming(props) {
  const classes = useStyles();

  return (
    <Icon classes={{ root: classes.iconRoot }}>
      <img className={classes.imageIcon} src="./images/svg/insured-confirming.svg" />
    </Icon>
  );
}
