/* @flow */

import { plainToClass } from 'class-transformer';

import BaseService from './api/baseService';

import {
  LEAD_GET,
  LEAD_POST,
  LEAD_ELIGIBILITY_QUESTIONNAIRE_CHECK_GET,
  LEAD_ELIGIBILITY_QUESTIONNAIRE_QUESTIONS_GET,
  LEAD_ELIGIBILITY_QUESTIONNAIRE_ANSWER_POST,
  LEAD_ELIGIBILITY_QUESTIONNAIRE_CONFIRM_POST,
  LEAD_BUSINESS_OPPORTUNITY_POST,
  LEAD_OFFER_DEFAULTS_GET,
  LEAD_OFFER_GET,
  LEAD_OFFER_POST,
  LEAD_OFFER_SEND_POST,
  LEAD_OFFER_UC_SEND_POST,
  LEAD_ALTFIN_POST,
  LEAD_BUSINESS_POTENTIALS_GET,
  LEAD_BUSINESS_POTENTIAL_POST,
  LEAD_BUSINESS_POTENTIAL_DELETE,
  LEAD_BUSINESS_POTENTIALS_CONFIRM_POST,
  LEAD_CALLBACK_TIME_POST
} from 'constants/apiRoutes';

import ConfirmBusinessPotentialsRequest from 'models/requests/sellers/deleteBusinessPotentialRequest';
import ConfirmEligibilityAnswersRequest from 'models/requests/leads/confirmEligibilityAnswersRequest';
import CreateLeadRequest from 'models/requests/leads/createLeadRequest';
import CreateLeadOfferRequest from 'models/requests/leads/createLeadOfferRequest';
import DeleteBusinessPotentialRequest from 'models/requests/sellers/deleteBusinessPotentialRequest';
import SendLeadOfferRequest from 'models/requests/leads/sendLeadOfferRequest';
import SetAlternativeFinancingRequest from 'models/requests/companies/setAlternativeFinancingRequest';
import SetEligibilityAnswerRequest from 'models/requests/leads/setEligibilityAnswerRequest';
import SetBusinessOpportunityRequest from 'models/requests/leads/setBusinessOpportunityRequest';
import UpsertBusinessPotentialRequest from 'models/requests/sellers/upsertBusinessPotentialRequest';
import OfferUnknownCompanyRequest from 'models/requests/leads/offerUnknownCompanyRequest';

import BusinessPotential from 'models/sellers/businessPotential';
import BusinessPotentials from 'models/sellers/businessPotentials';
import Company from 'models/companies/company';
import DebtorCompany from 'models/companies/debtorCompany';
import EligibilityAnswer from 'models/leads/eligibilityAnswer';
import EligibilityQuestion from 'models/leads/eligibilityQuestion';
import EligibilityQuestionnaire from 'models/leads/eligibilityQuestionnaire';
import EligibilityQuestionnaireResult from 'models/leads/eligibilityQuestionnaireResult';
import LeadOfferDefaults from 'models/leads/leadOfferDefaults';
import LeadOffer from 'models/leads/leadOffer';
import CallbackRequest from 'models/requests/leads/callbackRequest';

export default class LeadService {

  static getLeadId = (companyId: number, isoCompanyId: number) => BaseService.simpleGET(`${LEAD_GET}/${companyId}/${isoCompanyId}`);

  static createLead = (request: CreateLeadRequest) =>
    BaseService.anonymousPOST(LEAD_POST, request, null);

  static checkEligibility = (leadId: number) =>
    BaseService.simpleGET(`${LEAD_ELIGIBILITY_QUESTIONNAIRE_CHECK_GET}/${leadId}`,
      data => plainToClass(EligibilityQuestionnaire, data));

  static getEligibilityQuestions = (leadId: number) =>
    BaseService.simpleGET(`${LEAD_ELIGIBILITY_QUESTIONNAIRE_QUESTIONS_GET}/${leadId}`,
      data => data.map(d => {
        const question = plainToClass(EligibilityQuestion, d);
        question.answers = d.answers.map(a => plainToClass(EligibilityAnswer, a));
        return question;
      }));

  static setEligibilityAnswer = (request: SetEligibilityAnswerRequest) =>
    BaseService.anonymousPOST(LEAD_ELIGIBILITY_QUESTIONNAIRE_ANSWER_POST, request, null);

  static setCallbackTime = (request: CallbackRequest) =>
    BaseService.anonymousPOST(LEAD_CALLBACK_TIME_POST, request, null);

  static setBusinessOpportunity = (request: SetBusinessOpportunityRequest) =>
    BaseService.anonymousPOST(LEAD_BUSINESS_OPPORTUNITY_POST, request, null);

  static confirmEligibilityAnswers = (request: ConfirmEligibilityAnswersRequest) =>
    BaseService.anonymousPOST(LEAD_ELIGIBILITY_QUESTIONNAIRE_CONFIRM_POST, request, data => plainToClass(EligibilityQuestionnaireResult, data));

  static getLeadOfferDefaults = (leadId: number, debtorCompanyId: number) =>
    BaseService.simpleGET(`${LEAD_OFFER_DEFAULTS_GET}/${leadId}/${debtorCompanyId}`,
      data => plainToClass(LeadOfferDefaults, data));

  static getLeadOffer = (leadOfferId: number) =>
    BaseService.simpleGET(`${LEAD_OFFER_GET}/${leadOfferId}`,
      data => {
        const leadOffer = plainToClass(LeadOffer, data);
        leadOffer.sellerCompany = plainToClass(Company, data.sellerCompany);
        leadOffer.debtorCompany = plainToClass(DebtorCompany, data.debtorCompany);
        return leadOffer;
      });

  static createLeadOffer = (request: CreateLeadOfferRequest) =>
    BaseService.anonymousPOST(LEAD_OFFER_POST, request, null);

  static sendLeadOffer = (request: SendLeadOfferRequest) =>
    BaseService.simplePOST(LEAD_OFFER_SEND_POST, request, null);

  static sendLeadOfferUnknownCompany = (request: OfferUnknownCompanyRequest) =>
    BaseService.simplePOST(LEAD_OFFER_UC_SEND_POST, request, null);

  static setAlternativeFinancing = (request: SetAlternativeFinancingRequest) =>
    BaseService.anonymousPOST(LEAD_ALTFIN_POST, request, null);

  static getBusinessPotentials = (companyId: number) =>
    BaseService.simpleGET(`${LEAD_BUSINESS_POTENTIALS_GET}/${companyId}`,
      data => {
        const response = plainToClass(BusinessPotentials, data);
        response.businessPotentials = data.businessPotentials.map(bp => {
          const businessPotential = plainToClass(BusinessPotential, bp);
          businessPotential.debtorCompany = plainToClass(DebtorCompany, bp.debtorCompany);
          return businessPotential;
        });
        return response;
      });

  static upsertBusinessPotential = (request: UpsertBusinessPotentialRequest) =>
    BaseService.simplePOST(LEAD_BUSINESS_POTENTIAL_POST, request, null);

  static deleteBusinessPotential = (request: DeleteBusinessPotentialRequest) =>
    BaseService.simpleDELETE(LEAD_BUSINESS_POTENTIAL_DELETE, request, null);

  static confirmBusinessPotentials = (request: ConfirmBusinessPotentialsRequest) =>
    BaseService.simplePOST(LEAD_BUSINESS_POTENTIALS_CONFIRM_POST, request, null);
}
