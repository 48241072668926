

/* @flow */

import React from 'react';

import IntlMessageComponent from './intlMessage';

type BooleanFormatterComponentProps = {
  value: boolean;
}

export default function BooleanFormatterComponent(props: BooleanFormatterComponentProps) {
  return (<IntlMessageComponent id={`${props.value ? 'general.label.yes' : 'general.label.no'}`} />);
}
