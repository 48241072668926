/* @flow */

import React from 'react';
import { connect } from 'react-redux';

import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

import LoadingComponent from 'components/loading';

import CompanyUBOsFormComponent from './form';
import CompanyUBOsListComponent from './list';
import ActiveFilterComponent from 'components/filters/activeFilter';
import SecurityComponent from 'components/security/index';

import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import CompanyUBO from 'models/companies/companyUBO';
import ConfirmCompanyUBOsRequest from 'models/requests/companies/ubos/confirmCompanyUBOsRequest';
import DisableCompanyUBORequest from 'models/requests/companies/ubos/disableCompanyUBORequest';
import EnableCompanyUBORequest from 'models/requests/companies/ubos/enableCompanyUBORequest';

import CompanyService from 'services/CompanyService';
import { handleApiFormResponse } from 'lib/forms';

import { COMPANY_UBO_STATUS_DRAFT, COMPANY_UBO_STATUS_SUBMITTED, COMPANY_UBO_STATUS_EXPIRED, COMPANY_UBO_STATUS_EXPIRESOON, COMPANY_UBO_STATUS_NEW } from 'constants/constants';

type CompanyUbosComponentProps = {
  activeCompanyId: number;
  setUserCompanyCanExecute: () => void;
}

type CompanyUbosComponentState = {
  displayForm: boolean;
  editMode: boolean;
  formErrors: string[];
  isLoading: boolean;
  isProcessing: boolean;
  status: number[];
  ubo: CompanyUBO;
  ubos: CompanyUBO[];
  filteredUbos: CompanyUBO[];
  isActiveFilter: boolean;
}

class CompanyUbosComponent extends React.Component<CompanyUbosComponentProps, CompanyUbosComponentState> {

  constructor(props) {
    super(props);
    this.state = {
      displayForm: false,
      editMode: true,
      formErrors: [],
      isLoading: true,
      isProcessing: false,
      isActiveFilter: false,
      status: 0,
      ubo: null,
      ubos: null
    };
  }

  componentDidMount = async () => {
    try {
      // Load ubos and make them into an array of UBOForm
      const response = await CompanyService.getCompanyUBOs(this.props.activeCompanyId);
      const filteredUbos = response.ubos;
      this.setState({ filteredUbos, isLoading: false, status: response.status, ubos: response.ubos });
    } catch (e) {
      console.error(e);
    }
  };

  toggleEditMode = () => {
    this.setState({ editMode: true });
  }

  toggleUBOActiveStatus = async (id: number) => {
    const ubo = this.state.ubos.find(u => u.id === id);
    if (!ubo) return null;

    try {
      this.setState({ formErrors: [], isProcessing: true });

      if (ubo.isActive) {
        const request = new DisableCompanyUBORequest(this.props.activeCompanyId, id);
        await CompanyService.disableCompanyUBO(request);
        ubo.isActive = false;
      } else {
        const request = new EnableCompanyUBORequest(this.props.activeCompanyId, id);
        await CompanyService.enableCompanyUBO(request);
        ubo.isActive = true;
      }
      let filteredUbos = [];
      if (this.state.isActiveFilter) {
        filteredUbos = this.state.ubos.filter(ubo => ubo.isActive);
      } else {
        filteredUbos = this.state.ubos;
      }
      this.setState({ filteredUbos, isProcessing: false, status: COMPANY_UBO_STATUS_DRAFT });
    } catch (error) {
      handleApiFormResponse.call(this, error);
    }
  }

  newUBO = () => {
    this.setState({ displayForm: true, formErrors: [], ubo: null });
  }

  editUBO = (id: number) => {
    const ubo = this.state.ubos.find(ubo => ubo.id === id);
    if (!ubo) return;
    this.setState({ displayForm: true, formErrors: [], ubo });
  }

  handleFormCancel = () => {
    this.setState({ displayForm: false, formErrors: [], ubo: null });
  }

  handleFormSuccess = async () => {
    try {
      this.setState({ displayForm: false, formErrors: [], isLoading: true, ubo: null });

      // Load ubos and make them into an array of UBOForm
      const response = await CompanyService.getCompanyUBOs(this.props.activeCompanyId);
      let filteredUbos = [];
      if (this.state.isActiveFilter) {
        filteredUbos = response.ubos.filter(ubo => ubo.isActive);
      } else {
        filteredUbos = response.ubos;
      }

      this.setState({ filteredUbos, isLoading: false, status: COMPANY_UBO_STATUS_DRAFT, ubos: response.ubos });
    } catch (e) {
      console.error(e);
    }
  }

  currentShares = () => {
    let totalShares = 0;
    const editUbo = this.state.ubo
    this.state.ubos.forEach(function (ubo) {
      if (ubo.id === editUbo?.id || !ubo.isActive)
        totalShares += 0;
      else
        totalShares += ubo.shares;
    })
    return totalShares;
  }

  confirmUBOs = async () => {
    this.setState({ formErrors: [], isProcessing: true });

    // check shares not exceeding 100
    let totalShares = 0;
    for (let i = 0; i < this.state.ubos.length; i++) {
      if (this.state.ubos[i].isActive) {
        totalShares += this.state.ubos[i].shares;
      }
    }

    if (totalShares > 100) {
      const formErrors = ['val.ubos.totalShares'];
      this.setState({ formErrors, isProcessing: false });
      return;
    }

    try {
      const request = new ConfirmCompanyUBOsRequest(this.props.activeCompanyId);
      await CompanyService.confirmCompanyUBOs(request);
      this.setState({ isProcessing: false, status: COMPANY_UBO_STATUS_SUBMITTED });
    } catch (error) {
      handleApiFormResponse.call(this, error);
    }
  }

  handleActiveFilterChange = (event) => {
    let filteredUbos = [];
    if (event.target.value) {
      filteredUbos = this.state.ubos.filter(ubo => ubo.isActive);
    } else {
      filteredUbos = this.state.ubos;
    }
    this.setState({ isActiveFilter: event.target.value, filteredUbos });
  }

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <React.Fragment>
        {this.state.displayForm ? (
          <CompanyUBOsFormComponent
            ubo={this.state.ubo}
            currentShares={this.currentShares()}
            isExpired={this.state.status === COMPANY_UBO_STATUS_NEW || this.state.status == COMPANY_UBO_STATUS_EXPIRED || this.state.status == COMPANY_UBO_STATUS_DRAFT || this.state.status == COMPANY_UBO_STATUS_EXPIRESOON}
            onCancel={this.handleFormCancel}
            onSuccess={this.handleFormSuccess}
          />
        ) : (
          <React.Fragment>
            <ActiveFilterComponent
              isActiveFilter={this.state.isActiveFilter}
              handleActiveFilterChange={this.handleActiveFilterChange}
            />

            <CompanyUBOsListComponent
              editMode={this.state.editMode}
              formErrors={this.state.formErrors}
              isProcessing={this.state.isProcessing}
              status={this.state.status}
              ubos={this.state.filteredUbos}
              confirmUBOs={this.confirmUBOs}
              newUBO={this.newUBO}
              editUBO={this.editUBO}
              toggleEditMode={this.toggleEditMode}
              toggleUBOActiveStatus={this.toggleUBOActiveStatus}
              hasAnyInvalidId={this.state.ubos.filter(u => u.hasValidId == false && u.isActive).length > 0}
              hasAnyMissingField={this.state.ubos.filter(u => u.isActive === true && u.hasMissingFields()).length > 0}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps)(CompanyUbosComponent);
