/* @flow */

import moment from 'moment';

export default class LeadReport {
  // data
  isoCompany: string;
  isoOffice: string;
  isoPerson: string;
  sellerCompany: string;

  leadId: number;
  leadReopenedId: number;
  ownerClass: string;
  convertible: string;
  statusId: number;
  lostReasonId: number;
  creationDate: moment;
  closedDate: moment;
  age: number;
  canBeExtended: boolean;
  canBeClosed: Boolean;

  // filters
  filterISOId: number;
  filterISOOfficeId: number;
  filterISOPersonId: number;
}
