/* @flow */

import {withStyles} from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';

const StyledTableCellNumbersComponent = withStyles(_ => ({
  root: {
    padding: '4px 56px 4px 24px'
  },
  head: {
    lineHeight: '1.5'
  },
  paddingSpace: {
    padding: 0,
    width: 'auto'
  },
  paddingNone: {
    padding: '0 12px',
    width: 'auto'
  },
  paddingLarger: {
    padding: '0 12px',
    minWidth: '120px'
  },
  body: {
    fontSize: '0.8125rem',
    textAlign: 'right'
  },

}))(TableCell);

export default StyledTableCellNumbersComponent;
