const theme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 540,
      md: 720,
      lg: 960,
      xl: 1440,
    },
  },
  palette: {
    primary: { // blue
      light: '#70dbff',
      main: '#3cf',
      dark: '#27c0ff',
      contrastText: '#fff'
    },
    secondary: { // green
      light: '#b8db70',
      main: '#9c3',
      dark: '#86c027',
      contrastText: '#fff'
    },
    tertiary: { // red
      light: '#f26066',
      main: '#ed1c24',
      dark: '#e0090d',
      contrastText: '#fff'
    },
    blue: {
      light: '#70dbff',
      main: '#3cf',
      dark: '#27c0ff',
      contrastText: '#fff'
    },
    darkblue: {
      light: '#167392',
      main: '#167392',
      dark: '#167392',
      contrastText: '#fff'
    },
    darkgrey: {
      light: '#4e4e4e',
      main: '#4e4e4e',
      dark: '#4e4e4e',
      contrastText: '#fff'
    },
    green: {
      light: '#b8db70',
      main: '#99cc33',
      dark: '#86c027',
      contrastText: '#fff'
    },
    grey: {
      light: '#b5b8bd',
      main: '#959aa1',
      dark: '#82888f',
      contrastText: '#fff'
    },
    lightblue: {
      light: '#f0fbff',
      main: '#f0fbff',
      dark: '#e7f9ff',
      contrastText: '#fff'
    },
    lightgrey: {
      light: '#f9f9f9',
      main: '#f7f7f7',
      dark: '#f5f5f5',
      contrastText: '#fff'
    },
    orange: {
      light: '#f69566',
      main: '#f26724',
      dark: '#ee541b',
      contrastText: '#fff'
    },
    red: {
      light: '#f26066',
      main: '#ed1c24',
      dark: '#e0090d',
      contrastText: '#fff'
    },
    background: {
      paper: '#fff',
      default: '#f7f7f7',
      appBar: '#f7f7f7',
      contentFrame: '#f7f7f7',
      chip: '#f7f7f7',
      avatar: '#f7f7f7'
    }
  },
  a: {
    textColor: '#3cf'
  },
  typography: {
    h1: {
      fontSize: 24,
    },
    h2: {
      fontSize: 20,
    },
    h4: {
      fontSize: 16,
      fontWeight: 700
    },
    body1: {
      fontSize: 15,
    },
    subtitle2: {
      fontSize: 13,
      color: '#4e4e4e'
    },
    fontFamily: [
      'Rubik',
      'Arial',
      'sans-serif',
    ].join(','),
    button: {
      fontWeight: 400,
      textAlign: 'capitalize'
    },
  },
  overrides: {
    MuiButton: {
      root: {
        boxShadow: 'none !important',
        borderRadius: '0 !important'
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: 'none !important',
      },
    },
    MuiStepIcon: {
      completed: {
        color: '#9c3 !important'
      }
    },
    MuiSelect: {
      icon: {
        color: '#3cf'
      }
    },
    'page-heading': {
      root: {
        boxShadow: 'none !important',
      },
    }
  },
};

export default theme;
