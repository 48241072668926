/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import UploadFileFieldComponent from 'components/formFields/upload/index';
import Document from 'models/documents/v2/document';

type SubscriptionTab5ProcurationViewComponentProps = {
  isProcessing: boolean;
  hasSignedGC: boolean;
  doc: Document;
  sendProcurationEmail: () => void;
  procurationUnneeded: () => void;
  handleDocumentSelect: () => void;
  handleDocumentSelectFailure: () => void;
  handleDocumentRemove: () => void;
  uploadProcuration: () => void;
}

export default function SubscriptionTab5ProcurationViewComponent(props: SubscriptionTab5ProcurationViewComponentProps) {
  return (
    <React.Fragment>
      {!props.hasSignedGC && <PageSubTitleViewComponent id="page.subscription.tab5.procuration.title" />}
      {props.hasSignedGC && <PageSubTitleViewComponent id="page.subscription.tab5.procuration.title.gcsigned" />}

      {!props.hasSignedGC &&
        <Typography variant="body1" gutterBottom>
          <IntlMessageComponent id="page.subscription.tab5.procuration.text" />
        </Typography>
      }
      {props.hasSignedGC &&
        <Typography variant="body1" gutterBottom>
          <IntlMessageComponent id="page.subscription.tab5.procuration.gcsigned.text" />
        </Typography>
      }

      {!props.hasSignedGC &&
        <React.Fragment>

          <Grid container justify="flex-start" alignItems="flex-start" spacing={1}>
            <Grid item xs={12} md={6}>
              <Box mt={5}>
                <IntlMessageComponent id="page.subscription.tab5.procuration.alreadyhaveprocuration" />
              </Box>
              <Box>
                <UploadFileFieldComponent
                  id={props.doc.code}
                  document={props.doc}
                  filename={props.doc.filename}
                  isMissing={props.doc.isMissing}
                  isRequired={props.doc.isRequired}
                  isOptional={props.doc.isOptional}
                  successHandler={props.handleDocumentSelect}
                  failHandler={props.handleDocumentSelectFailure}
                  removeHandler={props.handleDocumentRemove}
                />
                <ButtonComponent
                  color="primary"
                  variant="contained"
                  isProcessing={props.isProcessing}
                  onClick={props.uploadProcuration}
                  disabled={!props.doc.file}
                ><IntlMessageComponent id="general.uploadProcuration" /></ButtonComponent>
              </Box>
            </Grid>
          </Grid>

          <Box mt={6} >
            <IntlMessageComponent id="page.subscription.tab5.procuration.noprocuration" />
          </Box>
          <Box mt={2} >
            <ButtonComponent
              color="primary"
              variant="contained"
              isProcessing={props.isProcessing}
              onClick={props.sendProcurationEmail}
            ><IntlMessageComponent id="general.sendEmail" /></ButtonComponent>
          </Box>
        </React.Fragment>
      }
      {
        props.hasSignedGC &&
        <Box mt={5}>
          <ButtonComponent
            color="primary"
            variant="contained"
            isProcessing={props.isProcessing}
            onClick={props.sendProcurationEmail}
          ><IntlMessageComponent id="page.subscription.tab5.procuration.send" /></ButtonComponent>
          <Box component="span" ml={1}>
            <ButtonComponent
              color="default"
              variant="contained"
              onClick={props.procurationUnneeded}
            ><IntlMessageComponent id="page.subscription.tab5.procuration.notneeded" /></ButtonComponent>
          </Box>
        </Box>
      }
    </React.Fragment >
  );
}
