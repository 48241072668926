/* @flow */

import React from 'react';

import ISOSellersComponent from 'app/isos/sellers';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function ISOSellersPage() {
  return (
    <WebsitePageComponent id="page.iso.sellers.title" data-walkme="isoinvoices-title-sellers" fluidContainer noPaper>
      <ISOSellersComponent />
    </WebsitePageComponent>
  );
}
