/* @flow */

import Filter from 'models/isos/filters/filter';
import FilterCompany from 'models/isos/filters/filterCompany';
import FilterPeriod from 'models/isos/filters/filterPeriod';

export default class LeadFilters {
  companies: FilterCompany[];
  creationDate: FilterPeriod;
  closedDate: FilterPeriod;
  status: Filter[];
  owners: Filter[];
  convertible: Filter[];
  lostReasons: Filter[];

  constructor(mode: number) {
    this.mode = mode;
    this.companies = [];
    this.creationDate = new FilterPeriod();
    this.closedDate = new FilterPeriod();
    this.status = [];
    this.owners = [];
    this.convertible = [];
    this.lostReasons = [];
    this.openClosedLeads = [];
  }
}
