/* @flow */

import React from 'react';

import IsoContactsComponent from 'app/isos/contacts';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function ISOContactsPage() {
  return (
    <WebsitePageComponent noTitle>
      <IsoContactsComponent />
    </WebsitePageComponent>
  );
}
