/* @flow */

import React from 'react';
import {useLocation, useParams} from "react-router-dom";

import SellerSellInvoiceComponent from 'app/sellers/sell';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function SellerSellInvoicePage() {
  const location = useLocation();
  const {id} = useParams();

  return (
    <WebsitePageComponent id="page.sellInvoice.description" noPaper>
      <SellerSellInvoiceComponent invoiceId={id || 0} leadOffer={location.state?.leadOffer} />
    </WebsitePageComponent>
  );
}
