/* @flow */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function IconPhone(props) {
  return (
    <SvgIcon viewBox="0 0 72.3 72.29" {...props}>
      <path d="M439.86,334.25a34.44,34.44,0,0,1-17.47-4.88c-15.7-8.93-27.87-21.25-35.24-37.93a29,29,0,0,1-2.61-12.34c.1-5.16,2-9.41,6.31-12.37a63.27,63.27,0,0,1,6-3.7,7.24,7.24,0,0,1,9,1.11,35.88,35.88,0,0,1,7.83,9.79,5.64,5.64,0,0,1,0,5.92c-1.72,2.69-3.56,5.32-5.5,7.86-.78,1-.85,1.59,0,2.6a163.64,163.64,0,0,0,20.3,20.31,1.72,1.72,0,0,0,2.64,0c2.44-1.92,5-3.72,7.6-5.4a5.85,5.85,0,0,1,6.4.06,38.65,38.65,0,0,1,9.67,7.79,7.16,7.16,0,0,1,1.41,8,26,26,0,0,1-6.58,9.66A13.65,13.65,0,0,1,439.86,334.25Zm.34-4c2.65-.12,5.27-.62,7-2.74a66.33,66.33,0,0,0,5.15-7.57,3.32,3.32,0,0,0-.74-4.51c-2.62-2.2-5.3-4.34-8-6.46a2.58,2.58,0,0,0-3.37,0c-2.49,1.8-5,3.6-7.43,5.43a4.51,4.51,0,0,1-6.06,0c-1.94-1.56-3.85-3.14-5.7-4.81a148.23,148.23,0,0,1-16.16-17,7.64,7.64,0,0,1-1.33-2.31,4.45,4.45,0,0,1,.94-4.17c1.88-2.53,3.71-5.11,5.51-7.7a2.38,2.38,0,0,0-.07-3q-3.27-4.12-6.65-8.17a3.36,3.36,0,0,0-4.44-.68c-1.88,1-3.63,2.17-5.41,3.31-4.62,3-5.38,7.45-4.69,12.38.84,6,3.4,11.36,6.55,16.46a80.66,80.66,0,0,0,15.08,17.51,61,61,0,0,0,18.75,11.95A29.1,29.1,0,0,0,440.2,330.24Z" transform="translate(-384.54 -261.97)"/>
    </SvgIcon>
  );
}
