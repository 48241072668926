/* @flow */

import React from 'react';
import {useRouteMatch} from 'react-router-dom';

import ImpersonateComponent from 'app/users/impersonate';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function ImpersonatePage() {
  const match = useRouteMatch();

  const target = match.params.target || '';
  const username = match.params.username || '';
  const password = match.params.password || '';

  return (
    <WebsitePageComponent id="page.impersonate.title">
      <ImpersonateComponent target={target} username={username} password={password} />
    </WebsitePageComponent>
  );
}
