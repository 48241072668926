/* @flow */

import {withStyles} from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';

const StyledTableCellTextSmallComponent = withStyles(_ => ({
  root: {
    padding: '4px 56px 4px 24px',
    width: '25%'
  },
  head: {
    lineHeight: '1.5'
  },
  paddingCheckbox: {
    padding: 0,
  },
  paddingNone: {
    padding: '0 12px'
  },
  body: {
    fontSize: '0.8125rem',
  },

}))(TableCell);

export default StyledTableCellTextSmallComponent;
