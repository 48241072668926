/* @flow */

import moment from 'moment';

import Company from 'models/companies/company';
import DebtorCompany from 'models/companies/debtorCompany';

export default class LeadOffer {
  id: number;
  sellerCompany: Company;
  debtorCompany: DebtorCompany;
  offerDate: moment;
  dueDate: moment;
  debtAmount: number;
  financedAmount: number;
  transactionPrice: number;
  listingFee: number;
  variablefee: number;
  totalFee: number;
  isAwaitingDebtorGrade: boolean;
  isValid: boolean;
}
