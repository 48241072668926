/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import PhoneNumberComponent from 'components/formFields/phoneNumber';
import ButtonComponent from 'components/buttons/button';
import CompanyLookupDebtorComponent from 'components/companyLookupV2/debtors';
import FormErrorsComponent from 'components/formErrors';
import IntlMessageComponent from 'components/formatters/intlMessage';
import LanguageFieldComponent from 'components/formFields/types/language';
import MandatoryFormFieldsComponent from 'components/form/mandatory';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import TitleFieldComponent from 'components/formFields/types/title';

import RegisterLeadForm from './model';

type DebtorRegisterLeadTab1ViewComponentProps = {
  errors: Map<string, ?string>;
  form: RegisterLeadForm;
  formErrors: string[];
  isProcessing: boolean;
  handleChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void;
  handleCompanyChange: () => void;
  handleTypeChange: (fieldName: string) => (id: number) => void;
  handleMobileChange: (value: string) => void;
  submitForm: () => void;
}

export default function DebtorRegisterLeadTab1ViewComponent(props: DebtorRegisterLeadTab1ViewComponentProps) {
  return (
    <React.Fragment>
      <FormErrorsComponent formErrors={props.formErrors} />

      <Box mt={3}>
        <Grid container spacing={6} alignItems="stretch">
          <Grid item xs={12} md={6}>
            <PageSubTitleViewComponent
              id="debtor.registerLead.tab1.companyInfo"
              data-walkme="debtor-registerLead-tab1-companyinfo"
            />

            <Box mt={3}>
              <CompanyLookupDebtorComponent
                company={props.form.company}
                companyErrorLabelId={props.errors.get('company')}
                handleSelect={props.handleCompanyChange}
                required
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <PageSubTitleViewComponent
              id="debtor.registerLead.tab1.userinfo"
              data-walkme="debtor-registerLead-tab1-userinfo"
            />

            <Box mt={3} px={4} py={3}>
              <TitleFieldComponent
                label={<IntlMessageComponent id="form.title.label" />}
                error={!!props.errors.get('titleId')}
                helperText={(props.errors.get('titleId')) ?
                  <IntlMessageComponent id={props.errors.get('titleId')} /> : ''}
                value={props.form.titleId}
                changeHandler={props.handleTypeChange('titleId')}
                fullWidth
                margin="normal"
                required
              />

              <TextField
                id="form.name"
                data-walkme="subscription-field-nom"
                label={<IntlMessageComponent id="form.name.label" />}
                helperText={(props.errors.get('lastName')) ?
                  <IntlMessageComponent id={props.errors.get('lastName')} /> : ''}
                error={!!props.errors.get('lastName')}
                value={props.form.lastName}
                onChange={props.handleChange('lastName')}
                fullWidth
                margin="normal"
                required
              />

              <TextField
                id="form.firstName"
                data-walkme="subscription-field-prenom"
                label={<IntlMessageComponent id="form.firstname.label" />}
                helperText={(props.errors.get('firstName')) ?
                  <IntlMessageComponent id={props.errors.get('firstName')} /> : ''}
                error={!!props.errors.get('firstName')}
                value={props.form.firstName}
                onChange={props.handleChange('firstName')}
                fullWidth
                margin="normal"
                required
              />

              <TextField
                id="form.email"
                label={<IntlMessageComponent id="form.email.label" />}
                error={!!props.errors.get('email')}
                helperText={(props.errors.get('email')) ?
                  <IntlMessageComponent id={props.errors.get('email')} /> : ''}
                value={props.form.email}
                onChange={props.handleChange('email')}
                fullWidth
                margin="normal"
                required
              />

              <PhoneNumberComponent
                id="form.mobile"
                label={<IntlMessageComponent id="form.mobile.label" />}
                helperText={(props.errors.get('mobile'))
                  ? <IntlMessageComponent id={props.errors.get('mobile')} />
                  : <IntlMessageComponent id="form.mobile.helperText.iso" />}
                error={!!props.errors.get('mobile')}
                value={props.form.mobile}
                onChange={props.handleMobileChange}
                fullWidth
                margin="normal"
                required
              />

              <LanguageFieldComponent
                label={<IntlMessageComponent id="form.language.label" />}
                error={!!props.errors.get('languageId')}
                helperText={(props.errors.get('languageId')) ?
                  <IntlMessageComponent id={props.errors.get('languageId')} /> : ''}
                value={props.form.languageId}
                changeHandler={props.handleTypeChange('languageId')}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box mt={5}>
        <ButtonComponent
          color="primary"
          variant="contained"
          isProcessing={props.isProcessing}
          onClick={props.submitForm}
        ><IntlMessageComponent id="general.continue" /></ButtonComponent>

        <MandatoryFormFieldsComponent/>
      </Box>
    </React.Fragment>
  );
}
