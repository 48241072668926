/* @flow */

import React from 'react';
import {useRouteMatch} from 'react-router-dom';

import ResetPasswordComponent from 'app/users/resetPassword';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function ResetPasswordPage() {
  const match = useRouteMatch();

  const email = match.params.email || '';
  const token = match.params.token || '';

  return (
    <WebsitePageComponent id="page.resetpassword.title">
      <ResetPasswordComponent email={email} token={token} />
    </WebsitePageComponent>
  );
}
