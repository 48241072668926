/* @flow */

export default class BuyerInvestmentSummaryOverview {
  investedAmountInsuredFor70Pct: number;
  investedAmountInsuredFor90Pct: number;
  investedAmount: number;
  investedAmountInactive: number;
  investedAmountAvailable: number;
  investedAmountPct: number;
  investedAmountAvailablePct: number;
  investedAmountTotal: number;
}
