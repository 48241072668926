/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import ButtonComponent from 'components/buttons/button';
import CompanyLookupDebtorComponent from 'components/companyLookupV2/debtors';
import FormErrorsComponent from 'components/formErrors';
import IntlMessageComponent from 'components/formatters/intlMessage';
import InputAmountComponent from 'components/formatters/inputAmount';
import MandatoryFormFieldsComponent from 'components/form/mandatory';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

type BestPricingFormViewComponentProps = {
  enableCancelButton: boolean;
  errors: Map<string,?string>;
  form: BusinessPotentialForm;
  formErrors: string[];
  isProcessing: boolean;
  handleChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void;
  handleDebtorCompanyChange: () => void;
  cancelHandler: () => void;
  submitForm: () => void;
}

export default function BestPricingFormViewComponent(props: BestPricingFormViewComponentProps) {

  return (
    <React.Fragment>
      <Paper elevation={0}>
        <Box p={5}>
          <FormErrorsComponent formErrors={props.formErrors} />

          <Typography variant="body1" gutterBottom>
            <IntlMessageComponent id="page.bestPricing.form.title" />
          </Typography>

          <Box mt={5}>
            <Grid container spacing={6} alignItems="stretch">
              <Grid item xs={12} md={6}>
                <PageSubTitleViewComponent
                  id="page.bestPricing.form.debtorCompany"
                  data-walkme="bestPricing-form-debtorCompanyInfo"
                />

                <Box mt={3}>
                  <CompanyLookupDebtorComponent
                    company={props.form.debtorCompany}
                    companyErrorLabelId={props.errors.get('debtorCompany')}
                    handleSelect={props.handleDebtorCompanyChange}
                    required
                  />
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <PageSubTitleViewComponent
                  id="page.bestPricing.form.invoice"
                  data-walkme="bestPricing-form-invoiceInfo"
                />

                <Box mt={3} px={4} py={3}>
                  <TextField
                    id="form.avgInvoiceAmount"
                    label={<IntlMessageComponent id="form.invoiceAmount.label" />}
                    error={!!props.errors.get('avgInvoiceAmount')}
                    helperText={props.errors.get('avgInvoiceAmount') ?
                      <IntlMessageComponent id={props.errors.get('avgInvoiceAmount')} /> : ''}
                    value={props.form.avgInvoiceAmount}
                    onChange={props.handleChange('avgInvoiceAmount')}
                    InputProps={{
                      inputComponent: InputAmountComponent,
                      startAdornment: <InputAdornment position="start">€</InputAdornment>
                    }}
                    fullWidth
                    margin="normal"
                    required
                  />

                  <TextField
                    id="form.nbrInvoices"
                    label={<IntlMessageComponent id="form.nbrInvoices.label" />}
                    error={!!props.errors.get('nbrInvoices')}
                    helperText={props.errors.get('nbrInvoices') ?
                      <IntlMessageComponent id={props.errors.get('nbrInvoices')} /> : ''}
                    value={props.form.nbrInvoices}
                    onChange={props.handleChange('nbrInvoices')}
                    fullWidth
                    margin="normal"
                    required
                  />

                  <TextField
                    id="form.paymentTerms"
                    data-walkme="bestpricing-field-delaidepaiement"
                    label={<IntlMessageComponent id="form.paymentTerm.label" />}
                    error={!!props.errors.get('paymentTerms')}
                    helperText={props.errors.get('paymentTerms') ?
                      <IntlMessageComponent id={props.errors.get('paymentTerms')} /> : ''}
                    value={props.form.paymentTerms}
                    onChange={props.handleChange('paymentTerms')}
                    fullWidth
                    margin="normal"
                    required
                  />
                </Box>
              </Grid>
            </Grid>

            <Box mt={5}>
              {props.enableCancelButton &&
                <Box component="span" mr={1}>
                  <ButtonComponent
                    color="default"
                    variant="contained"
                    disabled={props.isProcessing}
                    onClick={props.cancelHandler}
                  ><IntlMessageComponent id="general.cancel" /></ButtonComponent>
                </Box>
              }

              <ButtonComponent
                color="secondary"
                variant="contained"
                isProcessing={props.isProcessing}
                onClick={props.submitForm}
              ><IntlMessageComponent id="general.save" /></ButtonComponent>

              <MandatoryFormFieldsComponent />
            </Box>
          </Box>
        </Box>
      </Paper>
    </React.Fragment>
  );
}