/* @flow */

import React from 'react';
import { connect } from 'react-redux';

import TypeImplComponent from '../types/typeImpl';

import ConfirmingService from 'services/ConfirmingService';

type SupplierBankAccountFieldComponentProps = {
  error: boolean;
  fullWidth: boolean;
  helperText: IntlMessage;
  id: string;
  label: IntlMessage;
  margin: string;
  required: boolean;
  readOnly: boolean;
  value: number;
  supplierId: number;
  changeHandler: (typeId: number) => void;
}

type SupplierBankAccountFieldComponentState = {
  isLoading: boolean;
  values: { id: number, name: string }[];
}

class SupplierBankAccountFieldComponent extends React.Component<SupplierBankAccountFieldComponentProps, SupplierBankAccountFieldComponentState> {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      values: []
    };
  }

  componentDidMount = async () => {
    try {
      const bankAccounts = await ConfirmingService.getConfirmingSupplierBankAccounts(this.props.activeCompanyId, this.props.supplierId);
      const values = bankAccounts.map(b => ({ id: b.bankAccountID, name: b.bankAccount }));
      this.setState({ isLoading: false, values });
    } catch (e) {
      console.error(e);
    }
  }

  render() {
    return (
      <TypeImplComponent
        isLoading={this.state.isLoading}
        values={this.state.values}
        readOnly={this.props.readOnly}
        {...this.props}
      />
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  activeCompanyId: auth.user.activeCompany.id
});

export default connect(mapStateToProps)(SupplierBankAccountFieldComponent);
