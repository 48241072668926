/* @flow */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function IconAddFilled(props) {
  return (
    <SvgIcon viewBox="0 0 64.54 64.54" {...props}>
      <g>
        <path d="M421.23,265.26a32.27,32.27,0,1,0,32.27,32.27A32.3,32.3,0,0,0,421.23,265.26Zm0,62.54a30.27,30.27,0,1,1,30.27-30.27A30.27,30.27,0,0,1,421.23,327.8Z" transform="translate(-388.96 -265.26)"/>
        <path d="M421.23,269.9a27.64,27.64,0,1,0,27.63,27.63A27.64,27.64,0,0,0,421.23,269.9Zm16.19,29a8.48,8.48,0,0,1-1.23.09H423.4v.88q0,6.08,0,12.16a4.65,4.65,0,0,1-.15,1.29,1.94,1.94,0,0,1-2.11,1.29,1.9,1.9,0,0,1-1.63-1.91V299c-.35,0-.63,0-.91,0H406.35c-1.45,0-2.11-.4-2.46-1.44a1.89,1.89,0,0,1,1.41-2.43,6.41,6.41,0,0,1,1.16-.1h13v-.85c0-4.23,0-8.45,0-12.68a2,2,0,0,1,1.76-2.1,1.92,1.92,0,0,1,2.1,1.82c0,.29.05.58.05.87q0,6,0,11.94v1h13.11c1.41,0,2.07.39,2.42,1.4A1.88,1.88,0,0,1,437.42,298.88Z" transform="translate(-388.96 -265.26)"/>
      </g>
    </SvgIcon>
  );
}
