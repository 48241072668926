/* @flow */

import {ApiValidationError} from 'services/api/apiErrors';
import NotificationHelper from 'lib/notifications';
import {validateField, validateForm} from 'lib/validation/validator';

export function handleApiFormResponse(e) {

  if (e instanceof ApiValidationError) {
    return this.setState({
      formErrors: e.formErrors,
      isProcessing: false
    });
  }

  NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
};

export function getValidationError(e) {
  if (e instanceof ApiValidationError && e.formErrors.length > 0)  {
    return e.formErrors[0];
  }
  return null;
};

export function handleFormChange(fieldName: string, value: any) {
  const validationResult = validateField(value, this.state.constraints[fieldName]);
  
  const errors = this.state.errors;
  errors.set(fieldName, validationResult ? validationResult[0] : null);

  const form = this.state.form;
  form[fieldName] = value;
  form.hasChanges = true;
  this.setState({form, errors});
};

export function handleFormValidation() {
  const validationResults = validateForm(this.state.form, this.state.constraints);

  if (validationResults === undefined || !Object.keys(validationResults).length) {
    return true;
  }

  const errors = this.state.errors;
  Object.keys(validationResults).forEach((k) => {
    const validationResult = validationResults[k][0];
    const splited = validationResult.split(' ');
    const validationMessage = splited.pop();
    return errors.set(k, validationMessage);
  });

  this.setState({errors});
  return false;
};

export function getValidationErrors() {
  const validationResults = validateForm(this.state.form, this.state.constraints);

  if (validationResults === undefined || !Object.keys(validationResults).length) {
    return this.state.errors;
  }

  const errors = this.state.errors;
  Object.keys(validationResults).forEach((k) => {
    const validationResult = validationResults[k][0];
    const splited = validationResult.split(' ');
    const validationMessage = splited.pop();
    errors.set(k, validationMessage);
  });

  return errors;
};
