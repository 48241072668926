/* @flow */

import React from 'react';
import {useParams} from "react-router-dom";

import DebtorInvoicesToApproveTokenComponent from 'app/debtors/invoicesToApproveToken';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function DebtorInvoicesToApproveTokenPage() {
  const {token} = useParams();

  return (
    <WebsitePageComponent id="page.debtor.invoices.title">
      <DebtorInvoicesToApproveTokenComponent token={token} />
    </WebsitePageComponent>
  );
}
