/* @flow */

import CommissionMsf from './commissionMsf';

export default class CommissionMsfIsoPerson {
  id: number;
  name: string;
  msfs: CommissionMsf[];
  msfPayment: number;
  revenues: number;
  paidRevenues: number;
  msfCommission: number;

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
    this.msfs = [];
    this.msfPayment = 0;
    this.revenues = 0;
    this.paidRevenues = 0;
    this.msfCommission = 0;
  }
}
