/* @flow */

import SetPersonLegalInfoRequest from 'models/requests/register/setPersonLegalInfoRequest';

export default class PersonLegalInfoForm {
  isAdministrator: boolean;

  constructor() {
    this.isAdministrator = false;
  }

  toRequest(companyId: number) {
    const request = new SetPersonLegalInfoRequest();
    request.companyId = companyId;
    request.isAdministrator = this.isAdministrator;
    return request;
  }
}
