/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import DateFormatterComponent from 'components/formatters/date';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

type BlogViewComponentProps = {
  blogs: BlogEntry[];
}

export default function BlogViewComponent(props: BlogViewComponentProps) {
  return (
    <React.Fragment>
      <PageSubTitleViewComponent id="component.blogs.title" />

      <Box mt={3}>
        <Paper elevation={0}>
          <Box>
            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
            
              {props.blogs.map((blog, index) => {
                const values = {
                  publicationDate: <DateFormatterComponent date={blog.publicationDate} />
                };

                return (
                  <Grid item xs={12} md={6} xl={4} key={`blog-${index}`}>
                    <Box p={4}>
                      <Typography variant="h4" gutterBottom>{blog.title}</Typography>
                      <Typography variant="subtitle2" gutterBottom>
                        <IntlMessageComponent id="component.blogs.publicationDate" values={values} />
                      </Typography>

                      <Box mt={3}>
                        <Typography variant="body1" gutterBottom>{blog.contentText}</Typography>
                      </Box>

                      <Link href={blog.buttonUrl} target="_blank">{blog.buttonText}</Link>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Paper>
      </Box>
    </React.Fragment>
  );
}
