/* @flow */

import {plainToClass} from 'class-transformer';

import BaseService from './api/baseService';

import {
  PAYMENT_RESULTS_GET,
  PAYMENT_INVOICE_FEES_GET
} from 'constants/apiRoutes';

import InvoiceFeesPaymentRequest from 'models/requests/payments/invoiceFeesPaymentRequest';

import CompanyMembership from 'models/companies/companyMembership';
import InvoicePaymentResult from 'models/invoices/seller/invoicePaymentResult';
import PaymentInfo from 'models/payments/paymentInfo';
import PaymentDetail from 'models/payments/paymentDetail';
import PaymentOgone from 'models/payments/paymentOgone';
import PaymentOgoneValue from 'models/payments/paymentOgoneValue';
import PaymentResult from 'models/payments/paymentResult';

export default class PaymentService {

  static getPaymentResult = (paymentReference: string) =>
    BaseService.simpleGET(`${PAYMENT_RESULTS_GET}?paymentReference=${paymentReference}`,
    data => {
      const response = plainToClass(PaymentResult, data);
      if (data.membership) response.membership = plainToClass(CompanyMembership, data.membership);
      if (data.invoices) response.invoices = data.invoices.map(invoice => plainToClass(InvoicePaymentResult, invoice));
      return response;
    });

  static mapInvoiceFeesPaymentResponse = (data: any) => {
    const result = plainToClass(PaymentInfo, data);
    if (data.details) {
      result.details = data.details.map(d => plainToClass(PaymentDetail, d));
    } else {
      result.details = [];
    }
    if (data.ogone) {
      result.ogone = plainToClass(PaymentOgone, data.ogone);
      result.ogone.formValues = data.ogone.formValues.map(fv => plainToClass(PaymentOgoneValue, fv));
    }
    return result;
  }

  static requestInvoiceFeesPayment = (request: InvoiceFeesPaymentRequest) => 
    BaseService.simplePOST(PAYMENT_INVOICE_FEES_GET, request, PaymentService.mapInvoiceFeesPaymentResponse);
}
