/* @flow */

import React from 'react';

import ISOInvoicesComponent from 'app/isos/invoices';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function ISOInvoicesPage() {
  return (
    <WebsitePageComponent id="page.iso.invoices.title" data-walkme="isoinvoices-title-invoices" fluidContainer noPaper>
      <ISOInvoicesComponent />
    </WebsitePageComponent>
  );
}
