/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import IntlMessageComponent from 'components/formatters/intlMessage';

type CallbackSuccessViewComponentProps = {
};

export default function CallbackSuccessViewComponent(props: CallbackSuccessViewComponentProps) {
  return (
    <React.Fragment>
      <Paper elevation={0}>
        <Box p={3}>
            <IntlMessageComponent id="callback.success.message" />
        </Box>
      </Paper>
    </React.Fragment>
  );
}
