/* @flow */

import moment from 'moment';

export default class ProfileOverviewEntry {
  report: string;
  reportDate: moment;
  leads: number;
  leadsWon: number;
  leadsLost: number;
  leadsInProgress: number;
  members: number;
  sellers: number;
  pastSellers: number;
  activeSellers: number;
  financedAmount: number;
  unfinancedAmount: number;
  commission: number;
}
