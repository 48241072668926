/* @flow */

import IconAddFilled from 'components/icons/filled/add';
import IconDocumentFilled from 'components/icons/filled/document';
import IconHomeFilled from 'components/icons/filled/home';
import IconListFilled from 'components/icons/filled/list';
import IconSuitcaseFilled from 'components/icons/filled/suitcase';

import {
  PROTECTED_DEBTOR_APPROVE_INVOICES,
  PROTECTED_DEBTOR_BILLS,
  PROTECTED_DEBTOR_COMMERCIAL,
  PROTECTED_DEBTOR_DASHBOARD,
  PROTECTED_DEBTOR_PAY_INVOICES,
  PROTECTED_DEBTOR_PORTFOLIO,
  PROTECTED_DEBTOR_REGISTER_SUPPLIER,
  PROTECTED_DEBTOR_SUPPLIER_BANK_ACCOUNT
} from 'constants/pageRoutes';

export const debtorMenuData = [
  {pageName: 'POS.MainMenu', elementName: 'mnuHome', link: PROTECTED_DEBTOR_DASHBOARD, icon: <IconHomeFilled color='primary' size='1.5x'/>, messageId: 'sidebar.page.home'},
  {
    id: 'acordion-1',
    collapsed: false,
    type: 'sub-menu',
    icon: <IconSuitcaseFilled color='primary' size='1.5x'/>,
    messageId: 'sidebar.page.invoices',
    pageName: 'POS.MainMenu',
    elementName: 'mnuInvoicesAccordion',
    children: [
      {pageName: 'POS.MainMenu', elementName: 'mnuMyPortfolio', link: PROTECTED_DEBTOR_PORTFOLIO, icon: <IconHomeFilled color='primary' size='1.5x'/>, messageId: 'sidebar.page.myPortfolio'},
      {pageName: 'POS.MainMenu', elementName: 'mnuApproval', checkRoleExecute: true, link: PROTECTED_DEBTOR_APPROVE_INVOICES, icon: <IconHomeFilled color='primary' size='1.5x'/>, messageId: 'sidebar.page.approval', checkExecutePermissions: true},
      {pageName: 'POS.MainMenu', elementName: 'mnuPaymentConfirmation', checkRoleExecute: true, link: PROTECTED_DEBTOR_PAY_INVOICES, icon: <IconHomeFilled color='primary' size='1.5x'/>, messageId: 'sidebar.page.paymentConfirmation', checkExecutePermissions: true},
    ]
  },
  //{pageName: 'POS.MainMenu', elementName: 'mnuAccountancy', link: PROTECTED_DEBTOR_BILLS, icon: <IconListFilled color='primary' size='1.5x'/>, messageId: 'sidebar.page.accountancy'},
  {
    id: 'acordion-2',
    collapsed: false,
    type: 'sub-menu',
    icon: <IconAddFilled color='primary' size='1.5x'/>,
    messageId: 'sidebar.page.suppliers',
    pageName: 'POS.MainMenu',
    elementName: 'mnuSuppliersAccordion',
    children: [
      {pageName: 'POS.MainMenu', elementName: 'mnuCreateSupplier', checkExecute: true, link: PROTECTED_DEBTOR_REGISTER_SUPPLIER, icon: <IconHomeFilled color='primary' size='1.5x'/>, messageId: 'sidebar.page.createSupplier', checkExecutePermissions: true},
      {pageName: 'POS.MainMenu', elementName: 'mnuSupplierBankAccount', checkRoleExecute: true, link: PROTECTED_DEBTOR_SUPPLIER_BANK_ACCOUNT, icon: <IconHomeFilled color='primary' size='1.5x'/>, messageId: 'sidebar.page.supplierBankAccount', checkExecutePermissions: true},
      // {pageName: 'POS.MainMenu', elementName: 'mnuSupplierInfo', link: PROTECTED_DEBTOR_REGISTER_SUPPLIER, icon: <IconHomeFilled color='primary' size='1.5x'/>, messageId: 'sidebar.page.supplierInfo'},
      // {pageName: 'POS.MainMenu', elementName: 'mnuCoupons', link: PROTECTED_DEBTOR_REGISTER_SUPPLIER, icon: <IconHomeFilled color='primary' size='1.5x'/>, messageId: 'sidebar.page.coupons'},
    ]
  }//,
  //{pageName: 'POS.MainMenu', elementName: 'mnuMarketing', link: PROTECTED_DEBTOR_COMMERCIAL, icon: <IconDocumentFilled color='primary' size='1.5x'/>, messageId: 'sidebar.page.marketing'},
];
