/* @flow */

import moment from 'moment';

export default class InvoiceReport {

  // data
  isoCompany: string;
  isoOffice: string;
  isoPerson: string;
  sellerCompany: string;

  invoiceId: number;

  debtorInsurerRating: number;

  statusKey: string;

  debtAmount: number;

  isFinanced: boolean;
  financedAmount: number;

  dueDate: moment;

  serviceFee: number;

  // filters
  filterISOId: number;
  filterISOOfficeId: number;
  filterISOPersonId: number;
  filterSellerCompanyId: number;
}
