/* @flow */

import React from 'react';
import { useHistory } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import AmountFormatterComponent from 'components/formatters/amount';
import DateFormatterComponent from 'components/formatters/date';
import DebtorInvoiceStatusViewComponent from 'components/invoiceStatus/debtor';
import IntlMessageComponent from 'components/formatters/intlMessage';
import InvoiceNameComponent from 'components/invoice/name';
import NumberFormatterComponent from 'components/formatters/number';
import StyledTableCellTextComponent from 'components/table/cellText';
import StyledTableCellNumbersComponent from 'components/table/cellNumbers';
import StyledTableHeadComponent from 'components/table/head';
import StyledTableRowComponent from 'components/table/row';

import Invoice from 'models/debtors/invoice';

import { PROTECTED_DEBTOR_INVOICE_DETAILS } from 'constants/pageRoutes';

const columnData = [
  { id: 'statusKey', align:'left', translationKey: 'debtor.portfolio.table.statusKey', walkme: 'debtor-portfolio-table-statusKey' },
  { id: 'sellerCompany.name', align:'left', translationKey: 'debtor.portfolio.table.sellerCompany.name', walkme: 'debtor-portfolio-table-sellerCompanyName' },
  { id: 'reference', align:'left', translationKey: 'debtor.portfolio.table.reference', walkme: 'debtor-portfolio-table-reference' },
  { id: 'debtorReference', align:'left', translationKey: 'debtor.portfolio.table.debtorReference', walkme: 'debtor-portfolio-table-debtorReference' },
  { id: 'debtAmount', translationKey: 'debtor.portfolio.table.debtAmount', walkme: 'debtor-portfolio-table-debtAmount' },
  { id: 'outstandingBalance', translationKey: 'debtor.portfolio.table.outstandingBalance', walkme: 'debtor-portfolio-table-outstandingBalance' },
  { id: 'issueDate', align:'left', translationKey: 'debtor.portfolio.table.issueDate', walkme: 'debtor-portfolio-table-issueDate' },
  { id: 'dueDate', align:'left', translationKey: 'debtor.portfolio.table.dueDate', walkme: 'debtor-portfolio-table-dueDate' },
  { id: 'daysToDueDate', translationKey: 'debtor.portfolio.table.daysToDueDate', walkme: 'debtor-portfolio-table-daysToDueDate' },
  { id: 'validationAge', translationKey: 'debtor.portfolio.table.validationAge', walkme: 'debtor-portfolio-table-validationAge' },
  { id: 'paymentPromiseDate', align:'left', translationKey: 'debtor.portfolio.table.paymentPromiseDate', walkme: 'debtor-portfolio-table-paymentPromiseDate' },
  { id: 'id', align:'left', translationKey: 'debtor.portfolio.table.id', walkme: 'debtor-portfolio-table-invoiceId' }
];


type DebtorInvoicesPortfolioTableViewComponentProps = {
  invoices: Invoice[];
  order: string;
  orderBy: string;
  page: number;
  pageSize: number;
  handlePageChange: (page: number) => void;
  handlePageSizeChange: (pageSize: number) => void;
  handleSortOrderChange: (property: string) => void;
};


export default function DebtorInvoicesPortfolioTableViewComponent(props: DebtorInvoicesPortfolioTableViewComponentProps) {
  const history = useHistory();

  const handlePageChange = (_, page) => props.handlePageChange(page);
  const handlePageSizeChange = event => props.handlePageSizeChange(event.target.value);
  const navigateToInvoiceDetails = id => history.push({ pathname: `${PROTECTED_DEBTOR_INVOICE_DETAILS}/${id}` });

  return (
    <React.Fragment>
      <Box py={5}>
        <Box component="div" overflow="auto">
          <Table>
            <StyledTableHeadComponent data-walkme="debtorPortfolio-table-headline">
              <StyledTableRowComponent>
                {columnData.map(column => (
                  <StyledTableCellTextComponent align={column?.align ?? 'right'} key={column.id} padding="none">
                    <TableSortLabel
                      active={props.orderBy === column.id}
                      direction={props.order}
                      onClick={() => props.handleSortOrderChange(column.id)}
                      data-walkme={column.walkme || ''}
                    ><IntlMessageComponent id={column.translationKey} /></TableSortLabel>
                  </StyledTableCellTextComponent>
                ))}
              </StyledTableRowComponent>
            </StyledTableHeadComponent>

            <TableBody bgcolor="white">
              {props.invoices.length === 0 &&
                <StyledTableRowComponent>
                  <StyledTableCellTextComponent colSpan={columnData.length}>
                    <Box textAlign="center">
                      <IntlMessageComponent id="general.noData" />
                    </Box>
                  </StyledTableCellTextComponent>
                </StyledTableRowComponent>
              }

              {props.invoices.slice(props.page * props.pageSize, (props.page * props.pageSize) + props.pageSize).map(i => (
                <StyledTableRowComponent
                  key={`invList-${i.id}`}
                  hover
                  onClick={() => navigateToInvoiceDetails(i.id)}
                  tabIndex={-1}
                >
                  <StyledTableCellTextComponent padding="none">
                    <DebtorInvoiceStatusViewComponent statusKey={i.statusKey} />
                  </StyledTableCellTextComponent>

                  <StyledTableCellTextComponent padding="none">
                    {i.sellerCompany.name}
                  </StyledTableCellTextComponent>

                  <StyledTableCellTextComponent padding="none">
                    {i.reference}
                  </StyledTableCellTextComponent>

                  <StyledTableCellTextComponent padding="none">
                    {i.debtorReference}
                  </StyledTableCellTextComponent>

                  <StyledTableCellNumbersComponent padding="none">
                    <AmountFormatterComponent amount={i.debtAmount} />
                  </StyledTableCellNumbersComponent>

                  <StyledTableCellNumbersComponent padding="none">
                    <AmountFormatterComponent amount={i.outstandingBalance} />
                  </StyledTableCellNumbersComponent>

                  <StyledTableCellTextComponent padding="none">
                    <DateFormatterComponent date={i.issueDate} />
                  </StyledTableCellTextComponent>

                  <StyledTableCellTextComponent padding="none">
                    <DateFormatterComponent date={i.dueDate} />
                  </StyledTableCellTextComponent>

                  <StyledTableCellNumbersComponent padding="none">
                    {i.daysToDueDate > -1 && !i.paidDate ? <Box color="red.main"><NumberFormatterComponent value={i.daysToDueDate} /></Box> : <NumberFormatterComponent value={i.daysToDueDate} />}
                  </StyledTableCellNumbersComponent>

                  <StyledTableCellNumbersComponent padding="none">
                    <NumberFormatterComponent value={i.validationAge} />
                  </StyledTableCellNumbersComponent>

                  <StyledTableCellTextComponent padding="none">
                    <DateFormatterComponent date={i.paymentPromiseDate} />
                  </StyledTableCellTextComponent>

                  <StyledTableCellTextComponent padding="none">
                    <InvoiceNameComponent invoiceId={i.id} invoiceName={i.invoiceName} />
                  </StyledTableCellTextComponent>
                </StyledTableRowComponent>
              ))}
            </TableBody>
          </Table>
        </Box>

        <Box display="flex" height={60}>
          <Box flexGrow={1}></Box>
          <Box justifyContent="flex-end">
            <TablePagination
              component="div"
              count={props.invoices.length}
              rowsPerPage={props.pageSize}
              page={props.page}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePageSizeChange}
              labelRowsPerPage={<IntlMessageComponent id="general.invoicePerPage" />}
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
            />
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}
