/* @flow */

import React from 'react';
import {Route, Switch, useRouteMatch} from 'react-router-dom';

import Error404Page from 'components/errors/error404';
import BuyerInvestmentProfilesEditPage from './edit';
import BuyerInvestmentProfilesNewPage from './new';


export default function BuyerInvestmentProfilesPage() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.url}/`} component={BuyerInvestmentProfilesEditPage} />
      <Route exact path={`${match.url}/new`} component={BuyerInvestmentProfilesNewPage} />
      <Route component={Error404Page} />
    </Switch>
  );
}
