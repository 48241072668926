/* @flow */

import React from 'react';
import { useHistory } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import AmountFormatterComponent from 'components/formatters/amount';
import DateFormatterComponent from 'components/formatters/date';
import DebtorGradeForSellerComponent from 'components/debtorGrades/seller';
import IntlMessageComponent from 'components/formatters/intlMessage';
import InvoiceNameComponent from 'components/invoice/name';
import NumberFormatterComponent from 'components/formatters/number';
import PercentageFormatterComponent from 'components/formatters/percentage';
import StyledTableCellTextComponent from 'components/table/cellText';
import StyledTableCellNumbersComponent from 'components/table/cellNumbers';
import StyledTableHeadComponent from 'components/table/head';
import StyledTableRowComponent from 'components/table/row';

import Invoice from 'models/buyers/invoices/invoice';

import { PROTECTED_SELLER_INVOICE_DETAILS } from 'constants/pageRoutes';
import TooltipComponent from 'components/toolTips/index';

const columnData = [
  { id: 'id', align: 'left', translationKey: 'component.seller.invoiceList.header.invoiceNbr', walkme: 'sportfolio-column-refedebex' },
  { id: 'reference', align: 'left', translationKey: 'component.seller.invoiceList.header.reference', walkme: 'sportfolio-column-reforiginale' },
  { id: 'statusKey', align: 'left', translationKey: 'component.seller.invoiceList.header.status', walkme: 'sportfolio-column-statut' },
  { id: 'debtorCompany.name', align: 'left', translationKey: 'component.seller.invoiceList.header.debtor', walkme: 'sportfolio-column-client' },
  { id: 'debtorCompany.insurerRating', align: 'left', translationKey: 'component.seller.invoiceList.header.debtorInsurerRating', walkme: 'sportfolio-column-gradefinancier' },
  { id: 'debtAmount', translationKey: 'component.seller.invoiceList.header.amount', walkme: 'sportfolio-column-montantinitial' },
  { id: 'flexPrices.financedAmount', translationKey: 'component.seller.invoiceList.header.financedAmount', walkme: 'sportfolio-column-montantfinance' },
  { id: 'flexPrices.cashToTransfer', translationKey: 'component.seller.invoiceList.header.cashToTransfer', walkme: 'sportfolio-column-cashToTransfer' },
  { id: 'flexPrices.discount', translationKey: 'component.seller.invoiceList.header.discount', walkme: 'sportfolio-column-reduction' },
  { id: 'dueDate', align: 'left', translationKey: 'component.seller.invoiceList.header.dueDate', walkme: 'sportfolio-column-echeance' },
  { id: 'overdueDays', padding: 'larger', translationKey: 'component.seller.invoiceList.header.overdue', walkme: 'sportfolio-column-retard' },
  { id: 'flexPrices.listingFee', translationKey: 'component.seller.invoiceList.header.listingFee', walkme: 'sportfolio-column-listingFee' },
  { id: 'flexPrices.serviceFee', translationKey: 'component.seller.invoiceList.header.serviceFee', walkme: 'sportfolio-column-serviceFee' },
  { id: 'flexPrices.insuranceFee', translationKey: 'component.seller.invoiceList.header.insuranceFee', walkme: 'sportfolio-column-insuranceFee' }
];

type SellerInvoicesPortfolioTableViewComponentProps = {
  invoices: Invoice[];
  order: string;
  orderBy: string;
  page: number;
  pageSize: number;
  showInvestmentProfile: boolean;
  handlePageChange: (page: number) => void;
  handlePageSizeChange: (pageSize: number) => void;
  handleSortOrderChange: (property: string) => void;
};


export default function SellerInvoicesPortfolioTableViewComponent(props: SellerInvoicesPortfolioTableViewComponentProps) {
  const history = useHistory();

  const handlePageChange = (_, page) => props.handlePageChange(page);
  const handlePageSizeChange = event => props.handlePageSizeChange(event.target.value);
  const navigateToInvoiceDetails = id => history.push({ pathname: `${PROTECTED_SELLER_INVOICE_DETAILS}/${id}` });

  return (
    <React.Fragment>
      <Box py={5}>
        <Box component="div" overflow="auto">
          <Table>
            <StyledTableHeadComponent data-walkme="sportfolio-table-headline">
              <StyledTableRowComponent>
                {columnData.map(column => (
                  <StyledTableCellTextComponent align={column?.align ?? 'right'} key={column.id} padding={column?.padding ?? 'none'}>
                    <TableSortLabel
                      active={props.orderBy === column.id}
                      direction={props.order}
                      onClick={() => props.handleSortOrderChange(column.id)}
                      data-walkme={column.walkme || ''}
                    ><IntlMessageComponent id={column.translationKey} /></TableSortLabel>
                  </StyledTableCellTextComponent>
                ))}
                <StyledTableCellNumbersComponent padding="space" />
              </StyledTableRowComponent>
            </StyledTableHeadComponent>

            <TableBody bgcolor="white">
              {props.invoices.length === 0 &&
                <StyledTableRowComponent>
                  <StyledTableCellTextComponent colSpan={columnData.length}>
                    <Box textAlign="center">
                      <IntlMessageComponent id="seller.portfolio.noData" />
                    </Box>
                  </StyledTableCellTextComponent>
                </StyledTableRowComponent>
              }

              {props.invoices.slice(props.page * props.pageSize, (props.page * props.pageSize) + props.pageSize).map(i => (
                <StyledTableRowComponent
                  key={`invList-${i.id}`}
                  hover
                  onClick={() => navigateToInvoiceDetails(i.id)}
                  tabIndex={-1}
                >
                  <StyledTableCellTextComponent padding="none">
                    <InvoiceNameComponent invoiceId={i.id} invoiceName={i.invoiceName} />
                  </StyledTableCellTextComponent>

                  <StyledTableCellTextComponent padding="none">
                    {i.reference}
                  </StyledTableCellTextComponent>

                  <StyledTableCellTextComponent padding="none" pageName="Tooltip.Seller.Portfolio" elementName={`invoice.status.${i.statusKey}`}>
                    <TooltipComponent pageName="Tooltip.Seller.Portfolio" elementName={`invoice.status.${i.statusKey}`}>
                      <IntlMessageComponent id={`invoice.status.${i.statusKey}`} />
                    </TooltipComponent>
                  </StyledTableCellTextComponent>

                  <StyledTableCellTextComponent padding="none">
                    <strong>{i.debtorCompany.name}</strong>
                  </StyledTableCellTextComponent>

                  <StyledTableCellTextComponent padding="none">
                    <DebtorGradeForSellerComponent
                      insurerRating={i.debtorCompany.insurerRating || 0}
                      widthPercentage={33}
                      shortMessageWhenZero
                    />
                  </StyledTableCellTextComponent>

                  <StyledTableCellNumbersComponent padding="none">
                    <AmountFormatterComponent amount={i.debtAmount} />
                  </StyledTableCellNumbersComponent>

                  <StyledTableCellNumbersComponent padding="none">
                    <AmountFormatterComponent amount={i.flexPrices.financedAmount} />
                  </StyledTableCellNumbersComponent>

                  <StyledTableCellNumbersComponent padding="none">
                    <strong><AmountFormatterComponent amount={i.flexPrices.cashToTransfer} notApplicableIfMissing /></strong>
                  </StyledTableCellNumbersComponent>

                  <StyledTableCellNumbersComponent padding="none">
                    <PercentageFormatterComponent value={i.flexPrices.discountPct} />
                  </StyledTableCellNumbersComponent>

                  <StyledTableCellTextComponent padding="none">
                    <DateFormatterComponent date={i.dueDate} />
                  </StyledTableCellTextComponent>

                  <StyledTableCellNumbersComponent padding="larger">
                    <NumberFormatterComponent value={i.overdueDays} />
                  </StyledTableCellNumbersComponent>

                  <StyledTableCellNumbersComponent padding="none">
                    <AmountFormatterComponent amount={i.flexPrices.listingFee} />
                  </StyledTableCellNumbersComponent>

                  <StyledTableCellNumbersComponent padding="none">
                    <AmountFormatterComponent amount={i.flexPrices.serviceFee} />
                  </StyledTableCellNumbersComponent>

                  <StyledTableCellNumbersComponent padding="none">
                    <AmountFormatterComponent amount={i.flexPrices.insuranceFee} />
                  </StyledTableCellNumbersComponent>
                  <StyledTableCellNumbersComponent padding="space" />
                </StyledTableRowComponent>
              ))}
            </TableBody>
          </Table>
        </Box>

        <Box display="flex" height={60}>
          <Box flexGrow={1}></Box>
          <Box justifyContent="flex-end">
            <TablePagination
              component="div"
              count={props.invoices.length}
              rowsPerPage={props.pageSize}
              page={props.page}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePageSizeChange}
              labelRowsPerPage={<IntlMessageComponent id="general.invoicePerPage" />}
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
            />
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}
