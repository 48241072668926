/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import ButtonComponent from 'components/buttons/button';
import CompanyLookupISOLeadComponent from 'components/companyLookupV2/isoLeads';
import FormErrorsComponent from 'components/formErrors';
import InputAmountComponent from 'components/formatters/inputAmount';
import IntlMessageComponent from 'components/formatters/intlMessage';
import MandatoryFormFieldsComponent from 'components/form/mandatory';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import SecurityComponent from 'components/security';

import { PROTECTED_ISO_REGISTER_LEAD } from 'constants/pageRoutes';

import IsoCouponForm from './model';

type ISOCouponFormViewComponentProps = {
  errors: Map<string,?string>;
  form: IsoCouponForm;
  formErrors: string[];
  isProcessing: boolean;
  maximumCouponAmount: number;
  cancelHandler: () => void;
  handleChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void;
  handleCompanyChange: () => void;
  submitForm: () => void;
}

export default function ISOCouponFormViewComponent(props: ISOCouponFormViewComponentProps) {
  const values = {
    value: props.maximumCouponAmount
  };

  return (
    <React.Fragment>
      <Paper elevation={0}>
        <Box p={5}>
          <FormErrorsComponent formErrors={props.formErrors} />

          <Typography variant="body1" gutterBottom>
            <IntlMessageComponent id="component.iso.coupons.title" />
          </Typography>

          <Box mt={5}>
            <Grid container spacing={6} alignItems="stretch">
              <Grid item xs={12} md={6}>
                <PageSubTitleViewComponent
                  id="iso.coupons.form.client"
                  data-walkme="coupons-form-clientInfo"
                />

                <Box mt={3}>
                  <CompanyLookupISOLeadComponent
                    company={props.form.company}
                    newCompanyPageBtnLabelId="component.iso.coupons.createNewLead.button"
                    newCompanyPageUrl={PROTECTED_ISO_REGISTER_LEAD}
                    required
                    handleSelect={props.handleCompanyChange}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <PageSubTitleViewComponent
                  id="iso.coupons.form.discount"
                  data-walkme="coupons-form-discountInfo"
                />

                <Box mt={3} px={4} py={3}>
                  <TextField
                    id="form.coupons.amount"
                    data-walkme="isocoupons-field-value"
                    required
                    label={<IntlMessageComponent id="form.coupons.amount.label" />}
                    helperText={(props.errors.get('amount')) ?
                      <IntlMessageComponent id={props.errors.get('amount')} values={values} /> : ''}
                    error={!!props.errors.get('amount')}
                    value={props.form.amount}
                    onChange={props.handleChange('amount')}
                    InputProps={{
                      inputComponent: InputAmountComponent,
                      startAdornment: <InputAdornment position="start">€</InputAdornment>
                    }}
                    fullWidth
                    margin="normal"
                  />

                  <TextField
                    id="form.reason"
                    data-walkme="isocoupons-field-reason"
                    label={<IntlMessageComponent id="form.coupons.reason.label" />}
                    error={!!props.errors.get('reason')}
                    helperText={(props.errors.get('reason')) ? <IntlMessageComponent id={props.errors.get('reason')} /> : ''}
                    onChange={props.handleChange('reason')}
                    fullWidth
                    margin="normal"
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box mt={5}>
            <ButtonComponent
              color="default"
              variant="contained"
              disabled={props.isProcessing}
              onClick={props.cancelHandler}
            ><IntlMessageComponent id="general.cancel" /></ButtonComponent>

            <SecurityComponent pageName="POS.CouponDetail" elementName="btnSave" checkRoleExecute>
              <Box component="span" ml={1}>
                <ButtonComponent
                  color="primary"
                  variant="contained"
                  isProcessing={props.isProcessing}
                  onClick={props.submitForm}
                ><IntlMessageComponent id="general.save" /></ButtonComponent>
              </Box>
            </SecurityComponent>

            <MandatoryFormFieldsComponent />
          </Box>
        </Box>
      </Paper>
    </React.Fragment>
  );
}