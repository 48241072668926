/* @flow */

import React from 'react';

import DebtorRegisterLeadComponent from 'app/debtors/registerLead';
import WebsitePageComponent from 'components/websiteStructure/pages';

export default function DebtorRegisterLeadPage() {
  return (
    <WebsitePageComponent id="debtor.registerLead.title" data-walkme="debtor-registerLead-title" noPaper>
      <DebtorRegisterLeadComponent />
    </WebsitePageComponent>
  );
}
