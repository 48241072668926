/* @flow */

import React from 'react';
import {useSelector} from 'react-redux';
import {Route, Switch, useRouteMatch} from 'react-router-dom';

import Error404Page from 'components/errors/error404';
import RestrictedRouteComponent from 'components/route/restrictedRoute';

import SellerBestPricingPage from './bestPricing';
import SellerBillPortfolioPage from './bills';
import SellerDashboardPage from './dashboard';
import SellerInvoiceDetailsPage from './invoiceDetails';
import SellerMGMPage from './mgm';
import SellerOffersPage from './offers';
import SellerInvoicesPortfolioPage from './invoicesPortfolio';
import SellerReferralPage from './referral';
import SellInvoicePage from './sell';

import {PROTECTED_SELLER_DASHBOARD} from 'constants/pageRoutes';

export default function SellerPage() {
  const roleSeller = useSelector(state => state.auth.user.activeCompany.roleSeller);
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.url}/offer`} component={SellerOffersPage} redirectUrl={PROTECTED_SELLER_DASHBOARD}/>
      <RestrictedRouteComponent path={`${match.url}/sell/:id?`} component={SellInvoicePage} condition={roleSeller.canExecute} redirectUrl={PROTECTED_SELLER_DASHBOARD} />
      <Route exact path={`${match.url}/`} component={SellerDashboardPage} />
      <Route path={`${match.url}/bestpricing`} component={SellerBestPricingPage} />
      <Route path={`${match.url}/bills`} component={SellerBillPortfolioPage} />
      <Route path={`${match.url}/invoice/:id`} component={SellerInvoiceDetailsPage} />
      <Route path={`${match.url}/mgm`} component={SellerMGMPage} />
      <Route path={`${match.url}/portfolio`} component={SellerInvoicesPortfolioPage} />
      <Route path={`${match.url}/referral`} component={SellerReferralPage} />
      <Route component={Error404Page} />
    </Switch>
  );
}
