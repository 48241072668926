/* @flow */

export default class RegisterLeadRequest {
  debtorCompanyId: number;
  insurerId: string;

  titleId: number;
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  languageId: number;
}