/* @flow */

import React from 'react';

import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';
import LoadingComponent from 'components/loading';
import MandatoryFormFieldsComponent from 'components/form/mandatory';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import UploadFieldComponent from 'components/formFields/upload';

import Document from 'models/documents/document';
import DocumentType from 'models/documents/documentType';

import DocumentService from 'services/DocumentService';
import InvoiceSellerService from 'services/InvoiceSellerService';
import {handleApiFormResponse} from 'lib/forms';

import {
  INVOICE_SELLER_DOCUMENT_DELETE_POST,
  INVOICE_SELLER_DOCUMENT_POST
} from 'constants/apiRoutes';

type SellInvoiceTab4ComponentProps = {
  invoiceId: number;
  successHandler: () => void;
  cancelInvoice: () => void;
}

type SellInvoiceTab4ComponentState = {
  documents: Document[];
  extraDocuments: Document[];
  filteredExtraDocuments: Document[];
  isLoading: boolean;
  isProcessing: boolean;
  selectedDocumentType: DocumentType;
  toClassifyDocumentType: DocumentType;
}

class SellInvoiceTab4Component extends React.Component<SellInvoiceTab4ComponentProps, SellInvoiceTab4ComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isProcessing: false
    };
  }

  componentDidMount = async () => {
    const response = await InvoiceSellerService.getInvoiceDocuments(this.props.invoiceId);
    this.setState({
      documents: response.documents,
      extraDocuments: response.extraDocuments,
      filteredExtraDocuments: response.extraDocuments,
      selectedDocumentType: response.extraDocuments[0]?.type,
      toClassifyDocumentType: response.toClassifyDocumentType,
      isLoading: false
    });
  }

  addDocument = () => {
    const documents = this.state.documents;
    let filteredExtraDocuments = this.state.filteredExtraDocuments;
    let selectedDocumentType = this.state.selectedDocumentType;
    documents.push(new Document(selectedDocumentType, false));
    if (selectedDocumentType.typeId !== this.state.toClassifyDocumentType.typeId)
    {
      filteredExtraDocuments = filteredExtraDocuments.filter(d => d.type !== selectedDocumentType);
      selectedDocumentType = filteredExtraDocuments[0].type;
    }
    this.setState({documents, filteredExtraDocuments, selectedDocumentType});
  }

  handleDocumentSelect = (index: number) => (file: File) => {
    const documents = this.state.documents;
    documents[index].file = file;
    documents[index].filename = file.name;
    documents[index].isDeleted = false;
    documents[index].isMissing = false;
    this.setState({documents});
  };

  handleDocumentRemove = (index: number) => () => {
    const documents = this.state.documents;
    let filteredExtraDocuments = this.state.filteredExtraDocuments;
    if (documents[index].isRequired) {
      // can't remove required documents
      documents[index].file = null;
      documents[index].filename = '';
      documents[index].isDeleted = true;
    } else if (documents[index].id > 0) {
      // flag optional documents - but already submitted - to be archived
      documents[index].isDeleted = true;
    } else {
      // remove optional - not yet submitted - documents
      let document = documents[index];
      let foundDocumentType = this.state.extraDocuments.find(d => d.type.typeId === document.type.typeId)
      if (foundDocumentType !== undefined) {
        filteredExtraDocuments.push(foundDocumentType)
        filteredExtraDocuments = this.state.extraDocuments.filter(item => filteredExtraDocuments.includes(item));
      }
      
      documents.splice(index, 1);
    }
    this.setState({documents, filteredExtraDocuments});
  };

  submit = async () => {
    if (!this.validate()) return;

    const documents = this.state.documents;

    // delete optional removed documents
    const delPromises = [];
    for (let i = 0; i < documents.length; i++) {
      if (!documents[i].isRequired && documents[i].isDeleted) {
        delPromises.push(DocumentService.deleteDocument(INVOICE_SELLER_DOCUMENT_DELETE_POST, documents[i].id));
      }
    }

    // add documents
    const addPromises = [];
    for (let i = 0; i < documents.length; i++) {
      if (!documents[i].isDeleted && documents[i].file !== null) {
        addPromises.push(DocumentService.sendDocument2(INVOICE_SELLER_DOCUMENT_POST, documents[i].file,
          {documentTypeId: documents[i].type.typeId, invoiceId: this.props.invoiceId}));
      }
    }

    // if nothing, do nothing
    if (delPromises.length === 0 && addPromises.length === 0) {
      this.props.successHandler();
      return;
    }

    this.setState({isProcessing: true});

    try {
      await Promise.all(delPromises);
      await Promise.all(addPromises);
      this.props.successHandler();
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }
  }

  validate = () => {
    let isValid = true;
    const documents = this.state.documents;

    for (let i = 0; i < documents.length; i++) {
      if (documents[i].isRequired) {
        // Required documents

        if (documents[i].id === 0 && documents[i].file === null) {
          // not yet provided and missing -> issue
          documents[i].isMissing = true;
          isValid = false;
        }

        if (documents[i].id !== 0 && documents[i].isDeleted) {
          // provided then delete -> issue
          documents[i].isMissing = true;
          isValid = false;
        }
      } else if (documents[i].id === 0 && documents[i].file === null) {
      // Optional documents and missing -> issue
        documents[i].isMissing = true;
        isValid = false;
      }
    }

    if (!isValid) this.setState({documents});
    return isValid;
  }

  cancelInvoice = () => {
    this.setState({isProcessing: true});
    this.props.cancelInvoice();
  }

  handleDocumentTypeChange = (event) => {
    this.setState({selectedDocumentType: event.target.value});
  }

  render() {
    if (this.state.isLoading) return (<LoadingComponent />);

    const defaultFieldOptions = {
      margin: 'normal', fullWidth: true
    };

    return (
      <React.Fragment>
        <PageSubTitleViewComponent id="page.sellInvoice.tab4.title" data-walkme="sellinvoice-title-documents" />

        {this.state.documents.length === 0 ? (
          <Box mt={3} mb={3}>
            <Alert severity="info">
              <IntlMessageComponent id="page.sellInvoice.tab4.noDocumentRequired" />
            </Alert>
          </Box>
        ) : (
          <Box mt={3}>
            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={5}>
              {this.state.documents.map((doc, index) => {
                if (!doc.isRequired && doc.isDeleted) return null;
                return (
                  <Grid item xs={12} md={6} lg={3} key={`col-${index}`}>
                    <UploadFieldComponent
                      disabled={this.state.isProcessing}
                      displayProps={defaultFieldOptions}
                      filename={doc.filename}
                      isMissing={doc.isMissing}
                      isRequired={doc.isRequired}
                      label={`document.type.${doc.type.code}`}
                      successHandler={this.handleDocumentSelect(index)}
                      removeHandler={this.handleDocumentRemove(index)}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        )}

        <Box mt={5}>
          <FormControl>
            <InputLabel><IntlMessageComponent id="component.company.documentList.header.documenttype.optional" /></InputLabel>
            <Select
              style={{minWidth: 286}}
              labelId="select-document-type-dropdown-label"
              id="select-document-type-dropdown"
              value={this.state.selectedDocumentType}
              onChange={this.handleDocumentTypeChange}
            >
              {this.state.filteredExtraDocuments.map((doc) => {
                return (
                  <MenuItem value={doc.type}><IntlMessageComponent id={`document.type.${doc.type.code}`} /></MenuItem>
                );
              })}
            </Select>
            <Box mt={1}>
            <ButtonComponent
              color="secondary"
              variant="contained"
              disabled={this.state.isProcessing}
              onClick={this.addDocument}
            ><IntlMessageComponent id="general.button.addDocument" /></ButtonComponent>
            </Box>
          </FormControl>
        </Box>

        <Box mt={5}>
          <ButtonComponent
            color="default"
            variant="contained"
            disabled={this.state.isProcessing}
            onClick={this.cancelInvoice}
          ><IntlMessageComponent id="page.sellInvoice.button.cancelInvoice" /></ButtonComponent>

          <Box component="span" ml={1}>
            <ButtonComponent
              color="primary"
              variant="contained"
              isProcessing={this.state.isProcessing}
              onClick={this.submit}
            ><IntlMessageComponent id="page.sellInvoice.tab2.button.validate" /></ButtonComponent>
          </Box>

          <MandatoryFormFieldsComponent/>
        </Box>
      </React.Fragment>
    );
  }
}

export default SellInvoiceTab4Component;
