/* @flow */

import React from 'react';
import CountryFlag from "flagit";
import {useSelector} from 'react-redux';

import Country from 'models/generics/country';

type CountryFlagComponentProps = {
  countryId: number;
}

export default function CountryFlagComponent(props: CountryFlagComponentProps) {
  const {countries, genericDataIsLoading} = useSelector(state => state.locale);

  const getCountryById = (id: number) => {
    if (!genericDataIsLoading && Array.isArray(countries) && countries.length > 0) {
      const country = countries.find((c: Country) => c.id === id);
      if (country !== undefined && country !== null) return country;
    }
    return null;
  };

  const getIconFlag = (countryCode: string) => {
    return <CountryFlag countryShort={countryCode} size="sm" />
  }

  return getIconFlag(getCountryById(props.countryId).code);
}