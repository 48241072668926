/* @flow */

import CommissionMembershipIsoOffice from './commissionMembershipIsoOffice';

export default class CommissionMembershipIsoCompany {
  id: number;
  name: string;
  offices: CommissionMembershipIsoOffice[];
  membershipFeeCouponAmountUsed: number;
  membershipFee: number;
  membershipCommission: number;

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
    this.offices = [];
    this.membershipFeeCouponAmountUsed = 0;
    this.membershipFee = 0;
    this.membershipCommission = 0;
  }
}
