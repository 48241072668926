/* @flow */

import RegisterReferralNoteRequest from 'models/requests/sellers/registerReferralNoteRequest';

export default class RegisterReferralNoteForm {
  note: string;

  constructor() {
    this.note = '';
  }

  toRequest(referrerCompanyId: number, refereeCompanyId: number) {
    const request = new RegisterReferralNoteRequest();
    request.referrerCompanyId = referrerCompanyId;
    request.refereeCompanyId = refereeCompanyId;
    request.note = this.note;
    return request;
  }
}