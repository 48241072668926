/* @flow */

import moment from 'moment/moment';

export default class InvoiceBatch {
  id: number;
  reference: string;
  debtAmount: number;
  dueDate: ?moment;
  batchDebtorReference: string;
  batchIsExcluded: boolean;
  batchBalance: number;
}