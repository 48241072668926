/* @flow */

import SupplierCompany from 'models/companies/company';

export default class SubmitInvoiceTab1Form {
  supplierCompany: SupplierCompany;
  deliveredGoods: boolean;

  constructor(supplierCompany: SupplierCompany) {
    this.supplierCompany = supplierCompany;
    this.deliveredGoods = false;
  }
}
