/* @flow */

import ExtendsLeadLifetimeRequest from 'models/requests/isos/extendsLeadLifetimeRequest';

export default class ExtendLeadLifetimeForm {
  lifetimeExtensionReason: string;

  constructor() {
    this.lifetimeExtensionReason = '';
  }

  toRequest(companyId: number, leadId: number){
    const request = new ExtendsLeadLifetimeRequest();
    request.companyId = companyId;
    request.leadId = leadId;
    request.lifetimeExtensionReason = this.lifetimeExtensionReason;
    return request;
  }
}
