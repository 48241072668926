/* @flow */

import { plainToClass } from 'class-transformer';

import BaseService from './api/baseService';

import {
  CONFIRMING_INVOICES_DETAILS_DOCUMENTS_GET,
  CONFIRMING_INVOICES_DETAILS_GET,
  CONFIRMING_INVOICES_PORTFOLIO_EXPORT,
  CONFIRMING_INVOICES_PORTFOLIO_GET,
  CONFIRMING_OPEN_INVOICES_GET,
  CONFIRMING_PROFILE_SUMMARY_GET, CONFIRMING_SUPPLIERS_GET, CONFIRMING_SUPPLIERS_POST, CONFIRMING_SUPPLIER_BANKACCOUNT_GET, CONFIRMING_SUPPLIER_BANKACCOUNT_POST, CONFIRMING_SUPPLIER_DETAILS_GET, CONFIRMING_SUPPLIER_LINK_BANKACCOUNT_POST
} from 'constants/apiRoutes';

import ProfileSummaryCard from 'models/dashboards/profileSummaryCard';

import SupplierCompany from 'models/confirming/supplierCompany';
import SetCompanyBankAccountRequest from 'models/requests/register/setCompanyBankAccountRequest';
import RegisterSupplierRequest from 'models/requests/debtors/registerSupplierRequest';
import SupplierCompanyBankAccount from 'models/confirming/supplierCompanyBankAccount';
import LinkBankAccountToSpecificRequest from 'models/requests/confirming/LinkBankAccountToSpecificRequest';
import Invoice from 'models/confirming/invoice';
import InvoiceFilters from 'models/confirming/invoiceFilters';
import Company from 'models/companies/company';

export default class ConfirmingService {

  // Invoices - Details
  static getInvoiceDetails = (companyId: number, invoiceId: number) =>
    BaseService.simpleGET(`${CONFIRMING_INVOICES_DETAILS_GET}/${companyId}/${invoiceId}`,
      data => {
        const invoice = plainToClass(Invoice, data.invoice);
        invoice.sellerCompany = plainToClass(Company, data.invoice.sellerCompany);
        return { invoice, statusKeys: data.statusKeys };
      });

  static getInvoiceDocument = (companyId: number, invoiceId: number) =>
    BaseService.simpleFileURLGET(`${CONFIRMING_INVOICES_DETAILS_DOCUMENTS_GET}/${companyId}/${invoiceId}`);

  // Dashboard
  static getConfirmingProfileSummary = (companyId: number) =>
    BaseService.simpleGET(`${CONFIRMING_PROFILE_SUMMARY_GET}/${companyId}`, data => data.map(d => plainToClass(ProfileSummaryCard, d)));

  static getOpenInvoices = (companyId: number) =>
    BaseService.simpleGET(`${CONFIRMING_OPEN_INVOICES_GET}/${companyId}`,
      data => data.map(d => plainToClass(Invoice, d)));

  // Invoices
  static getInvoicesPortfolio = (companyId: number) =>
    BaseService.simpleGET(`${CONFIRMING_INVOICES_PORTFOLIO_GET}/${companyId}`,
      data => {
        const invoices = data.invoices.map(d => {
          const invoice = plainToClass(Invoice, d);
          invoice.supplierCompany = plainToClass(SupplierCompany, d.supplierCompany);
          return invoice;
        });
        const filters = new InvoiceFilters();
        if (data.filters.statusKeys)
          filters.statusKeys = data.filters.statusKeys;
        if (data.filters.supplierCompanies)
          filters.supplierCompanies = data.filters.supplierCompanies.map(d => new Company(d.supplierId, d.supplierName));
        if (data.filters.invoiceMaxAmount)
          filters.invoiceMaxAmount = data.filters.invoiceMaxAmount;
        if (data.filters.invoiceMinAmount)
          filters.invoiceMinAmount = data.filters.invoiceMinAmount;
        return { invoices, filters };
      });

  static downloadPortfolio = (request: DownloadPortfolioRequest) =>
    BaseService.simpleFileDownloadGET(`${CONFIRMING_INVOICES_PORTFOLIO_EXPORT}?${request.toRouteParameters()}`);

  // Suppliers
  static getConfirmingSuppliers = (companyId: number) =>
    BaseService.simpleGET(`${CONFIRMING_SUPPLIERS_GET}/${companyId}`, data => data.map(d => plainToClass(SupplierCompany, d)));
  static getConfirmingSupplierBankAccounts = (companyId: number, supplierId: number) =>
    BaseService.simpleGET(`${CONFIRMING_SUPPLIER_BANKACCOUNT_GET}/${companyId}/${supplierId}`, data => data.map(d => plainToClass(SupplierCompanyBankAccount, d)));

  static getConfirmingSupplierDetails = (companyId: number, supplierId: number) =>
    BaseService.simpleGET(`${CONFIRMING_SUPPLIER_DETAILS_GET}/${companyId}/${supplierId}`, data => plainToClass(SupplierCompany, data));

  static upsertSupplierBankAccount = (request: SetCompanyBankAccountRequest) =>
    BaseService.simplePOST(CONFIRMING_SUPPLIER_BANKACCOUNT_POST, request, null);

  static LinkBankAccountToSpecific = (request: LinkBankAccountToSpecificRequest) =>
    BaseService.simplePOST(CONFIRMING_SUPPLIER_LINK_BANKACCOUNT_POST, request, null);

  static addSupplier = (request: RegisterSupplierRequest) =>
    BaseService.simplePOST(CONFIRMING_SUPPLIERS_POST, request, data => data);
}
