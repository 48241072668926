/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';

import DebtorInvoicesToApproveTableComponent from 'app/debtors/invoicesToApproveTable';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

export default function DebtorInvoicesToApproveTableWithTitleComponent() {
  return (
    <React.Fragment>
      <PageSubTitleViewComponent id="debtor.dashboard.invoicesToApprove.title" data-walkme="debtor-invoicesToApprove" />

      <Box mt={3}>
        <DebtorInvoicesToApproveTableComponent />
      </Box>
    </React.Fragment>
  );
}
