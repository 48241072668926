/* @flow */

import SetBusinessOpportunityRequest from 'models/requests/leads/setBusinessOpportunityRequest';

export default class BusinessOpportunityForm {
  financingNeedId: number;
  financingNeedFrequencyId: number;
  invoicesAvgAmountId: number;
  invoicesYearlyAmountId: number;

  constructor() {
    this.financingNeedId = 0;
    this.financingNeedFrequencyId = 0;
    this.invoicesAvgAmountId = 0;
    this.invoicesYearlyAmountId = 0;
  }

  toRequest(leadId: number) {
    const request = new SetBusinessOpportunityRequest();
    request.leadId = leadId;
    request.financingNeedId = this.financingNeedId;
    request.financingNeedFrequencyId = this.financingNeedFrequencyId;
    request.invoicesAvgAmountId = this.invoicesAvgAmountId;
    request.invoicesYearlyAmountId = this.invoicesYearlyAmountId;
    return request;
  }
}
