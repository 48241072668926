/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import AmountFormatterComponent from 'components/formatters/amount';
import LoadingComponent from 'components/loading';
import NumberFormatterComponent from 'components/formatters/number';
import PercentageFormatterComponent from 'components/formatters/percentage';

import BuyerInvestmentProfileFormViewComponent from '../form/view';

import InvestmentProfileValues from 'models/buyers/investmentProfiles/investmentProfileValues';
import InvestmentProfile from 'models/buyers/investmentProfiles/investmentProfile';
import InvestmentProfileForm from '../form/model';

import BuyerService from 'services/BuyerService';
import {handleApiFormResponse, handleFormChange, handleFormValidation} from 'lib/forms';
import loadConstraints from 'lib/validation/loadConstraints';
import NotificationHelper from 'lib/notifications';

import {addInvestmentProfileSuccess} from 'store/actions/authActions';

type BuyerInvestmentProfileNewComponentProps = {
  activeCompanyId: number;
  activeCompanyInvestmentProfileId: number;
  canExecute: boolean;
  hasActiveMembership: boolean;
  addInvestmentProfileSuccess: (investmentProfile: InvestmentProfile) => void;
}

type BuyerInvestmentProfileNewComponentState = {
  constraints: any;
  errors: Map<string, ?string>;
  form: InvestmentProfileForm;
  formErrors: string[];
  isLoading: boolean;
  isProcessing: boolean;
  placeholders: any;
}

class BuyerInvestmentProfileNewComponent extends React.Component<BuyerInvestmentProfileNewComponentProps, BuyerInvestmentProfileNewComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      constraints: null,
      errors: null,
      form: null,
      formErrors: [],
      isLoading: true,
      isProcessing: false,
      placeholders: null
    };
  }

  componentDidMount = async () => {
    try {
       // Load defaults
       const defaults = await BuyerService.getInvestmentProfileDefaults();

      // Load & build constraints
      const constraints = await loadConstraints('InvestmentProfile');
      constraints.amount.numericality.greaterThanOrEqualTo = defaults.amountMinValue;
      constraints.minInvoiceAmount.numericality.greaterThanOrEqualTo = defaults.minAmountMinValue;
      constraints.maxInvoiceAmount.numericality.greaterThanOrEqualTo = defaults.maxAmountMinValue;
      constraints.maxInvoiceAmount.isLessThanSibling.multiplier = defaults.maxAmountMultiplier;
      constraints.pct90.numericality.greaterThanOrEqualTo = defaults.pct90MinValue;
      constraints.pct90.numericality.lessThanOrEqualTo = defaults.pct90MaxValue;

      const placeholders = {
        amount: <AmountFormatterComponent amount={defaults.amountMinValue} />,
        minAmount: <AmountFormatterComponent amount={defaults.minAmountMinValue} />,
        maxAmount: <AmountFormatterComponent amount={defaults.maxAmountMinValue} />,
        multiplier: <PercentageFormatterComponent noFractionDigits value={(defaults.maxAmountMultiplier * 100)} />,
        minPct: <NumberFormatterComponent value={defaults.pct90MinValue} />,
        maxPct: <NumberFormatterComponent value={defaults.pct90MaxValue} />
      };

      this.setState({
        constraints,
        errors: new Map(Object.keys(constraints).map(e => [e, undefined])),
        form: new InvestmentProfileForm(new InvestmentProfileValues()),
        isLoading: false,
        placeholders
      });
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  };

  handleChange = (fieldName: string) => (event: object) => handleFormChange.call(this, fieldName, event.target.value);
  handleChangeBankAccount = (bankAccountId: number) => handleFormChange.call(this, 'bankAccountId', bankAccountId);

  validateForm = () => handleFormValidation.call(this);

  submitForm = async () => {
    this.state.form.comment = 'New profile';
    if (!this.validateForm()) return;
    this.setState({formErrors: [], isProcessing: true});

    try {
      const request = this.state.form.toAddRequest(this.props.activeCompanyId, this.props.activeCompanyInvestmentProfileId);
      await BuyerService.addInvestmentProfile(request);
      NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS);
      this.props.addInvestmentProfileSuccess({id: 0, name: request.name, isActive: true, isMain:false, companyId: this.props.activeCompanyId});
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }
  };

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <BuyerInvestmentProfileFormViewComponent
        errors={this.state.errors}
        form={this.state.form}
        formErrors={this.state.formErrors}
        isActive={this.props.isActive}
        isProcessing={this.state.isProcessing}
        placeholders={this.state.placeholders}
        isModifyForm={false}
        btnId={"general.save"}
        pageTitle={"buyer.ip.create"}
        canExecute={this.props.canExecute && this.props.hasActiveMembership}
        handleChange={this.handleChange}
        handleChangeBankAccount={this.handleChangeBankAccount}
        submitForm={this.submitForm}
      />
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id,
  activeCompanyInvestmentProfileId: state.auth.user.activeCompany.investmentProfile.id,
  canExecute: state.auth.user.activeCompany.canExecute,
  hasActiveMembership: state.auth.user.activeCompany.hasActiveMembership
});

export default connect(mapStateToProps, {addInvestmentProfileSuccess})(BuyerInvestmentProfileNewComponent);
