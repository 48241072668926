import {connect} from 'react-redux';
import CountryFieldComponent from './countryImpl';

const mapStateToProps = (state, props) => ({
  ...props,
  countries: state.locale.countries,
  isLoading: state.locale.genericDataIsLoading,
  locale: state.locale.locale
});

export default connect(mapStateToProps)(CountryFieldComponent);
