import React from 'react';
import {useSelector} from 'react-redux';

import moment from 'moment';
import {Link} from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import ButtonComponent from 'components/buttons/button';
import DateFormatterComponent from 'components/formatters/date';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PageTitleViewComponent from 'components/pageTitle';

import {SIGN_IN} from 'constants/pageRoutes';

export default function MaintenanceComponent() {
  const maintenance = useSelector(state => state.locale.maintenance);
  const dateTo = moment.utc(maintenance.dateTo).local();

  const values = {
    date: <b><DateFormatterComponent date={dateTo} format="dddd DD MMMM YYYY" /></b>,
    time: <b><DateFormatterComponent date={dateTo} format="HH:mm" /></b>,
    timeUS: <b><DateFormatterComponent date={dateTo} format="LT" /></b>,    
    supportEmail: <a href="mailto:support@edebex.com">support@edebex.com</a>
  };

  return (
    <Container maxWidth="sm">
      <Box height="100%" textAlign="center">
        <img src="./images/maintenance.png" alt="maintenance" title="maintenance"/>

        <PageTitleViewComponent id="component.maintenance.title" />

        <Box mt={1}>
          <IntlMessageComponent id="component.maintenance.text" values={values} />
        </Box>

        <Box mt={5}>
          <ButtonComponent
            color="primary"
            variant="contained"
            component={Link}
            to={SIGN_IN}
          ><IntlMessageComponent id="component.maintenance.button" /></ButtonComponent>
        </Box>
      </Box>
    </Container>
  );
}
