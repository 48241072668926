/* @flow */

import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

import IntlMessageComponent from 'components/formatters/intlMessage';

import {
  COMPANY_TYPE_BUYER,
  COMPANY_TYPE_DEBTOR,
  COMPANY_TYPE_ISO,
  COMPANY_TYPE_SELLER,
  COMPANY_TYPE_CONFIRMING
} from 'constants/constants';

type CompanyTypeFormFieldComponentProps = {
  allowCompanyTypeDebtor: boolean;
  allowCompanyTypeISO: boolean;
  allowCompanyTypeConfirming: boolean;
  companyType: number;
  handleCompanyTypeChange: (companyType: number) => void;
}

export default function CompanyTypeFormFieldComponent(props: CompanyTypeFormFieldComponentProps) {

  const onChange = (event) => props.handleCompanyTypeChange(parseInt(event.target.value));

  const companyTypes = [COMPANY_TYPE_SELLER, COMPANY_TYPE_BUYER];
  if (props.allowCompanyTypeISO) {
    companyTypes.push(COMPANY_TYPE_ISO);
  }
  if (props.allowCompanyTypeDebtor) {
    companyTypes.push(COMPANY_TYPE_DEBTOR);
  }
  if (props.allowCompanyTypeConfirming) {
    companyTypes.push(COMPANY_TYPE_CONFIRMING);
  }

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">
        <IntlMessageComponent id="form.companyType.label" data-walkme="inscrire-text-veuillezchoisir" />
      </FormLabel>

      <RadioGroup
        aria-label="companyType"
        name="form.companyType"
        row
        value={props.companyType.toString()}
        onChange={onChange}
      >
        {companyTypes.map(companyType => (
          <FormControlLabel
            key={`radio-${companyType}`}
            value={companyType.toString()}
            control={<Radio color="primary" />}
            label={<IntlMessageComponent id={`form.companyType.option${companyType}.label`} />} />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
