/* @flow */

export default class DisableCompanyUBORequest {
  companyId: number;
  companyPersonId: number;

  constructor(companyId: number, companyPersonId: number) {
    this.companyId = companyId;
    this.companyPersonId = companyPersonId;
  }
}
