/* @flow */

import moment from 'moment';

export default class GetPerformanceRequest {      
  companyId: number;
  investmentProfileId: number;
  dateFrom: moment;
  dateTo: moment;
  invoiceState: number;

  constructor(companyId: number, investmentProfileId: number, dateFrom: moment, dateTo: moment, invoiceState: number) {
    this.companyId = companyId;
    this.investmentProfileId = investmentProfileId;
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    this.invoiceState = invoiceState;
  }

  toRouteParameters() {
    let route = `companyId=${this.companyId}&investmentProfileId=${this.investmentProfileId}`;
    if (this.invoiceState !== null && this.invoiceState > 0) {
      route += `&invoiceState=${this.invoiceState}`;
    }
    if (this.dateFrom && this.dateTo) {
      route += `&dateFrom=${this.dateFrom.format('YYYY-MM-DD')}&dateTo=${this.dateTo.format('YYYY-MM-DD')}`;
    }
    return route;
  }
}
