/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import BuyerInvestmentSummaryOverviewComponent from './investmentSummaryOverview';
import BuyerInvestmentSummaryPerformanceComponent from './investmentSummaryPerformance';

import BuyerInvestmentSummaryOverview from 'models/buyers/dashboards/investmentSummaryOverview';
import BuyerInvestmentSummaryPerformance from 'models/buyers/dashboards/investmentSummaryPerformance';

type BuyerInvestmentSummaryComponentProps = {
  investmentOverview: BuyerInvestmentSummaryOverview;
  investmentPerformance: BuyerInvestmentSummaryPerformance;
}

class BuyerInvestmentSummaryComponent extends React.PureComponent<BuyerInvestmentSummaryComponentProps> {
  render() {
    const items = [];
    items.push(<BuyerInvestmentSummaryOverviewComponent model={this.props.investmentOverview} />);
    items.push(<BuyerInvestmentSummaryPerformanceComponent model={this.props.investmentPerformance} />);
    const breakpoints={
      0: {items: 1},
      680: {items: 2},
      1000: {items: 3},
      1200: {items: 4},
      1400: {items: 5}
    };
    return (
      <React.Fragment>
        <PageSubTitleViewComponent id="buyer.is.title" />
        <div className="hiddenMobile">
          <Box mt={3}>
            <Grid container spacing={3} alignItems="stretch">
              <Grid item xs={12} md={6} data-walkme="bdashboard-box-roi">
                <BuyerInvestmentSummaryPerformanceComponent model={this.props.investmentPerformance} />
              </Grid>
              <Grid item xs={12} md={6} data-walkme="bdashboard-box-investmentsetup">
                <BuyerInvestmentSummaryOverviewComponent model={this.props.investmentOverview} />
              </Grid>
            </Grid>
          </Box>
        </div>
        <div className="hiddenDesktop">
          <Box mt={3}>
            <AliceCarousel disableButtonsControls items={items} mouseTracking responsive={breakpoints} />
          </Box>
        </div>

      </React.Fragment>
    );
  }
}

export default BuyerInvestmentSummaryComponent;
