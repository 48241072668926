/* @flow */

import React from 'react';

import Typography from '@material-ui/core/Typography';

import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

export default function PaymentDelayedComponent() {
  return (
    <React.Fragment>
      <PageSubTitleViewComponent id="page.payment.delay.title" />
      <Typography variant="body1">
        <IntlMessageComponent id="page.payment.delay.description" />
      </Typography>
    </React.Fragment>
  );
}
