/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import IntlMessageComponent from 'components/formatters/intlMessage';

import CommercialDocumentsActiveFilters from './model';

import LanguageData from 'constants/documentLanguages';

type ISOCommercialDocumentsTableControlsComponentProps = {
  activeFilters: CommercialDocumentsActiveFilters;
  handleFiltersChange: (filters: CommercialDocumentsActiveFilters) => void;
}

export default function ISOCommercialDocumentsTableControlsComponent(props: ISOCommercialDocumentsTableControlsComponentProps) {

  const handleChange = (property: string, value: string) => {
    const activeFilters = props.activeFilters;
    activeFilters[property] = value;
    props.handleFiltersChange(activeFilters);
  };

  return (
    <Paper elevation={0}>
      <Box p={3}>
        <Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={3}>
          <Grid container item xs={12} md={6}>
            <Grid item xs={12} md={6}>
              <Box display="flex" height="100%" alignItems="center">
                <b><IntlMessageComponent id="component.commercial.search.name" /></b>
              </Box>
            </Grid>
          
            <Grid item xs={12} md={6}>
              <TextField
                data-walkme="scommercial-field-search"
                value={props.activeFilters.name}
                onChange={(event) => handleChange('name', event.target.value)}
                fullWidth
                margin="dense"
              />
            </Grid>
          </Grid>

          <Grid container item xs={12} md={6}>
            <Grid item xs={12} md={6}>
              <Box display="flex" height="100%" alignItems="center">
                <b><IntlMessageComponent id="component.commercial.search.language" /></b>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <Select
                  data-walkme="scommercial-field-language"
                  autoWidth
                  input={<Input name="mode" id="mode-selection" />}
                  value={props.activeFilters.language}
                  onChange={(event) => handleChange('language', event.target.value)}                  
                >
                  <MenuItem value="All">All</MenuItem>
                  
                  {LanguageData.map(language => (
                    <MenuItem value={language.locale}>
                      <IntlMessageComponent id={`general.language.${language.locale}`} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}
