/* @flow */

export default class RegisterSellerCompanyRequest {
  isoCompanyId: number;
  insurerId: number;

  lastName: string;
  firstName: string;
  email: string;
  mobile: string;
  functionName: string;
  languageId: number;
}
