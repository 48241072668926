/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import WireTransferPayment from 'models/payments/wireTransferPayment';

type PaymentByTransferComponentProps = {
  wireTransferPayment: WireTransferPayment
}

export default function PaymentByTransferComponent (props: PaymentByTransferComponentProps) {
  return (
    <React.Fragment>
      <PageSubTitleViewComponent id="component.payViaTransfer.title" />
      
      <Box mt={3}>
        <Grid container spacing={6} alignItems="stretch">
          <Grid item xs={12} md={6}>
            <IntlMessageComponent id="component.payViaTransfer.description" />
          </Grid>

          <Grid item xs={12} md={6}>
            <Box style={{textTransform: 'uppercase'}}>
              <Typography variant="body1">
                <Box component="span" color="grey.main" fontSize={13}>
                  <IntlMessageComponent id="component.payViaTransfer.iban" />
                </Box>
              </Typography>

              <Typography variant="body1">
                <b>{props.wireTransferPayment.iban}</b>
              </Typography>

              <Typography variant="body1">
                <Box component="span" color="grey.main" fontSize={13}>
                  <IntlMessageComponent id="component.payViaTransfer.bic" />
                </Box>
              </Typography>

              <Typography variant="body1">
                <b>{props.wireTransferPayment.bic}</b>
              </Typography>

              <Typography variant="body1">
                <Box component="span" color="grey.main" fontSize={13}>
                  <IntlMessageComponent id="component.payViaTransfer.reference" />
                </Box>
              </Typography>
              
              <Typography variant="body1">
                <b>{props.wireTransferPayment.reference}</b>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}
