/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import ButtonComponent from 'components/buttons/button';
import FormErrorsComponent from 'components/formErrors';
import IntlMessageComponent from 'components/formatters/intlMessage';

import ImpersonateForm from './model';

type ImpersonateViewComponentProps = {
  errors: Map<string, string>;
  form: ImpersonateForm;
  formErrors: string[];
  impersonateFailedMessage: string;
  showImpersonateFailedMessage: boolean;
  showLoginFailedMessage: boolean;
  showServerErrorMessage: boolean;
  hasTwoFactor: boolean;
  handleChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void;
  onKeyDown: () => void;
  submitForm: () => void;
}

export default function ImpersonateViewComponent(props: ImpersonateViewComponentProps) {
  return (
    <React.Fragment>
      <FormErrorsComponent formErrors={props.formErrors} />

      <Typography variant="body1" gutterBottom>
        <IntlMessageComponent id="page.impersonate.description" />
      </Typography>

      {props.showLoginFailedMessage &&
        <Typography variant="body1" gutterBottom>
          <b><IntlMessageComponent id="appModule.signInFailed" /></b>
        </Typography>
      }

      {props.showServerErrorMessage &&
        <Typography variant="body1" gutterBottom>
          <b><IntlMessageComponent id="general.failure" /></b>
        </Typography>
      }

      {props.showImpersonateFailedMessage &&
        <Typography variant="body1" gutterBottom>
          <b><IntlMessageComponent id={`${props.impersonateFailedMessage}`} /></b>
        </Typography>
      }

      <Box mt={3}>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.target"
                label={<IntlMessageComponent id="form.target.label" />}
                error={!!props.errors.get('target')}
                helperText={(props.errors.get('target')) ?
                  <IntlMessageComponent id={props.errors.get('target')} /> : ''}
                value={props.form.target}
                onChange={props.handleChange('target')}
                onKeyDown={props.onKeyDown}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>
        </Grid>

        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.username"
                label={<IntlMessageComponent id="form.username.label" />}
                error={!!props.errors.get('username')}
                helperText={(props.errors.get('username')) ?
                  <IntlMessageComponent id={props.errors.get('username')} /> : ''}
                value={props.form.username}
                onChange={props.handleChange('username')}
                onKeyDown={props.onKeyDown}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>
        </Grid>

        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.password"
                label={<IntlMessageComponent id="form.password.label" />}
                error={!!props.errors.get('password')}
                helperText={(props.errors.get('password')) ?
                  <IntlMessageComponent id={props.errors.get('password')} /> : ''}
                value={props.form.password}
                onChange={props.handleChange('password')}
                onKeyDown={props.onKeyDown}
                fullWidth
                margin="normal"
                required
                type="password"
              />
            </Box>
          </Grid>
        </Grid>

        {props.hasTwoFactor && <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.twofactorpin"
                label={<IntlMessageComponent id="form.twofactorpin.label" />}
                error={!!props.errors.get('password')}
                helperText={(props.errors.get('twofactorpin')) ?
                  <IntlMessageComponent id={props.errors.get('twofactorpin')} /> : ''}
                value={props.form.twofactorpin}
                onChange={props.handleChange('twofactorpin')}
                onKeyDown={props.onKeyDown}
                fullWidth
                margin="normal"
                required
                type="password"
              />
            </Box>
          </Grid>
        </Grid>}
      </Box>

      <Box mt={5}>
        <ButtonComponent
          color="primary"
          variant="contained"
          onClick={props.submitForm}
        ><IntlMessageComponent id="general.submit" /></ButtonComponent>
      </Box>
    </React.Fragment>
  );
}
