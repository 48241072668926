/* @flow */

import moment from 'moment';
import SupplierCompany from 'models/companies/company';

import InsertInvoiceRequest from 'models/requests/sellers/insertInvoiceRequest';
import UpdateInvoiceRequest from 'models/requests/sellers/updateInvoiceRequest';

export default class SellInvoiceForm {
  supplierCompany: SupplierCompany;
  reference: string;
  debtAmount: number;
  dueDate: moment;
  issueDate: moment;

  constructor() {
    this.supplierCompany = null;
    this.reference = '';
    this.debtAmount = '';
    this.dueDate = null;
    this.issueDate = null;
  }

  toInsertRequest(confirmingCompanyId: number) {
    const request = new InsertInvoiceRequest();
    request.sellerCompanyId = this.supplierCompany.id;
    request.debtorCompanyId = confirmingCompanyId;
    request.reference = this.reference;
    request.debtAmount = this.debtAmount;
    request.dueDate = moment(this.dueDate).format('YYYY/MM/DD');
    request.issueDate = moment(this.issueDate).format('YYYY/MM/DD');
    return request;
  }

  toUpdateRequest(confirmingCompanyId: number, invoiceId: number) {
    const request = new UpdateInvoiceRequest();
    request.debtorCompanyId = confirmingCompanyId;
    request.invoiceId = invoiceId;
    request.reference = this.reference;
    request.debtAmount = this.debtAmount;
    request.dueDate = moment(this.dueDate).format('YYYY/MM/DD');
    request.issueDate = moment(this.issueDate).format('YYYY/MM/DD');
    return request;
  }
}
