/* @flow */

import React from 'react';

import LoadingComponent from 'components/loading';
import SubmitinvoiceTab4ViewComponent from './view';

import InvoiceProposal from 'models/invoices/seller/invoiceProposal';

import SubmitFixInvoiceRequest from 'models/requests/sellers/submitFixInvoiceRequest';
import SubmitFlexInvoiceRequest from 'models/requests/sellers/submitFlexInvoiceRequest';

import InvoiceSellerService from 'services/InvoiceSellerService';
import {handleApiFormResponse} from 'lib/forms';

type SubmitinvoiceTab4ComponentProps = {
  invoiceId: number;
  successHandler: () => void;
  cancelInvoice: () => void;
}

type SubmitinvoiceTab4ComponentState = {
  invoice: InvoiceProposal;
  isLoading: boolean;
  isProcessing: boolean;
}

class SubmitinvoiceTab4Component extends React.Component<SubmitinvoiceTab4ComponentProps, SubmitinvoiceTab4ComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      invoice: null,
      isLoading: true,
      isProcessing: false
    };
  }

  componentDidMount = async () => {
    const invoice = await InvoiceSellerService.getInvoiceProposal(this.props.invoiceId);
    this.setState({invoice, isLoading: false});
  }

  cancelInvoice = () => {
    this.setState({isProcessing: true});
    this.props.cancelInvoice();
  }

  submitFlexInvoice = async () => {
    this.setState({isProcessing: true});

    try {
      const request = new SubmitFlexInvoiceRequest(this.props.invoiceId);
      await InvoiceSellerService.submitFlexInvoice(request);
      this.props.successHandler();
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }
  }

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <SubmitinvoiceTab4ViewComponent
        invoice={this.state.invoice}
        isProcessing={this.state.isProcessing}
        cancelInvoice={this.cancelInvoice}
        submitFlexInvoice={this.submitFlexInvoice}
      />
    );
  }
}

export default SubmitinvoiceTab4Component;
